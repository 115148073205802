/* eslint-disable */
import { Breadcrumb, Button, Form, Input, Select } from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader/Loader';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import '../../../constants/global.scss';
import {
  DISCARD_CHANGES,
  FacilityMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Pattern from '../../../constants/pattern';
import { MaskStyle } from '../../../constants/react-style';
import * as Routes from '../../../constants/routes';
import {
  ButtonVariables,
  ClientFacilityVariables,
  NO_COUNTRY_DATA,
} from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { generateCode } from '../../../libs/codeGenerators';
import NotConnected from '../../../libs/NotConnected';
import { ElementPermissions } from '../../../permissions';
import {
  addNewFacility,
  clearFacilityState,
  editFacility,
  getFacility,
} from '../../../redux/actions/facility.action';
import {
  clearStatesFetchState,
  getState,
} from '../../../redux/actions/state.action';
import './ClientFacility.scss';

const { Option } = Select;

class ClientFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'add',
      formDisable: true,
      logoPath: '',
      country: [],
      selectedStates: [],
      addMore: false,
      visible: false,
      demandPage: window.location.pathname,
      demandPageData: {},
      formEdit: false,
      facilityData: {},
      clientInfoData: {},
      network: window.navigator.onLine,
      message: null,
      title: null,
    };

    this.formFieldsPart1 = [
      {
        label: ClientFacilityVariables.FACILITY_NAME,
        key: 'name',
        max: '100',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        pattern: [Pattern.Name.regx, Pattern.Name.message],
        blur: (e) => {
          this.getCode(e.target.value);
        },
      },
      {
        label: ClientFacilityVariables.FACILITY_CODE,
        key: 'facilityCode',
        max: '25',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: ClientFacilityVariables.MECHADEMY_FACILITY_CODE,
        key: 'mechademyId',
        max: '25',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: ClientFacilityVariables.FACILITY_PO,
        key: 'facilityPO',
        max: '50',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: ClientFacilityVariables.COMPANY_NAME,
        key: 'companyName',
        max: '100',
        pattern: [Pattern.Name.regx, Pattern.Name.message],
      },
    ];

    this.formFieldsPart2 = [
      {
        label: ClientFacilityVariables.ADDRESS_ONE,
        key: 'addressLineOne',
        max: '150',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        pattern: [Pattern.Address.regx, Pattern.Address.message],
      },
      {
        label: ClientFacilityVariables.ADDRESS_TWO,
        key: 'addressLineTwo',
        max: '150',
        pattern: [Pattern.Address.regx, Pattern.Address.message],
      },
      {
        label: ClientFacilityVariables.CITY,
        key: 'city',
        max: '50',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        pattern: [Pattern.Letters.regx, Pattern.Letters.message],
      },
      {
        label: ClientFacilityVariables.ZIP_CODE,
        key: 'zip',
        max: '10',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        pattern: [Pattern.Zip.regx, Pattern.Zip.message],
      },
      {
        label: ClientFacilityVariables.COUNTRY,
        key: 'country',
        type: 'dropdown',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        change: (e) => {
          this.onCountrySelect(e);
        },
        data: this.state.country,
      },
      {
        label: ClientFacilityVariables.STATE,
        key: 'state',
        type: 'dropdown',
        required: [true, FacilityMessages.REQUIRED_FIELD],
        data: this.state.selectedStates,
      },
    ];
  }

  componentDidMount() {
    const { getStateAction } = this.props;
    const {
      location: { state },
    } = this.props;

    if (this.handleNetwork()) {
      getStateAction();
    }

    if (state && state.clientData) {
      this.setState({
        clientInfoData: state.clientData,
        facilityData: state.facilityData,
      });
    }

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isSuccessAddFacility,
      isErrorAddFacility,
      isSuccessEditFacility,
      isErrorEditFacility,
      isSuccessFetchFacility,
      isErrorFetchFacility,
      errorCode,
      facilityData,
      message,
    } = this.props.facilityState.apiState;
    const { isSuccessStateFetch, stateData } = this.props.statesState.apiState;
    const { clearFacilityState, clearStatesFetchState } = this.props;
    const {
      location: { state },
    } = this.props;

    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';

    if (
      isSuccessAddFacility &&
      isSuccessAddFacility !==
        prevProps.facilityState.apiState.isSuccessAddFacility
    ) {
      let tempAddMore = this.state.addMore;
      Notification.show(Types.Success, FacilityMessages.FACILITY_ADDED);
      this.disableEditForm();
      if (!tempAddMore) {
        this.setState(
          {
            mode: 'view',
            formDisable: true,
            facilityData,
          },
          () => {
            tempAddMore = false;
          }
        );
      } else {
        this.setState(
          {
            addMore: tempAddMore,
            mode: 'add',
          },
          () => {
            tempAddMore = true;
            this.props.form.resetFields();
            this.disableEditForm();
          }
        );
      }
    }

    if (isSuccessStateFetch) {
      clearStatesFetchState();
      this.setState({ country: stateData });
      this.getPageMode();
    }

    if (isSuccessFetchFacility) {
      clearFacilityState();
      this.setState(
        {
          mode: 'view',
          facilityData,
          formDisable: true,
        },
        () => {
          this.setFormData();
        }
      );
    }

    /** Remove clearFacilityState and implemented in clientInfo.js with same condition */
    if (
      isSuccessEditFacility &&
      isSuccessEditFacility !==
        prevProps.facilityState.apiState.isSuccessEditFacility
    ) {
      Notification.show(Types.Success, FacilityMessages.FACILITY_UPDATED);
      this.disableEditForm();
      this.setState({
        mode: 'view',
        formDisable: true,
        facilityData,
      });
    }

    if (isErrorAddFacility) {
      if (errorCode === 'ALREADY_EXISTS') {
        if (_.includes(message, 'mechademy'))
          Notification.show(Types.Error, `${FacilityMessages[errorCode]}`);
        else
          Notification.show(Types.Error, FacilityMessages.NAME_ALREADY_EXISTS);
      } else {
        Notification.show(
          Types.Error,
          `${FacilityMessages[errorCode]} ${displayMessage}`
        );
      }
      clearFacilityState();
    }

    if (isErrorEditFacility) {
      if (errorCode === 'ALREADY_EXISTS') {
        if (_.includes(message, 'mechademy'))
          Notification.show(Types.Error, `${FacilityMessages[errorCode]}`);
        else Notification.show(Types.Error, message);
      } else {
        Notification.show(
          Types.Error,
          `${FacilityMessages[errorCode]} ${displayMessage}`
        );
      }
      clearFacilityState();
    }

    if (isErrorFetchFacility) {
      Notification.show(
        Types.Error,
        `${FacilityMessages[errorCode]} ${displayMessage}`
      );
      clearFacilityState();
    }

    if (
      (state.facilityData &&
        prevProps.location.state.facilityData &&
        state.facilityData._id !== prevProps.location.state.facilityData._id) ||
      !prevProps.location.state.facilityData
    ) {
      this.getFacility();
    }
  }

  getPageMode() {
    const {
      location: { state },
    } = this.props;
    if (state && state.mode) {
      if (state.mode === 'edit') {
        this.setState({ formDisable: false, mode: 'edit' });
      } else if (state.mode === 'add') {
        this.setState({ formDisable: false, mode: 'add' });
      } else {
        this.setState({ formDisable: true, mode: 'view' });
        this.getFacility();
      }
    } else {
      this.props.history.push(Routes.clients);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  getFacility() {
    const { getFacilityAction } = this.props;
    const {
      location: { state },
    } = this.props;

    if (state && state.facilityData) {
      if (this.handleNetwork()) {
        getFacilityAction(state.facilityData._id);
      }
    }
  }

  setFormData() {
    const { facilityData } = this.state;

    if (facilityData && facilityData._id) {
      this.onCountrySelect(facilityData.country);
      this.props.form.setFieldsValue(facilityData, () => {
        this.disableEditForm();
      });
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  onEdit() {
    this.setState({ mode: 'edit' }, () => {
      this.setState({ formDisable: false });
    });
  }

  onCancel() {
    const { mode, clientInfoData } = this.state;

    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        demandPage: mode === 'edit' ? null : Routes.clients,
        demandPageData: {},
      });
    } else if (mode === 'add') {
      this.props.history.push(Routes.clientInfo, {
        clientData: clientInfoData,
        mode: 'view',
      });
    } else {
      this.setState({ mode: 'view' }, () => {
        this.setState({
          formDisable: true,
        });
      });
      this.props.form.resetFields();
      this.setFormData();
    }
  }

  saveMore() {
    this.setState({ addMore: true }, () => {
      this.saveData();
    });
  }

  save() {
    this.setState({ addMore: false }, () => {
      this.saveData();
    });
  }

  getCode(value) {
    if (this.state.mode !== 'edit') {
      value = value || '';
      const code = generateCode(value.trim());
      const {
        facilityCode,
        mechademyId,
        name,
      } = this.props.form.getFieldsValue();
      if (!facilityCode && !mechademyId) {
        this.props.form.setFieldsValue({
          name: value.trim(),
          facilityCode: code,
          mechademyId: code,
        });
      } else if (
        name &&
        name.length >= 3 &&
        facilityCode &&
        facilityCode.length >= 3
      ) {
        const firstThreeCharOfName = name.trim().substr(0, 3).toLowerCase();
        const firstThreeCharOfFacilityCode = facilityCode
          .trim()
          .substr(0, 3)
          .toLowerCase();
        if (firstThreeCharOfName !== firstThreeCharOfFacilityCode) {
          this.props.form.setFieldsValue({
            name: value.trim(),
            facilityCode: code,
            mechademyId: code,
          });
        }
      }
    }
  }

  validateFieldsValue() {
    const values = this.props.form.getFieldsValue();
    const valuesKey = Object.keys(this.props.form.getFieldsValue());
    for (let i = 0; i < valuesKey.length; i++) {
      values[valuesKey[i]] = values[valuesKey[i]]
        ? values[valuesKey[i]].trim()
        : values[valuesKey[i]];
    }
    this.props.form.setFieldsValue(values);
  }

  saveData() {
    const {
      location: { state },
    } = this.props;
    this.validateFieldsValue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.client = state.clientData._id;
        for (const fieldKey in values) {
          if (!values[fieldKey]) {
            delete values[fieldKey];
          }
        }
        if (this.handleNetwork()) {
          this.props.addNewFacilityAction(values);
        }
      }
    });
  }

  update() {
    this.validateFieldsValue();
    const { facilityData, clientInfoData } = this.state;

    if (!this.state.formEdit) {
      this.onCancel();
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const keys = Object.keys(values);
        for (let i = 0; i < keys.length; i++) {
          if (values[keys[i]] && !_.isEmpty(values[keys[i]])) {
          } else {
            delete values[keys[i]];
          }
        }
        if (this.handleNetwork()) {
          this.props.editFacilityAction(
            values,
            facilityData._id,
            clientInfoData._id
          );
        }
      } else {
      }
    });
  }

  onCountrySelect(country) {
    const { stateData } = this.props.statesState.apiState;
    const selectedState = stateData.find((val) => val.name === country);
    this.setState({
      selectedStates: selectedState ? selectedState.states : [],
    });
    this.props.form.resetFields(['state']);
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { mode, demandPage, demandPageData } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        if (mode === 'edit' && !demandPage) {
          this.setState({ mode: 'view' }, () => {
            this.setState({
              formDisable: true,
              formEdit: false,
            });
          });
          this.props.form.resetFields();
          this.setFormData();
        } else {
          this.props.history.push(demandPage, demandPageData);
        }
      }
    );
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        message: null,
        title: null,
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  newSystem() {
    const { clientInfoData, facilityData } = this.state;
    this.props.history.push(Routes.clientSystem, {
      clientData: clientInfoData,
      facilityData,
      mode: 'add',
    });
  }

  getFacilityName() {
    const { facilityData } = this.state;
    return facilityData && facilityData.name
      ? facilityData.name
      : ClientFacilityVariables.ADD_FACILITY;
  }

  getOption(data) {
    return <Option value={data.name}>{data.name}</Option>;
  }

  /** Change routed to manage pi tags */
  manageTags = () => {
    const { clientInfoData, facilityData } = this.state;
    this.props.history.push(Routes.manageTags, {
      clientData: clientInfoData,
      facilityData,
      mode: 'add',
    });
  };

  renderFormButton() {
    const { mode, facilityData } = this.state;
    const facilityId = facilityData?._id;
    if (mode === 'add') {
      return elementsGaurd(
        <div>
          <a className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </a>
          <Button className="btn-default" onClick={() => this.save()}>
            {ButtonVariables.SAVE}
          </Button>
          <Button className="btn-default" onClick={() => this.saveMore()}>
            {ButtonVariables.SAVE_MORE}
          </Button>
        </div>,
        ElementPermissions.ADD_FACILITY
      );
    }
    if (mode === 'edit') {
      return elementsGaurd(
        <div>
          <a className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </a>
          <Button
            className="btn-default"
            onClick={() => {
              this.update();
            }}
          >
            {ButtonVariables.UPDATE}
          </Button>
        </div>,
        ElementPermissions.EDIT_FACILITY,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    if (mode === 'view' && facilityId) {
      return elementsGaurd(
        <Button
          className="btn-default"
          onClick={() => {
            this.onEdit();
          }}
        >
          {ButtonVariables.EDIT}
        </Button>,
        ElementPermissions.EDIT_FACILITY,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    if (field.type) {
      if (field.type === 'dropdown') {
        let data = [];
        if (field.key === 'country') {
          data = this.state.country;
        } else {
          data = this.state.selectedStates;
        }
        return (
          <Select
            disabled={formDisable}
            showArrow={!formDisable}
            showSearch
            placeholder="Search here"
            className="dropdown-search-field"
            onChange={field.change ? field.change : () => {}}
          >
            {field.key === 'country' ||
            (this.state.selectedStates &&
              this.state.selectedStates.length) ? null : (
              <Option value={null}>{NO_COUNTRY_DATA}</Option>
            )}
            {data && data.map((data) => this.getOption(data))}
          </Select>
        );
      }
      return (
        <Input
          maxLength={field.max ? field.max : ''}
          onBlur={field.blur ? field.blur : () => {}}
          autoComplete="off"
          disabled={formDisable}
        />
      );
    }
    return (
      <Input
        maxLength={field.max ? field.max : ''}
        onBlur={field.blur ? field.blur : () => {}}
        autoComplete="off"
        disabled={formDisable}
      />
    );
  }

  renderField(field) {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item label={field.label} style={field.style}>
        {getFieldDecorator(field.key, {
          rules: [
            field.required
              ? {
                  required: field.required[0],
                  message: field.required[1],
                }
              : '',
            field.pattern
              ? {
                  pattern: field.pattern[0],
                  message: field.pattern[1],
                }
              : '',
          ],
        })(this.renderFieldType(field))}
      </Form.Item>
    );
  }

  renderForm() {
    return (
      <Form>
        {this.formFieldsPart1.map((field) => this.renderField(field))}
        <div className="Address">
          <label className="Address-Details">
            {ClientFacilityVariables.ADDRESS_DETAILS}
          </label>
          <div className="bar">
            <hr />
          </div>
        </div>
        {this.formFieldsPart2.map((field) => this.renderField(field))}
        <div className="action-btn">{this.renderFormButton()}</div>
      </Form>
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
          title={this.state.title}
        />
      );
    }
    return null;
  }

  renderBreadCrumb() {
    const { clientInfoData } = this.state;

    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => this.navigateToScreen(Routes.clients)}>
            {ClientFacilityVariables.CLIENTS}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData: clientInfoData,
                mode: 'view',
              })
            }
          >
            {clientInfoData.name}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a>{this.getFacilityName()}</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  render() {
    const { mode, facilityData } = this.state;
    const { loading } = this.props.facilityState;
    const stateLoading = this.props.statesState.loading;
    const facilityId = facilityData?._id;
    return (
      <div className="ClientFacility">
        {loading || stateLoading ? <Loader /> : null}
        <div className="header" style={MaskStyle}>
          <div className="title">{this.getFacilityName()}</div>
          <div className="path">
            <div>{this.renderBreadCrumb()}</div>
            <div>
              {mode === 'view' && facilityId
                ? elementsGaurd(
                    <Button
                      className="btn-default"
                      onClick={() => this.manageTags()}
                    >
                      {ButtonVariables.MANGE_TAGS}
                    </Button>,
                    ElementPermissions.VIEW_PI_TAG,
                    {
                      type: 'facility',
                      dataRef: {
                        _id: facilityId,
                      },
                    }
                  )
                : ''}

              {mode === 'view' && facilityId
                ? elementsGaurd(
                    <Button
                      className="btn-default"
                      onClick={() => this.newSystem()}
                    >
                      {ClientFacilityVariables.ADD_SYSTEM}
                    </Button>,
                    ElementPermissions.ADD_SYSTEM,
                    {
                      type: 'facility',
                      dataRef: {
                        _id: facilityId,
                      },
                    }
                  )
                : ''}
            </div>
          </div>
        </div>
        <div className="screen-content">
          <div className="content-div">
            {this.state.network ? this.renderForm() : <NotConnected />}
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  facilityState: state.FacilityReducer,
  statesState: state.StateReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addNewFacilityAction: (payload) => {
    dispatch(addNewFacility(payload));
  },
  getFacilityAction: (payload) => {
    dispatch(getFacility(payload));
  },
  editFacilityAction: (payload, id, clientId) => {
    dispatch(editFacility(payload, id, clientId));
  },
  clearFacilityState: () => dispatch(clearFacilityState()),
  getStateAction: () => dispatch(getState()),
  clearStatesFetchState: () => dispatch(clearStatesFetchState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    Form.create({
      name: 'clientFacility',
      onFieldsChange: (props, fields, allfields) => {
        window.dispatchEvent(new Event('form-edit'));
      },
    })(ClientFacility)
  )
);
