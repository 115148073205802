import {
  PANEL_TYPES_FETCH_SUCCESS,
  PANEL_TYPES_FETCH_PROGRESS,
  PANEL_TYPES_FETCH_ERROR,
  PANEL_TYPES_CLEAR_STATE,
  PANEL_TYPES_SAVE_SUCCESS,
  PANEL_TYPES_SAVE_ERROR,
  PANEL_TYPES_SAVE_PROGRESS,
  PANEL_TYPES_DELETE_ERROR,
  PANEL_TYPES_DELETE_SUCCESS,
  PANEL_TYPES_DELETE_PROGRESS,
  PANEL_TYPES_UPDATE_PROGRESS,
  PANEL_TYPES_UPDATE_ERROR,
  PANEL_TYPES_UPDATE_SUCCESS,
} from './addNewCard.types';

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
  isAddSuccess: false,
  isDeleteSuccess: false,
  isUpdateSuccess: false,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default function PanelTypeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PANEL_TYPES_FETCH_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case PANEL_TYPES_FETCH_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case PANEL_TYPES_FETCH_SUCCESS:
      const data = action.payload
        ? action.payload.map((element) => ({
            name: element.type,
            id: element._id,
          }))
        : [];
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data,
      };
    case PANEL_TYPES_SAVE_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case PANEL_TYPES_SAVE_SUCCESS:
      const initialData = state.data;
      initialData.push({ name: action.payload.type, id: action.payload._id });
      return {
        ...state,
        ...INITIAL_API_STATE,
        isAddSuccess: true,
        data: initialData,
      };
    case PANEL_TYPES_SAVE_ERROR:
      const errorResponse = action.payload.response.body;
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: errorResponse?.code,
        message: errorResponse?.message,
      };
    case PANEL_TYPES_DELETE_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case PANEL_TYPES_DELETE_SUCCESS:
      const { id } = action.payload;
      const previousData = state.data;
      const currentData = previousData.filter((item) => item.id !== id);
      return {
        ...state,
        ...INITIAL_API_STATE,
        isDeleteSuccess: true,
        data: currentData,
      };
    case PANEL_TYPES_DELETE_ERROR:
      return {
        ...state,
        isError: true,
      };
    case PANEL_TYPES_UPDATE_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case PANEL_TYPES_UPDATE_SUCCESS:
      const updatedPanelData = action.payload;
      const currentPanelData = JSON.parse(JSON.stringify(state.data));
      currentPanelData.forEach((element) => {
        if (element.id === updatedPanelData._id) {
          element.name = updatedPanelData.type;
        }
      });
      return {
        ...state,
        ...INITIAL_API_STATE,
        isUpdateSuccess: true,
        data: currentPanelData,
      };
    case PANEL_TYPES_UPDATE_ERROR:
      const { message } = action.payload.response.body;
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        message,
      };
    case PANEL_TYPES_CLEAR_STATE:
      return {
        ...state,
        ...INITIAL_API_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
