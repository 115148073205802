import {
  PLANT_TIME_SUCCESS,
  PLANT_TIME_ERROR,
  PLANT_TIME_PROGRESS,
  CLEAR_PLANT_TIME,
} from '../Types/plots.types';

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errorCode: null,
  message: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PLANT_TIME_PROGRESS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };

    case PLANT_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload,
      };

    case PLANT_TIME_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorCode:
          action?.payload?.err?.response &&
          action?.payload?.err?.response?.body?.code,
        message:
          action?.payload?.err?.response &&
          action?.payload?.err?.response?.body?.message,
      };

    case CLEAR_PLANT_TIME:
      return {
        ...state,
        isSuccess: false,
      };

    default:
      return {
        ...state,
      };
  }
};
