import {
  SURGELINE_FETCH_PROGRESS,
  SURGELINE_FETCH_SUCCESS,
  SURGELINE_FETCH_ERROR,
  SURGELINE_FETCH_CLEAR,
} from "../Types/SurgeLines.type";

const INITIAL_STATE = {
  surgeLineData: "",
  isSurgeLineFetchSuccess: false,
  isSurgeLineFetchError: false,
  errorMessageSurgeLineFetch: "",
  loading: false,
};

export default function surgeLineReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SURGELINE_FETCH_PROGRESS:
      return { ...state, loading: true };

    case SURGELINE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        isSurgeLineFetchSuccess: true,
        surgeLineData: action.payload,
      };

    case SURGELINE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMessageSurgeLineFetch:
          action.payload.response && action.payload.response,
        isSurgeLineFetchError: true,
      };

    case SURGELINE_FETCH_CLEAR:
      return {
        ...state,
        isSurgeLineFetchSuccess: false,
        isSurgeLineFetchError: false,
      };

    default:
      return state;
  }
}
