export const ADD_ADDNEWCARD_ERROR = "ADD_ADDNEWCARD_ERROR";
export const ADD_ADDNEWCARD_PROGRESS = "ADD_ADDNEWCARD_PROGRESS";
export const ADD_ADDNEWCARD_SUCCESS = "ADD_ADDNEWCARD_SUCCESS";
export const ADD_ADDNEWCARD_CLEAR_STATE = "ADD_ADDNEWCARD_CLEAR_STATE";

export const KPI_BY_LEVEL_FETCH_ERROR = "KPI_BY_LEVEL_FETCH_ERROR";
export const KPI_BY_LEVEL_FETCH_PROGESS = "KPI_BY_LEVEL_FETCH_PROGESS";
export const KPI_BY_LEVEL_FETCH_SUCCESS = "KPI_BY_LEVEL_FETCH_SUCCESS";
export const KPI_BY_LEVEL_CLEAR_STATE = "KPI_BY_LEVEL_CLEAR_STATE";

export const CARDTYPE_FETCH_SUCCESS = "CARDTYPE_FETCH_SUCCESS";
export const CARDTYPE_FETCH_PROGRESS = "CARDTYPE_FETCH_PROGRESS";
export const CARDTYPE_FETCH_ERROR = "CARDTYPE_FETCH_ERROR";
export const CARDTYPE_CLEAR_STATE = "CARDTYPE_CLEAR_STATE";

export const DEPLOYED_COMPONENT_FETCH_SUCCESS =
  "DEPLOYED_COMPONENT_FETCH_SUCCESS";
export const DEPLOYED_COMPONENT_FETCH_PROGRESS =
  "DEPLOYED_COMPONENT_FETCH_PROGRESS";
export const DEPLOYED_COMPONENT_FETCH_ERROR = "DEPLOYED_COMPONENT_FETCH_ERROR";
export const DEPLOYED_COMPONENT_CLEAR_STATE = "DEPLOYED_COMPONENT_CLEAR_STATE";

export const DEPLOYED_EQUIPMENT_FETCH_SUCCESS =
  "DEPLOYED_EQUIPMENT_FETCH_SUCCESS";
export const DEPLOYED_EQUIPMENT_FETCH_PROGRESS =
  "DEPLOYED_EQUIPMENT_FETCH_PROGRESS";
export const DEPLOYED_EQUIPMENT_FETCH_ERROR = "DEPLOYED_EQUIPMENT_FETCH_ERROR";
export const DEPLOYED_EQUIPMENT_CLEAR_STATE = "DEPLOYED_EQUIPMENT_CLEAR_STATE";

export const TAGS_BY_COPMONENT_FETCH_SUCCESS =
  "TAGS_BY_COPMONENT_FETCH_SUCCESS";
export const TAGS_BY_COPMONENT_FETCH_PROGRESS =
  "TAGS_BY_COPMONENT_FETCH_PROGRESS";
export const TAGS_BY_COPMONENT_FETCH_ERROR = "TAGS_BY_COPMONENT_FETCH_ERROR";
export const TAGS_BY_COPMONENT_CLEAR_STATE = "TAGS_BY_COPMONENT_CLEAR_STATE";

export const TAGS_BY_CARDTYPE_FETCH_SUCCESS = "TAGS_BY_CARDTYPE_FETCH_SUCCESS";
export const TAGS_BY_CARDTYPE_FETCH_PROGRESS =
  "TAGS_BY_CARDTYPE_FETCH_PROGRESS";
export const TAGS_BY_CARDTYPE_FETCH_ERROR = "TAGS_BY_CARDTYPE_FETCH_ERROR";
export const TAGS_BY_CARDTYPE_CLEAR_STATE = "TAGS_BY_CARDTYPE_CLEAR_STATE";

export const DELETE_KPI_BY_KPIID_ERROR = "DELETE_KPI_BY_KPIID_ERROR";
export const DELETE_KPI_BY_KPIID_PROGESS = "DELETE_KPI_BY_KPIID_PROGESS";
export const DELETE_KPI_BY_KPIID_SUCCESS = "DELETE_KPI_BY_KPIID_SUCCESS";
export const DELETE_KPI_BY_KPIID_CLEAR_STATE =
  "DELETE_KPI_BY_KPIID_CLEAR_STATE";

export const UPDATE_KPI_BY_KPIID_ERROR = "UPDATE_KPI_BY_KPIID_ERROR";
export const UPDATE_KPI_BY_KPIID_PROGESS = "UPDATE_KPI_BY_KPIID_PROGESS";
export const UPDATE_KPI_BY_KPIID_SUCCESS = "UPDATE_KPI_BY_KPIID_SUCCESS";
export const UPDATE_KPI_BY_KPIID_CLEAR_STATE =
  "UPDATE_KPI_BY_KPIID_CLEAR_STATE";

export const GET_RUNTAGS_PROGRESS = "GET_RUNTAGS_PROGRESS";
export const GET_RUNTAGS_SUCCESS = "GET_RUNTAGS_SUCCESS";
export const GET_RUNTAGS_ERROR = "GET_RUNTAGS_ERROR";

export const UPDATE_KPI_REDUCER = "UPDATE_KPI_REDUCER";

export const CLEAR_PLOTS_STATE = "CLEAR_PLOTS_STATE";
export const PANEL_TYPES_FETCH_PROGRESS = "PANEL_TYPES_FETCH_PROGRESS";
export const PANEL_TYPES_FETCH_SUCCESS = "PANEL_TYPES_FETCH_SUCCESS";
export const PANEL_TYPES_FETCH_ERROR = "PANEL_TYPES_FETCH_ERROR";
export const PANEL_TYPES_CLEAR_STATE = "PANEL_TYPES_CLEAR_STATE";

export const PANEL_TYPES_SAVE_PROGRESS = "PANEL_TYPES_SAVE_PROGRESS";
export const PANEL_TYPES_SAVE_SUCCESS = "PANEL_TYPES_SAVE_SUCCESS";
export const PANEL_TYPES_SAVE_ERROR = "PANEL_TYPES_SAVE_ERROR";

export const PANEL_TYPES_DELETE_PROGRESS = "PANEL_TYPES_DELETE_PROGRESS";
export const PANEL_TYPES_DELETE_SUCCESS = "PANEL_TYPES_DELETE_SUCCESS";
export const PANEL_TYPES_DELETE_ERROR = "PANEL_TYPES_DELETE_ERROR";

export const KPI_BY_PANEL_FETCH_ERROR = "KPI_BY_PANEL_FETCH_ERROR";
export const KPI_BY_PANEL_FETCH_PROGESS = "KPI_BY_PANEL_FETCH_PROGESS";
export const KPI_BY_PANEL_FETCH_SUCCESS = "KPI_BY_PANEL_FETCH_SUCCESS";
export const KPI_BY_PANEL_CLEAR_STATE = "KPI_BY_PANEL_CLEAR_STATE";

export const PANEL_TYPES_UPDATE_PROGRESS = "PANEL_TYPES_UPDATE_PROGRESS";
export const PANEL_TYPES_UPDATE_SUCCESS = "PANEL_TYPES_UPDATE_SUCCESS";
export const PANEL_TYPES_UPDATE_ERROR = "PANEL_TYPES_UPDATE_ERROR";

export const UPDATE_KPI_BULK_PROGESS = 'UPDATE_KPI_BULK_PROGESS';
export const UPDATE_KPI_BULK_SUCCESS = 'UPDATE_KPI_BULK_SUCCESS';
export const UPDATE_KPI_BULK_ERROR = 'UPDATE_KPI_BULK_ERROR';
