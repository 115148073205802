import {
 Empty, Icon, Spin, Switch,
} from 'antd';
import PropType from 'prop-types';
import React, { useState } from 'react';
import { deleteModal } from '../../../constants/messages';
import { elementsGaurd } from '../../../gaurds';
import { getTimeInFormat } from '../../../libs/valueFormatter';
import { ElementPermissions } from '../../../permissions';
import ConfirmModal from '../../Modal/Modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import './VariableCard.scss';
import RunsCard from './RunsCard';

function VariableCard(props) {
  const [visible, setVisiable] = useState(false);
  const queryClient = new QueryClient()
  const {
    time,
    active,
    changeVisibleStatus,
    id,
    edit,
    cardData,
    variableName,
    delete: del,
    children,
    title,
    facilityId,
    cardTypeInfo,
    abbreviation,
    level,
    equipmentName
  } = props;
  const cardTitle = title || variableName;

  const renderEquipment = (level, equipmentName) => {
    if(level?.name && equipmentName?.name){
      if(level.name === "equipment"){
        return <></>
      }
      return equipmentName?.name
    }
  }
  const renderBody = () => {
    if (cardData && cardData.isLoading) {
      return <Spin />;
    }
    else if( cardTypeInfo === "Run Statistic"){
      //renderRunStats()
      return cardData
      && cardData.isSuccess
      && cardData.data
      && cardData.data.cardValue ? (
        <QueryClientProvider client={queryClient}>
          <RunsCard equipmentId ={cardData.data.cardValue[0].equipmentId} 
          data = {children} cardData = {cardData.data.cardValue} abbreviation = {abbreviation}></RunsCard>
          <div className="equipmentName">{renderEquipment(level, equipmentName)}</div>
        </QueryClientProvider>
    ) : (
      <Empty />
    );
    }
    return cardData
      && cardData.isSuccess
      && cardData.data
      && cardData.data.cardValue
      && cardData.data.cardValue.length ? (
      children
    ) : (
      <div className="card-body-section">
        <div className="card-info">
       ---
        </div>
      </div>
    );
  };

  return (
    <div className="variable-status-card">
      <div className="head">
        <div>
          <Icon type="clock-circle" theme="filled" />
          {time && ` ${getTimeInFormat(time)}`}
        </div>
        {elementsGaurd(
          <div className="more-items">
            <Switch
              checked={active}
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              onClick={changeVisibleStatus}
            />
            <button
              type="button"
              className="card-edit"
              onClick={() => {
                edit(id);
              }}
            >
              <Icon type="edit" />
            </button>
            <button
              type="button"
              className="card-delete"
              onClick={() => {
                setVisiable(true);
              }}
            >
              <Icon type="delete" />
            </button>
          </div>,
          ElementPermissions.CARD_ACTION,
          {
            type: "facility",
            dataRef: {
              _id: facilityId,
            },
          }
        )}
      </div>
      <div className="body-variable-card">
        {renderBody()}
        <div className="card-detail">
          <div>{cardTitle}</div>
        </div>
      </div>

      {visible && (
        <ConfirmModal
          visible={visible}
          handleOk={() => {
            del(id);
            setVisiable(false);
          }}
          handleCancel={() => {
            setVisiable(false);
          }}
          message={deleteModal.message}
          title={deleteModal.title}
        />
      )}
    </div>
  );
}

VariableCard.propTypes = {
  id: PropType.string.isRequired,
  time: PropType.string.isRequired,
  active: PropType.bool.isRequired,
  changeVisibleStatus: PropType.func.isRequired,
  edit: PropType.func.isRequired,
  cardData: PropType.shape.isRequired,
  variableName: PropType.string.isRequired,
  delete: PropType.func.isRequired,
  children: PropType.node.isRequired,
  title: PropType.string.isRequired,
};

export default VariableCard;
