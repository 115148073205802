/* eslint-disable */
import _ from 'lodash';

export const NodeTypes = {
  FACILITY: 'facility',
  CLIENT: 'client',
  SYSTEM: 'system',
  EQUIPMENT: 'equipment',
};

export const getClientTree = (clientInfoData) => ({
  facilities: clientInfoData.facilities,
  systems: clientInfoData.systems,
  equipments: clientInfoData.equipments,
});

export const getClientListObject = (clinets = []) => {
  const clientListObject = {};
  clinets &&
    clinets.forEach((item) => {
      clientListObject[item._id] = {
        info: item,
        type: 'client',
        clickable: false,
      };
    });
  return clientListObject;
};

export const getClientChildren = (clientInfoData) =>
  createClientTree(getClientTree(clientInfoData));

export const getSelectedNodeObject = (selectedNode, clientInfoData) => {
  if (selectedNode && selectedNode.props) {
    selectedNode.props.dataRef.info = clientInfoData && clientInfoData.info;
    selectedNode.props.dataRef.children = createClientTree(
      getClientTree(clientInfoData)
    );
    selectedNode.props.dataRef.type = NodeTypes.CLIENT;
  }
  return selectedNode;
};

export const getEquipmentList = (key, childrens, type) => {
  const equipmentList = [];
  if (type === 'facility') {
    childrens &&
      Object.keys(childrens).forEach((item) => {
        childrens[item].children &&
          Object.keys(childrens[item].children).forEach((item1) => {
            equipmentList.push(childrens[item].children[item1].info);
          });
      });
  } else if (type === 'system') {
    childrens &&
      Object.keys(childrens).forEach((item) => {
        equipmentList.push(childrens[item].info);
      });
  }
  return equipmentList;
};

export const getInfoObject = (clientInfoData) => {
  if (clientInfoData.facilities[0]) {
    return {
      title: clientInfoData.facilities[0].name,
      dataRef: clientInfoData.facilities[0],
      type: 'facility',
      equipmentList: getEquipmentList(
        clientInfoData.facilities[0]._id,
        createClientTree(getClientTree(clientInfoData))[
          clientInfoData.facilities[0]._id
        ].children,
        'facility'
      ),
    };
  }
  if (clientInfoData.systems[0]) {
    return {
      title: clientInfoData.systems[0].systemName,
      dataRef: clientInfoData.systems[0],
      type: 'system',
      equipmentList: getEquipmentList(
        clientInfoData.systems[0]._id,
        createClientTree(getClientTree(clientInfoData))[
          clientInfoData.systems[0]._id
        ].children,
        'system'
      ),
    };
  }
  if (clientInfoData.equipments[0]) {
    return {
      title: clientInfoData.equipments[0].name,
      dataRef: clientInfoData.equipments[0],
      type: 'equipment',
    };
  }
  return null;
};

export const getSelectedNodeInfoObject = (data) => ({
  title: data.info.name || data.info.systemName,
  dataRef: data.info,
  type: data.type,
  path: data.path,
  equipmentList:
    data.type === 'equipment'
      ? data.info
      : getEquipmentList(data.info._id, data.children, data.type),
});

export const createClientTree = (data) => {
  const keys = Object.keys(data);
  const priority = {
    facilities: 0,
    systems: 1,
    equipments: 2,
  };
  const type = {
    0: NodeTypes.FACILITY,
    1: NodeTypes.SYSTEM,
    2: NodeTypes.EQUIPMENT,
  };
  const client = [];
  for (let i = 0; i < keys.length; i++) {
    client[priority[keys[i]]] = groupById(data[keys[i]], type[i]);
  }

  return mapTree(client);
};

export const groupById = (data, type) => {
  let datas = [];
  datas = _.sortBy(data, (element) => {
    return type === 'system'
      ? _.capitalize(element.systemName)
      : _.capitalize(element.name);
  });
  let obj = {};
  for (let i = 0; i < datas.length; i++) {
    let iobj = {};
    iobj['info'] = { ...datas[i], name: datas[i].name || datas[i].systemName };
    iobj['type'] = type;
    obj[datas[i]._id] = iobj;
  }
  return obj;
};

export const mapTree = (data) => {
  const accessId = {
    0: 'clientId',
    1: 'facilityId',
    2: 'systemId',
  };
  let finalData = 0;
  for (let i = data.length - 2; i >= 0; i--) {
    if (Object.keys(data[i + 1]) && Object.keys(data[i + 1]).length) {
      Object.keys(data[i + 1]).forEach((item) => {
        // eslint-disable-line
        if (data[i][data[i + 1][item].info[accessId[i + 1]]]) {
          if (!data[i][data[i + 1][item].info[accessId[i + 1]]].children) {
            data[i][data[i + 1][item].info[accessId[i + 1]]].children = {};
          }
          data[i][data[i + 1][item].info[accessId[i + 1]]].children[item] =
            data[i + 1][item];
          finalData = i;
        }
      });
    }
  }
  for (let i = 0; i < data.length; i++) {
    if (Object.keys(data[i]).length) {
      finalData = i;
      break;
    }
  }

  return data[finalData];
};

export const getClientChildren2 = (clientInfoData) =>
  createClientTree2(getClientTree2(clientInfoData));

export const getClientTree2 = (clientInfoData) => ({
  facilities: clientInfoData.facilities,
  systems: clientInfoData.systems,
  equipments: clientInfoData.equipments,
});

export const createClientTree2 = (data) => {
  const keys = Object.keys(data);
  const priority = {
    facilities: 0,
    systems: 1,
    equipments: 2,
  };
  const type = {
    0: NodeTypes.FACILITY,
    1: NodeTypes.SYSTEM,
    2: NodeTypes.EQUIPMENT,
  };
  const client = [];
  for (let i = 0; i < keys.length; i++) {
    client[priority[keys[i]]] = groupById2(data[keys[i]], type[i]);
  }

  return mapTree2(client);
};

export const groupById2 = (data, type) => {
  let datas = [];
  datas = _.sortBy(data, (element) => {
    return type === 'system'
      ? _.capitalize(element.systemName)
      : _.capitalize(element.name);
  });
  let obj = {};
  for (let i = 0; i < datas.length; i++) {
    let iobj = {};
    iobj['info'] = { ...datas[i], name: datas[i].name || datas[i].systemName };
    iobj['type'] = type;
    obj[datas[i]._id] = iobj;
  }
  return obj;
};

export const mapTree2 = (data) => {
  const accessId = {
    0: 'clientId',
    1: 'facilityId',
    2: 'systemId',
  };
  let finalData = 0; // eslint-disable-line
  for (let i = data.length - 2; i >= 0; i--) {
    if (Object.keys(data[i + 1]) && Object.keys(data[i + 1]).length) {
      Object.keys(data[i + 1]).forEach((item) => {
        // eslint-disable-line
        if (data[i][data[i + 1][item].info[accessId[i + 1]]]) {
          if (!data[i][data[i + 1][item].info[accessId[i + 1]]].children) {
            data[i][data[i + 1][item].info[accessId[i + 1]]].children = {};
          }
          data[i][data[i + 1][item].info[accessId[i + 1]]].children[item] =
            data[i + 1][item];
          finalData = i;
        }
      });
    }
  }
  for (let i = 0; i < data.length; i++) {
    if (Object.keys(data[i]).length) {
      finalData = i; // eslint-disable-line
      break;
    }
  }

  return data;
};
