export const mobile = () => {
  if (window.innerWidth <= 700) {
    return true;
  }
  return false;
};

export const tab = () => {
  if (window.innerWidth <= 900 && window.innerWidth >= 700) {
    return true;
  }
  return false;
};
