import {
  RUNNERDATA_ADD_EDIT_ERROR,
  RUNNERDATA_ADD_EDIT_PROGRESS,
  RUNNERDATA_ADD_EDIT_SUCCESS,
  RUNNERDATA_CLEAR_STATE,
} from "../Types/runnerData.types";

const INITIAL_API_STATE = {
  data: null,
  isErrorFetchRunner: false,
  isSuccessFetchRunner: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function RunnerDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUNNERDATA_ADD_EDIT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case RUNNERDATA_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorFetchRunner: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case RUNNERDATA_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: action.payload,
          isSuccessFetchRunner: true,
        },
      };

    case RUNNERDATA_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          data: state.apiState.data,
        },
      };
    default:
      return state;
  }
}
