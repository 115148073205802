import { Modal } from 'antd'
import React from 'react'
import Notification, { Types } from '../../../../../../../common/Notification/Notification'
import { graphDetails } from '../../../CurvesData/Curves.constants'

const DuplicateAstestedCurve = (visible, setVisiblity, asTestedCurve, setOperationalBasedCurve) => {
    const enableEditForm = () => {
        window.dispatchEvent(new Event('form-edit'));
    }

    const handleOk = () => {
        setVisiblity(false)
        if(asTestedCurve.length < 3){
            return Notification.show(Types.Info, graphDetails.requiredMessage)
        }
        else{
            enableEditForm()
            setOperationalBasedCurve(asTestedCurve)  
        }
    }
    return(
        <Modal
            visible={visible}
            onOk={handleOk}
            onCancel={() =>setVisiblity(false)}
            title={"ALERT"}
        >
            {graphDetails.copyDataToOperational}
        </Modal>
    )
}

export default DuplicateAstestedCurve