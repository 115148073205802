import _ from 'lodash';
import moment from 'moment';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { cardTypeConfigMapper } from '../../../AddNewCard/AddNewCard.constants';
import {
  getAlertPlotByLevelId,
  getPlotDataByKPIId,
  addAlertKpiClearState,
  clearDataFetchState,
  fetchAlertKPIClearState,
  updateAlertKPIClearState,
  onUpdateAlertKpi,
  setTimeRangeVisibility,
  bulkUpdateAlertKpi,
  bulkUpdateAlertKPIClearState,
  deleteAlertKpiById,
  deleteAlertKPIclearState,
  setZoom,
  setMetaData,
} from '../../../../../redux/actions/alertplots.action';
import {
  getBatchTime,
  clearBatchState,
} from '../../../../../redux/actions/plantTime.action';
import AddNewCard from '../../../AddNewCard/AddNewCard';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';

const AlertKPIPlots = (props) => {
  const {
    info,
    kpiData,
    activeKey,
    getBatchTime,
    clearBatchState,
    kpiPlotData,
    userRole,
    batchTimeData,
    batchTimeIsSuccess,
    getPlotDataByKPIId,
    isDataProcessSuccess,
    isCardAddSucess,
    newCardData,
    addAlertKpiClearState,
    clearDataFetchState,
    isUpdateKPISuccess,
    updateAlertKPIClearState,
    onUpdateAlertKpi,
    setTimeRangeVisibility,
    dateTimeRangeVisible,
    bulkUpdateSuccess,
    bulkUpdateAlertKpi,
    bulkUpdateAlertKPIClearState,
    deleteAlertKpiById,
    deleteAlertKPIclearState,
    deleteKPISuccess,
    setZoom,
    setMetaData,
    needUpdate,
    alertPrint,
  } = props;

  const [batchTime, setBatchTime] = useState(null);
  const [facilityId, setFacilityId] = useState('');
  const [editableCard, setEditableCard] = useState(null);
  const [individualPlot, setIndividualPlot] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [emptyIconMessage, setEmptyIconMessage] = useState('');

  const getDashboardInfo = useCallback(() => {
    if (info && info.dataRef && activeKey === '2') {
      const presentFacilityId =
        info && info.type === 'facility'
          ? info['dataRef']['_id']
          : info && info.type !== 'facility'
          ? info['dataRef']['facilityId']
          : '';
      const emptyIconMessage =
        userRole === 'MechademyAdmin' || userRole === 'MechademyFacilityManager'
          ? 'ADD CARDS HERE'
          : 'NO DATA';
      setEmptyIconMessage(emptyIconMessage);
      setFacilityId(presentFacilityId);
      getBatchTime();
    }
  }, [info, getBatchTime, activeKey, userRole]);

  const fetchData = useCallback(
    (kpi, latestBatchTime) => {
      let dateFrom = null,
        dateTill = null;
      const kpiId = kpi._id;
      const updatedKPI = kpiData.find(i => i._id === kpiId);
      if (
        updatedKPI.inspection.mode &&
        updatedKPI.inspection.viewDateFrom &&
        updatedKPI.inspection.viewDateTill
      ) {
        dateFrom = updatedKPI.inspection.viewDateFrom;
        dateTill = updatedKPI.inspection.viewDateTill;
      } else {
        const currentDateTime = moment(latestBatchTime * 1000);
        const currentDateTime2 = _.cloneDeep(currentDateTime);
        dateFrom = currentDateTime.subtract(2, 'hours').valueOf();
        dateTill = currentDateTime2.valueOf();
      }
      getPlotDataByKPIId(kpiId, dateFrom, dateTill);
    },
    [getPlotDataByKPIId, kpiData]
  );

  // acts as componentDidMount
  useEffect(() => {
    getDashboardInfo();
  }, [getDashboardInfo]);

  useEffect(() => {
    if (batchTimeData && batchTimeData.to) {
      const batchTime = batchTimeData && batchTimeData.to;
      setBatchTime(batchTime);
      clearBatchState();
    }
  }, [batchTimeIsSuccess, batchTimeData, clearBatchState]);

  useEffect(() => {
    if (isDataProcessSuccess) {
      clearDataFetchState();
    }
  }, [isDataProcessSuccess, clearDataFetchState]);

  useEffect(() => {
    if (isCardAddSucess) {
      getBatchTime();
      fetchData(newCardData, batchTime);
      addAlertKpiClearState();
    }
  }, [
    isCardAddSucess,
    newCardData,
    getBatchTime,
    batchTime,
    addAlertKpiClearState,
    fetchData,
  ]);

  useEffect(() => {
    if (isUpdateKPISuccess) {
      getBatchTime();
      setEditModalVisible(false);
      Notification.show(Types.Success, 'Updated Successfully');
      if (needUpdate) fetchData(editableCard, batchTime);
      updateAlertKPIClearState();
    }
  }, [
    isUpdateKPISuccess,
    getBatchTime,
    fetchData,
    batchTime,
    editableCard,
    updateAlertKPIClearState,
    needUpdate
  ]);

  useEffect(() => {
    if (dateTimeRangeVisible) {
      setIsDatePickerVisible(true);
      setTimeRangeVisibility(false);
      setIndividualPlot(null);
    }
  }, [dateTimeRangeVisible, setTimeRangeVisibility]);

  useEffect(() => {
    if (bulkUpdateSuccess) {
      bulkUpdateAlertKPIClearState();
      kpiData.forEach((item) => {
        let dateFrom = null,
          dateTill = null;
        if (individualPlot) {
          if (item._id === individualPlot) {
            dateFrom = item.inspection.viewDateFrom;
            dateTill = item.inspection.viewDateTill;
          }
        } else {
          dateFrom = item.inspection.viewDateFrom;
          dateTill = item.inspection.viewDateTill;
        }
        if (dateFrom && dateTill) {
          getPlotDataByKPIId(item._id, dateFrom, dateTill, true);
        }
      });
    }
  }, [
    bulkUpdateSuccess,
    getPlotDataByKPIId,
    bulkUpdateAlertKPIClearState,
    kpiData,
    individualPlot
  ]);

  useEffect(() => {
    if (deleteKPISuccess) {
      Notification.show(Types.Success, `Deleted Successfully`);
      deleteAlertKPIclearState();
    }
  }, [deleteKPISuccess, deleteAlertKPIclearState]);

  const onEdit = (kpiId) => {
    const cardToEdit = kpiData.find((item) => item._id === kpiId);
    setEditableCard(cardToEdit);
    setEditModalVisible(true);
  };

  const renderEditCardModal = () => {
    if (editModalVisible) {
      return (
        <AddNewCard
          visible={editModalVisible}
          handleCancel={() => setEditModalVisible(false)}
          info={info}
          activeKey={'2'}
          mode={'edit'}
          cardData={editableCard}
        />
      );
    }
  };

  const zoom = (id, viewDateFrom, viewDateTill, cardType, type) => {
    viewDateFrom = kpiPlotData[id].viewDateFrom;
    viewDateTill = kpiPlotData[id].viewDateTill;
    // const dateFrom = moment(viewDateFrom).valueOf() + 1000;
    // const dateTill = moment(viewDateTill).valueOf() + 1000;
    const numberOfHours =
      (moment(viewDateTill).valueOf() +
        1000 -
        moment(viewDateFrom).valueOf() +
        1000) /
      3600 /
      1000;
    if (type === 'in') {
      viewDateFrom =
        moment(viewDateFrom)
          .add(numberOfHours / 2, 'hours')
          .valueOf() + 1000;
    } else if (type === 'out') {
      viewDateFrom =
        moment(viewDateFrom).subtract(numberOfHours, 'hours').valueOf() + 1000;
    }
    getPlotDataByKPIId(id, viewDateFrom, viewDateTill);
  };

  const onSelectScale = (data, manualScale, kpiId, cardDetails) => {
    const yAxisPayload = data.map((item) => {
      let min = '',
        max = '';
      if (manualScale) {
        min = item.minScale;
        max = item.maxScale;
      }
      return {
        minScale: min,
        maxScale: max,
        variableName: item.variableName,
        type: item.type,
        value: item.value,
      };
    });
    const payload = {
      axis: {
        y: yAxisPayload,
      },
      manualScale: manualScale,
    };
    onUpdateAlertKpi(kpiId, payload, false);
    setEditableCard(cardDetails);
  };

  const onTimeChange = (kpi) => {
    const payload = {
      inspection: {
        mode: true,
        viewDateFrom: kpi.dateFrom.toString(),
        viewDateTill: kpi.dateTill.toString()
      },
    };
    const kpiId = kpi?.id;
    const editCard = kpiData.find((i) => {
      return i._id === kpiId;
    });
    setEditableCard(editCard);
    onUpdateAlertKpi(kpiId, payload);
  };

  const timeSelect = (startTime, endTime, id) => {
    if (startTime && endTime && id) {
      const dateFrom = startTime._d.valueOf();
      const dateTill = endTime._d.valueOf();
      const kpiIds = kpiData.map((i) => {
        if (individualPlot) {
          if (individualPlot === i._id) return i._id;
          return undefined;
        } 
        return i._id;
      });
      const payload = {
        kpiId: kpiIds.filter(i => i !== undefined),
        inspection: {
          mode: true,
          viewDateFrom: dateFrom.toString(),
          viewDateTill: dateTill.toString(),
        },
      };
      bulkUpdateAlertKpi(payload);
    }
  };

  const toggleMetaData = (id, value) => {
    setMetaData(id, value);
  };

  const transformSeries = (series, id) => {
    series.forEach((piTag) => {
      if (kpiPlotData[id].metaData) {
        piTag.data = piTag.metaMaxMinPercentileData;
      } else {
        piTag.data = piTag.metaMaxMinData;
      }
    });
    return series;
  };

  const onDelete = (kpiId) => {
    deleteAlertKpiById(kpiId);
  };
  
  const fetchZoomedData = (id, dateFrom, dateTill, initDateFrom, initDateTill) => {
    const isZoomed = kpiPlotData[id].zoomIn;
    if (!isZoomed) {
      setZoom(id, true, initDateFrom, initDateTill);
    }
    getPlotDataByKPIId(id, dateFrom, dateTill);
  };

 const resetZoom = (id) => {
   setZoom(id, false);
   const dateFrom = kpiPlotData[id].initDateFrom;
   const dateTill = kpiPlotData[id].initDateTill;
   getPlotDataByKPIId(id, dateFrom, dateTill);
 };

 const setId = (id) =>  {
   setIndividualPlot(id);
 }

  return activeKey === '2' && (
    <div className="Trends">
      {
        <React.Fragment>
          {kpiData?.length ? (
            kpiData.map((item) => {
              const { cardType } = item;
              const graph = cardTypeConfigMapper[cardType.cardType];
              const KPIId = item && item._id;
              const KPITitle =
                item && item.cardTitle ? item.cardTitle : 'UNTITLED';
              const numberOfDays =
                (kpiPlotData[KPIId]?.viewDateTill -
                  kpiPlotData[KPIId]?.viewDateFrom) /
                (24 * 3600) /
                1000;
              let series = null;
              if (
                kpiPlotData &&
                kpiPlotData[KPIId] &&
                kpiPlotData[KPIId].data
              ) {
                series = _.cloneDeep(kpiPlotData[KPIId]?.data);
              }
              if (numberOfDays >= 3 && numberOfDays < 45) {
                series = transformSeries(series, KPIId);
              }
              const marginValue = alertPrint ? "5%" : "0%";
              const graphPlot = (
                <div style={{ margin: marginValue }}>
                  <graph.viewGraph
                    id={KPIId}
                    series={_.cloneDeep(series)}
                    viewDateFrom={_.cloneDeep(kpiPlotData[KPIId].viewDateFrom)}
                    viewDateTill={_.cloneDeep(kpiPlotData[KPIId].viewDateTill)}
                    cardDetails={item}
                    trends2={false}
                    numberOfDays={kpiPlotData[KPIId].numberOfDays}
                    metaData={
                      kpiPlotData[KPIId].numberOfDays >= 3 ? true : false
                    }
                    fetchData={fetchZoomedData}
                    alertPlots={true}
                    alertPrint={alertPrint}
                    compressedData={
                      kpiPlotData[KPIId].numberOfDays > 45 ? true : false
                    }
                  />
                </div>
              );
              const graphWithCard = (
                <graph.viewCard
                  id={KPIId}
                  key={KPIId}
                  title={KPITitle}
                  time={batchTime * 1000}
                  delete={onDelete}
                  edit={onEdit}
                  item={item}
                  cardType={item.cardType.cardType}
                  isLoader={true}
                  onTimeChange={onTimeChange}
                  isChangetime={true}
                  updatedAt={kpiPlotData[KPIId] ? kpiPlotData[KPIId].data : ''}
                  height={'fit-content'}
                  cardData={kpiPlotData[KPIId]}
                  activeKey={activeKey}
                  selectScale={onSelectScale}
                  cardDetails={item}
                  userInfo={userRole}
                  duration={item['timeDuration'] || 0}
                  zoom={zoom}
                  facilityId={facilityId}
                  timeSelect={timeSelect}
                  batchTime={batchTime}
                  alertPlots={true}
                  isDatePickerVisible={isDatePickerVisible}
                  setIsDatePickerVisible={setIsDatePickerVisible}
                  getBatchTime={getBatchTime}
                  viewDateFrom={_.cloneDeep(kpiPlotData[KPIId]?.viewDateFrom)}
                  viewDateTill={_.cloneDeep(kpiPlotData[KPIId]?.viewDateTill)}
                  numberOfDays={kpiPlotData[KPIId].numberOfDays}
                  resetZoom={resetZoom}
                  toggleMetaData={toggleMetaData}
                  metaData={kpiPlotData[KPIId]?.metaData}
                  setId={setId}
                  zoomInId={kpiPlotData[KPIId]?.zoomIn}
                  numberOfCards={kpiData.length}
                >
                  {graph.viewGraph ? (
                    graphPlot
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 50,
                      }}
                      description={
                        <span>
                          <b>{emptyIconMessage}</b>
                        </span>
                      }
                    ></Empty>
                  )}
                </graph.viewCard>
              );
              return (!alertPrint ? graphWithCard : graphPlot);
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{
                height: 50,
              }}
              description={
                <span>
                  <b>{emptyIconMessage}</b>
                </span>
              }
            ></Empty>
          )}
        </React.Fragment>
      }
      {renderEditCardModal()}
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    userRole: state.LoginReducer.apiState.userRole,
    kpiFetchSuccess: state.AlertPlotKPIReducer.isSuccess,
    kpiData: state.AlertPlotKPIReducer.data,
    batchTimeIsSuccess: state.batchTimeReducer.isSuccess,
    batchTimeData: state.batchTimeReducer.data,
    kpiPlotData: state.AlertPlotKPIReducer.kpisDataState,
    isDataProcessSuccess: state.AlertPlotKPIReducer.dataProcessed,
    newCardData: state.AlertPlotKPIReducer.newCardData,
    isCardAddSucess: state.AlertPlotKPIReducer.isKPIAddSuccess,
    isUpdateKPISuccess: state.AlertPlotKPIReducer.isKPIUpdateSuccess,
    needUpdate: state.AlertPlotKPIReducer.needUpdate,
    dateTimeRangeVisible: state.AlertPlotKPIReducer.isDateTimeVisible,
    bulkUpdateSuccess: state.AlertPlotKPIReducer.bulkUpdateSuccess,
    deleteKPISuccess: state.AlertPlotKPIReducer.deleteKPISuccess,
  };
};

const mapDispatchToProps = {
  getAlertPlotByLevelId,
  getBatchTime,
  clearBatchState,
  getPlotDataByKPIId,
  addAlertKpiClearState,
  clearDataFetchState,
  fetchAlertKPIClearState,
  updateAlertKPIClearState,
  onUpdateAlertKpi,
  setTimeRangeVisibility,
  bulkUpdateAlertKpi,
  bulkUpdateAlertKPIClearState,
  deleteAlertKpiById,
  deleteAlertKPIclearState,
  setZoom,
  setMetaData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertKPIPlots);
