import * as LoginTypes from "../Types/Login.types";
import { POSTRequest, GETRequest, PUTRequest } from "../../api/request";

export function login(credentials) {
  return function (dispatch) {
    // TODO delete indexed db
    dispatch({ type: LoginTypes.LOGIN_FETCH_PROGRESS });
    POSTRequest("public/login", credentials)
      .then((res) => {
        dispatch({ type: LoginTypes.LOGIN_FETCH_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: LoginTypes.LOGIN_FETCH_ERROR, payload: err });
      });
  };
}

export function clearLoginState() {
  return function (dispatch) {
    dispatch({ type: LoginTypes.LOGIN_CLEAR_STATE });
  };
}

export function userRole(credentials) {
  return function (dispatch) {
    dispatch({ type: LoginTypes.USER_ROLE_FETCH_PROGRESS });
    GETRequest("user/roles", credentials)
      .then((res) => {
        dispatch({ type: LoginTypes.USER_ROLE_FETCH_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: LoginTypes.USER_ROLE_FETCH_ERROR, payload: err });
      });
  };
}

export function clearUserRoleState() {
  return function (dispatch) {
    dispatch({ type: LoginTypes.USER_ROLE_CLEAR_STATE });
  };
}

export function addTourPage(id, page) {
  return function (dispatch) {
    dispatch({ type: LoginTypes.TOUR_TAKEN_PROGRESS });
    PUTRequest(`userid/${id}/page/${page}`)
      .then(() => {
        dispatch({ type: LoginTypes.TOUR_TAKEN_SUCCESS, payload: page });
      })
      .catch((err) => {
        dispatch({ type: LoginTypes.TOUR_TAKEN_ERROR, payload: err });
      });
  };
}
