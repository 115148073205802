import {
  CONFIG_FETCH_SUCCESS,
  CONFIG_FETCH_ERROR,
  CONFIG_FETCH_PROGRESS,
  CONFIG_CLEAR_STATE,
} from '../Types/config.types';

import { GETRequest } from '../../api/request';

const configProgress = () => ({ type: CONFIG_FETCH_PROGRESS });
const configSuccess = (payload) => ({
  type: CONFIG_FETCH_SUCCESS,
  payload,
});
const configError = (payload) => ({
  type: CONFIG_FETCH_ERROR,
  payload,
});
const configClear = () => ({ type: CONFIG_CLEAR_STATE });

export function getConfig(id, type) {
  return function (dispatch) {
    dispatch(configProgress());
    const componentAPIMapper = {
      'Centrifugal liquid expander': `components/${id}/liquidexpanderconfigs/runner`,
      'Centrifugal compressor': `components/${id}/compressorconfigs/impeller`,
      'Centrifugal pump': `components/${id}/pumpconfigs/impeller`,
      'Blank component':  `components/${id}/blankcomponentconfigs/design`,
      'Flow meter': `components/${id}/flowmeterconfigs`,
      'Steam Turbine': `components/${id}/stconfigs/data`,
      'Gas Turbine': `components/${id}/gtconfigs/data`,
      'Control Valve': `components/${id}/valveConfigs`,
      'Reciprocating Compressor': `components/${id}/rcconfigs/data`,
      'Pipeline Inventory Management': `components/${id}/pimconfigs/data`,
      'Pipeline Inventory Monitoring': `components/${id}/pimconfigs/data`,
      'Hydraulic Variable Speed Gear': `components/${id}/hvsgconfig/data`,
      'Screw Compressor': `components/${id}/scconfigs/data`,
    };
    GETRequest(componentAPIMapper[type])
      .then((res) => {
        dispatch(configSuccess(res.body));
      })
      .catch((err) => {
        dispatch(configError(err));
      });
  };
}

export function clearConfigState() {
  return function (dispatch) {
    dispatch(configClear());
  };
}
