import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";
import {
  ADD_EQUIPMENTS_ERROR,
  ADD_EQUIPMENTS_PROGRESS,
  ADD_EQUIPMENTS_SUCCESS,
  GET_EQUIPMENTS_ERROR,
  GET_EQUIPMENTS_PROGRESS,
  GET_EQUIPMENTS_SUCCESS,
  UPDATE_EQUIPMENTS_ERROR,
  UPDATE_EQUIPMENTS_PROGRESS,
  UPDATE_EQUIPMENTS_SUCCESS,
} from "../Types/assetEquipment.types";

const getEndPoint = (payload) => {
  if (payload.keyword !== null) {
    return `${endPoints.assetsEquipments}?skip=${payload.skip}&limit=${payload.limit}&keyword=${payload.keyword}`;
  }
  return `${endPoints.assetsEquipments}?skip=${payload.skip}&limit=${payload.limit}`;
};
export const getEquipmentAsset = (payload) => (dispatch) => {
  dispatch({ type: GET_EQUIPMENTS_PROGRESS });
  GETRequest(getEndPoint(payload))
    .then((res) =>
      dispatch({ type: GET_EQUIPMENTS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: GET_EQUIPMENTS_ERROR, payload: err }));
};

export const getEquipment = () => (dispatch) => {
  dispatch({ type: GET_EQUIPMENTS_PROGRESS });
  GETRequest(`${endPoints.assetsEquipments}`)
    .then((res) =>
      dispatch({ type: GET_EQUIPMENTS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: GET_EQUIPMENTS_ERROR, payload: err }));
};

export const addAssetEquipment = (payload) => (dispatch) => {
  dispatch({ type: ADD_EQUIPMENTS_PROGRESS });
  POSTRequest(endPoints.assetsEquipments, payload)
    .then((res) =>
      dispatch({ type: ADD_EQUIPMENTS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: ADD_EQUIPMENTS_ERROR, payload: err }));
};

export const updateAssetEquipment = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_EQUIPMENTS_PROGRESS });
  PUTRequest(`${endPoints.assetsEquipments}/${id}`, payload)
    .then((res) =>
      dispatch({ type: UPDATE_EQUIPMENTS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: UPDATE_EQUIPMENTS_ERROR, payload: err }));
};
