import { Icon, Input } from "antd";
import React from "react";
import { MotorParametersDataVariables } from "../../../../../../constants/variables";
import { validateNumber } from "../../../../../../libs/validator";

export const MotorParametersDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (value === MotorParametersDataVariables.fullLoadSpeed) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "Units",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      if (item.name !== MotorParametersDataVariables.i22tLimit) {
        const tempError = !!(
          (item.name === MotorParametersDataVariables.fullLoadSpeed &&
            !item.unit &&
            config.saveListener) ||
          (!item.unit &&
            (item.design || item.design === 0) &&
            config.saveListener)
        );
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            } `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "Design",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <div>
        <Input
          type="number"
          value={item.design}
          disabled={disabled}
          AutoComplete="off"
          onKeyDown={(e) => {
            validateNumber(e, value);
          }}
          onChange={(e) =>
            config.handleChange(e.target.value, item.name, "number")
          }
          className={
            (item.name === MotorParametersDataVariables.fullLoadSpeed &&
              !item.design &&
              item.design !== 0 &&
              config.saveListener) ||
            (!item.design &&
              item.unit &&
              item.design !== 0 &&
              config.saveListener)
              ? "error"
              : "not-error"
          }
        />
      </div>
    ),
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  {
    name: MotorParametersDataVariables.motorRatedVoltage,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.ratedVoltageSaturatedSubtransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.ratedVoltageSaturatedNegativeSequenceReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.ratedVoltageSaturatedArmatureTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.armatureLeakageReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.directAxisArmatureReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.saturatedDirectAxisSynchronousReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.unsaturatedDirectAxisSynchronousReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.potierReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.saturatedDirectAxisTransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.unsaturatedDirectAxisTransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.saturatedDirectAxisSubtransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.unsaturatedDirectAxisSubtransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.quadratureAxisArmatureReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.saturatedQuadratureAxisSynchronousReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.unsaturatedQuadratureAxisSynchronousReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.fieldLeakageReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.saturatedQuadratureAxisTransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.unsaturatedQuadratureAxisTransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.saturatedQuadratureAxisSubtransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.unsaturatedQuadratureAxisSubtransientReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.quadratureAxisAmortisseurReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.directAxisAmortisseurReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.saturatedNegativeSequenceReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.unsaturatedNegativeSequenceReactance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.statorArmatureResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.withoutFdrsFieldResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.withFdrsFieldResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.quadratureAxisAmortisseurResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.directAxisAmortisseurResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.positiveSequenceResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.negativeSequenceResistance,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.armatureShortCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.directAxisTransientOpenCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.quadratureAxisTransientOpenCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.directAxisSubtransientOpenCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.quadratureAxisSubtransientOpenCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.directAxisTransientShortCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name:
      MotorParametersDataVariables.directAxisSubtransientShortCircuitTimeConstant,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: MotorParametersDataVariables.windingCapacitancetoGround,
    unit: null,
    design: parseFloat(""),
  },
  { name: MotorParametersDataVariables.i22tLimit, design: parseFloat("") },
];

export const ChangeKey = {
  [MotorParametersDataVariables.motorRatedVoltage]: "motorRatedVoltage",
  [MotorParametersDataVariables.ratedVoltageSaturatedSubtransientReactance]:
    "ratedVoltageSaturatedSubtransientReactance",
  [MotorParametersDataVariables.ratedVoltageSaturatedNegativeSequenceReactance]:
    "ratedVoltageSaturatedNegativeSequenceReactance",
  [MotorParametersDataVariables.ratedVoltageSaturatedArmatureTimeConstant]:
    "ratedVoltageSaturatedArmatureTimeConstant",
  [MotorParametersDataVariables.armatureLeakageReactance]:
    "armatureLeakageReactance",
  [MotorParametersDataVariables.directAxisArmatureReactance]:
    "directAxisArmatureReactance",
  [MotorParametersDataVariables.saturatedDirectAxisSynchronousReactance]:
    "saturatedDirectAxisSynchronousReactance",
  [MotorParametersDataVariables.unsaturatedDirectAxisSynchronousReactance]:
    "unsaturatedDirectAxisSynchronousReactance",
  [MotorParametersDataVariables.potierReactance]: "potierReactance",
  [MotorParametersDataVariables.saturatedDirectAxisTransientReactance]:
    "saturatedDirectAxisTransientReactance",
  [MotorParametersDataVariables.unsaturatedDirectAxisTransientReactance]:
    "unsaturatedDirectAxisTransientReactance",
  [MotorParametersDataVariables.saturatedDirectAxisSubtransientReactance]:
    "saturatedDirectAxisSubtransientReactance",
  [MotorParametersDataVariables.unsaturatedDirectAxisSubtransientReactance]:
    "unsaturatedDirectAxisSubtransientReactance",
  [MotorParametersDataVariables.quadratureAxisArmatureReactance]:
    "quadratureAxisArmatureReactance",
  [MotorParametersDataVariables.saturatedQuadratureAxisSynchronousReactance]:
    "saturatedQuadratureAxisSynchronousReactance",
  [MotorParametersDataVariables.unsaturatedQuadratureAxisSynchronousReactance]:
    "unsaturatedQuadratureAxisSynchronousReactance",
  [MotorParametersDataVariables.fieldLeakageReactance]: "fieldLeakageReactance",
  [MotorParametersDataVariables.saturatedQuadratureAxisTransientReactance]:
    "saturatedQuadratureAxisTransientReactance",
  [MotorParametersDataVariables.unsaturatedQuadratureAxisTransientReactance]:
    "unsaturatedQuadratureAxisTransientReactance",
  [MotorParametersDataVariables.saturatedQuadratureAxisSubtransientReactance]:
    "saturatedQuadratureAxisSubtransientReactance",
  [MotorParametersDataVariables.unsaturatedQuadratureAxisSubtransientReactance]:
    "unsaturatedQuadratureAxisSubtransientReactance",
  [MotorParametersDataVariables.quadratureAxisAmortisseurReactance]:
    "quadratureAxisAmortisseurReactance",
  [MotorParametersDataVariables.directAxisAmortisseurReactance]:
    "directAxisAmortisseurReactance",
  [MotorParametersDataVariables.saturatedNegativeSequenceReactance]:
    "saturatedNegativeSequenceReactance",
  [MotorParametersDataVariables.unsaturatedNegativeSequenceReactance]:
    "unsaturatedNegativeSequenceReactance",
  [MotorParametersDataVariables.statorArmatureResistance]:
    "statorArmatureResistance",
  [MotorParametersDataVariables.withoutFdrsFieldResistance]:
    "withoutFdrsFieldResistance",
  [MotorParametersDataVariables.withFdrsFieldResistance]:
    "withFdrsFieldResistance",
  [MotorParametersDataVariables.quadratureAxisAmortisseurResistance]:
    "quadratureAxisAmortisseurResistance",
  [MotorParametersDataVariables.directAxisAmortisseurResistance]:
    "directAxisAmortisseurResistance",
  [MotorParametersDataVariables.positiveSequenceResistance]:
    "positiveSequenceResistance",
  [MotorParametersDataVariables.negativeSequenceResistance]:
    "negativeSequenceResistance",
  [MotorParametersDataVariables.armatureShortCircuitTimeConstant]:
    "armatureShortCircuitTimeConstant",
  [MotorParametersDataVariables.directAxisTransientOpenCircuitTimeConstant]:
    "directAxisTransientOpenCircuitTimeConstant",
  [MotorParametersDataVariables.quadratureAxisTransientOpenCircuitTimeConstant]:
    "quadratureAxisTransientOpenCircuitTimeConstant",
  [MotorParametersDataVariables.directAxisSubtransientOpenCircuitTimeConstant]:
    "directAxisSubtransientOpenCircuitTimeConstant",
  [MotorParametersDataVariables.quadratureAxisSubtransientOpenCircuitTimeConstant]:
    "quadratureAxisSubtransientOpenCircuitTimeConstant",
  [MotorParametersDataVariables.directAxisTransientShortCircuitTimeConstant]:
    "directAxisTransientShortCircuitTimeConstant",
  [MotorParametersDataVariables.directAxisSubtransientShortCircuitTimeConstant]:
    "directAxisSubtransientShortCircuitTimeConstant",
  [MotorParametersDataVariables.windingCapacitancetoGround]:
    "windingCapacitancetoGround",
  [MotorParametersDataVariables.i22tLimit]: "i22tLimit",
};
