import React from "react";
import { Input, Switch, Icon, Checkbox } from "antd";
import _ from "lodash";
import { MANAGE_PI_TAGS_CONSTANTS } from "../../../constants/variables";
import { newValidateNumber } from "../../../libs/validator";
import { ic_edit } from "../../../assets/ic_edit";

import SelectDropdown from "./SelectDropdown";
import {
  validateNegativeNumber,
  validatePositiveFloat,
} from "../../../common/methods";
import { UserRole } from "../../AppScreen/AppScreen";
import { elementsGaurd } from "../../../gaurds";
import { ElementPermissions } from "../../../permissions";

export const DATA_TYPE = ["Boolean", "Float", "Integer", "String"];

export const validateValues = (minValue, maxValue) => {
  if (_.isEmpty(minValue) || _.isEmpty(maxValue)) {
    return false;
  }
  if (parseFloat(minValue) >= parseFloat(maxValue)) {
    return false;
  }
  return true;
};

export const validateIQRParameter = (param, name) => {
  if (name === "multiplier" && !validatePositiveFloat(param)) return false;
  if (_.isEmpty(param) || validateNegativeNumber(param)) return false;
  return true;
};

const DiscardCheck = (item, config) => {
  const array = config.deleteIds;
  const index = array.findIndex((arrayData) => item._id === arrayData);
  if (index === -1) {
    array.push(item._id);
    config.handleDeleteBtn(array);
  } else {
    array.splice(index, 1);
    config.handleDeleteBtn(array);
    if (!array.length) {
      config.handleDeleteBtn(array);
    }
  }
};

export const piTagColumns = (config) => [
  {
    titleRender: "",
    dataIndex: "selected",
    render: (_value, _row, _col, _isDisable, item) => {
      const isChecked = config.deleteIds.includes(item._id);
      return (
        <Checkbox
          onChange={() => DiscardCheck(item, config)}
          checked={isChecked}
        />
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.VARIABLE_TAG,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.TAG_API_INDEX,
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.UNITS,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.UNITS_API_INDEX,
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.VARIABLE_NAME,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.NAME_API_INDEX,
    render: (value, row, col, isDisable, item) => (
      <div className="pi-tag-variable-name">{item.piVariableName}</div>
    ),
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.EQUIPMENTS,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.EQUIPMENTID,
    render: (value, row, col, isDisable, item) => {
      let equipment = [];
      let systems =
        config.systems &&
        config.systems.filter(
          (system) => system.facilityId === config.facilityId
        );
      let systemIds = _.map(systems, "_id");
      let name = null;
      if (typeof value === "string") {
        equipment = [];
        name = config.equipmentsList.find((element) => element._id === value);
        name && name.name && equipment.push(name && name.name);
      } else {
        equipment = [];
        value.length &&
          value.forEach((equipmentId) => {
            const selectedEquipment = config.equipmentsList.find(
              (element) =>
                element._id === equipmentId &&
                _.indexOf(systemIds, element.systemId) !== -1
            );
            selectedEquipment &&
              selectedEquipment.name &&
              equipment.push(selectedEquipment && selectedEquipment.name);
          });
      }

      return (
        <>
          <SelectDropdown
            isDisable={isDisable}
            type="multiDropdown"
            change={config.change}
            onFocus={() =>
              config.resetField(MANAGE_PI_TAGS_CONSTANTS.EQUIPMENTS)
            }
            data={config.equipmentsList.filter(
              (equipment) => _.indexOf(systemIds, equipment.systemId) !== -1
            )}
            name={MANAGE_PI_TAGS_CONSTANTS.EQUIPMENT}
            value={equipment}
            id={item ? item._id : null}
            className={
              isDisable
                ? "pi-tag-not-error"
                : config.isErrorClassActive
                  ? equipment.length
                    ? "pi-tag-not-error"
                    : "error"
                  : ""
            }
            width="290px"
          />
        </>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.DATA_TYPE,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.DATA_TYPE_API_INDEX,
    render: (value, _row, _col, isDisable, item) => (
      <>
        <SelectDropdown
          isDisable={isDisable}
          change={config.change}
          onFocus={() =>
            config.resetField(MANAGE_PI_TAGS_CONSTANTS.DATA_TYPE_API_INDEX)
          }
          data={DATA_TYPE}
          name={MANAGE_PI_TAGS_CONSTANTS.DATA_TYPE_API_INDEX}
          value={value}
          id={item ? item._id : null}
          className={
            isDisable
              ? "pi-tag-not-error"
              : config.isErrorClassActive
                ? value
                  ? "pi-tag-not-error"
                  : "error"
                : ""
          }
        />
      </>
    ),
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.VARIABLE_PROPERTY,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.PROPERTY_API_INDEX,
    render: (value, _row, _col, isDisable, item) => (
      <>
        <SelectDropdown
          isDisable={isDisable}
          change={config.change}
          onFocus={() =>
            config.resetField(MANAGE_PI_TAGS_CONSTANTS.PROPERTY_API_INDEX)
          }
          data={config.variablePropertyList}
          name={MANAGE_PI_TAGS_CONSTANTS.PROPERTY_API_INDEX}
          value={value}
          id={item ? item._id : null}
          className={
            isDisable
              ? "pi-tag-not-error"
              : config.isErrorClassActive
                ? value
                  ? "pi-tag-not-error"
                  : "error"
                : ""
          }
          width="290px"
        />
      </>
    ),
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.MECHADEMY_UNIT,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.MECH_UNIT_API_INDEX,
    render: (value, _row, _col, isDisable, item) => (
      <>
        <SelectDropdown
          value={value}
          isDisable={isDisable}
          change={config.change}
          onFocus={() => {
            config.resetField(
              MANAGE_PI_TAGS_CONSTANTS.MECH_UNIT_API_INDEX,
              item
            );
          }}
          data={config.variablePropertyUnits}
          name={MANAGE_PI_TAGS_CONSTANTS.MECH_UNIT_API_INDEX}
          id={item ? item._id : null}
          className={
            isDisable
              ? "pi-tag-not-error"
              : config.isErrorClassActive
                ? value
                  ? "pi-tag-not-error"
                  : "error"
                : ""
          }
          width="160px"
        />
      </>
    ),
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.VARIABLE_MIN_VALUE,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.VAR_MIN_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={
              !!(
                isDisable ||
                item.dataType === "String" ||
                item.dataType === "Boolean"
              )
            }
            name={MANAGE_PI_TAGS_CONSTANTS.VAR_MIN_API_INDEX}
            value={value}
            onChange={(e) => {
              config.change(e, item ? item._id : null);
            }}
            className={
              isDisable ||
              item.dataType === "String" ||
              item.dataType === "Boolean" ||
              validateValues(item.minThreshold, item.maxThreshold)
                ? "pi-tag-not-error"
                : config.isErrorClassActive
                  ? "error"
                  : ""
            }
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            autoComplete="off"
          />
        </div>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.VARIABLE_MAX_VALUE,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.VAR_MAX_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={
              !!(
                isDisable ||
                item.dataType === "String" ||
                item.dataType === "Boolean"
              )
            }
            name={MANAGE_PI_TAGS_CONSTANTS.VAR_MAX_API_INDEX}
            value={value}
            onChange={(e) => {
              config.change(e, item ? item._id : null);
            }}
            className={
              isDisable ||
              item.dataType === "String" ||
              item.dataType === "Boolean" ||
              validateValues(item.minThreshold, item.maxThreshold)
                ? "pi-tag-not-error"
                : config.isErrorClassActive
                  ? "error"
                  : ""
            }
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            autoComplete="off"
          />
        </div>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.UAD_MULTIPLIER,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.IQR_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={
              !!(
                isDisable ||
                item.dataType === "String" ||
                item.dataType === "Boolean"
              )
            }
            name={MANAGE_PI_TAGS_CONSTANTS.IQR_API_INDEX}
            value={value}
            onChange={(e) =>
              config.change(e, item ? item._id : null, null, item)
            }
            className={
              isDisable ||
              item.dataType === "String" ||
              item.dataType === "Boolean" ||
              validatePositiveFloat(value) ||
              value === ""
                ? "pi-tag-not-error"
                : config.isErrorClassActive ||
                  (value && !validatePositiveFloat(value))
                  ? "error"
                  : ""
            }
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            autoComplete="off"
          />
        </div>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.UAD_ADDER,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.ADDER_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={
              !!(
                isDisable ||
                item.dataType === "String" ||
                item.dataType === "Boolean"
              )
            }
            name={MANAGE_PI_TAGS_CONSTANTS.ADDER_API_INDEX}
            value={config.defaultAdder ? "0" : value}
            onChange={(e) => {
              config.change(e, item ? item._id : null, null, item);
            }}
            className={
              isDisable ||
              item.dataType === "String" ||
              item.dataType === "Boolean" ||
              !validateNegativeNumber(value)
                ? "pi-tag-not-error"
                : config.isErrorClassActive ||
                  (value && validateNegativeNumber(value))
                  ? "error"
                  : ""
            }
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            autoComplete="off"
          />
        </div>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.ENABLE_UAD,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.IS_IQR_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      const minIqr = _.isNil(item.multiplier) ? "" : item.multiplier.toString();
      const maxIqr = _.isNil(item.adder) ? "" : item.adder.toString();
      return (
        <>
          <Switch
            disabled={
              isDisable
                ? true
                : !!(
                  _.isEmpty(minIqr) ||
                    validateNegativeNumber(minIqr) ||
                    _.isEmpty(maxIqr) ||
                    validateNegativeNumber(maxIqr)
                )
            }
            className="user-management-switch"
            checked={
              !_.isEmpty(minIqr) &&
              !validateNegativeNumber(minIqr) &&
              !_.isEmpty(maxIqr) &&
              !validateNegativeNumber(maxIqr)
                ? value
                : false
            }
            onChange={(e) => {
              config.change(e, item ? item._id : null, "isIQR");
            }}
          />
        </>
      );
    },
  },
  {
    title: MANAGE_PI_TAGS_CONSTANTS.IS_FROZEN,
    dataIndex: MANAGE_PI_TAGS_CONSTANTS.IS_FROZEN_API_INDEX,
    render: (value, _row, _col, isDisable, item) => {
      return (
        <>
          <Switch
            disabled={isDisable}
            className="user-management-switch"
            checked={value ? value : false}
            onChange={(e) => {
              config.change(e, item ? item._id : null, "isFrozen");
            }}
          />
        </>
      );
    },
  },
  UserRole !== "MechademyFacilityManager"
    ? {
      title: "Actions",
      dataIndex: " ",
      render: (_value, row, _col, isDisable, item) => (
        <>
          {!isDisable ? (
            <div
              className="pi-tag-list-action"
              onClick={() => config.undo(item._id, row)}
            >
              <Icon type="undo" />
            </div>
          ) : (
            <div
              className="pi-tag-list-action"
              onClick={() => {
                config.edit(item._id, row);
              }}
            >
              <img src={ic_edit} alt="edit icon" />
            </div>
          )}
          <div
            className="pi-tag-list-action"
            onClick={() => {
              config.handleDeletePiTag(item._id);
              config.deleteModal();
            }}
          >
            <Icon type="delete" />
          </div>
        </>
      ),
    }
    : elementsGaurd(
      {
        title: "Actions",
        dataIndex: " ",
        render: (_value, row, _col, isDisable, item) => (
          <>
            {!isDisable ? (
              <div
                className="pi-tag-list-action"
                onClick={() => config.undo(item._id, row)}
              >
                <Icon type="undo" />
              </div>
            ) : (
              <div
                className="pi-tag-list-action"
                onClick={() => {
                  config.edit(item._id, row);
                }}
              >
                <img src={ic_edit} alt="edit icon" />
              </div>
            )}
            <div
              className="pi-tag-list-action"
              onClick={() => {
                config.handleDeletePiTag(item._id);
                config.deleteModal();
              }}
            >
              <Icon type="delete" />
            </div>
          </>
        ),
      },
      ElementPermissions.ADD_PI_TAG,
      {
        type: "facility",
        dataRef: {
          _id: config.facilityId,
        },
      }
    ),
];
