import { Empty, Input, Select, Tabs } from 'antd';
import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import CustomTable from "../../common/CustomTable/CustomTable";
import { NO_INTERNET, SettingMessages } from '../../constants/messages';
import { ButtonVariables } from '../../constants/variables';
import NotConnected from '../../libs/NotConnected';
import { clearEquipmentState, getEquipmentType, getManufacturer } from '../../redux/actions/equipment.action';
import { clearFlowMeasurementState, getFlowMeterTypes } from '../../redux/actions/flowMeasurement.action';
import { addTypes, clearDriveTypeState, getDrivetypes } from "../../redux/actions/settings.action";
import Notification, { Types } from './../../common/Notification/Notification';
import { MaskStyle } from './../../constants/react-style';
import { columnsProperties, datas } from './Settings.constants';
import './Settings.scss';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        network : window.navigator.onLine,
        equipment : [],
        drive: [],
        manufacturer: [],
        flow: [],
        type: 'Drive Type',
        displayData: [],
        dirtyRows: [],
      formEdit: false,
      calculatedData: [],
      preserveDisplayData: [],
      disabled: true,
      editRows: [],
      updatedFields: [],
      searchValue: '',
      }
  }

  // edit form handlers
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidMount = () => {
    if(this.handleNetwork()){
      this.props.getDrivetypes();
    }

     // event listeners
     window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', e => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }

  componentDidUpdate = (prevProps) => {
        const { getEquipmentTypeData, manufacturerData, isSuccessGetEquipmentType, isSuccessGetManufacturer } = this.props.equipmentState.apiState;
        const { isSuccessGetFlowMeterType, getFlowMeterTypeData } = this.props.flowMeterState.apiState;
        const { isAddSuccess, isAddError , errorCode, message, getDriveType, addData } = this.props.settingState.apiState;
        
        if(isSuccessGetEquipmentType && isSuccessGetEquipmentType !== prevProps.equipmentState.apiState.isSuccessGetEquipmentType ){
          this.setState({
            equipment: getEquipmentTypeData && getEquipmentTypeData.items,
            displayData: getEquipmentTypeData && getEquipmentTypeData.items,
            preserveDisplayData: getEquipmentTypeData && getEquipmentTypeData.items,
            },() => {
            this.clearDirtyForm();
            })
        }

        if(isSuccessGetManufacturer && isSuccessGetManufacturer !== prevProps.equipmentState.apiState.isSuccessGetManufacturer ){
            this.setState({
              manufacturer: manufacturerData && manufacturerData.items && manufacturerData.items.map(item => {item['type'] = item['name']; return item}),
              displayData: manufacturerData && manufacturerData.items && manufacturerData.items.map(item => {item['type'] = item['name']; return item}),
              preserveDisplayData: manufacturerData && manufacturerData.items && manufacturerData.items.map(item => {item['type'] = item['name']; return item}),
            },() => {
              this.clearDirtyForm();
              })
        }

        if(isSuccessGetFlowMeterType && isSuccessGetFlowMeterType !== prevProps.flowMeterState.apiState.isSuccessGetFlowMeterType ){
          this.setState({
              flow: getFlowMeterTypeData && getFlowMeterTypeData.items,
              displayData: getFlowMeterTypeData && getFlowMeterTypeData.items,
              preserveDisplayData: getFlowMeterTypeData && getFlowMeterTypeData.items,
          },() => {
            this.clearDirtyForm();
            })
      }
    //   if(getDriveType.isSuccess && getDriveType.isSuccess !== prevProps.settingState.apiState.getDriveType.isSuccess ){
    //     this.setState({
    //         drive: getDriveType.data && getDriveType.data.items ,
    //         displayData: getDriveType.data && getDriveType.data.items ,
    //         preserveDisplayData: getDriveType.data && getDriveType.data.items ,
    //         type: datas[0]
    //     },() => {
    //       this.clearDirtyForm();
    //       })
    // }
    if(getDriveType.isSuccess && getDriveType.data && getDriveType.data.items){
      this.setState({
          drive: getDriveType.data && getDriveType.data.items ,
          displayData: getDriveType.data && getDriveType.data.items ,
          preserveDisplayData: getDriveType.data && getDriveType.data.items ,
          type: datas[0]
      },() => {
        this.clearDirtyForm();
        })
      this.props.clearDriveTypeState()
  }

        if(isAddSuccess && isAddSuccess !== prevProps.settingState.apiState.isAddSuccess ){
              if(addData.category === 'drive' && this.handleNetwork()){
                this.props.getDrivetypes();
                Notification.show(Types.Success, SettingMessages.DRIVE_TYPE );   
                this.disableEditForm();       
              }
              else if(addData.category === 'equipment' && this.handleNetwork()){
                this.props.getEquipmentType();
                Notification.show(Types.Success, SettingMessages.EQUIPMENT_TYPE ); 
                this.disableEditForm();         
              }

              else if(addData.category === 'flowmeter' && this.handleNetwork()){
                this.props.getFlowMeterTypes();
                Notification.show(Types.Success, SettingMessages.FLOW_TYPE );
                this.disableEditForm();          
              }
              else{
                this.handleNetwork() && this.props.getManufacturer();
                Notification.show(Types.Success, SettingMessages.MANUFACTURER_TYPE );   
                this.disableEditForm();       
              }
      }

      if(isAddError && isAddError !== prevProps.settingState.apiState.isAddError){        
        if(errorCode === 'INVALID_INPUT'){
          Notification.show(Types.Error, SettingMessages.INVALID_INPUT )
        }          
        else{
          Notification.show(Types.Error, `${message}`);
        }
      }
  }

//   componentWillUnmount = () => {
//       this.props.clearEquipmentState();
//       this.props.clearFlowMeasurementState();
// }
  

  getOptions = () => {
    const { Option } = Select;
      return(          
            datas.map(item => <Option key={item}>{item}</Option>
      )
    )
  }

  handleNetwork = () => {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      this.setState({ network: false });
      return false;
    }
  };

  handleList = (value) => {
    const { equipment, flow, drive, manufacturer } = this.state;
    
    if(value === datas[3]){
      this.setState({
        displayData: manufacturer,
        preserveDisplayData: manufacturer,
        type: value,
        formEdit: false,
        editRows:[],
        searchValue: ''
      },()=>{
        this.props.getManufacturer();
      })       
    }
    else if(value === datas[2]){
      this.setState({
        displayData: flow,
        preserveDisplayData: flow,
        type: value,
        formEdit: false,
        editRows:[],
        searchValue: ''
      },()=>{
        this.props.getFlowMeterTypes();
      })       
    }
    else if(value === datas[1]){
      this.setState({
        displayData: equipment,
        preserveDisplayData: equipment,
        type: value,
        formEdit: false,
        editRows:[],
        searchValue: ''
      },()=>{
        this.props.getEquipmentType();
      })       
    }
    else {
      this.setState({
        displayData: drive,
        preserveDisplayData: drive,
        type: value,
        formEdit: false,
        editRows:[],
        searchValue: ''
      },()=>{
        this.props.getDrivetypes();
      })   
    }
  }

   // form clear function
   clearDirtyForm() {
    this.setState(
      {
        editRows: [],
        updatedFields: [],
        dirtyRows: [],
        formEdit: false,       
      });
  }

  // edit row function
  editRow = row => {
    const { updatedFields, displayData,editRows } = this.state;
    let tempDisplay = _.cloneDeep(displayData);
    let updatedTemp = _.cloneDeep(updatedFields);
    updatedTemp[row] = tempDisplay[row];
    editRows.length < 1 && this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.setEditRows();
        this.enableEditForm();
      }
    );
  };


  // edit rows setter
  setEditRows = () => {
    const { updatedFields, displayData } = this.state;
    let tempEditRows = [];
    let tempUpdatedField = [];
      updatedFields.forEach((field, uindex) => {
        field &&
          displayData.forEach((data, index) => {
            if (field._id === data._id) {            
              tempEditRows.push(index);
              tempUpdatedField[index] = updatedFields[uindex];
            }
          });
      });
      this.setState({
        editRows: tempEditRows,
        updatedFields: tempUpdatedField,
        formEdit: false
      },);
  };

  // undo row function
  undo = row => {
    const { updatedFields, displayData, dirtyRows } = this.state;
    let updatedTemp = _.cloneDeep(displayData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let tempupdatedField = _.cloneDeep(updatedFields);
    updatedTemp[row] = _.cloneDeep(tempupdatedField[row]);
    tempDirtyRows = tempDirtyRows.filter(function (element) {
      return element !== row;
    });
    tempupdatedField[row] = undefined;
    this.setState(
      {
        displayData: _.cloneDeep(updatedTemp),
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.setEditRows();
        this.disableEditForm();
      }
    );
  };

  handleChange = (field, row) => {
      const { displayData } = this.state;
      let tempDisplayData = _.cloneDeep(displayData);
      if (!tempDisplayData[row]) {
        tempDisplayData[row] = {};
      }
      if (field.type === 'text') {
        tempDisplayData[row]['type'] = field.value;
      }
      this.setState(
        {
          displayData: tempDisplayData,
        });
  }

  save = () => {
    const { displayData, editRows, type } = this.state;
    
      if(displayData[editRows[0]]['type'] && displayData[editRows[0]]['type'].trim()){
        if(type === 'Manufacturer Type' && editRows.length){
          this.props.addTypes(displayData[editRows[0]]['_id'], { name : displayData[editRows[0]]['type']}, 'man')
        }
        else if(editRows.length) {
          if(this.handleNetwork()){
            this.props.addTypes(displayData[editRows[0]]['_id'], { type : displayData[editRows[0]]['type']}, 'non-man')
          }
        }
      }
      else{
        Notification.show(Types.Error, SettingMessages.FIELDS_DIRTY)
      }
  }

  renderTabs() {
    const { TabPane } = Tabs;
    const { displayData, type, disabled, formEdit } = this.state;
    // const { loading } = this.props.settingState;
    // const { flowMeterState, equipmentState } = this.props;
    return (
      <Tabs type="card" className="tabs" onChange={this.setTabPane}>
        <TabPane tab={'Custom List'} key="1">
            <Select className = {'select-type'} value={this.state.type} onChange={this.handleList} defaultValue={'Select List'} placeholder={'Select List'}>
                {this.getOptions()}
                </Select>
    {/* { equipmentState.isLoading || flowMeterState.isLoading || loading ? <Loader /> : null} */}
     { displayData.length ? <CustomTable
            columns={columnsProperties({ type, disabled, edit: this.editRow, undo: this.undo, change: this.handleChange})}
            showHeader={true}
            data={displayData}
            editableRow={this.state.editRows}
          />: <Empty /> }
        {formEdit && <div className="config-bottom-buttons">
              <button className="btn-default" onClick={this.save}> {ButtonVariables.UPDATE} </button>
          </div>}
        </TabPane>
      </Tabs>
    );
  }

  onSearch(data) {
    const { type } = this.state;
    this.setState({
      searchValue: data
    })
    
    if (this.handleNetwork()) {
      if(type === datas[0]){
        this.props.getDrivetypes(data);
      }
      if(type === datas[1]){
        this.props.getEquipmentType(data);
      }
      if(type === datas[2]){
        this.props.getFlowMeterTypes(data);
      }
      if(type === datas[3]){
        this.props.getManufacturer(data);
      }
    }
  };

  onSearchChange(value) {
    this.setState({
      searchValue: value
    })    
    if (!value) {
      this.onSearch('');
    }
  };


  render() {
    const { Search } = Input;
    return (
      <div className="Settings">
        <div className="header" style={MaskStyle}>
          <div className="units-properties">{'Settings'}</div>
          <div className="page-action page-action-tab">
            <Search
              placeholder={'Search here'}
              className="search-bar"
              onChange={(e) => { this.onSearchChange(e.target.value) }}
              onSearch={(value) => { this.onSearch(value) }}
              value={this.state.searchValue}
              allowClear
            />
          </div>
        </div>
        <div className="content-body">
          {this.state.network ? this.renderTabs() : <NotConnected />}
        </div>
        </div>       
    );
  }
}

const mapStateToProps = state => (
   {
        settingState : state.settingReducer,
        equipmentState: state.EquipmentReducer,
        flowMeterState: state.flowMeasurementReducer,
        network: state.NetworkReducer,
});

const mapDispatchToProps = {
    getEquipmentType,
    clearEquipmentState,
    getManufacturer,
    getFlowMeterTypes,
    addTypes,
    getDrivetypes,
    clearFlowMeasurementState,
    clearDriveTypeState
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
