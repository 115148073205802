import { features } from ".";

export const ElementPermissions = {
  ADD_CARD: { relatedTo: features.DASHBOARD, type: "write" },
  ADD_CLIENT: { relatedTo: features.SETUP, type: "write" },
  EDIT_CLIENT: { relatedTo: features.SETUP, type: "write" },
  ADD_FACILITY: { relatedTo: features.SETUP, type: "write" },
  EDIT_FACILITY: { relatedTo: features.SETUP, type: "write" },
  ADD_SYSTEM: { relatedTo: features.SETUP, type: "write" },
  EDIT_SYSTEM: { relatedTo: features.SETUP, type: "write" },
  ADD_EQUIPMENT: { relatedTo: features.SETUP, type: "write" },
  EDIT_EQUIPMENT: { relatedTo: features.SETUP, type: "write" },
  ADD_SCHEMATIC: { relatedTo: features.SETUP, type: "write" },
  EDIT_SCHEMATIC: { relatedTo: features.SETUP, type: "write" },
  VIEW_SCHEMATIC: { relatedTo: features.SETUP, type: "read" },
  ADD_RUNNER: { relatedTo: features.SETUP, type: "write" },
  EDIT_RUNNER: { relatedTo: features.SETUP, type: "write" },
  ADD_INPUT: { relatedTo: features.SETUP, type: "write" },
  EDIT_INPUT: { relatedTo: features.SETUP, type: "write" },
  ADD_USER: { relatedTo: features.USER_MANAGEMENT, type: "write" },
  EDIT_USER: { relatedTo: features.USER_MANAGEMENT, type: "write" },
  ALERT_LISTING_STATUS: { relatedTo: features.DASHBOARD, type: "write" },
  ALERT_DETAILS: { relatedTo: features.DASHBOARD, type: "write" },
  CARD_ACTION: { relatedTo: features.DASHBOARD, type: "write" },
  ADD_TREND: { relatedTo: features.DASHBOARD, type: "write" },
  DASHBOARD_VIEW: { relatedTo: features.DASHBOARD, type: "read" },
  ADD_PI_TAG: { relatedTo: features.PI_TAG, type: "write" },
  VIEW_PI_TAG: { relatedTo: features.PI_TAG, type: "read" },
  EDIT_ML_ALGO: { relatedTo: features.ML_ALGORITHM, type: "write" },
  ADD_ML_ALGO: { relatedTo: features.ML_ALGORITHM, type: "write" },
  VIEW_ML_ALGO: { relatedTo: features.ML_ALGORITHM, type: "read" },
};
