export const GET_RUNTAGS_PROGRESS = "GET_RUNTAGS_PROGRESS";
export const GET_RUNTAGS_SUCCESS = "GET_RUNTAGS_SUCCESS";
export const GET_RUNTAGS_ERROR = "GET_RUNTAGS_ERROR";

export const GET_RUNTAGS_LIST_PROGRESS = "GET_RUNTAGS_LIST_PROGRESS";
export const GET_RUNTAGS_LIST_SUCCESS = "GET_RUNTAGS_LIST_SUCCESS";
export const GET_RUNTAGS_LIST_ERROR = "GET_RUNTAGS_LIST_ERROR";

export const SUBMIT_RUNTAGS_PROGRESS = "SUBMIT_RUNTAGS_PROGRESS";
export const SUBMIT_RUNTAGS_SUCCESS = "SUBMIT_RUNTAGS_SUCCESS";
export const SUBMIT_RUNTAGS_ERROR = "SUBMIT_RUNTAGS_ERROR";

export const GET_RUNTAGS_DATA_PROGRESS = "GET_RUNTAGS_DATA_PROGRESS";
export const GET_RUNTAGS_DATA_SUCCESS = "GET_RUNTAGS_DATA_SUCCESS";
export const GET_RUNTAGS_DATA_ERROR = "GET_RUNTAGS_DATA_ERROR";

export const SUBMIT_NEWRUN_PROGRESS = "SUBMIT_NEWRUN_PROGRESS";
export const SUBMIT_NEWRUN_SUCCESS = "SUBMIT_NEWRUN_SUCCESS";
export const SUBMIT_NEWRUN_ERROR = "SUBMIT_NEWRUN_ERROR";

export const EDIT_RUN_PROGRESS = "EDIT_RUN_PROGRESS";
export const EDIT_RUN_SUCCESS = "EDIT_RUN_SUCCESS";
export const EDIT_RUN_ERROR = "EDIT_RUN_ERROR";

export const DELETE_RUN_PROGRESS = "DELETE_RUN_PROGRESS";
export const DELETE_RUN_SUCCESS = "DELETE_RUN_SUCCESS";
export const DELETE_RUN_ERROR = "DELETE_RUN_ERROR";

export const FILTER_RUNTAGS_PROGRESS = "FILTER_RUNTAGS_PROGRESS";
export const FILTER_RUNTAGS_SUCCESS = "FILTER_RUNTAGS_SUCCESS";
export const FILTER_RUNTAGS_ERROR = "FILTER_RUNTAGS_ERROR";

export const SUBMIT_RUN_COMMENT_PROGRESS = "SUBMIT_RUN_COMMENT_PROGRESS";
export const SUBMIT_RUN_COMMENT_SUCCESS = "SUBMIT_RUN_COMMENT_SUCCESS";
export const SUBMIT_RUN_COMMENT_ERROR = "SUBMIT_RUN_COMMENT_ERROR";

export const RECALCULATE_RUN_PROGRESS = "RECALCULATE_RUN_PROGRESS";
export const RECALCULATE_RUN_SUCCESS = "RECALCULATE_RUN_SUCCESS";
export const RECALCULATE_RUN_ERROR = "RECALCULATE_RUN_ERROR";

export const GET_RUN_NUMBER_PROGRESS = "GET_RUN_NUMBER_PROGRESS";
export const GET_RUN_NUMBER_SUCCESS = "GET_RUN_NUMBER_SUCCESS";
export const GET_RUN_NUMBER_ERROR = "GET_RUN_NUMBER_ERROR";

export const GET_FILTER_RUN_NUMBER_PROGRESS = "GET_FILTER_RUN_NUMBER_PROGRESS";
export const GET_FILTER_RUN_NUMBER_SUCCESS = "GET_FILTER_RUN_NUMBER_SUCCESS";
export const GET_FILTER_RUN_NUMBER_ERROR = "GET_FILTER_RUN_NUMBER_ERROR";

export const GET_LAST_RUN_DATA_PROGRESS = "GET_LAST_RUN_DATA_PROGRESS";
export const GET_LAST_RUN_DATA_SUCCESS = "GET_LAST_RUN_DATA_SUCCESS";
export const GET_LAST_RUN_DATA_ERROR = "GET_LAST_RUN_DATA_ERROR";

export const BULK_DELETE_RUN_PROGRESS = "BULK_DELETE_RUN_PROGRESS";
export const BULK_DELETE_RUN_SUCCESS = "BULK_DELETE_RUN_SUCCESS";
export const BULK_DELETE_RUN_ERROR = "BULK_DELETE_RUN_ERROR";

export const AUTO_RECALC_STATE_SUCCESS = "AUTO_RECALC_STATE_SUCCESS";

export const RUN_TOUR_CHANGE = "RUN_TOUR_CHANGE";

export const CLEAR_RUNTAGS_STATE = "CLEAR_RUNTAGS_STATE";
export const CLEAR_SUBMIT_COMMENT_STATE = "CLEAR_SUBMIT_COMMENT_STATE";

export const CLEAR_FILTER_STATE = "CLEAR_FILTER_STATE";

export const CLEAR_EDIT_STATE = "CLEAR_EDIT_STATE";

export const CLEAR_RECALCULATE_STATE = "CLEAR_RECALCULATE_STATE";

export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

export const CLEAR_RUNTAG_STATE = "CLEAR_RUNTAG_STATE";
