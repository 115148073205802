import { Form, Modal } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Line from '../../../../../../common/Graphs/Line/Line';
import {validatePositiveFloat } from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import { savePerformaceCurve } from '../../../../../../redux/actions/motor.action';
import CustomTable from './../../../../../../common/CustomTable/CustomTable';
import Loader from './../../../../../../common/Loader/Loader';
import {
  CurvesMessages,
  DISCARD_CHANGES, FORM_DISCARD_MSG, NO_INTERNET,

  PerformanceCurveMessages
} from './../../../../../../constants/messages';
import { ButtonVariables } from './../../../../../../constants/variables';
import FormFields from '../../../../../../common/FormFields/FormFields';
import {
  inputFields,
  inputUnits, tableHeader,
  curveInput, graphDetails,
  curveVariables
} from './PerformaceCurves.Constants';
import './PerformanceCurves.scss';
class PerformaceCurves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: '',
      efficiency: '',
      current: '',
      powerFactor: '',
      slip: '',
      loadUnit: '',
      efficiencyUnit: '',
      powerFactorUnit: '',
      currentUnit: '',
      slipUnit: '',
      displayData: [],
      copyDisplayData: [],
      editTableRows: [],
      inititalDisplayData: [],
      unitData: [],
      visible: false,
      formEdit: false,
      eventListener: false,
      saveListener: false,
      visibleGraph: false,
      dirtyEditFields: false,
      visibleModal: false,
      xAxisValue: '',
      yAxisValue: '',
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      const { inititalDisplayData, saveListener } = this.state;
      const {
        motorConfigData: { performanceCurvesData },
      } = this.props.MotorReducer;
      this.setState({
        formEdit: false,
        load: null,
        efficiency: null,
        current: null,
        powerFactor: null,
        slip: null,
        editTableRows: [],
        eventListener: false,
        saveListener: false,
      });

      if (this.state.formEdit && !saveListener) {
        this.setState({
          loadUnit: performanceCurvesData.dataUnit.load,
          efficiencyUnit: performanceCurvesData.dataUnit.efficiency,
          powerFactorUnit: performanceCurvesData.dataUnit.powerFactor,
          currentUnit: performanceCurvesData.dataUnit.current,
          slipUnit: performanceCurvesData.dataUnit.slip,
          displayData: [...inititalDisplayData.map((item) => ({ ...item }))],
          copyDisplayData: [
            ...inititalDisplayData.map((item) => ({ ...item })),
          ],
        });
      }
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }
  setData() {
    const {
      motorConfigData: { performanceCurvesData },
    } = this.props.MotorReducer;

    const tempDisplayData = performanceCurvesData.data.map((item) => {
      return {
        load: item['load'] >= 0 ? item['load'] : '',
        efficiency: item['efficiency'] >= 0 ? item['efficiency'] : '',
        current: item['current'] >= 0 ? item['current'] : '',
        powerFactor: item['powerFactor'] >= 0 ? item['powerFactor'] : '',
        slip: item['slip'] >= 0 ? item['slip'] : '',
      };
    });

    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
      inititalDisplayData: _.cloneDeep(tempDisplayData),
      loadUnit: performanceCurvesData.dataUnit.load,
      efficiencyUnit: performanceCurvesData.dataUnit.efficiency,
      powerFactorUnit: performanceCurvesData.dataUnit.powerFactor,
      currentUnit: performanceCurvesData.dataUnit.current,
      slipUnit: performanceCurvesData.dataUnit.slip,
    });
  }

  componentDidUpdate(prevProps) {
    const { addPerformanceCurve, motorConfigData } = this.props.MotorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }

    if (this.props.activeKey !== prevProps.activeKey) {
      const {
        motorConfigData: { performanceCurvesData },
      } = this.props.MotorReducer;
      this.setState({
        loadUnit: performanceCurvesData.dataUnit.load,
        efficiencyUnit: performanceCurvesData.dataUnit.efficiency,
        powerFactorUnit: performanceCurvesData.dataUnit.powerFactor,
        currentUnit: performanceCurvesData.dataUnit.current,
        slipUnit: performanceCurvesData.dataUnit.slip,
        displayData: [
          ...this.state.inititalDisplayData.map((item) => ({ ...item })),
        ],
        copyDisplayData: [
          ...this.state.inititalDisplayData.map((item) => ({ ...item })),
        ],
      });
    }

    if (
      motorConfigData.isSuccess &&
      prevProps.MotorReducer.motorConfigData.isSuccess !==
        motorConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      addPerformanceCurve.isSuccess &&
      prevProps.MotorReducer.addPerformanceCurve.isSuccess !==
        addPerformanceCurve.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, PerformanceCurveMessages.SUCCESS);
    }
    if (
      addPerformanceCurve.isError &&
      prevProps.MotorReducer.addPerformanceCurve.isError !==
        addPerformanceCurve.isError
    ) {
      Notification.show(Types.Error, PerformanceCurveMessages.ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleChangeTableData = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
        eventListener: false,
      },
      () => this.enableEditForm()
    );
  };

  handleChangeTableEditData = (e, row) => {
    const { displayData } = this.state;
    const key = e.target.id;
    const value = e.target.value;
    const tempDisplayData = displayData;
    tempDisplayData[row][key] = value;
    this.setState(
      {
        displayData: tempDisplayData,
      },
      () => {
        this.enableEditForm();
        this.state.displayData.forEach((item) =>
          Object.keys(item).forEach((element) => {
            if (_.isFinite(parseFloat(item[element]))) {
              item[element] = item[element].toString();
            }
          })
        );
        this.validateEditFields(this.state.displayData);
      }
    );
  };

  onAdd = () => {
    const {
      displayData,
      load,
      efficiency,
      current,
      powerFactor,
      slip,
    } = this.state;
    this.setState({
      eventListener: true,
    });

    if (this.validateFields()) {
      this.setState(
        {
          displayData: [
            ...displayData,
            {
              load: _.isFinite(parseFloat(load)) ? load : '',
              efficiency: _.isFinite(parseFloat(efficiency)) ? efficiency : '',
              current: _.isFinite(parseFloat(current)) ? current : '',
              powerFactor: _.isFinite(parseFloat(powerFactor))
                ? powerFactor
                : '',
              slip: _.isFinite(parseFloat(slip)) ? slip : '',
            },
          ],
        },
        () => {
          this.setState(
            {
              load: '',
              efficiency: '',
              current: '',
              powerFactor: '',
              slip: '',
              eventListener: false,
            },
            () => this.enableEditForm()
          );
        }
      );
    } else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = (row) => {
    const { displayData, copyDisplayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    tempDisplayData[row] = { ...copyDisplayData[row] };
    const tempEditTableRows = editTableRows.filter((item) => item !== row);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditTableRows,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    this.setState(
      {
        editTableRows: [...editTableRows, row],
      },
      () => this.enableEditForm()
    );
  };

  onDelete = (row) => {
    const { displayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    const tempEditRow = editTableRows.filter((item) => item !== row);
    tempDisplayData.splice(row, 1);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditRow,
      },
      () => this.enableEditForm()
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    const {
      motorConfigData: { performanceCurvesData },
    } = this.props.MotorReducer;
    this.setState({
      displayData: [...copyDisplayData.map((item) => ({ ...item }))],
      loadUnit: performanceCurvesData.dataUnit.load,
      efficiencyUnit: performanceCurvesData.dataUnit.efficiency,
      powerFactorUnit: performanceCurvesData.dataUnit.powerFactor,
      currentUnit: performanceCurvesData.dataUnit.current,
      slipUnit: performanceCurvesData.dataUnit.slip,
      visible: false,
      editTableRows: [],
      saveListener: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  handleOkGraph = () => {
    this.setState({ visibleGraph: false });
  };

  getGraphData() {
    const { displayData } = this.state;
    if (displayData.length > 0) {
      const { xAxisValue, yAxisValue } = this.state;
      const axes = displayData.map(
        (item) => {
            return [item[xAxisValue] || null, item[yAxisValue] || null]
        });
        return axes;
    }
  }

  renderGraphModal() {
    const { visibleGraph } = this.state;
    if (visibleGraph) {
      const {xAxisValue, yAxisValue} = this.state;
      const graphData = this.getGraphData();
      return (
        <Modal
          title={graphDetails.title}
          visible={this.state.visibleGraph}
          onCancel={this.handleOkGraph}          
          footer={null}
          className="curve-modal"
        >
        <div>
          <button className="btn-default btn-small" float='right'
            onClick={() => {
              this.showGraph();
            }}>BACK</button>
        </div>
          <div className="curve-plot">
            <div style={{ width: 700 }}>
              <Line
                graphData={graphData}
                xAxis={graphDetails[xAxisValue]}
                yAxis={graphDetails[yAxisValue]}
              />
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  }

  cancelModal() {
    this.setState({
      xAxisValue: '',
      yAxisValue: ''
    });
    this.setState({ visibleModal: false });
  }

  onChangeXAxes = (value) => {
    this.setState({ xAxisValue: value });
  }

  onChangeYAxes = (value) => {
    this.setState({ yAxisValue: value });
  }

  onSelectAxes() {
    const { xAxisValue, yAxisValue } = this.state;
    const xAxisTag = curveVariables[xAxisValue];
    const yAxisTag = curveVariables[yAxisValue];
    this.setState({ xAxisValue: xAxisTag });
    this.setState({ yAxisValue: yAxisTag });
    this.setState({ visibleModal: false });
    this.setState({ visibleGraph: true });
  }

  renderCurveInputModal() {
    const { visibleModal } = this.state;
    if (visibleModal) {
      const data = ['Load', 'Efficiency', 'Power Factor', 'Current', 'Slip'];
      const { xAxisValue, yAxisValue } = this.state;
      return (
        <Modal
          title={'Curve Input'}
          visible={this.state.visibleModal}
          onCancel={() => this.cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(data, xAxisValue, yAxisValue, {
              changeX: (e) => {
                this.onChangeXAxes(e);
              },
              changeY: (e) => {
                this.onChangeYAxes(e);
              }
            })}
            form={this.props.form}
          />
          {
            <div className="ok-button">
              <button className="btn-default btn-small"
                onClick={() => {
                  this.onSelectAxes();
                }} disabled={xAxisValue === '' || yAxisValue === ''}>OK</button>
            </div>
          }
        </Modal>
      )
    }
  }

  showGraph() {
    if (this.state.displayData.length >= 3) {
      this.setState({
        xAxisValue: '',
        yAxisValue: '' 
      });
      this.setState({ visibleGraph: false });
      this.setState({ visibleModal: true });
    } else {
      Notification.show(Types.Info, PerformanceCurveMessages.MIN_CURVES);
    }
  }

  transformPayload = (data) => {
    data.forEach((item) => {
      if (!_.isFinite(parseFloat(item['slip']))) {
        delete item['slip'];
      } else {
        item['slip'] = parseFloat(item['slip']);
      }
      if (!_.isFinite(parseFloat(item['load']))) {
        delete item['load'];
      } else {
        item['load'] = parseFloat(item['load']);
      }

      if (!_.isFinite(parseFloat(item['efficiency']))) {
        delete item['efficiency'];
      } else {
        item['efficiency'] = parseFloat(item['efficiency']);
      }

      if (!_.isFinite(parseFloat(item['powerFactor']))) {
        delete item['powerFactor'];
      } else {
        item['powerFactor'] = parseFloat(item['powerFactor']);
      }

      if (!_.isFinite(parseFloat(item['current']))) {
        delete item['current'];
      } else {
        item['current'] = parseFloat(item['current']);
      }
    });
    return data;
  };

  onSave = () => {
    this.setState({
      saveListener: true,
    });
    const {
      loadUnit,
      efficiencyUnit,
      powerFactorUnit,
      currentUnit,
      slipUnit,
    } = this.state;
    this.state.displayData.forEach((item) =>
      Object.keys(item).forEach((element) => {
        if (_.isFinite(parseFloat(item[element]))) {
          item[element] = item[element].toString();
        }
      })
    );

    if (
      loadUnit &&
      efficiencyUnit &&
      powerFactorUnit &&
      currentUnit &&
      slipUnit
    ) {
      if (this.validateEditFields(this.state.displayData)) {
        const { displayData } = this.state;
        if (displayData.length >= 3 && displayData.length <= 15) {
          const payload = {
            dataUnit: {
              load: loadUnit,
              efficiency: efficiencyUnit,
              powerFactor: powerFactorUnit,
              current: currentUnit,
              slip: slipUnit,
            },
            data: this.transformPayload(displayData),
          };

          if (this.handleNetwork()) {
            const {
              location: { state },
              savePerformaceCurve,
            } = this.props;
            if (this.handleNetwork() && state && state.componentData.info._id) {
              savePerformaceCurve(state.componentData.info._id, payload);
            }
          }
        } else {
          Notification.show(Types.Info, PerformanceCurveMessages.MIN);
        }
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else {
      Notification.show(Types.Error, CurvesMessages.SELECT_UNITS);
    }
  };

  validateFields = () => {
    const { load, efficiency, current, powerFactor, slip } = this.state;
    let status = true;

    if (load || efficiency || current || powerFactor || slip) {
      if (
        (load && !validatePositiveFloat(load)) ||
        (efficiency && !validatePositiveFloat(efficiency)) ||
        (current && !validatePositiveFloat(current)) ||
        (powerFactor && !validatePositiveFloat(powerFactor)) ||
        (slip && !validatePositiveFloat(slip))
      ) {
        status = false;
      }
    } else {
      status = false;
    }
    return status;
  };

  validateEditFields = (data) => {
    let status = true;
    let dirtyEditFields = false;
    this.state.displayData.forEach((item) =>
      Object.keys(item).forEach((element) => {
        if (item[element] && !validatePositiveFloat(item[element])) {
          status = false;
        } else {
          if (
            !item['slip'] &&
            !item['powerFactor'] &&
            !item['load'] &&
            !item['current'] &&
            !item['efficiency']
          ) {
            status = false;
            dirtyEditFields = true;
          }
        }
      })
    );
    this.setState({
      dirtyEditFields,
    });
    return status;
  };

  render() {
    const {
      eventListener,
      displayData,
      unitData,
      editTableRows,
      load,
      efficiency,
      current,
      powerFactor,
      slip,
      loadUnit,
      efficiencyUnit,
      powerFactorUnit,
      currentUnit,
      slipUnit,
      saveListener,
      dirtyEditFields,
    } = this.state;
    const { motorConfigData, addPerformanceCurve } = this.props.MotorReducer;
    const { isSchematicEditable } = this.props;
    return (
      <div className="PerformaceCurves">
        {(motorConfigData.isLoading || addPerformanceCurve.isLoading) && (
          <Loader />
        )}
        <div>
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.showGraph();
              }}
              style={{ border: '1px solid #363b4e' }}
            >
              {'View Curve Plot'}
            </button>
          </div>
          <CustomTable
            showHeader={true}
            columns={tableHeader({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              dirtyEditFields,
              isSchematicEditable,
            })}
            data={displayData}
            renderAddRow={inputUnits({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              eventListener,
              load,
              efficiency,
              current,
              powerFactor,
              slip,
              unitData,
              loadUnit,
              efficiencyUnit,
              powerFactorUnit,
              currentUnit,
              slipUnit,
              saveListener,
              length: displayData.length,
              isSchematicEditable,
            })}
            headerColumns={
              isSchematicEditable &&
              inputFields({
                handleChangeTableData: this.handleChangeTableData,
                onAdd: this.onAdd,
                eventListener,
                load,
                efficiency,
                current,
                powerFactor,
                slip,
                length: displayData.length,
              })
            }
            editableRow={editTableRows}
          />
        </div>

        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderCurveInputModal()}
        {this.renderModal()}
        {this.renderGraphModal()}
      </div>
    );
  }
}
PerformaceCurves.propTypes = {
  getDataUnits: PropTypes.func,
  motorConfigData: PropTypes.object,
  addPerformanceCurve: PropTypes.object,
  location: PropTypes.object,
  savePerformaceCurve: PropTypes.func,
  network: PropTypes.object,
  isSchematicEditable: PropTypes.bool,
};

PerformaceCurves.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    MotorReducer: state.MotorReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  savePerformaceCurve: (id, payload) =>
    dispatch(savePerformaceCurve(id, payload)),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)
(Form.create({
  name: 'curveForm',
  onFieldsChange: (props, fields, allfields) => {
  },
})(withRouter(PerformaceCurves)));
