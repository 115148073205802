import React from "react";
import { Spin } from "antd";
import "antd/dist/antd.css";

function Loader() {
  return (
    <div className="spinner">
      <Spin size="large" />
    </div>
  );
}

export default Loader;
