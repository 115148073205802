export default {
  ADD_GT_DESIGN_PERF_DATA_PROGRESS: "ADD_GT_DESIGN_PERF_DATA_PROGRESS",
  ADD_GT_DESIGN_PERF_DATA_SUCCESS: "ADD_GT_DESIGN_PERF_DATA_SUCCESS",
  ADD_GT_DESIGN_PERF_DATA_FAILURE: "ADD_GT_DESIGN_PERF_DATA_FAILURE",
  ADD_GT_DESIGN_PERF_DATA_RESET: "ADD_GT_DESIGN_PERF_DATA_RESET",

  UPDATE_GT_PERF_DATA_PROGRESS: "UPDATE_GT_PERF_DATA_PROGRESS",
  UPDATE_GT_PERF_DATA_SUCCESS: "UPDATE_GT_PERF_DATA_SUCCESS",
  UPDATE_GT_PERF_DATA_FAILURE: "UPDATE_GT_PERF_DATA_FAILURE",
  UPDATE_GT_PERF_DATA_RESET: "UPDATE_GT_PERF_DATA_RESET",

  UPDATE_GT_FLUID_COMP_DATA_PROGRESS: "UPDATE_GT_FLUID_COMP_DATA_PROGRESS",
  UPDATE_GT_FLUID_COMP_DATA_SUCCESS: "UPDATE_GT_FLUID_COMP_DATA_SUCCESS",
  UPDATE_GT_FLUID_COMP_DATA_FAILURE: "UPDATE_GT_FLUID_COMP_DATA_FAILURE",
  UPDATE_GT_FLUID_COMP_DATA_RESET: "UPDATE_GT_FLUID_COMP_DATA_RESET",

  GET_GT_CONFIG_DATA_PROGRESS: "GET_GT_DESIGN_PERF_DATA_PROGRESS",
  GET_GT_CONFIG_DATA_SUCCESS: "GET_GT_DESIGN_PERF_DATA_SUCCESS",
  GET_GT_CONFIG_DATA_FAILURE: "GET_GT_DESIGN_PERF_DATA_FAILURE",
  GET_GT_CONFIG_DATA_RESET: "GET_GT_DESIGN_PERF_DATA_RESET",

  UPDATE_GT_CURVES_DATA_PROGRESS: "UPDATE_GT_CURVES_DATA_PROGRESS",
  UPDATE_GT_CURVES_DATA_SUCCESS: "UPDATE_GT_CURVES_DATA_SUCCESS",
  UPDATE_GT_CURVES_DATA_FAILURE: "UPDATE_GT_CURVES_DATA_FAILURE",
  UPDATE_GT_CURVES_DATA_RESET: "UPDATE_GT_CURVES_DATA_RESET",

  RESET_GT_REDUCER: "RESET_GT_REDUCER",
};
