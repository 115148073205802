import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SideMenuVariables } from '../../constants/variables';
import { Col, Form, Input, Modal, Row } from 'antd';
import * as Variables from '../../constants/variables';
import * as Storage from '../../libs/storage';
import Notification, { Types } from '../Notification/Notification';



const { firstName,lastName } = Storage.get(Variables.USER);
let userInfo = firstName+" " + lastName;
function ReasonModal(props) {
  const [reason, setReason] = useState('');
  const handleSubmit = () => {
    let reasonData = reason.trim();
    if (typeof reasonData === undefined || reasonData.length === 0) {
      Notification.show(Types.Warning, 'Reason cannot be empty!');
    } else if (reasonData.length < 20) {
      Notification.show(
        Types.Warning,
        'Reason should contain atleast 20 characters'
      );
    } else {
      props.handleOk(reason,userInfo);
    }
  };

  return (
    <Modal
      title={SideMenuVariables.MODAL_REASON}
      visible={props.visible}
      onOk={handleSubmit}
      onCancel={() => props.handleCancel()}
      okText={props.okText ? props.okText : 'Proceed'}
      closable={false}
    >
      {props.message ? (
        <p>{props.message} : <b>{props.modelName}</b></p>
      ) : (
        <p>
          {SideMenuVariables.MODAL_MESSAGE1}
          <br />
          {SideMenuVariables.MODAL_MESSAGE2}
        </p>
      )}
      <br />
      <Form>
        <Row>
          <Col>
            <Form.Item label="REASON">
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                style={{
                  height: '100px',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  resize: 'none'
                }}
                maxLength={500}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default withRouter(ReasonModal);
