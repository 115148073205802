import { ALERT_TABS } from "../../constants/variables";
import * as AssetTypes from "../Types/Alerts.types";

const INITIAL_API_STATE = {
  isErrorList: false,
  isSuccessMyAlertList: false,
  isSuccessNewAlertList: false,
  isSuccessAllAlertList: false,
  isSuccessActiveAlertList: false,
  isSuccessBypassedAlertList: false,
  isSuccessInactiveAlertList: false,
  errorCodeList: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
    isLoadingList: false,
  },
  itemMyAlerts: {
    list: [],
    data: {},
  },
  itemNewAlerts: {
    list: [],
    data: {},
  },
  itemAllAlerts: {
    list: [],
    data: {},
  },
  itemActiveAlerts: {
    list: [],
    data: {},
  },
  itemBypassedAlerts: {
    list: [],
    data: {},
  },
  itemInactiveAlerts: {
    list: [],
    data: {},
  },
};

export default function AlertReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AssetTypes.ALL_ALERTS_FETCH_SUCCESS:
      const apiData = action.payload;
      const { items, eventList, ...data } = apiData;
      const NewItems = items.map(
        (item) =>
          (item.component && {
            ...item,
            identifierDetails: {
              ...item.identifierDetails,
              displayVariableTagNumber: createDisplayTag(
                item.identifierDetails.variableTagNumber
              ),
            },
          }) ||
          item
      );
      switch (action.payload.tab) {
        case Object.keys(ALERT_TABS)[0]:
          return {
            ...state,
            // itemAllAlerts: action.payload,
            itemAllAlerts: {
              ...state.itemAllAlerts,
              list: [...state.itemAllAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessAllAlertList: true,
            },
          };
        case Object.keys(ALERT_TABS)[1]:
          return {
            ...state,
            // itemActiveAlerts: action.payload,
            itemActiveAlerts: {
              ...state.itemActiveAlerts,
              list: [...state.itemActiveAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessActiveAlertList: true,
            },
          };
        case Object.keys(ALERT_TABS)[2]:
          return {
            ...state,
            // itemBypassedAlerts: action.payload,
            itemBypassedAlerts: {
              ...state.itemBypassedAlerts,
              list: [...state.itemBypassedAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessBypassedAlertList: true,
            },
          };
        case Object.keys(ALERT_TABS)[3]:
          return {
            ...state,
            // itemInactiveAlerts: action.payload,
            itemInactiveAlerts: {
              ...state.itemInactiveAlerts,
              list: [...state.itemInactiveAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessInactiveAlertList: true,
            },
          };
        case Object.keys(ALERT_TABS)[4]:
          return {
            ...state,
            itemNewAlerts: {
              ...state.itemNewAlerts,
              list: [...state.itemNewAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessNewAlertList: true,
            },
          };
        case Object.keys(ALERT_TABS)[5]:
          return {
            ...state,
            itemMyAlerts: {
              ...state.itemMyAlerts,
              list: [...state.itemMyAlerts.list, ...NewItems],
              eventList,
              data,
            },
            apiState: {
              ...INITIAL_API_STATE,
              isLoadingList: false,
              isSuccessMyAlertList: true,
            },
          };
        default:
          return {
            ...state,
          };
      }

    case AssetTypes.ALL_ALERTS_FETCH_PROGRESS:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          isLoadingList: true,
        },
      };
    case AssetTypes.ALL_ALERTS_FETCH_ERROR:
      return {
        ...state,
        apiState: {
          isLoadingList: false,
          isErrorList: true,
          errorCodeList:
            action.payload.response && action.payload.response.body.code,
        },
      };

    case AssetTypes.ALERTS_CLEAR_API_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };

    case AssetTypes.ALL_ALERTS_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    case AssetTypes.ALERT_CLEAR_LIST:
      switch (action.tab) {
        case Object.keys(ALERT_TABS)[0]:
          return {
            ...state,
            itemAllAlerts: {
              list: [],
              data: {},
            },
          };
        case Object.keys(ALERT_TABS)[1]:
          return {
            ...state,
            itemActiveAlerts: {
              list: [],
              data: {},
            },
          };
        case Object.keys(ALERT_TABS)[2]:
          return {
            ...state,
            itemBypassedAlerts: {
              list: [],
              data: {},
            },
          };
        case Object.keys(ALERT_TABS)[3]:
          return {
            ...state,
            itemInactiveAlerts: {
              list: [],
              data: {},
            },
          };
        case Object.keys(ALERT_TABS)[4]:
          return {
            ...state,
            itemNewAlerts: {
              list: [],
              data: {},
            },
          };
        case Object.keys(ALERT_TABS)[5]:
          return {
            ...state,
            itemMyAlerts: {
              list: [],
              data: {},
            },
          };
        default:
          return {
            ...state,
          };
      }
    default:
      return state;
  }
}

function createDisplayTag(variableTag) {
  const TagNumberArr = variableTag.split("-");
  TagNumberArr.pop();
  return TagNumberArr.join("-");
}
