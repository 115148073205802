import { Modal } from 'antd';
import React from 'react'
import Line from '../../../../../../../common/Graphs/Line/Line';
import Notification, { Types } from '../../../../../../../common/Notification/Notification';
import { graphDetails } from '../../../CurvesData/Curves.constants';

const RenderGraph = (visible, setVisibility, type, asTested, operational) => {
    const getGraphData = () => {
        if (type === "capacityControl") {
            const asTestedCurve = asTested.map(
                (item) => ({
                    x: Number(item.dischargeSlideValvePosition),
                    y: Number(item.volumetricCapacityMultiplier),
                })
            );
            const operationalBaseLine = operational.map(
                (item) => ({
                    x: Number(item.dischargeSlideValvePosition),
                    y: Number(item.volumetricCapacityMultiplier),
                })
            );

            return { asTestedCurve, operationalBaseLine }
        }
        else if (type === "volumetricRatio") {
            const asTestedCurve = asTested.map(
                (item) => ({
                    x: Number(item.suctionSlideValvePosition),
                    y: Number(item.volumetricRatioMultiplier),
                })
            );
            const operationalBaseLine = operational.map(
                (item) => ({
                    x: Number(item.suctionSlideValvePosition),
                    y: Number(item.volumetricRatioMultiplier),
                })
            );
            return { asTestedCurve, operationalBaseLine }
        }
        else if (type === "lowMole" || type === "highMole") {
            const asTestedCurve = asTested.map(
                (item) => ({
                    x: Number(item.tipSpeed),
                    y: Number(item.adiabaticEfficiency),
                })
            );
            const operationalBaseLine = operational.map(
                (item) => ({
                    x: Number(item.tipSpeed),
                    y: Number(item.adiabaticEfficiency),
                })
            );
            return { asTestedCurve, operationalBaseLine }
        }
        else if (type === "adiabaticEfficiency") {
            const asTestedCurve = asTested.map(
                (item) => ({
                    x: Number(item.slideValvePosition),
                    y: Number(item.adiabaticEfficiency),
                })
            );
            const operationalBaseLine = operational.map(
                (item) => ({
                    x: Number(item.slideValvePosition),
                    y: Number(item.adiabaticEfficiency),
                })
            );
            return { asTestedCurve, operationalBaseLine }
        }
    }

    const getGraphAxis = (name, axis) => {
        if (name === "capacityControl") {
            return axis === "x" ? graphDetails.slideValvePosition : graphDetails.volumetricCapacityMultiplier
        }
        else if (name === "volumetricRatio") {
            return axis === "x" ? graphDetails.suctionSlideValvePosition : graphDetails.volumeRatioMultiplier
        }
        else if (name === "lowMole" || type === "highMole") {
            return axis === "x" ? graphDetails.tipSpeed : graphDetails.adiabaticEfficiency
        }
        else if (type === "adiabaticEfficiency") {
            return axis === "x" ? graphDetails.slideValvePosition : graphDetails.adiabaticEfficiency
        }
    }
    const renderGraphModal = () => {
        if (asTested.length < 2 && operational.length < 2 && visible) {
            setVisibility(false)
            return Notification.show(Types.Info, graphDetails.requiredMessage);
        }
        const graphData = getGraphData()
        return (
            <Modal
                title={graphDetails.title}
                visible={visible}
                onOk={() => setVisibility(!visible)}
                onCancel={() => setVisibility(!visible)}
                style={{
                    maxWidth: 'inherit'
                }}
                className='Curves'
                centered={true}
            >
                <div style={{ textAlign: "center", width: 500}}>
                    As-Tested Curve
                    <Line
                        graphData={graphData.asTestedCurve}
                        xAxis={getGraphAxis(type, "x")}
                        yAxis={getGraphAxis(type, "y")}
                    />
                    Operational Baseline curve
                    <Line
                        graphData={graphData.operationalBaseLine}
                        xAxis={getGraphAxis(type, "x")}
                        yAxis={getGraphAxis(type, "y")}
                    />
                </div>
            </Modal>
        )
    }

    return (
        renderGraphModal()
    )

}

export default RenderGraph