import { Divider } from 'antd';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../../common/Loader/Loader';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  ERROR_MESSAGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  StreamDataMessages,
} from '../../../../../constants/messages';
import { ButtonVariables } from '../../../../../constants/variables';
import { getDataUnits } from '../../../../../redux/actions/dataUnit.action';
import {
  addStreamData,
  clearStreamState,
  getStreamData,
  resetStreamReducer,
} from '../../../../../redux/actions/Stream.action';
import FlowMeasurement from '../FlowMeasurement/FlowMeasurement';
import { columnsDataVariablesMap } from '../FlowMeasurement/FlowMeasurementConstants';
import FluidComposition from '../FluidComposition/FluidComposition';
import './Stream.scss';

class Stream extends React.Component {
  constructor(props) {
    super(props);
    this.setCompositionData = this.setCompositionData.bind(this);
    this.setMeasurementData = this.setMeasurementData.bind(this);
    this.state = {
      payload: {
        streamId: '',
        fluidComposition: {},
        flowMeasurementData: {},
      },
      formEdit: false,
      isSave: false,
      isFlowMeasurementData: false,
      isFluidCompositionData: false,
      fcdata: {},
      fmdata: {},
    };
  }

  componentDidMount() {
    const {
      location: { state },
      getStreamData,
    } = this.props;
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        isSave: false,
      });
    });

    if (this.handleNetwork() && state && state.componentData.info._id) {
      getStreamData(state.componentData.info._id);
      this.props.getDataUnits(Object.values(columnsDataVariablesMap));
    }
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  componentDidUpdate(prevProps) {
    const {
      streamState: {
        isStreamDataSuccess,
        isStreamDataError,
        isAddStreamDataError,
        errorCode,
        isAddStreamDataSuccess,
      },
      clearStreamState,
    } = this.props;
    const {
      location: { state },
      addStreamData,
    } = this.props;
    const { isFlowMeasurementData, isFluidCompositionData } = this.state;

    if (
      isStreamDataSuccess &&
      isStreamDataSuccess !== prevProps.isStreamDataSuccess
    ) {
      this.setState({
        formEdit: false,
        isSave: false,
      });
    }
    if (
      isStreamDataError &&
      isStreamDataError !== prevProps.isStreamDataError
    ) {
      this.setState({
        formEdit: false,
        isSave: false,
      });
    }
    if (
      isAddStreamDataError &&
      isAddStreamDataError !== prevProps.isAddStreamDataError
    ) {
      Notification.show(Types.Error, ERROR_MESSAGES[errorCode]);
    }

    if (isAddStreamDataSuccess) {
      clearStreamState();
      Notification.show(Types.Success, StreamDataMessages.SUCCESS);
    }

    if (isFlowMeasurementData && isFluidCompositionData) {
      if (this.handleNetwork() && state && state.componentData.info._id) {
        const payload = {
          streamId: `stream${this.props.tabValue}`,
          fluidComposition: this.state.fcdata,
          flowMeasurementData: this.state.fmdata,
        };
        addStreamData(state.componentData.info._id, payload);
        this.setState({
          isFlowMeasurementData: false,
          isFluidCompositionData: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetStreamReducer();
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  onSave = () => {
    this.setState({
      isSave: true,
    });
  };

  setMeasurementData(data) {
    this.setState({
      fmdata: data,
      isFlowMeasurementData: true,
    });
  }

  setCompositionData(data) {
    this.setState({
      fcdata: data,
      isFluidCompositionData: true,
    });
  }

  setIsSave = () => {
    this.setState({
      isSave: false,
    });
  };

  onReset = () => {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
        dirtyRows: [],
      });
    }
  };

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const {
      streamState: { loading },
    } = this.props;
    return (
      <div>
        {loading && <Loader />}
        <div className="boxes">
          <div className="header-text">
            Flow Measurement Data
            <Divider />
          </div>
          <div className="body-text">
            <FlowMeasurement
              isSave={this.state.isSave}
              setIsSave={() => this.setIsSave()}
              setAddData={(data) => this.setMeasurementData(data)}
              streamId={this.props.tabValue}
              mixerEditable={this.props.isSchematicEditable}
            />
          </div>
        </div>
        <br />
        <br />
        <div className="boxes lower-box">
          <div className="header-text">
            Fluid Composition
            <Divider />
          </div>
          <div className="body-text">
            <FluidComposition
              isSave={this.state.isSave}
              setIsSave={() => this.setIsSave()}
              setAddData={(data) => this.setCompositionData(data)}
              streamId={this.props.tabValue}
              mixerEditable={this.props.isSchematicEditable}
            />
          </div>
        </div>
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModal()}
      </div>
    );
  }
}

Stream.propTypes = {
  isConfigData: PropType.bool,
  isSchematicEditable: PropType.bool.isRequired,
  tabValue: PropType.string,
  getDataUnits: PropType.func,
  addStreamData: PropType.func,
  getStreamData: PropType.func,
  clearStreamState: PropType.func,
  clearConfigState: PropType.func,
  configData: PropType.object,
  streamState: PropType.object,
  match: PropType.object,
  network: PropType.object,
  history: PropType.object,
  location: PropType.object,
  resetStreamReducer: PropType.func,
};

const mapStateToProps = (state) => ({
  streamState: state.streamReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = {
  getStreamData,
  addStreamData,
  getDataUnits,
  clearStreamState,
  resetStreamReducer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stream));
