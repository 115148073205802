import { Icon } from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DirectoryStructure from '../../../common/DirectoryStructure/DirectoryStructure';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  ClientListingMessages,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import NotConnected from '../../../libs/NotConnected';
import RouterOutlet from '../../../libs/RouterOutlet';
import {
  clearClientState,
  getClientInfo,
} from '../../../redux/actions/client.action';
import { clearFacilityState } from '../../../redux/actions/facility.action';
import { updateFormEdit } from '../../../redux/actions/formEdit.action';
import './ClientInfo.scss';

const NodeTypes = {
  FACILITY: 'facility',
  CLIENT: 'client',
  SYSTEM: 'system',
  EQUIPMENT: 'equipment',
};

class ClientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: true,
      visible: false,
      demandPage: window.location.pathname,
      pageData: {},
      formEdit: false,
      clientInfoData: {},
      closeMenu: false,
    };
  }

  componentDidMount() {
    const clientData = this.props;
    if (clientData.location.state) {
      this.getClientInfo();
    }
    window.addEventListener('form-edit', () => {
      this.props.updateFormEdit(true);
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isSuccessClientsInfo,
      isErrorClientInfo,
      errorCode,
      clientInfoData,
      isSuccessAddClients,
      isSuccessEditClients,
    } = this.props.clientState.apiState;
    const {
      isSuccessAddFacility,
      isSuccessEditFacility,
    } = this.props.facilityState.apiState;
    const {
      isSuccessAddSystem,
      isSuccessEditSystem,
    } = this.props.clientSystemState.apiState;
    const { clearClientState } = this.props;
    const {
      isSuccessAddEquipment,
      isSuccessEditEquipment,
    } = this.props.equipmentState.apiState;
    const {
      isSuccessCreateSchematicComponentFetch,
      isSuccessEditSchematicComponentFetch,
    } = this.props.schematicState.apiState;

    const { isAddSchematicSuccess } = this.props.systemSchematicState.apiState;

    if (isSuccessClientsInfo) {
      const clientTree = {
        facilities: clientInfoData.facilities,
        systems: clientInfoData.systems,
        equipments: clientInfoData.equipments,
        systemSchematics: clientInfoData.systemSchematics,
        schematics: clientInfoData.schematics,
      };
      clearClientState();
      const client = {};
      client.info = clientInfoData
        ? clientInfoData.info
        : this.props.location.state.clientData;
      client.children = this.createClientTree(clientTree);
      client.type = 'client';
      this.setState({
        clientInfoData: { [client.info._id]: client },
      });
    }

    if (
      isSuccessAddEquipment ||
      isSuccessEditEquipment ||
      isSuccessAddSystem ||
      isSuccessEditSystem ||
      isSuccessEditFacility !==
        prevProps.facilityState.apiState.isSuccessEditFacility ||
      isSuccessAddFacility !==
        prevProps.facilityState.apiState.isSuccessAddFacility ||
      isSuccessAddClients ||
      isSuccessEditClients ||
      isSuccessCreateSchematicComponentFetch ||
      isSuccessEditSchematicComponentFetch ||
      isAddSchematicSuccess !==
        prevProps.systemSchematicState.apiState.isAddSchematicSuccess
    ) {
      if (isSuccessEditFacility || isSuccessAddFacility) {
        this.props.clearFacilityState();
      }
      this.getClientInfo();
    }

    if (isErrorClientInfo) {
      Notification.show(Types.Error, ClientListingMessages[errorCode]);
      clearClientState();
    }
  }

  createClientTree(data) {
    const keys = Object.keys(data);
    const priority = {
      facilities: 0,
      systems: 1,
      equipments: 2,
    };

    // make a hash map for system schematic
    let systemSchematic = {};
    if (data && data.systemSchematics && data.systemSchematics.length) {
      systemSchematic = _.keyBy(data.systemSchematics, 'system');
    }
    // make a hash map for schematic
    let schematic = {};
    if (data && data.schematics && data.schematics.length) {
      schematic = _.keyBy(data.schematics, 'equipment');
    }

    const type = {
      0: NodeTypes.FACILITY,
      1: NodeTypes.SYSTEM,
      2: NodeTypes.EQUIPMENT,
    };
    const client = [];
    for (let i = 0; i < keys.length; i++) {
      if (
        keys[i] === 'facilities' ||
        keys[i] === 'systems' ||
        keys[i] === 'equipments'
      ) {
        let Schematic = {};
        if (type[i] === NodeTypes.SYSTEM) {
          Schematic = systemSchematic;
        }
        if (type[i] === NodeTypes.EQUIPMENT) {
          Schematic = schematic;
        }
        client[priority[keys[i]]] = this.groupById(
          data[keys[i]],
          type[i],
          Schematic
        );
      }
    }
    return this.mapTree(client);
  }

  groupById(data, type, Schematic) {
    const obj = {};
    for (let i = 0; i < data.length; i++) {
      const iobj = {};
      iobj.info = { ...data[i], name: data[i].name || data[i].systemName };
      iobj.type = type;
      if (type === NodeTypes.SYSTEM) {
        if (Schematic[data[i]._id]) {
          iobj.systemSchematic = Schematic[data[i]._id]._id;
        }
      }
      if (type === NodeTypes.EQUIPMENT) {
        if (Schematic[data[i]._id]) {
          iobj.schematic = Schematic[data[i]._id]._id;
        }
      }
      obj[data[i]._id] = iobj;
    }
    return obj;
  }

  mapTree(data) {
    const accessId = {
      0: 'clientId',
      1: 'facilityId',
      2: 'systemId',
    };
    let finalData = 0;
    for (let i = data.length - 2; i >= 0; i--) {
      if (Object.keys(data[i + 1]) && Object.keys(data[i + 1]).length) {
        // eslint-disable-next-line
        Object.keys(data[i + 1]).forEach((item) => {
          if (data[i][data[i + 1][item].info[accessId[i + 1]]]) {
            if (!data[i][data[i + 1][item].info[accessId[i + 1]]].children) {
              data[i][data[i + 1][item].info[accessId[i + 1]]].children = {};
            }
            data[i][data[i + 1][item].info[accessId[i + 1]]].children[item] =
              data[i + 1][item];
            finalData = i;
          }
        });
      }
    }
    for (let i = 0; i < data.length; i++) {
      if (Object.keys(data[i]).length) {
        finalData = i;
        break;
      }
    }
    return data[finalData];
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getClientInfo() {
    const clientInfo = this.props;
    const { getClientInfo } = this.props;
    if (this.props.location.state && this.handleNetwork()) {
      getClientInfo(clientInfo.location.state.clientData._id);
    }
  }

  getFacility(id) {
    const { clientInfoData } = this.props.clientState.apiState;
    for (let i = 0; i < clientInfoData.facilities.length; i++) {
      if (clientInfoData.facilities[i]._id === id) {
        return clientInfoData.facilities[i];
      }
    }
    return {};
  }

  getSystem(id) {
    const { clientInfoData } = this.props.clientState.apiState;
    for (let i = 0; i < clientInfoData.systems.length; i++) {
      if (clientInfoData.systems[i]._id === id) {
        return clientInfoData.systems[i];
      }
    }
    return {};
  }

  goToClient() {
    const clientInfoData = this.props.clientState.apiState.clientInfoData.info;

    if (this.state.formEdit || this.props.isEdit) {
      this.setState({
        visible: true,
        demandPage: Routes.clientInfo,
        pageData: { clientData: clientInfoData, mode: 'view' },
      });
    } else {
      this.props.history.push(Routes.clientInfo, {
        clientData: clientInfoData,
        mode: 'view',
      });
    }
  }

  onSelect = (data, key) => {
    const clientInfoData = this.props.clientState.apiState.clientInfoData.info;
    const systemInfo = this.getSystem(data.info.systemId);

    if (key === 'schematic') {
     if (this.state.formEdit || this.props.isEdit) {
      this.setState(
        {
          visible:true,
          demandPage: Routes.componentSchematic,
          pageData: {
            clientData: clientInfoData,
            facilityData: this.getFacility(systemInfo.facilityId),
            systemData: systemInfo,
            equipmentData: data.info,
            activePath: data,
          },
        });
      } else {
          this.props.history.push(Routes.componentSchematic, {
            clientData: clientInfoData,
            facilityData: this.getFacility(systemInfo.facilityId),
            systemData: systemInfo,
            equipmentData: data.info,
            activePath: data,
          });
        }
    } else if (key === 'systemSchematic') {
      if (this.state.formEdit || this.props.isEdit){
        this.setState({
          visible: true,
          demandPage: Routes.systemSchematic,
          pageData: {
            clientData: clientInfoData,
            facilityData: data.info,
            mode: 'view',
            activePath: data,
          },
        })
      } else {
      this.props.history.push(Routes.systemSchematic, {
        clientData: clientInfoData,
        facilityData: this.getFacility(data.info.facilityId),
        systemData: data.info,
        activePath: data,
      });
    }
    } else if (data.type === NodeTypes.CLIENT) {
      if (this.state.formEdit || this.props.isEdit) {
        this.setState({
          visible: true,
          demandPage: Routes.clientInfo,
          pageData: { clientData: data.info, mode: 'view', activePath: data },
        });
      } else {
        this.props.history.push(Routes.clientInfo, {
          clientData: data.info,
          mode: 'view',
          activePath: data,
        });
      }
    } else if (data.type === NodeTypes.FACILITY) {
      if (this.state.formEdit || this.props.isEdit) {
        this.setState({
          visible: true,
          demandPage: Routes.clientFacility,
          pageData: {
            clientData: clientInfoData,
            facilityData: data.info,
            mode: 'view',
            activePath: data,
          },
        });
      } else {
        this.props.history.push(Routes.clientFacility, {
          clientData: clientInfoData,
          facilityData: data.info,
          mode: 'view',
          activePath: data,
        });
      }
    } else if (data.type === NodeTypes.SYSTEM) {
      if (this.state.formEdit || this.props.isEdit) {
        this.setState({
          visible: true,
          demandPage: Routes.clientSystem,
          pageData: {
            clientData: clientInfoData,
            facilityData: this.getFacility(data.info.facilityId),
            systemData: data.info,
            mode: 'view',
            activePath: data,
          },
        });
      } else {
        this.props.history.push(Routes.clientSystem, {
          clientData: clientInfoData,
          facilityData: this.getFacility(data.info.facilityId),
          systemData: data.info,
          mode: 'view',
          activePath: data,
        });
      }
    } else if (data.type === NodeTypes.EQUIPMENT) {
      if (this.state.formEdit || this.props.isEdit) {
        this.setState({
          visible: true,
          demandPage: Routes.clientEquipment,
          pageData: {
            clientData: clientInfoData,
            facilityData: this.getFacility(systemInfo.facilityId),
            systemData: systemInfo,
            equipmentData: data.info,
            mode: 'view',
            activePath: data,
          },
        });
      } else {
        this.props.history.push(Routes.clientEquipment, {
          clientData: clientInfoData,
          facilityData: this.getFacility(systemInfo.facilityId),
          systemData: systemInfo,
          equipmentData: data.info,
          mode: 'view',
          activePath: data,
        });
      }
    } else {
      this.props.history.push(Routes.clients);
    }
  };

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.disableEditForm();
    this.setState(
      {
        visible: false,
        formEdit: false,
      },
      () => {
        this.props.history.push(this.state.demandPage, this.state.pageData);
      }
    );
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  closeMenu() {
    if (!this.state.closeMenu) {
      this.setState({
        closeMenu: true,
      });
    } else {
      this.setState({
        closeMenu: false,
      });
    }
  }

  render() {
    // const { loading } = this.props.clientState;
    const { clientInfoData } = this.state;
    const clientData = this.props;

    if (!clientData.location.state) {
      this.props.history.push(Routes.clients);
      return <div />;
    }

    return (
      <div>
        {/* {loading ? <Loader /> : null} */}
        {this.state.network ? (
          <div className="ClientInfo">
            <div
              className="structure"
              style={
                !this.state.closeMenu
                  ? { width: '220px', minWidth: '220px' }
                  : { width: '17px', minWidth: '17px' }
              }
            >
              <div
                className="close-button"
                onClick={() => {
                  this.closeMenu();
                }}
              >
                {!this.state.closeMenu ? (
                  <Icon type="left" />
                ) : (
                  <Icon type="menu" />
                )}
              </div>
              <div className="directory">
                {clientInfoData && Object.keys(clientInfoData).length ? (
                  <DirectoryStructure
                    data={clientInfoData}
                    onSelect={this.onSelect}
                    loader
                    // defaultSelectedKey={clientData.location.state && clientData.location.state.activePath ? clientData.location.state.activePath : null}
                    defaultExpandedKeys={[
                      clientInfoData && Object.keys(clientInfoData)[0],
                    ]}
                  />
                ) : null}
              </div>
            </div>
            <div className="routes">
              <RouterOutlet routes={this.props.routes} />
            </div>
          </div>
        ) : (
          <NotConnected />
        )}
        {this.state.visible ? (
          <ConfirmModal
            visible={this.state.visible}
            handleOk={() => this.handleOk()}
            handleCancel={() => this.handleCancel()}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  facilityState: state.FacilityReducer,
  clientSystemState: state.SystemReducer,
  network: state.NetworkReducer,
  equipmentState: state.EquipmentReducer,
  schematicState: state.SchematicReducer,
  systemSchematicState: state.systemSchematicReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClientInfo: (values) => dispatch(getClientInfo(values)),
  clearClientState: () => dispatch(clearClientState()),
  clearFacilityState: () => dispatch(clearFacilityState()),
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientInfo));
