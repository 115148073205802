import React from "react";
import { CollapseRow } from "./CollapseRow";
import { columnsTitle } from "../../constants/variables";

class CollapseTable extends React.Component {
  render() {
    return (
      <div className="associations-table">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "40%", paddingLeft: "57px" }}>
                {columnsTitle.associations.propertyName}
              </th>
              <th style={{ width: "35%" }}>
                {columnsTitle.associations.units}
              </th>
              <th>{columnsTitle.associations.dataVariable}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.collapseData.map((dara) => (
              <CollapseRow data={dara} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CollapseTable;
