import { Icon, Input, Popover } from "antd";
import _ from "lodash";
import React from "react";
import {
  validateNaturalNumber,
  validateNegativeNumber,
  validatePositiveFloat,
} from "../../../../../../common/methods";
import { frameSizePattern } from "../../../../../../constants/pattern";
import { DesignDataVariables } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

const content = (value) => {
  if (value !== "") {
    return <span>{hoverText[value]}</span>;
  }
};

export const DesignDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (
        value === DesignDataVariables.voltage ||
        value === DesignDataVariables.speedType ||
        value === DesignDataVariables.speedSignalRatio
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "Units",
    dataIndex: "unit",
    render: (_value, _row, _col, disabled, item) => {
      if (
        item.name === DesignDataVariables.voltage ||
        item.name === DesignDataVariables.frequency
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              !item.unit && _.isFinite(parseFloat(item.design))
                ? "error"
                : "not-error"
            }  `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "Design",
    dataIndex: "design",
    render: (value, _row, _col, disabled, item) => {
      if (
        item.name === DesignDataVariables.dutyType ||
        item.name === DesignDataVariables.insulationClass ||
        item.name === DesignDataVariables.temperatureClass ||
        item.name === DesignDataVariables.nemaEnclosureType ||
        item.name === DesignDataVariables.enclosureIPCode ||
        item.name === DesignDataVariables.imCode ||
        item.name === DesignDataVariables.speedType
      ) {
        const tempError = !!(
          item.name === DesignDataVariables.speedType &&
          !item.design &&
          config.saveListener
        );
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""}  ${
              tempError ? "error" : "not-error"
            }  `}
          >
            {" "}
            {!_.isEmpty(value) && item.name === DesignDataVariables.dutyType ? (
              <Popover
                placement="left"
                trigger="hover"
                content={content(value)}
              >
                <select
                  value={!item.design ? "" : item.design}
                  disabled={disabled}
                  onChange={(e) =>
                    config.handleChange(e.target.value, item.name, "select")
                  }
                >
                  <option value="">Select</option>
                  {data[item.name] &&
                    data[item.name].map((item) => (
                      <option value={item} title={hoverText[item]}>
                        {item}
                      </option>
                    ))}
                </select>
              </Popover>
            ) : (
              <select
                value={!item.design ? "" : item.design}
                disabled={disabled}
                onChange={(e) =>
                  config.handleChange(e.target.value, item.name, "select")
                }
              >
                <option value="">Select</option>
                {data[item.name] &&
                  data[item.name].map((item) => (
                    <option value={item} title={hoverText[item]}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
          </div>
        );
      }
      if (
        item.name === DesignDataVariables.icCode ||
        item.name === DesignDataVariables.bearingType ||
        item.name === DesignDataVariables.drivenEquipment
      ) {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "select")
              }
            />
          </div>
        );
      }
      if (item.name === DesignDataVariables.equipmentFrameSize) {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "string")
              }
              className={
                item.name === DesignDataVariables.equipmentFrameSize &&
                item.design &&
                !frameSizePattern.test(item.design.toString())
                  ? "error"
                  : "not-error"
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "number")
            }
            className={
              (item.name === DesignDataVariables.voltage &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.name === DesignDataVariables.voltage &&
                !parseFloat(item.design) &&
                parseFloat(item.design) !== 0 &&
                config.saveListener) ||
              (item.name === DesignDataVariables.voltage &&
                item.unit &&
                !_.isFinite(parseFloat(item.design))) ||
              (item.name === DesignDataVariables.frequency &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.name === DesignDataVariables.frequency &&
                item.unit &&
                !parseFloat(item.design) &&
                parseFloat(item.design) !== 0 &&
                config.saveListener) ||
              (item.name === DesignDataVariables.frequency &&
                item.unit &&
                !_.isFinite(parseFloat(item.design))) ||
              (item.name === DesignDataVariables.speedSignalRatio &&
                item.design &&
                validateNegativeNumber(item.design.toString())) ||
              (item.name === DesignDataVariables.speedSignalRatio &&
                !item.design &&
                !item.design.toString() &&
                config.saveListener) ||
              (item.name === DesignDataVariables.numberOfPoles &&
                item.design &&
                validateNegativeNumber(item.design.toString())) ||
              (item.name === DesignDataVariables.serviceFactor &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.name === DesignDataVariables.bearingQuantity &&
                item.design &&
                !validateNaturalNumber(item.design.toString()))
                ? "error"
                : "not-error"
            }
            onKeyDown={(e) => newValidateNumber(e)}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (_value, row, _col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const data = {
  [DesignDataVariables.speedType]: [
    "Constant Speed",
    "Varying Speed",
    "Adjustable Speed",
    "Multispeed",
  ],
  [DesignDataVariables.dutyType]: [
    "S1",
    "S2",
    "S3",
    "S4",
    "S5",
    "S6",
    "S7",
    "S8",
    "S9",
    "S10",
  ],
  [DesignDataVariables.insulationClass]: ["A", "B", "F", "H"],
  [DesignDataVariables.temperatureClass]: ["A", "B", "F", "H"],
  [DesignDataVariables.nemaEnclosureType]: [
    "Dripproof Machine (ODP)",
    "Splash-Proof Machine",
    "Semi-Guarded Machine",
    "Guarded Machine",
    "Dripproof Guarded Machine (DPG)",
    "Open Independently Ventilated Machine (OIV)",
    "Open Pipe-Ventilated Machine",
    "Weather Protected Type I (WP I)",
    "Weather Protected Type II (WP II)",
    "Totally Enclosed Nonventilated Machine (TENV)",
    "Totally Enclosed Fan-Cooled Machine (TEFC)",
    "Totally Enclosed Fan-Cooled Guarded Machine",
    "Totally Enclosed Pipe-Ventilated Machine (TEPV)",
    "Totally Enclosed Water-Cooled Machine (TEWC)",
    "Water-Proof Machine",
    "Totally Enclosed Air-to-Water-Cooled Machine (TEWAC)",
    "Totally Enclosed Air-to-Air-Cooled Machine (TEAAC)",
    "Totally Enclosed Air-Over Machine (TEAO)",
    "Explosion-Proof Machine (EXPL)",
    "Dust-Ignition-Proof Machine",
  ],
  [DesignDataVariables.enclosureIPCode]: [
    "IP00",
    "IP01",
    "IP02",
    "IP03",
    "IP04",
    "IP05",
    "IP06",
    "IP07",
    "IP08",
    "IP10",
    "IP11",
    "IP12",
    "IP13",
    "IP14",
    "IP15",
    "IP16",
    "IP17",
    "IP18",
    "IP20",
    "IP21",
    "IP22",
    "IP23",
    "IP24",
    "IP25",
    "IP26",
    "IP27",
    "IP28",
    "IP30",
    "IP31",
    "IP32",
    "IP33",
    "IP34",
    "IP35",
    "IP36",
    "IP37",
    "IP38",
    "IP40",
    "IP41",
    "IP42",
    "IP43",
    "IP44",
    "IP45",
    "IP46",
    "IP47",
    "IP48",
    "IP50",
    "IP51",
    "IP52",
    "IP53",
    "IP54",
    "IP55",
    "IP56",
    "IP57",
    "IP58",
    "IP60",
    "IP61",
    "IP62",
    "IP63",
    "IP64",
    "IP65",
    "IP66",
    "IP67",
    "IP68",
    "IP69K",
  ],
  [DesignDataVariables.imCode]: [
    "IM Bx",
    "IM B3",
    "IM B5",
    "IM B6",
    "IM B7",
    "IM B8",
    "IM B9",
    "IM B10",
    "IM B14",
    "IM B15",
    "IM B20",
    "IM B25",
    "IM B30",
    "IM B34",
    "IM B35",
    "IM Vx",
    "IM V1",
    "IM V2",
    "IM V3",
    "IM V4",
    "IM V5",
    "IM V6",
    "IM V8",
    "IM V9",
    "IM V10",
    "IM V14",
    "IM V15",
    "IM V16",
    "IM V17",
    "IM V18",
    "IM V19",
    "IM V30",
    "IM V31",
    "IM V35",
    "IM V37",
  ],
};

export const initialData = [
  { name: DesignDataVariables.dutyType, design: "" },
  { name: DesignDataVariables.numberOfPoles, design: "" },
  { name: DesignDataVariables.voltage, unit: "", design: "" },
  { name: DesignDataVariables.frequency, unit: "", design: "" },
  { name: DesignDataVariables.serviceFactor, design: "" },
  { name: DesignDataVariables.insulationClass, design: "" },
  { name: DesignDataVariables.temperatureClass, design: "" },
  { name: DesignDataVariables.nemaEnclosureType, design: "" },
  { name: DesignDataVariables.enclosureIPCode, design: "" },
  { name: DesignDataVariables.icCode, design: "" },
  { name: DesignDataVariables.imCode, design: "" },
  { name: DesignDataVariables.speedType, design: "" },
  { name: DesignDataVariables.speedSignalRatio, design: "" },
  { name: DesignDataVariables.bearingType, design: null },
  { name: DesignDataVariables.bearingQuantity, design: "" },
  { name: DesignDataVariables.equipmentFrameSize, design: null },
  { name: DesignDataVariables.drivenEquipment, design: null },
];

export const KeyChange = {
  [DesignDataVariables.dutyType]: "dutyType",
  [DesignDataVariables.numberOfPoles]: "numberOfPoles",
  [DesignDataVariables.voltage]: "voltage",
  [DesignDataVariables.frequency]: "frequency",
  [DesignDataVariables.serviceFactor]: "serviceFactor",
  [DesignDataVariables.insulationClass]: "insulationClass",
  [DesignDataVariables.temperatureClass]: "temperatureClass",
  [DesignDataVariables.nemaEnclosureType]: "nemaEnclosureType",
  [DesignDataVariables.enclosureIPCode]: "enclosureIPCode",
  [DesignDataVariables.icCode]: "icCode",
  [DesignDataVariables.imCode]: "imCode",
  [DesignDataVariables.speedType]: "speedType",
  [DesignDataVariables.speedSignalRatio]: "speedSignalRatio",
  [DesignDataVariables.bearingType]: "bearingType",
  [DesignDataVariables.bearingQuantity]: "bearingQuantity",
  [DesignDataVariables.equipmentFrameSize]: "equipmentFrameSize",
  [DesignDataVariables.drivenEquipment]: "drivenEquipment",
};

export const hoverText = {
  S1: "S1 (Continuous)",
  S2: "S2 (Short - time)",
  S3: "S3 (Intermittent periodic)",
  S4: "S4 (Intermittent periodic with starting)",
  S5: "S5 (Intermittent periodic with electric braking)",
  S6: "S6 (Continuous - operation periodic)",
  S7: "S7 (Continuous - operation periodic with electric braking)",
  S8: "S8 (Continuous - operation periodic with related load / speed changes)",
  S9: "S9 (Duty with non - periodic load and speed variations)",
  S10: "S10 (Duty with discrete constant loads and speeds)",
};

export const speedTypeTitle = {
  CONSTANT: "Constant Speed",
  "Varying Speed": "Varying Speed",
  "Adjustable Speed": "Adjustable Speed",
  Multispeed: "Multispeed",
};
