import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import {
  setToken,
  setUser,
  setRefreshToken,
} from '../../libs/token';
import { useHistory } from 'react-router';
import * as Routes from '../../constants/routes';



const Auth0Redirect = () => {
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } =useAuth0();
  const history = useHistory();

  function findItemsByRefreshToken() {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes('RefreshTokenKey')) {
        const value = localStorage.getItem(key);
        if (value) {
          const parsedValue = JSON.parse(value);
          if (parsedValue.body && parsedValue.body.refresh_token) {
            setRefreshToken(parsedValue.body.refresh_token);
            return parsedValue.body.refresh_token;
          }
        }
      }
    });
    return '';
  }

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      const user = await getIdTokenClaims()
      const bearerToken = 'Bearer ' + token
      setToken(bearerToken)
      setUser(user.email, user.nickname)
      findItemsByRefreshToken();
      history.push(Routes.app)
    };
    if (isAuthenticated && user){
      getToken()
    }
  }, [isAuthenticated, user]);// eslint-disable-line

  return <></>;
};

export default Auth0Redirect;
