export const GET_CALCULATED_DATA_PROGRESS = "GET_CALCULATED_DATA_PROGRESS";
export const GET_CALCULATED_DATA_SUCCESS = "GET_CALCULATED_DATA_SUCCESS";
export const GET_CALCULATED_DATA_ERROR = "GET_CALCULATED_DATA_ERROR";

export const ADD_CALCULATED_DATA_PROGRESS = "ADD_CALCULATED_DATA_PROGRESS";
export const ADD_CALCULATED_DATA_SUCCESS = "ADD_CALCULATED_DATA_SUCCESS";
export const ADD_CALCULATED_DATA_ERROR = "ADD_CALCULATED_DATA_ERROR";

export const GET_VARIABLE_NAME_DATA_PROGRESS =
  "GET_VARIABLE_NAME_DATA_PROGRESS";
export const GET_VARIABLE_NAME_DATA_SUCCESS = "GET_VARIABLE_NAME_DATA_SUCCESS";
export const GET_VARIABLE_NAME_DATA_ERROR = "GET_VARIABLE_NAME_DATA_ERROR";

export const CLEAR_STATE = "CLEAR_STATE";
