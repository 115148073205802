export const CLEAR_STATE = "CLEAR_STATE";

export const GET_EQUIPMENTS_SUCCESS = "GET_EQUIPMENTS_SUCCESS";
export const GET_EQUIPMENTS_PROGRESS = "GET_EQUIPMENTS_PROGRESS";
export const GET_EQUIPMENTS_ERROR = "GET_EQUIPMENTS_ERROR";

export const ADD_EQUIPMENTS_ERROR = "ADD_EQUIPMENTS_ERROR";
export const ADD_EQUIPMENTS_SUCCESS = "ADD_EQUIPMENTS_SUCCESS";
export const ADD_EQUIPMENTS_PROGRESS = "ADD_EQUIPMENTS_PROGRESS";

export const UPDATE_EQUIPMENTS_ERROR = "UPDATE_EQUIPMENTS_ERROR";
export const UPDATE_EQUIPMENTS_SUCCESS = "UPDATE_EQUIPMENTS_SUCCESS";
export const UPDATE_EQUIPMENTS_PROGRESS = "UPDATE_EQUIPMENTS_PROGRESS";
