import Type from "../Types/motor.type";

const motorConfigData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  componentId: "",
  /** Performace data */
  performanceData: {
    ratedPowerOutput: {},
    ratedCurrent: {},
    synchronousSpeed: {},
    fullLoadSpeed: {},
    fullLoadTorque: {},
    rotorGD2AtFLRPM: {},
    rotorWeight: {},
    lockedRotortorque: {},
    pullUpTorque: {},
    breakDownTorque: {},
    lockedRotorCurrent: {},
    lockedRotorPF: {},
    lockedRotorWithstandTimeCold: {},
    lockedRotorWithstandTimeRatedTemp: {},
    statorSlots: null,
    rotorSlots: null,
  },
  /** Equivalent Circuit Data */
  equivalentCircuitData: {
    /** Field data variable */
    dataVariable: {
      ratedkVA: {},
      subtransientReactance: {},
      totalWindingCapacitanceToGround: {},
    },
    /** Field Locked Rotor at rated voltage */
    lockedRotorAtRatedVoltage: {
      statorR: {},
      rotorR: {},
      magnR: {},
      statorX: {},
      rotorX: {},
      magnX: {},
    },
    /** Field rated load at rated voltage */
    ratedLoadAtRatedVoltage: {
      statorR: {},
      rotorR: {},
      magnR: {},
      statorX: {},
      rotorX: {},
      magnX: {},
    },

    /** Field residual voltage */
    residualVoltageOpenCircuitTimeConstant: {
      motorOnly: {},
      motorWithSurgeCapacitors: {},
      phasetoPhaseResistance: {},
      phasetoPhaseResistanceTemperature: {},
    },
  },
  /** Perforrmance curve */
  performanceCurvesData: {
    data: [],
    dataUnit: {},
  },
  /** Starting char curve */
  startingCharacteristicsCurvesData: {
    data: [],
    dataUnit: {},
  },
  variableSpeedCurvesData: {
    data: [],
    dataUnit: {},
    driveType: "",
    driveManufacturer: "",
    driveModel: "",
  },
  /** Design data */
  designData: {
    dutyType: "",
    numberOfPoles: "",
    voltage: { unit: "", design: "" },
    frequency: { unit: "", design: "" },
    serviceFactor: "",
    insulationClass: "",
    temperatureClass: "",
    nemaEnclosureType: "",
    enclosureIPCode: "",
    icCode: "",
    imCode: "",
    speedType: "",
    speedSignalRatio: "",
    bearingType: null,
    bearingQuantity: "",
    equipmentFrameSize: null,
    drivenEquipment: null,
  },
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addPerformaceData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addEquCktData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addPerformanceCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addStartingCharCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addVarSpeedCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addDriveTypes = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const getDriveType = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: [],
};

const INITIAL_STATE = {
  motorConfigData: { ...motorConfigData },
  addDesignData: { ...addDesignData },
  addPerformaceData: { ...addPerformaceData },
  addEquCktData: { ...addEquCktData },
  addPerformanceCurve: { ...addPerformanceCurve },
  addStartingCharCurve: { ...addStartingCharCurve },
  addVarSpeedCurve: { ...addVarSpeedCurve },
  addDriveTypes: { ...addDriveTypes },
  getDriveType: { ...getDriveType },
};

export default function MotorReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case Type.GET_MOTOR_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        motorConfigData: {
          ...INITIAL_STATE.motorConfigData,
          isLoading: true,
        },
      };
    case Type.GET_MOTOR_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        motorConfigData: {
          ...state.motorConfigData,
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          performanceData: action.payload.performanceData
            ? {
              ratedPowerOutput:
                  action.payload.performanceData.ratedPowerOutput ||
                  action.payload.performanceData.ratedPowerOutput === 0
                    ? action.payload.performanceData.ratedPowerOutput
                    : { unit: "", design: "" },
              ratedCurrent:
                  action.payload.performanceData.ratedCurrent ||
                  action.payload.performanceData.ratedCurrent === 0
                    ? action.payload.performanceData.ratedCurrent
                    : { unit: "", design: "" },
              synchronousSpeed: action.payload.performanceData
                .synchronousSpeed
                ? action.payload.performanceData.synchronousSpeed
                : { unit: "", design: "" },
              fullLoadSpeed: action.payload.performanceData.fullLoadSpeed
                ? action.payload.performanceData.fullLoadSpeed
                : { unit: "", design: "" },
              fullLoadTorque: action.payload.performanceData.fullLoadTorque
                ? action.payload.performanceData.fullLoadTorque
                : { unit: "", design: "" },
              rotorGD2AtFLRPM: action.payload.performanceData.rotorGD2AtFLRPM
                ? action.payload.performanceData.rotorGD2AtFLRPM
                : { unit: "", design: "" },
              rotorWeight: action.payload.performanceData.rotorWeight
                ? action.payload.performanceData.rotorWeight
                : { unit: "", design: "" },
              lockedRotortorque: action.payload.performanceData
                .lockedRotortorque
                ? action.payload.performanceData.lockedRotortorque
                : { unit: "", design: "" },
              pullUpTorque: action.payload.performanceData.pullUpTorque
                ? action.payload.performanceData.pullUpTorque
                : { unit: "", design: "" },
              breakDownTorque: action.payload.performanceData.breakDownTorque
                ? action.payload.performanceData.breakDownTorque
                : { unit: "", design: "" },
              lockedRotorCurrent: action.payload.performanceData
                .lockedRotorCurrent
                ? action.payload.performanceData.lockedRotorCurrent
                : { unit: "", design: "" },
              lockedRotorPF: action.payload.performanceData.lockedRotorPF
                ? action.payload.performanceData.lockedRotorPF
                : { unit: "", design: "" },
              lockedRotorWithstandTimeCold:
                  action.payload.performanceData.lockedRotorWithstandTimeCold ||
                  action.payload.performanceData
                    .lockedRotorWithstandTimeCold === 0
                    ? action.payload.performanceData
                      .lockedRotorWithstandTimeCold
                    : { unit: "", design: "" },
              lockedRotorWithstandTimeRatedTemp:
                  action.payload.performanceData
                    .lockedRotorWithstandTimeRatedTemp ||
                  action.payload.performanceData
                    .lockedRotorWithstandTimeRatedTemp === 0
                    ? action.payload.performanceData
                      .lockedRotorWithstandTimeRatedTemp
                    : { unit: "", design: "" },
              statorSlots:
                  action.payload.performanceData.statorSlots ||
                  action.payload.performanceData.statorSlots === 0
                    ? action.payload.performanceData.statorSlots
                    : "",
              rotorSlots:
                  action.payload.performanceData.rotorSlots ||
                  action.payload.performanceData.rotorSlots === 0
                    ? action.payload.performanceData.rotorSlots
                    : "",
            }
            : {
              ...INITIAL_STATE.motorConfigData.performanceData,
            },
          equivalentCircuitData: action.payload.equivalentCircuitData
            ? {
              /** Field data variable */
              dataVariable: action.payload.equivalentCircuitData.dataVariable
                ? {
                  ratedkVA: action.payload.equivalentCircuitData
                    .dataVariable.ratedkVA
                    ? action.payload.equivalentCircuitData.dataVariable
                      .ratedkVA
                    : {},
                  subtransientReactance: action.payload
                    .equivalentCircuitData.dataVariable
                    .subtransientReactance
                    ? action.payload.equivalentCircuitData.dataVariable
                      .subtransientReactance
                    : {},
                  totalWindingCapacitanceToGround: action.payload
                    .equivalentCircuitData.dataVariable
                    .totalWindingCapacitanceToGround
                    ? action.payload.equivalentCircuitData.dataVariable
                      .totalWindingCapacitanceToGround
                    : {},
                }
                : {
                  ...INITIAL_STATE.motorConfigData.equivalentCircuitData
                    .dataVariable,
                },
              /** Field Locked Rotor at rated voltage */
              lockedRotorAtRatedVoltage: action.payload.equivalentCircuitData
                .lockedRotorAtRatedVoltage
                ? {
                  statorR: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.statorR
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.statorR
                    : {},
                  rotorR: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.rotorR
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.rotorR
                    : {},
                  magnR: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.magnR
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.magnR
                    : {},
                  statorX: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.statorX
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.statorX
                    : {},
                  rotorX: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.rotorX
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.rotorX
                    : {},
                  magnX: action.payload.equivalentCircuitData
                    .lockedRotorAtRatedVoltage.magnX
                    ? action.payload.equivalentCircuitData
                      .lockedRotorAtRatedVoltage.magnX
                    : {},
                }
                : {
                  ...INITIAL_STATE.motorConfigData.equivalentCircuitData
                    .lockedRotorAtRatedVoltage,
                },
              /** Field rated load at rated voltage */
              ratedLoadAtRatedVoltage: action.payload.equivalentCircuitData
                .ratedLoadAtRatedVoltage
                ? {
                  statorR:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.statorR,
                  rotorR:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.rotorR,
                  magnR:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.magnR,
                  statorX:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.statorX,
                  rotorX:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.rotorX,
                  magnX:
                        action.payload.equivalentCircuitData
                          .ratedLoadAtRatedVoltage.magnX,
                }
                : {
                  ...INITIAL_STATE.motorConfigData.equivalentCircuitData
                    .ratedLoadAtRatedVoltage,
                },

              /** Field residual voltage */
              residualVoltageOpenCircuitTimeConstant: action.payload
                .equivalentCircuitData.residualVoltageOpenCircuitTimeConstant
                ? {
                  motorOnly:
                        action.payload.equivalentCircuitData
                          .residualVoltageOpenCircuitTimeConstant.motorOnly,
                  motorWithSurgeCapacitors:
                        action.payload.equivalentCircuitData
                          .residualVoltageOpenCircuitTimeConstant
                          .motorWithSurgeCapacitors,
                  phasetoPhaseResistance:
                        action.payload.equivalentCircuitData
                          .residualVoltageOpenCircuitTimeConstant
                          .phasetoPhaseResistance,
                  phasetoPhaseResistanceTemperature:
                        action.payload.equivalentCircuitData
                          .residualVoltageOpenCircuitTimeConstant
                          .phasetoPhaseResistanceTemperature,
                }
                : {
                  ...INITIAL_STATE.motorConfigData.equivalentCircuitData
                    .residualVoltageOpenCircuitTimeConstant,
                },
            }
            : {
              ...INITIAL_STATE.motorConfigData.equivalentCircuitData,
            },
          /** Perforrmance curve */
          performanceCurvesData: action.payload.performanceCurvesData
            ? {
              data: action.payload.performanceCurvesData.data
                ? action.payload.performanceCurvesData.data
                : {},
              dataUnit: action.payload.performanceCurvesData.dataUnit
                ? action.payload.performanceCurvesData.dataUnit
                : {},
            }
            : {
              ...INITIAL_STATE.motorConfigData.performanceCurvesData,
            },
          /** Starting char curve */
          startingCharacteristicsCurvesData: action.payload
            .startingCharacteristicsCurvesData
            ? {
              data: action.payload.startingCharacteristicsCurvesData.data
                ? action.payload.startingCharacteristicsCurvesData.data
                : {},
              dataUnit: action.payload.startingCharacteristicsCurvesData
                .dataUnit
                ? action.payload.startingCharacteristicsCurvesData.dataUnit
                : {},
            }
            : {
              ...INITIAL_STATE.motorConfigData
                .startingCharacteristicsCurvesData,
            },
          variableSpeedCurvesData: action.payload.variableSpeedCurvesData
            ? {
              data: action.payload.variableSpeedCurvesData.data
                ? action.payload.variableSpeedCurvesData.data
                : {},
              dataUnit: action.payload.variableSpeedCurvesData.dataUnit
                ? action.payload.variableSpeedCurvesData.dataUnit
                : {},
              driveType: action.payload.variableSpeedCurvesData.driveType
                ? action.payload.variableSpeedCurvesData.driveType
                : "",
              driveManufacturer: action.payload.variableSpeedCurvesData
                .driveManufacturer
                ? action.payload.variableSpeedCurvesData.driveManufacturer
                : "",
              driveModel: action.payload.variableSpeedCurvesData.driveModel
                ? action.payload.variableSpeedCurvesData.driveModel
                : "",
            }
            : {
              ...INITIAL_STATE.motorConfigData.variableSpeedCurvesData,
            },
          /** Design data */
          designData: action.payload.designData
            ? {
              dutyType:
                  action.payload.designData.dutyType ||
                  action.payload.designData.dutyType === 0
                    ? action.payload.designData.dutyType
                    : null,
              numberOfPoles:
                  action.payload.designData.numberOfPoles ||
                  action.payload.designData.numberOfPoles === 0
                    ? action.payload.designData.numberOfPoles
                    : null,
              voltage:
                  action.payload.designData.voltage ||
                  action.payload.designData.voltage === 0
                    ? action.payload.designData.voltage
                    : {},
              frequency:
                  action.payload.designData.frequency ||
                  action.payload.designData.frequency === 0
                    ? action.payload.designData.frequency
                    : {},
              serviceFactor:
                  action.payload.designData.serviceFactor ||
                  action.payload.designData.serviceFactor === 0
                    ? action.payload.designData.serviceFactor
                    : null,
              insulationClass:
                  action.payload.designData.insulationClass ||
                  action.payload.designData.insulationClass === 0
                    ? action.payload.designData.insulationClass
                    : null,
              temperatureClass:
                  action.payload.designData.temperatureClass ||
                  action.payload.designData.temperatureClass === 0
                    ? action.payload.designData.temperatureClass
                    : null,
              nemaEnclosureType:
                  action.payload.designData.nemaEnclosureType ||
                  action.payload.designData.nemaEnclosureType === 0
                    ? action.payload.designData.nemaEnclosureType
                    : null,
              enclosureIPCode:
                  action.payload.designData.enclosureIPCode ||
                  action.payload.designData.enclosureIPCode === 0
                    ? action.payload.designData.enclosureIPCode
                    : null,
              icCode:
                  action.payload.designData.icCode ||
                  action.payload.designData.icCode === 0
                    ? action.payload.designData.icCode
                    : null,
              imCode:
                  action.payload.designData.imCode ||
                  action.payload.designData.imCode === 0
                    ? action.payload.designData.imCode
                    : null,
              speedType:
                  action.payload.designData.speedType ||
                  action.payload.designData.speedType === 0
                    ? action.payload.designData.speedType
                    : null,
              speedSignalRatio:
                  action.payload.designData.speedSignalRatio ||
                  action.payload.designData.speedSignalRatio === 0
                    ? action.payload.designData.speedSignalRatio
                    : null,
              bearingType:
                  action.payload.designData.bearingType ||
                  action.payload.designData.bearingType === 0
                    ? action.payload.designData.bearingType
                    : null,
              bearingQuantity:
                  action.payload.designData.bearingQuantity ||
                  action.payload.designData.bearingQuantity === 0
                    ? action.payload.designData.bearingQuantity
                    : null,
              equipmentFrameSize:
                  action.payload.designData.equipmentFrameSize ||
                  action.payload.designData.equipmentFrameSize === 0
                    ? action.payload.designData.equipmentFrameSize
                    : null,
              drivenEquipment:
                  action.payload.designData.drivenEquipment ||
                  action.payload.designData.drivenEquipment === 0
                    ? action.payload.designData.drivenEquipment
                    : null,
            }
            : {
              ...INITIAL_STATE.motorConfigData.designData,
            },
        },
      };
    case Type.GET_MOTOR_CONFIG_DATA_FAILURE:
      return {
        ...state,
        motorConfigData: {
          ...state.motorConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_MOTOR_CONFIG_DATA_RESET:
      return {
        ...state,
        motorConfigData: {
          ...state.motorConfigData,
          isLoading: false,
          isError: false,
          errorCode: "",
          apiMsg: "",
          isSuccess: false,
          componentId: "",
        },
      };

    /** Add design data reducer */
    case Type.ADD_MOTOR_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.ADD_MOTOR_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_MOTOR_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_MOTOR_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    /** Add performance data */
    case Type.ADD_PERFORMANCE_DATA_PROGRESS:
      return {
        ...state,
        addPerformaceData: {
          ...INITIAL_STATE.addPerformaceData,
          isLoading: true,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        addPerformaceData: {
          ...state.addPerformaceData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        addPerformaceData: {
          ...state.addPerformaceData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_RESET:
      return {
        ...state,
        addPerformaceData: {
          ...INITIAL_STATE.addPerformaceData,
        },
      };

    /** Add equipment circuit data */
    case Type.ADD_EQUIVALENT_CIRCUIT_DATA_PROGRESS:
      return {
        ...state,
        addEquCktData: {
          ...INITIAL_STATE.addEquCktData,
          isLoading: true,
        },
      };
    case Type.ADD_EQUIVALENT_CIRCUIT_DATA_SUCCESS:
      return {
        ...state,
        addEquCktData: {
          ...state.addEquCktData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_EQUIVALENT_CIRCUIT_DATA_FAILURE:
      return {
        ...state,
        addEquCktData: {
          ...state.addEquCktData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_EQUIVALENT_CIRCUIT_DATA_RESET:
      return {
        ...state,
        addEquCktData: {
          ...INITIAL_STATE.addEquCktData,
        },
      };

    /** Add performace curve data  */
    case Type.ADD_PERFORMANCE_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
          isLoading: true,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_FAILURE:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_RESET:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
        },
      };

    /** Add starting char curve */
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
          isLoading: true,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_RESET:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
        },
      };

    /** Add variable speed curve */
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
          isLoading: true,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_FAILURE:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_RESET:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
        },
      };

    /** Save Drive Type */
    case Type.ADD_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        addDriveTypes: {
          ...INITIAL_STATE.addDriveTypes,
          isLoading: true,
        },
      };
    case Type.ADD_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Get Drive Type */
    case Type.GET_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        getDriveType: {
          ...INITIAL_STATE.getDriveType,
          isLoading: true,
        },
        addDriveTypes: {
          ...state.addDriveTypes,
          isSuccess: false,
          isError: false,
        },
      };
    case Type.GET_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: true,
          data: action.payload.items ? action.payload.items : [],
        },
      };
    case Type.GET_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Reset motor reducer */
    case Type.RESET_MOTOR_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
