import React from 'react';
import {Card, CardContent } from '@mui/material';
import Editor from './Editor';



const RenderComment = (props, stateData) => {
    const {data} = props
    const {alertComment, setAlertComment, alertInsights, setAlertInsights, selectedTab, setSelectedTab, userRole} = stateData
    const showAlertComments = userRole === 'MechademyAdmin' || userRole === 'MechademyFacilityManager' ? true : false
    const makeAlertInsightsReadOnly = data && data.mechademyStatus && data.mechademyStatus !== 'New' ? true : false
    
    const handleTabChange = (index) => {
        return setSelectedTab(index)
    }

    return (
      <div className="alert_comment_card">
        <div className="alert_commnet_button">
          {showAlertComments && (
            <button
              variant="contained"
              className={
                selectedTab === 0 ? 'selected_section' : 'unselected_section'
              }
              onClick={() => handleTabChange(0)}
              disabled={selectedTab === 0 ? true : false}
            >
              Alert Comments
            </button>
          )}

          <button
            variant="contained"
            className={
              selectedTab === 1 ? 'selected_section' : 'unselected_section'
            }
            onClick={() => handleTabChange(1)}
            disabled={selectedTab === 1 ? true : false}
          >
            Alert Insights
          </button>
        </div>
        <Card>
          <CardContent>
            {selectedTab === 0 ? (
              <div className="details_description_heading">Comments:</div>
            ) : (
              <div className="details_description_heading">Insights:</div>
            )}
            <Editor
              setAlertComment={setAlertComment}
              alertComment={alertComment}
              alertInsights={alertInsights}
              selectedTab={selectedTab}
              setAlertInsights={setAlertInsights}
              makeAlertInsightsReadOnly={makeAlertInsightsReadOnly}
              userRole = {userRole}
            />
          </CardContent>
        </Card>
      </div>
    );
}

export default RenderComment