import _ from 'lodash';
import moment from 'moment';
import { getLumpSumValue } from '../libs/valueFormatter';

const API_URL = process.env.REACT_APP_DEV_API;
const DB_NAME = `mechademy_${API_URL}`;
const DB_VERSION = 1;
export const DB_STORE_NAME = 'time-series';

const indexedDB =
  window.indexedDB ||
  window.webkitIndexedDB ||
  window.mozIndexedDB ||
  window.msIndexedDB;

let indexedDBService;
let request;
let store;
let tran;
let retriveData;

// TODO: for all browser window.indexedDB = window.indexedDB;

/**
 * @param {string} store_name
 * @param {string} mode either "readonly" or "readwrite"
 */
export function getObjectStore(store_name, mode) {
  // request = indexedDB.open(DB_NAME, DB_VERSION);
  const tx = indexedDBService.transaction(DB_STORE_NAME, mode);
  return tx.objectStore(DB_STORE_NAME);
}

export const requestDB = () => {
  if (indexedDB) {
    request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onupgradeneeded = function (event) {
      // Save the IDBDatabase interface
      indexedDBService = event;
      // Create an objectStore for this database
      store = indexedDBService.target.result.createObjectStore(DB_STORE_NAME, {
        keyPath: '_id',
      });
    };
    request.onerror = function (event) {
      // Do something with request.errorCode!
      console.error(`Indexed Database error: ${event}`);
    };

    request.onsuccess = function (event) {
      if (request.result.objectStoreNames === 'name') {
        request.onupgradeneeded = function (event) {
          // Save the IDBDatabase interface
          indexedDBService = event;
          // Create an objectStore for this database
          store = indexedDBService.target.result.createObjectStore(
            DB_STORE_NAME,
            { keyPath: '_id' }
          );
          retriveData = store.get('2020-03-02 12:52:57.788626');
          retriveData.onsuccess = function (event) {};
          retriveData.onerror = function (event) {
            console.error(`Indexed Database error: ${event}`);
          };
        };
      }
      // Do something with request.result!
      indexedDBService = request.result;
      tran = indexedDBService.transaction(DB_STORE_NAME, 'readwrite');
      tran.objectStore(DB_STORE_NAME);
    };
  } else {
    console.error('No Indexed Database available');
  }
};

/** Add ids into idb */
export function addDataIDB(
  kpiDataStore,
  store = getObjectStore(DB_STORE_NAME, 'readwrite'),
  storeName
) {
  /** read */
  // store = getObjectStore(DB_STORE_NAME, 'readwrite');
  kpiDataStore.forEach(async (kpiData) => {
    const cardType = kpiData.cardType && kpiData.cardType.cardType;
    if (cardType === 'Time series plot' || cardType === 'Alert Plot') {
      const isKPI = await store.get(kpiData._id);
      isKPI.onsuccess = async function (evt) {
        // append ids into IDB
        // also check weather id exist in IDB
        const piTags =
          kpiData &&
          kpiData.axis &&
          kpiData.axis.y &&
          _.cloneDeep(
            kpiData.axis.y.map((piTag) => ({
              value: piTag.value,
              variableName: piTag.variableName,
            }))
          );
        // store = getObjectStore(DB_STORE_NAME, "readwrite");
        const putResult = store.put({ _id: kpiData._id, piTags });
        putResult.onsuccess = function (event) {};
        putResult.onerror = function (event) {
          console.error(`Indexed Database error: ${event}`);
        };
      };
      isKPI.onerror = function (event) {
        console.error(`Indexed Database error: ${event}`);
      };
    }  else if (
      kpiData.cardType &&
      kpiData.cardType.cardType === 'Multiple X-Y Plot'
    ) {
      const isKPI = await store.get(kpiData._id);
      isKPI.onsuccess = async function (evt) {
        // append ids into IDB
        // also check weather id exist in IDB
        let piTags = [];
        if(kpiData &&
          kpiData.axis &&
          kpiData.axis.series.length > 0) {
            kpiData.axis.series.forEach((piTag) => {
              piTags.push({ value: piTag.x.value, variableName: piTag.x.variableName });
              piTags.push({ value: piTag.y.value, variableName: piTag.y.variableName });
            });
          }
        // store = getObjectStore(DB_STORE_NAME, 'readwrite');
        const putResult = store.put({ _id: kpiData._id, piTags });
        putResult.onsuccess = function (event) {};
        putResult.onerror = function (event) {
          console.error(`Indexed Database error: ${event}`);
        };
      };
      isKPI.onerror = function (event) {
        console.error(`Indexed Database error: ${event}`);
      };
    }
  });
}

/**
 * @param {IDBObjectStore=} store
 */
export function getDataIDB(id, LIMIT, timeStamp) {
  // if (typeof store === 'undefined')
  store = getObjectStore(DB_STORE_NAME, 'readonly');
  if(id)  {
  const req = store.get(id);
  return new Promise((resolve, reject) => {
    req.onsuccess = (evt) => {
      resolve(evt.target.result);
      return evt.target.result;
    };
    req.onerror = function (event) {
      console.error(`Indexed Database error: ${event}`);
    };
  });
  }
}

export function updateDataIDB(kpiData) {
  /** Deleting the data older than 15 weeks for trends and 1 week for kpi plots */
  if (kpiData.response.startTimestamp && kpiData.response.series) {
    // let date = kpiData.trends ? moment().subtract(15, "weeks").valueOf() : moment().subtract(7, "days").valueOf();
    // const date = moment().subtract(15, 'weeks').valueOf();
    kpiData.response.series = kpiData.response.series.map((piTag) => {
      let data = [];
      data = piTag.data;
      return {
        ...piTag,
        data,
      };
    });
  }

  store = getObjectStore(DB_STORE_NAME, 'readwrite');
  const putResult = store.put(kpiData);
  putResult.onsuccess = function (event) {};
  putResult.onerror = function (event) {
    console.error(`Indexed Database error: ${event}`);
  };
}

export function getBlob(key, store, success_callback) {
  const req = store.get(key);
  req.onsuccess = function (evt) {
    const value = evt.target.result;
    if (value) success_callback(value.blob);
  };
  req.onerror = function (event) {
    console.error(`Indexed Database error: ${event}`);
  };
}

export function addKpiDataIDB(timeStamp) {
  const store = getObjectStore(DB_STORE_NAME, 'readwrite');
  let req;
  try {
    req = store.add();
  } catch (e) {
    if (e.name === 'DataCloneError') throw e;
  }
  req.onsuccess = function (event) {};
  req.onerror = function (event) {
    console.error(`Indexed Database error: ${event}`);
  };
}

export function updateKpiDataIDB(kpiData, id) {
  if (typeof store === 'undefined')
    store = getObjectStore(DB_STORE_NAME, 'readonly');
  let req;
  req = store.openCursor();

  // logic for update
  return new Promise((resolve, reject) => {
    req.onsuccess = (evt) => {
      resolve(evt.target.result);
      return evt.target.result;
    };
    req.onerror = function (event) {
      console.error(`Indexed Database error: ${event}`);
    };
  });
}

/** Delete database as auth is incorrect */
export function deleteIDB() {
  const DBDeleteRequest = window.indexedDB.deleteDatabase(DB_NAME);
  DBDeleteRequest.onerror = function (event) {
    console.error(`Indexed Database error: ${event}`);
  };

  DBDeleteRequest.onsuccess = function (event) {};
}
/**
 *
 * @param {api response} gdata
 * @param {already series name} global
 * @param {TODO} timestampInterval
 * @param {*}
 */

export const destructurefn = (
  gdata,
  global,
  timestampInterval = 15000,
  previousDataToStore = false,
  nextDataToStore = false,
  returnNewData = false,
  sTime = 0,
  eTime = 0
) => {
  let startTimestamp = 0;
  let endTimestamp = 0;
  let tempGdata = {...gdata};
  let series = {};
  if (global && global.piTags) {
    const limit = global.piTags.length;
    for (let i = 0; i < limit; i++) {
      series = {
        ...series,
        [global.piTags[i].value]: {
          name: `${global.piTags[i].variableName} (${global.piTags[i].value})`,
          tag: global.piTags[i].value,
          data: [],
          tooltip: {
            valueSuffix: '',
          },
          yAxis: i,
          maxMinData: [],
          maxMinPercentile: []
        },
      };
    }
    if(gdata.result && gdata.result.length > 0) {
      const y = [];
      tempGdata.result.forEach((piTag) => {
        if(piTag.x[0])  {
          y.push(piTag.x[0]);
        }
        if(piTag.y[0])  {
          y.push(piTag.y[0]);
        }
        // y.push(piTag.y[0] || []);
      });
      gdata = { y };
    }
      gdata.y &&
        gdata.y.forEach((tag) => {
          let metaData = [];
          let metaPercentile = [];
          const array = _.map(tag.data, (d) => {
            d.epoc = new Date(d.timestamp).getTime();
            return d;
          });
          
          if(tag && tag?.meta)  {  
            tag.meta.forEach((element)=>{
              if (
                !_.isEmpty(element?.plantValue?.crest) &&
                !_.isEmpty(element?.plantValue?.trough) &&
                !_.isNull(element?.plantValue?.crest?.value) &&
                !_.isNull(element?.plantValue?.trough?.value)
              ) {
                metaData.push({
                  epoc: new Date(element.plantValue.crest.timestamp).getTime(),
                  value: element.plantValue.crest.value,
                });
                metaData.push({
                  epoc: new Date(element.plantValue.trough.timestamp).getTime(),
                  value: element.plantValue.trough.value,
                });
              }
              else if (
                !_.isEmpty(element?.rawValue?.crest) &&
                !_.isEmpty(element?.rawValue?.trough)
              ) {
                metaData.push({
                  epoc: new Date(element.rawValue.crest.timestamp).getTime(),
                  value: element.rawValue.crest.value,
                });
                metaData.push({
                  epoc: new Date(element.rawValue.trough.timestamp).getTime(),
                  value: element.rawValue.trough.value,
                });
              }
              if (
                !_.isEmpty(element?.plantValue?.maxpercentile) &&
                !_.isEmpty(element?.plantValue?.minpercentile)&&
                !_.isNull(element?.plantValue?.maxpercentile?.value) &&
                !_.isNull(element?.plantValue?.minpercentile?.value)
              ) {
                metaPercentile.push({
                  epoc: new Date(
                    element.plantValue.maxpercentile.timestamp
                  ).getTime(),
                  value: element.plantValue.maxpercentile.value,
                });
                metaPercentile.push({
                  epoc: new Date(
                    element.plantValue.minpercentile.timestamp
                  ).getTime(),
                  value: element.plantValue.minpercentile.value,
                });
              }
              else if (
                !_.isEmpty(element?.rawValue?.maxpercentile) &&
                !_.isEmpty(element?.rawValue?.minpercentile)
              ) {
                metaPercentile.push({
                  epoc: new Date(
                    element.rawValue.maxpercentile.timestamp
                  ).getTime(),
                  value: element.rawValue.maxpercentile.value,
                });
                metaPercentile.push({
                  epoc: new Date(
                    element.rawValue.minpercentile.timestamp
                  ).getTime(),
                  value: element.rawValue.minpercentile.value,
                });
              }
            });

          }
          const hashmap = _.keyBy(array, 'epoc');
          const metaDataHashmap = _.keyBy(metaData, 'epoc');
          const metaPercentileHashmap = _.keyBy(metaPercentile, 'epoc');
          for (const prop in hashmap) {
            const value = hashmap[prop].hasOwnProperty('plantValue')
              ? hashmap[prop].plantValue
              : hashmap[prop].predictedValue;
            hashmap[prop] = _.isFinite(parseFloat(value))
              ? +getLumpSumValue(value)
              : null;
          }
          for(const prop in metaDataHashmap)  {
            const value = metaDataHashmap[prop].value;
            metaDataHashmap[prop] = _.isFinite(parseFloat(value))
            ? +getLumpSumValue(value)
            : null;
          }
          for(const prop in metaPercentileHashmap)  {
            const value = metaPercentileHashmap[prop].value;
            metaPercentileHashmap[prop] = _.isFinite(parseFloat(value))
            ? +getLumpSumValue(value)
            : null;
          }
  
          const startTime = sTime;
          const endTime = eTime;
  
          const data = {};
          const metaDataMap = {};
          const metaPercentileMap = {};
          if (startTime && endTime) {
            startTimestamp = moment(startTime).valueOf();
            endTimestamp = moment(endTime).valueOf();

            for(const stamps in hashmap)
              data[stamps] = hashmap[stamps];
            for(const stamps in metaDataHashmap)
              metaDataMap[stamps] = metaDataHashmap[stamps];
            for(const stamps in metaPercentileHashmap)
              metaPercentileMap[stamps] = metaPercentileHashmap[stamps];

            const ordered = {};
            const metaDataOrdered = {};
            const metaPercentileOrdered = {};
            if (_.keys(data).length > 0) {
              const sortedData = _.keys(data).sort();
              sortedData.forEach((key) => {
                ordered[key] = data[key];
              });
            }
            if (_.keys(metaDataMap).length > 0) {
              const sortedData = _.keys(metaDataMap).sort();
              sortedData.forEach((key) => {
                metaDataOrdered[key] = metaDataMap[key];
              });
            }
            if (_.keys(metaPercentileMap).length > 0) {
              const sortedData = _.keys(metaPercentileMap).sort();
              sortedData.forEach((key) => {
                metaPercentileOrdered[key] = metaPercentileMap[key];
              });
            }
            series[tag._id].data = _.cloneDeep(_.toPairs(ordered));
            series[tag._id].data.forEach(
              (element) => (element[0] = parseInt(element[0]))
            );
            series[tag._id].maxMinData = _.cloneDeep(_.toPairs(metaDataOrdered));
            series[tag._id].maxMinData.forEach(
              (element) => (element[0] = parseInt(element[0]))
            );
            series[tag._id].maxMinPercentile = _.cloneDeep(_.toPairs(metaPercentileOrdered));
            series[tag._id].maxMinPercentile.forEach(
              (element) => (element[0] = parseInt(element[0]))
            );
            series[tag._id].tag = tag._id;
          }
          let plantUnit = tag.plantUnit
            ? tag.plantUnit
            : tag.data[0] && tag.data[0].plantUnit
            ? tag.data[0].plantUnit
            : '';
          
          plantUnit = plantUnit === 'None' ? '': plantUnit;
          series[tag._id].tooltip.valueSuffix = ` ${plantUnit}`;
        });

    // }
    /** Merge the remaining idb data with series */
    if (!returnNewData && global && global.response && global.response.series) {
      let idbData = [];
      idbData = global.response.series.filter((element, i) => {
        return !(series[element.tag] && series[element.tag].tag);
      });

      idbData.forEach((element) => {
        series[element.tag] = element;
      });
    }
  }
  const a =
    global.response &&
    global.response.startTimestamp &&
    startTimestamp >= global.response.startTimestamp
      ? global.response.startTimestamp
      : startTimestamp;
  const b =
    global.response &&
    global.response.endTimestamp &&
    endTimestamp < global.response.endTimestamp
      ? global.response.endTimestamp
      : endTimestamp;
  const startTime = startTimestamp
    ? a
    : global.response
    ? global.response.startTimestamp
    : 0;
  const endTime = endTimestamp
    ? b
    : global.response
    ? global.response.endTimestamp
    : 0;
  return {
    series: _.values(series),
    startTimestamp: startTime,
    endTimestamp: endTime,
    gData: tempGdata
  };
};

/** Delete Particular KPIDATA when delete from UI */
export function deleteParticularKPIDATA(kpidId) {
  store = getObjectStore(DB_STORE_NAME, 'readwrite');

  const req = store.get(kpidId);
  req.onsuccess = () => {
    store.delete(kpidId);
    store.onsuccess = function (event) {};
    store.onerror = function (event) {
      console.error('delete error', event);
    };
  };
}
