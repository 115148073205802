export const LOGIN_FETCH_SUCCESS = "LOGIN_FETCH_SUCCESS";
export const LOGIN_FETCH_PROGRESS = "LOGIN_FETCH_PROGRESS";
export const LOGIN_FETCH_ERROR = "LOGIN_FETCH_ERROR";
export const LOGIN_CLEAR_STATE = "LOGIN_CLEAR_STATE";

export const USER_ROLE_FETCH_SUCCESS = "USER_ROLE_FETCH_SUCCESS";
export const USER_ROLE_FETCH_PROGRESS = "USER_ROLE_FETCH_PROGRESS";
export const USER_ROLE_FETCH_ERROR = "USER_ROLE_FETCH_ERROR";
export const USER_ROLE_CLEAR_STATE = "USER_ROLE_CLEAR_STATE";

export const TOUR_TAKEN_PROGRESS = "TOUR_TAKEN_PROGRESS";
export const TOUR_TAKEN_SUCCESS = "TOUR_TAKEN_SUCCESS";
export const TOUR_TAKEN_ERROR = "TOUR_TAKEN_ERROR";
