import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import Loader from '../../../../common/Loader/Loader';
import {
  handleSort,
  validateNegativeNumber,
  validatePositiveFloat,
} from '../../../../common/methods';
import ConfirmModal from '../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  OutputDataMessages,
} from '../../../../constants/messages';
import {
  ButtonVariables,
  OutputDataVariables,
} from '../../../../constants/variables';
import {
  addOutputData,
  clearState,
  getOutputData,
  getVariableNames,
  getVariableProperty,
} from '../../../../redux/actions/outputData.actions';
import { addRowTable, outputTableColumns } from './OutputData.constant';
import './OutputData.scss';

class OutputData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirtyRows: [],
      zeroValue: false,
      nullValue: false,
      piTagError: false,
      formEdit: false,
      deleteErrors: null,
      variableData: [],
      preserveVariableData: [],
      variableUnit: {},
      variableName: [],
      nameData: [],
      preserveNameData: [],
      propertyData: [],
      unitData: [],
      outputData: [],
      editRows: [],
      existingOutputData: [],
      updatedFields: [],
      addFieldValues: {
        piTag: null,
        variableName: null,
        variableProperty: null,
        mechademyUnits: '',
        multiplier: '',
        adder: '0',
        dataType: null,
        isIQR: false,
        type: '',
        piVariableName: null,
        displayPiTag: '',
      },
      isError: {
        piTag: false,
        variableName: false,
        variableProperty: false,
        mechademyUnits: false,
        multiplier: false,
        adder: false,
        dataType: false,
        piVariableName: false,
      },
      addValueListener: false,
    };
  }

  componentDidMount() {
    const componentID = this.props.schematicState.schematicComponentData
      ? this.props.schematicState.schematicComponentData._id
      : this.props.location.state.componentData.info._id;

    this.getOutputData();

    if (this.handleNetwork()) {
      this.props.variableData();
      this.props.variableName(componentID);
    }

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
          addValueListener: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }

  getOutputData() {
    const componentID = this.props.schematicState.schematicComponentData
      ? this.props.schematicState.schematicComponentData._id
      : this.props.location.state.componentData.info._id;
    const equipmentID = this.props.location.state.equipmentData._id;

    if (this.handleNetwork()) {
      this.props.getOutputData(equipmentID, componentID);
    }
  }

  componentDidUpdate() {
    const {
      isVariableGetSuccess,
      variableData,
      isVariableNameGetSuccess,
      variableNameData,
      addedOutputData,
      isSuccessOutputDataFetch,
      isSuccessOutputDataAdded,
      isVariableGetError,
      isVariableNameGetError,
      isErrorOutputDataFetch,
      isErrorOutputDataAdded,
      outputData,
      errorCode,
    } = this.props.outputDataState.apiState;
    const { clearState } = this.props;

    if (isVariableGetSuccess) {
      clearState();
      this.setState(
        {
          propertyData:
            variableData && variableData.items ? variableData.items : [],
        },
        () => {
          this.setVariableData();
        }
      );
    }

    if (isVariableNameGetSuccess) {
      clearState();
      this.setState(
        {
          nameData: _.cloneDeep(variableNameData),
          preserveNameData: _.cloneDeep(variableNameData),
        },
        () => {
          this.filterUniqueDataVariableList();
        }
      );
    }

    if (isSuccessOutputDataFetch) {
      clearState();
      this.disableEditForm();
      this.setState({
        existingOutputData: outputData ? _.cloneDeep(outputData) : [],
      });
      this.setOutputData(this.transformDataRecieved(_.cloneDeep(outputData)));
    }

    if (isSuccessOutputDataAdded) {
      clearState();
      this.setState(
        {
          deleteErrors: addedOutputData.errors,
        },
        () => {
          if (_.isEmpty(this.state.deleteErrors)) {
            Notification.show(Types.Success, OutputDataMessages.ADDED);
          } else {
            Notification.show(Types.Error, this.state.deleteErrors[0]);
          }
          this.getOutputData();
        }
      );
      this.errors(addedOutputData.errors);
    }

    if (isVariableGetError) {
      clearState();
      Notification.show(Types.Error, OutputDataMessages[errorCode]);
    }

    if (isVariableNameGetError) {
      clearState();
      Notification.show(Types.Error, OutputDataMessages[errorCode]);
    }

    if (isErrorOutputDataFetch) {
      clearState();
      Notification.show(Types.Error, OutputDataMessages[errorCode]);
    }

    if (isErrorOutputDataAdded) {
      clearState();
      Notification.show(Types.Error, OutputDataMessages[errorCode]);
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  transformDataRecieved(data) {
    let outputArray = [];
    if (data && data.config) {
      outputArray = data.config.map((item) => {
        let {
          piTag,
          mechademyUnits,
          dataType,
          adder,
          multiplier,
          variableProperty,
          _id,
          isIQR,
          piVariableName,
        } = item.tag;

        if (adder || multiplier) {
          adder = adder && adder.toString();
          multiplier = multiplier && multiplier.toString();
        }
        if (adder === 0) {
          adder = adder.toString();
        }

        const { variableName } = item.abbreviation;
        const tag = _id;
        const abbreviation = item.abbreviation._id;
        const { tagInfo } = this.props.location.state.componentData.info;
        const displayPiTag = `${tagInfo}-${item.abbreviation.abbreviation}`;
        const equipmentId = this.props.location.state.equipmentData._id;
        return {
          piTag,
          mechademyUnits,
          variableName,
          dataType,
          adder,
          multiplier,
          variableProperty,
          abbreviation,
          tag,
          equipmentId,
          isIQR,
          piVariableName,
          displayPiTag,
        };
      });
    }
    return outputArray;
  }

  setOutputData(data) {
    this.setState(
      {
        outputData: [...data],
      },
      () => {
        this.filterUniqueDataVariableList();
      }
    );
  }

  setIQR = (value) => {
    const { adder, multiplier, isIQR } = this.state.addFieldValues;
    if (
      (!_.isFinite(parseFloat(adder)) || !parseFloat(multiplier)) &&
      isIQR === false
    ) {
      if (!_.isFinite(parseFloat(adder)) && !parseFloat(multiplier)) {
        Notification.show(Types.Info, OutputDataMessages.NULL_MIN_MAX);
      } else if (parseFloat(multiplier) === 0) {
        Notification.show(Types.Info, OutputDataMessages.ZERO_MESSAGE);
      } else {
        Notification.show(Types.Info, OutputDataMessages.NULL_MIN_MAX);
      }
    } else if (
      multiplier &&
      !validatePositiveFloat(multiplier) &&
      isIQR === false
    ) {
      Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
    } else if (adder && validateNegativeNumber(adder) && isIQR === false) {
      Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
    } else {
      this.setState({
        addFieldValues: {
          ...this.state.addFieldValues,
          isIQR: value,
        },
      });
    }
  };

  errors = (data) => {
    const errorMessages = data.map((item) => {
      const { message, tag } = item;

      return `${tag} : ${message}`;
    });

    for (let i = 0; i < errorMessages.length - 1; i++) {
      errorMessages[0] = `${errorMessages[i]}.\n${errorMessages[i + 1]}`;
    }
    this.setState(
      {
        deleteErrors: errorMessages,
      },
      () => {}
    );
  };

  filterUniqueDataVariableList() {
    const { preserveNameData, outputData } = this.state;
    const { tagNumber } = this.props.location.state.componentData.info;
    const { tagInfo } = this.props.location.state.componentData.info;
    const filteredNameData = preserveNameData.filter((item) => {
      const tempFilter = outputData.filter(
        (element) =>
          element.piTag === `${tagInfo}-${item.abbreviation}-${tagNumber}` ||
          element.variableName === item.variableName
      );
      if (tempFilter.length > 0) {
        return false;
      }
      return true;
    });
    this.setState(
      {
        nameData: _.cloneDeep(filteredNameData),
      },
      () => {
        this.setVariableName();
        this.setVariableData();
      }
    );
  }

  getPiTag(data) {
    const { nameData } = this.state;
    const { tagNumber } = this.props.location.state.componentData.info;
    const { tagInfo } = this.props.location.state.componentData.info;
    const selectedNameData = nameData.map((items) => {
      if (items.type === 'gas') {
        return items.variableName === data
          ? `OP-${tagNumber}-${items.abbreviation}`
          : '0';
      }
      return items.variableName === data
        ? `${tagInfo}-${items.abbreviation}-${tagNumber}`
        : '0';
    });
    const item = selectedNameData.find((element) => element !== '0');
    return item;
  }

  getType(data) {
    const { nameData } = this.state;
    const SelectedType = nameData.map((items) =>
      items.variableName === data ? items.type : ''
    );
    const item = SelectedType.find((element) => element !== '');
    return item;
  }

  getAbbreviation(data) {
    const { nameData } = this.state;
    const selectedAbbreviation = nameData.map((items) =>
      items.variableName === data ? items : ''
    );
    const item = selectedAbbreviation.find((element) => element !== '');
    return item.abbreviation;
  }

  getVariableUnit = (value) => {
    const { propertyData } = this.state;
    let selectedUnit = [];
    propertyData.forEach((items) => {
      if (items.property === value) {
        selectedUnit = _.cloneDeep(items.unit);
      }
    });
    return selectedUnit;
  };

  onSelectVariableProperty = (value) => {
    this.setState(
      {
        addFieldValues: {
          ...this.state.addFieldValues,
          variableProperty: value,
          mechademyUnits: '',
        },
        unitData: this.getVariableUnit(value),
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  onSelectVariableName = (value) => {
    const piTag = this.getPiTag(value);
    const type = this.getType(value);
    const abbreviation = this.getAbbreviation(value);
    const { tagInfo } = this.props.location.state.componentData.info;
    const displayPiTag = `${tagInfo}-${abbreviation}`;
    this.setState(
      {
        addFieldValues: {
          ...this.state.addFieldValues,
          variableName: value,
          piTag,
          type,
          displayPiTag,
        },
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  setVariableData = (key = null) => {
    const { propertyData } = this.state;
    const filteredVariableData = [];
    propertyData.forEach((item) => {
      if (!key) {
        filteredVariableData.push(item.property);
      } else if (item.property.toLowerCase().indexOf(key.toLowerCase()) > -1) {
        filteredVariableData.push(item.property);
      }
    });
    this.setState({
      variableData: filteredVariableData,
      addFieldValues: {
        ...this.state.addFieldValues,
        variableProperty: key,
        mechademyUnits: '',
      },
      unitData: [],
    });
  };

  setPiVariableName = (key = null) => {
    this.setState({
      addFieldValues: {
        ...this.state.addFieldValues,
        piVariableName: key.target.value,
      },
    });
  };

  setVariableName = (key = null) => {
    const { nameData } = this.state;
    const filteredVariableName = [];
    let piTagValue = null;
    nameData.forEach((items) => {
      if (!key) {
        filteredVariableName.push(items.variableName);
      } else if (
        items.variableName.toLowerCase().indexOf(key.toLowerCase()) > -1
      ) {
        filteredVariableName.push(items.variableName);
        piTagValue = this.getPiTag(key);
      }
    });
    this.setState({
      variableName: filteredVariableName,
      addFieldValues: {
        ...this.state.addFieldValues,
        variableName: key,
        piTag: piTagValue,
      },
    });
  };

  validateEditRow(row) {
    const { outputData, dirtyRows } = this.state;
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let status = false;
    let zeroValue = false;
    const nullValue = false;
    let dirtyFields = false;

    Object.keys(outputData[row]).forEach((item) => {
      if (
        (outputData[row].dataType === OutputDataVariables.BOOLEAN ||
          outputData[row].dataType === OutputDataVariables.STRING) &&
        (item === OutputDataVariables.MAX_MULTIPLIER ||
          item === OutputDataVariables.MIN_MULTIPLIER)
      ) {
        outputData[row][OutputDataVariables.MIN_MULTIPLIER] = '';
        outputData[row][OutputDataVariables.MAX_MULTIPLIER] = '0';
        outputData[row].isIQR = false;
      } else if (
        item === OutputDataVariables.IQR ||
        item === OutputDataVariables.MAX_MULTIPLIER ||
        item === OutputDataVariables.MIN_MULTIPLIER
      ) {
        if (outputData[row][OutputDataVariables.IQR] === true) {
          if (
            outputData[row][OutputDataVariables.MAX_MULTIPLIER] ||
            outputData[row][OutputDataVariables.MIN_MULTIPLIER]
          ) {
            if (
              outputData[row][OutputDataVariables.MAX_MULTIPLIER] &&
              validateNegativeNumber(
                outputData[row][OutputDataVariables.MAX_MULTIPLIER]
              )
            ) {
              dirtyFields = true;
              status = true;
            } else if (!outputData[row][OutputDataVariables.MAX_MULTIPLIER]) {
              dirtyFields = true;
              status = true;
            }

            if (
              outputData[row][OutputDataVariables.MIN_MULTIPLIER] &&
              !validatePositiveFloat(
                outputData[row][OutputDataVariables.MIN_MULTIPLIER]
              )
            ) {
              dirtyFields = true;
              status = true;
            } else if (!outputData[row][OutputDataVariables.MIN_MULTIPLIER]) {
              dirtyFields = true;
              status = true;
            }
          } else {
            dirtyFields = true;
            status = true;
          }
        } else {
          if (
            parseFloat(outputData[row][OutputDataVariables.MIN_MULTIPLIER]) ===
            0
          ) {
            zeroValue = true;
            status = true;
          }
          if (
            outputData[row][OutputDataVariables.MAX_MULTIPLIER] &&
            validateNegativeNumber(
              outputData[row][OutputDataVariables.MAX_MULTIPLIER]
            )
          ) {
            dirtyFields = true;
            status = true;
          }

          if (
            outputData[row][OutputDataVariables.MIN_MULTIPLIER] &&
            !validatePositiveFloat(
              outputData[row][OutputDataVariables.MIN_MULTIPLIER]
            )
          ) {
            dirtyFields = true;
            status = true;
          }
        }
      } else if (!outputData[row][item] || !outputData[row][item].length) {
        if (
          !outputData[row].mechademyUnits &&
          outputData[row].variableProperty === 'Dimensionless'
        ) {
          status = false;
        } else {
          status = true;
        }
      }

      if (
        outputData[row].dataType === OutputDataVariables.BOOLEAN ||
        outputData[row].dataType === OutputDataVariables.STRING
      ) {
        status = false;
      }
    });

    if (status) {
      tempDirtyRows.push(row);
    } else {
      tempDirtyRows = tempDirtyRows.filter((item) => item !== row);
    }
    this.setState({
      dirtyRows: tempDirtyRows,
      zeroValue,
      nullValue,
      dirtyFields,
    });
  }

  onEditRowChange = (field, row) => {
    const { outputData } = this.state;
    const tempOutputData = _.cloneDeep(outputData);
    if (!tempOutputData[row]) {
      tempOutputData[row] = {};
    }

    if (field.type === 'checked') {
      if (
        (!_.isFinite(parseFloat(tempOutputData[row].adder)) ||
          !tempOutputData[row].multiplier) &&
        tempOutputData[row].isIQR === false
      ) {
        if (
          !_.isFinite(parseFloat(tempOutputData[row].adder)) &&
          !tempOutputData[row].multiplier
        )
          Notification.show(Types.Info, OutputDataMessages.NULL_MIN_MAX);
        else Notification.show(Types.Info, OutputDataMessages.NULL_MIN_MAX);
      } else if (
        tempOutputData[row].multiplier &&
        !validatePositiveFloat(tempOutputData[row].multiplier) &&
        tempOutputData[row].isIQR === false
      ) {
        if (parseFloat(tempOutputData[row].multiplier) === 0) {
          Notification.show(Types.Info, OutputDataMessages.ZERO_MESSAGE);
        } else {
          Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
        }
      } else if (
        tempOutputData[row].adder &&
        validateNegativeNumber(tempOutputData[row].adder) &&
        tempOutputData[row].isIQR === false
      ) {
        Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
      } else {
        tempOutputData[row][field.name] = field.value;
      }
    } else {
      tempOutputData[row][field.name] = field.value;
    }
    this.setState(
      {
        outputData: tempOutputData,
      },
      () => {
        this.validateEditRow(row);
      }
    );
  };

  validateFields(values) {
    let zeroValue = false;
    const nullValue = false;
    const piTagError = false;
    let dirtyFields = false;
    let status = true;
    const temp = {
      piTag: false,
      variableName: false,
      variableProperty: false,
      mechademyUnits: false,
      multiplier: false,
      adder: false,
      dataType: false,
      piVariableName: false,
    };

    Object.keys(values).forEach((item) => {
      if (
        !values.piVariableName ||
        (values.piVariableName && !values.piVariableName.trim())
      ) {
        temp.piVariableName = true;
        status = false;
      }

      if (
        values.dataType === OutputDataVariables.BOOLEAN ||
        values.dataType === OutputDataVariables.STRING
      ) {
        if (
          item === OutputDataVariables.IQR ||
          item === OutputDataVariables.MAX_MULTIPLIER ||
          item === OutputDataVariables.MIN_MULTIPLIER
        ) {
        } else if (!values[item] || values[item].length === 0) {
          if (
            values.variableProperty === 'Dimensionless' &&
            !values.mechademyUnits
          ) {
            temp.mechademyUnits = false;
            status = true;
          } else {
            temp[item] = true;
            status = false;
          }
        }
      } else if (
        item === OutputDataVariables.IQR ||
        item === OutputDataVariables.MAX_MULTIPLIER ||
        item === OutputDataVariables.MIN_MULTIPLIER
      ) {
        if (values[OutputDataVariables.IQR] === true) {
          if (
            values[OutputDataVariables.MAX_MULTIPLIER] ||
            values[OutputDataVariables.MIN_MULTIPLIER]
          ) {
            if (
              parseFloat(values[OutputDataVariables.MIN_MULTIPLIER]) === 0 &&
              values.variableProperty &&
              values.piVariableName &&
              values.variableName &&
              values.mechademyUnits &&
              values.dataType
            ) {
              zeroValue = true;
              status = false;
            }

            if (
              item === OutputDataVariables.MAX_MULTIPLIER &&
              values[OutputDataVariables.MAX_MULTIPLIER] &&
              validateNegativeNumber(values[OutputDataVariables.MAX_MULTIPLIER])
            ) {
              dirtyFields = true;
              status = false;
            } else if (!values[OutputDataVariables.MAX_MULTIPLIER]) {
              dirtyFields = true;
              status = false;
            }
            if (
              item === OutputDataVariables.MIN_MULTIPLIER &&
              values[OutputDataVariables.MIN_MULTIPLIER] &&
              !validatePositiveFloat(values[OutputDataVariables.MIN_MULTIPLIER])
            ) {
              dirtyFields = true;
              status = false;
            } else if (
              !values[OutputDataVariables.MIN_MULTIPLIER] ||
              (values[OutputDataVariables.MIN_MULTIPLIER] &&
                !values[OutputDataVariables.MIN_MULTIPLIER].trim())
            ) {
              dirtyFields = true;
              status = false;
            } else if (
              item === OutputDataVariables.MIN_MULTIPLIER &&
              values[OutputDataVariables.MIN_MULTIPLIER] &&
              !validatePositiveFloat(values[OutputDataVariables.MIN_MULTIPLIER])
            ) {
              dirtyFields = true;
              status = false;
            }
          } else {
            temp[item] = true;
            dirtyFields = true;
            status = false;
          }
        } else {
          if (
            values[OutputDataVariables.MIN_MULTIPLIER] &&
            values[OutputDataVariables.MIN_MULTIPLIER] &&
            !validatePositiveFloat(values[OutputDataVariables.MIN_MULTIPLIER])
          ) {
            if (parseFloat(values[OutputDataVariables.MIN_MULTIPLIER]) === 0) {
              zeroValue = true;
              status = false;
            } else {
              dirtyFields = true;
              status = false;
            }
          }

          if (
            values[OutputDataVariables.MAX_MULTIPLIER] &&
            validateNegativeNumber(values[OutputDataVariables.MAX_MULTIPLIER])
          ) {
            dirtyFields = true;
            status = false;
          }
        }
      } else if (!values[item] || values[item].length === 0) {
        if (
          values.variableProperty === 'Dimensionless' &&
          !values.mechademyUnits
        ) {
          temp.mechademyUnits = false;
          status = true;
        } else {
          temp[item] = true;
          status = false;
        }
      } else {
        temp[item] = false;
      }
    });

    this.setState({
      isError: temp,
      zeroValue,
      nullValue,
      piTagError,
      dirtyFields,
    });

    return status;
  }

  onAddRowChange = (field, row) => {
    const { addFieldValues } = this.state;
    const temp = { ...addFieldValues };

    temp[field.name] = field.value;

    this.setState(
      {
        addFieldValues: temp,
      },
      () => {
        this.enableEditForm();
        this.validateFields(this.state.addFieldValues);
      }
    );
  };

  onAddRow = () => {
    this.setState({
      addValueListener: true,
    });
    const data = _.cloneDeep(this.state.outputData);
    const {
      addFieldValues,
      zeroValue,
      nullValue,
      piTagError,
      dirtyFields,
    } = this.state;

    const tempAddFieldValue = { ...addFieldValues };

    if (this.validateFields(addFieldValues)) {
      const { tagNumber } = this.props.location.state.componentData.info;
      const { tagInfo } = this.props.location.state.componentData.info;
      tempAddFieldValue.abbreviation = this.state.nameData.filter((item) => {
        if (addFieldValues.type !== 'gas') {
          return (
            `${tagInfo}-${item.abbreviation}-${tagNumber}` ===
            addFieldValues.piTag
          );
        }
        return `OP-${tagNumber}-${item.abbreviation}` === addFieldValues.piTag;
      })[0]._id;
      tempAddFieldValue.equipmentId = this.props.location.state.equipmentData._id;

      data.push({
        ...tempAddFieldValue,
        multiplier: tempAddFieldValue.multiplier.trim(),
      });
      this.setState(
        {
          outputData: _.cloneDeep(data),
          piTag: '',
          enableIQR: false,
          addFieldValues: {
            ...this.state.addFieldValues,
            piTag: null,
            variableName: null,
            variableProperty: null,
            mechademyUnits: '',
            multiplier: '',
            adder: '0',
            dataType: '',
            piVariableName: null,
            isIQR: false,
            displayPiTag: '',
          },
        },
        () => {
          this.filterUniqueDataVariableList();
          this.enableEditForm();
          this.setState({
            addValueListener: false,
          });
        }
      );
    } else if (zeroValue) {
      Notification.show(Types.Info, OutputDataMessages.ZERO_MESSAGE);
    } else if (nullValue) {
      Notification.show(Types.Error, OutputDataMessages.MULTIPLIERS_REQUIRED);
    } else if (piTagError) {
      Notification.show(Types.Error, OutputDataMessages.PITAG_INFO);
      this.setState({ piTagError: false });
    } else if (dirtyFields) {
      Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
    } else {
      this.setEditRows();
      Notification.show(Types.Error, OutputDataMessages.FIELDS_REQUIRED);
    }
  };

  setEditRows() {
    const { updatedFields, outputData } = this.state;
    const tempEditRows = [];
    const tempUpdatedField = [];
    updatedFields.forEach((field, key) => {
      field &&
        outputData.forEach((data, index) => {
          if (field.piTag === data.piTag) {
            tempEditRows.push(index);
            tempUpdatedField[index] = updatedFields[key];
          }
        });
    });
    this.setState({
      editRows: tempEditRows,
      updatedFields: tempUpdatedField,
    });
  }

  edit = (row) => {
    const { updatedFields, outputData } = this.state;
    const tempOutput = _.cloneDeep(outputData);
    const updatedTemp = _.cloneDeep(updatedFields);
    updatedTemp[row] = tempOutput[row];
    this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.setEditRows();
        this.enableEditForm();
      }
    );
  };

  undo = (row) => {
    const { updatedFields, outputData, dirtyRows } = this.state;
    const updatedTemp = _.cloneDeep(outputData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempUpdatedField = _.cloneDeep(updatedFields);
    updatedTemp[row] = _.cloneDeep(tempUpdatedField[row]);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempUpdatedField[row] = undefined;
    this.setState(
      {
        outputData: _.cloneDeep(updatedTemp),
        updatedFields: tempUpdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.setEditRows();
      }
    );
  };

  deleteRow = (row) => {
    const { outputData, updatedFields, dirtyRows } = this.state;
    const tempOutputData = _.cloneDeep(outputData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempUpdatedField = _.cloneDeep(updatedFields);
    tempOutputData.splice(row, 1);
    tempUpdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((item) => item !== row);
    this.setState(
      {
        outputData: tempOutputData,
        updatedFields: tempUpdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.enableEditForm();
        this.setEditRows();
        this.filterUniqueDataVariableList();
      }
    );
  };

  transformPayloadData(data) {
    data = data.filter((items) => {
      delete items.displayPiTag;
      return items;
    });
    const addedData = data.filter((item) => {
      if (!item.tag) {
        return true;
      }
      return false;
    });
    const editedData = data.filter((item, index) =>
      this.state.editRows.includes(index)
    );

    const tempData = _.unionBy(addedData, editedData);

    const finalAddedData = tempData
      ? tempData.map((item) => {
          item.facilityId = this.props.location.state.facilityData._id;

          delete item.tag;
          delete item.type;
          item.multiplier = parseFloat(
            item[OutputDataVariables.MIN_MULTIPLIER]
          );
          item.adder = parseFloat(item[OutputDataVariables.MAX_MULTIPLIER]);

          if (item.mechademyUnits === '') {
            delete item.mechademyUnits;
          }

          return item;
        })
      : [];
    const newAndUpdateConfig = [...finalAddedData];

    const oldData = data.filter((item) => {
      if (item.tag) {
        return true;
      }
      return false;
    });

    const payload = !_.isEmpty(oldData)
      ? oldData.map((item) => {
          const { tag, abbreviation } = item;
          return { tag, abbreviation };
        })
      : [];

    const final = {};
    if (newAndUpdateConfig && newAndUpdateConfig.length) {
      final.newAndUpdateConfig = _.cloneDeep(newAndUpdateConfig);
    }
    if (payload && payload.length) {
      final.existingConfig = _.cloneDeep(payload);
    }
    return final;
  }

  save = () => {
    const {
      location: { state },
    } = this.props;
    const { zeroValue, nullValue, dirtyFields } = this.state;
    const transFormededData = this.transformPayloadData(
      _.cloneDeep(this.state.outputData)
    );
    const componentID = state.componentData.info._id;
    const equipmentID = state.equipmentData._id;

    if (!_.isEmpty(transFormededData)) {
      if (this.state.dirtyRows.length === 0) {
        if (this.handleNetwork()) {
          this.props.addOutputData(transFormededData, equipmentID, componentID);
        }
      } else if (zeroValue) {
        Notification.show(Types.Info, OutputDataMessages.ZERO_MESSAGE);
      } else if (nullValue) {
        Notification.show(Types.Error, OutputDataMessages.MULTIPLIERS_REQUIRED);
      } else if (dirtyFields) {
        Notification.show(Types.Error, OutputDataMessages.INVALID_INPUT);
      } else {
        Notification.show(Types.Error, OutputDataMessages.FIELDS_REQUIRED);
      }
    } else {
      Notification.show(Types.Info, OutputDataMessages.MIN_DATA);
    }
  };

  clearDirtyForm() {
    const { existingOutputData } = this.state;
    this.setState(
      {
        editRows: [],
        updatedFields: [],
        addFieldValues: {
          piTag: null,
          variableName: null,
          variableProperty: null,
          mechademyUnits: '',
          multiplier: ' ',
          adder: '0',
          dataType: '',
          piVariableName: null,
          isIQR: false,
        },
        isError: {
          piTag: false,
          variableName: false,
          variableProperty: false,
          mechademyUnits: false,
          multiplier: false,
          adder: false,
          dataType: false,
          piVariableName: false,
        },
        addEventListener: false,
      },
      () => {
        this.setOutputData(
          this.transformDataRecieved(_.cloneDeep(existingOutputData))
        );
      }
    );
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  selectUnit = (value) => {
    this.setState(
      {
        addFieldValues: {
          ...this.state.addFieldValues,
          mechademyUnits: value,
        },
      },
      () => {
        this.enableEditForm();
        this.validateFields(this.state.addFieldValues);
      }
    );
  };

  selectDataType = (value) => {
    this.setState(
      {
        addFieldValues: {
          ...this.state.addFieldValues,
          dataType: value,
        },
      },
      () => {
        this.enableEditForm();
        this.validateFields(this.state.addFieldValues);
      }
    );
  };

  selectTableUnit = (value, row) => {
    const tempOutputData = _.cloneDeep(this.state.outputData);
    tempOutputData[row].mechademyUnits = value;
    this.setState(
      {
        outputData: tempOutputData,
      },
      () => {
        this.validateEditRow(row);
      }
    );
  };

  selectTableDataType = (value, row) => {
    const tempOutputData = _.cloneDeep(this.state.outputData);
    if (value === 'Integer' || value === 'Float') {
      if (!tempOutputData[row].adder && tempOutputData[row].adder !== '0') {
        tempOutputData[row].adder = '0';
        tempOutputData[row].dataType = value;
      } else {
        tempOutputData[row].dataType = value;
      }
    } else {
      tempOutputData[row].adder = '0';
      tempOutputData[row].multiplier = '';
      tempOutputData[row].dataType = value;
    }
    this.setState(
      {
        outputData: tempOutputData,
      },
      () => {
        this.validateEditRow(row);
      }
    );
  };

  render() {
    const { loading } = this.props.outputDataState;
    return (
      <div className="OutputData">
        {loading ? <Loader /> : null}
        <CustomTable
          showHeader
          columns={outputTableColumns({
            isOutputEditable: this.props.isSchematicEditable,
            getVariableUnit: this.getVariableUnit,
            selectVariableProperty: this.onSelectVariableProperty,
            selectVariableName: this.onSelectVariableName,
            setVariableData: this.setVariableData,
            setVariableName: this.setVariableName,
            selectUnit: this.selectTableUnit,
            selectDataType: this.selectTableDataType,
            change: this.onEditRowChange,
            delete: this.deleteRow,
            edit: this.edit,
            undo: this.undo,
            variableData: handleSort(this.state.variableData),
            variableUnit: this.state.variableUnit,
            variableName: handleSort(this.state.variableName),
            piVariableName: this.setPiVariableName,
            units: this.state.unitData,
          })}
          editableRow={this.state.editRows}
          data={this.state.outputData}
          renderAddRow={
            this.props.isSchematicEditable
              ? addRowTable({
                  selectVariableProperty: this.onSelectVariableProperty,
                  selectVariableName: this.onSelectVariableName,
                  setVariableData: this.setVariableData,
                  setVariableName: this.setVariableName,
                  selectUnit: this.selectUnit,
                  selectDataType: this.selectDataType,
                  change: this.onAddRowChange,
                  submit: this.onAddRow,
                  variableData: handleSort(this.state.variableData),
                  variableUnit: this.state.variableUnit,
                  variableName: handleSort(this.state.variableName),
                  piVariableName: this.setPiVariableName,
                  units: this.state.unitData,
                  error: this.state.isError,
                  enableIQR: this.state.enableIQR,
                  setIQR: this.setIQR,
                  addValueListener: this.state.addValueListener,
                })
              : null
          }
          addFieldValues={this.state.addFieldValues}
        />
        {this.state.formEdit && this.props.isSchematicEditable && (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  variableData: () => {
    dispatch(getVariableProperty());
  },
  clearState: () => {
    dispatch(clearState());
  },
  variableName: (id) => {
    dispatch(getVariableNames(id));
  },
  getOutputData: (equipmentID, componentID) => {
    dispatch(getOutputData(equipmentID, componentID));
  },
  addOutputData: (payload, equipmentID, componentID) => {
    dispatch(addOutputData(payload, equipmentID, componentID));
  },
});
const mapStateToProps = (state) => ({
  outputDataState: state.outputDataReducer,
  schematicState: state.SchematicReducer,
  network: state.NetworkReducer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OutputData));
