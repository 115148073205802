import Type from '../Types/synchronousGenerator.types';
import { endPoints } from '../../libs/endpoints';
import { GETRequest, PUTRequest, POSTRequest } from '../../api/request';

export function fetchSynchronousGeneratorConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_PROGRESS });
    GETRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorDesign}`
    )
      .then((res) =>
        dispatch({
          type: Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function fetchMotorConfigDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.GET_GENERATOR_CONFIG_DATA_RESET });
  };
}

export function saveDesignData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorDesign}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function designDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_RESET });
  };
}

export function savePerformanceData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorPerformanceData}`,
      payload
    )
      .then((res) =>
        dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_DATA_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_DATA_FAILURE, payload: err })
      );
  };
}

export function savePerformanceDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_DATA_RESET });
  };
}

export function saveMotorParameterData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PARAMETER_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorMotorParameterData}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GENERATOR_PARAMETER_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_GENERATOR_PARAMETER_DATA_FAILURE, payload: err })
      );
  };
}

export function saveMotorParameterDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PARAMETER_DATA_RESET });
  };
}

export function savePerformaceCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorPerformanceCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function savePerformaceCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_RESET });
  };
}

export function saveStartCharCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorStartingCharCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveStartCharCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_RESET });
  };
}

export function saveVarSpeedCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorVarSpeedCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveVarSpeedCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_RESET });
  };
}

export function clearMotorReducer() {
  return function (dispatch) {
    dispatch({ type: Type.RESET_GENERATOR_REDUCER });
  };
}

export function saveDrivetypes(payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_DRIVE_TYPE_PROGRESS });
    POSTRequest(`${endPoints.drivetypes}`, payload)
      .then((res) =>
        dispatch({ type: Type.ADD_DRIVE_TYPE_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_DRIVE_TYPE_FAILURE, payload: err })
      );
  };
}

export function getDrivetypes() {
  return function (dispatch) {
    dispatch({ type: Type.GET_DRIVE_TYPE_PROGRESS });
    POSTRequest(`${endPoints.drivetypes}/${endPoints.filter}`)
      .then((res) =>
        dispatch({ type: Type.GET_DRIVE_TYPE_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_DRIVE_TYPE_FAILURE, payload: err })
      );
  };
}

export function saveCapabilityCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.synchronousGeneratorCapabilityCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}