export default {
  ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_PROGRESS:
    'ADD_GENERATOR_DESIGN_DATA_PROGRESS',
  ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_SUCCESS:
    'ADD_GENERATOR_DESIGN_DATA_SUCCESS',
  ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_FAILURE:
    'ADD_GENERATOR_DESIGN_DATA_FAILURE',
  ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_RESET:
    'ADD_GENERATOR_DESIGN_DATA_RESET',

  GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_PROGRESS:
    'GET_GENERATOR_CONFIG_DATA_PROGRESS',
  GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_SUCCESS:
    'GET_GENERATOR_CONFIG_DATA_SUCCESS',
  GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_FAILURE:
    'GET_GENERATOR_CONFIG_DATA_FAILURE',
  GET_GENERATOR_CONFIG_DATA_RESET: 'GET_GENERATOR_CONFIG_DATA_RESET',

  ADD_GENERATOR_PERFORMANCE_DATA_PROGRESS:
    'ADD_GENERATOR_PERFORMANCE_DATA_PROGRESS',
  ADD_GENERATOR_PERFORMANCE_DATA_SUCCESS:
    'ADD_GENERATOR_PERFORMANCE_DATA_SUCCESS',
  ADD_GENERATOR_PERFORMANCE_DATA_FAILURE:
    'ADD_GENERATOR_PERFORMANCE_DATA_FAILURE',
  ADD_GENERATOR_PERFORMANCE_DATA_RESET: 'ADD_GENERATOR_PERFORMANCE_DATA_RESET',

  ADD_GENERATOR_PARAMETER_DATA_PROGRESS:
    'ADD_GENERATOR_PARAMETER_DATA_PROGRESS',
  ADD_GENERATOR_PARAMETER_DATA_SUCCESS: 'ADD_GENERATOR_PARAMETER_DATA_SUCCESS',
  ADD_GENERATOR_PARAMETER_DATA_FAILURE: 'ADD_GENERATOR_PARAMETER_DATA_FAILURE',
  ADD_GENERATOR_PARAMETER_DATA_RESET: 'ADD_GENERATOR_PARAMETER_DATA_RESET',

  ADD_GENERATOR_PERFORMANCE_CURVES_DATA_PROGRESS:
    'ADD_GENERATOR_PERFORMANCE_DATA_CURVES_PROGRESS',
  ADD_GENERATOR_PERFORMANCE_CURVES_DATA_SUCCESS:
    'ADD_GENERATOR_PERFORMANCE_DATA_CURVES_SUCCESS',
  ADD_GENERATOR_PERFORMANCE_CURVES_DATA_FAILURE:
    'ADD_GENERATOR_PERFORMANCE_DATA_CURVES_FAILURE',
  ADD_GENERATOR_PERFORMANCE_CURVES_DATA_RESET:
    'ADD_GENERATOR_PERFORMANCE_DATA_CURVES_RESET',

  ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS:
    'ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS',
  ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS:
    'ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS',
  ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_FAILURE:
    'ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE',
  ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_RESET:
    'ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE',

  ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_PROGRESS:
    'ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_PROGRESS',
  ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_SUCCESS:
    'ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_SUCCESS',
  ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_FAILURE:
    'ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_FAILURE',
  ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_RESET:
    'ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_RESET',

  ADD_DRIVE_TYPE_PROGRESS: 'ADD_DRIVE_TYPE_PROGRESS',
  ADD_DRIVE_TYPE_SUCCESS: 'ADD_DRIVE_TYPE_SUCCESS',
  ADD_DRIVE_TYPE_FAILURE: 'ADD_DRIVE_TYPE_FAILURE',

  GET_DRIVE_TYPE_PROGRESS: 'GET_DRIVE_TYPE_PROGRESS',
  GET_DRIVE_TYPE_SUCCESS: 'GET_DRIVE_TYPE_SUCCESS',
  GET_DRIVE_TYPE_FAILURE: 'GET_DRIVE_TYPE_FAILURE',

  RESET_GENERATOR_REDUCER: 'RESET_GENERATOR_REDUCER',

  ADD_GENERATOR_CAPABILITY_CURVES_DATA_PROGRESS:
    'ADD_GENERATOR_CAPABILITY_DATA_CURVES_PROGRESS',
  ADD_GENERATOR_CAPABILITY_CURVES_DATA_SUCCESS:
    'ADD_GENERATOR_CAPABILITY_DATA_CURVES_SUCCESS',
  ADD_GENERATOR_CAPABILITY_CURVES_DATA_FAILURE:
    'ADD_GENERATOR_CAPABILITY_DATA_CURVES_FAILURE',
  ADD_GENERATOR_CAPABILITY_CURVES_DATA_RESET:
    'ADD_GENERATOR_CAPABILITY_DATA_CURVES_RESET',
};
  