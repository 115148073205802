import { features } from "./features.permissions";

export const roles = {
  MECHADEMY_ADMIN: "MechademyAdmin",
  CLIENT_ADMIN: "ClientAdmin",
  FACILITY_MANAGER: "FacilityManager",
  SYSTEM_MANAGER: "SystemEngineer",
  EQUIPMENT_MANAGER: "EquipmentEngineer",
  MECHADEMY_FACILITY_MANAGER: "MechademyFacilityManager",
};

export const permissions = {
  [roles.MECHADEMY_ADMIN]: {
    [features.DASHBOARD]: { write: true, read: true },
    [features.SETUP]: { write: true, read: true },
    [features.USER_MANAGEMENT]: { write: true, read: true },
    [features.ASSET_LIBRARY]: { write: true, read: true },
    [features.UNIT_PROPERTIES]: { write: true, read: true },
    [features.ML_ALGORITHM]: { write: true, read: true },
    [features.REPORTING]: { write: true, read: true },
    [features.SETTINGS]: { write: true, read: true },
    [features.PI_TAG]: { write: true, read: true },
    [features.USER_MANAGEMENT_GENERAL]: { write: true, read: true },
  },
  [roles.CLIENT_ADMIN]: {
    [features.DASHBOARD]: { write: false, read: true },
    [features.USER_MANAGEMENT]: { write: false, read: true },
    [features.SYSTEMSCHEMATIC]: { write: false, read: true },
  },
  [roles.FACILITY_MANAGER]: {
    [features.DASHBOARD]: { write: false, read: true },
    [features.SYSTEMSCHEMATIC]: { write: false, read: true },
  },
  [roles.SYSTEM_MANAGER]: {
    [features.DASHBOARD]: { write: false, read: true },
    [features.SYSTEMSCHEMATIC]: { write: false, read: true },
  },

  [roles.EQUIPMENT_MANAGER]: {
    [features.DASHBOARD]: { write: false, read: true },
  },

  [roles.MECHADEMY_FACILITY_MANAGER]: {
    [features.SETUP]: { write: true, read: true },
    [features.DASHBOARD]: { write: true, read: true },
    [features.ML_ALGORITHM]: { write: true, read: true },
    [features.USER_MANAGEMENT]: { write: true, read: true },
    [features.USER_MANAGEMENT_GENERAL]: { write: true, read: true },
  },
};
