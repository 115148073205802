import {
  ALERT_DETAILS_SAVE_ERROR,
  ALERT_DETAILS_SAVE_REQUEST,
  ALERT_DETAILS_SAVE_SUCCESS,
  ALERT_DETAILS_CLEAR_STATE,
} from "../Types/Alerts.types";

const INITIAL_API_STATE = {
  isDetailsAddedSuccess: false,
  detailsAddedSuccessResponse: null,
  isError: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  loading: false,
  apiState: {
    ...INITIAL_API_STATE,
  },
};

export default function AlertDetailReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALERT_DETAILS_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALERT_DETAILS_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isDetailsAddedSuccess: true,
          detailsAddedSuccessResponse: action.payload,
        },
      };

    case ALERT_DETAILS_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ALERT_DETAILS_CLEAR_STATE:
      return {
        ...state,
        apiState: { ...INITIAL_API_STATE },
      };

    default:
      return state;
  }
}
