import { socket } from "../connection";

export const NewAlertSocketListner = (callback) => {
  socket.on("NewAlert", (event) => {
    callback(event);
  });
};

export const ForwardedAlertSocketListner = (callback) => {
  socket.on("FwdAlert", (event) => {
    callback(event);
  });
};

export const NewKPISocketListner = (callback) => {
  socket.on("NewKPI", (event) => {
    callback(event);
  });
};

export const NewMessageListner = (callback) => {
  socket.on("NewMsg", (event) => {
    callback(event);
  });
};

export const RemoveListeningNewAlert = () => {
  socket.removeAllListeners("NewAlert");
};

export const RemoveListeningNewKpi = () => {
  socket.removeAllListeners("NewKPI");
};

export const RemoveListeningNewMsg = () => {
  socket.removeAllListeners("NewMsg");
};

export const RemoveListeningFwdAlert = () => {
  socket.removeAllListeners("FwdAlert");
};
