export default {
  CONFIG_DATA_RESET: 'CONFIG_DATA_RESET',
  GET_CONFIG_DATA_PROGRESS: 'GET_CONFIG_DATA_PROGRESS',
  GET_CONFIG_DATA_SUCCESS: 'GET_CONFIG_DATA_SUCCESS',
  GET_CONFIG_DATA_FAILURE: 'GET_CONFIG_DATA_FAILURE',
  UPDATE_CONFIG_DATA: 'UPDATE_CONFIG_DATA',
  UPDATE_CONFIG_DATA_PROGRESS: 'UPDATE_CONFIG_DATA_PROGRESS',
  UPDATE_CONFIG_DATA_SUCCESS: 'UPDATE_CONFIG_DATA_SUCCESS',
  UPDATE_CONFIG_DATA_FAILURE: 'UPDATE_CONFIG_FAILURE',
};
