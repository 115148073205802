export const PI_TAG_GET_LIST_SUCCESS = "PI_TAG_GET_LIST_SUCCESS";
export const PI_TAG_GET_LIST_ERROR = "PI_TAG_GET_LIST_ERROR";
export const PI_TAG_GET_LIST_PROGRESS = "PI_TAG_GET_LIST_PROGRESS";
export const PI_TAG_GET_LIST_CLEAR = "PI_TAG_GET_LIST_CLEAR";
export const PI_TAG_LIST_UPDATE = "PI_TAG_LIST_UPDATE"

export const PI_TAG_LOG_GET_LIST_SUCCESS = "PI_TAG_LOG_GET_LIST_SUCCESS";
export const PI_TAG_LOG_GET_LIST_ERROR = "PI_TAG_LOG_GET_LIST_ERROR";
export const PI_TAG_LOG_GET_LIST_PROGRESS = "PI_TAG_LOG_GET_LIST_PROGRESS";
export const PI_TAG_LOG_GET_LIST_CLEAR = "PI_TAG_LOG_GET_LIST_CLEAR";

export const PI_TAG_UPLOAD_SUCCESS = "PI_TAG_UPLOAD_SUCCESS";
export const PI_TAG_UPLOAD_ERROR = "PI_TAG_UPLOAD_ERROR";
export const PI_TAG_UPLOAD_PROGRESS = "PI_TAG_UPLOAD_PROGRESS";
export const PI_TAG_UPLOAD_CLEAR = "PI_TAG_UPLOAD_CLEAR";

export const PI_TAG_SAVE_SUCCESS = "PI_TAG_SAVE_SUCCESS";
export const PI_TAG_SAVE_ERROR = "PI_TAG_SAVE_ERROR";
export const PI_TAG_SAVE_PROGRESS = "PI_TAG_SAVE_PROGRESS";
export const PI_TAG_SAVE_CLEAR = "PI_TAG_SAVE_CLEAR";

export const PT_TAG_DELETE_SUCCESS = "PT_TAG_DELETE_SUCCESS";
export const PT_TAG_DELETE_ERROR = "PT_TAG_DELETE_ERROR";
export const PT_TAG_DELETE_PROGRESS = "PT_TAG_DELETE_PROGRESS";
export const PT_TAG_DELETE_CLEAR = "PT_TAG_DELETE_CLEAR";

export const CLEAR_PI_TAG_REDUCER = "CLEAR_PI_TAG_REDUCER";

export const PI_TAG_UPDATE_SUCCESS = 'PI_TAG_UPDATE_SUCCESS';
export const PI_TAG_UPDATE_ERROR = 'PI_TAG_UPDATE_ERROR';
export const PI_TAG_UPDATE_PROGRESS = 'PI_TAG_UPDATE_PROGRESS';
export const PI_TAG_UPDATE_CLEAR = 'PI_TAG_UPDATE_CLEAR';

export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS';
export const TAG_UPDATE_ERROR = 'TAG_UPDATE_ERROR';
export const TAG_UPDATE_PROGRESS = 'TAG_UPDATE_PROGRESS';
export const TAG_UPDATE_CLEAR = 'TAG_UPDATE_CLEAR';

export const TAG_BASETIME_UPDATE_SUCCESS = 'TAG_BASETIME_UPDATE_SUCCESS';
export const TAG_BASETIME_UPDATE_ERROR = 'TAG_BASETIME_UPDATE_ERROR';
export const TAG_BASETIME_UPDATE_PROGRESS = 'TAG_BASETIME_UPDATE_PROGRESS';
export const TAG_BASETIME_UPDATE_CLEAR = 'TAG_BASETIME_UPDATE_CLEAR';