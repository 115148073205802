import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  MotorDesignDataMessages,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  ValveOperationDataTable,
  ReqdCalcDataTable,
  PipingGeoDataTable,
  StrokeMassFlowTable,
  filterValveOperationData,
  filterReqdCalcData,
  filterPipingGeoData,
  filterStrokeMassFlowData,
  initialData,
  KeyChange,
  blockDesignDataMap
} from './DesignDataControlValve.Constants';
import { saveDesignData } from '../../../../../../redux/actions/controlValve.action';
import Loader from '../../../../../../common/Loader/Loader';
import { controlValveDesignDataVariables } from '../../../../../../constants/variables';

class DesignDataControlValve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: {
        valveOpData: [],
        reqCalcData: [],
        pipingGeoData: [],
        strokeMassData: [],
      },
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: {  valveOpData: [],
          reqCalcData: [],
          pipingGeoData: [],
          strokeMassData: [], },
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  setData() {
    const {
      controlValveConfigData: { designData },
    } = this.props.controlValveReducer;
    const tempDisplayData = [
      {
        name: controlValveDesignDataVariables.purpose,

        design: designData.purpose?.design,
      },
      {
        name: controlValveDesignDataVariables.type,

        design: designData.type?.design,
      },
      {
        name: controlValveDesignDataVariables.inherentCharacter,

        design: designData.inherentCharacter?.design,
      },
      {
        name: controlValveDesignDataVariables.actuator,
        design: designData.actuator?.design,
      },
      {
        name: controlValveDesignDataVariables.equationConstantN1,

        design: designData.equationConstantN1?.design,
      },
      {
        name: controlValveDesignDataVariables.equationConstantN2,

        design: designData.equationConstantN2?.design,
      },
      {
        name: controlValveDesignDataVariables.equationConstantN3,

        design: designData.equationConstantN3?.design,
      },
      {
        name: controlValveDesignDataVariables.configDP,
        unit: designData.configDP?.unit,
        design: designData.configDP?.design,
      },
      {
        name: controlValveDesignDataVariables.nominalDiameter,
        unit: designData.nominalDiameter?.unit,
        design: designData.nominalDiameter?.design,
      },
      {
        name: controlValveDesignDataVariables.inletDiameter,
        unit: designData.inletDiameter?.unit,
        design: designData.inletDiameter?.design,
      },
      {
        name: controlValveDesignDataVariables.outletDiameter,
        unit: designData.outletDiameter?.unit,
        design: designData.outletDiameter?.design,
      },
      {
        name: controlValveDesignDataVariables.fluidPhase,
        design: designData.fluidPhase?.design,
      },
      {
        name: controlValveDesignDataVariables.ratedValveFlowCoeff,
        design: designData.ratedValveFlowCoeff?.design,
      },
      {
        name: controlValveDesignDataVariables.liqDPrecoveryFactor,
        design: designData.liqDPrecoveryFactor?.design,
      },
      {
        name: controlValveDesignDataVariables.chokedDPratioFactor,
        design: designData.chokedDPratioFactor?.design,
      },
      {
        name: controlValveDesignDataVariables.velHeadLossCoeff,
        design: designData.velHeadLossCoeff?.design,
      },
      {
        name: controlValveDesignDataVariables.pipingGeoFactor,
        design: designData.pipingGeoFactor?.design,
      },
      {
        name: controlValveDesignDataVariables.valveMaxStroke,
        unit: designData.valveMaxStroke?.unit,
        design: designData.valveMaxStroke?.design,
      },
      {
        name: controlValveDesignDataVariables.valveMaxFlowrate,
        unit: designData.valveMaxFlowrate?.unit,
        design: designData.valveMaxFlowrate?.design,
      },
      {
        name: controlValveDesignDataVariables.criticalPressure,
        unit: designData.criticalPressure?.unit,
        design: designData.criticalPressure?.design,
      },
      {
        name: controlValveDesignDataVariables.valveDPToggle,
        design: designData.valveDPToggle?.design,
      },
      {
        name: controlValveDesignDataVariables.valveOpenThreshold,
        design: designData.valveOpenThreshold?.design,
      }
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      addDesignData,
      controlValveConfigData,
    } = this.props.controlValveReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (
      controlValveConfigData.isSuccess &&
      prevProps.controlValveReducer.controlValveConfigData.isSuccess !==
        controlValveConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      controlValveConfigData.isError &&
      prevProps.controlValveReducer.controlValveConfigData.isError !==
        controlValveConfigData.isError
    ) {
      this.setState({ displayData: initialData, copyDisplayData: initialData });
      this.setData();
    }
    if (
      addDesignData.isSuccess &&
      prevProps.controlValveReducer.addDesignData.isSuccess !==
        addDesignData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: {
          valveOpData: [],
          reqCalcData: [],
          pipingGeoData: [],
          strokeMassData: [],
        },
      });
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
    if (
      addDesignData.isError &&
      prevProps.controlValveReducer.addDesignData.isError !==
        addDesignData.isError
    ) {
      Notification.show(Types.Error, MotorDesignDataMessages.ERROR);
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }
  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row, tableName) => {
    const { editTableRows } = this.state;
    let editTable = editTableRows;

    editTable[tableName].push(row);
    editTable[tableName].sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row, tableName) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    row = blockDesignDataMap(row,tableName);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    } , this.setData());
    this.disableEditForm();
  };
  onSave = () => {
    const {
      location: { state },
      saveDesignData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
      copyDisplayData:_.cloneDeep(displayData)
    });
    if (
      (displayData[4].design || displayData[4].design === 0) &&
      (displayData[5].design || displayData[5].design === 0) &&
      (displayData[6].design || displayData[6].design === 0) &&
      (displayData[7].design || displayData[7].design === 0) && displayData[7].unit &&
      (displayData[8].design || displayData[8].design === 0) && displayData[8].unit &&
      (displayData[9].design || displayData[9].design === 0) && displayData[9].unit &&
      (displayData[10].design || displayData[10].design === 0) && displayData[10].unit &&
      (displayData[11].design || displayData[11].design === 0) && 
      (displayData[12].design || displayData[12].design === 0) &&
      (displayData[13].design || displayData[13].design === 0) && 
      (displayData[14].design || displayData[14].design === 0) &&
      (displayData[19].design || displayData[19].design === 0) && displayData[19].unit &&
      (displayData[20].design || displayData[20].design === 0) &&
      ((!displayData[17].design && !displayData[17].unit) ||(displayData[17].design && displayData[17].unit)) &&
      ((!displayData[18].design && !displayData[18].unit) ||(displayData[18].design && displayData[18].unit))
    ) {
      const data = KeyChange;
      let payload = {};
      displayData.forEach((item) => {
        //variables with units
        if (
          (item.name === controlValveDesignDataVariables.configDP &&
            (item.design || item.design === 0) && item.unit) ||
          (item.name === controlValveDesignDataVariables.nominalDiameter &&
            (item.design || item.design === 0) && item.unit) ||
          (item.name === controlValveDesignDataVariables.inletDiameter &&
            (item.design || item.design === 0) && item.unit) ||
          (item.name === controlValveDesignDataVariables.outletDiameter &&
            (item.design || item.design === 0) && item.unit) ||
          (item.name === controlValveDesignDataVariables.valveMaxStroke &&
            (item.design || item.design === 0) && item.unit) ||
          (item.name === controlValveDesignDataVariables.valveMaxFlowrate &&
            (item.design || item.design === 0) && item.unit) ||
            (item.name === controlValveDesignDataVariables.criticalPressure &&
              (item.design || item.design === 0) && item.unit)
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: item.design,
          };
          //unitless variables
        } else if (
          item.design ||
          item.design === 0 ||
          item.name === controlValveDesignDataVariables.purpose ||
          item.name === controlValveDesignDataVariables.type ||
          item.name === controlValveDesignDataVariables.inherentCharacter ||
          item.name === controlValveDesignDataVariables.actuator ||
          item.name === controlValveDesignDataVariables.equationConstantN1 ||
          item.name === controlValveDesignDataVariables.equationConstantN2 ||
          item.name === controlValveDesignDataVariables.equationConstantN3 ||
          item.name === controlValveDesignDataVariables.fluidPhase ||
          item.name === controlValveDesignDataVariables.ratedValveFlowCoeff ||
          item.name === controlValveDesignDataVariables.liqDPrecoveryFactor ||
          item.name === controlValveDesignDataVariables.chokedDPratioFactor ||
          item.name === controlValveDesignDataVariables.velHeadLossCoeff ||
          item.name === controlValveDesignDataVariables.pipingGeoFactor||
          item.name === controlValveDesignDataVariables.valveDPToggle ||
          item.name === controlValveDesignDataVariables.valveOpenThreshold 
        ) {
          payload[data[item.name]] = { design: item.design };
        }
      });
      if (
        this.handleNetwork() &&
        state &&
        state.componentData.info._id &&
        payload
      ) {
        saveDesignData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, MotorDesignDataMessages.DIRTY_DATA);
    }
  };

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }
  render() {
    const {
      controlValveConfigData,
      addDesignData,
    } = this.props.controlValveReducer;
    const { saveListener, unitData } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignDataMotor">
        {(controlValveConfigData.isLoading || addDesignData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          showHeader={true}
          columns={ValveOperationDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterValveOperationData(this.state.displayData)}
          editableRow={this.state.editTableRows.valveOpData}
        />
        <CustomTable
          showHeader={true}
          columns={ReqdCalcDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterReqdCalcData(this.state.displayData)}
          editableRow={this.state.editTableRows.reqCalcData}
        />
        <CustomTable
          showHeader={true}
          columns={PipingGeoDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterPipingGeoData(this.state.displayData)}
          editableRow={this.state.editTableRows.pipingGeoData}
        />
                <CustomTable
          showHeader={true}
          columns={StrokeMassFlowTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterStrokeMassFlowData(this.state.displayData)}
          editableRow={this.state.editTableRows.strokeMassData}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}
DesignDataControlValve.propTypes = {
  controlValveConfigData: PropType.object,
  addDesignData: PropType.object,
  location: PropType.object,
  saveDesignData: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isSchematicEditable: PropType.bool,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
};

DesignDataControlValve.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    controlValveReducer: state.controlValveReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(DesignDataControlValve)
);
