import {
  GET_FLOW_METER_TYPE_FETCH_PROGRESS,
  GET_FLOW_METER_TYPE_FETCH_SUCCESS,
  GET_FLOW_METER_TYPE_FETCH_ERROR,
  ADD_FLOW_METER_TYPE_FETCH_PROGRESS,
  ADD_FLOW_METER_TYPE_FETCH_SUCCESS,
  ADD_FLOW_METER_TYPE_FETCH_ERROR,
  ADD_VALUE_DATA_SUCCESS,
  ADD_VALUE_DATA_PROGRESS,
  ADD_VALUE_DATA_ERROR,
  FLOW_MEASUREMENT_CLEAR_STATE,
} from "../Types/flowMeasurement.types";

const INITIAL_STATE = {
  apiState: {
    isErrorGetFlowMeterType: false,
    isSuccessGetFlowMeterType: false,
    isSuccessAddFlowMeterType: false,
    isErrorAddFlowMeterType: false,
    isAddDataSuccess: false,
    isAddDataError: false,
    getFlowMeterTypeData: [],
    addFlowMeterTypeValue: null,
    flowMeasurementData: null,
    errorCode: null,
    message: null,
  },
  isLoading: false,
};

export default function flowMeasurementReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case GET_FLOW_METER_TYPE_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
        apiState: {
          ...state.apiState,
          isSuccessGetFlowMeterType: false,
        },
      };

    case GET_FLOW_METER_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetFlowMeterType: true,
          getFlowMeterTypeData: action.payload,
        },
      };

    case GET_FLOW_METER_TYPE_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetFlowMeterType: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_FLOW_METER_TYPE_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case ADD_FLOW_METER_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessAddFlowMeterType: true,
          addFlowMeterTypeValue: action.payload,
        },
      };

    case ADD_FLOW_METER_TYPE_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorAddFlowMeterType: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_VALUE_DATA_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case ADD_VALUE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          flowMeasurementData: action.payload,
          isAddDataSuccess: true,
        },
      };

    case ADD_VALUE_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isAddDataError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case FLOW_MEASUREMENT_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_STATE,
          getFlowMeterTypeData: state.apiState.getFlowMeterTypeData,
          addFlowMeterTypeValue: state.apiState.addFlowMeterTypeValue,
          flowMeasurementData: state.flowMeasurementData,
        },
      };

    default:
      return state;
  }
}
