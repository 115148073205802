import * as Types from "../Types/curves.types";

const compressorCurvesData = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorCode: null,
  message: null,
};
const deleteCompressorCurves = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorCode: null,
  message: null,
};

const addEditCompressorCurves = {
  data:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorCode: null,
  message: null,
}

const INITIAL_API_STATE = {
  data: null,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};



const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  compressorCurvesData,
  deleteCompressorCurves,
  addEditCompressorCurves,
  loading: false,
};

export default function RecipCompCurveState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.COMPRESSOR_CURVES_ADD_EDIT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.COMPRESSOR_CURVES_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        addEditCompressorCurves: {
          ...state.addEditCompressorCurves,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Types.COMPRESSOR_CURVES_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        addEditCompressorCurves: {
          ...state.addEditCompressorCurves,
          data: action.payload,
          isSuccess: true,
          isError: false,
        },
      };
    case Types.COMPRESSOR_CURVES_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        addEditCompressorCurves: {
          ...state.addEditCompressorCurves,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.COMPRESSOR_CURVES_DELETE_PROGRESS:
      return {
        ...state,
        deleteCompressorCurves: {
          ...state.deleteCompressorCurves,
          isSuccess: false,
          isError: false,
          isLoading: true,
          errorCode: "",
          message: "",
        },
      };
    case Types.COMPRESSOR_CURVES_DELETE_SUCCESS:
      return {
        ...state,
        deleteCompressorCurves: {
          ...state.deleteCompressorCurves,
          isSuccess: true,
          isError: false,
          isLoading: false,
          errorCode: "",
          message: "",
        },
      };
    case Types.COMPRESSOR_CURVES_DELETE_ERROR:
      return {
        ...state,
        deleteCompressorCurves: {
          ...state.deleteCompressorCurves,
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Types.COMPRESSOR_CURVES_DELETE_CLEAR_STATE:
      return {
        ...state,
        deleteCompressorCurves: {
          ...state.deleteCompressorCurves,
          isSuccess: false,
          isError: false,
          isLoading: false,
          errorCode: "",
          message: "",
        },
      };
    default:
      return state;
  }
};
