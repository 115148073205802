import * as Type from "../Types/Stream.type";
import { GETRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

export function getStreamData(id) {
  return function (dispatch) {
    dispatch({ type: Type.STREAM_FETCH_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.mixer}`)
      .then((res) => {
        dispatch({ type: Type.STREAM_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.STREAM_FETCH_ERROR, payload: err });
      });
  };
}

export function addStreamData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.STREAM_ADD_PROGRESS });
    PUTRequest(`${endPoints.components}/${id}/${endPoints.mixer}`, payload)
      .then((res) => {
        dispatch({ type: Type.STREAM_ADD_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.STREAM_ADD_ERROR, payload: err });
      });
  };
}

export function clearStreamState() {
  return function (dispatch) {
    dispatch({ type: Type.STREAM_FETCH_RESET });
  };
}

export function resetStreamReducer() {
  return function (dispatch) {
    dispatch({ type: Type.STREAM_REDUCER_RESET });
  };
}
