export const FETCH_TRANSIENT_DATA_PROGRESS = 'FETCH_TRANSIENT_DATA_PROGRESS';
export const FETCH_TRANSIENT_DATA_SUCCESS = 'FETCH_TRANSIENT_DATA_SUCCESS';
export const FETCH_TRANSIENT_DATA_ERROR = 'FETCH_TRANSIENT_DATA_ERROR';

export const FETCH_VARIABLE_TAGS_PROGRESS = "FETCH_VARIABLE_TAGS_PROGRESS";
export const FETCH_VARIABLE_TAGS_SUCCESS = "FETCH_VARIABLE_TAGS_SUCCESS";
export const FETCH_VARIABLE_TAGS_ERROR = "FETCH_VARIABLE_TAGS_ERROR";
export const CLEAR_STATE = "CLEAR_STATE";

export const ADD_TRANSIENT_DATA_PROGRESS = "ADD_TRANSIENT_DATA_PROGRESS";
export const ADD_TRANSIENT_DATA_SUCCESS = "ADD_TRANSIENT_DATA_SUCCESS";
export const ADD_TRANSIENT_DATA_ERROR = "ADD_TRANSIENT_DATA_ERROR";

export const FETCH_TRANSIENT_DATA_CALC_PROGRESS = "FETCH_TRANSIENT_DATA_CALC_PROGRESS";
export const FETCH_TRANSIENT_DATA_CALC_SUCCESS = "FETCH_TRANSIENT_DATA_CALC_SUCCESS";
export const FETCH_TRANSIENT_DATA_CALC_ERROR = "FETCH_TRANSIENT_DATA_CALC_ERROR";
export const FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE = "FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE";

export const UPDATE_TRANSIENT_DATA_CALC_PROGRESS = "UPDATE_TRANSIENT_DATA_CALC_PROGRESS";
export const UPDATE_TRANSIENT_DATA_CALC_SUCESS = "UPDATE_TRANSIENT_DATA_CALC_SUCCESS";
export const UPDATE_TRANSIENT_DATA_CALC_ERROR = "UPDATE_TRANSIENT_DATA_CALC_ERROR";

export const FETCH_BASELINE_DATA_PROGRESS = "FETCH_BASELINE_DATA_PROGRESS";
export const FETCH_BASELINE_DATA_SUCCESS = "FETCH_BASELINE_DATA_SUCCESS";
export const FETCH_BASELINE_DATA_ERROR = "FETCH_BASELINE_DATA_ERROR";

