export const DATA_VARIABLE_FETCH_SUCCESS = "DATA_VARIABLE_FETCH_SUCCESS";
export const DATA_VARIABLE_FETCH_ERROR = "DATA_VARIABLE_FETCH_ERROR";
export const DESIGN_FETCH_PROGRESS = "DESIGN_FETCH_PROGRESS";
export const DESIGN_CLEAR_STATE = "DESIGN_CLEAR_STATE";
export const ADD_DESIGN_DATA_SUCCESS = "ADD_DESIGN_DATA_SUCCESS";
export const ADD_DESIGN_DATA_PROGRESS = "ADD_DESIGN_DATA_PROGRESS";
export const ADD_DESIGN_DATA_ERROR = "ADD_DESIGN_DATA_ERROR";
export const ADD_PERFORMANCE_DATA_SUCCESS = "ADD_PERFORMANCE_DATA_SUCCESS";
export const ADD_PERFORMANCE_DATA_PROGRESS = "ADD_PERFORMANCE_DATA_PROGRESS";
export const ADD_PERFORMANCE_DATA_ERROR = "ADD_PERFORMANCE_DATA_ERROR";
export const CLEAR_PERFORMANCE_DATA = "CLEAR_PERFORMANCE_DATA";
