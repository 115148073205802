import React from "react";
import { Icon, Input } from "antd";
import { CapacityControlVariables, CylinderDataVariables, designFieldConstant, RecpComperssorDesignVariables, ValveDataVariable } from "./designDataFieldConstants";
import { validateNumber } from "../../../../../../libs/validator";

export const renderHeader = (headerData, unitData, onEdit, onUndo, handleChange, headingName, stateName, saveFlag) => {
  return headerData({
    unitData,
    onEdit: onEdit,
    onUndo: onUndo,
    handleChange: handleChange,
    headingName: headingName,
    stateName: stateName,
    saveFlag: saveFlag
  })
}

export const designDataHeader = (config) => [
  {
    title: config.headingName,
    dataIndex: 'name',
    render: (value) => {
      if (
        value === RecpComperssorDesignVariables.application ||
        value === RecpComperssorDesignVariables.configSpeed ||
        value === RecpComperssorDesignVariables.startupSpeedThreshold ||
        value === RecpComperssorDesignVariables.startupPowerThreshold ||
        value === RecpComperssorDesignVariables.mechanicalEfficiency ||
        value === CylinderDataVariables.cylinderBoreDiameter ||
        value === CylinderDataVariables.stroke ||
        value === CylinderDataVariables.rodDiameter ||
        value === CylinderDataVariables.tailRodDiameter ||
        value === CylinderDataVariables.headEndFixedClearanceVolume1 ||
        value === CylinderDataVariables.headEndFixedClearanceVolume2 ||
        value === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
        value === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
        value === CylinderDataVariables.headEndClearancePocketMode ||
        value === CylinderDataVariables.crankEndClearancePocketMode ||
        value === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
        value === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
        value === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
        value === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
        value === CylinderDataVariables.fixedHeadEndInletLosses ||
        value === CylinderDataVariables.fixedHeadEndDischargeLosses ||
        value === CylinderDataVariables.fixedCrankEndInletLosses ||
        value === CylinderDataVariables.fixedCrankEndDischargeLosses
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        )
      }
      // Line below is written to remove white space and make the first character small
      else if (value === ValveDataVariable[(value.charAt(0).toLowerCase() + value.slice(1)).replace(/ /g, '')]) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        )
      }
      return value
    }
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === RecpComperssorDesignVariables.configSpeed ||
        item.name === RecpComperssorDesignVariables.maximumOperatingSpeed ||
        item.name === RecpComperssorDesignVariables.minimumOperatingSpeed ||
        item.name === RecpComperssorDesignVariables.startupSpeedThreshold ||
        item.name === RecpComperssorDesignVariables.startupPowerThreshold ||
        item.name === RecpComperssorDesignVariables.driverRatedPower ||
        item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
        item.name === CylinderDataVariables.cylinderBoreDiameter ||
        item.name === CylinderDataVariables.stroke ||
        item.name === CylinderDataVariables.rodDiameter ||
        item.name === CylinderDataVariables.tailRodDiameter ||
        item.name === CylinderDataVariables.headEndDisplacement ||
        item.name === CylinderDataVariables.crankEndDisplacement ||
        item.name === CylinderDataVariables.cylinderBoreDiameter ||
        item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
        item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
        item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
        item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
        item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
        item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
        item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
        item.name === CylinderDataVariables.ratedInletPressure ||
        item.name === CylinderDataVariables.ratedDischargePressure ||
        item.name === CylinderDataVariables.ratedGasLoadCompression ||
        item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
        item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
        item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
        item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
        item.name === CylinderDataVariables.ratedPistonSpeed ||
        item.name === CylinderDataVariables.ratedGasLoadTension ||
        item.name === ValveDataVariable.headEndSuctionValveBoreDiameter ||
        item.name === ValveDataVariable.headEndDischargeValveBoreDiameter ||
        item.name === ValveDataVariable.crankEndSuctionValveBoreDiamater ||
        item.name === ValveDataVariable.crankEndDischargeValveBoreDiameter ||
        item.name === ValveDataVariable.headEndSuctionValveLosses ||
        item.name === ValveDataVariable.headEndDischargeValveLosses ||
        item.name === ValveDataVariable.crankEndSuctionValveLosses ||
        item.name === ValveDataVariable.crankEndDischargeValveLosses ||
        item.name === CylinderDataVariables.fixedCrankEndDischargeLosses
      ) {
        const unitError = !!(
          (
            item.name === RecpComperssorDesignVariables.configSpeed ||
            item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
            item.name === RecpComperssorDesignVariables.startupSpeedThreshold ||
            item.name === RecpComperssorDesignVariables.startupPowerThreshold ||
            item.name === CylinderDataVariables.cylinderBoreDiameter ||
            item.name === CylinderDataVariables.stroke ||
            item.name === CylinderDataVariables.rodDiameter ||
            item.name === CylinderDataVariables.tailRodDiameter ||
            item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
            item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
            item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
            item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
            item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
            item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
            item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
            item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
            item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
            item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
            item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
            item.name === CylinderDataVariables.fixedCrankEndDischargeLosses ||
            item.name === ValveDataVariable.headEndSuctionValveBoreDiameter ||
            item.name === ValveDataVariable.headEndDischargeValveBoreDiameter ||
            item.name === ValveDataVariable.crankEndSuctionValveBoreDiamater ||
            item.name === ValveDataVariable.crankEndDischargeValveBoreDiameter ||
            item.name === ValveDataVariable.headEndSuctionValveLosses ||
            item.name === ValveDataVariable.headEndDischargeValveLosses ||
            item.name === ValveDataVariable.crankEndSuctionValveLosses ||
            item.name === ValveDataVariable.crankEndDischargeValveLosses
          ) &&
          (item.unit === "" || item.unit === undefined || item.unit === null || item.unit === 'Select')
          && config.saveFlag
        );
        return (
          <div
            className={`custom-table-select ${unitError ? 'error' : 'not-error'}  `} >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (
                  item.name === RecpComperssorDesignVariables.configSpeed ||
                  item.name === RecpComperssorDesignVariables.maximumOperatingSpeed ||
                  item.name === RecpComperssorDesignVariables.minimumOperatingSpeed ||
                  item.name === RecpComperssorDesignVariables.startupSpeedThreshold
                ) {
                  if (getUnits.dataVariable === "Speed") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === RecpComperssorDesignVariables.driverRatedPower) {
                  if (getUnits.dataVariable === "Output Power") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === RecpComperssorDesignVariables.startupPowerThreshold) {
                  if (getUnits.dataVariable === "Startup Power Threshold") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
                  item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
                  item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
                  item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
                  item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
                  item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
                  item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
                  item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
                  item.name === CylinderDataVariables.fixedCrankEndDischargeLosses
                ) {
                  if (getUnits.dataVariable === "Efficiency") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === CylinderDataVariables.cylinderBoreDiameter ||
                  item.name === CylinderDataVariables.stroke ||
                  item.name === CylinderDataVariables.rodDiameter ||
                  item.name === CylinderDataVariables.tailRodDiameter ||
                  item.name === ValveDataVariable.headEndSuctionValveBoreDiameter ||
                  item.name === ValveDataVariable.headEndDischargeValveBoreDiameter ||
                  item.name === ValveDataVariable.crankEndSuctionValveBoreDiamater ||
                  item.name === ValveDataVariable.crankEndDischargeValveBoreDiameter
                ) {
                  if (getUnits.dataVariable === "Diameter") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === CylinderDataVariables.ratedInletPressure ||
                  item.name === CylinderDataVariables.ratedDischargePressure
                ) {
                  if (getUnits.dataVariable === "Inlet Pressure") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === CylinderDataVariables.headEndDisplacement ||
                  item.name === CylinderDataVariables.crankEndDisplacement ||
                  item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
                  item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
                  item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
                  item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1
                ) {
                  if (getUnits.dataVariable === "Clearance Volume") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === CylinderDataVariables.ratedPistonSpeed
                ) {
                  if (getUnits.dataVariable === "Turbine Maximum Tip Speed") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === CylinderDataVariables.ratedGasLoadCompression ||
                  item.name === CylinderDataVariables.ratedGasLoadTension
                ) {
                  if (getUnits.dataVariable === "Rotor Weight") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === ValveDataVariable.headEndSuctionValveLosses ||
                  item.name === ValveDataVariable.headEndDischargeValveLosses ||
                  item.name === ValveDataVariable.crankEndSuctionValveLosses ||
                  item.name === ValveDataVariable.crankEndDischargeValveLosses
                ) {
                  if (getUnits.dataVariable === "Rated Power Output") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                return undefined;
              })}
            </select>
          </div>
        )
      }
    }
  },
  {
    title: "Design",
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const designError = !!(
          (
            item.name === RecpComperssorDesignVariables.application ||
            item.name === RecpComperssorDesignVariables.configSpeed ||
            item.name === RecpComperssorDesignVariables.startupSpeedThreshold ||
            item.name === RecpComperssorDesignVariables.startupPowerThreshold ||
            item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
            item.name === CylinderDataVariables.cylinderBoreDiameter ||
            item.name === CylinderDataVariables.stroke ||
            item.name === CylinderDataVariables.rodDiameter ||
            item.name === CylinderDataVariables.tailRodDiameter ||
            item.name === CylinderDataVariables.cylinderBoreDiameter ||
            item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
            item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
            item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
            item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
            item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
            item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
            item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
            item.name === CylinderDataVariables.fixedCrankEndDischargeLosses ||
            item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
            item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
            item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
            item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
            item.name === CylinderDataVariables.headEndClearancePocketMode ||
            item.name === CylinderDataVariables.crankEndClearancePocketMode ||
            item.name === CylinderDataVariables.crankEndClearancePocketMode ||
            item.name === ValveDataVariable[(item.name.charAt(0).toLowerCase() + item.name.slice(1)).replace(/ /g, '')]
            )&&
          (item.design === "" || item.design===undefined || item.design === null || item.design === "Select" ||
            (typeof(item.design) != 'string' && isNaN(item.design))
          )&&
          config.saveFlag
        )
      if (item.name === RecpComperssorDesignVariables.application ||
        item.name === RecpComperssorDesignVariables.driver ||
        item.name === RecpComperssorDesignVariables.cylinderConstruction ||
        item.name === RecpComperssorDesignVariables.cylinderConfiguration ||
        item.name === CylinderDataVariables.loadingmode1 ||
        item.name === CylinderDataVariables.loadingmode2 ||
        item.name === CylinderDataVariables.loadingmode3 ||
        item.name === CylinderDataVariables.crankEndClearancePocketMode ||
        item.name === CylinderDataVariables.headEndClearancePocketMode ||
        item.name === ValveDataVariable.calculateValveLosses ||
        item.name === CapacityControlVariables.using ||
        item.name === CapacityControlVariables.by ||
        item.name === CapacityControlVariables.for ||
        item.name === CapacityControlVariables.with ||
        item.name === CapacityControlVariables.action
      ) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design: item.design === false ? false :"Select" }
            >
              <option>Select</option>
              {designFieldConstant[item.name] &&
                designFieldConstant[item.name].map((item) => (
                  <option value={item.value} title={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div >
        )
      } 
      else if(item.name === RecpComperssorDesignVariables.purpose || 
        item.name === RecpComperssorDesignVariables.interstageEquipment
        ){
        return (
          <div className>
          <Input
            type="text"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'text')
            }
            className={designError ? 'error' : 'not-error'}
          />
        </div>
        )
      }
      return (
        <div className>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={designError? 'error' : 'not-error'}
          />
        </div>)
    }
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, config.stateName)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, config.stateName, item.name)} >
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
]

export const filterEquipmentData = (displayData, variableList) => {
  return displayData.filter((item) => {
    for (const key in variableList) {
      if (item.name === variableList[key]) {
        return item
      }
    }
    return undefined;
  });
}

export const Validate = async (displayData) => {
  let mandatoryCheckError = false;
  let unitCheckError = false;
  // mandatory field check
  displayData.forEach((item) => {
    if (
      item.name === RecpComperssorDesignVariables.application ||
      item.name === RecpComperssorDesignVariables.configSpeed ||
      item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
      item.name === RecpComperssorDesignVariables.startupSpeedThreshold ||
      item.name === RecpComperssorDesignVariables.startupPowerThreshold ||
      item.name === CylinderDataVariables.cylinderBoreDiameter ||
      item.name === CylinderDataVariables.stroke ||
      item.name === CylinderDataVariables.rodDiameter ||
      item.name === CylinderDataVariables.tailRodDiameter ||
      item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
      item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
      item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
      item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
      item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
      item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
      item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
      item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
      item.name === CylinderDataVariables.headEndClearancePocketMode ||
      item.name === CylinderDataVariables.crankEndClearancePocketMode ||
      item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
      item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
      item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
      item.name === CylinderDataVariables.fixedCrankEndDischargeLosses ||
      item.name === ValveDataVariable.calculateValveLosses ||
      item.name === ValveDataVariable.headEndSuctionValveQuantity ||
      item.name === ValveDataVariable.headEndDischargeValveQuantity ||
      item.name === ValveDataVariable.crankEndSuctionValveQuantity ||
      item.name === ValveDataVariable.crankEndDischargeValveQuantity ||
      item.name === ValveDataVariable.headEndSuctionValveBoreDiameter ||
      item.name === ValveDataVariable.headEndDischargeValveBoreDiameter ||
      item.name === ValveDataVariable.crankEndSuctionValveBoreDiamater ||
      item.name === ValveDataVariable.crankEndDischargeValveBoreDiameter ||
      item.name === ValveDataVariable.headEndSuctionValveResistanceFactor ||
      item.name === ValveDataVariable.headEndDischargeValveResistanceFactor ||
      item.name === ValveDataVariable.crankEndSuctionValveResistanceFactor ||
      item.name === ValveDataVariable.crankEndDischargeValveResistanceFactor ||
      item.name === ValveDataVariable.headEndSuctionValveLosses ||
      item.name === ValveDataVariable.headEndDischargeValveLosses ||
      item.name === ValveDataVariable.crankEndSuctionValveLosses ||
      item.name === ValveDataVariable.crankEndDischargeValveLosses
    ) {
      if(!item.design || item.design === "Select" || (typeof(item.design) != 'string' && isNaN(item.design))){
        if((typeof(item.design) != 'string' && isNaN(item.design)) || item.design === "Select"){
          mandatoryCheckError = true;
        }
        else if(item.design !== null && (item.design === 0 || typeof(item.design === 'boolean'))){
        }
        else {
          mandatoryCheckError = true;
        }
      }
    }
    if (
      item.name === RecpComperssorDesignVariables.configSpeed ||
      item.name === RecpComperssorDesignVariables.mechanicalEfficiency ||
      item.name === RecpComperssorDesignVariables.startupSpeedThreshold ||
      item.name === RecpComperssorDesignVariables.startupPowerThreshold ||
      item.name === CylinderDataVariables.cylinderBoreDiameter ||
      item.name === CylinderDataVariables.stroke ||
      item.name === CylinderDataVariables.rodDiameter ||
      item.name === CylinderDataVariables.tailRodDiameter ||
      item.name === CylinderDataVariables.headEndFixedClearanceVolume1 ||
      item.name === CylinderDataVariables.headEndFixedClearanceVolume2 ||
      item.name === CylinderDataVariables.crankEndFixedClearanceVolume1 ||
      item.name === CylinderDataVariables.crankEndFixedClearanceVolume2 ||
      item.name === CylinderDataVariables.headEndVariableClearancePocketVolume1 ||
      item.name === CylinderDataVariables.headEndVariableClearancePocketVolume2 ||
      item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume1 ||
      item.name === CylinderDataVariables.crankEndVariableClearancePocketVolume2 ||
      item.name === CylinderDataVariables.fixedHeadEndInletLosses ||
      item.name === CylinderDataVariables.fixedHeadEndDischargeLosses ||
      item.name === CylinderDataVariables.fixedCrankEndInletLosses ||
      item.name === CylinderDataVariables.fixedCrankEndDischargeLosses ||
      item.name === ValveDataVariable.headEndSuctionValveBoreDiameter ||
      item.name === ValveDataVariable.headEndDischargeValveBoreDiameter ||
      item.name === ValveDataVariable.crankEndSuctionValveBoreDiamater ||
      item.name === ValveDataVariable.crankEndDischargeValveBoreDiameter ||
      item.name === ValveDataVariable.headEndSuctionValveLosses ||
      item.name === ValveDataVariable.headEndDischargeValveLosses ||
      item.name === ValveDataVariable.crankEndSuctionValveLosses ||
      item.name === ValveDataVariable.crankEndDischargeValveLosses
    ) {
      if (!item.unit ||item.unit === 'Select'){
      console.log(item.name, "unit", item.unit)
      unitCheckError = true;
      }
    }
  });
  return mandatoryCheckError || unitCheckError;
}