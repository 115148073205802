export default {

  GET_VALVE_CONFIG_DATA_PROGRESS: 'GET_VALVE_DESIGN_PERF_DATA_PROGRESS',
  GET_VALVE_CONFIG_DATA_SUCCESS: 'GET_VALVE_DESIGN_PERF_DATA_SUCCESS',
  GET_VALVE_CONFIG_DATA_FAILURE: 'GET_VALVE_DESIGN_PERF_DATA_FAILURE',
  GET_VALVE_CONFIG_DATA_RESET: 'GET_VALVE_DESIGN_PERF_DATA_RESET',

  UPDATE_VALVE_DESIGN_DATA_PROGRESS: 'UPDATE_VALVE_DESIGN_PERF_DATA_PROGRESS',
  UPDATE_VALVE_DESIGN_DATA_SUCCESS: 'UPDATE_VALVE_DESIGN_PERF_DATA_SUCCESS',
  UPDATE_VALVE_DESIGN_DATA_FAILURE: 'UPDATE_VALVE_DESIGN_PERF_DATA_FAILURE',
  UPDATE_VALVE_DESIGN_DATA_RESET: 'UPDATE_VALVE_DESIGN_PERF_DATA_RESET',

  UPDATE_VALVE_CURVES_DATA_PROGRESS: 'UPDATE_VALVE_CURVES_DATA_PROGRESS',
  UPDATE_VALVE_CURVES_DATA_SUCCESS: 'UPDATE_VALVE_CURVES_DATA_SUCCESS',
  UPDATE_VALVE_CURVES_DATA_FAILURE: 'UPDATE_VALVE_CURVES_DATA_FAILURE',
  UPDATE_VALVE_CURVES_DATA_RESET: 'UPDATE_VALVE_CURVES_DATA_RESET',

  RESET_VALVE_REDUCER: 'RESET_VALVE_REDUCER',
};
