import {
  CURVES_ADD_EDIT_ERROR,
  CURVES_ADD_EDIT_PROGRESS,
  CURVES_ADD_EDIT_SUCCESS,
  CURVES_CLEAR_STATE,
  COMPRESSOR_CURVES_ADD_EDIT_ERROR,
  COMPRESSOR_CURVES_ADD_EDIT_PROGRESS,
  COMPRESSOR_CURVES_ADD_EDIT_SUCCESS,
  COMPRESSOR_CURVES_CLEAR_STATE,
  COMPRESSOR_CURVES_FETCH_ERROR,
  COMPRESSOR_CURVES_FETCH_PROGRESS,
  COMPRESSOR_CURVES_FETCH_SUCCESS,
  COMPRESSOR_CURVES_FETCH_CLEAR_STATE,
  COMPRESSOR_CURVES_DELETE_SUCCESS,
  COMPRESSOR_CURVES_DELETE_PROGRESS,
  COMPRESSOR_CURVES_DELETE_ERROR,
  COMPRESSOR_CURVES_DELETE_CLEAR_STATE,
} from "../Types/curves.types";

import { PUTRequest, GETRequest, DeleteRequest } from "../../api/request";

const curvesProgress = () => ({ type: CURVES_ADD_EDIT_PROGRESS });
const curvesSuccess = (payload) => ({
  type: CURVES_ADD_EDIT_SUCCESS,
  payload,
});
const curvesError = (payload) => ({
  type: CURVES_ADD_EDIT_ERROR,
  payload,
});

export function addEditCurves(id, payload, componentType) {
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/curves`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves`,
    "Reciprocating compressor": `components/${id}/rcconfigs/curves`,
  };
  console.log(componentType)
  return function (dispatch) {
    dispatch(curvesProgress());
    PUTRequest(componentAPIMapper[componentType], payload)
      .then((res) => dispatch(curvesSuccess(res.body)))
      .catch((err) => dispatch(curvesError(err)));
  };
}

export function addEditCompressorCurves(id, payload, componentType) {
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/curves`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves`,
    "Gas turbine": `components/${id}/gtconfigs/curves`,
    "Reciprocating Compressor": `components/${id}/rcconfigs/curves`,
  };
  return function (dispatch) {
    dispatch({ type: COMPRESSOR_CURVES_ADD_EDIT_PROGRESS });
    PUTRequest(componentAPIMapper[componentType], payload)
      .then((res) =>
        dispatch({
          type: COMPRESSOR_CURVES_ADD_EDIT_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: COMPRESSOR_CURVES_ADD_EDIT_ERROR, payload: err })
      );
  };
}

export function clearCompressorCurvesState() {
  return function (dispatch) {
    dispatch({ type: COMPRESSOR_CURVES_CLEAR_STATE });
  };
}

export function clearCurvesState() {
  return function (dispatch) {
    dispatch({ type: CURVES_CLEAR_STATE });
  };
}

export function getCompressorCurves(id, componentType) {
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/curves`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves`,
    "Gas turbine": `components/${id}/gtconfigs/curves`,
  };
  return function (dispatch) {
    dispatch({ type: COMPRESSOR_CURVES_FETCH_PROGRESS });
    GETRequest(componentAPIMapper[componentType])
      .then((res) =>
        dispatch({ type: COMPRESSOR_CURVES_FETCH_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: COMPRESSOR_CURVES_FETCH_ERROR, payload: err })
      );
  };
}

export function clearFetchCompressorCurvesState() {
  return function (dispatch) {
    dispatch({ type: COMPRESSOR_CURVES_FETCH_CLEAR_STATE });
  };
}

export const deleteCompressorCurves = (id, componentType, curveId, screenName) => {
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/curves`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves`,
    "Gas turbine": `components/${id}/gtconfigs/curves`,
    "Reciprocating Compressor": `components/${id}/rcconfigs/curves/screenname/${screenName}`,
  };
  return (dispatch) => {
    dispatch({ type: COMPRESSOR_CURVES_DELETE_PROGRESS });
    DeleteRequest(`${componentAPIMapper[componentType]}?curveId=${curveId}`)
      .then((res) =>
        dispatch({ type: COMPRESSOR_CURVES_DELETE_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: COMPRESSOR_CURVES_DELETE_ERROR, payload: err })
      );
  };
};

export function clearDeleteCompressorCurvesState() {
  return function (dispatch) {
    dispatch({ type: COMPRESSOR_CURVES_DELETE_CLEAR_STATE });
  };
}
