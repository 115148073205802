import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  PerformanceDataMessages,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  stPerformanceDataVariables,
} from '../../../../../../constants/variables';
import {
  addPerformanceData,
  clearPerformanceData,
} from '../../../../../../redux/actions/design.action';
import {
  ChangeKey,
  initialData,
  PerformanceDataShow,
} from './PerformanceData.Constants';
import './PerformanceData.scss';

class PerformanceDataGasTurbine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }
  componentDidMount() {
    this.setUnit();
    this.getData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnit() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  getData() {
    const { configData, isConfigData } = this.props;
    if (isConfigData && configData) {
      const { performanceData } = configData;
      const tempDisplayData = [
        {
          name: stPerformanceDataVariables.caseDescription,
          case1: performanceData
            ? performanceData.caseDescription
              ? performanceData.caseDescription.case1
              : ''
            : '',
          case2: performanceData
            ? performanceData.caseDescription
              ? performanceData.caseDescription.case2
              : ''
            : '',
          case3: performanceData
            ? performanceData.caseDescription
              ? performanceData.caseDescription.case3
              : ''
            : '',
          case4: performanceData
            ? performanceData.caseDescription
              ? performanceData.caseDescription.case4
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.throttleSteamFlow,
          unit: performanceData
            ? performanceData.throttleSteamFlow
              ? performanceData.throttleSteamFlow.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.throttleSteamFlow
              ? performanceData.throttleSteamFlow.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.throttleSteamFlow
              ? performanceData.throttleSteamFlow.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.throttleSteamFlow
              ? performanceData.throttleSteamFlow.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.throttleSteamFlow
              ? performanceData.throttleSteamFlow.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.suctionPressure,
          unit: performanceData
            ? performanceData.suctionPressure
              ? performanceData.suctionPressure.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.suctionPressure
              ? performanceData.suctionPressure.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.suctionPressure
              ? performanceData.suctionPressure.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.suctionPressure
              ? performanceData.suctionPressure.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.suctionPressure
              ? performanceData.suctionPressure.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.suctionTemperature,
          unit: performanceData
            ? performanceData.suctionTemperature
              ? performanceData.suctionTemperature.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.suctionTemperature
              ? performanceData.suctionTemperature.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.suctionTemperature
              ? performanceData.suctionTemperature.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.suctionTemperature
              ? performanceData.suctionTemperature.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.suctionTemperature
              ? performanceData.suctionTemperature.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.exhaustPressure,
          unit: performanceData
            ? performanceData.exhaustPressure
              ? performanceData.exhaustPressure.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.exhaustPressure
              ? performanceData.exhaustPressure.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.exhaustPressure
              ? performanceData.exhaustPressure.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.exhaustPressure
              ? performanceData.exhaustPressure.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.exhaustPressure
              ? performanceData.exhaustPressure.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.exhaustQuality,
          unit: performanceData
            ? performanceData.exhaustQuality
              ? performanceData.exhaustQuality.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.exhaustQuality
              ? performanceData.exhaustQuality.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.exhaustQuality
              ? performanceData.exhaustQuality.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.exhaustQuality
              ? performanceData.exhaustQuality.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.exhaustQuality
              ? performanceData.exhaustQuality.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.powerOutput,
          unit: performanceData
            ? performanceData.powerOutput
              ? performanceData.powerOutput.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.powerOutput
              ? performanceData.powerOutput.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.powerOutput
              ? performanceData.powerOutput.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.powerOutput
              ? performanceData.powerOutput.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.powerOutput
              ? performanceData.powerOutput.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.rotationalSpeed,
          unit: performanceData
            ? performanceData.rotationalSpeed
              ? performanceData.rotationalSpeed.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.rotationalSpeed
              ? performanceData.rotationalSpeed.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.rotationalSpeed
              ? performanceData.rotationalSpeed.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.rotationalSpeed
              ? performanceData.rotationalSpeed.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.rotationalSpeed
              ? performanceData.rotationalSpeed.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.steamRate,
          unit: performanceData
            ? performanceData.steamRate
              ? performanceData.steamRate.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.steamRate
              ? performanceData.steamRate.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.steamRate
              ? performanceData.steamRate.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.steamRate
              ? performanceData.steamRate.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.steamRate
              ? performanceData.steamRate.case4?.design
              : ''
            : '',
        },
        {
          name: stPerformanceDataVariables.outputTorque,
          unit: performanceData
            ? performanceData.outputTorque
              ? performanceData.outputTorque.unit
              : ''
            : '',
          case1: performanceData
            ? performanceData.outputTorque
              ? performanceData.outputTorque.case1?.design
              : ''
            : '',
          case2: performanceData
            ? performanceData.outputTorque
              ? performanceData.outputTorque.case2?.design
              : ''
            : '',
          case3: performanceData
            ? performanceData.outputTorque
              ? performanceData.outputTorque.case3?.design
              : ''
            : '',
          case4: performanceData
            ? performanceData.outputTorque
              ? performanceData.outputTorque.case4?.design
              : ''
            : '',
        },
      ];
      this.setState({
        displayData: _.cloneDeep(tempDisplayData),
        copyDisplayData: _.cloneDeep(tempDisplayData),
      });
    }
  }
  componentDidUpdate(prevProps) {
    const {
      isPerformanceAddSuccess,
      isPerformanceAddError,
      clearPerformanceData,
    } = this.props;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnit();
    }

    if (
      isPerformanceAddSuccess &&
      prevProps.isPerformanceAddSuccess !== isPerformanceAddSuccess
    ) {
      console.log('succ');
      clearPerformanceData();
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, PerformanceDataMessages.SUCCESS);
    }
    if (
      isPerformanceAddError &&
      prevProps.isPerformanceAddError !== isPerformanceAddError
    ) {
      Notification.show(Types.Error, PerformanceDataMessages.ERROR);
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'case1') {
        if (item.name === 'Case Description') {
          return {
            name,
            case1: val,
            case2: item.case2,
            case3: item.case3,
            case4: item.case4,
          };
        } else {
          return {
            name,
            unit: item.unit,
            case1: val,
            case2: item.case2,
            case3: item.case3,
            case4: item.case4,
          };
        }
      }
      if (item.name === name && type === 'case2') {
        if (item.name === 'Case Description') {
          return {
            name,
            case1: item.case1,
            case2: val,
            case3: item.case3,
            case4: item.case4,
          };
        } else {
          return {
            name,
            unit: item.unit,
            case1: item.case1,
            case2: val,
            case3: item.case3,
            case4: item.case4,
          };
        }
      }
      if (item.name === name && type === 'case3') {
        if (item.name === 'Case Description') {
          return {
            name,
            case1: item.case1,
            case2: item.case2,
            case3: val,
            case4: item.case4,
          };
        } else {
          return {
            name,
            unit: item.unit,
            case1: item.case1,
            case2: item.case2,
            case3: val,
            case4: item.case4,
          };
        }
      }
      if (item.name === name && type === 'case4') {
        if (item.name === 'Case Description') {
          return {
            name,
            case1: item.case1,
            case2: item.case2,
            case3: item.case3,
            case4: val,
          };
        } else {
          return {
            name,
            unit: item.unit,
            case1: item.case1,
            case2: item.case2,
            case3: item.case3,
            case4: val,
          };
        }
      }
      if (item.name === name && type === 'unit') {
        return {
          name,
          unit: val,
          case1: item.case1,
          case2: item.case2,
          case3: item.case3,
          case4: item.case4,
        };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        saveListener: true,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    const editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onSave = () => {
    const {
      location: { state },
      addPerformanceData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
    });
    const data = ChangeKey;
    const payload = {};
    displayData.forEach((item) => {
      if (item.name !== stPerformanceDataVariables.caseDescription) {
        payload[data[item.name]] = {
          unit: item.unit,
          case1: { design: item.case1 },
          case2: { design: item.case2 },
          case3: { design: item.case3 },
          case4: { design: item.case4 },
        };
      } else {
        payload[data[item.name]] = {
          case1: item.case1,
          case2: item.case2,
          case3: item.case3,
          case4: item.case4,
        };
      }
    });
    if (
      (this.handleNetwork() && state && state.componentData.info._id, payload)
    ) {
      addPerformanceData(
        state.componentData.info._id,
        payload,
        'Steam Turbine'
      );
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }
  render() {
    const { isSchematicEditable } = this.props;
    // const {
    //   gasTurbineConfigData,
    //   addPerformanceData,
    // } = this.props.gasTurbineReducer;
    const { saveListener, unitData } = this.state;
    const { loading } = this.props.dataUnitState;
    return (
      <div className="PerformanceData">
        {loading && <Loader />}
        <CustomTable
          showHeader
          columns={PerformanceDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}
PerformanceDataGasTurbine.PropType = {
  location: PropType.object,
  gasTurbineConfigData: PropType.object,
  addPerformanceData: PropType.object,
  savePerformanceDataReset: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
  isSchematicEditable: PropType.bool,
  clearPerformanceData: PropType.func,
};
PerformanceDataGasTurbine.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
  performanceErrorMessage: state.DesignReducer.apiState.message,
  isPerformanceAddError: state.DesignReducer.apiState.isPerformanceError,
  isPerformanceAddSuccess: state.DesignReducer.apiState.isPerformanceAddSuccess,
});

const mapsDispatchToProps = (dispatch) => ({
  addPerformanceData: (componentId, payload, type = 'Steam Turbine') =>
    dispatch(
      addPerformanceData(componentId, payload, (type = 'Steam Turbine'))
    ),
  clearPerformanceData: () => dispatch(clearPerformanceData()),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(PerformanceDataGasTurbine)
);
