import {
  KPI_BY_LEVEL_FETCH_SUCCESS,
  KPI_BY_LEVEL_FETCH_PROGESS,
  KPI_BY_LEVEL_FETCH_ERROR,
  KPI_BY_LEVEL_CLEAR_STATE,
  DELETE_KPI_BY_KPIID_SUCCESS,
  ADD_ADDNEWCARD_SUCCESS,
  UPDATE_KPI_REDUCER,
  CLEAR_PLOTS_STATE,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
  isSuccessEdit: false,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KPI_BY_LEVEL_FETCH_PROGESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case KPI_BY_LEVEL_FETCH_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case KPI_BY_LEVEL_FETCH_SUCCESS:
      // working, TODO delete other reducer of same type
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: action.payload,
      };
    case DELETE_KPI_BY_KPIID_SUCCESS: {
      if (state.data && state.data.data) {
        state.data.data.find(
          (card, i) =>
            card._id === action.payload.kpiId && state.data.data.splice(i, 1)
        );
      }
      return {
        ...state,
      };
    }
    case ADD_ADDNEWCARD_SUCCESS: {
      // if(card type !== trends) TODO
      const dataAppend = state.data.data;
      dataAppend.push(action.payload);
      return {
        ...state,
        ...INITIAL_API_STATE,
        data: {
          data: dataAppend,
        },
      };
    }

    case KPI_BY_LEVEL_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
        data: state.data,
      };

    case UPDATE_KPI_REDUCER: {
      return {
        ...INITIAL_STATE,
        data: action.payload,
        isSuccessEdit: true,
      };
    }

    case CLEAR_PLOTS_STATE: {
      return {
        ...state,
        isSuccessEdit: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
