const runKPIData = {
  "runKPIconst": ["Up-time (hours)", "Down-time (hours)", "MTTF: Mean time to failure (hours)", "Total Up-time",
    "Total Down-time", "METBF: Mean elapsed time between failures (hours)", "MUT: Mean Up-time (hours)",
    "MDT: Mean Down-time (hours)", "Mean / Overall Availability","Planned Down-time (hours)", "Unplanned Down-time (hours)",
    "Ao: Operational Availability","Availability", "Available Time  (hours)", "MTBR: Mean time between repairs  (hours)",
    "Total run cycle time" , "Total Available time","MART: Mean Active Repair time  (hours)","NS: Number of starts", "No. of Failures",
    "λ: Failure rate", ],
  "Failure_Maintenance": ["Reason for stoppage", "Failure Detection Method", "Maintenance activity performed", "Maintenance Start Time",
    "Failure Mechanism", "Maintenance Activity", "Maintenance End Time", "Failure Cause", "Failure Mode",
    "Active Repair Time  (hours)", "Machine available after maintenance",]
};


export default runKPIData;