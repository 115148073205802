import { get } from "../../libs/storage";

const labels = {
  statusTypeLabels: {
    MechademyAdmin: {
      labelConstants: [
        "New",
        "Forward",
        "Close",
        "Shelved",
        "Disable",
        "Discard",
      ],
      colorConstants: [
        "#E84C3D",
        "#6BD098",
        "#B9B9B9",
        "#FBC658",
        "#51CBCE",
        "#66615B",
      ],
    },
    ClientAdmin: {
      labelConstants: [
        "New",
        "Acknowledged",
        "Close",
        "Shelved",
        "Shelved Not Delivered",
      ],
      colorConstants: ["#E84C3D", "#6BD098", "#B9B9B9", "#EF8157"],
    },
  },
  ClientAdmin: {
    labelConstants: ["New", "Acknowledged", "Close", "Shelved"],
    colorConstants: ["#E84C3D", "#6BD098", "#B9B9B9", "#EF8157"],
  },
  priorityTypeLabels: {
    labelConstants: ["High", "Medium", "Low", "Unknown"],
    colorConstants: ["#E84C3D", "#EF8157", "#FBC658", "#B9B9B9"],
  },
  FailureTypeLabels: {
    labelConstants: [
      "Design",
      "Fabrication/ installation",
      "Operation/ maintenance",
      "Management",
      "Miscellaneous",
    ],
    colorConstants: ["#EF8157", "#B9B9B9", "#FBC658", "#6BD098", "#51CBCE"],
    count: [0, 0, 0, 0, 0],
  },
  FailureTypeTornadoLabels: {
    labelConstants: {
      System: "System",
      Design: "Design",
      "Fabrication/Installation": "Fabrication/Installation",
      "Operation/Maintenance": "Operation/Maintenance",
      Management: "Management",
      Miscellaneous: "Miscellaneous",
    },
    colorConstants: [
      "#51BCDA",
      "#EF8157",
      "#B9B9B9",
      "#FBC658",
      "#6BD098",
      "#51CBCE",
    ],
    count: [0, 0, 0, 0, 0, 0],
  },
};

export const setLabels = (viewDataAdmin = null) => {
  let level = [];
  let count = [0, 0, 0, 0];
  if (viewDataAdmin) {
    if (viewDataAdmin === "Mechademy") {
      level = labels.statusTypeLabels.MechademyAdmin.labelConstants;
      count = [0, 0, 0, 0, 0, 0];
    } else {
      level = labels.statusTypeLabels.ClientAdmin.labelConstants;
    }
    return {
      level,
      count,
    };
  }
  const user = get("USER");
  if (user.role[0] === "MechademyAdmin") {
    level = labels.statusTypeLabels.MechademyAdmin.labelConstants;
    count = [0, 0, 0, 0, 0, 0];
  } else {
    level = labels.statusTypeLabels.ClientAdmin.labelConstants;
  }
  return {
    level,
    count,
  };
};

export default labels;
