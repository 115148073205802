import Notification, { Types } from '../../../../../../../common/Notification/Notification';
import { screwCompValidation } from '../curveConstants';

const ValidateCurve = (graphName, curveUnits, asTestedCurve, operationalBaseLineCurves) => {
    let validationError = false
    if(asTestedCurve.length < 3 || operationalBaseLineCurves.length < 3){
        validationError = true
        Notification.show(Types.Error, screwCompValidation.minData);
    }
    let payload = {
        curveName: graphName,
        curveUnits: curveUnits.asTestedCurve,
        asTestedCurve: asTestedCurve,
        operationalBaseLineCurves: operationalBaseLineCurves
    }
    return { validationError, payload}
}

export default ValidateCurve