import PropTypes from "prop-types";
import React from "react";
import check from "../../assets/ic_checked/check.svg";
import cross from "../../assets/ic_cross/cross.svg";
import { ButtonVariables } from "../../constants/variables";
import "./Filter.scss";

const DefaultFilterDropDownComponent = (props) => {
  const {
    headLabels,
    childLabels,
    expandedIndex,
    onApply,
    onHeadPress,
    onChildPress,
    onReset,
    onClose,
  } = props;
  return (
    <ul
      className="dropdown-menu show"
      x-placement="bottom-start"
      style={{
        position: "absolute",
        willChange: "transform",
        top: "0px",
        left: "0px",
        transform: "translate3d(-131px, 40px, 0px)",
      }}
    >
      <div className="apply-filter-header">
        <span>{ButtonVariables.FILTER}</span>
        <span className="filter-close" onClick={onClose}>
          <img alt="" src={cross} />
        </span>
      </div>
      <span className="filter-container">
        <div className="filter-head-labels">
          <ul>
            {headLabels.map((headItem, headIndex) => (
              <li className="dropdown-submenu" key={headIndex}>
                <label
                  className={`dropdown-head ${
                    headIndex === expandedIndex ? "menu-drop" : ""
                  }`}
                  tabIndex="-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => onHeadPress(headIndex)}
                >
                  <span>{headItem}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="filter-child-labels">
          <ul>
            {headLabels.map((_headItem, headIndex) => (
              <ul
                className="dropdown-menu dropdown-list"
                style={{
                  display: headIndex === expandedIndex ? "block" : "none",
                }}
                key={headIndex}
              >
                {Object.keys(childLabels[headIndex]).map((childItem) => (
                  <li key={childItem}>
                    <label className="type">
                      <input
                        type="checkbox"
                        checked={childLabels[headIndex][childItem]}
                        onChange={(e) => {
                          onChildPress(headLabels[headIndex], childItem);
                        }}
                      />
                      <span
                        className={
                          childLabels[headIndex][childItem] ? " filter-label" : undefined
                        }
                      >
                        {childItem}
                      </span>
                      <span className="check-img">
                        {childLabels[headIndex][childItem] && (
                          <img alt="" src={check} />
                        )}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            ))}
          </ul>
        </div>
      </span>
      <div className="apply-filter">
        <span className="reset" onClick={onReset}>
          {ButtonVariables.RESET}
        </span>
        <span className="btn btn-default" onClick={onApply}>
          {ButtonVariables.APPLY}
        </span>
      </div>
    </ul>
  );
};

DefaultFilterDropDownComponent.propTypes = {
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
  onHeadPress: PropTypes.func,
  onChildPress: PropTypes.func,
  expandedIndex: PropTypes.number,
  childLabels: PropTypes.array.isRequired,
  headLabels: PropTypes.array.isRequired,
};

DefaultFilterDropDownComponent.defaultProps = {
  headLabels: ["High", "Low"],
  childItem: ["Level", "Type"],
};

export default DefaultFilterDropDownComponent;
