import * as Type from "../Types/piTagUpload.types";
import { MANAGE_TAGS_MESSAGE } from "../../constants/messages";

const piTagList = {
  isLoading: false,
  isSuccess: false,
  hasPrev: false,
  hasNext: false,
  item_count: 0,
  total_count: 0,
  baseIntervalTime: "",
  data: [],
  skip: 0,
  limit: 0,
  error: "",
  apiMessage: "",
  errorCode: "",
};

const piTagLogsData = {
  isLoading: false,
  isSuccess: false,
  item_count: 0,
  total_count: 0,
  data: [],
  skip: 0,
  limit: 0,
  error: '',
  apiMessage: '',
  errorCode: '',
};

const piTagDelete = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  list: [],
  error: "",
  apiMessage: "",
  errorCode: "",
  errorIds: [],
};

const piTagUpload = {
  isLoading: false,
  isSuccess: false,
  errorCSV: "",
  error: [],
  errorCode: "",
};

const piTagSave = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  apiMessage: "",
  data: null,
  errorCode: "",
};

const piTagUpdate = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: "",
  apiMessage: ""
}

const tagUpdate = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: "",
  apiMessage: ""
}

const baseTimeUpdate = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: "",
  apiMessage: ""
}

const INITIAL_STATE = {
  piTagList: { ...piTagList },
  piTagUpload: { ...piTagUpload },
  piTagDelete: { ...piTagDelete },
  piTagSave: { ...piTagSave },
  piTagUpdate: { ...piTagUpdate },
  tagUpdate: { ...tagUpdate },
  baseTimeUpdate: { ...baseTimeUpdate },
  piTagLogsData: { ...piTagLogsData },
};

export default function piTagReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.TAG_UPDATE_PROGRESS:
      return {
        ...state,
        tagUpdate: {
          ...state.tagUpdate,
          isLoading: true,
          isSuccess: false,
          isError: false,
          apiMessage: '',
        },
      };
    case Type.TAG_UPDATE_SUCCESS:
      return {
        ...state,
        tagUpdate: {
          ...INITIAL_STATE.tagUpdate,
          isSuccess: true,
          isError: false,
          data: action.payload,
        },
      };
    case Type.TAG_UPDATE_ERROR:
      return {
        ...state,
        tagUpdate: {
          ...INITIAL_STATE.tagUpdate,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.PI_TAG_GET_LIST_PROGRESS:
      return {
        ...state,
        piTagList: {
          ...state.piTagList,
          isLoading: true,
          apiMessage: '',
        },
      };
    case Type.PI_TAG_GET_LIST_SUCCESS:
      return {
        ...state,
        piTagList: {
          ...state.piTagList,
          isLoading: false,
          isSuccess: true,
          skip: action.payload.skip,
          baseIntervalTime:
            action.payload.items && action.payload.items.length > 0
              ? action.payload.items[0].facilityId.baseIntervalTime
              : '',
          data: [...action.payload.items],
          limit: action.payload.limit,
          hasPrev: action.payload.hasPrev,
          hasNext: action.payload.hasNext,
          apiMessage: action.payload.message,
          item_count: action.payload.item_count,
          total_count: action.payload.total_count,
        },
      };
    case Type.PI_TAG_GET_LIST_ERROR:
      return {
        ...state,
        piTagList: {
          ...INITIAL_STATE.piTagList,
          isLoading: false,
          isSuccess: false,
          isError: true,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };
    case Type.PI_TAG_GET_LIST_CLEAR:
      return {
        ...state,
        piTagList: {
          ...state.piTagList,
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
      };

    case Type.PI_TAG_LIST_UPDATE:
      const filteredData = [...state.piTagList.data].filter(tag => tag.piTag !== action.payload.piTag)
      return {
        ...state,
        piTagList: {
          ...state.piTagList,
          baseIntervalTime: filteredData.length > 0 
            ? filteredData[0].facilityId.baseIntervalTime
            : '',
          data: filteredData,
          item_count: state.piTagList.item_count-1,
          total_count: state.piTagList.total_count-1
        }
      }

    case Type.PI_TAG_LOG_GET_LIST_PROGRESS:
      return {
        ...state,
        piTagLogsData: {
          ...INITIAL_STATE.piTagLogsData,
          isLoading: true,
          apiMessage: '',
        },
      };
    case Type.PI_TAG_LOG_GET_LIST_SUCCESS:
      return {
        ...state,
        piTagLogsData: {
          ...INITIAL_STATE.piTagLogsData,
          isLoading: false,
          isSuccess: true,
          data: [...action.payload.result.modifiedLogs],
          apiMessage: action.payload.message,
          total_count: action.payload.result.totalCount,
        },
      };
    case Type.PI_TAG_LOG_GET_LIST_CLEAR:
      return {
        ...state,
        piTagLogsData: {
          ...INITIAL_STATE.piTagLogsData,
          isLoading: true,
          isSuccess: false,
          isError: false,
          apiMessage:"",
          data: [],
        },
      };

    case Type.PI_TAG_LOG_GET_LIST_ERROR:
      return {
        ...state,
        piTagLogsData: {
          ...INITIAL_STATE.piTagLogsData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          apiMessage: action.payload.result && action.payload.result.message,
          errorCode: action.payload.result && action.payload.result.code,
        },
      };
    case Type.PI_TAG_UPLOAD_PROGRESS:
      return {
        ...state,
        piTagUpload: {
          ...INITIAL_STATE.piTagUpload,
          apiMessage: '',
          isLoading: true,
        },
      };
    case Type.PI_TAG_UPLOAD_SUCCESS:
      return {
        ...state,
        piTagUpload: {
          ...INITIAL_STATE.piTagUpload,
          isSuccess: true,
          apiMessage: action.payload.message,
          isLoading: false,
          errorCSV: action.payload.errorCSV,
          error: action.payload.error,
        },
      };
    case Type.PI_TAG_UPLOAD_ERROR:
      return {
        ...state,
        piTagUpload: {
          ...INITIAL_STATE.piTagUpload,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          isError: true,
        },
      };
    case Type.PI_TAG_UPLOAD_CLEAR:
      return {
        ...state,
        piTagUpload: {
          ...INITIAL_STATE.piTagUpload,
        },
      };
    case Type.PI_TAG_SAVE_PROGRESS:
      return {
        ...state,
        piTagSave: {
          ...INITIAL_STATE.piTagSave,
          isLoading: true,
        },
      };
    case Type.PI_TAG_SAVE_SUCCESS:
      return {
        ...state,
        piTagSave: {
          ...state.piTagSave,
          isLoading: false,
          isSuccess: true,
          apiMessage: action.payload.message,
          data: action.payload.data,
        },
      };
    case Type.PI_TAG_SAVE_ERROR: {
      return {
        ...state,
        piTagSave: {
          ...state.piTagSave,
          isLoading: false,
          isError: true,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          data: action.payload.data,
        },
      };
    }
    case Type.PI_TAG_SAVE_CLEAR:
      return {
        ...state,
        piTagSave: {
          ...INITIAL_STATE.piTagSave,
        },
      };
    case Type.PT_TAG_DELETE_PROGRESS:
      return {
        ...state,
        piTagDelete: {
          ...INITIAL_STATE.piTagDelete,
          isLoading: true,
        },
      };
    case Type.PT_TAG_DELETE_SUCCESS:
      return {
        ...state,
        piTagDelete: {
          ...INITIAL_STATE.piTagDelete,
          isSuccess: true,
          isSuccessApi: action.payload.isSuccess,
          isErrorApi: action.payload.isError,
          errorIds: action.payload.errorIds,
          apiMessage: MANAGE_TAGS_MESSAGE.PI_TAG_DELETE_SUCCESS,
          data: action.payload.data,
        },
      };
    case Type.PT_TAG_DELETE_ERROR:
      return {
        ...state,
        piTagDelete: {
          ...INITIAL_STATE.piTagDelete,
          isLoading: false,
          isError: true,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          data: action.payload.data,
        },
      };
    case Type.PI_TAG_UPDATE_PROGRESS:
      return {
        ...state,
        piTagUpdate: {
          ...INITIAL_STATE.piTagUpdate,
          isLoading: true,
        },
      };
    case Type.PI_TAG_UPDATE_SUCCESS:
      return {
        ...state,
        piTagUpdate: {
          ...INITIAL_STATE.piTagUpdate,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Type.PI_TAG_UPDATE_ERROR:
      return {
        ...state,
        piTagUpdate: {
          ...INITIAL_STATE.piTagUpdate,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.PI_TAG_UPDATE_CLEAR:
      return {
        ...state,
        piTagUpdate: {
          ...INITIAL_STATE.piTagUpdate,
          isSuccess: false,
          isError: false
        }
      }
    case Type.PT_TAG_DELETE_CLEAR:
      return {
        ...state,
        piTagDelete: {
          ...INITIAL_STATE.piTagDelete,
        },
      };
    case Type.CLEAR_PI_TAG_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    case Type.TAG_BASETIME_UPDATE_PROGRESS:
      return {
        ...state,
        baseTimeUpdate: {
          ...state.baseTimeUpdate,
          isLoading: true,
          isSuccess: false,
          isError: false,
          apiMessage: '',
        },
      };
    case Type.TAG_BASETIME_UPDATE_SUCCESS:
      return {
        ...state,
        baseTimeUpdate: {
          ...INITIAL_STATE.baseTimeUpdate,
          isSuccess: true,
          isError: false,
          isLoading:false,
          data: action.payload,
        },
      };
    case Type.TAG_BASETIME_UPDATE_ERROR:
      return {
        ...state,
        baseTimeUpdate: {
          ...INITIAL_STATE.baseTimeUpdate,
          isError: true,
          isSuccess: false,
          isLoading:false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
        },
      };
    default:
      return state;
  }
}
