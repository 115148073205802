import {
  FLUID_COMPOSITION_CLEAR_STATE,
  FLUID_COMPOSITION_FETCH_ERROR,
  FLUID_COMPOSITION_FETCH_PROGRESS,
  FLUID_LIST_FETCH_SUCCESS,
  ADD_FLUID_FETCH_SUCCESS,
  ADD_FLUID_FETCH_ERROR,
  ADD_FLUID_FETCH_PROGRESS,
} from "../Types/fluidComposition.types";
import { GETRequest, PUTRequest } from "../../api/request";

export function getFluidList(query) {
  return function (dispatch) {
    dispatch({ type: FLUID_COMPOSITION_FETCH_PROGRESS });
    GETRequest("fluids", query)
      .then((res) => {
        dispatch({ type: FLUID_LIST_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: FLUID_COMPOSITION_FETCH_ERROR, payload: err });
      });
  };
}

export function addFluid(payload, id, type) {
  return function (dispatch) {
    dispatch({ type: ADD_FLUID_FETCH_PROGRESS });
    const componentAPIMapper = {
      "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/fluidcomposition`,
      "Centrifugal compressor": `components/${id}/compressorconfigs/fluidcomposition`,
      "Centrifugal pump": `components/${id}/pumpconfigs/fluidcomposition`,
      "Flow meter": `components/${id}/flowmeterconfigs/fluidcomposition`,
      "Steam Turbine": `components/${id}/stconfigs/fluidcomposition`,
      "Control Valve":`components/${id}/valveConfigs/fluidcomp`,
      "Reciprocating Compressor": `components/${id}/rcconfigs/fluidcomposition`,
      "Pipeline Inventory Management":`components/${id}/pimconfigs/fluidComp`,
      "Pipeline Inventory Monitoring":`components/${id}/pimconfigs/fluidComp`,
      "Screw Compressor": `components/${id}/scconfigs/fluidcomposition`,
    };
    PUTRequest(componentAPIMapper[type], payload)
      .then((res) => {
        dispatch({ type: ADD_FLUID_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_FLUID_FETCH_ERROR, payload: err });
      });
  };
}

export function clearFluidCompositionState() {
  return function (dispatch) {
    dispatch({ type: FLUID_COMPOSITION_CLEAR_STATE });
  };
}
