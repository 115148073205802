import {
  INVITATION_LINK_ERROR,
  INVITATION_LINK_PROGRESS,
  INVITATION_LINK_SUCCESS,
  RESET_DATA,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_PROGRESS,
  RESET_PASSWORD_SUCCESS,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_PROGRESS,
  USER_CHANGE_STATUS_SUCCESS,
  USER_CLEAR,
  USER_EDIT_ERROR,
  USER_EDIT_PROGRESS,
  USER_EDIT_SUCCESS,
  USER_FETCH_ERROR,
  USER_FETCH_PROGRESS,
  USER_FETCH_SUCCESS,
  USER_SAVE_ERROR,
  USER_SAVE_PROGRESS,
  USER_SAVE_SUCCESS,
} from "../Types/UserManagement.types";

const INITIAL_STATE = {
  userData: "",
  isUserFetchSuccess: false,
  isUserFetchError: false,
  errorMessageUserFetch: "",
  saveUser: "",
  isSaveUserSuccess: false,
  isSaveUserError: false,
  errorMessageSaveUser: "",
  changeStatus: "",
  isChangeStatusSuccess: false,
  isChangeStatusError: false,
  errormessageChangeStatus: "",
  editUser: "",
  isEditUserSuccess: false,
  isEditUserError: false,
  errorMessageEditUser: "",
  invitationLink: "",
  isInvitationLinkSuccess: false,
  isInvitationLinkError: false,
  errorMessageInvitationLink: "",
  resetPassword: "",
  isResetPasswordSuccess: false,
  isResetPasswordError: false,
  errorMessageResetPassword: "",
  loading: false,
};

export default function userManagementReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_FETCH_PROGRESS:
      return { ...state, loading: true };

    case USER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserFetchSuccess: true,
        userData: action.payload,
      };

    case USER_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMessageUserFetch:
          action.payload.response && action.payload.response,
        isUserFetchError: true,
      };

    case USER_SAVE_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case USER_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaveUserSuccess: true,
        saveUser: action.payload,
      };
    case USER_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        isSaveUserError: true,
        errorMessageSaveUser:
          action.payload.response && action.payload.response,
      };

    case USER_CLEAR:
      return {
        ...state,
        isUserFetchSuccess: false,
        isUserFetchError: false,
        isSaveUserSuccess: false,
        isSaveUserError: false,
        isChangeStatusSuccess: false,
        isChangeStatusError: false,
        isEditUserSuccess: false,
        isEditUserError: false,
        isInvitationLinkSuccess: false,
        isInvitationLinkError: false,
        isResetPasswordSuccess: false,
        isResetPasswordError: false,
      };

    case USER_CHANGE_STATUS_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case USER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isChangeStatusSuccess: true,
        changeStatus: action.payload,
      };
    case USER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        isChangeStatusError: true,
        errormessageChangeStatus:
          action.payload.response && action.payload.response,
      };
    case USER_EDIT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case USER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isEditUserSuccess: true,
        editUser: action.payload,
      };
    case USER_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        isEditUserError: true,
        errorMessageEditUser:
          action.payload.response && action.payload.response,
      };

    case INVITATION_LINK_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case INVITATION_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        invitationLink: action.payload,
        isInvitationLinkSuccess: true,
      };
    case INVITATION_LINK_ERROR:
      return {
        ...state,
        loading: false,
        errorMessageInvitationLink:
          action.payload.response && action.payload.response,
        isInvitationLinkError: true,
      };

    case RESET_PASSWORD_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPassword: action.payload,
        isResetPasswordSuccess: true,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        errorMessageResetPassword:
          action.payload.response && action.payload.response,
        isResetPasswordError: true,
      };
    case RESET_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
