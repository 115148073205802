import { Icon, Input } from "antd";
import React from "react";
import { PerformanceDataVariables } from "../../../../../../constants/variables";
import { validateNumber } from "../../../../../../libs/validator";

export const PerformanceDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (value === PerformanceDataVariables.fullLoadSpeed) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "Units",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === PerformanceDataVariables.fullLoadSpeed &&
          !item.unit &&
          config.saveListener) ||
        (!item.unit &&
          (item.design || item.design === 0) &&
          config.saveListener)
      );
      return (
        <div
          className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
            tempError ? "error" : "not-error"
          } `}
        >
          <select
            value={!item.unit ? "" : item.unit}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "unit")
            }
          >
            <option value="">Select</option>
            {config.unitData.map((getUnits) => {
              if (getUnits.dataVariable === item.name) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    title: "Design",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <div>
        <Input
          type="number"
          value={item.design}
          disabled={disabled}
          AutoComplete="off"
          onKeyDown={(e) => {
            validateNumber(e, value);
          }}
          onChange={(e) =>
            config.handleChange(e.target.value, item.name, "number")
          }
          className={
            (item.name === PerformanceDataVariables.fullLoadSpeed &&
              !item.design &&
              item.design !== 0 &&
              config.saveListener) ||
            (!item.design &&
              item.unit &&
              item.design !== 0 &&
              config.saveListener)
              ? "error"
              : "not-error"
          }
        />
      </div>
    ),
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  {
    name: PerformanceDataVariables.ratedPowerOutput,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.ratedCurrent,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.synchronousSpeed,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.fullLoadSpeed,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.fullLoadTorque,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.rotorGD2AtFLRPM,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.rotorWeight,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.lockedRotortorque,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.pullInTorque,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.pullOutTorque,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.lockedRotorCurrent,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.lockedRotorPF,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.lockedRotorWithstandTimeCold,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.lockedRotorWithstandTimeRatedTemp,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.ratedMotorFieldCurrent,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.ratedMotorFieldVoltage,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.ratedExciterFieldCurrent,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.ratedExciterFieldVoltage,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: PerformanceDataVariables.temperatureRiseAtZeroCurrent,
    unit: null,
    design: parseFloat(""),
  },
];

export const ChangeKey = {
  [PerformanceDataVariables.ratedPowerOutput]: "ratedPowerOutput",
  [PerformanceDataVariables.ratedCurrent]: "ratedCurrent",
  [PerformanceDataVariables.synchronousSpeed]: "synchronousSpeed",
  [PerformanceDataVariables.fullLoadSpeed]: "fullLoadSpeed",
  [PerformanceDataVariables.fullLoadTorque]: "fullLoadTorque",
  [PerformanceDataVariables.rotorGD2AtFLRPM]: "rotorGD2AtFLRPM",
  [PerformanceDataVariables.rotorWeight]: "rotorWeight",
  [PerformanceDataVariables.lockedRotortorque]: "lockedRotortorque",
  [PerformanceDataVariables.pullInTorque]: "pullInTorque",
  [PerformanceDataVariables.pullOutTorque]: "pullOutTorque",
  [PerformanceDataVariables.lockedRotorCurrent]: "lockedRotorCurrent",
  [PerformanceDataVariables.lockedRotorPF]: "lockedRotorPF",
  [PerformanceDataVariables.lockedRotorWithstandTimeCold]:
    "lockedRotorWithstandTimeCold",
  [PerformanceDataVariables.lockedRotorWithstandTimeRatedTemp]:
    "lockedRotorWithstandTimeRatedTemp",
  [PerformanceDataVariables.ratedMotorFieldCurrent]: "ratedMotorFieldCurrent",
  [PerformanceDataVariables.ratedMotorFieldVoltage]: "ratedMotorFieldVoltage",
  [PerformanceDataVariables.ratedExciterFieldCurrent]:
    "ratedExciterFieldCurrent",
  [PerformanceDataVariables.ratedExciterFieldVoltage]:
    "ratedExciterFieldVoltage",
  [PerformanceDataVariables.temperatureRiseAtZeroCurrent]:
    "temperatureRiseAtZeroCurrent",
};
