import {
  AUTH_ACCESS_TOKEN,
  AUTH_REFRESH_TOKEN,
  setRefreshToken,
  setToken,
} from '../libs/token';
import axios from 'axios';



export const RefreshAuthToken = () => {
 const AUTH0_REFRESH_TOKEN_TIME = process.env.REACT_APP_AUTH0_REFRESH_TOKEN_TIME
  setInterval(async () => {
    const options = {
      method: 'POST',
      url: process.env.REACT_APP_AUTH0_REFRESH_DOMAIN,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        code: localStorage.getItem(AUTH_ACCESS_TOKEN),
        redirect_uri: `${process.env.REACT_APP_DOMAIN}/callback`,
        refresh_token: localStorage.getItem(AUTH_REFRESH_TOKEN),
      }),
    };
    
    const token = await axios.request(options)
    
    if (token.data) {
      const { access_token, refresh_token } = token.data;
      if (access_token && refresh_token) {
        const bearerToken = "Bearer " + access_token
        setToken(bearerToken);
        setRefreshToken(refresh_token);
      }
    }
  }, Number(AUTH0_REFRESH_TOKEN_TIME))
};
