import React, { createRef } from 'react';
import './MessagePanel.scss';
import PropTypes from 'prop-types';
import {  Spin } from 'antd';
import { getTimeInFormat } from '../../../../../../libs/valueFormatter';
import { UserRole } from "../../../../../AppScreen/AppScreen";
export default class MessagePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: [],
            loadMore: false,
        };
        this.messagesEnd = createRef();
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.pagination && !prevProps.pagination !== this.props.pagination) || this.props.send) {
            if (this.messagesEnd.current != null) {
                this.messagesEnd.current.scrollTop = this.messagesEnd.current.scrollHeight;
            }
        }

        if (this.props.load) {
            if (this.messagesEnd.current != null) {
                this.messagesEnd.current.scrollTop = this.messagesEnd.current.scrollHeight / 2.5;
            }
        }
    }


    getData = () => {
        const { messages, pagination, alertId } = this.props;
        this.setState({
            loadMore: true
        }, () => {
            messages({ alertId, limit: 5, skip: pagination }, 'load');
        })
    }
    getMessages = (data) => {
        
        const time = getTimeInFormat(data.timestamp);
        return (
            <div className={data.user ? data.user.role[0] === UserRole ? 'user' : 'non-user' : 'non-user'}>
                {/* <Avatar className={'avatar'}><span className={'role'}>{data.user ? data.user.role[0] === 'MechademyAdmin' ? 'MA' : data.user.firstName[0] + data.user.lastName[0] : 'null'}</span></Avatar> */}
                <span className={'username'}>{data.user ? data.user.role[0] === 'MechademyAdmin' ? 'Mechademy Admin' : `${data.user.firstName}${' '}${data.user.lastName} (${data.user.role[0]})` : null}</span>
                <span className={data.user ? data.user.role[0] === UserRole ? 'user-message' : 'non-user' : 'non-user'}>
                    <li>{data.message}</li>
                </span>
                <span className={'time'}>{time}</span>
            </div>
        )
    }

    render() {
        let messages = this.props.message;
        const { hasNext, loading } = this.props;
        return (
            <ul className={'MessagePanel'} ref={this.messagesEnd}>
                {loading ? <Spin /> :
                    hasNext && <li onClick={() => this.getData()} className={'load'}></li>}
                {
                    messages.map(item => this.getMessages(item))
                }

            </ul>

        );
    }
}

MessagePanel.propTypes = {
    alertId: PropTypes.string,
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    message: PropTypes.array,
    messages: PropTypes.func,
    pagination: PropTypes.number,
    send: PropTypes.bool,
    load: PropTypes.bool,
    loading: PropTypes.bool,
};
MessagePanel.defaultProps = {
    alertId: '',
    hasNext: false,
    hasPrev: false,
    message: [],
    messages: () => { },
    pagination: 0,
    send: false,
    load: false,
    loading: false,
};