import * as Types from "../Types/discussionThread.types";

const getMessage = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: [],
  hasPrev: null,
  hasNext: null,
  total_count: 0,
  message: null,
  error: null,
};

const addMessage = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  message: null,
  error: null,
};

const INITIAL_STATE = {
  getMessage: { ...getMessage },
  addMessage: { ...addMessage },
};

export default function DiscussionThreadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CREATE_MESSAGE_PROGRESS:
      return {
        ...state,
        addMessage: {
          ...state.addMessage,
          isLoading: true,
        },
      };

    case Types.CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        addMessage: {
          ...state.addMessage,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.CREATE_MESSAGE_ERROR:
      return {
        ...state,
        addMessage: {
          ...state.addMessage,
          isLoading: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.GET_MESSAGE_PROGRESS:
      return {
        ...state,
        getMessage: {
          ...state.getMessage,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };

    case Types.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        getMessage: {
          ...state.getMessage,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: [...action.payload.items],
          hasNext: action.payload.hasNext,
          hasPrev: action.payload.hasPrev,
          total_count: action.payload.item_count,
        },
      };

    case Types.GET_MESSAGE_ERROR:
      return {
        ...state,
        getMessage: {
          ...state.getMessage,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.CLEAR_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
}
