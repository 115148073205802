import { Chip, FormControl, Grid, InputLabel, MenuItem, Popover, Select, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignees, getLabels } from '../../../../../../redux/actions/alertDetails.action';
import { Box } from '@mui/system';
import _ from 'lodash';
import { alertActions, alertLevel } from '../../Alerts.constants';
import getAlertInfoMessage from './alertInfoMessageConstant';
import { getTimeInFormat } from '../../../../../../libs/valueFormatter';
import { roles } from '../../../../../../permissions';
import { Select as AntdSelect } from 'antd';
import { Markup } from 'interweave';
const { Option } = AntdSelect;


const alertInfoConstants = {
    "Status": "mechademyStatus",
    "Level": "level",
    "Alert Date & Time": "dateTime",
    "Type": "type",
    "Equipment": "equipment",
    "Tag": "variableTagNumber",
    "Tag Description": "variableName",
    "Alert Id": "fwdAlertId",
    "Event Name": "eventName",
    "Last Edited By": "lastEditedBy",
    "Last Edited At": "updatedAt"
}

const RenderAlertInfo = (data, stateData) => {
    let assigneeList = useSelector((state) => state.AlertAssigneesReducer);
    let alertLabelList = useSelector((state) => state.AlertLabelReducer);
    let EventDetailsReducer = useSelector((state) => state.EventDetailsReducer)

    const dispatch = useDispatch();
    const { identifierDetails } = data
    const { userRole } = stateData

    let equipmentTagNumber = identifierDetails.equipmentTagNumber ? identifierDetails.equipmentTagNumber : ""
    let equipmentName = identifierDetails.equipmentName ? identifierDetails.equipmentName : ""
    data.variableName = identifierDetails.variableName ? identifierDetails.variableName : ""
    data.variableTagNumber = identifierDetails.variableTagNumber ? identifierDetails.variableTagNumber : ""
    data.equipment = equipmentName + " " + equipmentTagNumber
    // data.type = data.type && data.type === "IQR threshold exceedance" ? "Univariate Anomaly Detection" :
    //     data.type ? data.type : ""
    const [showModal, setShowModal] = useState(null)
    const [allAssignees, setAllAssignees] = useState([])
    const [alertLabels, setAlertLabels] = useState([])
    const [eventName, setEventName] = useState('--')

    useEffect(() => {

        data['lastEditedBy'] =
            data.clientId && data.clientId.firstName && data.clientId.lastName
                ? `${data.clientId.firstName}  ${data.clientId.lastName}`
                : '--';

        if (!data['updatedAt'])
            data['updatedAt'] = '--'

        if (!data['fwdAlertId'])
            data['fwdAlertId'] = '--'


    }, [data]) // eslint-disable-line

    useEffect(() => {

        let EventName =
            EventDetailsReducer &&
                EventDetailsReducer.data &&
                EventDetailsReducer.data.eventName
                ? EventDetailsReducer.data.eventName
                : '--';

        setEventName(EventName)

    }, [EventDetailsReducer])

    useEffect(() => {
        // delete data['eventName'] 
        setEventName('--')
    }, [])// eslint-disable-line

    useEffect(() => {
        if (identifierDetails.equipmentId) {
            dispatch(getAssignees(identifierDetails.equipmentId))
            dispatch(getLabels())
        }
    }, [identifierDetails])// eslint-disable-line

    useEffect(() => {
        if (assigneeList.data && assigneeList.data.length) {
            setAllAssignees(assigneeList.data)
        }
        if (alertLabelList.data && alertLabelList.data.length) {
            setAlertLabels(alertLabelList.data)
        }
    }, [assigneeList.data, alertLabelList.data])

    const openPopover = (event) => {
        setShowModal(event.currentTarget);
    };

    const renderAlertLevel = () => {
        const { level, setAlertLevel } = stateData

        const get_alert_level_class = () => {
            let alert_level_css = ""
            if (level === "High") {
                alert_level_css = 'high_level_alert'
            }
            else if (level === "Medium") {
                alert_level_css = 'medium_level_alert'
            }
            else {
                alert_level_css = 'low_level_alert'
            }
            return alert_level_css
        }
        const levels = alertLevel.map((data, index) => {
            return (
                <MenuItem id={index} value={data}><span className={get_alert_level_class()}>{data}</span></MenuItem>
            )
        })
        return (
            <FormControl fullWidth className="mw250">
                <InputLabel id="alert_level">Alert Level</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="alert_level"
                    value={level}
                    label="Alert Level"
                    onChange={(e) => setAlertLevel(e.target.value)}
                >
                    {levels}
                </Select>
            </FormControl>
        )

    }

    const renderSelectedAssignees = () => {
        const { selectedAssignees, setSelectedAssignees } = stateData

        const handleDelete = (id) => {
            let tempAssignees = _.cloneDeep(selectedAssignees)
            if (tempAssignees && tempAssignees.length) {
                tempAssignees = tempAssignees.filter((data) => {
                    return data !== id
                })
            }
            setSelectedAssignees(tempAssignees)
        }

        const getAssigneeName = (id, key) => {
            let name = ""
            for (let data of allAssignees) {
                if (data._id === id) {
                    name = data[key]
                }
            }
            return name
        }

        if (selectedAssignees && selectedAssignees.length) {
            const assignees = selectedAssignees.map((data, index) => {
                if (getAssigneeName(data, "firstName") === "") {
                    return <></>
                }
                return (
                    <span className='selected_assignees'>
                        <Chip
                            avatar={
                                <div className='assinee_padding'><AccountCircleIcon /> <br />
                                    {getAssigneeName(data, "firstName")} <br /> {getAssigneeName(data, "lastName")}
                                </div>
                            }
                            variant="outlined"
                            onDelete={() => { handleDelete(data) }}
                            id={index}
                            className="selected_assignees_padding"
                        />
                    </span>
                )
            })
            return assignees
        }
    }

    const assigneeOptions = () => {
        const { selectedAssignees, setSelectedAssignees } = stateData

        const assigneeToRemove = new Set(selectedAssignees);

        const AssigneeList = allAssignees.filter((name) => {
            return !assigneeToRemove.has(name._id);
        });

        const validAssignees = AssigneeList.filter(data => (data.isActive) || 
        data.role[0] === roles.MECHADEMY_ADMIN)

        const assignees = validAssignees.map((data, index) => {
            return (
                <MenuItem id={index} value={data._id}>{data.firstName + " " + data.lastName}</MenuItem>
            )
        })

        const handleAssigneeChange = (value) => {
            let newAssigneeList = _.cloneDeep(selectedAssignees)
            newAssigneeList.push(value)
            setSelectedAssignees(newAssigneeList)
            setShowModal(false)
        }
        return (
            <Box sx={{ minWidth: 300 }} >
                <FormControl fullWidth>
                    <InputLabel>Select Assignees</InputLabel>
                    <Select label="Select Assignees" onChange={(e) => { handleAssigneeChange(e.target.value) }}>
                        {assignees}
                    </Select>
                </FormControl>
            </Box>

        )
    }

    const renderAssignee = () => {
        return (
            <>
                <Grid item xs={4} className='alert_info_padding'>
                    Alert Assignees:
                </Grid>
                <Grid item xs={2} className='alert_info_padding'>
                    <Tooltip title={<h3 style={{ color: "white" }} >
                        {getAlertInfoMessage(userRole, 'Alert Assignees')}</h3>}
                    >
                        <InfoIcon />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} className='alert_info_padding'>
                    {renderSelectedAssignees()}
                    <Chip onClick={openPopover}
                        avatar={<AddIcon />}
                        label="Add"
                        variant="outlined"
                        className="selected_assignees_padding"
                    />
                    <Popover
                        open={Boolean(showModal)}
                        anchorEl={showModal}
                        anchorOrigin={{
                            vertical: 'bottom',
                        }}
                        onClose={() => { setShowModal(false) }}
                    >
                        <div >
                            <br />
                            {assigneeOptions()}
                            <br />
                        </div>
                    </Popover>
                </Grid>
            </>
        )
    }

    const renderAlertLabels = () => {
        const handleChange = (value) => {
            stateData.setSelectedLabels(value);
        };


        function getOption(data) {
            return (
                <Option key={data} value={data} title={data}>
                    {data}
                </Option>
            );
        }

        return (
            <>
                <Grid item xs={4} className="alert_info_padding">
                    Alert Labels:
                </Grid>
                <Grid item xs={2} className="alert_info_padding">
                    {/* <Tooltip title={<h3 style={{ color: "white" }} title=> */}
                    <Tooltip
                        title={
                            <h3 style={{ color: 'white' }}>
                                {getAlertInfoMessage(userRole, 'Alert Labels')}
                            </h3>
                        }
                    >
                        <InfoIcon />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} className="alert_info_padding">
                    <FormControl fullWidth className="mw250">
                        <AntdSelect
                            size={"large"}
                            placeholder="&nbsp;&nbsp; Select Label(s)"
                            mode="tags"
                            style={{
                                width: '100%',
                            }}
                            onChange={handleChange}
                            tokenSeparators={[',']}
                            disabled={false}
                            defaultValue={stateData.selectedLabels}
                        >
                            {alertLabels && alertLabels.map((data) => getOption(data))}
                        </AntdSelect>
                    </FormControl>
                </Grid>
            </>
        );
    };

    const renderAlertStatus = () => {
        return (<span>
            {stateData.userRole === roles.MECHADEMY_ADMIN ||
                stateData.userRole === roles.MECHADEMY_FACILITY_MANAGER ? (
                /** Render value MECHADEMY_ADMIN logged */
                <>
                    {data && data.mechademyStatus !== alertActions.Shelve ? (
                        data.mechademyStatus
                    ) : (
                        <div>
                            Shelved
                            <span className='shelved-until-time'>
                                (Until {getTimeInFormat(data['mechademyShelveTime'])} )
                            </span>
                        </div>
                    )}
                </>
            ) : (
                /** Render value MECHADEMY_ADMIN not logged */
                <>
                    {data && data.clientStatus !== alertActions.Shelve ? (
                        data.clientStatus
                    ) : (
                        <div>
                            Shelved
                            <span className="shelved-until-time">
                                (Until {getTimeInFormat(data["clientShelveTime"])})
                            </span>
                        </div>
                    )}
                </>
            )}
        </span>)
    }


    return (
        <>
            <Grid container className="padding_41">
                {
                    Object.keys(alertInfoConstants).map((key) => {
                        return (
                            <>
                                <Grid item xs={4} className='alert_info_padding'>
                                    {key + ":"}
                                </Grid>
                                <Grid item xs={2} className='alert_info_padding'>
                                    {data[alertInfoConstants[key]] || key === "Event Name" ?
                                        <Tooltip title={<h3 style={{ color: "white" }}>
                                            {getAlertInfoMessage(userRole, key)}</h3>}
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                        : <></>}
                                </Grid>
                                <Grid item xs={6} className='alert_info_padding'>
                                    {(key === 'Alert Date & Time' || key === 'Last Edited At') &&
                                        moment(data[alertInfoConstants[key]]).isValid() ?
                                        moment(data[alertInfoConstants[key]]).format('ddd, MMM Do YYYY, h:mm:ss A') :
                                        key === 'Level' ? renderAlertLevel() :
                                            key === 'Status' ? renderAlertStatus() : key === "Event Name" ? <Markup content={eventName} />   :
                                                data[alertInfoConstants[key]]}
                                </Grid>
                            </>
                        )
                    })
                }
                {renderAssignee()}
                {(userRole === roles.MECHADEMY_ADMIN || userRole === roles.MECHADEMY_FACILITY_MANAGER) && renderAlertLabels()}
            </Grid>
        </>
    )

}

export default RenderAlertInfo