const runInfos = [
    {
        "unit": "hours",
        "_id": "6124826d71175d0f16f58328",
        "tag": "RS-UPT-1021",
        "tagId": "60ec08c9b504ed259525eb94",
        "value": "24.000",
        "type": "Float",
        "abbreviation": "Up-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.322Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d955ef58334",
        "tag": "RS-DWT-1021",
        "tagId": "60ec08c9b504eda75725eb87",
        "value": "678.617",
        "type": "Float",
        "abbreviation": "Down-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.393Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175dba20f5832d",
        "tag": "RS-MTTF-1021",
        "tagId": "60ec08c9b504ed662925eb9c",
        "value": "72.000",
        "type": "Float",
        "abbreviation": "MTTF: Mean time to failure (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.184Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d60c7f58330",
        "tag": "RS-TUT-1021",
        "tagId": "60ec08c9b504ed76a225eba3",
        "value": "360.000",
        "type": "Float",
        "abbreviation": "Total Up-time",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.653Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175dafe9f58338",
        "tag": "RS-TDT-1021",
        "tagId": "60ec08c9b504eda3da25eba2",
        "value": "1855.717",
        "type": "Float",
        "abbreviation": "Total Down-time",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.699Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d3b45f58332",
        "tag": "RS-METBF-1021",
        "tagId": "60ec08c9b504ed19ed25eb8e",
        "value": "443.143",
        "type": "Float",
        "abbreviation": "METBF: Mean elapsed time between failures (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.214Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175ddfe5f58329",
        "tag": "RS-MUT-1021",
        "tagId": "60ec08c9b504ed760525eb9a",
        "value": "30.000",
        "type": "Float",
        "abbreviation": "MUT: Mean Up-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.439Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d4970f58337",
        "tag": "RS-MDT-1021",
        "tagId": "60ec08c9b504ed691825eb8b",
        "value": "154.643",
        "type": "Float",
        "abbreviation": "MDT: Mean Down-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.485Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d8a6bf58333",
        "tag": "RS-MEA-1021",
        "tagId": "60ec08c9b504ed1d6125eb9e",
        "value": "0.606",
        "type": "Float",
        "abbreviation": "Mean / Overall Availability",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.400Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d1bd0f5832b",
        "tag": "RS-PDT-1021",
        "tagId": "60ec08c9b504ed138d25eb95",
        "value": "0",
        "type": "Float",
        "abbreviation": "Planned Down-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.827Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d569cf5832c",
        "tag": "RS-UDT-1021",
        "tagId": "60ec08c9b504ed643825eb98",
        "value": "678.617",
        "type": "Float",
        "abbreviation": "Unplanned Down-time (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.885Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d83d4f58331",
        "tag": "RS-OA-1021",
        "tagId": "60ec08c9b504ed77a825eb9b",
        "value": "0.162",
        "type": "Float",
        "abbreviation": "Ao: Operational Availability",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.516Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175db7bbf58327",
        "tag": "RS-AVL-1021",
        "tagId": "60ec08c9b504ed0e1225eb97",
        "value": "False",
        "type": "Float",
        "abbreviation": "Availability",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.931Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d28c3f58335",
        "tag": "RS-AT-1021",
        "tagId": "60ec08c9b504ed273225eb96",
        "value": "24.000",
        "type": "Float",
        "abbreviation": "Available Time  (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.031Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d7365f5833a",
        "tag": "RS-MTBR-1021",
        "tagId": "60ec08c9b504ed7d3e25eb9d",
        "value": "292.272",
        "type": "Float",
        "abbreviation": "MTBR: Mean time between repairs  (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.275Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d29b0f5832e",
        "tag": "RS-TRCT-1021",
        "tagId": "60ec08c9b504ed290725eba1",
        "value": "2215.717",
        "type": "Float",
        "abbreviation": "Total run cycle time",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.608Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d4320f5833b",
        "tag": "RS-TAT-1021",
        "tagId": "60f55f78517edb78c183015b",
        "value": "1343.098",
        "type": "Float",
        "abbreviation": "Total Available time",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.076Z"
    },
    {
        "unit": "hours",
        "_id": "6124826d71175d7fc5f58339",
        "tag": "RS-MART-1021",
        "tagId": "60ec08c9b504ed2f4225eb9f",
        "value": "24.259",
        "type": "Float",
        "abbreviation": "MART: Mean Active Repair time  (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.336Z"
    },
    {
        "unit": null,
        "_id": "6124826d71175d29f8f58336",
        "tag": "RS-NOS-1021",
        "tagId": "60ec08c9b504ed064625eba0",
        "value": "12.000",
        "type": "Float",
        "abbreviation": "NS: Number of starts",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:08.561Z"
    },
    {
        "unit": null,
        "_id": "6124826d71175daabbf5832a",
        "tag": "RS-NOF-1021",
        "tagId": "60ec08c9b504ed4d5025eb89",
        "value": "5.000",
        "type": "Float",
        "abbreviation": "No. of Failures",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.153Z"
    },
    {
        "unit": null,
        "_id": "6124826d71175d7539f5832f",
        "tag": "RS-FR-1021",
        "tagId": "60ec08c9b504ed429b25eb92",
        "value": "0.014",
        "type": "Float",
        "abbreviation": "λ: Failure rate",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6124824271175d6b23f582df",
        "upTime": 24,
        "downTime": 678.6166666666667,
        "startAt": "2021-08-03T05:23:00.000Z",
        "endAt": "2021-08-04T05:23:00.000Z",
        "createdAt": "2021-09-14T12:03:09.368Z"
    }
]

export default runInfos