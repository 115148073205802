import {
  ADD_EQUIPMENT_FETCH_ERROR,
  ADD_EQUIPMENT_FETCH_SUCCESS,
  ADD_EQUIPMENT_FETCH_PROGRESS,
  GET_EQUIPMENT_FETCH_ERROR,
  GET_EQUIPMENT_FETCH_SUCCESS,
  GET_EQUIPMENT_FETCH_PROGRESS,
  EDIT_EQUIPMENT_FETCH_PROGRESS,
  EDIT_EQUIPMENT_FETCH_ERROR,
  EDIT_EQUIPMENT_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_PROGRESS,
  GET_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_ERROR,
  ADD_EQUIPMENT_TYPE_FETCH_ERROR,
  ADD_EQUIPMENT_TYPE_FETCH_PROGRESS,
  ADD_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_MANUFACTURER_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_PROGRESS,
  GET_MANUFACTURER_FETCH_SUCCESS,
  ADD_MANUFACTURER_FETCH_ERROR,
  ADD_MANUFACTURER_FETCH_PROGRESS,
  ADD_MANUFACTURER_FETCH_SUCCESS,
  EQUIPMENT_CLEAR_STATE,
} from "../Types/equipment.types";

import { POSTRequest, GETRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

export const addNewEquipment = (payload) => (dispatch) => {
  dispatch({ type: ADD_EQUIPMENT_FETCH_PROGRESS });
  POSTRequest(endPoints.equipment, payload)
    .then((res) =>
      dispatch({ type: ADD_EQUIPMENT_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: ADD_EQUIPMENT_FETCH_ERROR, payload: err })
    );
};

export const getEquipment = (id) => (dispatch) => {
  dispatch({ type: GET_EQUIPMENT_FETCH_PROGRESS });
  GETRequest(`${endPoints.equipment}/${id}`)
    .then((res) =>
      dispatch({ type: GET_EQUIPMENT_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_EQUIPMENT_FETCH_ERROR, payload: err })
    );
};

export const editEquipment = (payload, id, systemId) => (dispatch) => {
  dispatch({ type: EDIT_EQUIPMENT_FETCH_PROGRESS });
  PUTRequest(`${endPoints.equipment}/${id}/${systemId}`, payload)
    .then((res) =>
      dispatch({ type: EDIT_EQUIPMENT_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: EDIT_EQUIPMENT_FETCH_ERROR, payload: err })
    );
};

export const getEquipmentType = (keyword) => (dispatch) => {
  dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_PROGRESS });
  POSTRequest(
    `${endPoints.equipmentType}${keyword ? `?keyword=${keyword}` : ""}`
  )
    .then((res) =>
      dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_ERROR, payload: err })
    );
};

export const addEquipmentType = (payload) => (dispatch) => {
  dispatch({ type: ADD_EQUIPMENT_TYPE_FETCH_PROGRESS });
  POSTRequest(endPoints.addEquipmentType, payload)
    .then((res) =>
      dispatch({ type: ADD_EQUIPMENT_TYPE_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: ADD_EQUIPMENT_TYPE_FETCH_ERROR, payload: err })
    );
};

export const getManufacturer = (keyword) => (dispatch) => {
  dispatch({ type: GET_MANUFACTURER_FETCH_PROGRESS });
  POSTRequest(
    `${endPoints.manufacturer}${keyword ? `?keyword=${keyword}` : ""}`
  )
    .then((res) =>
      dispatch({ type: GET_MANUFACTURER_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_MANUFACTURER_FETCH_ERROR, payload: err })
    );
};

export const addManufacturer = (payload) => (dispatch) => {
  dispatch({ type: ADD_MANUFACTURER_FETCH_PROGRESS });
  POSTRequest(endPoints.addManufacturer, payload)
    .then((res) =>
      dispatch({ type: ADD_MANUFACTURER_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: ADD_MANUFACTURER_FETCH_ERROR, payload: err })
    );
};

export const clearEquipmentState = () => (dispatch) => {
  dispatch({ type: EQUIPMENT_CLEAR_STATE });
};
