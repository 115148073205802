import { GETRequest, POSTRequestFileCsv, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";
import * as Type from "../Types/piTagUpload.types";

const FACILITY_ID = "facilityId";
const BASE_TIME = "baseTime";

export function piTagListFetch(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_GET_LIST_PROGRESS });
    GETRequest(`${endPoints.piTags}?${FACILITY_ID}=${id}`, payload)
      .then((res) => {
        dispatch({ type: Type.PI_TAG_GET_LIST_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.PI_TAG_GET_LIST_ERROR, payload: err });
      });
  };
}

export function piTagListClear() {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_GET_LIST_CLEAR });
  };
}

export function piTagListUpdate(payload){
  return function (dispatch){
    dispatch({ type: Type.PI_TAG_LIST_UPDATE , payload })
  }
}

export function piTagLogsDataFetch(id, payload) {
  return function (dispatch) {
    dispatch({type: Type.PI_TAG_LOG_GET_LIST_PROGRESS});
    GETRequest(`${endPoints.logPiTag}?${FACILITY_ID}=${id}`, payload)
    .then((res)=>{
      dispatch({ type : Type.PI_TAG_LOG_GET_LIST_SUCCESS, payload: res.body});
    }).catch((err)=>{
      dispatch({type:Type.PI_TAG_LOG_GET_LIST_ERROR,payload:err});
    })
  }
}

export function piTagLogsDataClear() {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_LOG_GET_LIST_CLEAR });
  };
}

export function TagBaseTimeUpdate(id, baseTime) {
  return function (dispatch) {
    dispatch({ type: Type.TAG_BASETIME_UPDATE_PROGRESS });
    PUTRequest(`${endPoints.tagBaseTimeUpdate}?${FACILITY_ID}=${id}&${BASE_TIME}=${baseTime}`)
      .then((res) => {
        dispatch({ type: Type.TAG_BASETIME_UPDATE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.TAG_BASETIME_UPDATE_ERROR, payload: err });
      });
  };
}

export function piTagDelete(payload) {
  return function (dispatch) {
    dispatch({ type: Type.PT_TAG_DELETE_PROGRESS });
    PUTRequest(endPoints.piTagDelete, payload)
      .then((res) => {
        dispatch({ type: Type.PT_TAG_DELETE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.PT_TAG_DELETE_ERROR, payload: err });
      });
  };
}

export function piTagDeleteClear() {
  return function (dispatch) {
    dispatch({ type: Type.PT_TAG_DELETE_CLEAR });
  };
}

/**
 *
 * @param {Csv file} payload
 * @param {*facility id} id
 */
export function piTagUpload(payload, id) {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_UPLOAD_PROGRESS });
    POSTRequestFileCsv(`${endPoints.piTags}?${FACILITY_ID}=${id}`, payload)
      .then((res) => {
        dispatch({ type: Type.PI_TAG_UPLOAD_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.PI_TAG_UPLOAD_ERROR, payload: err });
      });
  };
}

export function piTagUploadClear() {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_UPLOAD_CLEAR });
  };
}

export function piTagSave(payload, id) {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_SAVE_PROGRESS });
    PUTRequest(`${endPoints.piTags}?${FACILITY_ID}=${id}`, payload)
      .then((res) => {
        dispatch({ type: Type.PI_TAG_SAVE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.PI_TAG_SAVE_ERROR, payload: err });
      });
  };
}

export function piTagSaveClear() {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_SAVE_CLEAR });
  };
}

export function piTagClearReducer() {
  return function (dispatch) {
    dispatch({ type: Type.CLEAR_PI_TAG_REDUCER });
  };
}

export function piTagUpdate(payload, id) {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_UPDATE_PROGRESS });
    PUTRequest(`${endPoints.updatePiTag}?${FACILITY_ID}=${id}`, payload)
      .then((res) => {
        dispatch({ type: Type.PI_TAG_UPDATE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.PI_TAG_UPDATE_ERROR, payload: err });
      });
  };
}

export function tagUpdate(payload, id) {
  return function (dispatch) {
    dispatch({ type: Type.TAG_UPDATE_PROGRESS });
    PUTRequest(`${endPoints.updateTag}?${FACILITY_ID}=${id}`, payload)
      .then((res) => {
        dispatch({ type: Type.TAG_UPDATE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.TAG_UPDATE_ERROR, payload: err });
      });
  };
}

export function piTagUpdateClearReducer() {
  return function (dispatch) {
    dispatch({ type: Type.PI_TAG_UPDATE_CLEAR });
  };
}


