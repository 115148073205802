import { Icon, Input } from 'antd';
import React from 'react';
import { validateNumberNegative } from '../../../../../../libs/validator';
import { syncGeneratorCapabilityCurvesVariables } from '../../../../../../constants/variables';

export const tableHeader = tableHeader => {
  return [
    {
      title: syncGeneratorCapabilityCurvesVariables.ambientTemperature,
      dataIndex: 'ambientTemperature',
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="ambientTemperature"
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                // className={tempError ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: syncGeneratorCapabilityCurvesVariables.realPower,
      dataIndex: 'realPower',
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="realPower"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: syncGeneratorCapabilityCurvesVariables.actions,
      dataIndex: 'actions',
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = input => {
  if (input.length !== 15) {
    return [
      {
        render: () => {
          return (
            <div>
              <Input
                id="ambientTemperature"
                autoComplete="off"
                type="number"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                value={input.ambientTemperature}
                onChange={(value) => input.handleChangeTableData(value)}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <div>
              <Input
                id="realPower"
                autoComplete="off"
                type="number"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                value={input.realPower}
                onChange={(value) => input.handleChangeTableData(value)}
                // className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = input => {
  return [
    {
      render: () => {
        const tempError =
          !input.ambientTemperatureUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && 'error'}`}>
            <select
              value={
                !input.ambientTemperatureUnit
                  ? ''
                  : input.ambientTemperatureUnit
              }
              id="ambientTemperatureUnit"
              onChange={(value) => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === 'Ambient Temperature') {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.realPowerUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && 'error'}`}>
            <select
              id="realPowerUnit"
              value={!input.realPowerUnit ? '' : input.realPowerUnit}
              onChange={(value) => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map((getUnits) => {
                if (
                  getUnits.dataVariable === 'Real Power At Unity Power Factor'
                ) {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};

export const curveInput = (data, xAxisValue, yAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '250px', padding: '10px' },
      change: (e) => { config.changeX(e) },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '250px', padding: '10px' },
      change: (e) => { config.changeY(e) },
    },
  ]
}

export const graphDetails = {
  title: 'Curve Plot',
  requiredMessage: 'Mininum three data is required',
  efficiency: 'Efficiency',
  powerFactor: 'Power Factor',
  load: 'Load',
  current: 'Current'
};

export const curveVariables = {
  'Ambient Temperature': 'ambientTemperature',
  'Real Power': 'realPower',
};