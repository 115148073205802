import { GETRequest, POSTRequestFileCsv } from "../../api/request";
import { endPoints } from "../../libs/endpoint";
import {
  ASSOCIATIONS_FETCH_SUCCESS,
  DATAVARIBLES_FETCH_SUCCESS,
  PROPERTIES_FETCH_SUCCESS,
  UNITSPROPERTIES_CLEAR_STATE,
  UNITSPROPERTIES_FETCH_ERROR,
  UNITSPROPERTIES_FETCH_SUCCESS,
  UNITS_FETCH_SUCCESS,
  UPLOAD_DATAVARIABLES_FAILURE,
  UPLOAD_DATAVARIABLES_PROGRESS,
  UPLOAD_DATAVARIABLES_SUCCESS,
  UPLOAD_UNITCONVERSION_FAILURE,
  UPLOAD_UNITCONVERSION_PROGRESS,
  UPLOAD_UNITCONVERSION_SUCCESS,
} from "../Types/unitsProperties";

export function unitsProperties(paneNumber, query) {
  if (paneNumber === "1") {
    return associations(query);
  }
  if (paneNumber === "2") {
    return properties(query);
  }
  if (paneNumber === "3") {
    return dataVariable(query);
  }
  if (paneNumber === "4") {
    return units(query);
  }
}

export function units(query = {}) {
  return (dispatch) => {
    dispatch({ type: UNITSPROPERTIES_FETCH_SUCCESS });
    GETRequest(endPoints.units, query)
      .then((res) => {
        dispatch({ type: UNITS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) =>
        dispatch({ type: UNITSPROPERTIES_FETCH_ERROR, payload: err })
      );
  };
}

export function dataVariable(query = {}) {
  return (dispatch) => {
    dispatch({ type: UNITSPROPERTIES_FETCH_SUCCESS });
    GETRequest(endPoints.datavariables, query)
      .then((res) => {
        dispatch({ type: DATAVARIBLES_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) =>
        dispatch({ type: UNITSPROPERTIES_FETCH_ERROR, payload: err })
      );
  };
}

export function properties(query = {}) {
  return (dispatch) => {
    dispatch({ type: UNITSPROPERTIES_FETCH_SUCCESS });
    GETRequest(endPoints.properties, query)
      .then((res) => {
        dispatch({ type: PROPERTIES_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) =>
        dispatch({ type: UNITSPROPERTIES_FETCH_ERROR, payload: err })
      );
  };
}

export function associations(query = {}) {
  return (dispatch) => {
    dispatch({ type: UNITSPROPERTIES_FETCH_SUCCESS });
    GETRequest(endPoints.associations, query)
      .then((res) => {
        dispatch({ type: ASSOCIATIONS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) =>
        dispatch({ type: UNITSPROPERTIES_FETCH_ERROR, payload: err })
      );
  };
}

export function clearState() {
  return (dispatch) => {
    dispatch({ type: UNITSPROPERTIES_CLEAR_STATE });
  };
}

export function uploadDataVariable(payload) {
  return function (dispatch) {
    dispatch({ type: UPLOAD_DATAVARIABLES_PROGRESS });
    POSTRequestFileCsv(endPoints.uploadDV, payload)
      .then((res) => {
        dispatch({ type: UPLOAD_DATAVARIABLES_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: UPLOAD_DATAVARIABLES_FAILURE, payload: err.response });
      });
  };
}

export function uploadUnitConversion(payload) {
  return function (dispatch) {
    dispatch({ type: UPLOAD_UNITCONVERSION_PROGRESS });
    POSTRequestFileCsv(endPoints.uploadUC, payload)
      .then((res) => {
        dispatch({ type: UPLOAD_UNITCONVERSION_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({
          type: UPLOAD_UNITCONVERSION_FAILURE,
          payload: err.response,
        });
      });
  };
}
