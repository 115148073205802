import * as Routes from "../constants/routes";

export const features = {
  DASHBOARD: "dashboard",
  SETUP: "setup",
  USER_MANAGEMENT: "userMgmt",
  ASSET_LIBRARY: "asset_library",
  UNIT_PROPERTIES: "user_properties",
  ML_ALGORITHM: "mlAlgo",
  SETTINGS: "settings",
  SYSTEMSCHEMATIC: "System Schematic",
  PI_TAG: "piTag",
  USER_MANAGEMENT_GENERAL: "userManagementGeneral",
};

export const appFeature = {
  [Routes.app]: { relatedTo: features.DASHBOARD, type: "read" },
  [Routes.dashboard]: { relatedTo: features.DASHBOARD, type: "read" },
  [Routes.addDashboardCard]: { relatedTo: features.DASHBOARD, type: "write" },
  [Routes.clients]: { relatedTo: features.SETUP, type: "read" },
  [Routes.clientAdd]: { relatedTo: features.SETUP, type: "write" },
  [Routes.clientInfo]: { relatedTo: features.SETUP, type: "read" },
  [Routes.clientFacility]: { relatedTo: features.SETUP, type: "read" },
  [Routes.clientSystem]: { relatedTo: features.SETUP, type: "read" },
  [Routes.clientEquipment]: { relatedTo: features.SETUP, type: "read" },
  [Routes.componentSchematic]: { relatedTo: features.SETUP, type: "read" },
  [Routes.componentInformation]: { relatedTo: features.SETUP, type: "read" },
  [Routes.manageTags]: { relatedTo: features.SETUP, type: "read" },
  [Routes.userManagement]: {
    relatedTo: features.USER_MANAGEMENT,
    type: "read",
  },
  [Routes.addUser]: { relatedTo: features.USER_MANAGEMENT, type: "write" },
  [Routes.assetLibrary]: { relatedTo: features.ASSET_LIBRARY, type: "read" },
  [Routes.addEquipment]: { relatedTo: features.ASSET_LIBRARY, type: "write" },
  [Routes.unitProperties]: {
    relatedTo: features.UNIT_PROPERTIES,
    type: "read",
  },
  [Routes.mlAlgorithm]: { relatedTo: features.ML_ALGORITHM, type: "write" },
  [Routes.setting]: { relatedTo: features.SETTINGS, type: "read" },
  [Routes.schematic]: { relatedTo: features.SYSTEMSCHEMATIC, type: "read" },
};
