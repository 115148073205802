import Type from '../Types/SQLComponent.types';
import { endPoints } from '../../libs/endpoints';
import { GETRequest, PUTRequest } from '../../api/request';

export function getConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_CONFIG_DATA_PROGRESS });

    GETRequest(`${endPoints.components}/${id}/${endPoints.sqlComponentConfig}`)
      .then((res) =>
        dispatch({
          type: Type.GET_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.GET_CONFIG_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function updateConfigData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_CONFIG_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.sqlComponentConfig}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_CONFIG_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function resetSqlComponentConfigData() {
  return function (dispatch) {
    dispatch({ type: Type.CONFIG_DATA_RESET });
  };
}
