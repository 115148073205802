import React, { Component } from 'react';
import './CustomTable.scss';

class CustomTable extends Component {
  renderHeader() {

    return (
      <tr>
        {this.props.columns.map(
          (item, index) =>
            item && (
              <th key={index} className = {this.props.displayFlex ? 'flexed':''}>
                {item.titleRender ? item.titleRender() : item.title}
                {item.renderExtra ? item.renderExtra(): ''}
              </th>
            )
        )}
      </tr>
    );
  }

  renderBody() {
    let rows = [];
    if (this.props.data) {
      let alerts = this.props.data.map((item, index) => (
        <tr
          key={item._id ? item._id : index}
          onClick={(e) =>
            this.props.rowSelection ? this.props.rowSelection(index, e) : {}
          }
        >
          {this.renderColumnData(item, index)}
        </tr>
      ));
      rows = rows.concat(alerts);
    }
    if (this.props.eventData) {
      let baseIndex = rows.length;
      if (this.props.eventData.docs.length > 0) {
        let events = [];
        this.props.eventData.docs.forEach((event, index) => {
          //event row is expanded
          if (event.expanded && event.expanded === true && event.alerts?.length>0) {
            let expandedEvent = [];
            //rendering columns for event row
            expandedEvent.push(
              <tr key={event._id ? event._id : index + baseIndex}>
                {this.renderColumnData(event, index + baseIndex)}
              </tr>
            );
            baseIndex += 1; //first obj in array is event , constituent alerts follow
            event &&
              event.alerts &&
              event.alerts.forEach((alert) => {
                baseIndex++; //updating index for each constituent alert
                //rendering columns for alert row
                expandedEvent.push(
                  <tr  
                    key={alert._id ? alert._id : index + baseIndex}
                    onClick={(e) =>
                      this.props.rowSelection
                        ? this.props.rowSelection(index + baseIndex, e)
                        : {}
                    }
                  >
                    {this.renderColumnData(alert, index + baseIndex)}
                  </tr>
                );
              });
              events.push(expandedEvent);
          }
          //event row is collapsed 
          else {
            baseIndex++;
            events.push(
              <tr key={event._id ? event._id : index + baseIndex}>
                {this.renderColumnData(event, index + baseIndex)}
              </tr>
            );
          }
         
        });
        rows = rows.concat(events);
      }
    }
    return rows;
    
  }
 
  renderColumnData(data, index) {
    const { columns,displayFlex } = this.props;
    return columns.map((item) => {
      if (item && item.render) {
        if (
          this.props.editableRow &&
          this.props.editableRow.indexOf(index) > -1
        ) {
          return (
            <td
              onClick={(e) => {
                item.hasOwnProperty('clickable') &&
                  item.clickable &&
                  this.props.onRowClick &&
                  this.props.onRowClick(
                    data[item.dataIndex],
                    index,
                    item.dataIndex,
                    false,
                    data
                  );
              }}
              className={
                this.getClassName(data, item, displayFlex, index, 'type1')  
              }
            >
              {item.render(
                data[item.dataIndex],
                index,
                item.dataIndex,
                false,
                data
              )}
            </td>
          );
        }

        return (
          <td
            onClick={(e) => {
              item.hasOwnProperty('clickable') &&
                item.clickable &&
                this.props.onRowClick &&
                this.props.onRowClick(
                  data[item.dataIndex],
                  index,
                  item.dataIndex,
                  false,
                  data
                );
            }}
            className={
              this.getClassName(data, item, displayFlex, index, 'type2')
            }
          >
            {item.render(
              data[item.dataIndex],
              index,
              item.dataIndex,
              true,
              data
            )}
          </td>
        );
      }
      return (
        item && (
          <td
            onClick={(e) => {
              item.hasOwnProperty('clickable') &&
                item.clickable &&
                this.props.onRowClick &&
                this.props.onRowClick(
                  data[item.dataIndex],
                  index,
                  item.dataIndex,
                  false,
                  data
                );
            }}
            className={
              this.getClassName(data, item, displayFlex, index, 'type3')
            }
          >
            {item.dataIndex ? data[item.dataIndex] : item.dataIndex}
          </td>
        )
      );
    });
  }

  getClassName(data, item, displayFlex, index, type) {
    let className='';
    if(item.hasOwnProperty('clickable') && item.clickable){
      className+='custom-table-row-clickable';
    }else{
      if(type === 'type1'){
        if(displayFlex){
          className+='enabled-input-field-flexed'
        }else{
          className+='enabled-input-field'
        }
      }else if(type === 'type2'){
        if(displayFlex){
          className+='disabled-input-field-flexed'
        }else{
          className+='disabled-input-field'
        }
      }else if(type === 'type3'){
        className+='disabled-input-field'
      }
    }
    if((this.props.userRole === 'MechademyAdmin' || this.props.userRole === 'MechademyFacilityManager') && data.hasOwnProperty('eventId') && data.eventId){
      if(index && index%2 === 0){
        className+=' eventAlertRowOdd'
      }else{
        className+=' eventAlertRowEven'
      }
    }
    return className;
  }

  renderTable() {
    return (
      <>
        <table>
          {this.props.showHeader ? <thead>{this.renderHeader()}</thead> : null}
          <tbody>
            {this.props.renderAddRow ? this.renderAddRow() : null}
            {this.props.headerColumns ? this.renderHeaderColumns() : null}
            {this.props.data || this.props.eventData ? this.renderBody() : null}
            {this.props.footerColumns ? this.renderFooterColumns() : null}
          </tbody>
        </table>
      </>
    );
  }

  renderAddRow() {
    return (
      <tr className="add-row">
        {this.props.renderAddRow &&
          this.props.renderAddRow.map((item, index) => {
            if (item && item.render) {
              return (
                <td key={index}>
                  {item.render(
                    this.props.addFieldValues &&
                      this.props.addFieldValues[item.dataIndex],
                    index,
                    item.dataIndex,
                    this.props.addFieldValues
                  )}
                </td>
              );
            }
            return (
              item && (
                <td key={index}>
                  {this.props.addFieldValues &&
                    this.props.addFieldValues[item.dataIndex]}
                </td>
              )
            );
          })}
      </tr>
    );
  }

  renderHeaderColumns() {
    return (
      <tr>
        {this.props.headerColumns.map((item, index) => {
          if (item && item.render) {
            return <td>{item.render(item.value, index)}</td>;
          }
          return item && <td>{item.value}</td>;
        })}
      </tr>
    );
  }

  renderFooterColumns() {
    return (
      <tr>
        {this.props.footerColumns.map((item, index) => {
          if (item && item.render) {
            return <td>{item.render(item.value, index)}</td>;
          }
          return item && <td>{item.value}</td>;
        })}
      </tr>
    );
  }

  render() {
    return (
      <div style={this.props.style} className="CustomTable">
        {this.renderTable()}
      </div>
    );
  }
}

export default CustomTable;
