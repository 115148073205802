import './transientData.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Row, Col, Empty, Button, Modal, DatePicker, Pagination } from 'antd';
import Search from 'antd/lib/input/Search';
import { UserOutlined } from '@ant-design/icons';
import { fetchTransientDataCalc, clearTransientDataCalcState, updateTransientDataCalc, fetchBaselineData } from '../../../../redux/actions/transientData.action'
import { getTimeInFormat, getLumpSumValue } from '../../../../libs/valueFormatter';
import AnalysisDetails from './analysisDetails';
import Notification, { Types } from '../../../../common/Notification/Notification';
import { TransientDataVariable } from '../../../../constants/variables';

import removeFilter from "../../../../assets/ic_filter_active/ic_filter_active.png";
import addFilter from "../../../../assets/ic_filter/filter.png";
import moment from 'moment';
const { Sider } = Layout;
const { RangePicker } = DatePicker;
const limit = 10

const TransientAnalysis = (props) => {
  const { 
    fetchTransientDataCalc, 
    activeKey, 
    info,
    fetchTransientCalcDataSuccess,
    transientCalculationData, 
    clearTransientDataCalcState,
    updateTransientDataCalc,
    updateTransientDataSuccess,
    fetchBaselineData,
    baselineData,
    isDataProcessed,
    isError,
    isRun
  } = props;
  const [selectedCalcData, setSelectedCalcData] = useState(null);
  const [selectedKey, setSelectedKey] = useState(['0']);
  const [transientCalcData, setTransientCalcData] = useState([]);
  const [analysisIndex, setAnalysisIndex] = useState(0);
  const [seriesData, setSeriesData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isDateFilterAdded, setIsDateFilterAdded] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [skip, setSkip] = useState(0);  
  const [totalDataCount, settotalDataCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchTagsInfo = useCallback(() => {
    if (info && info.equipmentList && info.equipmentList._id && activeKey === '5') {
      fetchTransientDataCalc(info.equipmentList._id, {});
    }
  }, [info, activeKey, fetchTransientDataCalc]);

  // acts as componentDidMount
  useEffect(() => {
    fetchTagsInfo();
  }, [fetchTagsInfo]);

  useEffect(() => {
    document.querySelector(".transient-sider").scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    document.querySelector(".DashboardInfo").scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pageNumber]);

  useEffect(() => {
    if (fetchTransientCalcDataSuccess && transientCalculationData && transientCalculationData.items) {
      setTransientCalcData(transientCalculationData.items);
      setSkip(transientCalculationData.skip);
      settotalDataCount(transientCalculationData.total_count);
      clearTransientDataCalcState();
      const selectedItem = transientCalculationData.items[0]; 
      setSelectedCalcData(selectedItem);
      setSelectedKey('0');
      setAnalysisIndex(0);
      if (selectedItem) {
        fetchBaselineData(selectedItem?.piTag, selectedItem?.analysisType, selectedItem?._id,
          selectedItem?.equipmentId);
      }
    }
  }, [fetchTransientCalcDataSuccess, transientCalculationData, clearTransientDataCalcState, fetchBaselineData]);

  useEffect(() => {
    if (updateTransientDataSuccess && transientCalculationData && transientCalculationData.items) {
      setTransientCalcData(transientCalculationData.items);
      setSkip(transientCalculationData.skip);
      settotalDataCount(transientCalculationData.total_count);
      clearTransientDataCalcState();
      const currentSelectedItem = transientCalculationData.items[analysisIndex];
      setSelectedCalcData(currentSelectedItem);
      if (
        (filterDate && filterDate.length === 2) ||
        selectedCalcData.startTime !== currentSelectedItem.startTime ||
        selectedCalcData.endTime !== currentSelectedItem.endTime
      ) {
        let payload = {
          skip: skip,
        };
        payload.keyword = getSearchValueFromKeyword(searchKeyword);
        if (filterDate && filterDate.length === 2) {
          payload.startTime = moment(filterDate[0]).utc().format("YYYY-MM-DDTHH:mm:ss");
          payload.endTime = moment(filterDate[1]).utc().format("YYYY-MM-DDTHH:mm:ss");
        }
        fetchTransientDataCalc(info.equipmentList._id, payload);
      } else  {
        fetchBaselineData(currentSelectedItem?.piTag, currentSelectedItem?.analysisType, currentSelectedItem?._id,
          currentSelectedItem?.equipmentId);
      }
      Notification.show(Types.Success, TransientDataVariable.UPDATE_SUCCESS);
    }
  }, [updateTransientDataSuccess, transientCalculationData, analysisIndex,
      clearTransientDataCalcState, fetchBaselineData, filterDate, info, searchKeyword, 
      fetchTransientDataCalc, selectedCalcData, skip
    ]);
  
  useEffect(() => {
    if(isDataProcessed && selectedCalcData) {
      clearTransientDataCalcState();
      const { data } = selectedCalcData;
      const series = [];
      const baselineSeries = [];
      const seriesLen = Math.min(data.timestamp.length, data.data.length);
      for(let i=0; i<seriesLen; i++)  {
          series.push([(data.timestamp[i] - data.timestamp[0]), parseFloat(getLumpSumValue(data.data[i]))]);
      }
      if(baselineData[selectedCalcData._id].isSuccess && baselineData[selectedCalcData._id].data) {
        const { data } = baselineData[selectedCalcData._id];
        const baselineSeriesLen = Math.min(data.timestamp.length, data.data.length);
        for(let i = 0; i < baselineSeriesLen; i++)  {
          baselineSeries.push([(data.timestamp[i] - data.timestamp[0]), parseFloat(getLumpSumValue(data.data[i]))]);
        }
      }
      const payload = [{
        name: 'Analysis Data',
        data: series,
        tooltip: { valueSuffix: ` ${selectedCalcData.plantUnit}` }
      },
      {
        name: 'Baseline Data',
        data: baselineSeries,
        tooltip: { valueSuffix: ` ${selectedCalcData.plantUnit}` }
      } 
    ];
      setSeriesData(payload);
    }
  }, [isDataProcessed, baselineData, selectedCalcData, clearTransientDataCalcState])

  useEffect(()=>{
    if(isError) {
      clearTransientDataCalcState();
      Notification.show(Types.Error, TransientDataVariable.error);
    }
  }, [isError, clearTransientDataCalcState])

  const updateAnalysisData = (payload) => {
    updateTransientDataCalc(selectedCalcData['equipmentId'], selectedCalcData['_id'], payload);
  }

  useEffect(() => {
    setAnalysisIndex(0);
    setSelectedKey('0');
    setSearchKeyword('');
    setFilterDate([]);
    setIsDateFilterAdded(false);
    setPageNumber(1);
    setSkip(0);
  }, [info]);


  const getSearchValueFromKeyword = (value) => {
    
    let searchValue = '';
    for (let i = 0; i < value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        searchValue += value[i];
      } else if (value[i] >= 'a' && value[i] <= 'z') {
        searchValue += value[i];
      } else if (value[i] >= 'A' && value[i] <= 'Z') {
        searchValue += value[i];
      } else {
        searchValue += '\\';
        searchValue += value[i];
      }
    }
    return searchValue;
  }

  const onSearch = (value) => {
    if (info && info.equipmentList && info.equipmentList._id) {
      setPageNumber(1);
      setSkip(0);
      const payload = getFetchTransientDataCalcPayload(value, filterDate)
      payload.skip = 0
      setSearchKeyword(value);
      fetchTransientDataCalc(info.equipmentList._id, payload);
      setSelectedKey('0')
      setAnalysisIndex(0)
    }
  };

  const getFetchTransientDataCalcPayload = (value, dateFilter) => {
    let payload = {};
    value = getSearchValueFromKeyword(value);
    if(dateFilter && dateFilter.length === 2){
      payload.startTime = moment(dateFilter[0]).utc().format("YYYY-MM-DDTHH:mm:ss");
      payload.endTime = moment(dateFilter[1]).utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    payload.skip = skip;
    payload.limit = limit;
    payload.keyword = value;
    return payload;
  }

  const change = (value) => {
    setSearchKeyword(value);
  };

  const handleFilterChange = () => {
    if (isDateFilterAdded && info && info.equipmentList && info.equipmentList._id) {
      setIsDateFilterAdded(false)
      setFilterDate([])
      // removing date filter on remove date filter button clicked
      setPageNumber(1);
      setSkip(0);
      const payload = getFetchTransientDataCalcPayload(searchKeyword, [])
      payload.skip = 0;
      fetchTransientDataCalc(info.equipmentList._id, payload);
      setSelectedKey('0')
      setAnalysisIndex(0)
    } else {
      setIsDatePickerVisible(true)
    }
  }

  const filterDataByDate = () => {
    if (filterDate.length !== 2) {
      Notification.show(Types.Error, "Please Select Both The Dates");
    }
    else if (filterDate[1] < filterDate[0]) {
      Notification.show(Types.Error, "Start Date Can Not be Greater Than End Date");
    }
    else if (filterDate.length === 2 && info && info.equipmentList && info.equipmentList._id) {
      setPageNumber(1)
      setSkip(0)
      const payload = getFetchTransientDataCalcPayload(searchKeyword, filterDate)
      payload.skip = 0;
      fetchTransientDataCalc(info.equipmentList._id, payload);
      setIsDateFilterAdded(true)
      setIsDatePickerVisible(false)
      setSelectedKey('0')
      setAnalysisIndex(0)
    }
  }

  const handlePageClick = (pageNumber) => {
    if (info && info.equipmentList && info.equipmentList._id) {
      const skip = (pageNumber - 1) * limit;
      setSkip(skip);
      setPageNumber(pageNumber);
      let payload = getFetchTransientDataCalcPayload(searchKeyword, filterDate);
      payload.skip = skip;
      fetchTransientDataCalc(info.equipmentList._id, payload);
      setSelectedKey('0');
      setAnalysisIndex(0);
    }
  };

  const getBaselineData = calcData => {
    if (calcData) {
      fetchBaselineData(calcData?.piTag, calcData?.analysisType, calcData?._id,
        calcData?.equipmentId);
    }
  }

  const paginateData = () => {
    if(totalDataCount){
      return (
        <div className="paginateAlign">
          <Pagination
            defaultCurrent={1}
            total={totalDataCount}
            onChange={handlePageClick}
            current={pageNumber}
            pageSize={limit}
          />
        </div>
      );
    }
  };

  return (
    <div className="Transient">
      <Layout className="Transient">
        <Sider
          style={{
            overflow: 'auto',
            left: 0,
            background: 'white',
          }}
          className="menuStyle transient-container transient-sider"
          width="25%"
        >
          <Menu mode="inline" selectedKeys={selectedKey}>
            <Row className="menu-padding">
              <Col span={12} className="menuHead">
                Transient Data
              </Col>
            </Row>
            <Row className="menu-padding">
              <Col span={18}>
                <Search
                  placeholder="Search Tag Id Here"
                  onSearch={(value) => {
                    onSearch(value);
                  }}
                  onChange={(e) => {
                    change(e.target.value);
                  }}
                  value={searchKeyword}
                  allowClear={true}
                />
              </Col>
              <Col span={6} className="filter-btn-align">
                <Button
                  type="default"
                  className={"filter " + (isDateFilterAdded ? "removeFilterButton" : "")}
                  onClick={() => handleFilterChange()}
                  style={{ marginRight: "5px" }}
                >
                  <img
                    src={isDateFilterAdded ? removeFilter : addFilter }
                    alt={isDateFilterAdded ? "removeFilter" : "addFilter" }
                    className={isDateFilterAdded ? "removeFilter" : "addFilter" }
                  />
                </Button>
              </Col>
            </Row>
            <br></br>
            {transientCalcData?.length &&
              transientCalcData.map((calcData, index) => {
                let num;
                if (index % 2 === 0) {
                  num = 'menu-color0';
                } else {
                  num = 'menu-color1';
                }
                return (
                  <Menu.Item
                    key={index}
                    icon={<UserOutlined />}
                    className={num}
                    onClick={() => {
                      setSelectedCalcData(calcData);
                      setSelectedKey([index.toString()]);
                      setAnalysisIndex(index);
                      getBaselineData(calcData);
                    }}
                  >
                    <div className="runs-menu">
                      <Row>
                        <Col span={24} className="alert-align">
                          <div className="text"> Analysis: {((pageNumber - 1) * limit) + index+1} </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="alert-align">
                          <div className="text"> TagId: {calcData.piTag} </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="alert-align">
                          <div className="text"> Type: {calcData.analysisType} </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="alert-align">
                          <div className="text"> Date: {getTimeInFormat(calcData.startTime)} </div>
                        </Col>
                      </Row>
                    </div>
                  </Menu.Item>
                );
              })}
            {!transientCalcData.length && (<Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 50,
                      }}
                      description={
                        <span>
                          <b>No Data Available</b>
                        </span>
                      }
                    />)}
          </Menu>
          {paginateData()}
        </Sider>
        {AnalysisDetails(selectedCalcData, updateAnalysisData, seriesData, updateTransientDataSuccess, isRun)}
      </Layout>
      <Modal
        visible={isDatePickerVisible}
        onOk={() => filterDataByDate()}
        onCancel={() => setIsDatePickerVisible(false)}
        title="Filter Transient Data"
      >
        <div>
          <RangePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(e) => setFilterDate(e)}
            value={filterDate}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.LoginReducer.apiState.userRole,
    fetchTransientCalcDataSuccess: state.transientDataCalcReducer.isSuccess,
    transientCalculationData: state.transientDataCalcReducer.calculationData,
    updateTransientDataSuccess: state.transientDataCalcReducer.dataUpdateSuccess,
    baselineData: state.transientDataCalcReducer.baselineDataState,
    isDataProcessed: state.transientDataCalcReducer.dataProcessed,
    isError: state.transientDataCalcReducer.isError,
    isRun: state.transientDataCalcReducer.isRun,
  };
};

const mapDispatchToProps = {
  fetchTransientDataCalc,
  clearTransientDataCalcState,
  updateTransientDataCalc,
  fetchBaselineData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransientAnalysis);
