export const detailConstant = {
  "RS-ROS": "Reason for stoppage",
  "RS-FDM": "Failure Detection Method",
  "RS-FMEC": "Failure Mechanism",
  "RS-FMO": "Failure Mode",
  "RS-FC": "Failure Cause",
  "RS-MA": "Maintenance Activity",
  "RS-MST": "Maintenance Start Time",
  "RS-MET": "Maintenance End Time",
  "RS-UPT": "Up-time (hours)",
  "RS-DWT": "Down-time (hours)",
  "RS-PDT": "Planned Down-time (hours)",
  "RS-UDT": "Unplanned Down-time (hours)",
  "RS-AVL": "Availability",
  "RS-FAL": "Failure",
  "RS-NOF": "No. of Failures",
  "RS-AT": "Available Time  (hours)",
  "RS-ART": "Active Repair Time  (hours)",
  "RS-MUT": "MUT: Mean Up-time (hours)",
  "RS-MDT": "MDT: Mean Down-time (hours)",
  "RS-OA": "Ao: Operational Availability",
  "RS-MEA": "Mean / Overall Availability",
  "RS-MTTF": "MTTF: Mean time to failure (hours)",
  "RS-METBF": "METBF: Mean elapsed time between failures (hours)",
  "RS-MTBR": "MTBR: Mean time between repairs  (hours)",
  "RS-MART": "MART: Mean Active Repair time  (hours)",
  "RS-NOS": "NS: Number of starts",
  "RS-FR": "λ: Failure rate",
  "RS-TRCT": "Total run cycle time",
  "RS-TDT": "Total Down-time",
  "RS-TUT": "Total Up-time",
  "RS-MAP": "Maintenance activity performed",
  "RS-MAAM": "Machine available after maintenance",
  "RS-TAT": "Total Available time"
};
export const runStatsConstant = [
  { abbreviation: "RS-UPT", Tag: "Up-time (hours)" },
  { abbreviation: "RS-DWT", Tag: "Down-time (hours)" },
  { abbreviation: "RS-TDT", Tag: "Total Down-time" },
  { abbreviation: "RS-TUT", Tag: "Total Up-time" },
  { abbreviation: "RS-PDT", Tag: "Planned Down-time (hours)" },
  { abbreviation: "RS-UDT", Tag: "Unplanned Down-time (hours)" },
  { abbreviation: "RS-NOF", Tag: "No. of Failures" },
  { abbreviation: "RS-AT", Tag: "Available Time  (hours)" },
  { abbreviation: "RS-MUT", Tag: "MUT: Mean Up-time (hours)" },
  { abbreviation: "RS-MDT", Tag: "MDT: Mean Down-time (hours)" },
  { abbreviation: "RS-OA", Tag: "Ao: Operational Availability" },
  { abbreviation: "RS-MEA", Tag: "Mean / Overall Availability" },
  { abbreviation: "RS-MTTF", Tag: "MTTF: Mean time to failure (hours)" },
  { abbreviation: "RS-METBF", Tag: "METBF: Mean elapsed time between failures (hours)" },
  { abbreviation: "RS-NOS", Tag: "NS: Number of starts" },
  { abbreviation: "RS-FR", Tag: "λ: Failure rate" },
  { abbreviation: "RS-TRCT", Tag: "Total run cycle time" },
  { abbreviation: "RS-TAT", Tag: "Total Available time" },
];