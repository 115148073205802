import { AddUserMessages } from "../../../../constants/messages";
import { elementsGaurd } from "../../../../gaurds";
import { ElementPermissions } from "../../../../permissions";
import { roles } from "../../../../permissions/roles.permissions";

export const formField = (config) => [
  {
    label: "Alert Level",
    key: "level",
    max: "50",
    type: "dropdown",
    data: alertLevel,
    width: '33%'
  },
  {
    label: "Type",
    key: "type",
    max: "50",
    type: "text",
    disabled: true,
  },
  {
    label: "Date & Time",
    key: "dateTime",
    max: "50",
    type: "text",
    disabled: true,
  },
  {
    label: "Tag Description",
    key: "classification",
    max: "50",
    type: "text",
    disabled: !(
      config.alertsData &&
      config.alertsData.level === "Unknown" &&
      config.mechademyAction === "Forward"
    ),
  },
  {
    label: "Last Edited By",
    key: "editedBy",
    max: "50",
    type: "text",
    disabled: true,
  },
  {
    label: "Last Edited at",
    key: "updatedAt",
    max: "50",
    type: "text",
    disabled: true,
  },
  {
    label: "Event Description",
    key: "description",
    max: "50",
    type: "text",
    disabled: true,
  },
  {
    label: "Event Name",
    key: "eventName",
    max: "50",
    type: "text",
    disabled: true,
    value: config.eventName? config.eventName: "--"
  },
  config.userRole !== roles.MECHADEMY_FACILITY_MANAGER
    ? elementsGaurd(
      {
        label: "Alert Labels",
        key: "labels",
        formDisable: config.formDisable,
        type: "selectTags",
        defaultValues: config.defaultValues,
        data: config.allLabels,
        currentLabels: config.alertsData.labels,
        disabled: !config.accessInfo.write,
        selectWidth: "50%"
      },
      ElementPermissions.ALERT_DETAILS,
      config.info
    )
    : {
      label: "Alert Labels",
      key: "labels",
      formDisable: config.formDisable,
      type: "selectTags",
      defaultValues: config.defaultValues,
      data: config.allLabels,
      currentLabels: config.alertsData.labels,
      disabled: !config.accessInfo.write,
      selectWidth: "50%"
    },
  {
    label: "Alert Assignees",
    key: "assignees",
    formDisable: config.formDisable,
    type: "selectAssignees",
    defaultValues: config.defaultValues,
    data: config.allAssignees,
    currentAssignees: config.alertsData.assignees,
    disabled: !config.accessInfo.write,
    selectWidth: "50%"
  },
  config.userRole !== roles.MECHADEMY_FACILITY_MANAGER
    ? elementsGaurd(
      {
        label: "Comments",
        key: "comment",
        max: "4000",
        required: [
          !!(
            config.alertsData &&
              config.alertsData.level === "High" &&
              config.mechademyAction !== "Forward" &&
              config.mechademyAction !== "Actions"
          ),
          AddUserMessages.REQUIRED_FIELD,
        ],
        formDisable: config.formDisable,
        type: "textarea",
      },
      ElementPermissions.ALERT_DETAILS,
      config.info
    )
    : {
      label: "Comments",
      key: "comment",
      max: "4000",
      required: [
        !!(
          config.alertsData &&
            config.alertsData.level === "High" &&
            config.mechademyAction !== "Forward" &&
            config.mechademyAction !== "Actions"
        ),
        AddUserMessages.REQUIRED_FIELD,
      ],
      formDisable: config.formDisable,
      type: "textarea",
    }, 
];

export const formFields = (config) => [
  {
    label: "Insights",
    key: "insights",
    max: "4000",
    required: [
      config.mechademyAction === "Forward",
      AddUserMessages.REQUIRED_FIELD,
    ],
    type: "textarea",
    formDisable: config.formDisable,
    disabled: config.disable,
  },
  config.mechademyStatus === "New"
    ? {
      label: "Resources",
      key: "resource",
      type: "text",
      disabled: false,
    }
    : {
      label: "Resources",
      key: "resource",
      type: "link",
      value: config.links && config.links,
    },
];

export const ALERT_ACTIONS_LIST = {
  NEW: "New",
  DISCARD: "Discard",
  DISABLE: "Disable",
  ENABLE: "Enable",
  SHELVED: "Shelve",
  UNSHELVED: "Unshelve",
  FORWARD: "Forward",
  CLOSE: "Close",
  ACKNOWLEDGE: "Acknowledge",
};

export const actions = (action, info) => {
  if (action === ALERT_ACTIONS_LIST.DISCARD) {
    return [ALERT_ACTIONS_LIST.DISCARD];
  }
  if (action === ALERT_ACTIONS_LIST.DISABLE) {
    return [ALERT_ACTIONS_LIST.ENABLE];
  }
  if (elementsGaurd(true, ElementPermissions.ALERT_DETAILS, info)) {
    return [
      ALERT_ACTIONS_LIST.FORWARD,
      ALERT_ACTIONS_LIST.DISABLE,
      ALERT_ACTIONS_LIST.DISCARD,
      ALERT_ACTIONS_LIST.SHELVED,
      ALERT_ACTIONS_LIST.CLOSE,
    ];
  }
  if (action === ALERT_ACTIONS_LIST.CLOSE) {
    return [];
  } else if (action === ALERT_ACTIONS_LIST.SHELVED) {
    return [ALERT_ACTIONS_LIST.UNSHELVED];
  } else if (action === `${ALERT_ACTIONS_LIST.ACKNOWLEDGE}d`) {
    return [ALERT_ACTIONS_LIST.SHELVED, ALERT_ACTIONS_LIST.CLOSE];
  }
  return [
    ALERT_ACTIONS_LIST.ACKNOWLEDGE,
    ALERT_ACTIONS_LIST.CLOSE,
    ALERT_ACTIONS_LIST.SHELVED,
  ];
};

export const alertLevel = ["Low", "Medium", "High"];

export const alertPlot = {
  OUT_OF_BOUND: "Out of Bound Data",
  IQR_THRESHOLD: "IQR threshold exceedance",
  PROCESS_DEVIATION: "Process deviation",
  IOW: "IOW threshold exceedance",
};

export const alertActions = {
  Acknowledge: "Acknowledged",
  Shelve: "Shelved",
  Unshelve: "Unshelved",
  Close: "Close",
  Forward: "Forward",
  Discard: "Discard",
  Disable: "Disable",
  Enable: "Enable",
};
