import {
  SCHEMATIC_COMPONENT_CLEAR_STATE,
  SCHEMATIC_COMPONENT_FETCH_ERROR,
  SCHEMATIC_COMPONENT_FETCH_PROGRESS,
  CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  GET_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  CREATE_SCHEMATIC_FETCH_SUCCESS,
  EDIT_SCHEMATIC_FETCH_SUCCESS,
  GET_SCHEMATIC_FETCH_SUCCESS,
  SCHEMATIC_FETCH_ERROR,
  SCHEMATIC_FETCH_PROGRESS,
  DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  VALIDATE_SCHEMATIC_FETCH_SUCCESS,
  UN_DEPLOY_EQUIPMENT_SUCCESS,
  UN_DEPLOY_EQUIPMENT_PROGRESS,
  UN_DEPLOY_EQUIPMENT_ERROR,
  UN_DEPLOY_EQUIPMENT_RESET,
} from "../Types/schematic.types";

import {
  GETRequest,
  POSTRequest,
  PUTRequest,
  DeleteRequest,
} from "../../api/request";
import { endPoints } from "../../libs/endpoints";

export function getSchematicComponent(id) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_COMPONENT_FETCH_PROGRESS });
    GETRequest(`${endPoints.components}/${id}`)
      .then((res) => {
        dispatch({
          type: GET_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_COMPONENT_FETCH_ERROR, payload: err });
      });
  };
}

export function createSchematicComponent(payload) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_COMPONENT_FETCH_PROGRESS });
    POSTRequest(`${endPoints.components}`, payload)
      .then((res) => {
        dispatch({
          type: CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_COMPONENT_FETCH_ERROR, payload: err });
      });
  };
}

export function editSchematicComponent(payload, id) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_COMPONENT_FETCH_PROGRESS });
    PUTRequest(`${endPoints.components}/${id}`, payload)
      .then((res) => {
        dispatch({
          type: EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_COMPONENT_FETCH_ERROR, payload: err });
      });
  };
}

export function deleteSchematicComponent(id) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_COMPONENT_FETCH_PROGRESS });
    DeleteRequest(`${endPoints.components}/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_COMPONENT_FETCH_ERROR, payload: err });
      });
  };
}

export function validateSchematic(query) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_FETCH_PROGRESS });
    GETRequest(endPoints.validateSchemetics, query)
      .then((res) => {
        dispatch({ type: VALIDATE_SCHEMATIC_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_FETCH_ERROR, payload: err });
      });
  };
}

export function getSchematic(query) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_FETCH_PROGRESS });
    GETRequest(endPoints.schemetics, query)
      .then((res) => {
        dispatch({ type: GET_SCHEMATIC_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_FETCH_ERROR, payload: err });
      });
  };
}

export function createSchematic(payload) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_FETCH_PROGRESS });
    POSTRequest(`${endPoints.schemetics}`, payload)
      .then((res) => {
        dispatch({ type: CREATE_SCHEMATIC_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_FETCH_ERROR, payload: err });
      });
  };
}

export function editSchematic(payload, id) {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_FETCH_PROGRESS });
    PUTRequest(`${endPoints.schemetics}/${id}`, payload)
      .then((res) => {
        dispatch({ type: EDIT_SCHEMATIC_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: SCHEMATIC_FETCH_ERROR, payload: err });
      });
  };
}

export function clearSchematicState() {
  return function (dispatch) {
    dispatch({ type: SCHEMATIC_COMPONENT_CLEAR_STATE });
  };
}

export const unDeploySchematic = (id) => (dispatch) => {
  dispatch({ type: UN_DEPLOY_EQUIPMENT_PROGRESS });
  PUTRequest(`${endPoints.unDeploySchematic}/${id}`)
    .then((res) =>
      dispatch({ type: UN_DEPLOY_EQUIPMENT_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: UN_DEPLOY_EQUIPMENT_ERROR, payload: err })
    );
};

export const resetUnDeploySchematic = () => (dispatch) =>
  dispatch({ type: UN_DEPLOY_EQUIPMENT_RESET });
