import React from 'react';
import './recipCompressorCurves.scss';
import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
    addEditCurves,
    clearCurvesState,
    addEditCompressorCurves,
    clearCompressorCurvesState,
} from '../../../../../../redux/actions/curves.action';
import {
    curvesTableColumns,
    curvesTableAddRow,
    AsTesteCurveFormField,
    curvesLabel,
    dataUnitsVariables,
    graphDetails,
    createCurvesPayoad,
    AsTesteCurveFormFieldOperational,
    CurveScreenConstants,
} from './curvesConstants';
import * as coefficient from '../../../../../../libs/coefficient';
import Notification, {
    Types,
} from '../../../../../../common/Notification/Notification';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import {
    NO_INTERNET,
    DISCARD_CHANGES,
    FORM_DISCARD_MSG,
    CurvesMessages,
    RESET_OR_SAVE,
} from '../../../../../../constants/messages';
import {
    getDataUnits,
    clearDataUnitState,
} from '../../../../../../redux/actions/dataUnit.action';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../../common/FormFields/FormFields';
import Line from '../../../../../../common/Graphs/Line/Line';
import Loader from '../../../../../../common/Loader/Loader';
import { ButtonVariables } from '../../../../../../constants/variables';



const asTestedCurves = 'asTestedCurves';
const operationalBaseLineCurves = 'operationalBaseLineCurves';
class CurveScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preserveCurveData: {},
            editableRows: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            updatedFields: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            dirtyRows: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            formEdit: false,
            asTestedCurves: [],
            operationalBaseLineCurves: [],
            addFieldValue: {
                volumeReduction: '',
                adiabaticEfficiency: '',
                inletVolume: ''
            },
            addFieldValueBaseLine: {
                volumeReduction: '',
                adiabaticEfficiency: '',
                inletVolume: ''
            },
            isError: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
            isErrorBaseLine: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
            speed: 10,
            unitData: [],
            infoVisible: false,
            volumetricFlowSI: 'value',
            headDropSI: 'value',
            efficiencySI: 'value',
            speedSI: 'value',
            visibleGraph: false,
            visibleDelete: false,
            runnerData: [],
            unitMapper: {},
            saveType: 'save',
            displayDelete: true,
            visibleOperational: false,
            tempAsTested: []
        };
    }

    disableEditForm() {
        window.dispatchEvent(new Event('form-edit-false'));
    }

    enableEditForm() {
        window.dispatchEvent(new Event('form-edit'));
    }

    handleNetwork() {
        const { networkState } = this.props.network;
        if (networkState) {
            return true;
        }
        Notification.show(Types.Error, NO_INTERNET);
        this.setState({ network: false });
        return false;
    }

    componentDidMount() {
        const { unitData } = this.props.dataUnitState.apiState;
        const { data } = this.props;
        if (
            data
        ) {
            const {
                asTestedCurves,
                operationalBaseLineCurves,
            } = data;
            if (asTestedCurves && operationalBaseLineCurves) {
                this.setCurveData(asTestedCurves, operationalBaseLineCurves);
            }
        }
        this.setState(
            {
                unitData: unitData && unitData.items ? unitData.items : [],
            },
            () => {
                this.setState({ runnerData: this.getRunnerData() });
            }
        );

        window.addEventListener('form-edit', () => {
            this.setState({
                formEdit: true,
            });
        });

        window.addEventListener('form-edit-false', (e) => {
            this.setState(
                {
                    formEdit: false,
                },
                () => {
                    this.clearDirtyForm();
                }
            );
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { isError, isSuccess } = this.props.compressorReducer.apiState;
        const {
            clearCompressorCurvesState,
            data
        } = this.props;

        if (prevProps.data && prevProps.data.asTestedCurves && prevProps.data.asTestedCurves.data &&
            prevProps.data.asTestedCurves.data.length !== this.state.tempAsTested.length) {
            const {
                asTestedCurves,
                operationalBaseLineCurves,
            } = data;
            if (asTestedCurves && operationalBaseLineCurves) {
                this.setCurveData(asTestedCurves, operationalBaseLineCurves);
                this.setState({ tempAsTested: [...asTestedCurves.data] })
            }
        }

        if (isError) {
            clearCompressorCurvesState();
            Notification.show(Types.Error);
        }

        if ((this.state.saveType !== prevState.saveType) && this.state.saveType === 'saveMore') {
            this.props.handleSaveAndMore('saveMore');
        }

        if (isSuccess) {
            clearCompressorCurvesState();
            this.disableEditForm();
            Notification.show(Types.Success, CurvesMessages.ADDED);
        }
        if (this.props.RecipCompCurveState.addEditCompressorCurves.isSuccess) {
            this.setState({ formEdit: false })
            this.setState({
                editableRows: {
                    asTestedCurves: [],
                    operationalBaseLineCurves: [],
                }
            })
            this.setState({
                addFieldValue: {
                    volumeReduction: '',
                    adiabaticEfficiency: '',
                    inletVolume: ''
                },
                addFieldValueBaseLine: {
                    volumeReduction: '',
                    adiabaticEfficiency: '',
                    inletVolume: ''
                },
                isError: {
                    volumeReduction: false,
                    adiabaticEfficiency: false,
                    inletVolume: false,
                },
                isErrorBaseLine: {
                    volumeReduction: false,
                    adiabaticEfficiency: false,
                    inletVolume: false,
                },
            })

            Notification.show(Types.Success, CurvesMessages.ADDED);
        }
        // if(this.props.data && this.props.data !== {}){
        //     const {
        //         asTestedCurves,
        //         operationalBaseLineCurves,
        //     } =this.props.data;
        //     this.setCurveData(asTestedCurves, operationalBaseLineCurves);
        // }
    }

    convertUnitToSI(units) {
        if (units) {
            const { efficiency, headDrop, unitSpeed, volumetricFlow } = units;
            const {
                unitMapper,
            } = this.props.dataUnitState.apiState;

            if (efficiency && headDrop && unitSpeed && volumetricFlow) {
                this.setState({
                    volumetricFlowSI:
                        unitMapper[dataUnitsVariables.volumetricFlow][volumetricFlow],
                    headDropSI: unitMapper[dataUnitsVariables.headDrop][headDrop],
                    efficiencySI: unitMapper[dataUnitsVariables.efficiency][efficiency],
                    speedSI: unitMapper[dataUnitsVariables.speed][unitSpeed],
                });
            }
        }
    }

    getUnitInSI(varialbeName, unit) {
        if (varialbeName && unit) {
            const { unitMapper } = this.props.dataUnitState.apiState;
            const siValue = unitMapper[varialbeName][unit];
            return siValue;
        }
        return 'value';
    }

    setCurveData(asTestedCurves, operationalBaseLineCurves) {
        if (asTestedCurves && operationalBaseLineCurves) {
            const { inletPressure, dataUnit } = asTestedCurves;
            const formData = {
                inletPressure: `${inletPressure.value}`,
                unit: inletPressure.unit,
                adiabaticEfficiency: dataUnit.adiabaticEfficiency,
                inletVolume: dataUnit.inletVolume,
            };

            this.setState({
                asTestedCurves: [...asTestedCurves.data],
                operationalBaseLineCurves: [...operationalBaseLineCurves.data],
            });
            this.props.form.setFieldsValue(formData, () => {
                this.disableEditForm();
            });
        }
    }

    validateFields(values, tableName) {
        let status = true;
        if (tableName === asTestedCurves) {
            const { isError } = this.state;
            const temp = { ...isError };
            Object.keys(temp).forEach((item) => {
                if (
                    (!values[item] && values[item] !== 0) ||
                    (values[item] &&
                        (values[item].length === 0 || values[item].trim().length === 0))
                ) {
                    temp[item] = true;
                    status = false;
                } else {
                    temp[item] = false;
                }
            });
            this.setState({ isError: temp });
        } else if (tableName === operationalBaseLineCurves) {
            const { isErrorBaseLine } = this.state;
            const temp = { ...isErrorBaseLine };
            Object.keys(temp).forEach((item) => {
                if (
                    (!values[item] && values[item] !== 0) ||
                    (values[item] &&
                        (values[item].length === 0 || values[item].trim().length === 0))
                ) {
                    temp[item] = true;
                    status = false;
                } else {
                    temp[item] = false;
                }
            });
            this.setState({ isErrorBaseLine: temp });
        }
        return status;
    }

    getRunnerData() {
        if (this.props.configData) {
            const { impellerData } = this.props.configData;
            if (impellerData) {
                const { data } = impellerData;
                const { apiState } = this.props.dataUnitState;
                const { unitMapper } = apiState;
                return data.map((item) => {
                    const toSI = unitMapper[dataUnitsVariables.diameter][item.unit];
                    let valueInSI;
                    if (item.type === 'Double Suction') {
                        valueInSI = eval(toSI.replace('value', parseFloat(item.diameter) / 2)); // eslint-disable-line
                    } else {
                        valueInSI = eval(toSI.replace('value', parseFloat(item.diameter))); // eslint-disable-line
                    }
                    return valueInSI;
                });
            }
        }
        return [];
    }

    calculateCofficient(values, tableName) {
        let speedValue = this.props.form.getFieldValue('speed')
            ? parseFloat(this.props.form.getFieldValue('speed'))
            : 0;
        if (tableName === operationalBaseLineCurves) {
            speedValue = this.props.form.getFieldValue('speedOperational')
                ? parseFloat(this.props.form.getFieldValue('speedOperational'))
                : 0;
        }

        const { volumetricFlowSI, headDropSI, efficiencySI, speedSI } = this.state;
        const volumetricFlow = eval(volumetricFlowSI.replace('value', values.volumetricFlow)); // eslint-disable-line
        const headDrop = eval(headDropSI.replace('value', values.headDrop)); // eslint-disable-line
        const speed = eval(speedSI.replace('value', speedValue)); // eslint-disable-line
        const efficiency = eval(efficiencySI.replace('value', values.efficiency)); // eslint-disable-line
        const flowCoefficient = coefficient.flowCoefficient(
            volumetricFlow,
            speed,
            this.state.runnerData[0]
        );
        const headCoefficient = coefficient.headCoefficient(
            headDrop,
            speed,
            this.state.runnerData
        );
        const workInput = coefficient.workInputCoefficient(
            efficiency,
            headCoefficient
        );
        return {
            volumetricFlow: parseFloat(values.volumetricFlow),
            headDrop: parseFloat(values.headDrop),
            efficiency: parseFloat(values.efficiency),
            flowCoefficient,
            headCoefficient,
            workInput,
        };
    }

    addRow(tableName) {
        const { name } = this.props.location.state.componentData.assetInfo;
        if (
            this.props.configData
        ) {
            if (this.validateFields(this.state.addFieldValue)) {
                if (tableName === asTestedCurves) {
                    this.props.onDataEdit(true)
                    if (this.validateFields(this.state.addFieldValue, asTestedCurves)) {
                        const { addFieldValue } = this.state;
                        const tableData = this.state.asTestedCurves;
                        const data = [...tableData, { ...addFieldValue }];
                        this.setState({
                            asTestedCurves: data,
                            addFieldValue: {
                                ...this.state.addFieldValue,
                                volumeReduction: '',
                                adiabaticEfficiency: '',
                                inletVolume: ''
                            },
                        });
                    }
                } else if (tableName === operationalBaseLineCurves) {
                    this.props.onDataEdit(true)
                    if (
                        this.validateFields(
                            this.state.addFieldValueBaseLine,
                            operationalBaseLineCurves
                        )
                    ) {
                        const { addFieldValueBaseLine } = this.state;
                        const tableData = this.state.operationalBaseLineCurves;
                        const data = [
                            ...tableData,
                            { ...addFieldValueBaseLine },
                        ];
                        this.setState({
                            operationalBaseLineCurves: data,
                            addFieldValueBaseLine: {
                                ...this.state.addFieldValueBaseLine,
                                volumeReduction: '',
                                adiabaticEfficiency: '',
                                inletVolume: ''
                            },
                        });
                    }
                }
            } else {
                Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
            }
        } else {
            Notification.show(
                Types.Info,
                name === 'Centrifugal liquid expander'
                    ? CurvesMessages.REQUIRED_RUNNER
                    : CurvesMessages.REQUIRED_IMPELLER
            );
        }
    }

    getGraphData() {
        if (
            this.state.operationalBaseLineCurves.length > 0 &&
            this.state.asTestedCurves.length > 0
        ) {
            const operationalBaseLineInlet = this.state.operationalBaseLineCurves.map(
                (item) => ({
                    x: Number(item.volumeReduction),
                    y: Number(item.inletVolume),
                })
            );
            const operationalBaseLineAdiabatic = this.state.operationalBaseLineCurves.map(
                (item) => ({
                    x: Number(item.volumeReduction),
                    y: Number(item.adiabaticEfficiency),
                })
            );
            const asTestedCurvesInlet = this.state.asTestedCurves.map((item) => ({
                x: Number(item.volumeReduction),
                y: Number(item.inletVolume),
            }));

            const asTestedCurvesAdiabatic = this.state.asTestedCurves.map((item) => ({
                x: Number(item.volumeReduction),
                y: Number(item.adiabaticEfficiency),
            }));

            return { asTestedCurvesInlet, asTestedCurvesAdiabatic, operationalBaseLineInlet, operationalBaseLineAdiabatic };
        }
    }

    onAddFieldChange(row, col, field, tableName) {
        if (tableName === asTestedCurves) {
            const { addFieldValue } = this.state;
            const temp = addFieldValue;
            temp[field.name] =
                parseFloat(field.value) === 0
                    ? parseFloat(field.value)
                    : field.value.replace(/^0+/, '');
            this.setState({ addFieldValue: temp }, () => {
                this.validateFields(this.state.addFieldValue, asTestedCurves);
                this.enableEditForm();
            });
        } else if (tableName === operationalBaseLineCurves) {
            const { addFieldValueBaseLine } = this.state;
            const temp = addFieldValueBaseLine;
            temp[field.name] =
                parseFloat(field.value) === 0
                    ? `${parseFloat(field.value)}`
                    : field.value.replace(/^0+/, '');
            this.setState({ addFieldValueBaseLine: temp }, () => {
                this.validateFields(
                    this.state.addFieldValueBaseLine,
                    operationalBaseLineCurves
                );
                this.enableEditForm();
            });
        }
    }

    onEditFieldChange(row, col, field, tableName) {
        let speed = this.props.form.getFieldValue('speed') || 0;

        const tableData = [...this.state[tableName]];

        const temp = { ...tableData[row] };
        temp[field.name] =
            parseFloat(field.value) === 0
                ? `${parseFloat(field.value)}`
                : field.value.replace(/^0+/, '');

        const { volumetricFlowSI, headDropSI, efficiencySI, speedSI } = this.state;
        const speedSIValue = eval(speedSI.replace('value', speed)); // eslint-disable-line
        if (field.name === 'volumeReduction') {
            const volumetricFlow = eval(volumetricFlowSI.replace('value', `${parseFloat(temp.volumetricFlow || 0)}`));// eslint-disable-line
            temp.flowCoefficient = coefficient.flowCoefficient(
                volumetricFlow,
                speedSIValue,
                this.state.runnerData[0]
            );
        } else if (field.name === 'adiabaticEfficiency') {
            const headDrop = eval(headDropSI.replace('value', `${parseFloat(temp.headDrop || 0)}`));// eslint-disable-line
            temp.headCoefficient = coefficient.headCoefficient(
                headDrop,
                speedSIValue,
                [...this.state.runnerData]
            );
        } else if (field.name === 'inletVolume') {
            const efficiency = eval(efficiencySI.replace('value', temp.efficiency || 0)); // eslint-disable-line
            temp.workInput = coefficient.workInputCoefficient(
                efficiency,
                temp.headCoefficient
            );
        }

        const tempCurveData = [...tableData];
        tempCurveData[row] = { ...temp };
        if (tableName === operationalBaseLineCurves) {
            this.setState(
                {
                    operationalBaseLineCurves: tempCurveData,
                },
                () => {
                    this.setDirtyRows(tableName, row);
                }
            );
        } else if (tableName === asTestedCurves) {
            this.setState(
                {
                    asTestedCurves: tempCurveData,
                },
                () => {
                    this.setDirtyRows(tableName, row);
                }
            );
        }
    }

    setDirtyRows(tableName, row) {
        const { dirtyRows } = this.state;
        const tableData = _.cloneDeep(this.state[tableName]);
        const tempDirtyRows = _.cloneDeep(dirtyRows);
        if (
            !tableData[row].volumeReduction.toString() ||
            !tableData[row].volumeReduction.toString().length ||
            !tableData[row].adiabaticEfficiency.toString() ||
            !tableData[row].adiabaticEfficiency.toString().length ||
            !tableData[row].inletVolume.toString() ||
            !tableData[row].inletVolume.toString().length
        ) {
            tempDirtyRows[tableName].push(row);
        } else {
            tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
                (e) => e !== row
            );
        }
        this.setState({
            dirtyRows: tempDirtyRows,
        });
    }

    edit(row, tableName) {
        const { updatedFields, editableRows } = this.state;
        const tableData = _.cloneDeep(this.state[tableName]);
        const updatedTemp = _.cloneDeep(updatedFields);
        const tempEditRows = _.cloneDeep(editableRows);
        updatedTemp[tableName][row] = _.cloneDeep(tableData[row]);
        tempEditRows[tableName].push(row);
        this.setState(
            {
                updatedFields: _.cloneDeep(updatedTemp),
                editableRows: tempEditRows,
            },
            () => {
                this.enableEditForm();
            }
        );
    }

    undo(row, tableName) {
        const { updatedFields, dirtyRows, editableRows } = this.state;
        const tempTableData = _.cloneDeep(this.state[tableName]);
        const tempDirtyRows = _.cloneDeep(dirtyRows);
        const tempupdatedField = _.cloneDeep(updatedFields);
        const tempEditRows = _.cloneDeep(editableRows);
        tempTableData[row] = _.cloneDeep(tempupdatedField[tableName][row]);
        tempEditRows[tableName] = tempEditRows[tableName].filter((e) => e !== row);
        tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
            (e) => e !== row
        );
        tempupdatedField[tableName][row] = undefined;
        if (tableName === operationalBaseLineCurves) {
            this.setState({
                operationalBaseLineCurves: tempTableData,
                dirtyRows: tempDirtyRows,
                updatedFields: tempupdatedField,
                editableRows: tempEditRows,
            });
        } else if (tableName === asTestedCurves) {
            this.setState({
                asTestedCurves: tempTableData,
                dirtyRows: tempDirtyRows,
                updatedFields: tempupdatedField,
                editableRows: tempEditRows,
            });
        }
    }

    deleteRow(row, tableName) {
        const { dirtyRows, updatedFields, editableRows } = this.state;
        const tempTableData = _.cloneDeep(this.state[tableName]);
        const tempDirtyRows = _.cloneDeep(dirtyRows);
        const tempupdatedField = _.cloneDeep(updatedFields);
        const tempEditRows = _.cloneDeep(editableRows);
        tempTableData.splice(row, 1);
        tempupdatedField[tableName].splice(row, 1);
        tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
            (e) => e !== row
        );
        tempEditRows[tableName] = tempEditRows[tableName].filter((e) => e !== row);
        if (tableName === operationalBaseLineCurves) {
            this.setState(
                {
                    operationalBaseLineCurves: tempTableData,
                    updatedFields: tempupdatedField,
                    dirtyRows: tempDirtyRows,
                    editableRows: tempEditRows,
                },
                () => {
                    this.enableEditForm();
                }
            );
        } else if (tableName === asTestedCurves) {
            this.setState(
                {
                    asTestedCurves: tempTableData,
                    updatedFields: tempupdatedField,
                    dirtyRows: tempDirtyRows,
                    editableRows: tempEditRows,
                },
                () => {
                    this.enableEditForm();
                }
            );
        }
    }

    save(type) {
        const { name } = this.props.location.state.componentData.assetInfo;
        const {
            location: { state },
            application,
            curveName,
        } = this.props;
        const { addEditCompressorCurves } = this.props;

        const { asTestedCurves, operationalBaseLineCurves
            // , inletPressure 
        } = this.state;
        this.props.form.validateFields((err, values) => {
            if (err) {
                throw err;
            }
            const lengthCheck =
                this.state.dirtyRows.asTestedCurves.length === 0 &&
                this.state.dirtyRows.operationalBaseLineCurves.length === 0;
            const curveLength =
                asTestedCurves.length >= 1 && operationalBaseLineCurves.length >= 1;
            const eqCurveLength =
                asTestedCurves.length === operationalBaseLineCurves.length;
            const asTestedLength = this.state.asTestedCurves.length
            const operationalBaslineLength = this.state.operationalBaseLineCurves.length
            if (!lengthCheck) {
                Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
                return;
            }
            if (!curveLength) {
                Notification.show(Types.Info, CurvesMessages.MIN_DATA);
                return;
            }
            if (!eqCurveLength) {
                Notification.show(Types.Info, CurvesMessages.EQUAL_DATA);
                return;
            }
            if (asTestedLength < 3 || operationalBaslineLength < 3) {
                Notification.show(Types.Info, CurvesMessages.MIN_DATA);
                return;
            }
            const payload = createCurvesPayoad(
                CurveScreenConstants[Number(this.props.activeKey) - 1],
                { ...values },
                [...asTestedCurves],
                [...operationalBaseLineCurves],
                application,
                curveName
            );
            if (this.handleNetwork() && state.componentData) {
                if (type === 'saveMore') {
                    this.setState({ saveType: type }, () => {
                        addEditCompressorCurves(
                            state.componentData.info._id,
                            payload,
                            name
                        );
                        this.setState({ saveType: 'save' })
                    });
                } else {
                    addEditCompressorCurves(state.componentData.info._id, payload, name);
                }
            }
        });
    }

    clearDirtyForm() {
        this.setState({
            editableRows: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            updatedFields: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            dirtyRows: {
                asTestedCurves: [],
                operationalBaseLineCurves: [],
            },
            formEdit: false,
            addFieldValue: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
            addFieldValueBaseLine: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
            isError: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
            isErrorBaseLine: {
                volumeReduction: false,
                adiabaticEfficiency: false,
                inletVolume: false,
            },
        });
    }

    reset() {
        this.setState({
            visible: true,
        });
    }

    handleCancel() {
        this.setState({
            visible: false,
        });
    }

    handleOk() {
        this.setState(
            {
                visible: false,
            },
            () => {
                this.disableEditForm();
            }

        );
        this.props.onDataEdit(false)
        if (this.props.RecipCompCurveState && this.props.RecipCompCurveState.addEditCompressorCurves &&
            this.props.RecipCompCurveState.addEditCompressorCurves.data &&
            this.props.RecipCompCurveState.addEditCompressorCurves.data.curvesData &&
            this.props.componentId === this.props.RecipCompCurveState.addEditCompressorCurves.data.component
        ) {
            const data = this.props.RecipCompCurveState.addEditCompressorCurves.data.curvesData
            const curveType = CurveScreenConstants[Number(this.props.activeKey) - 1]
            if (this.props.activeKey && data[curveType] && data[curveType][this.props.curveName] &&
                data[curveType][this.props.curveName].asTestedCurves &&
                data[curveType][this.props.curveName].operationalBaseLineCurves
            ) {
                const {
                    asTestedCurves,
                    operationalBaseLineCurves,
                } = data[curveType][this.props.curveName]
                this.setCurveData(asTestedCurves, operationalBaseLineCurves);
            }
            else {
                const formData = {
                    inletPressure: 0,
                    unit: 'barg',
                    adiabaticEfficiency: "%",
                    inletVolume: "m3/h",
                };
                this.setState({ asTestedCurves: [], operationalBaseLineCurves: [] })
                this.props.form.setFieldsValue(formData, () => {
                    this.disableEditForm();
                });
            }
            if (this.props.RecipCompCurveState.addEditCompressorCurves &&
                this.props.RecipCompCurveState.addEditCompressorCurves.data &&
                this.props.RecipCompCurveState.addEditCompressorCurves.data.application
            ) {
                this.props.setApplication(this.props.RecipCompCurveState.addEditCompressorCurves.data.application)
            }
        }
        else if (this.props.data && this.props.data.asTestedCurves && this.props.data.asTestedCurves.data) {
            const {
                asTestedCurves,
                operationalBaseLineCurves,
            } = this.props.data;
            if (asTestedCurves && operationalBaseLineCurves) {
                this.setCurveData(asTestedCurves, operationalBaseLineCurves);
            }
            if (this.props.initialApplicationState) {
                this.props.setApplication(this.props.initialApplicationState)
            }
            else {
                this.props.setApplication('Fixed Speed')
            }
        }
        else {
            const formData = {
                inletPressure: 0,
                unit: 'barg',
                adiabaticEfficiency: "%",
                inletVolume: "m3/h",
            };
            this.setState({ asTestedCurves: [], operationalBaseLineCurves: [] })
            this.props.setApplication('Fixed Speed')
            this.props.form.setFieldsValue(formData, () => {
                this.disableEditForm();
            });
        }
    }

    renderModal() {
        if (this.state.visible) {
            return (
                <ConfirmModal
                    title={DISCARD_CHANGES}
                    visible={this.state.visible}
                    handleOk={() => this.handleOk()}
                    handleCancel={() => this.handleCancel()}
                    message={FORM_DISCARD_MSG}
                />
            );
        }
        return null;
    }

    onDeleteCancel = () => {
        this.setState({ visibleDelete: false });
    };

    handleOkDelete = () => {
        this.setState({ visibleDelete: false }, () => {
            const { curveName, activeKey, addedCurves } = this.props;
            const { curvesData } = this.props.configData;
            const screenName = CurveScreenConstants[Number(activeKey) - 1]
            if (
                curvesData &&
                curvesData.curvesData &&
                curvesData.curvesData[screenName] &&
                curvesData.curvesData[screenName][curveName]
            ) {
                this.props.remove(curveName, screenName, activeKey);
            }
            else if (addedCurves &&
                addedCurves.data &&
                addedCurves.data.curvesData &&
                addedCurves.data.curvesData[screenName] &&
                addedCurves.data.curvesData[screenName][curveName]
            ) {
                this.props.remove(curveName, screenName, activeKey);
            }
            else {
                Notification.show(Types.Info, CurvesMessages.DELETE_NOTIFICATION);
            }
        });
    };

    renderDeleteModal() {
        if (this.state.visibleDelete) {
            return (
                <ConfirmModal
                    title={CurvesMessages.DELETE_TITLE}
                    visible={this.state.visibleDelete}
                    handleOk={this.handleOkDelete}
                    handleCancel={this.onDeleteCancel}
                    message={CurvesMessages.DELETE_MSG}
                />
            );
        }
        return null;
    }

    renderGraphModal() {
        if (this.state.visibleGraph) {
            const graphData = this.getGraphData();
            return (
                <Modal
                    title={graphDetails.title}
                    visible={this.state.visibleGraph}
                    onOk={this.handleOkGraph}
                    onCancel={this.handleOkGraph}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    style={{
                        maxWidth: 'inherit'
                    }}
                    className='Curves'
                    closable={false}
                    okText={"Back"}
                >
                    <div className="curve-plot">
                        <div style={{ width: 700 }}>
                            <Line
                                graphData={graphData.asTestedCurvesInlet}
                                xAxis={graphDetails.xAxis}
                                yAxis={graphDetails.yAxisInlet}
                            />
                        </div>
                        <div style={{ width: 700 }}>
                            <Line
                                graphData={graphData.asTestedCurvesAdiabatic}
                                xAxis={graphDetails.xAxis}
                                yAxis={graphDetails.yAxisAdiabatic}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>{graphDetails.asTestedCurve}</div>
                    <br />
                    <div className="curve-plot">
                        <div style={{ width: 700 }}>
                            <Line
                                graphData={graphData.operationalBaseLineInlet}
                                xAxis={graphDetails.xAxis}
                                yAxis={graphDetails.yAxisInlet}
                            />
                        </div>
                        <div style={{ width: 700 }}>
                            <Line
                                graphData={graphData.operationalBaseLineAdiabatic}
                                xAxis={graphDetails.xAxis}
                                yAxis={graphDetails.yAxisAdiabatic}
                            />
                        </div>
                        <br />

                    </div>
                    <div style={{ textAlign: 'center' }}>{graphDetails.operationalBasedCurve}</div>
                </Modal>
            );
        }
        return null;
    }

    handleOkGraph = () => {
        this.setState({ visibleGraph: false });
    };

    showGraph() {
        if (
            this.state.asTestedCurves.length >= 1 &&
            this.state.operationalBaseLineCurves.length >= 1
        ) {
            this.setState({ visibleGraph: true });
        } else {
            Notification.show(Types.Info, graphDetails.requiredMessage);
        }
    }

    onChangeSelect(value, key, tableName) {
        this.props.onDataEdit(true)
        if (typeof value !== 'object') {
            const { apiState } = this.props.dataUnitState;
            const { unitData } = apiState;
            const { items } = unitData;
            const obj = items.find((item) => item.dataVariable === key).conversion;
            const objSI = obj.find((item) => item.unit === value);

            if (key === dataUnitsVariables.volumetricFlow && objSI) {
                const { speedSI } = this.state;
                const speedValue = this.props.form.getFieldValue('speed') || 0;
                const speed = eval(speedSI.replace('value', speedValue)); // eslint-disable-line
                const speedValueOperational =
                    this.props.form.getFieldValue('speedOperational') || 0;
                const speedOperational = eval(speedSI.replace('value', speedValueOperational)); // eslint-disable-line
                this.setState({ volumetricFlowSI: objSI.toSI }, () => {
                    const {
                        asTestedCurves,
                        operationalBaseLineCurves,
                        volumetricFlowSI,
                        updatedFields,
                    } = this.state;

                    const tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
                        const tempFieldValue = { ...fieldValue };
                        const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line

                        tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                            volumetricFlow,
                            speed,
                            this.state.runnerData[0]
                        );
                        return tempFieldValue;
                    });

                    const tempUpdatedFieldAsTestedCurves = updatedFields.asTestedCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line

                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speed,
                                this.state.runnerData[0]
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedOperational,
                                this.state.runnerData[0]
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempUpdatedFieldAsOperationBaseLineCurves = updatedFields.operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedOperational,
                                this.state.runnerData[0]
                            );
                            return tempFieldValue;
                        }
                    );
                    this.setState({
                        asTestedCurves: tempAsTestedCurves,
                        operationalBaseLineCurves: tempAsOperationBaseLineCurves,
                        updatedFields: {
                            asTestedCurves: tempUpdatedFieldAsTestedCurves,
                            operationalBaseLineCurves: tempUpdatedFieldAsOperationBaseLineCurves,
                        },
                    });
                });
            } else if (key === dataUnitsVariables.headDrop && objSI) {
                const { speedSI } = this.state;
                const speedValue = this.props.form.getFieldValue('speed') || 0;
                const speed = eval(speedSI.replace('value', speedValue)); // eslint-disable-line
                const speedValueOperational =
                    this.props.form.getFieldValue('speedOperational') || 0;
                const speedOperational = eval(speedSI.replace('value', speedValueOperational)); // eslint-disable-line
                this.setState({ headDropSI: objSI.toSI }, () => {
                    const {
                        asTestedCurves,
                        operationalBaseLineCurves,
                        headDropSI,
                        updatedFields,
                    } = this.state;

                    const tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
                        const tempFieldValue = { ...fieldValue };
                        const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                        tempFieldValue.headCoefficient = coefficient.headCoefficient(
                            headDrop,
                            speed,
                            this.state.runnerData
                        );
                        return tempFieldValue;
                    });

                    const tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speed,
                                this.state.runnerData
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedOperational,
                                this.state.runnerData
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempUpdatedAsOperationBaseLineCurves = updatedFields.operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedOperational,
                                this.state.runnerData
                            );
                            return tempFieldValue;
                        }
                    );

                    this.setState({
                        asTestedCurves: tempAsTestedCurves,
                        operationalBaseLineCurves: tempAsOperationBaseLineCurves,
                        updatedFields: {
                            asTestedCurves: tempUpdatedAsTestedCurves,
                            operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
                        },
                    });
                });
            } else if (key === dataUnitsVariables.efficiency && objSI) {
                this.setState({ efficiencySI: objSI.toSI }, () => {
                    const {
                        asTestedCurves,
                        operationalBaseLineCurves,
                        efficiencySI,
                        updatedFields,
                    } = this.state;

                    const tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
                        const tempFieldValue = { ...fieldValue };
                        if (efficiencySI === 'value') {
                            tempFieldValue.efficiency *= 100;
                        } else {
                            tempFieldValue.efficiency /= 100;
                        }
                        return tempFieldValue;
                    });

                    const tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            if (efficiencySI === 'value') {
                                tempFieldValue.efficiency *= 100;
                            } else {
                                tempFieldValue.efficiency /= 100;
                            }
                            return tempFieldValue;
                        }
                    );

                    const tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            if (efficiencySI === 'value') {
                                tempFieldValue.efficiency *= 100;
                            } else {
                                tempFieldValue.efficiency /= 100;
                            }
                            return tempFieldValue;
                        }
                    );

                    const tempUpdatedAsOperationBaseLineCurves = updatedFields.operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            if (efficiencySI === 'value') {
                                tempFieldValue.efficiency *= 100;
                            } else {
                                tempFieldValue.efficiency /= 100;
                            }
                            return tempFieldValue;
                        }
                    );

                    this.setState({
                        asTestedCurves: tempAsTestedCurves,
                        operationalBaseLineCurves: tempAsOperationBaseLineCurves,
                        updatedFields: {
                            asTestedCurves: tempUpdatedAsTestedCurves,
                            operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
                        },
                    });
                });
            } else if (key === dataUnitsVariables.speed && objSI) {
                this.setState({ speedSI: objSI.toSI }, () => {
                    const { speedSI } = this.state;
                    const speedValue = this.props.form.getFieldValue('speed') || 0;
                    const speed = eval(speedSI.replace('value', speedValue)); // eslint-disable-line
                    const speedValueOperational =
                        this.props.form.getFieldValue('speedOperational') || 0;
                    const speedOperational = eval(speedSI.replace('value', speedValueOperational)); // eslint-disable-line

                    const {
                        asTestedCurves,
                        operationalBaseLineCurves,
                        headDropSI,
                        volumetricFlowSI,
                        updatedFields,
                    } = this.state;

                    const tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
                        const tempFieldValue = { ...fieldValue };
                        const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                        tempFieldValue.headCoefficient = coefficient.headCoefficient(
                            headDrop,
                            speed,
                            this.state.runnerData
                        );
                        const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                        tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                            volumetricFlow,
                            speed,
                            this.state.runnerData[0]
                        );
                        tempFieldValue.workInput = coefficient.workInputCoefficient(
                            tempFieldValue.efficiency,
                            tempFieldValue.headCoefficient
                        );
                        return tempFieldValue;
                    });

                    const tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop));    // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speed,
                                this.state.runnerData
                            );
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speed,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop));   // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedOperational,
                                this.state.runnerData
                            );
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedOperational,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempUpdatedAsOperationBaseLineCurves = updatedFields.operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedOperational,
                                this.state.runnerData
                            );
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedOperational,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );

                    this.setState({
                        asTestedCurves: tempAsTestedCurves,
                        operationalBaseLineCurves: tempAsOperationBaseLineCurves,
                        updatedFields: {
                            asTestedCurves: tempUpdatedAsTestedCurves,
                            operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
                        },
                    });
                });
            }
        } else {
            // When speed value is changed
            if (tableName === asTestedCurves) {
                this.props.form.setFieldsValue({
                    speed:
                        parseFloat(value.target.value) === 0
                            ? parseFloat(value.target.value)
                            : parseFloat(value.target.value.replace(/^0+/, '')),
                });
                const speedValue = this.props.form.getFieldValue('speed');
                if (speedValue) {
                    const {
                        asTestedCurves,
                        headDropSI,
                        volumetricFlowSI,
                        speedSI,
                        updatedFields,
                    } = this.state;

                    const speedValueInSI = eval(speedSI.replace('value', speedValue)); // eslint-disable-line

                    const tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
                        const tempFieldValue = { ...fieldValue };
                        const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop));  // eslint-disable-line
                        const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)); // eslint-disable-line
                        tempFieldValue.headCoefficient = coefficient.headCoefficient(
                            headDrop,
                            speedValueInSI,
                            this.state.runnerData
                        );
                        tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                            volumetricFlow,
                            speedValueInSI,
                            this.state.runnerData[0]
                        );
                        tempFieldValue.workInput = coefficient.workInputCoefficient(
                            tempFieldValue.efficiency,
                            tempFieldValue.headCoefficient
                        );
                        return tempFieldValue;
                    });

                    const tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop));  // eslint-disable-line
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedValueInSI,
                                this.state.runnerData
                            );
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedValueInSI,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );
                    this.setState({
                        asTestedCurves: tempAsTestedCurves,
                        updatedFields: {
                            ...this.state.updatedFields,
                            asTestedCurves: tempUpdatedAsTestedCurves,
                        },
                    });
                }
            } else if (tableName === operationalBaseLineCurves) {
                this.props.form.setFieldsValue({
                    speedOperational:
                        parseFloat(value.target.value) === 0
                            ? parseFloat(value.target.value)
                            : parseFloat(value.target.value.replace(/^0+/, '')),
                });
                const speedValue = this.props.form.getFieldValue('speedOperational');
                if (speedValue) {
                    const {
                        operationalBaseLineCurves,
                        headDropSI,
                        volumetricFlowSI,
                        speedSI,
                        updatedFields,
                    } = this.state;

                    const speedValueInSI = eval(speedSI.replace('value', speedValue));   // eslint-disable-line
                    const tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop));  // eslint-disable-line
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedValueInSI,
                                this.state.runnerData
                            );
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedValueInSI,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );

                    const tempUpdatedAsOperationBaseLineCurves = updatedFields.operationalBaseLineCurves.map(
                        (fieldValue) => {
                            const tempFieldValue = { ...fieldValue };
                            const headDrop = eval(headDropSI.replace('value', tempFieldValue.headDrop)); // eslint-disable-line
                            const volumetricFlow = eval(volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow));  // eslint-disable-line
                            tempFieldValue.headCoefficient = coefficient.headCoefficient(
                                headDrop,
                                speedValueInSI,
                                this.state.runnerData
                            );
                            tempFieldValue.flowCoefficient = coefficient.flowCoefficient(
                                volumetricFlow,
                                speedValueInSI,
                                this.state.runnerData[0]
                            );
                            tempFieldValue.workInput = coefficient.workInputCoefficient(
                                tempFieldValue.efficiency,
                                tempFieldValue.headCoefficient
                            );
                            return tempFieldValue;
                        }
                    );

                    this.setState({
                        operationalBaseLineCurves: tempAsOperationBaseLineCurves,
                        updatedFields: {
                            ...this.state.updatedFields,
                            operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
                        },
                    });
                }
            }
        }
    }

    renderAsTestedCurve() {
        return (
            <div>
                <div className="heading-table">
                    <div className="type-heading">{curvesLabel.asTestedCurve}</div>
                    <div className="unit-heading">
                        {this.props.isSchematicEditable ? curvesLabel.selectUnits : 'Units'}
                    </div>
                </div>
                <div>
                    <FormFields
                        formFields={AsTesteCurveFormField(this.state.unitData, {
                            change: (e, key) => {
                                this.onChangeSelect(e, key, asTestedCurves);
                            },
                            isCurveEditable: this.props.isSchematicEditable,
                        })}
                        form={this.props.form}
                    />
                </div>
                <div className="table-curve">
                    <CustomTable
                        showHeader
                        columns={curvesTableColumns({
                            isCurveEditable: this.props.isSchematicEditable,
                            onChange: (row, col, field) => {
                                this.onEditFieldChange(row, col, field, asTestedCurves);
                            },
                            edit: (row) => {
                                this.edit(row, asTestedCurves);
                                this.props.onDataEdit(true)
                            },
                            deleteRow: (row) => {
                                this.props.onDataEdit(true)
                                this.deleteRow(row, asTestedCurves);
                            },
                            undo: (row) => {
                                this.undo(row, asTestedCurves);
                            },
                            process: this.props.process,
                        })}
                        editableRow={this.state.editableRows.asTestedCurves}
                        data={this.state.asTestedCurves}
                        renderAddRow={
                            this.props.isSchematicEditable
                                ? this.state.asTestedCurves.length < 25
                                    ? curvesTableAddRow({
                                        onChange: (row, col, field) => {
                                            this.onAddFieldChange(row, col, field, asTestedCurves);
                                        },
                                        submit: (e) => {
                                            this.addRow(asTestedCurves);
                                        },
                                        isError: this.state.isError,
                                    })
                                    : null
                                : null
                        }
                        addFieldValues={this.state.addFieldValue}
                    />
                </div>
            </div>
        );
    }

    renderOperationBaseLineCurve() {
        return (
            <div>
                <div className="heading-table">
                    <div className="type-heading">
                        {curvesLabel.operationBaselineCurve}
                    </div>
                    <div className="unit-heading">
                        {this.props.isSchematicEditable ? curvesLabel.selectUnits : 'Units'}
                    </div>
                </div>
                <div>
                    <FormFields
                        formFields={AsTesteCurveFormFieldOperational(this.state.unitData, {
                            change: (e, key) => {
                                this.onChangeSelect(e, key, operationalBaseLineCurves);
                            },
                            isCurveEditable: this.props.isSchematicEditable,
                        })}
                        form={this.props.form}
                    />
                </div>
                <div className="table-curve">
                    <CustomTable
                        showHeader
                        columns={curvesTableColumns({
                            isCurveEditable: this.props.isSchematicEditable,
                            onChange: (row, col, field) => {
                                this.onEditFieldChange(
                                    row,
                                    col,
                                    field,
                                    operationalBaseLineCurves
                                );
                            },
                            edit: (row) => {
                                this.edit(row, operationalBaseLineCurves);
                                this.props.onDataEdit(true)
                            },
                            deleteRow: (row) => {
                                this.deleteRow(row, operationalBaseLineCurves);
                                this.props.onDataEdit(true)
                            },
                            undo: (row) => {
                                this.undo(row, operationalBaseLineCurves);
                            },
                            process: this.props.process,
                        })}
                        editableRow={this.state.editableRows.operationalBaseLineCurves}
                        data={this.state.operationalBaseLineCurves}
                        renderAddRow={
                            this.props.isSchematicEditable
                                ? this.state.operationalBaseLineCurves.length < 25
                                    ? curvesTableAddRow({
                                        onChange: (row, col, field) => {
                                            this.onAddFieldChange(
                                                row,
                                                col,
                                                field,
                                                operationalBaseLineCurves
                                            );
                                        },
                                        submit: (e) => {
                                            this.addRow(operationalBaseLineCurves);
                                        },
                                        isError: this.state.isErrorBaseLine,
                                    })
                                    : null
                                : null
                        }
                        addFieldValues={this.state.addFieldValueBaseLine}
                    />
                </div>
            </div>
        );
    }

    onDelete = () => {
        this.setState({ visibleDelete: true });
    };

    asTestedToOpertaional = () => {
        if (this.state.asTestedCurves.length) {
            if (this.state.dirtyRows.asTestedCurves.length === 0) {
                this.setState({
                    visibleOperational: true,
                });
            } else {
                Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
            }
        } else {
            Notification.show(Types.Info, CurvesMessages.DUPLICATE_MSG);
        }
    };

    handleCancelOperational() {
        this.setState({ visibleOperational: false });
    }

    handleOkOperational() {
        this.setState({ visibleOperational: false }, () => {
            this.setState({
                operationalBaseLineCurves: this.state.asTestedCurves,
                dirtyRows: {
                    asTestedCurves: [],
                    operationalBaseLineCurves: [],
                },
            });
            //   this.props.form.validateFields((error, value) => {
            //     this.props.form.setFieldsValue({
            //       speedOperational: value.speed,
            //     });
            //   });
        });
    }

    renderOperationalModal() {
        if (this.state.visibleOperational) {
            return (
                <ConfirmModal
                    title={CurvesMessages.COPY_TITLE}
                    visible={this.state.visibleOperational}
                    handleOk={() => this.handleOkOperational()}
                    handleCancel={() => this.handleCancelOperational()}
                    message={CurvesMessages.COPY_MSG}
                />
            );
        } else {
            return null;
        }
    }

    renderConfirmModal = () => {
        if (this.props.editSate) {
            return (
                <Modal
                    visible={this.props.saveWarning}
                    message={RESET_OR_SAVE}
                    onOk={() => { this.props.handleSaveWarning(false) }}
                    //   handleCancel = {() => this.props.handleSaveWarning(false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    title={"Alert"}
                    okText={"Ok"}
                >
                    {RESET_OR_SAVE}
                </ Modal>
            )
        }
        else {
            return (<></>)
        }
    }

    render() {
        const dataUnitLoading = this.props.dataUnitState.loading;
        const {
            compressorReducer,
            closable,
            variableCurves,
            isSchematicEditable
        } = this.props;
        const { loading } = this.props.curvesState;
        const { displayDelete } = this.state;
        return (
            <div className="Curves">
                <div className="CurvesInner">
                    {dataUnitLoading || loading || compressorReducer.loading ? (
                        <Loader />
                    ) : null}
                    <div className="curves-buttons">
                        {closable && variableCurves.length > 1 && displayDelete && (
                            <button className="btn-default" onClick={this.onDelete}>
                                Delete
                            </button>
                        )}

                        <button
                            className="btn-default btn-white"
                            onClick={() => {
                                this.showGraph();
                            }}
                        >
                            View Curve Plot
                        </button>
                        {isSchematicEditable && (
                            <button
                                className="btn-default btn-white"
                                onClick={this.asTestedToOpertaional}
                            >
                                {'Duplicate'}
                            </button>
                        )}
                    </div>
                    {this.renderAsTestedCurve()}

                    {this.renderOperationBaseLineCurve()}
                    {this.state.formEdit && this.props.isSchematicEditable ? (
                        <div className="config-bottom-buttons">
                            <button
                                className="btn-default btn-white"
                                onClick={() => {
                                    this.reset();
                                }}
                            >
                                {ButtonVariables.RESET}
                            </button>
                            <button
                                className="btn-default"
                                onClick={() => {
                                    this.save('save');
                                }}
                            >
                                {ButtonVariables.SAVE}
                            </button>
                            {variableCurves.length !== 8 && (
                                <button
                                    className="btn-default"
                                    onClick={() => {
                                        this.save('saveMore');
                                    }}
                                >
                                    Save and Add another curve
                                </button>
                            )}
                        </div>
                    ) : (
                        variableCurves.length < 8 &&
                        this.state.asTestedCurves.length !== 0 && this.state.operationalBaseLineCurves !== 0 &&
                        this.props.lastCurve === this.props.curveName
                    ) ?
                        <div className="config-bottom-buttons">
                            <button
                                className="btn-default"
                                onClick={() => {
                                    this.save('saveMore');
                                }}
                            >
                                Add another curve
                            </button>
                        </div>
                        : null}
                    {this.renderModal()}
                    {this.renderGraphModal()}
                    {this.renderDeleteModal()}
                    {this.renderOperationalModal()}
                    {this.renderConfirmModal()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    curvesState: state.CurvesReducer,
    dataUnitState: state.DataUnitReducer,
    runnerDataState: state.RunnerDataReducer,
    compressorReducer: state.CompressorReducer,
    network: state.NetworkReducer,
    RecipCompCurveState: state.RecipCompCurveState
});

const mapDispatchToProps = {
    addEditCurves,
    clearCurvesState,
    getDataUnits,
    clearDataUnitState,
    addEditCompressorCurves,
    clearCompressorCurvesState,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Form.create({
        name: 'curveForm',
        onFieldsChange: (props, fields, allfields) => {
            window.dispatchEvent(new Event('form-edit'));
        },
    })(withRouter(CurveScreen))
);
