import React from 'react';
import _ from 'lodash';
import AlertPlot from './AlertPlot';
import './AlertPrint.scss';
import { Col, Row } from 'antd';
import MechAlertLogo from '../../../../assets/print_page/Turbomechanica.png';
import MechLogo from '../../../../assets/print_page/MechademyLogo.png';
import TurbomechLogo from '../../../../assets/print_page/TurbomechLogo.png';
import moment from 'moment';
import { alertPlot } from './Alerts.constants';
import { roles } from '../../../../permissions';
import MultiTimeVarientLineGraph from '../../../../common/Graphs/MultiTimeVariantLineGraph';
import { Markup } from 'interweave';

export class PrintFrame extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      alertData: [],
      assignees: [],
      info: null,
      plotsLoaded: false,
    };
  }

  componentDidUpdate() {
    if (this.props.alertsData && this.props.alertsData.alertsData) {
      this.setState({
        alertData: this.props.alertsData,
        isLoaded: true,
        info: this.props.info,
      });
    }
  }

  getAssinees = () => {
    let assigneeList = [];
    const { alertData } = this.state;
    const { assignees, alertsData } = alertData;
    for (let person in assignees) {
      for (let assignee in alertsData.assignees) {
        if (assignees[person]._id === alertsData.assignees[assignee]) {
          if (
            assignees[person].role &&
            assignees[person].role[0] === 'MechademyAdmin'
          ) {
            assigneeList.push('Mechademy Admin');
          } else if (!assigneeList.length) {
            assigneeList.push(
              assignees[person].firstName + ' ' + assignees[person].lastName
            );
          } else {
            assigneeList.push(
              ', ' +
                assignees[person].firstName +
                ' ' +
                assignees[person].lastName +
                ' '
            );
          }
        }
      }
    }
    return assigneeList;
  };
  getMessages(messages) {
    if (
      messages &&
      messages.user &&
      messages.user.role &&
      messages.user.role[0] === 'MechademyAdmin'
    ) {
      return (
        <div className="discussion">
          <span className="discussion-name">
            {
              'Mechademy Admin' +
                ' ' +
                '(' +
                messages.user.role[0] +
                ')' /* eslint-disable-line */
            }
          </span>
          <span>
            {moment(messages.createdAt).format('DD-MMM-YYYY,h:mm:ss A')}
          </span>
          <div className="discussion-message">{messages.message}</div>
        </div>
      );
    } else {
      return (
        <div className="discussion">
          <span className="discussion-name">
            {
              messages.user && messages.user.firstName
                ? messages.user.firstName +
                  ' ' +
                  messages.user.lastName +
                  ' ' +
                  '(' +
                  messages.user.role[0] +
                  ')'
                : '' /* eslint-disable-line */
            }
          </span>
          <span>
            {moment(messages.createdAt).format('DD-MMM-YYYY,h:mm:ss A')}
          </span>
          <div className="discussion-message">{messages.message}</div>
        </div>
      );
    }
  }

  convertToPlain (html){
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
    
  getComments() {
    const { alertData } = this.state;
    const { userRole, alertsData } = alertData;
    if (
      (userRole === roles.MECHADEMY_ADMIN ||
        userRole === roles.MECHADEMY_FACILITY_MANAGER) &&
      alertsData.comment
    ) {
      return (
        <div>
          <div className="alert-heading">
            <span className="heading-alignment"> COMMENTS </span>
          </div>
          <div className="alert-data-align">
            <div className="alert-summary-value">
              {alertsData && alertsData.comment ? <Markup content={alertsData.comment} /> : ''}
            </div>
          </div>
        </div>
      );
    }
  }  
  
  transformSeries = (series, id) => {
  const { kpiPlotData } = this.props;
    series.forEach((piTag) => {
      if (kpiPlotData[id].metaData) {
        piTag.data = piTag.metaMaxMinPercentileData;
      } else {
        piTag.data = piTag.metaMaxMinData;
      }
    });
    return series;
  };

  getPlots() {
    const { kpiPlotData, kpiData } = this.props;
    let dataToReturn = [];
    for (let obj in kpiPlotData) {
      let series = null;
      const card = kpiData.find((i)=> i._id === obj);
      const numberOfDays =
        (kpiPlotData[obj]?.viewDateTill - kpiPlotData[obj]?.viewDateFrom) /
        (24 * 3600) /
        1000;
      if (
        kpiPlotData &&
        kpiPlotData[obj] &&
        kpiPlotData[obj].data
      ) {
        series = _.cloneDeep(kpiPlotData[obj]?.data);
      }
      if (numberOfDays >= 3 && numberOfDays < 45) {
        series = this.transformSeries(series, obj);
      }
      dataToReturn.push(
        <div>
          <br></br>
          <br></br>
          <MultiTimeVarientLineGraph
            id={obj}
            series={series}
            cardDetails={card}
            viewDateFrom={_.cloneDeep(kpiPlotData[obj].viewDateFrom)}
            viewDateTill={_.cloneDeep(kpiPlotData[obj].viewDateTill)}
            trends2={false}
            numberOfDays={kpiPlotData[obj].numberOfDays}
            metaData={kpiPlotData[obj].numberOfDays >= 3 ? true : false}
            alertPlots={true}
            alertPrint={true}
          />
          <br></br>
          <br></br>
        </div>
      );
    }
    return dataToReturn;
  }

  render() {
    const { isLoaded, alertData } = this.state;
    const { alertsData, discussion, userRole, descriptionText } = alertData;
    const alertPlotConst =
      alertData.plotData &&
      alertData.plotData.type === 'Univariate Anomaly Detection'
        ? 'IQR threshold exceedance'
        : alertData.plotData && alertData.plotData.type
        ? alertData.plotData.type
        : '';
    let tempArr = [];
    if(discussion && discussion.getMessage && discussion.getMessage.data) {
      for(let i = discussion.getMessage.data.length-1; i >= 0;i--)  {
        tempArr[
          (discussion.getMessage.data.length - i) - 1
        ] = discussion.getMessage.data[i];
      }
    }
    if (isLoaded === true) {
      return (
        <div className="alert-print-component">
          <div className="page-header">
            <Row>
              <Col span={16}>
               <div className="eq-var-num">{alertsData.fwdAlertId === '--' ? null : alertsData.fwdAlertId}</div>
                <div className="eq-name">
                  {alertsData.identifierDetails.equipmentName}&nbsp;
                  {alertsData.identifierDetails.equipmentTagNumber}
                </div>
              </Col>
              <Col span={8}>
                <div className="alert-logo">
                  <img
                    src={MechAlertLogo}
                    className="mechAlertLogo"
                    alt="mechAlertLogo"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <table>
            <thead>
              <tr>
                <td>
                  <div className="page-header-space"></div>
                </td>
              </tr>
            </thead>
            <tbody>
              <div className="page-margin">
                <div className="alert-heading">
                  <span className="heading-alignment"> ALERT SUMMARY </span>
                </div>
                <Row className="alert-data-align">
                  <Col span={6}>
                    Alert Date &amp; Time:{' '}
                    <div className="alert-summary-value">
                      {alertsData.dateTime
                        ? moment(alertsData.dateTime).format(
                            'DD-MMM-YYYY,h:mm:ss A'
                          )
                        : ''}
                    </div>
                  </Col>
                  <Col span={6}>
                    Alert Type:{' '}
                    <div className="alert-summary-value">{alertsData.type}</div>
                  </Col>
                  <Col span={6}>
                    Alert Level:{' '}
                    <div className="alert-summary-value">
                      {alertsData.level}
                    </div>
                  </Col>
                  <Col span={6}>
                    Alert Status:{' '}
                    <div className="alert-summary-value">
                      {userRole === roles.MECHADEMY_ADMIN ||
                      userRole === roles.MECHADEMY_FACILITY_MANAGER
                        ? alertsData.mechademyStatus
                        : alertsData.clientStatus}
                    </div>
                  </Col>
                  <Col span={6}>
                    Equipment:{' '}
                    <div
                      className="alert-summary-value"
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {alertsData.identifierDetails
                        ? (
                            alertsData.identifierDetails.equipmentName +
                            ' ' +
                            alertsData.identifierDetails.equipmentTagNumber
                          )
                            .split(' ')
                            .map((data) => {
                              return <span>{data}&nbsp;</span>;
                            })
                        : ''}
                    </div>
                  </Col>
                  <Col span={6}>
                    Tag:{' '}
                    <div className="alert-summary-value">
                      {alertsData.identifierDetails
                        ? alertsData.identifierDetails.variableTagNumber
                        : ''}
                    </div>
                  </Col>
                  <Col span={6}>
                    Tag Description:{' '}
                    <div
                      className="alert-summary-value"
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {alertsData.identifierDetails
                        ? alertsData.identifierDetails.variableName
                            .split(' ')
                            .map((data) => {
                              return <span>{data}&nbsp;</span>;
                            })
                        : ''}
                    </div>
                  </Col>
                  <Col span={6}>
                    Alert Assignee:{' '}
                    <div className="alert-summary-value">
                      {this.getAssinees()}
                    </div>
                  </Col>
                  <Col span={24}>
                    Event Description:{' '}
                    <div className="alert-values">{descriptionText}</div>
                  </Col>
                </Row>
                {this.getComments()}
                <div className="alert-heading">
                  <span className="heading-alignment"> ALERT INSIGHTS </span>
                </div>
                <Row className="alert-data-align">
                  Insights:
                  <Col span={24}>
                    <div className="alert-values">{alertsData.insights ? <Markup content={alertsData.insights} />: ""}</div>
                  </Col>
                </Row>
                <Row className="alert-insights">
                  Resources:
                  <Col span={24}>
                    <div className="alert-values">{alertsData.resource}</div>
                  </Col>
                </Row>
                <div>
                  <Row>
                    <Col className="alert-plot">
                      {alertData.plotData &&
                      (alertPlotConst === alertPlot.OUT_OF_BOUND ||
                        alertPlotConst === alertPlot.IQR_THRESHOLD ||
                        alertPlotConst === alertPlot.PROCESS_DEVIATION ||
                        alertPlotConst === alertPlot.IOW) ? (
                        <AlertPlot
                          unit={
                            alertData.plotData
                              ? alertData.plotData.alertMetaObject
                                ? alertData.plotData.alertMetaObject.unit
                                : ''
                              : ''
                          }
                          alertId={
                            alertData.plotData ? alertData.plotData._id : ''
                          }
                          alertPrint={true} //eslint-disable-line
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="alert-heading alert-discussion">
                  <span className="heading-alignment"> ALERT PLOTS </span>
                </div>
                <Row>
                  <Col className="alert-kpi-plot">{this.getPlots()}</Col>
                </Row>
                <div className="alert-heading alert-discussion">
                  <span className="heading-alignment">
                    {' '}
                    ALERT DISCUSSION BOARD
                  </span>
                </div>
                <Row className="alert-data-align">
                  {}
                  {tempArr.map((message, i) => {
                    return this.getMessages(message);
                  })}
                </Row>
              </div>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="page-footer">
            <Row>
              <Col span={12} className="footer-logo-align">
                <a href="https://www.mechademy.com/">
                  <img
                    src={MechLogo}
                    className="footer-logo"
                    alt="footer-logo"
                  />
                </a>
              </Col>
              <Col span={12} className="footer-logo-align turbo-mech-logo">
                <a href="https://turbomechanica.com/">
                  {' '}
                  <img
                    src={TurbomechLogo}
                    className="footer-logo"
                    alt="footer-logo"
                  />
                </a>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return <></>;
  }
}