import { Switch } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../common/Loader/Loader';
import {
  validatePositiveFloat,
  validateFloat,
} from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../../../constants/messages';
import { ButtonVariables } from '../../../../../constants/variables';
import {
  addEditDesign,
  clearDesignState,
  getDataVariables,
} from '../../../../../redux/actions/design.action';
import {
  Compressor,
  Liquid_Expander,
  Pump,
  Flow_Meter,
} from '../ConfigDataConstants';
import {
  getdataVariable,
  tableCalculatedValue,
  tableDataVariables,
  tableDischargeConditions,
  tableInletCondition,
  tableOtherDesignData,
  tablePerformanceValues,
} from './DesignData.constants';
import './DesignData.scss';
import PropTypes from 'prop-types';

class DesignData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirtyRows: [],
      designData: {},
      preserveDesignData: {},
      editRow: {},
      updatedFields: {},
      formEdit: false,
      dataUnits: {},
      visible: false,
      isDischargeTempSensorAvailable: false,
      preserveDischargeTempSensorAvailable: false,
      addListener: false,
    };
  }

  componentDidMount() {
    const { name } = this.props.location.state.componentData.assetInfo;
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });

    this.getDataVariables(getdataVariable(name));
  }

  componentDidUpdate() {
    const {
      dataVariables,
      isDataVariableError,
      isDesignError,
      isDataVariableSuccess,
      isDesignAddSuccess,
      errorCode,
    } = this.props.designState.apiState;
    const { clearDesignState } = this.props;
    const { configData, isConfigData, clearConfigState } = this.props;
    const { name } = this.props.location.state.componentData.assetInfo;

    if (isDataVariableSuccess) {
      clearDesignState();
      const obj = {};
      for (let i = 0; i < dataVariables.items.length; i++) {
        obj[dataVariables.items[i].dataVariable] = dataVariables.items[i].units;
      }
      this.setState({ dataUnits: obj });
    }

    if (isDataVariableError) {
      clearDesignState();
      Notification.show(Types.Error, errorCode);
    }

    if (isDesignAddSuccess) {
      clearDesignState();
      this.setState({
        addListener: false,
      });
      Notification.show(Types.Success, DesignDataMessages.ADDED);
    }

    if (isDesignError) {
      clearDesignState();
      Notification.show(Types.Error, DesignDataMessages[errorCode]);
    }

    if (isConfigData) {
      if (name === Compressor) {
        if (configData.designGuaranteeData) {
          const { dischargeConditions } = configData.designGuaranteeData;
          this.setState({
            isDischargeTempSensorAvailable:
              dischargeConditions &&
              dischargeConditions.isDischargeTempSensorAvailable,
            preserveDischargeTempSensorAvailable:
              dischargeConditions &&
              dischargeConditions.isDischargeTempSensorAvailable,
          });
        }

        const payload = {
          dischargeConditions: {
            outletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.design
                  : '',
            },
            outletTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletTemperature
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletTemperature
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletTemperature.design
                  : '',
            },
          },
          dataVariable: {
            speed: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.speed
                  ? configData.designGuaranteeData.dataVariable.speed.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.speed
                  ? configData.designGuaranteeData.dataVariable.speed.design
                  : '',
            },
            massFlowRate: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.massFlowRate
                  ? configData.designGuaranteeData.dataVariable.massFlowRate
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.massFlowRate
                  ? configData.designGuaranteeData.dataVariable.massFlowRate
                      .design
                  : '',
            },
          },
          inletConditions: {
            inletTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .inletTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .inletTemperature.design
                  : '',
            },
            inletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletPressure
                  ? configData.designGuaranteeData.inletConditions.inletPressure
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletPressure
                  ? configData.designGuaranteeData.inletConditions.inletPressure
                      .design
                  : '',
            },
            actualInletVolumetricFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .actualInletVolumetricFlow
                  ? configData.designGuaranteeData.inletConditions
                      .actualInletVolumetricFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .actualInletVolumetricFlow
                  ? configData.designGuaranteeData.inletConditions
                      .actualInletVolumetricFlow.design
                  : '',
            },
          },
          calculatedValues: {
            nConstant: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.nConstant
                  ? configData.designGuaranteeData.calculatedValues.nConstant
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.nConstant
                  ? configData.designGuaranteeData.calculatedValues.nConstant
                      .design
                  : '',
            },
            stagePower: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.stagePower
                  ? configData.designGuaranteeData.calculatedValues.stagePower
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.stagePower
                  ? configData.designGuaranteeData.calculatedValues.stagePower
                      .design
                  : '',
            },
            trainPower: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.trainPower
                  ? configData.designGuaranteeData.calculatedValues.trainPower
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.trainPower
                  ? configData.designGuaranteeData.calculatedValues.trainPower
                      .design
                  : '',
            },
            polytropicHead: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.polytropicHead
                  ? configData.designGuaranteeData.calculatedValues
                      .polytropicHead.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.polytropicHead
                  ? configData.designGuaranteeData.calculatedValues
                      .polytropicHead.design
                  : '',
            },
            polytropicEfficiency: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues
                  .polytropicEfficiency
                  ? configData.designGuaranteeData.calculatedValues
                      .polytropicEfficiency.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues
                  .polytropicEfficiency
                  ? configData.designGuaranteeData.calculatedValues
                      .polytropicEfficiency.design
                  : '',
            },
          },
        };
        clearConfigState();
        this.disableEditForm();
        this.setState({
          designData: _.cloneDeep(payload),
          preserveDesignData: _.cloneDeep(payload),
        });
      } else if (name === Liquid_Expander) {
        const payload = {
          dischargeConditions: {
            outletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.design
                  : '',
            },
          },
          dataVariable: {
            specificGravity: {
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable &&
                configData.designGuaranteeData.dataVariable.specificGravity
                  ? configData.designGuaranteeData.dataVariable.specificGravity
                      .design
                  : '',
            },
            speed: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.speed
                  ? configData.designGuaranteeData.dataVariable.speed.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.speed
                  ? configData.designGuaranteeData.dataVariable.speed.design
                  : '',
            },
            massFlowRate: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.massFlowRate
                  ? configData.designGuaranteeData.dataVariable.massFlowRate
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.massFlowRate
                  ? configData.designGuaranteeData.dataVariable.massFlowRate
                      .design
                  : '',
            },
            vaporPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.vaporPressure
                  ? configData.designGuaranteeData.dataVariable.vaporPressure
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.vaporPressure
                  ? configData.designGuaranteeData.dataVariable.vaporPressure
                      .design
                  : '',
            },
            dynamicViscosity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.dynamicViscosity
                  ? configData.designGuaranteeData.dataVariable.dynamicViscosity
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.dynamicViscosity
                  ? configData.designGuaranteeData.dataVariable.dynamicViscosity
                      .design
                  : '',
            },
          },
          inletConditions: {
            inletTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .inletTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .inletTemperature.design
                  : '',
            },
            inletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletPressure
                  ? configData.designGuaranteeData.inletConditions.inletPressure
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.inletPressure
                  ? configData.designGuaranteeData.inletConditions.inletPressure
                      .design
                  : '',
            },
            actualInletVolumetricFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .actualInletVolumetricFlow
                  ? configData.designGuaranteeData.inletConditions
                      .actualInletVolumetricFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .actualInletVolumetricFlow
                  ? configData.designGuaranteeData.inletConditions
                      .actualInletVolumetricFlow.design
                  : '',
            },
          },
          calculatedValues: {
            differentialPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues
                  .differentialPressure
                  ? configData.designGuaranteeData.calculatedValues
                      .differentialPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues
                  .differentialPressure
                  ? configData.designGuaranteeData.calculatedValues
                      .differentialPressure.design
                  : '',
            },
            differentialHead: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.differentialHead
                  ? configData.designGuaranteeData.calculatedValues
                      .differentialHead.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.differentialHead
                  ? configData.designGuaranteeData.calculatedValues
                      .differentialHead.design
                  : '',
            },
            efficiency: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.efficiency
                  ? configData.designGuaranteeData.calculatedValues.efficiency
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.efficiency
                  ? configData.designGuaranteeData.calculatedValues.efficiency
                      .design
                  : '',
            },

            outputPower: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.outputPower
                  ? configData.designGuaranteeData.calculatedValues.outputPower
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.calculatedValues.outputPower
                  ? configData.designGuaranteeData.calculatedValues.outputPower
                      .design
                  : '',
            },
          },
        };
        clearConfigState();
        this.disableEditForm();
        this.setState({
          designData: _.cloneDeep(payload),
          preserveDesignData: _.cloneDeep(payload),
        });
      } else if (name === Pump) {
        const payload = {
          dischargeConditions: {
            outletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dischargeConditions
                  .outletPressure
                  ? configData.designGuaranteeData.dischargeConditions
                      .outletPressure.design
                  : '',
            },
          },
          dataVariable: {
            inletNozzleSize: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.inletNozzleSize
                  ? configData.designGuaranteeData.dataVariable.inletNozzleSize
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.inletNozzleSize
                  ? configData.designGuaranteeData.dataVariable.inletNozzleSize
                      .design
                  : '',
            },
            outletNozzleSize: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.outletNozzleSize
                  ? configData.designGuaranteeData.dataVariable.outletNozzleSize
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.outletNozzleSize
                  ? configData.designGuaranteeData.dataVariable.outletNozzleSize
                      .design
                  : '',
            },
            normalCapacity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.normalCapacity
                  ? configData.designGuaranteeData.dataVariable.normalCapacity
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.normalCapacity
                  ? configData.designGuaranteeData.dataVariable.normalCapacity
                      .design
                  : '',
            },
            ratedCapacity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.ratedCapacity
                  ? configData.designGuaranteeData.dataVariable.ratedCapacity
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.ratedCapacity
                  ? configData.designGuaranteeData.dataVariable.ratedCapacity
                      .design
                  : '',
            },
            specificGravity: {
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.specificGravity
                  ? configData.designGuaranteeData.dataVariable.specificGravity
                      .design
                  : '',
            },
            vaporPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.vaporPressure
                  ? configData.designGuaranteeData.dataVariable.vaporPressure
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.vaporPressure
                  ? configData.designGuaranteeData.dataVariable.vaporPressure
                      .design
                  : '',
            },
            dynamicViscosity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.dynamicViscosity
                  ? configData.designGuaranteeData.dataVariable.dynamicViscosity
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.dynamicViscosity
                  ? configData.designGuaranteeData.dataVariable.dynamicViscosity
                      .design
                  : '',
            },
            npsha: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.npsha
                  ? configData.designGuaranteeData.dataVariable.npsha.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.npsha
                  ? configData.designGuaranteeData.dataVariable.npsha.design
                  : '',
            },
          },
          inletConditions: {
            maxInletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.maxInletPressure
                  ? configData.designGuaranteeData.inletConditions
                      .maxInletPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions.maxInletPressure
                  ? configData.designGuaranteeData.inletConditions
                      .maxInletPressure.design
                  : '',
            },
            ratedInletPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .ratedInletPressure
                  ? configData.designGuaranteeData.inletConditions
                      .ratedInletPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .ratedInletPressure
                  ? configData.designGuaranteeData.inletConditions
                      .ratedInletPressure.design
                  : '',
            },
            minPumpingTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .minPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .minPumpingTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .minPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .minPumpingTemperature.design
                  : '',
            },
            normalPumpingTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .normalPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .normalPumpingTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .normalPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .normalPumpingTemperature.design
                  : '',
            },
            maxPumpingTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .maxPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .maxPumpingTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.inletConditions
                  .maxPumpingTemperature
                  ? configData.designGuaranteeData.inletConditions
                      .maxPumpingTemperature.design
                  : '',
            },
          },
          performanceValues: {
            differentialPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .differentialPressure
                  ? configData.designGuaranteeData.performanceValues
                      .differentialPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .differentialPressure
                  ? configData.designGuaranteeData.performanceValues
                      .differentialPressure.design
                  : '',
            },
            differentialHead: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .differentialHead
                  ? configData.designGuaranteeData.performanceValues
                      .differentialHead.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .differentialHead
                  ? configData.designGuaranteeData.performanceValues
                      .differentialHead.design
                  : '',
            },
            npshMarginRatedCapacity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .npshMarginRatedCapacity
                  ? configData.designGuaranteeData.performanceValues
                      .npshMarginRatedCapacity.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .npshMarginRatedCapacity
                  ? configData.designGuaranteeData.performanceValues
                      .npshMarginRatedCapacity.design
                  : '',
            },
            npshrRatedCapacity: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .npshrRatedCapacity
                  ? configData.designGuaranteeData.performanceValues
                      .npshrRatedCapacity.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues
                  .npshrRatedCapacity
                  ? configData.designGuaranteeData.performanceValues
                      .npshrRatedCapacity.design
                  : '',
            },
            efficiency: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues.efficiency
                  ? configData.designGuaranteeData.performanceValues.efficiency
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues.efficiency
                  ? configData.designGuaranteeData.performanceValues.efficiency
                      .design
                  : '',
            },
            ratedPower: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues.ratedPower
                  ? configData.designGuaranteeData.performanceValues.ratedPower
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.performanceValues.ratedPower
                  ? configData.designGuaranteeData.performanceValues.ratedPower
                      .design
                  : '',
            },
          },
          otherDesignData: {
            minThermalContinuousFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minThermalContinuousFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minThermalContinuousFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minThermalContinuousFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minThermalContinuousFlow.design
                  : '',
            },
            minStableContinuousFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minStableContinuousFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minStableContinuousFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minStableContinuousFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minStableContinuousFlow.design
                  : '',
            },
            minPreferredOperatingFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minPreferredOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minPreferredOperatingFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minPreferredOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minPreferredOperatingFlow.design
                  : '',
            },
            maxPreferredOperatingFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxPreferredOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .maxPreferredOperatingFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxPreferredOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .maxPreferredOperatingFlow.design
                  : '',
            },
            minAllowableOperatingFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minAllowableOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minAllowableOperatingFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .minAllowableOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .minAllowableOperatingFlow.design
                  : '',
            },
            maxAllowableOperatingFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxAllowableOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .maxAllowableOperatingFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxAllowableOperatingFlow
                  ? configData.designGuaranteeData.otherDesignData
                      .maxAllowableOperatingFlow.design
                  : '',
            },
            maxHeadAtRatedImpeller: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxHeadAtRatedImpeller
                  ? configData.designGuaranteeData.otherDesignData
                      .maxHeadAtRatedImpeller.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxHeadAtRatedImpeller
                  ? configData.designGuaranteeData.otherDesignData
                      .maxHeadAtRatedImpeller.design
                  : '',
            },
            maxPowerAtRatedImpeller: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxPowerAtRatedImpeller
                  ? configData.designGuaranteeData.otherDesignData
                      .maxPowerAtRatedImpeller.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.otherDesignData
                  .maxPowerAtRatedImpeller
                  ? configData.designGuaranteeData.otherDesignData
                      .maxPowerAtRatedImpeller.design
                  : '',
            },
          },
        };

        clearConfigState();
        this.disableEditForm();
        this.setState({
          designData: _.cloneDeep(payload),
          preserveDesignData: _.cloneDeep(payload),
        });
      } else if (name === Flow_Meter) {
        const payload = {
          dataVariable: {
            fluidPhase: {
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.fluidPhase
                  ? configData.designGuaranteeData.dataVariable.fluidPhase
                  : '',
            },
            minFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.minFlow
                  ? configData.designGuaranteeData.dataVariable.minFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.minFlow
                  ? configData.designGuaranteeData.dataVariable.minFlow.design
                  : '',
            },
            maxFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxFlow
                  ? configData.designGuaranteeData.dataVariable.maxFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxFlow
                  ? configData.designGuaranteeData.dataVariable.maxFlow.design
                  : '',
            },
            normalFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.normalFlow
                  ? configData.designGuaranteeData.dataVariable.normalFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.normalFlow
                  ? configData.designGuaranteeData.dataVariable.normalFlow
                      .design
                  : '',
            },
            operatingPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.operatingPressure
                  ? configData.designGuaranteeData.dataVariable
                      .operatingPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.operatingPressure
                  ? configData.designGuaranteeData.dataVariable
                      .operatingPressure.design
                  : '',
            },
            maxPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxPressure
                  ? configData.designGuaranteeData.dataVariable.maxPressure.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxPressure
                  ? configData.designGuaranteeData.dataVariable.maxPressure
                      .design
                  : '',
            },
            operatingTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.operatingTemperature
                  ? configData.designGuaranteeData.dataVariable
                      .operatingTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.operatingTemperature
                  ? configData.designGuaranteeData.dataVariable
                      .operatingTemperature.design
                  : '',
            },
            maxTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxTemperature
                  ? configData.designGuaranteeData.dataVariable.maxTemperature
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.maxTemperature
                  ? configData.designGuaranteeData.dataVariable.maxTemperature
                      .design
                  : '',
            },
            maxAllowablePressureDrop: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .maxAllowablePressureDrop
                  ? configData.designGuaranteeData.dataVariable
                      .maxAllowablePressureDrop.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .maxAllowablePressureDrop
                  ? configData.designGuaranteeData.dataVariable
                      .maxAllowablePressureDrop.design
                  : '',
            },
            operatingSpecificGravity: {
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .operatingSpecificGravity
                  ? configData.designGuaranteeData.dataVariable
                      .operatingSpecificGravity
                  : '',
            },
            differentialPressureAtMaxFlow: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .differentialPressureAtMaxFlow
                  ? configData.designGuaranteeData.dataVariable
                      .differentialPressureAtMaxFlow.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .differentialPressureAtMaxFlow
                  ? configData.designGuaranteeData.dataVariable
                      .differentialPressureAtMaxFlow.design
                  : '',
            },
            flowrateRange: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.flowrateRange
                  ? configData.designGuaranteeData.dataVariable.flowrateRange
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.flowrateRange
                  ? configData.designGuaranteeData.dataVariable.flowrateRange
                      .design
                  : '',
            },
            accuracy: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.accuracy
                  ? configData.designGuaranteeData.dataVariable.accuracy.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.accuracy
                  ? configData.designGuaranteeData.dataVariable.accuracy.design
                  : '',
            },
            standardPressure: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.standardPressure
                  ? configData.designGuaranteeData.dataVariable.standardPressure
                      .unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable.standardPressure
                  ? configData.designGuaranteeData.dataVariable.standardPressure
                      .design
                  : '',
            },
            standardTemperature: {
              unit:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .standardTemperature
                  ? configData.designGuaranteeData.dataVariable
                      .standardTemperature.unit
                  : '',
              design:
                configData.designGuaranteeData &&
                configData.designGuaranteeData.dataVariable
                  .standardTemperature
                  ? configData.designGuaranteeData.dataVariable
                      .standardTemperature.design
                  : '',
            },
          },
        };
        clearConfigState();
        this.disableEditForm();
        this.setState({
          designData: _.cloneDeep(payload),
          preserveDesignData: _.cloneDeep(payload),
        });
      }
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getDataVariables(dataVariables) {
    const { getDataVariables } = this.props;
    if (this.handleNetwork()) {
      getDataVariables({ dataVariables });
    }
  }

  getDataArray(data, key) {
    const temp = [];
    if (data) {
      Object.keys(data).forEach((item) => {
        if (item !== '_id' && item !== 'isDischargeTempSensorAvailable') {
          data[item][key] = item;
          temp.push(data[item]);
        }
      });
    }
    return _.cloneDeep(temp);
  }

  onValueChange(field, row, key, item) {
    const { designData, dirtyRows } = this.state;
    let tempDirtyRow = [...dirtyRows];
    const temp = { ...designData };
    const rowKey = Object.keys(temp[key])[row];

    if (
      item.calculatedValues !== 'nConstant' &&
      item.dataVariable !== 'specificGravity' &&
      item.dataVariable !== 'fluidPhase'
    ) {
      if (
        (field.name === 'unit' &&
          ((field.value && !isFinite(item.design)) ||
            (!field.value && isFinite(item.design)))) ||
        (field.name === 'design' &&
          ((!isFinite(parseFloat(field.value)) && item.unit) ||
            (isFinite(parseFloat(field.value)) && !item.unit)))
      ) {
        tempDirtyRow.push(row);
      } else {
        tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
      }
    }

    if (!temp[key]) {
      temp[key] = {};
    } else {
      temp[key][rowKey][field.name] = field.value;
    }

    this.setState({
      designData: _.cloneDeep(temp),
      dirtyRows: tempDirtyRow,
    });
  }

  edit(row, key) {
    const { editRow, updatedFields, designData } = this.state;
    const temp = { ...editRow };
    const rowKey = Object.keys(designData[key])[row];
    const updatedTemp = { ...updatedFields };

    if (!temp[key]) {
      temp[key] = [];
    }
    temp[key].push(row);
    if (!updatedTemp[key]) {
      updatedTemp[key] = {};
    }
    updatedTemp[key][rowKey] = _.cloneDeep(designData[key][rowKey]);
    this.setState(
      {
        editRow: temp,
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  delete(row, key) {
    const { designData, dirtyRows } = this.state;
    let tempDirtyRow = [...dirtyRows];
    const temp = { ...designData };
    const rowKey = Object.keys(temp[key])[row];
    delete temp[key][rowKey];
    tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
    this.setState(
      {
        designData: _.cloneDeep(temp),
        dirtyRows: tempDirtyRow,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row, key) {
    const { editRow, updatedFields, designData, dirtyRows } = this.state;
    let tempDirtyRow = [...dirtyRows];
    const temp = { ...editRow };
    const rowKey = Object.keys(designData[key])[row];
    const updatedTemp = _.cloneDeep(designData);
    tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
    temp[key] = temp[key].filter((e) => e !== row);
    updatedTemp[key][rowKey] = { ...updatedFields[key][rowKey] };
    this.setState(
      {
        editRow: temp,
        designData: _.cloneDeep(updatedTemp),
        dirtyRows: tempDirtyRow,
      },
      () => {}
    );
  }

  validateFields = () => {
    const { designData } = this.state;
    const { name } = this.props.location.state.componentData.assetInfo;
    const tempDirtyRow = [];

    Object.values(designData).forEach((item) => {
      Object.values(item).forEach((element) => {
        if (_.isFinite(parseFloat(element.design))) {
          element.design = element.design.toString();
        }
      });
    });
    Object.values(designData).forEach((item) => {
      Object.values(item).forEach((element) => {
        if (name === Compressor) {
          if (
            element.calculatedValues === 'nConstant' &&
            (!element.design ||
              (element.design &&
                !validatePositiveFloat(element.design.toString())))
          ) {
            tempDirtyRow.push(element);
          }
        } else if (name === Flow_Meter) {
          if ((element.dataVariable === 'fluidPhase' ||
                element.dataVariable === 'standardPressure' ||
                element.dataVariable === 'standardTemperature'
              ) && !element.design) {
            tempDirtyRow.push(element);
          }
        } else if (
          element.dataVariable === 'specificGravity' &&
          (!element.design ||
            (element.design &&
              !validatePositiveFloat(element.design.toString())))
        ) {
          tempDirtyRow.push(element);
        }

        if (
          (name === Compressor
            ? element.calculatedValues !== 'nConstant'
            : element.dataVariable !== 'specificGravity' &&
              element.dataVariable !== 'operatingSpecificGravity' &&
              element.dataVariable !== 'fluidPhase') &&
          !element.unit &&
          element.design &&
          element.design.trim()
        ) {
          tempDirtyRow.push(element);
        }

        if (
          element.unit &&
          !element.design &&
          parseFloat(element.design) !== 0
        ) {
          tempDirtyRow.push(element);
        }

        if (element.unit && element.design) {
          if (
            element.inletConditions === 'inletTemperature' ||
            element.inletConditions === 'minPumpingTemperature' ||
            element.inletConditions === 'normalPumpingTemperature' ||
            element.inletConditions === 'maxPumpingTemperature' ||
            element.dischargeConditions === 'outletTemperature' ||
            element.calculatedValues === 'differentialHead' ||
            element.calculatedValues === 'differentialPressure' ||
            element.dataVariable === 'operatingTemperature' ||
            element.dataVariable === 'maxTemperature'
          ) {
            !validateFloat(element.design.toString()) &&
              tempDirtyRow.push(element);
          } else {
            !validatePositiveFloat(element.design.toString()) &&
              tempDirtyRow.push(element);
          }
        }
      });
    });

    if (tempDirtyRow.length > 0) {
      return false;
    }
    return true;
  };

  save() {
    const {
      location: { state },
    } = this.props;
    const { addEditDesignData } = this.props;
    const { designData, isDischargeTempSensorAvailable } = this.state;
    const { name } = this.props.location.state.componentData.assetInfo;
    this.setState({
      addListener: true,
    });
    if (this.validateFields()) {
      if (name === Compressor) {
        delete designData.calculatedValues.nConstant.unit;
      }
      let fluidPhaseValue = '';
      if (name === Flow_Meter) {
        fluidPhaseValue = designData.dataVariable.fluidPhase
          ? designData.dataVariable.fluidPhase.design
          : '';
      }
      const temp = _.cloneDeep(designData);
      Object.keys(temp).forEach((item) => {
        Object.keys(temp[item]).forEach((data) => {
          delete temp[item][data][item];
        });
      });

      Object.keys(temp).forEach((item) => {
        Object.keys(temp[item]).forEach((data) => {
          if (
            !Object.values(Object.values(temp[item][data]))[0] &&
            !parseFloat(Object.values(Object.values(temp[item][data]))[1]) &&
            parseFloat(Object.values(Object.values(temp[item][data]))[0]) !== 0
          ) {
            delete temp[item][data];
          }
        });
      });

      Object.keys(temp).forEach((item) => {
        Object.keys(temp[item]).forEach((data) => {
          if (_.isFinite(parseFloat(temp[item][data].design))) {
            temp[item][data].design = parseFloat(temp[item][data].design);
          }
        });
      });
      if (name === Flow_Meter) {
        if (
          temp.dataVariable &&
          temp.dataVariable.fluidPhase &&
          temp.dataVariable.fluidPhase.design
        ) {
          temp.dataVariable.fluidPhase = fluidPhaseValue.trim();
        }
        if (
          temp.dataVariable &&
          temp.dataVariable.operatingSpecificGravity &&
          temp.dataVariable.operatingSpecificGravity.design
        ) {
          const value = temp.dataVariable.operatingSpecificGravity.design;
          temp.dataVariable.operatingSpecificGravity = value;
        }
      }
      const payload = {
        designGuaranteeData: temp,
      };
      if (name === Compressor) {
        payload.designGuaranteeData.dischargeConditions.isDischargeTempSensorAvailable = isDischargeTempSensorAvailable;
      }
      if (this.handleNetwork() && state.componentData) {
        addEditDesignData(
          payload.designGuaranteeData,
          state.componentData.info._id,
          name
        );
      }
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  }

  clearDirtyForm() {
    const {
      preserveDesignData,
      preserveDischargeTempSensorAvailable,
    } = this.state;
    this.setState({
      designData: _.cloneDeep(preserveDesignData),
      editRow: {},
      dirtyRows: [],
      updatedFields: {},
      isDischargeTempSensorAvailable: preserveDischargeTempSensorAvailable,
      addListener: false,
    });
  }

  reset() {
    const { formEdit } = this.state;
    if (formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { preserveDischargeTempSensorAvailable } = this.state;
    this.setState(
      {
        visible: false,
        isDischargeTempSensorAvailable: preserveDischargeTempSensorAvailable,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  changeDischargeTempSensorAvailable = (value) => {
    this.setState(
      {
        isDischargeTempSensorAvailable: value,
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  render() {
    const {
      dataUnits,
      addListener,
      designData,
      editRow,
      isDischargeTempSensorAvailable,
      formEdit,
    } = this.state;
    const { name } = this.props.location.state.componentData.assetInfo;
    const { loading } = this.props.designState;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignData">
        {loading ? <Loader /> : null}
        <CustomTable
          columns={tableDataVariables({
            isDesignEditable: isSchematicEditable,
            change: (field, row, item) => {
              this.onValueChange(field, row, 'dataVariable', item);
            },
            edit: (row) => {
              this.edit(row, 'dataVariable');
            },
            delete: (row) => {
              this.delete(row, 'dataVariable');
            },
            undo: (row) => {
              this.undo(row, 'dataVariable');
            },
            dataUnits,
            name,
            addListener,
          })}
          data={this.getDataArray(designData.dataVariable, 'dataVariable')}
          editableRow={editRow.dataVariable}
          showHeader
        />
        {name !== Flow_Meter ? (
          <CustomTable
            columns={tableInletCondition({
              isDesignEditable: isSchematicEditable,
              change: (field, row, item) => {
                this.onValueChange(field, row, 'inletConditions', item);
              },
              edit: (row) => {
                this.edit(row, 'inletConditions');
              },
              delete: (row) => {
                this.delete(row, 'inletConditions');
              },
              undo: (row) => {
                this.undo(row, 'inletConditions');
              },
              dataUnits,
              name,
            })}
            data={this.getDataArray(
              designData.inletConditions,
              'inletConditions'
            )}
            editableRow={editRow.inletConditions}
            showHeader
          />
        ) : (
          ''
        )}
        {name !== Flow_Meter ? (
          <CustomTable
            columns={tableDischargeConditions({
              isDesignEditable: isSchematicEditable,
              change: (field, row, item) => {
                this.onValueChange(field, row, 'dischargeConditions', item);
              },
              edit: (row) => {
                this.edit(row, 'dischargeConditions');
              },
              delete: (row) => {
                this.delete(row, 'dischargeConditions');
              },
              undo: (row) => {
                this.undo(row, 'dischargeConditions');
              },
              dataUnits,
              name,
            })}
            data={this.getDataArray(
              designData.dischargeConditions,
              'dischargeConditions'
            )}
            editableRow={editRow.dischargeConditions}
            showHeader
          />
        ) : (
          ''
        )}
        {name === Compressor && (
          <div>
            <div className="sensorCheck">
              <span>
                Discharge Temperature Sensor Available?
                <span className="sensorCheckImportant"> *</span>
              </span>
              <Switch
                checked={isDischargeTempSensorAvailable}
                onChange={this.changeDischargeTempSensorAvailable}
                disabled={!isSchematicEditable}
              />
            </div>
            <br />
          </div>
        )}
        {(name === Compressor || name === Liquid_Expander) && (
          <div>
            <CustomTable
              columns={tableCalculatedValue({
                isDesignEditable: isSchematicEditable,
                change: (field, row, item) => {
                  this.onValueChange(field, row, 'calculatedValues', item);
                },
                edit: (row) => {
                  this.edit(row, 'calculatedValues');
                },
                delete: (row) => {
                  this.delete(row, 'calculatedValues');
                },
                undo: (row) => {
                  this.undo(row, 'calculatedValues');
                },
                dataUnits: dataUnits,
                name,
                addListener,
              })}
              data={this.getDataArray(
                designData.calculatedValues,
                'calculatedValues'
              )}
              editableRow={editRow.calculatedValues}
              showHeader
            />
          </div>
        )}

        {name === Pump && (
          <div>
            <CustomTable
              columns={tablePerformanceValues({
                isDesignEditable: isSchematicEditable,
                change: (field, row, item) => {
                  this.onValueChange(field, row, 'performanceValues', item);
                },
                edit: (row) => {
                  this.edit(row, 'performanceValues');
                },
                delete: (row) => {
                  this.delete(row, 'performanceValues');
                },
                undo: (row) => {
                  this.undo(row, 'performanceValues');
                },
                dataUnits: dataUnits,
                name,
              })}
              data={this.getDataArray(
                designData.performanceValues,
                'performanceValues'
              )}
              editableRow={editRow.performanceValues}
              showHeader
            />
            <CustomTable
              columns={tableOtherDesignData({
                isDesignEditable: isSchematicEditable,
                change: (field, row, item) => {
                  this.onValueChange(field, row, 'otherDesignData', item);
                },
                edit: (row) => {
                  this.edit(row, 'otherDesignData');
                },
                delete: (row) => {
                  this.delete(row, 'otherDesignData');
                },
                undo: (row) => {
                  this.undo(row, 'otherDesignData');
                },
                dataUnits: dataUnits,
                name,
              })}
              data={this.getDataArray(
                designData.otherDesignData,
                'otherDesignData'
              )}
              editableRow={editRow.otherDesignData}
              showHeader
            />
          </div>
        )}
        {formEdit && isSchematicEditable ? (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}

DesignData.propTypes = {
  configData: PropTypes.object,
  designState: PropTypes.object,
  location: PropTypes.object,
  getDataVariables: PropTypes.func,
  clearDesignState: PropTypes.func,
  clearConfigState: PropTypes.func,
  isConfigData: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  designState: state.DesignReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getDataVariables: (payload) => dispatch(getDataVariables(payload)),
  clearDesignState: () => dispatch(clearDesignState()),
  addEditDesignData: (payload, id, type) =>
    dispatch(addEditDesign(payload, id, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesignData));
