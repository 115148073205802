import {
  FACILITY_FETCH_ERROR,
  FACILITY_FETCH_PROGRESS,
  FACILITY_FETCH_SUCCESS,
  FACILITY_CLEAR_STATE,
  ADD_FACILITY_FETCH_SUCCESS,
  ADD_FACILITY_FETCH_PROGRESS,
  ADD_FACILITY_FETCH_ERROR,
  EDIT_FACILITY_FETCH_ERROR,
  EDIT_FACILITY_FETCH_SUCCESS,
  EDIT_FACILITY_FETCH_PROGRESS,
  FETCH_ALL_FACILITY_PROGRESS,
  FETCH_ALL_FACILITY_SUCCESS,
  FETCH_ALL_FACILITY_ERROR,
} from "../Types/facility.types";

const INITIAL_API_STATE = {
  facilityData: null,
  isErrorFetchFacility: false,
  isErrorAddFacility: false,
  isErrorEditFacility: false,
  isSuccessFetchFacility: false,
  isSuccessAddFacility: false,
  isSuccessEditFacility: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function FacilityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FACILITY_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case FACILITY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorFetchFacility: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case FACILITY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          facilityData: action.payload,
          isSuccessFetchFacility: true,
        },
      };
    case FACILITY_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          facilityData: state.apiState.facilityData,
        },
      };
    case ADD_FACILITY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          facilityData: action.payload,
          isSuccessAddFacility: true,
        },
      };
    case ADD_FACILITY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorAddFacility: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case ADD_FACILITY_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_FACILITY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          facilityData: action.payload,
          isSuccessEditFacility: true,
        },
      };
    case EDIT_FACILITY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorEditFacility: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case EDIT_FACILITY_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_FACILITY_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_FACILITY_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorFetchFacility: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case FETCH_ALL_FACILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          facilityData: action.payload,
          isSuccessFetchFacility: true,
        },
      };
    default:
      return state;
  }
}
