import {
    EVENT_DETAILS_GET_REQUEST,
    EVENT_DETAILS_GET_ERROR,
    EVENT_DETAILS_GET_SUCCESS,
    EVENT_DETAILS_GET_CLEAR_STATE,
  } from "../Types/Alerts.types";
  
  const INITIAL_API_STATE = {
    isLoading: false,
    isSuccess: false,
    data: [],
    isError: false,
    errorCode: null,
    message: null,
  };
  
  export default function EventDetailsReducer(
    state = INITIAL_API_STATE,
    action
  ) {
    switch (action.type) {
      case EVENT_DETAILS_GET_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          data: [],
          isError: false,
          errorCode: null,
          message: null,
        };
  
      case EVENT_DETAILS_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
          isError: false,
          errorCode: null,
          message: null,
        };
  
      case EVENT_DETAILS_GET_ERROR:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          data: [],
          isError: true,
          errorCode: action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        };
  
      case EVENT_DETAILS_GET_CLEAR_STATE:
        return {
          ...INITIAL_API_STATE,
        };
  
      default:
        return state;
    }
  }
  