import React from "react";
import { Icon, Input } from "antd";
import _ from "lodash";
import { FluidCompositionVariables } from "../../../../../../constants/variables";
import { FluidCompositionMessages } from "../../../../../../constants/messages";
import { newValidateNumber } from "../../../../../../libs/validator";
import { validateNonNegativeFloat } from "../../../../../../common/methods";

export const formFields_air = (config) => [
  {
    label: FluidCompositionVariables.EQUATION_OF_STATE,
    key: "equationState_air",
    type: "dropdown",
    required: [true, FluidCompositionMessages.EQUATION_STATE_REQUIRED],
    data: equationOfState,
    valueKey: "_id",
    titleKey: "value",
    searchKey: "value",
    change: config.changeEquation,
    disabled: !config.isConfigEditable,
  },
  {
    label: FluidCompositionVariables.COMPOSITION_TYPE,
    key: "type_air",
    type: "dropdown",
    required: [true, FluidCompositionMessages.COMPONENT_TYPE_REQUIRED],
    data: compositionType,
    change: config.changeType,
    disabled: !config.isConfigEditable,
  },
];

export const formFields_fg = (config) => [
  {
    label: FluidCompositionVariables.EQUATION_OF_STATE,
    key: "equationState_fg",
    type: "dropdown",
    required: [true, FluidCompositionMessages.EQUATION_STATE_REQUIRED],
    data: equationOfState,
    valueKey: "_id",
    titleKey: "value",
    searchKey: "value",
    change: config.changeEquation,
    disabled: !config.isConfigEditable,
  },
  {
    label: FluidCompositionVariables.COMPOSITION_TYPE,
    key: "type_fg",
    type: "dropdown",
    required: [true, FluidCompositionMessages.COMPONENT_TYPE_REQUIRED],
    data: compositionType,
    change: config.changeType,
    disabled: !config.isConfigEditable,
  },
];

export const GasTurbineFormFields = (config) => [
  {
    label: FluidCompositionVariables.EQUATION_OF_STATE,
    key: "equationState",
    type: "dropdown",
    required: [true, FluidCompositionMessages.EQUATION_STATE_REQUIRED],
    data: equationOfState,
    valueKey: "_id",
    titleKey: "value",
    searchKey: "value",
    change: config.changeEquation,
    disabled: !config.isConfigEditable,
  },
  {
    label: FluidCompositionVariables.COMPOSITION_TYPE,
    key: "type",
    type: "dropdown",
    required: [true, FluidCompositionMessages.COMPONENT_TYPE_REQUIRED],
    data: compositionType,
    change: config.changeType,
    disabled: !config.isConfigEditable,
  },
];

export const searchTable_air = () => [
  {
    title: FluidCompositionVariables.SEARCH_NAME,
    dataIndex: "shortName",
  },
];
export const searchTable_fg = () => [
  {
    title: FluidCompositionVariables.SEARCH_NAME,
    dataIndex: "shortName",
  },
];

export const fluidTableColumns_air = (config) => [
  {
    title: FluidCompositionVariables.FLUID_NAME,
    dataIndex: "name",
  },
  {
    title: FluidCompositionVariables.COMPOSITION,
    dataIndex: "value",
    render: (value, row, col, disabled) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="value"
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            config.change(e.target, row);
          }}
          disabled={disabled}
          value={value}
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          className={validateNonNegativeFloat(value) ? "not-error" : "error"}
        />
      );
    },
  },
  {
    title: FluidCompositionVariables.ACTIONS,
    render: (value, row, col, disabled) =>
      config.isConfigEditable && (
        <div className="more-items">
          {disabled ? (
            <button
              onClick={(e) => {
                config.edit(row);
              }}
            >
              <Icon type="edit" />
            </button>
          ) : null}
          {!disabled ? (
            <button
              onClick={(e) => {
                config.undo(row);
              }}
            >
              <Icon type="undo" />
            </button>
          ) : null}
          {
            <button
              onClick={(e) => {
                config.delete(row);
              }}
            >
              <Icon type="delete" />
            </button>
          }
        </div>
      ),
  },
];
export const fluidTableColumns_fg = (config) => [
  {
    title: FluidCompositionVariables.FLUID_NAME,
    dataIndex: "name",
  },
  {
    title: FluidCompositionVariables.COMPOSITION,
    dataIndex: "value",
    render: (value, row, col, disabled) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="value"
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            config.change(e.target, row);
          }}
          disabled={disabled}
          value={value}
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          className={validateNonNegativeFloat(value) ? "not-error" : "error"}
        />
      );
    },
  },
  {
    title: FluidCompositionVariables.ACTIONS,
    render: (value, row, col, disabled) =>
      config.isConfigEditable && (
        <div className="more-items">
          {disabled ? (
            <button
              onClick={(e) => {
                config.edit(row);
              }}
            >
              <Icon type="edit" />
            </button>
          ) : null}
          {!disabled ? (
            <button
              onClick={(e) => {
                config.undo(row);
              }}
            >
              <Icon type="undo" />
            </button>
          ) : null}
          {
            <button
              onClick={(e) => {
                config.delete(row);
              }}
            >
              <Icon type="delete" />
            </button>
          }
        </div>
      ),
  },
];

export const headerColumns_air = (config) => [
  {
    value: "Mole Fraction",
    render: (value, row) => (
      <div>{FluidCompositionVariables.MOLE_FRACTION}</div>
    ),
  },
  {
    value: null,
  },
  {
    value: config.ratioValue,
    render: (value, row) => (
      <div className="custom-table-select">
        <select
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.ratioChanged(e.target.value);
          }}
          disabled={!config.isConfigEditable}
        >
          <option value="100">{FluidCompositionVariables.PERCENT}</option>
          <option value="1">{FluidCompositionVariables.PERCENT_BY_100}</option>
        </select>
      </div>
    ),
  },
];
export const headerColumns_fg = (config) => [
  {
    value: "Mole Fraction",
    render: (value, row) => (
      <div>{FluidCompositionVariables.MOLE_FRACTION}</div>
    ),
  },
  {
    value: null,
  },
  {
    value: config.ratioValue,
    render: (value, row) => (
      <div className="custom-table-select">
        <select
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.ratioChanged(e.target.value);
          }}
          disabled={!config.isConfigEditable}
        >
          <option value="100">{FluidCompositionVariables.PERCENT}</option>
          <option value="1">{FluidCompositionVariables.PERCENT_BY_100}</option>
        </select>
      </div>
    ),
  },
];

export const footerColumns_air = (config) => [
  {
    value: "Total",
    render: (value, row) => <div>{FluidCompositionVariables.TOTAL}</div>,
  },
  {
    value: config.totalSum,
    render: (value, row) => {
      value = _.isNil(value) || _.isNaN(value) ? "" : value.toString();
      return <div>{value}</div>;
    },
  },
  {
    value: "Normalize",
    render: (value, row) => (
      <button
        className="btn-default btn-white"
        onClick={() => config.normalize()}
        disabled={!config.isConfigEditable}
      >
        {FluidCompositionVariables.NORMALIZE}
      </button>
    ),
  },
];
export const footerColumns_fg = (config) => [
  {
    value: "Total",
    render: (value, row) => <div>{FluidCompositionVariables.TOTAL}</div>,
  },
  {
    value: config.totalSum,
    render: (value, row) => {
      value = _.isNil(value) || _.isNaN(value) ? "" : value.toString();
      return <div>{value}</div>;
    },
  },
  {
    value: "Normalize",
    render: (value, row) => (
      <button
        className="btn-default btn-white"
        onClick={() => config.normalize()}
        disabled={!config.isConfigEditable}
      >
        {FluidCompositionVariables.NORMALIZE}
      </button>
    ),
  },
];

export const rowSelection = (config, selectedRowKeys) => ({
  selectedRowKeys,
  onChange: (selectedRowKeys, selectedRows) => {
    config.change(selectedRowKeys, selectedRows);
  },
  getCheckboxProps: (record) => {},
});

export const equationOfState = [
  { _id: "HEOS", value: "Helmholtz" },
  { _id: "REFPROP", value: "Refprop" },
  { _id: "PR", value: "Peng-Robinson" },
  { _id: "SRK", value: "Soave-Redlich-Kwong" },
];

export const compositionType = ["Fixed", "Variable"];

export const initialData = {
  air: {
    eos_air: "",
    composition_air: [],
    type: "",
    moleFraction: "1",
  },
  fg: {
    eos_fg: "",
    composition_fg: [],
    type: "",
    moleFraction: "1",
  },
};
