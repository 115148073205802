import { connect } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import {
  initialData,
  stDesignDataVariables,
  turbineCycleDataShow,
  reqCalcDataShow,
  filterTurbineCycleData,
  filterReqCalcData,
  designSteamCondData,
  designSteamCondShow,
  inductionExtractionSteamData,
  inductionExtractionDataShow,
  mechanicalDesignData,
  mechanicalDesignDataShow,
  KeyChange,
} from './designData.constant';
import _ from 'lodash';
import {
  addEditDesign,
  clearDesignState,
} from '../../../../../../redux/actions/design.action';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  MotorDesignDataMessages,
} from '../../../../../../constants/messages';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Loader from '../../../../../../common/Loader/Loader';

const SteamTurbineDesign = (props) => {
  const {
    dataUnitState,
    designDataObject,
    isSchematicEditable,
    isDesignAddSuccess,
    designData,
    isDesignAddError,
    designErrorMessage,
    location,
    network,
    addEditDesign,
    clearDesignState,
    dataUnitFetchSuccess,
    inUnitData,
  } = props;

  const [displayData, setDisplayData] = useState(initialData);
  const [copyDisplayData, setCopyDisplayData] = useState(initialData);
  const [unitData, setUnitData] = useState([]);
  const [saveListener, setSaveListener] = useState(false);
  const [editTableRows, setEditableRows] = useState({
    turbineCycleData: [],
    reqCalculationData: [],
    designSteamCond: [],
    indExtractionSteamData: [],
    mechanicalDesignData: [],
  });
  const [formEdit, setFormEdit] = useState(false);
  const [visible, setVisible] = useState(false);

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  const setData = useCallback(() => {
    const tempDisplayData = [
      {
        name: stDesignDataVariables.equipmentPressureType,
        design: designDataObject
          ? designDataObject.equipmentPressureType
            ? designDataObject.equipmentPressureType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.steamTurbineService,
        design: designDataObject
          ? designDataObject.steamTurbineService
            ? designDataObject.steamTurbineService.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.cycleType,
        design: designDataObject
          ? designDataObject.cycleType
            ? designDataObject.cycleType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.steamInductionType,
        design: designDataObject
          ? designDataObject.steamInductionType
            ? designDataObject.steamInductionType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.steamExtractionType,
        design: designDataObject
          ? designDataObject.steamExtractionType
            ? designDataObject.steamExtractionType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.drivenEquipmentDescription,
        design: designDataObject
          ? designDataObject.drivenEquipmentDescription
            ? designDataObject.drivenEquipmentDescription.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.service,
        design: designDataObject
          ? designDataObject.service
            ? designDataObject.service.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.flowEnthalpyDropMethod,
        design: designDataObject
          ? designDataObject.flowEnthalpyDropMethod
            ? designDataObject.flowEnthalpyDropMethod.design
            : false
          : false,
      },
      {
        name: stDesignDataVariables.configSpeed,
        unit: designDataObject
          ? designDataObject.configSpeed
            ? designDataObject.configSpeed.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.configSpeed
            ? designDataObject.configSpeed.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.stStartupSpeedThreshold,
        unit: designDataObject
          ? designDataObject.stStartupSpeedThreshold
            ? designDataObject.stStartupSpeedThreshold.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.stStartupSpeedThreshold
            ? designDataObject.stStartupSpeedThreshold.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.stStartupPowerThresold,
        unit: designDataObject
          ? designDataObject.stStartupPowerThresold
            ? designDataObject.stStartupPowerThresold.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.stStartupPowerThresold
            ? designDataObject.stStartupPowerThresold.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.condensingService,
        design: designDataObject
          ? designDataObject.condensingService
            ? designDataObject.condensingService.design
            : false
          : false,
      },
      {
        name: stDesignDataVariables.glandSteamLeakage,
        unit: designDataObject
          ? designDataObject.glandSteamLeakage
            ? designDataObject.glandSteamLeakage.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.glandSteamLeakage
            ? designDataObject.glandSteamLeakage.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.totalSteamLeakage,
        unit: designDataObject
          ? designDataObject.totalSteamLeakage
            ? designDataObject.totalSteamLeakage.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.totalSteamLeakage
            ? designDataObject.totalSteamLeakage.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamPressureRated,
        unit: designDataObject
          ? designDataObject.inletSteamPressureRated
            ? designDataObject.inletSteamPressureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamPressureRated
            ? designDataObject.inletSteamPressureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamPressureMax,
        unit: designDataObject
          ? designDataObject.inletSteamPressureMax
            ? designDataObject.inletSteamPressureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamPressureMax
            ? designDataObject.inletSteamPressureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamPressureMin,
        unit: designDataObject
          ? designDataObject.inletSteamPressureMin
            ? designDataObject.inletSteamPressureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamPressureMin
            ? designDataObject.inletSteamPressureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamTemperatureRated,
        unit: designDataObject
          ? designDataObject.inletSteamTemperatureRated
            ? designDataObject.inletSteamTemperatureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamTemperatureRated
            ? designDataObject.inletSteamTemperatureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamTemperatureMax,
        unit: designDataObject
          ? designDataObject.inletSteamTemperatureMax
            ? designDataObject.inletSteamTemperatureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamTemperatureMax
            ? designDataObject.inletSteamTemperatureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inletSteamTemperatureMin,
        unit: designDataObject
          ? designDataObject.inletSteamTemperatureMin
            ? designDataObject.inletSteamTemperatureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inletSteamTemperatureMin
            ? designDataObject.inletSteamTemperatureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.rotationalSpeed,
        unit: designDataObject
          ? designDataObject.rotationalSpeed
            ? designDataObject.rotationalSpeed.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.rotationalSpeed
            ? designDataObject.rotationalSpeed.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.outputSpeedRangeMin,
        unit: designDataObject
          ? designDataObject.outputSpeedRangeMin
            ? designDataObject.outputSpeedRangeMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.outputSpeedRangeMin
            ? designDataObject.outputSpeedRangeMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.outputSpeedRangeMax,
        unit: designDataObject
          ? designDataObject.outputSpeedRangeMax
            ? designDataObject.outputSpeedRangeMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.outputSpeedRangeMax
            ? designDataObject.outputSpeedRangeMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamPressureRated,
        unit: designDataObject
          ? designDataObject.exhaustSteamPressureRated
            ? designDataObject.exhaustSteamPressureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamPressureRated
            ? designDataObject.exhaustSteamPressureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamPressureMax,
        unit: designDataObject
          ? designDataObject.exhaustSteamPressureMax
            ? designDataObject.exhaustSteamPressureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamPressureMax
            ? designDataObject.exhaustSteamPressureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamPressureMin,
        unit: designDataObject
          ? designDataObject.exhaustSteamPressureMin
            ? designDataObject.exhaustSteamPressureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamPressureMin
            ? designDataObject.exhaustSteamPressureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamTemperatureRated,
        unit: designDataObject
          ? designDataObject.exhaustSteamTemperatureRated
            ? designDataObject.exhaustSteamTemperatureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamTemperatureRated
            ? designDataObject.exhaustSteamTemperatureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamTemperatureMax,
        unit: designDataObject
          ? designDataObject.exhaustSteamTemperatureMax
            ? designDataObject.exhaustSteamTemperatureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamTemperatureMax
            ? designDataObject.exhaustSteamTemperatureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamTemperatureMin,
        unit: designDataObject
          ? designDataObject.exhaustSteamTemperatureMin
            ? designDataObject.exhaustSteamTemperatureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamTemperatureMin
            ? designDataObject.exhaustSteamTemperatureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.throttleSteamFlowRated,
        unit: designDataObject
          ? designDataObject.throttleSteamFlowRated
            ? designDataObject.throttleSteamFlowRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.throttleSteamFlowRated
            ? designDataObject.throttleSteamFlowRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.throttleSteamFlowMax,
        unit: designDataObject
          ? designDataObject.throttleSteamFlowMax
            ? designDataObject.throttleSteamFlowMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.throttleSteamFlowMax
            ? designDataObject.throttleSteamFlowMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.throttleSteamFlowMin,
        unit: designDataObject
          ? designDataObject.throttleSteamFlowMin
            ? designDataObject.throttleSteamFlowMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.throttleSteamFlowMin
            ? designDataObject.throttleSteamFlowMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamFlowRated,
        unit: designDataObject
          ? designDataObject.exhaustSteamFlowRated
            ? designDataObject.exhaustSteamFlowRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamFlowRated
            ? designDataObject.exhaustSteamFlowRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamFlowMax,
        unit: designDataObject
          ? designDataObject.exhaustSteamFlowMax
            ? designDataObject.exhaustSteamFlowMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamFlowMax
            ? designDataObject.exhaustSteamFlowMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.exhaustSteamFlowMin,
        unit: designDataObject
          ? designDataObject.exhaustSteamFlowMin
            ? designDataObject.exhaustSteamFlowMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.exhaustSteamFlowMin
            ? designDataObject.exhaustSteamFlowMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamPressureRated,
        unit: designDataObject
          ? designDataObject.inductionSteamPressureRated
            ? designDataObject.inductionSteamPressureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamPressureRated
            ? designDataObject.inductionSteamPressureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamPressureMax,
        unit: designDataObject
          ? designDataObject.inductionSteamPressureMax
            ? designDataObject.inductionSteamPressureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamPressureMax
            ? designDataObject.inductionSteamPressureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamPressureMin,
        unit: designDataObject
          ? designDataObject.inductionSteamPressureMin
            ? designDataObject.inductionSteamPressureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamPressureMin
            ? designDataObject.inductionSteamPressureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamTemperatureRated,
        unit: designDataObject
          ? designDataObject.inductionSteamTemperatureRated
            ? designDataObject.inductionSteamTemperatureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamTemperatureRated
            ? designDataObject.inductionSteamTemperatureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamTemperatureMax,
        unit: designDataObject
          ? designDataObject.inductionSteamTemperatureMax
            ? designDataObject.inductionSteamTemperatureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamTemperatureMax
            ? designDataObject.inductionSteamTemperatureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamTemperatureMin,
        unit: designDataObject
          ? designDataObject.inductionSteamTemperatureMin
            ? designDataObject.inductionSteamTemperatureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamTemperatureMin
            ? designDataObject.inductionSteamTemperatureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamFlowRated,
        unit: designDataObject
          ? designDataObject.inductionSteamFlowRated
            ? designDataObject.inductionSteamFlowRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamFlowRated
            ? designDataObject.inductionSteamFlowRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamFlowMax,
        unit: designDataObject
          ? designDataObject.inductionSteamFlowMax
            ? designDataObject.inductionSteamFlowMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamFlowMax
            ? designDataObject.inductionSteamFlowMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.inductionSteamFlowMin,
        unit: designDataObject
          ? designDataObject.inductionSteamFlowMin
            ? designDataObject.inductionSteamFlowMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.inductionSteamFlowMin
            ? designDataObject.inductionSteamFlowMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamPressureRated,
        unit: designDataObject
          ? designDataObject.extractionSteamPressureRated
            ? designDataObject.extractionSteamPressureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamPressureRated
            ? designDataObject.extractionSteamPressureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamPressureMax,
        unit: designDataObject
          ? designDataObject.extractionSteamPressureMax
            ? designDataObject.extractionSteamPressureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamPressureMax
            ? designDataObject.extractionSteamPressureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamPressureMin,
        unit: designDataObject
          ? designDataObject.extractionSteamPressureMin
            ? designDataObject.extractionSteamPressureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamPressureMin
            ? designDataObject.extractionSteamPressureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamTemperatureRated,
        unit: designDataObject
          ? designDataObject.extractionSteamTemperatureRated
            ? designDataObject.extractionSteamTemperatureRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamTemperatureRated
            ? designDataObject.extractionSteamTemperatureRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamTemperatureMax,
        unit: designDataObject
          ? designDataObject.extractionSteamTemperatureMax
            ? designDataObject.extractionSteamTemperatureMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamTemperatureMax
            ? designDataObject.extractionSteamTemperatureMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamTemperatureMin,
        unit: designDataObject
          ? designDataObject.extractionSteamTemperatureMin
            ? designDataObject.extractionSteamTemperatureMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamTemperatureMin
            ? designDataObject.extractionSteamTemperatureMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamFlowRated,
        unit: designDataObject
          ? designDataObject.extractionSteamFlowRated
            ? designDataObject.extractionSteamFlowRated.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamFlowRated
            ? designDataObject.extractionSteamFlowRated.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamFlowMax,
        unit: designDataObject
          ? designDataObject.extractionSteamFlowMax
            ? designDataObject.extractionSteamFlowMax.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamFlowMax
            ? designDataObject.extractionSteamFlowMax.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.extractionSteamFlowMin,
        unit: designDataObject
          ? designDataObject.extractionSteamFlowMin
            ? designDataObject.extractionSteamFlowMin.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.extractionSteamFlowMin
            ? designDataObject.extractionSteamFlowMin.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.stShaftType,
        design: designDataObject
          ? designDataObject.stShaftType
            ? designDataObject.stShaftType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.numberOfStages,
        design: designDataObject
          ? designDataObject.numberOfStages
            ? designDataObject.numberOfStages.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.maxTipSpeed,
        unit: designDataObject
          ? designDataObject.maxTipSpeed
            ? designDataObject.maxTipSpeed.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.maxTipSpeed
            ? designDataObject.maxTipSpeed.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.finalStageRatedBladeLength,
        unit: designDataObject
          ? designDataObject.finalStageRatedBladeLength
            ? designDataObject.finalStageRatedBladeLength.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.finalStageRatedBladeLength
            ? designDataObject.finalStageRatedBladeLength.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.finalStageMaxBladeLength,
        unit: designDataObject
          ? designDataObject.finalStageMaxBladeLength
            ? designDataObject.finalStageMaxBladeLength.unit
            : ''
          : '',
        design: designDataObject
          ? designDataObject.finalStageMaxBladeLength
            ? designDataObject.finalStageMaxBladeLength.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.radialBearingType,
        design: designDataObject
          ? designDataObject.radialBearingType
            ? designDataObject.radialBearingType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.radialBearingManufacturer,
        design: designDataObject
          ? designDataObject.radialBearingManufacturer
            ? designDataObject.radialBearingManufacturer.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.thrustBearingType,
        design: designDataObject
          ? designDataObject.thrustBearingType
            ? designDataObject.thrustBearingType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.thrustBearingManufacturer,
        design: designDataObject
          ? designDataObject.thrustBearingManufacturer
            ? designDataObject.thrustBearingManufacturer.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.gearBoxType,
        design: designDataObject
          ? designDataObject.gearBoxType
            ? designDataObject.gearBoxType.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.gearBoxManufacturer,
        design: designDataObject
          ? designDataObject.gearBoxManufacturer
            ? designDataObject.gearBoxManufacturer.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.gearBoxSpeedRatio,
        design: designDataObject
          ? designDataObject.gearBoxSpeedRatio
            ? designDataObject.gearBoxSpeedRatio.design
            : ''
          : '',
      },
      {
        name: stDesignDataVariables.lubricatingOilGrade,
        design: designDataObject
          ? designDataObject.lubricatingOilGrade
            ? designDataObject.lubricatingOilGrade.design
            : ''
          : '',
      },
    ];
    setDisplayData(tempDisplayData);
    setCopyDisplayData(tempDisplayData);
  }, [designDataObject]);

  // component did Mount
  useEffect(() => {
    // setUnits();
    setData();
    window.addEventListener('form-edit', () => {
      setFormEdit(true);
    });
    window.addEventListener('form-edit-false', (e) => {
      setFormEdit(false);
      setSaveListener(false);
      setEditableRows({
        turbineCycleData: [],
        reqCalculationData: [],
        designSteamCond: [],
        indExtractionSteamData: [],
        mechanicalDesignData: [],
      });
    });
  }, [setData]);

  useEffect(() => {
    if (dataUnitFetchSuccess) {
      const tempUnitData =
      inUnitData && inUnitData.items ? _.clone(inUnitData.items) : [];
      setUnitData(tempUnitData);
    }
  }, [dataUnitFetchSuccess, inUnitData]);

  // upon successfully saving design data
  useEffect(() => {
    if (isDesignAddSuccess && designData) {
      clearDesignState();
      disableEditForm();
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
  }, [isDesignAddSuccess, designData, clearDesignState]);

  // error while saving design data
  useEffect(() => {
    if (isDesignAddError) {
      clearDesignState();
      Notification.show(Types.Error, designErrorMessage);
    }
  }, [isDesignAddError, clearDesignState, designErrorMessage]);

  const handleChange = (val, variableName, type) => {
    enableEditForm();
    const tempDisplayData = displayData.map((item) => {
      if (item.name === variableName && type === 'number') {
        return { name: variableName, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === variableName && type === 'select') {
        return { name: variableName, unit: item.unit, design: val };
      } else if (item.name === variableName && type === 'unit') {
        return { name: variableName, unit: val, design: item.design };
      } else if (item.name === variableName && type === 'text') {
        return { name: variableName, design: val };
      }
      return item;
    });
    setDisplayData(_.cloneDeep(tempDisplayData));
    enableEditForm();
  };

  const handleOk = () => {
    setDisplayData(copyDisplayData);
    setVisible(false);
    disableEditForm();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  };

  const onEdit = (row, tableName) => {
    let editTable = editTableRows;
    editTable[tableName].push(row);
    editTable[tableName].sort();
    setEditableRows(editTable);
    enableEditForm();
  };

  const onUndo = (row, tableName) => {
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);
    setEditableRows(editTable);
    setDisplayData(tempDisplayData);
    enableEditForm();
  };

  const onReset = () => {
    setVisible(true);
  };

  const handleNetwork = () => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  };

  const validateData = (displayData) =>  {
    let mandatoryCheckError = false;
    let unitCheckError = false;
    let dirtyRows = [];
    let unitdDirtyRows = [];
    // mandatory field check
    displayData.forEach((item)=>{
      if(
        item.name === stDesignDataVariables.steamTurbineService ||
        item.name === stDesignDataVariables.cycleType ||
        item.name === stDesignDataVariables.configSpeed ||
        item.name === stDesignDataVariables.stStartupSpeedThreshold ||
        item.name === stDesignDataVariables.stStartupPowerThresold ||
        item.name === stDesignDataVariables.inletSteamPressureRated ||
        item.name === stDesignDataVariables.inletSteamTemperatureRated
      ) {
        if(!(item.design))  {
          dirtyRows.push(item);
          mandatoryCheckError = true;
        }
      }
      if(
        item.name !== stDesignDataVariables.equipmentPressureType &&
        item.name !== stDesignDataVariables.steamTurbineService &&
        item.name !== stDesignDataVariables.cycleType &&
        item.name !== stDesignDataVariables.steamInductionType &&
        item.name !== stDesignDataVariables.steamExtractionType &&
        item.name !== stDesignDataVariables.drivenEquipmentDescription &&
        item.name !== stDesignDataVariables.service &&
        item.name !== stDesignDataVariables.flowEnthalpyDropMethod &&
        item.name !== stDesignDataVariables.condensingService &&
        item.name !== stDesignDataVariables.stShaftType &&
        item.name !== stDesignDataVariables.numberOfStages &&
        item.name !== stDesignDataVariables.radialBearingType &&
        item.name !== stDesignDataVariables.radialBearingManufacturer &&
        item.name !== stDesignDataVariables.thrustBearingType &&
        item.name !== stDesignDataVariables.thrustBearingManufacturer &&
        item.name !== stDesignDataVariables.gearBoxType &&
        item.name !== stDesignDataVariables.gearBoxManufacturer &&
        item.name !== stDesignDataVariables.gearBoxSpeedRatio &&
        item.name !== stDesignDataVariables.lubricatingOilGrade
      ) {
        if(item.design && !item.unit)  {
          unitdDirtyRows.push(item);
          unitCheckError = true;
        }
      }
    });
    return mandatoryCheckError || unitCheckError;
  }

  const onSave = () => {
    setSaveListener(true);
    const data = KeyChange;
    const payload = {};
    const isValid = validateData(displayData);
    if(!isValid) {
      displayData.forEach((item) => {
        if (
          item.name !== stDesignDataVariables.equipmentPressureType &&
          item.name !== stDesignDataVariables.steamTurbineService &&
          item.name !== stDesignDataVariables.cycleType &&
          item.name !== stDesignDataVariables.steamInductionType &&
          item.name !== stDesignDataVariables.steamExtractionType &&
          item.name !== stDesignDataVariables.drivenEquipmentDescription &&
          item.name !== stDesignDataVariables.flowEnthalpyDropMethod &&
          item.name !== stDesignDataVariables.stShaftType &&
          item.name !== stDesignDataVariables.numberOfStages &&
          item.name !== stDesignDataVariables.radialBearingType &&
          item.name !== stDesignDataVariables.radialBearingManufacturer &&
          item.name !== stDesignDataVariables.thrustBearingType &&
          item.name !== stDesignDataVariables.thrustBearingManufacturer &&
          item.name !== stDesignDataVariables.gearBoxType &&
          item.name !== stDesignDataVariables.gearBoxManufacturer &&
          item.name !== stDesignDataVariables.gearBoxSpeedRatio &&
          item.name !== stDesignDataVariables.lubricatingOilGrade &&
          item.name !== stDesignDataVariables.condensingService &&
          item.name !== stDesignDataVariables.service
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: item.design,
          };
        } else {
          if(item.name === stDesignDataVariables.flowEnthalpyDropMethod)  {
          }
          payload[data[item.name]] = {
            design: item.design,
          };
        }
      });
      const { state } = location;
      if ((handleNetwork() && state && state.componentData.info._id && payload)) {
        addEditDesign(payload, state.componentData.info._id, 'Steam Turbine');
      }
    }
    else  {
      Notification.show(Types.Error, MotorDesignDataMessages.DIRTY_DATA);
    }
  };

  return (
    <div className="DesignDataMotor">
      {dataUnitState.loading && <Loader />}
      <CustomTable
        showHeader={true}
        columns={turbineCycleDataShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
        })}
        data={filterTurbineCycleData(displayData)}
        editableRow={editTableRows.turbineCycleData}
      />
      <CustomTable
        showHeader={true}
        columns={reqCalcDataShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
          unitData,
        })}
        data={filterReqCalcData(displayData)}
        editableRow={editTableRows.reqCalculationData}
      />
      <CustomTable
        showHeader={true}
        columns={designSteamCondShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
          unitData,
        })}
        data={designSteamCondData(displayData)}
        editableRow={editTableRows.designSteamCond}
      />
      <CustomTable
        showHeader={true}
        columns={inductionExtractionDataShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
          unitData,
        })}
        data={inductionExtractionSteamData(displayData)}
        editableRow={editTableRows.indExtractionSteamData}
      />
      <CustomTable
        showHeader={true}
        columns={mechanicalDesignDataShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
          unitData,
        })}
        data={mechanicalDesignData(displayData)}
        editableRow={editTableRows.mechanicalDesignData}
      />
      {formEdit && (
        <div className="config-bottom-buttons">
          <button className="btn-default btn-white" onClick={onReset}>
            {'RESET'}
          </button>
          <button className="btn-default" onClick={onSave}>
            {'SAVE'}
          </button>
        </div>
      )}
      {renderModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataUnitState: state.DataUnitReducer,
    network: state.NetworkReducer.networkState,
    designData: state.DesignReducer.apiState.designData,
    isDesignAddSuccess: state.DesignReducer.apiState.isDesignAddSuccess,
    isDesignAddError: state.DesignReducer.apiState.isDesignError,
    designErrorMessage: state.DesignReducer.apiState.message,
    dataUnitFetchSuccess: state.DataUnitReducer.apiState.isSuccessDataUnitFetch,
    inUnitData: state.DataUnitReducer.apiState.unitData,
  };
};

const mapDispatchToProps = {
  addEditDesign,
  clearDesignState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SteamTurbineDesign);
