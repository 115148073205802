export const TRENDS_BY_LEVEL_FETCH_PROGRESS = "TRENDS_BY_LEVEL_FETCH_PROGRESS";
export const TRENDS_BY_LEVEL_FETCH_SUCCESS = "TRENDS_BY_LEVEL_FETCH_SUCCESS";
export const TRENDS_BY_LEVEL_FETCH_ERROR = "TRENDS_BY_LEVEL_FETCH_ERROR";
export const TRENDS_BY_LEVEL_CLEAR_STATE = "TRENDS_BY_LEVEL_CLEAR_STATE";

export const TRENDS_FETCH_PROGRESS = "TRENDS_FETCH_PROGRESS";
export const TRENDS_FETCH_SUCCESS = "TRENDS_FETCH_SUCCESS";
export const TRENDS_FETCH_ERROR = "TRENDS_FETCH_ERROR";
export const TRENDS_CLEAR_STATE = "TRENDS_CLEAR_STATE";

export const ADD_NEW_TREND_CARD_PROGRESS = "ADD_NEW_TREND_CARD_PROGRESS";
export const ADD_NEW_TREND_CARD_ERROR = "ADD_NEW_TREND_CARD_ERROR";
export const ADD_NEW_TREND_CARD_SUCCESS = "ADD_NEW_TREND_CARD_SUCCESS";
export const ADD_NEW_TREND_CARD_CLEAR_STATE = "ADD_NEW_TREND_CARD_CLEAR_STATE";

export const TREND_PARTICULAR_FETCH_SUCCESS = "TREND_PARTICULAR_FETCH_SUCCESS";

export const INSPECTION_MODE_ON = "INSPECTION_MODE_ON";
export const INSPECTION_MODE_OFF = "INSPECTION_MODE_OFF";

export const TRENDS_BY_ID_FETCH_PROGRESS = "TRENDS_BY_ID_FETCH_PROGRESS";
export const TRENDS_BY_ID_FETCH_SUCCESS = "TRENDS_BY_ID_FETCH_SUCCESS";
export const TRENDS_BY_ID_FETCH_ERROR = "TRENDS_BY_ID_FETCH_ERROR";

export const SEARCH_KPI_PROGRESS = 'SEARCH_KPI_PROGRESS';
export const SEARCH_KPI_SUCCESS = 'SEARCH_KPI_SUCCESS';
export const SEARCH_KPI_ERROR = 'SEARCH_KPI_ERROR';
