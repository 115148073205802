export const USER = 'USER';
export const MIXER = 'Mixer';
export const BASE_API_PATH = process.env.REACT_APP_DEV_API;
export const oneMB = 1024;
export const NO_COUNTRY_DATA = 'Please select country';
export const INTERNAL_ERROR = 'Unable to load data (404)';
export const FLOW_METER = 'Flow meter';

export const ButtonVariables = {
  OK: 'OK',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  UPDATE: 'Update',
  SAVE_MORE: 'Save & Add More',
  CREATE_COMPONENT_SCHEMATIC: 'Create Component Schematic',
  RESET: 'Reset',
  ADD: 'Add',
  DELETE: 'Delete',
  UNDO: 'Undo',
  VIEW_COMPONENT_SCHEMATIC: 'View Component Schematic',
  SUBMIT: 'SUBMIT',
  SEND: 'Send',
  MANGE_TAGS: 'Manage Tags',
  UPLOAD: 'Upload',
  UPLOAD_PI_TAG: 'Upload Tag List',
  DOWNLOAD_ERROR_PI_TAG: 'Download Error',
  CREATE_SYSTEM_SCHEMATIC: 'Create System Schematic',
  VIEW_SYSTEM_SCHEMATIC: 'View System Schematic',
  APPLY: 'Apply',
  FILTER: 'Filter',
  UN_DEPLOY: 'Undeploy',
  CREATE_MODEL: 'Create Model',
};

export const HeaderVariables = {
  EDIT_PROFILE: 'Edit Profile',
  LOGOUT: 'Logout',
  LOGO: 'Mechademy',
  NAME: 'Mechademy User',
  LOGOUT_MESSAGE: 'Are you sure you want to logout?',
  LOGOUT_HEADER: 'Log Out',
};

export const SideMenuVariables = {
  DASHBOARD: 'Dashboard',
  CLIENTS: 'Clients',
  USER_MANGEMENT: 'User Management',
  ASSET_LIBRARY: 'Asset Library',
  UNIT_PROPERTIES: 'Units & Properties',
  ML_ALGORITHM: 'ML Algorithm',
  REPORTING: 'Reporting',
  SETTING: 'Settings',
  SYSTEMSCHEMATIC: 'System Schematic',
  MODAL_MESSAGE1:
    ' Unsaved changes will be lost if you navigate away from this page. Are you sure you want to leave this page?',
  MODAL_MESSAGE2: '',
  MODAL_TITLE: 'Confirm Navigation',
  MODAL_REASON: 'Reason',
  CANCEL_TITLE: 'Discard Changes',
};

export const LoginVariables = {
  WELCOME_TEXT: 'Welcome to ',
  MECHADEMY: 'MECHADEMY',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN: 'Log in',
};

export const ClientListingVariables = {
  TITLE: 'Clients',
  ADD_CLIENT: 'Add Client',
  SEARCH_HERE: 'Search here',
};

export const ClientDetailsVariable = {
  ADDRESS_DETAILS: 'Address Details',
  ADD_CLIENT: 'Add Client',
  ADD_FACILITY: 'Add Facility',
  CLIENTS: 'Clients',
  CLIENT_NAME: 'Client Name',
  CLIENT_CONTARACT_NUMBER: 'Client Contract Number',
  CLIENT_CODE: 'Client Code',
  MECHADEMY_ID: 'Mechademy Code',
  ADDRESS_ONE: 'Address line 1',
  ADDRESS_TWO: 'Address line 2',
  ZIP_CODE: 'Zip code',
  COUNTRY: 'Country',
  CITY: 'City',
  STATE: 'State',
};

export const systemVariables = {
  ADD_EQUIPMENT: 'Add Equipment',
  ADD_SYSTEM: 'Add System',
  CREATE_SYSTEM_SCHEMATIC: 'Create System Schematic',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  SAVE: 'Save',
  EDIT: 'Edit',
  LENGTH: 'Length Exceeded',
};

export const systemLableVariables = {
  SYSTEM_NAME: 'System Name',
  CLIENT_SYSTEM_CODE: 'Client System Code',
  MECHADEMY_SYSTEM_CODE: 'Mechademy System Code',
  SYSTEM_DESCRIPTION: 'System Description',
};

export const fileUpload = {
  text: 'Upload Client Logo',
  hint: '(Format: JPG, JPEG or PNG, Max-Size: 500 KB)',
};

export const imageUpload = {
  text: 'Upload Equipment Image',
  hint: '(Format: JPG, JPEG, SVG or PNG, Max-Size: 1 MB)',
};

export const clientVariables = {
  madatoryMessage: 'This field is mandatory',
  logo: 'Logo required!',
  clientName: 'Please input your ClientName!',
  contactNumber: 'Please input your client contract number!',
  clientCode: 'Please input your client Code!',
  mechademyId: 'Please input MechademyId',
  addressLine1: 'Please input primaryAddress',
  addressLine2: 'Please input secondaryAddress',
  zipCode: 'Please input zipCode',
  state: 'Please input state',
  city: 'Please input city',
  country: 'Please input country',
};

export const ClientFacilityVariables = {
  FACILITY_NAME: 'Facility Name',
  COMPANY_NAME: 'Company Name',
  FACILITY_CODE: 'Client Facility Code',
  MECHADEMY_FACILITY_CODE: 'Mechademy Facility Code',
  FACILITY_PO: 'Facility Purchase Order',
  ADDRESS_DETAILS: 'Address Details',
  ADDRESS_ONE: 'Address Line 1',
  ADDRESS_TWO: 'Address Line 2',
  ZIP_CODE: 'Zip code',
  COUNTRY: 'Country',
  CITY: 'City',
  STATE: 'State',
  ADD_FACILITY: 'Add Facility',
  ADD_SYSTEM: 'Add System',
  CLIENTS: 'Clients',
};

export const ClientEquipmentVariables = {
  EQUIPMENT_NAME: 'Equipment Name',
  MECHADEMY_EQUIPMENT_CODE: 'Mechademy Equipment Code',
  CLIENT_EQUIPMENT_CODE: 'Client Equipment Code',
  EQUIPMENT_TYPE: 'Equipment Type',
  EQUIPMENT_TAG_NUMBWR: 'Equipment Tag Number',
  MANUFACTURER: 'Manufacturer',
  MODEL: 'Model',
  EQUIPMENT_DESCRIPTION: 'Equipment Description',
  ADD_EQUIPMENT: 'Add Equipment',
  ADD_NEW_EQUIPMENT_TYPE: ' Add New Equipment Type',
  ADD_NEW_MANUFACTURER: ' Add New Manufacturer',
  EQUIPMENT_SERIAL_NO: 'Equipment Serial No',
  EQUIPMENT_STANDARD: 'Equipment Standard',
  EQUIPMENT_TEST_DATE: 'Equipment Test Date',
};

export const UnitPropertiesVariables = {
  associations: 'Associations',
  properties: 'Properties',
  dataVariables: 'Data Variables',
  units: 'Units',
  UPLOAD_VARIABLE_FILE: 'Upload Data Variable',
  UPLOAD_UNIT_FILE: 'Upload Unit Conversion',
  searchHere: 'Search here',
};

export const columnsTitle = {
  associations: {
    propertyName: 'Property Name',
    units: 'Units',
    dataVariable: 'Data Variables',
  },
  properties: {
    properties: 'Properties',
    siUnit: 'SI Unit',
  },
  units: {
    unit: 'Unit',
    property: 'Property',
  },
  dataVariables: {
    dataVariable: 'Data Variable',
    property: 'Property',
  },
};

export const curvesColumnsTitle = {
  volumetricFlow: 'Volumetric Flow',
  headDrop: 'Head Drop',
  efficiency: 'Efficiency',
  flowCoefficient: 'Flow Coefficient',
  headCoefficient: 'Head Coefficient',
  workInputCoefficient: 'Work Input Coefficient',
  actions: 'Actions',
  compressorHead: 'Head',
  compressorEfficiency: 'Efficiency',
  throttleSteamFlow: 'Throttle Steam Mass Flow',
  outputPower: 'Output Power',
  volumeReduction: 'Volume Reduction',
  adiabaticEfficiency: 'Adiabatic Efficiency',
  inletVolume: 'Inlet Volume',
  suctionSlideValvePosition: "Slide Valve Position",
  dischargeSlideValvePosition: "Slide Valve Position",
  slideValvePosition: "Slide Valve Position",
  volumetricCapacityMultiplier: "Volumetric Capacity Multiplier",
  volumetricRatioMultiplier: "Volumetric Ratio Multiplier",
  tipSpeed: "Tip Speed",
  work: "Work Input"
};

export const byPassValueColumnsTitle = {
  valvePosition: 'Valve Position',
  valveFlowCoefficient: 'Valve Flow Coefficient',
  actions: 'Actions',
};

export const curvesColumnsName = {
  volumetricFlow: 'volumetricFlow',
  headDrop: 'headDrop',
  Efficiency: 'efficiency',
  flowCoefficient: 'flowCoefficient',
  headCoefficient: 'headCoefficient',
  workInput: 'workInput',
  throttleSteamFlow: 'throttleSteamMassFlow',
  outputPower: 'outputPower',
  volumeReduction: 'volumeReduction',
  adiabaticEfficiency: 'adiabaticEfficiency',
  inletVolume: 'inletVolume',
  suctionSlideValvePosition: "suctionSlideValvePosition",
  dischargeSlideValvePosition: "dischargeSlideValvePosition",
  volumetricCapacityMultiplier: "volumetricCapacityMultiplier",
  volumetricRatioMultiplier: "volumetricRatioMultiplier",
  slideValvePosition: "slideValvePosition",
  tipSpeed: "tipSpeed",
  work: "work"
};

export const compressorCurvesColumnsName = {
  volumetricFlow: 'volumetricFlow',
  head: 'head',
  Efficiency: 'efficiency',
  flowCoefficient: 'flowCoefficient',
  headCoefficient: 'headCoefficient',
  workInput: 'workInput',
  work: 'work'
};

export const RunnerDataVariables = {
  ITEM_NAME: 'Item Name',
  TYPE: 'Type',
  DIAMETER: 'Diameter',
  RATED_DIAMETER: 'Rated Diameter',
  MIN_DIAMETER: 'Min. Diameter',
  MAX_DIAMETER: 'Max. Diameter',
  UNIT: 'Unit',
  ACTIONS: 'Actions',
};

export const SchematicVariables = {
  DEPLOY: 'Deploy',
  TITLE: 'Component Schematic',
  CLIENTS: 'Clients',
  SYSTEM_TITLE: 'System Schematic',
};

export const GraphEditorVariables = {
  UNSAVED_CHANGES: 'Unsaved Changes',
};

export const ObjectContainerVariables = {
  OBJECT_PALLETE: 'Object Palette',
};

export const EditCurrentNodeVariables = {
  COMPONENT_ADDED: 'Add New Component to Schematic',
  COMPONENT_EDIT: 'Edit Component',
  COMPONENT_TITLE: 'Component Title',
  COMPONENT_TAG: 'Component Tag Number',
  UPDATE: 'Update',
  PROCEED: 'Proceed',
  CANCEL: 'Cancel',
  COMPONENT_TAG_INFO: 'Component Tag Info',
};

export const OutputDataVariables = {
  VARIABLE_TAG: 'Variable Tag',
  VARIABLE_NAME: 'Variable Name',
  VARIABLE_PROPERTY: 'Variable Property',
  PI_VARIABLE_NAME: 'Pi Variable Name',
  UNITS: 'Units',
  LOWER_IQR: 'UAD Multiplier',
  UPPER_IQR: 'UAD Adder',
  DATATYPES: 'Data Type',
  ACTIONS: 'Actions',
  SELECT: 'Select',
  SELECT_PROPERTY: 'Select Property',
  ON: 'ON',
  OFF: 'OFF',
  MIN_MULTIPLIER: 'multiplier',
  MAX_MULTIPLIER: 'adder',
  IQR: 'isIQR',
  BOOLEAN: 'Boolean',
  STRING: 'String',
  FLOAT: 'Float',
  NUMBER: 'Integer',
  SEARCH_HERE: 'Search here',
};

export const DesignDataVariables = {
  DATA_VARIABLES: 'Data Variables',
  UNITS: 'Units',
  DESIGN: 'Design',
  ACTIONS: 'Actions',
  INLET_CONDITIONS: 'Inlet Conditions',
  DISCHARGE_CONDITIONS: 'Discharge Conditions',
  CALCULATED_VALUES: 'Calculated Values',
  PERFORMACE_VALUES: 'Performance Values',
  OTHER_DESIGN_DATA: 'Other Design Data',
  COMPONENT_ADDED: 'Add New Component to Schematic',
  COMPONENT_TITLE: 'Component Title',
  COMPONENT_TAG: 'Component Tag Number',
  UPDATE: 'Update',
  PROCEED: 'Proceed',
  CANCEL: 'Cancel',
  dutyType: 'Duty Type',
  numberOfPoles: 'Number Of Poles',
  voltage: 'Voltage',
  frequency: 'Frequency',
  serviceFactor: 'Service Factor',
  insulationClass: 'Insulation Class',
  temperatureClass: 'Temperature Class',
  nemaEnclosureType: 'NEMA Enclosure Type',
  enclosureIPCode: 'Enclosure IP Code (IEC 60034-5)',
  icCode: 'IC Code (IEC 60034-6)',
  imCode: 'IM Code (IEC 60034-7)',
  speedType: 'Speed Type',
  speedSignalRatio: 'Speed Signal Ratio',
  bearingType: 'Bearing Type',
  bearingQuantity: 'Bearing Quantity',
  rotorConstruction: 'Rotor Construction',
  equipmentFrameSize: 'Equipment Frame Size',
  drivenEquipment: 'Driven Equipment',
};

export const gtDesignDataVariables = {
  application: 'Application',
  gtModel: 'Gas Turbine Model',
  gtClass: 'Gas Turbine Class',
  gtIsoPower: 'ISO Power Rating',
  gtIsoHeatRate: 'ISO Heat Rate',
  gtIsoExhaustFlowRate: 'ISO Exhaust Flow Rate',
  gtIsoSpeed: 'GT Speed at ISO Rating',
  configSpeed: 'Config Speed',
  startupSpeed: 'GT Start up Speed threshold',
  startupPower: 'GT Start up Power threshold',
  defaultAxialCompressorInletPressure:
    'Default Axial Compressor Inlet Pressure',
  referenceAmbientPressure: 'Reference Ambient Pressure',
  gtPowerCorrectionFactor: 'GT Power Correction Factor',
  gtPowerEstCorrectionFactor: 'GT Estimated Power Correction Factor',
  cycle: 'Cycle',
  gtDriverOutputSpeedRangeMin: 'GT Driver Output Speed Range: Minimum',
  gtDriverOutputSpeedRangeMax: 'GT Driver Output Speed Range: Maximum',
  airCompressorStages: 'Air Compressor Stages',
  airCompressorMaximumTipSpeed: 'Air Compressor Maximum Tip Speed',
  airCompressorType: 'Air Compressor Type',
  airCompressorPressureRatioISOConditions:
    'Air Compressor Pressure Ratio at ISO Conditions',
  airCompressorCasingSplit: 'Air Compressor Casing Split',
  airCompressorRotor: 'Air Compressor Rotor',
  turbineStages: 'Turbine Stages',
  turbineMaximumTipSpeed: 'Turbine Maximum Tip Speed',
  turbineCasingSplit: 'Turbine Casing Split',
  turbineRotor: 'Turbine Rotor',
  combustorType: 'Combustor Type',
  combustorQuantity: 'Combustor Quantity',
  fuelNozzlesPerCombustor: 'Fuel Nozzles Per Combustor',
  combustorConfiguration: 'Combustor Configuration',
  fuelType: 'Fuel Type',
  combustorMaxAllowableTemperatureVariation:
    'Combustor Max Allowable Temperature Variation',
  ratedFuelMoleWeight: 'Rated Fuel Mole Weight',
  ratedFuelLHV: 'Rated Fuel LHV',
  fuelPressure: 'Fuel Pressure',
  fuelTemperature: 'Fuel Temperature',
  mineralLubeOil: 'Mineral Lube Oil',
  syntheticLubeOil: 'Synthetic Lube Oil',
};

export const gtPerformanceDataVariables = {
  inletLoss: 'Inlet Loss',
  exhaustLoss: 'Exhaust Loss',
  dryBulbTemp: 'Dry Bulb Temperature (Ambient)',
  turbineInletAirChilled: 'Turbine Inlet Air Chilled (Yes/No)',
  turbineInletTemp: 'Turbine Inlet Temperature',
  relativeHumidity: 'Relative Humidity (Ambient)',
  barometricPressure: 'Barometric Pressure',
  gtOutputShaftPower: 'GT Output Shaft Power',
  gasGeneratorOutputShaftSpeed: 'Gas Generator (GG) Output Shaft Speed',
  powerTurbineOutputShaftSpeed: 'Power Turbine (PT) Output Shaft Speed',
  lhvHeatRate: 'LHV Heat Rate',
  lhvEfficiency: 'LHV Efficiency',
  firingTemp: 'Firing Temperature',
  airFlow: 'Air Flow',
  ggExhaustTemp: 'GG Exhaust Temperature',
  ptExhaustFlow: 'PT Exhaust Flow',
  ptExhaustTemp: 'PT Exhaust Temperature',
  fuelFlowrate: 'Fuel Flowrate',
  certifiedPoint: 'Certified Point (Yes/No)',
  steamFlow: 'Steam Flow',
  waterFlow: 'Water Flow',
};

export const controlValveDesignDataVariables = {
  purpose: 'Valve Purpose',
  type: 'Valve Type',
  inherentCharacter: 'Inherent Characteristic',
  actuator: 'Valve Actuator',
  equationConstantN1: 'Valve Equation Constant N1',
  equationConstantN2: 'Valve Equation Constant N2',
  equationConstantN3: 'Valve Equation Constant N3',
  configDP: 'Config DP',
  nominalDiameter: 'Valve Nominal Diameter',
  inletDiameter: 'Pipe Inlet Diameter',
  outletDiameter: 'Pipe Outlet Diameter',
  fluidPhase: 'Fluid Phase',
  ratedValveFlowCoeff: 'Rated Valve Flow Coefficient',
  liqDPrecoveryFactor: 'Liquid Pressure Drop Recover Factor',
  chokedDPratioFactor: 'Choked Pressure Drop Ratio Factor',
  velHeadLossCoeff: 'Velocity Head Loss Coefficient',
  pipingGeoFactor: 'Piping Geometry Factor',
  valveMaxStroke: 'Valve Maximum Stroke',
  valveMaxFlowrate: 'Valve Maximum Flowrate',
  criticalPressure: 'Critical Pressure',
  valveDPToggle: 'Valve DP Toggle',
  valveOpenThreshold: 'Valve Open Threshold',
};
export const flowMeasurementDropDownVariables = {
  METER_LOCATION: 'Meter Location',
  FLOW_METER_TYPE: 'Flow Meter Type',
  IS_FLOW_MEASUREMENT: 'Flow Meter Missing ',
  FLOW_METER_MANUFACTURER: 'Flow Meter Manufacturer',
  FLOW_METER_MODEL: 'Flow Meter Model',
  FLOW_METER_SERIAL_NUMBER: 'Flow Meter Serial Number',
  FLOW_METER_TAG_NUMBER: 'Flow Meter Tag Number',
};

export const METER_LOCATION_DATA = ['Suction', 'Discharge', 'Sidestream'];

export const flowMeasurementHeaderVariables = {
  VARIABLES: 'Variables',
  ACTIONS: 'Actions',
  VALUES: 'Value',
  UNITS: 'Units',
};

export const patterns = {
  name: {
    pattern: /^[a-zA-Z0-9 &/.()'‘’,-]*$/,
    message:
      "Only letters, spaces, numbers and following special characters are allowed & /. ( ) - , '",
  },
  address: {
    pattern: /^[a-zA-Z0-9 #/.:;()'‘’,-]*$/,
    message:
      "Only letters, spaces, numbers and following special characters are allowed # . , ; :  - /'",
  },
  common: {
    pattern: /^[a-zA-Z0-9 #/.'‘’,-]*$/,
    message:
      "Only letters, spaces, numbers and following special characters are allowed #  . , - /'",
  },
  zip: {
    pattern: /^[a-zA-Z0-9 -]*$/,
    message: 'Only letters, spaces and numbers are allowed',
  },
  city: {
    pattern: /^[a-zA-Z ]*$/,
    message: 'Only letters and spaces are allowed',
  },
};
export const BypassValveVariables = {
  COMPONENT_TITLE: 'Component Title',
  TAG_NUMBER: 'Tag Number',
  EQUIPMENT_TYPE: 'Equipment Type',
  EQUIPMENT_MANUFACTURER: 'Equipment Manufacturer',
  EQUIPMENT_MODEL: 'Equipment Model',
  CHARACTERSTICS: 'Characteristics',
  VALVE_NOMINAL_DIAMETER: 'Valve Nominal Diameter',
  INLET_PIPE_DIAMETER: 'Inlet Pipe Diameter',
  OUTLET_PIPE_DIAMETER: 'Outlet Pipe Diameter',
  PIPING_GEOMETRY_FACTOR: 'Piping Geometry Factor',
  BYPASS_VALVE_CALCULATION_THRESHOLD: 'Bypass Valve Calculation Threshold',
  FLOW_COEFFICIENT: 'Flow Coefficient',
  VELOCITY_HEAD_LOSS_COEFFICIENT: 'Velocity Head Loss Coefficient',
  VALVE_POSITION: 'Valve Position (%)',
  VALVE_FLOW_COEFFICIENT: 'Valve Flow Coefficient',
  ACTIONS: 'Actions',
  BYPASS_VALVE_DESC: 'Bypass Valve Description',
  BYPASS_VALVE_DATA: 'Bypass Valve Data',
  THEORITICAL: 'Theoretical',
  OPERATIONAL: 'Operational',
  VAVLE_CHARACTERSTIC_CURVE: 'Valve Characteristic Curve',
};

export const TagFieldVariables = {
  EQUIPMENT: "Equipment",
  DATA_TYPE: "Data Type",
  VARIABLE_PROPERTY: "Variable Property",
  MECHADEMY_UNIT: "Mechademy Unit",
  CLIENT_UNIT: "Client Unit",
  MIN_VALUE: "Variable min. value",
  MAX_VALUE: "Variable max. value",
  ADDER: "UAD Adder",
  MULTIPLYER: "UAD Multiplier",
  ENABLE_UAD: "Enable UAD",
  IS_FROZEN: "Is Frozen",
};

export const FluidCompositionVariables = {
  TOTAL: 'Total',
  NORMALIZE: 'Normalize',
  MOLE_FRACTION: 'Mole Fraction',
  PERCENT: '%',
  PERCENT_BY_100: '%/100',
  SEARCH: 'Search here',
  EQUATION_OF_STATE: 'Equation of State',
  COMPOSITION_TYPE: 'Composition Type',
  ACTIONS: 'Actions',
  COMPOSITION: 'Composition',
  FLUID_NAME: 'Fluid Name',
  SEARCH_NAME: 'Search Name',
  CALC_DISCHARGE_CONDITIONS: 'Calculate Discharge conditions',
};

export const CalculatedDataVariables = {
  TAG: 'Tag ',
  VARIABLE_NAME: 'Variable Name',
  VARIABLE_PROPERTY: 'Variable Property',
  UNITS: 'Units',
  MIN: 'Min Bound',
  MAX: 'Max Bound',
  ACTIONS: 'Actions',
  SEARCH_HERE: 'Search here',
};

export const DashboardVariables = {
  TITLE: 'Dashboard',
  ADD_CARD: 'Add Card',
  ADD_TREND: 'Add Trend Card',
  INSPECTION_MODE: 'Inspection Mode',
  NO_SYSTEMS_AND_EQUIPMENTS:
    "This facility doesn't include systems and equipments.",
  GLOBAL_INSPECTION_MODE: 'Global Inspection Mode',
};

/** Alerts Constants Start */
export const ALERT_TYPE = [
  'Anomaly',
  'Bad Data',
  'Frozen Data',
  'Timestamp error',
  'Process deviation',
  'Process deviation',
  'Out of Bound Data',
  'Missing input data',
  'Missing process data',
  'Run Status Change',
  'Iteration Failed',
  //'IQR threshold exceedance',
  'IOW threshold exceedance',
  'Univariate Anomaly Detection',
];

export const ALERT_TABS = {
  All: 'All Alerts',
  Active: 'Active Alerts',
  Bypassed: 'By Passed Alerts',
  Inactive: 'Inactive Alerts',
  New: 'New Alerts',
  MyAlerts: 'My Alerts',
};

export const SORT_ORDER = {
  ascending: 1,
  descending: -1,
};

export const ALERT_ACTION = {
  edit: 'Edit',
  discard: 'Discard',
  close: 'Close',
};

export const ALERT_FILTER_HEADING = [
  'Alert Level',
  'Alert Type',
  'Mechademy Status',
  'Client Status',
];

export const ALERT_FILTER_CLIENT_HEADING = [
  'Alert Level',
  'Alert Type',
  'Status',
];

export const ALERT_LEVELS = ['High', 'Medium', 'Low', 'Unknown'];

export const ALERT_MEC_STATUS = [
  'New',
  'Discarded',
  'Forwarded',
  'Shelved',
  'Disabled',
  'Closed',
  'Acknowledged',
  'Shelved Not Delivered',
];

export const ALERT_MEC_STATUS_KEY = {
  New: 'New',
  Discarded: 'Discard',
  Forwarded: 'Forward',
  Shelved: 'Shelved',
  Disabled: 'Disable',
  Closed: 'Close',
  Acknowledged: 'Acknowledged',
  'Shelved Not Delivered': 'Shelved Not Delivered',
};

export const ALERT_CLI_STATUS = [
  'New',
  'Shelved',
  'Closed',
  'Acknowledged',
  'Shelved Not Delivered',
];

export const ALERT_DETAIL_HEADER = 'Alert Summary';

export const ALERT_SORT_BY = [
  { name: 'Date: Oldest first', key: 1 },
  { name: 'Date: Latest first', key: -1 },
];

/** Alerts Constants End */
export const UserManagementVariable = {
  TITLE: 'User Management',
  ADD_USER: 'Add User',
};

export const USER_MANAGEMENT_FILTER_HEADING = ['Status'];

export const IOW_FILTER_HEADING = ['IOW  Status'];

export const IOW_STATUS =['ON','OFF'];

export const USER_MANAGEMENT_STATUS = ['Active', 'Inactive'];

export const USER_MANGEMENT_SORT_VALUE = [
  { name: 'Name: A-Z', key: 1 },
  { name: 'Name: Z-A', key: -1 },
];

export const ML_ALGORITHM_FILTER_HEADING = ['Algo Type', 'Status'];
export const ML_ALGORITHM_ALGO_TYPES = ['sklearn', 'xg_boost', 'ensemble', 'keras', 'xgb_datetime'];
export const ML_ALGORITHM_STATUS = ['Deployed', 'Undeployed'];

export const InputDataVariable = {
  variableTag: 'Variable Tag',
  variableName: 'Variable Name',
  piVariableName: 'Pi Variable Name',
  variableProperty: 'Variable Property',
  unit: 'Unit',
  minMultiplier: 'UAD Multiplier',
  maxMultiplier: 'UAD Adder',
  isIQR: 'Enable UAD',
  actions: 'Actions',
  on: 'ON',
  off: 'OFF',
};

export const forgotPassword = {
  TITLE: 'Forgot your password?',
  INSTRUCTION:
    "Enter your email address and we'll send you a link to reset your password.",
};

export const CreateNewPassword = {
  TITLE: 'Create New Password',
  INSTRUCTION:
    "Make sure it's at least 8 characters and at most 256 characters long, including a number, a special character, and an uppercase letter. Back slash() is an illegal character. ",
};

export const EquipmentAssetVariable = {
  ID: 'ID',
  EQUIPMENT_NAME: 'Equipment Name',
  EQUIPMENT_TYPE: 'Equipment Type',
  ACTIONS: 'Actions',
  ASSET_LIBRARY: 'Asset Library',
  EQUIPMENT: 'Equipment',
  ADD_EQUIPMENT: 'Add Equipment',
  OPEN_IMAGE: 'Open Image',
};

export const ROLE_ENUM = {
  MechademyAdmin: 'Mechademy Admin',
  ClientAdmin: 'Client Admin',
  FacilityManager: 'Facility Manager',
  SystemEngineer: 'System Engineer',
  EquipmentEngineer: 'Equipment Engineer',
  mechademyFacilityManager: 'Mechademy Facility Manager',
};

export const MANAGE_PI_TAGS_CONSTANTS = {
  TITLE: 'Manage Tags',
  TITLE_API_INDEX: '',
  VARIABLE_TAG: 'Variable Tag',
  TAG_API_INDEX: 'piTag',
  UNITS: 'Units',
  UNITS_API_INDEX: 'units',
  VARIABLE_NAME: 'PI Variable Name',
  NAME_API_INDEX: 'piVariableName',
  UNIT: 'Unit',
  IOW_STATUS: 'IOW Status',
  EQUIPMENTS: 'Equipment',
  EQUIPMENTID: 'equipmentId',
  EQUIPMENT: 'equipment',
  DATA_TYPE: 'Data Type',
  DATA_TYPE_API_INDEX: 'dataType',
  VARIABLE_PROPERTY: 'Variable Property',
  PROPERTY_API_INDEX: 'variableProperty',
  MECHADEMY_UNIT: 'Mechademy Unit',
  MECH_UNIT_API_INDEX: 'mechademyUnits',
  VARIABLE_MIN_VALUE: 'Variable min. value',
  VAR_MIN_API_INDEX: 'minThreshold',
  VARIABLE_MAX_VALUE: 'Variable max. value',
  VAR_MAX_API_INDEX: 'maxThreshold',
  IQR_MULTIPLIER: 'IQR multiplier',
  UAD_MULTIPLIER: 'UAD Multiplier',
  UAD_ADDER: 'UAD Adder',
  IQR_API_INDEX: 'multiplier',
  ADDER: 'Adder',
  ADDER_API_INDEX: 'adder',
  IS_IQR: 'Is IQR',
  ENABLE_UAD: 'Enable UAD',
  IS_IQR_API_INDEX: 'isIQR',
  IS_FROZEN: 'Is Frozen',
  IS_FROZEN_API_INDEX: 'isFrozen',
  EMPTY_VALUE: 'null',
  BASE_INTERVAL_TIME: 'Base interval time (in seconds)',
  CRITICAL_LIMIT_LOW: 'Critical Limit Low',
  CRITICAL_LIMIT_LOW_INDEX: 'criticalLimitLow',
  STANDARD_LIMIT_LOW: 'Standard Limit Low',
  STANDARD_LIMIT_LOW_INDEX: 'standardLimitLow',
  OPTIMAL_TARGET: 'Optimal Target',
  OPTIMAL_TARGET_INDEX: 'optimalTarget',
  CRITICAL_LIMIT_HIGH: 'Critical Limit High',
  CRITICAL_LIMIT_HIGH_INDEX: 'criticalLimitHigh',
  STANDARD_LIMIT_HIGH: 'Standard Limit High',
  STANDARD_LIMIT_HIGH_INDEX: 'standardLimitHigh',
  ENABLE_IOW: 'Enable IOW',
  ENABLE_IOW_INDEX: 'enableIOW',
  REFERENCE_START_DATE: 'Reference Start Date',
  OPTIMAL_TIME_RANGE: 'Time in Optimal Range (Hr)',
  CRITICAL_LIM_LOW_TIME: 'Critical Limit Low Time (Hr)',
  CRITICAL_LIM_HIGH_TIME: 'Critical Limit High Time (Hr)',
  STANDARD_LIM_LOW_TIME: 'Standard Limit Low Time (Hr)',
  STANDARD_LIM_HIGH_TIME: 'Standard Limit High Time (Hr)',
  REFERENCE_START_DATE_INDEX: 'referenceStartDate',
  OPTIMAL_TIME_RANGE_INDEX: 'optimalTimeRange',
  CRITICAL_LIM_LOW_TIME_INDEX: 'criticalLimitLowTime',
  CRITICAL_LIM_HIGH_TIME_INDEX: 'criticalLimitHighTime',
  STANDARD_LIM_LOW_TIME_INDEX: 'standardLimitLowTime',
  STANDARD_LIM_HIGH_TIME_INDEX: 'standardLimitHighTime',
  REQUIRED_FIELD: 'This field is mandatory!',
  CRITICAL_LIMITS_MANDATORY: 'Atleast one critical limit is required!',
  ENTER_VALUES_CORRECTLY: 'Please enter values correctly!',
  ENTER_CRITICAL_LIMIT_LOW_CORRECTLY:
    'Please enter Critical Limit Low correctly!',
  ENTER_CRITICAL_LIMIT_HIGH_CORRECTLY:
    'Please enter Critical Limit High correctly!',
  ENTER_STANDARD_LIMIT_LOW_CORRECTLY:
    'Please enter Standard Limit Low correctly!',
  ENTER_STANDARD_LIMIT_HIGH_CORRECTLY:
    'Please enter Standard Limit High correctly!',
  ENTER_OPTIMAL_TARGET_CORRECTLY: 'Please enter Optimal Target correctly!',
  RUN_STATUS_CHECK_IOW: 'Run Check'
};

export const USER_MANGEMENT_TABLE_HEADER = {
  NAME: 'Name',
  EMAIL: 'Email',
  WORK_PHONE: 'Work Phone',
  CLIENT_NAME: 'Client Name',
  ROLE: 'Role',
  STATUS: 'Status',
  ACTION: 'Action',
};

export const DEPLOY_ERROR = {
  CONFIG: 'Config Data Errors',
  INPUT: 'Input Data Errors',
  OUTPUT: 'Output Data Errors',
  CALCULATED: 'Calculated Data Errors',
  HAS_ERRORS: ' config has errors.',
};

export const CIRCUIT_DATA = {
  DATA_VARIABLES: 'Data Variables',
  UNITS: 'Units',
  VALUES: 'Design',
  ACTIONS: 'Actions',
  LOCKED_ROTOR: 'Locked Rotor at Rated Voltage',
  RATED_LOAD: 'Rated Load at Rated Voltage',
  RESIDUAL_VOLTAGE: 'Residual Voltage Open-Circuit Time Constant',
  ADDED: 'Equivalent circuit data saved successfully',
  DIRTY_ROWS: 'Please enter values correctly',
};

export const PerformanceDataVariables = {
  ratedPowerOutput: 'Rated Power Output',
  ratedCurrent: 'Rated Current',
  synchronousSpeed: 'Synchronous Speed',
  fullLoadSpeed: 'Full Load Speed',
  fullLoadTorque: 'Full Load Torque (FLT)',
  rotorGD2AtFLRPM: 'Rotor GD2 at FLRPM',
  rotorWeight: 'Rotor Weight',
  lockedRotortorque: 'Locked Rotor Torque (LRT)',
  pullUpTorque: 'Pull-up Torque (PUT)',
  breakDownTorque: 'Break Down Torque (BDT)',
  lockedRotorCurrent: 'Locked-Rotor Current',
  lockedRotorPF: 'Locked-Rotor PF',
  lockedRotorWithstandTimeCold: 'Locked-Rotor Withstand Time, Cold',
  lockedRotorWithstandTimeRatedTemp: 'Locked-Rotor Withstand Time, Rated Temp',
  statorSlots: 'Stator Slots',
  rotorSlots: 'Rotor Slots',
  pullInTorque: 'Pull-in Torque (PIT)',
  pullOutTorque: 'Pull-out Torque (POT)',
  ratedMotorFieldCurrent: 'Rated Motor Field Current',
  ratedMotorFieldVoltage: 'Rated Motor Field Voltage',
  ratedExciterFieldCurrent: 'Rated Exciter Field Current',
  ratedExciterFieldVoltage: 'Rated Exciter Field Voltage',
  temperatureRiseAtZeroCurrent: 'Temperature Rise at Zero current',
};

export const ML_ALGORITHM_VARIABLE = {
  HEADER_TITLE: 'Ml Algorithm',
  ADD_ALGO_TITLE: 'Create Model',
  EDIT_ALGO_TITLE: 'Edit Model',
  OUTPUT_TAG: 'Compare with',
  INPUT_TAG: 'Input tag',
  VIEW_ALGO_TITLE: 'View Model',
  PREDICTED_TAG: 'Predicted Tag',
  VIEW_ALGO: 'View Model',
  ML_TAG: 'ML-Tag',
  CALCULATED_TAG: 'Calculated Tag',
  DEPLOY: 'Deploy',
  UNDEPLOY: 'Undeploy',
};

export const PerformaceCurvesVariables = {
  load: 'Load',
  efficiency: 'Efficiency',
  powerFactor: 'Power Factor',
  current: 'Current',
  slip: 'Slip',
  actions: 'Actions',
};

export const StartingCharacteristicsCurvesVariables = {
  speed: 'Speed',
  currentVoltage100: 'Current 100% Voltage',
  currentVoltage80: 'Current 80% Voltage',
  powerFactor: 'Power Factor',
  torqueRatedLoad: 'Torque Rated Load',
  torqueVoltage100: 'Torque 100% Voltage',
  torqueVoltage80: 'Torque 80% Voltage',
  actions: 'Actions',
};

export const VariableSpeedCurvesVariables = {
  speed: 'Speed',
  torque: 'Torque',
  current: 'Current',
  powerFactor: 'Power Factor',
  power: 'Power',
  efficiency: 'Efficiency',
  actions: 'Actions',
};

export const MotorParametersDataVariables = {
  generatorRatedVoltage: 'Generator kVA Base at Rated Voltage and 25 C',
  ratedVoltageSaturatedSubtransientReactance:
    'Rated Voltage Saturated Subtransient Reactance',
  ratedVoltageSaturatedNegativeSequenceReactance:
    'Rated Voltage Saturated Negative Sequence Reactance',
  ratedVoltageSaturatedArmatureTimeConstant:
    'Rated Voltage Saturated Armature Time Constant',
  armatureLeakageReactance: 'Armature Leakage Reactance',
  directAxisArmatureReactance: 'Direct Axis Armature Reactance',
  saturatedDirectAxisSynchronousReactance:
    'Direct Axis Synchronous Reactance (Saturated)',
  unsaturatedDirectAxisSynchronousReactance:
    'Direct Axis Synchronous Reactance (Un-Saturated)',
  potierReactance: 'Potier Reactance',
  saturatedDirectAxisTransientReactance:
    'Direct Axis Transient Reactance (Saturated)',
  unsaturatedDirectAxisTransientReactance:
    'Direct Axis Transient Reactance (Un-Saturated)',
  saturatedDirectAxisSubtransientReactance:
    'Direct Axis Subtransient Reactance (Saturated)',
  unsaturatedDirectAxisSubtransientReactance:
    'Direct Axis Subtransient Reactance (Un-Saturated)',
  quadratureAxisArmatureReactance: 'Quadrature Axis Armature Reactance',
  saturatedQuadratureAxisSynchronousReactance:
    'Quadrature Axis Synchronous Reactance (Saturated)',
  unsaturatedQuadratureAxisSynchronousReactance:
    'Quadrature Axis Synchronous Reactance (Un-Saturated)',
  fieldLeakageReactance: 'Field Leakage Reactance',
  saturatedQuadratureAxisTransientReactance:
    'Quadrature Axis Transient Reactance (Saturated)',
  unsaturatedQuadratureAxisTransientReactance:
    'Quadrature Axis Transient Reactance (Un-Saturated)',
  saturatedQuadratureAxisSubtransientReactance:
    'Quadrature Axis Subtransient Reactance (Saturated)',
  unsaturatedQuadratureAxisSubtransientReactance:
    'Quadrature Axis Subtransient Reactance (Un-Saturated)',
  quadratureAxisAmortisseurReactance:
    'Quadrature Axis Amortisseur Reactance (Slip = 1.0)',
  directAxisAmortisseurReactance:
    'Direct Axis Amortisseur Reactance (Slip = 1.0)',
  saturatedNegativeSequenceReactance: 'Negative Sequence Reactance (Saturated)',
  unsaturatedNegativeSequenceReactance:
    'Negative Sequence Reactance (Un-Saturated)',
  statorArmatureResistance: 'Stator Armature Resistance/Phase',
  withoutFdrsFieldResistance: 'Field Resistance (Without FDRs)',
  withFdrsFieldResistance: 'Field Resistance (With FDRs)',
  quadratureAxisAmortisseurResistance:
    'Quadrature Axis Amortisseur Resistance (Slip = 1.0)',
  directAxisAmortisseurResistance:
    'Direct Axis Amortisseur Resistance (Slip = 1.0)',
  zeroSequenceResistance: 'Zero Sequence Resistance',
  negativeSequenceResistance: 'Negative Sequence Resistance',
  armatureShortCircuitTimeConstant: 'Armature Short-Circuit Time Constant',
  directAxisTransientOpenCircuitTimeConstant:
    'Direct Axis Transient Open-Circuit Time Constant',
  directAxisSubtransientOpenCircuitTimeConstant:
    'Direct Axis Subtransient Open-Circuit Time Constant',
  quadratureAxisSubtransientOpenCircuitTimeConstant:
    'Quadrature Axis Subtransient Open-Circuit Time Constant',
  directAxisTransientShortCircuitTimeConstant:
    'Direct Axis Transient Short-Circuit Time Constant',
  directAxisSubtransientShortCircuitTimeConstant:
    'Direct Axis Subtransient Short-Circuit Time Constant',
  windingCapacitancetoGround: 'Winding Capacitance to Ground (per phase)',
  i22tLimit: 'I22t Limit',
};

export const stPerformanceDataVariables = {
  caseDescription: 'Case Description',
  throttleSteamFlow: 'Throttle steam mass flow',
  suctionPressure: 'Suction pressure',
  suctionTemperature: 'Suction temperature',
  exhaustPressure: 'Exhaust pressure',
  exhaustQuality: 'Exhaust quality',
  powerOutput: 'Power output',
  rotationalSpeed: 'Rotational speed',
  steamRate: 'Steam rate',
  outputTorque: 'Output Torque',
};

export const stDesignDataVariables = {
  stStartupSpeedThreshold: 'ST startup speed threshold (for run-status)',
  stStartupPowerThresold: 'ST startup power threshold (for run-status)',
  glandSteamLeakage:
    'Gland steam leakage (to determine throttle steam mass flow)',
  totalSteamLeakage:
    'Total steam leakage (to determine throttle steam mass flow)',
  inletSteamPressureRated: 'Inlet steam pressure rated',
  inletSteamPressureMax: 'Inlet steam pressure max',
  inletSteamPressureMin: 'Inlet steam pressure min',
  inletSteamTemperatureRated: 'Inlet steam temperature rated',
  inletSteamTemperatureMax: 'Inlet steam temperature max',
  inletSteamTemperatureMin: 'Inlet steam temperature min',
  outputSpeedRangeMin: 'ST Output Speed Range: Minimum',
  outputSpeedRangeMax: 'ST Output Speed Range: Maximum',
  exhaustSteamPressureRated: 'Exhaust steam pressure rated',
  exhaustSteamPressureMax: 'Exhaust steam pressure max',
  exhaustSteamPressureMin: 'Exhaust steam pressure min',
  exhaustSteamTemperatureRated: 'Exhaust steam temperature rated',
  exhaustSteamTemperatureMax: 'Exhaust steam temperature max',
  exhaustSteamTemperatureMin: 'Exhaust steam temperature min',
  throttleSteamFlowRated: 'Throttle steam flow rated',
  throttleSteamFlowMax: 'Throttle steam flow max',
  throttleSteamFlowMin: 'Throttle steam flow min',
  exhaustSteamFlowRated: 'Exhaust steam flow rated',
  exhaustSteamFlowMax: 'Exhaust steam flow max',
  exhaustSteamFlowMin: 'Exhaust steam flow min',
  inductionSteamPressureRated: 'Induction steam pressure rated',
  inductionSteamPressureMax: 'Induction steam pressure max',
  inductionSteamPressureMin: 'Induction steam pressure min',
  inductionSteamTemperatureRated: 'Induction steam temperature rated',
  inductionSteamTemperatureMax: 'Induction steam temperature max',
  inductionSteamTemperatureMin: 'Induction steam temperature min',
  inductionSteamFlowRated: 'Induction steam flow rated',
  inductionSteamFlowMax: 'Induction steam flow max',
  inductionSteamFlowMin: 'Induction steam flow min',
  extractionSteamPressureRated: 'Extraction steam pressure rated',
  extractionSteamPressureMax: 'Extraction steam pressure max',
  extractionSteamPressureMin: 'Extraction steam pressure min',
  extractionSteamTemperatureRated: 'Extraction steam temperature rated',
  extractionSteamTemperatureMax: 'Extraction steam temperature max',
  extractionSteamTemperatureMin: 'Extraction steam temperature min',
  extractionSteamFlowRated: 'Extraction steam flow rated',
  extractionSteamFlowMax: 'Extraction steam flow max',
  extractionSteamFlowMin: 'Extraction steam flow min',
  maxTipSpeed: 'Blades/buckets maximum tip speed',
  finalStageRatedBladeLength: 'Final stage rated blade length',
  finalStageMaxBladeLength: 'Final stage maximum blade length',
};

export const syncGeneratorPerformaceCurvesVariables = {
  loadPercentage: 'Load %',
  efficiency: 'Efficiency',
  powerFactor: 'Power Factor',
  currentPercentage: 'Current %',
  slip: 'Slip',
  actions: 'Actions',
  load: 'Load',
  current: 'Current',
};

export const syncGeneratorCapabilityCurvesVariables = {
  ambientTemperature: 'Ambient Temperature',
  realPower: 'Real Power At Unity Power Factor',
  actions: 'Actions',
};

export const recipCompVariable = {
  clearenceVolume: 'Clearance Volume',
  startupPowerThreshold: 'Startup Power Threshold',
  airCompressorMaximumTipSpeed: 'Air Compressor Maximum Tip Speed',
};

export const PIMComponentDesignDataVariables = {
  application: 'Application',
  twoPhaseFrictionFactorCorrelation: 'Two phase friction factor correlation',
  heatTransferCorrelationFactor: 'Heat transfer correlation',
  singlePhaseFrictionFactorCorrelation: 'Single phase friction factor correlation',
  palmerCorrectionFactor: 'Palmer correction',
  waterVolume: 'Water volume',
  pipeSchedule:'Pipe schedule',
  nominalDiameter:'Nominal diameter',
  inletDiameter:'Inside diameter',
  outletDiameter:'Outside diameter',
  pipeWallThickness:'Pipe wall thickness',
  segmentLength:'Segment length',
  inletElevation:'Inlet elevation',
  outletElevation:'Outlet elevation',
  elevationChange:'Elevation change',
  pipeMaterial:'Pipe Material',
  pipeWallThermalConductivity:'Pipe wall thermal conductivity',
  pipeWallThermalConductivityNote: 'Note(Pipe wall thermal conductivity)',
  pipeMaterialType: "Pipe material type",
  pipeRoughness:'Pipe roughness',
  pipeRoughnessNote:'Note(Pipe roughness)',
  thermalExpansionCoefficient : 'Thermal expansion coefficient',
  thermalExpansionCoefficientNote:'Note(Thermal expansion coefficient)',
  hasInsideCoating: 'Include inside coating',
  coatingType: 'Coating type',
  coatingRoughness: 'Coating roughness',
  coatingRoughnessNote: 'Note(coating roughness)',
  coatingThickness:'Coating thickness',
  hasPipeWall: 'Include pipe wall',
  hasInsulation: 'Include insulation',
  insulationType: 'Insulation type',
  insulationThermalConductivity:'Insulation thermal conductivity',
  insulationThermalConductivityNote:'Note(Insulation thermal conductivity)',
  insulationThickness:'Insulation thickness',
  hasOuterHTC:'Include outer HTC',
  ambientMedium:'Ambient medium',
  soilType:'Soil type',
  soilThermalConductivity:'Soil thermal conductivity',
  soilThermalConductivityNote:'Note(Soil thermal conductivity)',
  burialDepth:'Burial depth',
  ambientPressure:'Ambient pressure',
  ambientVelocity:'Ambient velocity',
  ambientVelocityNote:'Note(Ambient velocity)',
  janAmbientTemp: 'January',
  febAmbientTemp: 'February',
  marAmbientTemp: 'March',
  aprAmbientTemp: 'April',
  mayAmbientTemp: 'May',
  junAmbientTemp: 'June',
  julAmbientTemp: 'July',
  augAmbientTemp: 'August',
  sepAmbientTemp: 'September',
  octAmbientTemp: 'October',
  novAmbientTemp: 'November',
  decAmbientTemp: 'December',
  ambientTemperatureNote: 'Note(Ambient temperature)',
  numSegments: "No. of segments"
};
export const screwCompVariable = {
  oilSpecificHeat: "Oil Specific Heat",
}

export const TransientDataVariable = {
  variableTag: 'Variable Tag',
  variableName: 'Variable Name',
  analysisType: 'Analysis Type',
  curveType: 'Curve Type',
  lookBackTime: 'Look Back Time',
  lookForwardTime: 'Look Forward Time',
  enable: 'Enable',
  actions: 'Actions',
  tagId: 'Tag ID',
  startTime: 'Start Time',
  endTime: 'End Time',
  analysisNumber: 'Analysis No.',
  type: 'Type',
  eventTriggerTime: 'Event Trigger Time',
  baseline: 'Baseline',
  markAsBad: 'Mark As Bad',
  analysisDetails: 'Analysis Details',
  analysisKPIs: 'Analysis KPIs',
  runUpTime: 'Run Up Time',
  auc: 'Area Under Curve',
  rateOfChange: 'Rate Of Change',
  startValue: 'Start Value',
  endValue: 'End Value',
  baselineDeviation: 'Deviation to Baseline',
  comments: 'Comments',
  cardType: 'Transient Analysis Plot',
  UPDATE_SUCCESS: 'Updated Successfully',
  dataPlot: 'Data Plot',
  error: 'Error',
  differential: 'Differential',
  timeToComplete: 'Time To Complete',
  triggerTimeStartTimeDiff: 'Trigger Time Start Time Difference',
  aucDifference: 'Difference in area under the curve',
  aucDifferencePerc: 'Difference in area under the curve(%)',
  aucTimeDifference: 'Difference in time taken to complete the run(s)',
  aucTimeDifferencePerc: 'Difference in time taken to complete the run(%)',
  timeUnit: "s"
};

export const TransientDataKeyChain = {
  variableTag: 'variableTag',
  variableName: 'variableName',
  analysisType: 'analysisType',
  curveType: 'curveType',
  lookForwardTime: 'lookForwardTime',
  lookBackwardTime: 'lookBackTime',
  enable: 'enable'
};

export const hvsgDesignDataVariables = {
  speedType: 'Speed Type',
  minGuideVanePosition: 'Min Guide Vane Position',
  maxGuideVanePosition: 'Max Guide Vane Position',
};

export const hvsgPerformanceDataVariables = {
  designPower: 'Design Power',
  designTorque: 'Design Torque',
  inputSpeed: 'Input Speed',
  designSpeedRatio: 'Design Speed Ratio',
  designOutputSpeed: 'Design Output Speed',
  powerMaxThreshold: 'Power Max Threshold',
  powerMinThreshold: 'Power Min Threshold',
  startupSpeed: 'Startup Speed',
  startupPower: 'Startup Power',
};

export const hvsgGearDataVariables = {
  gear: 'Gear',
  pinion: 'Pinion',
};

export const hvsgWorkingFluidVariables = {
  workingFluidType: 'Working Fluid Type',
  workingFluidISO: 'Working Fluid ISO',
  workingFluidViscosity: 'Working Fluid Viscosity',
  workingFluidCP: 'Working Fluid CP',
  fluidSpecificGravity: 'Fluid Specific Gravity',
};
