import { UserRole, UserAccess } from '../components/AppScreen/AppScreen';
import { clearToken } from '../libs/token';
import { appFeature, permissions, roles } from '../permissions';
import { features } from '../permissions/features.permissions';

let role = null;
let access = null;

const getRole = () => {
  role = UserRole;
  access = UserAccess;
  if (!role || !Object.entries(roles).find((item) => item[1] === role)) {
    clearToken();
  }
};

export const RoleGaurd = (route) => {
  getRole();
  if (
    permissions[role] &&
    appFeature[route] &&
    appFeature[route].relatedTo &&
    permissions[role][appFeature[route].relatedTo]
  ) {
    return permissions[role][appFeature[route].relatedTo][
      appFeature[route].type
    ];
  }
  return false;
};

export const ElementsGaurd = (element, type, info = null) => {
  getRole();
  switch (role) {
    case roles.MECHADEMY_ADMIN:
    case roles.CLIENT_ADMIN:
    case roles.EQUIPMENT_MANAGER:
    case roles.FACILITY_MANAGER:
    case roles.SYSTEM_MANAGER:
      if (
        permissions[role] &&
        type &&
        type.relatedTo &&
        permissions[role][type.relatedTo]
      ) {
        if (permissions[role][type.relatedTo][type.type]) {
          return element;
        }
      }
      return null;
    case roles.MECHADEMY_FACILITY_MANAGER:
      if (type && type.relatedTo === features.USER_MANAGEMENT) {
        const userManagementAccess = access?.userManagementPermission;
        return userManagementAccess
          ? userManagementAccess[type.type]
            ? element
            : null
          : null;
      }
      const presentFacility = getFacility(info);
      const facilityInfo = access.facility.find(
        (fac) => fac.facilityId === presentFacility
      );
      if (facilityInfo) {
        if (facilityInfo.permission[type.relatedTo][type.type]) {
          return element;
        }
        return null;
      }
      return null;
    default:
      return null;
  }
};

const getFacility = (info) => {
  let presentFacility = '';
  if (info) {
    if (info.type === 'facility') {
      presentFacility = info.dataRef._id;
    } else {
      presentFacility = info.dataRef.facilityId;
    }
  }
  return presentFacility;
};
