export const GET_SYSTEM_SCHEMATIC_PROGRESS = "GET_SYSTEM_SCHEMATIC_PROGRESS";
export const GET_SYSTEM_SCHEMATIC_SUCCESS = "GET_SYSTEM_SCHEMATIC_SUCCESS";
export const GET_SYSTEM_SCHEMATIC_ERROR = "GET_SYSTEM_SCHEMATIC_ERROR";

export const ADD_SYSTEM_SCHEMATIC_PROGRESS = "ADD_SYSTEM_SCHEMATIC_PROGRESS";
export const ADD_SYSTEM_SCHEMATIC_SUCCESS = "ADD_SYSTEM_SCHEMATIC_SUCCESS";
export const ADD_SYSTEM_SCHEMATIC_ERROR = "ADD_SYSTEM_SCHEMATIC_ERROR";

export const EDIT_SYSTEM_SCHEMATIC_PROGRESS = "EDIT_SYSTEM_SCHEMATIC_PROGRESS";
export const EDIT_SYSTEM_SCHEMATIC_SUCCESS = "EDIT_SYSTEM_SCHEMATIC_SUCCESS";
export const EDIT_SYSTEM_SCHEMATIC_ERROR = "EDIT_SYSTEM_SCHEMATIC_ERROR";

export const CLEAR_STATE = "CLEAR_STATE";
