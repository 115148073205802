import { Icon, Input } from 'antd';
import React from 'react';
import { validateNumberNegative } from '../../../../../../libs/validator';
import {
  syncGeneratorPerformaceCurvesVariables,
} from './../../../../../../constants/variables';

export const tableHeader = tableHeader => {
  return [
    {
      title: syncGeneratorPerformaceCurvesVariables.loadPercentage,
      dataIndex: 'loadPercentage',
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.loadPercentage && item.loadPercentage !== ' ') ||
          (item.efficiency && item.efficiency !== ' ') ||
          (item.currentPercentage && item.currentPercentage !== ' ') ||
          item.loadPercentage === 0 ||
          item.efficiency === 0 ||
          item.currentPercentage === 0
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="loadPercentage"
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                className={tempError ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: syncGeneratorPerformaceCurvesVariables.efficiency,
      dataIndex: 'efficiency',
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.loadPercentage && item.loadPercentage !== ' ') ||
          (item.efficiency && item.efficiency !== ' ') ||
          (item.currentPercentage && item.currentPercentage !== ' ') ||
          item.loadPercentage === 0 ||
          item.efficiency === 0 ||
          item.currentPercentage === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="efficiency"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: syncGeneratorPerformaceCurvesVariables.currentPercentage,
      dataIndex: 'currentPercentage',
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.loadPercentage && item.loadPercentage !== ' ') ||
          (item.efficiency && item.efficiency !== ' ') ||
          (item.currentPercentage && item.currentPercentage !== ' ') ||
          item.loadPercentage === 0 ||
          item.efficiency === 0 ||
          item.currentPercentage === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="currentPercentage"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: syncGeneratorPerformaceCurvesVariables.actions,
      dataIndex: 'actions',
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = input => {
  if (input.length !== 15) {
    const tempError =
      (input.loadPercentage && input.loadPercentage !== ' ') ||
      input.loadPercentage === 0 ||
      (input.currentPercentage && input.currentPercentage !== ' ') ||
      input.currentPercentage === 0 ||
      (input.load && input.load !== ' ') ||
      input.load === 0 ||
      (input.efficiency && input.efficiency !== ' ') ||
      input.efficiency === 0 ||
      (input.powerFactor && input.powerFactor !== ' ') ||
      input.powerFactor === 0 ||
      (input.current && input.current !== ' ') ||
      input.current === 0 ||
      !input.eventListener;
    return [
      {
        render: () => {
          return (
            <div>
              <Input
                id="loadPercentage"
                autoComplete="off"
                type="number"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                value={input.loadPercentage}
                onChange={(value) => input.handleChangeTableData(value)}
                className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <div>
              <Input
                id="efficiency"
                autoComplete="off"
                type="number"
                onKeyDown={(e) => {
                  validateNumberNegative(e);
                }}
                value={input.efficiency}
                onChange={(value) => input.handleChangeTableData(value)}
                className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="currentPercentage"
              autoComplete="off"
              type="number"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              value={input.currentPercentage}
              onChange={(value) => input.handleChangeTableData(value)}
              className={tempError ? 'not-error' : 'error'}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = input => {
  return [
    {
      render: () => {
        const tempError =
          !input.loadPercentageUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && 'error'}`}>
            <select
              value={!input.loadPercentageUnit ? '' : input.loadPercentageUnit}
              id="loadPercentageUnit"
              onChange={(value) => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === 'Performance curve - Load') {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.efficiencyUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && 'error'}`}>
            <select
              id="efficiencyUnit"
              value={!input.efficiencyUnit ? '' : input.efficiencyUnit}
              onChange={(value) => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === 'Performance curve - Load') {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return (
          <div className={`custom-table-select`}>
            <select
              id="currentPercentageUnit"
              value={
                !input.currentPercentageUnit ? '' : input.currentPercentageUnit
              }
              onChange={(value) => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === 'Performance curve - Current') {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};

export const curveInput = (data, xAxisValue, yAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '250px', padding: '10px' },
      change: (e) => { config.changeX(e) },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '250px', padding: '10px' },
      change: (e) => { config.changeY(e) },
    },
  ]
}

export const graphDetails = {
  title: 'Curve Plot',
  requiredMessage: 'Mininum three data is required',
  efficiency: 'Efficiency',
  powerFactor: 'Power Factor',
  load: 'Load',
  current: 'Current'
};

export const curveVariables = {
  'Load%': 'loadPercentage',
  'Efficiency': 'efficiency',
};

export const powerFactorFormField = (config) => [
  {
    label: 'Curve Power Factor',
    key: 'powerFactor',
    type: 'textWithDrop',
    input: 'number',
    required: [true, 'Power Factor is required!'],
    value: config.value,
    dropValue: config.unit,
    dropDownKey: 'unitPowerFactor',
    dropDownData: ['%','%/100'],
    dropDownchange: (value) => {
      config.change('powerFactorUnit', value);
    },
    change: (value) => {
      config.change('powerFactor', value);
    },
  },
];