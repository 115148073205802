import {
  CONFIG_FETCH_SUCCESS,
  CONFIG_FETCH_ERROR,
  CONFIG_FETCH_PROGRESS,
  CONFIG_CLEAR_STATE,
} from "../Types/config.types";

const INITIAL_API_STATE = {
  configData: null,
  isConfigFetchError: false,
  isConfigFetchSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function ConfigReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIG_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CONFIG_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isConfigFetchError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          configData: action.payload,
          isConfigFetchSuccess: true,
        },
      };
    case CONFIG_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          configData: state.apiState.configData,
        },
      };
    default:
      return state;
  }
}
