export const VARIABLE_PROPERTY_FETCH_PROGRESS =
  "VARIABLE_PROPERTY_FETCH_PROGRESS";
export const VARIABLE_PROPERTY_FETCH_SUCCESS =
  "VARIABLE_PROPERTY_FETCH_SUCCESS";
export const VARIABLE_PROPERTY_FETCH_ERROR = "VARIABLE_PROPERTY_FETCH_ERROR";
export const VARIABLE_NAME_FETCH_PROGRESS = "VARIABLE_NAME_FETCH_PROGRESS";
export const VARIABLE_NAME_FETCH_SUCCESS = "VARIABLE_NAME_FETCH_SUCCESS";
export const VARIABLE_NAME_FETCH_ERROR = "VARIABLE_NAME_FETCH_ERROR";
export const OUTPUT_DATA_FETCH_PROGRESS = "OUTPUT_DATA_FETCH_PROGRESS";
export const OUTPUT_DATA_FETCH_ERROR = "OUTPUT_DATA_FETCH_ERROR";
export const OUTPUT_DATA_FETCH_SUCCESS = "OUTPUT_DATA_FETCH_SUCCESS";
export const OUTPUT_DATA_ADD_PROGRESS = "OUTPUT_DATA_ADD_PROGRESS";
export const OUTPUT_DATA_ADD_ERROR = "OUTPUT_DATA_ADD_ERROR";
export const OUTPUT_DATA_ADD_SUCCESS = "OUTPUT_DATA_ADD_SUCCESS";

export const CLEAR_STATE = "CLEAR_STATE";
