import React from "react";
import { UserManagementVariable } from "../../../constants/variables";
import { elementsGaurd } from "../../../gaurds";
import { ElementPermissions } from "../../../permissions";

export const filters = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  Reset: "RESET",
};

export const ListHeaderConfig = (config) => ({
  isFilterActive: config.isFilterActive,
  onSearch: config.search,
  filterdata: config.filterdata,
  order: config.order,
  sortOrder: config.sortOrder,
  dropDownData: Object.keys(filters),
  isSortActive: config.isSortActive,
  actions: elementsGaurd(
    [
      <button className="btn-default" onClick={() => config.addUser()}>
        {UserManagementVariable.ADD_USER}
      </button>,
    ],
    ElementPermissions.ADD_USER
  ),
});
