import { Icon, Input } from "antd";
import React from "react";
import { curvesColumnsName, curvesColumnsTitle } from "../../../../../../constants/variables";
import { validateNonZeroInteger, validatePositiveNumber } from "../../../../../../libs/validator";

const percentageConstant = ["%", "%/100"]

export const screwCompValidation = {
  minData: "Add at least 3 data points for both Operational and As Tested curve",
  resetFailed: "Failed to reset curve",
  moleWeight: "Mole weight should be greater than 0"
}
export const CapacityControlUnit = (config) => [
  {
    label: "Slide Valve Position",
    key: "dischargeSlideValvePosition",
    type: "dropdown",
    required: [true, ""],
    value:config.data.asTestedCurve,
    data: percentageConstant,
    change: (e) => {
      config.change(e);
    },
    disabled: !config.isCurveEditable,
  },
];

export const VolumetricRatioUnit = (config) => [
  {
    label: "Slide Valve Position",
    key: "suctionSlideValvePosition",
    type: "dropdown",
    required: [true, ""],
    value:config.data.asTestedCurve,
    data: percentageConstant,
    change: (e) => {
      config.change(e);
    },
    disabled: !config.isCurveEditable,
  },
];

export const AdiabaticEfficiencyUnit = (config) => [
  {
    label: "Slide Valve Position",
    key: "slideValvePosition",
    type: "dropdown",
    required: [true, ""],
    value:config.data.asTestedCurve["slideValvePosition"],
    data: percentageConstant,
    change: (e) => {
      config.change(e, "slideValvePosition");
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Adiabatic Efficiency",
    key: "adiabaticEfficiency",
    type: "dropdown",
    required: [true, ""],
    value:config.data.asTestedCurve["adiabaticEfficiency"],
    data: percentageConstant,
    change: (e) => {
      config.change(e, "adiabaticEfficiency");
    },
    disabled: !config.isCurveEditable,
  },
];

export const adiabaticEfficiencyCurvesTable = (config) => [
  {
    title: curvesColumnsTitle.slideValvePosition,
    dataIndex: curvesColumnsName.slideValvePosition,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.slideValvePosition}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.adiabaticEfficiency}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },

  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const adiabaticEfficiencyTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.slideValvePosition,
    dataIndex: curvesColumnsName.slideValvePosition,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.slideValvePosition}
        type="number"
        // defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.slideValvePosition]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.adiabaticEfficiency}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.adiabaticEfficiency] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={() => {
            actions.submit();
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];


export const capacityControlCurvesTable = (config) => [
  {
    title: curvesColumnsTitle.dischargeSlideValvePosition,
    dataIndex: curvesColumnsName.dischargeSlideValvePosition,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.dischargeSlideValvePosition}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.volumetricCapacityMultiplier,
    dataIndex: curvesColumnsName.volumetricCapacityMultiplier,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.volumetricCapacityMultiplier}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },

  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];


export const capacityControlTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.dischargeSlideValvePosition,
    dataIndex: curvesColumnsName.dischargeSlideValvePosition,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.dischargeSlideValvePosition}
        type="number"
        // defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.dischargeSlideValvePosition]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.volumetricCapacityMultiplier,
    dataIndex: curvesColumnsName.volumetricCapacityMultiplier,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.volumetricCapacityMultiplier}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.volumetricCapacityMultiplier] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={() => {
            actions.submit();
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const volumetricRatioCurvesTable = (config) => [
  {
    title: curvesColumnsTitle.suctionSlideValvePosition,
    dataIndex: curvesColumnsName.suctionSlideValvePosition,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.suctionSlideValvePosition}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.volumetricRatioMultiplier,
    dataIndex: curvesColumnsName.volumetricRatioMultiplier,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.volumetricRatioMultiplier}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },

  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const volumetricRatioAddCurve = (actions) => [
  {
    title: curvesColumnsTitle.suctionSlideValvePosition,
    dataIndex: curvesColumnsName.suctionSlideValvePosition,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.suctionSlideValvePosition}
        type="number"
        // defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.suctionSlideValvePosition]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.volumetricRatioMultiplier,
    dataIndex: curvesColumnsName.volumetricRatioMultiplier,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.volumetricRatioMultiplier}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.volumetricRatioMultiplier] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={() => {
            actions.submit();
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const LowMoleUnit = (unitData, config) => [
  {
    label: "Mole Weight",
    key: "moleWeight",
    type: "textWithDrop",
    input: "number",
    value: config.data.moleWeight,
    dropDownKey: "unit",
    dropDownData: unitData.moleWeight,
    dropDownDefaultValue: config.data.moleUnit,
    required: [true, ""],
    change: (e) => {
      config.change(e, "moleWeight");
    },
    dropDownchange: (e) => {
      config.change(e, "moleUnit");
    },
    onKeyDown: (e) => {
      validateNonZeroInteger(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Tip Speed",
    key: "tipSpeed",
    type: "dropdown",
    required: [true, ""],
    value:config.data.tipSpeed,
    data: unitData.tipSpeed,
    change: (e) => {
      config.change(e, "tipSpeed");
    },
    onKeyDown: (e) => {
      validatePositiveNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Adiabatic Efficiency",
    key: "adiabaticEffeciency",
    type: "dropdown",
    required: [true, ""],
    value:config.data.adiabaticEfficiency,
    data: unitData.adiabaticEffeciency,
    change: (e) => {
      config.change(e, "adiabaticEfficiency");
    },
    onKeyDown: (e) => {
      validatePositiveNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
];


export const lowMoleAddCurve = (actions) => [
  {
    title: curvesColumnsTitle.tipSpeed,
    dataIndex: curvesColumnsName.tipSpeed,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.tipSpeed}
        type="number"
        // defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.tipSpeed]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.adiabaticEfficiency}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.adiabaticEfficiency] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={() => {
            actions.submit();
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const lowMoleCurvesTable = (config) => [
  {
    title: curvesColumnsTitle.tipSpeed,
    dataIndex: curvesColumnsName.tipSpeed,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.tipSpeed}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.adiabaticEfficiency}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },

  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];



export const HighMoleUnit = (unitData, config) => [
  {
    label: "Mole Weight",
    key: "moleWeight",
    type: "textWithDrop",
    input: "number",
    value: config.data.moleWeight,
    dropDownKey: "unit",
    dropDownData: unitData.moleWeight,
    dropDownDefaultValue: config.data.moleUnit,
    required: [true, ""],
    change: (e) => {
      config.change(e, "moleWeight");
    },
    dropDownchange: (e) => {
      config.change(e, "moleUnit");
    },
    onKeyDown: (e) => {
      validateNonZeroInteger(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Tip Speed",
    key: "tipSpeed",
    type: "dropdown",
    required: [true, ""],
    value: config.data.tipSpeed,
    data: unitData.tipSpeed,
    change: (e) => {
      config.change(e, "tipSpeed");
    },
    onKeyDown: (e) => {
      validatePositiveNumber(e, e.target.value)
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Adiabatic Efficiency",
    key: "adiabaticEffeciency",
    type: "dropdown",
    required: [true, ""],
    value: config.data.adiabaticEfficiency,
    data: unitData.adiabaticEffeciency,
    change: (e) => {
      config.change(e, "adiabaticEfficiency");
    },
    onKeyDown: (e) => {
      validatePositiveNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
];


export const HighMoleAddCurve = (actions) => [
  {
    title: curvesColumnsTitle.tipSpeed,
    dataIndex: curvesColumnsName.tipSpeed,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.tipSpeed}
        type="number"
        // defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.tipSpeed]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.adiabaticEfficiency}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.adiabaticEfficiency] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={() => {
            actions.submit();
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const HighMoleCurvesTable = (config) => [
  {
    title: curvesColumnsTitle.tipSpeed,
    dataIndex: curvesColumnsName.tipSpeed,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.tipSpeed}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.adiabaticEfficiency}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validatePositiveNumber(e, e.target.value);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },

  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
