import React from 'react';
import './ExhaustLossesCurve.scss';
import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CustomTable from '../../../../../../../common/CustomTable/CustomTable';
import { saveCurvesData } from '../../../../../../../redux/actions/gasTurbine.action';
import {
  curvesTableColumns,
  curvesTableAddRow,
  ExhaustDPCurveFormField,
  curvesLabel,
  graphDetails,
  createCurvesPayload,
  curveInput,
  curveVariables,
} from './ExhaustLossesCurve.Constants';
import Notification, {
  Types,
} from '../../../../../../../common/Notification/Notification';
import FormFields from '../../../../../../../common/FormFields/FormFields';
import ConfirmModal from '../../../../../../../common/Modal/Modal';
import { ButtonVariables } from '../../../../../../../constants/variables';
import {
  NO_INTERNET,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  CurvesMessages,
} from '../../../../../../../constants/messages';
import {
  getDataUnits,
  clearDataUnitState,
} from '../../../../../../../redux/actions/dataUnit.action';
import Loader from '../../../../../../../common/Loader/Loader';
import Line from '../../../../../../../common/Graphs/Line/Line';

class ExhaustLossesCurve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preserveCurveData: {},
      editableRows: [],
      updatedFields: [],
      dirtyRows: [],
      formEdit: false,
      exhaustDPCurves: [],
      addFieldValue: {
        dp: '',
        power_corr: '',
        hr_corr: '',
      },
      isError: {
        dp: false,
        power_corr: false,
        hr_corr: false,
      },
      unitData: [],
      infoVisible: false,
      visibleGraph: false,
      yAxisValue: '',
      unitMapper: {},
    };
  }
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  componentDidMount() {
    const { unitData } = this.props.dataUnitState.apiState;
    const { configData } = this.props;
    if (
      configData &&
      configData.curvesData &&
      configData.curvesData.curvesData
    ) {
      const { exhaustDpCurve } = configData.curvesData.curvesData;
      if (exhaustDpCurve) {
        this.setCurveData(exhaustDpCurve);
      }
    }
    this.setState({
      unitData: unitData && unitData.items ? unitData.items : [],
    });
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { addExhaustLossesCurve } = this.props.gasTurbineReducer;

    if (
      addExhaustLossesCurve.isSuccess &&
      prevProps.gasTurbineReducer.addExhaustLossesCurve.isSuccess !==
        addExhaustLossesCurve.isSuccess
    ) {
      this.disableEditForm();
      Notification.show(Types.Success, CurvesMessages.ADDED);
    }
    if (
      addExhaustLossesCurve.isError &&
      prevProps.gasTurbineReducer.addExhaustLossesCurve.isError !==
        addExhaustLossesCurve.isError
    ) {
      Notification.show(Types.Error);
    }
  }

  setCurveData(exhaustDPCurves) {
    if (exhaustDPCurves) {
      const { data, dataUnit } = exhaustDPCurves;
      const formData = {
        exhaustDP: dataUnit?.dp,
      };

      this.setState({
        exhaustDPCurves: [...data],
      });
      this.props.form.setFieldsValue(formData, () => {
        this.disableEditForm();
      });
    }
  }

  validateFields(values) {
    let status = true;
    const { isError } = this.state;
    const temp = { ...isError };
    Object.keys(temp).forEach((item) => {
      if (
        (!values[item] && values[item] !== 0) ||
        (values[item] &&
          (values[item].length === 0 || values[item].trim().length === 0))
      ) {
        temp[item] = true;
        status = false;
      } else {
        temp[item] = false;
      }
    });
    this.setState({ isError: temp });

    return status;
  }

  addRow() {
    if (this.validateFields(this.state.addFieldValue)) {
      const { addFieldValue } = this.state;
      const tableData = this.state.exhaustDPCurves;
      const data = [...tableData, { ...addFieldValue }];
      this.setState({
        exhaustDPCurves: data,
        addFieldValue: {
          ...this.state.addFieldValue,
          dp: '',
          power_corr: '',
          hr_corr: '',
        },
      });
    } else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
  }

  getGraphData() {
    if (this.state.exhaustDPCurves.length > 0) {
      const { yAxisValue } = this.state;
      const exhaustLossesCurve = this.state.exhaustDPCurves.map((item) => {
        return [item.dp, item[yAxisValue]];
      });
      return { exhaustLossesCurve };
    }
  }

  onAddFieldChange(row, col, field) {
    const { addFieldValue } = this.state;
    const temp = addFieldValue;
    temp[field.name] =
      parseFloat(field.value) === 0
        ? parseFloat(field.value)
        : field.value.replace(/^0+/, '');
    this.setState({ addFieldValue: temp }, () => {
      this.validateFields(this.state.addFieldValue);
      this.enableEditForm();
    });
  }

  onEditFieldChange(row, col, field) {
    const tableData = [...this.state.exhaustDPCurves];

    const temp = { ...tableData[row] };
    temp[field.name] =
      parseFloat(field.value) === 0
        ? `${parseFloat(field.value)}`
        : field.value.replace(/^0+/, '');
    const tempCurveData = [...tableData];
    tempCurveData[row] = { ...temp };

    this.setState(
      {
        exhaustDPCurves: tempCurveData,
      },
      () => {
        this.setDirtyRows(row);
      }
    );
  }

  setDirtyRows(row) {
    const { dirtyRows } = this.state;
    const tableData = _.cloneDeep(this.state.exhaustDPCurves);
    const tableDataRow = tableData[row];
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    if (
      !tableDataRow.dp.toString() ||
      !tableDataRow.dp.toString().length ||
      !tableDataRow.power_corr.toString() ||
      !tableDataRow.power_corr.toString().length ||
      !tableDataRow.hr_corr.toString() ||
      !tableDataRow.hr_corr.toString().length
    ) {
      tempDirtyRows.push(row);
    } else {
      tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    }
    this.setState({
      dirtyRows: tempDirtyRows,
    });
  }

  edit(row) {
    const { updatedFields, editableRows } = this.state;
    const tableData = _.cloneDeep(this.state.exhaustDPCurves);
    const updatedTemp = _.cloneDeep(updatedFields);
    const tempEditRows = _.cloneDeep(editableRows);
    updatedTemp[row] = _.cloneDeep(tableData[row]);
    tempEditRows.push(row);
    this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
        editableRows: tempEditRows,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row) {
    const { updatedFields, dirtyRows, editableRows } = this.state;
    const tempTableData = _.cloneDeep(this.state.exhaustDPCurves);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData[row] = _.cloneDeep(tempupdatedField[row]);
    tempEditRows = tempEditRows.filter((e) => e !== row);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempupdatedField[row] = undefined;
    this.setState({
      exhaustDPCurves: tempTableData,
      dirtyRows: tempDirtyRows,
      updatedFields: tempupdatedField,
      editableRows: tempEditRows,
    });
  }

  deleteRow(row) {
    const { dirtyRows, updatedFields, editableRows } = this.state;
    const tempTableData = _.cloneDeep(this.state.exhaustDPCurves);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData.splice(row, 1);
    tempupdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempEditRows = tempEditRows.filter((e) => e !== row);
    this.setState(
      {
        exhaustDPCurves: tempTableData,
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
        editableRows: tempEditRows,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  save() {
    const {
      location: { state },
    } = this.props;
    const { saveCurvesData } = this.props;
    const { exhaustDPCurves } = this.state;
    this.props.form.validateFields((err, values) => {
      if (err) {
        throw err;
      }
      const lengthCheck = this.state.dirtyRows.length === 0;
      const curveLength = exhaustDPCurves.length >= 1;
      if (!lengthCheck) {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
        return;
      }
      if (!curveLength) {
        Notification.show(Types.Info, CurvesMessages.MIN_DATA);
        return;
      }
      const payload = createCurvesPayload(values, exhaustDPCurves);
      if (this.handleNetwork() && state.componentData) {
        saveCurvesData(state.componentData.info._id, payload);
      }
    });
  }

  clearDirtyForm() {
    this.setState({
      editableRows: [],
      updatedFields: [],
      dirtyRows: [],
      formEdit: false,
      addFieldValue: {
        dp: '',
        power_corr: '',
        hr_corr: '',
      },
      isError: {
        dp: false,
        power_corr: false,
        hr_corr: false,
      },
    });
  }

  reset() {
    this.setState({
      visible: true,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { configData } = this.props;
    const { exhaustDpCurve } = configData.curvesData.curvesData;
    this.setState(
      {
        visible: false,
      },
      () => {
        exhaustDpCurve && this.setCurveData(exhaustDpCurve);
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  onChangeSelect(value, key) {
    this.enableEditForm();
  }
  renderGraphModal() {
    if (this.state.visibleGraph) {
      const { yAxisValue } = this.state;
      const { exhaustLossesCurve } = this.getGraphData();
      return (
        <Modal
          title={graphDetails.title}
          visible={this.state.visibleGraph}
          onOk={() => this.handleOkGraph()}
          onCancel={() => this.handleOkGraph()}
          footer={null}
          className="curve-modal"
        >
          <div>
            <button
              className="btn-default btn-small"
              float="right"
              onClick={() => {
                this.showGraph();
              }}
            >
              BACK
            </button>
          </div>
          <div className="curve-plot">
            <div style={{ width: 500 }}>
              <Line
                graphData={exhaustLossesCurve}
                xAxis={graphDetails.xAxis}
                yAxis={graphDetails[yAxisValue]}
              />
              <span>{graphDetails.exhaustLossesCurve}</span>
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  }

  cancelModal() {
    this.setState({
      yAxisValue: '',
    });
    this.setState({ visibleModal: false });
  }

  handleOkGraph = () => {
    this.setState({ visibleGraph: false });
  };

  onChangeYAxes = (value) => {
    this.setState({ yAxisValue: value });
  };

  onSelectAxes() {
    const { yAxisValue } = this.state;
    const yAxisTag = curveVariables[yAxisValue];
    this.setState({
      yAxisValue: yAxisTag,
      visibleModal: false,
      visibleGraph: true,
    });
  }

  renderCurveInputModal() {
    if (this.state.visibleModal) {
      const data = ['Power Correction', 'Heat Rate Correction'];
      const { yAxisValue } = this.state;
      return (
        <Modal
          title={'Curve Input'}
          visible={this.state.visibleModal}
          onCancel={() => this.cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(data, yAxisValue, {
              changeY: (e) => {
                this.onChangeYAxes(e);
              },
            })}
            form={this.props.form}
          />
          {
            <div className="ok-button">
              <button
                className="btn-default btn-small"
                onClick={() => {
                  this.onSelectAxes();
                }}
                disabled={yAxisValue === ''}
              >
                OK
              </button>
            </div>
          }
        </Modal>
      );
    }
  }

  showGraph() {
    if (this.state.exhaustDPCurves.length >= 1) {
      this.setState({
        yAxisValue: '',
        visibleGraph: false,
        visibleModal: true,
      });
    } else {
      Notification.show(Types.Info, graphDetails.requiredMessage);
    }
  }

  renderExhaustDPCurve() {
    return (
      <div>
        <div className="heading-table">
          <div className="type-heading" style={{ width: '100%' }}>
            {curvesLabel.exhaustDPCurve}
          </div>
        </div>
        <div>
          <FormFields
            formFields={ExhaustDPCurveFormField(this.state.unitData, {
              change: (e, key) => {
                this.onChangeSelect(e, key);
              },
              isCurveEditable: this.props.isSchematicEditable,
            })}
            form={this.props.form}
          />
        </div>
        <div className="table-curve">
          <CustomTable
            showHeader
            columns={curvesTableColumns({
              isCurveEditable: this.props.isSchematicEditable,
              onChange: (row, col, field) => {
                this.onEditFieldChange(row, col, field);
              },
              edit: (row) => {
                this.edit(row);
              },
              deleteRow: (row) => {
                this.deleteRow(row);
              },
              undo: (row) => {
                this.undo(row);
              },
              process: this.props.process,
            })}
            editableRow={this.state.editableRows}
            data={this.state.exhaustDPCurves}
            renderAddRow={
              this.props.isSchematicEditable
                ? this.state.exhaustDPCurves.length < 15
                  ? curvesTableAddRow({
                      onChange: (row, col, field) => {
                        this.onAddFieldChange(row, col, field);
                      },
                      submit: (e) => {
                        this.addRow();
                      },
                      isError: this.state.isError,
                    })
                  : null
                : null
            }
            addFieldValues={this.state.addFieldValue}
          />
        </div>
      </div>
    );
  }

  render() {
    const dataUnitLoading = this.props.dataUnitState.loading;
    const { gasTurbineReducer } = this.props;
    return (
      <div className="Curves">
        <div className="CurvesInner">
          {dataUnitLoading || gasTurbineReducer.isLoading ? <Loader /> : null}
          <div className="curves-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.showGraph();
              }}
            >
              View Curve Plot
            </button>
          </div>
          {this.renderExhaustDPCurve()}

          {this.state.formEdit && this.props.isSchematicEditable ? (
            <div className="config-bottom-buttons">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  this.reset();
                }}
              >
                {ButtonVariables.RESET}
              </button>
              <button
                className="btn-default"
                onClick={() => {
                  this.save();
                }}
              >
                {ButtonVariables.SAVE}
              </button>
            </div>
          ) : null}
          {this.renderModal()}
          {this.renderGraphModal()}
          {this.renderCurveInputModal()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dataUnitState: state.DataUnitReducer,
  gasTurbineReducer: state.gasTurbineReducer,
  network: state.NetworkReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getDataUnits,
  clearDataUnitState,
  saveCurvesData: (componentId, payload) =>
    dispatch(saveCurvesData(componentId, payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(ExhaustLossesCurve))
);
