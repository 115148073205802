import {
  CLEAR_STATE,
  ADD_BYPASS_VALVE_PROGRESS,
  ADD_BYPASS_VALVE_SUCCESS,
  ADD_BYPASS_VALVE_ERROR,
  GET_EQUIPMENT_TYPE_FETCH_PROGRESS,
  GET_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_PROGRESS,
  GET_MANUFACTURER_FETCH_SUCCESS,
} from "../Types/bypass.types";

const INITIAL_API_STATE = {
  isBypassValveAddedSuccess: false,
  isSuccessGetEquipmentType: false,
  isSuccessGetManufacturer: false,
  isErrorGetEquipmentType: false,
  isErrorGetManufacturer: false,
  bypassData: null,
  equipmentTypeData: null,
  manufacturerData: null,
  isError: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function BypassReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_BYPASS_VALVE_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case ADD_BYPASS_VALVE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          bypassData: action.payload,
          isBypassValveAddedSuccess: true,
        },
      };

    case ADD_BYPASS_VALVE_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetEquipmentType: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetEquipmentType: true,
          equipmentTypeData: action.payload,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };
    case GET_MANUFACTURER_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetManufacturer: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_MANUFACTURER_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetManufacturer: true,
          manufacturerData: action.payload,
        },
      };

    case GET_MANUFACTURER_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          bypassData: state.apiState.bypassData,
        },
      };
    default:
      return state;
  }
}
