import React from "react";
// TODO: change variables as per camel case
export const Name = {
  regx: /^[a-zA-Z0-9 &/.()'‘’,-]*$/,
  message: "Only Letter, Number, Space and & / . ( ) - , ' are allowed",
};

export const Code = {
  regx: /^[a-zA-Z0-9 #/.'‘’,-]*$/,
  message: "Only Letter, Number, Space and # / . - , ' are allowed",
};

export const Address = {
  regx: /^[a-zA-Z0-9 #;:/.'‘’,-]*$/,
  message: "Only Letter, Number, Space and # / : ; . - , ' are allowed",
};

export const Zip = {
  regx: /^[a-zA-Z0-9 -]*$/,
  message: "Only Letter, Number and Space are allowed",
};

export const Letters = {
  regx: /^[a-zA-Z ]*$/,
  message: "Only Letters and space are allowed",
};

export const Email = {
  regx: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, // eslint-disable-line
  message: "Please enter a vaild email",
};

export const Mobile = {
  regx: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, // eslint-disable-line
  message: "Please enter a vaild mobile number",
};

export const Phone = {
  regx: /^([+]{0,1}\d)*([-]{0,1}(\d))*$/,
  message: "Please enter a vaild phone number",
};
export const Password = {
  regx: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[ !"#\$%&'\(\)\*\+,-\.\/:;<=>\?@\[\]\^_`{\|}~])[a-zA-Z0-9 !"#\$%&'\(\)\*\+,-\.\/:;<=>\?@\[\]\^_`{\|}~]{8,256}$/, // eslint-disable-line
  message: <span>Invalid Password</span>,
};

export const customName = {
  regx: /^[a-zA-Z &/.()'‘’,-]*$/,
  message: "Only Letter Space and & / . ( ) - , ' are allowed",
};

export const Title = {
  regx: /^(\w+\s)*\w+$/,
  message: "Only Letter, Number and space between are allowed",
};

export const NegativeNumber = /^-(\d*(\.\d*)?)$/;

export const PositiveNumber = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;

export const NaturalNumber = /^0*([1-9]\d*)$/;

export const FloatNumber = /^(-)?(\d+)(\d*(\.\d*)?)$/;

export const positiveInteger = /^([1-9]\d*)$/;

export const NonNegativeNumber = /^0*[0-9][1-9]*(\.[0-9]+)?/;

export const frameSizePattern = /^[-/\\\w\s]*$/;

export const NegativeNumberMessage = "Enter only valid numbers";

export const NumberMessage = "Enter only valid positive numbers";
