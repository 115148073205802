import { combineReducers } from "redux";
import LoginReducer from "./login.reducer";
import ForgotPasswordReducer from "./forgotPassword.reducer";
import unitsProperty from "./unitsProperty.reducer";
import ClientReducer from "./client.reducer";
import FacilityReducer from "./facility.reducer";
import StateReducer from "./state.reducer";
import AssetReducer from "./asset.reducer";
import SystemReducer from "./system.reducer";
import NetworkReducer from "./network.reducer";
import EquipmentReducer from "./equipment.reducer";
import SchematicReducer from "./schematic.reducer";
import ConfigReducer from "./config.reducer";
import inputDataReducer from "./inputData.reducer";
import outputDataReducer from "./outputData.reducer";
import BypassReducer from "./bypass.reducer";
import DesignReducer from "./design.reducer";
import FluidCompositionReducer from "./fluidComposition.reducer";
import CurvesReducer from "./curves.reducer";
import RunnerDataReducer from "./runnerData.reducer";
import DataUnitReducer from "./dataUnit.reducer";
import AlertReducer from "./Alerts.reducer";
import calculatedDataReducer from "./calculatedData.reducer";
import AlertDetailReducer from "./alertDetails.reducer";
import userManagementReducer from "./UserManagement.reducer";
import AlertActionReducer from "./AlertAction.reducer";
import flowMeasurementReducer from "./flowMeasurement.reducer";
import AddNewCardReducer from "../AddNewCard/addNewCard.reducer";
import CardTypesReducer from "../AddNewCard/cardType.reducer";
import DeployedComponentReducer from "../AddNewCard/deployedComponent.reducer";
import DeployedEquipmentReducer from "../AddNewCard/deployedEquipment.reducer";
import TagsByComponentReducer from "../AddNewCard/tagsByComponent.reducer";
import TagsByCardTypeReducer from "../AddNewCard/tagsByCardType.reducer";
import KpiByLevelReducer from "../AddNewCard/kpiByLevel.reducer";
import PlotsReducer from "./plots.reducer";
import DeleteKpiByIdReducer from "../AddNewCard/deletekpiById.reducer";
import UpdateKpiByIdReducer from "../AddNewCard/updatekpiById.reducer";
import streamReducer from "./Stream.reducer";
import CompressorReducer from "./compressorCurves.reducer";
import commonPlotsReducer from "./commonPlots.reducer";
import surgeLineReducer from "./SurgeLines.reducer";
import piTagReducer from "./piTagUpload.reducer";
import assetEquipmentReducer from "./assetEquipment.reducer";
import systemSchematicReducer from "./systemSchematic.reducer";
import AlertPlotReducer from "./alertPlot.reducer";
import AlertLabelReducer from "./alertLabels.reducer";
import AlertAssigneesReducer from "./alertAssignees.reducer";
import EventDetailsReducer from "./eventDetails.reducer";
import MotorReducer from "./motor.reducer";
import DiscussionThreadReducer from "./discussionThread.reducer";
import curveReducer from "./curve.reducer";
import formEditReducer from "./formEdit.reducer";
import MlAlgorithmReducer from "./mlAlgorithm.reducer";
import liveDataReducer from "./liveData.reducer";
import RunTagsReducer from "./runTags.reducer";
import settingReducer from "./settings.reducer";
import trendsReducer from "./trends.reducer";
import plantTimeReducer from "./plantTime.reducer";
import synchronousMotorReducer from "./synchronousMotor.reducer";
import gasTurbineReducer from "./gasTurbine.reducer";
import PanelTypeReducer from "../AddNewCard/panelType.reducer";
import KPIByPanelReducer from '../AddNewCard/kpiByPanel.reducer';
import batchTimeReducer from './batchTime.reducer';
import synchronousGeneratorReducer from './synchronousGenerator.reducer';
import steamTurbineReducer from './steamTurbine.reducer';
import controlValveReducer from "./controlValve.reducer";
import reciprocatingCompressorReducer from "./reciprocatingCompressor.reducer";
import RecipCompCurveState from "./compressorCurves.reducer";
import AlertPlotKPIReducer from "./alertPlotKPI.reducer";
import PIMComponentReducer from "./PIMComponent.reducer";
import HydraulicVariableSpeedGearComponentReducer from "./hydraulicVariableSpeedGear.reducer";
import SQLComponentReducer from "./sqlComponent.reducer";
import MaterialEnvironmentReducer from "./materialEnvironments.reducer";
import screwCompressorReducer from "./screwCompressor.reducer";
import transientDataReducer from './transientData.reducer';
import transientDataCalcReducer from './transientCalculationData.reducer';

import iowTagsReducer from "./iowTags.reducer";

const reducers = {
  curveReducer,
  formEditReducer,
  LoginReducer,
  unitsProperty,
  ClientReducer,
  FacilityReducer,
  StateReducer,
  AssetReducer,
  SystemReducer,
  NetworkReducer,
  EquipmentReducer,
  SchematicReducer,
  ConfigReducer,
  inputDataReducer,
  outputDataReducer,
  BypassReducer,
  DesignReducer,
  FluidCompositionReducer,
  CurvesReducer,
  RunnerDataReducer,
  DataUnitReducer,
  AddNewCardReducer,
  AlertDetailReducer,
  userManagementReducer,
  flowMeasurementReducer,
  CardTypesReducer,
  DeployedComponentReducer,
  DeployedEquipmentReducer,
  TagsByComponentReducer,
  KpiByLevelReducer,
  PlotsReducer,
  TagsByCardTypeReducer,
  DeleteKpiByIdReducer,
  AlertReducer,
  calculatedDataReducer,
  AlertActionReducer,
  commonPlotsReducer,
  UpdateKpiByIdReducer,
  surgeLineReducer,
  assetEquipmentReducer,
  CompressorReducer,
  piTagReducer,
  streamReducer,
  systemSchematicReducer,
  ForgotPasswordReducer,
  AlertLabelReducer,
  AlertAssigneesReducer,
  EventDetailsReducer,
  AlertPlotReducer,
  MotorReducer,
  DiscussionThreadReducer,
  MlAlgorithmReducer,
  liveDataReducer,
  RunTagsReducer,
  settingReducer,
  synchronousMotorReducer,
  gasTurbineReducer,
  trendsReducer,
  plantTimeReducer,
  PanelTypeReducer,
  KPIByPanelReducer,
  batchTimeReducer,
  steamTurbineReducer,
  synchronousGeneratorReducer,
  controlValveReducer,
  reciprocatingCompressorReducer,
  RecipCompCurveState,
  AlertPlotKPIReducer,
  PIMComponentReducer,
  MaterialEnvironmentReducer,
  screwCompressorReducer,
  transientDataReducer,
  iowTagsReducer,
  transientDataCalcReducer,
  HydraulicVariableSpeedGearComponentReducer,
  SQLComponentReducer,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  const appState = state;
  return appReducer(appState, action);
};

export default function makeRootReducer() {
  return rootReducer;
}
