import {
  SCHEMATIC_COMPONENT_CLEAR_STATE,
  SCHEMATIC_COMPONENT_FETCH_ERROR,
  SCHEMATIC_COMPONENT_FETCH_PROGRESS,
  CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  GET_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS,
  CREATE_SCHEMATIC_FETCH_SUCCESS,
  EDIT_SCHEMATIC_FETCH_SUCCESS,
  GET_SCHEMATIC_FETCH_SUCCESS,
  SCHEMATIC_FETCH_ERROR,
  SCHEMATIC_FETCH_PROGRESS,
  VALIDATE_SCHEMATIC_FETCH_SUCCESS,
  UN_DEPLOY_EQUIPMENT_SUCCESS,
  UN_DEPLOY_EQUIPMENT_PROGRESS,
  UN_DEPLOY_EQUIPMENT_ERROR,
  UN_DEPLOY_EQUIPMENT_RESET,
} from "../Types/schematic.types";
import { API_ERROR_MSG } from "../../constants/messages";

const INITIAL_API_STATE = {
  schematicComponentData: null,
  schematicData: null,
  validateSchematicData: null,
  isErrorComponentSchematic: false,
  isErrorSchematic: false,
  isSuccessCreateSchematicComponentFetch: false,
  isSuccessGetSchematicComponentFetch: false,
  isSuccessEditSchematicComponentFetch: false,
  isSuccessDeleteSchematicComponentFetch: false,
  isSuccessCreateSchematicFetch: false,
  isSuccessGetSchematicFetch: false,
  isSuccessEditSchematicFetch: false,
  isSuccessValidateSchematicFetch: false,
  errorCode: null,
  message: null,
  isErrorUnDeploy: null,
  isSuccessunDeploy: null,
  dataUnDeploy: null,
  errorCodeUnDeploy: null,
  msgUnDeploy: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function SchematicReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SCHEMATIC_COMPONENT_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          schematicComponentData: state.apiState.schematicComponentData,
          schematicData: state.apiState.schematicData,
          validateSchematicData: state.apiState.validateSchematicData,
        },
      };
    case SCHEMATIC_COMPONENT_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case SCHEMATIC_COMPONENT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          isErrorComponentSchematic: true,
        },
      };
    case CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessCreateSchematicComponentFetch: true,
          schematicComponentData: action.payload,
        },
      };
    case GET_SCHEMATIC_COMPONENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessGetSchematicComponentFetch: true,
          schematicComponentData: action.payload,
        },
      };
    case EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessEditSchematicComponentFetch: true,
          schematicComponentData: action.payload,
        },
      };
    case DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessDeleteSchematicComponentFetch: true,
          schematicComponentData: action.payload,
        },
      };
    case SCHEMATIC_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case SCHEMATIC_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
          isErrorSchematic: true,
        },
      };
    case CREATE_SCHEMATIC_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessCreateSchematicFetch: true,
          schematicData: action.payload,
        },
      };
    case VALIDATE_SCHEMATIC_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessValidateSchematicFetch: true,
          validateSchematicData: action.payload,
        },
      };
    case GET_SCHEMATIC_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessGetSchematicFetch: true,
          schematicData: action.payload,
        },
      };
    case EDIT_SCHEMATIC_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isSuccessEditSchematicFetch: true,
          schematicData: action.payload,
        },
      };

    case UN_DEPLOY_EQUIPMENT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case UN_DEPLOY_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          isSuccessunDeploy: true,
          isErrorUnDeploy: false,
        },
      };
    case UN_DEPLOY_EQUIPMENT_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          isSuccessunDeploy: false,
          isErrorUnDeploy: true,
          errorCodeUnDeploy:
            action.payload.response && action.payload.response.body.code,
          msgUnDeploy:
            action.payload.response &&
            action.payload.response.body.code === "INVALID_INPUT"
              ? API_ERROR_MSG.INVALID_INPUT
              :(action.payload.response &&
                action.payload.response.body.code === "NOT_ALLOWED" ? API_ERROR_MSG.NOT_ALLOWED  : API_ERROR_MSG.INTERNAL) ,
        },
      };

    case UN_DEPLOY_EQUIPMENT_RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
