import {
  CURVES_ADD_EDIT_ERROR,
  CURVES_ADD_EDIT_PROGRESS,
  CURVES_ADD_EDIT_SUCCESS,
  CURVES_CLEAR_STATE,
} from "../Types/curves.types";

const INITIAL_API_STATE = {
  data: null,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function CurvesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CURVES_ADD_EDIT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CURVES_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case CURVES_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: action.payload,
          isSuccess: true,
          isError: false,
        },
      };
    case CURVES_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: false,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
}
