import {
  flowCoefficient,
  headCoefficient,
  workInputCoefficient,
} from "../../../../../libs/coefficient";
import { COMPONENT } from "./Curves.constants";

/**
 *
 * @param {String} name Component Name
 * @param {Array} unitMapper this.props.dataUnitState.apiState
 * @param {Array} runnerData
 * @param {Array} curvesData
 */
export const calculateCurvesData = (
  name,
  unitMapper,
  runnerData,
  curvesData
) => {
  const type = runnerData.length ? runnerData[0].type : undefined;
  const diameters = runnerData.length
    ? runnerData.map((item) => {
      const toSI = unitMapper.Diameter[item.unit];
      // eslint-disable-next-line
        const valueInSI = eval(
        toSI.replace("value", parseFloat(item.diameter))
      );
      return valueInSI;
    })
    : [];

  if (name === COMPONENT.EXPANDER) {
    let speed;
    let volumetricFlow;
    let headDrop;
    let efficiency;

    const asTestedCurves = curvesData.asTestedCurves.map((item) => {
      speed = item.speed && item.speed.siValue ? item.speed.siValue : 0;
      volumetricFlow =
        item.volumetricFlow && item.volumetricFlow.siValue
          ? item.volumetricFlow.siValue
          : 0;
      headDrop =
        item.headDrop && item.headDrop.siValue ? item.headDrop.siValue : 0;
      efficiency =
        item.efficiency && item.efficiency.siValue
          ? item.efficiency.siValue
          : 0;

      item.flowCoefficient.value = flowCoefficient(
        volumetricFlow,
        speed,
        diameters[0],
        type
      );
      item.headCoefficient.value = headCoefficient(headDrop, speed, diameters);
      item.workInput.value = workInputCoefficient(
        efficiency,
        item.headCoefficient.value
      );

      return {
        _id: item._id,
        application: item.application,
        speed: item.speed,
        volumetricFlow: item.volumetricFlow,
        headDrop: item.headDrop,
        efficiency: item.efficiency,
        flowCoefficient: item.flowCoefficient,
        headCoefficient: item.headCoefficient,
        workInput: item.workInput,
      };
    });

    const operationalBaseLineCurves = curvesData.operationalBaseLineCurves.map(
      (item) => {
        speed = item.speed && item.speed.siValue ? item.speed.siValue : 0;
        volumetricFlow =
          item.volumetricFlow && item.volumetricFlow.siValue
            ? item.volumetricFlow.siValue
            : 0;
        headDrop =
          item.headDrop && item.headDrop.siValue ? item.headDrop.siValue : 0;
        efficiency =
          item.efficiency && item.efficiency.siValue
            ? item.efficiency.siValue
            : 0;

        item.flowCoefficient.value = flowCoefficient(
          volumetricFlow,
          speed,
          diameters[0],
          type
        );
        item.headCoefficient.value = headCoefficient(
          headDrop,
          speed,
          diameters
        );
        item.workInput.value = workInputCoefficient(
          efficiency,
          item.headCoefficient.value
        );

        return {
          _id: item._id,
          speed: item.speed,
          volumetricFlow: item.volumetricFlow,
          headDrop: item.headDrop,
          efficiency: item.efficiency,
          flowCoefficient: item.flowCoefficient,
          headCoefficient: item.headCoefficient,
          workInput: item.workInput,
        };
      }
    );

    return {
      _id: curvesData._id,
      application: curvesData.application,
      asTestedCurves,
      operationalBaseLineCurves,
    };
  }
  const curve =
    curvesData && curvesData.curvesData ? curvesData.curvesData : {};

  let res = {};

  Object.keys(curve).forEach((item) => {
    const speed = curve[item].asTestedCurves.speed.siValue || 0;
    const speedOperational =
      curve[item].operationalBaseLineCurves.speed.siValue || 0;

    const asTestedCurvesData = curve[item].asTestedCurves.data.map((val) => {
      const { volumetricFlowSIValue, headDropSIValue, efficiencySIValue } = val;

      const FlowCoefficient = volumetricFlowSIValue
        ? flowCoefficient(volumetricFlowSIValue, speed, diameters[0], type)
        : 0;
      const HeadCoefficient = headDropSIValue
        ? headCoefficient(headDropSIValue, speed, diameters)
        : 0;
      const WorkInput = efficiencySIValue
        ? workInputCoefficient(efficiencySIValue, HeadCoefficient)
        : 0;

      return {
        volumetricFlow: val.volumetricFlow,
        headDrop: val.headDrop,
        efficiency: val.efficiency,
        volumetricFlowSIValue,
        headDropSIValue,
        efficiencySIValue,
        flowCoefficient: FlowCoefficient,
        headCoefficient: HeadCoefficient,
        workInput: WorkInput,
      };
    });

    const operationalBaseLineCurvesData = curve[
      item
    ].operationalBaseLineCurves.data.map((val) => {
      const { volumetricFlowSIValue, headDropSIValue, efficiencySIValue } = val;

      const FlowCoefficient = volumetricFlowSIValue
        ? flowCoefficient(
          volumetricFlowSIValue,
          speedOperational,
          diameters[0],
          type
        )
        : 0;
      const HeadCoefficient = headDropSIValue
        ? headCoefficient(headDropSIValue, speedOperational, diameters)
        : 0;
      const WorkInput = efficiencySIValue
        ? workInputCoefficient(efficiencySIValue, HeadCoefficient)
        : 0;

      return {
        volumetricFlow: val.volumetricFlow,
        headDrop: val.headDrop,
        efficiency: val.efficiency,
        volumetricFlowSIValue,
        headDropSIValue,
        efficiencySIValue,
        flowCoefficient: FlowCoefficient,
        headCoefficient: HeadCoefficient,
        workInput: WorkInput,
      };
    });

    res = {
      ...res,
      [item]: {
        asTestedCurves: {
          speed: curve[item].asTestedCurves.speed,
          dataUnit: curve[item].asTestedCurves.dataUnit,
          data: asTestedCurvesData,
          identifier: curve[item].asTestedCurves.identifier
        },
        operationalBaseLineCurves: {
          speed: curve[item].operationalBaseLineCurves.speed,
          dataUnit: curve[item].operationalBaseLineCurves.dataUnit,
          data: operationalBaseLineCurvesData,
          identifier: curve[item].operationalBaseLineCurves.identifier
        },
      },
    };
  });
  return res;
};
