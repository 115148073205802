export const curveTypes = ['Single', 'Multiple'];

export const curvesMapper = {
  curve1: 'Curve 1',
  curve2: 'Curve 2',
  curve3: 'Curve 3',
  curve4: 'Curve 4',
  curve5: 'Curve 5',
  curve6: 'Curve 6',
  curve7: 'Curve 7',
  curve8: 'Curve 8',
};

export const curveInput = (data, yAxisValue, xAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'X');
      },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'Y');
      },
    },
  ];
};

export const graphDetails = {
  title: 'Curve',
  xAxis: 'Suction Steam  Pressure',
  power_corr: 'Exhaust Flow Power correction factor',
  hr_corr: 'Exhaust Flow Heatrate correction factor',
  requiredMessage: 'Mininum three data is required',
  exhaustLossesCurve: 'Exhaust Losses Curve',
};

export const getCurvesGraphData = (
  curvesData,
  Xaxsis,
  Yaxsis,
  DataColumKeyChange
) => {
  const curveGraphData = curvesData.map((item) => {
    return [
      parseFloat(item[DataColumKeyChange[Xaxsis]]),
      parseFloat(item[DataColumKeyChange[Yaxsis]]),
    ];
  });
  return curveGraphData;
};

export const hvsgGetUnitsData = (curveData, DataColumKeyChange) => {
  const unitData = {};
  const dataItem = curveData[0];
  const DataColumkeys = Object.values(DataColumKeyChange);
  const keys = Object.keys(dataItem);
  keys.map((item) => {
    if (DataColumkeys.includes(item)) {
      unitData[item] = dataItem[item].unit;
    }
    return item;
  });
  return unitData;
};

export const hvsgCurveData = (curveData, DataColumKeyChange) => {
  const keys = Object.values(DataColumKeyChange);
  return curveData.map((item) => {
    let tempItem = {};
    keys.forEach((key) => {
      if (item[key] && item[key].design !== undefined ) {  
        tempItem[key] = item[key].design;
      }
    });
    return tempItem;
  });
};

export const createCurvesPayload = (
  curveId,
  curveTitleKey,
  unitsData,
  curvesData,
  curveType,
  identifierData,
  DataColumKeyChange,
  identifierKey,
  formValues
) => {
  let payload = {};
  const keys = Object.values(DataColumKeyChange);
  let curvePayload = curvesData.map((item) => {
    let tempItem = {};
    keys.forEach((key) => {
      tempItem[key] = {
        design: parseFloat(item[key]),
        unit: unitsData[key] ? unitsData[key] : formValues[curveTitleKey + key],
      };
    });
    return tempItem;
  });
  if (identifierKey) {
    const identifier = identifierData;
    identifier['identifierName'] = identifierKey;
    payload['identifier'] = identifier;
  }

  payload.curveType = curveType;
  payload.data = curvePayload;
  payload['curveId'] = curveId;

  let tempPayload = {};
  tempPayload[curveTitleKey] = payload;
  return tempPayload;
};
