import React from 'react';

const RenderHeader = (heading,padding) => {
    return (
        <>
            <div className='details_heading' style={padding?{paddingTop:padding,position:'absolute',top:'61px'}:heading === "Discussion Thread" && window.innerWidth<1400 ? {paddingTop:'0rem'}:{paddingTop:'0.5rem'}}>
                {heading}
            </div>
            <hr className='heading_underline' />
        </>
    )
}

export default RenderHeader