import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';

export default function Editor({
  setAlertComment,
  alertComment,
  alertInsights,
  selectedTab,
  setAlertInsights,
  makeAlertInsightsReadOnly,
  userRole
}) {
  let config = {}
  if (userRole === 'MechademyAdmin' || userRole === 'MechademyFacilityManager'){
    config = {
     askBeforePasteHTML: false,
     autofocus: true,
     buttons: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
     buttonsSM: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
     buttonsXS: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
     buttonsMD: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
     height:'auto',
     minHeight:'30vh',
     showCharsCounter: false,
     showWordsCounter: false,
     showXPathInStatusbar: false
      }
  }
  else{
    config = {
      autofocus: true,
      buttons: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
      buttonsSM: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
      buttonsXS: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
      buttonsMD: ['bold', 'italic', 'underline','fontsize','brush','link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
      readonly: makeAlertInsightsReadOnly && selectedTab === 1,
      activeButtonsInReadOnly: ['fullsize'],
      height:'auto',
      minHeight:'30vh',
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false
    };
  }
  
  return useMemo(
    () => (
      <>
        {selectedTab === 0 && (
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            <JoditEditor
              onChange={(content) => {
                setAlertComment(content);
              }}
              value={alertComment}
              config={config}
            />
          </div>
        )}
        {selectedTab === 1 && (
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            <JoditEditor
              onChange={(content) => {
                setAlertInsights(content);
              }}
              value={alertInsights}
              config={config}
            />
          </div>
        )}
      </>
    ),
    [selectedTab] // eslint-disable-line
  );
}
