import {
  GET_CALCULATED_DATA_ERROR,
  GET_CALCULATED_DATA_PROGRESS,
  GET_CALCULATED_DATA_SUCCESS,
  GET_VARIABLE_NAME_DATA_ERROR,
  GET_VARIABLE_NAME_DATA_PROGRESS,
  GET_VARIABLE_NAME_DATA_SUCCESS,
  ADD_CALCULATED_DATA_ERROR,
  ADD_CALCULATED_DATA_PROGRESS,
  ADD_CALCULATED_DATA_SUCCESS,
  CLEAR_STATE,
} from "../Types/calculatedData.types";
import { GETRequest, PUTRequest } from "../../api/request";

export function getVariableName(componentId) {
  return function (dispatch) {
    dispatch({ type: GET_VARIABLE_NAME_DATA_PROGRESS });
    GETRequest(`calculationvariable?componentId=${componentId}`)
      .then((res) => {
        dispatch({ type: GET_VARIABLE_NAME_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: GET_VARIABLE_NAME_DATA_ERROR, payload: err });
      });
  };
}

export function getCalculatedData(equipmentId, id) {
  return function (dispatch) {
    dispatch({ type: GET_CALCULATED_DATA_PROGRESS });
    GETRequest(`equipment/${equipmentId}/component/${id}/config/calculation`)
      .then((res) => {
        dispatch({ type: GET_CALCULATED_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: GET_CALCULATED_DATA_ERROR, payload: err });
      });
  };
}

export function addCalculatedData(payload, equipmentId, id) {
  return function (dispatch) {
    dispatch({ type: ADD_CALCULATED_DATA_PROGRESS });
    PUTRequest(
      `equipment/${equipmentId}/component/${id}/config/calculation`,
      payload
    )
      .then((res) => {
        dispatch({ type: ADD_CALCULATED_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_CALCULATED_DATA_ERROR, payload: err });
      });
  };
}

export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}
