import _ from "lodash";
import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import cardTypeMapper from "../../../constants/cardTypes";
import labels from "../graph.constants";

const monthMap = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        colors: [],
        legend: {
          position: "bottom",
        },
        chart: {
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {},
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Alert Count",
          },
        },
        fill: {
          opacity: 1,
        },
      },
      series: [],
    };
  }

  static getDerivedStateFromProps(props) {
    const arrayMonth = [];
    for (let i = 0; i < 12; i++) {
      const currentMonth = moment().subtract(i, "month").format("MM");
      arrayMonth.unshift({
        month: currentMonth,
        year: moment().subtract(i, "month").format("YYYY"),
      });
    }
    let categoryList = [];
    const { cardValue, cardType, viewDataAdmin } = props;
    if (cardValue && cardValue.data) {
      const { data } = cardValue;

      if (cardType === cardTypeMapper["Alert by status bar chart"]) {
        if (viewDataAdmin === "Mechademy") {
          categoryList = labels.statusTypeLabels.MechademyAdmin.labelConstants;
        } else {
          categoryList = labels.statusTypeLabels.ClientAdmin.labelConstants;
        }
        data.forEach((item) => {
          // find levels
          const levels = _.map(item.data, "status");
          const remainingLevel = _.difference(categoryList, levels);
          _.forEach(remainingLevel, (rl) => {
            item.data.push({ status: rl, count: 0, month: item._id.month });
          });
        });
        const categories = {
          categories: [],
          New: [],
          Forward: [],
          Close: [],
          Disable: [],
          Discard: [],
          Shelved: [],
          Acknowledged: [],
          shelvedNotDel: [],
        };
        _.forEach(arrayMonth, (arr) => {
          const valueIndex = _.findIndex(
            data,
            (d) => +d._id.month === +arr.month && +d._id.year === +arr.year
          );
          // if index exists
          if (valueIndex > -1) {
            categories.categories.push(monthMap[arr.month]);
            _.forEach(data[valueIndex].data, (lv) => {
              if (lv.status === "New") {
                categories.New.push(lv.count);
              } else if (lv.status === "Forward") {
                categories.Forward.push(lv.count);
              } else if (lv.status === "Close") {
                categories.Close.push(lv.count);
              } else if (lv.status === "Disable") {
                categories.Disable.push(lv.count);
              } else if (lv.status === "Discard") {
                categories.Discard.push(lv.count);
              } else if (lv.status === "Shelved") {
                categories.Shelved.push(lv.count);
              } else if (lv.status === "Acknowledged") {
                categories.Acknowledged.push(lv.count);
              } else if (lv.status === "Shelved Not Delivered") {
                categories.shelvedNotDel.push(lv.count);
              }
            });
          } else {
            categories.categories.push(monthMap[arr.month]);
            categories.New.push(0);
            categories.Forward.push(0);
            categories.Close.push(0);
            categories.Disable.push(0);
            categories.Discard.push(0);
            categories.Shelved.push(0);
            categories.Acknowledged.push(0);
            categories.shelvedNotDel.push(0);
          }
        });
        return {
          options: {
            dataLabels: {
              enabled: false,
            },
            colors: labels.statusTypeLabels.MechademyAdmin.colorConstants,
            legend: {
              position: "bottom",
              offsetY: -5,
              formatter(seriesName, SeriesOptions) {
                return [
                  `${seriesName} : ${
                    SeriesOptions.w.globals.seriesTotals[
                      SeriesOptions.seriesIndex
                    ]
                  }`,
                ];
              },
            },
            chart: {
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {},
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            xaxis: {
              categories: categories.categories,
            },
            yaxis: {
              title: {
                text: "Alert Count",
              },
            },
            fill: {
              opacity: 1,
            },
          },
          series:
            viewDataAdmin === "Mechademy"
              ? [
                {
                  name: "New",
                  data: categories.New,
                },
                {
                  name: "Forwarded",
                  data: categories.Forward,
                },
                {
                  name: "Closed",
                  data: categories.Close,
                },
                {
                  name: "Disabled",
                  data: categories.Disable,
                },
                {
                  name: "Discarded",
                  data: categories.Discard,
                },
                {
                  name: "Shelved",
                  data: categories.Shelved,
                },
              ]
              : [
                {
                  name: "New",
                  data: categories.New,
                },
                {
                  name: "Acknowledged",
                  data: categories.Acknowledged,
                },
                {
                  name: "Closed",
                  data: categories.Close,
                },
                {
                  name: "Shelved",
                  data: categories.Shelved,
                },
                {
                  name: "Shelved Not Delivered",
                  data: categories.shelvedNotDel,
                },
              ],
        };
      }
      categoryList = labels.priorityTypeLabels.labelConstants;
      data.forEach((item) => {
        // find levels
        const levels = _.map(item.data, "level");
        const remainingLevel = _.difference(categoryList, levels);
        _.forEach(remainingLevel, (rl) => {
          item.data.push({ level: rl, count: 0, month: item._id.month });
        });
      });
      const categories = {
        categories: [],
        High: [],
        Low: [],
        Medium: [],
        Unknown: [],
      };
      _.forEach(arrayMonth, (arr) => {
        const valueIndex = _.findIndex(
          data,
          (d) => +d._id.month === +arr.month && +d._id.year === +arr.year
        );
        // if index exists
        if (valueIndex > -1) {
          categories.categories.push(monthMap[arr.month]);
          _.forEach(data[valueIndex].data, (lv) => {
            if (lv.level === "High") {
              categories.High.push(lv.count);
            }
            if (lv.level === "Medium") {
              categories.Medium.push(lv.count);
            }
            if (lv.level === "Low") {
              categories.Low.push(lv.count);
            }
            if (lv.level === "Unknown") {
              categories.Unknown.push(lv.count);
            }
          });
        } else {
          categories.categories.push(monthMap[arr.month]);
          categories.High.push(0);
          categories.Medium.push(0);
          categories.Low.push(0);
          categories.Unknown.push(0);
        }
      });
      return {
        options: {
          dataLabels: {
            enabled: false,
          },
          colors: labels.priorityTypeLabels.colorConstants,
          legend: {
            position: "bottom",
            offsetY: -5,
            formatter(seriesName, SeriesOptions) {
              return [
                `${seriesName} : ${
                  SeriesOptions.w.globals.seriesTotals[
                    SeriesOptions.seriesIndex
                  ]
                }`,
              ];
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },

          xaxis: {
            categories: categories.categories,
          },
          yaxis: {
            title: {
              text: "Alert Count",
            },
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: "High",
            data: categories.High,
          },
          {
            name: "Medium",
            data: categories.Medium,
          },
          {
            name: "Low",
            data: categories.Low,
          },
          {
            name: "Unknown",
            data: categories.Unknown,
          },
        ],
      };
    }
    return null;
  }

  render() {
    const { options, series } = this.state;
    return (
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height="350"
        />
      </div>
    );
  }
}

export default BarChart;
