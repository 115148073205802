import React, { useEffect, useState, useCallback } from 'react';
import '../curves.scss';
import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CustomTable from '../../../../../../../common/CustomTable/CustomTable';
import {
  saveHVSGCurvesData,
  deleteHVSGCurvesData,
  clearDeleteCurvesState,
  clearUpdateCurveDataState,
} from '../../../../../../../redux/actions/hydraulicVariableSpeedGear.action';
import {
  curveInput,
  graphDetails,
  createCurvesPayload,
  getCurvesGraphData,
  hvsgGetUnitsData,
  hvsgCurveData,
} from './curveConstants';
import Notification, {
  Types,
} from '../../../../../../../common/Notification/Notification';
import FormFields from '../../../../../../../common/FormFields/FormFields';
import ConfirmModal from '../../../../../../../common/Modal/Modal';
import { ButtonVariables } from '../../../../../../../constants/variables';
import {
  NO_INTERNET,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  CurvesMessages,
  RESET_OR_SAVE,
} from '../../../../../../../constants/messages';
import Loader from '../../../../../../../common/Loader/Loader';
import Line from '../../../../../../../common/Graphs/Line/Line';

const GearboxCurve = (props) => {
  const {
    isSchematicEditable,
    location,
    network,
    saveHVSGCurvesData,
    deleteHVSGCurvesData,
    HVSGCurvesData,
    deleteGearboxCurves,
    clearDeleteCurvesState,
    clearUpdateCurveDataState,
    dataUnitLoading,
    dataUnitFetchSuccess,
    inUnitData,
    form,
    variableCurves,
    curveId,
    curveData,
    HydraulicGearboxCurveFormField,
    curvesTableColumns,
    curvesTableAddRow,
    DataColumnsTitle,
    defaultIsErrorValues,
    curveType,
    identifierKey,
    DataColumKeyChange,
    curveName,
    identifierData,
    curveTitleKey,
    updateCurveDataOnDeletion,
    lastCurve,
    onAddCurve,
    onDataEdit,
    editState,
    saveWarning,
    setSaveWarning,
    isCurveTypeChanged,
    setIsCurveTypeChanged,
    resetCurveTypeData,
    updateCurveTypeData
  } = props;

  const defaultAddFieldValues = {};
  Object.values(DataColumKeyChange).forEach((item) => {
    defaultAddFieldValues[item] = '';
  });
  const isIdentiferRequired = !(identifierKey === '');

  const [inEditState, setInEditState] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [editTableRows, setEditableRows] = useState([]);
  const [updatedFields, setUpdatedFields] = useState([]);
  const [dirtyRows, setDirtyRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleGraph, setVisibleGraph] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [curvesData, setCurvesData] = useState([]);
  const [initCurvesData, setInitCurvesData] = useState([]);
  const [tempCurvesData, setTempCurvesData] = useState([]);
  const [activeIdentifierData, setActiveIdentifierData] = useState({
    unit: '',
    design: '',
  });
  const [tempIdentifierData, setTempIdentifierData] = useState({
    unit: '',
    design: '',
  });
  const [initIdentifierData, setInitIdentifierData] = useState({});
  const [selectedUnitsData, setSelectedUnitsData] = useState({});
  const [temporaryUnitData, setTemporaryUnitData] = useState({});
  const [unitData, setUnitData] = useState([]);
  const [addFieldValues, setAddFieldValues] = useState(defaultAddFieldValues);
  const [isError, setIsError] = useState(defaultIsErrorValues);
  const [xAxisValue, setXaxisValue] = useState('');
  const [yAxisValue, setYaxisValue] = useState('');
  const [deleteKey, setDeleteKey] = useState('');
  const [isNewCurveBeingAdded, setIsNewCurveBeingAdded] = useState(false);

  useEffect(() => {
    if (isCurveTypeChanged) {
      setFormEdit(true);
    }
  }, [isCurveTypeChanged]);

  useEffect(() => {
    if (HVSGCurvesData.isUpdateSuccess) {
      disableEditForm();
      clearUpdateCurveDataState();
      let data = { ...curvesData };
      let idenData = { ...activeIdentifierData };
      if (
        HVSGCurvesData.curvesData &&
        HVSGCurvesData.curvesData[curveTitleKey]
      ) {
        let currCurveDataList =
          HVSGCurvesData.curvesData[curveTitleKey]?.curvesData;

        currCurveDataList.filter((curveDataItem) => {
          if (curveDataItem.curveId === curveId) {
            data = curveDataItem.data;
            idenData = curveDataItem.identifier;
            return curveDataItem;
          }
          return curveDataItem;
        });

        if (!_.isEmpty(data)) {
          const curveData = hvsgCurveData(data, DataColumKeyChange);
          const unitData = hvsgGetUnitsData(data, DataColumKeyChange);
          setCurvesData([...curveData]);
          setTempCurvesData([...curveData]);
          setSelectedUnitsData(_.cloneDeep(unitData));
          setTemporaryUnitData({ ...unitData });
        }
        if (!_.isEmpty(idenData)) {
          let currIdentifierData = {
            design: idenData && idenData.design ? idenData.design : 0,
            unit: idenData && idenData.unit ? idenData.unit : '',
          };
          setActiveIdentifierData({ ...currIdentifierData });
          setTempIdentifierData({ ...currIdentifierData });
        }
        console.log(currCurveDataList)
        updateCurveTypeData(currCurveDataList);
        setTempCurvesData(curvesData);
        setIsCurveTypeChanged(false);
      }
      Notification.show(Types.Success, CurvesMessages.ADDED);
    } else if (HVSGCurvesData.isUpdateError) {
      Notification.show(Types.Error, HVSGCurvesData.apiMsg);
      clearUpdateCurveDataState();
    }
  }, [
    HVSGCurvesData,
    clearUpdateCurveDataState,
    curvesData,
    DataColumKeyChange,
    curveId,
    activeIdentifierData,
    curveTitleKey,
    setIsCurveTypeChanged,
    updateCurveTypeData,
  ]);

  const initialiseCurveData = useCallback(() => {
    if (
      !_.isEmpty(curveData) &&
      !inEditState &&
      !_.isEqual(curveData, initCurvesData)
    ) {
      setInitCurvesData(curveData);
      const curvData = hvsgCurveData(curveData, DataColumKeyChange);
      const unitData = hvsgGetUnitsData(curveData, DataColumKeyChange);
      setCurvesData(curvData);
      setTempCurvesData(curvData);
      setSelectedUnitsData(_.cloneDeep(unitData));
      setTemporaryUnitData({ ...unitData });
    }
    if (
      !_.isEmpty(identifierData) &&
      !inEditState &&
      !_.isEqual(identifierData, initIdentifierData)
    ) {
      setInitIdentifierData(identifierData);
      let currIdentifierData = {
        design:
          identifierData && identifierData.design ? identifierData.design : 0,
        unit: identifierData && identifierData.unit ? identifierData.unit : '',
      };
      setActiveIdentifierData({ ...currIdentifierData });
      setTempIdentifierData({ ...currIdentifierData });
    }
  }, [
    curveData,
    identifierData,
    inEditState,
    DataColumKeyChange,
    initCurvesData,
    initIdentifierData,
  ]);

  useEffect(() => {
    initialiseCurveData();
  }, [initialiseCurveData]);

  useEffect(() => {
    window.addEventListener('form-edit', () => {
      setFormEdit(true);
    });
    window.addEventListener('form-edit-false', (e) => {
      setFormEdit(false);
      setUpdatedFields([]);
      setEditableRows([]);
      setDirtyRows([]);
      setIsError(defaultIsErrorValues);
      setIsNewCurveBeingAdded(false);
      setDeleteKey('');
    });
  }, [defaultIsErrorValues]);

  useEffect(() => {
    if (dataUnitFetchSuccess) {
      const tempUnitData =
        inUnitData && inUnitData.items ? _.clone(inUnitData.items) : [];
      setUnitData(tempUnitData);
    }
  }, [dataUnitFetchSuccess, inUnitData]);

  const deleteCurveData = useCallback(() => {
    clearDeleteCurvesState();

    updateCurveDataOnDeletion(deleteKey);
  }, [updateCurveDataOnDeletion, clearDeleteCurvesState, deleteKey]);

  useEffect(() => {
    if (deleteGearboxCurves.isSuccess) {
      deleteCurveData();
    } else if (deleteGearboxCurves.isError) {
      Notification.show(Types.Error, deleteGearboxCurves.message);
    }
  }, [deleteGearboxCurves, deleteCurveData]);

  const handleNetwork = () => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  };

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };
  const updateFormFields = () => {
  
    form &&
      form.setFieldsValue({
        [curveTitleKey + identifierKey]: tempIdentifierData.design,
        [curveTitleKey + identifierKey + '_unit']: tempIdentifierData.unit,
      });
    Object.keys(temporaryUnitData).forEach((item) => {
      form.setFieldsValue({
        [curveTitleKey + item]: temporaryUnitData[item],
      });
    });
  };

  const handleOk = () => {
    setVisible(false);
    disableEditForm();
    onDataEdit(false);
    setCurvesData(tempCurvesData);
    setActiveIdentifierData(tempIdentifierData);
    setSelectedUnitsData(_.cloneDeep(temporaryUnitData));
    updateFormFields();
    if(isCurveTypeChanged){
      resetCurveTypeData();
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const validateFields = (values) => {
    let status = true;
    const temp = { ...isError };
    Object.keys(temp).forEach((item) => {
      if (
        (!values[item] && values[item] !== 0) ||
        (values[item] &&
          (values[item].length === 0 || values[item].trim().length === 0))
      ) {
        temp[item] = true;
        status = false;
      } else {
        temp[item] = false;
      }
    });
    setIsError(temp);
    return status;
  };

  const onAddFieldChange = (field) => {
    const temp = addFieldValues;
    temp[field.name] =
      parseFloat(field.value) === 0
        ? parseFloat(field.value)
        : field.value.replace(/^0+/, '');

    setAddFieldValues(temp);
    validateFields(addFieldValues);
    enableEditForm();
    setInEditState(true);
  };

  const onEditFieldChange = (row, col, field) => {
    const tableData = [...curvesData];
    const temp = { ...tableData[row] };
    temp[field.name] =
      parseFloat(field.value) === 0
        ? `${parseFloat(field.value)}`
        : field.value.replace(/^0+/, '');
    const tempCurveData = [...tableData];
    tempCurveData[row] = { ...temp };
    setCurvesData(tempCurveData);
  };

  const editRow = (row) => {
    let tableData = _.cloneDeep(curvesData);
    const updatedTemp = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editTableRows);
    updatedTemp[row] = _.cloneDeep(tableData[row]);
    tempEditRows.push(row);
    setUpdatedFields(_.cloneDeep(updatedTemp));
    setEditableRows(tempEditRows);
    enableEditForm();
  };

  const deleteRow = (row) => {
    let tempTableData = _.cloneDeep(curvesData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editTableRows);
    tempTableData.splice(row, 1);
    tempupdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempEditRows = tempEditRows.filter((e) => e !== row);

    setUpdatedFields(tempupdatedField);
    setEditableRows(tempEditRows);
    setDirtyRows(tempDirtyRows);
    setCurvesData(tempTableData);
    setInEditState(true);
    enableEditForm();
  };

  const undo = (row) => {
    let tempTableData = _.cloneDeep(curvesData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editTableRows);
    tempTableData[row] = _.cloneDeep(tempupdatedField[row]);
    tempEditRows = tempEditRows.filter((e) => e !== row);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempupdatedField[row] = undefined;
    setUpdatedFields(tempupdatedField);
    setEditableRows(tempEditRows);
    setDirtyRows(tempDirtyRows);
    setCurvesData(tempTableData);
    setInEditState(true);
    enableEditForm();
  };
  const addRow = () => {
    if (!validateFields(addFieldValues)) {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      return;
    }
    const tableData = curvesData;
    const data = [...tableData, { ...addFieldValues }];
    setCurvesData(data);
    setAddFieldValues(defaultAddFieldValues);
    onDataEdit(true);
  };

  const validateCurveData = () => {
    let isCurveDataValid = true;
    if (!dirtyRows.length === 0) {
      isCurveDataValid = false;
    }
    if ( curveTitleKey !== 'maxTorqueLimitCurve' && !(activeIdentifierData && activeIdentifierData.design)) {
      isCurveDataValid = activeIdentifierData.design === 0 ? true : false;
    }
    if (curvesData && curvesData.length > 0) {
      curvesData.forEach((element) => {
        Object.keys(element).forEach((item) => {
          if (!element[item] && element[item] !== 0) {
            isCurveDataValid = false;
          }
        });
      });
    }

    if (curvesData.length < 3) {
      Notification.show(
        Types.Info,
        'Minimum 3 rows are required for adding Curve Data'
      );
      return false;
    }
    if (!isCurveDataValid) {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
    return isCurveDataValid;
  };

  const save = () => {
    form.validateFields((err, values) => {
      if (err) {
        throw err;
      }
      if (!validateCurveData()) {
        return;
      }
      const payload = createCurvesPayload(
        curveId,
        curveTitleKey,
        selectedUnitsData,
        curvesData,
        curveType,
        activeIdentifierData,
        DataColumKeyChange,
        identifierKey,
        values
      );
      const { state } = location;
      if (handleNetwork() && state && state.componentData.info._id && payload) {
        saveHVSGCurvesData(state.componentData.info._id, payload);
        onDataEdit(false);
      }
    });
  };

  const showGraph = () => {
    if (curvesData.length > 2) {
      setVisibleGraph(false);
      setVisibleModal(true);
    } else {
      Notification.show(Types.Info, graphDetails.requiredMessage);
    }
  };
  const reset = () => {
    setVisible(true);
  };

  const addCurve = () => {
    setIsNewCurveBeingAdded(true);
    onAddCurve();
  };

  const onDeleteCancel = () => {
    setVisibleDelete(false);
  };

  const handleOkDelete = () => {
    setVisibleDelete(false);
    if (isNewCurveBeingAdded) {
      updateCurveDataOnDeletion(curveId);
    } else {
      const { state } = location;
      if (handleNetwork() && state && state.componentData.info._id) {
        deleteHVSGCurvesData(
          state.componentData.info._id,
          curveId,
          curveTitleKey
        );
        setDeleteKey(curveId);
      }
    }
  };
  const handleOkGraph = () => {
    setVisibleGraph(false);
  };

  const cancelModal = () => {
    setXaxisValue('');
    setYaxisValue('');
    setVisibleModal(false);
  };

  const onChangeAxes = (value, axis) => {
    if (axis === 'Y') {
      setYaxisValue(value);
    }
    if (axis === 'X') {
      setXaxisValue(value);
    }
  };
  const onSelectAxes = () => {
    setVisibleGraph(true);
    setVisibleModal(false);
  };
  const renderDeleteModal = () => {
    if (visibleDelete) {
      return (
        <ConfirmModal
          title={CurvesMessages.DELETE_TITLE}
          visible={visibleDelete}
          handleOk={handleOkDelete}
          handleCancel={onDeleteCancel}
          message={CurvesMessages.DELETE_MSG}
        />
      );
    }
    return null;
  };
  const onChangeSelect = (value, key) => {
    setInEditState(true);
    onDataEdit(true);
    if (key === 'identifierUnit') {
      const currIdentifiertData = { ...activeIdentifierData };
      currIdentifiertData.unit = value;

      setActiveIdentifierData(currIdentifiertData);
    } else if (key === 'identifierValue') {
      const currIdentifiertData = { ...activeIdentifierData };
      currIdentifiertData.design =
        value.target && value.target.value
          ? parseFloat(value.target.value)
          : '';
      setActiveIdentifierData(currIdentifiertData);
    } else {
      const currUnitData = selectedUnitsData;
      currUnitData[key] = value;
      setSelectedUnitsData(_.cloneDeep(currUnitData));
    }
  };
  const renderGraphModal = () => {
    if (visibleGraph) {
      const graphData = getCurvesGraphData(
        curvesData,
        xAxisValue,
        yAxisValue,
        DataColumKeyChange
      );

      return (
        <Modal
          title={curveName}
          visible={visibleGraph}
          onOk={() => handleOkGraph()}
          onCancel={() => handleOkGraph()}
          footer={null}
          className="curve-modal"
        >
          <div>
            <button
              className="btn-default btn-small"
              float="left"
              onClick={() => {
                showGraph();
              }}
            >
              BACK
            </button>
          </div>
          <div className="curve-plot">
            <div style={{ width: 500, height: 280 }}>
              <Line
                graphData={graphData}
                xAxis={xAxisValue}
                yAxis={yAxisValue}
              />
              <span>
                {yAxisValue +
                  ' plotted against ' +
                  xAxisValue +
                  ' for ' +
                  curveName}
              </span>
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  };
  const renderCurveInputModal = () => {
    if (visibleModal) {
      return (
        <Modal
          title={'Curve Input'}
          visible={visibleModal}
          onCancel={() => cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(DataColumnsTitle, yAxisValue, xAxisValue, {
              change: (e, axis) => {
                onChangeAxes(e, axis);
              },
            })}
            form={form}
          />
          {
            <div className="ok-button">
              <button
                className="btn-default btn-small"
                onClick={() => {
                  onSelectAxes();
                }}
                disabled={yAxisValue === '' || xAxisValue === ''}
              >
                OK
              </button>
            </div>
          }
        </Modal>
      );
    }
  };

  const renderConfirmModal = () => {
    if (editState) {
      return (
        <Modal
          visible={saveWarning}
          message={RESET_OR_SAVE}
          onOk={() => {
            setSaveWarning(false);
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={'Alert'}
          okText={'Ok'}
        >
          {RESET_OR_SAVE}
        </Modal>
      );
    } else {
      return <></>;
    }
  };

  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  };

  const renderHydraulicGearboxCurve = () => {
    return (
      <div>
        <div className="heading-table">
          {isIdentiferRequired && (
            <div className="type-heading">{curveName}</div>
          )}
          <div
            className={
              isIdentiferRequired ? 'unit-heading' : 'unit-heading-full'
            }
          >
            {isSchematicEditable ? 'Select Units' : 'Units'}
          </div>
        </div>
        <div>
          <FormFields
            formFields={HydraulicGearboxCurveFormField(
              unitData,
              activeIdentifierData,
              selectedUnitsData,
              {
                change: (e, key) => {
                  enableEditForm();
                  onChangeSelect(e, key);
                },
                isCurveEditable: isSchematicEditable,
              }
            )}
            form={form}
          />
        </div>
        <div className="table-curve">
          <CustomTable
            showHeader
            columns={curvesTableColumns({
              isCurveEditable: isSchematicEditable,
              onChange: (row, col, field) => {
                onEditFieldChange(row, col, field);
              },
              edit: (row) => {
                editRow(row);
                onDataEdit(true);
              },
              deleteRow: (row) => {
                deleteRow(row);
                onDataEdit(true);
              },
              undo: (row) => {
                undo(row);
              },
            })}
            editableRow={editTableRows}
            data={curvesData}
            renderAddRow={
              isSchematicEditable
                ? curvesData.length < 25
                  ? curvesTableAddRow({
                      onChange: (row, col, field) => {
                        onAddFieldChange(field);
                      },
                      submit: (e) => {
                        addRow();
                      },
                      isError: isError,
                    })
                  : null
                : null
            }
            addFieldValues={addFieldValues}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="CurvesInner">
        {HVSGCurvesData.isLoading ||
        dataUnitLoading ||
        deleteGearboxCurves.isLoading ? (
          <Loader />
        ) : null}

        <div className="curves-buttons">
          {variableCurves.length > 1 && curveId === lastCurve && (
            <button
              className="btn-default"
              onClick={() => {
                setVisibleDelete(true);
              }}
            >
              Delete Curve
            </button>
          )}

          <button
            className="btn-default btn-white"
            onClick={() => {
              showGraph();
            }}
          >
            View Curve Plot
          </button>
        </div>

        {renderHydraulicGearboxCurve()}
        <div className="config-bottom-buttons">
          {formEdit && isSchematicEditable ? (
            <div>
              <button
                className="btn-default btn-white"
                onClick={() => {
                  reset();
                }}
              >
                {ButtonVariables.RESET}
              </button>
              <button
                className="btn-default"
                onClick={() => {
                  save();
                }}
              >
                {ButtonVariables.SAVE}
              </button>
            </div>
          ) : null}
          {curveType === 'Multiple' &&
            !formEdit &&
            isSchematicEditable &&
            !isNewCurveBeingAdded &&
            variableCurves.length < 8 && (
              <button
                className="btn-default"
                onClick={() => {
                  addCurve();
                }}
              >
                {'Add Curve'}
              </button>
            )}
        </div>
        {renderModal()}
        {renderDeleteModal()}
        {renderGraphModal()}
        {renderCurveInputModal()}
        {renderConfirmModal()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    HVSGCurvesData:
      state.HydraulicVariableSpeedGearComponentReducer.HVSGCurvesData,
    dataUnitFetchSuccess: state.DataUnitReducer.apiState.isSuccessDataUnitFetch,
    inUnitData: state.DataUnitReducer.apiState.unitData,
    dataUnitLoading: state.DataUnitReducer.loading,
    deleteGearboxCurves:
      state.HydraulicVariableSpeedGearComponentReducer.deleteGearboxCurves,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveHVSGCurvesData: (componentId, payload) =>
    dispatch(saveHVSGCurvesData(componentId, payload)),
  deleteHVSGCurvesData: (componentId, curveId, curveTitleKey) =>
    dispatch(deleteHVSGCurvesData(componentId, curveId, curveTitleKey)),
  clearDeleteCurvesState: () => dispatch(clearDeleteCurvesState()),
  clearUpdateCurveDataState: () => dispatch(clearUpdateCurveDataState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(GearboxCurve))
);
