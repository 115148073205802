import { Checkbox, Icon, Badge, Row, Col } from 'antd';
import React from 'react';
import icAlertHigh from '../../../../assets/ic_alert_high';
import icAlertLow from '../../../../assets/ic_alert_low';
import icAlertMedium from '../../../../assets/ic_alert_medium';
import icAlertUnknown from '../../../../assets/ic_alert_unknown';
import icNewMail from '../../../../assets/ic_new_mail';
import icOpenedMail from '../../../../assets/ic_opened_mail';
import { ALERT_LEVELS, ALERT_TABS } from '../../../../constants/variables';
import { elementsGaurd } from '../../../../gaurds';
import { getTimeInFormat } from '../../../../libs/valueFormatter';
import { ElementPermissions } from '../../../../permissions';
import _ from 'lodash';
import { Markup } from 'interweave';

let arrayIds = [];
let highLevelArrayIds = [];
let alertAssocIds = [];
let eventIds = [];
let modalCount = 0;
let modalValue = false;
let currentTab;
const status = {
  Forward: 'Forwarded',
  New: 'New',
  Close: 'Closed',
  Disable: 'Disabled',
  Discard: 'Discarded',
  Shelved: 'Shelved',
  Acknowledged: 'Acknowledged',
};
const DiscardCheck = (item, config, type) => {
  if (type === 'checkOne') {
    let index = arrayIds.findIndex((arrayData) => {
      return item._id === arrayData; // eslint-disable-line
    }); // eslint-disable-line
    if (index === -1) {
      arrayIds.push(item._id); // eslint-disable-line
      item.level === ALERT_LEVELS[0] && highLevelArrayIds.push(item._id); // eslint-disable-line
      item.eventId &&
        !alertAssocIds.includes(item._id) &&
        alertAssocIds.push(item._id); // eslint-disable-line
      config.showDisableActionDropdown(
        true,
        arrayIds,
        highLevelArrayIds,
        alertAssocIds,
        eventIds
      );
    } else {
      const highLevelIndex = _.indexOf(highLevelArrayIds, arrayIds[index]);
      const alertAssocIndex = _.indexOf(alertAssocIds, arrayIds[index]);
      highLevelIndex > -1 && highLevelArrayIds.splice(highLevelIndex, 1);
      alertAssocIndex > -1 && alertAssocIds.splice(alertAssocIndex, 1);
      arrayIds.splice(index, 1);
      config.showDisableActionDropdown(
        true,
        arrayIds,
        highLevelArrayIds,
        alertAssocIds,
        eventIds
      );
      if (!arrayIds.length) {
        config.showDisableActionDropdown(false);
      }
    }
  }
};

const DiscardCheckEvent = (item, config) => {
  const eventIndex = _.indexOf(eventIds, item._id);
  eventIndex === -1 && eventIds.push(item._id);
  eventIndex > -1 && eventIds.splice(eventIndex, 1);
  item.alerts.forEach((alert) => {
    DiscardCheck(alert, config, 'checkOne');
  });
};

const SelectAll = (config, eventAlerts) => {
  //select
  if (arrayIds.length === 0 && highLevelArrayIds.length === 0 && eventIds.length === 0) {
    config.alertList.concat(eventAlerts).map((item) => {
      item.mechademyStatus === 'New' && arrayIds.push(item._id); // eslint-disable-line
      item.mechademyStatus === 'New' &&
        item.level === 'High' &&
        highLevelArrayIds.push(item._id); // eslint-disable-line
      item.mechademyStatus === 'New' && item.eventId && !alertAssocIds.includes(item._id) && alertAssocIds.push(item._id);
      item.eventId && !eventIds.includes(item.eventId) && eventIds.push(item.eventId);
      arrayIds.length &&
        config.showDisableActionDropdown(true, arrayIds, highLevelArrayIds, alertAssocIds, eventIds);
      return true;
    });
  } else {
    arrayIds = [];
    highLevelArrayIds = [];
    alertAssocIds = [];
    eventIds = []
    config.showDisableActionDropdown(false);
  }
};

const isChecked = (config, eventAlerts) => {


  const { alertList } = config; let flag = true;

  //all selected IDs on this page 
  //eventAlerts is alertIds within events
  let currentIds = alertList.concat(eventAlerts).filter((item) => {
    return item.mechademyStatus === status.New;
  }).map(item => item._id);
  //for select all to be true , all alerts on this page should be in currentIds
  arrayIds.forEach((alertId) => {
    if (flag === false)
      return;
    if (!_.includes(currentIds, alertId)) {
      flag = false;
      return;
    }

  });

  return flag && (arrayIds.length === currentIds.length);

};

const RefreshArrayIds = (config) => {
  modalCount = (modalCount + 1) % 2;
  modalValue =
    config.isDiscardModal ||
    config.isCloseModal ||
    config.isAssocModal ||
    config.isDisassocModal;
  if (!modalCount) {
    arrayIds = [];
    highLevelArrayIds = [];
    alertAssocIds = [];
    eventIds = []
    config.showDisableActionDropdown(false);
  }
};

export const columns = (config) => {
  arrayIds = config.alertDiscardIds;
  highLevelArrayIds = config.highLevelDiscardIds;
  alertAssocIds = config.alertAssocIds;
  if (currentTab !== config.alertTab) {
    currentTab = config.alertTab;
    arrayIds = [];
    highLevelArrayIds = [];
    alertAssocIds = [];
    eventIds = [];
  }
  let high, low, med, unknown;
  let eventAlerts = [];
  config?.eventList &&
    config.eventList.docs.length > 0 &&
    config.eventList.docs.forEach((evnt) => {
      eventAlerts = eventAlerts.concat(evnt.alerts);
    });
  return [
    /** Checkbox visible only if user have peremission and in 'all alert' , 'active' & 'new alerts'  tab*/
    ((config.alertList && config.alertList.length > 0) ||
      (config.eventList?.docs && config.eventList.docs.length > 0)) &&
      (config.alertTab === Object.keys(ALERT_TABS)[0] ||
        config.alertTab === Object.keys(ALERT_TABS)[1] ||
        config.alertTab === Object.keys(ALERT_TABS)[4]) &&
      config.alertPermission
      ? {
        title: (
          <Checkbox
            checked={arrayIds.length > 0 && isChecked(config, eventAlerts)}
            disabled={
              config.alertList?.filter((item) => {
                return item.mechademyStatus === status.New;
              }).length === 0 && !checkEvents(config.eventList)
            }
            onChange={() => {
              SelectAll(config, eventAlerts);
            }}
          />
        ),
        dataIndex: 'selected',
        clickable: false,
        render: (value, row, col, isDisable, item) => {
          const isChecked = arrayIds.includes(item._id); // eslint-disable-line
          if (
            (config.isDiscardModal ||
              config.isCloseModal ||
              config.isAssocModal ||
              config.isDisassocModal) !== modalValue
          )
            RefreshArrayIds(config);
          return (
            <Checkbox
              onChange={() => {
                item.mechademyStatus
                  ? DiscardCheck(item, config, 'checkOne')
                  : DiscardCheckEvent(item, config);
              }}
              checked={
                (item.mechademyStatus && item.mechademyStatus === 'New'
                  ? isChecked
                  : false) ||
                (item.alerts?.filter(
                  (alert) => alert.mechademyStatus === 'New'
                ).length > 0
                  ? eventCheckbox(item) //item is event
                  : false)
              }
              disabled={
                item.mechademyStatus
                  ? item.mechademyStatus === 'New'
                    ? false
                    : true
                  : item.alerts?.filter(
                    (alert) => alert.mechademyStatus === 'New'
                  ).length === item.alerts?.length
                    ? false
                    : true
              }
            />
          );
        },
      }
      : null,

    {
      title: ' ',
      dataIndex: 'level',
      clickable: false,
      render: (value, row, col, isDisable, item) => {
        if (item.level) {
          //item is alert
          return (
            <>
              {item.level === ALERT_LEVELS[0] && (
                <div className="alert-level-icon">
                  <img alt="" src={icAlertHigh} />
                </div>
              )}
              {item.level === ALERT_LEVELS[1] && (
                <div className="alert-level-icon">
                  <img alt="" src={icAlertMedium} />
                </div>
              )}
              {item.level === ALERT_LEVELS[2] && (
                <div className="alert-level-icon">
                  <img alt="" src={icAlertLow} />
                </div>
              )}
              {item.level === ALERT_LEVELS[3] && (
                <div className="alert-level-icon">
                  <img alt="" src={icAlertUnknown} />
                </div>
              )}
            </>
          );
        } //item is event
        else {
          ({ high, low, med, unknown } = eventAlertLevel(
            item
          ));
          return (
            <>
              <div>
                {low > 0 && (
                  <span className="event-level-span">
                    {
                      <Badge style={{ backgroundColor: '#706f6f' }} count={low}>
                        <img
                          alt=""
                          src={icAlertLow}
                          className="event-level-icon"
                        />
                      </Badge>
                    }
                  </span>
                )}
                {med > 0 && (
                  <span className="event-level-span">
                    {
                      <Badge style={{ backgroundColor: '#706f6f' }} count={med}>
                        <img
                          alt=""
                          src={icAlertMedium}
                          className="event-level-icon"
                        />{' '}
                      </Badge>
                    }
                  </span>
                )}
                {high > 0 && (
                  <span className="event-level-span">
                    {
                      <Badge
                        style={{ backgroundColor: '#706f6f' }}
                        count={high}
                      >
                        <img
                          alt=""
                          src={icAlertHigh}
                          className="event-level-icon"
                        />{' '}
                      </Badge>
                    }
                  </span>
                )}
                {unknown > 0 && (
                  <span className="event-level-span">
                    {
                      <Badge
                        style={{ backgroundColor: '#706f6f' }}
                        count={unknown}
                      >
                        <img
                          alt=""
                          src={icAlertUnknown}
                          className="event-level-icon"
                        />{' '}
                      </Badge>
                    }
                  </span>
                )}
              </div>
            </>
          );
        }
      },
    },
    elementsGaurd(
      {
        title: ' ',
        dataIndex: 'comment',
        clickable: false,
        render: (value, row, col, isDisable, item) => {
          if (item.comment) {
            //item is alert
            return (
              <>
                {item.comment && item.comment !== '' && (
                  <Icon className="comment-icon" type="message" />
                )}
              </>
            );
          } //item is event
          else {
            let comments = 0;
            item.alerts &&
              item.alerts.forEach((alert) => {
                if (alert.comment && alert.comment !== '')
                  comments++;
              });
            return (
              <>
                {comments > 0 && (
                  <Badge
                    style={{ backgroundColor: '#706f6f' }}
                    count={comments}
                  >
                    <Icon className="comment-icon" type="message" />
                  </Badge>
                )}
              </>
            );
          }
        },
      },
      ElementPermissions.ALERT_LISTING_STATUS,
      config.info
    ),

    {
      title: ' ',
      dataIndex: 'insight',
      clickable: false,
      render: (value, row, col, isDisable, item) => {
        if (item.insights) {
          return (
            <>
              {item.insights && item.insights !== '' && (
                <Icon className="insights-icon" type="info" />
              )}
            </>
          );
        } // item is event
        else {
          let insights = 0;
          item.alerts &&
            item.alerts.forEach((alert) => {
              if (alert.insights && alert.insights !== '')
                insights++;
            });
          return (
            <>
              {insights > 0 && (
                <Badge style={{ backgroundColor: '#706f6f' }} count={insights}>
                  <Icon className="insights-icon" type="info" />
                </Badge>
              )}
            </>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'messages',
      clickable: false,
      render: (value, row, col, isDisable, item) => {
        if (item.lastMessage) {
          const { lastMessage } = item;
          const currUser = config.userId;
          return (
            <>
              {lastMessage &&
                currUser !== lastMessage &&
                item.readBy &&
                item.readBy.indexOf(currUser) === -1 && (
                  <div className="mail-icon">
                    <img alt="" src={icNewMail} />
                  </div>
                )}
              {((lastMessage && lastMessage === currUser) ||
                (item.readBy && item.readBy.indexOf(currUser) >= 0)) && (
                  <div className="mail-icon">
                    <img alt="" src={icOpenedMail} />
                  </div>
                )}
            </>
          );
        } // item is event
        else {
          let read = 0,
            unread = 0;
          item.alerts &&
            item.alerts.forEach((alert) => {
              alert.lastMessage &&
                config.userId !== alert.lastMessage &&
                alert.readBy &&
                alert.readBy.indexOf(config.userId) === -1 &&
                unread++;

              ((alert.lastMessage && alert.lastMessage === config.userId) ||
                (alert.readBy && alert.readBy.indexOf(config.userId) >= 0)) &&
                read++;
            });

          return (
            <>
              {
                <div>
                  {unread > 0 && (
                    <Badge
                      style={{ backgroundColor: '#706f6f' }}
                      count={unread}
                    >
                      <img alt="" src={icNewMail} />
                    </Badge>
                  )}
                  {read > 0 && (
                    <Badge style={{ backgroundColor: '#706f6f' }} count={read}>
                      <img alt="" src={icOpenedMail} />
                    </Badge>
                  )}
                </div>
              }
            </>
          );
        }
      },
    },
    !config.userRole.startsWith('Mechademy') && {
      title: 'Alert ID',
      dataIndex: 'alertID',
      clickable: true,
      render: (value, row, col, isDisable, item) => {
        return item.fwdAlertId ? (
          <span>{item.fwdAlertId}</span>
        ) : (
          <span>{'--'}</span>
        );
      },
    },
    {
      title: 'Alert Identifier',
      dataIndex: 'identifier',
      clickable: true,
      render: (value, row, col, isDisable, item) => {
        if (item.identifierDetails) {
          // item is alert
          item.type = item.type === "IQR threshold exceedance" ? "Univariate Anomaly Detection" : item.type
          const {
            equipmentName,
            equipmentTagNumber,
            variableName,
            variableTagNumber,
            displayVariableTagNumber,
          } = item.identifierDetails;
          return config.userRole.startsWith('Mechademy') ? (
            <span>
              {item.identifierDetails
                ? `${item.fwdAlertId ? item.fwdAlertId + ' - ' : ''
                }${equipmentName} - ${equipmentTagNumber}  - ${displayVariableTagNumber
                  ? displayVariableTagNumber
                  : item.component
                    ? createDisplayTag(variableTagNumber)
                    : variableTagNumber
                }`
                : '--'}
            </span>
          ) : (
            <span>
              {' '}
              {item.identifierDetails
                ? `${equipmentName} - ${equipmentTagNumber} -${variableName} - ${displayVariableTagNumber
                  ? displayVariableTagNumber
                  : item.component
                    ? createDisplayTag(variableTagNumber)
                    : variableTagNumber
                }`
                : '--'}
            </span>
          );
        } //item is event
        else {
          return (
            <span>
              <Row className="menu-padding">
                <Col span={22} className="menuHead">
                  {item.eventName ? <Markup content={item.eventName}> </Markup> : <></>}{' '}
                </Col>
                <Col span={2} className="menuHead">
                  <Badge
                    style={{ backgroundColor: '#706f6f' }}
                    count={item.eventSize}
                  />
                </Col>
              </Row>
            </span>
          );
        }
      },
    },
    {
      title: 'Alert Type',
      dataIndex: 'type',
      clickable: true,
      render: (value, row, col, isDisable, item) => {
        item.type = item.type && item.type === "IQR threshold exceedance" ?
          "Univariate Anomaly Detection" : item.type
        return <>{item.type}</>
      }
    },
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      clickable: true,
      render: (value, row, col, isDisable, item) => {
        return <span>{item.dateTime && getTimeInFormat(item.dateTime)}</span>;
      },
    },
    elementsGaurd(
      {
        title: 'Classification',
        dataIndex: 'classification',
      },
      ElementPermissions.ALERT_LISTING_STATUS,
      config.info
    ),
    /** Element Guard in case of MeAdmin only this fields will render */
    config.userRole !== 'MechademyFacilityManager'
      ? elementsGaurd(
        {
          title: 'Status',
          dataIndex: 'mechademyStatus',
          clickable: true,
          render: (value) => <>{status[value]}</>,
        },
        ElementPermissions.ALERT_LISTING_STATUS,
        config.info
      )
      : {
        title: 'Status',
        dataIndex: 'mechademyStatus',
        clickable: true,
        render: (value) => <>{status[value]}</>,
      },

    config.userRole === 'MechademyFacilityManager' ||
      config.userRole === 'MechademyAdmin'
      ? {
        title: 'Client Status',
        dataIndex: 'clientStatus',
        clickable: true,
        render: (value) => <>{status[value]}</>,
      }
      : {
        title: 'Status',
        dataIndex: 'clientStatus',
        clickable: true,
        render: (value) => <>{status[value]}</>,
      },
    elementsGaurd(
      {
        title: 'Edit',
        clickable: true,
        render: (value, row, col, isDisable, item) => {
          if (!item.level) {
            return (
              <>
                {isDisable ? (
                  <button
                    onClick={(e) => {
                      config.eventNameEdit(item);
                    }}
                    className="editUndoButton"
                  >
                    <Icon type="edit" />
                  </button>
                ) : null}
              </>
            );
          } else return null;
        },
      },
      ElementPermissions.ALERT_LISTING_STATUS,
      config.info
    ),
  ];
};

//count the number of alerts in an event grouped by level
const eventAlertLevel = (evnt) => {
  let low = 0,
    med = 0,
    high = 0,
    unknown = 0;
  if (evnt && evnt.alerts.length > 0) {
    evnt.alerts.forEach((alert) => {
      alert.level === 'High' && high++;
      alert.level === 'Low' && low++;
      alert.level === 'Medium' && med++;
      alert.level === 'Unknown' && unknown++;
    });
  }
  return { low, med, high, unknown };
};

//to check whether event contains 'new' alerts or not
const checkEvents = (eventList) => {
  let flag = false;
  if (eventList && eventList.docs.length > 0) {
    eventList.docs.forEach((event) => {
      event.alerts.forEach((alert) => {
        if (alert.mechademyStatus === status.New) {
          flag = true;
          return;
        }
      });
      if (flag === true) return;
    });
  }
  return flag;
};

//to check/uncheck event checkbox
const eventCheckbox = (evnt) => {
  let flag = true;
  evnt.alerts.forEach((alert) => {
    if (arrayIds.indexOf(alert._id) === -1) {
      flag = false;
      return;
    }
  });
  return flag;
};

function createDisplayTag(variableTag) {
  const TagNumberArr = variableTag.split("-");
  TagNumberArr.pop();
  return TagNumberArr.join("-");
}