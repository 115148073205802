import {
  Breadcrumb,
  Button,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Select,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader/Loader';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import '../../../constants/global.scss';
import {
  DISCARD_CHANGES,
  EquipmentMessage,
  EquipmentTypeMessage,
  ERROR_MESSAGES,
  FORM_DISCARD_MSG,
  ManufacturerMessage,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Pattern from '../../../constants/pattern';
import { MaskStyle } from '../../../constants/react-style';
import * as Routes from '../../../constants/routes';
import {
  ButtonVariables,
  ClientEquipmentVariables,
  INTERNAL_ERROR,
} from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { generateCode } from '../../../libs/codeGenerators';
import { ElementPermissions } from '../../../permissions';
import {
  addEquipmentType,
  addManufacturer,
  addNewEquipment,
  clearEquipmentState,
  editEquipment,
  getEquipment,
  getEquipmentType,
  getManufacturer,
} from '../../../redux/actions/equipment.action';
import './ClientEquipment.scss';

const { TextArea } = Input;
const { Option } = Select;

class ClientEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      formEdit: false,
      formDisable: false,
      visible: false,
      demandPage: null,
      demandPageData: null,
      equipmentTypes: [],
      manufacturers: [],
      equipmentData: {},
      systemData: {},
      facilityData: {},
      clientInfoData: {},
      addModalType: null,
      addModalVisible: false,
      addNewValue: null,
      network: window.navigator.onLine,
      message: null,
      title: null,
      addMore: false,
      /** added for conditional rendering of calender */
      isCalenderVisible: false,
      testDate: '',
    };
    /** Use ref for closing of calender when clicked outside */
    this.node = React.createRef();
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;
    document.addEventListener('mousedown', this.handleClick);
    if (state && state.clientData) {
      this.setState({
        clientInfoData: state.clientData,
        facilityData: state.facilityData,
        systemData: state.systemData,
        equipmentData: state.equipmentData,
      });
    }

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });

    this.getPageMode();
    this.getEquipmentType();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate(prevProps) {
    const {
      isSuccessGetEquipmentType,
      isSuccessAddEquipment,
      isSuccessEditEquipment,
      isSuccessGetEquipment,
      isSuccessGetManufacturer,
      isSuccessAddManufacturer,
      isSuccessAddEquipmentType,
      isErrorAddEquipment,
      isErrorEditEquipment,
      isErrorGetEquipment,
      isErrorGetEquipmentType,
      isErrorGetManufacturer,
      isErrorAddEquipmentType,
      isErrorAddManufacturer,
      getEquipmentTypeData,
      manufacturerData,
      equipmentData,
      addEquipmentTypeData,
      addManufacturerData,
      errorCode,
      message,
    } = this.props.equipmentState.apiState;
    const { clearEquipmentState } = this.props;

    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';

    const {
      location: { state },
    } = this.props;

    if (isSuccessAddManufacturer) {
      clearEquipmentState();
      this.getManufacturer();
      this.props.form.setFieldsValue({
        manufacturer: addManufacturerData.nameId,
      });
      this.closeAddModal();
    }

    if (isSuccessAddEquipmentType) {
      clearEquipmentState();
      this.getEquipmentType();
      this.props.form.setFieldsValue({
        type: addEquipmentTypeData.nameId,
      });
      this.closeAddModal();
    }

    if (isSuccessGetEquipmentType) {
      clearEquipmentState();
      this.setState({
        equipmentTypes: getEquipmentTypeData ? getEquipmentTypeData.items : [],
      });
      this.getManufacturer();
    }

    if (isSuccessGetManufacturer) {
      clearEquipmentState();
      this.setState({
        manufacturers: manufacturerData ? manufacturerData.items : [],
      });
    }

    if (isSuccessEditEquipment) {
      Notification.show(Types.Success, EquipmentMessage.EQUIPMENT_UPDATED);
      clearEquipmentState();
      this.disableEditForm();
      this.setState(
        {
          mode: 'view',
          formDisable: true,
          equipmentData,
        },
        () => {
          this.setState({ formDisable: true });
        }
      );
    }

    if (isSuccessAddEquipment) {
      let tempAddMore = this.state.addMore;
      clearEquipmentState();
      Notification.show(Types.Success, EquipmentMessage.EQUIPMENT_ADDED);
      this.disableEditForm();

      if (!tempAddMore) {
        this.setState(
          {
            mode: 'view',
            formDisable: true,
            equipmentData,
          },
          () => {
            tempAddMore = false;
          }
        );
      } else {
        this.setState(
          {
            addMore: tempAddMore,
            mode: 'add',
          },
          () => {
            tempAddMore = true;
            this.props.form.resetFields();
            this.disableEditForm();
          }
        );
      }
    }

    if (isSuccessGetEquipment) {
      clearEquipmentState();
      this.setState(
        {
          equipmentData,
          mode: 'view',
          formDisable: true,
        },
        () => {
          this.setFormData();
        }
      );
    }

    if (
      isErrorAddEquipment ||
      isErrorEditEquipment ||
      isErrorGetEquipment ||
      isErrorGetEquipmentType ||
      isErrorGetManufacturer
    ) {
      if (errorCode === 'ALREADY_EXISTS') {
        if (_.includes(message, 'mechademy'))
          Notification.show(Types.Error, `${EquipmentMessage[errorCode]}`);
        else Notification.show(Types.Error, message);
      } else if (!errorCode) {
        Notification.show(Types.Error, INTERNAL_ERROR);
        this.props.history.push(Routes.clientSystem, {
          systemData: state.systemData,
          facilityData: state.facilityData,
          clientData: state.clientData,
          mode: 'view',
        });
      } else {
        Notification.show(
          Types.Error,
          `${EquipmentMessage[errorCode]} ${displayMessage}`
        );
      }
      clearEquipmentState();
    }

    if (isErrorAddEquipmentType) {
      Notification.show(
        Types.Error,
        `${EquipmentTypeMessage[errorCode]} ${displayMessage}`
      );
      clearEquipmentState();
    }

    if (isErrorAddManufacturer) {
      Notification.show(
        Types.Error,
        `${ManufacturerMessage[errorCode]} ${displayMessage}`
      );
      clearEquipmentState();
    }

    if (
      state.equipmentData &&
      ((prevProps.location.state.equipmentData &&
        state.equipmentData._id !==
          prevProps.location.state.equipmentData._id) ||
        !prevProps.location.state.equipmentData)
    ) {
      this.setState({
        facilityData: state.facilityData ? state.facilityData : {},
        systemData: state.systemData ? state.systemData : {},
      });
      this.getEquipment();
    }
  }

  getPageMode() {
    const {
      location: { state },
    } = this.props;
    if (state && state.mode) {
      if (state.mode === 'add') {
        this.setState({ formDisable: false, mode: 'add' });
      } else if (state.mode === 'edit') {
        this.setState({ formDisable: false, mode: 'edit' });
      } else {
        this.setState({ formDisable: true, mode: 'view' });
        this.getEquipment();
      }
    } else {
      this.props.history.push(Routes.clients);
    }
  }

  getEquipment() {
    const { getEquipment } = this.props;
    const {
      location: { state },
    } = this.props;

    if (state && state.equipmentData) {
      if (this.handleNetwork()) {
        getEquipment(state.equipmentData._id);
      }
    }
  }

  getEquipmentType() {
    const { getEquipmentType } = this.props;
    if (this.handleNetwork()) {
      getEquipmentType();
    }
  }

  getManufacturer() {
    const { getManufacturer } = this.props;
    if (this.handleNetwork()) {
      getManufacturer();
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  getCode(value) {
    this.props.form.validateFields((err, values) => {
      if (this.state.mode !== 'edit' && !err.name) {
        const code = generateCode(value.trim());
        const {
          mechademyEquipmentId,
          clientEquipmentId,
          name,
        } = this.props.form.getFieldsValue();
        if (!mechademyEquipmentId && !clientEquipmentId) {
          this.props.form.setFieldsValue({
            name: name && name.trim(),
            mechademyEquipmentId: code,
            clientEquipmentId: code,
          });
        } else if (
          name &&
          name.length >= 3 &&
          mechademyEquipmentId &&
          mechademyEquipmentId.length >= 3
        ) {
          const firstThreeCharOfName = name.trim().substr(0, 3).toLowerCase();
          const firstThreeCharOfEquipmentId = mechademyEquipmentId
            .trim()
            .substr(0, 3)
            .toLowerCase();
          if (firstThreeCharOfName !== firstThreeCharOfEquipmentId) {
            this.props.form.setFieldsValue({
              name: name.trim(),
              mechademyEquipmentId: code,
              clientEquipmentId: code,
            });
          }
        }
      }
    });
  }

  formFields = (validate) => [
    {
      label: ClientEquipmentVariables.EQUIPMENT_NAME,
      key: 'name',
      max: '50',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
      pattern: !this.state.addModalVisible && [
        Pattern.Name.regx,
        Pattern.Name.message,
      ],
      blur: (e) => {
        this.getCode(e.target.value);
      },
    },
    {
      label: ClientEquipmentVariables.MECHADEMY_EQUIPMENT_CODE,
      key: 'mechademyEquipmentId',
      max: '25',
      pattern: !this.state.addModalVisible && [
        Pattern.Code.regx,
        Pattern.Code.message,
      ],
    },
    {
      label: ClientEquipmentVariables.CLIENT_EQUIPMENT_CODE,
      key: 'clientEquipmentId',
      max: '25',
      pattern: !this.state.addModalVisible && [
        Pattern.Code.regx,
        Pattern.Code.message,
      ],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_TYPE,
      key: 'type',
      type: 'dropdown',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_TAG_NUMBWR,
      key: 'tag',
      max: '50',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
      pattern: !this.state.addModalVisible && [
        Pattern.Name.regx,
        Pattern.Name.message,
      ],
    },
    {
      label: ClientEquipmentVariables.MANUFACTURER,
      key: 'manufacturer',
      type: 'dropdown',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
    },
    {
      label: ClientEquipmentVariables.MODEL,
      key: 'model',
      max: '50',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
      pattern: !this.state.addModalVisible && [
        Pattern.Name.regx,
        Pattern.Name.message,
      ],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_SERIAL_NO,
      key: 'serialNo',
      max: '50',
      pattern: !this.state.addModalVisible && [
        Pattern.Name.regx,
        Pattern.Name.message,
      ],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_STANDARD,
      key: 'standard',
      max: '70',
      pattern: !this.state.addModalVisible && [
        Pattern.Name.regx,
        Pattern.Name.message,
      ],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_TEST_DATE,
      type: 'calender',
      key: 'testDate',
      required: [false, null, 'object'],
      pattern: false,
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_DESCRIPTION,
      type: 'textarea',
      key: 'description',
      max: '250',
      required: [!validate, EquipmentMessage.REQUIRED_FIELD],
      style: { width: '65%' },
    },
  ];

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        visible: true,
        message: null,
        title: null,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { mode, demandPage, demandPageData } = this.state;
    this.disableEditForm();
    this.setState(
      {
        visible: false,
      },
      () => {
        if (mode === 'edit' && !demandPage) {
          this.setState({ mode: 'view' }, () => {
            this.setState({
              formDisable: true,
            });
          });
          this.props.form.resetFields();
          this.setFormData();
        } else {
          this.props.history.push(demandPage, demandPageData);
        }
      }
    );
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  validateFieldsValue() {
    const values = this.props.form.getFieldsValue();
    const valuesKey = Object.keys(this.props.form.getFieldsValue());

    for (let i = 0; i < valuesKey.length; i++) {
      values[valuesKey[i]] =
        values[valuesKey[i]] && valuesKey[i] !== 'testDate'
          ? values[valuesKey[i]].trim()
          : values[valuesKey[i]];
    }
    this.props.form.setFieldsValue(values);
  }

  setFormData() {
    const { equipmentData } = this.state;
    const temp = equipmentData.testDate;
    const tempData = { ...equipmentData };
    if (tempData.testDate) {
      tempData.testDate = moment(temp);
    }
    if (tempData && tempData._id) {
      this.props.form.resetFields();
      this.props.form.setFieldsValue(tempData, () => {
        this.disableEditForm();
      });
    }
  }

  onCancel() {
    const { mode } = this.state;
    const {
      location: { state },
    } = this.props;

    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        demandPage: mode === 'edit' ? null : Routes.clientSystem,
        demandPageData: {
          systemData: state.systemData,
          facilityData: state.facilityData,
          clientData: state.clientData,
          mode: 'view',
        },
      });
    } else if (mode === 'add') {
      this.props.history.push(Routes.clientSystem, {
        systemData: state.systemData,
        facilityData: state.facilityData,
        clientData: state.clientData,
        mode: 'view',
      });
    } else {
      this.setState({ mode: 'view' }, () => {
        this.setState({
          formDisable: true,
        });
      });
      this.props.form.resetFields();
      this.setFormData();
    }
  }

  createSchematic() {
    const {
      location: { state },
    } = this.props;
    const { equipmentData } = this.state;
    if (this.handleNetwork()) {
      this.props.history.push(Routes.componentSchematic, {
        ...state,
        equipmentData,
      });
    }
  }

  onEdit() {
    if (this.handleNetwork()) {
      this.setState({ mode: 'edit' }, () => {
        this.setState({ formDisable: false });
      });
    }
  }

  saveMore() {
    this.setState({ addMore: true }, () => {
      this.saveData();
    });
  }

  save() {
    this.setState({ addMore: false }, () => {
      this.saveData();
    });
  }

  saveData() {
    const { addEquipment, addModalVisible } = this.props;
    const {
      location: { state },
    } = this.props;
    this.validateFieldsValue();

    !addModalVisible &&
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.handleNetwork()) {
            values.systemId = state.systemData._id;
            if (this.state.testDate && values.testDate) {
              values.testDate = values.testDate.format('DD-MM-YYYY');
            } else {
              delete values.testDate;
            }
            addEquipment(values);
          }
        } else {
        }
      });
  }

  update() {
    const { editEquipment, addModalVisible } = this.props;
    const {
      location: { state },
    } = this.props;
    const { equipmentData } = this.state;
    this.validateFieldsValue();

    if (!this.state.formEdit) {
      this.onCancel();
      return;
    }
    !addModalVisible &&
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (values.testDate) {
            values.testDate = values.testDate.format('YYYY-MM-DD');
          } else {
            delete values.testDate;
          }
          if (this.handleNetwork()) {
            editEquipment(values, equipmentData._id, state.systemData._id);
          }
        } else {
        }
      });
  }

  getEquipmentName() {
    const { equipmentData } = this.state;
    return equipmentData && equipmentData.name
      ? equipmentData.name
      : ClientEquipmentVariables.ADD_EQUIPMENT;
  }

  renderBreadCrumb() {
    const { clientInfoData, facilityData, systemData } = this.state;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <label onClick={() => this.navigateToScreen(Routes.clients)}>
            Clients
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData: clientInfoData,
                mode: 'view',
              })
            }
          >
            {clientInfoData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData: clientInfoData,
                facilityData,
                mode: 'view',
              })
            }
          >
            {facilityData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientSystem, {
                clientData: clientInfoData,
                facilityData,
                systemData,
                mode: 'view',
              })
            }
          >
            {systemData.systemName}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{this.getEquipmentName()}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
          title={this.state.title}
        />
      );
    }
    return null;
  }

  renderFormButton() {
    const { mode, facilityData } = this.state;
    const facilityId = facilityData['_id'];

    if (mode === 'add') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button className="btn-default" onClick={() => this.save()}>
            {ButtonVariables.SAVE}
          </Button>
          <Button className="btn-default" onClick={() => this.saveMore()}>
            {ButtonVariables.SAVE_MORE}
          </Button>
        </div>,
        ElementPermissions.ADD_EQUIPMENT,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    if (mode === 'edit') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button
            className="btn-default"
            onClick={() => {
              this.update();
            }}
          >
            {ButtonVariables.UPDATE}
          </Button>
        </div>,
        ElementPermissions.EDIT_EQUIPMENT,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    if (mode === 'view') {
      return elementsGaurd(
        <Button
          className="btn-default"
          onClick={() => {
            this.onEdit();
          }}
        >
          {ButtonVariables.EDIT}
        </Button>,
        ElementPermissions.EDIT_EQUIPMENT,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
  }

  getOption(data) {
    return (
      <Option value={data.nameId}>{data.type ? data.type : data.name}</Option>
    );
  }

  openAddModal(key) {
    this.setState({
      addModalType: key,
      addModalVisible: true,
    });
  }

  closeAddModal = () => {
    this.setState({
      addModalVisible: false,
      addNewValue: null,
    });
  };

  setAddNewValue(value) {
    this.setState({ addNewValue: value });
  }

  saveAddModalValue = () => {
    const { addModalType, addNewValue, addModalVisible } = this.state;
    const { addEquipmentType, addManufacturer } = this.props;

    addModalVisible &&
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.handleNetwork()) {
            if (
              addNewValue &&
              addNewValue.trim() &&
              addNewValue.trim().length
            ) {
              if (addModalType === 'type') {
                addEquipmentType({ type: addNewValue });
              }
              if (addModalType === 'manufacturer') {
                addManufacturer({ name: addNewValue });
              }
            }
          }
        }
      });
  };

  visibleCalender = () => {
    if (this.state.mode === 'add' || this.state.mode === 'edit') {
      this.setState({ isCalenderVisible: true });
    }
  };

  closeCalender = () => {
    this.setState({ isCalenderVisible: false });
  };

  onSelectDate = (value, key) => {
    this.setState({ testDate: value, isCalenderVisible: false });
  };

  handleClick = (e) => {
    if (this.node.current && this.node.current.contains(e.target)) {
      return;
    }
    this.closeCalender();
  };

  disabledDate = (current) => !current || current.isAfter();

  renderAddModal() {
    const { addModalType, addModalVisible } = this.state;

    return (
      <Modal
        title={
          addModalType === 'type'
            ? ClientEquipmentVariables.ADD_NEW_EQUIPMENT_TYPE
            : ClientEquipmentVariables.ADD_NEW_MANUFACTURER
        }
        centered
        visible={addModalVisible}
        onOk={this.saveAddModalValue}
        onCancel={this.closeAddModal}
        okText="Save"
        cancelText="Cancel"
      >
        <Form>
          <Form.Item>
            {addModalVisible &&
              this.props.form.getFieldDecorator('addNewName', {
                rules: [
                  { required: true, message: ERROR_MESSAGES.REQUIRED_FIELD },
                  { pattern: Pattern.Name.regx, message: Pattern.Name.message },
                ],
              })(
                <Input
                  onChange={(e) => {
                    this.setAddNewValue(e.target.value);
                  }}
                  maxLength={70}
                />
              )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    if (field.type) {
      if (field.type === 'dropdown') {
        let data = [];
        if (field.key === 'type') {
          data = this.state.equipmentTypes;
        } else if (field.key === 'manufacturer') {
          data = this.state.manufacturers;
        }
        return (
          <Select
            onChange={(e) => {}}
            disabled={formDisable}
            showArrow={!formDisable}
            showSearch
            placeholder="Search here"
            className="dropdown-search-field"
            dropdownRender={(menu) => (
              <div>
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    this.openAddModal(field.key);
                  }}
                >
                  <Icon type="plus" />
                  {field.key === 'type'
                    ? ClientEquipmentVariables.ADD_NEW_EQUIPMENT_TYPE
                    : ClientEquipmentVariables.ADD_NEW_MANUFACTURER}
                </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </div>
            )}
          >
            {data.map((data) => this.getOption(data))}
          </Select>
        );
      }
      if (field.type === 'textarea') {
        return (
          <TextArea
            rows={5}
            maxLength={field.max ? field.max : ''}
            disabled={formDisable}
          />
        );
      }
      if (field.type === 'calender') {
        return (
          <DatePicker
            showToday={false}
            disabled={formDisable}
            disabledDate={this.disabledDate}
            format="DD-MM-YYYY"
          />
        );
      }
      return (
        <Input
          maxLength={field.max ? field.max : ''}
          onBlur={field.blur ? field.blur : () => {}}
          autoComplete="off"
          disabled={formDisable}
        />
      );
    }
    return (
      <Input
        maxLength={field.max ? field.max : ''}
        onBlur={field.blur ? field.blur : () => {}}
        autoComplete="off"
        disabled={formDisable}
      />
    );
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        {this.formFields(this.state.addModalVisible).map((field) => (
          <Form.Item label={field.label} style={field.style}>
            {getFieldDecorator(field.key, {
              rules:
                field.key === 'testDate'
                  ? [{ required: false, type: 'object' }]
                  : [
                      field.required
                        ? {
                            required: field.required[0],
                            message: field.required[1],
                          }
                        : '',
                      field.pattern && !this.state.addModalVisible
                        ? {
                            pattern: field.pattern[0],
                            message: field.pattern[1],
                          }
                        : '',
                    ],
            })(this.renderFieldType(field))}
          </Form.Item>
        ))}
        <div className="action-btn">{this.renderFormButton()}</div>
      </Form>
    );
  }

  renderSchematicButton() {
    const { equipmentData, facilityData } = this.state;
    const facilityId = facilityData['_id'];
    if (equipmentData.schematic) {
      return elementsGaurd(
        <Button
          className="btn-default"
          onClick={() => {
            this.createSchematic();
          }}
        >
          {ButtonVariables.VIEW_COMPONENT_SCHEMATIC}
        </Button>,
        ElementPermissions.VIEW_SCHEMATIC,
        {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    return elementsGaurd(
      <Button
        className="btn-default"
        onClick={() => {
          this.createSchematic();
        }}
      >
        {ButtonVariables.CREATE_COMPONENT_SCHEMATIC}
      </Button>,
      ElementPermissions.ADD_SCHEMATIC,
      {
        type: 'facility',
        dataRef: {
          _id: facilityId,
        },
      }
    );
  }

  render() {
    const { mode } = this.state;
    const { isLoading } = this.props.equipmentState;
    return (
      <div className="ClientEquipment">
        {isLoading ? <Loader /> : null}
        <div className="header" style={MaskStyle}>
          <div className="title">{this.getEquipmentName()}</div>
          <div className="path">
            {this.renderBreadCrumb()}
            {mode === 'view' ? this.renderSchematicButton() : ''}
          </div>
        </div>
        <div className="screen-content">
          <div className="content-div">{this.renderForm()}</div>
        </div>
        {this.renderModal()}
        {this.renderAddModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  equipmentState: state.EquipmentReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  editEquipment: (payload, id, systemId) =>
    dispatch(editEquipment(payload, id, systemId)),
  addEquipment: (payload) => dispatch(addNewEquipment(payload)),
  getEquipment: (id) => dispatch(getEquipment(id)),
  getEquipmentType: () => dispatch(getEquipmentType()),
  addEquipmentType: (payload) => dispatch(addEquipmentType(payload)),
  getManufacturer: () => dispatch(getManufacturer()),
  addManufacturer: (payload) => dispatch(addManufacturer(payload)),
  clearEquipmentState: () => dispatch(clearEquipmentState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'clientEquipment',
    onFieldsChange: () => {
      window.dispatchEvent(new Event('form-edit'));
    },
  })(withRouter(ClientEquipment))
);
