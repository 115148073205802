import Type from '../Types/materialEnvironment.types';
import { GETRequest , PUTRequest } from '../../api/request';

export function getMaterialEnvironment() {
    return function (dispatch) {
      dispatch({ type:Type.MATERIAL_ENVIRON_FETCH_PROGRESS});
      GETRequest("materialEnvironment")
        .then((res) => {
          dispatch({ type: Type.MATERIAL_ENVIRON_FETCH_SUCCESS, payload: res.body });
        })
        .catch((err) => {
          dispatch({ type: Type.MATERIAL_ENVIRON_FETCH_ERROR, payload: err });
        });
    };
}

// export function updateMaterialEnvironment() {
//     return function (dispatch) {
//       dispatch({ type:Type.MATERIAL_ENVIRON_FETCH_PROGRESS});
//       GETRequest("materialEnvironment")
//         .then((res) => {
//           dispatch({ type: Type.MATERIAL_ENVIRON_FETCH_SUCCESS, payload: res.body });
//         })
//         .catch((err) => {
//           dispatch({ type: Type.MATERIAL_ENVIRON_FETCH_ERROR, payload: err });
//         });
//     };
// }

export function updateMaterialEnvironment(payload) {
    return function (dispatch) {
      dispatch({ type: Type.MATERIAL_ENVIRON_UPDATE_PROGRESS });
      PUTRequest(
        "materialEnvironment",
        payload
      )
        .then((res) =>
          dispatch({
            type: Type.MATERIAL_ENVIRON_UPDATE_SUCCESS,
            payload: res.body,
          })
        )
        .catch((err) =>
          dispatch({ type: Type.MATERIAL_ENVIRON_UPDATE_ERROR, payload: err })
        );
    };
}
  
export function clearMaterialEnvironmentState() {
    return function (dispatch) {
      dispatch({ type: Type.MATERIAL_ENVIRON_REDUCER_RESET });
    };
  }