import { Empty, Pagination, Button } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Table from '../../common/CustomTable';
import HeaderNav from '../../common/HeaderNav';
import Modal from '../../common/Modal/Modal';
import ReasonModal from '../../common/Modal/Modalreason';
import Notification, { Types } from '../../common/Notification/Notification';
import { ML_ALGO_MSG, NO_INTERNET } from '../../constants/messages';
import { MaskStyle } from '../../constants/react-style';
import {
  ButtonVariables,
  ML_ALGORITHM_VARIABLE,
  ML_ALGORITHM_FILTER_HEADING,
  ML_ALGORITHM_ALGO_TYPES,
  ML_ALGORITHM_STATUS
} from '../../constants/variables';
import { elementsGaurd } from '../../gaurds';
import { clientList } from '../../redux/actions/client.action';
import {
  deployMlModal,
  getMlList,
  resetMlState,
  clearMLModalListState,
  deployMlModalBulk,
  resetBulkDeployState,
} from '../../redux/actions/mlAlgorithm.action';
import AddAlgo from './AddAlgo';
import { FILE_STATUS, ML_TABLE } from './ML_Algorithm.constant';
import { ElementPermissions } from '../../permissions';
import './ML_Algorithm.scss';
import Loader from '../../common/Loader/Loader';
import { populateInitialValues } from '../../common/methods';

const IS_FILTER_OPEN = -1;
const PAGINATED_VALUE = 10;
const INITIAL_SKIP = 0;
class ML_Algorithm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isModal: false,
      isReason: false,
      isAlgoModal: false,
      clientId: '',
      facilityId: '',
      algoType: '',
      codeVersion: '',
      limit: PAGINATED_VALUE,
      keyword: '',
      skip: INITIAL_SKIP,
      editModelData: {},
      modelId: '',
      mode: '',
      modelName:'',
      isDeployed: false,
      algoAcessList: [],
      modelIdArray: [],
      bulkDeploy: false,
      deployLoading: false,
      undeployLoading: false,
      hasFilterActive: false,
      expandedIndex: IS_FILTER_OPEN,
      algoTypeFilterValues: { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      algoTypeFilterState: { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      statusFilterValues: { ...populateInitialValues(ML_ALGORITHM_STATUS) },
      statusFilterState: { ...populateInitialValues(ML_ALGORITHM_STATUS) },
    };
  }

  componentDidMount() {
    const algoType = localStorage.getItem('algoTypeFilter') ? JSON.parse(localStorage.getItem('algoTypeFilter')) : null;
    const status = localStorage.getItem('statusFilter') ? JSON.parse(localStorage.getItem('statusFilter')) : null;
    const selectedAlgoType = algoType ? this.mapObjtoArray(algoType) : [];
    const selectedStatus = status ? this.mapObjtoArray(status) : [];
    const mlAlgoPage = localStorage.getItem('mlAlgoPage');
    const selectedKeyword = localStorage.getItem('mlAlgoKeyword') ? localStorage.getItem('mlAlgoKeyword') : '';
    const skip = mlAlgoPage ? PAGINATED_VALUE * (mlAlgoPage - 1) : this.state.skip;
    if (selectedAlgoType.length || selectedStatus.length) {
      this.setState({
        hasFilterActive: true,
    });
    } else {
      this.setState({
        hasFilterActive: false,
      });
    }
    if (mlAlgoPage) {
      this.setState({
        skip: PAGINATED_VALUE * (mlAlgoPage - 1),
      });
    }
    const reqObj = {
      skip: skip,
      limit: PAGINATED_VALUE,
      keyword: selectedKeyword ? selectedKeyword : this.state.keyword,
      algoType: selectedAlgoType,
      status:selectedStatus
    };
    if (this.handleNetwork()) {
      this.props.getMlList(reqObj);
      this.props.clientList({});
    }
    this.evaluateAccess();
    this.setState({
      algoTypeFilterValues: algoType ? { ...algoType } : { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      algoTypeFilterState: algoType ? { ...algoType } : { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      statusFilterValues: status ? { ...status } : { ...populateInitialValues(ML_ALGORITHM_STATUS) },
      statusFilterState: status ? { ...status } : { ...populateInitialValues(ML_ALGORITHM_STATUS) },
      keyword: selectedKeyword ? selectedKeyword : this.state.keyword,
    });
  }

  mapValuesToState = () => {
    this.setState({
      algoTypeFilterState: { ...this.state.algoTypeFilterValues },
      statusFilterState: { ...this.state.statusFilterValues }
    });
  };

  toggleFilter = (filterHeadName, value) => {
    const {
      algoTypeFilterState,
      statusFilterState
    } = this.state;
    if (filterHeadName === ML_ALGORITHM_FILTER_HEADING[0]) {
      const filters = { ...algoTypeFilterState };
      filters[value] = !algoTypeFilterState[value];
      this.setState({
        algoTypeFilterState: filters,
      });
    } else if (filterHeadName === ML_ALGORITHM_FILTER_HEADING[1]) {
      const filters = { ...statusFilterState };
      filters[value] = !statusFilterState[value];
      this.setState({
        statusFilterState: filters,
      });
    }
  };

  // componentWillUnmount() {
  //   this.handleNetwork() && this.props.resetMlState();
  // }

  handleNetwork = () => {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  };

  payload = () => {
      const {
        algoTypeFilterState,
        statusFilterState,
      } = this.state;
  
      const selectedAlgoType = this.mapObjtoArray(algoTypeFilterState);
      const selectedStatus = this.mapObjtoArray(statusFilterState);

      return {
        skip: this.state.skip,
        limit: PAGINATED_VALUE,
        keyword: this.state.keyword,
        algoType: selectedAlgoType,
        status:selectedStatus
      };
  };

  toggleAlgoModal = (item, mode) => {
    this.setState({
      isAlgoModal: !this.state.isAlgoModal,
      editModelData: item,
      mode,
    });
  };

  searchMl = (searchValue) => {
    localStorage.setItem('mlAlgoKeyword', searchValue);
    if (searchValue === '') localStorage.removeItem('mlAlgoKeyword');
    localStorage.removeItem('mlAlgoPage');
    this.setState({
      skip: INITIAL_SKIP,
      keyword: searchValue,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      deployModelReducer,
      getMlList,
      mlModalList,
      clearMLModalListState,
      deployModelBulkReducer,
      resetBulkDeployState,
    } = this.props;
    const { keyword, skip } = this.state;
    if (keyword !== prevState.keyword || skip !== prevState.skip) {
      this.handleNetwork() && getMlList(this.payload());
    }

    if (
      deployModelReducer.isSuccess &&
      deployModelReducer.isSuccess !== prevProps.deployModelReducer.isSuccess
    ) {
      Notification.show(Types.Success, ML_ALGO_MSG.STATUS_CHANGE_SUCCESS);
      this.handleNetwork() && getMlList(this.payload());
    } else if (
      deployModelReducer.isError &&
      deployModelReducer.isError !== prevProps.deployModelReducer.isError
    ) {
      Notification.show(Types.Error, deployModelReducer.message);
    }

    if (
      mlModalList.isError &&
      mlModalList.isError !== prevProps.mlModalList.isError
    ) {
      Notification.show(Types.Error, ML_ALGO_MSG.UNABLE_TO_FETCH_ML_LIST);
    }
    if (
      mlModalList.isSuccess &&
      mlModalList.isSuccess !== prevProps.mlModalList.isSuccess
    ) {
      clearMLModalListState();
      this.evaluateAccess();
    }
    if (
      deployModelBulkReducer.isSuccess &&
      deployModelBulkReducer.isSuccess !==
        prevProps.deployModelBulkReducer.isSuccess
    ) {
      this.setState({ deployLoading: false, undeployLoading: false });
      resetBulkDeployState();
      Notification.show(Types.Success, ML_ALGO_MSG.STATUS_CHANGE_SUCCESS);
      this.handleNetwork() && getMlList(this.payload());
    } else if (
      deployModelBulkReducer.isError &&
      deployModelBulkReducer.isError !==
        prevProps.deployModelBulkReducer.isError
    ) {
      this.setState({ deployLoading: false, undeployLoading: false });
      resetBulkDeployState();
      Notification.show(Types.Error, ML_ALGO_MSG.TRY_AGAIN);
    }
  }

  onPagination = (value) => {
    localStorage.setItem('mlAlgoPage', value);
    this.setState({
      skip: (value - 1) * 10,
    });
  };

  rowClick = (item, mode) => {
    this.toggleAlgoModal(item, mode);
  };

  deleteAlgo = (id, value) => { 
    
    const {mlModalList} = this.props
    mlModalList.data.forEach((ele)=>{
      if(ele._id === id)
      {
       this.setState({
        modelName:ele.name,
       })
      }
    })
    
    this.setState({
      isModal: true,
      isReason: false,
      modelId: id,
      isDeployed: value,
    });
  };

  changeModalStatus = (reason,userInfo) => {
    const { bulkDeploy, algoAcessList, modelId, isDeployed } = this.state;
    const { deployMlModalBulk, deployMlModal } = this.props;
    const payload ={
      reason:reason,
      userInfo:userInfo
    }
    if (isDeployed && bulkDeploy) {
      this.setState({
        deployLoading: true,
      });
    } else if (!isDeployed && bulkDeploy) {
      this.setState({
        undeployLoading: true,
      });
    }
    if (this.handleNetwork()) {
      if (bulkDeploy && algoAcessList) {
        deployMlModalBulk({ modelId: algoAcessList, status: isDeployed });
        this.setState({ bulkDeploy: false });
      } else {
        deployMlModal({id:modelId,payload});
        this.setState({ isModal: false, isReason: false});
      }
    }
  };

  reasonDialog = () => {
    this.setState({
      isReason: true,
      isModal: false,
    });
  };


  handleCancel = () => {
    this.setState({
      isModal: false,
      isReason: false,
      modelId: '',
      bulkDeploy: false,
    });
  };

  goBack = () => {
    this.setState(
      {
        isEdit: false,
        isModal: false,
        isAlgoModal: false,
        clientId: '',
        facilityId: '',
        algoType: '',
        codeVersion: '',
        limit: PAGINATED_VALUE,
        keyword: '',
        editModelData: {},
        modelId: '',
      },
      () => {
        if (this.handleNetwork()) {
          this.props.getMlList(this.payload());
          this.props.clientList({});
        }
      }
    );
  };

  evaluateAccess = () => {
    const { mlModalList } = this.props;
    let mlAlgoAcessId = [];
    if (mlModalList && mlModalList.data) {
      mlModalList.data.forEach((element) => {
        const isAccessForElement = elementsGaurd(
          true,
          ElementPermissions.EDIT_ML_ALGO,
          {
            type: 'facility',
            dataRef: {
              _id: element.facilityId._id,
            },
          }
        );
        if (
          isAccessForElement &&
          element.fileStatus === FILE_STATUS.Completed
        ) {
          mlAlgoAcessId.push(element._id);
        }
      });
    }
    this.setState({ algoAcessList: mlAlgoAcessId });
  };

  deployBulk = (value) => {
    this.setState({
      isDeployed: value,
      bulkDeploy: true,
    });
  };

  setExpandedIndex = (index) => {
    const expandedIndex =
      index === this.state.expandedIndex ? IS_FILTER_OPEN : index;
    this.setState({ expandedIndex });
  };

  /** mapping obj with true values into array */
  mapObjtoArray = (obj) => {
    const resultArray = [];
    Object.keys(obj).forEach((keys) => {
      if (obj[keys]) {
        resultArray.push(keys);
      }
    });
    return resultArray;
  };

  applyFilter = () => {

    const {
      algoTypeFilterState,
      statusFilterState,
    } = this.state;

    localStorage.setItem('algoTypeFilter', JSON.stringify(algoTypeFilterState));
    localStorage.setItem('statusFilter', JSON.stringify(statusFilterState));

    const selectedAlgoType = this.mapObjtoArray(algoTypeFilterState);
    const selectedStatus = this.mapObjtoArray(statusFilterState);

    if (selectedAlgoType.length || selectedStatus.length) {
      this.setState({
        hasFilterActive: true,
    });
    } else {
      this.setState({
        hasFilterActive: false,
      });
    }
    this.props.getMlList(this.payload());
    this.setState({
      algoTypeFilterValues: { ...algoTypeFilterState },
      statusFilterValues: { ...statusFilterState },
      skip: INITIAL_SKIP,
    });
  };

  resetFilter = () => {
    this.setState({
      algoTypeFilterValues: { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      algoTypeFilterState: { ...populateInitialValues(ML_ALGORITHM_ALGO_TYPES) },
      statusFilterValues: { ...populateInitialValues(ML_ALGORITHM_STATUS) },
      statusFilterState: { ...populateInitialValues(ML_ALGORITHM_STATUS) },
      skip: INITIAL_SKIP,
      hasFilterActive: false,
    });
   const reqObj = {
      skip: this.state.skip,
      limit: PAGINATED_VALUE,
      keyword: this.state.keyword,
    };
    this.props.getMlList(reqObj);
    localStorage.removeItem('algoTypeFilter');
    localStorage.removeItem('statusFilter');
  };

  render() {
    const { mlModalList, deployModelReducer, deployModelBulkReducer } =
      this.props;
    const { algoAcessList, bulkDeploy , modelName } = this.state;
    const algoStatusAcess = algoAcessList ? true : false;
    const selectedKeyword = localStorage.getItem('mlAlgoKeyword');
    return (
      <>
        {/* {(mlModalList.isLoading || deployModelBulkReducer.isLoading) && (
          <Loader />
        )} */}
        {this.state.isAlgoModal ? (
          <>
            <AddAlgo
              goBackTOMlList={this.goBack}
              editModel={this.state.editModelData}
              mode={this.state.mode}
            />
          </>
        ) : (
          <div className="ml-algo-wrapper upload-pi-tags-wrapper ClientDetails">
            <div className="header-content" style={MaskStyle}>
              <div className="pi-tag-header ">
                <div className="pi-tag-title">
                  {ML_ALGORITHM_VARIABLE.HEADER_TITLE}
                  <HeaderNav
                    placeholder={'Search here (Model Name)'}
                    onSearch={this.searchMl}
                    keyword={selectedKeyword}
                    isSort={false}
                    isExpandableFilter
                    headLabels={ML_ALGORITHM_FILTER_HEADING}
                    childLabels={[this.state.algoTypeFilterState, this.state.statusFilterState]}
                    onChildPress={this.toggleFilter}
                    onToggleOpen={(isVisible) => {
                      if (!isVisible) {
                        this.mapValuesToState();
                      }
                    }}
                    onHeadPress={this.setExpandedIndex}
                    expandedIndex={this.state.expandedIndex}
                    onApply={this.applyFilter}
                    onReset={this.resetFilter}
                    hasFilterActive={this.state.hasFilterActive}
                  />
                </div>
                <div className="path">
                  <Button
                    className="ml-algo-add-btn btn-default"
                    onClick={() => this.deployBulk(true)}
                  >
                    {ML_ALGORITHM_VARIABLE.DEPLOY}
                  </Button>
                </div>
                &nbsp;&nbsp;
                <div className="path">
                  <Button
                    className="ml-algo-add-btn btn-default"
                    onClick={() => this.deployBulk(false)}
                  >
                    {ML_ALGORITHM_VARIABLE.UNDEPLOY}
                  </Button>
                </div>
                &nbsp;&nbsp;
                <div className="path">
                  <button
                    className="ml-algo-add-btn btn-default"
                    onClick={() => this.toggleAlgoModal({})}
                  >
                    {ButtonVariables.CREATE_MODEL}
                  </button>
                </div>
              </div>
            </div>

            <div className="screen-content">
              <div className="content-div">
                <div className="ml-table">
                  <Table
                    showHeader={true}
                    columns={ML_TABLE({
                      delete: this.deleteAlgo,
                      rowClick: this.rowClick,
                      algoStatusAcess: algoStatusAcess,
                      deployBulk: this.deployBulk,
                    })}
                    data={mlModalList.data}
                  />
                  {deployModelReducer.isLoading ||
                  deployModelBulkReducer.isLoading ? (
                    <Loader />
                  ) : null}
                  <div className="ml-table-footer">
                    <Pagination
                      current={this.state.skip / 10 + 1}
                      pageSize={PAGINATED_VALUE}
                      total={mlModalList.totalCount}
                      onChange={(val) => this.onPagination(val)}
                      hideOnSinglePage={true}
                    />
                  </div>
                  {!mlModalList.isLoading && _.isEmpty(mlModalList.data) && (
                    <Empty />
                  )}
                </div>
              </div>
            </div>
            {this.state.isModal && (
              <Modal
                title={ML_ALGO_MSG.MODAL_TITLE}
                visible={this.state.isModal}
                handleOk={this.reasonDialog}
                handleCancel={this.handleCancel}
                message={
                  this.props.mlModalList.data[this.state.isDeployed].isDeployed
                    ? ML_ALGO_MSG.CONFIRMATION_MSG_UNDEPLOY_TOGGLE
                    : ML_ALGO_MSG.CONFIRMATION_MSG_DEPLOY_TOGGLE
                }
              />
            )}

            {bulkDeploy && (
              <Modal
                title={ML_ALGO_MSG.MODAL_TITLE}
                visible={this.state.bulkDeploy}
                handleOk={this.changeModalStatus}
                handleCancel={this.handleCancel}
                message={ML_ALGO_MSG.PROCEED}
              />
            )}
            
            {this.state.isReason && (
              <ReasonModal
                title={ML_ALGO_MSG.MODAL_TITLE}
                visible={this.state.isReason}
                handleOk={this.changeModalStatus}
                handleCancel={this.handleCancel}
                message={
                  this.props.mlModalList.data[this.state.isDeployed].isDeployed
                    ? ML_ALGO_MSG.REASON_UNDEPLOY 
                    : ML_ALGO_MSG.REASON_DEPLOY 
                }
                modelName={modelName}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

ML_Algorithm.propTypes = {
  clientState: PropTypes.object.isRequired,
  loginState: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  mlModalList: PropTypes.object.isRequired,
  saveModalReducer: PropTypes.object.isRequired,
  deployModelReducer: PropTypes.object.isRequired,
  clientList: PropTypes.func.isRequired,
  getMlList: PropTypes.func.isRequired,
  deployMlModal: PropTypes.func.isRequired,
  resetMlState: PropTypes.func,
  clearMLModalListState: PropTypes.func.isRequired,
  deployMlModalBulk: PropTypes.func.isRequired,
  resetBulkDeployState: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  network: state.NetworkReducer,
  mlModalList: state.MlAlgorithmReducer.getMlModalList,
  deployModelReducer: state.MlAlgorithmReducer.deployMlModal,
  deployModelBulkReducer: state.MlAlgorithmReducer.deployMlModelBulk,
});

const mapDispatchToProps = {
  clientList,
  getMlList,
  deployMlModal,
  resetMlState,
  clearMLModalListState,
  deployMlModalBulk,
  resetBulkDeployState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ML_Algorithm);
