import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import { validatePositiveFloat} from "../../../../../../common/methods";
import { newValidateNumber } from "../../../../../../libs/validator";
import { PerformaceCurvesVariables } from "./../../../../../../constants/variables";

export const tableHeader = tableHeader => {
  return [
    {
      title: PerformaceCurvesVariables.load,
      dataIndex: "load",
      render: (value, row, col, disabled,item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="load"
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                className={(value && !validatePositiveFloat(value.toString())) 
                  || (!item.load && !item.slip && !item.efficiency && !item.powerFactor && !item.current) 
                  ? "error" : "not-error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.efficiency,
      dataIndex: "efficiency",
      render: (value, row, col, disabled,item) => {          
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                
                id="efficiency"
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={(value && !validatePositiveFloat(value.toString())) 
                  || (!item.load && !item.slip && !item.efficiency && !item.powerFactor && !item.current) 
                  ? "error" : "not-error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.powerFactor,
      dataIndex: "powerFactor",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"

                id="powerFactor"
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={(value && !validatePositiveFloat(value.toString())) 
                  || (!item.load && !item.slip && !item.efficiency && !item.powerFactor && !item.current) 
                  ? "error" : "not-error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.current,
      dataIndex: "current",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                
                id="current"
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={(value && !validatePositiveFloat(value.toString())) 
                  || (!item.load && !item.slip && !item.efficiency && !item.powerFactor && !item.current)
                  ? "error" : "not-error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.slip,
      dataIndex: "slip",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                
                id="slip"
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={(value && !validatePositiveFloat(value.toString())) 
                  || (!item.load && !item.slip && !item.efficiency && !item.powerFactor && !item.current) 
                  ? "error" : "not-error"}
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: PerformaceCurvesVariables.actions,
      dataIndex: "actions",
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = input => {
  if (input.length !== 15) {
   
    return [
      {
        render: () => {
          let value = _.isNil(input.load) ? "" : input.load.toString();
          return (
            <div>
              <Input
                id="load"
                autoComplete="off" 
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                value={input.load}
                onChange={value => input.handleChangeTableData(value)}
                className={(!_.isEmpty(value) && input.eventListener) && !validatePositiveFloat(value)  ? "error" : "not-error"}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.efficiency) ? "" : input.efficiency.toString();
          return (
            <div>
              <Input
                id="efficiency"
                autoComplete="off"
                
                onKeyDown={e => {
                  newValidateNumber(e);
                }}
                value={input.efficiency}
                onChange={value => input.handleChangeTableData(value)}
                className={(!_.isEmpty(value) && input.eventListener) && !validatePositiveFloat(value)  ? "error" : "not-error"}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.powerFactor) ? "" : input.powerFactor.toString();
          return (
            <Input
              id="powerFactor"
              autoComplete="off"
              
              onKeyDown={e => {
                newValidateNumber(e);
              }}
              value={input.powerFactor}
              onChange={value => input.handleChangeTableData(value)}
              className={(!_.isEmpty(value) && input.eventListener) && !validatePositiveFloat(value)  ? "error" : "not-error"}
            />
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.current) ? "" : input.current.toString();
          return (
            <Input
              id="current"
              autoComplete="off"
              
              onKeyDown={e => {
                newValidateNumber(e);
              }}
              value={input.current}
              onChange={value => input.handleChangeTableData(value)}
              className={(!_.isEmpty(value) && input.eventListener) && !validatePositiveFloat(value)  ? "error" : "not-error"}
            />
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.slip) ? "" : input.slip.toString();
          return (
            <Input
              id="slip"
              autoComplete="off"
              
              onKeyDown={e => {
                newValidateNumber(e);
              }}
              value={input.slip}
              onChange={value => input.handleChangeTableData(value)}
              className={(!_.isEmpty(value)) && !validatePositiveFloat(value)  ? "error" : "not-error"}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = input => {
  return [
    {
      render: () => {
        const tempError = !input.loadUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              value={!input.loadUnit ? "" : input.loadUnit}
              id="loadUnit"
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Load") {
                  // eslint-disable-next-line
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.efficiencyUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="efficiencyUnit"
              value={!input.efficiencyUnit ? "" : input.efficiencyUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Load") {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.powerFactorUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="powerFactorUnit"
              value={!input.powerFactorUnit ? "" : input.powerFactorUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable === "Performance curve - Power Factor"
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.currentUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="currentUnit"
              value={!input.currentUnit ? "" : input.currentUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Current") {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError = !input.slipUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"} `}>
            <select
              id="slipUnit"
              value={!input.slipUnit ? "" : input.slipUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Slip") {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};

export const curveInput = (data, xAxisValue, yAxisValue, config) => {
  return [
    {
      label: "X Axis",
      key: "xAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: xAxisValue,
      style: { width: "250px", padding: "10px" },
      change: (e) => { config.changeX(e); },
    },
    {
      label: "Y Axis",
      key: "yAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: yAxisValue,
      style: { width: "250px", padding: "10px" },
      change: (e) => { config.changeY(e); },
    },
  ];
};

export const graphDetails = {
  title: "Curve Plots",
  requiredMessage: "Mininum three data is required",
  efficiency: "Efficiency",
  powerFactor: "Power Factor",
  load: "Load",
  slip: "Slip",
  current: "Current"
};

export const curveVariables = {
  "Load": "load",
  "Efficiency": "efficiency",
  "Power Factor": "powerFactor",
  "Current": "current",
  "Slip": "slip",
};