import React from 'react';
import _ from 'lodash';
import CustomTable from './../../../../../../common/CustomTable/CustomTable';
import {
  tableHeader,
  inputFields,
  inputUnits,
} from './StartingCharacteristicsCurves.constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  NO_INTERNET,
  FORM_DISCARD_MSG,
  StartingCharacteristicsCurvesMessages,
  CurvesMessages,
  DISCARD_CHANGES,
} from './../../../../../../constants/messages';
import { ButtonVariables } from './../../../../../../constants/variables';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import './StartingCharacteristicsCurves.scss';
import Loader from './../../../../../../common/Loader/Loader';
import PropTypes from 'prop-types';
import { saveStartCharCurve } from '../../../../../../redux/actions/synchronousMotor.action';
class StartingCharacteristicsCurves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: ' ',
      currentVoltage100: ' ',
      currentVoltage80: ' ',
      powerFactor: ' ',
      torqueRatedLoad: ' ',
      torqueVoltage100: ' ',
      torqueVoltage80: ' ',
      speedUnit: '',
      currentVoltage100Unit: '',
      currentVoltage80Unit: '',
      powerFactorUnit: '',
      torqueRatedLoadUnit: '',
      torqueVoltage100Unit: '',
      torqueVoltage80Unit: '',
      displayData: [],
      copyDisplayData: [],
      editTableRows: [],
      inititalDisplayData: [],
      unitData: [],
      visible: false,
      formEdit: false,
      eventListener: false,
      saveListener: false,
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', e => {
      const { inititalDisplayData, saveListener } = this.state;
      const {
        synchronousMotorConfigData: { startingCharacteristicsCurvesData },
      } = this.props.synchronousMotorReducer;
      this.setState({
        formEdit: false,
        speed: null,
        currentVoltage100: null,
        currentVoltage80: null,
        powerFactor: null,
        torqueRatedLoad: null,
        torqueVoltage100: null,
        torqueVoltage80: '',
        editTableRows: [],
        eventListener: false,
        saveListener: false,
      });

      if (this.state.formEdit && !saveListener) {
        this.setState({
          speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
          currentVoltage100Unit:
            startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
          currentVoltage80Unit:
            startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
          powerFactorUnit:
            startingCharacteristicsCurvesData.dataUnit.powerFactor,
          torqueRatedLoadUnit:
            startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
          torqueVoltage100Unit:
            startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
          torqueVoltage80Unit:
            startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
          displayData: [...inititalDisplayData.map(item => ({ ...item }))],
          copyDisplayData: [...inititalDisplayData.map(item => ({ ...item }))],
        });
      }
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }
  setData() {
    const {
      synchronousMotorConfigData: { startingCharacteristicsCurvesData },
    } = this.props.synchronousMotorReducer;

    const tempDisplayData = startingCharacteristicsCurvesData.data.map(item => {
      return {
        speed: item['speed'] >= 0 ? item['speed'] : ' ' ,
        currentVoltage100: item['currentVoltage100'] >= 0 ? item['currentVoltage100'] : ' ',
        currentVoltage80: item['currentVoltage80'] >= 0 ? item['currentVoltage80'] : ' ',
        powerFactor: item['powerFactor'] >= 0 ? item['powerFactor'] : ' ',
        torqueRatedLoad: item['torqueRatedLoad'] >= 0 ? item['torqueRatedLoad'] : ' ',
        torqueVoltage100: item['torqueVoltage100'] >= 0  ? item['torqueVoltage100'] : ' ',
        torqueVoltage80: item['torqueVoltage80'] >= 0 ? item['torqueVoltage80'] : ' ',
      };
    });

    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
      inititalDisplayData: _.cloneDeep(tempDisplayData),
      speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
      currentVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
      currentVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
      powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
      torqueRatedLoadUnit:
        startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
      torqueVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
      torqueVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
    });
  }

  componentDidUpdate(prevProps) {
    const { addStartingCharCurve, synchronousMotorConfigData } = this.props.synchronousMotorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (this.props.activeKey !== prevProps.activeKey) {
      const {
        synchronousMotorConfigData: { startingCharacteristicsCurvesData },
      } = this.props.synchronousMotorReducer;

      this.setState({
        speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
        currentVoltage100Unit:
          startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
        currentVoltage80Unit:
          startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
        powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
        torqueRatedLoadUnit:
          startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
        torqueVoltage100Unit:
          startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
        torqueVoltage80Unit:
          startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
        displayData: [
          ...this.state.inititalDisplayData.map(item => ({ ...item })),
        ],
        copyDisplayData: [
          ...this.state.inititalDisplayData.map(item => ({ ...item })),
        ],
      });
    }

    if (
      synchronousMotorConfigData.isSuccess &&
      prevProps.synchronousMotorReducer.synchronousMotorConfigData.isSuccess !==
      synchronousMotorConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      addStartingCharCurve.isSuccess &&
      prevProps.synchronousMotorReducer.addStartingCharCurve.isSuccess !==
        addStartingCharCurve.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(
        Types.Success,
        StartingCharacteristicsCurvesMessages.SUCCESS
      );
    }
    if (
      addStartingCharCurve.isError &&
      prevProps.synchronousMotorReducer.addStartingCharCurve.isError !==
        addStartingCharCurve.isError
    ) {
      Notification.show(
        Types.Error,
        StartingCharacteristicsCurvesMessages.ERROR
      );
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleChangeTableData = (e, unit) => {
    if (unit) {
      this.setState(
        {
          [e.target.id]: e.target.value,
        },
        () => this.enableEditForm()
      );
    } else {
      this.setState(
        {
          [e.target.id]: parseFloat(e.target.value) === 0 ? e.target.value + '' : parseFloat(e.target.value.replace(/^0+/, '')),
        },
        () => this.enableEditForm()
      );
    }
  };

  handleChangeTableEditData = (e, row) => {
    const { displayData } = this.state;
    const key = e.target.id;
    const value = e.target.value;
    const tempDisplayData = displayData;
    tempDisplayData[row][key] = parseFloat(value) === 0 ? value + '' : parseFloat(value.replace(/^0+/, ''));
    this.setState(
      {
        displayData: tempDisplayData,
      },
      () => this.enableEditForm()
    );
  };

  onAdd = () => {
    const {
      displayData,
      copyDisplayData,
      speed,
      currentVoltage100,
      currentVoltage80,
      powerFactor,
      torqueRatedLoad,
      torqueVoltage100,
      torqueVoltage80,
    } = this.state;
    this.setState({
      eventListener: true,
    });
    if (
      _.isEmpty(speed) ||
      speed === 0 ||
      _.isEmpty(currentVoltage100) ||
      currentVoltage100 === 0 ||
      _.isEmpty(currentVoltage80) ||
      currentVoltage80 === 0 ||
      _.isEmpty(powerFactor) ||
      powerFactor === 0 ||
      _.isEmpty(torqueRatedLoad) ||
      torqueRatedLoad === 0 ||
      _.isEmpty(torqueVoltage100) ||
      torqueVoltage100 === 0 ||
      _.isEmpty(torqueVoltage80) ||
      torqueVoltage80 === 0
    ) {
      this.setState(
        {
          displayData: [
            ...displayData,
            {
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
            },
          ],
          copyDisplayData: [
            ...copyDisplayData,
            {
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
            },
          ],
        },
        () => {
          this.setState(
            {
              speed: ' ',
              currentVoltage100: ' ',
              currentVoltage80: ' ',
              powerFactor: ' ',
              torqueRatedLoad: ' ',
              torqueVoltage100: ' ',
              torqueVoltage80: ' ',
              eventListener: false,
            },
            () => this.enableEditForm()
          );
        }
      );
    }
    else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY)
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = row => {
    const { displayData, copyDisplayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    tempDisplayData[row] = { ...copyDisplayData[row] };
    const tempEditTableRows = editTableRows.filter(item => item !== row);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditTableRows,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = row => {
    const { editTableRows } = this.state;
    this.setState(
      {
        editTableRows: [...editTableRows, row],
      },
      () => this.enableEditForm()
    );
  };

  onDelete = row => {
    const { displayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    const tempEditRow = editTableRows.filter(item => item !== row);
    tempDisplayData.splice(row, 1);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditRow,
      },
      () => this.enableEditForm()
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    const {
      synchronousMotorConfigData: { startingCharacteristicsCurvesData },
    } = this.props.synchronousMotorReducer;
    this.setState({
      displayData: [...copyDisplayData.map(item => ({ ...item }))],
      speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
      currentVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
      currentVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
      powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
      torqueRatedLoadUnit:
        startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
      torqueVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
      torqueVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
      visible: false,
      editTableRows: [],
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  transformPayload = (data) => {
    data.forEach(item => {
    //  !_.isFinite(item['speed']) && delete item['speed']
    //  !_.isFinite(item['torqueRatedLoad']) && delete item['torqueRatedLoad']
    //  !_.isFinite(item['torqueVoltage100']) && delete item['torqueVoltage100']
    //  !_.isFinite(item['torqueVoltage80']) && delete item['torqueVoltage80']
    //  !_.isFinite(item['powerFactor']) && delete item['powerFactor']
    //  !_.isFinite(item['currentVoltage100']) && delete item['currentVoltage100']
    //  !_.isFinite(item['currentVoltage80']) && delete item['currentVoltage80']

    if(!_.isFinite(parseFloat(item['speed']))){
      delete item['speed']
      }
      else{
        item['speed'] = parseFloat(item['speed'])
      }
      if(!_.isFinite(parseFloat(item['torqueRatedLoad']))){
       delete item['torqueRatedLoad']
       }
       else{
         item['torqueRatedLoad'] = parseFloat(item['torqueRatedLoad'])
       }
       if(!_.isFinite(parseFloat(item['torqueVoltage100']))){
        delete item['torqueVoltage100']
        }
        else{
          item['torqueVoltage100'] = parseFloat(item['torqueVoltage100'])
        }
        if(!_.isFinite(parseFloat(item['torqueVoltage80']))){
          delete item['torqueVoltage80']
          }
          else{
            item['torqueVoltage80'] = parseFloat(item['torqueVoltage80'])
          }
       if(!_.isFinite(parseFloat(item['currentVoltage100']))){
         delete item['currentVoltage100']
         }
         else{
           item['currentVoltage100'] = parseFloat(item['currentVoltage100'])
         }
           if(!_.isFinite(parseFloat(item['powerFactor']))){
             delete item['powerFactor']
             }
             else{
               item['powerFactor'] = parseFloat(item['powerFactor'])
             }
 
             if(!_.isFinite(parseFloat(item['currentVoltage80']))){
               delete item['currentVoltage80']
               }
               else{
                 item['currentVoltage80'] = parseFloat(item['currentVoltage80'])
               }

    })
    return data
  }


  onSave = () => {
    this.setState({
      saveListener: true,
    });
    const {
      speedUnit,
      currentVoltage100Unit,
      currentVoltage80Unit,
      powerFactorUnit,
      torqueRatedLoadUnit,
      torqueVoltage100Unit,
      torqueVoltage80Unit,
    } = this.state;

    let totalError = true;
    this.state.displayData.forEach(item => {
      let error = false;
      if(parseFloat(item['speed']) || 
      parseFloat(item['currentVoltage100']) || 
      parseFloat(item['currentVoltage80']) || 
      parseFloat(item['powerFactor']) || 
      parseFloat(item['torqueRatedLoad']) || 
      parseFloat(item['torqueVoltage100']) || 
      parseFloat(item['torqueVoltage80'])
      ){
        error = true;
      }
      totalError = totalError && error;
    });
    if (
      !_.isEmpty(speedUnit) &&
      !_.isEmpty(currentVoltage100Unit) &&
      !_.isEmpty(currentVoltage80Unit) &&
      !_.isEmpty(powerFactorUnit) &&
      !_.isEmpty(torqueRatedLoadUnit) &&
      !_.isEmpty(torqueVoltage100Unit) &&
      !_.isEmpty(torqueVoltage80Unit)
    ) {
      if(totalError){
        const { displayData } = this.state;
        if (displayData.length <= 15) {
          const payload = {
            dataUnit: {
              speed: speedUnit,
              currentVoltage80: currentVoltage80Unit,
              currentVoltage100: currentVoltage100Unit,
              powerFactor: powerFactorUnit,
              torqueRatedLoad: torqueRatedLoadUnit,
              torqueVoltage100: torqueVoltage100Unit,
              torqueVoltage80: torqueVoltage80Unit,
            },
            data: this.transformPayload(displayData),
          };
  
          if (this.handleNetwork()) {
            const {
              location: { state },
              saveStartCharCurve,
            } = this.props;
            if (this.handleNetwork() && state && state.componentData.info._id) {
              saveStartCharCurve(state.componentData.info._id, payload);
            }
          }
        }
      }
      else{
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY)
      }
    }
    else {
      Notification.show(Types.Error, CurvesMessages.SELECT_UNITS)
    }
  };

  render() {
    const {
      eventListener,
      displayData,
      asTestedSurgeLines,
      unitData,
      editTableRows,
      volumetricFlowUnit,
      headUnit,
      speed,
      currentVoltage100,
      currentVoltage80,
      powerFactor,
      torqueRatedLoad,
      torqueVoltage100,
      torqueVoltage80,
      speedUnit,
      currentVoltage100Unit,
      currentVoltage80Unit,
      powerFactorUnit,
      torqueRatedLoadUnit,
      torqueVoltage100Unit,
      torqueVoltage80Unit,
      saveListener,
    } = this.state;
    const { synchronousMotorConfigData, addStartingCharCurve } = this.props.synchronousMotorReducer;
    const { isSchematicEditable } = this.props;
    return (
      <div className="StartingCharacteristicsCurves">
        {(synchronousMotorConfigData.isLoading || addStartingCharCurve.isLoading) && (
          <Loader />
        )}
        <div>
          <CustomTable
            showHeader={true}
            columns={tableHeader({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              isSchematicEditable
            })}
            renderAddRow={inputUnits({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              asTestedSurgeLines,
              volumetricFlowUnit,
              headUnit,
              eventListener,
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
              unitData,
              speedUnit,
              currentVoltage100Unit,
              currentVoltage80Unit,
              powerFactorUnit,
              torqueRatedLoadUnit,
              torqueVoltage100Unit,
              torqueVoltage80Unit,
              saveListener,
              length: displayData.length,
              isSchematicEditable
            })}
            headerColumns={isSchematicEditable &&  inputFields({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              asTestedSurgeLines,
              volumetricFlowUnit,
              headUnit,
              eventListener,
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
              length: displayData.length,
            })}
            data={displayData}
            editableRow={editTableRows}
          />
        </div>

        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}
StartingCharacteristicsCurves.propTypes = {
  getDataUnits: PropTypes.func,
  synchronousMotorConfigData: PropTypes.object,
  addStartingCharCurve: PropTypes.object,
  location: PropTypes.object,
  saveStartCharCurve: PropTypes.func,
  network: PropTypes.object,
  isSchematicEditable: PropTypes.bool,
};

StartingCharacteristicsCurves.defaultProps = {
  isSchematicEditable: true
}
const mapStateToProps = state => {
  return {
    network: state.NetworkReducer,
    dataUnitState: state.DataUnitReducer,
    synchronousMotorReducer: state.synchronousMotorReducer
  };
};

const mapsDispatchToProps = dispatch => ({
  saveStartCharCurve: (id, payload) =>
    dispatch(saveStartCharCurve(id, payload)),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(withRouter(StartingCharacteristicsCurves));
