import moment from "moment";

export const format = "DD-MMM-YYYY, HH:mm:ss";

export const getTimeInFormat = (timeStamp) => {
  if (timeStamp) {
    return moment(timeStamp).format(format);
  }
  return "";
};

export const getLumpSumValue = (value) => {
  if (value) {
    return value && value.toFixed(4);
  }
  else if(value === 0)  {
    return 0
  }
  return "";
};
