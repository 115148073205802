import React from "react";
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import { SideMenuVariables } from "../../constants/variables";

function ConfirmModal(props) {
  return (
    <Modal
      title={props.title ? props.title : SideMenuVariables.MODAL_TITLE}
      visible={props.visible}
      onOk={() => props.handleOk()}
      onCancel={() => props.handleCancel()}
      okText={props.okText ? props.okText : "Proceed"}
      closable={false}
    >
      {props.message ? (
        <p>{props.message}</p>
      ) : (
        <p>
          {SideMenuVariables.MODAL_MESSAGE1}
          <br />
          {SideMenuVariables.MODAL_MESSAGE2}
        </p>
      )}
    </Modal>
  );
}

export default withRouter(ConfirmModal);
