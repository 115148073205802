import {
  DATA_VARIABLE_FETCH_ERROR,
  DATA_VARIABLE_FETCH_SUCCESS,
  DESIGN_CLEAR_STATE,
  DESIGN_FETCH_PROGRESS,
  ADD_DESIGN_DATA_SUCCESS,
  ADD_DESIGN_DATA_PROGRESS,
  ADD_DESIGN_DATA_ERROR,
  ADD_PERFORMANCE_DATA_PROGRESS,
  ADD_PERFORMANCE_DATA_SUCCESS,
  ADD_PERFORMANCE_DATA_ERROR,
  CLEAR_PERFORMANCE_DATA,
} from '../Types/design.type';
import { POSTRequest, PUTRequest } from '../../api/request';

export function getDataVariables(payload) {
  return function (dispatch) {
    dispatch({ type: DESIGN_FETCH_PROGRESS });
    POSTRequest('dataunits', payload)
      .then((res) => {
        dispatch({ type: DATA_VARIABLE_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: DATA_VARIABLE_FETCH_ERROR, payload: err });
      });
  };
}

export function clearDesignState() {
  return function (dispatch) {
    dispatch({ type: DESIGN_CLEAR_STATE });
  };
}

export function addEditDesign(payload, id, type) {
  return function (dispatch) {
    dispatch({ type: ADD_DESIGN_DATA_PROGRESS });
    const componentAPIMapper = {
      'Centrifugal liquid expander': `components/${id}/liquidexpanderconfigs/design`,
      'Centrifugal compressor': `components/${id}/compressorconfigs/design`,
      'Centrifugal pump': `components/${id}/pumpconfigs/design`,
      'Blank component': `components/${id}/blankcomponentconfigs/design`,
      'Flow meter': `components/${id}/flowmeterconfigs/design`,
      'Gas turbine': `components/${id}/gtconfigs/data`,
      'Steam Turbine': `components/${id}/stconfigs/design`,
    };
    PUTRequest(componentAPIMapper[type], payload)
      .then((res) => {
        dispatch({ type: ADD_DESIGN_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_DESIGN_DATA_ERROR, payload: err });
      });
  };
}

export function addPerformanceData(id, payload, type) {
  return function (dispatch) {
    dispatch({ type: ADD_PERFORMANCE_DATA_PROGRESS });
    const componentAPIMapper = {
      'Steam Turbine': `components/${id}/stconfigs/performance`,
    };
    PUTRequest(componentAPIMapper[type], payload)
      .then((res) => {
        dispatch({ type: ADD_PERFORMANCE_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_PERFORMANCE_DATA_ERROR, payload: err });
      });
  };
}

export function clearPerformanceData() {
  return function (dispatch) {
    dispatch({ type: CLEAR_PERFORMANCE_DATA });
  };
}
