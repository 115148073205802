import {
  FACILITY_FETCH_ERROR,
  FACILITY_FETCH_SUCCESS,
  FACILITY_FETCH_PROGRESS,
  ADD_FACILITY_FETCH_PROGRESS,
  ADD_FACILITY_FETCH_SUCCESS,
  ADD_FACILITY_FETCH_ERROR,
  EDIT_FACILITY_FETCH_ERROR,
  EDIT_FACILITY_FETCH_PROGRESS,
  EDIT_FACILITY_FETCH_SUCCESS,
  FACILITY_CLEAR_STATE,
  FETCH_ALL_FACILITY_PROGRESS,
  FETCH_ALL_FACILITY_SUCCESS,
  FETCH_ALL_FACILITY_ERROR,
} from "../Types/facility.types";

import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";

export function getFacility(query) {
  return function (dispatch) {
    dispatch({ type: FACILITY_FETCH_PROGRESS });
    GETRequest(`facility/${query}`)
      .then((res) => {
        dispatch({ type: FACILITY_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: FACILITY_FETCH_ERROR, payload: err });
      });
  };
}

export function addNewFacility(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_FACILITY_FETCH_PROGRESS });
    POSTRequest("facility", payload)
      .then((res) => {
        dispatch({ type: ADD_FACILITY_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_FACILITY_FETCH_ERROR, payload: err });
      });
  };
}

export function editFacility(payload, id, clientId) {
  return function (dispatch) {
    dispatch({ type: EDIT_FACILITY_FETCH_PROGRESS });
    PUTRequest(`facility/${id}/${clientId}`, payload)
      .then((res) => {
        dispatch({ type: EDIT_FACILITY_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: EDIT_FACILITY_FETCH_ERROR, payload: err });
      });
  };
}

export function getAllFacilityList() {
  return function (dispatch) {
    dispatch({ type: FETCH_ALL_FACILITY_PROGRESS });
    POSTRequest("facility/filter")
      .then((res) => {
        dispatch({ type: FETCH_ALL_FACILITY_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: FETCH_ALL_FACILITY_ERROR, payload: err });
      });
  };
}

export function clearFacilityState() {
  return function (dispatch) {
    dispatch({ type: FACILITY_CLEAR_STATE });
  };
}
