import React from "react";
import { Input, Icon } from "antd";
import _ from "lodash";
import { surgeLineMessages } from "../../../../../constants/messages";
import { newValidateNumber } from "../../../../../libs/validator";
import * as Pattern from "../../../../../constants/pattern";
import { validateWholeNumber, handleSort } from "../../../../../common/methods";

export const formFieldsValueAsTestedSurgeLines = (fieldData) => [
  {
    label: "Slope Coefficient (K)",
    key: "slopeCoefficientAsTestedCurveLines",
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    onKeyDown: (e) => {
      newValidateNumber(e);
    },
    change: (e) =>
      fieldData.handleChangeFieldData(
        e.target.value ? Number(e.target.value) : null,
        "slopeCoefficientAsTestedCurveLines"
      ),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Surge Margin (b1)",
    key: "surgeMarginAsTestedCurveLines",
    pattern: [Pattern.NaturalNumber, Pattern.NumberMessage],
    onKeyDown: (e) => {
      newValidateNumber(e);
    },
    change: (e) =>
      fieldData.handleChangeFieldData(
        e.target.value ? Number(e.target.value) : null,
        "surgeMarginAsTestedCurveLines"
      ),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Volumetric Flow Unit",
    key: "volumetricFlowUnit",
    type: "dropdown",
    required: fieldData.displayAsTestedSurgeLines.length && [
      true,
      surgeLineMessages.REQUIRED_FIELD,
    ],
    data: fieldData.unitData[0]
      ? handleSort(fieldData.unitData[0].units)
      : null,
    change: (value) =>
      fieldData.handleChangeFieldData(value, "volumetricFlowUnit"),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Polytropic/Adiabatic Head Unit",
    key: "headUnit",
    type: "dropdown",
    required: fieldData.displayAsTestedSurgeLines.length && [
      true,
      surgeLineMessages.REQUIRED_FIELD,
    ],
    data: fieldData.unitData[1]
      ? handleSort(fieldData.unitData[1].units)
      : null,
    change: (value) => fieldData.handleChangeFieldData(value, "headUnit"),
    disabled: !fieldData.isSchematicEditable,
  },
];

export const formFieldsValueOperationalSurgeLines = (fieldData) => [
  {
    label: "Slope Coefficient (K)",
    key: "slopeCoefficientOperationalSurgeLines",
    pattern: [Pattern.NegativeNumber, Pattern.NegativeNumberMessage],
    onKeyDown: (e) => {
      newValidateNumber(e);
    },
    change: (e) =>
      fieldData.handleChangeFieldData(
        e.target.value ? Number(e.target.value) : null,
        "slopeCoefficientOperationalSurgeLines"
      ),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Surge Margin (b1)",
    key: "surgeMarginOperationalSurgeLines",
    pattern: [Pattern.NaturalNumber, Pattern.NumberMessage],
    onKeyDown: (e) => {
      newValidateNumber(e);
    },
    change: (e) =>
      fieldData.handleChangeFieldData(
        e.target.value ? Number(e.target.value) : null,
        "surgeMarginOperationalSurgeLines"
      ),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Volumetric Flow Unit",
    key: "volumetricFlowUnits",
    type: "dropdown",
    required: fieldData.displayOperationalSurgeLines.length && [
      true,
      surgeLineMessages.REQUIRED_FIELD,
    ],
    data: fieldData.unitData[0]
      ? handleSort(fieldData.unitData[0].units)
      : null,
    change: (value) =>
      fieldData.handleChangeFieldData(value, "volumetricFlowUnits"),
    disabled: !fieldData.isSchematicEditable,
  },
  {
    label: "Polytropic/Adiabatic Head Unit",
    key: "headUnits",
    type: "dropdown",
    required: fieldData.displayOperationalSurgeLines.length && [
      true,
      surgeLineMessages.REQUIRED_FIELD,
    ],
    data: fieldData.unitData[1]
      ? handleSort(fieldData.unitData[1].units)
      : null,
    change: (value) => fieldData.handleChangeFieldData(value, "headUnits"),
    disabled: !fieldData.isSchematicEditable,
  },
];

export const tableHeaderAsTestedSurgeLines = (tableHeader) => [
  {
    title: title.volumetricFlow,
    dataIndex: "volumetricFlow",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="volumetricFlow"
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.headDrop,
    dataIndex: "headDrop",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="headDrop"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.pressureRatio,
    dataIndex: "pressureRatio",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="pressureRatio"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.reduceFlow,
    dataIndex: "reduceFlow",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="reduceFlow"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.surgeLimit,
    dataIndex: "surgeLimit",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="surgeLimit"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.surgeControl,
    dataIndex: "surgeControl",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="surgeControl"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  tableHeader.isSchematicEditable && {
    title: title.actions,
    dataIndex: "actions",
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={() =>
              tableHeader.onEdit(row, variableName.asTestedSurgeLines)
            }
          >
            <Icon type="edit" />
          </button>
        ) : (
          <button
            onClick={() =>
              tableHeader.onUndo(row, variableName.asTestedSurgeLines)
            }
          >
            <Icon type="undo" />
          </button>
        )}
        <button
          onClick={() =>
            tableHeader.onDelete(row, variableName.asTestedSurgeLines)
          }
        >
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const inputFieldsAsTestedSurgeLines = (input) => {
  if (input.length !== 15) {
    return [
      {
        render: () => {
          let data = input.asTestedSurgeLines.volumetricFlow;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <div>
              <Input
                id="volumetricFlow"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={data}
                onChange={(value) =>
                  input.handleChangeTableData(
                    value,
                    variableName.asTestedSurgeLines
                  )
                }
                className={
                  (data.trim().length > 0 && !validateWholeNumber(data)) ||
                  (input.asTestedIsError && data.trim().length === 0)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let data = input.asTestedSurgeLines.headDrop;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <div>
              <Input
                id="headDrop"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={data}
                onChange={(value) =>
                  input.handleChangeTableData(
                    value,
                    variableName.asTestedSurgeLines
                  )
                }
                className={
                  (data.trim().length > 0 && !validateWholeNumber(data)) ||
                  (input.asTestedIsError && data.trim().length === 0)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let data = input.asTestedSurgeLines.pressureRatio;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="pressureRatio"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.asTestedIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.asTestedSurgeLines.reduceFlow;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="reduceFlow"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.asTestedIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.asTestedSurgeLines.surgeLimit;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="surgeLimit"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.asTestedIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.asTestedSurgeLines.surgeControl;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="surgeControl"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.asTestedSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.asTestedIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => (
          <button
            className="btn-default btn-small"
            onClick={() => input.onAdd(variableName.asTestedSurgeLines)}
          >
            Add
          </button>
        ),
      },
    ];
  }
};

export const inputFieldsOperationalSurgeLines = (input) => {
  if (input.length !== 15) {
    return [
      {
        render: () => {
          let data = input.operationalSurgeLines.volumetricFlow;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <div style={{ display: "flex" }}>
              <Input
                id="volumetricFlow"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={data}
                onChange={(value) =>
                  input.handleChangeTableData(
                    value,
                    variableName.operationalSurgeLines
                  )
                }
                className={
                  (data.trim().length > 0 && !validateWholeNumber(data)) ||
                  (input.operationalIsError && data.trim().length === 0)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let data = input.operationalSurgeLines.headDrop;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <div style={{ display: "flex" }}>
              <Input
                id="headDrop"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={data}
                onChange={(value) =>
                  input.handleChangeTableData(
                    value,
                    variableName.operationalSurgeLines
                  )
                }
                className={
                  (data.trim().length > 0 && !validateWholeNumber(data)) ||
                  (input.operationalIsError && data.trim().length === 0)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let data = input.operationalSurgeLines.pressureRatio;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="pressureRatio"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.operationalSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.operationalIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.operationalSurgeLines.reduceFlow;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="reduceFlow"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.operationalSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.operationalIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.operationalSurgeLines.surgeLimit;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="surgeLimit"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.operationalSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.operationalIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let data = input.operationalSurgeLines.surgeControl;
          data = _.isNil(data) ? "" : data.toString();
          return (
            <Input
              id="surgeControl"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={data}
              onChange={(value) =>
                input.handleChangeTableData(
                  value,
                  variableName.operationalSurgeLines
                )
              }
              className={
                (data.trim().length > 0 && !validateWholeNumber(data)) ||
                (input.operationalIsError && data.trim().length === 0)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => (
          <button
            className="btn-default btn-small"
            onClick={() => input.onAdd(variableName.operationalSurgeLines)}
          >
            Add
          </button>
        ),
      },
    ];
  }
};

export const tableHeaderoperationalSurgeLines = (tableHeader) => [
  {
    title: title.volumetricFlow,
    dataIndex: "volumetricFlow",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              id="volumetricFlow"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.headDrop,
    dataIndex: "headDrop",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              id="headDrop"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              autoComplete="off"
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.pressureRatio,
    dataIndex: "pressureRatio",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              id="pressureRatio"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.reduceFlow,
    dataIndex: "reduceFlow",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              id="reduceFlow"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.surgeLimit,
    dataIndex: "surgeLimit",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              id="surgeLimit"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  {
    title: title.surgeControl,
    dataIndex: "surgeControl",
    render: (value, row, col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              id="surgeControl"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                tableHeader.handleChangeTableEditData(
                  e,
                  row,
                  variableName.operationalSurgeLines
                )
              }
              className={
                _.isEmpty(value.trim()) || !validateWholeNumber(value)
                  ? "error"
                  : "not-error"
              }
            />
          )}
        </div>
      );
    },
  },
  tableHeader.isSchematicEditable && {
    title: title.actions,
    dataIndex: "actions",
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={() =>
              tableHeader.onEdit(row, variableName.operationalSurgeLines)
            }
          >
            <Icon type="edit" />
          </button>
        ) : (
          <button
            onClick={() =>
              tableHeader.onUndo(row, variableName.operationalSurgeLines)
            }
          >
            <Icon type="undo" />
          </button>
        )}
        <button
          onClick={() =>
            tableHeader.onDelete(row, variableName.operationalSurgeLines)
          }
        >
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

const title = {
  volumetricFlow: "Volumetric Flow",
  headDrop: "Polytropic/Adiabatic Head",
  pressureRatio: "Pressure Ratio (Rc)",
  reduceFlow: "Reduced Flow (Qr)",
  surgeLimit: "Surge Limit Line [Qr(K)]",
  surgeControl: "Surge Control Line [Qr(b1)]",
  actions: "Actions",
};

export const surgeLabel = {
  operationBaselineCurve: "Operational Baseline Curve",
  selectUnits: "Select Units",
  asTestedCurve: "AS-Tested Curve",
};

export const variableName = {
  operationalSurgeLines: "OperationalSurgeLines",
  asTestedSurgeLines: "AsTestedSurgeLines",
};
