import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import {
  validateNaturalNumber,
  validateNegativeNumber,
  validatePositiveFloat,
} from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  MotorDesignDataMessages,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  DesignDataVariables,
} from '../../../../../../constants/variables';
import { saveDesignData } from '../../../../../../redux/actions/motor.action';
import {
  DesignDataShow,
  initialData,
  KeyChange,
  speedTypeTitle,
} from './DesignDataMotor.Constants';
import './DesignDataMotor.scss';

class DesignDataMotor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  setData() {
    const {
      motorConfigData: { designData },
    } = this.props.MotorReducer;
    const tempDisplayData = [
      { name: DesignDataVariables.dutyType, design: designData.dutyType },
      {
        name: DesignDataVariables.numberOfPoles,
        design: designData.numberOfPoles,
      },
      {
        name: DesignDataVariables.voltage,
        unit: designData.voltage.unit,
        design: designData.voltage.design,
      },
      {
        name: DesignDataVariables.frequency,
        unit: designData.frequency.unit,
        design: designData.frequency.design,
      },
      {
        name: DesignDataVariables.serviceFactor,
        design: designData.serviceFactor,
      },
      {
        name: DesignDataVariables.insulationClass,
        design: designData.insulationClass,
      },
      {
        name: DesignDataVariables.temperatureClass,
        design: designData.temperatureClass,
      },
      {
        name: DesignDataVariables.nemaEnclosureType,
        design: designData.nemaEnclosureType,
      },
      {
        name: DesignDataVariables.enclosureIPCode,
        design: designData.enclosureIPCode,
      },
      { name: DesignDataVariables.icCode, design: designData.icCode },
      { name: DesignDataVariables.imCode, design: designData.imCode },
      {
        name: DesignDataVariables.speedType,
        design: speedTypeTitle[designData.speedType],
      },
      {
        name: DesignDataVariables.speedSignalRatio,
        design: designData.speedSignalRatio,
      },
      { name: DesignDataVariables.bearingType, design: designData.bearingType },
      {
        name: DesignDataVariables.bearingQuantity,
        design: designData.bearingQuantity,
      },
      {
        name: DesignDataVariables.equipmentFrameSize,
        design: designData.equipmentFrameSize,
      },
      {
        name: DesignDataVariables.drivenEquipment,
        design: designData.drivenEquipment,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const { addDesignData, motorConfigData } = this.props.MotorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (prevProps.activeKey !== '1' && this.props.activeKey === '1') {
      this.setData();
    }
    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (
      motorConfigData.isSuccess &&
      prevProps.MotorReducer.motorConfigData.isSuccess !==
        motorConfigData.isSuccess
    ) {
      this.setData();
    }

    if (
      addDesignData.isSuccess &&
      prevProps.MotorReducer.addDesignData.isSuccess !== addDesignData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
    if (
      addDesignData.isError &&
      prevProps.MotorReducer.addDesignData.isError !== addDesignData.isError
    ) {
      Notification.show(Types.Error, MotorDesignDataMessages.ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChange = (val, name, type) => {
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: val };
      }
      if (item.name === name && type === 'string') {
        return { name, unit: item.unit, design: val };
      }
      if (item.name === name && type === 'select') {
        return { name, design: val };
      }
      if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    const editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onSave = () => {
    const {
      location: { state },
      saveDesignData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
    });

    if (this.validateFields()) {
      const data = KeyChange;
      const payload = {};
      displayData.forEach((item) => {
        if (
          (item.name === DesignDataVariables.voltage &&
            (item.design || parseFloat(item.design) === 0)) ||
          (item.name === DesignDataVariables.frequency &&
            (item.design || parseFloat(item.design) === 0))
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: parseFloat(item.design),
          };
        } else if (
          (item.design && item.design.trim()) ||
          parseFloat(item.design) === 0
        ) {
          payload[data[item.name]] =
            item.name === 'Speed Signal Ratio' ||
            item.name === 'Number Of Poles' ||
            item.name === 'Service Factor' ||
            item.name === 'Bearing Quantity' ||
            item.name === 'Equipment Frame Size'
              ? parseFloat(item.design)
              : item.design;
        }
        if (item.name === 'Speed Type' && item.design === 'Constant Speed') {
          payload[data[item.name]] = 'CONSTANT';
        }
      });
      if (
        (this.handleNetwork() && state && state.componentData.info._id, payload)
      ) {
        saveDesignData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    this.setState(
      {
        displayData: _.cloneDeep(copyDisplayData),
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  validateFields = () => {
    let status = true;
    this.state.displayData.forEach((item) => {
      if (_.isFinite(parseFloat(item.design))) {
        item.design = item.design.toString();
      }
      if (
        item.name === 'Voltage' ||
        item.name === 'Speed Signal Ratio' ||
        item.name === 'Speed Type'
      ) {
        if (item.name === 'Voltage' && item.unit && item.design) {
          if (item.design && !validatePositiveFloat(item.design)) {
            status = false;
          } else if (item.design && !_.isFinite(parseFloat(item.design))) {
            status = false;
          }
        } else {
          if (!item.design) {
            status = false;
          }
          if (item.name === 'Voltage' && !item.unit) {
            status = false;
          }

          if (
            item.name === 'Speed Signal Ratio' &&
            item.design &&
            validateNegativeNumber(item.design)
          ) {
            status = false;
          }
        }
      }
      if (item.name === 'Number Of Poles' || item.name === 'Bearing Quantity') {
        if (item.design && !validateNaturalNumber(item.design)) {
          status = false;
        }
      }
      if (item.name === 'Service Factor') {
        if (item.design && !validatePositiveFloat(item.design)) {
          status = false;
        }
      }
      if (item.name === 'Frequency') {
        if (item.unit && !item.design) {
          status = false;
        }

        if (
          (item.design && !item.design) ||
          (item.design && !validatePositiveFloat(item.design))
        ) {
          status = false;
        } else if (!item.unit && item.design) {
          status = false;
        }
      }
    });
    return status;
  };

  render() {
    const { motorConfigData, addDesignData } = this.props.MotorReducer;
    const { saveListener, unitData } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignDataMotor">
        {(motorConfigData.isLoading || addDesignData.isLoading) && <Loader />}
        <CustomTable
          showHeader
          columns={DesignDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.state.visible && this.renderModal()}
      </div>
    );
  }
}
DesignDataMotor.propTypes = {
  motorConfigData: PropType.object,
  addDesignData: PropType.object,
  location: PropType.object,
  saveDesignData: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isSchematicEditable: PropType.bool,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
};

DesignDataMotor.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  MotorReducer: state.MotorReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(DesignDataMotor)
);
