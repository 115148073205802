import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import { StartingCharacteristicsCurvesVariables } from "../../../../../../constants/variables";
import { validateNumberNegative } from "../../../../../../libs/validator";

export const tableHeader = (tableHeader) => [
  {
    title: StartingCharacteristicsCurvesVariables.speed,
    dataIndex: "speed",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;

      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="speed"
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  {
    title: StartingCharacteristicsCurvesVariables.currentVoltage100,
    dataIndex: "currentVoltage100",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="currentVoltage100"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  {
    title: StartingCharacteristicsCurvesVariables.currentVoltage80,
    dataIndex: "currentVoltage80",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="currentVoltage80"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },

  {
    title: StartingCharacteristicsCurvesVariables.powerFactor,
    dataIndex: "powerFactor",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="powerFactor"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueRatedLoad,
    dataIndex: "torqueRatedLoad",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="torqueRatedLoad"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueVoltage100,
    dataIndex: "torqueVoltage100",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="torqueVoltage100"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueVoltage80,
    dataIndex: "torqueVoltage80",
    render: (value, row, col, disabled, item) => {
      const tempError =
        parseFloat(item.speed) ||
        parseFloat(item.currentVoltage100) ||
        parseFloat(item.currentVoltage80) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.torqueRatedLoad) ||
        parseFloat(item.torqueVoltage100) ||
        parseFloat(item.torqueVoltage80) ||
        item.speed === 0 ||
        item.currentVoltage100 === 0 ||
        item.currentVoltage80 === 0 ||
        item.powerFactor === 0 ||
        item.torqueRatedLoad === 0 ||
        item.torqueVoltage100 === 0 ||
        item.torqueVoltage80 === 0;
      return (
        <div>
          {disabled ? (
            value
          ) : (
            <Input
              value={value}
              autoComplete="off"
              type="number"
              id="torqueVoltage80"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
              className={tempError ? "not-error" : "error"}
            />
          )}
        </div>
      );
    },
  },
  tableHeader.isSchematicEditable && {
    title: StartingCharacteristicsCurvesVariables.actions,
    dataIndex: "actions",
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => tableHeader.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => tableHeader.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
        <button onClick={() => tableHeader.onDelete(row)}>
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const inputFields = (input) => {
  if (input.length !== 15) {
    const tempError =
      _.isEmpty(input.speed) ||
      _.isEmpty(input.currentVoltage100) ||
      _.isEmpty(input.currentVoltage80) ||
      _.isEmpty(input.powerFactor) ||
      _.isEmpty(input.torqueRatedLoad) ||
      _.isEmpty(input.torqueVoltage100) ||
      _.isEmpty(input.torqueVoltage80) ||
      input.speed === 0 ||
      input.currentVoltage100 === 0 ||
      input.currentVoltage80 === 0 ||
      input.powerFactor === 0 ||
      input.torqueRatedLoad === 0 ||
      input.torqueVoltage100 === 0 ||
      input.torqueVoltage80 === 0 ||
      !input.eventListener;

    return [
      {
        render: () => (
          <div>
            <Input
              id="speed"
              autoComplete="off"
              type="number"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              value={input.speed}
              onChange={(value) => input.handleChangeTableData(value)}
              className={tempError ? "not-error" : "error"}
            />
          </div>
        ),
      },
      {
        render: () => (
          <div>
            <Input
              id="currentVoltage100"
              autoComplete="off"
              type="number"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              value={input.currentVoltage100}
              onChange={(value) => input.handleChangeTableData(value)}
              className={tempError ? "not-error" : "error"}
            />
          </div>
        ),
      },
      {
        render: () => (
          <div>
            <Input
              id="currentVoltage80"
              autoComplete="off"
              type="number"
              onKeyDown={(e) => {
                validateNumberNegative(e);
              }}
              value={input.currentVoltage80}
              onChange={(value) => input.handleChangeTableData(value)}
              className={tempError ? "not-error" : "error"}
            />
          </div>
        ),
      },
      {
        render: () => (
          <Input
            id="powerFactor"
            autoComplete="off"
            type="number"
            onKeyDown={(e) => {
              validateNumberNegative(e);
            }}
            value={input.powerFactor}
            onChange={(value) => input.handleChangeTableData(value)}
            className={tempError ? "not-error" : "error"}
          />
        ),
      },
      {
        render: () => (
          <Input
            id="torqueRatedLoad"
            autoComplete="off"
            type="number"
            onKeyDown={(e) => {
              validateNumberNegative(e);
            }}
            value={input.torqueRatedLoad}
            onChange={(value) => input.handleChangeTableData(value)}
            className={tempError ? "not-error" : "error"}
          />
        ),
      },
      {
        render: () => (
          <Input
            id="torqueVoltage100"
            autoComplete="off"
            type="number"
            onKeyDown={(e) => {
              validateNumberNegative(e);
            }}
            value={input.torqueVoltage100}
            onChange={(value) => input.handleChangeTableData(value)}
            className={tempError ? "not-error" : "error"}
          />
        ),
      },
      {
        render: () => (
          <Input
            id="torqueVoltage80"
            autoComplete="off"
            type="number"
            onKeyDown={(e) => {
              validateNumberNegative(e);
            }}
            value={input.torqueVoltage80}
            onChange={(value) => input.handleChangeTableData(value)}
            className={tempError ? "not-error" : "error"}
          />
        ),
      },
      {
        render: () => (
          <button
            className="btn-default btn-small"
            onClick={() => input.onAdd()}
          >
            Add
          </button>
        ),
      },
    ];
  }
};

export const inputUnits = (input) => [
  {
    render: () => {
      const tempError = !!(!input.speedUnit && input.saveListener);
      return (
        <div className={`custom-table-select  ${tempError && "error"}`}>
          <select
            value={!input.speedUnit ? "" : input.speedUnit}
            id="speedUnit"
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (getUnits.dataVariable === "Starting curve - Speed") {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.currentVoltage100Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="currentVoltage100Unit"
            value={
              !input.currentVoltage100Unit ? "" : input.currentVoltage100Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable ===
                "Starting curve - Current 100% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.currentVoltage80Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="currentVoltage80Unit"
            value={
              !input.currentVoltage80Unit ? "" : input.currentVoltage80Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Current 80% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.powerFactorUnit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="powerFactorUnit"
            value={!input.powerFactorUnit ? "" : input.powerFactorUnit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (getUnits.dataVariable === "Starting curve - Power Factor") {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueRatedLoadUnit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueRatedLoadUnit"
            value={!input.torqueRatedLoadUnit ? "" : input.torqueRatedLoadUnit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque Rated Load"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueVoltage100Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueVoltage100Unit"
            value={
              !input.torqueVoltage100Unit ? "" : input.torqueVoltage100Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque 100% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueVoltage80Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueVoltage80Unit"
            value={!input.torqueVoltage80Unit ? "" : input.torqueVoltage80Unit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque 80% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => <div />,
  },
];
