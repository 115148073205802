import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validatePositiveFloat } from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import { saveStartCharCurve } from '../../../../../../redux/actions/motor.action';
import CustomTable from './../../../../../../common/CustomTable/CustomTable';
import Loader from './../../../../../../common/Loader/Loader';
import {
  CurvesMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  StartingCharacteristicsCurvesMessages
} from './../../../../../../constants/messages';
import { ButtonVariables } from './../../../../../../constants/variables';
import {
  inputFields,
  inputUnits,
  tableHeader
} from './StartingCharacteristicsCurves.constants';
import './StartingCharacteristicsCurves.scss';
class StartingCharacteristicsCurves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: '',
      currentVoltage100: '',
      currentVoltage80: '',
      powerFactor: '',
      torqueRatedLoad: '',
      torqueVoltage100: '',
      torqueVoltage80: '',
      speedUnit: '',
      currentVoltage100Unit: '',
      currentVoltage80Unit: '',
      powerFactorUnit: '',
      torqueRatedLoadUnit: '',
      torqueVoltage100Unit: '',
      torqueVoltage80Unit: '',
      displayData: [],
      copyDisplayData: [],
      editTableRows: [],
      inititalDisplayData: [],
      unitData: [],
      visible: false,
      formEdit: false,
      eventListener: false,
      saveListener: false,
      dirtyEditFields: false,
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      const { inititalDisplayData, saveListener } = this.state;
      const {
        motorConfigData: { startingCharacteristicsCurvesData },
      } = this.props.MotorReducer;
      this.setState({
        formEdit: false,
        speed: null,
        currentVoltage100: null,
        currentVoltage80: null,
        powerFactor: null,
        torqueRatedLoad: null,
        torqueVoltage100: null,
        torqueVoltage80: '',
        editTableRows: [],
        eventListener: false,
        saveListener: false,
      });

      if (this.state.formEdit && !saveListener) {
        this.setState({
          speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
          currentVoltage100Unit:
            startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
          currentVoltage80Unit:
            startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
          powerFactorUnit:
            startingCharacteristicsCurvesData.dataUnit.powerFactor,
          torqueRatedLoadUnit:
            startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
          torqueVoltage100Unit:
            startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
          torqueVoltage80Unit:
            startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
          displayData: [...inititalDisplayData.map((item) => ({ ...item }))],
          copyDisplayData: [
            ...inititalDisplayData.map((item) => ({ ...item })),
          ],
        });
      }
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }
  setData() {
    const {
      motorConfigData: { startingCharacteristicsCurvesData },
    } = this.props.MotorReducer;

    const tempDisplayData = startingCharacteristicsCurvesData.data.map(
      (item) => {
        return {
          speed: item['speed'] >= 0 ? item['speed'] : '',
          currentVoltage100:
            item['currentVoltage100'] >= 0 ? item['currentVoltage100'] : '',
          currentVoltage80:
            item['currentVoltage80'] >= 0 ? item['currentVoltage80'] : '',
          powerFactor: item['powerFactor'] >= 0 ? item['powerFactor'] : '',
          torqueRatedLoad:
            item['torqueRatedLoad'] >= 0 ? item['torqueRatedLoad'] : '',
          torqueVoltage100:
            item['torqueVoltage100'] >= 0 ? item['torqueVoltage100'] : '',
          torqueVoltage80:
            item['torqueVoltage80'] >= 0 ? item['torqueVoltage80'] : '',
        };
      }
    );

    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
      inititalDisplayData: _.cloneDeep(tempDisplayData),
      speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
      currentVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
      currentVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
      powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
      torqueRatedLoadUnit:
        startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
      torqueVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
      torqueVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
    });
  }

  componentDidUpdate(prevProps) {
    const { addStartingCharCurve, motorConfigData } = this.props.MotorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (this.props.activeKey !== prevProps.activeKey) {
      const {
        motorConfigData: { startingCharacteristicsCurvesData },
      } = this.props.MotorReducer;

      this.setState({
        speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
        currentVoltage100Unit:
          startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
        currentVoltage80Unit:
          startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
        powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
        torqueRatedLoadUnit:
          startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
        torqueVoltage100Unit:
          startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
        torqueVoltage80Unit:
          startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
        displayData: [
          ...this.state.inititalDisplayData.map((item) => ({ ...item })),
        ],
        copyDisplayData: [
          ...this.state.inititalDisplayData.map((item) => ({ ...item })),
        ],
      });
    }

    if (
      motorConfigData.isSuccess &&
      prevProps.MotorReducer.motorConfigData.isSuccess !==
        motorConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      addStartingCharCurve.isSuccess &&
      prevProps.MotorReducer.addStartingCharCurve.isSuccess !==
        addStartingCharCurve.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(
        Types.Success,
        StartingCharacteristicsCurvesMessages.SUCCESS
      );
    }
    if (
      addStartingCharCurve.isError &&
      prevProps.MotorReducer.addStartingCharCurve.isError !==
        addStartingCharCurve.isError
    ) {
      Notification.show(
        Types.Error,
        StartingCharacteristicsCurvesMessages.ERROR
      );
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleChangeTableData = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
        eventListener: false,
      },
      () => this.enableEditForm()
    );
  };

  handleChangeTableEditData = (e, row) => {
    const { displayData } = this.state;
    const key = e.target.id;
    const value = e.target.value;
    const tempDisplayData = displayData;
    tempDisplayData[row][key] = value;
    this.setState(
      {
        displayData: tempDisplayData,
      },
      () => {
        this.enableEditForm();
        this.state.displayData.forEach((item) =>
          Object.keys(item).forEach((element) => {
            if (_.isFinite(parseFloat(item[element]))) {
              item[element] = item[element].toString();
            }
          })
        );
        this.validateEditFields(this.state.displayData);
      }
    );
  };

  onAdd = () => {
    const {
      displayData,
      speed,
      currentVoltage100,
      currentVoltage80,
      powerFactor,
      torqueRatedLoad,
      torqueVoltage100,
      torqueVoltage80,
    } = this.state;
    this.setState({
      eventListener: true,
    });
    if (this.validateFields()) {
      this.setState(
        {
          displayData: [
            ...displayData,
            {
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
            },
          ],
        },
        () => {
          this.setState(
            {
              speed: '',
              currentVoltage100: '',
              currentVoltage80: '',
              powerFactor: '',
              torqueRatedLoad: '',
              torqueVoltage100: '',
              torqueVoltage80: '',
              eventListener: false,
            },
            () => this.enableEditForm()
          );
        }
      );
    } else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = (row) => {
    const { displayData, copyDisplayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    tempDisplayData[row] = { ...copyDisplayData[row] };
    const tempEditTableRows = editTableRows.filter((item) => item !== row);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditTableRows,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    this.setState(
      {
        editTableRows: [...editTableRows, row],
      },
      () => this.enableEditForm()
    );
  };

  onDelete = (row) => {
    const { displayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    const tempEditRow = editTableRows.filter((item) => item !== row);
    tempDisplayData.splice(row, 1);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditRow,
      },
      () => this.enableEditForm()
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    const {
      motorConfigData: { startingCharacteristicsCurvesData },
    } = this.props.MotorReducer;
    this.setState({
      displayData: [...copyDisplayData.map((item) => ({ ...item }))],
      speedUnit: startingCharacteristicsCurvesData.dataUnit.speed,
      currentVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage100,
      currentVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.currentVoltage80,
      powerFactorUnit: startingCharacteristicsCurvesData.dataUnit.powerFactor,
      torqueRatedLoadUnit:
        startingCharacteristicsCurvesData.dataUnit.torqueRatedLoad,
      torqueVoltage100Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage100,
      torqueVoltage80Unit:
        startingCharacteristicsCurvesData.dataUnit.torqueVoltage80,
      visible: false,
      editTableRows: [],
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  transformPayload = (data) => {
    data.forEach((item) => {
      if (!_.isFinite(parseFloat(item['speed']))) {
        delete item['speed'];
      } else {
        item['speed'] = parseFloat(item['speed']);
      }
      if (!_.isFinite(parseFloat(item['torqueRatedLoad']))) {
        delete item['torqueRatedLoad'];
      } else {
        item['torqueRatedLoad'] = parseFloat(item['torqueRatedLoad']);
      }
      if (!_.isFinite(parseFloat(item['torqueVoltage100']))) {
        delete item['torqueVoltage100'];
      } else {
        item['torqueVoltage100'] = parseFloat(item['torqueVoltage100']);
      }
      if (!_.isFinite(parseFloat(item['torqueVoltage80']))) {
        delete item['torqueVoltage80'];
      } else {
        item['torqueVoltage80'] = parseFloat(item['torqueVoltage80']);
      }
      if (!_.isFinite(parseFloat(item['currentVoltage100']))) {
        delete item['currentVoltage100'];
      } else {
        item['currentVoltage100'] = parseFloat(item['currentVoltage100']);
      }
      if (!_.isFinite(parseFloat(item['powerFactor']))) {
        delete item['powerFactor'];
      } else {
        item['powerFactor'] = parseFloat(item['powerFactor']);
      }

      if (!_.isFinite(parseFloat(item['currentVoltage80']))) {
        delete item['currentVoltage80'];
      } else {
        item['currentVoltage80'] = parseFloat(item['currentVoltage80']);
      }
    });
    return data;
  };

  onSave = () => {
    this.setState({
      saveListener: true,
    });
    const {
      speedUnit,
      currentVoltage100Unit,
      currentVoltage80Unit,
      powerFactorUnit,
      torqueRatedLoadUnit,
      torqueVoltage100Unit,
      torqueVoltage80Unit,
    } = this.state;

    this.state.displayData.forEach((item) =>
      Object.keys(item).forEach((element) => {
        if (_.isFinite(parseFloat(item[element]))) {
          item[element] = item[element].toString();
        }
      })
    );

    if (
      speedUnit &&
      currentVoltage100Unit &&
      currentVoltage80Unit &&
      powerFactorUnit &&
      torqueRatedLoadUnit &&
      torqueVoltage100Unit &&
      torqueVoltage80Unit
    ) {
      if (this.validateEditFields(this.state.displayData)) {
        const { displayData } = this.state;
        if (displayData.length <= 15) {
          const payload = {
            dataUnit: {
              speed: speedUnit,
              currentVoltage80: currentVoltage80Unit,
              currentVoltage100: currentVoltage100Unit,
              powerFactor: powerFactorUnit,
              torqueRatedLoad: torqueRatedLoadUnit,
              torqueVoltage100: torqueVoltage100Unit,
              torqueVoltage80: torqueVoltage80Unit,
            },
            data: this.transformPayload(displayData),
          };

          if (this.handleNetwork()) {
            const {
              location: { state },
              saveStartCharCurve,
            } = this.props;
            if (this.handleNetwork() && state && state.componentData.info._id) {
              saveStartCharCurve(state.componentData.info._id, payload);
            }
          }
        }
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else {
      Notification.show(Types.Error, CurvesMessages.SELECT_UNITS);
    }
  };

  validateFields = () => {
    const {
      speed,
      currentVoltage100,
      currentVoltage80,
      powerFactor,
      torqueRatedLoad,
      torqueVoltage100,
      torqueVoltage80,
    } = this.state;
    let status = true;

    if (
      currentVoltage100 ||
      speed ||
      currentVoltage80 ||
      torqueRatedLoad ||
      powerFactor ||
      torqueVoltage100 ||
      torqueVoltage80
    ) {
      if (
        (currentVoltage100 && !validatePositiveFloat(currentVoltage100)) ||
        (currentVoltage80 && !validatePositiveFloat(currentVoltage80)) ||
        (torqueRatedLoad && !validatePositiveFloat(torqueRatedLoad)) ||
        (powerFactor && !validatePositiveFloat(powerFactor)) ||
        (torqueVoltage100 && !validatePositiveFloat(torqueVoltage100)) ||
        (torqueVoltage80 && !validatePositiveFloat(torqueVoltage80)) ||
        (speed && !validatePositiveFloat(speed))
      ) {
        status = false;
      }
    } else {
      status = false;
    }
    return status;
  };

  validateEditFields = (data) => {
    let status = true;
    let dirtyEditFields = false;
    this.state.displayData.forEach((item) =>
      Object.keys(item).forEach((element) => {
        if (item[element] && !validatePositiveFloat(item[element])) {
          status = false;
        } else {
          if (
            !item['speed'] &&
            !item['powerFactor'] &&
            !item['torqueRatedLoad'] &&
            !item['currentVoltage100'] &&
            !item['currentVoltage80'] &&
            !item['torqueVoltage100'] &&
            !item['torqueVoltage80']
          ) {
            status = false;
            dirtyEditFields = true;
          }
        }
      })
    );
    this.setState({
      dirtyEditFields,
    });
    return status;
  };

  render() {
    const {
      eventListener,
      displayData,
      asTestedSurgeLines,
      unitData,
      editTableRows,
      volumetricFlowUnit,
      headUnit,
      speed,
      currentVoltage100,
      currentVoltage80,
      powerFactor,
      torqueRatedLoad,
      torqueVoltage100,
      torqueVoltage80,
      speedUnit,
      currentVoltage100Unit,
      currentVoltage80Unit,
      powerFactorUnit,
      torqueRatedLoadUnit,
      torqueVoltage100Unit,
      torqueVoltage80Unit,
      saveListener,
      dirtyEditFields,
    } = this.state;
    const { motorConfigData, addStartingCharCurve } = this.props.MotorReducer;
    const { isSchematicEditable } = this.props;
    return (
      <div className="StartingCharacteristicsCurves">
        {(motorConfigData.isLoading || addStartingCharCurve.isLoading) && (
          <Loader />
        )}
        <div>
          <CustomTable
            showHeader={true}
            columns={tableHeader({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              dirtyEditFields,
              isSchematicEditable,
            })}
            renderAddRow={inputUnits({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              asTestedSurgeLines,
              volumetricFlowUnit,
              headUnit,
              eventListener,
              speed,
              currentVoltage100,
              currentVoltage80,
              powerFactor,
              torqueRatedLoad,
              torqueVoltage100,
              torqueVoltage80,
              unitData,
              speedUnit,
              currentVoltage100Unit,
              currentVoltage80Unit,
              powerFactorUnit,
              torqueRatedLoadUnit,
              torqueVoltage100Unit,
              torqueVoltage80Unit,
              saveListener,
              length: displayData.length,
              isSchematicEditable,
            })}
            headerColumns={
              isSchematicEditable &&
              inputFields({
                handleChangeTableData: this.handleChangeTableData,
                onAdd: this.onAdd,
                asTestedSurgeLines,
                volumetricFlowUnit,
                headUnit,
                eventListener,
                speed,
                currentVoltage100,
                currentVoltage80,
                powerFactor,
                torqueRatedLoad,
                torqueVoltage100,
                torqueVoltage80,
                length: displayData.length,
              })
            }
            data={displayData}
            editableRow={editTableRows}
          />
        </div>

        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}
StartingCharacteristicsCurves.propTypes = {
  getDataUnits: PropTypes.func,
  motorConfigData: PropTypes.object,
  addStartingCharCurve: PropTypes.object,
  location: PropTypes.object,
  saveStartCharCurve: PropTypes.func,
  network: PropTypes.object,
  isSchematicEditable: PropTypes.bool,
};

StartingCharacteristicsCurves.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    MotorReducer: state.MotorReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveStartCharCurve: (id, payload) =>
    dispatch(saveStartCharCurve(id, payload)),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(withRouter(StartingCharacteristicsCurves));
