import {
  SYSTEM_FETCH_ERROR,
  SYSTEM_FETCH_PROGRESS,
  SYSTEM_FETCH_SUCCESS,
  SYSTEM_CLEAR_STATE,
  ADD_SYSTEM_FETCH_SUCCESS,
  ADD_SYSTEM_FETCH_PROGRESS,
  ADD_SYSTEM_FETCH_ERROR,
  EDIT_SYSTEM_FETCH_ERROR,
  EDIT_SYSTEM_FETCH_SUCCESS,
  EDIT_SYSTEM_FETCH_PROGRESS,
} from "../Types/System.types";

const INITIAL_API_STATE = {
  systemData: null,
  isErrorFetchSystem: false,
  isErrorAddSystem: false,
  isErrorEditSystem: false,
  isSuccessFetchSystem: false,
  isSuccessAddSystem: false,
  isSuccessEditSystem: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function SystemReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SYSTEM_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case SYSTEM_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorFetchSystem: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case SYSTEM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          systemData: action.payload,
          isSuccessFetchSystem: true,
        },
      };
    case SYSTEM_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          systemData: state.apiState.systemData,
        },
      };
    case ADD_SYSTEM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          systemData: action.payload,
          isSuccessAddSystem: true,
          isErrorAddSystem: false,
        },
      };
    case ADD_SYSTEM_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorAddSystem: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case ADD_SYSTEM_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_SYSTEM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          systemData: action.payload,
          isSuccessEditSystem: true,
          isErrorEditSystem: false,
        },
      };
    case EDIT_SYSTEM_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorEditSystem: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case EDIT_SYSTEM_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
