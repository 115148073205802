import _, { isNull } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { Types } from '../../../../common/Notification/Notification';
import { AlertDetailMessages, NO_INTERNET } from '../../../../constants/messages';
import { roles } from '../../../../permissions';
import { alertDetail as updateAlertDetails, alertDetailClearState, getEventDetails } from '../../../../redux/actions/alertDetails.action';
import { fetchAlertKPIClearState, getAlertPlotByLevelId, getPlotDataByKPIId } from '../../../../redux/actions/alertplots.action';
import { clearBatchState, getBatchTime } from '../../../../redux/actions/plantTime.action';
import { alertActions, ALERT_ACTIONS_LIST } from './Alerts.constants';
import { generateAlertPayload } from './NewAlertDeatils/components/ValidateAlertFields';
import RenderMobileView from './NewAlertDeatils/RenderMobileView';
import RenderPcView from './NewAlertDeatils/RenderPcView';
import { clearState } from '../../../../redux/actions/discussionThread.action';



const AlertDeails = (props) => {
  const dispatch = useDispatch();
  const ACTION = 'Actions';

  let kpiFetchSuccess = useSelector((state) => state.AlertPlotKPIReducer.isSuccess),
    kpiData = useSelector((state) => state.AlertPlotKPIReducer.data),
    batchTimeData = useSelector((state) => state.batchTimeReducer.data),
    batchTimeIsSuccess = useSelector((state) => state.batchTimeReducer.isSuccess),
    userRole = useSelector((state) => state.LoginReducer.apiState.userRole),
    networkState = useSelector((state) => state.NetworkReducer),
    alertDetailState = useSelector((state) => state.AlertDetailReducer)

  const { data, editAlertDetials } = props
  const [screenWidht] = useState(window.innerWidth)
  const [loader] = useState(false)
  const [alertComment, setAlertComment] = useState(data.comment ? data.comment : "")
  const [alertInsights, setAlertInsights] = useState(data.insights ? data.insights : "")
  const [selectedTab, setSelectedTab] = useState(userRole === roles.MECHADEMY_ADMIN || userRole === roles.MECHADEMY_FACILITY_MANAGER ? 0 : 1)
  const [selectedAssignees, setSelectedAssignees] = useState(data.assignees ? data.assignees : [])
  const [selectedLabels, setSelectedLabels] = useState(data.labels ? data.labels : [])
  const [batchTime, setbatchTime] = useState(null)
  const [mechademyActions, setMechademyActions] = useState(ACTION)
  const [alertLevel, setAlertLevel] = useState(data.level)
  const [shelvedModalVisibility, setShelvedModalVisibility] = useState(false)
  const [shelvedTime, setShelvedTime] = useState("")
  const [expanded, setExpanded] = useState("1");
  const [actionChanged, setActionChanged] = useState(false)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenWidth(window.innerWidth);
  //   }
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    const { info, data } = props
    dispatch(clearState())
    if (info.type && info.dataRef && info.dataRef._id && data['_id']) {
      dispatch(getAlertPlotByLevelId(info.type, info.dataRef._id, data['_id']))
    }
    dispatch(getBatchTime());
    if (data.eventId)
      dispatch(getEventDetails(data.eventId));
  }, [])// eslint-disable-line

  useEffect(() => {
    if (batchTimeIsSuccess) {
      const batchTime = batchTimeData && batchTimeData.to;
      setbatchTime(batchTime)
      dispatch(clearBatchState());
    }
  }, [batchTimeIsSuccess])// eslint-disable-line

  useEffect(() => {
    if (mechademyActions === ALERT_ACTIONS_LIST.SHELVED) {
      setShelvedModalVisibility(true)
    }
  }, [mechademyActions])

  useEffect(() => {
    const {
      isDetailsAddedSuccess,
      isError,
      errorCode
    } = alertDetailState.apiState;

    if (isDetailsAddedSuccess) {
      dispatch(alertDetailClearState());
      Notification.show(Types.Success, AlertDetailMessages.ALERT_UPDATED);
      editAlertDetials(false, {});
    }

    if (isError) {
      dispatch(alertDetailClearState());
      Notification.show(Types.Error, AlertDetailMessages[errorCode]);
    }

  }, [alertDetailState])// eslint-disable-line



  const transfromTime = () => {
    const times = shelvedTime.split(':');
    const hours = parseFloat(times[0]);
    const minutes = parseFloat(times[1]);
    if (!hours && !minutes) {
      return false;
    } else {
      const tempShelvedTime =
        Date.now() + (hours * 60 * 60 * 1000 + minutes * 60 * 1000);
      return tempShelvedTime;
    }
  }

  const handleShelvedModalOk = (value) => {
    setShelvedModalVisibility(false)
    setShelvedTime(value)
  }
  const handleNetwork = () => {
    if (networkState.networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  };

  const HandleAlertSave = () => {
    let alertData = data
    const { levelDetails } = props
    let validation = alertData
    let payload = generateAlertPayload(validation)
    if (payload) {
      delete payload['dateTime'];
      delete payload['type'];
      delete payload['updatedAt'];
      delete payload['editedBy'];
      delete payload['type'];
      delete payload['eventName'];
      payload['_id'] = alertData['_id'];
      payload['comment'] = alertComment === "<p><br></p>" ? null : alertComment
      payload['insights'] = alertInsights === "<p><br></p>" ? null : alertInsights
      payload['level'] = alertLevel
      payload['assignees'] = selectedAssignees
      payload['labels'] = selectedLabels
      if (
        userRole === roles.MECHADEMY_ADMIN ||
        userRole === roles.MECHADEMY_FACILITY_MANAGER
      ) {
        payload['mechademyAction'] = alertActions[mechademyActions]
        payload['labels'] = selectedLabels
        if (payload['mechademyAction'] === ACTION) {
          delete payload['mechademyAction'];
        }
        if (mechademyActions === ALERT_ACTIONS_LIST.SHELVED) {
          if (shelvedTime === "") {
            return setShelvedModalVisibility(true)
          }
          payload['mechademyShelveTime'] = transfromTime();
        }
        if (payload["level"] === "High" && isNull(payload['comment']) &&
          payload['mechademyAction'] !== ALERT_ACTIONS_LIST.FORWARD &&
          payload['mechademyAction'] !== ACTION
        ) {
          return Notification.show(Types.Error, AlertDetailMessages.FILL_REQUIREND_COMMENTS);
        }
        if (payload['mechademyAction'] && payload['mechademyAction'] !== ALERT_ACTIONS_LIST.FORWARD
          && alertComment === "" && payload["level"] === "High") {
           return Notification.show(Types.Error, AlertDetailMessages.FILL_REQUIREND_COMMENTS);
        }
        if (payload['mechademyAction'] && payload['mechademyAction'] === ALERT_ACTIONS_LIST.FORWARD
          && alertInsights === "") {
          return Notification.show(Types.Error, AlertDetailMessages.FILL_REQUIREND_INSIGHTS);
        }
        if(actionChanged === false)
        delete payload["mechademyAction"]
      }
      else {
        if (payload['clientAction'] === ACTION) {
          delete payload['clientAction'];
        }
        payload['clientAction'] = mechademyActions;

        if (payload['clientAction'] === ALERT_ACTIONS_LIST.SHELVED) {
          if (shelvedTime === "") {
            return setShelvedModalVisibility(true)
          }
          payload['clientShelveTime'] = transfromTime();
        }

        if (payload['clientAction']) {
          payload['clientAction'] = alertActions[payload['clientAction']];
        }
        if (payload["level"] === "High" && isNull(payload['comment']) &&
          payload['mechademyAction'] !== ALERT_ACTIONS_LIST.FORWARD &&
          payload['mechademyAction'] !== ACTION
        ) {
          return Notification.show(Types.Error, AlertDetailMessages.FILL_REQUIREND_COMMENTS);
        }
        if (actionChanged === false)
          delete payload['clientAction'];
      }

      payload['levelid'] = levelDetails._id; // eslint-disable-line
      payload['levelname'] = levelDetails.levelname;

      if (
        payload.mechademyAction === ALERT_ACTIONS_LIST.CLOSE &&
        alertData.clientStatus === 'New'
      ) {
        Notification.show(Types.Error, AlertDetailMessages.ERROR_CLOSE);
      } else {
        if (handleNetwork()) {
          dispatch(updateAlertDetails(payload));
        }
      }
    }

  }

  useEffect(() => {
    if (kpiFetchSuccess) {
      if (kpiData.length !== 0) {
        kpiData.forEach((value) => {
          let dateFrom = null,
            dateTill = null;
          const kpiId = value['_id'];
          if (
            value.inspection.mode &&
            value.inspection.viewDateFrom &&
            value.inspection.viewDateTill
          ) {
            dateFrom = value.inspection.viewDateFrom;
            dateTill = value.inspection.viewDateTill;
          } else {
            const currentDateTime = moment(batchTime * 1000);
            const currentDateTime2 = _.cloneDeep(currentDateTime);
            dateFrom = currentDateTime.subtract(2, 'hours').valueOf();
            dateTill = currentDateTime2.valueOf();
          }
          if (dateFrom && dateTill) {
            dispatch(getPlotDataByKPIId(kpiId, dateFrom, dateTill));
          }
        });
      }
      dispatch(fetchAlertKPIClearState());
    }
  }, [kpiFetchSuccess]) // eslint-disable-line

  const stateData = {
    alertComment: alertComment,
    alertInsights: alertInsights,
    selectedTab: selectedTab,
    actionChanged: actionChanged,
    setActionChanged: setActionChanged,
    setAlertComment: setAlertComment,
    setAlertInsights: setAlertInsights,
    setSelectedTab: setSelectedTab,
    selectedAssignees: selectedAssignees,
    setSelectedAssignees: setSelectedAssignees,
    selectedLabels: selectedLabels,
    setSelectedLabels: setSelectedLabels,
    mechademyActions: mechademyActions,
    setMechademyActions: setMechademyActions,
    userRole: userRole,
    level: alertLevel,
    setAlertLevel: setAlertLevel,
    HandleAlertSave: HandleAlertSave,
    shelvedModalVisibility: shelvedModalVisibility,
    setShelvedModalVisibility: setShelvedModalVisibility,
    handleShelvedModalOk: handleShelvedModalOk,
    expanded: expanded,
    setExpanded: setExpanded
  }

  if (loader) {
    return <>loader</>
  }
  else if (screenWidht <= 1400) {
    return RenderMobileView(props, stateData)
  }
  return RenderPcView(props, stateData)
}

export default AlertDeails;