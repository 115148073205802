export const endPoints = {
  equipment: 'equipments',
  forgotPassword: 'public/user/forgotPassword',
  changePassword: 'public/user/changePassword',
  resetPassword: 'public/user/resetPassword',
  addFlowMeterType: 'flowmetertypes',
  flowMeterType: 'flowmetertypes/filter',
  equipmentType: 'equipmenttypes/filter',
  addEquipmentType: 'equipmenttypes',
  manufacturer: 'manufacturers/filter',
  addManufacturer: 'manufacturers',
  components: 'components',
  schemetics: 'schematic',
  validateSchemetics: 'validate/schematic',
  alerts: 'alerts',
  commonPlots: 'plots/common',
  surgeLines: 'compressorconfigs/surgeLines',
  mixer: 'mixerconfigs',
  variable: 'config/variable',
  propertyunits: 'propertyunits',
  equipments: 'equipment',
  component: 'component',
  output: 'config/output',
  assetsEquipments: 'assets/equipments',
  input: 'config/input',
  config: 'config',
  tags: 'tags',
  systemSchematic: 'system/schematic?systemId',
  createSystemSchematic: 'system/schematic',
  piTags: 'pitag',
  updatePiTag: 'pitag/update',
  logPiTag: 'pitag/log',
  updateTag: 'tag/update',
  user: 'user',
  invitationLink: 'invitationLink',
  userStatus: 'user/status',
  motorDesign: 'motorconfigs/design',
  synchronousMotorDesign: 'synchronousmotorconfigs/design',
  motorPerformaceData: 'motorconfigs/performancedata',
  synchronousMotorPerformanceData: 'synchronousmotorconfigs/performancedata',
  gtConfig: 'gtconfigs/data',
  gtDesignPerformance: 'gtconfigs/designPerformance',
  gtFluidComp: 'gtconfigs/fluidComposition',
  gtCurves: 'gtconfigs/curves',
  alertsDiscussion: 'alerts/discussion/',
  alertPlots: 'alerts/plots',
  labels: 'labels',
  assignees: 'assignees',
  eventDetails: 'eventDetails',
  mlModal: 'model',
  mlModalDeploy: 'model/deploy',
  mlModalDeployBulk: 'model/bulk/deploy',
  client: 'client',
  facility: 'facility',
  motorPerformaceCurve: 'motorconfigs/performancecurves',
  synchronousmotorPerformanceCurve: 'synchronousmotorconfigs/performancecurves',
  synchronousMotorMotorParameterData: 'synchronousmotorconfigs/motorparameters',
  motorStartingCharCurve: 'motorconfigs/startingcharacteristicscurves',
  valveConfig: 'valveConfigs',
  valveDesign: 'valveConfigs/design',
  valveFluidComposition: 'valveConfigs/fluidcomp',
  valveCurves: 'valveConfigs/curves',
  synchronousMotorStartingCharCurve:
    'synchronousmotorconfigs/startingcharacteristicscurves',
  motorVarSpeedCurve: 'motorconfigs/variablespeedcurves',
  synchronousMotorVarSpeedCurve: 'synchronousmotorconfigs/variablespeedcurves',
  motorEquCkt: 'motorconfigs/equivalentcircuitdata',
  drivetypes: 'drivetypes',
  filter: 'filter',
  mlModelVersion: 'model/version',
  piTagDelete: 'pitag/delete',
  tagBaseTimeUpdate: 'tag/basetime/update',
  statistics: 'statistics',
  type: 'type',
  unDeploySchematic: 'schematic/undeploy',
  stCurves: 'stconfigs/curves',
  synchronousGeneratorDesign: 'synchronousgeneratorconfigs/design',
  synchronousGeneratorMotorParameterData:
    'synchronousgeneratorconfigs/motorparameters',
  synchronousGeneratorPerformanceCurve:
    'synchronousgeneratorconfigs/performancecurves',
  synchronousGeneratorPerformanceData:
    'synchronousgeneratorconfigs/performancedata',
  synchronousGeneratorStartingCharCurve:
    'synchronousgeneratorconfigs/startingcharacteristicscurves',
  synchronousGeneratorVarSpeedCurve:
    'synchronousgeneratorconfigs/variablespeedcurves',
  synchronousGeneratorCapabilityCurve:
    'synchronousgeneratorconfigs/capabilitycurves',
  reciprocatingCompressorConfig: 'rcconfigs/data',
  reciprocatingCompressorDesign: 'reciprocatingCompressorConfigs/design',
  reciprocatingCompressorFluidComposition:
    'reciprocatingCompressorConfigs/fluidcomp',
  reciprocatingCompressorCurves: 'reciprocatingCompressorConfigs/curves',
  pimConfig: 'pimconfigs/data',
  pimDesign: 'pimconfigs/design',
  pimIteration: 'pimconfigs/iterationData',
  pimFluidComposition: 'pimconfigs/fluidComp',
  screwCompressorConfig: 'scconfigs/data',
  screwCompressorFluidComposition: 'scconfigs/fluidcomposition',
  screwCompressorCurves: 'scconfigs/curves',
  screwCompressorDesign: 'scconfigs/designData',
  hydraulicVariableSpeedGearConfig: 'hvsgconfig/data',
  hydraulicVariableSpeedGearDesign: 'hvsgconfig/design',
  hydraulicVariableSpeedGearPerformance: 'hvsgconfig/performance',
  hydraulicVariableSpeedGearWorkingFluid: 'hvsgconfig/workingfluid',
  hydraulicVariableSpeedGearCurves: 'hvsgcurves/data',
  iowData: 'iowData',
  changePassInvite: 'pass-email',
  sqlComponentConfig: 'sqlconfig',
};
