import { Button, Col, Layout, Row, Modal, Empty } from "antd";
import React, { useEffect, useState } from "react";
import EditTags from "./EditTags";
import onSign from "../../../assets/ic_on/on.png"
import offSign from "../../../assets/ic_off/off.png"
import { useDispatch, useSelector } from "react-redux";
import { piTagDelete, piTagLogsDataFetch } from "../../../redux/actions/piTagUpload.action";
import * as Variables from "../../../constants/variables";
import * as Storage from '../../../libs/storage';
import {Logs} from './MasterTagUpdates/ChangeLogs'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const { Content } = Layout;

const TagDetails = (selectedTag, equipmentsList, systems, facilityId, variablePropertyList, variablePropertyUnits, handleChange, bulkSelect, currentPage, setCurrentPage) => {
    
    const dispatch = useDispatch()
    let tagUpdate = useSelector((data) => data.piTagReducer.tagUpdate);
    const userInfo = useSelector((state) => state.LoginReducer.apiState);
    const logData = useSelector((state) => state.piTagReducer.piTagLogsData)
    const [editModalShow, setEditModalShow] = useState(false)
    const [associatedEquipments, setAssociatedEquipments] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [userData, setUserData] = useState("")
    const [logs, setLogs] = useState()
    const [isLoading,setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleSetExpand = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    useEffect(() => {
      if (logData) {
        setLogs({ data: logData.data, totalCount: logData.total_count });
        setExpanded(false)
        setIsLoading(logData.isLoading)
      }
    }, [logData]);

    useEffect (()=>{
      if(selectedTag){
        let skip = currentPage === 1 ? 0 : (currentPage - 1) * 100;
        let limit = 100;
        dispatch(piTagLogsDataFetch(facilityId,{piTag: selectedTag.piTag , skip ,limit })) 
      }

    },[selectedTag,currentPage]) // eslint-disable-line

    useEffect(() => {
        if (selectedTag && selectedTag.equipmentId && equipmentsList) {
            let associatedEuip = []
            selectedTag.equipmentId.forEach((data) => {
                equipmentsList.forEach((value) => {
                    if (data === value._id) {
                        associatedEuip.push(value.name)
                    }
                })
            })
            setAssociatedEquipments(associatedEuip)
        }
    }, [// eslint-disable-line
        selectedTag, equipmentsList
    ])

    useEffect(() => {
        if (!tagUpdate.isLoading && tagUpdate.isSuccess) {
            setEditModalShow(false)
        }
    }, [// eslint-disable-line
        tagUpdate.isSuccess
    ])

    useEffect(() => {
        const {firstName, lastName } = Storage.get(Variables.USER);
        if (firstName && lastName) {
            let user = firstName +" "+ lastName
            setUserData(user)
        }
        else if( userInfo && userInfo.userRole){
            setUserData(userInfo.userRole)
        }
    }, [userInfo ])

    const handleDeleteOk = () => {
        let payload = {
            facilityId: facilityId,
            tags: [selectedTag._id]
        }
        setShowDeleteModal(false)
        if (payload.facilityId && payload.tags.length)
            dispatch(piTagDelete(payload))
    }
    const renderDeleteModal = () => {
        if (showDeleteModal) {
            return (
                <Modal
                    onCancel={() => setShowDeleteModal(false)}
                    onOk={() => { handleDeleteOk() }}
                    title={"Alert"}
                    visible={showDeleteModal}
                >
                    Delete selected tag ?
                </Modal>
            )

        }
    }

    const validateData = (data) =>{
        
      for(let start=0;start<data.length;start++){
        
         if(data[start].action?.equipmentId?.deleted){
             data[start].action.equipmentId.deleted.forEach((curr,index) => {
                equipmentsList.forEach((value) => {
                    if (curr === value._id) {
                        data[start].action.equipmentId.deleted[index]=(value.name)
                    }
                })
            })
         }

         if(data[start].action?.equipmentId?.added){
            data[start].action.equipmentId.added.forEach((curr,index) => {
                equipmentsList.forEach((value) => {
                    if (curr === value._id) {
                        data[start].action.equipmentId.added[index]=(value.name)
                    }
                })
            })
         }

      }

       return data;
    }
    return (
        <Layout>
            <Content className="tag-details">
                {selectedTag && selectedTag.piTag ?
                    <div>
                        <div className="tag-details-button">
                            <Button className="capsule-btn" onClick={() => { setEditModalShow(true) }} disabled={bulkSelect > 0}>
                                Edit
                            </Button>
                            <Button className="capsule-btn" onClick={() => setShowDeleteModal(true)} disabled={bulkSelect > 0}>
                                Delete
                            </Button>
                        </div>
                        <div>
                            <div className="details-heading">
                                Tag Info
                            </div>
                            <div className="details-data">
                                <Row>
                                    <Col className="tag-data" span={12}>
                                        Tag ID : {selectedTag ? selectedTag.piTag : ""}
                                    </Col>
                                    <Col className="tag-data" span={12}>
                                        Tag Name : {selectedTag ? selectedTag.variableName : ""}
                                    </Col> <Col className="tag-data" span={12}>
                                        Data Type : {selectedTag ? selectedTag.dataType : ""}
                                    </Col> <Col className="tag-data" span={12}>
                                        Variable Property : {selectedTag ? selectedTag.variableProperty : ""}
                                    </Col> <Col className="tag-data" span={12}>
                                        Customer Unit : {selectedTag ? selectedTag.units : ""}
                                    </Col> <Col className="tag-data" span={12}>
                                        Mechademy Unit : {selectedTag ? selectedTag.mechademyUnits : ""}
                                    </Col>
                                </Row>
                            </div>
                            <div className="details-heading">
                                Variable / Transmitter Range
                            </div>
                            <div className="details-data">
                                <Row>
                                    <Col className="tag-data" span={12}>
                                        Variable Minimum Value : {selectedTag ? selectedTag.minThreshold : ""}
                                    </Col>
                                    <Col className="tag-data" span={12}>
                                        Variable Maximum Value : {selectedTag ? selectedTag.maxThreshold : ""}
                                    </Col>
                                </Row>
                            </div>
                            <div className="details-heading">
                                Univariate Anomaly Detection
                            </div>
                            <div className="details-data">
                                <Row>
                                    <Col className="tag-data" span={12}>
                                        UAD Multiplier  : {selectedTag ? selectedTag.multiplier : ""}
                                    </Col>
                                    <Col className="tag-data" span={12}>
                                        UAD Adder : {selectedTag ? selectedTag.adder : ""}
                                    </Col>
                                    <Col className="tag-data" span={12}>
                                        UAD Enabled  : {selectedTag && typeof selectedTag.isIQR === "boolean" && selectedTag.isIQR === true ?
                                            <img src={onSign} className="state-logo" alt="state-logo" /> :
                                            <img src={offSign} className="state-logo" alt="state-logo" />
                                        }
                                    </Col>
                                    <Col className="tag-data" span={12}>
                                        Frozen Data Checks : {selectedTag && typeof selectedTag.isFrozen === "boolean" && selectedTag.isFrozen === true ?
                                            <img src={onSign} className="state-logo" alt="state-logo" /> : <img src={offSign} className="state-logo" alt="state-logo" />}
                                    </Col>
                                </Row>
                            </div>
                            <div className="details-heading">
                                Associated Equipments
                            </div>
                            <div className="details-data">
                                <ul style={{display:"flex",flexWrap:"wrap"}}>
                                    {
                                        associatedEquipments.map((data) => {
                                            return (
                                                <li style={{width:"33%"}}className="tag-data">
                                                    {data}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="modification_logs_accordion">
                               <Accordion style={{background:"rgba(105, 105, 105, 0.2)"}}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <div className="details-heading">
                                      <span style={{letterSpacing:"0.5px"}}>Change Logs</span>
                                    </div>
                                  </AccordionSummary >
                                  <AccordionDetails style={logs.data.length === 0 ? {background:"white"} : {} }>
                                    <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                      {Logs(
                                        validateData(logs.data),
                                        logs.totalCount,
                                        currentPage,
                                        setCurrentPage,
                                        isLoading,
                                        expanded,
                                        handleSetExpand,
                                        )}
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                               </div>
                        </div>

                    </div> :
                    <div className="empty-space">
                        <Empty />
                    </div>
                }
            </Content>
            <EditTags
                state={editModalShow}
                setModalState={setEditModalShow}
                tagData={selectedTag}
                equipmentsList={equipmentsList}
                systems={systems}
                facilityId={facilityId}
                variablePropertyList={variablePropertyList}
                variablePropertyUnits={variablePropertyUnits}
                handleChange={handleChange}
                userData = {userData}
            />
            {renderDeleteModal()}
        </Layout>
    )
}

export default TagDetails