import * as Types from "../Types/ScrewCompressor.types"
import { endPoints } from "../../libs/endpoints";
import {
  GETRequest,
  PUTRequest,
} from "../../api/request";

export const updateDesignData = (id, payload) => (dispatch) => {
  dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_PROGRESS });
  PUTRequest(`${endPoints.components}/${id}/${endPoints.screwCompressorDesign}`, payload)
    .then((res) =>
      dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_FAILURE, payload: err })
    );
};

export const clearUpdateState = () => (dispatch) => {
  dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_RESET });
};

export const fetchScrewCompressorConfig = (id) => (dispatch) => {
  dispatch({ type: Types.GET_SCREW_COMPRESSOR_CONFIG_DATA_PROGRESS });
  GETRequest(`${endPoints.components}/${id}/${endPoints.screwCompressorConfig}`)
    .then((res) =>
      dispatch({
        type: Types.GET_SCREW_COMPRESSOR_CONFIG_DATA_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: Types.GET_SCREW_COMPRESSOR_CONFIG_DATA_FAILURE,
        payload: err,
      })
    );
}

export const updateCurvesData = (id, payload) => (dispatch) => {
  dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_CURVES_DATA_PROGRESS });
  PUTRequest(`${endPoints.components}/${id}/${endPoints.screwCompressorCurves}`, payload)
    .then((res) =>
      dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_CURVES_DATA_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_CURVES_DATA_FAILURE, payload: err })
    );
}

export const clearCurveUpdateState = () => (dispatch) => {
  dispatch({ type: Types.UPDATE_SCREW_COMPRESSOR_CURVES_DATA_RESET });
};
