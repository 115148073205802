import { Icon, Input } from 'antd';
import React from 'react';
import { validateNumberNegative } from '../../../../../../libs/validator';
import { hvsgDesignDataVariables } from '../../../../../../constants/variables';

export const keyChange = {
  [hvsgDesignDataVariables.speedType]: 'speedType',
  [hvsgDesignDataVariables.minGuideVanePosition]: 'minGuideVanePosition',
  [hvsgDesignDataVariables.maxGuideVanePosition]: 'maxGuideVanePosition',
};

export const fixedDropdownData = {
  [hvsgDesignDataVariables.speedType]: ['Fixed', 'Variable'],
};

export const initialData = Object.values(hvsgDesignDataVariables).map(
  (item) => {
    if (hvsgDesignDataVariables.speedType === item) {
      return {
        name: item,
        design: ' ',
      };
    } else {
      return {
        name: item,
        design: '',
      };
    }
  }
);

const getNumberInputDesign = (value, item, disabled, config) => {
  const tempError = !!(
    (item.name === hvsgDesignDataVariables.minGuideVanePosition ||
      item.name === hvsgDesignDataVariables.maxGuideVanePosition ||
      config.dirtyValues.indexOf(item.name) >= 0) &&
    (item.design === '' || !item.design) &&
    config.saveListener
  );
  return (
    <div>
      <Input
        type="number"
        defaultValue={value}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          config.handleChange(e.target.value, item.name, 'number');
        }}
        onKeyDown={(e) => {
          validateNumberNegative(e, e.target.value)
        }}
        className={tempError ? 'error' : 'not-error'}
      />
    </div>
  );
};

const getDropDownInput = (item, disabled, config) => {
  const tempError =
    config.dirtyValues.indexOf(item.name) >= 0 ||
    item.design === 'Select' ||
    !item.design;
  return (
    <div
      className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
        tempError ? 'error' : 'not-error'
      }  `}
    >
      <select
        value={!item.design ? '' : item.design}
        disabled={disabled}
        onChange={(e) =>
          config.handleChange(e.target.value, item.name, 'select')
        }
        className={tempError ? 'error' : 'not-error'}
      >
        <option key= "select" value="">Select</option>
        {fixedDropdownData[item.name] &&
          fixedDropdownData[item.name].map((item) => (
            <option key={item} value={item} title={item}>
              {item}
            </option>
          ))}
      </select>
    </div>
  );
};

export const hvsgDesignDataTable = (config) => [
  {
    title: 'Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          {value} <span className="mandatory">*</span>
        </div>
      );
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (item.name === hvsgDesignDataVariables.speedType) {
        return getDropDownInput(item, disabled, config);
      }
      return getNumberInputDesign(value, item, disabled, config);
    },
  },
  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const hvsgDesignData = (displayData) => {
  return displayData.filter((item) => {
    if (Object.values(hvsgDesignDataVariables).includes(item.name)) {
      return item;
    }
    return undefined;
  });
};

export const validateData = (displayData, setDirtyValues) => {
  let isInValid = false;
  let isMinGuideVaneGreaterThanMax = false;
  let dirtyRows = [];
  let minGuideVanePosition = 0;
  let maxGuideVanePosition = 0;
  // mandatory field check
  displayData.forEach((item) => {
    let design = item.design;
    if (item.name === hvsgDesignDataVariables.speedType) {
      design = design.trim();
    }
    if (!design && design !== 0) {
      dirtyRows.push(item.name);
      isInValid = true;
    }
    if (item.name === hvsgDesignDataVariables.minGuideVanePosition) {
      minGuideVanePosition = item.design;
    }
    if (item.name === hvsgDesignDataVariables.maxGuideVanePosition) {
      maxGuideVanePosition = item.design;
    }
    setDirtyValues(dirtyRows);
  });

  if (minGuideVanePosition > maxGuideVanePosition) {
    isMinGuideVaneGreaterThanMax = true;
  }

  return { isInValid, isMinGuideVaneGreaterThanMax };
};
