import {
  CLEAR_STATE,
  ADD_BYPASS_VALVE_ERROR,
  ADD_BYPASS_VALVE_PROGRESS,
  ADD_BYPASS_VALVE_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_PROGRESS,
  GET_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_PROGRESS,
  GET_MANUFACTURER_FETCH_SUCCESS,
} from "../Types/bypass.types";
import { PUTRequest, POSTRequest } from "../../api/request";

export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}

export function addBypassValve(payload, id) {
  return function (dispatch) {
    dispatch({ type: ADD_BYPASS_VALVE_PROGRESS });
    PUTRequest(`components/${id}/liquidexpanderconfigs/bypass`, payload)
      .then((res) => {
        dispatch({ type: ADD_BYPASS_VALVE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_BYPASS_VALVE_ERROR, payload: err });
      });
  };
}

export const getManufacturer = () => (dispatch) => {
  dispatch({ type: GET_MANUFACTURER_FETCH_PROGRESS });
  POSTRequest("manufacturers/filter")
    .then((res) =>
      dispatch({ type: GET_MANUFACTURER_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_MANUFACTURER_FETCH_ERROR, payload: err })
    );
};

export const getEquipmentType = () => (dispatch) => {
  dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_PROGRESS });
  POSTRequest("equipmenttypes/filter")
    .then((res) =>
      dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_EQUIPMENT_TYPE_FETCH_ERROR, payload: err })
    );
};
