import { Icon, Input } from "antd";
import React from "react";
import { gtDesignDataVariables } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

export const dataUnitsOptions = (units) => {
  if (units) {
    return units.map((item) => <option value={item}>{item}</option>);
  }
  return null;
};

export const DesignDataShow = (config) => [
  {
    title: "Config at ISO Conditions",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (
        value === gtDesignDataVariables.application ||
        value === gtDesignDataVariables.gtClass ||
        value === gtDesignDataVariables.gtIsoHeatRate ||
        value === gtDesignDataVariables.gtIsoPower ||
        value === gtDesignDataVariables.configSpeed
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      if (
        item.name !== gtDesignDataVariables.application &&
        item.name !== gtDesignDataVariables.gtModel &&
        item.name !== gtDesignDataVariables.gtClass &&
        item.name !== gtDesignDataVariables.cycle
      ) {
        const tempError = !!(
          (item.name === gtDesignDataVariables.gtIsoPower ||
            item.name === gtDesignDataVariables.gtIsoHeatRate ||
            item.name === gtDesignDataVariables.configSpeed) &&
          !item.unit &&
          config.saveListener
        );
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            }  `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        item.name === gtDesignDataVariables.application &&
        item.design === "" &&
        config.saveListener
      );
      if (item.name === gtDesignDataVariables.application) {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            }  `}
          >
            <select
              value={!item.design ? "" : item.design}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "select")
              }
              className={tempError ? "error" : "not-error"}
            >
              <option value="">Select</option>
              {data[item.name] &&
                data[item.name].map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div>
        );
      }

      if (
        item.name === gtDesignDataVariables.gtModel ||
        item.name === gtDesignDataVariables.gtClass ||
        item.name === gtDesignDataVariables.cycle
      ) {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "select")
              }
              className={
                (item.name === gtDesignDataVariables.gtModel ||
                  item.name === gtDesignDataVariables.gtClass) &&
                !item.design &&
                config.saveListener
                  ? "error"
                  : "not-error"
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "number")
            }
            className={
              (item.name === gtDesignDataVariables.gtIsoPower &&
                !item.design &&
                config.saveListener) ||
              (item.name === gtDesignDataVariables.gtIsoHeatRate &&
                !item.design &&
                config.saveListener) ||
              (item.name === gtDesignDataVariables.configSpeed &&
                !item.design &&
                config.saveListener)
                ? "error"
                : "not-error"
            }
          />
        </div>
      );
    },
  },

  config.isSchematicEditable && {
    title: "",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, "isoData")}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, "isoData")}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const ReqdCalcDataTable = (config) => [
  {
    title: "Required Calculation Data",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (
        value === gtDesignDataVariables.startupPower ||
        value === gtDesignDataVariables.startupSpeed ||
        value === gtDesignDataVariables.referenceAmbientPressure ||
        value === gtDesignDataVariables.defaultAxialCompressorInletPressure ||
        value === gtDesignDataVariables.gtPowerCorrectionFactor ||
        value === gtDesignDataVariables.gtPowerEstCorrectionFactor
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
    },
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === gtDesignDataVariables.startupPower ||
          item.name === gtDesignDataVariables.startupSpeed ||
          item.name === gtDesignDataVariables.referenceAmbientPressure ||
          item.name ===
            gtDesignDataVariables.defaultAxialCompressorInletPressure) &&
        !item.unit &&
        config.saveListener
      );

      if (
        item.name === gtDesignDataVariables.startupPower ||
        item.name === gtDesignDataVariables.startupSpeed ||
        item.name === gtDesignDataVariables.referenceAmbientPressure ||
        item.name === gtDesignDataVariables.defaultAxialCompressorInletPressure
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            }  `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "number")
            }
            className={"not-error"}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: "",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, "reqData")}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, "reqData")}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];
export const OtherDesignDataTable = (config) => [
  {
    title: "Other Design Data",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      return value;
    },
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      //sort this temperror
      const tempError = false;
      if (
        item.name === gtDesignDataVariables.airCompressorMaximumTipSpeed ||
        item.name === gtDesignDataVariables.turbineMaximumTipSpeed ||
        item.name ===
          gtDesignDataVariables.combustorMaxAllowableTemperatureVariation ||
        item.name === gtDesignDataVariables.ratedFuelMoleWeight ||
        item.name === gtDesignDataVariables.ratedFuelLHV ||
        item.name === gtDesignDataVariables.fuelPressure ||
        item.name === gtDesignDataVariables.fuelTemperature
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            }  `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => {
      if (
        item.name === gtDesignDataVariables.airCompressorType ||
        item.name === gtDesignDataVariables.airCompressorCasingSplit ||
        item.name === gtDesignDataVariables.airCompressorRotor ||
        item.name === gtDesignDataVariables.turbineCasingSplit ||
        item.name === gtDesignDataVariables.turbineRotor ||
        item.name === gtDesignDataVariables.fuelType
      ) {
        return (
          <select
            value={!item.design ? "" : item.design}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "select")
            }
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        );
      }

      if (
        item.name === gtDesignDataVariables.combustorType ||
        item.name === gtDesignDataVariables.combustorConfiguration ||
        item.name === gtDesignDataVariables.mineralLubeOil ||
        item.name === gtDesignDataVariables.syntheticLubeOil
      ) {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "select")
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "number")
            }
            className={"not-error"}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: "",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, "othData")}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, "othData")}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  {
    name: gtDesignDataVariables.application,
    design: parseFloat(""),
  },
  { name: gtDesignDataVariables.gtModel, design: parseFloat("") },
  { name: gtDesignDataVariables.gtClass, design: parseFloat("") },
  { name: gtDesignDataVariables.gtIsoPower, design: parseFloat("") },
  { name: gtDesignDataVariables.gtIsoHeatRate, design: parseFloat("") },
  { name: gtDesignDataVariables.gtIsoExhaustFlowRate, design: parseFloat("") },
  { name: gtDesignDataVariables.gtIsoSpeed, design: parseFloat("") },
  { name: gtDesignDataVariables.configSpeed, design: parseFloat("") },
  { name: gtDesignDataVariables.startupSpeed, design: parseFloat("") },
  { name: gtDesignDataVariables.startupPower, design: parseFloat("") },
  {
    name: gtDesignDataVariables.defaultAxialCompressorInletPressure,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.referenceAmbientPressure,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.gtPowerCorrectionFactor,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.gtPowerEstCorrectionFactor,
    design: parseFloat(""),
  },
  { name: gtDesignDataVariables.cycle, design: parseFloat("") },
  {
    name: gtDesignDataVariables.gtDriverOutputSpeedRangeMin,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.gtDriverOutputSpeedRangeMax,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorStages,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorMaximumTipSpeed,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorType,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorPressureRatioISOConditions,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorCasingSplit,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.airCompressorRotor,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.turbineStages,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.turbineMaximumTipSpeed,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.turbineCasingSplit,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.turbineRotor,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.combustorType,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.combustorQuantity,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.fuelNozzlesPerCombustor,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.combustorConfiguration,
    unit: null,
    design: parseFloat(""),
  },
  { name: gtDesignDataVariables.fuelType, unit: null, design: parseFloat("") },
  {
    name: gtDesignDataVariables.combustorMaxAllowableTemperatureVariation,
    design: parseFloat(""),
  },
  { name: gtDesignDataVariables.ratedFuelMoleWeight, design: parseFloat("") },
  { name: gtDesignDataVariables.ratedFuelLHV, design: parseFloat("") },
  { name: gtDesignDataVariables.fuelPressure, design: parseFloat("") },
  { name: gtDesignDataVariables.fuelTemperature, design: parseFloat("") },
  {
    name: gtDesignDataVariables.mineralLubeOil,
    unit: null,
    design: parseFloat(""),
  },
  {
    name: gtDesignDataVariables.syntheticLubeOil,
    unit: null,
    design: parseFloat(""),
  },
];

export const KeyChange = {
  [gtDesignDataVariables.application]: "application",
  [gtDesignDataVariables.gtModel]: "gtModel",
  [gtDesignDataVariables.gtClass]: "gtClass",
  [gtDesignDataVariables.gtIsoPower]: "gtIsoPower",
  [gtDesignDataVariables.gtIsoHeatRate]: "gtIsoHeatRate",
  [gtDesignDataVariables.gtIsoExhaustFlowRate]: "gtIsoExhaustFlowRate",
  [gtDesignDataVariables.gtIsoSpeed]: "gtIsoSpeed",
  [gtDesignDataVariables.configSpeed]: "configSpeed",
  [gtDesignDataVariables.startupSpeed]: "startupSpeed",
  [gtDesignDataVariables.startupPower]: "startupPower",
  [gtDesignDataVariables.defaultAxialCompressorInletPressure]:
    "defaultAxialCompressorInletPressure",
  [gtDesignDataVariables.referenceAmbientPressure]: "referenceAmbientPressure",
  [gtDesignDataVariables.gtPowerCorrectionFactor]: "gtPowerCorrectionFactor",
  [gtDesignDataVariables.gtPowerEstCorrectionFactor]:
    "gtPowerEstCorrectionFactor",
  [gtDesignDataVariables.cycle]: "cycle",
  [gtDesignDataVariables.gtDriverOutputSpeedRangeMin]:
    "gtDriverOutputSpeedRangeMin",
  [gtDesignDataVariables.gtDriverOutputSpeedRangeMax]:
    "gtDriverOutputSpeedRangeMax",
  [gtDesignDataVariables.airCompressorStages]: "airCompressorStages",
  [gtDesignDataVariables.airCompressorMaximumTipSpeed]:
    "airCompressorMaximumTipSpeed",
  [gtDesignDataVariables.airCompressorType]: "airCompressorType",
  [gtDesignDataVariables.airCompressorPressureRatioISOConditions]:
    "airCompressorPressureRatioISOConditions",
  [gtDesignDataVariables.airCompressorCasingSplit]: "airCompressorCasingSplit",
  [gtDesignDataVariables.airCompressorRotor]: "airCompressorRotor",
  [gtDesignDataVariables.turbineStages]: "turbineStages",
  [gtDesignDataVariables.turbineMaximumTipSpeed]: "turbineMaximumTipSpeed",
  [gtDesignDataVariables.turbineCasingSplit]: "turbineCasingSplit",
  [gtDesignDataVariables.turbineRotor]: "turbineRotor",
  [gtDesignDataVariables.combustorType]: "combustorType",
  [gtDesignDataVariables.combustorQuantity]: "combustorQuantity",
  [gtDesignDataVariables.fuelNozzlesPerCombustor]: "fuelNozzlesPerCombustor",
  [gtDesignDataVariables.combustorConfiguration]: "combustorConfiguration",
  [gtDesignDataVariables.fuelType]: "fuelType",
  [gtDesignDataVariables.combustorMaxAllowableTemperatureVariation]:
    "combustorMaxAllowableTemperatureVariation",
  [gtDesignDataVariables.ratedFuelMoleWeight]: "ratedFuelMoleWeight",
  [gtDesignDataVariables.ratedFuelLHV]: "ratedFuelLHV",
  [gtDesignDataVariables.fuelPressure]: "fuelPressure",
  [gtDesignDataVariables.fuelTemperature]: "fuelTemperature",
  [gtDesignDataVariables.mineralLubeOil]: "mineralLubeOil",
  [gtDesignDataVariables.syntheticLubeOil]: "syntheticLubeOil",
};

export const DataVariableMap = {
  application: "Application",
  gtModel: "Gas Turbine Model",
  gtClass: "Gas Turbine Class",
  gtIsoPower: "ISO Power Rating",
  gtIsoHeatRate: "ISO Heat Rate",
  gtIsoExhaustFlowRate: "ISO Exhaust Flow Rate",
  gtIsoSpeed: "GT Speed at ISO Rating",
  configSpeed: "Config Speed",
  startupSpeed: "GT Start up Speed threshold",
  startupPower: "GT Start up Power threshold",
  defaultAxialCompressorInletPressure:
    "Default Axial Compressor Inlet Pressure",
  referenceAmbientPressure: "Reference Ambient Pressure",
  gtPowerCorrectionFactor: "GT Power Correction Factor",
  gtPowerEstCorrectionFactor: "GT Estimated Power Correction Factor",
  cycle: "Cycle",
  gtDriverOutputSpeedRangeMin: "GT Driver Output Speed Range: Minimum",
  gtDriverOutputSpeedRangeMax: "GT Driver Output Speed Range: Maximum",
  airCompressorStages: "Air Compressor Stages",
  airCompressorMaximumTipSpeed: "Air Compressor Maximum Tip Speed",
  airCompressorType: "Air Compressor Type",
  airCompressorPressureRatioISOConditions:
    "Air Compressor Pressure Ratio at ISO Conditions",
  airCompressorCasingSplit: "Air Compressor Casing Split",
  airCompressorRotor: "Air Compressor Rotor",
  turbineStages: "Turbine Stages",
  turbineMaximumTipSpeed: "Turbine Maximum Tip Speed",
  turbineCasingSplit: "Turbine Casing Split",
  turbineRotor: "Turbine Rotor",
  combustorType: "Combustor Type",
  combustorQuantity: "Combustor Quantity",
  fuelNozzlesPerCombustor: "Fuel Nozzles Per Combustor",
  combustorConfiguration: "Combustor Configuration",
  fuelType: "Fuel Type",
  combustorMaxAllowableTemperatureVariation:
    "Combustor Max Allowable Temperature Variation",
  ratedFuelMoleWeight: "Rated Fuel Mole Weight",
  ratedFuelLHV: "Rated Fuel LHV",
  fuelPressure: "Fuel Pressure",
  fuelTemperature: "Fuel Temperature",
  mineralLubeOil: "Mineral Lube Oil",
  syntheticLubeOil: "Synthetic Lube Oil",
};

export const data = {
  [gtDesignDataVariables.application]: ["Variable Speed", "Fixed Speed"],
  [gtDesignDataVariables.airCompressorType]: ["Axial", "Radial"],
  [gtDesignDataVariables.airCompressorCasingSplit]: ["Axial", "Radial"],
  [gtDesignDataVariables.airCompressorRotor]: ["Solid", "Built-up"],
  [gtDesignDataVariables.turbineCasingSplit]: ["Axial", "Radial"],
  [gtDesignDataVariables.turbineRotor]: ["Solid", "Built-up"],
  [gtDesignDataVariables.fuelType]: ["Gas", "Liquid", "Dual"],
};

export const filterReqdData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === gtDesignDataVariables.startupPower ||
      item.name === gtDesignDataVariables.startupSpeed ||
      item.name === gtDesignDataVariables.referenceAmbientPressure ||
      item.name === gtDesignDataVariables.defaultAxialCompressorInletPressure ||
      item.name === gtDesignDataVariables.gtPowerCorrectionFactor ||
      item.name === gtDesignDataVariables.gtPowerEstCorrectionFactor
    ) {
      return item;
    }
    return undefined;
  });
};

export const filterOtherDesignData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === gtDesignDataVariables.airCompressorStages ||
      item.name === gtDesignDataVariables.airCompressorMaximumTipSpeed ||
      item.name === gtDesignDataVariables.airCompressorType ||
      item.name ===
        gtDesignDataVariables.airCompressorPressureRatioISOConditions ||
      item.name === gtDesignDataVariables.airCompressorCasingSplit ||
      item.name === gtDesignDataVariables.airCompressorRotor ||
      item.name === gtDesignDataVariables.turbineStages ||
      item.name === gtDesignDataVariables.turbineMaximumTipSpeed ||
      item.name === gtDesignDataVariables.turbineCasingSplit ||
      item.name === gtDesignDataVariables.turbineRotor ||
      item.name === gtDesignDataVariables.combustorType ||
      item.name === gtDesignDataVariables.combustorQuantity ||
      item.name === gtDesignDataVariables.fuelNozzlesPerCombustor ||
      item.name === gtDesignDataVariables.combustorConfiguration ||
      item.name === gtDesignDataVariables.fuelType ||
      item.name ===
        gtDesignDataVariables.combustorMaxAllowableTemperatureVariation ||
      item.name === gtDesignDataVariables.ratedFuelMoleWeight ||
      item.name === gtDesignDataVariables.ratedFuelLHV ||
      item.name === gtDesignDataVariables.fuelPressure ||
      item.name === gtDesignDataVariables.fuelTemperature ||
      item.name === gtDesignDataVariables.mineralLubeOil ||
      item.name === gtDesignDataVariables.syntheticLubeOil
    ) {
      return item;
    }
    return undefined;
  });
};

export const filterConfigIsoCondData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === gtDesignDataVariables.application ||
      item.name === gtDesignDataVariables.gtModel ||
      item.name === gtDesignDataVariables.gtClass ||
      item.name === gtDesignDataVariables.gtIsoPower ||
      item.name === gtDesignDataVariables.gtIsoHeatRate ||
      item.name === gtDesignDataVariables.gtIsoExhaustFlowRate ||
      item.name === gtDesignDataVariables.gtIsoSpeed ||
      item.name === gtDesignDataVariables.configSpeed ||
      item.name === gtDesignDataVariables.cycle ||
      item.name === gtDesignDataVariables.gtDriverOutputSpeedRangeMin ||
      item.name === gtDesignDataVariables.gtDriverOutputSpeedRangeMax
    ) {
      return item;
    }
    return undefined;
  });
};
