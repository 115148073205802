import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import {
  handleSort,
  validatePositiveFloat,
  validateWholeNumber,
} from "../../../../../../common/methods";
import { PerformanceDataVariables } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

export const PerformanceDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (value === PerformanceDataVariables.fullLoadSpeed) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "Units",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      if (
        item.name !== PerformanceDataVariables.statorSlots &&
        item.name !== PerformanceDataVariables.rotorSlots
      ) {
        const tempError = !!(
          (item.name === PerformanceDataVariables.fullLoadSpeed &&
            !item.unit &&
            config.saveListener) ||
          (!item.unit &&
            (item.design || item.design === 0) &&
            config.saveListener)
        );
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            } `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return handleSort(getUnits.units).map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "Design",
    dataIndex: "design",
    render: (_value, _row, _col, disabled, item) => (
      <div>
        <Input
          value={item.design}
          disabled={disabled}
          AutoComplete="off"
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          onChange={(e) =>
            config.handleChange(e.target.value, item.name, "number")
          }
          className={
            (item.name === PerformanceDataVariables.fullLoadSpeed &&
              ((item.unit &&
                item.design &&
                !validateWholeNumber(item.design.toString())) ||
                (item.unit &&
                  item.design &&
                  !_.isFinite(parseFloat(item.design))) ||
                (!item.design &&
                  !_.isFinite(parseFloat(item.design)) &&
                  config.saveListener))) ||
            (item.unit &&
              item.design &&
              !validatePositiveFloat(item.design.toString())) ||
            (item.unit && !_.isFinite(parseFloat(item.design))) ||
            ((item.name === PerformanceDataVariables.statorSlots ||
              item.name === PerformanceDataVariables.rotorSlots) &&
              item.design &&
              item.design.toString() &&
              !validateWholeNumber(item.design.toString()))
              ? "error"
              : "not-error"
          }
        />
      </div>
    ),
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  { name: PerformanceDataVariables.ratedPowerOutput, unit: null, design: "" },
  { name: PerformanceDataVariables.ratedCurrent, unit: null, design: "" },
  { name: PerformanceDataVariables.synchronousSpeed, unit: null, design: "" },
  { name: PerformanceDataVariables.fullLoadSpeed, unit: null, design: "" },
  { name: PerformanceDataVariables.fullLoadTorque, unit: null, design: "" },
  { name: PerformanceDataVariables.rotorGD2AtFLRPM, unit: null, design: "" },
  { name: PerformanceDataVariables.rotorWeight, unit: null, design: "" },
  { name: PerformanceDataVariables.lockedRotortorque, unit: null, design: "" },
  { name: PerformanceDataVariables.pullUpTorque, unit: null, design: "" },
  { name: PerformanceDataVariables.breakDownTorque, unit: null, design: "" },
  { name: PerformanceDataVariables.lockedRotorCurrent, unit: null, design: "" },
  { name: PerformanceDataVariables.lockedRotorPF, unit: null, design: "" },
  {
    name: PerformanceDataVariables.lockedRotorWithstandTimeCold,
    unit: null,
    design: "",
  },
  {
    name: PerformanceDataVariables.lockedRotorWithstandTimeRatedTemp,
    unit: null,
    design: "",
  },
  { name: PerformanceDataVariables.statorSlots, design: "" },
  { name: PerformanceDataVariables.rotorSlots, design: "" },
];

export const ChangeKey = {
  [PerformanceDataVariables.ratedPowerOutput]: "ratedPowerOutput",
  [PerformanceDataVariables.ratedCurrent]: "ratedCurrent",
  [PerformanceDataVariables.synchronousSpeed]: "synchronousSpeed",
  [PerformanceDataVariables.fullLoadSpeed]: "fullLoadSpeed",
  [PerformanceDataVariables.fullLoadTorque]: "fullLoadTorque",
  [PerformanceDataVariables.rotorGD2AtFLRPM]: "rotorGD2AtFLRPM",
  [PerformanceDataVariables.rotorWeight]: "rotorWeight",
  [PerformanceDataVariables.lockedRotortorque]: "lockedRotortorque",
  [PerformanceDataVariables.pullUpTorque]: "pullUpTorque",
  [PerformanceDataVariables.breakDownTorque]: "breakDownTorque",
  [PerformanceDataVariables.lockedRotorCurrent]: "lockedRotorCurrent",
  [PerformanceDataVariables.lockedRotorPF]: "lockedRotorPF",
  [PerformanceDataVariables.lockedRotorWithstandTimeCold]:
    "lockedRotorWithstandTimeCold",
  [PerformanceDataVariables.lockedRotorWithstandTimeRatedTemp]:
    "lockedRotorWithstandTimeRatedTemp",
  [PerformanceDataVariables.statorSlots]: "statorSlots",
  [PerformanceDataVariables.rotorSlots]: "rotorSlots",
};
