import * as ForgotPassword from "../Types/forgotPassword.types";

const changePasswordState = {
  isError: false,
  errorCode: null,
  message: null,
  isSuccess: false,
  isLoading: false,
};

const INITIAL_STATE = {
  changePasswordState,
  isError: false,
  errorCode: null,
  message: null,
  isSuccess: false,
  isLoading: false,
};

export default function flowMeasurementReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case ForgotPassword.SET_FORGOT_PASSWORD_PROGRESS:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };

    case ForgotPassword.SET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message:
          action.payload.response && action.payload.response.body.message,
        isError: false,
      };

    case ForgotPassword.SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case ForgotPassword.SET_NEW_FORGOT_PASSWORD_PROGRESS:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isLoading: true,
          isError: false,
        },
      };

    case ForgotPassword.SET_NEW_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordState: {
          isLoading: false,
          isSuccess: true,
          message:
            action.payload.response && action.payload.response.body.message,
          isError: false,
        },
      };

    case ForgotPassword.SET_NEW_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordState: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ForgotPassword.FORGOT_PASSWORD_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
