import { Form, Modal, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FormFields from '../../../common/FormFields/FormFields';
import Loader from '../../../common/Loader/Loader';
import Notification, { Types } from '../../../common/Notification/Notification';
import cardTypeMapper from '../../../constants/cardTypes';
import {
  addNewCardMessages,
  NO_INTERNET,
  ERROR_MESSAGES,
} from '../../../constants/messages';
import { isMaxExceeded } from '../../../libs/validator';
import { getRunTags } from '../../../redux/actions/runTags.action';
import { addNewTrendCard } from '../../../redux/actions/trends.action';
import { runStatsConstant } from '../DashboardInfo/Runs/DetailsConstants';
import {
  addNewCard,
  clearAddNewCardState,
  clearCardState,
  clearDeloyedEquipment,
  clearDeployedComponent,
  clearTagsByCardType,
  clearTagsByComponent,
  clearUpdataKpiState,
  getCardTypes,
  getDeployedComponent,
  getDeployedEquipment,
  getTagsByCardType,
  getTagsByComponent,
  getTagsforTrends,
  onUpdateKpi,
  getPanelTypes,
  savePanelTypes,
  clearPanelTypeState
} from '../../../redux/AddNewCard/addNewCard.action';
import {
  formFieldsTrendsValue,
  formFieldsValueData,
  getCardTypeForm,
  editCard,
  trendsForm,
  customAlertPlot
} from './AddNewCard.constants';
import {
  addAlertPlot,
  onUpdateAlertKpi
} from '../../../redux/actions/alertplots.action';
import * as Pattern from '../../../constants/pattern';
import './AddNewCard.scss';

class AddNewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelName: '',
      levelId: '',
      visible: true,
      cardTypeId: null,
      deployedComponentId: '',
      deployedEquipmentId: '',
      xAxisTagId: '',
      yAxisTagId: '',
      equipmentList: [],
      cardTypes: [],
      deployedEquipmentList: [],
      deployedComponentList: [],
      tags: [],
      cardTypeName: '',
      isAddTrends: false,
      isCardTypes: false,
      yname: [],
      xname: [],
      tempYAxis: [],
      cardTitleValue: '',
      variableTagValue: '',
      isVisibleToClientValue: false,
      deployedEquipmentValue: '',
      deployedComponentValue: '',
      componentType: '',
      yAxisBaselineTag: '',
      xAxisBaselineTag: '',
      isXYBaseline: false,
      isEditTrends: false,
      addModalVisible: false,
      panelTypeName: '',
      panelTypes: [],
      selectedPanel: '',
      trendsCardType: '',
      series: [],
      seriesLen: 1,
      trendsCardTitle: '',
      seriesTitle: '',
      seriesTitleList: [],
      recipCurveArray: [],
      screwCurveType:"",
      axisInfo: [],
    };
  }

  componentDidMount() {
    const {
      info,
      getTagsByCardType,
      getCardTypes,
      activeKey,
      cardData,
      mode,
    } = this.props;
    const { type, dataRef } = info;
    const levelId = dataRef._id;
    this.setState({ levelName: type, levelId });
    if (activeKey === '6' || activeKey === '7' || activeKey === '2') {
      const cardType = cardData?.cardType?.cardType || 'Time series plot';
      this.setState({
        isAddTrends: true,
        cardTypeName: 'Time series plot',
        trendsCardType: cardType,
      });
      if (mode === 'edit') {
        this.setState({ isEditTrends: true });
        this.onEditCard(cardData);
      } else {
        this.handleNetwork() &&
          getTagsByCardType(info.type, info.dataRef._id, 'trends');
      }
    } else if (mode === 'edit') {
      this.onEditCard(cardData);
    } else {
      this.handleNetwork() && getCardTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      CardTypesReducer,
      trendsReducer,
      clearCardState,
      DeployedComponentReducer,
      DeployedEquipmentReducer,
      clearDeloyedEquipment,
      clearDeployedComponent,
      TagsByComponentReducer,
      clearTagsByComponent,
      AddNewCardReducer,
      clearAddNewCardState,
      TagsByCardTypeReducer,
      clearTagsByCardType,
      handleCancel,
      UpdateKpiByIdReducer,
      RunTagsReducer: { getRunTags },
      PanelTypeReducer,
      clearPanelTypeState,
      AlertPlotKPIReducer,
    } = this.props;

    if (CardTypesReducer.isSuccess) {
      const { data } = CardTypesReducer;
      if (data && data.length > 0) {
        clearCardState();
        this.setState({
          cardTypes: data,
          tag: [],
          deployedEquipmentList: [],
          deployedComponentList: [],
        });
      }
    }

    if (CardTypesReducer.isError) {
      const { errorCode } = CardTypesReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearCardState();
    }

    if (DeployedEquipmentReducer.isSuccess) {
      const { data } = DeployedEquipmentReducer;
      if (data && data.length > 0) {
        clearDeloyedEquipment();
        this.setState({ deployedEquipmentList: data });
      }
    }

    if (DeployedEquipmentReducer.isError) {
      const { errorCode } = DeployedEquipmentReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearDeloyedEquipment();
    }

    if (DeployedComponentReducer.isSuccess) {
      const { data } = DeployedComponentReducer;
      if (data && data.length > 0) {
        clearDeployedComponent();
        this.setState({ deployedComponentList: data });
      }
    }

    if (DeployedComponentReducer.isError) {
      const { errorCode } = DeployedEquipmentReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearDeployedComponent();
    }

    if (
      TagsByComponentReducer.isSuccess &&
      TagsByComponentReducer.isSuccess !==
        prevProps.TagsByComponentReducer.isSuccess
    ) {
      const { data } = TagsByComponentReducer;
      if (data && data.length > 0) {
        this.setState({ tags: data }, () => {
          clearTagsByComponent();
        });
      }
    }

    if (TagsByComponentReducer.isError) {
      const { errorCode } = TagsByComponentReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearTagsByComponent();
    }

    if (getRunTags.isSuccess) {
      const { data } = TagsByCardTypeReducer;
      if (data && data.length > 0) {
        this.setState({ tags: data });
      }
    }
    if (getRunTags.isError) {
      const { errorCode } = getRunTags;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
    }

    if (AddNewCardReducer.isSuccess) {
      clearAddNewCardState();
      handleCancel();
      Notification.show(Types.Success, addNewCardMessages['SUCCESS']);
    }
    if (AddNewCardReducer.isError) {
      const { errorCode } = AddNewCardReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearAddNewCardState();
    }

    if (
      trendsReducer.addNewCardSuccess &&
      trendsReducer.addNewCardSuccess !==
        prevProps.trendsReducer.addNewCardSuccess
    ) {
      handleCancel();
      Notification.show(Types.Success, addNewCardMessages['SUCCESS']);
    }
    if (
      trendsReducer.addNewCardError &&
      trendsReducer.addNewCardError !== prevProps.trendsReducer.addNewCardError
    ) {
      const { errorCode } = trendsReducer;
      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
    }

    if (
      TagsByCardTypeReducer.isSuccess &&
      TagsByCardTypeReducer.isSuccess !==
        prevProps.TagsByCardTypeReducer.isSuccess
    ) {
      const { data } = TagsByCardTypeReducer;
      this.setState({ tags: data }, () => {
        clearTagsByCardType();
      });
    }

    if (TagsByCardTypeReducer.isError) {
      const { errorCode } = TagsByCardTypeReducer;

      Notification.show(Types.Error, `${addNewCardMessages[errorCode]}`);
      clearTagsByCardType();
    }
    if (UpdateKpiByIdReducer.isSuccess) {
      Notification.show(Types.Success, `${addNewCardMessages['UPDATED']}`);
      handleCancel();
    }

    if (UpdateKpiByIdReducer.isError) {
      const { message } = UpdateKpiByIdReducer;

      Notification.show(Types.Error, `${message}`);
      clearUpdataKpiState();
    }

    if (PanelTypeReducer.isSuccess) {
      clearPanelTypeState();
      const { data } = PanelTypeReducer;
      this.setState({
        panelTypes: data,
      });
    }

    if (PanelTypeReducer.isAddSuccess) {
      clearPanelTypeState();
      Notification.show(
        Types.Success,
        `${addNewCardMessages['ADD_PANEL_SUCCESS']}`
      );
      const { data } = PanelTypeReducer;
      const { panelTypeName } = this.state;
      this.setState({
        panelTypes: data,
        addModalVisible: false,
        selectedPanel: panelTypeName,
      });
    }

    if (PanelTypeReducer.isError) {
      clearPanelTypeState();
      const { message } = PanelTypeReducer;
      Notification.show(Types.Error, `${message}`);
    }

    if (AlertPlotKPIReducer.isKPIAddSuccess) {
      clearAddNewCardState();
      handleCancel();
      Notification.show(Types.Success, addNewCardMessages['SUCCESS']);
    }
  }

  checkIfIdentical(newAxis, oldAxis) {
    if(_.isUndefined(newAxis) || _.isUndefined(oldAxis))  return true;
    if (newAxis.length !== oldAxis.length) return false;
    let umap = new Map(),
      i = 0,
      res = true;
    oldAxis.forEach((i) => {
      umap.set(i.value, true);
    });
    while (i < newAxis.length && res) {
      //console.log(newAxis[i].value, umap.has(newAxis[i].value));
      if (!umap.has(newAxis[i].value)) res = false;
      i++;
    }
    return res;
  }

  handleOk = () => {
    const {
      tags,
      isAddTrends,
      cardTypeName,
      xAxisTagId,
      yAxisTagId,
      cardTypeId,
      deployedEquipmentId,
      deployedComponentId,
      xAxisBaselineTag,
      yAxisBaselineTag,
      isEditTrends,
      selectedPanel,
      trendsCardType,
      series,
      seriesLen,
      trendsCardTitle,
      seriesTitle,
      recipCurveArray,
      axisInfo,
      screwCurveType
    } = this.state;
    const dataValues = {};
    const {
      addNewCard,
      info,
      addNewTrendCard,
      onUpdateKpi,
      cardData,
      mode,
      alertId,
      activeKey,
      addAlertPlot,
      onUpdateAlertKpi,
    } = this.props;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let y = [];
        let x = [];
        let identicalAxisValues = true;
        if (isAddTrends) {
          if (trendsCardType === 'Multiple X-Y Plot') {
            if (this.props.mode === 'edit') {
              const tempSeries = [];
              const oldSeries = cardData?.axis?.series;
              for (let i = 0; i < this.state.seriesLen; i++) {
                const yTagVal = this.props.form.getFieldValue(`yAxis${i}`);
                const xTagVal = this.props.form.getFieldValue(`xAxis${i}`);
                const seriesTitleVal = _.isEmpty(
                  this.props.form.getFieldValue(`seriesTitle${i}`)
                )
                  ? ''
                  : this.props.form.getFieldValue(`seriesTitle${i}`);
                let yTag = tags.find(
                  (tagElement) => tagElement.variableNameTag === yTagVal
                );
                let yObj = {
                  value: yTag.name,
                  type: yTag.type,
                  variableName: yTag.variableName,
                  minScale:
                    oldSeries[i]?.y?.value === yTag.name
                      ? oldSeries[i]?.y?.minScale
                      : '',
                  maxScale:
                    oldSeries[i]?.y?.value === yTag.name
                      ? oldSeries[i]?.y?.maxScale
                      : '',
                };
                let xTag = tags.find(
                  (tagElement) => tagElement.variableNameTag === xTagVal
                );
                let xObj = {
                  value: xTag.name,
                  type: xTag.type,
                  variableName: xTag.variableName,
                  minScale:
                    oldSeries[i]?.x?.value === xTag.name
                      ? oldSeries[i]?.x?.minScale
                      : '',
                  maxScale:
                    oldSeries[i]?.x?.value === xTag.name
                      ? oldSeries[i]?.x?.maxScale
                      : '',
                };
                tempSeries.push({
                  seriesTitle: seriesTitleVal,
                  x: xObj,
                  y: yObj,
                });
              }
              values['axis'] = { series: tempSeries };
              for (let i = 0; i < seriesLen; i++) {
                delete values[`xAxis${i}`];
                delete values[`yAxis${i}`];
              }
              dataValues['cardType'] = '6141ba1df8d02a51c1f13116';
              dataValues['type'] = 'trends';
            } else {
              const tempSeries = [
                ...series,
                { x: xAxisTagId, y: yAxisTagId, seriesTitle },
              ];
              tempSeries.forEach((element) => {
                let yTag = tags.find(
                  (tagElement) =>
                    tagElement._id === element.y ||
                    tagElement.name === element.y
                );
                element.y = {
                  value: yTag.name,
                  type: yTag.type,
                  variableName: yTag.variableName,
                };
                let xTag = tags.find(
                  (tagElement) =>
                    tagElement._id === element.x ||
                    tagElement.name === element.x
                );
                element.x = {
                  value: xTag.name,
                  type: xTag.type,
                  variableName: xTag.variableName,
                };
              });
              values['axis'] = { series: tempSeries };
              for (let i = 0; i < seriesLen; i++) {
                delete values[`xAxis${i}`];
                delete values[`yAxis${i}`];
              }
              dataValues['cardType'] = '6141ba1df8d02a51c1f13116';
              dataValues['type'] = 'trends';
            }
          } else if (yAxisTagId) {
            yAxisTagId.forEach((tagId) => {
              let tag = tags.find(
                (tagElement) =>
                  tagElement._id === tagId || tagElement.name === tagId
              );
              if (tag === undefined) {
                axisInfo.forEach((i) => {
                  if (
                    i.value === tagId ||
                    i._id === tagId ||
                    i.variableName + ` (${i.value})` === tagId
                  ) {
                    tag = {
                      name: i.value,
                      type: i.type,
                      variableName: i.variableName,
                    };
                  }
                });
              }
              let { name, type, variableName } = tag;
              y.push({ value: name, type, variableName });
            });
            identicalAxisValues = this.checkIfIdentical(y, cardData?.axis?.y);
            if (mode === 'edit') {
              y.forEach((item) => {
                cardData.axis.y.forEach((i) => {
                  if (item.value === i.value) {
                    item['maxScale'] = i['maxScale'] || '';
                    item['minScale'] = i['minScale'] || '';
                  }
                });
              });
            }
            values['axis'] = { y };
            delete values.yAxis;
            dataValues['cardType'] = '5de60eae2df9536907beff6e';
            dataValues['type'] = 'trends';
            if (alertId && activeKey === '2') {
              dataValues['alertId'] = alertId;
              dataValues['type'] = 'alertPlot';
              dataValues['cardType'] = '626a513a79882552776b669a';
            }
          }
        } else if (
          cardTypeName === cardTypeMapper['Time series plot'] ||
          cardTypeName === cardTypeMapper['Equipment Run Plot']
        ) {
          if (yAxisTagId) {
            yAxisTagId.forEach((tagId) => {
              let tag = tags.find(
                (tagElement) =>
                  tagElement._id === tagId || tagElement.name === tagId
              );
              let { name, type, variableName } = tag;
              y.push({ value: name, type, variableName });
            });
            if (
              mode === 'edit' &&
              cardTypeName === cardTypeMapper['Time series plot']
            ) {
              y.forEach((item) => {
                cardData.axis.y.forEach((i) => {
                  if (item.value === i.value) {
                    item['maxScale'] = i['maxScale'] || '';
                    item['minScale'] = i['minScale'] || '';
                  }
                });
              });
            }
            values['axis'] = { y };
            delete values.yAxis;
          }
        } else if (
          cardTypeName === cardTypeMapper['Variable card'] ||
          cardTypeName === cardTypeMapper['Standard deviation card']
        ) {
          let tag = tags.find(
            (tagElement) =>
              tagElement._id === yAxisTagId || tagElement.name === yAxisTagId
          );
          let { name, type, variableName } = tag;
          values['cardValue'] = { value: name, type, variableName };
          dataValues['cardValue'] = { value: name, type, variableName };
          if (
            cardTypeName === cardTypeMapper['Variable card'] &&
            selectedPanel !== ''
          ) {
            dataValues['panelType'] = this.assignPanelId();
          }
        } else if (cardTypeName === cardTypeMapper['Run Statistic']) {
          let abbreviation = '';
          for await (let tags of runStatsConstant) {
            if (tags.Tag === values.Tag) {
              abbreviation = tags.abbreviation;
            }
          }
          values['cardValue'] = {
            type: 'input',
            value: abbreviation,
            variableName: values.Tag,
          };
          dataValues['cardValue'] = {
            type: 'input',
            value: abbreviation,
            variableName: values.Tag,
          };
        } else {
          if (yAxisTagId) {
            let tag = tags.find(
              (tagElement) =>
                tagElement._id === yAxisTagId || tagElement.name === yAxisTagId
            );
            let { name, type, variableName } = tag;
            y.push({ value: name, type, variableName });
            delete values.yAxis;
          }
          if (xAxisTagId) {
            let tag = tags.find(
              (tagElement) =>
                tagElement._id === xAxisTagId || tagElement.name === xAxisTagId
            );
            let { name, type, variableName } = tag;
            x.push({ value: name, type, variableName });
            delete values.xAxis;
          }
          values['axis'] = { x, y, xAxisBaselineTag, yAxisBaselineTag };
        }
        if (mode === 'edit') {
          values['isVisibleToClient'] = cardData['isVisibleToClient'];
        }
        dataValues['cardTitle'] = values['cardTitle']
          ? values['cardTitle']
          : trendsCardTitle;

        if (!values['isVisibleToClient']) {
          dataValues['isVisibleToClient'] = false;
        } else {
          dataValues['isVisibleToClient'] = true;
        }

        if (values['toViewClientData']) {
          dataValues['toViewClientData'] = values['toViewClientData'];
        }
        if (values['axis']) {
          dataValues['axis'] = values['axis'];
        }
        if (values['cardType'] && !isAddTrends) {
          dataValues['cardType'] = cardTypeId;
        }
        if (values['equipment']) {
          dataValues['equipment'] = deployedEquipmentId;
        }
        if (values['component']) {
          dataValues['component'] = deployedComponentId;
        }
        if(screwCurveType !== ""){
          dataValues['axis']['curveType'] = [
            {name: screwCurveType}
          ]
          this.setState({screwCurveType:""})
        }
        if (recipCurveArray.length > 0) {
          const curveTypeArr = recipCurveArray.map((item) => {
            return { name: item };
          });
          dataValues['axis']['curveType'] = curveTypeArr;
        }
        if (mode === 'edit') {
          delete dataValues['cardType'];
          delete dataValues['type'];
          if (isEditTrends) {
            if (trendsCardType === 'Alert Plot') {
              onUpdateAlertKpi(cardData._id, dataValues, !identicalAxisValues);
            } else {
              onUpdateKpi(
                cardData._id,
                dataValues,
                'trends'
              );
            }
          } else {
            onUpdateKpi(cardData._id, dataValues);
          }
        } else if (this.handleNetwork() && isAddTrends) {
          if (activeKey === '2' && alertId) {
            addAlertPlot(dataValues, info.type, info.dataRef._id);
          } else {
            addNewTrendCard(info.type, info.dataRef._id, dataValues);
          }
        } else {
          addNewCard(info.type, info.dataRef._id, dataValues);
        }
      }
    });
  };

  onCardTypeChange = (cardTypeId, type = null) => {
    if (cardTypeId) {
      let cardType = '';
      const { cardTypes } = this.state;
      const {
        getTagsByCardType,
        getDeployedEquipment,
        cardData,
        mode,
        info,
        getPanelTypes,
      } = this.props;
      if (mode === 'edit') {
        cardType = cardData.cardType.cardType;
      } else {
        cardType = cardTypes.find((card) => card._id === cardTypeId).cardType;
      }
      if (
        cardType === cardTypeMapper['Time series plot'] ||
        cardType === cardTypeMapper['X-Y Plot'] ||
        cardType === cardTypeMapper['Multiple X-Y Plot'] ||
        cardType === cardTypeMapper['Alert Plot']
      ) {
        if (type === 'trends' || type === 'alertPlot') {
          this.handleNetwork() &&
            getTagsByCardType(info.type, info.dataRef._id, type);
        } else {
          this.handleNetwork() &&
            getTagsByCardType(info.type, info.dataRef._id);
        }
      } else if (cardType === cardTypeMapper['Standard deviation card']) {
        this.handleNetwork() &&
          getTagsByCardType(info.type, info.dataRef._id, 'iqr');
      } else if (cardType === 'Variable card') {
        this.handleNetwork() && getTagsByCardType(info.type, info.dataRef._id);
        this.handleNetwork() && getPanelTypes(info.type, info.dataRef._id);
      } else {
        this.handleNetwork() &&
          getDeployedEquipment(info.type, info.dataRef._id);
      }
      this.props.form.resetFields([
        'equipment',
        'component',
        'xAxis',
        'yAxis',
        'cardValue',
        'toViewClientData',
        'xAxisBaseline',
        'yAxisBaseline',
      ]);
      this.setState({
        cardTypeId,
        cardTypeName: cardType,
        xAxisTagId: '',
        yAxisTagId: '',
        deployedEquipmentId: '',
        deployedComponentId: '',
        tags: [],
        deployedEquipmentList: [],
        deployedComponentList: [],
        xAxisBaselineTag: '',
        yAxisBaselineTag: '',
      });
    }
  };

  onDeployedEquipmentChange = (deployedEquipmentId) => {
    if (deployedEquipmentId) {
      const { cardTypeName } = this.state;
      const { getRunTags, getDeployedComponent } = this.props;
      this.props.form.resetFields([
        'component',
        'xAxis',
        'yAxis',
        'cardValue',
        'xAxisBaseline',
        'yAxisBaseline',
      ]);
      this.setState({
        deployedEquipmentId,
        deployedComponentId: '',
        xAxisTagId: '',
        yAxisTagId: '',
        xAxisBaselineTag: '',
        yAxisBaselineTag: '',
        tags: [],
        deployedComponentList: [],
      });
      // todo change if condition
      if (
        cardTypeName !==
          cardTypeMapper['Alert priority Analytics tornado graph'] &&
        cardTypeName !== cardTypeMapper['Failure cause pie chart'] &&
        cardTypeName !== cardTypeMapper['Failure bar chart'] &&
        cardTypeName !== cardTypeMapper['Alert by status bar chart'] &&
        cardTypeName !== cardTypeMapper['Alert by priority bar chart'] &&
        cardTypeName !== cardTypeMapper['Alert by status pie chart'] &&
        cardTypeName !== cardTypeMapper['Alert by priority pie chart']
      ) {
        if (cardTypeName === cardTypeMapper['Equipment Run Plot']) {
          this.handleNetwork() && getRunTags(deployedEquipmentId);
        } else {
          this.handleNetwork() && getDeployedComponent(deployedEquipmentId);
        }
      }
    }
  };

  onDeployedComponentChange = (deployedComponentId) => {
    const { getTagsByComponent } = this.props;
    const { deployedComponentList } = this.state;
    let componentType = '';
    deployedComponentList.forEach((component) => {
      if (component._id === deployedComponentId) {
        if (component.type === 'Electric motors') {
          componentType = 'motor';
        } else if (component.type === 'Reciprocating Compressor') {
          componentType = 'recipCompressor';
        } else if (component.type === 'Synchronous motors') {
          componentType = 'syncMotor';
        } else if(component.type === 'Screw Compressor'){
          componentType = 'screwCompressor';
        }else if(component.type === 'Hydraulic Variable Speed Gear'){
          componentType = 'hydraulicVariableSpeedGear';
        }
        else if(component.type === 'Radial Inflow Gas Expander'){
          componentType = 'radialInflowGasExpander';
        }
        else if(component.type === 'Gas Engine'){
          componentType = 'gasEngine';
        }
        else {
          componentType = 'other';
        }
      }
    });

    this.props.form.resetFields([
      'xAxis',
      'yAxis',
      'cardValue',
      'xAxisBaseline',
      'yAxisBaseline',
    ]);
    this.setState({
      deployedComponentId,
      xAxisTagId: '',
      yAxisTagId: '',
      tags: [],
      componentType: componentType,
      xAxisBaselineTag: '',
      yAxisBaselineTag: '',
    });

    this.handleNetwork() && getTagsByComponent(deployedComponentId);
  };

  onXAxisChange = (xAxisTagId) => {
    this.setState({ xAxisTagId });
  };

  onYAxisChange = (yAxisTagId) => {
    this.setState({ yAxisTagId });
  };

  handleNetwork() {
    const { networkState } = this.props.NetworkReducer;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  validateMultiSelection = (_rule, value, callback) => {
    const { cardTypeName } = this.state;
    try {
      if (
        cardTypeName === cardTypeMapper['Time series plot'] ||
        cardTypeName === cardTypeMapper['Equipment Run Plot'] ||
        cardTypeName === cardTypeMapper['Multiple X-Y Plot'] ||
        cardTypeName === cardTypeMapper['Alert Plot']
      ) {
        if (isMaxExceeded(value, 10)) {
          callback('Maximum 10 values are allowed');
        } else {
          callback();
        }
      } else {
        if (isMaxExceeded(value, 5)) {
          callback('Maximum 5 values are allowed');
        } else {
          callback();
        }
      }
    } catch (err) {
      callback();
    }
  };

  onEditCard = (cardData) => {
    this.onCardTypeChange(cardData.cardType._id, cardData.type);
    const cardTypeName = cardData.cardType.cardType;
    const equipmentId = cardData.equipment ? cardData.equipment._id : '';
    const equipmentName = cardData.equipment ? cardData.equipment.name : '';
    const componentId = cardData.component ? cardData.component._id : '';
    const componentName = cardData.component ? cardData.component.name : '';
    this.setState({ deployedEquipmentId: equipmentId });
    this.setState({ deployedEquipmentValue: equipmentName });
    this.setState({ cardTypeName: cardTypeName });
    this.setState({ cardTypeId: cardData.cardType._id });
    this.setState({ cardTitleValue: cardData.cardTitle });
    const { getDeployedComponent } = this.props;
    switch (cardTypeName) {
      case cardTypeMapper['X-Y Plot']:
        cardData.axis.x.forEach((xAxisValue) => {
          this.onXAxisChange(xAxisValue.value);
        });
        cardData.axis.y.forEach((yAxisValue) => {
          this.onYAxisChange(yAxisValue.value);
        });
        const yaxisXY = cardData.axis.y.map((yAxisValue) => {
          return yAxisValue.variableName + ` (${yAxisValue.value})`;
        });
        const xaxisXY = cardData.axis.x.map((xAxisValue) => {
          return xAxisValue.variableName + ` (${xAxisValue.value})`;
        });
        this.setState({ yname: yaxisXY });
        this.setState({ xname: xaxisXY });
        break;
      case cardTypeMapper['X-Y Plot with baseline curve']:
        this.setState({ isXYBaseline: true });
        this.onDeployedComponentChange(componentId);
        this.handleNetwork() && getDeployedComponent(equipmentId);
        cardData.axis.x.forEach((xAxisValue) => {
          this.onXAxisChange(xAxisValue.value);
        });
        cardData.axis.y.forEach((yAxisValue) => {
          this.onYAxisChange(yAxisValue.value);
        });
        const yaxisXYBl = cardData.axis.y.map((yAxisValue) => {
          return yAxisValue.variableName + ` (${yAxisValue.value})`;
        });
        const xaxisXYBl = cardData.axis.x.map((xAxisValue) => {
          return xAxisValue.variableName + ` (${xAxisValue.value})`;
        });
        const xAxisBaselineTag = cardData.axis.xAxisBaselineTag || '';
        const yAxisBaselineTag = cardData.axis.yAxisBaselineTag || '';
        this.onBaselineXAxisChange(xAxisBaselineTag);
        this.onBaselineYAxisChange(yAxisBaselineTag);
        this.setState({ yname: yaxisXYBl });
        this.setState({ xname: xaxisXYBl });
        this.setState({ deployedComponentId: componentId });
        this.setState({ deployedComponentValue: componentName });
        let curveType = cardData.axis?.curveType || [];
        curveType = curveType.map((item) => item.name);
        this.setState({ recipCurveArray: curveType });
        break;
      case cardTypeMapper['Time series plot']:
      case cardTypeMapper['Alert Plot']:
        let yaxisTS = [],
          y = [];
        cardData.axis.y.forEach((yAxisValue) => {
          yaxisTS.push(yAxisValue.variableName + ` (${yAxisValue.value})`);
          y.push(yAxisValue.value);
        });
        this.setState({ yname: yaxisTS });
        this.setState({ yAxisTagId: y });
        this.setState({ axisInfo: cardData.axis.y });
        break;
      case cardTypeMapper['Multiple X-Y Plot']:
        let yaxisM = [],
          xaxisM = [],
          yM = [],
          xM = [],
          seriesTitle = [],
          len = 0;
        cardData.axis.series.forEach((item) => {
          yaxisM.push(item.y.variableName + ` (${item.y.value})`);
          xaxisM.push(item.x.variableName + ` (${item.x.value})`);
          yM.push(item.x.value);
          xM.push(item.y.value);
          seriesTitle.push(item.seriesTitle || '');
          len++;
        });
        this.setState({
          yname: yaxisM,
          xname: xaxisM,
          seriesLen: len,
          xAxisTagId: xM,
          yAxisTagId: yM,
          seriesTitleList: seriesTitle,
        });
        break;
      case cardTypeMapper['Variable card']:
      case cardTypeMapper['Standard deviation card']:
        const variableTag =
          cardData.cardValue.variableName + ` (${cardData.cardValue.value})`;
        const panelType = cardData.panelType ? cardData.panelType.type : '';
        this.setState({
          yAxisTagId: cardData.cardValue.value,
          variableTagValue: variableTag,
          selectedPanel: panelType,
        });
        break;
      case cardTypeMapper['Equipment Run Plot']:
        const { getRunTags } = this.props;
        this.handleNetwork() && getRunTags(equipmentId);
        let yaxisERP = [],
          yERP = [];
        cardData.axis.y.forEach((yAxisValue) => {
          yaxisERP.push(yAxisValue.variableName + ` (${yAxisValue.value})`);
          yERP.push(yAxisValue.value);
        });
        this.setState({ yname: yaxisERP });
        this.setState({ yAxisTagId: yERP });
        break;
      default:
        break;
    }
  };

  onBaselineXAxisChange = (xAxisBaselineTag) => {
    this.setState({ xAxisBaselineTag });
  };

  onBaselineYAxisChange = (yAxisBaselineTag) => {
    this.setState({ yAxisBaselineTag });
  };

  renderAddModal() {
    const { addModalVisible } = this.state;
    return (
      <div className="EditCurrentNode">
        <Modal
          title={addNewCardMessages.ADD_PANEL_TYPE}
          visible={addModalVisible}
          onOk={this.saveAddModalValue}
          onCancel={this.closeAddModal}
          okText="Save"
          cancelText="Cancel"
        >
          <Form>
            <Form.Item>
              {addModalVisible &&
                this.props.form.getFieldDecorator('name', {
                  rules: [
                    { required: true, message: ERROR_MESSAGES.REQUIRED_FIELD },
                    {
                      pattern: Pattern.Name.regx,
                      message: Pattern.Name.message,
                    },
                  ],
                })(
                  <Input
                    onChange={(e) => {
                      this.setAddNewValue(e.target.value);
                    }}
                    maxLength={70}
                  />
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  setAddNewValue = (value) => {
    this.setState({
      panelTypeName: value,
    });
  };

  saveAddModalValue = () => {
    const { panelTypeName, levelId, levelName } = this.state;
    const { savePanelTypes } = this.props;
    this.handleNetwork() &&
      savePanelTypes({
        type: panelTypeName,
        level: {
          name: levelName,
          id: levelId,
        },
      });
  };

  closeAddModal = () => {
    this.setState({
      addModalVisible: false,
    });
  };

  openAddModal = () => {
    this.setState({
      addModalVisible: true,
    });
  };

  onPanelTypeChange = (selectedPanel) => {
    this.setState({
      selectedPanel,
    });
  };

  assignPanelId = () => {
    const { selectedPanel } = this.state;
    const { data } = this.props.PanelTypeReducer;
    return data.find((item) => item.name === selectedPanel).id;
  };

  trendsCardTypeChange = (cardType) => {
    if (cardType) {
      this.setState({ trendsCardType: cardType });
    }
  };

  createSeries = () => {
    if (this.props.mode === 'edit') {
      const { seriesLen } = this.state;
      this.setState({
        seriesLen: seriesLen + 1,
      });
    } else {
      const {
        xAxisTagId,
        yAxisTagId,
        series,
        seriesLen,
        seriesTitle,
      } = this.state;
      const tempSeries = {
        x: xAxisTagId,
        y: yAxisTagId,
        seriesTitle,
      };
      const tempLen = seriesLen + 1;
      this.setState({
        series: [...series, tempSeries],
        seriesLen: tempLen,
        seriesTitle: '',
      });
      this.props.form.resetFields(['xAxis', 'yAxis']);
    }
  };

  changeTrendsTitle = (value) => {
    this.setState({ trendsCardTitle: value.target.value });
  };

  changeSeriesTitle = (value) => {
    this.setState({ seriesTitle: value.target.value });
  };

  changeCurveTypes = (value) => {
    this.setState({ recipCurveArray: value });
  };

  changeScrewCurveType = (value) =>{
    this.setState({ screwCurveType: value });
  }

  render() {
    const {
      equipmentList,
      cardTypes,
      deployedComponentList,
      deployedEquipmentList,
      tags,
      cardTypeId,
      cardTypeName,
      yname,
      xname,
      cardTitleValue,
      variableTagValue,
      isVisibleToClientValue,
      deployedEquipmentValue,
      deployedComponentValue,
      componentType,
      isXYBaseline,
      deployedComponentId,
      deployedEquipmentId,
      levelName,
      panelTypes,
      selectedPanel,
      trendsCardType,
      yAxisTagId,
      xAxisTagId,
      seriesLen,
      seriesTitleList,
      recipCurveArray
    } = this.state;
    // console.log(this.props)
    const {
      UpdateKpiByIdReducer,
      AddNewCardReducer,
      RunTagsReducer: { getRunTags },
      mode,
      cardData,
      activeKey,
      visible,
      handleCancel,
      PanelTypeReducer,
    } = this.props;
    const { data } = PanelTypeReducer;
    const title = mode === 'edit' ? 'Edit Card' : 'Add New Card';
    return (
      <div>
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancel}
          onOk={this.handleOk}
          maskClosable={false}
          closable={false}
          footer={[
            <Button
              type="primary"
              onClick={this.createSeries}
              hidden={
                trendsCardType === 'Multiple X-Y Plot' &&
                xAxisTagId !== '' &&
                yAxisTagId !== ''
                  ? false
                  : true
              }
            >
              ADD MORE
            </Button>,
            <Button type="primary" onClick={this.handleOk}>
              OK
            </Button>,
            <Button type="primary" onClick={handleCancel}>
              CANCEL
            </Button>,
          ]}
        >
          <div className="addNewCard">
            {UpdateKpiByIdReducer.isLoading ||
              AddNewCardReducer.isLoading ||
              (getRunTags.isLoading && <Loader />)}
            {cardTypeId &&
            mode === 'edit' &&
            activeKey !== '6' &&
            activeKey !== '7' ? (
              deployedComponentList === [] && isXYBaseline ? (
                <Loader />
              ) : (
                <FormFields
                  formFields={editCard(cardTypeName, {
                    data: {
                      equipmentList,
                      cardTypes,
                      deployedComponentList,
                      deployedEquipmentList,
                      tags,
                      cardTypeId,
                      cardTypeName,
                      yname,
                      xname,
                      cardTitleValue,
                      variableTagValue,
                      isVisibleToClientValue,
                      deployedEquipmentValue,
                      deployedComponentValue,
                      deployedComponentId,
                      cardData,
                      data,
                      selectedPanel,
                    },
                    onXAxisChange: this.onXAxisChange,
                    onYAxisChange: this.onYAxisChange,
                    validateMultiSelection: this.validateMultiSelection,
                    mode: mode,
                    levelName: levelName,
                    deployedComponentId: deployedComponentId,
                    deployedEquipmentId: deployedEquipmentId,
                    cardTypeId: cardTypeId,
                    onBaselineXAxisChange: this.onBaselineXAxisChange,
                    onBaselineYAxisChange: this.onBaselineYAxisChange,
                    openAddModal: this.openAddModal,
                    onPanelTypeChange: this.onPanelTypeChange,
                    curveType: recipCurveArray,
                    changeCurveTypes: this.changeCurveTypes,
                    changeScrewCurveType: this.changeScrewCurveType,
                  })}
                  form={this.props.form}
                />
              )
            ) : null}
            {activeKey === '6' || activeKey === '7' ? (
              trendsCardType === 'Time series plot' && mode !== 'edit' ? (
                <div className="trends">
                  <FormFields
                    formFields={formFieldsTrendsValue({
                      data: { tags, cardTitleValue, yname },
                      mode: mode,
                      onYAxisChange: this.onYAxisChange,
                      onXAxisChange: this.onXAxisChange,
                      validateMultiSelection: this.validateMultiSelection,
                      onCardTypeChange: this.trendsCardTypeChange,
                      cardType: trendsCardType,
                      length: seriesLen,
                      changeTrendsTitle: this.changeTrendsTitle,
                    })}
                    form={this.props.form}
                  />
                </div>
              ) : (
                <div className="trends">
                  <FormFields
                    formFields={trendsForm({
                      data: {
                        tags,
                        cardTitleValue,
                        yname,
                        xname,
                        trendsCardType,
                        seriesTitleList,
                      },
                      mode: mode,
                      onYAxisChange: this.onYAxisChange,
                      onXAxisChange: this.onXAxisChange,
                      validateMultiSelection: this.validateMultiSelection,
                      onCardTypeChange: this.trendsCardTypeChange,
                      cardType: trendsCardType,
                      length: seriesLen,
                      changeSeriesTitle: this.changeSeriesTitle,
                    })}
                    form={this.props.form}
                  />
                </div>
              )
            ) : mode !== 'edit' ? (
              activeKey !== '2' ? (
                <FormFields
                  formFields={formFieldsValueData({
                    data: {
                      equipmentList,
                      cardTypes,
                      deployedComponentList,
                      deployedEquipmentList,
                      tags,
                    },
                    onCardTypeChange: this.onCardTypeChange,
                    mode: mode,
                  })}
                  form={this.props.form}
                />
              ) : (
                <FormFields
                  formFields={customAlertPlot({
                    data: {
                      tags,
                    },
                    mode: mode,
                    onYAxisChange: this.onYAxisChange,
                    validateMultiSelection: this.validateMultiSelection,
                  })}
                  form={this.props.form}
                />
              )
            ) : activeKey === '2' ? (
              <FormFields
                formFields={customAlertPlot({
                  data: {
                    tags,
                    yname,
                    cardTitleValue,
                  },
                  mode: mode,
                  onYAxisChange: this.onYAxisChange,
                  validateMultiSelection: this.validateMultiSelection,
                })}
                form={this.props.form}
              />
            ) : null}
            {cardTypeId &&
            mode !== 'edit' &&
            activeKey !== '6' &&
            activeKey !== '7' ? (
              <FormFields
                formFields={getCardTypeForm(
                  cardTypes.find((item) => item._id === cardTypeId).name,
                  {
                    data: {
                      equipmentList,
                      cardTypes,
                      deployedComponentList,
                      deployedEquipmentList,
                      tags,
                      panelTypes,
                      selectedPanel,
                    },
                    onCardTypeChange: this.onCardTypeChange,
                    onDeployedEquipmentChange: this.onDeployedEquipmentChange,
                    onDeployedComponentChange: this.onDeployedComponentChange,
                    onXAxisChange: this.onXAxisChange,
                    onYAxisChange: this.onYAxisChange,
                    validateMultiSelection: this.validateMultiSelection,
                    mode: mode,
                    levelName: levelName,
                    deployedComponentId: deployedComponentId,
                    deployedEquipmentId: deployedEquipmentId,
                    componentType: componentType,
                    cardTypeId: cardTypeId,
                    onBaselineXAxisChange: this.onBaselineXAxisChange,
                    onBaselineYAxisChange: this.onBaselineYAxisChange,
                    openAddModal: this.openAddModal,
                    onPanelTypeChange: this.onPanelTypeChange,
                    changeCurveTypes: this.changeCurveTypes,
                    changeScrewCurveType: this.changeScrewCurveType,
                  }
                )}
                form={this.props.form}
              />
            ) : null}
          </div>
        </Modal>
        {this.renderAddModal()}
      </div>
    );
  }
}

const mapStateToProps = ({
  AddNewCard,
  trendsReducer,
  RunTagsReducer,
  CardTypesReducer,
  DeployedComponentReducer,
  DeployedEquipmentReducer,
  NetworkReducer,
  TagsByComponentReducer,
  TagsByCardTypeReducer,
  AddNewCardReducer,
  UpdateKpiByIdReducer,
  PanelTypeReducer,
  AlertPlotKPIReducer,
}) => ({
  AddNewCard,
  trendsReducer,
  RunTagsReducer,
  CardTypesReducer,
  DeployedComponentReducer,
  DeployedEquipmentReducer,
  TagsByComponentReducer,
  AddNewCardReducer,
  TagsByCardTypeReducer,
  NetworkReducer,
  UpdateKpiByIdReducer,
  PanelTypeReducer,
  AlertPlotKPIReducer,
});

const mapDispatchToProps = {
  getRunTags,
  addNewCard,
  clearAddNewCardState,
  getCardTypes,
  clearCardState,
  getDeployedComponent,
  clearDeployedComponent,
  getDeployedEquipment,
  clearDeloyedEquipment,
  getTagsByComponent,
  clearTagsByComponent,
  getTagsByCardType,
  clearTagsByCardType,
  onUpdateKpi,
  clearUpdataKpiState,
  getTagsforTrends,
  addNewTrendCard,
  getPanelTypes,
  clearPanelTypeState,
  savePanelTypes,
  addAlertPlot,
  onUpdateAlertKpi,
};
AddNewCard.propTypes = {
  getRunTags: PropTypes.func.isRequired,
  addNewCard: PropTypes.func.isRequired,
  clearAddNewCardState: PropTypes.func.isRequired,
  getCardTypes: PropTypes.func.isRequired,
  clearCardState: PropTypes.func.isRequired,
  getDeployedComponent: PropTypes.func.isRequired,
  clearDeployedComponent: PropTypes.func.isRequired,
  getDeployedEquipment: PropTypes.func.isRequired,
  clearDeloyedEquipment: PropTypes.func.isRequired,
  getTagsByComponent: PropTypes.func.isRequired,
  clearTagsByComponent: PropTypes.func.isRequired,
  getTagsByCardType: PropTypes.func.isRequired,
  clearTagsByCardType: PropTypes.func.isRequired,
  clearUpdataKpiState: PropTypes.func.isRequired,
  getTagsforTrends: PropTypes.func.isRequired,
  addNewTrendCard: PropTypes.func.isRequired,
  getPanelTypes: PropTypes.func.isRequired,
  clearPanelTypeState: PropTypes.func.isRequired,
  savePanelTypes: PropTypes.func.isRequired,
  addAlertPlot: PropTypes.func.isRequired,
  onUpdateAlertKpi: PropTypes.func.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'AddCardForm' })(AddNewCard));
