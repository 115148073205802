import {
  CURVE_PROGRESS,
  CURVE_ADDED,
  CURVE_DELETED,
  CURVE_ERROR,
  CURVE_DETECT_CHANGE,
  CLEAR_CURVES_STATE
} from "../Types/curve.types";

const INITIAL_API_STATE = {
  data: null,
  isAdded: false,
  isDeleted: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
  changeDetected: false
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function CurveReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CURVE_PROGRESS:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        isError: false,
        isAdded: false,
        isDeleted: false,
      };
    case CURVE_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case CURVE_ADDED:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: action.payload,
          isAdded: true,
          isDeleted: false,
          isSuccess: true,
          isError: false,
        },
      };
    case CURVE_DELETED:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: action.payload,
          isAdded: false,
          isDeleted: true,
          isError: false,
          isSuccess: true,
        },
      };
      case CURVE_DETECT_CHANGE:
        console.log('chnage detected', action.payload)
        return {
          ...state,
          apiState: {
            ...state.apiState,
            changeDetected: action.payload
          },
        };
      case CLEAR_CURVES_STATE:
        return {
          ...state,
          apiState: {
            ...state.apiState,
            isAdded: false,
            isSuccess: false
          },
        };
    default:
      return state;
  }
}
