import React, { useEffect, useState, useCallback } from 'react';
import './torqueCurves.scss';
import { connect } from 'react-redux';
import { Select, Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ConfirmModal from '../../../../../../../common/Modal/Modal';
import GearboxCurve from '../utils/GearboxCurve';
import { saveHVSGCurvesData } from '../../../../../../../redux/actions/hydraulicVariableSpeedGear.action';
import { curvesMapper, curveTypes } from '../utils/curveConstants';
import {
  HydraulicGearboxCurveFormField,
  curvesTableColumns,
  curvesTableAddRow,
  DataColumnsTitle,
  curveTitle,
  curveTitleKey,
  defaultIsErrorValues,
  identifierKey,
  DataColumKeyChange,
  isCurveTypeSelectionEnabled,
  defaultIdentifierData,
} from './curveConstants';
import {
  DISCARD_CHANGES,
  CurvesMessages,
} from '../../../../../../../constants/messages';
const { TabPane } = Tabs;
const { Option } = Select;

const HydraulicVariableSpeedGearTorqueCurves = (props) => {
  const { isSchematicEditable, HVSGCurvesData } = props;

  const [editTableRows, setEditableRows] = useState([]);
  const [variableCurves, setVariableCurves] = useState([
    { data: [], identifier: {}, curveId: 'curve1' },
  ]);
  const [tempVariableCurves, setTempVariableCurves] = useState([
    { data: [], identifier: {}, curveId: 'curve1' },
  ]);
  const [initcurveType, setInitCurveType] = useState(curveTypes[0]);
  const [activeKey, setActiveKey] = useState('curve1');
  const [tempCurveType, setTempCurveType] = useState('');
  const [curveType, setCurveType] = useState(curveTypes[0]);
  const [editState, setEditState] = useState(false);
  const [saveWarning, setSaveWarning] = useState(false);
  const [visible, setVisible] = useState(false);

  const [isCurveTypeChanged, setIsCurveTypeChanged] = useState(false);

  const setData = useCallback(() => {
    if (HVSGCurvesData.isSuccess) {
      let tempCurveData =
        HVSGCurvesData.curvesData && HVSGCurvesData.curvesData[curveTitleKey];

      if (tempCurveData && tempCurveData.curveType) {
        setCurveType(tempCurveData.curveType);
        setInitCurveType(tempCurveData.curveType);
      }
      if (tempCurveData && tempCurveData.curvesData) {
        setVariableCurves(tempCurveData.curvesData);
        setTempVariableCurves(tempCurveData.curvesData);
      }
    }
  }, [HVSGCurvesData]);

  useEffect(() => {
    setData();
  }, [setData]);

  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  const onEdit = (row) => {
    let editTable = editTableRows;
    editTable.push(row);
    editTable.sort();
    setEditableRows(editTable);
    enableEditForm();
  };

  const onDataEdit = (state) => {
    setEditState(state);
  };
  const onChange = (activeKey) => {
    if (editState) {
      setSaveWarning(true);
    } else {
      setActiveKey(activeKey);
    }
  };

  const onAddCurve = () => {
    let newCurveId = 'curve' + (variableCurves.length + 1);
    let tempCurveDataPoint = {
      data: [],
      identifier: {},
      curveId: newCurveId,
    };

    let tempData = variableCurves;
    tempData.push(tempCurveDataPoint);
    setVariableCurves(tempData);
    setActiveKey(newCurveId);
  };

  const updateCurveTypeData = (curvesData) => {
    setTempVariableCurves(curvesData);
    setInitCurveType(curveType);
  };

  const resetCurveTypeData = () => {
    setVariableCurves(tempVariableCurves);
    setCurveType(initcurveType);
    setIsCurveTypeChanged(false);
  };

  const updateCurveDataOnDeletion = (curveId) => {
    let tempData = variableCurves.filter(
      (curveDataItem) => curveDataItem.curveId !== curveId
    );

    if (
      tempData[tempData.length - 1] &&
      tempData[tempData.length - 1].curveId
    ) {
      const tempActiveKey = tempData[tempData.length - 1].curveId;
      setActiveKey(tempActiveKey);
    }
    setVariableCurves(tempData);
    setTempVariableCurves(tempData);
  };

  const onChangeCurveType = (selectedCurveType) => {
    if (selectedCurveType === 'Single') {
      setTempCurveType(selectedCurveType);
      setVisible(true);
    } else {
      setCurveType(selectedCurveType);
      setIsCurveTypeChanged(true);
    }
  };

  const handleOk = () => {
    setCurveType(tempCurveType);
    setIsCurveTypeChanged(true);
    setVisible(false);
    if (tempCurveType === 'Single') {
      let tempData = variableCurves.filter(
        (curveDataItem) => curveDataItem.curveId === 'curve1'
      );
      setActiveKey('curve1');
      setVariableCurves(tempData);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={CurvesMessages.SELECT_MSG}
        />
      );
    }
    return null;
  };
  // Render component
  return (
    <div className="TorqueCurves">
      <div className="CurvesHeader">
        <div className="application-process">
          <div className="app">
            <label>Curve Type</label>
            <Select
              value={curveType}
              onChange={onChangeCurveType}
              disabled={isCurveTypeSelectionEnabled}
            >
              {curveTypes.map((curveType) => (
                <Option key={curveType} value={curveType}>
                  {curveType}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={(event) => onChange(event)}
        onEdit={onEdit}
      >
        {variableCurves.map((curveItem) => (
          <TabPane
            tab={curvesMapper[curveItem.curveId]}
            key={curveItem.curveId}
          >
            <GearboxCurve
              curveData={_.cloneDeep(curveItem.data)}
              curveName={curveTitle}
              curveTitleKey={curveTitleKey}
              curveId={curveItem.curveId}
              curveType={curveType}
              isSchematicEditable={isSchematicEditable}
              identifierData={
                curveItem.identifier && !_.isEmpty(curveItem.identifier)
                  ? curveItem.identifier
                  : defaultIdentifierData
              }
              variableCurves={variableCurves}
              resetCurveTypeData={resetCurveTypeData}
              updateCurveTypeData={updateCurveTypeData}
              activeKey={activeKey}
              lastCurve={
                variableCurves.slice(-1)[0]
                  ? variableCurves.slice(-1)[0].curveId
                  : ''
              }
              editState={editState}
              saveWarning={saveWarning}
              setSaveWarning={setSaveWarning}
              HydraulicGearboxCurveFormField={HydraulicGearboxCurveFormField}
              curvesTableColumns={curvesTableColumns}
              curvesTableAddRow={curvesTableAddRow}
              DataColumnsTitle={DataColumnsTitle}
              defaultIsErrorValues={defaultIsErrorValues}
              identifierKey={identifierKey}
              DataColumKeyChange={DataColumKeyChange}
              updateCurveDataOnDeletion={updateCurveDataOnDeletion}
              onAddCurve={onAddCurve}
              onDataEdit={onDataEdit}
              isCurveTypeChanged={isCurveTypeChanged}
              setIsCurveTypeChanged={setIsCurveTypeChanged}
            />
          </TabPane>
        ))}
      </Tabs>
      {renderModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    HVSGCurvesData:
      state.HydraulicVariableSpeedGearComponentReducer.HVSGCurvesData,
    dataUnitFetchSuccess: state.DataUnitReducer.apiState.isSuccessDataUnitFetch,
    inUnitData: state.DataUnitReducer.apiState.unitData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveHVSGCurvesData: (componentId, payload) =>
    dispatch(saveHVSGCurvesData(componentId, payload)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HydraulicVariableSpeedGearTorqueCurves)
);
