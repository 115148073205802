import { Icon, Input, Popover } from 'antd';
import _ from 'lodash';
import React from 'react';
import { DesignDataVariables } from '../../../../../../constants/variables';
import { validateNumber } from '../../../../../../libs/validator';

export const designDataVariables = {
  frequency: 'Frequency',
  ratedPower: 'Rated Power',
  ratedCurrent: 'Rated Current',
  ratedVoltage: 'Rated Voltage',
  dampingFactor: 'Damping Factor',
  fieldI2RLosses: 'Field I2R Losses',
  strayLoadLosses: 'Stray Load Losses',
  inertiaConstant: 'Inertia Constant',
  ratedPowerFactor: 'Rated Power Factor',
  ratedTemperature: 'Rated Temperature',
  synchronousSpeed: 'Synchronous Speed',
  armatureI2RLosses: 'Armature I2R losses',
  excitorStatorResistance: 'Excitor Stator Resistance',
  generatorRotorResistance: 'Generator Rotor Resistance',
  generatorStatorResistance: 'Generator Stator Resistance',
  generatorAccelarationTime: 'Generator Accelaration Time',
  coreLossesAtNominalRating: 'Core Losses At Nominal Rating',
  shortCircuitCurrent3PhaseRMS: 'Short Circuit Current (3 Phase RMS)',
  shortCircuitCurrent2PhasePeak: 'Short Circuit Current (2 Phase Peak)',
  shortCircuitCurrent3PhasePeak: 'Short Circuit Current (3 Phase Peak)',
  shortCircuitCurrent3SteadyState: 'Short Circuit Current (Steady state)',
  frictionWindageLossesAtNominalRating:
    'Friction and windage losses at nominal rating',
  statorCurrentThresholdForRunningStatus:
    'Stator current Threshold For Running Status',
  generatorPowerThresholdForRunningStatus:
    'Generator power Threshold For Running Status',
  generatorSpeedThresholdForRunningStatus:
    'Generator speed Threshold For Running Status',
  // modelNumber: 'Model Number',
  frameSize: 'Frame Size/Designation',
  numberOfPhases: 'No. of Phases',
  // manufacturer: 'Manufacturer',
  statorInsulationClass: 'Stator Insulation Class',
  rotorInsulationClass: 'Rotor Insulation Class',
  statorTemperatureRise: 'Stator Temperature Rise',
  rotorTemperatureRise: 'Rotor Temperature Rise',
  exciterTemperatureRise: 'Exciter Temperature Rise',
  permanentMagnetGenerator: 'Permanent Magnet Generator',
  nemaEnclosureType: 'NEMA Enclosure Type',
  dutyType: 'Duty Type',
  enclosureIPCode: 'Enclosure IP Code (IEC 60034-5)',
  excitationType: 'Type of excitation',
  exciterType: 'Exciter Type',
  icCode: 'IC Code (IEC 60034-6)',
  imCode: 'IM Code (IEC 60034-7)',
  speedType: 'Speed Type',
  speedSignalRatio: 'Speed Signal Ratio',
  bearingType: 'Bearing Type',
  bearingQuantity: 'Bearing Quantity',
  rotorConstruction: 'Rotor Construction',
};

const content = (value) => {
  if (value !== 'Select') {
    return <span>{hoverText[value]}</span>;
  }
};

export const DesignDataShow = (config) => [
  {
    title: 'Data Variables',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === designDataVariables.ratedPower ||
        value === designDataVariables.ratedVoltage ||
        value === designDataVariables.statorCurrentThresholdForRunningStatus ||
        value === designDataVariables.generatorPowerThresholdForRunningStatus ||
        value === designDataVariables.speedType ||
        value === designDataVariables.speedSignalRatio ||
        value === designDataVariables.synchronousSpeed ||
        value === designDataVariables.generatorSpeedThresholdForRunningStatus
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === designDataVariables.frequency ||
        item.name === designDataVariables.ratedPower ||
        item.name === designDataVariables.ratedCurrent ||
        item.name === designDataVariables.ratedVoltage ||
        item.name === designDataVariables.fieldI2RLosses ||
        item.name === designDataVariables.strayLoadLosses ||
        item.name === designDataVariables.ratedPowerFactor ||
        item.name === designDataVariables.ratedTemperature ||
        item.name === designDataVariables.synchronousSpeed ||
        item.name === designDataVariables.armatureI2RLosses ||
        item.name === designDataVariables.excitorStatorResistance ||
        item.name === designDataVariables.generatorRotorResistance ||
        item.name === designDataVariables.generatorStatorResistance ||
        item.name === designDataVariables.generatorAccelarationTime ||
        item.name === designDataVariables.coreLossesAtNominalRating ||
        item.name === designDataVariables.shortCircuitCurrent3PhaseRMS ||
        item.name === designDataVariables.shortCircuitCurrent2PhasePeak ||
        item.name === designDataVariables.shortCircuitCurrent3PhasePeak ||
        item.name === designDataVariables.shortCircuitCurrent3SteadyState ||
        item.name === designDataVariables.frictionWindageLossesAtNominalRating ||
        item.name === designDataVariables.statorCurrentThresholdForRunningStatus ||
        item.name === designDataVariables.generatorPowerThresholdForRunningStatus ||
        item.name === designDataVariables.generatorSpeedThresholdForRunningStatus
      ) {
        const tempError = !!(
          ((!item.unit || item.unit === undefined) &&
            (item.design || item.design === 0) &&
            config.saveListener)
        );
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === designDataVariables.dutyType ||
        item.name === designDataVariables.nemaEnclosureType ||
        item.name === designDataVariables.enclosureIPCode ||
        item.name === designDataVariables.imCode ||
        item.name === designDataVariables.speedType ||
        item.name === designDataVariables.rotorConstruction
      ) {
        const tempError = !!(
          item.name === DesignDataVariables.speedType &&
          !item.design &&
          config.saveListener
        );
        return (
          // <div className={`${tooltip && 'tooltip'}`}>{hoverText[item.design] && <span class="tooltiptext"> {hoverText[item.design]}</span>} {disabled && item.design ? <span className='selectedValue'>{item.design}</span> :
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''}  ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            {' '}
            {!_.isEmpty(value) && item.name === DesignDataVariables.dutyType ? (
              <Popover
                placement="left"
                trigger="hover"
                content={content(value)}
              >
                <select
                  value={!item.design ? '' : item.design}
                  disabled={disabled}
                  onChange={(e) =>
                    config.handleChange(e.target.value, item.name, 'select')
                  }
                >
                  <option value="">Select</option>
                  {data[item.name] &&
                    data[item.name].map((item) => (
                      <option value={item} title={hoverText[item]}>
                        {item}
                      </option>
                    ))}
                </select>
              </Popover>
            ) : (
              <select
                value={!item.design ? '' : item.design}
                disabled={disabled}
                onChange={(e) =>
                  config.handleChange(e.target.value, item.name, 'select')
                }
              >
                <option value="">Select</option>
                {data[item.name] &&
                  data[item.name].map((item) => (
                    <option value={item} title={hoverText[item]}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
          </div>
          // }
          // </div>
        );
      }
      if (
        item.name === designDataVariables.icCode ||
        item.name === designDataVariables.bearingType ||
        item.name === designDataVariables.drivenEquipment ||
        item.name === designDataVariables.exciterTemperatureRise ||
        item.name === designDataVariables.rotorTemperatureRise ||
        item.name === designDataVariables.statorTemperatureRise ||
        item.name === designDataVariables.rotorInsulationClass ||
        item.name === designDataVariables.statorInsulationClass ||
        item.name === designDataVariables.excitationType ||
        item.name === designDataVariables.exciterType ||
        item.name === designDataVariables.permanentMagnetGenerator
      ) {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validateNumber(e, value);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={
              (item.name === designDataVariables.ratedPower &&
                !item.design &&
                config.saveListener) ||
              (item.name ===
                designDataVariables.statorCurrentThresholdForRunningStatus &&
                !item.design &&
                config.saveListener) ||
              (item.name ===
                designDataVariables.generatorPowerThresholdForRunningStatus &&
                !item.design &&
                config.saveListener) ||
              (item.name ===
                designDataVariables.generatorSpeedThresholdForRunningStatus &&
                !item.design &&
                config.saveListener) ||
              (item.name === designDataVariables.speedSignalRatio &&
                !item.design &&
                config.saveListener) ||
              (item.name === designDataVariables.ratedVoltage &&
                !item.design &&
                config.saveListener) ||
              (item.name === designDataVariables.synchronousSpeed &&
                !item.design &&
                config.saveListener) ||
              (!item.design &&
                item.unit &&
                item.design !== 0 &&
                config.saveListener)
                ? 'error'
                : 'not-error'
            }
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const data = {
  [DesignDataVariables.speedType]: [
    'Constant Speed',
    'Varying Speed',
    'Adjustable Speed',
    'Multispeed',
  ],
  [DesignDataVariables.dutyType]: [
    'S1',
    'S2',
    'S3',
    'S4',
    'S5',
    'S6',
    'S7',
    'S8',
    'S9',
    'S10',
  ],
  [DesignDataVariables.insulationClass]: ['A', 'B', 'F', 'H'],
  [DesignDataVariables.temperatureClass]: ['A', 'B', 'F', 'H'],
  [DesignDataVariables.nemaEnclosureType]: [
    'Dripproof Machine (ODP)',
    'Splash-Proof Machine',
    'Semi-Guarded Machine',
    'Guarded Machine',
    'Dripproof Guarded Machine (DPG)',
    'Open Independently Ventilated Machine (OIV)',
    'Open Pipe-Ventilated Machine',
    'Weather Protected Type I (WP I)',
    'Weather Protected Type II (WP II)',
    'Totally Enclosed Nonventilated Machine (TENV)',
    'Totally Enclosed Fan-Cooled Machine (TEFC)',
    'Totally Enclosed Fan-Cooled Guarded Machine',
    'Totally Enclosed Pipe-Ventilated Machine (TEPV)',
    'Totally Enclosed Water-Cooled Machine (TEWC)',
    'Water-Proof Machine',
    'Totally Enclosed Air-to-Water-Cooled Machine (TEWAC)',
    'Totally Enclosed Air-to-Air-Cooled Machine (TEAAC)',
    'Totally Enclosed Air-Over Machine (TEAO)',
    'Explosion-Proof Machine (EXPL)',
    'Dust-Ignition-Proof Machine',
  ],
  [DesignDataVariables.enclosureIPCode]: [
    'IP00',
    'IP01',
    'IP02',
    'IP03',
    'IP04',
    'IP05',
    'IP06',
    'IP07',
    'IP08',
    'IP10',
    'IP11',
    'IP12',
    'IP13',
    'IP14',
    'IP15',
    'IP16',
    'IP17',
    'IP18',
    'IP20',
    'IP21',
    'IP22',
    'IP23',
    'IP24',
    'IP25',
    'IP26',
    'IP27',
    'IP28',
    'IP30',
    'IP31',
    'IP32',
    'IP33',
    'IP34',
    'IP35',
    'IP36',
    'IP37',
    'IP38',
    'IP40',
    'IP41',
    'IP42',
    'IP43',
    'IP44',
    'IP45',
    'IP46',
    'IP47',
    'IP48',
    'IP50',
    'IP51',
    'IP52',
    'IP53',
    'IP54',
    'IP55',
    'IP56',
    'IP57',
    'IP58',
    'IP60',
    'IP61',
    'IP62',
    'IP63',
    'IP64',
    'IP65',
    'IP66',
    'IP67',
    'IP68',
    'IP69K',
  ],
  [DesignDataVariables.imCode]: [
    'IM Bx',
    'IM B3',
    'IM B5',
    'IM B6',
    'IM B7',
    'IM B8',
    'IM B9',
    'IM B10',
    'IM B14',
    'IM B15',
    'IM B20',
    'IM B25',
    'IM B30',
    'IM B34',
    'IM B35',
    'IM Vx',
    'IM V1',
    'IM V2',
    'IM V3',
    'IM V4',
    'IM V5',
    'IM V6',
    'IM V8',
    'IM V9',
    'IM V10',
    'IM V14',
    'IM V15',
    'IM V16',
    'IM V17',
    'IM V18',
    'IM V19',
    'IM V30',
    'IM V31',
    'IM V35',
    'IM V37',
  ],
  [DesignDataVariables.rotorConstruction]: [
    'Solid Cylindrical',
    'Laminated Cylindrical',
    'Salient-Pole Solid',
    'Salient - Pole Star Punching Type, Shrunk Onto Shaft',
    'Salient - Pole Laminated / Bolted',
    'Salient - Pole Dove Tail or T-Tail Attachment to Shaft',
    'Other',
  ],
};

export const KeyChange = {
  [designDataVariables.frequency]: 'frequency',
  [designDataVariables.ratedPower]: 'ratedPower',
  [designDataVariables.ratedCurrent]: 'ratedCurrent',
  [designDataVariables.ratedVoltage]: 'ratedVoltage',
  [designDataVariables.dampingFactor]: 'dampingFactor',
  [designDataVariables.fieldI2RLosses]: 'fieldI2RLosses',
  [designDataVariables.strayLoadLosses]: 'strayLoadLosses',
  [designDataVariables.inertiaConstant]: 'inertiaConstant',
  [designDataVariables.ratedPowerFactor]: 'ratedPowerFactor',
  [designDataVariables.ratedTemperature]: 'ratedTemperature',
  [designDataVariables.synchronousSpeed]: 'synchronousSpeed',
  [designDataVariables.armatureI2RLosses]: 'armatureI2RLosses',
  [designDataVariables.excitorStatorResistance]: 'excitorStatorResistance',
  [designDataVariables.generatorRotorResistance]: 'generatorRotorResistance',
  [designDataVariables.generatorStatorResistance]: 'generatorStatorResistance',
  [designDataVariables.generatorAccelarationTime]: 'generatorAccelarationTime',
  [designDataVariables.coreLossesAtNominalRating]: 'coreLossesAtNominalRating',
  [designDataVariables.statorCurrentThresholdForRunningStatus]:
    'statorCurrentThresholdForRunningStatus',
  [designDataVariables.frictionWindageLossesAtNominalRating]:
    'frictionWindageLossesAtNominalRating',
  [designDataVariables.shortCircuitCurrent3SteadyState]:
    'shortCircuitCurrent3SteadyState',
  [designDataVariables.shortCircuitCurrent3PhasePeak]:
    'shortCircuitCurrent3PhasePeak',
  [designDataVariables.shortCircuitCurrent2PhasePeak]:
    'shortCircuitCurrent2PhasePeak',
  [designDataVariables.shortCircuitCurrent3PhaseRMS]:
    'shortCircuitCurrent3PhaseRMS',
  [designDataVariables.generatorPowerThresholdForRunningStatus]:
    'generatorPowerThresholdForRunningStatus',
  [designDataVariables.generatorSpeedThresholdForRunningStatus]:
    'generatorSpeedThresholdForRunningStatus',
  // [designDataVariables.modelNumber]: 'modelNumber',
  [designDataVariables.frameSize]: 'frameSize',
  [designDataVariables.numberOfPhases]: 'numberOfPhases',
  [designDataVariables.speedSignalRatio]: 'speedSignalRatio',
  [designDataVariables.bearingQuantity]: 'bearingQuantity',
  // [designDataVariables.manufacturer]: 'manufacturer',
  [designDataVariables.nemaEnclosureType]: 'nemaEnclosureType',
  [designDataVariables.permanentMagnetGenerator]: 'permanentMagnetGenerator',
  [designDataVariables.exciterTemperatureRise]: 'exciterTemperatureRise',
  [designDataVariables.rotorTemperatureRise]: 'rotorTemperatureRise',
  [designDataVariables.statorTemperatureRise]: 'statorTemperatureRise',
  [designDataVariables.rotorInsulationClass]: 'rotorInsulationClass',
  [designDataVariables.statorInsulationClass]: 'statorInsulationClass',
  [designDataVariables.rotorConstruction]: 'rotorConstruction',
  [designDataVariables.bearingType]: 'bearingType',
  [designDataVariables.speedType]: 'speedType',
  [designDataVariables.imCode]: 'imCode',
  [designDataVariables.icCode]: 'icCode',
  [designDataVariables.enclosureIPCode]: 'enclosureIPCode',
  [designDataVariables.dutyType]: 'dutyType',
  [designDataVariables.exciterType]: 'exciterType',
  [designDataVariables.excitationType]: 'excitationType',
};

export const hoverText = {
  S1: 'S1 (Continuous)',
  S2: 'S2 (Short - time)',
  S3: 'S3 (Intermittent periodic)',
  S4: 'S4 (Intermittent periodic with starting)',
  S5: 'S5 (Intermittent periodic with electric braking)',
  S6: 'S6 (Continuous - operation periodic)',
  S7: 'S7 (Continuous - operation periodic with electric braking)',
  S8: 'S8 (Continuous - operation periodic with related load / speed changes)',
  S9: 'S9 (Duty with non - periodic load and speed variations)',
  S10: 'S10 (Duty with discrete constant loads and speeds)',
};

export const speedTypeTitle = {
  CONSTANT: 'Constant Speed',
  'Varying Speed': 'Varying Speed',
  'Adjustable Speed': 'Adjustable Speed',
  Multispeed: 'Multispeed',
};

export const initialData = [
  {
    name: designDataVariables.ratedPower,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.frequency,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.ratedCurrent,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.ratedVoltage,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.dampingFactor,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.fieldI2RLosses,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.strayLoadLosses,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.inertiaConstant,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.ratedPowerFactor,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.ratedTemperature,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.synchronousSpeed,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.armatureI2RLosses,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.excitorStatorResistance,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.generatorRotorResistance,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.generatorStatorResistance,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.generatorAccelarationTime,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.coreLossesAtNominalRating,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.statorCurrentThresholdForRunningStatus,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.frictionWindageLossesAtNominalRating,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.shortCircuitCurrent3SteadyState,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.shortCircuitCurrent3PhasePeak,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.shortCircuitCurrent2PhasePeak,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.shortCircuitCurrent3PhaseRMS,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.generatorPowerThresholdForRunningStatus,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.generatorSpeedThresholdForRunningStatus,
    unit: null,
    design: parseFloat(''),
  },
  // {
  //   name: designDataVariables.modelNumber,
  //   design: parseFloat(''),
  // },
  {
    name: designDataVariables.frameSize,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.numberOfPhases,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.speedSignalRatio,
    design: parseFloat(''),
  },
  {
    name: designDataVariables.bearingQuantity,
    design: parseFloat(''),
  },
  // {
  //   name: designDataVariables.manufacturer,
  //   design: '',
  // },
  {
    name: designDataVariables.nemaEnclosureType,
    design: '',
  },
  {
    name: designDataVariables.permanentMagnetGenerator,
    design: '',
  },
  {
    name: designDataVariables.exciterTemperatureRise,
    design: '',
  },
  {
    name: designDataVariables.rotorTemperatureRise,
    design: '',
  },
  {
    name: designDataVariables.statorTemperatureRise,
    design: '',
  },
  {
    name: designDataVariables.rotorInsulationClass,
    design: '',
  },
  {
    name: designDataVariables.statorInsulationClass,
    design: '',
  },
  {
    name: designDataVariables.rotorConstruction,
    design: '',
  },
  {
    name: designDataVariables.bearingType,
    design: '',
  },
  {
    name: designDataVariables.speedType,
    design: '',
  },
  {
    name: designDataVariables.imCode,
    design: '',
  },
  {
    name: designDataVariables.icCode,
    design: '',
  },
  {
    name: designDataVariables.enclosureIPCode,
    design: '',
  },
  {
    name: designDataVariables.dutyType,
    design: '',
  },
  {
    name: designDataVariables.dutexciterTypeType,
    design: '',
  },
  {
    name: designDataVariables.excitationType,
    design: '',
  },
];
