import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AssetMessages } from '../../../../../constants/messages';
import { addMessage, clearState, getMessages } from "../../../../../redux/actions/discussionThread.action";
import './DiscussionThread.scss';
import MessagePanel from "./MessagePanel/MessagePanel";
import { UserRole, UserId, FirstName , LastName } from "../../../../AppScreen/AppScreen";

class DiscussionThread extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            message: [],
            send: false,
            load: false,
            total_count: 0
        }
    }

    componentDidMount = () => {
        const { alert } = this.props;
        this.props.getMessages(alert, AssetMessages.COMPONENT_PAGE_COUNT, 0);
    }

    componentDidUpdate = (prevProps) => {
        const { isSuccess, data, hasPrev, total_count } = this.props.discussionState.getMessage;
        const { addMessage } = this.props.discussionState;

        if (isSuccess && isSuccess !== prevProps.discussionState.getMessage.isSuccess) {
            this.setState({
                message: _.orderBy(this.filterMessage(data), 'timestamp', 'asc'),
                total_count: this.state.total_count + total_count,
            }, () => {
                if (hasPrev) {
                    this.setState({ load: false })
                }
            })
        }

        if (addMessage.isSuccess && addMessage.isSuccess !== prevProps.discussionState.addMessage.isSuccess) {
            this.setState({
                send: false
            })
        }

    }

    // componentWillUnmount = () => {
    //     this.props.clearState();
    // }

    filterMessage = (data) => {
        return data.map(item => {
            const { message, timestamp, isActive, user } = item;
            return { message, timestamp, isActive, user }
        })
    }

    getMessages = (data, type) => {
        if (type) {
            this.setState({ load: true }, () => { this.props.getMessages(data.alertId, data.limit, data.skip) })
        }

    }

    onChange = (e) => {
        this.setState({
            input: e.target.value
        })
    }

    buttonClick = () => {
        this.setState({
            send: true,
        })
    }

    onClick = (e) => {
        e.preventDefault();
        this.buttonClick();
        const { alert } = this.props;
        const { input } = this.state;
        let tempMessage = [...this.state.message];
        let added = { message: input, user: { role: [UserRole], firstName : FirstName, lastName : LastName}, timestamp: new Date().toISOString() }
        tempMessage.push(added);

        let payload = {};

        payload['user'] = UserId;
        payload['alert'] = alert;
        payload['message'] = input;
        this.setState({
            message: tempMessage,
            input: '',
        }, () => {
            this.props.addMessage(payload);
        })

    }

    handleKeyDown = event =>{
        console.log("event", event)
        if(event.key === "Enter"){
            if(this.state.input.trim()){
                this.onClick(event)
            }
        }
    }



    render() {
        const { message, total_count, send, load } = this.state;
        const { alert } = this.props;
        const { getMessage } = this.props.discussionState;
        const { hasNext, hasPrev } = this.props.discussionState.getMessage;
        return (
            <div className='thread'>
                <form>
                    <MessagePanel
                        message={!_.isEmpty(message) ? message : []}
                        messages={this.getMessages}
                        pagination={total_count}
                        alertId={alert}
                        hasNext={hasNext}
                        hasPrev={hasPrev}
                        send={send}
                        load={load}
                        loading={getMessage.isLoading}
                    />
                </form>
                <input onChange={this.onChange} value={this.state.input} onKeyDown={this.handleKeyDown} placeholder="Type here..." />
                <button id='add-comment' className={'btn-default'} onClick={this.state.input.trim() && this.onClick}>Comment</button>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        discussionState: state.DiscussionThreadReducer,
        loginState: state.LoginReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addMessage: payload => dispatch(addMessage(payload)),
        getMessages: (id, limit, skip) => dispatch(getMessages(id, limit, skip)),
        clearState: () => dispatch(clearState())
    }
}

DiscussionThread.propTypes = {
    alert: PropTypes.string,
    discussionState: PropTypes.object,
    addMessage: PropTypes.func,
    getMessages: PropTypes.func
};
DiscussionThread.defaultProps = {
    alert: '',
    discussionState: {},
    addMessage: () => { },
    getMessages: () => { }
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionThread);