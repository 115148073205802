export default {
  UPDATE_ST_CURVES_DATA_PROGRESS: 'UPDATE_ST_CURVES_DATA_PROGRESS',
  UPDATE_ST_CURVES_DATA_SUCCESS: 'UPDATE_ST_CURVES_DATA_SUCCESS',
  UPDATE_ST_CURVES_DATA_FAILURE: 'UPDATE_ST_CURVES_DATA_FAILURE',
  UPDATE_ST_CURVES_DATA_RESET: 'UPDATE_ST_CURVES_DATA_RESET',

  GET_ST_CURVES_DATA_PROGRESS: 'GET_ST_CURVES_DATA_PROGRESS',
  GET_ST_CURVES_DATA_SUCCESS: 'GET_ST_CURVES_DATA_SUCCESS',
  GET_ST_CURVES_DATA_FAILURE: 'GET_ST_CURVES_DATA_FAILURE',
};
