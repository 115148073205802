import { Icon, Input } from 'antd';
import React from 'react';
import { newValidateNumber } from '../../../../../../libs/validator';

export const stDesignDataVariables = {
  equipmentPressureType: 'Equipment Pressure Type',
  steamTurbineService: 'Steam Turbine Service',
  cycleType: 'Cycle type',
  steamInductionType: 'Steam induction type',
  steamExtractionType: 'Steam extraction type',
  drivenEquipmentDescription: 'Driven equipment description',
  service: 'Service',
  flowEnthalpyDropMethod: 'Flow by enthalpy drop method',
  configSpeed: 'Config Speed',
  stStartupSpeedThreshold: 'ST startup speed threshold (for run-status)',
  stStartupPowerThresold: 'ST startup power threshold (for run-status)',
  condensingService: 'Condensing service',
  glandSteamLeakage:
    'Gland steam leakage (to determine throttle steam mass flow)',
  totalSteamLeakage:
    'Total steam leakage (to determine throttle steam mass flow)',
  inletSteamPressureRated: 'Inlet steam pressure rated',
  inletSteamPressureMax: 'Inlet steam pressure max',
  inletSteamPressureMin: 'Inlet steam pressure min',
  inletSteamTemperatureRated: 'Inlet steam temperature rated',
  inletSteamTemperatureMax: 'Inlet steam temperature max',
  inletSteamTemperatureMin: 'Inlet steam temperature min',
  rotationalSpeed: 'Rotational speed',
  outputSpeedRangeMin: 'ST Output Speed Range: Minimum',
  outputSpeedRangeMax: 'ST Output Speed Range: Maximum',
  exhaustSteamPressureRated: 'Exhaust steam pressure rated',
  exhaustSteamPressureMax: 'Exhaust steam pressure max',
  exhaustSteamPressureMin: 'Exhaust steam pressure min',
  exhaustSteamTemperatureRated: 'Exhaust steam temperature rated',
  exhaustSteamTemperatureMax: 'Exhaust steam temperature max',
  exhaustSteamTemperatureMin: 'Exhaust steam temperature min',
  throttleSteamFlowRated: 'Throttle steam flow rated',
  throttleSteamFlowMax: 'Throttle steam flow max',
  throttleSteamFlowMin: 'Throttle steam flow min',
  exhaustSteamFlowRated: 'Exhaust steam flow rated',
  exhaustSteamFlowMax: 'Exhaust steam flow max',
  exhaustSteamFlowMin: 'Exhaust steam flow min',
  inductionSteamPressureRated: 'Induction steam pressure rated',
  inductionSteamPressureMax: 'Induction steam pressure max',
  inductionSteamPressureMin: 'Induction steam pressure min',
  inductionSteamTemperatureRated: 'Induction steam temperature rated',
  inductionSteamTemperatureMax: 'Induction steam temperature max',
  inductionSteamTemperatureMin: 'Induction steam temperature min',
  inductionSteamFlowRated: 'Induction steam flow rated',
  inductionSteamFlowMax: 'Induction steam flow max',
  inductionSteamFlowMin: 'Induction steam flow min',
  extractionSteamPressureRated: 'Extraction steam pressure rated',
  extractionSteamPressureMax: 'Extraction steam pressure max',
  extractionSteamPressureMin: 'Extraction steam pressure min',
  extractionSteamTemperatureRated: 'Extraction steam temperature rated',
  extractionSteamTemperatureMax: 'Extraction steam temperature max',
  extractionSteamTemperatureMin: 'Extraction steam temperature min',
  extractionSteamFlowRated: 'Extraction steam flow rated',
  extractionSteamFlowMax: 'Extraction steam flow max',
  extractionSteamFlowMin: 'Extraction steam flow min',
  stShaftType: 'Steam turbine shaft type',
  numberOfStages: 'Number of stages',
  maxTipSpeed: 'Blades/buckets maximum tip speed',
  finalStageRatedBladeLength: 'Final stage rated blade length',
  finalStageMaxBladeLength: 'Final stage maximum blade length',
  radialBearingType: 'Radial Bearing Type',
  radialBearingManufacturer: 'Radial Bearing Manufacturer',
  thrustBearingType: 'Thrust Bearing Type',
  thrustBearingManufacturer: 'Thrust Bearing Manufacturer',
  gearBoxType: 'Gearbox Type',
  gearBoxManufacturer: 'Gearbox Manufacturer',
  gearBoxSpeedRatio: 'Gearbox Speed Ratio',
  lubricatingOilGrade: 'Lubricating Oil Grade',
};

export const initialData = [
  {
    name: stDesignDataVariables.equipmentPressureType,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.steamTurbineService,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.cycleType,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.steamInductionType,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.steamExtractionType,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.drivenEquipmentDescription,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.service,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.flowEnthalpyDropMethod,
    design: false,
  },
  {
    name: stDesignDataVariables.configSpeed,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.stStartupSpeedThreshold,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.stStartupPowerThresold,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.condensingService,
    design: false,
  },
  {
    name: stDesignDataVariables.glandSteamLeakage,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.totalSteamLeakage,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamPressureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamPressureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamPressureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamTemperatureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamTemperatureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inletSteamTemperatureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.rotationalSpeed,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.outputSpeedRangeMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.outputSpeedRangeMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamPressureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamPressureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamPressureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamTemperatureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamTemperatureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamTemperatureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.throttleSteamFlowRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.throttleSteamFlowMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.throttleSteamFlowMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamFlowRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamFlowMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.exhaustSteamFlowMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamPressureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamPressureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamPressureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamTemperatureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamTemperatureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamTemperatureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamFlowRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamFlowMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.inductionSteamFlowMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamPressureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamPressureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamPressureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamTemperatureRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamTemperatureMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamTemperatureMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamFlowRated,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamFlowMax,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.extractionSteamFlowMin,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.stShaftType,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.numberOfStages,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.maxTipSpeed,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.finalStageRatedBladeLength,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.finalStageMaxBladeLength,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: stDesignDataVariables.radialBearingType,
    design: '',
  },
  {
    name: stDesignDataVariables.radialBearingManufacturer,
    design: '',
  },
  {
    name: stDesignDataVariables.thrustBearingType,
    design: '',
  },
  {
    name: stDesignDataVariables.thrustBearingManufacturer,
    design: '',
  },
  {
    name: stDesignDataVariables.gearBoxType,
    design: '',
  },
  {
    name: stDesignDataVariables.gearBoxManufacturer,
    design: '',
  },
  {
    name: stDesignDataVariables.gearBoxSpeedRatio,
    design: '',
  },
  {
    name: stDesignDataVariables.lubricatingOilGrade,
    design: '',
  },
];

export const KeyChange = {
  [stDesignDataVariables.equipmentPressureType]: 'equipmentPressureType',

  [stDesignDataVariables.steamTurbineService]: 'steamTurbineService',

  [stDesignDataVariables.cycleType]: 'cycleType',

  [stDesignDataVariables.steamInductionType]: 'steamInductionType',

  [stDesignDataVariables.steamExtractionType]: 'steamExtractionType',

  [stDesignDataVariables.drivenEquipmentDescription]:
    'drivenEquipmentDescription',

  [stDesignDataVariables.service]: 'service',

  [stDesignDataVariables.flowEnthalpyDropMethod]: 'flowEnthalpyDropMethod',

  [stDesignDataVariables.configSpeed]: 'configSpeed',

  [stDesignDataVariables.stStartupSpeedThreshold]: 'stStartupSpeedThreshold',

  [stDesignDataVariables.stStartupPowerThresold]: 'stStartupPowerThresold',

  [stDesignDataVariables.condensingService]: 'condensingService',

  [stDesignDataVariables.glandSteamLeakage]: 'glandSteamLeakage',

  [stDesignDataVariables.totalSteamLeakage]: 'totalSteamLeakage',

  [stDesignDataVariables.inletSteamPressureRated]: 'inletSteamPressureRated',

  [stDesignDataVariables.inletSteamPressureMax]: 'inletSteamPressureMax',

  [stDesignDataVariables.inletSteamPressureMin]: 'inletSteamPressureMin',

  [stDesignDataVariables.inletSteamTemperatureRated]:
    'inletSteamTemperatureRated',

  [stDesignDataVariables.inletSteamTemperatureMax]: 'inletSteamTemperatureMax',

  [stDesignDataVariables.inletSteamTemperatureMin]: 'inletSteamTemperatureMin',

  [stDesignDataVariables.rotationalSpeed]: 'rotationalSpeed',

  [stDesignDataVariables.outputSpeedRangeMin]: 'outputSpeedRangeMin',

  [stDesignDataVariables.outputSpeedRangeMax]: 'outputSpeedRangeMax',

  [stDesignDataVariables.exhaustSteamPressureRated]:
    'exhaustSteamPressureRated',

  [stDesignDataVariables.exhaustSteamPressureMax]: 'exhaustSteamPressureMax',

  [stDesignDataVariables.exhaustSteamPressureMin]: 'exhaustSteamPressureMin',

  [stDesignDataVariables.exhaustSteamTemperatureRated]:
    'exhaustSteamTemperatureRated',

  [stDesignDataVariables.exhaustSteamTemperatureMax]:
    'exhaustSteamTemperatureMax',

  [stDesignDataVariables.exhaustSteamTemperatureMin]:
    'exhaustSteamTemperatureMin',

  [stDesignDataVariables.throttleSteamFlowRated]: 'throttleSteamFlowRated',

  [stDesignDataVariables.throttleSteamFlowMax]: 'throttleSteamFlowMax',

  [stDesignDataVariables.throttleSteamFlowMin]: 'throttleSteamFlowMin',

  [stDesignDataVariables.exhaustSteamFlowRated]: 'exhaustSteamFlowRated',

  [stDesignDataVariables.exhaustSteamFlowMax]: 'exhaustSteamFlowMax',

  [stDesignDataVariables.exhaustSteamFlowMin]: 'exhaustSteamFlowMin',

  [stDesignDataVariables.inductionSteamPressureRated]:
    'inductionSteamPressureRated',

  [stDesignDataVariables.inductionSteamPressureMax]:
    'inductionSteamPressureMax',

  [stDesignDataVariables.inductionSteamPressureMin]:
    'inductionSteamPressureMin',

  [stDesignDataVariables.inductionSteamTemperatureRated]:
    'inductionSteamTemperatureRated',

  [stDesignDataVariables.inductionSteamTemperatureMax]:
    'inductionSteamTemperatureMax',

  [stDesignDataVariables.inductionSteamTemperatureMin]:
    'inductionSteamTemperatureMin',

  [stDesignDataVariables.inductionSteamFlowRated]: 'inductionSteamFlowRated',

  [stDesignDataVariables.inductionSteamFlowMax]: 'inductionSteamFlowMax',

  [stDesignDataVariables.inductionSteamFlowMin]: 'inductionSteamFlowMin',

  [stDesignDataVariables.extractionSteamPressureRated]:
    'extractionSteamPressureRated',

  [stDesignDataVariables.extractionSteamPressureMax]:
    'extractionSteamPressureMax',

  [stDesignDataVariables.extractionSteamPressureMin]:
    'extractionSteamPressureMin',

  [stDesignDataVariables.extractionSteamTemperatureRated]:
    'extractionSteamTemperatureRated',

  [stDesignDataVariables.extractionSteamTemperatureMax]:
    'extractionSteamTemperatureMax',

  [stDesignDataVariables.extractionSteamTemperatureMin]:
    'extractionSteamTemperatureMin',

  [stDesignDataVariables.extractionSteamFlowRated]: 'extractionSteamFlowRated',

  [stDesignDataVariables.extractionSteamFlowMax]: 'extractionSteamFlowMax',

  [stDesignDataVariables.extractionSteamFlowMin]: 'extractionSteamFlowMin',

  [stDesignDataVariables.stShaftType]: 'stShaftType',

  [stDesignDataVariables.numberOfStages]: 'numberOfStages',

  [stDesignDataVariables.maxTipSpeed]: 'maxTipSpeed',

  [stDesignDataVariables.finalStageRatedBladeLength]:
    'finalStageRatedBladeLength',

  [stDesignDataVariables.finalStageMaxBladeLength]: 'finalStageMaxBladeLength',

  [stDesignDataVariables.radialBearingType]: 'radialBearingType',

  [stDesignDataVariables.radialBearingManufacturer]:
    'radialBearingManufacturer',

  [stDesignDataVariables.thrustBearingType]: 'thrustBearingType',

  [stDesignDataVariables.thrustBearingManufacturer]:
    'thrustBearingManufacturer',

  [stDesignDataVariables.gearBoxType]: 'gearBoxType',

  [stDesignDataVariables.gearBoxManufacturer]: 'gearBoxManufacturer',

  [stDesignDataVariables.gearBoxSpeedRatio]: 'gearBoxSpeedRatio',

  [stDesignDataVariables.lubricatingOilGrade]: 'lubricatingOilGrade',
};

export const data = {
  [stDesignDataVariables.equipmentPressureType]: [
    { title: 'High Pressure(HP)', value: 'highPressure' },
    { title: 'Intermediate Pressure(IP)', value: 'intermediatePressure' },
    { title: 'Low Pressure(LP)', value: 'lowPressure' },
  ],
  [stDesignDataVariables.steamTurbineService]: [
    { title: 'Extraction consdensing', value: 'extractionCondensing' },
    { title: 'Non-extraction condensing', value: 'nonExtractionCondensing' },
    {
      title: 'Non condensing (Back Pressure)',
      value: 'nonExtractionCondensing',
    },
  ],
  [stDesignDataVariables.cycleType]: [
    { value: 'rankine', title: 'Rankine' },
    { value: 'reheat', title: 'Reheat' },
    { value: 'regenerative', title: 'Regenerative' },
    { value: 'reheatRegenerative', title: 'Reheat-Regenerative' },
  ],
  [stDesignDataVariables.steamInductionType]: [
    { value: 'controlled', title: 'Controlled' },
    { value: 'uncontrolled', title: 'Uncontrolled' },
    { value: 'notApplicable', title: 'Not applicable' },
  ],
  [stDesignDataVariables.steamExtractionType]: [
    { value: 'controlled', title: 'Controlled' },
    { value: 'uncontrolled', title: 'Uncontrolled' },
    { value: 'notApplicable', title: 'Not applicable' },
  ],
  [stDesignDataVariables.service]: [
    { value: 'powerGeneration', title: 'Power generation' },
    { value: 'mechanicalDrive', title: 'Mechanical drive' },
  ],
  [stDesignDataVariables.stShaftType]: [
    { value: 'integral', title: 'Integral' },
    { value: 'builtUp', title: 'Built-Up' },
    { value: 'combination', title: 'Combination' },
  ],
  [stDesignDataVariables.flowEnthalpyDropMethod]: [
    { value: true, title: 'True' },
    { value: false, title: 'False' },
  ],
  [stDesignDataVariables.condensingService]: [
    { value: true, title: 'True' },
    { value: false, title: 'False' },
  ],
};

export const turbineCycleDataShow = (config) => [
  {
    title: 'Turbine Cycle Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === stDesignDataVariables.steamTurbineService ||
        value === stDesignDataVariables.cycleType
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === stDesignDataVariables.steamTurbineService ||
          item.name === stDesignDataVariables.cycleType) &&
        (item.design === '' || !item.design) &&
        config.saveListener
      );
      if (item.name !== stDesignDataVariables.drivenEquipmentDescription) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.design ? '' : item.design}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              className={tempError ? 'error' : 'not-error'}
            >
              <option value="">Select</option>
              {data[item.name] &&
                data[item.name].map((item) => (
                  <option value={item.value} title={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div>
        );
      } else {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
            />
          </div>
        );
      }
    },
  },

  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'turbineCycleData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'turbineCycleData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const reqCalcDataShow = (config) => [
  {
    title: 'Required Calculation Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === stDesignDataVariables.flowEnthalpyDropMethod ||
        value === stDesignDataVariables.configSpeed ||
        value === stDesignDataVariables.stStartupSpeedThreshold ||
        value === stDesignDataVariables.stStartupPowerThresold ||
        value === stDesignDataVariables.condensingService
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === stDesignDataVariables.flowEnthalpyDropMethod ||
          item.name === stDesignDataVariables.configSpeed ||
          item.name === stDesignDataVariables.stStartupSpeedThreshold ||
          item.name === stDesignDataVariables.stStartupPowerThresold ||
          item.name === stDesignDataVariables.condensingService) &&
        item.unit === '' &&
        config.saveListener
      );
      const unitError = !!(
        (item.name !== stDesignDataVariables.flowEnthalpyDropMethod ||
          item.name !== stDesignDataVariables.condensingService) &&
        item.design &&
        !item.unit
      );
      if (
        item.name !== stDesignDataVariables.flowEnthalpyDropMethod &&
        item.name !== stDesignDataVariables.condensingService
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError || unitError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (
          item.name === stDesignDataVariables.configSpeed ||
          item.name === stDesignDataVariables.stStartupSpeedThreshold ||
          item.name === stDesignDataVariables.stStartupPowerThresold) &&
          (item.design === '' || !item.design)  &&
        config.saveListener
      );
      const tempErrorBool = !!((
        item.name === stDesignDataVariables.flowEnthalpyDropMethod ||
        item.name === stDesignDataVariables.condensingService ) &&
        (item.design === '')  &&
        config.saveListener
      );
      if (
        item.name === stDesignDataVariables.flowEnthalpyDropMethod ||
        item.name === stDesignDataVariables.condensingService
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempErrorBool ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={item.design === '' ? '' : item.design}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              className={tempError ? 'error' : 'not-error'}
            >
              <option value="">Select</option>
              {data[item.name] &&
                data[item.name].map((item) => (
                  <option value={item.value} title={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div>
        );
      } else {
        return (
          <div>
            <Input
              type="number"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'number')
              }
              className={tempError ? 'error' : 'not-error'}
            />
          </div>
        );
      }
    },
  },

  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'reqCalculationData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'reqCalculationData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const designSteamCondShow = (config) => [
  {
    title: 'Design Steam Conditions',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === stDesignDataVariables.inletSteamPressureRated ||
        value === stDesignDataVariables.inletSteamTemperatureRated
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === stDesignDataVariables.inletSteamPressureRated ||
          item.name === stDesignDataVariables.inletSteamTemperatureRated) &&
        item.unit === '' &&
        config.saveListener
      );
      const unitError = !!(item.design && !item.unit);
      return (
        <div
          className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
            unitError || tempError ? 'error' : 'not-error'
          }  `}
        >
          <select
            value={!item.unit ? '' : item.unit}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'unit')
            }
          >
            <option value="Select">Select</option>
            {config.unitData.map((getUnits) => {
              if (getUnits.dataVariable === item.name) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === stDesignDataVariables.inletSteamPressureRated ||
          item.name === stDesignDataVariables.inletSteamTemperatureRated) &&
          (item.design === '' || !item.design)  &&
        config.saveListener
      );
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={tempError
                ? 'error'
                : 'not-error'
            }
          />
        </div>
      );
    },
  },

  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'designSteamCond')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'designSteamCond')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const inductionExtractionDataShow = (config) => [
  {
    title: 'Induction And Extraction Steam Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      const unitError = !!(item.design && !item.unit);
      return (
        <div
          className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
            unitError ? 'error' : 'not-error'
          }  `}
        >
          <select
            value={!item.unit ? '' : item.unit}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'unit')
            }
          >
            <option value="Select">Select</option>
            {config.unitData.map((getUnits) => {
              if (getUnits.dataVariable === item.name) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
          />
        </div>
      );
    },
  },

  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'indExtractionSteamData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'indExtractionSteamData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const mechanicalDesignDataShow = (config) => [
  {
    title: 'Mechanical Design Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      const unitError = !!(
        (item.name !== stDesignDataVariables.maxTipSpeed ||
          item.name !== stDesignDataVariables.finalStageMaxBladeLength || 
          item.name !== stDesignDataVariables.finalStageRatedBladeLength) &&
        item.design &&
        !item.unit
      );
      if (
        item.name === stDesignDataVariables.maxTipSpeed ||
        item.name === stDesignDataVariables.finalStageRatedBladeLength ||
        item.name === stDesignDataVariables.finalStageMaxBladeLength
      ) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              unitError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (item.name === stDesignDataVariables.stShaftType) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''}
          }  `}
          >
            <select
              value={!item.design ? '' : item.design}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
            >
              <option value="">Select</option>
              {data[item.name] &&
                data[item.name].map((item) => (
                  <option value={item.value} title={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div>
        );
      } else if (
        item.name === stDesignDataVariables.numberOfStages ||
        item.name === stDesignDataVariables.maxTipSpeed ||
        item.name === stDesignDataVariables.finalStageRatedBladeLength ||
        item.name === stDesignDataVariables.finalStageMaxBladeLength
      ) {
        return (
          <div>
            <Input
              type="number"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'number')
              }
            />
          </div>
        );
      } else  {
        return (
          <div>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'text')
              }
            />
          </div>
        );
      }
    },
  },

  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'mechanicalDesignData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'mechanicalDesignData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const filterTurbineCycleData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === stDesignDataVariables.equipmentPressureType ||
      item.name === stDesignDataVariables.steamTurbineService ||
      item.name === stDesignDataVariables.cycleType ||
      item.name === stDesignDataVariables.steamInductionType ||
      item.name === stDesignDataVariables.steamExtractionType ||
      item.name === stDesignDataVariables.drivenEquipmentDescription ||
      item.name === stDesignDataVariables.service
    ) {
      return item;
    }
    return undefined;
  });
};

export const filterReqCalcData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === stDesignDataVariables.flowEnthalpyDropMethod ||
      item.name === stDesignDataVariables.configSpeed ||
      item.name === stDesignDataVariables.stStartupSpeedThreshold ||
      item.name === stDesignDataVariables.stStartupPowerThresold ||
      item.name === stDesignDataVariables.condensingService ||
      item.name === stDesignDataVariables.glandSteamLeakage ||
      item.name === stDesignDataVariables.totalSteamLeakage
    ) {
      return item;
    }
    return undefined;
  });
};

export const designSteamCondData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === stDesignDataVariables.inletSteamPressureMax ||
      item.name === stDesignDataVariables.inletSteamPressureRated ||
      item.name === stDesignDataVariables.inletSteamPressureMin ||
      item.name === stDesignDataVariables.inletSteamTemperatureRated ||
      item.name === stDesignDataVariables.inletSteamTemperatureMax ||
      item.name === stDesignDataVariables.inletSteamTemperatureMin ||
      item.name === stDesignDataVariables.rotationalSpeed ||
      item.name === stDesignDataVariables.outputSpeedRangeMin ||
      item.name === stDesignDataVariables.outputSpeedRangeMax ||
      item.name === stDesignDataVariables.exhaustSteamPressureRated ||
      item.name === stDesignDataVariables.exhaustSteamPressureMax ||
      item.name === stDesignDataVariables.exhaustSteamPressureMin ||
      item.name === stDesignDataVariables.exhaustSteamTemperatureRated ||
      item.name === stDesignDataVariables.exhaustSteamTemperatureMax ||
      item.name === stDesignDataVariables.exhaustSteamTemperatureMin ||
      item.name === stDesignDataVariables.throttleSteamFlowRated ||
      item.name === stDesignDataVariables.throttleSteamFlowMax ||
      item.name === stDesignDataVariables.throttleSteamFlowMin ||
      item.name === stDesignDataVariables.exhaustSteamFlowRated ||
      item.name === stDesignDataVariables.exhaustSteamFlowMax ||
      item.name === stDesignDataVariables.exhaustSteamFlowMin
    ) {
      return item;
    }
    return undefined;
  });
};

export const inductionExtractionSteamData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === stDesignDataVariables.inductionSteamPressureRated ||
      item.name === stDesignDataVariables.inductionSteamPressureMax ||
      item.name === stDesignDataVariables.inductionSteamPressureMin ||
      item.name === stDesignDataVariables.inductionSteamPressureRated ||
      item.name === stDesignDataVariables.inductionSteamTemperatureMax ||
      item.name === stDesignDataVariables.inductionSteamTemperatureMin ||
      item.name === stDesignDataVariables.extractionSteamPressureRated ||
      item.name === stDesignDataVariables.extractionSteamPressureMax ||
      item.name === stDesignDataVariables.extractionSteamPressureMin ||
      item.name === stDesignDataVariables.extractionSteamTemperatureRated ||
      item.name === stDesignDataVariables.extractionSteamTemperatureMax ||
      item.name === stDesignDataVariables.extractionSteamTemperatureMin ||
      item.name === stDesignDataVariables.inductionSteamFlowRated ||
      item.name === stDesignDataVariables.inductionSteamFlowMax ||
      item.name === stDesignDataVariables.inductionSteamFlowMin ||
      item.name === stDesignDataVariables.extractionSteamFlowRated ||
      item.name === stDesignDataVariables.extractionSteamFlowMax ||
      item.name === stDesignDataVariables.extractionSteamFlowMin
    ) {
      return item;
    }
    return undefined;
  });
};

export const mechanicalDesignData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === stDesignDataVariables.stShaftType ||
      item.name === stDesignDataVariables.numberOfStages ||
      item.name === stDesignDataVariables.maxTipSpeed ||
      item.name === stDesignDataVariables.finalStageMaxBladeLength ||
      item.name === stDesignDataVariables.finalStageRatedBladeLength ||
      item.name === stDesignDataVariables.radialBearingType ||
      item.name === stDesignDataVariables.radialBearingManufacturer ||
      item.name === stDesignDataVariables.thrustBearingType ||
      item.name === stDesignDataVariables.thrustBearingManufacturer ||
      item.name === stDesignDataVariables.gearBoxType ||
      item.name === stDesignDataVariables.gearBoxManufacturer ||
      item.name === stDesignDataVariables.gearBoxSpeedRatio ||
      item.name === stDesignDataVariables.lubricatingOilGrade
    ) {
      return item;
    }
    return undefined;
  });
};
