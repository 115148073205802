import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  PerformanceDataMessages,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  gtPerformanceDataVariables,
} from '../../../../../../constants/variables';
import {
  savePerformanceData,
  savePerformanceDataReset,
} from '../../../../../../redux/actions/gasTurbine.action';
import {
  ChangeKey,
  initialData,
  PerformanceDataShow,
} from './PerformanceData.Constants';
import './PerformanceData.scss';

class PerformanceDataGasTurbine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }
  componentDidMount() {
    this.setUnit();
    this.getData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnit() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  getData() {
    const {
      gasTurbineConfigData: {
        designPerformanceData: { performanceData },
      },
    } = this.props.gasTurbineReducer;
    const tempDisplayData = [
      {
        name: gtPerformanceDataVariables.inletLoss,
        unit: performanceData.inletLoss.unit,
        normalDuty: performanceData.inletLoss.normalDuty,
        siteRated: performanceData.inletLoss.siteRated,
        siteMaxTemp: performanceData.inletLoss.siteMaxTemp,
        siteMinTemp: performanceData.inletLoss.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.exhaustLoss,
        unit: performanceData.exhaustLoss.unit,
        normalDuty: performanceData.exhaustLoss.normalDuty,
        siteRated: performanceData.exhaustLoss.siteRated,
        siteMaxTemp: performanceData.exhaustLoss.siteMaxTemp,
        siteMinTemp: performanceData.exhaustLoss.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.dryBulbTemp,
        unit: performanceData.dryBulbTemp.unit,
        normalDuty: performanceData.dryBulbTemp.normalDuty,
        siteRated: performanceData.dryBulbTemp.siteRated,
        siteMaxTemp: performanceData.dryBulbTemp.siteMaxTemp,
        siteMinTemp: performanceData.dryBulbTemp.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.turbineInletAirChilled,
        normalDuty: performanceData.turbineInletAirChilled.normalDuty,
        siteRated: performanceData.turbineInletAirChilled.siteRated,
        siteMaxTemp: performanceData.turbineInletAirChilled.siteMaxTemp,
        siteMinTemp: performanceData.turbineInletAirChilled.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.turbineInletTemp,
        unit: performanceData.turbineInletTemp.unit,
        normalDuty: performanceData.turbineInletTemp.normalDuty,
        siteRated: performanceData.turbineInletTemp.siteRated,
        siteMaxTemp: performanceData.turbineInletTemp.siteMaxTemp,
        siteMinTemp: performanceData.turbineInletTemp.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.relativeHumidity,
        unit: performanceData.relativeHumidity.unit,
        normalDuty: performanceData.relativeHumidity.normalDuty,
        siteRated: performanceData.relativeHumidity.siteRated,
        siteMaxTemp: performanceData.relativeHumidity.siteMaxTemp,
        siteMinTemp: performanceData.relativeHumidity.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.barometricPressure,
        unit: performanceData.barometricPressure.unit,
        normalDuty: performanceData.barometricPressure.normalDuty,
        siteRated: performanceData.barometricPressure.siteRated,
        siteMaxTemp: performanceData.barometricPressure.siteMaxTemp,
        siteMinTemp: performanceData.barometricPressure.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.gtOutputShaftPower,
        unit: performanceData.gtOutputShaftPower.unit,
        normalDuty: performanceData.gtOutputShaftPower.normalDuty,
        siteRated: performanceData.gtOutputShaftPower.siteRated,
        siteMaxTemp: performanceData.gtOutputShaftPower.siteMaxTemp,
        siteMinTemp: performanceData.gtOutputShaftPower.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.gasGeneratorOutputShaftSpeed,
        unit: performanceData.gasGeneratorOutputShaftSpeed.unit,
        normalDuty: performanceData.gasGeneratorOutputShaftSpeed.normalDuty,
        siteRated: performanceData.gasGeneratorOutputShaftSpeed.siteRated,
        siteMaxTemp: performanceData.gasGeneratorOutputShaftSpeed.siteMaxTemp,
        siteMinTemp: performanceData.gasGeneratorOutputShaftSpeed.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.powerTurbineOutputShaftSpeed,
        unit: performanceData.powerTurbineOutputShaftSpeed.unit,
        normalDuty: performanceData.powerTurbineOutputShaftSpeed.normalDuty,
        siteRated: performanceData.powerTurbineOutputShaftSpeed.siteRated,
        siteMaxTemp: performanceData.powerTurbineOutputShaftSpeed.siteMaxTemp,
        siteMinTemp: performanceData.powerTurbineOutputShaftSpeed.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.lhvHeatRate,
        unit: performanceData.lhvHeatRate.unit,
        normalDuty: performanceData.lhvHeatRate.normalDuty,
        siteRated: performanceData.lhvHeatRate.siteRated,
        siteMaxTemp: performanceData.lhvHeatRate.siteMaxTemp,
        siteMinTemp: performanceData.lhvHeatRate.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.lhvEfficiency,
        unit: performanceData.lhvEfficiency.unit,
        normalDuty: performanceData.lhvEfficiency.normalDuty,
        siteRated: performanceData.lhvEfficiency.siteRated,
        siteMaxTemp: performanceData.lhvEfficiency.siteMaxTemp,
        siteMinTemp: performanceData.lhvEfficiency.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.firingTemp,
        unit: performanceData.firingTemp.unit,
        normalDuty: performanceData.firingTemp.normalDuty,
        siteRated: performanceData.firingTemp.siteRated,
        siteMaxTemp: performanceData.firingTemp.siteMaxTemp,
        siteMinTemp: performanceData.firingTemp.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.airFlow,
        unit: performanceData.airFlow.unit,
        normalDuty: performanceData.airFlow.normalDuty,
        siteRated: performanceData.airFlow.siteRated,
        siteMaxTemp: performanceData.airFlow.siteMaxTemp,
        siteMinTemp: performanceData.airFlow.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.ggExhaustTemp,
        unit: performanceData.ggExhaustTemp.unit,
        normalDuty: performanceData.ggExhaustTemp.normalDuty,
        siteRated: performanceData.ggExhaustTemp.siteRated,
        siteMaxTemp: performanceData.ggExhaustTemp.siteMaxTemp,
        siteMinTemp: performanceData.ggExhaustTemp.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.ptExhaustFlow,
        unit: performanceData.ptExhaustFlow.unit,
        normalDuty: performanceData.ptExhaustFlow.normalDuty,
        siteRated: performanceData.ptExhaustFlow.siteRated,
        siteMaxTemp: performanceData.ptExhaustFlow.siteMaxTemp,
        siteMinTemp: performanceData.ptExhaustFlow.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.ptExhaustTemp,
        unit: performanceData.ptExhaustTemp.unit,
        normalDuty: performanceData.ptExhaustTemp.normalDuty,
        siteRated: performanceData.ptExhaustTemp.siteRated,
        siteMaxTemp: performanceData.ptExhaustTemp.siteMaxTemp,
        siteMinTemp: performanceData.ptExhaustTemp.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.fuelFlowrate,
        unit: performanceData.fuelFlowrate.unit,
        normalDuty: performanceData.fuelFlowrate.normalDuty,
        siteRated: performanceData.fuelFlowrate.siteRated,
        siteMaxTemp: performanceData.fuelFlowrate.siteMaxTemp,
        siteMinTemp: performanceData.fuelFlowrate.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.certifiedPoint,
        normalDuty: performanceData.certifiedPoint.normalDuty,
        siteRated: performanceData.certifiedPoint.siteRated,
        siteMaxTemp: performanceData.certifiedPoint.siteMaxTemp,
        siteMinTemp: performanceData.certifiedPoint.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.steamFlow,
        unit: performanceData.steamFlow.unit,
        normalDuty: performanceData.steamFlow.normalDuty,
        siteRated: performanceData.steamFlow.siteRated,
        siteMaxTemp: performanceData.steamFlow.siteMaxTemp,
        siteMinTemp: performanceData.steamFlow.siteMinTemp,
      },
      {
        name: gtPerformanceDataVariables.waterFlow,
        unit: performanceData.waterFlow.unit,
        normalDuty: performanceData.waterFlow.normalDuty,
        siteRated: performanceData.waterFlow.siteRated,
        siteMaxTemp: performanceData.waterFlow.siteMaxTemp,
        siteMinTemp: performanceData.waterFlow.siteMinTemp,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }
  componentDidUpdate(prevProps) {
    const {
      addPerformanceData,
      gasTurbineConfigData,
    } = this.props.gasTurbineReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnit();
    }

    if (
      gasTurbineConfigData.isSuccess &&
      prevProps.gasTurbineReducer.gasTurbineConfigData.isSuccess !==
        gasTurbineConfigData.isSuccess
    ) {
      this.getData();
    }

    if (
      addPerformanceData.isSuccess &&
      prevProps.gasTurbineReducer.addPerformanceData.isSuccess !==
        addPerformanceData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, PerformanceDataMessages.SUCCESS);
    }
    if (
      addPerformanceData.isError &&
      prevProps.gasTurbineReducer.addPerformanceData.isError !==
        addPerformanceData.isError
    ) {
      Notification.show(Types.Error, PerformanceDataMessages.ERROR);
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'siteRated') {
        return {
          name,
          unit: item.unit,
          siteRated:
            item.name !== gtPerformanceDataVariables.turbineInletAirChilled &&
            item.name !== gtPerformanceDataVariables.certifiedPoint
              ? parseFloat(val)
              : val,
          siteMinTemp: item.siteMinTemp,
          siteMaxTemp: item.siteMaxTemp,
          normalDuty: item.normalDuty,
        };
      }
      if (item.name === name && type === 'siteMinTemp') {
        return {
          name,
          unit: item.unit,
          siteRated: item.siteRated,
          siteMinTemp:
            item.name !== gtPerformanceDataVariables.turbineInletAirChilled &&
            item.name !== gtPerformanceDataVariables.certifiedPoint
              ? parseFloat(val)
              : val,
          siteMaxTemp: item.siteMaxTemp,
          normalDuty: item.normalDuty,
        };
      }
      if (item.name === name && type === 'siteMaxTemp') {
        return {
          name,
          unit: item.unit,
          siteRated: item.siteRated,
          siteMinTemp: item.siteMinTemp,
          siteMaxTemp:
            item.name !== gtPerformanceDataVariables.turbineInletAirChilled &&
            item.name !== gtPerformanceDataVariables.certifiedPoint
              ? parseFloat(val)
              : val,
          normalDuty: item.normalDuty,
        };
      }
      if (item.name === name && type === 'normalDuty') {
        return {
          name,
          unit: item.unit,
          siteRated: item.siteRated,
          siteMinTemp: item.siteMinTemp,
          siteMaxTemp: item.siteMaxTemp,
          normalDuty:
            item.name !== gtPerformanceDataVariables.turbineInletAirChilled &&
            item.name !== gtPerformanceDataVariables.certifiedPoint
              ? parseFloat(val)
              : val,
        };
      }
      if (item.name === name && type === 'unit') {
        return {
          name,
          unit: val,
          siteRated: item.siteRated,
          siteMinTemp: item.siteMinTemp,
          siteMaxTemp: item.siteMaxTemp,
          normalDuty: item.normalDuty,
        };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        saveListener: true,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    const editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onSave = () => {
    const {
      location: { state },
      savePerformanceData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
    });
    const data = ChangeKey;
    const payload = {};
    displayData.forEach((item) => {
      payload[data[item.name]] = {
        unit: item.unit,
        normalDuty: item.normalDuty,
        siteRated: item.siteRated,
        siteMaxTemp: item.siteMaxTemp,
        siteMinTemp: item.siteMinTemp,
      };
    });
    if (
      (this.handleNetwork() && state && state.componentData.info._id, payload)
    ) {
      savePerformanceData(state.componentData.info._id, payload);
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }
  render() {
    const { isSchematicEditable } = this.props;
    const {
      gasTurbineConfigData,
      addPerformanceData,
    } = this.props.gasTurbineReducer;
    const { saveListener, unitData } = this.state;

    return (
      <div className="PerformanceData">
        {(gasTurbineConfigData.isLoading || addPerformanceData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          showHeader
          columns={PerformanceDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}
PerformanceDataGasTurbine.PropType = {
  location: PropType.object,
  gasTurbineConfigData: PropType.object,
  addPerformanceData: PropType.object,
  performanceData: PropType.object,
  savePerformanceData: PropType.func,
  savePerformanceDataReset: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
  isSchematicEditable: PropType.bool,
};
PerformanceDataGasTurbine.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => ({
  gasTurbineReducer: state.gasTurbineReducer,
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  savePerformanceData: (componentId, payload) =>
    dispatch(savePerformanceData(componentId, payload)),
  savePerformanceDataReset: () => dispatch(savePerformanceDataReset()),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(PerformanceDataGasTurbine)
);
