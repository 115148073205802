import {
  CURVE_PROGRESS,
  CURVE_ADDED,
  CURVE_DELETED,
  CURVE_ERROR,
  CURVE_DETECT_CHANGE,
  CLEAR_CURVES_STATE
} from "../Types/curve.types";

import { PUTRequest, DeleteRequest } from "../../api/request";

const curveProgress = () => ({
  type: CURVE_PROGRESS,
});
const curveAdded = (payload) => ({
  type: CURVE_ADDED,
  payload,
});
const curveDelete = (payload) => ({
  type: CURVE_DELETED,
  payload,
});
const curveError = (payload) => ({
  type: CURVE_ERROR,
  payload,
});
const curveChange = (payload) => ({
  type: CURVE_DETECT_CHANGE,
  payload
});
const clearCurves = () => ({
  type: CLEAR_CURVES_STATE
});

export function addEditCurve(id, payload, componentType) {
  // URL Builder
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/curves`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves`,
    "Centrifugal pump": `components/${id}/pumpconfigs/curves`,
    "Steam Turbine": `components/${id}/stconfigs/curves`,
  };
  return (dispatch) => {
    dispatch(curveProgress());
    PUTRequest(componentAPIMapper[componentType], payload)
      .then((res) => dispatch(curveAdded(res.body)))
      .catch((err) => dispatch(curveError(err)));
  };
}

export const deleteCurve = (id, componentType, curveId) => {
  const componentAPIMapper = {
    "Centrifugal compressor": `components/${id}/compressorconfigs/curves?curveId=${curveId}`,
    "Centrifugal pump": `components/${id}/pumpconfigs/curves?curveId=${curveId}`,
    "Steam Turbine": `components/${id}/stconfigs/curves?curveId=${curveId}`
  };
  return (dispatch) => {
    dispatch(curveProgress());
    DeleteRequest(componentAPIMapper[componentType])
      .then((res) => dispatch(curveDelete(curveId)))
      .catch((err) => dispatch(curveError(err)));
  };
};

export const toggleDetectState = (status) =>  {
  return (dispatch) => {
    dispatch(curveChange(status));
  }
}

export const clearCurvesState = () => {
  return (dispatch) => {
    dispatch(clearCurves());
  }
}
