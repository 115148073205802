import Type from '../Types/synchronousGenerator.types';

const synchronousGeneratorConfigData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  componentId: '',
  /** generator parameter data */
  motorParameterData: {
    generatorRatedVoltage: {},
    ratedVoltageSaturatedSubtransientReactance: {},
    ratedVoltageSaturatedNegativeSequenceReactance: {},
    ratedVoltageSaturatedArmatureTimeConstant: {},
    armatureLeakageReactance: {},
    directAxisArmatureReactance: {},
    saturatedDirectAxisSynchronousReactance: {},
    unsaturatedDirectAxisSynchronousReactance: {},
    potierReactance: {},
    saturatedDirectAxisTransientReactance: {},
    unsaturatedDirectAxisTransientReactance: {},
    saturatedDirectAxisSubtransientReactance: {},
    unsaturatedDirectAxisSubtransientReactance: {},
    quadratureAxisArmatureReactance: {},
    saturatedQuadratureAxisSynchronousReactance: {},
    unsaturatedQuadratureAxisSynchronousReactance: {},
    fieldLeakageReactance: {},
    saturatedQuadratureAxisTransientReactance: {},
    unsaturatedQuadratureAxisTransientReactance: {},
    saturatedQuadratureAxisSubtransientReactance: {},
    unsaturatedQuadratureAxisSubtransientReactance: {},
    quadratureAxisAmortisseurReactance: {},
    directAxisAmortisseurReactance: {},
    saturatedNegativeSequenceReactance: {},
    unsaturatedNegativeSequenceReactance: {},
    statorArmatureResistance: {},
    withoutFdrsFieldResistance: {},
    withFdrsFieldResistance: {},
    quadratureAxisAmortisseurResistance: {},
    directAxisAmortisseurResistance: {},
    zeroSequenceResistance: {},
    negativeSequenceResistance: {},
    armatureShortCircuitTimeConstant: {},
    directAxisTransientOpenCircuitTimeConstant: {},
    directAxisSubtransientOpenCircuitTimeConstant: {},
    quadratureAxisSubtransientOpenCircuitTimeConstant: {},
    directAxisTransientShortCircuitTimeConstant: {},
    directAxisSubtransientShortCircuitTimeConstant: {},
    windingCapacitancetoGround: {},
    i22tLimit: null,
  },
  /** Perforrmance curve */
  performanceCurvesData: {
    data: [],
    dataUnit: {},
    powerFactor: {},
  },
  /** Capability curve */
  capabilityCurvesData: {
    data: [],
    dataUnit: {},
  },
  /** Starting char curve */
  startingCharacteristicsCurvesData: {
    data: [],
    dataUnit: {},
  },
  variableSpeedCurvesData: {
    data: [],
    dataUnit: {},
    driveType: '',
    driveManufacturer: '',
    driveModel: '',
    lineSideConverter: '',
    loadSideInverter: '',
    speedControl: '',
  },
  /** Design data */
  designData: {
    frequency: {},
    ratedPower: {},
    ratedCurrent: {},
    ratedVoltage: {},
    dampingFactor: '',
    fieldI2RLosses: {},
    strayLoadLosses: {},
    inertiaConstant: '',
    ratedPowerFactor: {},
    ratedTemperature: {},
    synchronousSpeed: {},
    armatureI2RLosses: {},
    excitorStatorResistance: {},
    generatorRotorResistance: {},
    generatorStatorResistance: {},
    generatorAccelarationTime: {},
    coreLossesAtNominalRating: {},
    shortCircuitCurrent3PhaseRMS: {},
    shortCircuitCurrent2PhasePeak: {},
    shortCircuitCurrent3PhasePeak: {},
    shortCircuitCurrent3SteadyState: {},
    frictionWindageLossesAtNominalRating: {},
    statorCurrentThresholdForRunningStatus: {},
    generatorPowerThresholdForRunningStatus: {},
    generatorSpeedThresholdForRunningStatus: {},
    modelNumber: '',
    frameSize: '',
    numberOfPhases: '',
    manufacturer: '',
    statorInsulationClass: '',
    rotorInsulationClass: '',
    statorTemperatureRise: '',
    rotorTemperatureRise: '',
    exciterTemperatureRise: '',
    permanentMagnetGenerator: '',
    nemaEnclosureType: '',
    dutyType: '',
    enclosureIPCode: '',
    icCode: '',
    imCode: '',
    speedType: '',
    speedSignalRatio: '',
    bearingType: '',
    bearingQuantity: '',
    rotorConstruction: '',
    excitationType: '',
    exciterType: '',
  },
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addMotorParameterData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addPerformanceData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};
const addPerformanceCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};
const addCapabilityCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};
const addStartingCharCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};
const addVarSpeedCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addDriveTypes = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const getDriveType = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: [],
};

const INITIAL_STATE = {
  synchronousGeneratorConfigData: { ...synchronousGeneratorConfigData },
  addDesignData: { ...addDesignData },
  addPerformanceData: { ...addPerformanceData },
  addPerformanceCurve: { ...addPerformanceCurve },
  addCapabilityCurve: { ...addCapabilityCurve },
  addStartingCharCurve: { ...addStartingCharCurve },
  addVarSpeedCurve: { ...addVarSpeedCurve },
  addDriveTypes: { ...addDriveTypes },
  getDriveType: { ...getDriveType },
  addMotorParameterData: { ...addMotorParameterData },
};

export default function synchronousGeneratorReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        synchronousGeneratorConfigData: {
          ...INITIAL_STATE.synchronousGeneratorConfigData,
          isLoading: true,
        },
      };
    case Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          isSuccess: true,
          isLoading: false,
          componentId: action.payload?.componentId,
          /* Motor Parameter Data */
          motorParameterData: action.payload?.motorParameterData
            ? {
                generatorRatedVoltage:
                  action.payload.motorParameterData.generatorRatedVoltage ||
                  action.payload.motorParameterData.generatorRatedVoltage === 0
                    ? action.payload.motorParameterData.generatorRatedVoltage
                    : {},
                ratedVoltageSaturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                        .ratedVoltageSaturatedNegativeSequenceReactance
                    : {},
                ratedVoltageSaturatedSubtransientReactance:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedSubtransientReactance ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedSubtransientReactance === 0
                    ? action.payload.motorParameterData
                        .ratedVoltageSaturatedSubtransientReactance
                    : {},
                ratedVoltageSaturatedArmatureTimeConstant:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedArmatureTimeConstant ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedArmatureTimeConstant === 0
                    ? action.payload.motorParameterData
                        .ratedVoltageSaturatedArmatureTimeConstant
                    : {},
                armatureLeakageReactance:
                  action.payload.motorParameterData.armatureLeakageReactance ||
                  action.payload.motorParameterData.armatureLeakageReactance ===
                    0
                    ? action.payload.motorParameterData.armatureLeakageReactance
                    : {},
                directAxisArmatureReactance:
                  action.payload.motorParameterData
                    .directAxisArmatureReactance ||
                  action.payload.motorParameterData
                    .directAxisArmatureReactance === 0
                    ? action.payload.motorParameterData
                        .directAxisArmatureReactance
                    : {},
                saturatedDirectAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedDirectAxisSynchronousReactance
                    : {},
                unsaturatedDirectAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedDirectAxisSynchronousReactance
                    : {},
                potierReactance:
                  action.payload.motorParameterData.potierReactance ||
                  action.payload.motorParameterData.potierReactance === 0
                    ? action.payload.motorParameterData.potierReactance
                    : {},
                saturatedDirectAxisTransientReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisTransientReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedDirectAxisTransientReactance
                    : {},
                unsaturatedDirectAxisTransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisTransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedDirectAxisTransientReactance
                    : {},
                saturatedDirectAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedDirectAxisSubtransientReactance
                    : {},
                unsaturatedDirectAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedDirectAxisSubtransientReactance
                    : {},
                quadratureAxisArmatureReactance:
                  action.payload.motorParameterData
                    .quadratureAxisArmatureReactance ||
                  action.payload.motorParameterData
                    .quadratureAxisArmatureReactance === 0
                    ? action.payload.motorParameterData
                        .quadratureAxisArmatureReactance
                    : {},
                saturatedQuadratureAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedQuadratureAxisSynchronousReactance
                    : {},
                unsaturatedQuadratureAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedQuadratureAxisSynchronousReactance
                    : {},
                fieldLeakageReactance:
                  action.payload.motorParameterData.fieldLeakageReactance ||
                  action.payload.motorParameterData.fieldLeakageReactance === 0
                    ? action.payload.motorParameterData.fieldLeakageReactance
                    : {},
                saturatedQuadratureAxisTransientReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisTransientReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedQuadratureAxisTransientReactance
                    : {},
                unsaturatedQuadratureAxisTransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisTransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedQuadratureAxisTransientReactance
                    : {},
                saturatedQuadratureAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedQuadratureAxisSubtransientReactance
                    : {},
                unsaturatedQuadratureAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedQuadratureAxisSubtransientReactance
                    : {},
                quadratureAxisAmortisseurReactance:
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurReactance ||
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurReactance === 0
                    ? action.payload.motorParameterData
                        .quadratureAxisAmortisseurReactance
                    : {},
                directAxisAmortisseurReactance:
                  action.payload.motorParameterData
                    .directAxisAmortisseurReactance ||
                  action.payload.motorParameterData
                    .directAxisAmortisseurReactance === 0
                    ? action.payload.motorParameterData
                        .directAxisAmortisseurReactance
                    : {},
                saturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .saturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .saturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                        .saturatedNegativeSequenceReactance
                    : {},
                unsaturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .unsaturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .unsaturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                        .unsaturatedNegativeSequenceReactance
                    : {},
                statorArmatureResistance:
                  action.payload.motorParameterData.statorArmatureResistance ||
                  action.payload.motorParameterData.statorArmatureResistance ===
                    0
                    ? action.payload.motorParameterData.statorArmatureResistance
                    : {},
                withoutFdrsFieldResistance:
                  action.payload.motorParameterData
                    .withoutFdrsFieldResistance ||
                  action.payload.motorParameterData
                    .withoutFdrsFieldResistance === 0
                    ? action.payload.motorParameterData
                        .withoutFdrsFieldResistance
                    : {},
                withFdrsFieldResistance:
                  action.payload.motorParameterData.withFdrsFieldResistance ||
                  action.payload.motorParameterData.withFdrsFieldResistance ===
                    0
                    ? action.payload.motorParameterData.withFdrsFieldResistance
                    : {},
                quadratureAxisAmortisseurResistance:
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurResistance ||
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurResistance === 0
                    ? action.payload.motorParameterData
                        .quadratureAxisAmortisseurResistance
                    : {},
                directAxisAmortisseurResistance:
                  action.payload.motorParameterData
                    .directAxisAmortisseurResistance ||
                  action.payload.motorParameterData
                    .directAxisAmortisseurResistance === 0
                    ? action.payload.motorParameterData
                        .directAxisAmortisseurResistance
                    : {},
                zeroSequenceResistance:
                  action.payload.motorParameterData.zeroSequenceResistance ||
                  action.payload.motorParameterData.zeroSequenceResistance === 0
                    ? action.payload.motorParameterData.zeroSequenceResistance
                    : {},
                negativeSequenceResistance:
                  action.payload.motorParameterData
                    .negativeSequenceResistance ||
                  action.payload.motorParameterData
                    .negativeSequenceResistance === 0
                    ? action.payload.motorParameterData
                        .negativeSequenceResistance
                    : {},
                armatureShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .armatureShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .armatureShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .armatureShortCircuitTimeConstant
                    : {},
                directAxisTransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisTransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisTransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .directAxisTransientOpenCircuitTimeConstant
                    : {},
                directAxisSubtransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisSubtransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisSubtransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .directAxisSubtransientOpenCircuitTimeConstant
                    : {},
                quadratureAxisSubtransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .quadratureAxisSubtransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .quadratureAxisSubtransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .quadratureAxisSubtransientOpenCircuitTimeConstant
                    : {},
                directAxisTransientShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisTransientShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisTransientShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .directAxisTransientShortCircuitTimeConstant
                    : {},
                directAxisSubtransientShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisSubtransientShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisSubtransientShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                        .directAxisSubtransientShortCircuitTimeConstant
                    : {},
                windingCapacitancetoGround:
                  action.payload.motorParameterData
                    .windingCapacitancetoGround ||
                  action.payload.motorParameterData
                    .windingCapacitancetoGround === 0
                    ? action.payload.motorParameterData
                        .windingCapacitancetoGround
                    : {},
                i22tLimit:
                  action.payload.motorParameterData.i22tLimit ||
                  action.payload.motorParameterData.i22tLimit === 0
                    ? action.payload.motorParameterData.i22tLimit
                    : null,
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData
                  .motorParameterData,
              },

          /** Perforrmance curve */
          performanceCurvesData: action.payload?.performanceCurvesData
            ? {
                data: action.payload.performanceCurvesData.data
                  ? action.payload.performanceCurvesData.data
                  : {},
                dataUnit: action.payload.performanceCurvesData.dataUnit
                  ? action.payload.performanceCurvesData.dataUnit
                  : {},
                powerFactor: action.payload.performanceCurvesData.powerFactor
                  ? action.payload.performanceCurvesData.powerFactor
                  : {},
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData
                  .performanceCurvesData,
              },

          /** Perforrmance curve */
          capabilityCurvesData: action.payload?.capabilityCurvesData
            ? {
                data: action.payload.capabilityCurvesData.data
                  ? action.payload.capabilityCurvesData.data
                  : {},
                dataUnit: action.payload.capabilityCurvesData.dataUnit
                  ? action.payload.capabilityCurvesData.dataUnit
                  : {},
                powerFactor: action.payload.capabilityCurvesData.powerFactor
                  ? action.payload.capabilityCurvesData.powerFactor
                  : {},
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData
                  .capabilityCurvesData,
              },
          /** Starting char curve */
          startingCharacteristicsCurvesData: action.payload?.startingCharacteristicsCurvesData
            ? {
                data: action.payload.startingCharacteristicsCurvesData.data
                  ? action.payload.startingCharacteristicsCurvesData.data
                  : {},
                dataUnit: action.payload.startingCharacteristicsCurvesData
                  .dataUnit
                  ? action.payload.startingCharacteristicsCurvesData.dataUnit
                  : {},
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData
                  .startingCharacteristicsCurvesData,
              },
          variableSpeedCurvesData: action.payload?.variableSpeedCurvesData
            ? {
                data: action.payload.variableSpeedCurvesData.data
                  ? action.payload.variableSpeedCurvesData.data
                  : {},
                dataUnit: action.payload.variableSpeedCurvesData.dataUnit
                  ? action.payload.variableSpeedCurvesData.dataUnit
                  : {},
                driveType: action.payload.variableSpeedCurvesData.driveType
                  ? action.payload.variableSpeedCurvesData.driveType
                  : '',
                driveManufacturer: action.payload.variableSpeedCurvesData
                  .driveManufacturer
                  ? action.payload.variableSpeedCurvesData.driveManufacturer
                  : '',
                driveModel: action.payload.variableSpeedCurvesData.driveModel
                  ? action.payload.variableSpeedCurvesData.driveModel
                  : '',
                lineSideConverter: action.payload.variableSpeedCurvesData
                  .lineSideConverter
                  ? action.payload.variableSpeedCurvesData.lineSideConverter
                  : '',
                loadSideInverter: action.payload.variableSpeedCurvesData
                  .loadSideInverter
                  ? action.payload.variableSpeedCurvesData.loadSideInverter
                  : '',
                speedControl: action.payload.variableSpeedCurvesData
                  .speedControl
                  ? action.payload.variableSpeedCurvesData.speedControl
                  : '',
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData
                  .variableSpeedCurvesData,
              },
          /** Design data */
          designData: action.payload?.designData
            ? {
                dutyType:
                  action.payload.designData.dutyType ||
                  action.payload.designData.dutyType === 0
                    ? action.payload.designData.dutyType
                    : null,
                numberOfPhases:
                  action.payload.designData.numberOfPhases ||
                  action.payload.designData.numberOfPhases === 0
                    ? action.payload.designData.numberOfPhases
                    : null,
                voltage:
                  action.payload.designData.voltage ||
                  action.payload.designData.voltage === 0
                    ? action.payload.designData.voltage
                    : {},
                ratedPower:
                  action.payload.designData.ratedPower ||
                  action.payload.designData.ratedPower === 0
                    ? action.payload.designData.ratedPower
                    : {},
                ratedCurrent:
                  action.payload.designData.ratedCurrent ||
                  action.payload.designData.ratedCurrent === 0
                    ? action.payload.designData.ratedCurrent
                    : {},
                ratedVoltage:
                  action.payload.designData.ratedVoltage ||
                  action.payload.designData.ratedVoltage === 0
                    ? action.payload.designData.ratedVoltage
                    : {},
                fieldI2RLosses:
                  action.payload.designData.fieldI2RLosses ||
                  action.payload.designData.fieldI2RLosses === 0
                    ? action.payload.designData.fieldI2RLosses
                    : {},
                strayLoadLosses:
                  action.payload.designData.strayLoadLosses ||
                  action.payload.designData.strayLoadLosses === 0
                    ? action.payload.designData.strayLoadLosses
                    : {},
                frequency:
                  action.payload.designData.frequency ||
                  action.payload.designData.frequency === 0
                    ? action.payload.designData.frequency
                    : {},
                ratedPowerFactor:
                  action.payload.designData.ratedPowerFactor ||
                  action.payload.designData.ratedPowerFactor === 0
                    ? action.payload.designData.ratedPowerFactor
                    : {},
                ratedTemperature:
                  action.payload.designData.ratedTemperature ||
                  action.payload.designData.ratedTemperature === 0
                    ? action.payload.designData.ratedTemperature
                    : {},
                synchronousSpeed:
                  action.payload.designData.synchronousSpeed ||
                  action.payload.designData.synchronousSpeed === 0
                    ? action.payload.designData.synchronousSpeed
                    : {},
                armatureI2RLosses:
                  action.payload.designData.armatureI2RLosses ||
                  action.payload.designData.armatureI2RLosses === 0
                    ? action.payload.designData.armatureI2RLosses
                    : {},
                serviceFactor:
                  action.payload.designData.serviceFactor ||
                  action.payload.designData.serviceFactor === 0
                    ? action.payload.designData.serviceFactor
                    : null,
                insulationClass:
                  action.payload.designData.insulationClass ||
                  action.payload.designData.insulationClass === 0
                    ? action.payload.designData.insulationClass
                    : null,
                temperatureClass:
                  action.payload.designData.temperatureClass ||
                  action.payload.designData.temperatureClass === 0
                    ? action.payload.designData.temperatureClass
                    : null,
                nemaEnclosureType:
                  action.payload.designData.nemaEnclosureType ||
                  action.payload.designData.nemaEnclosureType === 0
                    ? action.payload.designData.nemaEnclosureType
                    : null,
                enclosureIPCode:
                  action.payload.designData.enclosureIPCode ||
                  action.payload.designData.enclosureIPCode === 0
                    ? action.payload.designData.enclosureIPCode
                    : null,
                icCode:
                  action.payload.designData.icCode ||
                  action.payload.designData.icCode === 0
                    ? action.payload.designData.icCode
                    : null,
                imCode:
                  action.payload.designData.imCode ||
                  action.payload.designData.imCode === 0
                    ? action.payload.designData.imCode
                    : null,
                speedType:
                  action.payload.designData.speedType ||
                  action.payload.designData.speedType === 0
                    ? action.payload.designData.speedType
                    : null,
                speedSignalRatio:
                  action.payload.designData.speedSignalRatio ||
                  action.payload.designData.speedSignalRatio === 0
                    ? action.payload.designData.speedSignalRatio
                    : null,
                bearingType:
                  action.payload.designData.bearingType ||
                  action.payload.designData.bearingType === 0
                    ? action.payload.designData.bearingType
                    : null,
                bearingQuantity:
                  action.payload.designData.bearingQuantity ||
                  action.payload.designData.bearingQuantity === 0
                    ? action.payload.designData.bearingQuantity
                    : null,
                rotorConstruction:
                  action.payload.designData.rotorConstruction ||
                  action.payload.designData.rotorConstruction === 0
                    ? action.payload.designData.rotorConstruction
                    : null,
                dampingFactor:
                  action.payload.designData.dampingFactor ||
                  action.payload.designData.dampingFactor === 0
                    ? action.payload.designData.dampingFactor
                    : null,
                inertiaConstant:
                  action.payload.designData.inertiaConstant ||
                  action.payload.designData.inertiaConstant === 0
                    ? action.payload.designData.inertiaConstant
                    : null,
                excitorStatorResistance:
                  action.payload.designData.excitorStatorResistance ||
                  action.payload.designData.excitorStatorResistance === 0
                    ? action.payload.designData.excitorStatorResistance
                    : {},
                generatorRotorResistance:
                  action.payload.designData.generatorRotorResistance ||
                  action.payload.designData.generatorRotorResistance === 0
                    ? action.payload.designData.generatorRotorResistance
                    : {},
                generatorStatorResistance:
                  action.payload.designData.generatorStatorResistance ||
                  action.payload.designData.generatorStatorResistance === 0
                    ? action.payload.designData.generatorStatorResistance
                    : {},
                generatorAccelarationTime:
                  action.payload.designData.generatorAccelarationTime ||
                  action.payload.designData.generatorAccelarationTime === 0
                    ? action.payload.designData.generatorAccelarationTime
                    : {},
                coreLossesAtNominalRating:
                  action.payload.designData.coreLossesAtNominalRating ||
                  action.payload.designData.coreLossesAtNominalRating === 0
                    ? action.payload.designData.coreLossesAtNominalRating
                    : {},
                shortCircuitCurrent3PhaseRMS:
                  action.payload.designData.shortCircuitCurrent3PhaseRMS ||
                  action.payload.designData.shortCircuitCurrent3PhaseRMS === 0
                    ? action.payload.designData.shortCircuitCurrent3PhaseRMS
                    : {},
                shortCircuitCurrent2PhasePeak:
                  action.payload.designData.shortCircuitCurrent2PhasePeak ||
                  action.payload.designData.shortCircuitCurrent2PhasePeak === 0
                    ? action.payload.designData.shortCircuitCurrent2PhasePeak
                    : {},
                shortCircuitCurrent3PhasePeak:
                  action.payload.designData.shortCircuitCurrent3PhasePeak ||
                  action.payload.designData.shortCircuitCurrent3PhasePeak === 0
                    ? action.payload.designData.shortCircuitCurrent3PhasePeak
                    : {},
                shortCircuitCurrent3SteadyState:
                  action.payload.designData.shortCircuitCurrent3SteadyState ||
                  action.payload.designData.shortCircuitCurrent3SteadyState ===
                    0
                    ? action.payload.designData.shortCircuitCurrent3SteadyState
                    : {},
                frictionWindageLossesAtNominalRating:
                  action.payload.designData
                    .frictionWindageLossesAtNominalRating ||
                  action.payload.designData
                    .frictionWindageLossesAtNominalRating === 0
                    ? action.payload.designData
                        .frictionWindageLossesAtNominalRating
                    : {},
                statorCurrentThresholdForRunningStatus:
                  action.payload.designData
                    .statorCurrentThresholdForRunningStatus ||
                  action.payload.designData
                    .statorCurrentThresholdForRunningStatus === 0
                    ? action.payload.designData
                        .statorCurrentThresholdForRunningStatus
                    : {},
                generatorPowerThresholdForRunningStatus:
                  action.payload.designData
                    .generatorPowerThresholdForRunningStatus ||
                  action.payload.designData
                    .generatorPowerThresholdForRunningStatus === 0
                    ? action.payload.designData
                        .generatorPowerThresholdForRunningStatus
                    : {},
                generatorSpeedThresholdForRunningStatus:
                  action.payload.designData
                    .generatorSpeedThresholdForRunningStatus ||
                  action.payload.designData
                    .generatorSpeedThresholdForRunningStatus === 0
                    ? action.payload.designData.generatorSpeedThresholdForRunningStatus
                    : {},
                modelNumber:
                  action.payload.designData.modelNumber ||
                  action.payload.designData.modelNumber === 0
                    ? action.payload.designData.modelNumber
                    : null,
                frameSize:
                  action.payload.designData.frameSize ||
                  action.payload.designData.frameSize === 0
                    ? action.payload.designData.frameSize
                    : null,
                manufacturer:
                  action.payload.designData.manufacturer ||
                  action.payload.designData.manufacturer === 0
                    ? action.payload.designData.manufacturer
                    : null,
                statorInsulationClass:
                  action.payload.designData.statorInsulationClass ||
                  action.payload.designData.statorInsulationClass === 0
                    ? action.payload.designData.statorInsulationClass
                    : null,
                rotorInsulationClass:
                  action.payload.designData.rotorInsulationClass ||
                  action.payload.designData.rotorInsulationClass === 0
                    ? action.payload.designData.rotorInsulationClass
                    : null,
                statorTemperatureRise:
                  action.payload.designData.statorTemperatureRise ||
                  action.payload.designData.statorTemperatureRise === 0
                    ? action.payload.designData.statorTemperatureRise
                    : null,
                rotorTemperatureRise:
                  action.payload.designData.rotorTemperatureRise ||
                  action.payload.designData.rotorTemperatureRise === 0
                    ? action.payload.designData.rotorTemperatureRise
                    : null,
                exciterTemperatureRise:
                  action.payload.designData.exciterTemperatureRise ||
                  action.payload.designData.exciterTemperatureRise === 0
                    ? action.payload.designData.exciterTemperatureRise
                    : null,
                permanentMagnetGenerator:
                  action.payload.designData.permanentMagnetGenerator ||
                  action.payload.designData.permanentMagnetGenerator === 0
                    ? action.payload.designData.permanentMagnetGenerator
                    : null,
                exciterType:
                  action.payload.designData.exciterType ||
                  action.payload.designData.exciterType === 0
                    ? action.payload.designData.exciterType
                    : null,
                excitationType:
                  action.payload.designData.excitationType ||
                  action.payload.designData.excitationType === 0
                    ? action.payload.designData.excitationType
                    : null,
              }
            : {
                ...INITIAL_STATE.synchronousGeneratorConfigData.designData,
              },
        },
      };
    case Type.GET_SYNCHRONOUS_GENERATOR_CONFIG_DATA_FAILURE:
      return {
        ...state,
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_GENERATOR_CONFIG_DATA_RESET:
      return {
        ...state,
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          componentId: '',
        },
      };

    /** Add design data reducer */
    case Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_SYNCHRONOUS_GENERATOR_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    /** Add performance data */
    case Type.ADD_GENERATOR_PERFORMANCE_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          performanceData: action.payload.performanceData
            ? action.payload.performanceData
            : state.synchronousGeneratorConfigData.performanceData,
          performanceCurvesData: {
            data: action.payload.performanceCurvesData?.data
              ? action.payload.performanceCurvesData.data
              : state.synchronousGeneratorConfigData.performanceCurvesData.data,
            dataUnit: action.payload.performanceCurvesData?.dataUnit
              ? action.payload.performanceCurvesData.dataUnit
              : state.synchronousGeneratorConfigData.performanceCurvesData
                  .dataUnit,
            powerFactor: action.payload.performanceCurvesData.powerFactor
              ? action.payload.performanceCurvesData.powerFactor
              : {},
          },
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_DATA_RESET:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
        },
      };
    /** Add motor parameter data */

    case Type.ADD_GENERATOR_PARAMETER_DATA_PROGRESS:
      return {
        ...state,
        addMotorParameterData: {
          ...INITIAL_STATE.addMotorParameterData,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_PARAMETER_DATA_SUCCESS:
      return {
        ...state,
        addMotorParameterData: {
          ...state.addMotorParameterData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_GENERATOR_PARAMETER_DATA_FAILURE:
      return {
        ...state,
        addMotorParameterData: {
          ...state.addMotorParameterData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_PARAMETER_DATA_RESET:
      return {
        ...state,
        addMotorParameterData: {
          ...INITIAL_STATE.addMotorParameterData,
        },
      };

    /** Add performace curve data  */
    case Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.performanceCurvesData,
        },
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          performanceCurvesData: {
            data: action.payload.performanceCurvesData.data
              ? action.payload.performanceCurvesData.data
              : state.synchronousGeneratorConfigData.performanceCurvesData.data,
            dataUnit: action.payload.performanceCurvesData.dataUnit
              ? action.payload.performanceCurvesData.dataUnit
              : state.synchronousGeneratorConfigData.performanceCurvesData
                  .dataUnit,
            powerFactor: action.payload.performanceCurvesData.powerFactor
              ? action.payload.performanceCurvesData.powerFactor
              : {},
          },
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_FAILURE:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_PERFORMANCE_CURVES_DATA_RESET:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
        },
      };

    /** Add capability curve data  */
    case Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addCapabilityCurve: {
          ...INITIAL_STATE.addCapabilityCurve,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addCapabilityCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.performanceCurvesData,
        },
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          capabilityCurvesData: {
            data: action.payload.capabilityCurvesData.data
              ? action.payload.capabilityCurvesData.data
              : state.synchronousGeneratorConfigData.capabilityCurvesData.data,
            dataUnit: action.payload.capabilityCurvesData.dataUnit
              ? action.payload.capabilityCurvesData.dataUnit
              : state.synchronousGeneratorConfigData.capabilityCurvesData
                  .dataUnit,
            powerFactor: action.payload.capabilityCurvesData.powerFactor
              ? action.payload.capabilityCurvesData.powerFactor
              : {},
          },
        },
      };
    case Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_FAILURE:
      return {
        ...state,
        addCapabilityCurve: {
          ...state.addCapabilityCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_CAPABILITY_CURVES_DATA_RESET:
      return {
        ...state,
        addCapabilityCurve: {
          ...INITIAL_STATE.addCapabilityCurve,
        },
      };

    /** Add starting char curve */
    case Type.ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_FAILURE:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_CHARACTERISTICS_CURVES_DATA_RESET:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
        },
      };

    /** Add variable speed curve */
    case Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
          isLoading: true,
        },
      };
    case Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        synchronousGeneratorConfigData: {
          ...state.synchronousGeneratorConfigData,
          variableSpeedCurvesData: {
            data: action.payload.variableSpeedCurvesData.data
              ? action.payload.variableSpeedCurvesData.data
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .data,
            dataUnit: action.payload.variableSpeedCurvesData.dataUnit
              ? action.payload.variableSpeedCurvesData.dataUnit
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .dataUnit,
            driveType: action.payload.variableSpeedCurvesData.driveType
              ? action.payload.variableSpeedCurvesData.driveType
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .driveType,
            driveManufacturer: action.payload.variableSpeedCurvesData
              .driveManufacturer
              ? action.payload.variableSpeedCurvesData.driveManufacturer
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .driveManufacturer,
            driveModel: action.payload.variableSpeedCurvesData.driveModel
              ? action.payload.variableSpeedCurvesData.driveModel
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .driveModel,
            lineSideConverter: action.payload.variableSpeedCurvesData
              .lineSideConverter
              ? action.payload.variableSpeedCurvesData.lineSideConverter
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .lineSideConverter,
            loadSideInverter: action.payload.variableSpeedCurvesData
              .loadSideInverter
              ? action.payload.variableSpeedCurvesData.loadSideInverter
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .loadSideInverter,
            speedControl: action.payload.variableSpeedCurvesData.speedControl
              ? action.payload.variableSpeedCurvesData.speedControl
              : state.synchronousGeneratorConfigData.variableSpeedCurvesData
                  .speedControl,
          },
        },
      };
    case Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_FAILURE:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GENERATOR_VARIABLE_SPEED_CURVES_DATA_RESET:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
        },
      };

    /** Save Drive Type */
    case Type.ADD_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        addDriveTypes: {
          ...INITIAL_STATE.addDriveTypes,
          isLoading: true,
        },
      };
    case Type.ADD_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Get Drive Type */
    case Type.GET_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        getDriveType: {
          ...INITIAL_STATE.getDriveType,
          isLoading: true,
        },
        addDriveTypes: {
          ...state.addDriveTypes,
          isSuccess: false,
          isError: false,
        },
      };
    case Type.GET_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: true,
          data: action.payload.items ? action.payload.items : [],
        },
      };
    case Type.GET_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Reset synchronous motor reducer */
    case Type.RESET_GENERATOR_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
