import { Icon, Input } from "antd";
import React from "react";
import {
  validatePositiveFloat,
  validateFloat,
} from "../../../../../common/methods";
import { DesignDataVariables } from "../../../../../constants/variables";
import { newValidateNumber } from "../../../../../libs/validator";
import {
  Compressor,
  Liquid_Expander,
  Pump,
  Flow_Meter,
} from "../ConfigDataConstants";

export const dataUnitsOptions = (units) => {
  if (units) {
    return units.map((item) => <option value={item}>{item}</option>);
  }
  return null;
};

export const fluidPhaseOptions = () => {
  const options = [
    "Liquid",
    "Gas",
    "2-Phase (liquid-gas)",
    "2-Phase (solid-liquid)",
  ];
  return options.map((item) => <option value={item}>{item}</option>);
};

export const tableDataVariables = (config) => [
  {
    title: DesignDataVariables.DATA_VARIABLES,
    dataIndex: "dataVariable",
    render: (value) => (
      <span>
        {getColumnsDataVariablesMap(config.name)[value]}
        {(getColumnsDataVariablesMap(config.name)[value] ===
          "Specific Gravity" ||
          getColumnsDataVariablesMap(config.name)[value] === "Fluid Phase" ||
          getColumnsDataVariablesMap(config.name)[value] === "Standard Pressure" ||
          getColumnsDataVariablesMap(config.name)[value] === "Standard Temperature"
          ) && (
          <span style={{ color: "red" }}> *</span>
        )}
      </span>
    ),
  },
  {
    title: DesignDataVariables.UNITS,
    dataIndex: "unit",
    render: (value, row, col, disabled, item) =>
      item.dataVariable !== "specificGravity" &&
      item.dataVariable !== "fluidPhase" &&
      item.dataVariable !== "operatingSpecificGravity" ? (
          <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
            <select
              defaultValue={value}
              value={value || ""}
              disabled={disabled}
              name="unit"
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                (((item.dataVariable === 'standardTemperature' ||
                  item.dataVariable === 'standardPressure') &&
                  !value) && config.addListener) ||
                ((item.design || item.design === 0) && !value)
                  ? "error"
                  : "not-error"
              }
            >
              <option value="">Select</option>
              {dataUnitsOptions(
                config.dataUnits[
                  getDataVariableMap(config.name)[item.dataVariable]
                ]
              )}
            </select>
          </div>
        ) : (
          <div />
        ),
  },
  {
    title: DesignDataVariables.DESIGN,
    dataIndex: "design",
    render: (value, row, col, disabled, item) => {
      return item.dataVariable !== "fluidPhase" ? (
        <Input
          name="design"
          defaultValue={`${value}`}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          disabled={disabled}
          value={value}
          autoComplete="off"
          onKeyDown={(e) => newValidateNumber(e)}
          className={
            (item.unit && !value && value !== 0) ||
            (item.unit &&
              value &&
              !validatePositiveFloat(value.toString()) &&
              item.dataVariable !== "operatingTemperature" &&
              item.dataVariable !== "maxTemperature") ||
            (value &&
              !validatePositiveFloat(value.toString()) &&
              item.dataVariable !== "operatingTemperature" &&
              item.dataVariable !== "maxTemperature") ||
            (item.dataVariable === "specificGravity" &&
              (!value || (value && !validatePositiveFloat(value.toString()))) &&
              config.addListener) ||
              ((item.dataVariable === "standardTemperature"|| 
                 item.dataVariable === "standardPressure") &&
              (!value || !item.unit || (value && !validatePositiveFloat(value.toString()))) &&
              config.addListener)
              ? "error"
              : "not-error"
          }
        />
      ) : (
        <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
          <select
            defaultValue={value}
            value={value || ""}
            disabled={disabled}
            name="design"
            onChange={(e) => {
              config.change(e.target, row, item);
            }}
            className={
              (!value) &&
              config.addListener
                ? "error"
                : "not-error"
            }
          >
            <option value="">Select</option>
            {fluidPhaseOptions()}
          </select>
        </div>
      );
    },
  },
  config.isDesignEditable && {
    title: DesignDataVariables.ACTIONS,
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

export const tableInletCondition = (config) => [
  {
    title: DesignDataVariables.INLET_CONDITIONS,
    dataIndex: "inletConditions",
    render: (value) => (
      <span>{getColumnsDataVariablesMap(config.name)[value]}</span>
    ),
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => (
      <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
        <select
          defaultValue={value}
          name="unit"
          value={value || ""}
          disabled={disabled}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          className={
            (item.design || item.design === 0) && !value ? "error" : "not-error"
          }
        >
          <option value="">Select</option>
          {dataUnitsOptions(
            config.dataUnits[
              getDataVariableMap(config.name)[item.inletConditions]
            ]
          )}
        </select>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <Input
        name="design"
        defaultValue={value}
        onChange={(e) => {
          config.change(e.target, row, item);
        }}
        disabled={disabled}
        value={value}
        autoComplete="off"
        className={
          (item.unit && !value && value !== 0) ||
          item.inletConditions === "inletTemperature" ||
          item.inletConditions === "minPumpingTemperature" ||
          item.inletConditions === "normalPumpingTemperature" ||
          item.inletConditions === "maxPumpingTemperature"
            ? value && !validateFloat(value.toString())
            : value && !validatePositiveFloat(value.toString())
              ? "error"
              : "not-error"
        }
      />
    ),
  },
  config.isDesignEditable && {
    title: "",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

export const tableDischargeConditions = (config) => [
  {
    title: DesignDataVariables.DISCHARGE_CONDITIONS,
    dataIndex: "dischargeConditions",
    render: (value) => (
      <span>{getColumnsDataVariablesMap(config.name)[value]}</span>
    ),
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => (
      <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
        <select
          defaultValue={value}
          name="unit"
          value={value || ""}
          disabled={disabled}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          className={
            (item.design || item.design === 0) && !value ? "error" : "not-error"
          }
        >
          <option value="">Select</option>
          {dataUnitsOptions(
            config.dataUnits[
              getDataVariableMap(config.name)[item.dischargeConditions]
            ]
          )}
        </select>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <Input
        name="design"
        defaultValue={value}
        onChange={(e) => {
          config.change(e.target, row, item);
        }}
        disabled={disabled}
        value={value}
        autoComplete="off"
        className={
          (item.unit && !value && value !== 0) ||
          (item.dischargeConditions === "outletTemperature"
            ? value && !validateFloat(value.toString())
            : value && !validatePositiveFloat(value.toString()))
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  config.isDesignEditable && {
    title: "",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

export const tableCalculatedValue = (config) => [
  {
    title: DesignDataVariables.CALCULATED_VALUES,
    dataIndex: "calculatedValues",
    render: (value) => (
      <span>
        {getColumnsDataVariablesMap(config.name)[value]}
        {getColumnsDataVariablesMap(config.name)[value] === "n Constant" && (
          <span style={{ color: "red" }}> *</span>
        )}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) =>
      item.calculatedValues !== "nConstant" &&
      item.dataVariable !== "fluidPhase" ? (
          <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
            <select
              defaultValue={value}
              name="unit"
              value={value || ""}
              disabled={disabled}
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                (item.design || item.design === 0) && !value
                  ? "error"
                  : "not-error"
              }
            >
              <option value="">Select</option>
              {dataUnitsOptions(
                config.dataUnits[
                  getDataVariableMap(config.name)[item.calculatedValues]
                ]
              )}
            </select>
          </div>
        ) : (
          <div />
        ),
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <Input
        name="design"
        defaultValue={value}
        onChange={(e) => {
          config.change(e.target, row, item);
        }}
        disabled={disabled}
        value={value}
        autoComplete="off"
        className={
          (item.unit && !value && value !== 0) ||
          (value &&
            (item.calculatedValues === "differentialHead" ||
            item.calculatedValues === "differentialPressure"
              ? !validateFloat(value.toString())
              : !validatePositiveFloat(value.toString()))) ||
          (item.calculatedValues === "nConstant" &&
            (!value || (value && !validatePositiveFloat(value.toString()))) &&
            config.addListener)
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  config.isDesignEditable && {
    title: "",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

export const tablePerformanceValues = (config) => [
  {
    title: DesignDataVariables.PERFORMACE_VALUES,
    dataIndex: "performanceValues",
    render: (value) => (
      <span>{getColumnsDataVariablesMap(config.name)[value]}</span>
    ),
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => (
      <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
        <select
          defaultValue={value}
          name="unit"
          value={value || ""}
          disabled={disabled}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          className={
            (item.design || item.design === 0) && !value ? "error" : "not-error"
          }
        >
          <option value="">Select</option>
          {dataUnitsOptions(
            config.dataUnits[
              getDataVariableMap(config.name)[item.performanceValues]
            ]
          )}
        </select>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <Input
        name="design"
        defaultValue={value}
        onChange={(e) => {
          config.change(e.target, row, item);
        }}
        disabled={disabled}
        value={value}
        autoComplete="off"
        className={
          (item.unit && !value && value !== 0) ||
          (value && !validatePositiveFloat(value.toString()))
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  config.isDesignEditable && {
    title: "",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];
export const tableOtherDesignData = (config) => [
  {
    title: DesignDataVariables.OTHER_DESIGN_DATA,
    dataIndex: "otherDesignData",
    render: (value) => (
      <span>{getColumnsDataVariablesMap(config.name)[value]}</span>
    ),
  },
  {
    title: "",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => (
      <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
        <select
          defaultValue={value}
          name="unit"
          value={value || ""}
          disabled={disabled}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          className={
            (item.design || item.design === 0) && !value ? "error" : "not-error"
          }
        >
          <option value="">Select</option>
          {dataUnitsOptions(
            config.dataUnits[
              getDataVariableMap(config.name)[item.otherDesignData]
            ]
          )}
        </select>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "design",
    render: (value, row, col, disabled, item) => (
      <Input
        name="design"
        defaultValue={value}
        onChange={(e) => {
          config.change(e.target, row, item);
        }}
        disabled={disabled}
        value={value}
        autoComplete="off"
        className={
          (item.unit && !value && value !== 0) ||
          (value && !validatePositiveFloat(value.toString()))
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  config.isDesignEditable && {
    title: "",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

export const getData = (name) => {
  if (name === Liquid_Expander) {
    return {
      dischargeConditions: {
        outletPressure: {
          unit: "",
          design: "",
        },
      },
      dataVariable: {
        specificGravity: {
          design: "",
        },
        speed: {
          unit: "",
          design: "",
        },
        massFlowRate: {
          unit: "",
          design: "",
        },
        vaporPressure: {
          unit: "",
          design: "",
        },
        dynamicViscosity: {
          unit: "",
          design: "",
        },
      },
      inletConditions: {
        inletTemperature: {
          unit: "",
          design: "",
        },
        inletPressure: {
          unit: "",
          design: "",
        },
        actualInletVolumetricFlow: {
          unit: "",
          design: "",
        },
      },
      calculatedValues: {
        differentialPressure: {
          unit: "",
          design: "",
        },
        differentialHead: {
          unit: "",
          design: "",
        },
        efficiency: {
          unit: "",
          design: "",
        },
        outputPower: {
          unit: "",
          design: "",
        },
      },
    };
  }
  if (name === Compressor) {
    return {
      dischargeConditions: {
        outletPressure: {
          unit: "",
          design: "",
        },
        outletTemperature: {
          unit: "",
          design: "",
        },
      },
      dataVariable: {
        speed: {
          unit: "",
          design: "",
        },
        massFlowRate: {
          unit: "",
          design: "",
        },
      },
      inletConditions: {
        inletTemperature: {
          unit: "",
          design: "",
        },
        inletPressure: {
          unit: "",
          design: "",
        },
        actualInletVolumetricFlow: {
          unit: "",
          design: "",
        },
      },
      calculatedValues: {
        nConstant: {
          unit: "",
          design: "",
        },
        stagePower: {
          unit: "",
          design: "",
        },
        trainPower: {
          unit: "",
          design: "",
        },
        polytropicHead: {
          unit: "",
          design: "",
        },
        polytropicEfficiency: {
          unit: "",
          design: "",
        },
      },
    };
  }
  if (name === Pump) {
    return {
      dischargeConditions: {
        outletPressure: {
          unit: "",
          design: "",
        },
      },
      dataVariable: {
        specificGravity: {
          design: "",
        },
        inletNozzleSize: {
          unit: "",
          design: "",
        },
        outletNozzleSize: {
          unit: "",
          design: "",
        },
        normalCapacity: {
          unit: "",
          design: "",
        },
        ratedCapacity: {
          unit: "",
          design: "",
        },
        vaporPressure: {
          unit: "",
          design: "",
        },
        dynamicViscosity: {
          unit: "",
          design: "",
        },
        npsha: {
          unit: "",
          design: "",
        },
      },
      inletConditions: {
        maxInletPressure: {
          unit: "",
          design: "",
        },
        ratedInletPressure: {
          unit: "",
          design: "",
        },
        minPumpingTemperature: {
          unit: "",
          design: "",
        },
        normalPumpingTemperature: {
          unit: "",
          design: "",
        },
        maxPumpingTemperature: {
          unit: "",
          design: "",
        },
      },
      performanceValues: {
        differentialPressure: {
          unit: "",
          design: "",
        },
        differentialHead: {
          unit: "",
          design: "",
        },
        npshMarginRatedCapacity: {
          unit: "",
          design: "",
        },
        npshrRatedCapacity: {
          unit: "",
          design: "",
        },
        efficiency: {
          unit: "",
          design: "",
        },
        ratedPower: {
          unit: "",
          design: "",
        },
      },
      otherDesignData: {
        minThermalContinuousFlow: {
          unit: "",
          design: "",
        },
        minStableContinuousFlow: {
          unit: "",
          design: "",
        },
        minPreferredOperatingFlow: {
          unit: "",
          design: "",
        },
        maxPreferredOperatingFlow: {
          unit: "",
          design: "",
        },
        minAllowableOperatingFlow: {
          unit: "",
          design: "",
        },
        maxAllowableOperatingFlow: {
          unit: "",
          design: "",
        },
        maxHeadAtRatedImpeller: {
          unit: "",
          design: "",
        },
        maxPowerAtRatedImpeller: {
          unit: "",
          design: "",
        },
      },
    };
  }
};

export const getdataVariable = (name) => {
  if (name === Liquid_Expander) {
    return [
      "Outlet Pressure",
      "Specific Gravity",
      "Speed",
      "Mass Flow Rate",
      "Vapor Pressure",
      "Dynamic Viscosity",
      "Inlet Temperature",
      "Inlet Pressure",
      "Volumetric Flow",
      "Differential Pressure",
      "Differential Head",
      "Efficiency",
      "Output Power",
    ];
  }
  if (name === Compressor) {
    return [
      "Outlet Pressure",
      "Outlet Temperature",
      "Speed",
      "Mass Flow Rate",
      "Inlet Temperature",
      "Inlet Pressure",
      "Volumetric Flow",
      "n Constant",
      "Stage Power",
      "Train Power",
      "Polytropic Head",
      "Polytropic Efficiency",
    ];
  }
  if (name === Pump) {
    return [
      "Outlet Pressure",
      "Inlet Nozzle Size",
      "Outlet Nozzle Size",
      "Normal Capacity",
      "Rated Capacity",
      "Specific Gravity",
      "Vapor Pressure",
      "Dynamic Viscosity",
      "NPSHA",
      "Maximum Inlet Pressure",
      "Rated Inlet Pressure",
      "Minimum Pumping Temperature",
      "Normal Pumping Temperature",
      "Maximum Pumping Temperature",
      "Differential Pressure",
      "Differential Head",
      "NPSH Margin at Rated Capacity",
      "NPSHR at Rated Capacity",
      "Efficiency",
      "Rated Power",
      "Minimum Thermal Continuous Flow",
      "Minimum Stable Continuous Flow",
      "Minimum Preferred Operating Flow",
      "Maximum Preferred Operating Flow",
      "Minimum Allowable Operating Flow",
      "Maximum Allowable Operating Flow",
      "Maximum Head at Rated Impeller",
      "Maximum Power at Rated Impeller",
    ];
  }
  if (name === Flow_Meter) {
    return [
      "Minimum Flow",
      "Maximum Flow",
      "Normal Flow",
      "Operating Pressure",
      "Maximum Pressure",
      "Operating Temperature",
      "Maximum Temperature",
      "Maximum Allowable Pressure Drop",
      "Differential Pressure at Max Flow",
      "Flowrate Range",
      "Accuracy",
      "Standard Pressure",
      "Standard Temperature",
    ];
  }
};

export const getDataVariableMap = (name) => {
  if (name === Liquid_Expander) {
    return {
      outletPressure: "Outlet Pressure",
      specificGravity: "Specific Gravity",
      speed: "Speed",
      massFlowRate: "Mass Flow Rate",
      vaporPressure: "Vapor Pressure",
      dynamicViscosity: "Dynamic Viscosity",
      inletTemperature: "Inlet Temperature",
      inletPressure: "Inlet Pressure",
      actualInletVolumetricFlow: "Volumetric Flow",
      differentialPressure: "Differential Pressure",
      differentialHead: "Differential Head",
      efficiency: "Efficiency",
      outputPower: "Output Power",
    };
  }
  if (name === Compressor) {
    return {
      outletPressure: "Outlet Pressure",
      outletTemperature: "Outlet Temperature",
      speed: "Speed",
      massFlowRate: "Mass Flow Rate",
      inletTemperature: "Inlet Temperature",
      inletPressure: "Inlet Pressure",
      actualInletVolumetricFlow: "Volumetric Flow",
      nConstant: "n Constant",
      stagePower: "Stage Power",
      trainPower: "Train Power",
      polytropicHead: "Polytropic Head",
      polytropicEfficiency: "Polytropic Efficiency",
    };
  }
  if (name === Pump) {
    return {
      outletPressure: "Outlet Pressure",
      inletNozzleSize: "Inlet Nozzle Size",
      outletNozzleSize: "Outlet Nozzle Size",
      normalCapacity: "Normal Capacity",
      ratedCapacity: "Rated Capacity",
      specificGravity: "Specific Gravity",
      vaporPressure: "Vapor Pressure",
      dynamicViscosity: "Dynamic Viscosity",
      npsha: "NPSHA",
      maxInletPressure: "Maximum Inlet Pressure",
      ratedInletPressure: "Rated Inlet Pressure",
      minPumpingTemperature: "Minimum Pumping Temperature",
      normalPumpingTemperature: "Normal Pumping Temperature",
      maxPumpingTemperature: "Maximum Pumping Temperature",
      differentialPressure: "Differential Pressure",
      differentialHead: "Differential Head",
      npshMarginRatedCapacity: "NPSH Margin at Rated Capacity",
      npshrRatedCapacity: "NPSHR at Rated Capacity",
      efficiency: "Efficiency",
      ratedPower: "Rated Power",
      minThermalContinuousFlow: "Minimum Thermal Continuous Flow",
      minStableContinuousFlow: "Minimum Stable Continuous Flow",
      minPreferredOperatingFlow: "Minimum Preferred Operating Flow",
      maxPreferredOperatingFlow: "Maximum Preferred Operating Flow",
      minAllowableOperatingFlow: "Minimum Allowable Operating Flow",
      maxAllowableOperatingFlow: "Maximum Allowable Operating Flow",
      maxHeadAtRatedImpeller: "Maximum Head at Rated Impeller",
      maxPowerAtRatedImpeller: "Maximum Power at Rated Impeller",
    };
  }
  if (name === Flow_Meter) {
    return {
      fluidPhase: "Fluid Phase",
      minFlow: "Minimum Flow",
      maxFlow: "Maximum Flow",
      normalFlow: "Normal Flow",
      operatingPressure: "Operating Pressure",
      maxPressure: "Maximum Pressure",
      operatingTemperature: "Operating Temperature",
      maxTemperature: "Maximum Temperature",
      maxAllowablePressureDrop: "Maximum Allowable Pressure Drop",
      operatingSpecificGravity: "Operating Specific Gravity",
      differentialPressureAtMaxFlow: "Differential Pressure at Max Flow",
      flowrateRange: "Flowrate Range",
      accuracy: "Accuracy",
      standardPressure: "Standard Pressure",
      standardTemperature: "Standard Temperature",
    };
  }
};

export const getColumnsDataVariablesMap = (name) => {
  if (name === Liquid_Expander) {
    return {
      outletPressure: "Outlet Pressure",
      specificGravity: "Specific Gravity",
      speed: "Speed",
      massFlowRate: "Mass Flow Rate",
      vaporPressure: "Vapor Pressure",
      dynamicViscosity: "Dynamic Viscosity",
      inletTemperature: "Inlet Temperature",
      inletPressure: "Inlet Pressure",
      actualInletVolumetricFlow: "Actual Inlet Volumetric Flow",
      differentialPressure: "Differential Pressure",
      differentialHead: "Differential Head",
      efficiency: "Efficiency",
      outputPower: "Output Power",
    };
  }
  if (name === Compressor) {
    return {
      outletPressure: "Outlet Pressure",
      outletTemperature: "Outlet Temperature",
      speed: "Speed",
      massFlowRate: "Mass Flow Rate",
      inletTemperature: "Inlet Temperature",
      inletPressure: "Inlet Pressure",
      actualInletVolumetricFlow: "Actual Inlet Volumetric Flow",
      nConstant: "n Constant",
      stagePower: "Stage Power",
      trainPower: "Train Power",
      polytropicHead: "Polytropic Head",
      polytropicEfficiency: "Polytropic Efficiency",
    };
  }
  if (name === Pump) {
    return {
      outletPressure: "Outlet Pressure",
      inletNozzleSize: "Inlet Nozzle Size",
      outletNozzleSize: "Outlet Nozzle Size",
      normalCapacity: "Normal Capacity",
      ratedCapacity: "Rated Capacity",
      specificGravity: "Specific Gravity",
      vaporPressure: "Vapor Pressure",
      dynamicViscosity: "Dynamic Viscosity",
      npsha: "NPSHA",
      maxInletPressure: "Maximum Inlet Pressure",
      ratedInletPressure: "Rated Inlet Pressure",
      minPumpingTemperature: "Minimum Pumping Temperature",
      normalPumpingTemperature: "Normal Pumping Temperature",
      maxPumpingTemperature: "Maximum Pumping Temperature",
      differentialPressure: "Differential Pressure",
      differentialHead: "Differential Head",
      npshMarginRatedCapacity: "NPSH Margin at Rated Capacity",
      npshrRatedCapacity: "NPSHR at Rated Capacity",
      efficiency: "Efficiency",
      ratedPower: "Rated Power",
      minThermalContinuousFlow: "Minimum Thermal Continuous Flow",
      minStableContinuousFlow: "Minimum Stable Continuous Flow",
      minPreferredOperatingFlow: "Minimum Preferred Operating Flow",
      maxPreferredOperatingFlow: "Maximum Preferred Operating Flow",
      minAllowableOperatingFlow: "Minimum Allowable Operating Flow",
      maxAllowableOperatingFlow: "Maximum Allowable Operating Flow",
      maxHeadAtRatedImpeller: "Maximum Head at Rated Impeller",
      maxPowerAtRatedImpeller: "Maximum Power at Rated Impeller",
    };
  }
  if (name === Flow_Meter) {
    return {
      fluidPhase: "Fluid Phase",
      minFlow: "Minimum Flow",
      maxFlow: "Maximum Flow",
      normalFlow: "Normal Flow",
      operatingPressure: "Operating Pressure",
      maxPressure: "Maximum Pressure",
      operatingTemperature: "Operating Temperature",
      maxTemperature: "Maximum Temperature",
      maxAllowablePressureDrop: "Maximum Allowable Pressure Drop",
      operatingSpecificGravity: "Operating Specific Gravity",
      differentialPressureAtMaxFlow: "Differential Pressure at Max Flow",
      flowrateRange: "Flowrate Range",
      accuracy: "Accuracy",
      standardPressure: "Standard Pressure",
      standardTemperature: "Standard Temperature",
    };
  }
};
