import { Icon, Input } from "antd";
import React from "react";
import { validateNumberNegative } from "../../../../../../libs/validator";
import { PerformaceCurvesVariables } from "./../../../../../../constants/variables";

export const tableHeader = tableHeader => {
  return [
    {
      title: PerformaceCurvesVariables.load,
      dataIndex: "load",
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.load && item.load !== " ") ||
          (item.efficiency && item.efficiency !== " ") ||
          (item.current && item.current !== " ") ||
          (item.powerFactor && item.powerFactor !== " ") ||
          item.load === 0 ||
          item.efficiency === 0 ||
          item.current === 0 ||
          item.powerFactor === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="load"
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                className={tempError ? "not-error" : "error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.efficiency,
      dataIndex: "efficiency",
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.load && item.load !== " ") ||
          (item.efficiency && item.efficiency !== " ") ||
          (item.current && item.current !== " ") ||
          (item.powerFactor && item.powerFactor !== " ") ||
          item.load === 0 ||
          item.efficiency === 0 ||
          item.current === 0 ||
          item.powerFactor === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="efficiency"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? "not-error" : "error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.powerFactor,
      dataIndex: "powerFactor",
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.load && item.load !== " ") ||
          (item.efficiency && item.efficiency !== " ") ||
          (item.current && item.current !== " ") ||
          (item.powerFactor && item.powerFactor !== " ") ||
          item.load === 0 ||
          item.efficiency === 0 ||
          item.current === 0 ||
          item.powerFactor === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="powerFactor"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? "not-error" : "error"}
              />
            )}
          </div>
        );
      },
    },
    {
      title: PerformaceCurvesVariables.current,
      dataIndex: "current",
      render: (value, row, col, disabled, item) => {
        const tempError =
          (item.load && item.load !== " ") ||
          (item.efficiency && item.efficiency !== " ") ||
          (item.current && item.current !== " ") ||
          (item.powerFactor && item.powerFactor !== " ") ||
          item.load === 0 ||
          item.efficiency === 0 ||
          item.current === 0 ||
          item.powerFactor === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="current"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? "not-error" : "error"}
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: PerformaceCurvesVariables.actions,
      dataIndex: "actions",
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = input => {
  if (input.length !== 15) {
    const tempError =
      (input.load && input.load !== " ") ||
      input.load === 0 ||
      (input.efficiency && input.efficiency !== " ") ||
      input.efficiency === 0 ||
      (input.powerFactor && input.powerFactor !== " ") ||
      input.powerFactor === 0 ||
      (input.current && input.current !== " ") ||
      input.current === 0 ||
      !input.eventListener;
    return [
      {
        render: () => {
          return (
            <div>
              <Input
                id="load"
                autoComplete="off"
                type="number"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                value={input.load}
                onChange={value => input.handleChangeTableData(value)}
                className={tempError ? "not-error" : "error"}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <div>
              <Input
                id="efficiency"
                autoComplete="off"
                type="number"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                value={input.efficiency}
                onChange={value => input.handleChangeTableData(value)}
                className={tempError ? "not-error" : "error"}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="powerFactor"
              autoComplete="off"
              type="number"
              onKeyDown={e => {
                validateNumberNegative(e);
              }}
              value={input.powerFactor}
              onChange={value => input.handleChangeTableData(value)}
              className={tempError ? "not-error" : "error"}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="current"
              autoComplete="off"
              type="number"
              onKeyDown={e => {
                validateNumberNegative(e);
              }}
              value={input.current}
              onChange={value => input.handleChangeTableData(value)}
              className={tempError ? "not-error" : "error"}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = input => {
  return [
    {
      render: () => {
        const tempError = !input.loadUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              value={!input.loadUnit ? "" : input.loadUnit}
              id="loadUnit"
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Load") {
                  return getUnits.units.map(dispUnit => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.efficiencyUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="efficiencyUnit"
              value={!input.efficiencyUnit ? "" : input.efficiencyUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Load") {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.powerFactorUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="powerFactorUnit"
              value={!input.powerFactorUnit ? "" : input.powerFactorUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable === "Performance curve - Power Factor"
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.currentUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select ${tempError && "error"}`}>
            <select
              id="currentUnit"
              value={!input.currentUnit ? "" : input.currentUnit}
              onChange={value => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === "Performance curve - Current") {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};

export const curveInput = (data, xAxisValue, yAxisValue, config) => {
  return [
    {
      label: "X Axis",
      key: "xAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: xAxisValue,
      style: { width: "250px", padding: "10px" },
      change: (e) => { config.changeX(e); },
    },
    {
      label: "Y Axis",
      key: "yAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: yAxisValue,
      style: { width: "250px", padding: "10px" },
      change: (e) => { config.changeY(e); },
    },
  ];
};

export const graphDetails = {
  title: "Curve Plots",
  requiredMessage: "Mininum three data is required",
  efficiency: "Efficiency",
  powerFactor: "Power Factor",
  load: "Load",
  current: "Current"
};

export const curveVariables = {
  "Load": "load",
  "Efficiency": "efficiency",
  "Power Factor": "powerFactor",
  "Current": "current",
};
