import { connect } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { calculatedEquations, CalculatedTagsDemoTable, initialData } from "./designDataBlankComponent.Constants";
import CustomTable from "../../../../../../common/CustomTable/CustomTable";
import {
  DesignDataShow,
  ExpressionDemoTable,
  arithmeticTable,
} from "./designDataBlankComponent.Constants";
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  MotorDesignDataMessages,
  MANAGE_TAGS_MESSAGE,
} from "../../../../../../constants/messages";
import ConfirmModal from "../../../../../../common/Modal/Modal";
import {
  addEditDesign,
  clearDesignState,
} from "../../../../../../redux/actions/design.action";
import Notification, {
  Types,
} from "../../../../../../common/Notification/Notification";

const BlankComponentDesignData = (props) => {
  const {
    designDataObject,
    isSchematicEditable,
    location,
    network,
    addEditDesign,
    isDesignAddSuccess,
    designData,
    clearDesignState,
    isDesignAddError,
    designErrorMessage,
    isEdit
  } = props;
  const [formEdit, setFormEdit] = useState(false);
  const [saveListener, setSaveListener] = useState(false);
  const [displayData, setDisplayData] = useState(initialData);
  const [editTableRows, setEditableRows] = useState([]);
  const [copyDisplayData, setCopyDisplayData] = useState(initialData);
  const [visible, setVisible] = useState(false);

  const setData = useCallback(() => {
    const tempDisplayData = [
      {
        name: "expression",
        value: designDataObject ? designDataObject.expression : "",
        comment: designDataObject ? designDataObject.comment : "",
      },
      {
        name: "expression 2",
        value: designDataObject ? designDataObject.expression2 : "",
        comment: designDataObject ? designDataObject.comment2 : "",
      },
    ];
    setDisplayData(tempDisplayData);
    setCopyDisplayData(tempDisplayData);
  }, [designDataObject, isEdit]);  // eslint-disable-line

  const disableEditForm = () => {
    window.dispatchEvent(new Event("form-edit-false"));
  };

  const enableEditForm = () => {
    window.dispatchEvent(new Event("form-edit"));
  };

  // acts as componentDidMount
  useEffect(() => {
    setData();
    window.addEventListener("form-edit", () => {
      setFormEdit(true);
    });
    window.addEventListener("form-edit-false", (e) => {
      setFormEdit(false);
      setSaveListener(false);
      setEditableRows([]);
    });
  }, [setData]);

  //upon adding data
  useEffect(() => {
    if (isDesignAddSuccess && designData) {
      clearDesignState();
      setSaveListener(false);
      disableEditForm();
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
      const data = designData.designData;
      const tempDisplayData = [
        {
          name: "expression",
          value: data ? data.expression : "",
          comment: data ? data.comment : "",
        },
        {
          name: "expression 2",
          value: data ? data.expression2 : "",
          comment: data ? data.comment2 : "",
        },
      ];
      setDisplayData(tempDisplayData);
      //setCopyDisplayData(tempDisplayData);
    }
  }, [isDesignAddSuccess, designData, clearDesignState]);

  //upon Error on saving data
  useEffect(() => {
    if (isDesignAddError) {
      clearDesignState();
      Notification.show(Types.Error, designErrorMessage);
    }
  }, [isDesignAddError, designErrorMessage, clearDesignState]);

  const handleChange = (val, name, type, commentName) => {
    let tempDisplayData = [];
    if (name === "expression") {
      tempDisplayData = [{ name, value: val, comment: displayData[0] && displayData[0].comment ? displayData[0].comment : "" }];
      tempDisplayData[1] = displayData[1]
    }
    else if (name === "expression 2") {
      tempDisplayData[0] = displayData[0]
      tempDisplayData[1] = { name, value: val, comment: displayData[1] && displayData[1].comment ? displayData[1].comment : "" }
    }
    else if (commentName === "expression") {
      tempDisplayData = [...displayData];
      tempDisplayData[0].comment = val;
    }
    else if (commentName === "expression 2") {
      tempDisplayData = [...displayData];
      tempDisplayData[1].comment = val;
    }
    setDisplayData(tempDisplayData);
  };

  const onEdit = (row) => {
    let editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    setEditableRows(editTable);
    enableEditForm();
  };

  const onUndo = (row) => {
    if (row === 0) {
      const tempDisplayData = [
        {
          name: "expression",
          value: designDataObject ? designDataObject.expression : "",
          comment: designDataObject ? designDataObject.comment : "",
        },
        {
          name: "expression 2",
          value: displayData[1] ? displayData[1].value : "",
          comment: displayData[1] ? displayData[1].comment : "",
        },
      ];
      setDisplayData(tempDisplayData);
      console.log(copyDisplayData)
    }
    else if (row === 1) {
      const tempDisplayData = [
        {
          name: "expression",
          value: displayData[0] ? displayData[0].value : "",
          comment: displayData[0] ? displayData[0].comment : "",
        },
        {
          name: "expression 2",
          value: designDataObject ? designDataObject.expression2 : "",
          comment: designDataObject ? designDataObject.comment2 : "",
        },
      ];
      setDisplayData(tempDisplayData);
    }
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    // const tempDisplayData = _.cloneDeep(displayData);
    // tempDisplayData[row] = _.cloneDeep(displayData[row]);
    setEditableRows(editTable);
    enableEditForm();
  };

  const handleOk = () => {
    const tempDisplayData = [
      {
        name: "expression",
        value: designDataObject ? designDataObject.expression : "",
        comment: designDataObject ? designDataObject.comment : "",
      },
      {
        name: "expression 2",
        value: designDataObject ? designDataObject.expression2 : "",
        comment: designDataObject ? designDataObject.comment2 : "",
      },
    ];
    setDisplayData(tempDisplayData);
    setVisible(false);
    disableEditForm();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  };

  const onReset = () => {
    setVisible(true);
  };

  const handleNetwork = () => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  };

  const onSave = () => {
    setSaveListener(true);
    const componentId = location ? location.state.componentData.info._id : "";
    const type = location ? location.state.componentData.assetInfo.name : "";
    let payload = [];
    displayData.forEach((item) => {
      if (item.name === "expression") {
        let data = { "expression": item.value, comment: item.comment }
        payload.push(data)
      }
      else if (item.name === "expression 2") {
        let data = { "expression2": item.value, comment: item.comment }
        payload.push(data)
      }
      // let expressionName = item.keys[0]
      // payload[item["name"]] = item["value"];
      // payload["comment"] = item["comment"];
    });
    if (!payload[0]["expression"] || (payload[0]["expression"]).match(/^ *$/) !== null) {
      Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.FIELDS_DIRTY);
    } else {
      if (componentId && handleNetwork()) {
        addEditDesign(payload, componentId, type);
      }
    }
  };

  return (
    <div className="DesignDataSynchronousMotor">
      <CustomTable
        showHeader={true}
        columns={DesignDataShow({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
        })}
        data={displayData}
        editableRow={editTableRows}
      />
      <div>
        <br></br>
      </div>
      <CustomTable
        showHeader={true}
        columns={ExpressionDemoTable({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
        })}
        data={arithmeticTable}
        editableRow={editTableRows}
      />
      <br />
      <CustomTable
        showHeader={true}
        columns={CalculatedTagsDemoTable({
          handleChange: handleChange,
          onEdit: onEdit,
          onUndo: onUndo,
          saveListener,
          isSchematicEditable,
        })}
        data={calculatedEquations}
        editableRow={editTableRows}
      />
      {formEdit && (
        <div className="config-bottom-buttons">
          <button className="btn-default btn-white" onClick={onReset}>
            {"RESET"}
          </button>
          <button className="btn-default" onClick={onSave}>
            {"SAVE"}
          </button>
        </div>
      )}
      {renderModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    designData: state.DesignReducer.apiState.designData,
    isDesignAddSuccess: state.DesignReducer.apiState.isDesignAddSuccess,
    isDesignAddError: state.DesignReducer.apiState.isDesignError,
    designErrorMessage: state.DesignReducer.apiState.message,
    isEdit: state.formEditReducer.isEdit
  };
};

const mapDispatchToProps = {
  clearDesignState,
  addEditDesign,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlankComponentDesignData);
