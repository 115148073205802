export default {
  MATERIAL_ENVIRON_FETCH_SUCCESS: 'MATERIAL_ENVIRON_FETCH_SUCCESS',
  MATERIAL_ENVIRON_FETCH_ERROR: 'MATERIAL_ENVIRON_FETCH_ERROR',
  MATERIAL_ENVIRON_FETCH_PROGRESS: 'MATERIAL_ENVIRON_FETCH_PROGRESS',
  MATERIAL_ENVIRON_FETCH_RESET: 'MATERIAL_ENVIRON_FETCH_RESET',

  MATERIAL_ENVIRON_UPDATE_SUCCESS: 'MATERIAL_ENVIRON_UPDATE_SUCCESS',
  MATERIAL_ENVIRON_UPDATE_ERROR: 'MATERIAL_ENVIRON_UPDATE_ERROR',
  MATERIAL_ENVIRON_UPDATE_PROGRESS: 'MATERIAL_ENVIRON_UPDATE_PROGRESS',
  MATERIAL_ENVIRON_UPDATE_RESET : 'MATERIAL_ENVIRON_UPDATE_RESET',

  MATERIAL_ENVIRON_REDUCER_RESET: 'MATERIAL_ENVIRON_REDUCER_RESET',
};
