import * as AssetTypes from "../Types/Alerts.types";

const INITIAL_STATE = {
  isErrorList: false,
  isSuccess: false,
  isCloseSuccess: false,
  isAssocSuccess: false,
  isDisassocSuccess: false,
  isRequest: false,
  isCloseRequest: false,
  isAssocRequest:false,
  isDisassocRequest:false,
  errorIds: [],
  successIds: [],
  isError: false,
  isReload: false,
  isDiscard: false,
};

export default function AlertActionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AssetTypes.ALERT_DISCARD_SUCCESS:
      return {
        ...state,
        isRequest: false,
        errorIds: action.payload.errorIds,
        successIds: action.payload.successIds,
        isSuccess: true,
      };
    case AssetTypes.ALERT_DISCARD_REQUEST:
      return {
        ...state,
        isRequest: true,
      };
    case AssetTypes.ALERT_DISCARD_ERROR:
      if (action.payload) {
        return {
          ...state,
          isRequest: false,
          isError: true,
          successIds: [],
          isSuccess: false,
        };
      }
      return state;
    case AssetTypes.ALERT_DISCARD_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    case AssetTypes.ALERT_CLOSE_SUCCESS:
      return {
        ...state,
        isCloseRequest: false,
        errorIds: action.payload.errorIds,
        successIds: action.payload.successIds,
        isCloseSuccess: true,
      };
    case AssetTypes.ALERT_CLOSE_REQUEST:
      return {
        ...state,
        isCloseRequest: true,
      };
    case AssetTypes.ALERT_CLOSE_ERROR:
      if (action.payload) {
        return {
          ...state,
          isCloseRequest: false,
          isError: true,
          successIds: [],
          isCloseSuccess: false,
        };
      }
      return state;
    case AssetTypes.ALERT_CLOSE_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    case AssetTypes.ALERT_ASSOC_CLEAR_STATE:
    case AssetTypes.ALERT_DISASSOC_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    case AssetTypes.ALERT_ASSOC_ERROR:
      if (action.payload) {
        return {
          ...state,
          isAssocRequest: false,
          isError: true,
          successIds: [],
          isAssocSuccess: false,
        };
      }
      return state;
    case AssetTypes.ALERT_ASSOC_REQUEST:
      return {
        ...state,
        isAssocRequest: true,
      };
    case AssetTypes.ALERT_ASSOC_SUCCESS:
      return {
        ...state,
        isAssocRequest: false,
        errorIds: action.payload.errorIds,
        successIds: action.payload.successIds,
        isAssocSuccess: true,
      };
    case AssetTypes.ALERT_DISASSOC_ERROR:
      if (action.payload) {
        return {
          ...state,
          isDisassocRequest: false,
          isError: true,
          successIds: [],
          isDisassocSuccess: false,
        };
      }
      return state;
    case AssetTypes.ALERT_DISASSOC_REQUEST:
      return {
        ...state,
        isDisassocRequest: true,
      };
    case AssetTypes.ALERT_DISASSOC_SUCCESS:
      return {
        ...state,
        isDisassocRequest: false,
        errorIds: action.payload.errorIds,
        successIds: action.payload.successIds,
        isDisassocSuccess: true,
      };
    
    default:
      return state;
  }
}
