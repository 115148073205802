export const CLIENTS_FETCH_SUCCESS = "CLIENTS_FETCH_SUCCESS";
export const CLIENTS_FETCH_ERROR = "CLIENTS_FETCH_ERROR";
export const CLIENTS_FETCH_PROGRESS = "CLIENTS_FETCH_PROGRESS";

export const CLIENTS_DETAILS_FETCH_SUCCESS = "CLIENTS_DETAILS_FETCH_SUCCESS";
export const CLIENTS_DETAILS_FETCH_ERROR = "CLIENTS_DETAILS_FETCH_ERROR";
export const CLIENTS_DETAILS_FETCH_PROGRESS = "CLIENTS_DETAILS_FETCH_PROGRESS";

export const ADD_CLIENTS_FETCH_SUCCESS = "ADD_CLIENTS_FETCH_SUCCESS";
export const ADD_CLIENTS_FETCH_PROGRESS = "ADD_CLIENTS_FETCH_PROGRESS";
export const ADD_CLIENTS_FETCH_ERROR = "ADD_CLIENTS_FETCH_ERROR";

export const STATE_FETCH_SUCCESS = "STATE_FETCH_SUCCESS";
export const STATE_FETCH_PROGRESS = "STATE_FETCH_PROGRESS";
export const STATE_FETCH_ERROR = "STATE_FETCH_ERROR";
export const CLIENTS_INFO_FETCH_SUCCESS = "CLIENTS_INFO_FETCH_SUCCESS";
export const CLIENTS_INFO_FETCH_ERROR = "CLIENTS_INFO_FETCH_ERROR";
export const CLIENTS_INFO_FETCH_PROGRESS = "CLIENTS_INFO_FETCH_PROGRESS";

export const EDIT_CLIENTS_FETCH_PROGRESS = "EDIT_CLIENTS_FETCH_PROGRESS";
export const EDIT_CLIENTS_FETCH_SUCCESS = "EDIT_CLIENTS_FETCH_SUCCESS";
export const EDIT_CLIENTS_FETCH_ERROR = "EDIT_CLIENTS_FETCH_ERROR";

export const CLIENTS_CLEAR_STATE = "CLIENTS_CLEAR_STATE";

export const RESET_CLIENT_REDUCER = "RESET_CLIENT_REDUCER";
