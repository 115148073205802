import { AddUserMessages } from "../../../../../constants/messages";

const formField = ({ reqd,isEventAssocAction, eventName,nameSuggestions } ) => [
  {
    label: isEventAssocAction? "Event Name":"Comments",
    key: "comment",
    value: eventName,
    max: isEventAssocAction?"100":"4000",
    required: [reqd, AddUserMessages.REQUIRED_FIELD],
    type: (nameSuggestions && nameSuggestions.size >=1) ? "textareaWithSugg": "textarea",
    suggestions :nameSuggestions
  },
];
export default formField;
