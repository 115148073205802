import './iow.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  piTagListFetch,
  piTagUpdate,
  piTagUpdateClearReducer,
  piTagListClear,
  piTagListUpdate
} from '../../../../redux/actions/piTagUpload.action';
import {
  iowDataListFetch,
  iowTagListClear,
  iowTagUpdate,
  iowTagUpdateClearReducer,
  iowTagListUpdate
} from '../../../../redux/actions/iowTags.action'
import { Pagination, Form } from 'antd';
import { IOW_FILTER_HEADING ,IOW_STATUS, MANAGE_PI_TAGS_CONSTANTS } from '../../../../constants/variables';
import { Layout, Menu, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import Search from 'antd/lib/input/Search';
import TagDetails from './tagDetails';
import { useSelector } from 'react-redux';
import piTagsDummy from './Dummy/IOW_dummyData';
import HeaderNavBar from '../../../../common/HeaderNav/HeaderNav';
import { populateInitialValues } from '../../../../common/methods';

const { Sider } = Layout;
const PAGINATED_VALUE = 20;
const INITIAL_SKIP = 0;
const IS_FILTER_OPEN = -1;

const Iow = (props) => {
  const piData = useSelector((state) => state.iowTagsReducer.iowTagList.data);
  const data = useSelector((state) => state.iowTagsReducer);
  const tourState = data.iowTour;
  const {
    info,
    activeKey,
    iowDataListFetch,
    iowTagListSuccess,
    piTagUpdate,
    iowTagUpdate,
    piTagUpdateClearReducer,
    piTagUpdateSuccess,
    piTagUpdateData,
    iowTagUpdateClearReducer,
    iowTagUpdateSuccess,
    iowTagUpdateData,
    totalCount,
    piTagListError,
    piTagListErrorMessage,
    piTagListClear,
    iowTagListClear,
    piTagListLoading,
    iowTagListUpdate
  } = props;

  const [piTagData, setPiTagData] = useState([]);
  const [limit] = useState(PAGINATED_VALUE);
  const [piTagList, setPiTagList] = useState([]);
  const [selectedKey, setSelectedKey] = useState(['0']);
  const [tagInfo, setTagInfo] = useState(null);
  const [totalTagCount, setTotalTagCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoaded, setIsLoaded] = useState(true);
  const [expanded,setExpanded]=useState(IS_FILTER_OPEN);
  const [hasFilterActive,sethasFilterActive] = useState(false);
  const [filterData,setFilterData]=useState('');
  const [ statusFilterState,setStatusFilterState] = useState({ ...populateInitialValues(IOW_STATUS) });
  //useEffect for tour page
  useEffect(() => {
    if (tourState.startTour) {
      setPiTagList(piTagsDummy);
      setPiTagData(piTagsDummy);
      setTagInfo(piTagsDummy[0]);
      setSelectedKey(['0']);
      setTotalTagCount(1);
      setIsLoaded(false);
    } 
    }, [tourState, piTagData]);

  const fetchTagsInfo = useCallback(() => {
    if (info && info.dataRef && activeKey === '8') {
      const presentLevelId = info && info['dataRef']['_id'];
      const presentLevelName = info && info['type'];
      const payload = {
        limit: PAGINATED_VALUE,
        skip: INITIAL_SKIP,
        allType: true,
        levelName: presentLevelName,
        levelId: presentLevelId,     
      };
      sethasFilterActive(false);
      setStatusFilterState({ ...populateInitialValues(IOW_STATUS) });
      setExpanded(IS_FILTER_OPEN);
      setPageNumber(1);
      iowDataListFetch(payload)
    }
  }, [info, activeKey, iowDataListFetch]);

  useEffect(() => {
    fetchTagsInfo();
  }, [fetchTagsInfo]);

  useEffect(() => {
    if (!tourState.startTour || iowTagListSuccess) {
      setPiTagList(piData);
      setPiTagData(piData);
      setTagInfo(piData[0]);
      setSelectedKey(['0']);
      setTotalTagCount(totalCount);
      iowTagListClear();
    }
  }, [piTagData, totalCount, 
      iowTagListClear, tourState, piData, iowTagListSuccess]);

  useEffect(() => {
    if (piTagListError) {
      piTagListClear();
      iowTagListClear();
      Notification.show(Types.Error, piTagListErrorMessage);
      setPiTagList([]);
      setTagInfo(null);
      setSelectedKey(null);
      setTotalTagCount(0);
    }
  }, [piTagListError, piTagListClear, piTagListErrorMessage
      ,iowTagListClear]);

  useEffect(() => {
    if (piTagUpdateSuccess || iowTagUpdateSuccess) {
  piTagUpdateClearReducer();
   iowTagUpdateClearReducer();
      const tempPiTagList = piTagList.map((item) => {
        let updatedTagData = piTagUpdateData
          ? piTagUpdateData
          : iowTagUpdateData
          ? iowTagUpdateData
          : [];
        if (item.piTag === updatedTagData.piTag) {
          item['criticalLimitLow'] = updatedTagData['criticalLimitLow'];
          item['standardLimitLow'] = updatedTagData['standardLimitLow'];
          item['criticalLimitHigh'] = updatedTagData['criticalLimitHigh'];
          item['standardLimitHigh'] = updatedTagData['standardLimitHigh'];
          item['optimalTarget'] = updatedTagData['optimalTarget'];
          item['enableIOW'] = updatedTagData['enableIOW'];
          item['referenceStartDate'] = updatedTagData['referenceStartDate'];
          item['runStatusCheckIow'] = updatedTagData['runStatusCheckIow'];
          if (iowTagUpdateData) {
            item['optimalTimeRange'] = updatedTagData['optimalTimeRange'];
            item['criticalLimitLowTime'] =
              updatedTagData['criticalLimitLowTime'];
            item['criticalLimitHighTime'] =
              updatedTagData['criticalLimitHighTime'];
            item['standardLimitLowTime'] =
              updatedTagData['standardLimitLowTime'];
            item['standardLimitHighTime'] =
              updatedTagData['standardLimitHighTime'];
          }
        }
        return item;
      });
      setPiTagList(tempPiTagList);
      Notification.show(Types.Success, 'Updated Successfully!');
    }
  }, [piTagUpdateSuccess, piTagUpdateData, piTagList, piTagUpdateClearReducer, 
      iowTagUpdateSuccess, iowTagUpdateClearReducer, iowTagUpdateData]);

  useEffect(() => {
    setSearchKeyword('');
    setFilterData('');
  }, [info]);

  const renderTagsData = (
    payload,
    facilityId,
    payloadTimers = {},
    changedInLimits = true,
    changedInTimers = false
  ) => {
    if (changedInTimers) {
      const presentLevelName = info && info['type'];
      const presentLevelId = info && info['dataRef']['_id'];
      payloadTimers['levelName'] = presentLevelName;
      payloadTimers['levelId'] = presentLevelId;
      iowTagUpdate(payloadTimers);
    }
    changedInLimits && piTagUpdate(payload, facilityId);
  };
  
  const fetchTagList = (payload) => {
    const presentLevelId = info && info['dataRef']['_id'];
    const presentLevelName = info && info['type'];
    payload['levelName'] = presentLevelName;
    payload['levelId'] = presentLevelId;
    iowDataListFetch(payload)
  };

  const onSearch = (value) => {
    setSearchKeyword(value);
    const payload = {
      limit: limit,
      skip: INITIAL_SKIP,
      allType: true,
      keyword: value,
      filter:filterData
    };
    setPageNumber(1);
    fetchTagList(payload);
  };

  const handlePageClick = (pageNumber) => {
    const skipTags = (pageNumber - 1) * 20;
    setPageNumber(pageNumber);
    const payload = {
      limit: limit,
      skip: pageNumber === 1 ? 0 : skipTags,
      allType: true,
      filter:filterData,
    };
    if(searchKeyword) { payload['keyword'] = searchKeyword }
    fetchTagList(payload);  
    if(filterData) {payload['filter'] = filterData}
    fetchTagList(payload);  
  };

  const paginateData = () => {
    return (
      <div className="paginateAlign">
        <Pagination
          defaultCurrent={1}
          total={totalTagCount}
          onChange={handlePageClick}
          current={pageNumber}
          pageSize={20}
        />
      </div>
    );
  };

  const applyFilter = () => {
    const obj = statusFilterState;
    const selectedStatus = Object.keys(obj).find((keys) => obj[keys]);
    sethasFilterActive(!!selectedStatus);
     const payload = {
       limit: limit,
       skip: INITIAL_SKIP,
       allType: true,
       keyword: searchKeyword,
       filter:filterData 
     };
     fetchTagList(payload);
     setPageNumber(1)
    } 

  const resetFilter = () => {
    setStatusFilterState({...populateInitialValues(IOW_STATUS)})
    sethasFilterActive(false)
     setFilterData('');
    const payload = {
      limit: limit,
      skip: INITIAL_SKIP,
      allType: true,
      keyword: searchKeyword,
      filter:null
    };
    fetchTagList(payload);
    setPageNumber(1)
  }

  
const setExpandedIndex = (index) =>{
  (expanded === index)?setExpanded(IS_FILTER_OPEN):setExpanded(index);  
}

const toggleFilter = (filterHeadName,value) =>{
  const filters ={...populateInitialValues(IOW_STATUS)};
  filters[value] = !setStatusFilterState[value];
  setStatusFilterState(filters);  
  setFilterData(value === "ON");
}

  const change = (value) => {
    setSearchKeyword(value);
  }

  return (
    <div className="Trends">
      <Layout className="Trends">
        <Sider
          style={{
            overflow: 'auto',
            height: '70em',
            left: 0,
            background: 'white',
          }}
          className="menuStyle trends-container trend-sider"
          width="50%"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Search className='Search'
            placeholder="Search Here"
            onSearch={(value) => {
              onSearch(value);
            }}
            onChange={(e) => {
              change(e.target.value);
            }}
            value={searchKeyword}
            allowClear={true}
            style={{ padding: '5px' }}
            loading={piTagListLoading}
          />
          <HeaderNavBar
          filterValue={IOW_STATUS}
          headLabels={IOW_FILTER_HEADING }
          value={filterData}
          childLabels = {[statusFilterState]}
          isSearchBar={false}
          isSort={false}
          onApply={applyFilter}
          onReset={resetFilter}
          onHeadPress={setExpandedIndex}
          onChildPress={toggleFilter}
          expandedIndex={expanded}
          isExpandableFilter
          hasFilterActive={hasFilterActive}     
          />  
          </div>       
           <Menu mode="vertical" selectedKeys={selectedKey}>
            <Menu.Item
              // key={index}
              icon={<UserOutlined />}
              // className={num}
              style={{ height: '50px' }}
            >
              <div className="runs-menu">
                <Row>
                  <Col span={4} className="alert-align">
                    <div className="text">
                      {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_TAG}
                    </div>
                  </Col>
                  <Col span={12} className="alert-align">
                    <div className="text-two">
                      {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_NAME}
                    </div>
                  </Col>
                  <Col span={4} className="alert-align">
                    <div className="text">{MANAGE_PI_TAGS_CONSTANTS.UNIT}</div>
                  </Col>
                  <Col span={4} className="alert-align">
                    <div className="text">
                      <b>{MANAGE_PI_TAGS_CONSTANTS.IOW_STATUS}</b>
                    </div>
                  </Col>
                </Row>
              </div>
            </Menu.Item>
            {piTagData?.length &&
              piTagData.map((piTagData, index) => {
                let num;
                if (index % 2 === 0) {
                  num = 'menu-color0';
                } else {
                  num = 'menu-color1';
                }
                return (
                  <Menu.Item
                    key={index}
                    icon={<UserOutlined />}
                    className={num}
                    onClick={() => {
                      setTagInfo(piTagData);
                      setSelectedKey([index.toString()]);
                    }}
                    style={{ height: '50px' }}
                  >
                    <div className="runs-menu">
                      <Row>
                        <Col span={4} className="alert-align">
                          <div className="text">{piTagData.piTag}</div>
                        </Col>
                        <Col span={12} className="alert-align">
                          <div className="text">{piTagData.piVariableName}</div>
                        </Col>
                        <Col span={4} className="alert-align">
                          <div className="text">{piTagData.mechademyUnits}</div>
                        </Col>
                        <Col span={4} className="alert-align">
                          <div className="text">
                            {piTagData.enableIOW ? 'ON': 'OFF'}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Menu.Item>
                );
              })}
          </Menu> 
          {paginateData()}
        </Sider>
        {TagDetails(tagInfo, props.form, renderTagsData, isLoaded, filterData, iowTagListUpdate)}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.LoginReducer.apiState.userRole,
    piTagListSuccess: state.piTagReducer.piTagList.isSuccess,
    piTagListLoading: state.piTagReducer.piTagList.isLoading,
    piTagListError: state.piTagReducer.piTagList.isError,
    piTagListErrorMessage: state.piTagReducer.piTagList.apiMessage,
    clientData: state.ClientReducer.apiState.clientInfoData,
    systemData: state.ClientReducer.apiState.clientInfoData.systems,
    piTagUpdateSuccess: state.piTagReducer.piTagUpdate.isSuccess,
    piTagUpdateData: state.piTagReducer.piTagUpdate.data,
    iowTagUpdateSuccess: state.iowTagsReducer.iowTagUpdate.isSuccess,
    iowTagUpdateData: state.iowTagsReducer.iowTagUpdate.data,
    totalCount: state.iowTagsReducer.iowTagList.total_count,
    iowTagListSuccess: state.iowTagsReducer.iowTagList.isSuccess,
    iowTagListLoading: state.iowTagsReducer.iowTagList.isLoading,
    iowTagListError: state.iowTagsReducer.iowTagList.isError,
    iowTagListErrorMessage: state.iowTagsReducer.iowTagList.apiMessage,
  };
};

const mapDispatchToProps = {
  piTagListFetch,
  iowDataListFetch,
  piTagUpdate,
  iowTagUpdate,
  piTagUpdateClearReducer,
  iowTagUpdateClearReducer,
  piTagListClear,
  iowTagListClear,
  piTagListUpdate,
  iowTagListUpdate
};

Iow.propTypes = {
  info: PropType.object,
  userRole: PropType.string,
  activeKey: PropType.string,
  piTagList: PropType.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'IowForm' })(Iow));
