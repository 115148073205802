import _ from "lodash";
import Type from "../Types/mlAlgorithm.types";

const createMlModal = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: "",
  message: "",
  data: null,
};

const getMlModalList = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
  errorCode: "",
  data: null,
  hasNext: false,
  hasPrev: false,
  totalCount: null,
  currentPage: 1,
  globalModelSwitch: false
};

const deployMlModal = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: "",
  message: "",
  data: null,
};

const updateMlModal = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: "",
  message: "",
  data: null,
};

const piTagListAll = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  totalCount: 0,
  errorCode: "",
  message: "",
  data: [],
  hasNext: false,
  hasPrev: false,
};

const modelVersions = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: {},
  algoList: [],
  errorCode: "",
  message: "",
};

const deployMlModelBulk = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: '',
  message: '',
  data: null,
};


const INITIAL_STATE = {
  createMlModal: { ...createMlModal },
  getMlModalList: { ...getMlModalList },
  deployMlModal: { ...deployMlModal },
  updateMlModal: { ...updateMlModal },
  piTagListAll: { ...piTagListAll },
  modelVersions: _.cloneDeep(modelVersions),
  deployMlModelBulk: {...deployMlModelBulk}
};

export default function MlAlgorithmReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.CREATE_ML_MODEL_PROGRESS:
      return {
        ...state,
        createMlModal: {
          ...INITIAL_STATE.createMlModal,
          isLoading: true,
        },
      };
    case Type.CREATE_ML_MODEL_SUCCESS:
      return {
        ...state,
        createMlModal: {
          ...state.createMlModal,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Type.CREATE_ML_MODEL_FAILURE:
      return {
        ...state,
        createMlModal: {
          ...state.createMlModal,
          isLoading: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
      /** ML MODAL LIST */

    case Type.GET_ML_MODEL_PROGRESS:
      return {
        ...state,
        getMlModalList: {
          ...state.getMlModalList,
          isLoading: true,
        },
      };

    case Type.GET_ML_MODEL_SUCCESS:
      const mlModelArray = action.payload ? action.payload.items ? action.payload.items : [] : [];
      let mlSwitch = false;
      mlModelArray.forEach((item) => {
        if(item.isDeployed) { mlSwitch = true; }
      })
      return {
        ...state,
        getMlModalList: {
          ...state.getMlModalList,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.payload.items,
          hasNext: action.payload.hasNext,
          hasPrev: action.payload.hasPrev,
          totalCount: action.payload.total_count,
          globalModelSwitch: mlSwitch
        },
      };
    case Type.GET_ML_MODEL_RESET:
      return {
        ...state,
        getMlModalList: {
          ...state.getMlModalList,
          isLoading: false,
          isSuccess: false,
        },
      };
    case Type.GET_ML_MODEL_FAILURE:
      return {
        ...state,
        getMlModalList: {
          ...state.getMlModalList,
          isLoading: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Pi Tag list all(input and output) */
    case Type.GET_ALL_PI_TAG_PROGRESS:
      return {
        ...state,
        piTagListAll: {
          ...state.piTagListAll,
          isLoading: true,
        },
      };
    case Type.GET_ALL_PI_TAG_SUCCESS:
      return {
        ...state,
        piTagListAll: {
          ...state.piTagListAll,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: [...state.piTagListAll.data, ...action.payload.items],
          hasNext: action.payload.hasNext,
          hasPrev: action.payload.hasPrev,
          totalCount: action.payload.total_count,
        },
      };
    case Type.GET_ALL_PI_TAG_FAILURE:
      return {
        ...state,
        piTagListAll: {
          ...state.piTagListAll,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_ALL_PI_TAG_RESET:
      return {
        ...state,
        piTagListAll: {
          ...INITIAL_STATE.piTagListAll,
        },
      };

    /** Deploy ml reducer */
    case Type.DEPLOY_ML_MODEL_PROGRESS:
      return {
        ...state,
        deployMlModal: {
          ...INITIAL_STATE.deployMlModal,
          isLoading: true,
        },
      };
    case Type.DEPLOY_ML_MODEL_SUCCESS:
      return {
        ...state,
        deployMlModal: {
          ...state.deployMlModal,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.DEPLOY_ML_MODEL_FAILURE:
      return {
        ...state,
        deployMlModal: {
          ...state.deployMlModal,
          isLoading: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Update reducer */
    case Type.UPDATE_ML_MODEL_PROGRESS:
      return {
        ...state,
        updateMlModal: {
          ...INITIAL_STATE.updateMlModal,
          isLoading: true,
        },
      };
    case Type.UPDATE_ML_MODEL_SUCCESS:
      return {
        ...state,
        updateMlModal: {
          ...state.updateMlModal,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Type.UPDATE_ML_MODEL_FAILURE:
      return {
        ...state,
        updateMlModal: {
          ...state.updateMlModal,
          isLoading: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Ml Model Version */
    case Type.GET_ML_VERSION_PROGRESS:
      return {
        ...state,
        modelVersions: {
          ...INITIAL_STATE.modelVersions,
          isLoading: true,
        },
      };

    case Type.GET_ML_VERSION_SUCCESS:
      const versionArray = {};
      const algoArray = [];
      action.payload.forEach((versions) => {
        versionArray[versions.algo] = versions.algo && versions.version;
        algoArray.push(versions.algo);
      });
      return {
        ...state,
        modelVersions: {
          ...state.modelVersions,
          isLoading: false,
          data: versionArray,
          algoList: algoArray,
        },
      };
    case Type.GET_ML_VERSION_ERROR:
      return {
        ...state,
        modelVersions: {
          ...state.modelVersions,
          isLoading: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

      /** Deploy BULK ml reducer */
      case Type.DEPLOY_ML_MODEL_BULK_PROGRESS:
        return {
          ...state,
          deployMlModelBulk: {
            ...INITIAL_STATE.deployMlModelBulk,
            isLoading: true,
          },
        };
      case Type.DEPLOY_ML_MODEL_BULK_SUCCESS:
        return {
          ...state,
          deployMlModelBulk: {
            ...state.deployMlModelBulk,
            isLoading: false,
            isSuccess: true,
            data: action.payload.data,
          },
        };
      case Type.DEPLOY_ML_MODEL_BULK_FAILURE:
        return {
          ...state,
          deployMlModelBulk: {
            ...state.deployMlModelBulk,
            isLoading: false,
            isError: true,
            errorCode:
              action.payload.response && action.payload.response.body.code,
            message:
              action.payload.response && action.payload.response.body.message,
          },
        };
        case Type.DEPLOY_ML_MODEL_BULK_RESET:
          return {
            ...state,
            deployMlModelBulk: {
              ...INITIAL_STATE.deployMlModelBulk,
            },
          };

    case Type.RESET_ML_ALGORITHM_REDUCER:
      return _.cloneDeep(INITIAL_STATE);

    default:
      return {
        ...state,
      };
  }
}
