export const SET_FORGOT_PASSWORD_SUCCESS = "SET_FORGOT_PASSWORD_SUCCESS";
export const SET_FORGOT_PASSWORD_PROGRESS = "SET_FORGOT_PASSWORD_PROGRESS";
export const SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR";

export const SET_NEW_FORGOT_PASSWORD_SUCCESS =
  "SET_NEW_FORGOT_PASSWORD_SUCCESS";
export const SET_NEW_FORGOT_PASSWORD_PROGRESS =
  "SET_NEW_FORGOT_PASSWORD_PROGRESS";
export const SET_NEW_FORGOT_PASSWORD_ERROR = "SET_NEW_FORGOT_PASSWORD_ERROR";

export const FORGOT_PASSWORD_CLEAR_STATE = "FORGOT_PASSWORD_CLEAR_STATE";
