import {
  INTERNET_CONNECTED,
  INTERNET_DISCONNECTED,
} from "../Types/network.types";

export function isNeworkConnected() {
  return function (dispatch) {
    if (window.navigator.onLine) {
      dispatch({ type: INTERNET_CONNECTED });
    } else {
      dispatch({ type: INTERNET_DISCONNECTED });
    }
  };
}
