import { PUTRequest } from "../../api/request";
import {
  SURGELINE_FETCH_PROGRESS,
  SURGELINE_FETCH_SUCCESS,
  SURGELINE_FETCH_ERROR,
  SURGELINE_FETCH_CLEAR,
} from "../Types/SurgeLines.type";
import { endPoints } from "../../libs/endpoints";

const surgeLinesProcess = () => ({ type: SURGELINE_FETCH_PROGRESS });
const surgeLinesSuccess = (data) => ({
  type: SURGELINE_FETCH_SUCCESS,
  payload: data,
});
const surgeLinesError = (error) => ({
  type: SURGELINE_FETCH_ERROR,
  payload: error,
});
const clearSurgeLine = () => ({ type: SURGELINE_FETCH_CLEAR });

export function surgeLinesData(id, payload) {
  return function (dispatch) {
    dispatch(surgeLinesProcess());
    PUTRequest(`${endPoints.components}/${id}/${endPoints.surgeLines}`, payload)
      .then((response) => dispatch(surgeLinesSuccess(response.body)))
      .catch((error) => dispatch(surgeLinesError(error)));
  };
}

export function clearSurgeLineData() {
  return function (dispatch) {
    dispatch(clearSurgeLine());
  };
}
