import superagent from "superagent";
import { getToken } from "../libs/token";

export default function call({
  method = "get",
  url,
  endpoint,
  payload,
  query,
  token,
  type = "application/json",
}) {
  if (window.navigator.onLine) {
    let API_URL = process.env.REACT_APP_DEV_API;
    if (process.env.NODE_ENV === "development") {
      API_URL = process.env.REACT_APP_DEV_API;
    } else {
      API_URL = process.env.REACT_APP_PROD_API;
    }
    const _url = `${API_URL}/${endpoint}`;
    return (
      superagent(method, _url)
        /** fileCsv: In case of file upload form data defines its content-type by itself */
        .set("Content-Type", type === "fileCsv" ? null : type)
        .set("Authorization", getToken())
        .set("api_key", process.env.REACT_APP_API_KEY)
        .send(payload)
        .query(query)
    );
  }
}
