import { Icon, Input, Select, Switch } from "antd";
import React from "react";
import {
  handleSort,
  validateNegativeNumber,
  validatePositiveFloat,
} from "../../../../common/methods";
import {
  ButtonVariables,
  OutputDataVariables,
} from "../../../../constants/variables";
import { newValidateNumber } from "../../../../libs/validator";
import _ from "lodash";
const { Option } = Select;

export const outputTableColumns = (config) => [
  {
    title: OutputDataVariables.VARIABLE_TAG,
    dataIndex: "displayPiTag",
  },
  {
    title: OutputDataVariables.VARIABLE_NAME,
    dataIndex: "variableName",
  },
  {
    title: OutputDataVariables.PI_VARIABLE_NAME,
    dataIndex: "piVariableName",
    render: (value, row, _col, disabled, item) => (
      <div>
        {!disabled ? (
          <Input
            name="piVariableName"
            type="text"
            autoComplete="off"
            value={value}
            disabled={disabled}
            onChange={(e) => config.change(e.target, row)}
            className={!item.piVariableName.trim() ? "error" : "not-error"}
          />
        ) : (
          value
        )}
      </div>
    ),
  },
  {
    title: OutputDataVariables.VARIABLE_PROPERTY,
    dataIndex: "variableProperty",
  },
  {
    title: OutputDataVariables.UNITS,
    dataIndex: "mechademyUnits",
    render: (value, row, _col, disabled, item) =>
      item.variableProperty === "Dimensionless" ? (
        "No Unit"
      ) : (
        <Select
          name="mechademyUnits"
          onChange={(e) => {
            config.selectUnit(e, row);
          }}
          value={value}
          className="no-search"
          disabled={disabled}
          showArrow={false}
        >
          {handleSort(config.getVariableUnit(item.variableProperty)).map(
            (item) => (
              <Option value={item} title={item}>
                {item}
              </Option>
            )
          )}
        </Select>
      ),
  },
  {
    title: OutputDataVariables.DATATYPES,
    dataIndex: "dataType",
    render: (value, row, _col, disabled) => (
      <Select
        name="dataType"
        disabled={disabled}
        value={value}
        onChange={(e) => {
          config.selectDataType(e, row);
        }}
        showArrow={!disabled}
        className="no-search"
      >
        {dataType.map((item) => (
          <Option value={item}>{item}</Option>
        ))}
      </Select>
    ),
  },
  {
    title: OutputDataVariables.LOWER_IQR,
    dataIndex: "multiplier",
    render: (value, row, _col, disabled, item) => (
      <div>
        {item.dataType !== "String" && item.dataType !== "Boolean" ? (
          <Input
            name="multiplier"
            value={value}
            disabled={disabled}
            onKeyDown={(e) => newValidateNumber(e)}
            onChange={(e) => {
              config.change(e.target, row);
            }}
            autoComplete="off"
            className={
              (!item.isIQR && value === "") ||
              (value && validatePositiveFloat(value))
                ? "no-error"
                : (item.isIQR && value === "") ||
                  (value && !validatePositiveFloat(value))
                  ? "error"
                  : ""
            }
          />
        ) : (
          <div />
        )}
      </div>
    ),
  },
  {
    title: OutputDataVariables.UPPER_IQR,
    dataIndex: "adder",
    render: (value, row, _col, disabled, item) => (
      <div>
        {item.dataType !== "String" && item.dataType !== "Boolean" ? (
          <Input
            name="adder"
            value={value}
            disabled={disabled}
            onChange={(e) => {
              config.change(e.target, row);
            }}
            autoComplete="off"
            onKeyDown={(e) => newValidateNumber(e)}
            className={
              (item.isIQR && !value) ||
              (value && validateNegativeNumber(value.toString()))
                ? "error"
                : "no-error"
            }
          />
        ) : (
          <div />
        )}
      </div>
    ),
  },
  {
    title: "Enable UAD",
    dataIndex: "isIQR",
    render: (value, row, _col, disabled, item) => (
      <div>
        {item.dataType === "String" || item.dataType === "Boolean" ? (
          <Switch
            onChange={(e) => {
              config.change(
                { value: false, type: "checked", name: "isIQR" },
                row
              );
            }}
            checkedChildren={OutputDataVariables.ON}
            unCheckedChildren={OutputDataVariables.OFF}
            disabled
            checked={false}
          />
        ) : (
          <Switch
            onChange={(e) => {
              config.change({ value: e, type: "checked", name: "isIQR" }, row);
            }}
            checkedChildren={OutputDataVariables.ON}
            unCheckedChildren={OutputDataVariables.OFF}
            disabled={disabled}
            checked={value}
          />
        )}
      </div>
    ),
  },
  config.isOutputEditable && {
    title: OutputDataVariables.ACTIONS,
    dataIndex: "actions",
    render: (value, row, _col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.delete(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const addRowTable = (config) => [
  {
    dataIndex: "displayPiTag",
    render: (value) => (
      <Input
        name="displayPiTag"
        value={value}
        disabled
        placeholder={OutputDataVariables.VARIABLE_TAG}
      />
    ),
  },
  {
    dataIndex: "variableName",
    render: (value, _row, _col, item) => (
      <div
        className={`custom-table-Select ${
          config.error.variableName && (!value || !value.length)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          name="variableName"
          showArrow={false}
          showSearch
          onSelect={(value) => config.selectVariableName(value)}
          value={!value ? OutputDataVariables.SEARCH_HERE : value}
          placeholder={OutputDataVariables.SEARCH_HERE}
          defaultActiveFirstOption={false}
          className={
            (config.error.variableName && (!value || !value.length)) ||
            (config.addValueListener && item.piTag === null)
              ? "error"
              : "not-error"
          }
        >
          {config.variableName.map((item) => (
            <Option value={item} title={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "piVariableName",
    render: (value) => (
      <div>
        <Input
          name="piVariableName"
          autoComplete="off"
          value={value}
          onChange={(e) => config.change(e.target)}
          className={config.error.piVariableName ? "error" : "not-error"}
        />
      </div>
    ),
  },
  {
    dataIndex: "variableProperty",
    render: (value) => (
      <div
        className={`custom-table-Select ${
          config.error.variableProperty && (!value || !value.length)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          name="variableProperty"
          showArrow={false}
          onSelect={(value) => config.selectVariableProperty(value)}
          value={!value ? OutputDataVariables.SEARCH_HERE : value}
          showSearch
          className={
            config.error.variableProperty && (!value || !value.length)
              ? "error "
              : "not-error set-width"
          }
        >
          {config.variableData.map((item) => (
            <Option value={item} title={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "mechademyUnits",
    render: (value, _row, _col, item) =>
      item.variableProperty === "Dimensionless" ? (
        "No Unit"
      ) : (
        <div
          className={`custom-table-Select ${
            config.error.mechademyUnits && (!value || !value.length)
              ? "error"
              : "not-error"
          }`}
        >
          <Select
            name="mechademyUnits"
            onSelect={(e) => {
              config.selectUnit(e);
            }}
            value={value}
            showArrow={false}
            defaultActiveFirstOption={false}
            className="no-search"
          >
            {!item.variableProperty || !item.variableProperty.length ? (
              <Option value="" title={OutputDataVariables.SELECT_PROPERTY}>
                {OutputDataVariables.SELECT_PROPERTY}
              </Option>
            ) : (
              handleSort(config.units).map((item) => (
                <Option value={item} title={item}>
                  {item}
                </Option>
              ))
            )}
          </Select>
        </div>
      ),
  },
  {
    dataIndex: "dataType",
    render: (value) => (
      <div
        className={`custom-table-Select ${
          config.error.dataType && (!value || !value.length)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          name="dataType"
          value={!value ? OutputDataVariables.SELECT : value}
          onSelect={(e) => {
            config.selectDataType(e);
          }}
          className="no-search"
          onKeyDown={(e) => newValidateNumber(e)}
          placeholder={OutputDataVariables.SELECT}
        >
          {dataType.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "multiplier",
    render: (value, _row, _col, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          {item.dataType !== "String" && item.dataType !== "Boolean" ? (
            <Input
              name="multiplier"
              value={value}
              autoComplete="off"
              onChange={(e) => {
                config.change(e.target);
              }}
              onKeyDown={(e) => newValidateNumber(e)}
              placeholder={OutputDataVariables.LOWER_IQR}
              className={
                (!item.isIQR && value === "") ||
                (!item.isIQR && value === " ") ||
                (value !== "" && value !== " " && validatePositiveFloat(value))
                  ? "no-error"
                  : config.error.multiplier ||
                    (value !== "" &&
                      value !== " " &&
                      !validatePositiveFloat(value))
                    ? "error"
                    : ""
              }
            />
          ) : (
            <div />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: "adder",
    render: (value, _row, _col, item) => (
      <div>
        {item.dataType !== "String" && item.dataType !== "Boolean" ? (
          <Input
            name="adder"
            defaultValue="0"
            value={value}
            autoComplete="off"
            onChange={(e) => {
              config.change(e.target);
            }}
            onKeyDown={(e) => newValidateNumber(e)}
            placeholder={OutputDataVariables.UPPER_IQR}
            className={
              (item.isIQR && !value) ||
              (value && validateNegativeNumber(value)) ||
              config.error.adder
                ? "error"
                : "no-error"
            }
          />
        ) : (
          <div />
        )}
      </div>
    ),
  },
  {
    dataIndex: "isIQR",
    render: (value, _row, _col, item) => (
      <div>
        {item.dataType === "String" || item.dataType === "Boolean" ? (
          <Switch
            checkedChildren={OutputDataVariables.ON}
            unCheckedChildren={OutputDataVariables.OFF}
            onChange={config.setIQR}
            checked={false}
            defaultChecked={false}
            disabled
          />
        ) : (
          <Switch
            checkedChildren={OutputDataVariables.ON}
            unCheckedChildren={OutputDataVariables.OFF}
            onChange={config.setIQR}
            checked={value}
            defaultChecked={false}
          />
        )}
      </div>
    ),
  },
  {
    dataIndex: "actions",
    render: () => (
      <button
        className="btn-default btn-small"
        onClick={(e) => {
          config.submit(e.target);
        }}
      >
        {ButtonVariables.ADD}
      </button>
    ),
  },
];

export const dataType = ["Boolean", "Float", "Integer", "String"];
