import { Breadcrumb, Icon } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import ConfirmModal from "../../../../common/Modal/Modal";
import { MaskStyle } from "../../../../constants/react-style";
import * as Routes from "../../../../constants/routes";
import "./AddUserHeader.scss";

class AddUserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      route: "",
      backRedirect: false,
    };
  }

  redirectUserManagement() {
    if (this.props.formEdit) {
      this.setState({
        visible: true,
        backRedirect: true,
      });
    } else {
      this.props.history.push(Routes.userManagement);
    }
  }

  navigateToScreen(route) {
    if (this.props.formEdit) {
      this.setState({
        visible: true,
        route,
      });
    } else {
      this.props.history.push(route);
    }
  }

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
        />
      );
    }
    return null;
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState({
      visible: false,
    });
    if (!this.state.backRedirect) {
      this.props.history.push(this.state.route);
    } else {
      this.setState({
        backRedirect: false,
      });
      this.props.history.push(Routes.userManagement);
    }
  }

  renderBreadCrumb() {
    const { state } = this.props.history.location;
    const firstName = state && state.data.firstName;
    const lastName = state && state.data.lastName;

    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <span
            className="add-user-span"
            onClick={() => this.navigateToScreen(Routes.userManagement)}
          >
            User Mangement
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {firstName ? `${firstName} ${lastName}` : "Add User"}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  render() {
    const { state } = this.props.history.location;
    const firstName = state && state.data.firstName;
    const lastName = state && state.data.lastName;
    return (
      <div className="AddUserHeader" style={MaskStyle}>
        <div className="title">
          <Icon type="left" onClick={() => this.redirectUserManagement()} />{" "}
          {firstName ? `${firstName} ${lastName}` : "Add User"}
        </div>
        <div className="path">{this.renderBreadCrumb()}</div>
        {this.renderModal()}
      </div>
    );
  }
}
export default withRouter(AddUserHeader);
