import {
  FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS,
  FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS,
  FETCH_ALERT_KPIS_BY_LEVEL_ERROR,
  FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE,
  ADD_ALERT_KPIS_PROGRESS,
  ADD_ALERT_KPIS_SUCCESS,
  ADD_ALERT_KPIS_ERROR,
  FETCH_ALERT_PLOT_DATA_PROGRESS,
  FETCH_ALERT_PLOT_DATA_SUCCESS,
  FETCH_ALERT_PLOT_DATA_ERROR,
  ADD_ALERT_KPIS_CLEAR_STATE,
  FETCH_ALERT_PLOT_DATA_CLEAR_STATE,
  UPDATE_ALERT_KPI_PROGRESS,
  UPDATE_ALERT_KPI_SUCCESS,
  UPDATE_ALERT_KPI_ERROR,
  UPDATE_ALERT_KPI_CLEAR_STATE,
  SET_TIME_RANGE_VISIBILITY_ON,
  SET_TIME_RANGE_VISIBILITY_OFF,
  UPDATE_ALERT_KPI_BULK_PROGRESS,
  UPDATE_ALERT_KPI_BULK_SUCCESS,
  UPDATE_ALERT_KPI_BULK_ERROR,
  BULK_UPDATE_ALERT_KPI_CLEAR_STATE,
  DELETE_ALERT_KPI_PROGRESS,
  DELETE_ALERT_KPI_SUCCESS,
  DELETE_ALERT_KPI_ERROR,
  DELETE_ALERT_KPI_CLEAR_STATE,
  SET_ZOOM_IN,
  SET_ZOOM_OUT,
  SET_META_DATA_ON,
  SET_META_DATA_OFF,
  CLEAR_DATA_UPDATE_STATE,
} from '../Types/alertPlots.types';
import {
  addDataIDB,
  getDataIDB,
  destructurefn,
  updateDataIDB,
} from '../../services/indexedDB';
import { dbDeleteHandling } from '../../libs/token';
import cardTypeMapper from '../../constants/cardTypes';
import { GETRequest, POSTRequest, PUTRequest, DeleteRequest } from '../../api/request';

export function getAlertPlotByLevelId(levelName, levelId, alertId) {
  return function (dispatch) {
    dispatch({ type: FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS });
    GETRequest(`kpis/${levelName}/${levelId}?type=alertPlot&alertId=${alertId}`)
      .then((res) => {
        // append ids into IDB
        // also check weather id exist in IDB
        addDataIDB(res.body.data);
        return dispatch({
          type: FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) =>
        dispatch({ type: FETCH_ALERT_KPIS_BY_LEVEL_ERROR, payload: err })
      );
  };
}

export const addAlertPlot = (payload, levelName, levelId) => (dispatch) => {
  dispatch({ type: ADD_ALERT_KPIS_PROGRESS });
  POSTRequest(`kpis/${levelName}/${levelId}`, payload)
    .then((res) => {
      /** append ids into IDB */
      if (res.body.cardType.cardType === cardTypeMapper['Alert Plot']) {
        addDataIDB([res.body]);
      }
      return dispatch({
        type: ADD_ALERT_KPIS_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_ALERT_KPIS_ERROR,
        payload: err,
      })
    );
};

export function getPlotDataByKPIId(kpiId, dateFrom, dateTill, refreshZoom=false) {
  let idbData = {};
  const viewDateFrom = dateFrom;
  const viewDateTill = dateTill;
  return async function (dispatch) {
    dispatch({ type: FETCH_ALERT_PLOT_DATA_PROGRESS, payload: kpiId });

    try {
      idbData = await getDataIDB(kpiId);
    } catch (e) {}
    /** if idbData data present */
    if (
      idbData &&
      idbData.response &&
      idbData.response.series &&
      idbData.response.startTimestamp &&
      false
    ) {
      // todo compare with all base interval time
    } else {
      // IF DATA NOT PRESENT

      GETRequest(`plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}`)
        .then((res) => {
          const data = idbData
            ? destructurefn(
                JSON.parse(res.text),
                idbData,
                15000,
                false,
                false,
                true,
                dateFrom,
                dateTill
              )
            : res.text;
          idbData &&
            updateDataIDB({
              _id: kpiId,
              piTags: idbData.piTags,
              response: data,
              trends: true,
            });
          dispatch({
            type: FETCH_ALERT_PLOT_DATA_SUCCESS,
            payload: {
              kpiId,
              response: data,
              isTimeSeries: true,
              viewDateFrom,
              viewDateTill,
              refreshZoom
            },
          });
        })
        .catch((err) => {
          if (
            err.message ===
            "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
          ) {
            dbDeleteHandling();
          } else {
            dispatch({
              type: FETCH_ALERT_PLOT_DATA_ERROR,
              payload: { kpiId, err },
            });
          }
        });
    }
  };
}

export const onUpdateAlertKpi = (kpiId, payload, needUpdate=true) => (dispatch) => {
  dispatch({ type: UPDATE_ALERT_KPI_PROGRESS });
  PUTRequest(`kpisdetails/${kpiId}`, payload)
    .then((res) =>  {
      /** append ids into IDB, also check weather id exists in IDB */
      addDataIDB([res.body]);
      dispatch({
        type: UPDATE_ALERT_KPI_SUCCESS,
        payload: res.body,
        needUpdate: needUpdate,
      });
    }
    )
    .catch((err) =>
      dispatch({
        type: UPDATE_ALERT_KPI_ERROR,
        payload: err,
      })
    );
};

export const bulkUpdateAlertKpi = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_ALERT_KPI_BULK_PROGRESS });
  PUTRequest(`bulk/kpisdetails`, payload)
    .then((res) =>
      dispatch({
        type: UPDATE_ALERT_KPI_BULK_SUCCESS,
        payload: res.body,
        updateBody: payload,
      })
    )
    .catch((err) =>
      dispatch({
        type: UPDATE_ALERT_KPI_BULK_ERROR,
        payload: err,
      })
    );
};

export const setTimeRangeVisibility = (value) => (dispatch) => {
  const dispatchType = value
    ? SET_TIME_RANGE_VISIBILITY_ON
    : SET_TIME_RANGE_VISIBILITY_OFF;
  dispatch({ type: dispatchType });
};

export const deleteAlertKpiById = (kpiId) => (dispatch) => {
  dispatch({ type: DELETE_ALERT_KPI_PROGRESS });
  DeleteRequest(`kpisdetails/${kpiId}`)
    .then((res) =>
      dispatch({
        type: DELETE_ALERT_KPI_SUCCESS,
        payload: { kpiId, status: res.body },
      })
    )
    .catch((err) =>
      dispatch({
        type: DELETE_ALERT_KPI_ERROR,
        payload: { kpiId, err },
      })
    );
};

export const setZoom = (kpiId, value, dateFrom = null, dateTill = null) => (
  dispatch
) => {
  const dispatchType = value ? SET_ZOOM_IN : SET_ZOOM_OUT;
  dispatch({
    type: dispatchType,
    payload: { kpiId: kpiId, dateFrom: dateFrom, dateTill: dateTill },
  });
};

export const setMetaData = (kpiId, value) => (dispatch) => {
  const dispatchType = value ? SET_META_DATA_ON : SET_META_DATA_OFF;
  dispatch({
    type: dispatchType,
    payload: kpiId
  });
};

export const addAlertKpiClearState = () => (dispatch) => {
  dispatch({ type: ADD_ALERT_KPIS_CLEAR_STATE });
};

export const clearDataFetchState = () => (dispatch) => {
  dispatch({ type: FETCH_ALERT_PLOT_DATA_CLEAR_STATE });
};

export const fetchAlertKPIClearState = () => (dispatch) => {
  dispatch({ type: FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE });
};

export const updateAlertKPIClearState = () => (dispatch) => {
  dispatch({ type: UPDATE_ALERT_KPI_CLEAR_STATE });
};

export const bulkUpdateAlertKPIClearState = () => (dispatch) => {
  dispatch({ type: BULK_UPDATE_ALERT_KPI_CLEAR_STATE });
};

export const deleteAlertKPIclearState = () => (dispatch) => {
  dispatch({ type: DELETE_ALERT_KPI_CLEAR_STATE });
}

export const clearDataUpdateState = () => (dispatch) => {
  dispatch({ type: CLEAR_DATA_UPDATE_STATE });
}

