/* eslint-disable */
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GraphEditor from '../../../common/CustomMxGraph/GraphEditorComponent/GraphEditor';
import Loader from '../../../common/Loader/Loader';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  AssetMessages,
  NO_INTERNET,
  SchematicMessages,
} from '../../../constants/messages';
import { MaskStyle } from '../../../constants/react-style';
import * as Routes from '../../../constants/routes';
import { SchematicVariables } from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { ElementPermissions } from '../../../permissions';
import { clearAssetState, getAsset } from '../../../redux/actions/asset.action';
import {
  clearEquipmentState,
  getEquipment,
} from '../../../redux/actions/equipment.action';
import {
  clearSchematicState,
  createSchematic,
  editSchematic,
  getSchematic,
  resetUnDeploySchematic,
  unDeploySchematic,
  validateSchematic,
} from '../../../redux/actions/schematic.action';
import './ComponentSchematic.scss';
import SchematicDeployError from './SchematicDeployError/SchematicDeployError';

class ComponentSchematic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graph: null,
      graphData: null,
      network: window.navigator.onLine,
      objects: null,
      isGraphChanged: false,
      formEdit: false,
      demandPage: window.location.pathname,
      demandPageData: {},
      message: '',
      validateSchematicInfo: null,
      validGraph: false,
    };
  }

  componentDidMount() {
    const { getAsset } = this.props;

    if (this.handleNetwork()) {
      getAsset({ keyword: null });
      this.getEquipment();
    }

    window.addEventListener('form-edit', () => {
      if (!this.state.formDisable) {
        this.setState({
          formEdit: true,
        });
      }
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate(prevProps) {
    const { isSuccessGetEquipment } = this.props.equipmentState.apiState;
    const { isSuccess, data, isError } = this.props.assetState.apiState;
    const assetErrorCode =
      this.props.assetState.apiState &&
      this.props.assetState.apiState.errorCode;
    const {
      clearAssetState,
      clearSchematicState,
      clearEquipmentState,
    } = this.props;
    const {
      isSuccessCreateSchematicFetch,
      isSuccessGetSchematicFetch,
      isSuccessEditSchematicFetch,
      isSuccessValidateSchematicFetch,
      isErrorSchematic,
      schematicData,
      validateSchematicData,
      errorCode,
      isSuccessunDeploy,
      isErrorUnDeploy,
      msgUnDeploy,
    } = this.props.schematicState.apiState;

    if (
      prevProps.location.state.equipmentData &&
      prevProps.location.state.equipmentData._id !==
        this.props.location.state.equipmentData._id
    ) {
      if (this.handleNetwork()) {
        this.props.getAsset({ keyword: null });
        this.getEquipment();
      }
    }
    if (isSuccess) {
      clearAssetState();
      this.setState({
        objects: data ? data.items : null,
      });
    }

    if (isSuccessGetEquipment) {
      clearEquipmentState();
      this.getSchematic();
    }

    if (isSuccessCreateSchematicFetch) {
      Notification.show(Types.Success, SchematicMessages.ADDED);
      clearSchematicState();
      this.setState({
        graph: schematicData,
        isGraphChanged: true,
      });
    }

    if (isSuccessValidateSchematicFetch) {
      clearSchematicState();
      this.setState(
        {
          validateSchematicInfo: validateSchematicData,
        },
        () => {
          if (validateSchematicData.isValidgraph && validateSchematicData.isLockAvailable) {
            Notification.show(Types.Success, SchematicMessages.DEPLOYED);
            this.setState(
              {
                validGraph: validateSchematicData.isValidgraph,
              },
              () => {
                this.getSchematic();
              }
            );
          } 
          else if (validateSchematicData.isValidgraph && !validateSchematicData.isLockAvailable)
          {
            Notification.show(Types.Error, "Cannot deploy. File is in processing.");
          }
          else {
            this.setState({
              validGraph: validateSchematicData.isValidgraph,
            });
            if (_.isEmpty(validateSchematicData.configErrorInfo)) {
              Notification.show(Types.Error, SchematicMessages.INVALID_GRAPH);
            }
          }
        }
      );
    }

    if (isSuccessGetSchematicFetch) {
      clearSchematicState();
      this.setState({
        graph: schematicData,
        isGraphChanged: true,
        validateSchematicInfo: null,
      });
    }

    if (isSuccessEditSchematicFetch) {
      Notification.show(Types.Success, SchematicMessages.UPDATED);
      clearSchematicState();
      this.setState({
        graph: schematicData,
        isGraphChanged: true,
      });
    }

    if (isError) {
      clearAssetState();
      Notification.show(Types.Error, AssetMessages[assetErrorCode]);
    }

    if (isErrorSchematic) {
      clearSchematicState();
      Notification.show(Types.Error, SchematicMessages[errorCode]);
    }

    if (
      isSuccessunDeploy &&
      isSuccessunDeploy !== prevProps.schematicState.apiState.isSuccessunDeploy
    ) {
      this.getSchematic();
      Notification.show(Types.Success, SchematicMessages.UN_DEPLOYED_SUCCESS);
    } else if (
      isErrorUnDeploy &&
      isErrorUnDeploy !== prevProps.schematicState.apiState.isErrorUnDeploy
    ) {
      clearEquipmentState();
      this.getSchematic();
      Notification.show(Types.Error, msgUnDeploy);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getEquipment() {
    const { getEquipment } = this.props;
    const {
      location: { state },
    } = this.props;

    if (state && state.equipmentData) {
      if (this.handleNetwork()) {
        getEquipment(state.equipmentData._id);
      }
    }
  }

  getSchematic() {
    const {
      equipmentState: { apiState: equipmentData },
    } = this.props;
    const { getSchematic } = this.props;
    if (equipmentData && equipmentData.equipmentData) {
      if (this.handleNetwork() && equipmentData.equipmentData.schematic) {
        getSchematic({ equipmentId: equipmentData.equipmentData._id });
      }
    }
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  renderBreadCrumb() {
    const {
      clientData,
      facilityData,
      systemData,
      equipmentData,
    } = this.props.location.state;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => this.navigateToScreen(Routes.clients)}>
            {SchematicVariables.CLIENTS}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData,
                mode: 'view',
              })
            }
          >
            {clientData.name}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData,
                facilityData,
                mode: 'view',
              })
            }
          >
            {facilityData.name}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            onClick={() =>
              this.navigateToScreen(Routes.clientSystem, {
                clientData,
                facilityData,
                systemData,
                mode: 'view',
              })
            }
          >
            {systemData.systemName}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            onClick={() =>
              this.navigateToScreen(Routes.clientEquipment, {
                clientData,
                facilityData,
                systemData,
                equipmentData,
                mode: 'view',
              })
            }
          >
            {equipmentData.name}
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  filterGraph(graph) {
    const {
      equipmentState: { apiState: equipmentData },
    } = this.props;
    const { createSchematic, editSchematic } = this.props;
    const sourceMap = this.createSourceMap(graph.graph);
    if (equipmentData && graph && sourceMap) {
      if (this.handleNetwork()) {
        if (this.state.graph && this.state.graph._id) {
          editSchematic(
            {
              equipment: equipmentData.equipmentData._id,
              sourceMap,
              graph: graph.graph,
            },
            this.state.graph._id
          );
        } else {
          createSchematic({
            equipment: equipmentData.equipmentData._id,
            sourceMap,
            graph: graph.graph,
          });
        }
      }
    }
  }

  deploy(graph) {
    const {
      equipmentState: { apiState: equipmentData },
    } = this.props;
    const { validateSchematic } = this.props;
    if (this.handleNetwork()) {
      validateSchematic({ equipmentId: equipmentData.equipmentData._id });
    }
  }

  createSourceMap(graph) {
    const map = [];
    for (let i = 0; i < graph.length; i++) {
      if (graph[i].vertex) {
        if (
          graph[i].value.assetInfo &&
          graph[i].value.assetInfo.type &&
          graph[i].value.assetInfo.type.name !== 'Stream'
        ) {
          map.push(this.getComponentInfo(graph[i], graph));
        }
      }
    }
    return map;
  }

  getComponentInfo(component, graph) {
    const obj = {
      id: component.value.info._id,
      info: component.value.info,
      input: [],
      output: [],
    };
    if (component.edges) {
      for (let i = 0; i < component.edges.length; i++) {
        if (
          component.edges[i].source === component.id &&
          component.edges[i].target
        ) {
          this.getComponentId(component.edges[i].target, graph) &&
            obj.output.push(
              this.getComponentId(component.edges[i].target, graph)
            );
        }
        if (component.edges[i].target === component.id) {
          this.getComponentId(component.edges[i].target, graph) &&
            obj.input.push(
              this.getComponentId(component.edges[i].source, graph)
            );
        }
      }
    }
    return obj;
  }

  getComponentId(id, graph) {
    for (let i = 0; i < graph.length; i++) {
      if (
        graph[i].id === id &&
        graph[i].value.assetInfo.type &&
        graph[i].value.assetInfo.type.name !== 'Stream'
      ) {
        return graph[i].value.info._id;
      }
    }
    return null;
  }

  clearGraphChanged() {
    this.setState({
      isGraphChanged: false,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { demandPage, demandPageData } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.history.push(demandPage, demandPageData);
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
        />
      );
    }
    return null;
  }

  render() {
    const { equipmentData, facilityData } = this.props.location.state;
    const { loading } = this.props.schematicState;
    const { validateSchematicInfo } = this.state;
    const facilityId = facilityData ? facilityData['_id'] : '';

    return (
      <div className="ComponentSchemacic">
        {loading ? <Loader /> : null}
        <div className="ClientSystem">
          <SchematicDeployError validateSchematicInfo={validateSchematicInfo} />
          <div className="header" style={MaskStyle}>
            <div className="title">{SchematicVariables.TITLE}</div>
            <div className="path">{this.renderBreadCrumb()}</div>
          </div>
          <div className="screen-content">
            <div className="content-div">
              {this.state.objects &&
              (!this.state.graph ||
                (this.state.graph &&
                  this.state.graph.hasOwnProperty('deployed'))) ? (
                <GraphEditor
                  toolbar
                  model={this.state.graph}
                  graphData={this.state.graphData}
                  configure={() => {}}
                  objects={
                    (this.state.graph && !this.state.graph.deployed) ||
                    !this.state.graph
                      ? elementsGaurd(
                          this.state.objects,
                          ElementPermissions.EDIT_SCHEMATIC,
                          {
                            type: 'facility',
                            dataRef: {
                              _id: facilityId,
                            },
                          }
                        )
                      : null
                  }
                  saveButtonText={SchematicVariables.DEPLOY}
                  save={elementsGaurd(
                    (graph) => {
                      this.filterGraph(graph);
                    },
                    ElementPermissions.EDIT_SCHEMATIC,
                    {
                      type: 'facility',
                      dataRef: {
                        _id: facilityId,
                      },
                    }
                  )}
                  deploy={elementsGaurd(
                    (graph) => {
                      this.deploy(graph);
                    },
                    ElementPermissions.EDIT_SCHEMATIC,
                    {
                      type: 'facility',
                      dataRef: {
                        _id: facilityId,
                      },
                    }
                  )}
                  equipmentId={equipmentData._id}
                  isValidgraph={this.state.validGraph}
                  isGraphChanged={this.state.isGraphChanged}
                  clearGraphChanged={() => this.clearGraphChanged()}
                  resetUnDeploySchematic={this.props.resetUnDeploySchematic}
                  unDeploySchematic={this.props.unDeploySchematic}
                  facilityData={facilityData}
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  assetState: state.AssetReducer,
  schematicState: state.SchematicReducer,
  equipmentState: state.EquipmentReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAsset: (keyword) => dispatch(getAsset(keyword)),
  getEquipment: (id) => dispatch(getEquipment(id)),
  clearAssetState: () => dispatch(clearAssetState()),
  createSchematic: (payload) => dispatch(createSchematic(payload)),
  editSchematic: (payload, id) => dispatch(editSchematic(payload, id)),
  getSchematic: (query) => dispatch(getSchematic(query)),
  validateSchematic: (query) => dispatch(validateSchematic(query)),
  clearSchematicState: () => dispatch(clearSchematicState()),
  clearEquipmentState: () => dispatch(clearEquipmentState()),
  unDeploySchematic: (id) => dispatch(unDeploySchematic(id)),
  resetUnDeploySchematic: () => dispatch(resetUnDeploySchematic()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComponentSchematic));
