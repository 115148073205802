import {
  DATA_UNIT_CLEAR_STATE,
  DATA_UNIT_FETCH_ERROR,
  DATA_UNIT_FETCH_PROGRESS,
  DATA_UNIT_FETCH_SUCCESS,
} from "../Types/dataUnit.types";

const INITIAL_API_STATE = {
  unitData: null,
  unitMapper: {},
  isErrorDataUnitFetch: false,
  isSuccessDataUnitFetch: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function DataUnitReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DATA_UNIT_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case DATA_UNIT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorDataUnitFetch: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case DATA_UNIT_FETCH_SUCCESS:
      const unitMapper = action.payload.items.reduce((iValue, dataArray) => {
        iValue[dataArray.dataVariable] = dataArray.conversion.reduce(
          (iUnit, siValue) => {
            iUnit[siValue.unit] = siValue.toSI;
            return iUnit;
          },
          {}
        );
        return iValue;
      }, {});
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          unitMapper,
          unitData: action.payload,
          isSuccessDataUnitFetch: true,
        },
      };
    case DATA_UNIT_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          unitData: state.apiState.unitData,
          unitMapper: state.apiState.unitMapper,
        },
      };
    default:
      return state;
  }
}
