import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import { validatePositiveFloat} from "../../../../../../common/methods";
import { newValidateNumber } from "../../../../../../libs/validator";
import { VariableSpeedCurvesVariables } from "./../../../../../../constants/variables";

export const formFieldsValue = (fieldData) => {
  return [
    {
      label: "Drive Type",
      key: "driveType",
      type: "dropdownWithAdd",
      data:
        fieldData &&
        fieldData.driveTypeList &&
        fieldData.driveTypeList.map((item) => item.type),
      change: (value) => fieldData.handleChangeFieldData(value, "driveType"),
      openAddModal: () => fieldData.openAddModal("isDriveType"),
      addMessage: "Add Drive Type",
      disabled: !fieldData.isSchematicEditable,
    },
    {
      label: "Manufacturer",
      key: "driveManufacturer",
      type: "dropdownWithAdd",
      data:
        fieldData &&
        fieldData.driveManufacturerList &&
        fieldData.driveManufacturerList.map((item) => item.name),
      change: (value) =>
        fieldData.handleChangeFieldData(value, "driveManufacturer"),
      openAddModal: () => fieldData.openAddModal("isDriveManufacturer"),
      addMessage: "Add Drive Manufacturer",
      disabled: !fieldData.isSchematicEditable,
    },
    {
      label: "Drive Model",
      key: "driveModel",
      type: "text",
      change: (value) => fieldData.handleChangeFieldData(value, "driveModel"),
      disabled: !fieldData.isSchematicEditable,
    },
  ];
};

export const tableHeader = (tableHeader) => {
  return [
    {
      title: VariableSpeedCurvesVariables.speed,
      dataIndex: "speed",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="speed"
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.torque,
      dataIndex: "torque",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="torque"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.current,
      dataIndex: "current",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="current"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.power,
      dataIndex: "power",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="power"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },

    {
      title: VariableSpeedCurvesVariables.powerFactor,
      dataIndex: "powerFactor",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="powerFactor"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },

    {
      title: VariableSpeedCurvesVariables.efficiency,
      dataIndex: "efficiency",
      render: (value, row, col, disabled, item) => {
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                id="efficiency"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
                className={
                  (value && !validatePositiveFloat(value.toString())) ||
                  (!item.torque &&
                    !item.power &&
                    !item.efficiency &&
                    !item.powerFactor &&
                    !item.current &&
                    !item.speed)
                    ? "error"
                    : "not-error"
                }
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: VariableSpeedCurvesVariables.actions,
      dataIndex: "actions",
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = (input) => {
  if (input.length !== 15) {
    return [
      {
        render: () => {
          let value = _.isNil(input.speed) ? "" : input.speed.toString();
          return (
            <div>
              <Input
                id="speed"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.speed}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  (!_.isEmpty(value) && input.eventListener) &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.torque) ? "" : input.torque.toString();
          return (
            <div>
              <Input
                id="torque"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.torque}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  (!_.isEmpty(value) && input.eventListener) &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.current) ? "" : input.current.toString();
          return (
            <div>
              <Input
                id="current"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.current}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  (!_.isEmpty(value) && input.eventListener) &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.power) ? "" : input.power.toString();
          return (
            <Input
              id="power"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.power}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                (!_.isEmpty(value) && input.eventListener) &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.powerFactor)
            ? ""
            : input.powerFactor.toString();
          return (
            <Input
              id="powerFactor"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.powerFactor}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                (!_.isEmpty(value) && input.eventListener) &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          let value = _.isNil(input.efficiency)
            ? ""
            : input.efficiency.toString();
          return (
            <Input
              id="efficiency"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.efficiency}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                (!_.isEmpty(value) && input.eventListener) &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = (input) => {
  return [
    {
      render: () => {
        const tempError = !input.speedUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="speedUnit"
              value={!input.speedUnit ? "" : input.speedUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === "Starting curve - Speed") {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.torqueUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="torqueUnit"
              value={!input.torqueUnit ? "" : input.torqueUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (
                  getUnits.dataVariable ===
                  "Starting curve - Current 100% Voltage"
                ) {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.currentUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="currentUnit"
              value={!input.currentUnit ? "" : input.currentUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (
                  getUnits.dataVariable ===
                  "Starting curve - Current 80% Voltage"
                ) {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },

    {
      render: () => {
        const tempError = !input.powerUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="powerUnit"
              value={!input.powerUnit ? "" : input.powerUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (
                  getUnits.dataVariable ===
                  "Starting curve - Torque 100% Voltage"
                ) {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.powerFactorUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="powerFactorUnit"
              value={!input.powerFactorUnit ? "" : input.powerFactorUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (getUnits.dataVariable === "Starting curve - Power Factor") {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.efficiencyUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && "error"}`}>
            <select
              id="efficiencyUnit"
              value={!input.efficiencyUnit ? "" : input.efficiencyUnit}
              onChange={(value) => input.handleChangeTableData(value, "unit")}
              disabled={!input.isSchematicEditable}
            >
              <option value={""}>{"Select"}</option>
              {input.unitData.map((getUnits) => {
                if (
                  getUnits.dataVariable ===
                  "Starting curve - Torque 80% Voltage"
                ) {
                  return getUnits.units.map((dispUnit) => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};
