import {
  INPUTDATA_FETCH_PROGRESS,
  INPUTDATA_FETCH_SUCCESS,
  INPUTDATA_FETCH_ERROR,
  INPUTDATA_FETCH_CLEAR,
  VARIABLENAME_FETCH_PROGRESS,
  VARIABLENAME_FETCH_SUCCESS,
  VARIABLENAME_FETCH_ERROR,
  VARIABLETAG_FETCH_PROGRESS,
  VARIABLETAG_FETCH_SUCCESS,
  VARIABLETAG_FETCH_ERROR,
  ADDINPUT_PUT_PROGRESS,
  ADDINPUTDATA_PUT_SUCCESS,
  ADDINPUTDATA_PUT_ERROR,
} from "../Types/inputData.type";

const INITIAL_STATE = {
  inputData: "",
  isInputDataFetchSuccess: false,
  isInputDataFetchError: false,
  errorMessageInputData: "",
  loading: false,
  variableName: "",
  isVariableNameFetchSuccess: false,
  isVariableNameFetchError: false,
  errorMessageVariableName: "",
  loadingVariableName: false,
  variableTag: "",
  isVariableTagFetchSuccess: false,
  isVariableTagFetchError: false,
  errorMessageVariableTag: "",
  loadingVariableTag: false,
  addInputData: "",
  isAddInputDataSuccess: false,
  isAddInputDataError: false,
  errorMessageAddInputData: "",
  loadingAddInputData: false,
};

export default function inputDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INPUTDATA_FETCH_PROGRESS:
      return { ...state, loading: true };

    case INPUTDATA_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        isInputDataFetchSuccess: true,
        inputData: action.payload,
      };

    case INPUTDATA_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMessageInputData:
          action.payload.response && action.payload.response,
        isInputDataFetchError: true,
      };

    case INPUTDATA_FETCH_CLEAR:
      return {
        ...state,
        isInputDataFetchSuccess: false,
        isInputDataFetchError: false,
        isVariableNameFetchSuccess: false,
        isVariableNameFetchError: false,
        isVariableTagFetchSuccess: false,
        isVariableTagFetchError: false,
        isAddInputDataSuccess: false,
        isAddInputDataError: false,
      };

    case VARIABLENAME_FETCH_PROGRESS:
      return {
        ...state,
        loadingVariableName: true,
      };
    case VARIABLENAME_FETCH_SUCCESS:
      return {
        ...state,
        loadingVariableName: false,
        isVariableNameFetchSuccess: true,
        variableName: action.payload && action.payload,
      };
    case VARIABLENAME_FETCH_ERROR:
      return {
        ...state,
        loadingVariableName: false,
        isVariableNameFetchError: true,
        errorMessageVariableName:
          action.payload.response && action.payload.response,
      };

    case VARIABLETAG_FETCH_PROGRESS:
      return {
        ...state,
        loadingVariableTag: true,
      };

    case VARIABLETAG_FETCH_SUCCESS:
      return {
        ...state,
        loadingVariableTag: false,
        isVariableTagFetchSuccess: true,
        variableTag: action.payload && action.payload,
      };

    case VARIABLETAG_FETCH_ERROR:
      return {
        ...state,
        loadingVariableTag: false,
        isVariableTagFetchError: true,
        errorMessageVariableTag:
          action.payload.response && action.payload.response,
      };

    case ADDINPUT_PUT_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case ADDINPUTDATA_PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAddInputDataSuccess: true,
        addInputData: action.payload,
      };
    case ADDINPUTDATA_PUT_ERROR:
      return {
        ...state,
        loading: false,
        isAddInputDataError: true,
        errorMessageAddInputData:
          action.payload.response && action.payload.response,
      };
    default:
      return state;
  }
}
