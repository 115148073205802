   export const GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_PROGRESS = 'GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_PROGRESS';
   export const GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_SUCCESS = 'GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_SUCCESS';
   export const GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_FAILURE = 'GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_FAILURE';
   export const GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_RESET = 'GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_RESET';

   export const UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_PROGRESS = 'UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_PROGRESS';
   export const UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_SUCCESS = 'UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_SUCCESS';
   export const UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_FAILURE = 'UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_FAILURE';
   export const UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_RESET = 'UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_RESET';

   export const UPDATE_RECIPROCATING_CURVES_DATA_PROGRESS = 'UPDATE_RECIPROCATING_CURVES_DATA_PROGRESS';
   export const UPDATE_RECIPROCATING_CURVES_DATA_SUCCESS = 'UPDATE_RECIPROCATING_CURVES_DATA_SUCCESS';
   export const UPDATE_RECIPROCATING_CURVES_DATA_FAILURE = 'UPDATE_RECIPROCATING_CURVES_DATA_FAILURE';
   export const UPDATE_RECIPROCATING_CURVES_DATA_RESET = 'UPDATE_RECIPROCATING_CURVES_DATA_RESET';

   export const RESET_RECIPROCATING_COMPRESSOR_REDUCER = 'RESET_RECIPROCATING_COMPRESSOR_REDUCER';