export const ALL_ALERTS_FETCH_SUCCESS = "ALL_ALERTS_FETCH_SUCCESS";
export const ALL_ALERTS_FETCH_ERROR = "ALL_ALERTS_FETCH_ERROR";
export const ALL_ALERTS_FETCH_PROGRESS = "ALL_ALERTS_FETCH_PROGRESS";
export const ALL_ALERTS_CLEAR_STATE = "ALL_ALERTS_CLEAR_STATE";
export const ALERTS_CLEAR_API_STATE = "ALERTS_CLEAR_API_STATE";
export const ALERT_CLEAR_LIST = "ALERT_CLEAR_LIST";

export const SENT_ALERTS_FETCH_SUCCESS = "SENT_ALERTS_FETCH_SUCCESS";
export const SENT_ALERTS_FETCH_ERROR = "SENT_ALERTS_FETCH_ERROR";
export const SENT_ALERTS_FETCH_PROGRESS = "SENT_ALERTS_FETCH_PROGRESS";
export const SENT_ALERTS_CLEAR_STATE = "SENT_ALERTS_CLEAR_STATE";

export const ALERT_DISCARD_REQUEST = "ALERT_DISCARD_REQUEST";
export const ALERT_DISCARD_ERROR = "ALERT_DISCARD_ERROR";
export const ALERT_DISCARD_SUCCESS = "ALERT_DISCARD_SUCCESS";
export const ALERT_DISCARD_CLEAR_STATE = "ALERT_DISCARD_CLEAR_STATE";

export const ALERT_CLOSE_REQUEST = "ALERT_CLOSE_REQUEST";
export const ALERT_CLOSE_ERROR = "ALERT_CLOSE_ERROR";
export const ALERT_CLOSE_SUCCESS = "ALERT_CLOSE_SUCCESS";
export const ALERT_CLOSE_CLEAR_STATE = "ALERT_CLOSE_CLEAR_STATE";

export const ALERT_ASSOC_REQUEST = "ALERT_ASSOC_REQUEST";
export const ALERT_ASSOC_ERROR = "ALERT_ASSOC_ERROR";
export const ALERT_ASSOC_SUCCESS = "ALERT_ASSOC_SUCCESS";
export const ALERT_ASSOC_CLEAR_STATE = "ALERT_ASSOC_CLEAR_STATE";

export const ALERT_DISASSOC_REQUEST = "ALERT_DISASSOC_REQUEST";
export const ALERT_DISASSOC_ERROR = "ALERT_DISASSOC_ERROR";
export const ALERT_DISASSOC_SUCCESS = "ALERT_DISASSOC_SUCCESS";
export const ALERT_DISASSOC_CLEAR_STATE = "ALERT_DISASSOC_CLEAR_STATE";

export const ALERT_DETAILS_SAVE_REQUEST = "ALERT_DETAILS_SAVE_REQUEST";
export const ALERT_DETAILS_SAVE_ERROR = "ALERT_DETAILS_SAVE_ERROR";
export const ALERT_DETAILS_SAVE_SUCCESS = "ALERT_DETAILS_SAVE_SUCCESS";
export const ALERT_DETAILS_CLEAR_STATE = "ALERT_DETAILS_CLEAR_STATE";

export const ALERT_PLOT_GET_REQUEST = "ALERT_PLOT_GET_REQUEST";
export const ALERT_PLOT_GET_ERROR = "ALERT_PLOT_GET_ERROR";
export const ALERT_PLOT_GET_SUCCESS = "ALERT_PLOT_GET_SUCCESS";
export const ALERT_PLOT_GET_CLEAR_STATE = "ALERT_PLOT_GET_STATE";

export const ALERT_LABELS_GET_REQUEST = "ALERT_LABELS_GET_REQUEST";
export const ALERT_LABELS_GET_ERROR = "ALERT_LABELS_GET_ERROR";
export const ALERT_LABELS_GET_SUCCESS = "ALERT_LABELS_GET_SUCCESS";
export const ALERT_LABELS_GET_CLEAR_STATE = "ALERT_LABELS_GET_STATE";

export const ALERT_ASSIGNEES_GET_REQUEST = "ALERT_ASSIGNEES_GET_REQUEST";
export const ALERT_ASSIGNEES_GET_ERROR = "ALERT_ASSIGNEES_GET_ERROR";
export const ALERT_ASSIGNEES_GET_SUCCESS = "ALERT_ASSIGNEES_GET_SUCCESS";
export const ALERT_ASSIGNEES_GET_CLEAR_STATE = "ALERT_ASSIGNEES_GET_STATE";

export const EVENT_DETAILS_GET_REQUEST = "EVENT_DETAILS_GET_REQUEST";
export const EVENT_DETAILS_GET_ERROR = "EVENT_DETAILS_GET_ERROR";
export const EVENT_DETAILS_GET_SUCCESS = "EVENT_DETAILS_GET_SUCCESS";
export const EVENT_DETAILS_GET_CLEAR_STATE = "EVENT_DETAILS_GET_STATE";
