import { Select, Tabs } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CurvesData from './CompressorData';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../../../common/Loader/Loader';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import { curveBoardMessages } from '../../../../../../constants/messages';
import { getConfig } from '../../../../../../redux/actions/config.action';
import {
  clearCompressorCurvesState,
  clearDeleteCompressorCurvesState,
  clearFetchCompressorCurvesState,
  deleteCompressorCurves,
  getCompressorCurves,
} from '../../../../../../redux/actions/curves.action';
import CompressorCurves from './compressorCurves';
import {
  applications,
  CurveScreenConstants,
  curvesMapper,
} from './curvesConstants';
import './recipCompressorCurves.scss';

const { TabPane } = Tabs;
const { Option } = Select;

class CurvesBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      numberOfCurves: 1,
      application: 'Fixed Speed',
      process: 'Adiabatic',
      variableCurves: [{ name: 'curve1', closable: false, data: {} }],
      activeKey: 'curve1',
      componentName: this.props.location.state.componentData.assetInfo.name,
      componentId: this.props.location.state.componentData.info._id,
      applicationChange: '',
      editSate:false,
      saveWarning:false,
      deleteKey: "",
      tempActiveKey: "curve1"
    };
  }

  componentDidMount() {
    const { curvesData } = this.props.configData;
    const {activeKey } = this.props
    if (curvesData && curvesData.curvesData && curvesData.curvesData[CurveScreenConstants[activeKey-1]]) {
      const dataCurves = curvesData.curvesData[CurveScreenConstants[activeKey-1]];
      const { application } = curvesData;
      const curves = Object.keys(dataCurves).map((curve) => ({
        name: curve,
        closable: false,
        data: dataCurves[curve],
      }));
      if (curves.length !== 1) {
        curves[curves.length - 1].closable = true;
      }
      let activeKeyValue = "";
      if(curves[curves.length - 2] && curves[curves.length - 2].name){
        activeKeyValue = curves[0].name;
      }
      else{
        activeKeyValue = curves[curves.length - 1].name;
      }
      this.setState({ variableCurves: curves, activeKey: activeKeyValue, application });
    }
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate() {
    const {
      compressorReducer,
      clearDeleteCompressorCurvesState,
      isConfigData,
      clearConfigState,
      editCurve,
      getConfig,
      clearCompressorCurvesState
    } = this.props;
    const { deleteCompressorCurves } = compressorReducer;
    if (isConfigData) {
      clearConfigState();
      const { curvesData } = this.props.configData;
      if (curvesData && curvesData.curvesData) {
        const { application, process } = curvesData;
        this.setState({ application, process });
      }
    }

    if (deleteCompressorCurves.isSuccess) {
      clearDeleteCompressorCurvesState();
      const { variableCurves } = this.state;
      let activeKeys = "";
      let tempData = this.state.variableCurves
      if(variableCurves[variableCurves.length - 2] && variableCurves[variableCurves.length - 2].name &&
         this.props.activeKey === this.state.deleteKey){
        activeKeys = variableCurves[variableCurves.length - 2].name;
        tempData.splice(-1, 1)
      }
      else{
        activeKeys = variableCurves[variableCurves.length - 1].name;
      }
      if (variableCurves.length !== 1) {
        tempData[tempData.length - 1].closable = true;
      }
      this.setState({
        activeKey:activeKeys,
        variableCurves: tempData,
      });
    }
    if(editCurve.addEditCompressorCurves.isSuccess){
      clearCompressorCurvesState()
      const { componentId } = this.props.configData;
      getConfig(componentId, 'Reciprocating Compressor')
      this.setState({editSate: false})
    }
  }

  onChange = (activeKey) => {
    this.setState({tempActiveKey: activeKey})
    if(this.state.editSate){
      this.setState({saveWarning: true})
    }
    else{
    this.setState({ activeKey });
    }
  };
  
  onDataEdit = (state) => {
    this.setState({editSate: state})
  }

  handleSaveWarning = (state, change) => {
    this.setState({saveWarning: state})
    if(change === true){
      this.setState({activeKey: this.state.tempActiveKey})
    }
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  remove = (curveId, screenName, activeKey) => {
    this.props.deleteCompressorCurves(
      this.state.componentId,
      this.state.componentName,
      curveId,
      screenName
    );
    this.setState({deleteKey: activeKey})
  };

  handleChangeNumberOfCurves = (saveType) => {
    const { variableCurves } = this.state;
    const noOfCurves = variableCurves.length;
    const name = `curve${noOfCurves + 1}`;
    const closable = true;
    if (saveType === 'saveMore') {
      variableCurves[noOfCurves - 1].closable = false;
      variableCurves.push({ name, closable });
      this.setState({ variableCurves, activeKey: name });
    }
  };

  onChangeAppication = (application) => {
    this.setState(
      {
        applicationChange: application,
      },
      () => this.enableEditForm()
    );
    const { variableCurves } = this.state;
      variableCurves[variableCurves.length - 1].closable = true;
      this.setState({ application, variableCurves });
    // else {
    //   this.setState({ visible: true });
    // }
  };

  onChangeProcess = (process) => {
    this.setState({ process });
  };

  onOk = () => {
    const { variableCurves } = this.state;
    const fixedSpeedCurves = variableCurves.slice(0, 1);
    fixedSpeedCurves[fixedSpeedCurves.length - 1].closable = false;
    this.setState({ visible: false, variableCurves: fixedSpeedCurves });
    variableCurves[variableCurves.length - 1].closable = false;
    this.setState(
      {
        application: this.state.applicationChange,
        variableCurves,
        visible: false,
      },
      () => this.enableEditForm()
    );
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  setApplication = (application) => {
    this.setState({application: application})
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={curveBoardMessages.MODAL_TITLE}
          visible={this.state.visible}
          handleOk={this.onOk}
          handleCancel={this.onCancel}
          message={curveBoardMessages.MODAL_MSG}
        />
      );
    }
    return null;
  }

  onSave = (data) => {
    alert(data);
  };

  render() {
    const { variableCurves, application, process } = this.state;
    const { isSchematicEditable, configData, compressorReducer, activeKey, editCurve } = this.props;
    const { addEditCompressorCurves } = editCurve
    const noOfCurves = variableCurves.length;
    return (
      <div className="CompressorCurvesBoard">
        {compressorReducer.deleteCompressorCurves.isLoading ? <Loader /> : null}
        <div className="CurvesHeader">
          <div className="application-process">
            <div className="app">
              <label>Application</label>
              <Select value={application} onChange={this.onChangeAppication}>
                {applications.map((application) => (
                  <Option value={application}>{application}</Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
          <Tabs
            activeKey={this.state.activeKey}
            onChange={event => this.onChange(event)}
            onEdit={this.onEdit}
          >
            {this.state.variableCurves.map((curveItem, index) => (
              <TabPane tab={curvesMapper[curveItem.name]} key={curveItem.name}>
                <CompressorCurves
                  process={process}
                  data={_.cloneDeep(curveItem.data)}
                  curveName={curveItem.name}
                  curveId={curvesMapper[curveItem.name]}
                  noOfCurves={noOfCurves}
                  application={application}
                  isSchematicEditable={isSchematicEditable}
                  configData={configData}
                  closable={curveItem.closable}
                  componentName={this.state.componentName}
                  componentId={this.state.componentId}
                  remove={this.remove}
                  handleSaveAndMore={this.handleChangeNumberOfCurves}
                  variableCurves={variableCurves}
                  activeKey = {activeKey}
                  lastCurve = {(variableCurves.slice(-1)[0])? variableCurves.slice(-1)[0].name:""}
                  setApplication = {this.setApplication}
                  initialApplicationState = {configData && configData.curvesData && 
                    configData.curvesData.application? configData.curvesData.application: ""
                  }
                  addedCurves = {addEditCompressorCurves}
                  onDataEdit = {this.onDataEdit}
                  editSate = {this.state.editSate}
                  saveWarning = {this.state.saveWarning}
                  handleSaveWarning = {this.handleSaveWarning}
                />            
              </TabPane>
            ))}
          </Tabs>
        {this.renderModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  compressorReducer: state.CompressorReducer,
  network: state.NetworkReducer,
  editCurve: state.RecipCompCurveState
});


const mapDispatchToProps = {
  getCompressorCurves,
  clearFetchCompressorCurvesState,
  deleteCompressorCurves,
  clearDeleteCompressorCurvesState,
  getConfig,
  clearCompressorCurvesState
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurvesBoard)
);
