import {
  STATE_FETCH_ERROR,
  STATE_FETCH_PROGRESS,
  STATE_FETCH_SUCCESS,
  STATE_CLEAR_STATE,
} from "../Types/State.types";

import { GETRequest } from "../../api/request";

export function getState() {
  return function (dispatch) {
    dispatch({ type: STATE_FETCH_PROGRESS });
    GETRequest("states")
      .then((res) => {
        dispatch({ type: STATE_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: STATE_FETCH_ERROR, payload: err });
      });
  };
}

export function clearStatesFetchState() {
  return function (dispatch) {
    dispatch({ type: STATE_CLEAR_STATE });
  };
}
