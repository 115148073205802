import { Switch } from "antd";
import _ from "lodash";
import React from "react";
import { AddUserMessages } from "../../../../constants/messages.js";
import { Email, Name, Phone } from "../../../../constants/pattern";
import { roles } from "../../../../permissions/roles.permissions";
import { UserRole } from "../../../AppScreen/AppScreen";

export const formFieldsValveData = (Fields) => {
  let preSelected = [];
  const fieldData = [
    {
      label: "First Name",
      key: "firstName",
      type: "text",
      max: 50,
      required: [true, AddUserMessages.REQUIRED_FIELD],
      pattern: [Name.regx, Name.message],
    },
    {
      label: "Last Name",
      key: "lastName",
      type: "text",
      max: 50,
      required: [true, AddUserMessages.REQUIRED_FIELD],
      pattern: [Name.regx, Name.message],
    },
    {
      label: "Email",
      key: "email",
      type: "email",
      disabled: !!(Fields.editUserData && Fields.editUserData.data.email),
      required: [true, AddUserMessages.REQUIRED_FIELD],
      fixType: ["email", Email.message],
      max: 254,
    },
    {
      label: "Work Phone",
      key: "workPhone",
      type: "text",
      max: 20,
      required: [true, AddUserMessages.REQUIRED_FIELD],
      pattern: [Phone.regx, Phone.message],
    },
    {
      label: "Mobile",
      key: "phone",
      type: "text",
      max: 20,
      pattern: [Phone.regx, Phone.message],
    },
    {
      label: "Role",
      key: "role",
      type: "dropdown",
      required: [true, AddUserMessages.REQUIRED_FIELD],
      data: roleData(),
      valueKey: "role",
      titleKey: "displayRole",
      searchKey: "displayRole",
      change: (val) => Fields.roleSelect(val),
    },
    {
      label: "Client Name",
      key: "client",
      required:
        Fields.role !== roles.MECHADEMY_FACILITY_MANAGER
          ? [true, AddUserMessages.REQUIRED_FIELD]
          : [false, AddUserMessages.INTERNAL ,"array"],
      type: Fields.role !== roles.MECHADEMY_FACILITY_MANAGER ? "dropdown" : "multiDropdown",
      data: Fields.clientList,
      valueKey: "_id",
      titleKey: "name",
      searchKey: "name",
      change: (val) => Fields.clientSelect(val),
      disabled: (!Fields.role) || (Fields.role === roles.MECHADEMY_FACILITY_MANAGER),
    },
  ];

  const getFaciltyName = (facilty_id) => {
    let facilityName = "";
    Fields.clientInfoData.facilities &&
      Fields.clientInfoData.facilities.map((item) => {
        if (facilty_id === item._id) {
          facilityName += item.name;
        }
        return facilityName
      });
    if (facilityName){
      facilityName = " (" + facilityName + ")"
    }
    return facilityName;
  };

  if (
    (Fields.clientId && Fields.role && Fields.role !== roles.CLIENT_ADMIN) ||
    Fields.role === roles.MECHADEMY_FACILITY_MANAGER
  ) {
    const dataField = () => {
      if (Fields.role === roles.FACILITY_MANAGER) {
        return (
          Fields.clientInfoData.facilities &&
          Fields.clientInfoData.facilities.map((item) => ({
            name: item.name,
            _id: item._id,
          }))
        );
      }
      if (Fields.role === roles.EQUIPMENT_MANAGER) {
        return (
          Fields.clientInfoData.equipments &&
          Fields.clientInfoData.equipments.map((item) => ({
            name: item.name + " (" + item.systemName + ")",
            _id: item._id,
          }))
        );
      }
      if (Fields.role === roles.SYSTEM_MANAGER) {
        return (
          Fields.clientInfoData.facilities && Fields.clientInfoData.systems &&
          Fields.clientInfoData.systems.map((item) => ({
            name: item.systemName + getFaciltyName(item.facilityId),
            _id: item._id,
          }))
        );
      }
      if (Fields.role === roles.MECHADEMY_FACILITY_MANAGER) {
        return (
          Fields.clientFacilityList &&
          Fields.clientFacilityList.map((item) => ({
            name: item.name,
            _id: item.id,
            client: item.client,
          }))
        );
      }
    };

    const label = () => {
      if (
        Fields.role === roles.FACILITY_MANAGER ||
        Fields.role === roles.MECHADEMY_FACILITY_MANAGER
      ) {
        return "Select Facility";
      }
      if (Fields.role === roles.SYSTEM_MANAGER) {
        return "Select System";
      }
      if (Fields.role === roles.EQUIPMENT_MANAGER) {
        return "Select Equipment";
      }
    };

    fieldData.push({
      label: label(),
      key: "selectRole",
      type: "multiDropdown",
      required: [true, AddUserMessages.REQUIRED_FIELD, "array"],
      data: dataField(),
      valueKey: "_id",
      titleKey: "name",
      searchKey: "name",
      select: (val) => Fields.multipleRoleSelect(val),
      deSelect: (val) => Fields.multipleRoleDeSelect(val),
    });

    fieldData.push({
      label: "Mail Subscription",
      key: "mailSubscription",
      type: "switch",
      data: Fields.mailSubscription || Fields?.editUserData?.data?.mailSubscription,
      change: (val)=> Fields.onSwitchChange(val),
    });

    const dataFields = dataField();
    if (Fields.preSelected.length) {
      preSelected = Fields.preSelected.map(
        (data) =>
          dataFields &&
          dataFields.length &&
          dataFields.find((ele) => ele._id === data.data)
      );
    }
    if (preSelected.length && preSelected[0]) {
      Fields.populateData(_.map(preSelected, "name"));
    }
  }else{
    fieldData.push({
      label: "Mail Subscription",
      key: "mailSubscription",
      type: "switch",
      data: Fields.mailSubscription || Fields?.editUserData?.data?.mailSubscription,
      change: (val)=> Fields.onSwitchChange(val),
    });
  }
  return fieldData;
};

export const tableheader = (Fields) => {
  const selectedRole = (Fields) => {
    if (Fields && Fields.role === roles.FACILITY_MANAGER) {
      return userRole[1].displayRole;
    }
    if (Fields && Fields.role === roles.SYSTEM_MANAGER) {
      return userRole[2].displayRole;
    }
    if (Fields && Fields.role === roles.EQUIPMENT_MANAGER) {
      return userRole[3].displayRole;
    }
    if (Fields && Fields.role === roles.MECHADEMY_FACILITY_MANAGER) {
      return userRole[4].displayRole;
    }
    if (Fields && Fields.role === roles.MECHADEMY_FACILITY_MANAGER) {
      return UserRole[4].displayRole;
    }
  };
  if (Fields.role !== roles.MECHADEMY_FACILITY_MANAGER) {
    return [
      {
        title: selectedRole(Fields),
        dataIndex: "role",
      },
      {
        title: "Alert Access (Read)",
        dataIndex: "selectedRead",
        render: (value, row, _col, _disabled, item) => (
          <Switch
            checked={value}
            className="user-management-switch"
            onChange={() => Fields.onchangeAccess(item.data, row, "read")}
            disabled={item.selectedWrite}
          />
        ),
      },
      {
        title: "Alert Access (Write)",
        dataIndex: "selectedWrite",
        render: (value, row, _col, _disabled, item) => (
          <Switch
            checked={value}
            className="user-management-switch"
            onChange={() => Fields.onchangeAccess(item.data, row, "write")}
          />
        ),
      },
      {
        title: "",
        dataIndex: "",
      },
    ];
  }
  let accessTableForm = [
    {
      title: selectedRole(Fields),
      dataIndex: "role",
    },
  ];
  accessAreas.forEach((element) => {
    accessTableForm.push(
      {
        title: accessTable[element].readTitle,
        dataIndex: accessTable[element].readIndex,
        render: (value, row, _col, _disabled, item) => (
          <Switch
            checked={value}
            className="user-management-switch"
            onChange={() =>
              Fields.onchangeAccess(item.data, row, "read", element)
            }
            disabled={item.selectedWrite}
          />
        ),
      },
      {
        title: accessTable[element].writeTitle,
        dataIndex: accessTable[element].writeIndex,
        render: (value, row, _col, _disabled, item) => (
          <Switch
            checked={value}
            className="user-management-switch"
            onChange={() =>
              Fields.onchangeAccess(item.data, row, "write", element)
            }
          />
        ),
      },
      {
        title: "",
        dataIndex: "",
      }
    );
  });
  return accessTableForm;
};

export const userManagementTableHeader = (Fields) => {
  const selectedRole = (Fields) => {
    if (Fields && Fields.role === roles.MECHADEMY_FACILITY_MANAGER) {
      return "Mechademy Facility Manager";
    }
  };

  return [
    {
      title: selectedRole(Fields),
      dataIndex: "role",
    },
    {
      title: "User Management (Read)",
      dataIndex: "selectedUserMgmtRead",
      render: (value, row, _col, _disabled, item) => (
        <Switch
          checked={value}
          className="user-management-switch"
          onChange={() =>
            Fields.onchangeAccess(item.data, row, "read", "userMgmt")
          }
          disabled={item.selectedWrite}
        />
      ),
    },
    {
      title: "User Management (Write)",
      dataIndex: "selectedUserMgmtWrite",
      render: (value, row, _col, _disabled, item) => (
        <Switch
          checked={value}
          className="user-management-switch"
          onChange={() =>
            Fields.onchangeAccess(item.data, row, "write", "userMgmt")
          }
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
    },
  ];
};

export const userRole = [
  { role: "ClientAdmin", displayRole: "Client Admin" },
  { role: "FacilityManager", displayRole: "Facility Admin" },
  { role: "SystemEngineer", displayRole: "System Admin" },
  { role: "EquipmentEngineer", displayRole: "Equipment Admin" },
  { role: "MechademyFacilityManager", displayRole: "Mechademy Facility User" },
];

export const accessTable = {
  dashboard: {
    writeTitle: "Dashboard Access (Write)",
    readTitle: "Dashboard Access (Read)",
    writeIndex: "selectedDashboardWrite",
    readIndex: "selectedDashboardRead",
  },
  setup: {
    writeTitle: "Setup Access (Write)",
    readTitle: "Setup Access (Read)",
    writeIndex: "selectedSetupWrite",
    readIndex: "selectedSetupRead",
  },
  mlAlgo: {
    writeTitle: "ML Algo Access (Write)",
    readTitle: "ML Algo Access (Read)",
    writeIndex: "selectedMlWrite",
    readIndex: "selectedMlRead",
  },
  piTag: {
    writeTitle: "PI Tag Access (Write)",
    readTitle: "PI Tag Access (Read)",
    writeIndex: "selectedPiTagWrite",
    readIndex: "selectedPitagRead",
  },
};

const roleData = () => {
  switch (UserRole) {
    case "MechademyAdmin":
      return userRole;
    case "MechademyFacilityManager":
      return [
        {
          role: "MechademyFacilityManager",
          displayRole: "Mechademy Facility User",
        },
        { role: "FacilityManager", displayRole: "Facility Admin" },
        { role: "SystemEngineer", displayRole: "System Admin" },
        { role: "EquipmentEngineer", displayRole: "Equipment Admin" },
      ];
    default:
      return [];
  }
};

export const accessAreas = ["dashboard", "setup", "mlAlgo", "piTag"];
