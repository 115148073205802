import {
  Avatar,
  Breadcrumb,
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Upload,
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ic_download } from '../../../assets/ic_download';
import Loader from '../../../common/Loader/Loader';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  EquipmentAssetMessages,
  EquipmentMessage,
  EquipmentTypeMessage,
  fileMessage,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Pattern from '../../../constants/pattern';
import { MaskStyle } from '../../../constants/react-style';
import * as Routes from '../../../constants/routes';
import '../../../constants/style.scss';
import {
  ButtonVariables,
  ClientEquipmentVariables,
  EquipmentAssetVariable,
  imageUpload,
} from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { getImageUrl, getToken } from '../../../libs/token';
import { imgProps } from '../../../libs/uploader';
import { ElementPermissions } from '../../../permissions';
import {
  addAssetEquipment,
  getEquipmentAsset,
  updateAssetEquipment,
} from '../../../redux/actions/assetEquipment.action';
import {
  addEquipmentType,
  clearEquipmentState,
  getEquipmentType,
} from '../../../redux/actions/equipment.action';
import './AddEquipment.scss';

const { TextArea } = Input;
const { Option } = Select;

class AddEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'add',
      formEdit: false,
      formDisable: false,
      visible: false,
      demandPage: null,
      equipmentTypes: [],
      equipmentData: {},
      addModalType: null,
      addModalVisible: false,
      addNewValue: null,
      network: window.navigator.onLine,
      message: null,
      title: null,
      addMore: false,
      logoUrl: '',
      logoPath: '',
      logoError: false,
      viewLogo: false,
      error: false,
      tabType: this.props.location.state ? this.props.location.state.type : '2',
      type: null,
    };
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;
    if (state && state.data) {
      this.setState({
        equipmentData: state.data,
      });
    }

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });

    this.getPageMode();
    this.getEquipmentType();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isSuccessGetEquipmentType,
      isSuccessAddEquipment,
      isSuccessEditEquipment,
      isSuccessAddEquipmentType,
      isErrorGetEquipmentType,
      isErrorAddEquipmentType,
      getEquipmentTypeData,
      equipmentData,
      addEquipmentTypeData,
      message,
    } = this.props.equipmentState.apiState;

    const {
      isGetSuccess,
      getData,
      isAddSuccess,
      isUpdated,
      isUpdateError,
      isAddError,
      isGetError,
    } = this.props.assetEquipmentState.apiState;
    const { clearEquipmentState } = this.props;

    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';

    if (isSuccessAddEquipmentType) {
      clearEquipmentState();
      Notification.show(Types.Success, EquipmentTypeMessage.ADDED);
      this.getEquipmentType();
      this.props.form.setFieldsValue({
        type: addEquipmentTypeData.nameId,
      });
      this.closeAddModal();
    }

    if (isAddSuccess) {
      Notification.show(Types.Success, EquipmentAssetMessages.ADDED);
      this.props.history.push(Routes.assetLibrary, {
        type: this.state.tabType,
      });
    }

    if (isGetSuccess) {
      this.setState({
        equipmentData: getData.items,
      });
    }

    if (isUpdated) {
      Notification.show(Types.Success, EquipmentAssetMessages.UPDATED);
      this.props.history.push(Routes.assetLibrary, {
        type: this.state.tabType,
      });
    }

    if (isSuccessGetEquipmentType) {
      clearEquipmentState();
      this.setState({
        equipmentTypes: getEquipmentTypeData ? getEquipmentTypeData.items : [],
      });
    }

    if (isSuccessEditEquipment) {
      Notification.show(Types.Success, EquipmentMessage.EQUIPMENT_UPDATED);
      clearEquipmentState();
      this.disableEditForm();
      this.setState(
        {
          mode: 'view',
          formDisable: true,
          equipmentData,
        },
        () => {
          this.setState({ formDisable: true });
        }
      );
    }

    if (isSuccessAddEquipment) {
      let tempAddMore = this.state.addMore;
      clearEquipmentState();
      Notification.show(Types.Success, EquipmentMessage.EQUIPMENT_ADDED);
      this.disableEditForm();

      if (!tempAddMore) {
        this.setState(
          {
            mode: 'view',
            formDisable: true,
            equipmentData,
          },
          () => {
            tempAddMore = false;
          }
        );
      } else {
        this.setState(
          {
            addMore: tempAddMore,
            mode: 'add',
          },
          () => {
            tempAddMore = true;
            this.props.form.resetFields();
            this.disableEditForm();
          }
        );
      }
    }

    if (
      isUpdateError &&
      isUpdateError !== prevProps.assetEquipmentState.apiState.isUpdateError
    ) {
      Notification.show(
        Types.Error,
        EquipmentAssetMessages[
          this.props.assetEquipmentState.apiState.errorCode
        ]
      );
    }
    if (
      isAddError &&
      isAddError !== prevProps.assetEquipmentState.apiState.isAddError
    ) {
      Notification.show(
        Types.Error,
        EquipmentAssetMessages[
          this.props.assetEquipmentState.apiState.errorCode
        ]
      );
    }
    if (
      isGetError &&
      isGetError !== prevProps.assetEquipmentState.apiState.isGetError
    ) {
      Notification.show(
        Types.Error,
        EquipmentAssetMessages[
          this.props.assetEquipmentState.apiState.errorCode
        ]
      );
    }

    if (isErrorAddEquipmentType) {
      Notification.show(
        Types.Error,
        `${
          EquipmentTypeMessage[this.props.equipmentState.apiState.errorCode]
        } ${displayMessage}`
      );
      clearEquipmentState();
    }

    if (isErrorGetEquipmentType) {
      Notification.show(
        Types.Error,
        `${
          EquipmentTypeMessage[this.props.equipmentState.apiState.errorCode]
        } ${displayMessage}`
      );
      clearEquipmentState();
    }
  }

  componentWillUnmount() {
    this.disableEditForm();
  }

  goToComponent = () => {
    const { mode } = this.state;
    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        type: 'goTo',
        demandPage: mode === 'edit' ? null : Routes.assetLibrary,
      });
    } else {
      this.props.history.push(Routes.assetLibrary, {
        type: this.state.tabType,
      });
    }
  };

  getPageMode() {
    const {
      location: { state },
    } = this.props;
    if (state && state.mode) {
      if (state.mode === 'add') {
        this.setState({ formDisable: false, mode: 'add' });
      } else if (state.mode === 'edit') {
        this.setState({ formDisable: false, mode: 'edit' });
        this.getEquipment();
      } else {
        this.setState({ formDisable: true, mode: 'view' });
        this.getEquipment();
      }
    } else {
      this.props.history.push(Routes.addEquipment);
    }
  }

  getEquipment() {
    const {
      location: { state },
    } = this.props;
    if (state && state.data) {
      this.setFormData(_.cloneDeep(state.data));
    }
  }

  getEquipmentType() {
    const { getEquipmentType } = this.props;
    if (this.handleNetwork()) {
      getEquipmentType();
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk = () => {
    const { mode, equipmentData, type } = this.state;
    this.disableEditForm();
    if (type === 'cancel') {
      this.setState(
        {
          visible: false,
        },
        () => {
          if (mode === 'viewEdit') {
            this.setState(
              {
                mode: 'view',
                formDisable: true,
              },
              () => {
                this.props.form.resetFields();
                this.setFormData(equipmentData);
              }
            );
          } else {
            this.props.history.push(Routes.assetLibrary, {
              type: this.state.tabType,
            });
          }
        }
      );
    }
    if (type === 'goTo') {
      this.props.history.push(Routes.assetLibrary, {
        type: this.state.tabType,
      });
    }
  };

  formFields = (config) => [
    {
      label: ClientEquipmentVariables.EQUIPMENT_NAME,
      key: 'name',
      max: '50',
      required: [!config.validate, EquipmentMessage.REQUIRED_FIELD],
      pattern: [Pattern.customName.regx, Pattern.customName.message],
    },
    {
      label: ClientEquipmentVariables.EQUIPMENT_TYPE,
      key: 'type',
      type: 'dropdown',
      required: [!config.validate, EquipmentMessage.REQUIRED_FIELD],
    },
  ];

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  validateFieldsValue() {
    const values = this.props.form.getFieldsValue();
    const valuesKey = Object.keys(this.props.form.getFieldsValue());

    for (let i = 0; i < valuesKey.length; i++) {
      if (valuesKey[i] !== 'imgUrl') {
        values[valuesKey[i]] = values[valuesKey[i]]
          ? values[valuesKey[i]].trim()
          : values[valuesKey[i]];
      } else if (values[valuesKey[i]] === undefined) {
        this.setState({
          logoError: true,
        });
      }
    }
    this.props.form.setFieldsValue(values);
  }

  setFormData() {
    const { data } = this.props.location.state;

    if (data && data._id) {
      this.props.form.setFieldsValue(data, () => {
        this.disableEditForm();
      });
    }
  }

  onCancel = () => {
    const { mode, equipmentData } = this.state;
    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        type: 'cancel',
        demandPage: mode === 'edit' ? null : Routes.assetLibrary,
      });
    } else if (mode === 'add' || mode === 'edit') {
      this.props.history.push(Routes.assetLibrary, {
        type: this.state.tabType,
      });
    } else {
      this.setState(
        { mode: 'viewEdit' },
        () => {
          this.setState({
            formDisable: true,
            mode: 'view',
          });
        },
        () => {
          this.props.form.resetFields();
          this.setFormData(equipmentData);
        }
      );
    }
  };

  onEdit = () => {
    if (this.handleNetwork()) {
      this.setState({ mode: 'viewEdit' }, () => {
        this.setState({ formDisable: false });
      });
    }
  };

  saveMore() {
    this.setState({ addMore: true }, () => {
      this.saveData();
    });
  }

  save() {
    this.setState({ addMore: false }, () => {
      this.saveData();
    });
  }

  saveData() {
    this.validateFieldsValue();
    const { addAssetEquipment } = this.props;
    const { equipmentTypes, logoPath } = this.state;
    const a = equipmentTypes.map((item) => {
      const { nameId, _id } = item;
      return { nameId, _id };
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.imgUrl.file) {
          values.imgUrl = values.imgUrl.file.response.fileId;
        } else {
          values.imgUrl = logoPath;
        }
        values.type = a.find((item) => {
          if (item.nameId === values.type) {
            return item;
          }
          return null;
        });
        values.type = values.type._id;
        delete values.eqType;
        if (this.handleNetwork()) {
          addAssetEquipment(values);
        }
      } else {
      }
    });
  }

  update = () => {
    this.validateFieldsValue();
    const { updateAssetEquipment } = this.props;
    const {
      location: { state },
    } = this.props;
    const { equipmentTypes, logoPath } = this.state;
    const id = state.data._id;

    !this.state.formEdit
      ? this.props.history.push(Routes.assetLibrary, {
          type: this.state.tabType,
        })
      : this.props.form.validateFields((err, values) => {
          if (!err) {
            const tempValues = _.cloneDeep(values);
            if (values.imgUrl && tempValues.imgUrl.file) {
              values.imgUrl = tempValues.imgUrl.file.response.fileId;
            } else if (logoPath !== '') {
              values.imgUrl = logoPath;
            }
            const tempEquipmentType = equipmentTypes.find((item) => {
              const { nameId, _id } = item;
              if (_.toLower(nameId) === _.toLower(tempValues.type)) {
                return _id;
              }
              return undefined;
            });
            values.type = tempEquipmentType
              ? tempEquipmentType._id
              : tempValues.type;
            if (this.handleNetwork()) {
              delete values.eqType;
              updateAssetEquipment(values, id);
            }
          } else {
          }
        });
  };

  getEquipmentName() {
    const { equipmentData } = this.state;
    return equipmentData && equipmentData.name
      ? equipmentData.name
      : ClientEquipmentVariables.ADD_EQUIPMENT;
  }

  onImageUpload(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({ uploading: false });
    } else {
      this.setState({ uploading: true });
    }

    if (status === 'done') {
      this.dataPopup(info.file.response.fileId);
      Notification.show(Types.Success, fileMessage.success);
    }
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
          title={this.state.title}
        />
      );
    }
    return null;
  }

  renderFormButton() {
    const { mode } = this.state;

    if (mode === 'add') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button className="btn-default" onClick={() => this.save()}>
            {ButtonVariables.SAVE}
          </Button>
        </div>,
        ElementPermissions.ADD_EQUIPMENT
      );
    }
    if (mode === 'edit' || mode === 'viewEdit') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button
            className="btn-default"
            onClick={() => {
              this.update();
            }}
          >
            {ButtonVariables.UPDATE}
          </Button>
        </div>,
        ElementPermissions.EDIT_EQUIPMENT
      );
    }
    if (mode === 'view') {
      return elementsGaurd(
        <Button
          className="btn-default"
          onClick={() => {
            this.onEdit();
          }}
        >
          {ButtonVariables.EDIT}
        </Button>,
        ElementPermissions.EDIT_EQUIPMENT
      );
    }
  }

  getOption(data) {
    return (
      <Option className="option" value={data.nameId}>
        {data.type ? data.type : data.name}
      </Option>
    );
  }

  dataPopup = (path) => {
    this.setState({ logoPath: `${path}` }, () => {
      const imageUrl = getImageUrl(path);
      this.setState({ logoUrl: imageUrl, logoError: false });
    });
  };

  openAddModal(key) {
    this.setState({
      addModalType: key,
      addModalVisible: true,
    });
  }

  closeAddModal() {
    this.setState(
      {
        addModalVisible: false,
        addNewValue: null,
        error: false,
      },
      () =>
        this.props.form.setFieldsValue({
          eqType: this.state.addNewValue,
        })
    );
  }

  setAddNewValue(value) {
    this.setState({ addNewValue: value });
  }

  saveAddModalValue() {
    const { addNewValue } = this.state;
    const { addEquipmentType } = this.props;

    this.props.form.validateFields((err, _values) => {
      if (!err) {
        if (this.handleNetwork()) {
          if (addNewValue && addNewValue.trim() && addNewValue.trim().length) {
            addEquipmentType({ type: addNewValue });
          }
        }
      }
    });
  }

  renderAddModal() {
    const { addModalType, addModalVisible } = this.state;

    return (
      <Modal
        title={
          addModalType === 'type'
            ? ClientEquipmentVariables.ADD_NEW_EQUIPMENT_TYPE
            : ClientEquipmentVariables.ADD_NEW_MANUFACTURER
        }
        centered
        visible={addModalVisible}
        onOk={() => {
          this.saveAddModalValue();
        }}
        onCancel={() => {
          this.closeAddModal();
        }}
        okText="Save"
        cancelText="Cancel"
        className="eqTypeModal"
      >
        <Form>
          <Form.Item className={this.state.error && 'error'}>
            {addModalVisible &&
              this.props.form.getFieldDecorator('eqType', {
                rules: [
                  { required: true, message: EquipmentMessage.REQUIRED_FIELD },
                  { pattern: Pattern.Name.regx, message: Pattern.Name.message },
                ],
              })(
                <Input
                  onChange={(e) => {
                    this.setAddNewValue(e.target.value);
                  }}
                  maxlength={70}
                  required={[true, 'sads']}
                />
              )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    if (field.type) {
      if (field.type === 'dropdown') {
        let data = [];
        if (field.key === 'type') {
          data = this.state.equipmentTypes;
        }
        return (
          <Select
            onChange={(e) => {}}
            disabled={formDisable}
            showArrow={!formDisable}
            showSearch
            placeholder="Search here"
            className="dropdown-search-field"
            dropdownRender={(menu) => (
              <div>
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    this.openAddModal(field.key);
                  }}
                >
                  <Icon type="plus" />
                  {field.key === 'type'
                    ? ClientEquipmentVariables.ADD_NEW_EQUIPMENT_TYPE
                    : ClientEquipmentVariables.ADD_NEW_MANUFACTURER}
                </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </div>
            )}
          >
            {data.map((data) => this.getOption(data))}
          </Select>
        );
      }
      if (field.type === 'textarea') {
        return (
          <TextArea
            rows={5}
            maxLength={field.max ? field.max : ''}
            disabled={formDisable}
          />
        );
      }
      return (
        <Input
          maxLength={field.max ? field.max : ''}
          onBlur={field.blur ? field.blur : () => {}}
          autoComplete="off"
          disabled={formDisable}
        />
      );
    }
    return (
      <Input
        maxLength={field.max ? field.max : ''}
        onBlur={field.blur ? field.blur : () => {}}
        autoComplete="off"
        disabled={formDisable}
      />
    );
  }

  openViewModal = () => {
    this.setState({
      viewLogo: true,
    });
  };

  getLogo() {
    const { mode, equipmentData, logoUrl, logoPath } = this.state;
    if (mode === 'add' && logoUrl) {
      return <img src={logoUrl} alt="logo" height="100px" width="120px" />;
    }
    if (mode === 'view' && equipmentData) {
      if (equipmentData) {
        return (
          <img
            src={getImageUrl(equipmentData.imgUrl)}
            alt="logo"
            height="100px"
            width="120px"
          />
        );
      }
      return <Avatar src="" />;
    }
    if (
      (mode === 'edit' || mode === 'viewEdit') &&
      equipmentData &&
      equipmentData.imgUrl
    ) {
      return (
        <img
          src={
            _.isEmpty(logoPath)
              ? getImageUrl(equipmentData.imgUrl)
              : getImageUrl(logoPath)
          }
          alt="logo"
          height="100px"
          width="120px"
        />
      );
    }
    return (
      <div className="ant-upload">
        <p className="ant-upload-drag-icon">
          <img src={`${ic_download}`} alt="ic" />
        </p>
        <p className="ant-upload-text">{imageUpload.text}</p>
        <p className="ant-upload-hint">{imageUpload.hint}</p>
      </div>
    );
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { logoError, formDisable, mode, logoUrl, equipmentData } = this.state;
    return (
      <Form className="form">
        <div className="parent1">
          <Form.Item className={logoError ? 'upload-box-error' : 'upload-box'}>
            {getFieldDecorator(
              'imgUrl',
              {}
            )(
              mode === 'view' ? (
                <div className="viewModal">
                  {this.getLogo()}
                  <Button
                    onClick={this.openViewModal}
                    style={{ marginTop: '3px' }}
                  >
                    {EquipmentAssetVariable.OPEN_IMAGE}
                  </Button>
                </div>
              ) : !_.isEmpty(logoUrl) || equipmentData.imgUrl ? (
                <div style={{ height: '150px', paddingTop: 30 }}>
                  {this.getLogo()}
                  <div className="upload-icon">
                    <Upload
                      accept="image/svg+xml,image/png,image/jpg,image/jpeg"
                      onChange={(e) => {
                        this.onImageUpload(e);
                      }}
                      {...imgProps}
                      headers={{
                        authorization: getToken(),
                        api_key: process.env.REACT_APP_API_KEY,
                      }}
                      disabled={formDisable}
                      style={{
                        position: 'relative',
                        left: '25px',
                        top: '50px',
                      }}
                    >
                      <Icon
                        type="edit"
                        style={{ color: 'black', cursor: 'pointer' }}
                      />
                    </Upload>
                  </div>
                </div>
              ) : (
                <Dragger
                  accept="image/svg+xml,image/png,image/jpg,image/jpeg"
                  onChange={(e) => {
                    this.onImageUpload(e);
                  }}
                  {...imgProps}
                  headers={{
                    authorization: getToken(),
                    api_key: process.env.REACT_APP_API_KEY,
                  }}
                  disabled={formDisable}
                >
                  <div>{this.getLogo()}</div>
                </Dragger>
              )
            )}
          </Form.Item>

          <div className="parent2">
            {this.formFields({ validate: this.state.addModalVisible }).map(
              (field) => (
                <Form.Item label={field.label} style={field.style}>
                  {getFieldDecorator(field.key, {
                    rules: [
                      field.required
                        ? {
                            required: field.required[0],
                            message: field.required[1],
                          }
                        : '',
                      field.pattern && !this.state.addModalVisible
                        ? {
                            pattern: field.pattern[0],
                            message: field.pattern[1],
                          }
                        : '',
                    ],
                  })(this.renderFieldType(field))}
                </Form.Item>
              )
            )}
          </div>
        </div>
        <div className="action-btn">{this.renderFormButton()}</div>
      </Form>
    );
  }

  render() {
    const { uploading, equipmentData } = this.state;
    const { isLoading } = this.props.equipmentState;
    const { loading } = this.props.assetEquipmentState;

    return (
      <div className="AddEquipment">
        {isLoading || uploading || loading ? <Loader /> : null}
        <div className="header" style={MaskStyle}>
          <div className="Mask">
            <div className="title">
              {' '}
              <Icon type="left" onClick={this.goToComponent} />
              {EquipmentAssetVariable.ASSET_LIBRARY}
            </div>
            <div className="path">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <span onClick={() => this.goToComponent()}>
                    {EquipmentAssetVariable.EQUIPMENT}
                  </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {equipmentData && equipmentData.name
                    ? equipmentData.name
                    : EquipmentAssetVariable.ADD_EQUIPMENT}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="screen-content">
          <div className="content-div">{this.renderForm()}</div>
        </div>
        {this.renderModal()}
        {this.renderAddModal()}
        {this.state.viewLogo && (
          <Modal
            visible
            onCancel={(e) => this.setState({ viewLogo: false })}
            maskClosable
            closable
            footer={[]}
          >
            <div style={{ height: '450px', width: '470px' }}>
              <img
                alt=""
                src={getImageUrl(this.state.equipmentData.imgUrl)}
                height="430px"
                width="450px"
                style={{ marginTop: '30px' }}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

AddEquipment.prototypes = {
  equipmentState: PropTypes.object,
  assetEquipmentState: PropTypes.object,
  network: PropTypes.object,
  getEquipmentType: PropTypes.func,
  getEquipment: PropTypes.func,
  updateAssetEquipment: PropTypes.func,
  addEquipmentType: PropTypes.func,
  addAssetEquipment: PropTypes.func,
  clearEquipmentState: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

AddEquipment.defaultProps = {
  equipmentState: {},
  assetEquipmentState: {},
  network: {},
  getEquipmentType: () => {},
  getEquipment: () => {},
  updateAssetEquipment: () => {},
  addEquipmentType: () => {},
  addAssetEquipment: () => {},
  clearEquipmentState: () => {},
  history: {},
  location: {},
  match: {},
};

const mapStateToProps = (state) => ({
  equipmentState: state.EquipmentReducer,
  assetEquipmentState: state.assetEquipmentReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = {
  getEquipmentType,
  getEquipmentAsset,
  updateAssetEquipment,
  addEquipmentType,
  addAssetEquipment,
  clearEquipmentState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'addEquipment',
    onFieldsChange: (props, fields, allfields) => {
      window.dispatchEvent(new Event('form-edit'));
    },
  })(withRouter(AddEquipment))
);
