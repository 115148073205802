import Type from "../Types/liveData.type";

const INITIAL_STATE = {
  liveData: null,
  isSuccess: false,
  isError: false,
  isloading: false,
  errorMsg: null,
};

export default function liveDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.GET_STATISTICS_PROGRESS:
      return {
        ...state,
        isloading: true,
        isSuccess: false,
        isError: false,
      };

    case Type.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        isloading: false,
        isSuccess: true,
        liveData: action.payload.body,
      };

    case Type.GET_STATISTICS_FAILURE:
      return {
        ...state,
        isloading: false,
        errorMsg: action.payload.response && action.payload.response,
        isError: true,
      };
    case Type.GET_STATISTICS_CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
