import {
  TAGS_BY_COPMONENT_CLEAR_STATE,
  TAGS_BY_COPMONENT_FETCH_ERROR,
  TAGS_BY_COPMONENT_FETCH_PROGRESS,
  TAGS_BY_COPMONENT_FETCH_SUCCESS,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TAGS_BY_COPMONENT_FETCH_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case TAGS_BY_COPMONENT_FETCH_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case TAGS_BY_COPMONENT_FETCH_SUCCESS:
      const tags = action.payload.data
        .filter((element) => element.tag || element.piTag)
        .map((tagElement) => {
          if (tagElement.piTag) {
            const { piTag, _id, type, variable } = tagElement;
            const { variableName } = variable;
            return {
              name: piTag,
              _id,
              type,
              variableName,
              variableNameTag: `${variableName} (${piTag})`,
            };
          }
          if (typeof tagElement.tag === "object") {
            const { tag } = tagElement;
            const { piTag, _id, type, piVariableName } = tag;
            return {
              name: piTag,
              _id,
              type,
              variableName: piVariableName,
              variableNameTag: `${piVariableName} (${piTag})`,
            };
          }
          const { tag, _id, variable } = tagElement;
          const { variableName } = variable;
          return {
            name: tag,
            _id,
            type: "calculated",
            variableName,
            variableNameTag: `${variableName} (${tag})`,
          };
        });
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: tags,
      };
    case TAGS_BY_COPMONENT_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
};
