import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import { saveIterationData } from '../../../../../../redux/actions/PIMComponent.action';
import {
  tableColumsRoughnessRange,
  tableColumsSoilTempVariation,
  tableAddRowRoughnessRange,
  tableAddRowSoilTempVariation,
  createPayload,
  iterationDataFormField,
} from './PIMComponentIterationData.Constants';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import FormFields from '../../../../../../common/FormFields/FormFields';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  NO_INTERNET,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  CurvesMessages,
} from '../../../../../../constants/messages';
import {
  getDataUnits,
  clearDataUnitState,
} from '../../../../../../redux/actions/dataUnit.action';
import Loader from '../../../../../../common/Loader/Loader';

class IterationDataPIMComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preserveCurveData: {},
      editableRows: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      updatedFields: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      dirtyRows: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      formEdit: false,
      iterationData: {
        soilTempVariation: [],
        roughnessRange: [],
      },
      addFieldValue: {
        pressurePercError: '',
        temperaturePercError: '',
        roughnessRange: '',
        soilTempVariation: '',
      },
      isErrorSoilTempVariation: {
        soilTempVariation: false,
        temperaturePercError: false,
      },
      isErrorRoughnessRange: {
        roughnessRange: false,
        pressurePercError: false,
      },
      unitData: [],
      infoVisible: false,
      visibleGraph: false,
      yAxisValue: '',
      unitMapper: {},
    };
  }
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  componentDidMount() {
    const { unitData } = this.props.dataUnitState.apiState;
    const {
      PIMConfigData: { iterationData },
    } = this.props.PIMComponentReducer;

    !_.isEmpty(iterationData) && this.setData(iterationData);
    this.setState({
      unitData: unitData && unitData.items ? unitData.items : [],
    });
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { addIterationData } = this.props.PIMComponentReducer;

    if (
      addIterationData.isSuccess &&
      prevProps.PIMComponentReducer.addIterationData.isSuccess !==
        addIterationData.isSuccess
    ) {
      this.disableEditForm();
      Notification.show(Types.Success, CurvesMessages.ADDED);
    }
    if (
      (addIterationData.isErrorSoilTempVariation &&
        prevProps.PIMComponentReducer.addIterationData
          .isErrorSoilTempVariation !==
          addIterationData.isErrorSoilTempVariation) ||
      (addIterationData.isErrorRoughnessRange &&
        prevProps.PIMComponentReducer.addIterationData.isErrorRoughnessRange !==
          addIterationData.isErrorRoughnessRange)
    ) {
      Notification.show(Types.Error);
    }
  }

  setData(iterationData) {
    if (iterationData) {
      const { data } = iterationData;
      const formData = iterationData.dataUnit;

      this.setState({
        iterationData: {
          soilTempVariation: [...data.soilTempVariation],
          roughnessRange: [...data.roughnessRange],
        },
      });
      this.props.form.setFieldsValue(formData, () => {
        this.disableEditForm();
      });
    }
  }

  validateFields(values, table) {
    let status = true;
    const { isErrorSoilTempVariation, isErrorRoughnessRange } = this.state;
    const temp =
      table === 'soilTempVariation'
        ? { ...isErrorSoilTempVariation }
        : { ...isErrorRoughnessRange };

    Object.keys(temp).forEach((item) => {
      if (
        (!values[item] && values[item] !== 0) ||
        (values[item] &&
          (values[item].length === 0 || values[item].trim().length === 0))
      ) {
        temp[item] = true;
        status = false;
      } else {
        temp[item] = false;
      }
    });

    table === 'soilTempVariation'
      ? this.setState({ isErrorSoilTempVariation: temp })
      : this.setState({ isErrorRoughnessRange: temp });

    return status;
  }

  addRow(table) {
    let dataToValidate = {};
    if (table === 'soilTempVariation') {
      dataToValidate = {
        temperaturePercError: this.state.addFieldValue.temperaturePercError,
        soilTempVariation: this.state.addFieldValue.soilTempVariation,
      };
    } //roughnessRange
    else {
      dataToValidate = {
        pressurePercError: this.state.addFieldValue.pressurePercError,
        roughnessRange: this.state.addFieldValue.roughnessRange,
      };
    }
    if (this.validateFields(dataToValidate, table)) {
      const { addFieldValue } = this.state;

      table === 'soilTempVariation'
        ? this.setState({
            iterationData: {
              ...this.state.iterationData,
              soilTempVariation: [
                ...this.state.iterationData.soilTempVariation,
                { ...addFieldValue },
              ],
            },
            addFieldValue: {
              ...this.state.addFieldValue,
              soilTempVariation: '',
              temperaturePercError: '',
            },
          })
        : this.setState({
            iterationData: {
              ...this.state.iterationData,
              roughnessRange: [
                ...this.state.iterationData.roughnessRange,
                { ...addFieldValue },
              ],
            },
            addFieldValue: {
              ...this.state.addFieldValue,
              roughnessRange: '',
              pressurePercError: '',
            },
          });
    } else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
  }

  onAddFieldChange(row, col, field, table) {
    const { addFieldValue } = this.state;

    const temp =
      table === 'soilTempVariation'
        ? {
            temperaturePercError: addFieldValue.temperaturePercError,
            soilTempVariation: addFieldValue.soilTempVariation,
          }
        : {
            pressurePercError: addFieldValue.pressurePercError,
            roughnessRange: addFieldValue.roughnessRange,
          };
    temp[field.name] =
      parseFloat(field.value) === 0
        ? parseFloat(field.value)
        : field.value.replace(/^0+/, '');
    this.setState({ addFieldValue: temp }, () => {
      this.validateFields(this.state.addFieldValue, table);
      this.enableEditForm();
    });
  }

  onEditFieldChange(row, col, field, table) {
    const tableData = { ...this.state.iterationData };

    const temp = { ...tableData[table][row] };
    temp[field.name] =
      parseFloat(field.value) === 0
        ? `${parseFloat(field.value)}`
        : field.value.replace(/^0+/, '');
    const tempCurveData = { ...tableData };
    tempCurveData[table][row] = { ...temp };

    this.setState(
      {
        iterationData: tempCurveData,
      },
      () => {
        this.setDirtyRows(table, row);
      }
    );
  }

  setDirtyRows(table, row) {
    const { dirtyRows } = this.state;
    const tableData = _.cloneDeep(this.state.iterationData);
    const tableDataRow = tableData[table][row];
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    if (
      (table === 'soilTempVariation' &&
        ((tableDataRow['soilTempVariation'] &&
          !tableDataRow['soilTempVariation'].toString()) ||
          !tableDataRow['soilTempVariation'].toString().length ||
          !tableDataRow['temperaturePercError'].toString() ||
          !tableDataRow['temperaturePercError'].toString().length)) ||
      (table === 'roughnessRange' &&
        ((tableDataRow['roughnessRange'] &&
          !tableDataRow['roughnessRange'].toString()) ||
          !tableDataRow['roughnessRange'].toString().length ||
          !tableDataRow['pressurePercError'].toString() ||
          !tableDataRow['pressurePercError'].toString().length))
    ) {
      tempDirtyRows[table].push(row);
    } else {
      tempDirtyRows[table] = tempDirtyRows[table].filter((e) => e !== row);
    }
    this.setState({
      dirtyRows: tempDirtyRows,
    });
  }

  edit(row, table) {
    const { updatedFields, editableRows } = this.state;
    const tableData = _.cloneDeep(this.state.iterationData);
    const updatedTemp = _.cloneDeep(updatedFields);
    const tempEditRows = _.cloneDeep(editableRows);
    updatedTemp[table][row] = _.cloneDeep(tableData[table][row]);
    tempEditRows[table].push(row);
    this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
        editableRows: tempEditRows,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row, table) {
    const { updatedFields, dirtyRows, editableRows } = this.state;
    const tempTableData = _.cloneDeep(this.state.iterationData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData[table][row] = _.cloneDeep(tempupdatedField[table][row]);
    tempEditRows[table] = tempEditRows[table].filter((e) => e !== row);
    tempDirtyRows[table] = tempDirtyRows[table].filter((e) => e !== row);
    tempupdatedField[table][row] = undefined;
    this.setState({
      iterationData: tempTableData,
      dirtyRows: tempDirtyRows,
      updatedFields: tempupdatedField,
      editableRows: tempEditRows,
    });
  }

  deleteRow(row, table) {
    const { dirtyRows, updatedFields, editableRows } = this.state;
    const tempTableData = _.cloneDeep(this.state.iterationData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData[table].splice(row, 1);
    tempupdatedField[table].splice(row, 1);
    tempDirtyRows[table] = tempDirtyRows[table].filter((e) => e !== row);
    tempEditRows[table] = tempEditRows[table].filter((e) => e !== row);
    this.setState(
      {
        iterationData: tempTableData,
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
        editableRows: tempEditRows,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  save() {
    const {
      location: { state },
    } = this.props;
    const { saveIterationData } = this.props;
    const { iterationData } = this.state;

    this.props.form.validateFields((err, values) => {
      if (err) {
        throw err;
      }
      const lengthCheck =
        this.state.dirtyRows?.soilTempVariation?.length === 0 &&
        this.state.dirtyRows?.roughnessRange?.length === 0;
      const curveLength =
        iterationData.roughnessRange.length >= 3 &&
        iterationData.soilTempVariation.length >= 3;
      if (!lengthCheck) {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
        return;
      }
      if (!curveLength) {
        Notification.show(Types.Info, CurvesMessages.MIN_DATA);
        return;
      }
      const payload = createPayload(values, iterationData);
      if (this.handleNetwork() && state.componentData) {
        saveIterationData(state.componentData.info._id, payload);
      }
    });
  }

  clearDirtyForm() {
    this.setState({
      editableRows: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      updatedFields: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      dirtyRows: {
        roughnessRange: [],
        soilTempVariation: [],
      },
      formEdit: false,
      addFieldValue: {
        roughnessRange: '',
        soilTempVariation: '',
        pressurePercError: '',
        temperaturePercError: '',
      },
      isErrorSoilTempVariation: {
        soilTempVariation: false,
        temperaturePercError: false,
      },
      isErrorRoughnessRange: {
        roughnessRange: false,
        pressurePercError: false,
      },
    });
  }

  reset() {
    this.setState({
      visible: true,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const {
      PIMConfigData: { iterationData },
    } = this.props.PIMComponentReducer;

    this.setState(
      {
        visible: false,
      },
      () => {
        !_.isEmpty(iterationData.data) && this.setData(iterationData);
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  onChangeSelect(value, key) {
    this.enableEditForm();
  }

  renderIterationData() {
    const { addFieldValue } = this.state;
    return (
      <div>
        <div>
          <FormFields
            formFields={iterationDataFormField(this.state.unitData, {
              change: (e, key) => {
                this.onChangeSelect(e, key);
              },
              isCurveEditable: this.props.isSchematicEditable,
            })}
            form={this.props.form}
          />
        </div>
        <div className="table-curve">
          <CustomTable
            showHeader
            columns={tableColumsSoilTempVariation({
              isCurveEditable: this.props.isSchematicEditable,
              onChange: (row, col, field) => {
                this.onEditFieldChange(row, col, field, 'soilTempVariation');
              },
              edit: (row) => {
                this.edit(row, 'soilTempVariation');
              },
              deleteRow: (row) => {
                this.deleteRow(row, 'soilTempVariation');
              },
              undo: (row) => {
                this.undo(row, 'soilTempVariation');
              },
              process: this.props.process,
            })}
            editableRow={this.state.editableRows['soilTempVariation']}
            data={this.state.iterationData?.soilTempVariation}
            renderAddRow={
              this.props.isSchematicEditable
                ? this.state.iterationData?.soilTempVariation.length < 15
                  ? tableAddRowSoilTempVariation({
                      onChange: (row, col, field) => {
                        this.onAddFieldChange(
                          row,
                          col,
                          field,
                          'soilTempVariation'
                        );
                      },
                      submit: (e) => {
                        this.addRow('soilTempVariation');
                      },
                      isError: this.state.isErrorSoilTempVariation,
                    })
                  : null
                : null
            }
            addFieldValues={{
              temperaturePercError: addFieldValue.temperaturePercError,
              soilTempVariation: addFieldValue.soilTempVariation,
            }}
          />
          <CustomTable
            showHeader
            columns={tableColumsRoughnessRange({
              isCurveEditable: this.props.isSchematicEditable,
              onChange: (row, col, field) => {
                this.onEditFieldChange(row, col, field, 'roughnessRange');
              },
              edit: (row) => {
                this.edit(row, 'roughnessRange');
              },
              deleteRow: (row) => {
                this.deleteRow(row, 'roughnessRange');
              },
              undo: (row) => {
                this.undo(row, 'roughnessRange');
              },
              process: this.props.process,
            })}
            editableRow={this.state.editableRows['roughnessRange']}
            data={this.state.iterationData?.roughnessRange}
            renderAddRow={
              this.props.isSchematicEditable
                ? this.state.iterationData?.roughnessRange.length < 15
                  ? tableAddRowRoughnessRange({
                      onChange: (row, col, field) => {
                        this.onAddFieldChange(
                          row,
                          col,
                          field,
                          'roughnessRange'
                        );
                      },
                      submit: (e) => {
                        this.addRow('roughnessRange');
                      },
                      isError: this.state.isErrorRoughnessRange,
                    })
                  : null
                : null
            }
            addFieldValues={this.state.addFieldValue}
          />
        </div>
      </div>
    );
  }

  render() {
    const dataUnitLoading = this.props.dataUnitState.loading;
    const { PIMComponentReducer } = this.props;
    return (
      <div className="Curves">
        <div className="CurvesInner">
          {dataUnitLoading || PIMComponentReducer.isLoading ? <Loader /> : null}
          {this.renderIterationData()}

          {this.state.formEdit && this.props.isSchematicEditable ? (
            <div className="config-bottom-buttons">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  this.reset();
                }}
              >
                {ButtonVariables.RESET}
              </button>
              <button
                className="btn-default"
                onClick={() => {
                  this.save();
                }}
              >
                {ButtonVariables.SAVE}
              </button>
            </div>
          ) : null}
          {this.renderModal()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dataUnitState: state.DataUnitReducer,
  PIMComponentReducer: state.PIMComponentReducer,
  network: state.NetworkReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getDataUnits,
  clearDataUnitState,
  saveIterationData: (componentId, payload) =>
    dispatch(saveIterationData(componentId, payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(IterationDataPIMComponent))
);
