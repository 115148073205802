import {
  ADD_ADDNEWCARD_ERROR,
  ADD_ADDNEWCARD_PROGRESS,
  ADD_ADDNEWCARD_SUCCESS,
  KPI_BY_LEVEL_FETCH_ERROR,
  KPI_BY_LEVEL_FETCH_PROGESS,
  KPI_BY_LEVEL_FETCH_SUCCESS,
  KPI_BY_LEVEL_CLEAR_STATE,
} from "../Types/addNewCard.types";

import { POSTRequest, GETRequest } from "../../api/request";
import { addDataIDB } from "../../services/indexedDB";
import {
  KPI_BY_PANEL_FETCH_ERROR,
  KPI_BY_PANEL_FETCH_PROGESS,
  KPI_BY_PANEL_FETCH_SUCCESS,
  KPI_BY_PANEL_CLEAR_STATE,
} from "../AddNewCard/addNewCard.types";

export function addNewCard(levelName, levelId, payload) {
  // todo delte this one
  return function (dispatch) {
    dispatch({ type: ADD_ADDNEWCARD_PROGRESS });
    POSTRequest(`kpis/${levelName}/${levelId}`, payload)
      .then((res) => {
        dispatch({ type: ADD_ADDNEWCARD_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_ADDNEWCARD_ERROR, payload: err });
      });
  };
}

export function getKPIByLevelId(levelName, levelId) {
  return function (dispatch) {
    dispatch({ type: KPI_BY_LEVEL_FETCH_PROGESS });
    GETRequest(`kpis/${levelName}/${levelId}`)
      .then((res) => {
        // append ids into IDB
        // also check weather id exist in IDB
        addDataIDB(res.body.data);
        return dispatch({
          type: KPI_BY_LEVEL_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) =>
        dispatch({ type: KPI_BY_LEVEL_FETCH_ERROR, payload: err })
      );
  };
}

export function getKPIByPanelType(levelName, levelId, panelType) {
  return function (dispatch) {
    dispatch({ type: KPI_BY_PANEL_FETCH_PROGESS });
    GETRequest(`kpis/${levelName}/${levelId}/${panelType}`)
      .then((res) => {
        // append ids into IDB
        // also check weather id exist in IDB
        addDataIDB(res.body.data);
        return dispatch({
          type: KPI_BY_PANEL_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) =>
        dispatch({ type: KPI_BY_PANEL_FETCH_ERROR, payload: err })
      );
  };
}

export function clearKPIState() {
  return function (dispatch) {
    dispatch({ type: KPI_BY_LEVEL_CLEAR_STATE });
  };
}

export const clearKPIByPanelState = () => (dispatch) =>
  dispatch({ type: KPI_BY_PANEL_CLEAR_STATE });
