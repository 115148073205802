const resetCurve = (name, updatedCurveData, screwCompCurveData, componentID) => {
    let emptyResetData = {
        data: {asTestedCurves: [], operationalBaseLineCurves: []},
        dataUnit:{} 
    }
    if (name === "adiabaticEfficiency") {
        if (updatedCurveData.data
            && updatedCurveData.data.curvesData
            && updatedCurveData.data.curvesData.adiabaticEfficiency
            && updatedCurveData.data.curvesData.adiabaticEfficiency.data
            && updatedCurveData?.data?.component === componentID
        ) {
            return updatedCurveData.data.curvesData.adiabaticEfficiency
        }
        else if (screwCompCurveData
            && screwCompCurveData.curvesData
            && screwCompCurveData.curvesData.adiabaticEfficiency
            && screwCompCurveData.curvesData.adiabaticEfficiency.data
            && screwCompCurveData?.component?._id === componentID
        ) {
            return screwCompCurveData.curvesData.adiabaticEfficiency
        }
        else {
            return emptyResetData
            //return Notification.show(Types.Error, screwCompValidation.resetFailed)
        }
    }
    else if (name === "volumetricRatio" || name === "capacityControl") {
        if (updatedCurveData.data
            && updatedCurveData.data.curvesData
            && updatedCurveData.data.curvesData[name]
            && updatedCurveData.data.curvesData[name].data
            && updatedCurveData?.data?.component === componentID
        ) {
            return updatedCurveData.data.curvesData[name]
        }
        else if (screwCompCurveData
            && screwCompCurveData.curvesData
            && screwCompCurveData.curvesData[name]
            && screwCompCurveData.curvesData[name].data
            && screwCompCurveData?.component?._id === componentID
        ) {
            return screwCompCurveData.curvesData[name]
        }
        else {
            return emptyResetData
            //return Notification.show(Types.Error, screwCompValidation.resetFailed)
        }
    }
}

export default resetCurve