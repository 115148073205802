import React from 'react';
import { Icon, Input } from 'antd';
import { newValidateNumber } from '../../../../../../../libs/validator';

export const curvesColumnsTitle = {
  valvePosition: 'Valve Position',
  valveFlowCoefficient: 'Valve Flow Coefficient',
};
export const curvesColumnsName = {
  valvePosition: 'valvePosition',
  valveFlowCoefficient: 'valveFlowCoefficient',
};

export const DataUnit = ['Efficiency'];

export const dataUnitsVariables = {
  valvePosition: 'Efficiency',
};

export const curvesLabel = {
  operationCharCurve: 'Operational Characteristic Curve',
  theoreticalCharCurve: 'Theoretical Characteristic Curve',
  selectUnits: 'Select Units',
};

export const graphDetails = {
  title: 'Curve',
  xAxis: 'Position',
  yAxis: 'Flow Coefficient',
  requiredMessage: 'Mininum three data is required',
  operationCharCurve: 'Operational Characteristic Curve',
  theoreticalCharCurve: 'Theoretical Characteristic Curve',
};

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const CharCurveFormFieldOp = (units, config, req) => [
  {
    label: 'Valve Position',
    key: 'valvePositionOp',
    type: 'dropdown',
    required: [req, ''],
    value: getUnit(units, 'Efficiency')[0],
    data: getUnit(units, 'Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const CharCurveFormFieldTheo = (units, config) => [
  {
    label: 'Valve Position',
    key: 'valvePositionTheo',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'Efficiency')[0],
    data: getUnit(units, 'Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.valvePosition,
    dataIndex: curvesColumnsName.valvePosition,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.valvePosition}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: curvesColumnsTitle.valveFlowCoefficient,
    dataIndex: curvesColumnsName.valveFlowCoefficient,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.valveFlowCoefficient}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },

  config.isCurveEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.valvePosition,
    dataIndex: curvesColumnsName.valvePosition,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.valvePosition}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.valvePosition]
            ? 'error'
            : 'not-error'
        }
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.valveFlowCoefficient,
    dataIndex: curvesColumnsName.valveFlowCoefficient,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.valveFlowCoefficient}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.valveFlowCoefficient]
            ? 'error'
            : 'not-error'
        }
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const initialValue = {
  operationCharCurve: [
    {
      valvePosition: {
        value: 0,
        unit: '%',
      },
      valveFlowCoefficient: {
        value: 0,
      },
    },
  ],
  theoreticalCharCurve: [
    {
      valvePosition: {
        value: 0,
        unit: '%',
      },
      valveFlowCoefficient: {
        value: 0,
      },
    },
  ],
};

export const createCurvesPayload = (
  formfieldValue,
  flowCoefficientCurveOp = [],
  flowCoefficientCurveTheo = []
) => {
  const { valvePositionOp, valvePositionTheo } = formfieldValue;
  const dataflowCoefficientCurveOp = flowCoefficientCurveOp.map((item) => {
    const { valvePosition, valveFlowCoefficient } = item;
    return {
      valvePosition: parseFloat(valvePosition),
      valveFlowCoefficient: parseFloat(valveFlowCoefficient),
    };
  });
  const dataflowCoefficientCurveTheo = flowCoefficientCurveTheo.map((item) => {
    const { valvePosition, valveFlowCoefficient } = item;
    return {
      valvePosition: parseFloat(valvePosition),
      valveFlowCoefficient: parseFloat(valveFlowCoefficient),
    };
  });

  const payload = {
    flowCoefficientCurve: {
      data: {
        flowCoefficientCurveTheo: dataflowCoefficientCurveTheo,
        flowCoefficientCurveOp: dataflowCoefficientCurveOp,
      },
      dataUnit: {
        flowCoefficientCurveTheo: { valvePosition: valvePositionTheo },
        flowCoefficientCurveOp: {
          valvePosition:
            dataflowCoefficientCurveOp.length > 0 ? valvePositionOp : null,
        },
      },
    },
  };
  return payload;
};

export const curveInput = (data, yAxisValue, config) => {
  return [
    {
      label: 'Characteristic',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '250px', padding: '10px' },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};

export const curveVariables = {
  'Operational Characteristics': 'flowCoefficientCurveOp',
  'Theoretical Characteristics': 'flowCoefficientCurveTheo',
};
