const generateAlertPayload = (alertData) => {
    const fields = ['level', 'type', 'dateTime', 'classification', 'editedBy', 'updatedAt',
        'description', 'comment', 'labels', 'assignees', 'eventName', 'insights']
    let payload = {}

    for (var index = 0; index < fields.length; index++) {
        let key = fields[index]
        let tempData = { [key]: alertData[fields[index]] }
        payload = { ...payload, ...tempData }
    }
    return payload

}

export { generateAlertPayload } 