import { Icon, Input } from 'antd';
import React from 'react';
import { validatePositiveNumber } from '../../../../../../libs/validator';
import { hvsgPerformanceDataVariables } from '../../../../../../constants/variables';

export const keyChange = {
  [hvsgPerformanceDataVariables.designPower]: 'designPower',
  [hvsgPerformanceDataVariables.designTorque]: 'designTorque',
  [hvsgPerformanceDataVariables.inputSpeed]: 'inputSpeed',
  [hvsgPerformanceDataVariables.workingFluidCp]: 'workingFluidCp',
  [hvsgPerformanceDataVariables.designSpeedRatio]: 'designSpeedRatio',
  [hvsgPerformanceDataVariables.designOutputSpeed]: 'designOutputSpeed',
  [hvsgPerformanceDataVariables.powerMaxThreshold]: 'powerMaxThreshold',
  [hvsgPerformanceDataVariables.powerMinThreshold]: 'powerMinThreshold',
  [hvsgPerformanceDataVariables.fluidSpecificGravity]: 'fluidSpecificGravity',
  [hvsgPerformanceDataVariables.startupSpeed]: 'startupSpeed',
  [hvsgPerformanceDataVariables.startupPower]: 'startupPower',
};

export const initialData = Object.values(hvsgPerformanceDataVariables).map(
  (item) => {
    return {
      name: item,
      design: parseFloat(''),
      unit: null,
    };
  }
);

const getNumberInputDesign = (item, disabled, config) => {
  const tempError = !!(
    config.dirtyValues.indexOf(item.name) >= 0 &&
    (item.design === '' || !item.design) &&
    config.saveListener
  );

  return (
    <div>
      <Input
        type="number"
        value={item.design}
        disabled={disabled}
        AutoComplete="off"
        onKeyDown={(e) => {
          validatePositiveNumber(e);
        }}
        onChange={(e) =>
          config.handleChange(e.target.value, item.name, 'number')
        }
        className={tempError ? 'error' : 'not-error'}
      />
    </div>
  );
};

const getPerformanceDataUnits = (item, disabled, config) => {
  const unitError = !!(
    config.dirtyUnits.indexOf(item.name) >= 0 &&
    (item.unit === '' ||
      item.unit === undefined ||
      item.unit === null ||
      item.unit === 'Select') &&
    config.saveFlag
  );
  return (
    <div
      className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
        unitError ? 'error' : 'not-error'
      }  `}
    >
      <select
        value={!item.unit ? '' : item.unit}
        disabled={disabled}
        onChange={(e) => config.handleChange(e.target.value, item.name, 'unit')}
      >
        <option value="Select">Select</option>
        {config.unitData.map((getUnits) => {
          if (getUnits.dataVariable === item.name) {
            return getUnits.units.map((dispUnit) => (
              <option value={dispUnit}>{dispUnit}</option>
            ));
          }
          return undefined;
        })}
      </select>
    </div>
  );
};

export const hvsgPerformanceDataTable = (config) => [
  {
    title: 'Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          {value} <span className="mandatory">*</span>
        </div>
      );
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      return getPerformanceDataUnits(item, disabled, config);
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      return getNumberInputDesign(item, disabled, config);
    },
  },
  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const hvsgPerformanceData = (displayData) => {
  return displayData.filter((item) => {
    if (Object.values(hvsgPerformanceDataVariables).includes(item.name)) {
      return item;
    }
    return undefined;
  });
};

export const validateData = (displayData, setDirtyValues, setDirtyUnits) => {
  let mandatoryCheckError = false;
  let unitCheckError = false;
  let dirtyUnits = [];
  let dirtyRows = [];
  displayData.forEach((item) => {
    if (
      !item.design ||
      item.design === 'Select' ||
      (typeof item.design != 'string' && isNaN(item.design))
    ) {
      mandatoryCheckError = true;
      dirtyRows.push(item.name);
    }

    if (!item.unit || item.unit === 'Select') {
      dirtyUnits.push(item.name);
      unitCheckError = true;
    }
  });
  setDirtyValues(dirtyRows);
  setDirtyUnits(dirtyUnits);

  return mandatoryCheckError || unitCheckError;
};
