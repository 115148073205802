import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomTable from "../../../../../common/CustomTable/CustomTable";
import Loader from "../../../../../common/Loader/Loader";
import { validatePositiveFloat } from "../../../../../common/methods";
import ConfirmModal from "../../../../../common/Modal/Modal";
import Notification, {
  Types,
} from "../../../../../common/Notification/Notification";
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  RunnerDataMessages,
} from "../../../../../constants/messages";
import { ButtonVariables } from "../../../../../constants/variables";
import {
  clearDataUnitState,
  getDataUnits,
} from "../../../../../redux/actions/dataUnit.action";
import {
  addEditRunnerData,
  clearRunnerReducerState,
} from "../../../../../redux/actions/runnerData.action";
import { Liquid_Expander } from "../ConfigDataConstants";
import { calculateCurvesData } from "../CurvesData/CurvesUtils";
import {
  getRunnerOrImpellerArray,
  runnerTableAddRow,
  runnerTableColumns,
} from "./RunnerData.constants";
import "./RunnerData.scss";

class RunnerData extends Component {
  constructor(props) {
    super(props);
    const { info } = this.props.location.state.componentData;

    this.state = {
      dirtyRows: [],
      runnerData: [],
      preserveRunnerData: [],
      editRows: [],
      addFieldValue: {
        name: info.name === Liquid_Expander ? "Runner 1" : "Impeller 1",
        type: "",
        diameter: "",
        minDiameter: "",
        maxDiameter: "",
        unit: "",
      },
      isError: {
        name: false,
        type: false,
        diameter: false,
        minDiameter: false,
        maxDiameter: false,
        unit: false,
      },
      updatedFields: [],
      visible: false,
      message: FORM_DISCARD_MSG,
      unitData: {},
      changeUnitSelected: "",
      preserveRunnerDataforUndo: [],
      curvesData: "",
      visibleSave: false,
    };
  }

  componentDidMount() {
    this.getRunnerIndex();

    window.addEventListener("form-edit", () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener("form-edit-false", (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }

  componentDidUpdate() {
    const {
      isConfigData,
      configData,
      clearConfigState,
      clearRunnerReducerState,
    } = this.props;
    const {
      isSuccessFetchRunner,
      isErrorFetchRunner,
      errorCode,
    } = this.props.runnerDataState.apiState;
    const {
      unitData,
      isSuccessDataUnitFetch,
    } = this.props.dataUnitState.apiState;
    const { clearDataUnitState } = this.props;
    const { name } = this.props.location.state.componentData.assetInfo;

    if (isSuccessDataUnitFetch) {
      clearDataUnitState();
      this.setState({
        unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
      });
      this.setState({
        addFieldValue: {
          ...this.state.addFieldValue,
          type: "",
          diameter: "",
          minDiameter: "",
          maxDiameter: "",
          unit: "",
        },
      });
    }

    if (isConfigData) {
      this.setState({
        curvesData: configData.curvesData,
      });

      clearConfigState();
      this.disableEditForm();
      const setPreserveRunnerData =
        name === Liquid_Expander
          ? configData && configData.runnerData
            ? _.cloneDeep(configData.runnerData)
            : null
          : configData && configData.impellerData
            ? _.cloneDeep(configData.impellerData)
            : null;
      this.setState({
        preserveRunnerData: _.cloneDeep(setPreserveRunnerData),
        preserveRunnerDataforUndo: _.cloneDeep(
          setPreserveRunnerData && setPreserveRunnerData.data
        ),
      });
      const onSetRunnerData =
        name === Liquid_Expander
          ? configData && configData.runnerData
            ? _.cloneDeep(configData.runnerData)
            : []
          : configData && configData.impellerData
            ? _.cloneDeep(configData.impellerData)
            : [];
      this.setRunnerData(onSetRunnerData);
      if (this.props.dataUnitState.apiState.unitData) {
        this.setState({
          unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
        });
      }
    }

    if (isSuccessFetchRunner) {
      clearRunnerReducerState();
      Notification.show(
        Types.Success,
        name === Liquid_Expander
          ? RunnerDataMessages.RUNNERSUCCESS
          : RunnerDataMessages.IMPELERSUCCESS
      );
      this.setState({
        editRows: [],
        updatedFields: [],
      });
      this.setState({
        addFieldValue: {
          ...this.state.addFieldValue,
          type: "",
          diameter: "",
          minDiameter: "",
          maxDiameter: "",
          unit: "",
        },
      });
    }

    if (isErrorFetchRunner) {
      clearRunnerReducerState();
      Notification.show(Types.Error, RunnerDataMessages[errorCode]);
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event("form-edit-false"));
  }

  enableEditForm() {
    window.dispatchEvent(new Event("form-edit"));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  setRunnerData(data) {
    if (data && data.data) {
      const runnerData = [...data.data];
      const sortedRunnerData = _.sortBy(runnerData, (val) =>
        val.name.split(" ")[1]
          ? parseInt(val.name.split(" ")[1])
          : val.name.split(" ")[1]
      );
      this.setState(
        {
          runnerData: sortedRunnerData,
          addFieldValue: { ...this.state.addFieldValue, unit: "" },
        },
        () => {
          this.getRunnerIndex();
        }
      );
    } else {
      this.setState({
        runnerData: [],
      });
    }
  }

  getRunnerIndex() {
    const { name } = this.props.location.state.componentData.assetInfo;

    const { runnerData, addFieldValue } = this.state;
    if (runnerData.length > 0) {
      const filledRunnerData = runnerData.map((item) => item.name);
      let newRunnerValue = getRunnerOrImpellerArray(name)[0];
      for (let i = 0; i < getRunnerOrImpellerArray(name).length; i++) {
        if (!filledRunnerData.includes(getRunnerOrImpellerArray(name)[i])) {
          newRunnerValue = getRunnerOrImpellerArray(name)[i];
          break;
        }
      }
      const temp = { ...addFieldValue };
      temp.name = newRunnerValue;
      this.setState({ addFieldValue: _.cloneDeep(temp) });
    } else {
      const temp = { ...addFieldValue };
      temp.name = name === Liquid_Expander ? "Runner 1" : "Impeller 1";
      this.setState({ addFieldValue: _.cloneDeep(temp) });
    }
  }

  setEditRows() {
    const { updatedFields, runnerData } = this.state;
    const tempEditRows = [];
    const tempUpdatedField = [];
    const tempDirtyRows = [];
    updatedFields.forEach((field, uindex) => {
      field &&
        runnerData.forEach((data, index) => {
          data.diameter = _.isNil(data.diameter)
            ? ""
            : data.diameter.toString();
          data.minDiameter = _.isNil(data.minDiameter)
            ? ""
            : data.minDiameter.toString();
          data.maxDiameter = _.isNil(data.maxDiameter)
            ? ""
            : data.maxDiameter.toString();
          if (field.name === data.name) {
            tempEditRows.push(index);
            tempUpdatedField[index] = updatedFields[uindex];
          }

          if (
            _.isEmpty(data.diameter) ||
            !validatePositiveFloat(data.diameter)
          ) {
            tempDirtyRows.push(index);
          } else if (
            !_.isEmpty(data.minDiameter) &&
            !validatePositiveFloat(data.minDiameter)
          ) {
            tempDirtyRows.push(index);
          } else if (
            !_.isEmpty(data.maxDiameter) &&
            !validatePositiveFloat(data.maxDiameter)
          ) {
            tempDirtyRows.push(index);
          }
        });
    });
    this.setState({
      editRows: tempEditRows,
      updatedFields: tempUpdatedField,
      dirtyRows: tempDirtyRows,
    });
  }

  edit(row) {
    const { updatedFields, runnerData } = this.state;
    const updatedTemp = _.cloneDeep(updatedFields);
    updatedTemp[row] = _.cloneDeep(runnerData[row]);
    this.setState(
      {
        updatedFields: updatedTemp,
      },
      () => {
        this.setEditRows();
        this.enableEditForm();
      }
    );
  }

  undo(row) {
    const {
      updatedFields,
      runnerData,
      dirtyRows,
      preserveRunnerDataforUndo,
    } = this.state;
    const tempRunnerData = _.cloneDeep(runnerData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    tempRunnerData[row] = _.cloneDeep(tempupdatedField[row]);

    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempupdatedField[row] = undefined;
    this.setState(
      {
        runnerData: tempRunnerData,
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        const tempRunnerData = [...this.state.runnerData];
        for (let i = 0; i < tempRunnerData.length; i++) {
          tempRunnerData[i].unit = _.cloneDeep(
            preserveRunnerDataforUndo[row].unit
          );
        }
        this.setState({
          runnerData: tempRunnerData,
        });
        this.setEditRows();
      }
    );
  }

  deleteRow(row) {
    const { runnerData, updatedFields, dirtyRows } = this.state;
    const tempRunnerData = _.cloneDeep(runnerData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    tempRunnerData.splice(row, 1);
    tempupdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    this.setState(
      {
        runnerData: tempRunnerData,
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.enableEditForm();
        this.setEditRows();
        this.getRunnerIndex();
      }
    );
  }

  addRow() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const { runnerData, addFieldValue } = this.state;
    let data = [...runnerData];

    if (
      addFieldValue.name &&
      addFieldValue.type &&
      !_.isEmpty(addFieldValue.diameter) &&
      validatePositiveFloat(addFieldValue.diameter) &&
      (addFieldValue.minDiameter === "" ||
        validatePositiveFloat(addFieldValue.minDiameter)) &&
      (addFieldValue.maxDiameter === "" ||
        validatePositiveFloat(addFieldValue.maxDiameter)) &&
      addFieldValue.unit
    ) {
      if (data.length < getRunnerOrImpellerArray(name).length) {
        const tempRunnerData = [...this.state.runnerData];
        for (let i = 0; i < tempRunnerData.length; i++) {
          tempRunnerData[i].unit = this.state.changeUnitSelected;
        }
        this.setState({
          runnerData: _.cloneDeep(tempRunnerData),
          preserveRunnerDataforUndo: _.cloneDeep(tempRunnerData),
        });
        addFieldValue.diameter = parseFloat(addFieldValue.diameter);
        data.push({ ...addFieldValue });
        data = _.sortBy(data, (val) => parseInt(val.name.split(" ")[1]));
        this.setState(
          {
            runnerData: _.cloneDeep(data),
            preserveRunnerDataforUndo: _.cloneDeep(data),
            addFieldValue: {
              ...addFieldValue,
              name: null,
              type: "",
              diameter: "",
              minDiameter: "",
              maxDiameter: "",
              unit: "",
            },
            isError: {
              name: false,
              type: false,
              diameter: false,
              minDiameter: false,
              maxDiameter: false,
              unit: false,
            },
          },
          () => {
            this.enableEditForm();
            this.getRunnerIndex();
            this.setEditRows();
          }
        );
      } else {
        Notification.show(
          Types.Info,
          name === Liquid_Expander
            ? RunnerDataMessages.MAX_RUNNER
            : RunnerDataMessages.MAX_IMPELLER
        );
      }
    } else {
      this.validateFields(this.state.addFieldValue);
      Notification.show(Types.Error, RunnerDataMessages.FIELDS_DIRTY);
    }
  }

  onAddRowChange(field) {
    const { addFieldValue, updatedFields } = this.state;
    const temp = { ...addFieldValue };
    const tempUpdate = [...updatedFields];
    if (field.name === "unit") {
      temp[field.name] = field.value;
      this.setState({
        changeUnitSelected: field.value,
      });
      for (let i = 0; i < tempUpdate.length; i++) {
        if (tempUpdate[i]) {
          tempUpdate[i].unit = field.value;
        }
      }
    } else if (field.name === "type") {
      temp[field.name] = field.value;
    } else {
      // temp[field.name] = parseFloat(field.value) === 0 ? parseFloat(field.value) : (field.value > 1 ? (field.value.replace(/^0+/, '')) : (field.value.replace(/^0+/, '0')));
      temp[field.name] = field.value;
    }

    this.setState(
      {
        addFieldValue: temp,
        updatedFields: tempUpdate,
      },
      () => {
        this.validateFields(this.state.addFieldValue);
        this.enableEditForm();
      }
    );
  }

  onValueChange(field, row) {
    const { runnerData, updatedFields } = this.state;
    const temp = [...runnerData];
    const tempUpdate = [...updatedFields];
    if (field.name === "unit") {
      temp[row][field.name] = field.value;
      for (let i = 0; i < temp.length; i++) {
        temp[i].unit = field.value;
      }
      for (let i = 0; i < tempUpdate.length; i++) {
        if (tempUpdate[i]) {
          if (tempUpdate[i]) {
            tempUpdate[i].unit = field.value;
          }
        }
      }
    } else if (field.name === "type") {
      temp[row][field.name] = field.value;
    } else {
      temp[row][field.name] = field.value;
    }

    this.setState(
      {
        runnerData: temp,
        addFieldValue: { ...this.state.addFieldValue, unit: "" },
        updatedFields: tempUpdate,
      },
      () => {
        this.setEditRows();
      }
    );
  }

  validateFields(values) {
    const { isError } = this.state;
    let status = true;
    const temp = { ...isError };
    Object.keys(values).forEach((item) => {
      if (
        item === "diameter" &&
        (_.isEmpty(values[item]) ||
          (!_.isNil(values[item]) && !validatePositiveFloat(values[item])))
      ) {
        temp[item] = true;
        status = false;
      } else if (item === "maxDiameter" || item === "minDiameter") {
        temp[item] = false;
        status = false;
      } else if (
        !values[item] ||
        values[item].length === 0 ||
        values[item].trim().length === 0
      ) {
        temp[item] = true;
        status = false;
      } else {
        temp[item] = false;
      }
    });
    this.setState({
      isError: temp,
    });
    return status;
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  save() {
    const { runnerData } = this.state;
    if (
      this.state.curvesData &&
      this.state.dirtyRows.length === 0 &&
      runnerData &&
      runnerData.length >= 1
    ) {
      this.setState({
        visibleSave: true,
      });
    } else {
      const { name } = this.props.location.state.componentData.assetInfo;
      const {
        location: { state },
      } = this.props;
      const { runnerData, addFieldValue } = this.state;
      this.setState({ addFieldValue: { ...addFieldValue, unit: "" } });
      if (this.state.dirtyRows.length === 0) {
        if (runnerData && runnerData.length >= 1) {
          const temp = [...runnerData];
          for (let i = 0; i < temp.length; i++) {
            temp[i].diameter = parseFloat(temp[i].diameter);
            temp[i].minDiameter = parseFloat(temp[i].minDiameter);
            temp[i].maxDiameter = parseFloat(temp[i].maxDiameter);
            if (temp[i]._id) {
              delete temp[i]._id;
            }
            if (
              temp[i].siValue ||
              temp[i].siValue === 0 ||
              temp[i].siValueMinDiameter ||
              temp[i].siValueMinDiameter === 0 ||
              temp[i].siValueMaxDiameter ||
              temp[i].siValueMaxDiameter === 0
            ) {
              delete temp[i].siValue;
              delete temp[i].siValueMinDiameter;
              delete temp[i].siValueMaxDiameter;
            }
          }

          Object.keys(temp).forEach((item) => {
            Object.keys(temp[item]).forEach((data) => {
              if (!temp[item][data] && temp[item][data] !== 0) {
                delete temp[item][data];
              }
            });
          });

          const payload = {
            data: [
              ...temp.map((item) => ({
                ...item,
                sNo: Number(item.name.split(" ")[1]),
              })),
            ],
          };

          if (this.handleNetwork() && state.componentData) {
            this.props.addEditRunnerData(
              state.componentData.info._id,
              payload,
              name
            );
          }
        } else {
          Notification.show(
            Types.Info,
            name === Liquid_Expander
              ? RunnerDataMessages.MIN_RUNNER
              : RunnerDataMessages.MIN_IMPELLER
          );
        }
      } else {
        Notification.show(Types.Error, RunnerDataMessages.FIELDS_DIRTY);
      }
    }
  }

  clearDirtyForm() {
    const { assetInfo } = this.props.location.state.componentData;
    const { preserveRunnerData } = this.state;
    this.setState(
      {
        runnerData:
          preserveRunnerData && preserveRunnerData.data
            ? _.cloneDeep(preserveRunnerData.data)
            : [],
        preserveRunnerDataforUndo:
          preserveRunnerData && preserveRunnerData.data
            ? _.cloneDeep(preserveRunnerData.data)
            : [],
        editRows: [],
        updatedFields: [],
        isError: {
          name: false,
          type: false,
          diameter: false,
          minDiameter: false,
          maxDiameter: false,
          unit: false,
        },
        addFieldValue: {
          ...this.state.addFieldValue,
          name: assetInfo.name === Liquid_Expander ? "Runner 1" : "Impeller 1",
          type: "",
          diameter: "",
          minDiameter: "",
          maxDiameter: "",
        },
      },
      () => {
        this.getRunnerIndex();
      }
    );
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOkSave() {
    this.setState({
      visibleSave: false,
    });
    const { name } = this.props.location.state.componentData.assetInfo;
    const {
      location: { state },
    } = this.props;
    const { runnerData } = this.state;
    if (this.state.dirtyRows.length === 0) {
      if (runnerData && runnerData.length >= 1) {
        const temp = [...runnerData];
        for (let i = 0; i < temp.length; i++) {
          temp[i].diameter = parseFloat(temp[i].diameter);
          temp[i].minDiameter = parseFloat(temp[i].minDiameter);
          temp[i].maxDiameter = parseFloat(temp[i].maxDiameter);
          if (temp[i]._id) {
            delete temp[i]._id;
          }
          if (
            temp[i].siValue ||
            temp[i].siValue === 0 ||
            temp[i].siValueMinDiameter ||
            temp[i].siValueMinDiameter === 0 ||
            temp[i].siValueMaxDiameter ||
            temp[i].siValueMaxDiameter === 0
          ) {
            delete temp[i].siValue;
            delete temp[i].siValueMinDiameter;
            delete temp[i].siValueMaxDiameter;
          }
        }
        Object.keys(temp).forEach((item) => {
          Object.keys(temp[item]).forEach((data) => {
            if (!temp[item][data] && temp[item][data] !== 0) {
              delete temp[item][data];
            }
          });
        });

        const payload = {
          data: [
            ...temp.map((item) => ({
              ...item,
              sNo: Number(item.name.split(" ")[1]),
            })),
          ],
        };

        if (this.state.curvesData) {
          const tempCurvesData = calculateCurvesData(
            name,
            this.props.dataUnitState.apiState.unitMapper,
            payload.data,
            this.state.curvesData
          );
          payload.curvesData = tempCurvesData;
        }

        if (this.handleNetwork() && state.componentData) {
          this.props.addEditRunnerData(
            state.componentData.info._id,
            payload,
            name
          );
        }
      } else {
        Notification.show(
          Types.Info,
          name === Liquid_Expander
            ? RunnerDataMessages.MIN_RUNNER
            : RunnerDataMessages.MIN_IMPELLER
        );
      }
    }
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleCancelSave() {
    this.setState({
      visibleSave: false,
    });
  }

  renderModalSave() {
    const { name } = this.props.location.state.componentData.assetInfo;
    if (this.state.visibleSave) {
      return (
        <ConfirmModal
          title={name === Liquid_Expander ? "Runner" : "Impeller"}
          visible={this.state.visibleSave}
          handleOk={() => this.handleOkSave()}
          okText="Proceed"
          handleCancel={() => this.handleCancelSave()}
          message={
            name === Liquid_Expander
              ? RunnerDataMessages.ONSAVERUNNER
              : RunnerDataMessages.ONSAVEIMPELLER
          }
        />
      );
    }
    return null;
  }

  render() {
    const { loading } = this.props.runnerDataState;
    const { name } = this.props.location.state.componentData.assetInfo;
    return (
      <div className="RunnerData">
        {loading ? <Loader /> : null}
        {name === Liquid_Expander ? (
          <div className="runnner-data-count">
            {`${
              this.state.runnerData ? this.state.runnerData.length : 0
            } Runner`}
          </div>
        ) : (
          <div className="runnner-data-count">
            {`${
              this.state.runnerData ? this.state.runnerData.length : 0
            } Impeller`}
          </div>
        )}

        <div>
          <CustomTable
            showHeader
            columns={runnerTableColumns({
              isRunnerEditable: this.props.isSchematicEditable,
              change: (field, row) => {
                this.onValueChange(field, row);
              },
              edit: (row) => {
                this.edit(row);
              },
              delete: (row) => {
                this.deleteRow(row);
              },
              undo: (row) => {
                this.undo(row);
              },
              unitData: this.state.unitData,
              name,
            })}
            editableRow={this.state.editRows}
            data={this.state.runnerData}
            renderAddRow={
              this.props.isSchematicEditable
                ? this.state.runnerData.length < 20
                  ? runnerTableAddRow({
                    change: (e) => {
                      this.onAddRowChange(e);
                    },
                    submit: (e) => {
                      this.addRow();
                    },
                    isError: this.state.isError,
                    unitData: this.state.unitData,
                    name,
                  })
                  : null
                : null
            }
            addFieldValues={this.state.addFieldValue}
          />
        </div>
        {this.state.formEdit && this.props.isSchematicEditable && (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModal()}
        {this.renderModalSave()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  runnerDataState: state.RunnerDataReducer,
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addEditRunnerData: (id, payload, type) => {
    dispatch(addEditRunnerData(id, payload, type));
  },
  clearRunnerReducerState: () => {
    dispatch(clearRunnerReducerState());
  },
  getDataUnits: (payload) => {
    dispatch(getDataUnits(payload));
  },
  clearDataUnitState: () => {
    dispatch(clearDataUnitState());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RunnerData));
