import React from 'react';
import AlertPlot from '../../AlertPlot';
import { alertPlot } from '../../Alerts.constants';

const RenderPlots = (data) => {
    const { _id } = data
    const { unit } = data.alertMetaObject;
    const alertPlotConst =
        data.type === 'Univariate Anomaly Detection'
            ? 'IQR threshold exceedance'
            : data.type;

    return (
        <>
            {(_id && unit) &&
                ((alertPlotConst === alertPlot.OUT_OF_BOUND ||
                    alertPlotConst === alertPlot.IQR_THRESHOLD ||
                    alertPlotConst === alertPlot.PROCESS_DEVIATION ||
                    alertPlotConst === alertPlot.IOW) && (
                        <>
                            <div className='details_description_heading plot_padding'>
                                Plot:
                            </div>
                            <AlertPlot
                                unit={unit}
                                alertId={_id}
                                alertPrint={false}
                            />
                        </>

                    ))
            }
        </>
    )
}

export default RenderPlots