import {
  PLANT_TIME_SUCCESS,
  PLANT_TIME_ERROR,
  PLANT_TIME_PROGRESS,
  CLEAR_PLANT_TIME,
  BATCH_TIME_SUCCESS,
  BATCH_TIME_ERROR,
  BATCH_TIME_PROGRESS,
  CLEAR_BATCH_TIME,
} from "../Types/plots.types";
import { GETRequest } from "../../api/request";

export function getPlantTime() {
  return function (dispatch) {
    dispatch({ type: PLANT_TIME_PROGRESS });
    GETRequest("plant/time")
      .then((res) => dispatch({ type: PLANT_TIME_SUCCESS, payload: res.body }))
      .catch((err) => dispatch({ type: PLANT_TIME_ERROR, payload: err }));
  };
}

export function getBatchTime() {
  return function (dispatch) {
    dispatch({ type: BATCH_TIME_PROGRESS });
    GETRequest("batch/time")
      .then((res) => dispatch({ type: BATCH_TIME_SUCCESS, payload: res.body }))
      .catch((err) => dispatch({ type: BATCH_TIME_ERROR, payload: err }));
  };
}

export function clearBatchState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_BATCH_TIME });
  };
}

export function clearPlantState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_PLANT_TIME });
  };
}
