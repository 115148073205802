const runStatuses = [
    {
      _id: ('5f4e1e3d38fd5ee353a2d444'),
      start: ('2020-11-30T09:00:00.000Z'),
      end: ('2021-04-28T09:00:00.000Z'),
      equipment: ('5ee9e474190834002c061d4c'),
      down: 0,
      up: 6500.68638888889,
      comment: 'Sample Comment',
      runNumber: 2,
      ROS: 'Machine operation not required',
      createdAt: ('2021-05-25T05:41:11.462Z'),
    },
    {
      _id: ('5f4e1e3d38fd5ee353a2d444'),
      start: ('2020-08-27T09:00:00.000Z'),
      end: ('2020-11-28T09:00:00.000Z'),
      equipment: ('5ee9e474190834002c061d4c'),
      down: 0,
      up: 6500.68638888889,
      comment: 'Sample Comment',
      runNumber: 1,
      ROS: 'Machine operation not required',
      createdAt: ('2021-05-25T05:41:11.462Z'),
    }
  ];
  
  export default runStatuses;