import React from 'react';
import './UserManagement.scss';
import UserManagementHeader from './UserManagementHeader/UserManagement.header';
import UserManagementBody from './UserManagementBody/UserManagementBody';
import { userFetch } from '../../redux/actions/UserManagement.action';
import { connect } from 'react-redux';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
      skip: 0,
      keyword: '',
      filter: '',
    };
  }

  commonData (keyword,filter,order) {
    this.setState({
      keyword,
      filter,
      order
    });
   this.props.userFetch({keyword,filter,order})
  }

  onSearch = (keyword) => {
    const {filter,order}=this.state;
    this.commonData(keyword,filter,order);
  };

  onSort = (value) => {
    const {filter,keyword}=this.state;
    this.commonData(keyword,filter,value);
  };

  onFilter = (filter) => {
    const {keyword,order} = this.state;
    this.commonData(keyword,filter,order);
  };

  render() {
    const { keyword, order, filter } = this.state;
    return (
      <div className="UserManagement">
        <UserManagementHeader
          onSearch={this.onSearch}
          order={this.onSort}
          onFilter={this.onFilter}
        />
        <UserManagementBody keyword={keyword} order={order} filter={filter} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
	network: state.NetworkReducer,
})

//export default UserManagement;
const mapDispatchToProps= (dispatch) => ({
	userFetch: (payload) => dispatch(userFetch(payload))
});
export default connect(
  mapStateToProps,
	mapDispatchToProps
)(UserManagement);