import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import { validatePositiveFloat } from "../../../../../../common/methods";
import { StartingCharacteristicsCurvesVariables } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

export const tableHeader = (tableHeader) => [
  {
    title: StartingCharacteristicsCurvesVariables.speed,
    dataIndex: "speed",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="speed"
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  {
    title: StartingCharacteristicsCurvesVariables.currentVoltage100,
    dataIndex: "currentVoltage100",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="currentVoltage100"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  {
    title: StartingCharacteristicsCurvesVariables.currentVoltage80,
    dataIndex: "currentVoltage80",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="currentVoltage80"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },

  {
    title: StartingCharacteristicsCurvesVariables.powerFactor,
    dataIndex: "powerFactor",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="powerFactor"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueRatedLoad,
    dataIndex: "torqueRatedLoad",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="torqueRatedLoad"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueVoltage100,
    dataIndex: "torqueVoltage100",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="torqueVoltage100"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  {
    title: StartingCharacteristicsCurvesVariables.torqueVoltage80,
    dataIndex: "torqueVoltage80",
    render: (value, row, col, disabled, item) => (
      <div>
        {disabled ? (
          value
        ) : (
          <Input
            value={value}
            autoComplete="off"
            id="torqueVoltage80"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) => tableHeader.handleChangeTableEditData(e, row)}
            className={
              (value && !validatePositiveFloat(value.toString())) ||
              (!item.torqueRatedLoad &&
                !item.torqueVoltage100 &&
                !item.torqueVoltage80 &&
                !item.currentVoltage100 &&
                !item.powerFactor &&
                !item.currentVoltage80 &&
                !item.speed)
                ? "error"
                : "not-error"
            }
          />
        )}
      </div>
    ),
  },
  tableHeader.isSchematicEditable && {
    title: StartingCharacteristicsCurvesVariables.actions,
    dataIndex: "actions",
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => tableHeader.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => tableHeader.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
        <button onClick={() => tableHeader.onDelete(row)}>
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const inputFields = (input) => {
  if (input.length !== 15) {
    return [
      {
        render: () => {
          const value = _.isNil(input.speed) ? "" : input.speed.toString();
          return (
            <div>
              <Input
                id="speed"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.speed}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  !_.isEmpty(value) &&
                  input.eventListener &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.currentVoltage100)
            ? ""
            : input.currentVoltage100.toString();
          return (
            <div>
              <Input
                id="currentVoltage100"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.currentVoltage100}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  !_.isEmpty(value) &&
                  input.eventListener &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.currentVoltage80)
            ? ""
            : input.currentVoltage80.toString();
          return (
            <div>
              <Input
                id="currentVoltage80"
                autoComplete="off"
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                value={input.currentVoltage80}
                onChange={(value) => input.handleChangeTableData(value)}
                className={
                  !_.isEmpty(value) &&
                  input.eventListener &&
                  !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
              />
            </div>
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.powerFactor)
            ? ""
            : input.powerFactor.toString();
          return (
            <Input
              id="powerFactor"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.powerFactor}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                !_.isEmpty(value) &&
                input.eventListener &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.torqueRatedLoad)
            ? ""
            : input.torqueRatedLoad.toString();
          return (
            <Input
              id="torqueRatedLoad"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.torqueRatedLoad}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                !_.isEmpty(value) &&
                input.eventListener &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.torqueVoltage100)
            ? ""
            : input.torqueVoltage100.toString();
          return (
            <Input
              id="torqueVoltage100"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.torqueVoltage100}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                !_.isEmpty(value) &&
                input.eventListener &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => {
          const value = _.isNil(input.torqueVoltage80)
            ? ""
            : input.torqueVoltage80.toString();
          return (
            <Input
              id="torqueVoltage80"
              autoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              value={input.torqueVoltage80}
              onChange={(value) => input.handleChangeTableData(value)}
              className={
                !_.isEmpty(value) &&
                input.eventListener &&
                !validatePositiveFloat(value)
                  ? "error"
                  : "not-error"
              }
            />
          );
        },
      },
      {
        render: () => (
          <button
            className="btn-default btn-small"
            onClick={() => input.onAdd()}
          >
            Add
          </button>
        ),
      },
    ];
  }
};

export const inputUnits = (input) => [
  {
    render: () => {
      const tempError = !!(!input.speedUnit && input.saveListener);
      return (
        <div className={`custom-table-select  ${tempError && "error"}`}>
          <select
            value={!input.speedUnit ? "" : input.speedUnit}
            id="speedUnit"
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (getUnits.dataVariable === "Starting curve - Speed") {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.currentVoltage100Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="currentVoltage100Unit"
            value={
              !input.currentVoltage100Unit ? "" : input.currentVoltage100Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable ===
                "Starting curve - Current 100% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.currentVoltage80Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="currentVoltage80Unit"
            value={
              !input.currentVoltage80Unit ? "" : input.currentVoltage80Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Current 80% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.powerFactorUnit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="powerFactorUnit"
            value={!input.powerFactorUnit ? "" : input.powerFactorUnit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (getUnits.dataVariable === "Starting curve - Power Factor") {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueRatedLoadUnit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueRatedLoadUnit"
            value={!input.torqueRatedLoadUnit ? "" : input.torqueRatedLoadUnit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque Rated Load"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueVoltage100Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueVoltage100Unit"
            value={
              !input.torqueVoltage100Unit ? "" : input.torqueVoltage100Unit
            }
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque 100% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => {
      const tempError = !!(!input.torqueVoltage80Unit && input.saveListener);
      return (
        <div className={`custom-table-select ${tempError && "error"}`}>
          <select
            id="torqueVoltage80Unit"
            value={!input.torqueVoltage80Unit ? "" : input.torqueVoltage80Unit}
            onChange={(value) => input.handleChangeTableData(value, "unit")}
            disabled={!input.isSchematicEditable}
          >
            <option value="">Select</option>
            {input.unitData.map((getUnits) => {
              if (
                getUnits.dataVariable === "Starting curve - Torque 80% Voltage"
              ) {
                return getUnits.units.map((dispUnit) => (
                  <option value={dispUnit}>{dispUnit}</option>
                ));
              }
              return undefined;
            })}
          </select>
        </div>
      );
    },
  },
  {
    render: () => <div />,
  },
];
