import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import * as Pattern from '../../../../constants/pattern';
import { DatePicker } from 'antd';
import { MANAGE_PI_TAGS_CONSTANTS } from '../../../../constants/variables';

export const DATA_TYPE = ['Boolean', 'Float', 'Integer', 'String'];

export const validateValues = (minValue, maxValue) => {
  if (_.isEmpty(minValue) || _.isEmpty(maxValue)) {
    return false;
  }
  if (parseFloat(minValue) >= parseFloat(maxValue)) {
    return false;
  }
  return true;
};

export const piTagColumns = (config) => {
  return [
    {
      label: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_LOW,
      key: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_LOW_INDEX,
      max: 70,
      value: config.criticalLimitLow,
      change: (e) => {
        config.change(e, MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_LOW_INDEX);
      },
      type: 'text',
      pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
    },
    {
      label: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_LOW,
      key: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_LOW_INDEX,
      max: 70,
      value: config.standardLimitLow,
      change: (e) => {
        config.change(e, MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_LOW_INDEX);
      },
      type: 'text',
      pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
    },
    {
      label: MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TARGET,
      key: MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TARGET_INDEX,
      max: 70,
      value: config.optimalTarget,
      change: (e) => {
        config.change(e, MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TARGET_INDEX);
      },
      type: 'text',
      pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
    },
    {
      label: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_HIGH,
      key: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_HIGH_INDEX,
      max: 70,
      value: config.standardLimitHigh,
      change: (e) => {
        config.change(e, MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_HIGH_INDEX);
      },
      type: 'text',
      pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
    },
    {
      label: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_HIGH,
      key: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_HIGH_INDEX,
      max: 70,
      value: config.criticalLimitHigh,
      change: (e) => {
        config.change(e, MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_HIGH_INDEX);
      },
      type: 'text',
      pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
    },
  ];
};

export const datePick = (config) => {
  return (
    <div>
      {`${MANAGE_PI_TAGS_CONSTANTS.REFERENCE_START_DATE} :`}
        <DatePicker
          showTime
          onChange={config.change}
          value={config.referenceStartDate ? moment(config.referenceStartDate) : ''}
        />
    </div>
  );
}

export const iowTimers = (config) => [
  {
    label: MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TIME_RANGE,
    key: MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TIME_RANGE_INDEX,
    max: 70,
    type: 'number',
    value: config?.optimalTimeRange,
    change: (e) => {
      config.change(e, MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TIME_RANGE_INDEX);
    },
  },
  {
    label: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_LOW_TIME,
    key: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_LOW_TIME_INDEX,
    max: 70,
    type: 'number',
    value: config?.criticalLimitLowTime,
    change: (e) => {
      config.change(e, MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_LOW_TIME_INDEX);
    },
  },
  {
    label: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_HIGH_TIME,
    key: MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_HIGH_TIME_INDEX,
    max: 70,
    type: 'number',
    value: config?.criticalLimitHighTime,
    change: (e) => {
      config.change(e, MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_HIGH_TIME_INDEX);
    },
  },
  {
    label: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_LOW_TIME,
    key: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_LOW_TIME_INDEX,
    max: 70,
    type: 'number',
    value: config?.standardLimitLowTime,
    change: (e) => {
      config.change(e, MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_LOW_TIME_INDEX);
    },
  },
  {
    label: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_HIGH_TIME,
    key: MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_HIGH_TIME_INDEX,
    max: 70,
    type: 'number',
    value: config?.standardLimitHighTime,
    change: (e) => {
      config.change(e, MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_HIGH_TIME_INDEX);
    },
  },
];
