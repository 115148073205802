import moment from "moment";

export const DETECTION_DATA = [
  "Periodic maintenance",
  "Functional testing",
  "Inspection",
  "Periodic condition monitoring",
  "Pressure testing",
  "Continuous condition monitoring",
  "Production interference",
  "Casual observation",
  "Corrective maintenance",
  "On demand",
  "Other",
];

export const MECHANISM_DATA = [
  "Mechanical - Leakage",
  "Mechanical - Vibration",
  "Mechanical - Clearance / alignment",
  "Mechanical - Deformation",
  "Mechanical - Looseness",
  "Mechanical - Sticking",
  "Material - Cavitation",
  "Material - Corrosion",
  "Material - Erosion",
  "Material - Wear",
  "Material - Breakage",
  "Material - Fatigue",
  "Material - Overheating",
  "Material - Burst",
  "Instrument - Control",
  "Instrument - No signal / indication/alarm",
  "Instrument - Faulty signal/indication/alarm",
  "Instrument - Out of adjustment",
  "Instrument - Software error",
  "Instrument - Common cause/ Common mode",
  "Electrical - Short circuiting",
  "Electrical - Open circuit",
  "Electrical - No power/voltage",
  "Electrical - Faulty power/voltage",
  "Electrical - Earth/isolation fault",
  "External influence - Blockage/plugged",
  "External influence - Contamination",
  "External influence - Miscellaneous",
  "Miscellaneous - No cause found",
  "Miscellaneous - Combined causes",
  "Miscellaneous - Other",
  "Miscellaneous - Unknown",
];

export const CAUSE_DATA = [
  "Design - Improper capacity",
  "Design - Improper material",
  "Fabrication/ installation - Fabrication failure",
  "Fabrication/ installation - Installation failure",
  "Operation/ maintenance - Off-design service",
  "Operation/ maintenance - Operating error",
  "Operation/ maintenance - Maintenance error",
  "Operation/ maintenance - Expected wear and tear",
  "Management - Documentation error",
  "Management - Management error",
  "Miscellaneous - No cause found",
  "Miscellaneous - Common cause",
  "Miscellaneous - Combined causes",
  "Miscellaneous - Other unit/ cascading failure",
  "Miscellaneous - Other",
  "Miscellaneous - Unknown",
];

export const MODE_DATA = [
  "Abnormal instrument reading",
  "Breakdown",
  "Control-line-to-well communication",
  "Control / signal failure",
  "Delayed operation",
  "External leakage - fuel",
  "External leakage - process medium",
  "External leakage - utility medium",
  "Erratic output",
  "Failure to connect",
  "Failure to cut",
  "Failure in lightning protection system",
  "Faulty output frequency",
  "Faulty output voltage",
  "Failure to rotate",
  "Failure to close on demand",
  "Failure to disconnect",
  "Failure to function on demand",
  "Failure to function as intended",
  "Failure to lock/unlock",
  "Failure to open on demand",
  "Failure to regulate",
  "Failure to start on demand",
  "Failure while running",
  "High output",
  "Heating failure",
  "Insufficient heat transfer",
  "Internal leakage - process medium",
  "Internal leakage - utility medium",
  "Internal leakage",
  "Low oil supply pressure",
  "Leakage in closed position",
  "Load drop",
  "Loss of buoyancy",
  "Low output",
  "Mooring failure",
  "Noise",
  "No output",
  "Overheating",
  "Other",
  "Premature closure",
  "Parameter deviation",
  "Plugged/ choked",
  "Loss of functions on both pods",
  "Insufficient power",
  "Power/signal transmission failure",
  "Sludge build-up",
  "Minor in-service problems",
  "Failure to set/retrieve",
  "Spurious high alarm level",
  "Spurious low alarm level",
  "Slippage",
  "Spurious operation",
  "Structural deficiency",
  "Failure to stop on demand",
  "Stuck",
  "Global buckling",
  "Unknown",
  "Spurious stop",
  "Vibration",
  "Very low output",
  "Well-to-control-line communication",
];

export const ACTIVITY = [
  "Replace",
  "Repair",
  "Modify",
  "Adjust",
  "Refit",
  "Check",
  "Service",
  "Test",
  "Inspection",
  "Overhaul",
  "Combination",
  "Other",
];

export const POP_DATA = {
  "Periodic maintenance":
    "Failure discovered during preventive service, replacement or overhaul of an item when executing the preventive maintenance programme",
  "Functional testing":
    "Failure discovered by activating an intended function and comparing the response against a predefined standard. This is one typical method for detecting hidden failures",
  Inspection:
    "Failure discovered during planned inspection, e.g. visual inspection, non-destructive testing",
  "Periodic condition monitoring":
    "Failures revealed during a planned, scheduled condition monitoring of a predefined failure mode, either manually or automatically, e.g. thermography, vibration measuring, oil analysis, sampling",
  "Pressure testing": "Failure observed during pressure testing",
  "Continuous condition monitoring":
    "Failures revealed during a continuous condition monitoring of a predefined failure mode",
  "Production interference":
    "Failure discovered by production upset, reduction, etc.",
  "Casual observation":
    "Casual observation during routine or casual operator checks, mainly by senses (noise, smell, smoke, leakage, appearance, etc.)",
  "Corrective maintenance": "Failure observed during corrective maintenance",
  "On demand":
    "Failure discovered during an on-demand attempt to activate an equipment unit (e.g. safety valve fails to close on ESD-signal, fail to start a gas turbine on demand, etc.)",
  Other: "Other observation method and/or combination of several methods",
  "Mechanical - Leakage":
    "External and internal leakage, either liquids or gases: If the failure mode at equipment unit level is coded as “leakage”, a more causally oriented failure mechanism should be used wherever possible.",
  "Mechanical - Vibration":
    "Abnormal vibration: If the failure mode at equipment level is “vibration”, which is a more causally oriented failure mechanism, the failure cause (root cause) should be recorded wherever possible.",
  "Mechanical - Clearance / alignment":
    "Failure caused by faulty clearance or alignment",
  "Mechanical - Deformation":
    "Distortion, bending, buckling, denting, yielding, shrinking, blistering, creeping, etc.",
  "Mechanical - Looseness": "Disconnection, loose items",
  "Mechanical - Sticking":
    "Sticking, seizure, jamming due to reasons other than deformation or clearance/alignment failures",
  "Material - Cavitation": "Relevant for equipment such as pumps and valves",
  "Material - Corrosion":
    "All types of corrosion, both wet (electrochemical) and dry (chemical)",
  "Material - Erosion": "Erosive wear",
  "Material - Wear":
    "Abrasive and adhesive wear, e.g. scoring, galling, scuffing, fretting",
  "Material - Breakage": "Fracture, breach, crack",
  "Material - Fatigue":
    "If the cause of breakage can be traced to fatigue, this code should be used.",
  "Material - Overheating": "Material damage due to overheating/burning",
  "Material - Burst": "Item burst, blown, exploded, imploded, etc.",
  "Instrument - Control": "No, or faulty, regulation",
  "Instrument - No signal / indication/alarm":
    "No signal/indication/alarm when expected",
  "Instrument - Faulty signal/indication/alarm":
    "Signal/indication/alarm is wrong in relation to actual process. Can be spurious, intermittent, oscillating, arbitrary",
  "Instrument - Out of adjustment": "Calibration error, parameter drift",
  "Instrument - Software error":
    "Faulty, or no, control/monitoring/operation due to software error",
  "Instrument - Common cause/ Common mode":
    "Several instrument items failed simultaneously, e.g. redundant fire and gas detectors; also, failures related to a common cause.",
  "Electrical - Short circuiting": "Short circuit",
  "Electrical - Open circuit": "Disconnection, interruption, broken wire/cable",
  "Electrical - No power/voltage":
    "Missing or insufficient electrical power supply",
  "Electrical - Faulty power/voltage":
    "Faulty electrical power supply, e.g. overvoltage",
  "Electrical - Earth/isolation fault":
    "Earth fault, low electrical resistance",
  "External influence - Blockage/plugged":
    "Flow restricted/blocked due to fouling, contamination, icing, flow assurance (hydrates), etc.",
  "External influence - Contamination":
    "Contaminated fluid/gas/surface, e.g. lubrication oil contaminated, gas-detector head contaminated",
  "External influence - Miscellaneous":
    "Foreign objects, impacts, environmental influence from neighboring systems",
  "Miscellaneous - No cause found":
    "Failure investigated but cause not revealed or too uncertain",
  "Miscellaneous - Combined causes":
    "Several causes: If there is one predominant cause this should be coded.",
  "Miscellaneous - Other": "No code applicable: Use free text.",
  "Miscellaneous - Unknown": "No information available",
  "Design - Improper capacity": "Inadequate dimensioning/capacity",
  "Design - Improper material": "Improper material selection",
  "Fabrication/ installation - Fabrication failure":
    "Manufacturing or processing failure",
  "Fabrication/ installation - Installation failure":
    "Installation or assembly failure (assembly after maintenance not included)",
  "Operation/ maintenance - Off-design service":
    "Off-design or unintended service conditions, e.g. compressor operation outside envelope, pressure above specification, etc.",
  "Operation/ maintenance - Operating error":
    "Human error: Mistake, misuse, negligence, oversights, etc. during operation (e.g. due to human fatigue)",
  "Operation/ maintenance - Maintenance error":
    "Human error: Mistake, misuse, negligence, oversights, etc. during maintenance (e.g. due to human fatigue)",
  "Operation/ maintenance - Expected wear and tear":
    "Failure caused by wear and tear resulting from normal operation of the equipment unit",
  "Management - Documentation error":
    "Human error: Failure related to procedures, specifications, drawings, reporting, etc. (e.g. due to human fatigue)",
  "Management - Management error":
    "Failure related to planning, organization, quality assurance, etc.",
  // 'Miscellaneous - No cause found':
  //   'Failure investigated but no specific cause found',
  // 'Miscellaneous - Common cause': 'Common cause/mode',
  // 'Miscellaneous - Combined causes':
  //   'Several causes are acting simultaneously. If one cause is predominant, this cause should be highlighted',
  // 'Miscellaneous - Other unit/ cascading failure':
  //   'Failure caused by the failure of another equipment unit, subunit or maintainable item (cascading failure)',
  // 'Miscellaneous - Other':
  //   'None of the above codes applies. Specify cause as free text',
  // 'Miscellaneous - Unknown':
  //   'No information available related to the failure cause',
  Replace:
    "Replacement of the item by a new or re- furbished item of the same type and make",
  Repair:
    "Manual maintenance action performed to restore an item to its original appearance or state",
  Modify:
    "Replace, renew or change the item, or a part of it, with an item/part of a different type, make, material or design",
  Adjust: "Bringing any out-of-tolerance condition into tolerance",
  Refit:
    "Minor repair/servicing activity to bring back an item to an acceptable appearance, internal and external",
  Check:
    "The cause of the failure is investigated, but no maintenance action performed, or action is deferred. Able to regain function by simple actions, e.g. restart or resetting.",
  Service: "Periodic service tasks: Normally no dis-mantling of the item",
  Test: "Periodic test of function or performance",
  // Inspection:
  //   'Periodic inspection/check: a careful scrutiny of an item carried out with or without dismantling, normally by use of senses',
  Overhaul: "Major overhaul",
  Combination: "Several of the above activities are included",
  // Other: 'Maintenance activity other than specified above',
};

export const fields = (config) => {
  return [
    config.reason === "Failure/Trip" && {
      label: "Failure Detection Method",
      key: "RS-FDM",
      type: "dropdown",
      data: DETECTION_DATA,
      required: [true, "This field is mandatory"],
      toolTip: true,
      content: config.popOverContentDetection
        ? config.popOverContentDetection
        : false,
      change: config.handleFormDetection,
    },
    config.reason === "Failure/Trip" && {
      label: "Failure Mechanism",
      key: "RS-FMEC",
      type: "dropdown",
      data: MECHANISM_DATA,
      required: [true, "This field is mandatory"],
      toolTip: true,
      content: config.popOverContentMechanism
        ? config.popOverContentMechanism
        : false,
      change: config.handleFormMechanism,
    },
    config.reason === "Failure/Trip" && {
      label: "Failure Cause",
      key: "RS-FC",
      type: "dropdown",
      data: CAUSE_DATA,
      required: [true, "This field is mandatory"],
      toolTip: true,
      content: config.popOverContentFailure
        ? config.popOverContentFailure
        : false,
      change: config.handleFormFailure,
    },
    config.reason === "Failure/Trip" && {
      label: "Failure Mode",
      key: "RS-FMO",
      type: "dropdown",
      data: MODE_DATA,
      required: [true, "This field is mandatory"],
    },
    config.reason !== "Preventive Maintenance" && {
      label: "Was maintenance activity performed?",
      key: "RS-MAP",
      type: "dropdown",
      data: ["Yes", "No"],
      required: [true, "This field is mandatory"],
      change: config.onSelect,
    },
    (config.renderMore || config.reason === "Preventive Maintenance") && {
      label: "Maintenance Activity",
      key: "RS-MA",
      type: "dropdown",
      data: ACTIVITY,
      required: [true, "This field is mandatory"],
      toolTip: true,
      content: config.popOverContentActivity
        ? config.popOverContentActivity
        : false,
      change: config.handleFormActivity,
    },
    (config.renderMore || config.reason === "Preventive Maintenance") && {
      label: "Maintenance Start Time",
      key: "RS-MST",
      type: "datetimepicker",
      required: [true, "This field is mandatory", "object"],
      disabledDate: config.disabledDate,
      disabledTime: config.disabledTime,
      onOk: config.onOk,
      change: config.handleChange,
      value: config.startTime,
    },
    (config.renderMore || config.reason === "Preventive Maintenance") && {
      label: "Maintenance End Time",
      key: "RS-MET",
      type: "datetimepicker",
      required: [true, "This field is mandatory", "object"],
      disabledDate: config.disabledDate,
      disabledTime: config.disabledTime,
      onOk: config.onOk,
      value: config.endTime,
      disabled: config.startTime ? false : true,
      format: "DD MM YYYY",
    },
    (config.renderMore || config.reason === "Preventive Maintenance") && {
      label: "Was machine available after maintenance?",
      key: "RS-MAAM",
      type: "dropdown",
      data: ["Yes", "No"],
      required: [true, "This field is mandatory"],
    },
  ];
};

export const Editfields = (config) => {
  const dataval = (tag, runDetails) => {
    if(runDetails){
      for (let i = 0; i<runDetails.length; i++){
        if(tag === runDetails[i].abbreviation){
          return runDetails[i].value
        }
      }
    }
  }
  return [
    config.reason === 'Failure/Trip' && {
      label: 'Failure Detection Method',
      key: 'RS-FDM',
      type: 'dropdown',
      data: DETECTION_DATA,
      required: [true, 'This field is mandatory'],
      toolTip: true,
      content: config.popOverContentDetection
        ? config.popOverContentDetection
        : false,
      change: config.handleFormDetection,
      value: dataval("RS-FDM", config.runDetails) 
    },
    config.reason === 'Failure/Trip' && {
      label: 'Failure Mechanism',
      key: 'RS-FMEC',
      type: 'dropdown',
      data: MECHANISM_DATA,
      required: [true, 'This field is mandatory'],
      toolTip: true,
      content: config.popOverContentMechanism
        ? config.popOverContentMechanism
        : false,
      change: config.handleFormMechanism,
      value: dataval("RS-FMEC", config.runDetails) 
    },
    config.reason === 'Failure/Trip' && {
      label: 'Failure Cause',
      key: 'RS-FC',
      type: 'dropdown',
      data: CAUSE_DATA,
      required: [true, 'This field is mandatory'],
      toolTip: true,
      content: config.popOverContentFailure
        ? config.popOverContentFailure
        : false,
      change: config.handleFormFailure,
      value: dataval("RS-FC", config.runDetails) 

    },
    config.reason === 'Failure/Trip' && {
      label: 'Failure Mode',
      key: 'RS-FMO',
      type: 'dropdown',
      data: MODE_DATA,
      required: [true, 'This field is mandatory'],
      value: dataval("RS-FMO", config.runDetails) 
    },
    config.reason !== 'Preventive Maintenance' && {
      label: 'Was maintenance activity performed?',
      key: 'RS-MAP',
      type: 'dropdown',
      data: ['Yes', 'No'],
      required: [true, 'This field is mandatory'],
      change: config.onSelect,
      value: dataval("RS-MAP", config.runDetails)
    },
    (config.renderMore || config.reason === 'Preventive Maintenance') && {
      label: 'Maintenance Activity',
      key: 'RS-MA',
      type: 'dropdown',
      data: ACTIVITY,
      required: [true, 'This field is mandatory'],
      toolTip: true,
      content: config.popOverContentActivity
        ? config.popOverContentActivity
        : false,
      change: config.handleFormActivity,
      value: dataval("RS-MA", config.runDetails) 
    },
    (config.renderMore || config.reason === 'Preventive Maintenance') && {
      label: 'Maintenance Start Time',
      key: 'RS-MST',
      type: 'datetimepicker',
      required: [true, 'This field is mandatory', 'object'],
      disabledDate: config.disabledDate,
      disabledTime: config.disabledTime,
      onOk: config.onOk,
      change: config.handleChange,
      value: moment(dataval("RS-MST", config.runDetails)) 
    },
    (config.renderMore || config.reason === 'Preventive Maintenance') && {
      label: 'Maintenance End Time',
      key: 'RS-MET',
      type: 'datetimepicker',
      required: [true, 'This field is mandatory', 'object'],
      disabledDate: config.disabledDate,
      disabledTime: config.disabledTime,
      onOk: config.onOk,
      value: moment(dataval("RS-MET", config.runDetails)) ,
      //disabled: config.startTime ? false : true,
      format: 'DD MM YYYY',
    },
    (config.renderMore || config.reason === 'Preventive Maintenance') && {
      label: 'Was machine available after maintenance?',
      key: 'RS-MAAM',
      type: 'dropdown',
      data: ['Yes', 'No'],
      required: [true, 'This field is mandatory'],
      value: dataval("RS-MAAM", config.runDetails)
    },
  ];
};


