import { Icon, Input } from 'antd';
import React from 'react';
import { stPerformanceDataVariables } from '../../../../../../constants/variables';
import { newValidateNumber } from '../../../../../../libs/validator';

export const PerformanceDataShow = (config) => [
  {
    title: 'Data Variables',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return value;
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (item.name !== stPerformanceDataVariables.caseDescription) {
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: 'Case - 1',
    dataIndex: 'case1',
    render: (value, row, col, disabled, item) => {
      if (item.name === stPerformanceDataVariables.caseDescription) {
        return (
          <div>
            <Input
              type="text"
              value={item.case1}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'case1')
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.case1}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'case1')
            }
          />
        </div>
      );
    },
  },
  {
    title: 'Case - 2',
    dataIndex: 'case2',
    render: (value, row, col, disabled, item) => {
      if (item.name === stPerformanceDataVariables.caseDescription) {
        return (
          <div>
            <Input
              type="text"
              value={item.case2}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'case2')
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.case2}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'case2')
            }
          />
        </div>
      );
    },
  },
  {
    title: 'Case - 3',
    dataIndex: 'case3',
    render: (value, row, col, disabled, item) => {
      if (item.name === stPerformanceDataVariables.caseDescription) {
        return (
          <div>
            <Input
              type="text"
              value={item.case3}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'case3')
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.case3}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'case3')
            }
          />
        </div>
      );
    },
  },
  {
    title: 'Case - 4',
    dataIndex: 'case4',
    render: (value, row, col, disabled, item) => {
      if (item.name === stPerformanceDataVariables.caseDescription) {
        return (
          <div>
            <Input
              type="text"
              value={item.case4}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'case4')
              }
            />
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.case4}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'case4')
            }
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  {
    name: stPerformanceDataVariables.caseDescription,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.throttleSteamFlow,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.suctionPressure,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.suctionTemperature,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.exhaustPressure,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.exhaustQuality,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.powerOutput,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.rotationalSpeed,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.steamRate,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
  {
    name: stPerformanceDataVariables.outputTorque,
    unit: null,
    case1: parseFloat(''),
    case2: parseFloat(''),
    case3: parseFloat(''),
    case4: parseFloat(''),
  },
];

export const ChangeKey = {
  [stPerformanceDataVariables.caseDescription]: 'caseDescription',
  [stPerformanceDataVariables.throttleSteamFlow]: 'throttleSteamFlow',
  [stPerformanceDataVariables.suctionPressure]: 'suctionPressure',
  [stPerformanceDataVariables.suctionTemperature]: 'suctionTemperature',
  [stPerformanceDataVariables.exhaustPressure]: 'exhaustPressure',
  [stPerformanceDataVariables.exhaustQuality]: 'exhaustQuality',
  [stPerformanceDataVariables.powerOutput]: 'powerOutput',
  [stPerformanceDataVariables.rotationalSpeed]: 'rotationalSpeed',
  [stPerformanceDataVariables.steamRate]: 'steamRate',
  [stPerformanceDataVariables.outputTorque]: 'outputTorque',
};
