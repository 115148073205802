export default {

    GET_PIM_CONFIG_DATA_PROGRESS: 'GET_PIM_DESIGN_PERF_DATA_PROGRESS',
    GET_PIM_CONFIG_DATA_SUCCESS: 'GET_PIM_DESIGN_PERF_DATA_SUCCESS',
    GET_PIM_CONFIG_DATA_FAILURE: 'GET_PIM_DESIGN_PERF_DATA_FAILURE',
    GET_PIM_CONFIG_DATA_RESET: 'GET_PIM_DESIGN_PERF_DATA_RESET',
  
    UPDATE_PIM_DESIGN_DATA_PROGRESS: 'UPDATE_PIM_DESIGN_PERF_DATA_PROGRESS',
    UPDATE_PIM_DESIGN_DATA_SUCCESS: 'UPDATE_PIM_DESIGN_PERF_DATA_SUCCESS',
    UPDATE_PIM_DESIGN_DATA_FAILURE: 'UPDATE_PIM_DESIGN_PERF_DATA_FAILURE',
    UPDATE_PIM_DESIGN_DATA_RESET: 'UPDATE_PIM_DESIGN_PERF_DATA_RESET',

    UPDATE_PIM_ITERATION_DATA_PROGRESS: 'UPDATE_PIM_ITERATION_DATA_PROGRESS',
    UPDATE_PIM_ITERATION_DATA_SUCCESS: 'UPDATE_PIM_ITERATION_DATA_SUCCESS',
    UPDATE_PIM_ITERATION_DATA_FAILURE: 'UPDATE_PIM_ITERATION_DATA_FAILURE',
    UPDATE_PIM_ITERATION_DATA_RESET: 'UPDATE_PIM_ITERATION_DATA_RESET',
  
    RESET_PIM_REDUCER: 'RESET_PIM_REDUCER',
  };