const alertInfoMessages = {
    "Status": `Indicates the current status of the alert.
            Alert Status Possible Values: New, Closed, Forwarded, Shelved, Discarded, Disabled.`,
    "Client Status": `Indicates the current status of the alert.
            Alert Status Possible Values: New, Acknowledged, Closed, Shelved.`,
    "Level": `Indicates the recommended urgency in 
            actioning the alert. Click on the drop down to change the alert level. 
            Alert Level Possible Values: Low, Medium, High.`,
    "Alert Date & Time": `Indicates the date and time that the alert was generated. 
            Time is always shown as local time in region where the alert is being viewed.`,
    "Type": `Indicates the alert detection methodology that generated the alert.`,
    "Equipment": `Indicates Equipment Name and ID specified in the equipment configuration.`,
    "Tag": `Indicates Tag ID of the variable or instrument that this alert is associated with.`,
    "Tag Description": `Indicates the name of the variable or instrument that this alert is associated with.`,
    "Event Name": `Indicates Name of the Event that this alert is associated with. An event is a collection of 
            two or more alerts.`,
    "Last Edited By": `Indicates the User name of the user account that last edited this alert.`,
    "Last Edited At": `Indicates the date and time that the alert was last edited. Time is always shown as local time in region where the 
                alert is being viewed.`,
    "Alert Assignees": `Indicates one or more users that have been assigned to this alert. Click on ‘+ Add’ to 
                add an alert assignee and ‘X’ to remove an alert assignee. `,
    "Alert Labels": `Indicates a list of one or more labels that can be used for additional classification of the alert. Click on the drop down to see a list of all available alert labels.`,
    "Alert Id" : `Indicates the unique id associated with the alert.  `            

}

const getAlertInfoMessage = (role, key) => {
    if ((role === 'MechademyAdmin' ||
        role === 'MechademyFacilityManager') && key === "Status") {
        return alertInfoMessages[key]
    }
    else if(key === "Status"){
        return alertInfoMessages["Client Status"]
    }
    else{
        return alertInfoMessages[key]
    }
}

export default getAlertInfoMessage