import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import _ from 'lodash';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  MotorDesignDataMessages,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  saveDesignData,
  ResetDesignData,
} from '../../../../../../redux/actions/hydraulicVariableSpeedGear.action';
import Loader from '../../../../../../common/Loader/Loader';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  keyChange,
  initialData,
  hvsgDesignDataTable,
  hvsgDesignData,
  validateData,
} from './hvsgDesignDataConstants';
import { hvsgDesignDataVariables } from '../../../../../../constants/variables';
import './hvsgDesignData.scss';

const HydraulicVariableSpeedGearDesignDataConfig = (props) => {
  const {
    isSchematicEditable,
    location,
    network,
    saveDesignData,
    ResetDesignData,
    HVSGConfigData,
    addDesignData,
  } = props;

  const [formEdit, setFormEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [displayData, setDisplayData] = useState(initialData);
  const [copyDisplayData, setCopyDisplayData] = useState(initialData);
  const [saveListener, setSaveListener] = useState(false);
  const [editTableRows, setEditableRows] = useState([]);
  const [dirtyValues, setDirtyValues] = useState([]);

  // upon successfully saving design data
  useEffect(() => {
    if (addDesignData.isSuccess) {
      disableEditForm();
      ResetDesignData();
      setCopyDisplayData(displayData);
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    } else if (addDesignData.isError) {
      Notification.show(Types.Error, addDesignData.apiMsg);
    }
  }, [addDesignData, displayData, ResetDesignData]);

  const setData = useCallback(() => {
    if (HVSGConfigData.isSuccess) {
      const tempDesignData = HVSGConfigData.designData;
      const tempDisplayData = Object.values(hvsgDesignDataVariables).map(
        (item) => {
          if (item === hvsgDesignDataVariables.speedType) {
            return {
              name: item,
              design: tempDesignData
                ? tempDesignData[keyChange[item]]
                  ? tempDesignData[keyChange[item]]
                  : ''
                : '',
            };
          }
          return {
            name: item,
            design: tempDesignData
              ? tempDesignData[keyChange[item]]
                ? tempDesignData[keyChange[item]].design
                : ''
              : '',
          };
        }
      );
      setDisplayData(_.cloneDeep(tempDisplayData));
      setCopyDisplayData(_.cloneDeep(tempDisplayData));
    }
  }, [HVSGConfigData]);

  useEffect(() => {
    setData();
    window.addEventListener('form-edit', () => {
      setFormEdit(true);
    });
    window.addEventListener('form-edit-false', (e) => {
      setFormEdit(false);
      setSaveListener(false);
      setEditableRows([]);
    });
  }, [setData]);

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  const handleChange = (val, variableName, type) => {
    enableEditForm();
    const tempDisplayData = displayData.map((item) => {
      if (item.name === variableName && type === 'number') {
        let designValue = val;
        if (!isNaN(parseFloat(val))) {
          designValue = parseFloat(val);
        }
        return {
          name: variableName,
          unit: item.unit,
          design: designValue,
        };
      } else if (item.name === variableName && type === 'select') {
        return { name: variableName, unit: item.unit, design: val };
      } else if (item.name === variableName && type === 'unit') {
        return { name: variableName, unit: val, design: item.design };
      } else if (item.name === variableName && type === 'text') {
        return { name: variableName, design: val };
      }
      return item;
    });
    setDisplayData(_.cloneDeep(tempDisplayData));
    enableEditForm();
  };

  const handleOk = () => {
    setDisplayData(copyDisplayData);
    setVisible(false);
    disableEditForm();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNetwork = () => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  };

  const onReset = () => {
    setDisplayData(_.cloneDeep(copyDisplayData));
    setVisible(false);
    disableEditForm();
  };

  const onSave = () => {
    setSaveListener(true);
    let payload = {};
    const { isInValid, isMinGuideVaneGreaterThanMax } = validateData(
      displayData,
      setDirtyValues
    );
    if (!isInValid & isMinGuideVaneGreaterThanMax) {
      Notification.show(
        Types.Error,
        'Min Guide Vane Position Can not be Greater than Max Guide Vane position'
      );
    } else if (!isInValid) {
      displayData.forEach((item) => {
        if (hvsgDesignDataVariables.speedType === item.name) {
          payload[keyChange[item.name]] = item.design;
        } else if (hvsgDesignDataVariables.minGuideVanePosition === item.name) {
          payload[keyChange[item.name]] = {
            design: parseFloat(item.design),
          };
        } else {
          payload[keyChange[item.name]] = {
            design: item.design,
          };
        }
      });

      const { state } = location;
      if (handleNetwork() && state && state.componentData.info._id && payload) {
        payload = { designData: payload };
        saveDesignData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, MotorDesignDataMessages.DIRTY_DATA);
    }
  };

  const onEdit = (row) => {
    let editTable = editTableRows;
    editTable.push(row);
    editTable.sort();
    setEditableRows(editTable);
    enableEditForm();
  };

  const onUndo = (row) => {
    let editTable = editTableRows;
    editTable = editTable.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);
    setEditableRows(editTable);
    setDisplayData(tempDisplayData);
    enableEditForm();
  };

  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  };

  // Render component
  return (
    <>
      <div className="HVSGDesignData">
        {(HVSGConfigData.isLoading || addDesignData.isLoading) && <Loader />}
        <CustomTable
          showHeader={true}
          columns={hvsgDesignDataTable({
            handleChange: handleChange,
            onEdit: onEdit,
            onUndo: onUndo,
            saveListener,
            isSchematicEditable,
            dirtyValues,
          })}
          data={hvsgDesignData(displayData)}
          editableRow={editTableRows}
        />
      </div>
      {formEdit && (
        <div className="config-bottom-buttons">
          <button className="btn-default btn-white" onClick={onReset}>
            {ButtonVariables.RESET}
          </button>
          <button className="btn-default" onClick={onSave}>
            {ButtonVariables.SAVE}
          </button>
        </div>
      )}
      {renderModal()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    addDesignData:
      state.HydraulicVariableSpeedGearComponentReducer.addDesignData,
    HVSGConfigData:
      state.HydraulicVariableSpeedGearComponentReducer.HVSGConfigData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
  ResetDesignData: () => dispatch(ResetDesignData()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HydraulicVariableSpeedGearDesignDataConfig)
);
