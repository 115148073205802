import { Icon, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ic_csv from "../../../../../../../assets/ic_csv/ic_csv.svg";
import ic_upload from "../../../../../../../assets/ic_upload/ic_upload.svg";
import Notification, { Types } from '../../../../../../../common/Notification/Notification';
import { CsvValidationMessage } from '../../../../../../../constants/messages';
import validateFields from './validateFileds';

const UploadModal = (props) => {
    const fileInput = React.createRef();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileData, setFileData] = useState([]);
    const [validationFlag, setValidationFlag] = useState(false);
    const fileReader = new FileReader();

    useEffect(() => () => {
        setFile(null);
        setFileName("");
    }, []);

    useEffect(() => {
        if(validationFlag && fileData.length){
            const validatedData = validateFields(fileData, props)
            const {asTestedPayload , operationalPayload } = validatedData
            if(asTestedPayload.length && operationalPayload.length){
                props.setAsTestedCurve(asTestedPayload)
                props.setOperationalBasedCurve(operationalPayload)
                props.setCopyAsTestedCurve(asTestedPayload)
                props.setCopyOperationalBasedCurve(operationalPayload)
                enableEditForm()
            }
        }
    },[fileData, validationFlag]) // eslint-disable-line

    const enableEditForm = () => {
        window.dispatchEvent(new Event('form-edit'));
    }

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        setFileData([])
        setValidationFlag(false)
        e.preventDefault();
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                let error = false
                const processCSV = (str, delim = ',') => {
                    const headers = str.slice(0, str.indexOf('\n')).trim().split(delim);
                    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
                    const newArray = rows.map(row => {
                        const values = row.split(delim);
                        const eachObject = headers.reduce((obj, header, i) => {
                            if(values[i] === '' || isNaN(Number(values[i]))){
                                error= true
                                return Notification.show(Types.Error, CsvValidationMessage.NOT_NUMBER)
                            }
                            else{
                                obj[header] = Number(values[i]);
                                return obj;
                            }
                        }, {})
                        return eachObject;
                    })
                   if(!error){
                    setFileData(newArray)
                    setValidationFlag(true)
                    setFile(null);
                   }
                }
                processCSV(csvOutput)
            };
            fileReader.readAsText(file);
        }
        else if (!file){
            Notification.show(Types.Error, CsvValidationMessage.UPLOAD_FILE)
        }
        props.setVisibility(false)
        clearFileData()
    };

    const fileUpload = () => {
        if (file === null) {
            fileInput.current.click();
        }
    };
    const clearFileData = () => {
        setFileName("");
        setFile(null);
        fileInput.current.value = null;
    };

    return (
        <Modal
            visible={props.visible}
            onCancel={() => { props.setVisibility(false); clearFileData() }}
            title={"Upload File"}
            onOk={(e) => handleOnSubmit(e)}
        >
            <div className="upload-file-main-screen">
                <div className={file === null ? "file-input" : "file-uploaded"}>
                    {file !== null && (
                        <div className="modal-cross-icon">
                            <span onClick={() => clearFileData()}>
                                <Icon type="close-circle" />
                            </span>
                        </div>
                    )}
                    {file === null ? (
                        <div onClick={() => fileUpload()}>
                            <div className="csv-file-upload-icon">
                                <img alt="" src={ic_upload} />
                            </div>
                            <div className="content">Data for {props.graphName} curves</div>
                        </div>
                    ) : (
                        <div onClick={() => fileUpload()}>
                            <img src={ic_csv} alt="csv-icon" />
                            <div className="content">{fileName}</div>
                        </div>
                    )}
                    <form className="form-inline" encType="multipart/form-data">
                        <div onClick={() => fileUpload()} />
                        <input
                            accept=".csv"
                            type="file"
                            className="input-csv-file"
                            onChange={handleOnChange}
                            ref={fileInput}
                        />
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default UploadModal