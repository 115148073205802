import _ from 'lodash';
import moment from 'moment';
import {
  DELETE_KPI_BY_KPIID_SUCCESS,
  UPDATE_KPI_BULK_SUCCESS,
} from '../AddNewCard/addNewCard.types';
import {
  ADD_NEW_TREND_CARD_ERROR,
  ADD_NEW_TREND_CARD_PROGRESS,
  ADD_NEW_TREND_CARD_SUCCESS,
  TRENDS_BY_LEVEL_FETCH_ERROR,
  TRENDS_BY_LEVEL_FETCH_PROGRESS,
  TRENDS_BY_LEVEL_FETCH_SUCCESS,
  INSPECTION_MODE_ON,
  INSPECTION_MODE_OFF,
  TRENDS_FETCH_ERROR,
  TRENDS_FETCH_PROGRESS,
  TREND_PARTICULAR_FETCH_SUCCESS,
  TRENDS_FETCH_SUCCESS,
  TRENDS_BY_ID_FETCH_PROGRESS,
  TRENDS_BY_ID_FETCH_SUCCESS,
  TRENDS_BY_ID_FETCH_ERROR,
  TRENDS_CLEAR_STATE,
  SEARCH_KPI_SUCCESS,
  SEARCH_KPI_ERROR,
} from '../Types/trends.types';

const INITIAL_CARD_STATE = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: null,
  errorMsg: null,
  viewDateFrom: null,
  viewDateTill: null,
};

const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: null,
  errorMsg: null,
  addNewCardSuccess: false,
  addNewCardError: false,
  addNewCardData: null,
  ids: {},
  kpisDataState: {},
  inspectionMode: false,
  isSuccessEdit: false,
  dataProcessed: false,
};

let series = [];

const trendsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRENDS_BY_LEVEL_FETCH_PROGRESS:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };

    case INSPECTION_MODE_ON:
      return {
        ...state,
        inspectionMode: true,
      };

    case INSPECTION_MODE_OFF:
      return {
        ...state,
        inspectionMode: false,
      };

    case TRENDS_BY_LEVEL_FETCH_SUCCESS:
      const obj = {};
      action.payload &&
        action.payload.data.length &&
        action.payload.data.forEach((kpiId) => {
          obj[kpiId._id] = {
            ...INITIAL_CARD_STATE,
            data: {
              y: kpiId.axis.y.map((kpiData) => ({
                _id: kpiData.value,
                data: [],
              })),
            },
          };
        });
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        ids: action.payload,
        kpisDataState: obj,
      };

    case SEARCH_KPI_SUCCESS:
      const object = {};
      action.payload &&
        action.payload.data.length &&
        action.payload.data.forEach((kpiId) => {
          object[kpiId._id] = {
            ...INITIAL_CARD_STATE,
            data: {
              y: kpiId.axis.y.map((kpiData) => ({
                _id: kpiData.value,
                data: [],
              })),
            },
          };
        });
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        ids: { data: action.payload.data },
        kpisDataState: object,
      };

    case SEARCH_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMsg: action.payload.response.body.message,
      };

    case TRENDS_BY_LEVEL_FETCH_ERROR:
      return {
        ...INITIAL_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case TRENDS_FETCH_PROGRESS:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload]: {
            isLoading: true,
            isError: false,
            isSuccess: false,
            data: null,
            errorCode: null,
            errorMsg: null,
            viewDateFrom: null,
            viewDateTill: null,
          },
        },
      };

    case TRENDS_FETCH_SUCCESS:
      series = [];
      if (action.payload.response.series) {
        series = action.payload.response.series.map((pitag) => {
          let data = [];
          let metaMaxMinData = [];
          let metaMaxMinPercentileData = [];
          data = pitag.data.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinData = pitag.maxMinData.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinPercentileData = pitag.maxMinPercentile.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          data = data.sort();
          metaMaxMinData = metaMaxMinData.sort();
          metaMaxMinPercentileData = metaMaxMinPercentileData.sort();
          return {
            name: pitag.name,
            data: data,
            tooltip: pitag.tooltip,
            yAxis: pitag.yAxis,
            _id: pitag.tag,
            metaMaxMinData: metaMaxMinData,
            metaMaxMinPercentileData: metaMaxMinPercentileData,
          };
        });
      }
      const tempSeries = [];
      if (
        action.payload.response.gData.result &&
        action.payload.response.gData.result.length > 0
      ) {
        let yAxisNum = 0;
        let xAxisNum = 0;
        action.payload.response.gData.result.forEach((element) => {
          const xTag = element.x[0]?._id;
          const yTag = element.y[0]?._id;
          if (xTag && yTag) {
            let xData,
              yData,
              xunit,
              yunit,
              xVariableName,
              yVariableName,
              xmetaMaxMinData,
              xmetaMaxMinPercentileData,
              ymetaMaxMinData,
              ymetaMaxMinPercentileData;
            series.forEach((i) => {
              if (i._id === xTag) {
                xData = i.data;
                xmetaMaxMinData = i.metaMaxMinData;
                xmetaMaxMinPercentileData = i.metaMaxMinPercentileData;
                xunit = i.tooltip.valueSuffix;
                xVariableName = i.name;
              }
              if (i._id === yTag) {
                yData = i.data;
                ymetaMaxMinData = i.metaMaxMinData;
                ymetaMaxMinPercentileData = i.metaMaxMinPercentileData;
                yunit = i.tooltip.valueSuffix;
                yVariableName = i.name;
              }
            });
            let tempObj = {
              name: '',
              data: [],
              metaMaxMinData: [],
              metaMaxMinPercentileData: [],
              xPiTag: xTag,
              yPiTag: yTag,
              xVariableName,
              yVariableName,
              yAxis: 0,
              xAxis: 0,
            };
            let length = Math.min(xData.length, yData.length);
            for (let i = 0; i < length; i++) {
              tempObj.data.push({
                x: xData[i][1],
                y: yData[i][1],
                time: moment(xData[i][0]).format('DD/MM/YY HH:mm:ss'),
                epoch: xData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            length = Math.min(xmetaMaxMinData.length, ymetaMaxMinData.length);
            for (let i = 0; i < length; i++) {
              tempObj.metaMaxMinData.push({
                x: xmetaMaxMinData[i][1],
                y: ymetaMaxMinData[i][1],
                time: moment(xmetaMaxMinData[i][0]).format('DD/MM/YY HH:mm:ss'),
                epoch: xmetaMaxMinData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            length = Math.min(
              xmetaMaxMinPercentileData.length,
              ymetaMaxMinPercentileData.length
            );
            for (let i = 0; i < length; i++) {
              tempObj.metaMaxMinPercentileData.push({
                x: xmetaMaxMinPercentileData[i][1],
                y: ymetaMaxMinPercentileData[i][1],
                time: moment(xmetaMaxMinPercentileData[i][0]).format(
                  'DD/MM/YY HH:mm:ss'
                ),
                epoch: xmetaMaxMinPercentileData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            tempObj.yAxis = yAxisNum;
            tempObj.xAxis = xAxisNum;
            tempObj.name = `Series ${yAxisNum + 1}`;
            tempSeries.push(tempObj);
            yAxisNum++;
            xAxisNum++;
          }
        });
        series = [...tempSeries];
      }
      const xyPlot =
        action.payload.response.gData.result &&
        action.payload.response.gData.result.length > 0
          ? true
          : false;
      return {
        ...state,
        dataProcessed: true,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: false,
            isSuccess: true,
            data: _.cloneDeep(series),
            errorCode: null,
            errorMsg: null,
            viewDateFrom: action.payload.viewDateFrom,
            viewDateTill: action.payload.viewDateTill,
            xyPlot: xyPlot,
          },
        },
      };

    case TRENDS_FETCH_ERROR:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: true,
            isSuccess: false,
            data: null,
            viewDateFrom: null,
            viewDateTill: null,
            errorCode:
              action.payload.err.response &&
              action.payload.err.response.body.code,
            errorMsg:
              action.payload.err.response &&
              action.payload.err.response.body.message,
          },
        },
      };

    case ADD_NEW_TREND_CARD_PROGRESS:
      return {
        ...state,
        isLoading: true,
        addNewCardData: null,
        addNewCardSuccess: false,
        addNewCardError: false,
      };

    case ADD_NEW_TREND_CARD_SUCCESS: {
      const dataAppend = state.ids.data;
      const newObj = {};

      dataAppend.push(action.payload);
      newObj[action.payload._id] = {
        ...INITIAL_CARD_STATE,
        data: {
          y: action.payload.axis.y.map((kpiData) => ({
            _id: kpiData.value,
            data: [],
          })),
        },
      };

      return {
        ...state,
        ids: {
          data: dataAppend,
        },
        kpisDataState: {
          ...state.kpisDataState,
          ...newObj,
        },
        isLoading: false,
        addNewCardSuccess: true,
        addNewCardError: false,
        addNewCardData: action.payload,
      };
    }

    case ADD_NEW_TREND_CARD_ERROR: {
      return {
        ...state,
        isLoading: false,
        addNewCardSuccess: false,
        addNewCardError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        errorMsg:
          action.payload.response && action.payload.response.body.message,
      };
    }

    case TREND_PARTICULAR_FETCH_SUCCESS: {
      series = [];
      if (action.payload.response.series) {
        series = action.payload.response.series.map((pitag) => {
          let data = [];
          let metaMaxMinData = [];
          let metaMaxMinPercentileData = [];
          data = pitag.data.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinData = pitag.maxMinData.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinPercentileData = pitag.maxMinPercentile.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          data = data.sort();
          metaMaxMinData = metaMaxMinData.sort();
          metaMaxMinPercentileData = metaMaxMinPercentileData.sort();
          return {
            name: pitag.name,
            data: data,
            tooltip: pitag.tooltip,
            yAxis: pitag.yAxis,
            _id: pitag.tag,
            metaMaxMinData: metaMaxMinData,
            metaMaxMinPercentileData: metaMaxMinPercentileData,
          };
        });
      }
      const tempSeries = [];
      if (
        action.payload.response.gData.result &&
        action.payload.response.gData.result.length > 0
      ) {
        let yAxisNum = 0;
        let xAxisNum = 0;
        action.payload.response.gData.result.forEach((element) => {
          const xTag = element.x[0]?._id;
          const yTag = element.y[0]?._id;
          if (xTag && yTag) {
            let xData,
              yData,
              xunit,
              yunit,
              xVariableName,
              yVariableName,
              xmetaMaxMinData,
              xmetaMaxMinPercentileData,
              ymetaMaxMinData,
              ymetaMaxMinPercentileData;
            series.forEach((i) => {
              if (i._id === xTag) {
                xData = i.data;
                xmetaMaxMinData = i.metaMaxMinData;
                xmetaMaxMinPercentileData = i.metaMaxMinPercentileData;
                xunit = i.tooltip.valueSuffix;
                xVariableName = i.name;
              }
              if (i._id === yTag) {
                yData = i.data;
                ymetaMaxMinData = i.metaMaxMinData;
                ymetaMaxMinPercentileData = i.metaMaxMinPercentileData;
                yunit = i.tooltip.valueSuffix;
                yVariableName = i.name;
              }
            });
            let tempObj = {
              name: '',
              data: [],
              metaMaxMinData: [],
              metaMaxMinPercentileData: [],
              xPiTag: xTag,
              yPiTag: yTag,
              xVariableName,
              yVariableName,
              yAxis: 0,
              xAxis: 0,
            };
            let length = Math.min(xData.length, yData.length);
            for (let i = 0; i < length; i++) {
              tempObj.data.push({
                x: xData[i][1],
                y: yData[i][1],
                time: moment(xData[i][0]).format('DD/MM/YY HH:mm:ss'),
                epoch: xData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            length = Math.min(xmetaMaxMinData.length, ymetaMaxMinData.length);
            for (let i = 0; i < length; i++) {
              tempObj.metaMaxMinData.push({
                x: xmetaMaxMinData[i][1],
                y: ymetaMaxMinData[i][1],
                time: moment(xmetaMaxMinData[i][0]).format('DD/MM/YY HH:mm:ss'),
                epoch: xmetaMaxMinData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            length = Math.min(
              xmetaMaxMinPercentileData.length,
              ymetaMaxMinPercentileData.length
            );
            for (let i = 0; i < length; i++) {
              tempObj.metaMaxMinPercentileData.push({
                x: xmetaMaxMinPercentileData[i][1],
                y: ymetaMaxMinPercentileData[i][1],
                time: moment(xmetaMaxMinPercentileData[i][0]).format(
                  'DD/MM/YY HH:mm:ss'
                ),
                epoch: xmetaMaxMinPercentileData[i][0],
                xPiTag: xTag,
                yPiTag: yTag,
                xunit,
                yunit,
              });
            }
            tempObj.yAxis = yAxisNum;
            tempObj.xAxis = xAxisNum;
            tempObj.name = `Series ${yAxisNum + 1}`;
            tempSeries.push(tempObj);
            yAxisNum++;
            xAxisNum++;
          }
        });
        series = [...tempSeries];
      }
      const xyPlot =
        action.payload.response.gData.result &&
        action.payload.response.gData.result.length > 0
          ? true
          : false;
      return {
        ...state,
        dataProcessed: true,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: false,
            isSuccess: true,
            data: _.cloneDeep(series),
            viewDateFrom: action.payload.viewDateFrom,
            viewDateTill: action.payload.viewDateTill,
            xyPlot: xyPlot,
            errorCode: null,
            errorMsg: null,
          },
        },
      };
    }

    case DELETE_KPI_BY_KPIID_SUCCESS: {
      state.ids.data &&
        state.ids.data.find(
          (card, i) =>
            card._id === action.payload.kpiId && state.ids.data.splice(i, 1)
        );
      delete state.kpisDataState[action.payload.kpiId];
      return {
        ...state,
      };
    }

    case TRENDS_BY_ID_FETCH_SUCCESS:
      let newData = [];
      const updatedCard = action.payload;
      const index =
        state.ids.data &&
        state.ids.data.findIndex((card) => card._id === updatedCard._id);
      newData = state.ids.data;
      newData[index] = updatedCard;

      return {
        ...state,
        ids: {
          data: newData,
        },
        kpisDataState: {
          ...state.kpisDataState,
        },
        isLoading: false,
        isSuccess: false,
        isSuccessEdit: true,
      };

    case TRENDS_BY_ID_FETCH_ERROR:
      return {
        ...INITIAL_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case TRENDS_BY_ID_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case TRENDS_CLEAR_STATE:
      return {
        ...state,
        isSuccessEdit: false,
        dataProcessed: false,
        isError: false,
      };

    case UPDATE_KPI_BULK_SUCCESS:
      let tempTrendsData = _.cloneDeep(state?.ids?.data);
      tempTrendsData.forEach((kpi) => {
        if (kpi.cardType.cardType === 'Time series plot') {
          kpi.inspection = action.updateBody.inspection;
        } else {
          kpi.inspection = {
            mode: action.updateBody.inspection.mode,
            viewDateFrom: action.xyDateFrom,
            viewDateTill: action.updateBody.inspection.viewDateTill,
          };
        }
      });
      return {
        ...state,
        ids: {
          data: tempTrendsData,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default trendsReducer;
