import {
  VARIABLE_PROPERTY_FETCH_PROGRESS,
  VARIABLE_PROPERTY_FETCH_SUCCESS,
  VARIABLE_PROPERTY_FETCH_ERROR,
  VARIABLE_NAME_FETCH_PROGRESS,
  VARIABLE_NAME_FETCH_SUCCESS,
  VARIABLE_NAME_FETCH_ERROR,
  OUTPUT_DATA_FETCH_SUCCESS,
  OUTPUT_DATA_FETCH_PROGRESS,
  OUTPUT_DATA_FETCH_ERROR,
  OUTPUT_DATA_ADD_PROGRESS,
  OUTPUT_DATA_ADD_SUCCESS,
  OUTPUT_DATA_ADD_ERROR,
  CLEAR_STATE,
} from "../Types/outputData.types";

const INITIAL_API_STATE = {
  isVariableGetSuccess: false,
  isVariableNameGetSuccess: false,
  isSuccessOutputDataFetch: false,
  isSuccessOutputDataAdded: false,
  isVariableGetError: false,
  isVariableNameGetError: false,
  isErrorOutputDataFetch: false,
  isErrorOutputDataAdded: false,
  addedOutputData: null,
  outputData: null,
  variableData: null,
  variableNameData: null,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  loading: false,
  apiState: {
    ...INITIAL_API_STATE,
  },
};

export default function outputDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VARIABLE_PROPERTY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isVariableGetSuccess: true,
          variableData: action.payload,
        },
      };

    case VARIABLE_PROPERTY_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case VARIABLE_PROPERTY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          isVariableGetError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case VARIABLE_NAME_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isVariableNameGetSuccess: true,
          variableNameData: action.payload,
        },
      };

    case VARIABLE_NAME_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case VARIABLE_NAME_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isVariableNameGetError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case OUTPUT_DATA_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case OUTPUT_DATA_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          outputData: action.payload,
          isSuccessOutputDataFetch: true,
        },
      };

    case OUTPUT_DATA_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorOutputDataFetch: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case OUTPUT_DATA_ADD_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case OUTPUT_DATA_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isSuccessOutputDataAdded: true,
          addedOutputData: action.payload,
        },
      };

    case OUTPUT_DATA_ADD_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorOutputDataAdded: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
