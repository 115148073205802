import React from "react";
import ReactApexChart from "react-apexcharts";
import cardTypeMapper from "../../../constants/cardTypes";
import labels, { setLabels } from "../graph.constants";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        colors: labels.statusTypeLabels.MechademyAdmin.colorConstants,
        legend: {
          position: "bottom",
          formatter(seriesName) {
            return [`${seriesName} : 0`];
          },
        },
        labels:
          props.cardType === cardTypeMapper["Alert by status pie chart"]
            ? setLabels(props.viewDataAdmin).level
            : props.cardType === cardTypeMapper["Alert by priority pie chart"]
              ? labels.priorityTypeLabels.labelConstants
              : labels.FailureTypeLabels.labelConstants,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      series:
        props.cardType === cardTypeMapper["Alert by status pie chart"]
          ? setLabels(props.viewDataAdmin).count
          : [0, 0, 0, 0],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let level = [];
    let count = [0, 0, 0, 0];

    if (props.cardType === cardTypeMapper["Failure cause pie chart"]) {
      if (
        props.cardValue &&
        props.cardValue.data &&
        props.cardValue.data.length > 0
      ) {
        level = labels.FailureTypeLabels.labelConstants;
        count = labels.FailureTypeLabels.count;
        props.cardValue.data.forEach((barItem) => {
          if (barItem._id === "Design") {
            count[0] = barItem.count;
          } else if (barItem._id === "Fabrication/ installation") {
            count[1] = barItem.count;
          } else if (barItem._id === "Operation/ maintenance") {
            count[2] = barItem.count;
          } else if (barItem._id === "Management") {
            count[3] = barItem.count;
          } else if (barItem._id === "Miscellaneous") {
            count[4] = barItem.count;
          }
        });
        return {
          options: {
            colors: labels.FailureTypeLabels.colorConstants,
            legend: {
              position: "bottom",
              formatter(_seriesName, seriesOptions) {
                if (
                  seriesOptions.w.globals.labels.length >
                  seriesOptions.seriesIndex
                ) {
                  return [
                    `${
                      seriesOptions.w.globals.labels[seriesOptions.seriesIndex]
                    } : ${
                      seriesOptions.w.globals.seriesTotals[
                        seriesOptions.seriesIndex
                      ]
                    }`,
                  ];
                }
              },
            },
            labels: level,
          },
          series: count,
        };
      }
      return null;
    }
    if (
      props.cardValue &&
      props.cardValue.data &&
      props.cardValue.data.length > 0 &&
      props.cardValue.data[0].data
    ) {
      if (props.cardType === cardTypeMapper["Alert by status pie chart"]) {
        level = setLabels(props.viewDataAdmin).level;
        count = setLabels(props.viewDataAdmin).count;
        props.cardValue.data[0].data.forEach((barItem) => {
          if (barItem.status === "New") {
            count[0] = barItem.count;
          } else if (
            barItem.status === "Forward" ||
            barItem.status === "Acknowledged"
          ) {
            count[1] = barItem.count;
          } else if (barItem.status === "Close") {
            count[2] = barItem.count;
          } else if (barItem.status === "Shelved") {
            count[3] = barItem.count;
          } else if (barItem.status === "Disable") {
            count[4] = barItem.count;
          } else if (barItem.status === "Discard") {
            count[5] = barItem.count;
          }
        });
        const pieChartData = { level, count };
        return {
          options: {
            colors: labels.statusTypeLabels.MechademyAdmin.colorConstants,
            legend: {
              position: "bottom",
              formatter(_seriesName, seriesOptions) {
                if (
                  seriesOptions.w.globals.labels.length >
                  seriesOptions.seriesIndex
                ) {
                  return [
                    `${
                      seriesOptions.w.globals.labels[seriesOptions.seriesIndex]
                    } : ${
                      seriesOptions.w.globals.seriesTotals[
                        seriesOptions.seriesIndex
                      ]
                    }`,
                  ];
                }
              },
            },
            labels: pieChartData.level,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: pieChartData.count,
        };
      }
      level = labels.priorityTypeLabels.labelConstants;
      props.cardValue.data[0].data.forEach((barItem) => {
        if (barItem.level === "High") {
          count[0] = barItem.count;
        } else if (barItem.level === "Medium") {
          count[1] = barItem.count;
        } else if (barItem.level === "Low") {
          count[2] = barItem.count;
        } else if (barItem.level === "Unknown") {
          count[3] = barItem.count;
        }
      });
      const pieChartData = { level, count };
      return {
        options: {
          colors: labels.priorityTypeLabels.colorConstants,
          legend: {
            position: "bottom",
            formatter(_seriesName, seriesOptions) {
              if (
                seriesOptions.w.globals.labels.length >
                seriesOptions.seriesIndex
              ) {
                return [
                  `${
                    seriesOptions.w.globals.labels[seriesOptions.seriesIndex]
                  } : ${
                    seriesOptions.w.globals.seriesTotals[
                      seriesOptions.seriesIndex
                    ]
                  }`,
                ];
              }
            },
          },
          labels: pieChartData.level,
        },
        series: pieChartData.count,
      };
    }
    return null;
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height="350"
        />
      </div>
    );
  }
}

export default PieChart;
