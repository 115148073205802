import _ from 'lodash';
import moment from 'moment';
import { GETRequest } from '../../api/request';
import cardTypeMapper from '../../constants/cardTypes';
import { dbDeleteHandling } from '../../libs/token';
import {
  destructurefn,
  getDataIDB,
  updateDataIDB,
} from '../../services/indexedDB';
import {
  PLOTS_CLEAR_STATE,
  PLOTS_FETCH_ERROR,
  PLOTS_FETCH_PROGRESS,
  PLOTS_FETCH_SUCCESS,
  PLOTS_PARTICULAR_FETCH_SUCCESS,
} from '../Types/plots.types';
/** Check for data in DB and fetch else api hit */
export function getPlotByKPIId(kpiId, card, batchTime = null) {
  const cardType = card && card.cardType?.cardType;
  if (card && cardType === cardTypeMapper['Time series plot']) {
    const currentDateTime = moment(batchTime * 1000);
    const currentDateTime2 = _.cloneDeep(currentDateTime);
    const dateFrom = currentDateTime.subtract(8, 'hours').valueOf();
    const dateTill = currentDateTime2.valueOf();

    return async function (dispatch) {
      dispatch({ type: PLOTS_FETCH_PROGRESS, payload: kpiId });
      /** check in db for data with st,endt */
      const idbData = await getDataIDB(kpiId);
      /** if idbData data present */
      if (
        idbData &&
        idbData.response &&
        idbData.response.series &&
        idbData.response.startTimestamp &&
        false
      ) {
      } else {
        /** if idbData data not present */
        GETRequest(`plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}`)
          .then((res) => {
            const data = idbData
              ? destructurefn(
                  JSON.parse(res.text),
                  idbData,
                  15000,
                  false,
                  false,
                  true,
                  dateFrom,
                  dateTill
                )
              : res.text;
            idbData &&
              updateDataIDB({
                _id: kpiId,
                piTags: idbData.piTags,
                response: data,
              });
            dispatch({
              type: PLOTS_FETCH_SUCCESS,
              payload: {
                kpiId,
                response: data,
                isTimeSeries: true,
                dateFrom,
                dateTill,
              },
            });
          })
          .catch((err) => {
            if (
              err.message ===
              "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
            ) {
              dbDeleteHandling();
            } else {
              return dispatch({
                type: PLOTS_FETCH_ERROR,
                payload: { kpiId, err },
              });
            }
          });
      }
    };
  }
  /** Api call for other cards */
  return function (dispatch) {
    dispatch({ type: PLOTS_FETCH_PROGRESS, payload: kpiId });
    const currentDateTime = moment(batchTime * 1000);
    const currentDateTime2 = _.cloneDeep(currentDateTime);
    let dateFrom = '';
    if (
      (card && cardType === cardTypeMapper['Failure cause pie chart']) ||
      cardType === cardTypeMapper['Failure bar chart']
    ) {
      const cardDuration = card && card.cardDuration;
      if (cardDuration === '1 years') {
        dateFrom = currentDateTime.subtract(1, 'years').valueOf();
      } else if (cardDuration === '6 months') {
        dateFrom = currentDateTime.subtract(6, 'months').valueOf();
      } else {
        dateFrom = currentDateTime.subtract(1, 'months').valueOf();
      }
    } else {
      dateFrom = currentDateTime.subtract(2, 'hours').valueOf();
    }
    const dateTill = currentDateTime2.valueOf();
    GETRequest(`plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}`)
      .then((res) => {
        dispatch({
          type: PLOTS_FETCH_SUCCESS,
          payload: { kpiId, response: res.text },
        });
      })
      .catch((err) =>
        dispatch({ type: PLOTS_FETCH_ERROR, payload: { kpiId, err } })
      );
  };
}

export function getParticularPlotDataByKPIId({
  id: kpiId,
  cardType,
  dateFrom,
  dateTill,
  duration,
}) {
  let idbData = {};
  if (cardType === cardTypeMapper['Time series plot']) {
    return async function (dispatch) {
      dispatch({ type: PLOTS_FETCH_PROGRESS, payload: kpiId });

      try {
        idbData = await getDataIDB(kpiId);
      } catch (err) {
        console.error('Error: ', err);
      }
      /** if idbData data present */
      if (
        idbData &&
        idbData.response &&
        idbData.response.series &&
        idbData.response.startTimestamp &&
        false
      ) {
        // todo compare with all base interval time
      } else {
        // IF DATA NOT PRESENT

        GETRequest(
          `plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}&duration=${duration}`
        )
          .then((res) => {
            const data = idbData
              ? destructurefn(
                  JSON.parse(res.text),
                  idbData,
                  15000,
                  false,
                  false,
                  true,
                  dateFrom,
                  dateTill
                )
              : res.text;
            idbData &&
              updateDataIDB({
                _id: kpiId,
                piTags: idbData.piTags,
                response: data,
              });
            dispatch({
              type: PLOTS_PARTICULAR_FETCH_SUCCESS,
              payload: {
                kpiId,
                response: data,
                isTimeSeries: true,
                dateFrom,
                dateTill,
              },
            });
          })
          .catch((err) => {
            if (
              err.message ===
              "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
            ) {
              dbDeleteHandling();
            } else {
              dispatch({ type: PLOTS_FETCH_ERROR, payload: { kpiId, err } });
            }
          });
      }
    };
  }
  /** Api for other card types */
  return function (dispatch) {
    dispatch({ type: PLOTS_FETCH_PROGRESS, payload: kpiId });
    GETRequest(
      `plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}&duration=${duration}`
    )
      .then((res) => {
        dispatch({
          type: PLOTS_PARTICULAR_FETCH_SUCCESS,
          payload: { kpiId, response: res.text },
        });
      })
      .catch((err) => {
        dispatch({ type: PLOTS_FETCH_ERROR, payload: { kpiId, err } });
      });
  };
}

export function clearPlotState() {
  return function (dispatch) {
    dispatch({ type: PLOTS_CLEAR_STATE });
  };
}
