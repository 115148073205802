export const ADD_EQUIPMENT_FETCH_SUCCESS = "ADD_EQUIPMENT_FETCH_SUCCESS";
export const ADD_EQUIPMENT_FETCH_PROGRESS = "ADD_EQUIPMENT_FETCH_PROGRESS";
export const ADD_EQUIPMENT_FETCH_ERROR = "ADD_EQUIPMENT_FETCH_ERROR";

export const GET_EQUIPMENT_FETCH_ERROR = "GET_EQUIPMENT_FETCH_ERROR";
export const GET_EQUIPMENT_FETCH_SUCCESS = "GET_EQUIPMENT_FETCH_SUCCESS";
export const GET_EQUIPMENT_FETCH_PROGRESS = "GET_EQUIPMENT_FETCH_PROGRESS";

export const EDIT_EQUIPMENT_FETCH_ERROR = "EDIT_EQUIPMENT_FETCH_ERROR";
export const EDIT_EQUIPMENT_FETCH_SUCCESS = "EDIT_EQUIPMENT_FETCH_SUCCESS";
export const EDIT_EQUIPMENT_FETCH_PROGRESS = "EDIT_EQUIPMENT_FETCH_PROGRESS";

export const GET_EQUIPMENT_TYPE_FETCH_ERROR = "GET_EQUIPMENT_TYPE_FETCH_ERROR";
export const GET_EQUIPMENT_TYPE_FETCH_SUCCESS =
  "GET_EQUIPMENT_TYPE_FETCH_SUCCESS";
export const GET_EQUIPMENT_TYPE_FETCH_PROGRESS =
  "GET_EQUIPMENT_TYPE_FETCH_PROGRESS";

export const ADD_EQUIPMENT_TYPE_FETCH_ERROR = "ADD_EQUIPMENT_TYPE_FETCH_ERROR";
export const ADD_EQUIPMENT_TYPE_FETCH_SUCCESS =
  "ADD_EQUIPMENT_TYPE_FETCH_SUCCESS";
export const ADD_EQUIPMENT_TYPE_FETCH_PROGRESS =
  "ADD_EQUIPMENT_TYPE_FETCH_PROGRESS";

export const GET_MANUFACTURER_FETCH_ERROR = "GET_MANUFACTURER_FETCH_ERROR";
export const GET_MANUFACTURER_FETCH_SUCCESS = "GET_MANUFACTURER_FETCH_SUCCESS";
export const GET_MANUFACTURER_FETCH_PROGRESS =
  "GET_MANUFACTURER_FETCH_PROGRESS";

export const ADD_MANUFACTURER_FETCH_ERROR = "ADD_MANUFACTURER_FETCH_ERROR";
export const ADD_MANUFACTURER_FETCH_SUCCESS = "ADD_MANUFACTURER_FETCH_SUCCESS";
export const ADD_MANUFACTURER_FETCH_PROGRESS =
  "ADD_MANUFACTURER_FETCH_PROGRESS";

export const EQUIPMENT_CLEAR_STATE = "EQUIPMENT_CLEAR_STATE";
