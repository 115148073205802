import * as Types from "../Types/assetEquipment.types";

const INITIAL_API_STATE = {
  addData: null,
  getData: null,
  updatedData: null,
  isAddError: false,
  isGetError: false,
  isUpdateError: false,
  isAddSuccess: false,
  isGetSuccess: false,
  isUpdated: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};
export default function assetEquipmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_EQUIPMENTS_PROGRESS:
      return {
        ...state,
        loading: true,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };

    case Types.ADD_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isAddSuccess: true,
          addData: action.payload,
        },
      };

    case Types.ADD_EQUIPMENTS_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isAddError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };

    case Types.GET_EQUIPMENTS_PROGRESS:
      return {
        ...state,
        loading: true,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };

    case Types.GET_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isGetSuccess: true,
          getData: action.payload,
        },
      };

    case Types.GET_EQUIPMENTS_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isGetError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.UPDATE_EQUIPMENTS_PROGRESS:
      return {
        ...state,
        loading: true,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };

    case Types.UPDATE_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          updatedData: action.payload,
          isUpdated: true,
        },
      };

    case Types.UPDATE_EQUIPMENTS_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          isUpdateError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    default:
      return {
        ...INITIAL_STATE,
      };
  }
}
