import Type from '../Types/SQLComponent.types';
const INITIAL_STATE = {
  ConfigData: {
    isLoading: false,
    isError: false,
    errorCode: '',
    apiMsg: '',
    isSuccess: false,
    isUpdateSuccess: false,
    isUpdateError: false,
    componentId: '',
    sqlComponentId: '',
  },
};

export default function SQLComponentReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        ConfigData: {
          ...INITIAL_STATE.ConfigData,
          isLoading: true,
        },
      };
    case Type.GET_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        ConfigData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          sqlComponentId: action.payload.sqlComponentId
            ? action.payload.sqlComponentId
            : INITIAL_STATE.ConfigData.sqlComponentId,
        },
      };
    case Type.GET_CONFIG_DATA_FAILURE:
      return {
        ...state,
        ConfigData: {
          ...state.ConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        ConfigData: {
          ...INITIAL_STATE.ConfigData,
          isLoading: true,
        },
      };
    case Type.UPDATE_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        ConfigData: {
          ...state.ConfigData,
          isLoading: false,
          isUpdateSuccess: true,
          sqlComponentId: action.payload.sqlComponentId
            ? action.payload.sqlComponentId
            : INITIAL_STATE.ConfigData.sqlComponentId,
        },
      };
    case Type.UPDATE_CONFIG_DATA_FAILURE:
      return {
        ...state,
        ConfigData: {
          ...state.ConfigData,
          isLoading: false,
          isUpdateSuccess: false,
          isUpdateError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.CONFIG_DATA_RESET:
      return {
        ...state,
        ConfigData: {
          ...state.ConfigData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          isUpdateError:false,
          isUpdateSuccess:false,
          componentId: '',
          sqlComponentId: '',
        },
      };
    default:
      return {
        ...state,
      };
  }
}
