import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../../../../permissions';
import { setTimeRangeVisibility } from '../../../../../../redux/actions/alertplots.action';
import AddNewCard from '../../../../AddNewCard/AddNewCard';

const RenderAlertPlotButtons = (key, props) => {
    const dispatch = useDispatch();

    let userRoleState = useSelector((state) => state.LoginReducer);
    let kpiData = useSelector((state) => state.AlertPlotKPIReducer.data);
    const { userRole } = userRoleState.apiState;
    const { info, data } = props

    const [viewAddCard, setViewAddCard] = useState(false)

    const customClassName = kpiData.length < 1
        ? 'btn-default btn-time-range disabled'
        : 'btn-default btn-time-range';

    const handleAddCardCancel = () => {
        setViewAddCard(false)
    }

    const handleGlobalTimeModal = () => {
        dispatch(setTimeRangeVisibility(true))
    }

    const renderAddCardModal = () => {
        if (viewAddCard) {
            return (
                <AddNewCard 
                visible={viewAddCard}
                handleCancel={handleAddCardCancel}
                info={info}
                activeKey={'2'}
                alertId={data['_id']}
                />
                )
        }
    }

    return (
        <>
            {key === 1 && userRoleState.accessInfo && (
                <span className="bottom-buttons add_card_button">
                    {(userRole === roles.MECHADEMY_ADMIN ||
                        userRole === roles.MECHADEMY_FACILITY_MANAGER) && (
                            <button
                                type="button"
                                className="btn-default btn-add"
                                onClick={() => setViewAddCard(true)}
                            >
                                {'ADD CARD'}
                            </button>
                        )}
                </span>
            )}
            {key === 1 && userRoleState.accessInfo && (
                <span className="bottom-buttons">
                    {(userRole === roles.MECHADEMY_ADMIN ||
                        userRole === roles.MECHADEMY_FACILITY_MANAGER) && (
                            <button
                                type="button"
                                className={customClassName}
                                onClick={() => handleGlobalTimeModal()}
                                disabled={kpiData.length < 1}
                            >
                                {'Global Time Range'}
                            </button>
                        )}
                </span>
            )}
            {renderAddCardModal()}
        </>
    )
}

export default RenderAlertPlotButtons