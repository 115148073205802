import { UPDATE_FORM_EDIT } from "../Types/formEdit.types";

const INITIAL_STATE = {
  isEdit: false,
};

const formEdit = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_FORM_EDIT:
      return { isEdit: action.status };
    default:
      return state;
  }
};

export default formEdit;
