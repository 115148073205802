import React from "react";
import PropType from "prop-types";
import "./TableCard.scss";

function TableCard(props) {
  return (
    <div className="table-card">
      <div className="table-card-title">{props.title}</div>
      <div>{props.children}</div>
    </div>
  );
}

TableCard.prototypes = {
  title: PropType.string,
};

export default TableCard;
