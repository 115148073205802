export const INPUTDATA_FETCH_PROGRESS = "INPUTDATA_FETCH_PROGRESS";
export const INPUTDATA_FETCH_SUCCESS = "INPUTDATA_FETCH_SUCCESS";
export const INPUTDATA_FETCH_ERROR = "INPUTDATA_FETCH_ERROR";
export const INPUTDATA_FETCH_CLEAR = "INPUTDATA_FETCH_CLEAR";
export const VARIABLENAME_FETCH_PROGRESS = "VARIABLENAME_FETCH_PROGRESS";
export const VARIABLENAME_FETCH_SUCCESS = "VARIABLENAME_FETCH_SUCCESS";
export const VARIABLENAME_FETCH_ERROR = "VARIABLENAME_FETCH_ERROR";
export const VARIABLETAG_FETCH_PROGRESS = "VARIABLETAG_FETCH_PROGRESS";
export const VARIABLETAG_FETCH_SUCCESS = "VARIABLETAG_FETCH_SUCCESS";
export const VARIABLETAG_FETCH_ERROR = "VARIABLETAG_FETCH_ERROR";
export const ADDINPUT_PUT_PROGRESS = "ADDINPUT_PUT_PROGRESS";
export const ADDINPUTDATA_PUT_SUCCESS = "ADDINPUTDATA_PUT_SUCCESS";
export const ADDINPUTDATA_PUT_ERROR = "ADDINPUTDATA_PUT_ERROR";
