import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../../../../../common/CustomTable/CustomTable";
import { filterEquipmentData, renderHeader, Validate } from "./designDataFunctions";
import { designDataHeader } from "./designDataFunctions";
import _ from 'lodash';
import { CapacityControlVariables, CylinderDataVariables, FieldMapper, inputSections, payloadMapper, RecpComperssorDesignVariables, screenConstant, ValveDataVariable } from "./designDataFieldConstants";
import { clearUpdateState, updateDesignData } from "../../../../../../redux/actions/reciprocatingCompressorAction";
import { getConfig } from "../../../../../../redux/actions/config.action";
import { Spin } from "antd";
import { RecipCompDataMessage } from "../../../../../../constants/messages";
import Notification,{ Types } from "../../../../../../common/Notification/Notification";

const RecipCompressorComponent = (props) => {
  //Connecting component to redux
  const data = useSelector((state) => state.DataUnitReducer.apiState.unitData);
  const recipCompReducerData = useSelector((state) => state.reciprocatingCompressorReducer.updateDesignData);
  const dispatch = useDispatch();

  // State definition 
  const [unitData, setUnitData] = useState([]);
  const [componentId, setComponentId] = useState([]);
  const [InitialState, setInitialState] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [copyData, setCopyData] = useState([])
  const [saveFlag, setSaveFlag] = useState(false)
  const [editTableRows, setEditableRows] = useState({
    equipmentData: [],
    cylinderData: [],
    valveData: [],
    capacityControlData: []
  });
  const [showSubmitButton, setShowSubmitButton] = useState(false)
  const [loading, setLoading] = useState(false)

  // component did mount and component did update  
  useEffect(() => {
    FieldMapper(inputSections).then(arr => setInitialState(arr))
  }, [])// eslint-disable-line


  useEffect(() => {
    if (data && data.items) {
      setUnitData(data.items)
    }
  }, [data])// eslint-disable-line

  useEffect(() => {
    setDisplayData(InitialState)

  }, [InitialState])// eslint-disable-line

  useEffect(() => {
    const { componentID } = props
    if (componentId !== componentID) {
      setCopyData([])
    }
    setComponentId(componentID)
  }, [props])// eslint-disable-line
  
  useEffect(() => {
    if (recipCompReducerData.isSuccess) {
      setShowSubmitButton(false)
      setLoading(false)
      setEditableRows({
        equipmentData: [],
        cylinderData: [],
        valveData: [],
        capacityControlData: []
      })
      setSaveFlag(false)
      Notification.show(Types.Success, RecipCompDataMessage.SUCCESS)
      dispatch(clearUpdateState())
      dispatch(getConfig(props.configData.componentId, 'Reciprocating Compressor'))
    }
    else if (recipCompReducerData.isLoading) {
      setLoading(true)
    }
    else if (recipCompReducerData.isError) {
      setLoading(false)
    }
  }, [recipCompReducerData])// eslint-disable-line

  useEffect(() => {
      if((props.configData && props.configData.componentId && props.configData.componentId === componentId) || 
      (recipCompReducerData.data && recipCompReducerData.data.componentId && 
      recipCompReducerData.data.componentId === componentId)){
        let designData = {}
        if(props.configData && props.configData.designData && props.configData.designData.equipmentData
          && props.configData.componentId === componentId 
          ){
          designData = props.configData.designData
        }
        else if( recipCompReducerData.data && recipCompReducerData.data.designData && 
          recipCompReducerData.data.componentId === componentId
          ){
          designData = recipCompReducerData.data.designData
        }
        const prefill = []
        let counter = 0
        if (designData.equipmentData && designData.cylinderData && designData.valveData && designData.capacityControlData) {
          const designDataList = [
            designData.equipmentData,
            designData.cylinderData,
            designData.valveData,
            designData.capacityControlData
          ]
          designDataList.forEach((value) => {
            for (const data in value) {
              if (
                RecpComperssorDesignVariables[data] ||
                CylinderDataVariables[data] ||
                ValveDataVariable[data] ||
                CapacityControlVariables[data]
              ) {
                const prefillValue = {}
                prefillValue.name = screenConstant[counter]["variableName"][data]
                prefillValue.design = value[data].design === 0 ? 0 :value[data].design ? value[data].design : value[data].design === false? false : null
                prefillValue.unit = value[data].unit ? value[data].unit : null
                prefill.push(prefillValue)
              }
            }
            counter = counter + 1
          })
        }
        setDisplayData(prefill)
        setCopyData(prefill)
      }
  }, [props.configData.designData, recipCompReducerData.isSuccess])// eslint-disable-line


  // Component functions 
  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };
  // const disableEditForm = () => {
  //   window.dispatchEvent(new Event('form-edit-false'));
  // };

  const handleChange = (val, variableName, type) => {
    enableEditForm();
    const tempDisplayData = displayData.map((item) => {
      if (item.name === variableName && type === 'number') {
        return { name: variableName, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === variableName && type === 'select') {
        return { name: variableName, unit: item.unit, design: val };
      } else if (item.name === variableName && type === 'unit') {
        return { name: variableName, unit: val, design: item.design };
      }
      else if (item.name === variableName && type === 'text'){
        return { name: variableName, unit: val, design: val }
      }
      return item;
    });
    setDisplayData(_.cloneDeep(tempDisplayData));
    enableEditForm();
  };

  const onEdit = (row, tableName) => {
    let editTable = editTableRows
    editTable[tableName].push(row)
    editTable[tableName].sort()
    setEditableRows(editTable)
    enableEditForm()
    setShowSubmitButton(true)
  }

  const onUndo = (row, tableName, itemName) => {
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    if (copyData.length) {
      displayData.map((data, index) => {
        if (data.name === itemName) {
          displayData[index] = copyData[index]
        }
        return null
      })
    }
    else {
      displayData.map((data, index) => {
        if (data.name === itemName) {
          displayData[index] = InitialState[index]
        }
        return null
      })
    }
    setEditableRows(editTable);
    enableEditForm()
  }



  const onSubmit = async () => {
    setSaveFlag(true)
    const { location } = props
    const { state } = location

    const isInvalid = await Validate(displayData)

    if (!isInvalid) {
      const payload = {}
      payload["equipmentData"] = {}
      payload["cylinderData"] = {}
      payload["valveData"] = {}
      payload["capacityControlData"] = {}
      let index = 0
      for await (let object of payloadMapper) {
        for (const key in object) {
          for await (let data of displayData) {
            if (object[key] === data.name) {
              if (index === 0) {
                payload["equipmentData"][key] = data
              }
              else if (index === 1) {
                payload["cylinderData"][key] = data
              }
              else if (index === 2) {
                payload["valveData"][key] = data
              }
              else if (index === 3) {
                payload["capacityControlData"][key] = data
              }
            }
          }
        }
        index = index + 1
      }
      dispatch(updateDesignData(state.componentData.info._id, payload))
    }
    else{
      Notification.show(Types.Error, RecipCompDataMessage.DIRTY_DATA)
    }
  }

  const onReset = () => {
    setSaveFlag(false)
    setShowSubmitButton(false)
    setEditableRows({
      equipmentData: [],
      cylinderData: [],
      valveData: [],
      capacityControlData: []
    })
    copyData.length ? setDisplayData(copyData) : setDisplayData(InitialState)
  }

  // Render component
  return (
    <>
      {
        loading ? (
          <Spin />
        ) :
          (
            <div className="DesignDataMotor">
              {screenConstant.map((screen, index) => {
                return (
                  <CustomTable
                    showHeader={true}
                    columns={renderHeader(designDataHeader, unitData, onEdit, onUndo, handleChange,
                       screen.heading, screen.stateName, saveFlag
                       )}
                    data={filterEquipmentData(displayData, screen.variableName)}
                    editableRow={editTableRows[screen.stateName]}
                    key={index}
                  ></CustomTable> 
                )
              })}
              {
                showSubmitButton && (
                  <div className="config-bottom-buttons">
                    <button className="btn-default btn-white" onClick={onReset}>
                      {'RESET'}
                    </button>
                    <button className="btn-default" onClick={onSubmit}>
                      {'SAVE'}
                    </button>
                  </div>
                )
              }
            </div>
          )
      }
    </>
  )

}

export default RecipCompressorComponent