import Type from "../Types/gasTurbine.types";

const gasTurbineConfigData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  componentId: "",
  designPerformanceData: {
    designData: {
      application: {},
      gtModel: {},
      gtClass: {},
      gtIsoPower: {},
      gtIsoHeatRate: {},
      gtIsoExhaustFlowRate: {},
      gtIsoSpeed: {},
      configSpeed: {},
      startupSpeed: {},
      startupPower: {},
      defaultAxialCompressorInletPressure: {},
      referenceAmbientPressure: {},
      gtPowerCorrectionFactor: {},
      gtPowerEstCorrectionFactor: {},
      cycle: {},
      gtDriverOutputSpeedRangeMin: {},
      gtDriverOutputSpeedRangeMax: {},
      airCompressorStages: {},
      airCompressorMaximumTipSpeed: {},
      airCompressorType: {},
      airCompressorPressureRatioISOConditions: {},
      airCompressorCasingSplit: {},
      airCompressorRotor: {},
      turbineStages: {},
      turbineMaximumTipSpeed: {},
      turbineCasingSplit: {},
      turbineRotor: {},
      combustorType: {},
      combustorQuantity: {},
      fuelNozzlesPerCombustor: {},
      combustorConfiguration: {},
      fuelType: {},
      combustorMaxAllowableTemperatureVariation: {},
      ratedFuelMoleWeight: {},
      ratedFuelLHV: {},
      fuelPressure: {},
      fuelTemperature: {},
      mineralLubeOil: {},
      syntheticLubeOil: {},
    },
    performanceData: {
      inletLoss: {},
      exhaustLoss: {},
      dryBulbTemp: {},
      turbineInletAirChilled: {},
      turbineInletTemp: {},
      relativeHumidity: {},
      barometricPressure: {},
      gtOutputShaftPower: {},
      gasGeneratorOutputShaftSpeed: {},
      powerTurbineOutputShaftSpeed: {},
      lhvHeatRate: {},
      lhvEfficiency: {},
      firingTemp: {},
      airFlow: {},
      ggExhaustTemp: {},
      ptExhaustFlow: {},
      ptExhaustTemp: {},
      fuelFlowrate: {},
      certifiedPoint: {},
      steamFlow: {},
      waterFlow: {},
    },
  },
  fluidComposition: {
    air: {
      eos_air: "",
      composition_air: [],
    },
    fg: {
      eos_fg: "",
      composition_fg: [],
    },
  },
  curvesData: {},
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addPerformanceData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addFluidCompositionData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addInletTempCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addInletLossesCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addExhaustLossesCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addRelHumidityCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addPercDegradationCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const INITIAL_STATE = {
  gasTurbineConfigData: { ...gasTurbineConfigData },
  addDesignData: { ...addDesignData },
  addPerformanceData: { ...addPerformanceData },
  addFluidCompositionData: { ...addFluidCompositionData },
  addInletTempCurve: { ...addInletTempCurve },
  addInletLossesCurve: { ...addInletLossesCurve },
  addExhaustLossesCurve: { ...addExhaustLossesCurve },
  addRelHumidityCurve: { ...addRelHumidityCurve },
  addPercDegradationCurve: { ...addPercDegradationCurve },
};

export default function gasTurbineReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    /** Get Gas Turbine config data */
    case Type.GET_GT_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        gasTurbineConfigData: {
          ...INITIAL_STATE.gasTurbineConfigData,
          isLoading: true,
        },
      };
    case Type.GET_GT_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        gasTurbineConfigData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          designPerformanceData: {
            /** Design data */
            designData: action.payload.designPerformanceData.designData
              ? {
                application:
                    action.payload.designPerformanceData.designData
                      .application ||
                    action.payload.designPerformanceData.designData
                      .application === 0
                      ? action.payload.designPerformanceData.designData
                        .application
                      : null,
                gtModel:
                    action.payload.designPerformanceData.designData.gtModel ||
                    action.payload.designPerformanceData.designData.gtModel ===
                      0
                      ? action.payload.designPerformanceData.designData.gtModel
                      : null,
                gtClass:
                    action.payload.designPerformanceData.designData.gtClass ||
                    action.payload.designPerformanceData.designData.gtClass ===
                      0
                      ? action.payload.designPerformanceData.designData.gtClass
                      : null,
                gtIsoPower:
                    action.payload.designPerformanceData.designData
                      .gtIsoPower ||
                    action.payload.designPerformanceData.designData
                      .gtIsoPower === 0
                      ? action.payload.designPerformanceData.designData
                        .gtIsoPower
                      : null,
                gtIsoHeatRate:
                    action.payload.designPerformanceData.designData
                      .gtIsoHeatRate ||
                    action.payload.designPerformanceData.designData
                      .gtIsoHeatRate === 0
                      ? action.payload.designPerformanceData.designData
                        .gtIsoHeatRate
                      : null,
                gtIsoExhaustFlowRate:
                    action.payload.designPerformanceData.designData
                      .gtIsoExhaustFlowRate ||
                    action.payload.designPerformanceData.designData
                      .gtIsoExhaustFlowRate === 0
                      ? action.payload.designPerformanceData.designData
                        .gtIsoExhaustFlowRate
                      : null,
                gtIsoSpeed:
                    action.payload.designPerformanceData.designData
                      .gtIsoSpeed ||
                    action.payload.designPerformanceData.designData
                      .gtIsoSpeed === 0
                      ? action.payload.designPerformanceData.designData
                        .gtIsoSpeed
                      : null,
                configSpeed:
                    action.payload.designPerformanceData.designData
                      .configSpeed ||
                    action.payload.designPerformanceData.designData
                      .configSpeed === 0
                      ? action.payload.designPerformanceData.designData
                        .configSpeed
                      : null,
                startupSpeed:
                    action.payload.designPerformanceData.designData
                      .startupSpeed ||
                    action.payload.designPerformanceData.designData
                      .startupSpeed === 0
                      ? action.payload.designPerformanceData.designData
                        .startupSpeed
                      : null,
                startupPower:
                    action.payload.designPerformanceData.designData
                      .startupPower ||
                    action.payload.designPerformanceData.designData
                      .startupPower === 0
                      ? action.payload.designPerformanceData.designData
                        .startupPower
                      : null,
                defaultAxialCompressorInletPressure:
                    action.payload.designPerformanceData.designData
                      .defaultAxialCompressorInletPressure ||
                    action.payload.designPerformanceData.designData
                      .defaultAxialCompressorInletPressure === 0
                      ? action.payload.designPerformanceData.designData
                        .defaultAxialCompressorInletPressure
                      : null,
                referenceAmbientPressure:
                    action.payload.designPerformanceData.designData
                      .referenceAmbientPressure ||
                    action.payload.designPerformanceData.designData
                      .referenceAmbientPressure === 0
                      ? action.payload.designPerformanceData.designData
                        .referenceAmbientPressure
                      : null,
                gtPowerCorrectionFactor:
                    action.payload.designPerformanceData.designData
                      .gtPowerCorrectionFactor ||
                    action.payload.designPerformanceData.designData
                      .gtPowerCorrectionFactor === 0
                      ? action.payload.designPerformanceData.designData
                        .gtPowerCorrectionFactor
                      : null,
                gtPowerEstCorrectionFactor:
                    action.payload.designPerformanceData.designData
                      .gtPowerEstCorrectionFactor ||
                    action.payload.designPerformanceData.designData
                      .gtPowerEstCorrectionFactor === 0
                      ? action.payload.designPerformanceData.designData
                        .gtPowerEstCorrectionFactor
                      : null,
                cycle:
                    action.payload.designPerformanceData.designData.cycle ||
                    action.payload.designPerformanceData.designData.cycle === 0
                      ? action.payload.designPerformanceData.designData.cycle
                      : null,
                gtDriverOutputSpeedRangeMin:
                    action.payload.designPerformanceData.designData
                      .gtDriverOutputSpeedRangeMin ||
                    action.payload.designPerformanceData.designData
                      .gtDriverOutputSpeedRangeMin === 0
                      ? action.payload.designPerformanceData.designData
                        .gtDriverOutputSpeedRangeMin
                      : null,
                gtDriverOutputSpeedRangeMax:
                    action.payload.designPerformanceData.designData
                      .gtDriverOutputSpeedRangeMax ||
                    action.payload.designPerformanceData.designData
                      .gtDriverOutputSpeedRangeMax === 0
                      ? action.payload.designPerformanceData.designData
                        .gtDriverOutputSpeedRangeMax
                      : null,
                airCompressorStages:
                    action.payload.designPerformanceData.designData
                      .airCompressorStages ||
                    action.payload.designPerformanceData.designData
                      .airCompressorStages === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorStages
                      : null,
                airCompressorMaximumTipSpeed:
                    action.payload.designPerformanceData.designData
                      .airCompressorMaximumTipSpeed ||
                    action.payload.designPerformanceData.designData
                      .airCompressorMaximumTipSpeed === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorMaximumTipSpeed
                      : null,
                airCompressorType:
                    action.payload.designPerformanceData.designData
                      .airCompressorType ||
                    action.payload.designPerformanceData.designData
                      .airCompressorType === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorType
                      : null,
                airCompressorPressureRatioISOConditions:
                    action.payload.designPerformanceData.designData
                      .airCompressorPressureRatioISOConditions ||
                    action.payload.designPerformanceData.designData
                      .airCompressorPressureRatioISOConditions === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorPressureRatioISOConditions
                      : null,
                airCompressorCasingSplit:
                    action.payload.designPerformanceData.designData
                      .airCompressorCasingSplit ||
                    action.payload.designPerformanceData.designData
                      .airCompressorCasingSplit === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorCasingSplit
                      : null,
                airCompressorRotor:
                    action.payload.designPerformanceData.designData
                      .airCompressorRotor ||
                    action.payload.designPerformanceData.designData
                      .airCompressorRotor === 0
                      ? action.payload.designPerformanceData.designData
                        .airCompressorRotor
                      : null,
                turbineStages:
                    action.payload.designPerformanceData.designData
                      .turbineStages ||
                    action.payload.designPerformanceData.designData
                      .turbineStages === 0
                      ? action.payload.designPerformanceData.designData
                        .turbineStages
                      : null,
                turbineMaximumTipSpeed:
                    action.payload.designPerformanceData.designData
                      .turbineMaximumTipSpeed ||
                    action.payload.designPerformanceData.designData
                      .turbineMaximumTipSpeed === 0
                      ? action.payload.designPerformanceData.designData
                        .turbineMaximumTipSpeed
                      : null,
                turbineCasingSplit:
                    action.payload.designPerformanceData.designData
                      .turbineCasingSplit ||
                    action.payload.designPerformanceData.designData
                      .turbineCasingSplit === 0
                      ? action.payload.designPerformanceData.designData
                        .turbineCasingSplit
                      : null,
                turbineRotor:
                    action.payload.designPerformanceData.designData
                      .turbineRotor ||
                    action.payload.designPerformanceData.designData
                      .turbineRotor === 0
                      ? action.payload.designPerformanceData.designData
                        .turbineRotor
                      : null,
                combustorType:
                    action.payload.designPerformanceData.designData
                      .combustorType ||
                    action.payload.designPerformanceData.designData
                      .combustorType === 0
                      ? action.payload.designPerformanceData.designData
                        .combustorType
                      : null,
                combustorQuantity:
                    action.payload.designPerformanceData.designData
                      .combustorQuantity ||
                    action.payload.designPerformanceData.designData
                      .combustorQuantity === 0
                      ? action.payload.designPerformanceData.designData
                        .combustorQuantity
                      : null,
                fuelNozzlesPerCombustor:
                    action.payload.designPerformanceData.designData
                      .fuelNozzlesPerCombustor ||
                    action.payload.designPerformanceData.designData
                      .fuelNozzlesPerCombustor === 0
                      ? action.payload.designPerformanceData.designData
                        .fuelNozzlesPerCombustor
                      : null,
                combustorConfiguration:
                    action.payload.designPerformanceData.designData
                      .combustorConfiguration ||
                    action.payload.designPerformanceData.designData
                      .combustorConfiguration === 0
                      ? action.payload.designPerformanceData.designData
                        .combustorConfiguration
                      : null,
                fuelType:
                    action.payload.designPerformanceData.designData.fuelType ||
                    action.payload.designPerformanceData.designData.fuelType ===
                      0
                      ? action.payload.designPerformanceData.designData.fuelType
                      : null,
                combustorMaxAllowableTemperatureVariation:
                    action.payload.designPerformanceData.designData
                      .combustorMaxAllowableTemperatureVariation ||
                    action.payload.designPerformanceData.designData
                      .combustorMaxAllowableTemperatureVariation === 0
                      ? action.payload.designPerformanceData.designData
                        .combustorMaxAllowableTemperatureVariation
                      : null,
                ratedFuelMoleWeight:
                    action.payload.designPerformanceData.designData
                      .ratedFuelMoleWeight ||
                    action.payload.designPerformanceData.designData
                      .ratedFuelMoleWeight === 0
                      ? action.payload.designPerformanceData.designData
                        .ratedFuelMoleWeight
                      : null,
                ratedFuelLHV:
                    action.payload.designPerformanceData.designData
                      .ratedFuelLHV ||
                    action.payload.designPerformanceData.designData
                      .ratedFuelLHV === 0
                      ? action.payload.designPerformanceData.designData
                        .ratedFuelLHV
                      : null,
                fuelPressure:
                    action.payload.designPerformanceData.designData
                      .fuelPressure ||
                    action.payload.designPerformanceData.designData
                      .fuelPressure === 0
                      ? action.payload.designPerformanceData.designData
                        .fuelPressure
                      : null,
                fuelTemperature:
                    action.payload.designPerformanceData.designData
                      .fuelTemperature ||
                    action.payload.designPerformanceData.designData
                      .fuelTemperature === 0
                      ? action.payload.designPerformanceData.designData
                        .fuelTemperature
                      : null,
                mineralLubeOil:
                    action.payload.designPerformanceData.designData
                      .mineralLubeOil ||
                    action.payload.designPerformanceData.designData
                      .mineralLubeOil === 0
                      ? action.payload.designPerformanceData.designData
                        .mineralLubeOil
                      : null,
                syntheticLubeOil:
                    action.payload.designPerformanceData.designData
                      .syntheticLubeOil ||
                    action.payload.designPerformanceData.designData
                      .syntheticLubeOil === 0
                      ? action.payload.designPerformanceData.designData
                        .syntheticLubeOil
                      : null,
              }
              : {
                ...INITIAL_STATE.gasTurbineConfigData.designPerformanceData
                  .designData,
              },
            performanceData: action.payload.designPerformanceData
              .performanceData
              ? {
                inletLoss:
                    action.payload.designPerformanceData.performanceData
                      .inletLoss ||
                    action.payload.designPerformanceData.performanceData
                      .inletLoss === 0
                      ? action.payload.designPerformanceData.performanceData
                        .inletLoss
                      : null,
                exhaustLoss:
                    action.payload.designPerformanceData.performanceData
                      .exhaustLoss ||
                    action.payload.designPerformanceData.performanceData
                      .exhaustLoss === 0
                      ? action.payload.designPerformanceData.performanceData
                        .exhaustLoss
                      : null,
                dryBulbTemp:
                    action.payload.designPerformanceData.performanceData
                      .dryBulbTemp ||
                    action.payload.designPerformanceData.performanceData
                      .dryBulbTemp === 0
                      ? action.payload.designPerformanceData.performanceData
                        .dryBulbTemp
                      : null,
                turbineInletAirChilled:
                    action.payload.designPerformanceData.performanceData
                      .turbineInletAirChilled ||
                    action.payload.designPerformanceData.performanceData
                      .turbineInletAirChilled === 0
                      ? action.payload.designPerformanceData.performanceData
                        .turbineInletAirChilled
                      : null,
                turbineInletTemp:
                    action.payload.designPerformanceData.performanceData
                      .turbineInletTemp ||
                    action.payload.designPerformanceData.performanceData
                      .turbineInletTemp === 0
                      ? action.payload.designPerformanceData.performanceData
                        .turbineInletTemp
                      : null,
                relativeHumidity:
                    action.payload.designPerformanceData.performanceData
                      .relativeHumidity ||
                    action.payload.designPerformanceData.performanceData
                      .relativeHumidity === 0
                      ? action.payload.designPerformanceData.performanceData
                        .relativeHumidity
                      : null,
                barometricPressure:
                    action.payload.designPerformanceData.performanceData
                      .barometricPressure ||
                    action.payload.designPerformanceData.performanceData
                      .barometricPressure === 0
                      ? action.payload.designPerformanceData.performanceData
                        .barometricPressure
                      : null,
                gtOutputShaftPower:
                    action.payload.designPerformanceData.performanceData
                      .gtOutputShaftPower ||
                    action.payload.designPerformanceData.performanceData
                      .gtOutputShaftPower === 0
                      ? action.payload.designPerformanceData.performanceData
                        .gtOutputShaftPower
                      : null,
                gasGeneratorOutputShaftSpeed:
                    action.payload.designPerformanceData.performanceData
                      .gasGeneratorOutputShaftSpeed ||
                    action.payload.designPerformanceData.performanceData
                      .gasGeneratorOutputShaftSpeed === 0
                      ? action.payload.designPerformanceData.performanceData
                        .gasGeneratorOutputShaftSpeed
                      : null,
                powerTurbineOutputShaftSpeed:
                    action.payload.designPerformanceData.performanceData
                      .powerTurbineOutputShaftSpeed ||
                    action.payload.designPerformanceData.performanceData
                      .powerTurbineOutputShaftSpeed === 0
                      ? action.payload.designPerformanceData.performanceData
                        .powerTurbineOutputShaftSpeed
                      : null,
                lhvHeatRate:
                    action.payload.designPerformanceData.performanceData
                      .lhvHeatRate ||
                    action.payload.designPerformanceData.performanceData
                      .lhvHeatRate === 0
                      ? action.payload.designPerformanceData.performanceData
                        .lhvHeatRate
                      : null,
                lhvEfficiency:
                    action.payload.designPerformanceData.performanceData
                      .lhvEfficiency ||
                    action.payload.designPerformanceData.performanceData
                      .lhvEfficiency === 0
                      ? action.payload.designPerformanceData.performanceData
                        .lhvEfficiency
                      : null,
                firingTemp:
                    action.payload.designPerformanceData.performanceData
                      .firingTemp ||
                    action.payload.designPerformanceData.performanceData
                      .firingTemp === 0
                      ? action.payload.designPerformanceData.performanceData
                        .firingTemp
                      : null,
                airFlow:
                    action.payload.designPerformanceData.performanceData
                      .airFlow ||
                    action.payload.designPerformanceData.performanceData
                      .airFlow === 0
                      ? action.payload.designPerformanceData.performanceData
                        .airFlow
                      : null,
                ggExhaustTemp:
                    action.payload.designPerformanceData.performanceData
                      .ggExhaustTemp ||
                    action.payload.designPerformanceData.performanceData
                      .ggExhaustTemp === 0
                      ? action.payload.designPerformanceData.performanceData
                        .ggExhaustTemp
                      : null,
                ptExhaustFlow:
                    action.payload.designPerformanceData.performanceData
                      .ptExhaustFlow ||
                    action.payload.designPerformanceData.performanceData
                      .ptExhaustFlow === 0
                      ? action.payload.designPerformanceData.performanceData
                        .ptExhaustFlow
                      : null,
                ptExhaustTemp:
                    action.payload.designPerformanceData.performanceData
                      .ptExhaustTemp ||
                    action.payload.designPerformanceData.performanceData
                      .ptExhaustTemp === 0
                      ? action.payload.designPerformanceData.performanceData
                        .ptExhaustTemp
                      : null,
                fuelFlowrate:
                    action.payload.designPerformanceData.performanceData
                      .fuelFlowrate ||
                    action.payload.designPerformanceData.performanceData
                      .fuelFlowrate === 0
                      ? action.payload.designPerformanceData.performanceData
                        .fuelFlowrate
                      : null,
                certifiedPoint:
                    action.payload.designPerformanceData.performanceData
                      .certifiedPoint ||
                    action.payload.designPerformanceData.performanceData
                      .certifiedPoint === 0
                      ? action.payload.designPerformanceData.performanceData
                        .certifiedPoint
                      : null,
                steamFlow:
                    action.payload.designPerformanceData.performanceData
                      .steamFlow ||
                    action.payload.designPerformanceData.performanceData
                      .steamFlow === 0
                      ? action.payload.designPerformanceData.performanceData
                        .steamFlow
                      : null,
                waterFlow:
                    action.payload.designPerformanceData.performanceData
                      .waterFlow ||
                    action.payload.designPerformanceData.performanceData
                      .waterFlow === 0
                      ? action.payload.designPerformanceData.performanceData
                        .waterFlow
                      : null,
              }
              : {
                ...INITIAL_STATE.gasTurbineConfigData.designPerformanceData
                  .performanceData,
              },
          },
          fluidComposition: action.payload.fluidComposition
            ? {
              air: action.payload.fluidComposition.air
                ? action.payload.fluidComposition.air
                : null,
              fg: action.payload.fluidComposition.fg
                ? action.payload.fluidComposition.fg
                : null,
            }
            : {
              ...INITIAL_STATE.gasTurbineConfigData.fluidComposition,
            },
        },
      };
    case Type.GET_GT_CONFIG_DATA_FAILURE:
      return {
        ...state,
        gasTurbineConfigData: {
          ...state.gasTurbineConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_GT_CONFIG_DATA_RESET:
      return {
        ...state,
        gasTurbineConfigData: {
          ...state.gasTurbineConfigData,
          isLoading: false,
          isError: false,
          errorCode: "",
          apiMsg: "",
          isSuccess: false,
          componentId: "",
        },
      };

    /** Add Gas Turbine design data */
    case Type.ADD_GT_DESIGN_PERF_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.ADD_GT_DESIGN_PERF_DATA_SUCCESS:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_GT_DESIGN_PERF_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_GT_DESIGN_PERF_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    /** Add Gas Turbine performance data */
    case Type.UPDATE_GT_PERF_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
          isLoading: true,
        },
      };
    case Type.UPDATE_GT_PERF_DATA_FAILURE:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_GT_PERF_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_GT_PERF_DATA_RESET:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
        },
      };

    /** Add Fluid Composition Data */
    case Type.UPDATE_GT_FLUID_COMP_DATA_PROGRESS:
      return {
        ...state,
        addFluidCompositionData: {
          ...INITIAL_STATE.addFluidCompositionData,
          isLoading: true,
        },
      };
    case Type.UPDATE_GT_FLUID_COMP_DATA_SUCCESS:
      return {
        ...state,
        addFluidCompositionData: {
          ...state.addFluidCompositionData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_GT_FLUID_COMP_DATA_FAILURE:
      return {
        ...state,
        addFluidCompositionData: {
          ...state.addFluidCompositionData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_GT_FLUID_COMP_DATA_RESET:
      return {
        ...state,
        addFluidCompositionData: {
          ...INITIAL_STATE.addFluidCompositionData,
        },
      };

    /** Add Inlet Temp Curve */
    case Type.UPDATE_GT_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addInletTempCurve: {
          ...INITIAL_STATE.addInletTempCurve,
          isLoading: true,
        },
        addInletLossesCurve: {
          ...INITIAL_STATE.addInletLossesCurve,
          isLoading: true,
        },
        addExhaustLossesCurve: {
          ...INITIAL_STATE.addExhaustLossesCurve,
          isLoading: true,
        },
        addRelHumidityCurve: {
          ...INITIAL_STATE.addRelHumidityCurve,
          isLoading: true,
        },
        addPercDegradationCurve: {
          ...INITIAL_STATE.addPercDegradationCurve,
          isLoading: true,
        },
      };
    case Type.UPDATE_GT_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addInletTempCurve: {
          ...state.addInletTempCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        addInletLossesCurve: {
          ...state.addInletLossesCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        addExhaustLossesCurve: {
          ...state.addExhaustLossesCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        addRelHumidityCurve: {
          ...state.addRelHumidityCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
        addPercDegradationCurve: {
          ...state.addPercDegradationCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_GT_CURVES_DATA_FAILURE:
      return {
        ...state,
        addInletTempCurve: {
          ...state.addInletTempCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
        addInletLossesCurve: {
          ...state.addInletLossesCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
        addExhaustLossesCurve: {
          ...state.addExhaustLossesCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
        addRelHumidityCurve: {
          ...state.addRelHumidityCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
        addPercDegradationCurve: {
          ...state.addPercDegradationCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_GT_CURVES_DATA_RESET:
      return {
        ...state,
        addInletTempCurve: {
          ...INITIAL_STATE.addInletTempCurve,
        },
        addInletLossesCurve: {
          ...INITIAL_STATE.addInletLossesCurve,
        },
        addExhaustLossesCurve: {
          ...INITIAL_STATE.addExhaustLossesCurve,
        },
        addRelHumidityCurve: {
          ...INITIAL_STATE.addRelHumidityCurve,
        },
        addPercDegradationCurve: {
          ...INITIAL_STATE.addPercDegradationCurve,
        },
      };

    /** Reset gas turbine reducer */
    case Type.RESET_GT_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
