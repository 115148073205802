import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

export const CollapseRow = (props) => (
  <tr>
    <td colSpan="3">
      <Collapse pagination accordion>
        <Panel header={props.data._id} key={props.data._id}>
          <div className="associations-column">
            <ul className="col-1">
              {props.data.units.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
            <ul className="col-2">
              {props.data.dataVariables.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </Panel>
      </Collapse>
    </td>
  </tr>
);
