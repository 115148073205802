import { GETRequest, POSTRequest } from "../../api/request";
import { dbDeleteHandling } from "../../libs/token";
import {
  addDataIDB,
  destructurefn,
  getDataIDB,
  updateDataIDB,
} from "../../services/indexedDB";
import {
  ADD_NEW_TREND_CARD_ERROR,
  ADD_NEW_TREND_CARD_PROGRESS,
  ADD_NEW_TREND_CARD_SUCCESS,
  TRENDS_BY_LEVEL_FETCH_ERROR,
  TRENDS_BY_LEVEL_FETCH_PROGRESS,
  TRENDS_BY_LEVEL_FETCH_SUCCESS,
  TRENDS_FETCH_ERROR,
  TRENDS_FETCH_PROGRESS,
  TRENDS_FETCH_SUCCESS,
  TREND_PARTICULAR_FETCH_SUCCESS,
  INSPECTION_MODE_ON,
  INSPECTION_MODE_OFF,
  TRENDS_BY_ID_FETCH_ERROR,
  TRENDS_BY_ID_FETCH_PROGRESS,
  TRENDS_BY_ID_FETCH_SUCCESS,
  TRENDS_CLEAR_STATE,
  SEARCH_KPI_PROGRESS,
  SEARCH_KPI_SUCCESS,
  SEARCH_KPI_ERROR,
} from '../Types/trends.types';

export const setInspectionModeOn = () => (dispatch) => {
  dispatch({ type: INSPECTION_MODE_ON });
};

export const setInspectionModeOff = () => (dispatch) => {
  dispatch({ type: INSPECTION_MODE_OFF });
};

export const addNewTrendCard = (levelName, levelId, payload) => (dispatch) => {
  dispatch({ type: ADD_NEW_TREND_CARD_PROGRESS });
  POSTRequest(`kpis/${levelName}/${levelId}?type=trends`, payload)
    .then((res) => {
      /** append ids into IDB */
      addDataIDB([res.body]);
      return dispatch({ type: ADD_NEW_TREND_CARD_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      if (
        err.message ===
        "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
      ) {
        dbDeleteHandling();
      } else {
        return dispatch({ type: ADD_NEW_TREND_CARD_ERROR, payload: err });
      }
    });
};
// export const clearAddNewCardState = () => dispatch => dispatch({ type: addNewCardTypes.ADD_ADDNEWCARD_CLEAR_STATE })

export function getTrendsByLevelId(levelName, levelId) {
  return function (dispatch) {
    dispatch({ type: TRENDS_BY_LEVEL_FETCH_PROGRESS });
    GETRequest(`kpis/${levelName}/${levelId}?type=trends`)
      .then((res) => {
        /** append ids into IDB, also check weather id exists in IDB */
        addDataIDB(res.body.data);
        return dispatch({
          type: TRENDS_BY_LEVEL_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        if (
          err.message ===
          "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
        ) {
          dbDeleteHandling();
        } else {
          return dispatch({ type: TRENDS_BY_LEVEL_FETCH_ERROR, payload: err });
        }
      });
  };
}

export function getTrendByKPIId(kpiId, viewDateFrom, viewDateTill) {
  const dateFrom = viewDateFrom;
  const dateTill = viewDateTill;

  return async function (dispatch) {
    dispatch({ type: TRENDS_FETCH_PROGRESS, payload: kpiId });
    /** check in db for data with st,endt */
    const idbData = await getDataIDB(kpiId);
    /** if idbData data present */
    if (
      idbData &&
      idbData.response &&
      idbData.response.series &&
      idbData.response.startTimestamp &&
      false
    ) {
    } else {
      /** if idbData data not present */
      GETRequest(`plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}`)
        .then((res) => {
          const data = idbData
            ? destructurefn(
              JSON.parse(res.text),
              idbData,
              15000,
              false,
              false,
              true,
              dateFrom,
              dateTill
            )
            : res.text;
          idbData &&
            updateDataIDB({
              _id: kpiId,
              piTags: idbData.piTags,
              response: data,
              trends: true,
            });
          dispatch({
            type: TRENDS_FETCH_SUCCESS,
            payload: {
              kpiId,
              response: data,
              viewDateFrom,
              viewDateTill,
            },
          });
        })
        .catch((err) => {
          if (
            err.message ===
            "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
          ) {
            dbDeleteHandling();
          } else {
            return dispatch({
              type: TRENDS_FETCH_ERROR,
              payload: { kpiId, err },
            });
          }
        });
    }
  };
}

export function getParticularTrendDataByKPIId({
  id: kpiId,
  _cardType,
  dateFrom,
  dateTill,
}) {
  let idbData = {};
  const viewDateFrom = dateFrom;
  const viewDateTill = dateTill;
  return async function (dispatch) {
    dispatch({ type: TRENDS_FETCH_PROGRESS, payload: kpiId });

    try {
      idbData = await getDataIDB(kpiId);
    } catch (e) {}
    /** if idbData data present */
    if (
      idbData &&
      idbData.response &&
      idbData.response.series &&
      idbData.response.startTimestamp &&
      false
    ) {
      // todo compare with all base interval time
    } else {
      // IF DATA NOT PRESENT

      GETRequest(`plots/${kpiId}?dateFrom=${dateFrom}&dateTill=${dateTill}`)
        .then((res) => {
          const data = idbData
            ? destructurefn(
              JSON.parse(res.text),
              idbData,
              15000,
              false,
              false,
              true,
              dateFrom,
              dateTill
            )
            : res.text;
          idbData &&
            updateDataIDB({
              _id: kpiId,
              piTags: idbData.piTags,
              response: data,
              trends: true,
            });
          dispatch({
            type: TREND_PARTICULAR_FETCH_SUCCESS,
            payload: {
              kpiId,
              response: data,
              isTimeSeries: true,
              viewDateFrom,
              viewDateTill,
            },
          });
        })
        .catch((err) => {
          if (
            err.message ===
            "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
          ) {
            dbDeleteHandling();
          } else {
            dispatch({ type: TRENDS_FETCH_ERROR, payload: { kpiId, err } });
          }
        });
    }
  };
}

export function getTrendsById(id) {
  return function (dispatch) {
    dispatch({ type: TRENDS_BY_ID_FETCH_PROGRESS });
    GETRequest(`kpisdetails/${id}`)
      .then((res) => {
        /** append ids into IDB, also check weather id exists in IDB */
        addDataIDB([res.body]);
        return dispatch({
          type: TRENDS_BY_ID_FETCH_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        if (
          err.message ===
          "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
        ) {
          dbDeleteHandling();
        } else {
          return dispatch({ type: TRENDS_BY_ID_FETCH_ERROR, payload: err });
        }
      });
  };
}

export function searchKPI(levelname, levelid,payload) {
  return function (dispatch) {
    dispatch({ type: SEARCH_KPI_PROGRESS });
    GETRequest(`search/kpis/${levelname}/${levelid}`, payload)
      .then((res) => {
        /** append ids into IDB, also check weather id exists in IDB */
        addDataIDB([res.body]);
        return dispatch({
          type: SEARCH_KPI_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        if (
          err.message ===
          "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
        ) {
          dbDeleteHandling();
        } else {
          return dispatch({ type: SEARCH_KPI_ERROR, payload: err });
        }
      });
  };
}

export const clearTrendsState = () => (dispatch) =>
  dispatch({ type: TRENDS_CLEAR_STATE });
