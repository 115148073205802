import React from "react";
import { Icon, Input } from "antd";
import { newValidateNumber } from "../../../../../../../libs/validator";

export const DataUnit = ["Relative Humidity (Ambient)"];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const dataUnitsVariables = {
  humidity: "Percentage",
};
export const variablesMapper = {
  humidity: "humidity",
  powerCorr: "powerCorr",
  heatRateCorr: "heatRateCorr",
};
export const curvesColumnsTitle = {
  humidity: "Relative Humidity",
  powerCorr: "Power Correction Factor",
  heatRateCorr: "Heat Rate Correction Factor",
};
export const curvesColumnsName = {
  humidity: "humidity",
  powerCorr: "power_corr",
  heatRateCorr: "hr_corr",
};
export const graphDetails = {
  title: "Curve",
  xAxis: "Relative Humidity",
  power_corr: "Relative Humidity Power correction factor",
  hr_corr: "Relative Humidity Heatrate correction factor",
  requiredMessage: "Mininum three data is required",
  relHumidityCurve: "Relative Humidity Curve",
};

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.humidity,
    dataIndex: curvesColumnsName.humidity,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.humidity}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
export const RelHumidityCurveFormField = (units, config) => [
  {
    label: "Relative Humidity",
    key: "humidity",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Relative Humidity (Ambient)")[0],
    data: getUnit(units, "Relative Humidity (Ambient)"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesLabel = {
  relHumidityCurve: "Relative Humidity Curve",
};
export const createCurvesPayload = (formfieldValue, relHumidityCurves = []) => {
  const { ...dataUnit } = formfieldValue;

  const dataRelHumidityCurves = relHumidityCurves.map((item) => {
    const { humidity, power_corr, hr_corr } = item;
    return {
      humidity: parseFloat(humidity),
      power_corr: parseFloat(power_corr),
      hr_corr: parseFloat(hr_corr),
    };
  });
  const payload = {
    humidityCurve: {
      dataUnit: { humidity: dataUnit.humidity },
      data: dataRelHumidityCurves,
    },
  };
  return payload;
};

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.humidity,
    dataIndex: curvesColumnsName.humidity,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.humidity}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.humidity] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.powerCorr] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.heatRateCorr]
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const curveVariables = {
  "Power Correction": "power_corr",
  "Heat Rate Correction": "hr_corr",
};

export const curveInput = (data, yAxisValue, config) => {
  return [
    {
      label: "Y Axis",
      key: "yAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: yAxisValue,
      style: { width: "200px", padding: "10px" },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};
