import {
  STATE_CLEAR_STATE,
  STATE_FETCH_ERROR,
  STATE_FETCH_SUCCESS,
  STATE_FETCH_PROGRESS,
} from "../Types/State.types";

const INITIAL_API_STATE = {
  stateData: null,
  isErrorStateFetch: false,
  isSuccessStateFetch: false,
  errorCode: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function StateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STATE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          stateData: action.payload,
          isSuccessStateFetch: true,
        },
      };
    case STATE_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case STATE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          isErrorStateFetch: true,
        },
      };
    case STATE_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          stateData: state.apiState.stateData,
        },
      };
    default:
      return state;
  }
}
