import {
  GET_CALCULATED_DATA_ERROR,
  GET_CALCULATED_DATA_PROGRESS,
  GET_CALCULATED_DATA_SUCCESS,
  GET_VARIABLE_NAME_DATA_ERROR,
  GET_VARIABLE_NAME_DATA_PROGRESS,
  GET_VARIABLE_NAME_DATA_SUCCESS,
  ADD_CALCULATED_DATA_ERROR,
  ADD_CALCULATED_DATA_PROGRESS,
  ADD_CALCULATED_DATA_SUCCESS,
  CLEAR_STATE,
} from "../Types/calculatedData.types";

const INITIAL_API_STATE = {
  isVariableData: false,
  isCalculatedDataAdded: false,
  getCalculatedData: false,
  variableData: null,
  calculatedDataAdded: null,
  calculatedDataRecieved: null,
  isVariableGetError: false,
  isCalculatedDataAddError: false,
  isCalculatedDataRecievedError: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  loading: false,
  apiState: {
    ...INITIAL_API_STATE,
  },
};

export default function calculatedDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_VARIABLE_NAME_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case GET_VARIABLE_NAME_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isVariableData: true,
          variableData: action.payload,
        },
      };

    case GET_VARIABLE_NAME_DATA_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isVariableGetError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_CALCULATED_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case ADD_CALCULATED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isCalculatedDataAdded: true,
          calculatedDataAdded: action.payload,
        },
      };

    case ADD_CALCULATED_DATA_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isCalculatedDataAddError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_CALCULATED_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case GET_CALCULATED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          getCalculatedData: true,
          calculatedDataRecieved: action.payload,
        },
      };

    case GET_CALCULATED_DATA_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isCalculatedDataRecievedError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
}
