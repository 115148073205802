import { Breadcrumb } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader/Loader';
import {
  validateNegativeNumber,
  validatePositiveIntegers,
  validatePositiveFloat,
  handleSort,
} from '../../../common/methods';
import UploadModal from '../../../common/Modal/UploadModal';
import Notification, { Types } from '../../../common/Notification/Notification';
import { MANAGE_TAGS_MESSAGE, NO_INTERNET } from '../../../constants/messages';
import { MaskStyle } from '../../../constants/react-style';
import {
  ButtonVariables,
  MANAGE_PI_TAGS_CONSTANTS,
  SideMenuVariables,
} from '../../../constants/variables';
import { userRole } from '../../../redux/actions/login.action';
import {
  clearState,
  getVariableProperty,
} from '../../../redux/actions/outputData.actions';
import {
  piTagClearReducer,
  piTagDelete,
  piTagDeleteClear,
  piTagListClear,
  piTagListFetch,
  piTagSave,
  piTagSaveClear,
  piTagUpload,
  piTagUploadClear,
} from '../../../redux/actions/piTagUpload.action';
import * as Routes from './../../../constants/routes';
import './ManagePiTags.scss';
import { validateIQRParameter } from './ManageTags.constants';
import { elementsGaurd } from '../../../gaurds';
import { ElementPermissions } from '../../../permissions';
import Validator from './piTagValidator/uploadPiTags';
import { associations } from '../../../redux/actions/unitsProperties.action';
import ManageTag from './ManageTag';

const PAGINATED_VALUE = 30;
const INITIAL_SKIP = 0;
const ERROR_TYPE = {
  ERROR: 'error',
  BOUND_ERROR: 'bound_error',
};
const DELETETYPE = ['SINGLETAG', 'MULTIPLETAGS'];
class ManageTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpload: false,
      defaultAdder: false,
      facilityData: this.props.location.state.facilityData,
      limit: PAGINATED_VALUE,
      keyword: '',
      skip: INITIAL_SKIP,
      list: [],
      baseIntervalTime: null,
      errorBaseLine: false,
      isEdit: false,
      variableProperty: [],
      searchedProperty: [],
      selectedProperty: '',
      units: [],
      searchedUnits: [],
      editTableRows: [],
      isCancelModal: false,
      isCancel: false,
      deleteIds: [],
      isDeleteModal: false,
      isErrorOnSave: false,
      demandPage: window.location.pathname,
      demandPageData: {},
      deleteId: [],
      equipmentsList: [],
      deleteType: '',
      searchValue: '',
      dataUnits: [],
      associationLimit: 200,
    };
  }
  componentDidMount() {
    if (this.handleNetwork()) {
      this.props.getVariableProperty();
      this.props.associations({ limit: this.state.associationLimit });
    }
    this.fetchList();
    window.addEventListener('form-edit', () => {
      this.setState({
        isEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        isEdit: false,
      });
    });

    const equipmentsList = this.props.clientState.apiState.clientInfoData
      .equipments;
    equipmentsList &&
      equipmentsList.length &&
      this.setState({
        equipmentsList,
      });
  }

  disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  fetchList = () => {
    this.setState({
      limit: PAGINATED_VALUE,
      skip: INITIAL_SKIP,
      isEdit: false,
      editTableRows: [],
    });
    //const payload = { limit: PAGINATED_VALUE, skip: INITIAL_SKIP };
    if (this.handleNetwork()) {
      //this.props.piTagListFetch(this.state.facilityData._id, payload);
    }
  };
  componentWillUnmount() {
    this.props.piTagClearReducer();
    this.props.clearState();
  }

  componentDidUpdate(_prevProps, _prevState) {
    const {
      piTagList,
      variableProperty,
      piTagUploadState,
      piTagDeleteState,
      piTagSaveState,
      clientState,
      associationsData,
    } = this.props;
    const { editTableRows } = this.state;

    if (
      clientState.apiState.clientInfoData.equipments &&
      clientState !== _prevProps.clientState
    ) {
      this.setState({
        equipmentsList: clientState.apiState.clientInfoData.equipments
          ? clientState.apiState.clientInfoData.equipments
          : [],
        defaultAdder: false,
      });
    }
    if (
      piTagList.isSuccess &&
      piTagList.isSuccess !== _prevProps.piTagList.isSuccess
    ) {
      this.setState({
        list: _.cloneDeep(piTagList.data),
        defaultAdder: false,
      });
    } else if (
      piTagList.isError &&
      piTagList.isError !== _prevProps.piTagList.isError
    ) {
      this.setState({
        defaultAdder: false,
      });
      Notification.show(Types.Error, piTagList.apiMessage);
    }

    /** append data in state */
    if (
      piTagList.data !== this.state.list &&
      _prevProps.piTagList.data !== piTagList.data
    ) {
      this.setState({
        list: _.cloneDeep(piTagList.data),
        baseIntervalTime:
          piTagList.baseIntervalTime && piTagList.baseIntervalTime.toString(),
        defaultAdder: false,
      });
    }
    if (
      piTagUploadState.isSuccess &&
      piTagUploadState.isSuccess !== _prevProps.piTagUploadState.isSuccess
    ) {
      this.setState({
        isUpload: false,
        defaultAdder: true,
      });
      Notification.show(Types.Success, MANAGE_TAGS_MESSAGE.CSV_SUCCESS_UPLOAD);
      this.fetchList();
      this.setState({
        list: _.cloneDeep(piTagList.data),
      });
    } else if (
      piTagUploadState.isError &&
      piTagUploadState.isError !== _prevProps.piTagUploadState.isError
    ) {
      this.setState({
        defaultAdder: false,
      });
      Notification.show(Types.Error, piTagUploadState.apiMessage);
    }
    if (
      variableProperty.apiState.isVariableGetSuccess &&
      variableProperty.apiState.isVariableGetSuccess !==
        _prevProps.variableProperty.apiState.isVariableGetSuccess
    ) {
      const variableProps = variableProperty.apiState.variableData.items;
      const updatedVariableNames = variableProps.map((item) => {
        let object = {};
        object[item.property] = item.unit;
        return object;
      });
      this.setState({
        variableProperty: updatedVariableNames,
        searchedProperty: updatedVariableNames,
        defaultAdder: false,
      });
    }

    /** Error and success handling on delete */
    if (
      piTagDeleteState.isSuccess &&
      piTagDeleteState.isSuccess !== _prevProps.piTagDeleteState.isSuccess
    ) {
      if (piTagDeleteState.isSuccessApi && piTagDeleteState.isErrorApi) {
        this.state.deleteIds.length > 0 &&
          this.setState({
            deleteIds: piTagDeleteState.errorIds,
            defaultAdder: false,
          });
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.DELETE_PARTIALLY);
      } else if (
        piTagDeleteState.isSuccessApi &&
        !piTagDeleteState.isErrorApi
      ) {
        this.state.deleteIds.length > 0 &&
          this.setState({
            deleteIds: [],
            defaultAdder: false,
          });
        Notification.show(
          Types.Success,
          MANAGE_TAGS_MESSAGE.PI_TAG_DELETE_SUCCESS
        );
      } else if (
        !piTagDeleteState.isSuccessApi &&
        piTagDeleteState.isErrorApi
      ) {
        this.state.deleteIds.length > 0 &&
          this.setState({
            deleteIds: piTagDeleteState.errorIds,
            defaultAdder: false,
          });
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.DELETE_ERROR);
      } else if (
        !piTagDeleteState.isSuccessApi &&
        !piTagDeleteState.isErrorApi
      ) {
        this.state.deleteIds.length > 0 &&
          this.setState({
            deleteIds: piTagDeleteState.errorIds,
            defaultAdder: false,
          });
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.DELETE_ERROR);
      }
      this.fetchList();
    } else if (
      piTagDeleteState.isError &&
      piTagDeleteState.isError !== _prevProps.piTagDeleteState.isError
    ) {
      if (piTagDeleteState.errorCode === 'NOT_ALLOWED') {
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.NOT_ALLOWED);
      } else {
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.PI_TAG_DELETE_ERROR);
      }
      this.setState({
        defaultAdder: false,
      });
    }

    /** Load more data */
    if (this.state.skip !== _prevState.skip) {
      let payload;
      if (this.state.searchValue) {
        payload = {
          keyword: this.state.searchValue,
          limit: this.state.limit,
          skip: this.state.skip,
        };
      } else {
        payload = { limit: this.state.limit, skip: this.state.skip };
      }
      if (this.handleNetwork()) {
        this.props.piTagListFetch(this.state.facilityData._id, payload);
      }
      this.setState({
        defaultAdder: false,
      });
    }

    /** Success and error in case of save */
    if (
      piTagSaveState.isSuccess &&
      piTagSaveState.isSuccess !== _prevProps.piTagSaveState.isSuccess
    ) {
      Notification.show(Types.Success, MANAGE_TAGS_MESSAGE.PI_TAG_SAVED);
      this.fetchList();
      this.setState({
        isEdit: false,
        isErrorOnSave: false,
        defaultAdder: false,
      });
    } else if (
      piTagSaveState.isError &&
      piTagSaveState.isError !== _prevProps.piTagSaveState.isError
    ) {
      this.setState({
        defaultAdder: false,
      });
      Notification.show(Types.Error, piTagSaveState.apiMessage);
    }

    if (!editTableRows.length && editTableRows !== _prevState.editTableRows) {
      this.setState({
        isEdit: false,
        isErrorOnSave: false,
        defaultAdder: false,
      });
    }

    if (this.state.isEdit && this.state.isEdit !== _prevState.isEdit) {
      this.setState({
        defaultAdder: false,
      });
      // this.enableEditForm();
    } else if (!this.state.isEdit && this.state.isEdit !== _prevState.isEdit) {
      this.setState({
        defaultAdder: false,
      });
      this.disableEditForm();
    }
    if (
      associationsData &&
      associationsData.items &&
      this.state.dataUnits.length !== associationsData.items.length
    ) {
      this.setState({ dataUnits: associationsData.items });
    }
  }

  onSearch(value) {
    const payload = { keyword: value, limit: this.state.limit, skip: 0 };
    this.setState(
      {
        searchValue: value.trim(),
      },
      () => {
        if (this.handleNetwork()) {
          this.props.piTagListFetch(this.state.facilityData._id, payload);
        }
      }
    );
  }
  onSearchChange(value) {
    if (!value) {
      this.setState({
        limit: PAGINATED_VALUE,
        skip: INITIAL_SKIP,
      });

      const payload = { limit: PAGINATED_VALUE, skip: INITIAL_SKIP };
      if (this.handleNetwork()) {
        this.props.piTagListFetch(this.state.facilityData._id, payload);
      }
    }
  }

  uploadCsv = (data, id) => {
    if (data) {
      if (this.handleNetwork()) {
        this.props.piTagUploadClear();
        this.props.piTagUpload(data, id);
      }
    } else {
      Notification.show(
        Types.Error,
        MANAGE_TAGS_MESSAGE.EMPTY_UPLOAD_ERROR_MSG
      );
    }
  };

  handleToggle = () => {
    this.setState({ isUpload: !this.state.isUpload });
  };

  handleChange = (e, id, name, row) => {
    const { list, isEdit, searchedProperty } = this.state;
    const { data } = this.props.piTagList;
    if (name === 'variableProperty') {
      let unitList = [];
      searchedProperty.forEach((unit) => {
        if (Object.keys(unit)[0] === e) {
          unitList = unit[e];
        }
      });
      this.setState({
        units: unitList,
        searchedUnits: unitList,
        selectedProperty: e,
      });
    }
    if (id) {
      const updatedList = list.map((item) => {
        if (item._id === id) {
          if (row && row._id && row._id === item._id) {
            if (
              e &&
              (e.target.name === 'adder' || e.target.name === 'multiplier')
            ) {
              const prevIQRVal = data.filter(
                (dataItem) => dataItem._id === id
              )[0]['isIQR'];
              if (!validateIQRParameter(e.target.value, e.target.name)) {
                item['isIQR'] = false;
              } else if (e.target.name === 'adder') {
                const multiplierValue = row.multiplier
                  ? row.multiplier.toString()
                  : '';
                if (
                  validateIQRParameter(multiplierValue, 'multiplier') &&
                  prevIQRVal
                ) {
                  item['isIQR'] = true;
                }
              } else if (e.target.name === 'multiplier') {
                const adderValue = row.adder ? row.adder.toString() : '';
                if (validateIQRParameter(adderValue, 'adder') && prevIQRVal) {
                  item['isIQR'] = true;
                }
              }
            }
          }
          if (name) {
            if (name === MANAGE_PI_TAGS_CONSTANTS.EQUIPMENT) {
              const selectedEquipment = _.map(e, 'key');
              item[MANAGE_PI_TAGS_CONSTANTS.EQUIPMENTID] = selectedEquipment;
            }
            item[name] = e;
            if (name === MANAGE_PI_TAGS_CONSTANTS.PROPERTY_API_INDEX) {
              item[MANAGE_PI_TAGS_CONSTANTS.MECH_UNIT_API_INDEX] = null;
            } else if (
              name === MANAGE_PI_TAGS_CONSTANTS.DATA_TYPE_API_INDEX &&
              (e === 'String' || e === 'Boolean')
            ) {
              item[MANAGE_PI_TAGS_CONSTANTS.VAR_MAX_API_INDEX] = '';
              item[MANAGE_PI_TAGS_CONSTANTS.VAR_MIN_API_INDEX] = '';
              item[MANAGE_PI_TAGS_CONSTANTS.ADDER_API_INDEX] = null;
              item[MANAGE_PI_TAGS_CONSTANTS.IQR_API_INDEX] = null;
              item[MANAGE_PI_TAGS_CONSTANTS.IS_IQR_API_INDEX] = false;
              item[MANAGE_PI_TAGS_CONSTANTS.ENABLE_UAD] = false;
            } else if (name === MANAGE_PI_TAGS_CONSTANTS.IS_FROZEN_API_INDEX) {
              item[MANAGE_PI_TAGS_CONSTANTS.IS_FROZEN_API_INDEX] = e;
            }
          } else {
            item[e.target.name] = e.target.value;
          }
          return item;
        }
        return item;
      });
      this.setState({
        list: [...updatedList],
      });
    } else if (e && e.target && e.target.name === 'baseIntervalTime') {
      if (
        validatePositiveIntegers(e.target.value) &&
        parseFloat(e.target.value) >= 999999999999999
      ) {
        e.preventDefault();
      } else {
        this.setState({
          baseIntervalTime: e.target.value,
          errorBaseLine: false,
        });
      }
    }

    if (!isEdit) {
      this.setState({ isEdit: true });
    }
  };

  handleUndo = (_id, row) => {
    const { data } = this.props.piTagList;
    const { list, editTableRows } = this.state;
    if (editTableRows.includes(row)) {
      const filteredRows = editTableRows.filter((item) => item !== row);
      const undoList = _.cloneDeep(list);
      undoList[row] = _.cloneDeep(data[row]);
      this.setState({
        list: _.cloneDeep(undoList),
        editTableRows: filteredRows,
      });
    }
  };

  destructureData = (data) => {
    let error = '';
    let editedRowData = null;
    let payload = {
      _id: data._id,
      units: data.units,
      piVariableName: data.piVariableName,
      dataType: data.dataType,
      equipmentId: data.equipmentId,
      mechademyUnits: data.mechademyUnits,
      variableProperty: data.variableProperty,
      minThreshold: data.minThreshold,
      maxThreshold: data.maxThreshold,
      multiplier:
        data.dataType === 'String' || data.dataType === 'Boolean'
          ? null
          : data.multiplier,
      adder:
        data.dataType === 'String' || data.dataType === 'Boolean'
          ? null
          : data.adder,
      isIQR: data.isIQR ? data.isIQR : false,
      isFrozen: data.isFrozen ? data.isFrozen : false,
    };
    if (payload.dataType === 'String' || payload.dataType === 'Boolean') {
      editedRowData = Object.keys(payload);
      for (let i = 0; i < editedRowData.length; i++) {
        const item = editedRowData[i];
        if (item === 'equipmentId' && !payload[item].length) {
          error = ERROR_TYPE.ERROR;
        }
        if (
          !payload[item] &&
          item !== 'minThreshold' &&
          item !== 'maxThreshold' &&
          item !== 'multiplier' &&
          item !== 'adder' &&
          item !== 'isIQR' &&
          item !== 'isFrozen'
        ) {
          error = ERROR_TYPE.ERROR;
          break;
        }
      }
    } else {
      editedRowData = Object.keys(payload);
      for (let i = 0; i < editedRowData.length; i++) {
        const item = editedRowData[i];
        if (item === 'equipmentId' && !payload[item].length) {
          error = ERROR_TYPE.ERROR;
        }
        if (
          !payload[item] &&
          item !== 'isIQR' &&
          item !== 'multiplier' &&
          item !== 'adder' &&
          item !== 'isFrozen'
        ) {
          error = ERROR_TYPE.ERROR;
          break;
        }
        if (
          item === 'adder' &&
          payload[item] &&
          validateNegativeNumber(payload[item].toString())
        ) {
          error = MANAGE_TAGS_MESSAGE.FIELDS_DIRTY;
          break;
        } else if (item === 'multiplier' && payload[item]) {
          if (validateNegativeNumber(payload[item].toString())) {
            error = MANAGE_TAGS_MESSAGE.FIELDS_DIRTY;
            break;
          }
          if (!validatePositiveFloat(payload[item].toString())) {
            error = MANAGE_TAGS_MESSAGE.ZERO_VALIDATION;
            break;
          }
        } else if (item === 'minThreshold' || item === 'maxThreshold') {
          if (
            parseFloat(payload.maxThreshold) <= parseFloat(payload.minThreshold)
          ) {
            error = ERROR_TYPE.BOUND_ERROR;
            break;
          }
        }
      }
    }
    if (error) {
      return error;
    }
    return {
      _id: data._id,
      units: data.units,
      piVariableName: data.piVariableName,
      dataType: data.dataType,
      equipmentId: data.equipmentId,
      mechademyUnits: data.mechademyUnits,
      variableProperty: data.variableProperty,
      minThreshold: _.isNaN(parseFloat(data.minThreshold))
        ? null
        : '' + parseFloat(data.minThreshold),
      maxThreshold: _.isNaN(parseFloat(data.maxThreshold))
        ? null
        : '' + parseFloat(data.maxThreshold),
      multiplier: _.isNaN(parseFloat(data.multiplier))
        ? null
        : +data.multiplier,
      adder: _.isNaN(parseFloat(data.adder)) ? null : +data.adder,
      isIQR: data.isIQR ? data.isIQR : false,
      isFrozen: data.isFrozen ? data.isFrozen : false,
    };
  };

  handleSave = () => {
    const { facilityData, baseIntervalTime, editTableRows, list } = this.state;
    const editedPiTagData = [];
    let isSuccess = false;
    let payload = null;
    if (list.length === 0) {
      Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.NO_DATA);
      return true;
    }
    if (!baseIntervalTime) {
      this.setState({
        errorBaseLine: true,
        isErrorOnSave: true,
      });
      Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.BASE_TIME_INTERVAL);
      return false;
    } else if (!validatePositiveIntegers(baseIntervalTime)) {
      this.setState({
        errorBaseLine: true,
        isErrorOnSave: true,
      });
      Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.FIELDS_DIRTY);
      return false;
    } else if (editTableRows.length > 0) {
      for (let item of editTableRows) {
        payload = this.destructureData(this.state.list[item]);
        if (payload === MANAGE_TAGS_MESSAGE.MULTIPLIER_REQUIRED) {
          this.setState({ isErrorOnSave: true });
          Notification.show(
            Types.Error,
            MANAGE_TAGS_MESSAGE.MULTIPLIER_REQUIRED
          );
          break;
        } else if (payload === MANAGE_TAGS_MESSAGE.FIELDS_DIRTY) {
          this.setState({ isErrorOnSave: true });
          Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.FIELDS_DIRTY);
          break;
        } else if (payload === MANAGE_TAGS_MESSAGE.ZERO_VALIDATION) {
          this.setState({ isErrorOnSave: true });
          Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.ZERO_VALIDATION);
          break;
        } else if (payload === ERROR_TYPE.ERROR) {
          this.setState({ isErrorOnSave: true });
          Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.FILED_EMPTY);
          break;
        } else if (payload === ERROR_TYPE.BOUND_ERROR) {
          this.setState({ isErrorOnSave: true });
          Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.BOUND_ERROR_MSG);
          break;
        } else {
          this.setState({ isErrorOnSave: false });
          isSuccess = true;
          editedPiTagData.push(payload);
        }
      }
    } else {
      isSuccess = true;
    }

    if (isSuccess) {
      const payload = {
        baseIntervalTime: parseFloat(this.state.baseIntervalTime),
        config: editedPiTagData,
      };
      if (
        this.handleNetwork() &&
        payload.config.length === editTableRows.length
      ) {
        this.props.piTagSaveClear();
        this.props.piTagSave(payload, facilityData._id);
      }
    } else {
      !payload &&
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.FILED_EMPTY);
    }
  };

  handleEdit = (id, row) => {
    this.enableEditForm();
    const index = this.state.editTableRows.findIndex((item) => {
      return item === row;
    });
    if (index === -1) {
      this.setState({
        editTableRows: [...this.state.editTableRows, row],
      });
    }
  };

  handleDeleteArray = (ids) => {
    this.setState({
      deleteIds: ids,
      deleteType: DELETETYPE[1],
    });
  };

  handleDeletePiTag = (id) => {
    this.setState({
      deleteId: [id],
      deleteType: DELETETYPE[0],
    });
  };

  /** handle delete action */
  handleDelete = () => {
    this.props.piTagDeleteClear();
    this.props.piTagDelete({
      facilityId: this.state.facilityData._id,
      tags:
        this.state.deleteType === DELETETYPE[0]
          ? this.state.deleteId
          : this.state.deleteIds,
    });
    this.cancelDeleteModal();
  };

  goBack = () => {
    const { demandPageData, demandPage } = this.state;
    this.setState({ isCancelModal: false, isCancel: false });
    !_.isEmpty(demandPageData)
      ? this.props.history.push(demandPage, demandPageData)
      : demandPage === '/app/clients'
      ? this.props.history.push(demandPage, demandPageData)
      : this.props.history.goBack();
  };

  loadMore = (page) => {
    this.setState({
      skip: PAGINATED_VALUE * (page - 1),
    });
  };

  /** handle network function */
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ isCancelModal: true, isCancel: true });
    }
  };

  onCancelModal = () => {
    this.setState({ isCancelModal: false, isCancel: false });
  };

  handleOk = () => {
    this.setState({
      isEdit: false,
    });
    this.disableEditForm();
  };

  filterData = (key) => {
    const { variableProperty } = this.state;
    let temp = [];
    variableProperty.forEach((items) => {
      if (!key) {
        temp.push(items);
      } else if (
        Object.keys(items)[0].toLowerCase().indexOf(key.toLowerCase()) > -1
      ) {
        temp.push(items);
      }
    });
    this.setState({
      searchedProperty: temp,
    });
  };

  filterUnit = (key) => {
    const { units } = this.state;
    let tempList = [];
    units.forEach((unit) => {
      if (!key) {
        tempList.push(unit);
      } else if (unit.toLowerCase().search(key.toLowerCase()) > -1) {
        tempList.push(unit);
      }
    });
    this.setState({ searchedUnits: tempList });
  };

  unitFiltered = (value) => {
    let unitList = [];
    this.state.searchedProperty.forEach((unit) => {
      if (Object.keys(unit)[0] === value) {
        unitList = unit[value];
      }
    });

    return unitList;
  };

  resetField = (name, value) => {
    if (name === MANAGE_PI_TAGS_CONSTANTS.PROPERTY_API_INDEX) {
      this.setState({
        searchedProperty: this.state.variableProperty,
        searchedUnits: [],
      });
    } else if (!value) {
      this.setState({
        searchedUnits: [],
      });
    } else {
      this.setState({
        searchedUnits: this.unitFiltered(value.variableProperty),
      });
    }
  };

  deletePiTag = () => {
    this.setState({ isDeleteModal: true });
  };

  cancelDeleteModal = () => {
    this.setState({ isDeleteModal: false });
  };

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.isEdit) {
      this.setState({
        isCancelModal: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }
  renderBreadCrumb() {
    const { clientData, facilityData } = this.props.location.state;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <label
            className="crumb"
            onClick={() => this.navigateToScreen(Routes.clients)}
          >
            {SideMenuVariables.CLIENTS}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            className="crumb"
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData: clientData,
                mode: 'view',
              })
            }
          >
            {clientData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            className="crumb"
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData: clientData,
                facilityData: facilityData,
                mode: 'view',
              })
            }
          >
            {facilityData.name}
          </label>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  render() {
    const { facilityData, dataUnits } = this.state;
    const {
      piTagList,
      clientState,
      piTagSaveState,
      piTagDeleteState,
      piTagUploadState,
    } = this.props;
    const facilityId = facilityData['_id'];
    return (
      <div className="upload-pi-tags-wrapper ClientDetails">
        {((this.state.skip === INITIAL_SKIP && piTagList.isLoading) ||
          clientState.loading) && <Loader />}
        {(piTagSaveState.isLoading ||
          piTagDeleteState.isLoading ||
          piTagUploadState.isLoading ||
          piTagList.isLoading) && <Loader />}
        <div className="header" style={MaskStyle}>
          <div className="title">{MANAGE_PI_TAGS_CONSTANTS.TITLE}</div>
          <div className="path">
            {this.renderBreadCrumb()}
            {elementsGaurd(
              <button
                className="upload-pi-tag-btn btn-default"
                onClick={this.handleToggle}
              >
                {ButtonVariables.UPLOAD_PI_TAG}
              </button>,
              ElementPermissions.ADD_PI_TAG,
              {
                type: 'facility',
                dataRef: {
                  _id: facilityId,
                },
              }
            )}
          </div>
        </div>
        
        <ManageTag
          facilityId={this.state.facilityData}
          equipmentsList={handleSort(this.state.equipmentsList, 'name')}
          systems={clientState.apiState.clientInfoData.systems}
          FacilityId={facilityId}
          variablePropertyList={this.state.searchedProperty.map(
            (item) => Object.keys(item)[0]
          )}
          variablePropertyUnits={this.state.searchedUnits}
          handleChange={this.handleChange}
        />
        {this.state.isUpload && (
          <UploadModal
            title={'Upload File'}
            content={'Upload Pi Tag List'}
            visible={this.state.isUpload}
            handleOk={(payload) =>
              Validator(
                this.uploadCsv,
                payload,
                facilityData._id,
                dataUnits,
                this.handleToggle,
                this.props.piTagUploadClear
              )
            }
            handleCancel={this.handleToggle}
            id={facilityData._id}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  clientState: state.ClientReducer,
  facilityData: state.passDataRoute,
  piTagSaveState: state.piTagReducer.piTagSave,
  piTagList: state.piTagReducer.piTagList,
  piTagDeleteState: state.piTagReducer.piTagDelete,
  piTagUploadState: state.piTagReducer.piTagUpload,
  variableProperty: state.outputDataReducer,
  associationsData: state.unitsProperty.association,
});

const mapDispatchToProps = {
  userRole,
  piTagListFetch,
  piTagListClear,
  piTagDelete,
  piTagDeleteClear,
  piTagUpload,
  piTagUploadClear,
  piTagSave,
  piTagSaveClear,
  piTagClearReducer,
  getVariableProperty,
  clearState,
  associations,
};

ManageTags.propTypes = {
  network: PropTypes.object,
  facilityData: PropTypes.object,
  piTagSave: PropTypes.func.isRequired,
  piTagList: PropTypes.object,
  piTagDeleteState: PropTypes.object,
  piTagUploadState: PropTypes.object,
  variableProperty: PropTypes.object,
  userRole: PropTypes.func.isRequired,
  piTagListFetch: PropTypes.func.isRequired,
  piTagListClear: PropTypes.func.isRequired,
  piTagDelete: PropTypes.func.isRequired,
  piTagDeleteClear: PropTypes.func.isRequired,
  piTagUpload: PropTypes.func.isRequired,
  piTagUploadClear: PropTypes.func.isRequired,
  piTagSaveClear: PropTypes.func.isRequired,
  piTagClearReducer: PropTypes.func.isRequired,
  getVariableProperty: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageTags));
