import React, { Component } from "react";
import "./ObjectContainer.scss";
import ReactDOM from "react-dom";
import {
  BASE_API_PATH,
  ObjectContainerVariables,
} from "../../../constants/variables";
import { getImageUrl } from "../../../libs/token";

class ObjectContainer extends Component {
  constructor(props) {
    super(props);
    this.getComponents = this.getComponents.bind(this);
  }

  componentDidMount() {
    this.getComponents();
    this.props.myRef(this.refs);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.objects !== this.props.objects) {
      this.getComponents();
      this.props.myRef(this.refs);
    }
  }

  getComponents() {
    const componentsdiv = ReactDOM.findDOMNode(this.refs.components);
    componentsdiv.innerHTML = "";
    const { objects, schematic } = this.props;
    objects.forEach((element) => {
      const div = document.createElement("div");
      div.classList.add("itemDiv");
      const img = document.createElement("img");
      img.src =
        schematic === "system"
          ? getImageUrl(element.imgUrl)
          : `${BASE_API_PATH}/${element.imgUrl}`;
      img.classList.add("item");
      img.style.width = this.props.schematic === "system" ? "90px" : "60px";
      img.style.height = this.props.schematic === "system" ? "70px" : "50px";
      img.title = element.name;
      img.setAttribute("data", JSON.stringify(element));
      const label = document.createElement("label");
      label.innerHTML =(element.name === 'Pipeline Inventory Management') ? 'Pipeline Inventory Monitoring':element.name;
      div.appendChild(img);
      div.appendChild(label);
      componentsdiv.appendChild(div);
    });
  }

  render() {
    return (
      <div>
        <div>
          <p className="title">{ObjectContainerVariables.OBJECT_PALLETE}</p>
        </div>
        <hr />
        <div className="components" ref="components" />
      </div>
    );
  }
}

export default ObjectContainer;
