import * as Types from "../Types/systemSchematic.types";

const INITIAL_API_STATE = {
  isGetSchematicSuccess: false,
  getSchematicData: [],
  isGetSchematicError: false,
  isAddSchematicSuccess: false,
  addSchematicData: [],
  isAddSchematicError: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  isLoading: false,
  apiState: { ...INITIAL_API_STATE },
};

const systemSchematicReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_SYSTEM_SCHEMATIC_PROGRESS:
      return {
        ...state,
        isLoading: true,
        apiState: {
          ...state.apiState,
          isGetSchematicSuccess: false,
          isGetSchematicError: false,
        },
      };

    case Types.GET_SYSTEM_SCHEMATIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isGetSchematicSuccess: true,
          getSchematicData: action.payload,
        },
      };

    case Types.GET_SYSTEM_SCHEMATIC_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isGetSchematicError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.ADD_SYSTEM_SCHEMATIC_PROGRESS:
      return {
        ...state,
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case Types.ADD_SYSTEM_SCHEMATIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isAddSchematicSuccess: true,
          addSchematicData: action.payload,
        },
      };

    case Types.ADD_SYSTEM_SCHEMATIC_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isAddSchematicError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.EDIT_SYSTEM_SCHEMATIC_PROGRESS:
      return {
        ...state,
        isLoading: true,
        apiState: {
          ...state.apiState,
          isAddSchematicSuccess: false,
          isAddSchematicError: false,
        },
      };

    case Types.EDIT_SYSTEM_SCHEMATIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isAddSchematicSuccess: true,
          addSchematicData: action.payload,
        },
      };

    case Types.EDIT_SYSTEM_SCHEMATIC_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...state.apiState,
          isAddSchematicError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export default systemSchematicReducer;
