import JoditEditor from "jodit-react";
import React, { useMemo } from "react"

export default function BulkActionEditor(props) {
    const { closingComment, setClosingComment } = props

    const config = {
        autofocus: true,
        buttons: ['bold', 'italic', 'underline', 'fontsize', 'brush', 'link', 'unlink', 'font', 'ul', 'ol', 'align', 'table', 'fullsize', 'image'],
        height: 'auto',
        minHeight: '15vw',
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        askBeforePasteHTML: false,
    };

    return useMemo(() => (
        <div>
            <JoditEditor value={closingComment} config={config} onChange={(comment) => {
                setClosingComment(comment)
            }} />
        </div>
    ), []) // eslint-disable-line
}