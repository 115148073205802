import React, { Component } from 'react';
import './ConfigData.scss';
import { Tabs, Empty } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../common/Loader/Loader';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import { NO_INTERNET } from '../../../../constants/messages';
import {
  getConfig,
  clearConfigState,
} from '../../../../redux/actions/config.action';
import ConfirmModal from '../../../../common/Modal/Modal';
import { dataVariable } from './RunnerData/RunnerData.constants';
import { getDataUnits } from '../../../../redux/actions/dataUnit.action';
import {
  fetchMotorConfigData,
  clearMotorReducer,
  getDrivetypes,
} from '../../../../redux/actions/motor.action';
import { fetchSynchronousMotorConfigData } from '../../../../redux/actions/synchronousMotor.action';
import { fetchGasTurbineConfigData } from '../../../../redux/actions/gasTurbine.action';
import {fetchPIMConfigData} from '../../../../redux/actions/PIMComponent.action'
import { fetchValveConfigData } from '../../../../redux/actions/controlValve.action';
import { updateFormEdit } from '../../../../redux/actions/formEdit.action';
import { getManufacturer } from '../../../../redux/actions/equipment.action';
import { getCurvesData } from '../../../../redux/actions/steamTurbine.action';
import { fetchSynchronousGeneratorConfigData } from '../../../../redux/actions/synchronousGenerator.action';
import { fetchRecipCompressorConfigData } from '../../../../redux/actions/reciprocatingCompressorAction';
import { fetchScrewCompressorConfig } from '../../../../redux/actions/screwCompressor.action';
import { fetchHVSGConfigData, fetchHVSGCurvesData } from '../../../../redux/actions/hydraulicVariableSpeedGear.action';

const { TabPane } = Tabs;

class ConfigData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfigData: [],
      isUnit: [],
      message: '',
      activeKey: '1',
      tabChange: null,
      formEdit: false,
      visible: false,
    };
  }

  componentDidMount() {
    if (this.handleNetwork()) {
      this.getConfig();
      this.getData();
      const { getDrivetypes, getDataUnits, getManufacturer } = this.props;
      getDrivetypes();
      getDataUnits(dataVariable);
      getManufacturer();
    }

    window.addEventListener('form-edit', () => {
      const { updateFormEdit } = this.props;
      updateFormEdit(true);
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      const { updateFormEdit } = this.props;
      updateFormEdit(false);
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeKey } = this.props;
    if (prevProps.activeKey !== activeKey) {
      this.setState({
        activeKey: activeKey || '1',
      });
    }

    /**
     *
     * not used anywhere
     */

    // const { isSuccess } = this.props.curveState.apiState
    const { isSurgeLineFetchSuccess } = this.props.surgeLineState;
    const {
      isConfigFetchSuccess,
      isConfigFetchError,
    } = this.props.configState.apiState;
    const {
      addPerformaceData,
      addDesignData,
      addStartingCharCurve,
      addVarSpeedCurve,
      addPerformanceCurve,
      addEquCktData,
      addDriveTypes,
    } = this.props.MotorReducer;
    const { clearConfigState, tabsInfo } = this.props;
    // const { deleteCompressorCurves } = compressorReducer;
    const { isSuccessFetchRunner } = this.props.runnerDataState.apiState;
    const { isDesignAddSuccess } = this.props.designState.apiState;
    const { isFluidAddSuccess } = this.props.fluidCompositionState.apiState;
    // const compressorCurvessSuccess = this.props.compressorReducer.apiState.isSuccess;
    const { isBypassValveAddedSuccess } = this.props.bypassState.apiState;
    const { isAddDataSuccess } = this.props.flowMeasurementState.apiState;
    const { isSuccessAddManufacturer } = this.props.equipmentState.apiState;
    //console.log(this.props)
    if (isConfigFetchSuccess) {
      clearConfigState();
      this.setState({
        isConfigData: Object.keys(tabsInfo).map((item) => true),
      });
    }

    if (isConfigFetchError) {
      clearConfigState();
    }

    // Added Later for Curves
    const { curveState, getDrivetypes, getManufacturer } = this.props;
    if (
      curveState.apiState.isSuccess &&
      curveState.apiState.isAdded &&
      prevProps.curveState.apiState.data !== curveState.apiState.data
    ) {
      if (this.handleNetwork()) {
        this.getConfig();
      }
    }
    // Added Later for Curves
    if (
      curveState.apiState.isSuccess &&
      curveState.apiState.isDeleted &&
      prevProps.curveState.apiState.data !== curveState.apiState.data
    ) {
      if (this.handleNetwork()) {
        this.getConfig();
      }
    }

    if (
      isSuccessFetchRunner ||
      isFluidAddSuccess ||
      isDesignAddSuccess ||
      isBypassValveAddedSuccess ||
      isSurgeLineFetchSuccess ||
      isAddDataSuccess
    ) {
      if (this.handleNetwork()) {
        this.getConfig();
        getDataUnits(dataVariable);
      }
    }

    if (
      (addPerformaceData.isSuccess &&
        prevProps.MotorReducer.addPerformaceData.isSuccess !==
        addPerformaceData.isSuccess) ||
      (addDesignData.isSuccess &&
        prevProps.MotorReducer.addDesignData.isSuccess !==
        addDesignData.isSuccess) ||
      (addPerformanceCurve.isSuccess &&
        prevProps.MotorReducer.addPerformanceCurve.isSuccess !==
        addPerformanceCurve.isSuccess) ||
      (addStartingCharCurve.isSuccess &&
        prevProps.MotorReducer.addStartingCharCurve.isSuccess !==
        addStartingCharCurve.isSuccess) ||
      (addVarSpeedCurve.isSuccess &&
        prevProps.MotorReducer.addVarSpeedCurve.isSuccess !==
        addVarSpeedCurve.isSuccess) ||
      (addEquCktData.isSuccess &&
        prevProps.MotorReducer.addEquCktData.isSuccess !==
        addEquCktData.isSuccess)
    ) {
      if (this.handleNetwork()) {
        this.getData();
      }
    }
    if (
      addDriveTypes.isSuccess &&
      addDriveTypes.isSuccess !== prevProps.MotorReducer.addDriveTypes.isSuccess
    ) {
      if (this.handleNetwork()) {
        getDrivetypes();
      }
    }

    if (
      addDriveTypes.isSuccess &&
      addDriveTypes.isSuccess !== prevProps.MotorReducer.addDriveTypes.isSuccess
    ) {
      if (this.handleNetwork()) {
        getDrivetypes();
      }
    }

    if (
      isSuccessAddManufacturer &&
      prevProps.equipmentState.apiState.isSuccessAddManufacturer !==
      isSuccessAddManufacturer
    ) {
      getManufacturer();
    }
  }

  getConfig() {
    const {
      getConfig,
      location: { state },
    } = this.props;
    const { name } = this.props.location.state.componentData.assetInfo;
    if (
      this.handleNetwork() &&
      state.componentData &&
      state.componentData.info &&
      name !== 'AC induction motor' &&
      name !== 'Mixer' &&
      name !== 'Synchronous motor' &&
      name !== 'Synchronous generator' 
    ) {
      getConfig(state.componentData.info._id, name);
    }
  }

  getData() {
    const {
      location: { state },
      fetchMotorConfigData,
      fetchSynchronousMotorConfigData,
      fetchGasTurbineConfigData,
      getCurvesData,
      fetchSynchronousGeneratorConfigData,
      fetchValveConfigData,
      fetchPIMConfigData,
      fetchRecipCompressorConfigData,
      fetchScrewCompressorConfig,
      fetchHVSGConfigData,
      fetchHVSGCurvesData,
    } = this.props;
    const { name } = this.props.location.state.componentData.assetInfo;
    if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Synchronous motor'
    ) {
      fetchSynchronousMotorConfigData(state.componentData.info._id);
    } else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'AC induction motor'
    ) {
      fetchMotorConfigData(state.componentData.info._id);
    } else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Gas Turbine'
    ) {
      fetchGasTurbineConfigData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Control Valve'
    ) {
      fetchValveConfigData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Steam Turbine'
    ) {
      getCurvesData(state.componentData.info._id);
    } else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Synchronous generator'
    ) {
      fetchSynchronousGeneratorConfigData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Reciprocating Compressor'
    ) {
      fetchRecipCompressorConfigData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      (name === 'Pipeline Inventory Management' || name === 'Pipeline Inventory Monitoring')
    ) {
      fetchPIMConfigData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Hydraulic Variable Speed Gear'
    ) {
      fetchHVSGConfigData(state.componentData.info._id);
      fetchHVSGCurvesData(state.componentData.info._id);
    }
    else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      name === 'Screw Compressor'
    ) {
      fetchScrewCompressorConfig(state.componentData.info._id);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  onTabChange(pane) {
    const { isEdit } = this.props;
    const { formEdit, activeKey } = this.state;
    if (formEdit || isEdit) {
      this.setState({
        activeKey: activeKey,
        tabChange: pane,
        visible: true,
      });
    } else {
      this.setState({
        activeKey: pane,
      });
    }
  }

  clearConfigState(index) {
    const { isConfigData } = this.state;
    const newisConfig = [...isConfigData];
    newisConfig[index] = false;
    this.setState({
      isConfigData: newisConfig,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { tabChange } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.setState({
          activeKey: tabChange,
          tabChange: null,
        });
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    const { visible, message } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={message}
        />
      );
    }
    return null;
  }

  componentWillUnmount() {
    const { clearMotorReducer } = this.props;
    clearMotorReducer();
  }

  render() {
    const { tabsInfo, isSchematicEditable, stCurvesData } = this.props;
    const { loading } = this.props.configState;
    const { configData } = this.props.configState.apiState;
    const designDataObject = configData?.designData;
    const performanceData = configData?.performanceData;
    const { activeKey, isConfigData } = this.state;
    const {
      location: { state },
    } = this.props;
    return (
      <div className="ConfigData">
        {loading ? <Loader /> : null}
        <div className="tab-type-button">
          <Tabs
            defaultActiveKey="1"
            onChange={(event) => {
              this.onTabChange(event);
            }}
            activeKey={activeKey}
          >
            {tabsInfo &&
              Object.keys(tabsInfo).map((item, index) => {
                const tabItem = tabsInfo[item];
                return (
                  <TabPane tab={item} key={`${index + 1}`}>
                    {tabItem.screen ? (
                      <tabItem.screen
                        isSchematicEditable={isSchematicEditable}
                        activeKey={activeKey}
                        configData={{ ...configData }}
                        isConfigData={isConfigData[index]}
                        clearConfigState={() => {
                          this.clearConfigState(index);
                        }}
                        tabValue={this.state.activeKey}
                        location={this.props.location}
                        designDataObject={designDataObject}
                        performanceData={performanceData}
                        stCurvesData={stCurvesData}
                        componentID={state &&  state.componentData && state.componentData.info &&
                          state.componentData.info._id ?
                          state.componentData.info._id: ""}
                      />
                    ) : (
                      <Empty />
                    )}
                  </TabPane>
                );
              })}
          </Tabs>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
  network: state.NetworkReducer,
  configState: state.ConfigReducer,
  runnerDataState: state.RunnerDataReducer,
  fluidCompositionState: state.FluidCompositionReducer,
  designState: state.DesignReducer,
  curvesState: state.CurvesReducer,
  bypassState: state.BypassReducer,
  compressorReducer: state.CompressorReducer,
  surgeLineState: state.surgeLineReducer,
  flowMeasurementState: state.flowMeasurementReducer,
  dataUnitState: state.DataUnitReducer,
  MotorReducer: state.MotorReducer,
  curveState: state.curveReducer,
  equipmentState: state.EquipmentReducer,
  stCurvesData: state.steamTurbineReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
  getConfig: (id, type) => dispatch(getConfig(id, type)),
  clearConfigState: () => dispatch(clearConfigState()),
  getDataUnits: (data) => dispatch(getDataUnits(data)),
  fetchMotorConfigData: (componentId) =>
    dispatch(fetchMotorConfigData(componentId)),
  fetchSynchronousMotorConfigData: (componentId) =>
    dispatch(fetchSynchronousMotorConfigData(componentId)),
  fetchGasTurbineConfigData: (componentId) =>
    dispatch(fetchGasTurbineConfigData(componentId)),
  fetchValveConfigData: (componentId) => dispatch(fetchValveConfigData(componentId)),
  fetchPIMConfigData: (componentId) => dispatch(fetchPIMConfigData(componentId)),
  fetchHVSGConfigData: (componentId) => dispatch(fetchHVSGConfigData(componentId)),
  fetchHVSGCurvesData: (componentId => dispatch(fetchHVSGCurvesData(componentId))),
  clearMotorReducer: () => dispatch(clearMotorReducer()),
  getDrivetypes: () => dispatch(getDrivetypes()),
  getManufacturer: () => dispatch(getManufacturer()),
  getCurvesData: (id) => dispatch(getCurvesData(id)),
  fetchSynchronousGeneratorConfigData: (componentId) =>
    dispatch(fetchSynchronousGeneratorConfigData(componentId)),
  fetchRecipCompressorConfigData: (componentId) =>
    dispatch(fetchRecipCompressorConfigData(componentId)),
  fetchScrewCompressorConfig: (componentId) =>
    dispatch(fetchScrewCompressorConfig(componentId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfigData)
);
