import {
  Button,
  Divider,
  Empty,
  Modal,
  Select,
  Spin,
  Switch,
  DatePicker,
  Tooltip,
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../../CustomTable';
import cardTypeMapper from '../../../constants/cardTypes';
import { CURRENT_DURATION, deleteModal } from '../../../constants/messages';
import { elementsGaurd } from '../../../gaurds';
import { getTimeInFormat } from '../../../libs/valueFormatter';
import { ElementPermissions } from '../../../permissions';
import ConfirmModal from '../../Modal/Modal';
import Notification, { Types } from '../../Notification/Notification';
import { scaleColumn } from './GraphCard.constant';
import { CalendarOutlined, PercentageOutlined, MinusOutlined } from '@ant-design/icons';
import './GraphCard.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const dataLimit = ['2 Hr', '1 Day', '1 Week'];

export const trendsDataLimit = [
  '2 Hr',
  '4 Hr',
  '8 Hr',
  '1 Day',
  '1 Week',
  '1 Month',
];

export const trends2DataLimit = [
  '2 Hr',
  '4 Hr',
  '8 Hr',
  '1 Day',
  '1 Week',
  '1 Month',
  '3 Months',
  '6 Months',
  'Custom',
];

export const trendsDataClientLimit = [
  '2 Hr',
  '4 Hr',
  '8 Hr',
  '1 Day',
  '1 Week',
  '1 Month',
  '3 Months',
  '6 Months',
  'Custom',
];

export const multipleXYLimit = [
  '2 Hr',
  '4 Hr',
  '8 Hr',
  '1 Day',
  '1 Week',
  'Custom',
];

export const failureDataLimit = ['1 Month', '6 Months', '1 Year'];

let inDebounce;
export const debounce = (func, delay) => (...args) => {
  clearTimeout(inDebounce);
  inDebounce = setTimeout(() => func(...args), delay);
};

class GraphCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment(),
      time: '',
      visible: false,
      message: deleteModal.message,
      title: deleteModal.title,
      durationIndex: 0,
      isVisibleToClient:
        this.props.item &&
        this.props.item.isVisibleToClient /** for trends cards only */,
      isScaleVisible: false,
      manualScale: false,
      dataList: [],
      customDate: null,
      isXAxisVisible: false,
      isYAxisVisible: false,
      isMinimizeVisible: false,
    };
  }

  componentDidMount() {
    const { activeKey, duration, isXAxisVisible, isYAxisVisible } = this.props;
    if (activeKey) {
      this.setState({
        durationIndex: duration,
        isXAxisVisible,
        isYAxisVisible,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cardData } = this.props;

    /** check only for  trends */
    if (
      cardData &&
      cardData.isSuccess &&
      prevProps.cardData.isSuccess !== cardData.isSuccess
    ) {
      this.setState({
        viewDateFrom: cardData.viewDateFrom,
        viewDateTill: cardData.viewDateTill,
      });
    } else if (
      cardData &&
      cardData.isError &&
      prevProps.cardData.isError !== cardData.isError
    ) {
      Notification.show(Types.Error, '');
      this.setState({
        viewDateFrom: cardData.viewDateFrom,
        viewDateTill: cardData.viewDateTill,
      });
    }
  }

  renderModal() {
    const { visible, message, title } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={message}
          title={title}
        />
      );
    }
    return null;
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  handleOk() {
    const { id } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.delete(id);
      }
    );
  }

  openModal = () => {
    this.setState({ visible: true });
  };

  renderChildren = (children) => {
    const {
      id,
      cardType,
      activeKey,
      showNextData,
      showPreviousData,
      alertPlots,
    } = this.props;
    const {
      viewDateFrom,
      viewDateTill,
      currentTime,
      durationIndex,
    } = this.state;

    return (
      <div className="time-series">
        {activeKey && !alertPlots && (
          <LeftOutlined
            type="left"
            onClick={() =>
              showPreviousData(
                id,
                cardType,
                viewDateFrom,
                viewDateTill,
                currentTime,
                durationIndex
              )
            }
          />
        )}

        <span className="time-series-highchart">{children}</span>
        {activeKey && !alertPlots && (
          <RightOutlined
            type="right"
            style={{ padding: '0.5pc' }}
            onClick={() =>
              showNextData(
                id,
                cardType,
                viewDateFrom,
                viewDateTill,
                currentTime,
                durationIndex
              )
            }
          />
        )}
      </div>
    );
  };

  changeChecked = (value) => {
    this.setState({ isVisibleToClient: value });
  };

  getData = (isDurationChange, e, type = null) => {
    const {
      id,
      cardType,
      onTimeChange,
      batchTime,
      trends2,
      getBatchTime,
      alertPlots,
    } = this.props;
    if (trends2) {
      getBatchTime();
    }
    let userDurationIndex = e;
    let currentDateTime2 = null;
    let dateFrom;
    let dateTill;
    const isFailureChart =
      cardType === cardTypeMapper['Failure cause pie chart'];
    const currentDateTime =
      trends2 || alertPlots ? moment(batchTime * 1000) : moment();
    if (isDurationChange) {
      this.setState({
        currentTime: currentDateTime,
        durationIndex: userDurationIndex,
      });
    } else {
      this.setState({ currentTime: currentDateTime });
      const { durationIndex } = this.state;
      userDurationIndex = durationIndex;
    }
    currentDateTime2 = _.cloneDeep(currentDateTime);
    dateTill = currentDateTime2.valueOf();
    if (
      (userDurationIndex === 8 || (userDurationIndex === 5 && cardType === 'Multiple X-Y Plot') )&&
      type === 'now'
    ) {
      userDurationIndex = 0;
    }
    if (
      (userDurationIndex === 8 && type === null) ||
      (userDurationIndex === 5 &&
        cardType === 'Multiple X-Y Plot' &&
        type === null)
    ) {
      this.pickerWithType();
    } else {
      switch (userDurationIndex) {
        case 1:
          dateFrom = isFailureChart
            ? currentDateTime.subtract(6, 'months').valueOf()
            : currentDateTime.subtract(4, 'hours').valueOf();
          break;
        case 2:
          dateFrom = isFailureChart
            ? currentDateTime.subtract(1, 'years').valueOf()
            : currentDateTime.subtract(8, 'hours').valueOf();
          break;
        case 3:
          dateFrom = currentDateTime.subtract(1, 'days').valueOf();
          break;
        case 4:
          dateFrom = currentDateTime.subtract(7, 'days').valueOf();
          break;
        case 5:
          dateFrom = currentDateTime.subtract(1, 'months').valueOf();
          break;
        case 6:
          dateFrom = currentDateTime.subtract(3, 'months').valueOf();
          break;
        case 7:
          dateFrom = currentDateTime.subtract(6, 'months').valueOf();
          break;
        default:
          dateFrom = isFailureChart
            ? currentDateTime.subtract(1, 'months').valueOf()
            : currentDateTime.subtract(2, 'hours').valueOf();
          break;
      }
      return onTimeChange(
        {
          id,
          cardType,
          dateFrom,
          dateTill,
        },
        e,
        type
      );
    }
  };

  handleScaleCancel() {
    this.setState({ isScaleVisible: false, isMinimizeVisible: false, dataList: [] });
  }

  handleScaleOk() {
    const { dataList, manualScale } = this.state;
    const { selectScale, id, cardDetails } = this.props;
    const payloadData = dataList;
    const payloadDataManualScale = manualScale;
    this.setState({ isScaleVisible: false, isMinimizeVisible: false, dataList: [] });
    selectScale(payloadData, payloadDataManualScale, id, cardDetails);
  }

  handleClickedOut() {
    this.setState({ isScaleVisible: false });
  }

  handleMinimize() {
    this.setState({ isMinimizeVisible: true, isScaleVisible: false });
  }

  openScaleModal() {
    const { dataList, isMinimizeVisible, manualScale } = this.state;
    const { cardDetails, cardType } = this.props;
    if (cardType === cardTypeMapper['Multiple X-Y Plot']) {
      const result = [];
      const map = new Map();
      let iter = 0;
      for (const item of cardDetails.axis.series) {
        if (!map.has(item.x.value)) {
          map.set(item.x.value, true);
          result.push({
            id: item.x._id,
            index: iter,
            name: `${item.x.variableName} (${item.x.value})`,
            minScale: item.x.minScale || '',
            maxScale: item.x.maxScale || '',
            value: item.x.value,
            variableName: item.x.variableName,
            type: item.x.type,
          });
          iter++;
        }
        if (!map.has(item.y.value)) {
          map.set(item.y.value, true);
          result.push({
            id: item.y._id,
            index: iter,
            name: `${item.y.variableName} (${item.y.value})`,
            minScale: item.y.minScale || '',
            maxScale: item.y.maxScale || '',
            value: item.y.value,
            variableName: item.y.variableName,
            type: item.y.type,
          });
          iter++;
        }
      }
      this.setState({
        dataList: isMinimizeVisible ? dataList : result,
        manualScale: isMinimizeVisible ? manualScale : cardDetails.manualScale || false,
      });
      this.setState({ isScaleVisible: true, isMinimizeVisible: false });
    } else {
      const data = cardDetails.axis.y.map((item, index) => ({
        id: item._id,
        index,
        name: `${item.variableName} (${item.value})`,
        minScale: item.minScale || '',
        maxScale: item.maxScale || '',
        value: item.value,
        variableName: item.variableName,
        type: item.type,
      }));
      this.setState({
        dataList: isMinimizeVisible ? dataList : data,
        manualScale: isMinimizeVisible ? manualScale : cardDetails.manualScale || false,
      });
      this.setState({ isScaleVisible: true, isMinimizeVisible: false });
    }
  }

  handleChange = (value, index) => {
    const { dataList } = this.state;
    const updatedList = dataList.map((item) => {
      if (item.index === index) {
        item[value.target.name] = value.target.value;
      }
      return item;
    });
    this.setState({ dataList: updatedList });
  };

  changeDefaultScaling(value) {
    this.setState({ manualScale: value });
  }

  renderScaleModal() {
    const { isScaleVisible, isMinimizeVisible } = this.state;
    if (isScaleVisible || isMinimizeVisible) {
      const { manualScale, dataList } = this.state;
      return (
        <Modal
          style={{ maxWidth: '300rem', width: '100%' }}
          visible={isScaleVisible}
          closable={false}
          onCancel={() => this.handleClickedOut()}
          title={
            <div>
              Scale
              {
                manualScale ?
                  <Tooltip>
                    <MinusOutlined
                      className="ant-modal-minimize"
                      title="Minimize"
                      onClick={() => this.handleMinimize()}
                      style={{ float: 'right' }}>
                      Minimize
                    </MinusOutlined>
                  </Tooltip>
                  : null
              }
            </div>
          }
          footer={[
            <Button key="cancel"
              className="ant-btn" onClick={() => { this.handleScaleCancel() }}>Cancel</Button>,
            <Button key="ok"
              className="ant-btn-primary" onClick={() => { this.handleScaleOk() }}>OK</Button>,
          ]}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Manual Scaling:</div>
              <div>
                <Switch
                  title="Manual Scale"
                  checked={manualScale}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onClick={(value) => {
                    this.changeDefaultScaling(value);
                  }}
                />
              </div>
            </div>

            {manualScale || isMinimizeVisible ? (
              <CustomTable
                style={{
                  paddingTop: '20px',
                  marginBottom: '-25px',
                  borderBottom: '1px solid #f5f5f5',
                }}
                showHeader
                columns={scaleColumn({
                  change: this.handleChange,
                })}
                data={dataList}
              />
            ) : null}
          </div>
        </Modal>
      );
    }
    return null;
  }

  zoom(type) {
    const { zoom, id, cardType } = this.props;
    const { viewDateFrom, viewDateTill } = this.state;
    zoom(id, viewDateFrom, viewDateTill, cardType, type);
  }

  pickOption = (type, id) => {
    this.setState({ type, cardIdSelected: id });
  };

  renderDatePickerModal() {
    const {
      isDatePickerVisible,
      setIsDatePickerVisible,
      numberOfCards,
    } = this.props;
    const maskStyle =
      numberOfCards < 7
        ? { backgroundColor: 'rgba(0,0,0,0.1)' }
        : { backgroundColor: 'rgba(0,0,0,0.01)' };
    if (isDatePickerVisible) {
      return (
        <Modal
          visible={isDatePickerVisible}
          onOk={() => this.dateSelectedOk()}
          onCancel={() => setIsDatePickerVisible(false)}
          title="Custom"
          maskStyle={maskStyle}
        >
          <div>
            <RangePicker
              showTime
              disabledDate={this.disabledDate}
              format="YYYY-MM-DD HH:mm"
              onOk={this.dateSelected}
              onCalendarChange={this.dateChange}
            />
          </div>
        </Modal>
      );
    }
  }

  pickerWithType() {
    const { setIsDatePickerVisible } = this.props;
    setIsDatePickerVisible(true);
  }

  disabledDate = (current) => {
    const { customDate } = this.state;
    if (!customDate || customDate.length === 0) {
      return current && current > moment().endOf('day');
    }
    if (current && customDate && customDate.length > 0) {
      const tooLate = customDate[0] && current.diff(customDate[0], 'days') > 180;
      const tooEarly =
        customDate[1] && customDate[1].diff(current, 'days') > 180;
      return tooEarly || tooLate;
    }
  };

  dateChange = (time) => {
    this.setState({ customDate: time });
  };

  dateSelectedOk = () => {
    const { setIsDatePickerVisible, cardType } = this.props;
    setIsDatePickerVisible(false);
    const { customDate } = this.state;
    const { timeSelect, id } = this.props;
    timeSelect(customDate[0], customDate[1], id, cardType);
    this.setState({ customDate: null });
  };

  switchXAxis(value) {
    const { toggleXAxis } = this.props;
    this.setState({
      isXAxisVisible: value,
    });
    toggleXAxis(value);
  }

  switchYAxis(value) {
    const { toggleYAxis } = this.props;
    this.setState({
      isYAxisVisible: value,
    });
    toggleYAxis(value);
  }

  resetZoom() {
    const { id, resetZoom } = this.props;
    resetZoom(id);
  }

  setIndividualPlotDatePicker(id) {
    const { setIsDatePickerVisible, setId } = this.props;
    setIsDatePickerVisible(true);
    setId(id);
  }

  render() {
    const {
      cardData,
      cardType,
      height,
      title,
      viewDataAdmin,
      edit,
      id,
      userInfo,
      duration,
      activeKey,
      changeVisibleStatus,
      active,
      time,
      isPlots,
      isChangetime,
      children,
      facilityId,
      trends2,
      isDatePickerVisible,
      zoomIn,
      alertPlots,
      viewDateFrom,
      viewDateTill,
      zoomInId,
      numberOfDays,
      metaData,
      toggleMetaData,
    } = this.props;

    const {
      isVisibleToClient,
      durationIndex,
      visible,
      isScaleVisible,
      isXAxisVisible,
      isYAxisVisible,
    } = this.state;

    const isFailureChart =
      cardType === cardTypeMapper['Failure cause pie chart'] ||
      cardType === cardTypeMapper['Failure bar chart'] ||
      cardType === cardTypeMapper['Equipment Run Plot'];
    const isTimeSeriesChart = cardType === cardTypeMapper['Time series plot'];
    const isMultipleXYPlot = cardType === cardTypeMapper['Multiple X-Y Plot'];
    const isTrendsChart = activeKey;
    let customDuration = duration;
    if (trends2 && duration === 8) {
      customDuration = 0;
    }
    const xStyleElement = isXAxisVisible
      ? { backgroundColor: '#90ee90' }
      : { backgroundColor: '#ccc' };
    const yStyleElement = isYAxisVisible
      ? { backgroundColor: '#90ee90' }
      : { backgroundColor: '#ccc' };
    return (
      <div
        className={
          cardType === cardTypeMapper['Time series plot'] ||
          cardType === cardTypeMapper['Equipment Run Plot'] ||
          cardType === cardTypeMapper['Alert Plot']
            ? 'GraphCard fullWidthCard'
            : cardType === cardTypeMapper['Multiple X-Y Plot']
            ? 'GraphCard xyFullWidthCard'
            : 'GraphCard'
        }
        style={{ height: height || '470px' }}
      >
        <div className="head">
          <div className={title ? 'bold-title' : 'hidden'}>
            {title || 'Title'}
          </div>
          {/* // let it be for kpi WIP */}
          {/* <h5>{this.props.id}</h5> */}
          <div className="more-items">
            {elementsGaurd(
              <span>
                {!isTrendsChart && (
                  <Switch
                    checked={isTrendsChart ? isVisibleToClient : active}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onClick={(value) => {
                      isTrendsChart && this.changeChecked(value);
                      return changeVisibleStatus(value);
                    }}
                  />
                )}
              </span>,
              ElementPermissions.CARD_ACTION,
              {
                type: 'facility',
                dataRef: {
                  _id: facilityId,
                },
              }
            )}
            {(isTrendsChart && !alertPlots && (
              <button className="card-delete" onClick={this.openModal}>
                <DeleteOutlined />
              </button>
            )) ||
              elementsGaurd(
                <button className="card-delete" onClick={this.openModal}>
                  <DeleteOutlined />
                </button>,
                ElementPermissions.CARD_ACTION,
                {
                  type: 'facility',
                  dataRef: {
                    _id: facilityId,
                  },
                }
              )}
            {(isTrendsChart && !alertPlots && (
              <button
                type="button"
                className="card-edit"
                onClick={() => edit(id)}
              >
                <EditOutlined />
              </button>
            )) ||
              elementsGaurd(
                <button
                  type="button"
                  className="card-edit"
                  onClick={() => edit(id)}
                >
                  <EditOutlined />
                </button>,
                ElementPermissions.CARD_ACTION,
                {
                  type: 'facility',
                  dataRef: {
                    _id: facilityId,
                  },
                }
              )}
            {numberOfDays >= 3 && numberOfDays < 45 && alertPlots ? (
              <Switch
                checked={metaData}
                checkedChildren={<PercentageOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onClick={(value) => {
                  toggleMetaData(id, value);
                }}
              ></Switch>
            ) : null}
          </div>
        </div>
        <Divider />
        <div className="body">
          {alertPlots ? (
            userInfo === 'MechademyAdmin' ||
            userInfo === 'MechademyFacilityManager' ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.setIndividualPlotDatePicker(id)}
              >
                <CalendarOutlined style={{ marginRight: '10px' }} />
                {viewDateFrom && viewDateTill
                  ? getTimeInFormat(viewDateFrom) +
                    ' - ' +
                    getTimeInFormat(viewDateTill)
                  : null}
              </span>
            ) : (
              <span>
                <CalendarOutlined style={{ marginRight: '10px' }} />
                {viewDateFrom && viewDateTill
                  ? getTimeInFormat(viewDateFrom) +
                    ' - ' +
                    getTimeInFormat(viewDateTill)
                  : null}
              </span>
            )
          ) : (
            <div className="timeFormat">
              <ClockCircleOutlined />
              {` ${getTimeInFormat(time)}`}
            </div>
          )}
          <div className="sub">
            {isPlots &&
              isTimeSeriesChart &&
              cardData &&
              !cardData.isLoading && (
                <HomeOutlined
                  type="home"
                  style={{ fontSize: '22px', padding: '5px' }}
                  onClick={() => this.zoom('home')}
                />
              )}
            {(isTrendsChart && !alertPlots) && cardData && !cardData.isLoading && (
                <Button
                  onClick={() =>
                    debounce(this.getData(false, durationIndex, 'now'), 500)
                  }
                >
                  {CURRENT_DURATION}
                </Button>
              )}
              {(alertPlots && (userInfo === 'MechademyAdmin' || 
                  userInfo === 'MechademyFacilityManager')) && cardData && !cardData.isLoading && (
                  <Button
                    onClick={() =>
                      debounce(this.getData(false, durationIndex, 'now'), 500)
                    }
                  >
                    {CURRENT_DURATION}
                  </Button>
                )}
            {isMultipleXYPlot && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1.5%',
                  alignItems: 'left',
                }}
              >
                <div>
                  <b>X:&nbsp;</b>
                </div>
                <Switch
                  title="X-Axis"
                  checked={isXAxisVisible}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onClick={(value) => {
                    this.switchXAxis(value);
                  }}
                  style={xStyleElement}
                />
              </div>
            )}
            {isMultipleXYPlot && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1.5%',
                  alignItems: 'left',
                }}
              >
                <div>
                  <b>Y:&nbsp;</b>
                </div>
                <Switch
                  title="Y-Axis"
                  checked={isYAxisVisible}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onClick={(value) => {
                    this.switchYAxis(value);
                  }}
                  style={yStyleElement}
                />
              </div>
            )}
            {(isTimeSeriesChart || isMultipleXYPlot || alertPlots) && (
              <div>
                <ZoomInOutlined
                  style={{
                    fontSize: '25px',
                    padding: '5px',
                  }}
                  onClick={() => this.zoom('in', isTrendsChart)}
                />
              </div>
            )}
            {(isTimeSeriesChart || isMultipleXYPlot || alertPlots) && (
              <div>
                <ZoomOutOutlined
                  style={{
                    fontSize: '25px',
                    padding: '5px',
                  }}
                  onClick={() => this.zoom('out', isTrendsChart)}
                />
              </div>
            )}
            {(isTrendsChart || (isPlots && isTimeSeriesChart)) && !alertPlots && (
              <Button
                className="add-scale"
                onClick={() => this.openScaleModal()}
              >
                Scale
              </Button>
            )}
            {(isPlots && isTimeSeriesChart) ||
              (alertPlots &&
                elementsGaurd(
                  <Button
                    className="add-scale"
                    onClick={() => this.openScaleModal()}
                  >
                    Scale
                  </Button>,
                  ElementPermissions.CARD_ACTION,
                  {
                    type: 'facility',
                    dataRef: {
                      _id: facilityId,
                    },
                  }
                ))}
            {isPlots || alertPlots ? (
              ''
            ) : (
              <div>
                <div
                  className="dropdown-select"
                  style={{ paddingRight: '10%' }}
                >
                  {isChangetime && (
                    <Select
                      defaultValue={
                        isFailureChart
                          ? failureDataLimit[0]
                          : isTrendsChart === '7'
                          ? trends2DataLimit[customDuration]
                          : isTrendsChart === '6'
                          ? trendsDataLimit[duration]
                          : dataLimit[0]
                      }
                      onChange={(e) => this.getData(true, e)}
                      style={{ width: '150px' }}
                      className="selectDate"
                    >
                      {isFailureChart &&
                        failureDataLimit.map((item, index) => (
                          <Option value={index} key={item}>
                            {item}
                          </Option>
                        ))}
                      {!isFailureChart &&
                        !isTrendsChart &&
                        dataLimit.map((item, index) => (
                          <Option value={index} key={item}>
                            {item}
                          </Option>
                        ))}
                      {isTrendsChart &&
                      (userInfo === 'MechademyAdmin' ||
                        userInfo === 'MechademyFacilityManager')
                        ? isTrendsChart === '6'
                          ? trendsDataLimit.map((item, index) => (
                              <Option value={index} key={item}>
                                {item}
                              </Option>
                            ))
                          : cardType === 'Time series plot'
                          ? trends2DataLimit.map((item, index) => (
                              <Option value={index} key={item}>
                                {item}
                              </Option>
                            ))
                          : multipleXYLimit.map((item, index) => (
                              <Option value={index} key={item}>
                                {item}
                              </Option>
                            ))
                        : isTrendsChart && cardType === 'Time series plot'
                        ? trendsDataClientLimit.map((item, index) => (
                            <Option value={index} key={item}>
                              {item}
                            </Option>
                          ))
                        : cardType === 'Multiple X-Y Plot'
                        ? multipleXYLimit.map((item, index) => (
                            <Option value={index} key={item}>
                              {item}
                            </Option>
                          ))
                        : null}
                    </Select>
                  )}
                </div>
                <div>
                  {zoomIn && (
                    <Button
                      className="reset-button"
                      onClick={() => this.resetZoom()}
                    >
                      Reset Zoom
                    </Button>
                  )}
                </div>
              </div>
            )}
            {alertPlots && zoomInId && (
              <div>
                <Button
                  className="reset-button"
                  onClick={() => this.resetZoom()}
                >
                  Reset Zoom
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="graph-data-render">
          {cardData && cardData.isLoading ? (
            <Spin />
          ) : cardData && cardData.data && cardData.data ? (
            this.renderChildren(children)
          ) : (
            <Empty />
          )}
        </div>

        {cardType.includes('bar') ? (
          cardType.includes('Failure') ? (
            <div className="footer-tornado">
              {viewDataAdmin} alert count for last{' '}
              {failureDataLimit[durationIndex]}
            </div>
          ) : (
            <div className="footer-bar">{viewDataAdmin} alert count</div>
          )
        ) : null}
        {cardType.includes('tornado') && (
          <div className="footer-tornado">
            {viewDataAdmin} alert count for last{' '}
            {isFailureChart ? failureDataLimit[durationIndex] : '1 Month'}
          </div>
        )}
        {cardType.includes('pie') && (
          <div className="footer-pie">
            {viewDataAdmin} alert count for last{' '}
            {isFailureChart ? failureDataLimit[durationIndex] : '1 Month'}
          </div>
        )}
        {visible && this.renderModal()}
        {isScaleVisible && this.renderScaleModal()}
        {isDatePickerVisible && this.renderDatePickerModal()}
      </div>
    );
  }
}

GraphCard.propTypes = {
  cardType: PropTypes.object,
  cardData: PropTypes.object,
  onTimeChange: PropTypes.func,
  delete: PropTypes.func,
  zoom: PropTypes.func,
  edit: PropTypes.func,
  isPlots: PropTypes.bool,
  changeVisibleStatus: PropTypes.func,
};

export default GraphCard;
