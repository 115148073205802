import { TagFieldVariables } from "../../../constants/variables";

export const DATA_TYPE = ["Boolean", "Float", "Integer", "String"];

export const tagFields = (config) => {
    let requiredCheck = config.dataType === "Boolean" ? false : true
    let fields = [
        {
            label: TagFieldVariables.EQUIPMENT,
            key: "Equipemnt",
            type: "multiDropdown",
            max: 50,
            data: config.equipments,
            value: config.selectedEquipmentList,
        },
        {
            label: TagFieldVariables.DATA_TYPE,
            key: "dataType",
            type: "dropdown",
            max: 50,
            value: config.dataType,
            data: DATA_TYPE,
            change: (val) => config.handleFormChange(val, "dataType"),
            required: [true, 'This field is mandatory']
        },
        {
            label: TagFieldVariables.VARIABLE_PROPERTY,
            key: "variableProperty",
            type: "dropdown",
            max: 50,
            value: config.variableProperty,
            data: config.variablePropertyList,
            change: (val) => { config.handleChange(val, undefined, 'variableProperty'); config.handleFormChange(val, "variableProperty") },
            required: [true, 'This field is mandatory']
        },
        {
            label: TagFieldVariables.CLIENT_UNIT,
            key: "clientUnits",
            type: "text",
            max: 50,
            value: config.clientUnits? config.clientUnits : "",
            data: config.variablePropertyUnits,
        },
        {
            label: TagFieldVariables.MECHADEMY_UNIT,
            key: "mechademyUnits",
            type: "dropdown",
            max: 50,
            value: config.variablePropertyUnits.indexOf(config.mechademyUnits) >= 0 ? config.mechademyUnits : config.variablePropertyUnits[0],
            data: config.variablePropertyUnits,
            change: (val) => config.handleFormChange(val, "mechademyUnits"),
            required: [true, 'This field is mandatory']
        },
        {
            label: TagFieldVariables.MIN_VALUE,
            key: "minThreshold",
            type: "number",
            max: 50,
            value: config.minThreshold,
            required: [requiredCheck, 'This field is mandatory']
        },
        {
            label: TagFieldVariables.MAX_VALUE,
            key: "maxThreshold",
            type: "number",
            max: 50,
            value: config.maxThreshold,
            change: (val) => config.handleFormChange(val, "maxThreshold"),
            required: [requiredCheck, 'This field is mandatory']
        },
        {
            label: TagFieldVariables.MULTIPLYER,
            key: "multiplier",
            type: "number",
            max: 50,
            value: config.multiplier,
            change: (val) => config.handleFormChange(val, "multiplier"),
        },
        {
            label: TagFieldVariables.ADDER,
            key: "adder",
            type: "number",
            max: 50,
            value: config.adder,
            change: (val) => config.handleFormChange(val, "adder"),
        },
        {
            label: TagFieldVariables.ENABLE_UAD,
            key: "enableUAD",
            type: "switch",
            max: 50,
            data: config.UADEnabled,
            change: config.setUADEnabled,
        },
        {
            label: TagFieldVariables.IS_FROZEN,
            key: "enableIsFrozen",
            type: "switch",
            data: config.frozenEnabled,
            change: config.setFrozenEnabled,
        },
    ]
    return fields
}