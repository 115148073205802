import * as AssetTypes from "../Types/Asset.types";

const INITIAL_API_STATE = {
  data: null,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
  pagination: {},
};

export default function AssetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AssetTypes.ASSET_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: action.payload.body,
          token: action.payload.headers.authorization,
          message: null,
          isSuccess: true,
        },
      };
    case AssetTypes.ASSET_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case AssetTypes.ASSET_FETCH_ERROR:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          apiState: {
            ...state.apiState,
            // message: action.payload.message,
            errorCode:
              action.payload.response && action.payload.response.body.code,
            message:
              action.payload.response && action.payload.response.body.message,
            isError: true,
          },
        };
      }
      return state;

    case AssetTypes.ASSET_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          data: state.apiState.data,
        },
      };

    default:
      return state;
  }
}
