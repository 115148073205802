import {
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Popover,
  Select,
  Spin,
  Switch,
  AutoComplete
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import _ from "lodash";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { fileMessage } from "../../constants/messages";
import { getToken } from "../../libs/token";
import { fileUploadProps } from "../../libs/uploader";
import Notification, { Types } from "../Notification/Notification";
import "./FormFields.scss";

const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;

class FormFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formDisable: this.props.formDisable,
      uploading: false,
      fileId: "",
    };
  }

  onFileUpload(info, func) {
    const { status, response, name } = info.file;
    if (status !== "uploading") {
      this.setState({ uploading: false });
    } else {
      this.setState({ uploading: true });
    }
    if (status === "done") {
      this.setState({ uploading: false });
      func(response.fileId, name);
      Notification.show(Types.Success, fileMessage.successMsgFile);
    }
  }

  getOption(data) {
    return (
      <Option key={data} value={data} title={data}>
        {data}
      </Option>
    );
  }

  getAssigneeOption(data) {
    const { firstName, lastName, _id, email, role } = data;
    return (
      <Option key={_id} value={_id} title={email}>
        {firstName && lastName ? `${firstName} ${lastName}` : role[0]}
      </Option>
    );
  }

  getOptionObject(data, field) {
    if (field.searchKey) {
      return (
        <Option
          key={data[field.valueKey]}
          value={data[field.titleKey]}
          title={data[field.titleKey]}
        >
          {data[field.titleKey]}
        </Option>
      );
    }
    return (
      <Option
        key={data[field.valueKey]}
        value={data[field.valueKey]}
        title={data[field.titleKey]}
      >
        {data[field.titleKey]}
      </Option>
    );
  }

  getOptionReturnObj(data, field) {
    const stringObj = JSON.stringify(data);
    if (field.searchKey) {
      return (
        <Option
          value={data[field.titleKey]}
          key={stringObj}
          title={data[field.titleKey]}
        >
          {data[field.titleKey]}
        </Option>
      );
    }
    return (
      <Option value={data[field.valueKey]} title={data[field.titleKey]}>
        {data[field.titleKey]}
      </Option>
    );
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    const { getFieldDecorator } = this.props.form;
    const disabled = typeof field.disabled
      ? field.disabled
      : this.props.formDisable;

    if (field && field.type) {
      if (field.type === "dropdown") {
        return (
          <Select
            style={{ width: field.width ? field.width : "" }}
            disabled={disabled}
            showArrow={!disabled}
            showSearch
            placeholder="Search here"
            className="dropdown-search-field"
            onChange={
              field.change
                ? field.searchKey
                  ? (_value, event) => {
                    field.change(event.key);
                  }
                  : (e) => {
                    field.change(e);
                  }
                : () => {}
            }
            onFocus={field.resetField ? field.resetField : () => {}}
            defaultActiveFirstOption={false}
          >
            {field.data &&
              field.data.length &&
              field.data.map((data) => {
                if (field.ret && field.ret === "obj") {
                  return this.getOptionReturnObj(data, field);
                }
                return field.titleKey || field.valueKey
                  ? this.getOptionObject(data, field)
                  : this.getOption(data);
              })}
          </Select>
        );
      }
      if (field.type === "dropdownWithAdd") {
        return (
          <Select
            disabled={formDisable || field.disabled}
            showArrow={!formDisable}
            showSearch
            placejolder="Search here"
            className="dropdown-search-field"
            onChange={
              field.change
                ? (e) => {
                  field.change(e, field);
                }
                : () => {}
            }
            dropdownRender={(menu) => (
              <div>
                <div
                  style={{
                    padding: "4px 8px",
                    cursor: "pointer",
                    color: "green",
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    field.openAddModal(field.key);
                  }}
                >
                  <Icon type="plus" />
                  {field.addMessage}
                </div>
                <Divider style={{ margin: "4px 0" }} />
                {menu}
              </div>
            )}
          >
            {field.data &&
              field.data.map((data) =>
                field.titleKey || field.valueKey
                  ? this.getOptionObject(data, field)
                  : this.getOption(data)
              )}
          </Select>
        );
      }
      if (field && field.type === "multiDropdown") {
        return (
          <Select
            mode="multiple"
            placeholder="Search here"
            showArrow
            disabled={formDisable || field.disabled}
            onChange={
              field.change
                ? (_value, event) => {
                  field.change(_.map(event, "key"));
                }
                : () => {}
            }
            onSelect={
              field.select
                ? (_value, event) => {
                  field.select(event.key);
                }
                : () => {}
            }
            onDeselect={
              field.deSelect
                ? (_value, event) => {
                  field.deSelect(event.key);
                }
                : () => {}
            }
            autoClearSearchValue={field.autoClearSearchValue}
          >
            {field.data &&
              field.data.map((data) =>
                field.titleKey || field.valueKey
                  ? this.getOptionObject(data, field)
                  : this.getOption(data)
              )}
          </Select>
        );
      }
      if (field && field.type === "selectTags") {
        return (
          <Select
            mode="tags"
            disabled={field.disabled}
            style={{ width: "80%" }}
            defaultValue={field.currentLabels}
          >
            {field.data && field.data.map((data) => this.getOption(data))}
          </Select>
        );
      }
      if (field && field.type === "selectAssignees") {
        return (
          <Select
            mode="tags"
            disabled={field.disabled}
            defaultValue={field.currentAssignees}
            style={{ width: "80%" }}
          >
            {field.data &&
              field.data.map((data) => this.getAssigneeOption(data))}
          </Select>
        );
      }
      if (field && field.type === "textarea") {
        return (
          <TextArea
            rows={5}
            maxLength={field.max ? +field.max : ""}
            disabled={formDisable || field.disabled}
            onChange={
              field.change
                ? (e) => {
                  field.change(e);
                }
                : () => {}
            }
            autoSize={{minRows: 5, maxRows: 20}}
          />
        );
      }
      if(field && field.type === "textareaWithSugg")
      {
        return (
          <AutoComplete
          style={{width: '400' , height: '100px'}}
          dataSource={ Array.from(field.suggestions)}
        >
           <TextArea
          style={{ height: 100 }}
        />
          </AutoComplete>
        );
      }
      if (field && field.type === "checkbox") {
        return (
          <Switch
            onChange={
              field.change
                ? (e) => {
                  field.change(e);
                }
                : () => {}
            }
            checked={field.checked}
            disabled={field.disabled ? field.disabled : false}
          />
        );
      }
      if (field && field.type === "textWithDrop") {
        return (
          <Input
            type={field.input ? field.input : "text"}
            maxLength={field.max ? +field.max : ""}
            onBlur={field.blur ? field.blur : () => {}}
            onKeyDown={field.onKeyDown ? field.onKeyDown : () => {}}
            autoComplete="off"
            disabled={formDisable || field.disabled}
            min="0"
            onChange={
              field.change
                ? (e) => {
                  field.change(e);
                }
                : () => {}
            }
            addonAfter={getFieldDecorator(field.dropDownKey, {
              initialValue: field.dropDownDefaultValue,
              rules: [
                field.required
                  ? {
                    required: field.required[0],
                    message: field.required[1],
                  }
                  : "",
              ],
            })(
              <Select
                style={{ width: field.width ? field.width : "85px" }}
                onChange={
                  field.dropDownchange
                    ? (e) => {
                      field.dropDownchange(e);
                    }
                    : () => {}
                }
                disabled={formDisable || field.disabled}
              >
                {field.dropDownData.map((val) => (
                  <Option value={val} title={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            )}
          />
        );
      }
      if (field && field.type === "infiniteScrollSearchDropdown") {
        return (
          <Select
            disabled={formDisable || field.disabled}
            showArrow={!formDisable}
            showSearch
            onSearch={(val) => field.onSearch(val)}
            placeholder="Search here"
            className="dropdown-search-field"
            onChange={
              field.change && field.searchKey
                ? (_value, event) => {
                  field.change(event.key, event.title, event);
                }
                : (e) => field.change(e)
            }
            dropdownRender={(menu) => (
              <div>
                <InfiniteScroll
                  height={250}
                  dataLength={field.data.length}
                  hasMore={field.hasNext}
                  next={field.loadMore}
                  loader={<Spin size="large" />}
                >
                  {menu}
                </InfiniteScroll>
              </div>
            )}
          >
            {field.data &&
              field.data.length &&
              field.data.map((data) =>
                field.titleKey || field.valueKey
                  ? this.getOptionReturnObj(data, field)
                  : this.getOption(data)
              )}
          </Select>
        );
      }
      if (field && field.type === "uploadFile") {
        return (
          <>
            <Dragger
              className="form-field-dragger"
              accept={field.fileType}
              onChange={(e) => {
                this.onFileUpload(e, field.change);
              }}
              {...fileUploadProps}
              headers={{
                authorization: getToken(),
                api_key: process.env.REACT_APP_API_KEY,
              }}
              disabled={formDisable}
            >
              {this.state.uploading ? (
                <Spin size="small" />
              ) : field.fileName ? (
                <Input
                  disabled
                  type="text"
                  value={field.fileName}
                  className="dragger-input-tag"
                />
              ) : (
                <Icon type="upload" className="icon-upload-form-fields" />
              )}
            </Dragger>
          </>
        );
      }
      if (field && field.type === "switch") {
        return (
          <Switch
            className="form-field-switch"
            checked={field.data}
            onChange={(value) => field.change(value, field)}
            disabled={field.disabled ? field.disabled : false}
          />
        );
      }
      if (field && field.type === "datetimepicker") {
        return (
          <DatePicker
            showTime={{ format: "HH:mm" }}
            disabledDate={
              field.disabledDate
                ? (e) => field.disabledDate(e, field.key)
                : () => {}
            }
            disabledTime={
              field.disabledTime
                ? (e) => field.disabledTime(e, field.key)
                : () => {}
            }
            onOk={field.onOk ? (e) => field.onOk(e, field.key) : () => {}}
            onChange={
              field.change
                ? (date, dateString) =>
                  field.change(date, dateString, field.key)
                : () => {}
            }
            disabled={field.disabled}
            format="DD-MM-YYYY HH:mm"
            use12Hours={false}
          />
        );
      }
      if (field && field.type === "link") {
        return (
          <div>
            {field.value &&
              field.value.length &&
              field.value.map((item) => (
                <>
                  {/* eslint-disable-next-line */}
                  <a href={`https://${item.trim()}`} target="_blank">
                    {item}
                  </a>
                  &nbsp;&nbsp;&nbsp;
                </>
              ))}
          </div>
        );
      }
      return (
        <Input
          type={field && field.type ? field.type : "text"}
          maxLength={field.max ? +field.max : ""}
          onBlur={field.blur ? field.blur : () => {}}
          onKeyDown={field.onKeyDown ? field.onKeyDown : () => {}}
          autoComplete="off"
          disabled={formDisable || field.disabled}
          min="0"
          onChange={
            field.change
              ? (e) => {
                field.change(e);
              }
              : () => {}
          }
        />
      );
    }
    return (
      <Input
        type={field && field.type ? field.type : "text"}
        maxLength={field.max ? +field.max : ""}
        onBlur={field.blur ? field.blur : () => {}}
        onKeyDown={field.onKeyDown ? field.onKeyDown : () => {}}
        autoComplete="off"
        disabled={formDisable || field.disabled}
        min="0"
        onChange={
          field.change
            ? (e) => {
              field.change(e);
            }
            : () => {}
        }
        defaultValue={field.value ? field.value : ""}
      />
    );
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        {this.props.formFields.map((field) => {
          const rules = [];
          if (field && field.required) {
            rules.push({
              required: field.required[0],
              message: field.required[1],
              type: field.required[2] ? field.required[2] : "",
            });
          }
          if (field && field.pattern) {
            rules.push({
              pattern: field.pattern[0],
              message: field.pattern[1],
            });
          }
          if (field && field.fixType) {
            rules.push({
              type: field.fixType[0],
              message: field.fixType[1],
            });
          }
          if (field && field.validator) {
            rules.push({
              validator: field.validator,
            });
          }

          const decorator = {
            initialValue: field && field.value,
            rules,
          };

          if (field && field.valuePropName) {
            decorator.valuePropName = field.valuePropName;
          }
          if (field) {
            return (
              <Item
                key={field && field.label}
                label={
                  <span>
                    {field && field.label}
                    &nbsp;
                    {field && field.toolTip && field.content && (
                      <Popover
                        placement="rightTop"
                        content={field && field.content}
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    )}
                  </span>
                }
                style={field && field.style}
              >
                {getFieldDecorator(
                  field.key,
                  decorator
                )(this.renderFieldType(field))}
              </Item>
            );
          }
          return undefined;
        })}
      </Form>
    );
  }

  render() {
    return <div className="FormFields">{this.renderForm()}</div>;
  }
}

export default FormFields;
