import React from 'react';
import './SchemacticDeployError.scss';
import { Icon, Modal, Collapse, Button } from 'antd';
import _ from 'lodash';
import { ButtonVariables, DEPLOY_ERROR } from '../../../../constants/variables';

const { Panel } = Collapse;

const constantMap = {
  standardConfigError: 'Config Data',
  inputConfigError: 'Input Data',
  outputConfigError: 'Output Data',
  calculatedConfigError: 'Calculated Data',
  runnerData: 'Runner Data',
  curvesData: 'Curves Data',
  fluidComposition: 'Fluid Composition Data',
  bypassValve: 'Bypass Valve Data',
  input: 'Input Data',
  output: 'Output Data',
  calculated: 'Calculated Data',
  impellerData: 'Impeller Data',
  designGuaranteeData: 'Design/Guarantee Data',
  flowMeasurementData: 'Flow Measurement Data',
  surgeLines: 'Surge Lines Data',
  stream1: 'Stream 1',
  stream2: 'Stream 2',
  designData: 'Design Data',
  performanceData: 'Performance Data',
  performanceCurvesData: 'Performance Curves Data',
};

class SchematicDeployError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: false,
    };
  }

  componentDidUpdate() {}

  showError() {
    this.setState({
      errors: true,
    });
  }

  handleCancel() {
    this.setState({
      errors: false,
    });
  }

  renderErrors() {
    const { validateSchematicInfo } = this.props;

    return (
      <Collapse
        defaultActiveKey={['1']}
        expandIconPosition="left"
        accordion
        bordered={false}
      >
        {validateSchematicInfo &&
          validateSchematicInfo.configErrorInfo &&
          validateSchematicInfo.configErrorInfo.map((component, index) => (
            <Panel
              header={`${component.tagNumber} - ${component.name}`}
              key={`${index + 1}`}
            >
              <ul>
                {component && component.standardConfigError && (
                  <li>
                    {DEPLOY_ERROR.CONFIG}
                    <ul>
                      {component &&
                        component.standardConfigError.map((point) => (
                          <li>
                            {constantMap[point] + DEPLOY_ERROR.HAS_ERRORS}
                          </li>
                        ))}
                    </ul>
                  </li>
                )}
                {component && component.inputConfigError && (
                  <li>
                    {DEPLOY_ERROR.INPUT}
                    <ul>
                      {component &&
                        component.inputConfigError.map((point) => (
                          <li>
                            {constantMap[point] + DEPLOY_ERROR.HAS_ERRORS}
                          </li>
                        ))}
                    </ul>
                  </li>
                )}
                {component && component.outputConfigError && (
                  <li>
                    {DEPLOY_ERROR.OUTPUT}
                    <ul>
                      {component.outputConfigError.map((point) => (
                        <li>{constantMap[point] + DEPLOY_ERROR.HAS_ERRORS}</li>
                      ))}
                    </ul>
                  </li>
                )}
                {component && component.calculatedConfigError && (
                  <li>
                    {DEPLOY_ERROR.CALCULATED}
                    <ul>
                      {component.calculatedConfigError.map((point) => (
                        <li>{constantMap[point] + DEPLOY_ERROR.HAS_ERRORS}</li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </Panel>
          ))}
      </Collapse>
    );
  }

  renderErrorModal = () => (
    <Modal
      visible={this.state.errors}
      title="Schematic Configuration error"
      onOk={() => this.handleCancel()}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={() => this.handleCancel()}>
          {ButtonVariables.OK}
        </Button>,
      ]}
    >
      {this.renderErrors()}
    </Modal>
  );

  render() {
    const { validateSchematicInfo } = this.props;
    return (
      <div className="SchematicDeployError">
        {validateSchematicInfo &&
          !_.isEmpty(validateSchematicInfo.configErrorInfo) && (
            <div
              className="error-tag"
              onClick={() => {
                this.showError();
              }}
            >
              <Icon type="exclamation-circle" />
              {' Schematic Configuration Errors'}
            </div>
          )}
        {this.renderErrorModal()}
      </div>
    );
  }
}

export default SchematicDeployError;
