import { Icon, Input, Switch } from "antd";
import _ from "lodash";
import React from "react";
import { ic_edit } from "../../assets/ic_edit";
import FormFields from "../../common/FormFields/FormFields";
import {
  validateNegativeNumber,
  validatePositiveFloat,
} from "../../common/methods";
import { ERROR_MESSAGES } from "../../constants/messages";
import { newValidateNumber } from "../../libs/validator";
import SelectDropdown from "../Clients/ManageTags/SelectDropdown";
import { elementsGaurd } from "../../gaurds";
import { ElementPermissions } from "../../permissions";

export const FILE_STATUS = {
  InProgress: "In Progress",
  Completed: "Completed",
  Failed: "Failed",
};

export const ADD_ALGO_FIELDS = (config) => [
  {
    label: 'Client',
    key: 'clientId',
    type: 'dropdown',
    valueKey: '_id',
    titleKey: 'name',
    searchKey: 'name',
    change: config.changeClient,
    data: config.clients ? config.clients : [],
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    disabled: config.editMode ? true : config.disabled,
  },
  {
    label: 'Facility',
    key: 'facilityId',
    type: 'dropdown',
    valueKey: '_id',
    titleKey: 'name',
    searchKey: 'name',
    change: config.changeFacility,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    data: config.facilities ? config.facilities : [],
    endMsg: config.isClientSelected ? ERROR_MESSAGES.SELECT_CLIENT : 'No Data',
  },
  {
    label: 'Equipment',
    key: 'equipmentId',
    type: 'dropdown',
    valueKey: '_id',
    titleKey: 'name',
    searchKey: 'name',
    change: config.changeEquipment,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    data: config.equipmentsList ? config.equipmentsList : [],
    endMsg: config.isClientSelected ? ERROR_MESSAGES.SELECT_CLIENT : 'No Data',
  },
  {
    label: 'Model Name',
    key: 'name',
    change: config.changeName,
    disabled: config.disabled,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
  },
  {
    label: 'Algo type',
    key: 'algoType',
    type: 'dropdown',
    change: config.changeAlgoType,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    data: config.algoList,
    disabled: config.disabled,
  },
  {
    label: 'Code version',
    key: 'codeVersion',
    type: 'dropdown',
    change: config.changeCodeVersion,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    data: config.algoType ? config.version[config.algoType] : [],
    disabled: config.disabled,
    endMsg: config.algoType
      ? 'No versions available'
      : 'Please select algo type',
  },
  {
    label: 'File path',
    key: 'imageId',
    max: 256,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
    change: config.changeFile,
    disabled: config.disabled,
  },
  config.editMode && {
    label: 'Model ID',
    key: 'modelId',
    type: 'label',
    disabled: true,
  },
  config.editMode && {
    label: 'Running Status Check',
    key: 'isRunStatus',
    type: 'checkbox',
    change: config.changeRunStatus,
    disabled: config.disabled,
    checked: config.checked,
  },
  config.editMode && {
    label: 'Model Explainability',
    key: 'isInfo',
    type: 'checkbox',
    change: config.flipIsInfo,
    disabled: config.disabled,
    checked: config.isInfo,
  },
];

export const ML_TABLE = (config) => [
  {
    title: "Model Name",
    dataIndex: "name",
  },
  {
    title: "Algo Type",
    dataIndex: "algoType",
  },
  {
    title: "Algo Version",
    dataIndex: "codeVersion",
  },
  {
    title: "Facility Name",
    dataIndex: "type",
    render: (_value, _row, _col, _isDisable, item) => (
      <span>{item.facilityId ? item.facilityId.name : ""}</span>
    ),
  },
  {
    title: "File Status",
    dataIndex: "fileStatus",
    render: (value) => <span>{FILE_STATUS[value]}</span>,
  },
  {
    title: 'Status  ',
    dataIndex: 'isDeployed',
    render: (value, row, _col, _isDisable, item) => (
      <>
        {elementsGaurd(
          <Switch
            className="ml-action-switch"
            checked={value}
            disabled={
              item.fileStatus !== FILE_STATUS.Completed
                ? true
                : !!_.isEmpty(item.inputTags)
            }
            onChange={() => config.delete(item._id, row)}
          />,
          ElementPermissions.EDIT_ML_ALGO,
          {
            type: "facility",
            dataRef: {
              _id: item.facilityId._id,
            },
          }
        )}
        <span className="ml-status-change">
          {item.isDeployed ? "Deployed" : "Undeployed"}
        </span>
      </>
    ),
  },
  {
    title: "Action",
    dataIndex: "type",
    render: (_value, _row, _col, _isDisable, item) => (
      <>
        {elementsGaurd(
          <button
            className="view-mode-btn"
            onClick={() => config.rowClick(item, "view")}
          >
            <Icon type="eye" />
          </button>,
          ElementPermissions.VIEW_ML_ALGO,
          {
            type: "facility",
            dataRef: {
              _id: item.facilityId._id,
            },
          }
        )}
        {!item.isDeployed
          ? (item.fileStatus === FILE_STATUS.Completed ||
              item.fileStatus === FILE_STATUS.Failed) &&
            elementsGaurd(
              <button
                className="view-mode-btn"
                onClick={() => config.rowClick(item, "edit")}
              >
                <img src={ic_edit} alt="ic_edit" />
              </button>,
              ElementPermissions.EDIT_ML_ALGO,
              {
                type: "facility",
                dataRef: {
                  _id: item.facilityId._id,
                },
              }
            )
          : null}
      </>
    ),
  },
];

/**
 * Input Tag
 */
export const ML_DETAIL_TABLE = (config) => [
  {
    title: "Variable Tag",
    dataIndex: "piTag",
  },
  {
    title: "Name",
    dataIndex: "piVariableName",
  },
  !config.disabled && {
    title: "Action",
    dataIndex: "none",
    render: (_value, row, _col, _isDisable, item) => (
      <div onClick={() => config.delete(item._id, row)}>
        <Icon className="pointer-cursor" type="delete" />
      </div>
    ),
  },
];

export const piTagDropdown = (config) => [
  {
    label: " ",
    key: "piTag",
    type: "dropdown",
    valueKey: "_id",
    titleKey: "piTag",
    searchKey: "piTag",
    change: config.change,
    data: config.piTagList,
    endMsg: config.piTagList.length > 0 ? "Search here..." : " ",
    ret: "obj",
    resetField: config.resetField,
    disabled: config.disabled,
    // endMsg: 'No data',
    width: "290px",
  },
];

export const ADD_ML_ROW = (config) => [
  {
    dataIndex: "piTag",
    render: () => (
      <div>
        <FormFields
          formFields={piTagDropdown({
            piTagList: _.cloneDeep(config.piTagList),
            change: config.piTagSelected,
            loadMore: config.loadMorePiTag,
            hasNext: config.hasNext,
            onSearch: config.onSearch,
            isLoading: config.isLoading,
            resetField: config.resetField,
            disabled: config.disabled,
          })}
          formDisable={config.formDisable}
          form={config.form}
        />
      </div>
    ),
  },
  {
    dataIndex: "name",

    render: () => (
      <div>
        {config.selectedPiTag.piVariableName
          ? config.selectedPiTag.piVariableName
          : ""}
      </div>
    ),
  },
  {
    title: "Add",
    dataIndex: "piTag",
    render: () => (
      <div onClick={config.addInputTag}>
        <button
          disabled={_.isEmpty(config.selectedPiTag)}
          className="btn-default btn-small"
        >
          Add
        </button>
      </div>
    ),
  },
];

export const OUTPUT_ADD_ALGO = (config) => [
  {
    title: config.algoType === "autoencoder" ? "Loss Tag" : "Calculated Tag",
    dataIndex: "piTag",
  },
  {
    title:
      config.algoType === "autoencoder"
        ? "Loss Variable Name"
        : "Calculated Variable Name",
    dataIndex: "variableName",
    render: (value, _row, _col, _isDisable, _item) => (
      <Input
        disabled={config.disabled}
        name="variableName"
        value={value}
        className={config.isError ? (value && value.trim() ? '' : 'error') : ''}
        onChange={(e) => config.outputChange(e.target.value, 'variableName')}
        style={{width: "100%", minWidth:'100px'}}
      />
    ),
  },
  config.algoType === "autoencoder" && {
    title: "IQR Multiplier",
    dataIndex: "multiplier",
    render: (value, _row, _col, _isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="multiplier"
          disabled={config.disabled}
          value={value}
          autoComplete="off"
          className={
            config.isError
              ? !_.isEmpty(value) && validatePositiveFloat(value)
                ? ""
                : "error"
              : ""
          }
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          onChange={(e) => config.outputChange(e.target.value, "multiplier")}
        />
      );
    },
  },
  config.algoType === "autoencoder" && {
    title: "Adder",
    dataIndex: "adder",
    render: (value, _row, _col, _isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="adder"
          disabled={config.disabled}
          value={value}
          defaultValue={0}
          autoComplete="off"
          className={
            config.isError
              ? !_.isEmpty(value) && !validateNegativeNumber(value)
                ? ""
                : "error"
              : ""
          }
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          onChange={(e) => config.outputChange(e.target.value, "adder")}
        />
      );
    },
  },
  config.algoType !== "autoencoder" && {
    title: "Variable Property",
    dataIndex: "variableProperty",
    render: (value, _row, _col, _isDisable, item) => {
      const  metricProperty = metricProperties ? Object.keys(metricProperties) : [];
      return (
        <>
          <SelectDropdown
            isDisable={config.disabled}
            change={(val) => config.outputChange(val, "variableProperty")}
            data={metricProperty}
            name="variableProperty"
            value={value}
            id={item ? item._id : null}
            className={
              config.isError ? (value ? "pi-tag-not-error" : "error") : ""
            }
            width="290px"
          />{" "}
        </>
      );
    },
  },
  config.algoType !== "autoencoder" && {
    title: "Units",
    dataIndex: "units",
    render: (value, _row, _col, _isDisable, item) => {
      const unitList =
        item.variableProperty && metricProperties
          ? metricProperties[item.variableProperty]?.units
          : [];
      return (
        <>
          <SelectDropdown
            isDisable={config.disabled}
            change={(val) => config.outputChange(val, "units")}
            data={unitList}
            name="units"
            value={value}
            id={item ? item._id : null}
            className={
              config.isError ? (value ? "pi-tag-not-error" : "error") : ""
            }
            width="140px"
          />{" "}
        </>
      );
    },
  },
  config.algoType !== "autoencoder" && {
    title: "Min Bound",
    dataIndex: "minThreshold",
    render: (value, _row, _col, _isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="minThreshold"
          value={value}
          autoComplete="off"
          type="number"
          disabled={config.disabled}
          className={
            config.isError
              ? parseFloat(value) < parseFloat(item.maxThreshold)
                ? "pi-tag-not-error"
                : "error ml-error"
              : ""
          }
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          onChange={(e) => config.outputChange(e)}
        />
      );
    },
  },
  config.algoType !== "autoencoder" && {
    title: "Max Bound",
    dataIndex: "maxThreshold",
    render: (value, _row, _col, _isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="maxThreshold"
          type="number"
          value={value}
          disabled={config.disabled}
          autoComplete="off"
          className={
            config.isError
              ? parseFloat(value) > parseFloat(item.minThreshold)
                ? "pi-tag-not-error"
                : "error ml-error"
              : ""
          }
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          onChange={(e) => config.outputChange(e)}
        />
      );
    },
  },
];

export const calcFilterTag = (config) => [
  {
    title: 'Filter',
    dataIndex: 'isFilter',
    render: (value, row, _col, _isDisable, item) => (
      <>
        {
          <div style={{"padding-right": "180px"}}>
          <Switch
            className="ml-action-switch"
            checked={config.isFilter}
            onChange={(e) => config.flipFilter(e)}
            disabled={config.disabled}
          />
          </div>
        }
      </>
    ),
  },
  {
    title: 'Wavelet Type *',
    dataIndex: 'waveletType',
    render: (value, _row, _col, _isDisable, item) => {
      value = config?.waveletType || '';
      return (
        <>
          <SelectDropdown
            isDisable={!config.isFilter || config.disabled}
            change={(val) => config.outputChange(val, 'waveletType')}
            data={config.waveletTypeOptions}
            name="variableProperty"
            value={config.waveletType}
            width="250px"
            className={
              config.isError
                ? value !== ''
                  ? 'pi-tag-not-error'
                  : 'error ml-error'
                : ''
            }
          />{' '}
        </>
      );
    },
  },
  {
    title: 'Level *',
    dataIndex: 'level',
    render: (value, _row, _col, _isDisable, item) => {
      value = config?.level || '';
      return (
        <Input
          type="number"
          name="level"
          value={value}
          disabled={!config.isFilter || config.disabled}
          autoComplete="off"
          className={
            config.isError
              ? parseFloat(value) > 0 && parseFloat(value) < 6
                ? 'pi-tag-not-error'
                : 'error ml-error'
              : ''
          }
          onChange={(e) => config.levelChange(e)}
        />
      );
    },
  },
];

/** ML-Tag */
export const OUTPUT_PI_TAG = (config) => [
  {
    label: " Variable tag",
    key: "outputTag",
    type: "dropdown",
    valueKey: "_id",
    titleKey: "piTag",
    searchKey: "piTag",
    change: config.change,
    data: config.piTagList ? config.piTagList : [],
    ret: "obj",
    resetField: config.resetField,
    disabled: config.disabled,
  },
  {
    label: "Variable name",
    key: "piTagName",
    disabled: true,
  },
];

export const PREDICTED_TAG = (config) => [
  {
    label: 'Predicted Tag',
    key: 'predictedTag',
    disabled: true,
  },
  {
    label: 'Predicted Variable Name',
    type: 'text',
    key: 'predictedVariableName',
    change: config.mlVarName,
    disabled: config.disabled,
    required: [true, ERROR_MESSAGES.REQUIRED_FIELD],
  },
  {
    label: 'Constant To Add',
    type: 'number',
    key: 'constantToAdd',
    change: config.handleConstantChange,
    disabled: config.disabled,
  },
];

export const WAVELET_TYPE = ['sym9', 'sym10', 'sym11', 'db5', 'db6', 'db7'];

export const metricProperties = {
  Percentage: {
    units: ['%'],
  },
  Difference: {
    units: ['Dimensionless'],
  },
};
