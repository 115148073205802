import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import {
  filterEquipmentData,
  renderHeader,
  Validate,
} from './designDataFunctions';
import { designDataHeader } from './designDataFunctions';
import _ from 'lodash';
import {
  CapacityControlVariables,
  OilDataVariable,
  FieldMapper,
  inputSections,
  payloadMapper,
  ScrewCompressorDesignVariable,
  screenConstant,
} from './designDataFieldConstants';
import { Spin } from 'antd';
import { RecipCompDataMessage } from '../../../../../../constants/messages';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  clearUpdateState,
  updateDesignData,
} from '../../../../../../redux/actions/screwCompressor.action';

const ScrewCompressorConfig = (props) => {
  //Connecting component to redux
  const data = useSelector((state) => state.DataUnitReducer.apiState.unitData);
  const screwCompReducerData = useSelector(
    (state) => state.screwCompressorReducer.configData
  );
  const updateScrewCompData = useSelector(
    (state) => state.screwCompressorReducer.updateDesignData
  );
  const dispatch = useDispatch();

  // State definition
  const [unitData, setUnitData] = useState([]);
  const [componentId, setComponentId] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [saveFlag, setSaveFlag] = useState(false);
  const [editTableRows, setEditableRows] = useState({
    equipmentData: [],
    oilData: [],
    capacityControlData: [],
  });
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSchematicEditable] = useState(props.isSchematicEditable);
  const [dirtyUnits, setDirtyUnits] = useState([]);
  const [dirtyValues, setDirtyValues] = useState([]);

  // component did mount and component did update
  useEffect(() => {
    FieldMapper(inputSections).then((arr) => setInitialState(arr));
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('form-edit', () => {
      setShowSubmitButton(true);
    });
    window.addEventListener('form-edit-false', (e) => {
      setShowSubmitButton(false);
      setEditableRows({
        equipmentData: [],
        oilData: [],
        capacityControlData: [],
      });
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (props.activeKey !== '1') {
      copyData.length ? setDisplayData(copyData) : setDisplayData(InitialState);
    }
  }, [props]); // eslint-disable-line

  useEffect(() => {
    if (data && data.items) {
      setUnitData(data.items);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    setDisplayData(InitialState);
  }, [InitialState]); // eslint-disable-line

  useEffect(() => {
    const { componentID } = props;
    if (componentId !== componentID) {
      setCopyData([]);
    }
    setComponentId(componentID);
  }, [props]); // eslint-disable-line

  useEffect(() => {
    if (updateScrewCompData.isSuccess) {
      setShowSubmitButton(false);
      setLoading(false);
      setEditableRows({
        equipmentData: [],
        oilData: [],
        capacityControlData: [],
      });
      setSaveFlag(false);
      Notification.show(Types.Success, RecipCompDataMessage.SUCCESS);
      dispatch(clearUpdateState());
    } else if (updateScrewCompData.isLoading) {
      setLoading(true);
    } else if (updateScrewCompData.isError) {
      setLoading(false);
    }
  }, [updateScrewCompData]); // eslint-disable-line

  const upcomingChanges = (prevState, currState) => {
    for (let i = 0; i < prevState.length; i++) {
      for (let j = 0; j < currState.length; j++) {
        if (currState[j].name === prevState[i].name) {
          if (currState[j].unit !== prevState[i].unit)
            prevState[i].unit = currState[j].unit;
          if (currState[j].design !== prevState[i].design)
            prevState[i].design = currState[j].design;
        }
      }
    }
  };

  const getPrefill = (prefill, designData) => {
    let counter = 0;
    if (
      designData.equipmentData &&
      designData.oilData &&
      designData.capacityControlData
    ) {
      const designDataList = [
        designData.equipmentData,
        designData.oilData,
        designData.capacityControlData,
      ];
      designDataList.forEach((value) => {
        for (const data in value) {
          if (
            ScrewCompressorDesignVariable[data] ||
            OilDataVariable[data] ||
            CapacityControlVariables[data]
          ) {
            const prefillValue = {};
            prefillValue.name = screenConstant[counter]['variableName'][data];
            prefillValue.design =
              value[data].design === 0
                ? 0
                : value[data].design
                ? value[data].design
                : value[data].design === false
                ? false
                : null;
            prefillValue.unit = value[data].unit ? value[data].unit : null;
            prefill.push(prefillValue);
          }
        }
        counter = counter + 1;
      });
    }
  };

  useEffect(() => {
    const prefill = [];
    let designData = {};
    if (
      updateScrewCompData.data &&
      updateScrewCompData.data.componentId &&
      updateScrewCompData.data.componentId === componentId &&
      updateScrewCompData.data.designData
    ) {
      designData = updateScrewCompData.data.designData;
      getPrefill(prefill, designData);
      upcomingChanges(displayData, prefill);
      disableEditForm();
      setDisplayData(displayData);
      setCopyData(displayData);
    } else if (
      screwCompReducerData &&
      screwCompReducerData.componentId &&
      screwCompReducerData.componentId === componentId
    ) {
      if (
        screwCompReducerData &&
        screwCompReducerData.designData &&
        screwCompReducerData.componentId === componentId
      ) {
        designData = screwCompReducerData.designData;
      }
      getPrefill(prefill, designData);
      if (prefill.length !== 0) {
        upcomingChanges(displayData, prefill);
        setDisplayData(displayData);
        setCopyData(displayData);
      }
    }
  }, [props.configData.designData,screwCompReducerData.isSuccess,updateScrewCompData.isSuccess]); // eslint-disable-line

  // Component functions
  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  const handleChange = (val, variableName, type) => {
    enableEditForm();
    const tempDisplayData = displayData.map((item) => {
      if (item.name === variableName && type === 'number') {
        return { name: variableName, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === variableName && type === 'select') {
        return { name: variableName, unit: item.unit, design: val };
      } else if (item.name === variableName && type === 'unit') {
        return { name: variableName, unit: val, design: item.design };
      } else if (item.name === variableName && type === 'text') {
        return { name: variableName, unit: null, design: val };
      }
      return item;
    });

    setDisplayData(_.cloneDeep(tempDisplayData));

    enableEditForm();
  };

  const onEdit = (row, tableName) => {
    let editTable = editTableRows;
    editTable[tableName].push(row);
    editTable[tableName].sort();
    setEditableRows(editTable);
    enableEditForm();
    setShowSubmitButton(true);
  };

  const onUndo = (row, tableName, itemName) => {
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    if (copyData.length) {
      displayData.map((data, index) => {
        if (data.name === itemName) {
          displayData[index] = copyData[index];
        }
        return null;
      });
    } else {
      displayData.map((data, index) => {
        if (data.name === itemName) {
          displayData[index] = InitialState[index];
        }
        return null;
      });
    }
    setEditableRows(editTable);
    enableEditForm();
  };

  const onSubmit = async () => {
    setSaveFlag(true);
    const { location } = props;
    const { state } = location;

    const isInvalid = await Validate(
      displayData,
      setDirtyUnits,
      setDirtyValues
    );

    if (!isInvalid) {
      const payload = {};
      payload['equipmentData'] = {};
      payload['oilData'] = {};
      payload['capacityControlData'] = {};
      let index = 0;
      for await (let object of payloadMapper) {
        for (const key in object) {
          for await (let data of displayData) {
            if (object[key] === data.name) {
              if (index === 0) {
                payload['equipmentData'][key] = data;
              } else if (index === 1) {
                payload['oilData'][key] = data;
              } else if (index === 2) {
                payload['capacityControlData'][key] = data;
              }
            }
          }
        }
        index = index + 1;
      }

      dispatch(updateDesignData(state.componentData.info._id, payload));
    } else {
      Notification.show(Types.Error, RecipCompDataMessage.DIRTY_DATA);
    }
  };

  const onReset = () => {
    setSaveFlag(false);
    setShowSubmitButton(false);
    setEditableRows({
      equipmentData: [],
      oilData: [],
      capacityControlData: [],
    });
    copyData.length ? setDisplayData(copyData) : setDisplayData(InitialState);
    disableEditForm();
  };

  // Render component
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className="DesignDataMotor">
          {screenConstant.map((screen, index) => {
            return (
              <CustomTable
                showHeader={true}
                columns={renderHeader(
                  designDataHeader,
                  unitData,
                  onEdit,
                  onUndo,
                  handleChange,
                  screen.heading,
                  screen.stateName,
                  saveFlag,
                  isSchematicEditable,
                  dirtyValues,
                  dirtyUnits
                )}
                data={filterEquipmentData(displayData, screen.variableName)}
                editableRow={editTableRows[screen.stateName]}
                key={index}
              ></CustomTable>
            );
          })}
          {showSubmitButton && (
            <div className="config-bottom-buttons">
              <button className="btn-default btn-white" onClick={onReset}>
                {'RESET'}
              </button>
              <button className="btn-default" onClick={onSubmit}>
                {'SAVE'}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScrewCompressorConfig;
