import Type from "../Types/mlAlgorithm.types";
import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

const facilityId = "facilityId";

const createMlModal = ({ clientId, facilityId, payload }) => (dispatch) => {
  dispatch({ type: Type.CREATE_ML_MODEL_PROGRESS });
  POSTRequest(
    `${endPoints.client}/${clientId}/${endPoints.facility}/${facilityId}/${endPoints.mlModal}`,
    payload
  )
    .then((res) =>
      dispatch({ type: Type.CREATE_ML_MODEL_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Type.CREATE_ML_MODEL_FAILURE, payload: err })
    );
};

const getMlList = (payload) => (dispatch) => {
  dispatch({ type: Type.GET_ML_MODEL_PROGRESS });
  GETRequest(`${endPoints.mlModal}`, payload)
    .then((res) =>
      dispatch({ type: Type.GET_ML_MODEL_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Type.GET_ML_MODEL_FAILURE, payload: err })
    );
};

const deployMlModal = ({id,payload}) => (dispatch) => {
  
  dispatch({ type: Type.DEPLOY_ML_MODEL_PROGRESS });
  PUTRequest(`${endPoints.mlModalDeploy}/${id}`,payload)
  .then((res) =>
  dispatch({ type: Type.DEPLOY_ML_MODEL_SUCCESS, payload: res.body }),
    )
    .catch((err) =>
      dispatch({ type: Type.DEPLOY_ML_MODEL_FAILURE, payload: err })
    );
};

const updateMlModal = ({ id, payload }) => (dispatch) => {
  dispatch({ type: Type.UPDATE_ML_MODEL_PROGRESS });
  PUTRequest(`${endPoints.mlModal}/${id}`, payload)
    .then((res) =>
      dispatch({ type: Type.UPDATE_ML_MODEL_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Type.UPDATE_ML_MODEL_FAILURE, payload: err })
    );
};

function getPiTagList(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.GET_ALL_PI_TAG_PROGRESS });
    GETRequest(`${endPoints.piTags}?${facilityId}=${id}`, payload)
      .then((res) =>
        dispatch({ type: Type.GET_ALL_PI_TAG_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_ALL_PI_TAG_FAILURE, payload: err })
      );
  };
}

function getPiTagListReset() {
  return function (dispatch) {
    dispatch({ type: Type.GET_ALL_PI_TAG_RESET });
  };
}

function getModelVersion() {
  return function (dispatch) {
    dispatch({ type: Type.GET_ML_VERSION_PROGRESS });
    GETRequest(endPoints.mlModelVersion)
      .then((res) =>
        dispatch({ type: Type.GET_ML_VERSION_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_ML_VERSION_ERROR, payload: err })
      );
  };
}

function resetMlState() {
  return function (dispatch) {
    dispatch({ type: Type.RESET_ML_ALGORITHM_REDUCER });
  };
}

function clearMLModalListState() {
  return function (dispatch) {
    dispatch({ type: Type.GET_ML_MODEL_RESET });
  };
}

export function deployMlModalBulk(payload) {
  return function(dispatch) {
    dispatch({ type: Type.DEPLOY_ML_MODEL_BULK_PROGRESS });
    PUTRequest(`${endPoints.mlModalDeployBulk}`, payload)
      .then((res) =>
        dispatch({ type: Type.DEPLOY_ML_MODEL_BULK_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.DEPLOY_ML_MODEL_BULK_FAILURE, payload: err })
      );
  }
};

function resetBulkDeployState() {
  return function (dispatch) {
    dispatch({ type: Type.DEPLOY_ML_MODEL_BULK_RESET });
  };
}

export {
  createMlModal,
  updateMlModal,
  deployMlModal,
  getMlList,
  getPiTagList,
  getPiTagListReset,
  getModelVersion,
  resetMlState,
  clearMLModalListState,
  resetBulkDeployState
};
