import React from 'react';
import './UserManagement.header.scss';
import '../../../constants/global.scss';
import { withRouter } from 'react-router-dom';
import { MaskStyle } from '../../../constants/react-style';
import ListActionHeader from '../../../common/ListActionHeader/ListActionHeader';
import { ListHeaderConfig, filters } from './UserManagementHeader.constants';
import * as Routes from '../../../constants/routes';
import { UserManagementVariable } from '../../../constants/variables';

class UserManagementHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterActive: false,
      isSortActive: false,
      order: null,
    };
  }

  addUser = () => {
    this.props.history.push(Routes.addUser);
  };

  onSearch = (search) => {
    this.props.onSearch(search);
  };

  filterdata = (filter) => {
    if (filter === 'Reset') {
      this.props.onFilter();
      this.setState({
        isFilterActive: false,
      });
    } else {
      this.setState({
        isFilterActive: true,
      });
      this.props.onFilter(filters[filter]);
    }
  };

  order = (value) => {
    this.setState({ order: value });
    this.props.order(value);
  };

  render() {
    const { isSortActive } = this.state;
    return (
      <div className="UserManagementHeader" style={MaskStyle}>
        <div className="title">{UserManagementVariable.TITLE}</div>
        <div className="searchtab">
          <ListActionHeader
            config={ListHeaderConfig({
              search: this.onSearch,
              filterdata: this.filterdata,
              isFilterActive: this.state.isFilterActive,
              order: this.order,
              addUser: this.addUser,
              sortOrder: this.state.order,
              isSortActive: isSortActive
            })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(UserManagementHeader);
