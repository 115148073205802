import React from "react";
import { splash_screen_logo } from "./assets/splash_screen_logo";

export default function AppLoader(props) {
  return (
    <div
      style={{
        textAlign: "center",
        height: "100%",
        width: "100%",
        marginTop: "0.1%",
        position: "absolute",
        zIndex: 9999999,
        background: "white",
        paddingTop: "18%",
      }}
    >
      <img alt="splash_screen" src={splash_screen_logo} height="250px" />
    </div>
  );
}
