import React from "react";
import { Input } from "antd";
import _ from "lodash";

export const scaleColumn = (config) => [
  {
    title: "PI TAG",
    dataIndex: "name",
  },
  {
    title: "MIN",
    dataIndex: "minScale",
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={false}
            name="minScale"
            value={value}
            onChange={(e) => {
              config.change(e, item ? item.index : null);
            }}
          />
        </div>
      );
    },
  },
  {
    title: "MAX",
    dataIndex: "maxScale",
    render: (value, _row, _col, isDisable, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <div>
          <Input
            disabled={false}
            name="maxScale"
            value={value}
            onChange={(e) => {
              config.change(e, item ? item.index : null);
            }}
          />
        </div>
      );
    },
  },
];
