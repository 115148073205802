import { Empty, Pagination } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../common/CustomTable/CustomTable';
import { handleSort } from '../../../common/methods';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  NO_INTERNET,
  UserManagementMessages,
} from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import {
  changeUserStatus,
  clearUserData,
  invitationLink,
  userFetch,
} from '../../../redux/actions/UserManagement.action';
import { UserRole } from '../../AppScreen/AppScreen';
import { filterType, tableheader } from './UserManagementBody.Constants';
import './UserManagementBody.scss';
import { Typography, Skeleton } from '@mui/material';
class UserManagementBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      skip: 0,
      pageSize: 10,
      sort: 'firstName',
      visible: false,
      changeStatusValue: '',
      changeStatusRow: '',
      searchedData: [],
      masterData: [],
      filteredData: [],
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isChangeStatusSuccess,
      isInvitationLinkSuccess,
      isChangeStatusError,
      isInvitationLinkError,
      isResetPasswordSuccess,
      isUserFetchSuccess,
      userData,
    } = this.props.userManagementState;
    const { currentPage, masterData } = this.state;
    const { keyword, order, filter, clearUserData } = this.props;

    if (prevState.currentPage !== currentPage) {
      this.pagination();
    }

    if (prevProps.keyword !== keyword) {
      this.commonSorting();
    }

    if (prevProps.order !== order) {
      if (order === '-1') {
        this.setState(
          {
            masterData: masterData.reverse(),
          },
          () => this.commonSorting()
        );
      } else if (prevProps.order === '-1' && order === '1') {
        this.setState(
          {
            masterData: masterData.reverse(),
          },
          () => this.commonSorting()
        );
      }
    }

    if (prevProps.filter !== filter) {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.commonSorting();
        }
      );
    }

    if (isChangeStatusSuccess) {
      clearUserData();
      this.props.userFetch({ keyword, filter, order });
      Notification.show(
        Types.Success,
        UserManagementMessages.STATUS_CHANGE_SUCCESS
      );
    }
    if (isChangeStatusError) {
      clearUserData();
      Notification.show(
        Types.Error,
        UserManagementMessages.STATUS_CHANGE_ERROR
      );
    }

    if (isInvitationLinkSuccess) {
      clearUserData();
      Notification.show(Types.Success, UserManagementMessages.INVITATION_SENT);
    }
    if (isInvitationLinkError) {
      clearUserData();
      Notification.show(
        Types.Success,
        UserManagementMessages.INVITATION_NOT_SENT
      );
    }
    if (isResetPasswordSuccess) {
      clearUserData();
      if (this.handleNetwork()) {
        this.getUserList();
      }
    }

    if (
      isUserFetchSuccess &&
      isUserFetchSuccess !== prevProps.userManagementState.isUserFetchSuccess
    ) {
      this.setState(
        {
          masterData: handleSort(_.cloneDeep(userData), 'firstName'),
        },
        () => {
          this.pagination();
        }
      );
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  getUserList() {
    if (this.handleNetwork()) {
      this.props.userFetch(this.props.filter);
    }
  }

  onPagination = (currentPage) => {
    this.setState({
      currentPage,
    });
  };

  editUser = (editUserRow) => {
    this.props.history.push(Routes.addUser, {
      data: editUserRow,
      mode: 'edit',
    });
  };

  changeStatus = (value, item) => {
    this.setState({
      visible: true,
      changeStatusValue: value,
      changeStatusRow: item,
    });
  };

  renderModal() {
    const { visible, changeStatusValue } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title="Confirmation"
          message={
            changeStatusValue
              ? UserManagementMessages.ACTIVATE_USER
              : UserManagementMessages.DEAVTIVATE_USER
          }
        />
      );
    }
    return null;
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { changeStatusValue, changeStatusRow } = this.state;
    const payload = {
      isActive: changeStatusValue,
    };
    if (this.handleNetwork()) {
      this.props.changeUserStatus(changeStatusRow._id, payload);
    }
    this.setState({
      visible: false,
    });
  };

  sendInvitation = (id) => {
    if (this.handleNetwork()) {
      this.props.invitationLink({ email: id['email'] });
    }
  };

  pagination = () => {
    const { pageSize, currentPage, masterData, filteredData } = this.state;
    const { keyword, filter } = this.props;
    const tempData = [];
    for (
      let i = (currentPage - 1) * pageSize;
      i < currentPage * pageSize;
      i++
    ) {
      if (keyword || filter) {
        filteredData[i] && tempData.push(filteredData[i]);
      } else {
        masterData[i] && tempData.push(masterData[i]);
      }
    }
    this.setState({
      data: _.cloneDeep(tempData),
    });
  };

  commonSorting = () => {
    const { pageSize, masterData } = this.state;
    const { keyword, filter } = this.props;
    const tempData = [];

    let searchedData = _.cloneDeep(masterData);
    if (keyword.length) {
      searchedData = _.filter(masterData, (element) => {
        const item = new RegExp(`^${keyword}`, 'i');
        const fullName = `${element.firstName} ${element.lastName}`;
        if (
          item.test(element.firstName) ||
          item.test(element.lastName) ||
          item.test(element.clientName) ||
          item.test(element.email) ||
          item.test(fullName)
        ) {
          return item;
        }
      });
    }

    let filteredData = [];
    if (filter) {
      searchedData.forEach((element) => {
        if (filter === filterType[0] && element.isActive) {
          filteredData.push(element);
        }
        if (filter === filterType[1] && !element.isActive) {
          filteredData.push(element);
        }
      });
    } else {
      filteredData = _.cloneDeep(searchedData);
    }

    for (let i = 0; i < pageSize; i++) {
      filteredData[i] && tempData.push(filteredData[i]);
    }
    this.setState({
      data: tempData,
      filteredData,
      currentPage: 1,
    });
  };

  render() {
    const { loading, userData } = this.props.userManagementState;
    const { userId } = this.props.loginState.apiState;
    const { currentPage, pageSize, masterData, filteredData } = this.state;
    const { keyword, filter } = this.props;
    if (loading) {
      const variants = Array.from({ length: 5 }, () => 'h5');
      return (
        <div className="UserManagementBody">
          <div className="content-div text-center">
            {variants.map((variant, index) => (
              <Typography component="div" key={index} variant={variant}>
                <Skeleton />
              </Typography>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className="UserManagementBody">
        <div className="content-div text-center">
          {!userData && !loading ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div>
              <CustomTable
                showHeader
                columns={tableheader({
                  editUser: this.editUser,
                  changeStatus: this.changeStatus,
                  sendInvitation: this.sendInvitation,
                  UserRole,
                  userId,
                })}
                data={userData}
              />
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={
                  keyword || filter
                    ? filteredData.length
                    : masterData && masterData.length
                }
                onChange={(page) => this.onPagination(page)}
                hideOnSinglePage
              />
            </div>
          )}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  userManagementState: state.userManagementReducer,
  loginState: state.LoginReducer,
});
const mapDispatchToProps = (dispatch) => ({
  userFetch: (payload) => dispatch(userFetch(payload)),
  changeUserStatus: (id, payload) => dispatch(changeUserStatus(id, payload)),
  invitationLink: (id) => dispatch(invitationLink(id)),
  clearUserData: () => dispatch(clearUserData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserManagementBody));
