import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  MotorDesignDataMessages,
  NO_INTERNET,
  GeneratorParameterDataMessages,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import { saveDesignData } from '../../../../../../redux/actions/synchronousGenerator.action';
import Loader from '../../../../../../common/Loader/Loader';
import {
  DesignDataShow,
  initialData,
  KeyChange,
  speedTypeTitle,
  designDataVariables,
} from './DesignDataSynchronousGenerator.Constants';
import './DesignDataSynchronousGenerator.scss';

class DesignDataSynchronousGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  setData() {
    const {
      synchronousGeneratorConfigData: { designData },
    } = this.props.synchronousGeneratorReducer;
    const tempDisplayData = [
      // {
      //   name: designDataVariables.manufacturer,
      //   design: designData.manufacturer,
      // },
      // {
      //   name: designDataVariables.modelNumber,
      //   design: designData.modelNumber,
      // },
      {
        name: designDataVariables.frameSize,
        design: designData.frameSize,
      },
      {
        name: designDataVariables.ratedPower,
        unit: designData.ratedPower?.unit,
        design: designData.ratedPower?.design,
      },
      {
        name: designDataVariables.ratedCurrent,
        unit: designData.ratedCurrent?.unit,
        design: designData.ratedCurrent?.design,
      },
      {
        name: designDataVariables.ratedTemperature,
        unit: designData.ratedTemperature?.unit,
        design: designData.ratedTemperature?.design,
      },
      {
        name: designDataVariables.synchronousSpeed,
        unit: designData.synchronousSpeed?.unit,
        design: designData.synchronousSpeed?.design,
      },
      {
        name: designDataVariables.ratedVoltage,
        unit: designData.ratedVoltage?.unit,
        design: designData.ratedVoltage?.design,
      },
      {
        name: designDataVariables.numberOfPhases,
        design: designData.numberOfPhases,
      },
      {
        name: designDataVariables.frequency,
        unit: designData.frequency?.unit,
        design: designData.frequency?.design,
      },
      {
        name: designDataVariables.dutyType,
        design: designData.dutyType,
      },
      {
        name: designDataVariables.ratedPowerFactor,
        unit: designData.ratedPowerFactor?.unit,
        design: designData.ratedPowerFactor?.design,
      },
      {
        name: designDataVariables.statorCurrentThresholdForRunningStatus,
        unit: designData.statorCurrentThresholdForRunningStatus?.unit,
        design: designData.statorCurrentThresholdForRunningStatus?.design,
      },
      {
        name: designDataVariables.generatorPowerThresholdForRunningStatus,
        unit: designData.generatorPowerThresholdForRunningStatus?.unit,
        design: designData.generatorPowerThresholdForRunningStatus?.design,
      },
      {
        name: designDataVariables.generatorSpeedThresholdForRunningStatus,
        unit: designData.generatorSpeedThresholdForRunningStatus?.unit,
        design: designData.generatorSpeedThresholdForRunningStatus?.design,
      },
      {
        name: designDataVariables.rotorInsulationClass,
        design: designData.rotorInsulationClass,
      },
      {
        name: designDataVariables.statorInsulationClass,
        design: designData.statorInsulationClass,
      },
      {
        name: designDataVariables.exciterTemperatureRise,
        design: designData.exciterTemperatureRise,
      },
      {
        name: designDataVariables.rotorTemperatureRise,
        design: designData.rotorTemperatureRise,
      },
      {
        name: designDataVariables.statorTemperatureRise,
        design: designData.statorTemperatureRise,
      },
      {
        name: designDataVariables.nemaEnclosureType,
        design: designData.nemaEnclosureType,
      },
      {
        name: designDataVariables.enclosureIPCode,
        design: designData.enclosureIPCode,
      },
      {
        name: designDataVariables.imCode,
        design: designData.imCode,
      },
      {
        name: designDataVariables.icCode,
        design: designData.icCode,
      },
      {
        name: designDataVariables.speedType,
        design: speedTypeTitle[designData.speedType],
      },
      {
        name: designDataVariables.speedSignalRatio,
        design: designData.speedSignalRatio,
      },
      {
        name: designDataVariables.bearingType,
        design: designData.bearingType,
      },
      {
        name: designDataVariables.bearingQuantity,
        design: designData.bearingQuantity,
      },
      {
        name: designDataVariables.rotorConstruction,
        design: designData.rotorConstruction,
      },
      {
        name: designDataVariables.excitationType,
        design: designData.excitationType,
      },
      {
        name: designDataVariables.exciterType,
        design: designData.exciterType,
      },
      {
        name: designDataVariables.permanentMagnetGenerator,
        design: designData.permanentMagnetGenerator,
      },
      {
        name: designDataVariables.generatorRotorResistance,
        unit: designData.generatorRotorResistance?.unit,
        design: designData.generatorRotorResistance?.design,
      },
      {
        name: designDataVariables.generatorStatorResistance,
        unit: designData.generatorStatorResistance?.unit,
        design: designData.generatorStatorResistance?.design,
      },
      {
        name: designDataVariables.excitorStatorResistance,
        unit: designData.excitorStatorResistance?.unit,
        design: designData.excitorStatorResistance?.design,
      },
      {
        name: designDataVariables.shortCircuitCurrent3SteadyState,
        unit: designData.shortCircuitCurrent3SteadyState?.unit,
        design: designData.shortCircuitCurrent3SteadyState?.design,
      },
      {
        name: designDataVariables.shortCircuitCurrent3PhasePeak,
        unit: designData.shortCircuitCurrent3PhasePeak?.unit,
        design: designData.shortCircuitCurrent3PhasePeak?.design,
      },
      {
        name: designDataVariables.shortCircuitCurrent2PhasePeak,
        unit: designData.shortCircuitCurrent2PhasePeak?.unit,
        design: designData.shortCircuitCurrent2PhasePeak?.design,
      },
      {
        name: designDataVariables.shortCircuitCurrent3PhaseRMS,
        unit: designData.shortCircuitCurrent3PhaseRMS?.unit,
        design: designData.shortCircuitCurrent3PhaseRMS?.design,
      },
      {
        name: designDataVariables.generatorAccelarationTime,
        unit: designData.generatorAccelarationTime?.unit,
        design: designData.generatorAccelarationTime?.design,
      },
      {
        name: designDataVariables.inertiaConstant,
        design: designData.inertiaConstant,
      },
      {
        name: designDataVariables.dampingFactor,
        design: designData.dampingFactor,
      },
      {
        name: designDataVariables.frictionWindageLossesAtNominalRating,
        unit: designData.frictionWindageLossesAtNominalRating?.unit,
        design: designData.frictionWindageLossesAtNominalRating?.design,
      },
      {
        name: designDataVariables.coreLossesAtNominalRating,
        unit: designData.coreLossesAtNominalRating?.unit,
        design: designData.coreLossesAtNominalRating?.design,
      },
      {
        name: designDataVariables.strayLoadLosses,
        unit: designData.strayLoadLosses?.unit,
        design: designData.strayLoadLosses?.design,
      },
      {
        name: designDataVariables.armatureI2RLosses,
        unit: designData.armatureI2RLosses?.unit,
        design: designData.armatureI2RLosses?.design,
      },
      {
        name: designDataVariables.fieldI2RLosses,
        unit: designData.fieldI2RLosses?.unit,
        design: designData.fieldI2RLosses?.design,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      addDesignData,
      synchronousGeneratorConfigData,
    } = this.props.synchronousGeneratorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (
      synchronousGeneratorConfigData.isSuccess &&
      prevProps.synchronousGeneratorReducer.synchronousGeneratorConfigData
        .isSuccess !== synchronousGeneratorConfigData.isSuccess
    ) {
      this.setData();
    }

    if (
      addDesignData.isSuccess &&
      prevProps.synchronousGeneratorReducer.addDesignData.isSuccess !==
        addDesignData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
    if (
      addDesignData.isError &&
      prevProps.synchronousGeneratorReducer.addDesignData.isError !==
        addDesignData.isError
    ) {
      Notification.show(Types.Error, MotorDesignDataMessages.ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }
  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        saveListener: true,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    let editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  validate = () => {
    const { displayData } = this.state;
    let tempError = false;
    let dirtyFields = [];
    // mandatory fields
    displayData.forEach((item) => {
      if (
        item.name === designDataVariables.ratedPower ||
        item.name === designDataVariables.synchronousSpeed ||
        item.name === designDataVariables.ratedVoltage ||
        item.name ===
          designDataVariables.statorCurrentThresholdForRunningStatus ||
        item.name ===
          designDataVariables.generatorPowerThresholdForRunningStatus ||
        item.name ===
          designDataVariables.generatorSpeedThresholdForRunningStatus
      ) {
        if (
          !item.design ||
          item.design === undefined ||
          !item.unit ||
          item.unit === undefined
        ) {
          tempError = true;
          dirtyFields.push(item);
        }
      }
      if (
        item.name === designDataVariables.speedType ||
        item.name === designDataVariables.speedSignalRatio
      ) {
        if (!item.design || item.design === undefined) {
          tempError = true;
          dirtyFields.push(item);
        }
      }
      if (
        item.name === designDataVariables.frequency ||
        item.name === designDataVariables.ratedPower ||
        item.name === designDataVariables.ratedCurrent ||
        item.name === designDataVariables.ratedVoltage ||
        item.name === designDataVariables.fieldI2RLosses ||
        item.name === designDataVariables.strayLoadLosses ||
        item.name === designDataVariables.ratedPowerFactor ||
        item.name === designDataVariables.ratedTemperature ||
        item.name === designDataVariables.synchronousSpeed ||
        item.name === designDataVariables.armatureI2RLosses ||
        item.name === designDataVariables.excitorStatorResistance ||
        item.name === designDataVariables.generatorRotorResistance ||
        item.name === designDataVariables.generatorStatorResistance ||
        item.name === designDataVariables.generatorAccelarationTime ||
        item.name === designDataVariables.coreLossesAtNominalRating ||
        item.name === designDataVariables.shortCircuitCurrent3PhaseRMS ||
        item.name === designDataVariables.shortCircuitCurrent2PhasePeak ||
        item.name === designDataVariables.shortCircuitCurrent3PhasePeak ||
        item.name === designDataVariables.shortCircuitCurrent3SteadyState ||
        item.name ===
          designDataVariables.frictionWindageLossesAtNominalRating ||
        item.name ===
          designDataVariables.statorCurrentThresholdForRunningStatus ||
        item.name ===
          designDataVariables.generatorPowerThresholdForRunningStatus ||
        item.name ===
          designDataVariables.generatorSpeedThresholdForRunningStatus
      ) {
        if (
          item.unit &&
          item.unit !== undefined &&
          (!item.design || item.design === undefined)
        ) {
          tempError = true;
          dirtyFields.push(item);
        }
        if (
          item.design &&
          item.design !== undefined &&
          (!item.unit || item.unit === undefined)
        ) {
          tempError = true;
          dirtyFields.push(item);
        }
      }
    });
    return tempError;
  };

  onSave = () => {
    const {
      location: { state },
      saveDesignData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
      copyDisplayData: displayData
    });
    if (!this.validate()) {
      const data = KeyChange;
      let payload = {};
      displayData.forEach((item) => {
        if (
          (item.name === designDataVariables.frequency &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.ratedPower &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.ratedCurrent &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.ratedVoltage &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.fieldI2RLosses &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.strayLoadLosses &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.ratedPowerFactor &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.ratedTemperature &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.synchronousSpeed &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.armatureI2RLosses &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.excitorStatorResistance &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.generatorRotorResistance &&
            (item.design || item.design === 0)) ||
            (item.name === designDataVariables.generatorStatorResistance &&
              (item.design || item.design === 0)) ||
          (item.name === designDataVariables.generatorAccelarationTime &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.coreLossesAtNominalRating &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.shortCircuitCurrent3PhaseRMS &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.shortCircuitCurrent2PhasePeak &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.shortCircuitCurrent3PhasePeak &&
            (item.design || item.design === 0)) ||
          (item.name === designDataVariables.shortCircuitCurrent3SteadyState &&
            (item.design || item.design === 0)) ||
          (item.name ===
            designDataVariables.frictionWindageLossesAtNominalRating &&
            (item.design || item.design === 0)) ||
          (item.name ===
            designDataVariables.statorCurrentThresholdForRunningStatus &&
            (item.design || item.design === 0)) ||
          (item.name ===
            designDataVariables.generatorPowerThresholdForRunningStatus &&
            (item.design || item.design === 0)) ||
          (item.name ===
            designDataVariables.generatorSpeedThresholdForRunningStatus &&
            (item.design || item.design === 0))
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: item.design,
          };
        } else if (item.design || item.design === 0) {
          payload[data[item.name]] = item.design;
        }
        if (item.name === 'Speed Type' && item.design === 'Constant Speed') {
          payload[data[item.name]] = 'CONSTANT';
        }
      });
      if (
        this.handleNetwork() &&
        state &&
        state.componentData.info._id &&
        payload
      ) {
        saveDesignData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, GeneratorParameterDataMessages.DIRTY_DATA);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      synchronousGeneratorConfigData,
      addDesignData,
    } = this.props.synchronousGeneratorReducer;
    const { saveListener, unitData } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignDataSynchronousMotor">
        {(synchronousGeneratorConfigData.isLoading ||
          addDesignData.isLoading) && <Loader />}
        <CustomTable
          showHeader={true}
          columns={DesignDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}
DesignDataSynchronousGenerator.propTypes = {
  synchronousGeneratorConfigData: PropType.object,
  addDesignData: PropType.object,
  location: PropType.object,
  saveDesignData: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isSchematicEditable: PropType.bool,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
};

DesignDataSynchronousGenerator.defaultProps = {
  isSchematicEditable: true,
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    synchronousGeneratorReducer: state.synchronousGeneratorReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(DesignDataSynchronousGenerator)
);
