export const NOT_FOUND_MESSAGE = "The Requested Url is not found.";

export const T_AND_C = "Please accept the terms and conditions to continue";

export const NOT_FOUND_LABEL = "404 Not Found";

export const LOADING_MESSAGE =
  "Please Wait while your request is being processed.";

export const TAG =
  "An integrated artificial intelligence and process analytics IoT platform";

export const WELCOME_TEXT = "Welcome to ";

export const MECHADEMY = "";

export const NO_DATA_API = "No Data Found For this request";

export const NO_INTERNET =
  "You are not connected to the internet. Check your internet connection and try again.";

export const INTERNET_CONNECTED = "You are now connected to the internet";

export const MODULE_PROGRESS_TEXT = "This Module is in Progess";

export const ERROR_HANDLE_TEXT =
  "There was an error while processing your request";

export const FORM_DISCARD_MSG =
  "Are you sure you want to discard your changes ?";

export const RESET_OR_SAVE =
  "Either reset or save your changes";  

export const DISCARD_CHANGES = "Discard changes";

export const LOGGEDIN_WARNING =
  "You are already Logged in. Please do reset Password";

export const LOGGEDIN_DIFFERENT_USER =
  "You are already logged in with different user. Please refresh the page.";

export const CURRENT_DURATION = "Now";

export const ERROR_MESSAGES = {
  ALREADY_EXISTS: "Value already exists",
  UNAUTHORIZED: "Invalid token",
  REQUIRED_FIELD: "This field is mandatory",
  INVALID_INPUT_DATA: "Please enter valid data",
  INVALID_INPUT: "Invalid input",
  NOT_FOUND: "Data not found",
  SELECT_CLIENT: "Please select client",
};

export const AssetMessages = {
  NO_DATA: "No Data for this Category Available",
  EDIT_ASSET: "Please Click here to Edit Asset Details",
  HEADING_ASSET: "Asset Library",
  EDIT_LABEL: "Edit",
  ADD_COMPONENET_LABEL: "Add Component",
  ID_TITLE: "ID",
  COMPONENT_NAME_TITLE: "Component Name",
  COMPONENT_TYPE_TITLE: "Component Type",
  COMPONENT_SYMBOL_TITLE: "Symbol",
  COMPONENT_ACTION_TITLE: "Action",
  DUMMY_API_URL: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  FILE_SUCCESS_UPLOAD: "file uploaded successfully.",
  FILE_FAIL_UPLOAD: "file upload failed.",
  COMPONENT_LABEL: "Component",
  UPLOAD_IMAGE: "Upload Image",
  SUPPORTED_IMG_FORMATS: "(Format PNG or JPEG, Max Size: 500 KB)",
  COMPONENT_PAGE_COUNT: 10,
  NOT_AVAILABLE_LABEL: "NA",
  SEARCH_PLACEHOLDER: "Search here",
  UNAUTHORIZED: "Invalid token",
  INTERNAL: "Internal server error",
  INVALID_SEARCH: "Invalid search",
};
export const LoginMessages = {
  INVALID_LOGIN: "Email and password does not match",
  INVALID_INPUT: "Email and password does not match",
  REQUIRED_EMAIL: "Please enter your email",
  INVALID_EMAIL: "Please enter valid email",
  REQUIRED_PASSWORD: "Please enter your password",
  SUCCESS: "Logged in Successfully",
  USER_DISABLED: "You can't login. You are disable.",
  INTERNAL: "Internal server error",
  INVALID_CAPTCHA: "Captcha invalid or timeout",
  REQUIRED_CAPTCHA: "Captcha required",
};

export const forgotPasswordMessages = {
  SUCCESS: "Email sent successfully",
  INVALID_INPUT: "Please enter valid email id",
  NOT_FOUND: "Email id does not exist",
  NO_ACCESS: "No Access provided to this user",
  USER_DISABLED: "Can't change password. User is disabled.",
  INTERNAL: "Internal server error",
  INVALID_CAPTCHA: "Captcha invalid or timeout",
  REQUIRED_CAPTCHA: "Captcha required",
};

export const changePasswordMessages = {
  SUCCESS: "Password reset successfully",
  INVALID_INPUT:
    "This link is no longer valid. Please try resetting your password again.",
  INTERNAL: "Internal server error",
  NOT_ALLOWED:
    "This link is no longer valid. Please try resetting your password again.",
};

export const ClientListingMessages = {
  UNAUTHORIZED: "Invalid token",
  INTERNAL: "Internal server error",
};

export const ClientsMessages = {
  REQUIRED_FIELD: "This field is mandatory",
  INVALID_INPUT_DATA: "Please enter valid data",
  INVALID_INPUT: "Invalid input",
  ALREADY_EXISTS: "Mechademy Code already exists",
  UNAUTHORIZED: "Invalid token",
  clientAdded: "Client added successfully",
  clientUpdated: "Client updated successfully",
  invalidInput: "You have entered a invalid input",
  INTERNAL: "Internal server error",
  NAME_ALREADY_EXISTS: "Client Name already exists",
};

export const FacilityMessages = {
  FACILITY_ADDED: "Facility added successfully",
  FACILITY_UPDATED: "Facility updated successfully",
  INVALID_INPUT: "Invalid input",
  INVALID_INPUT_DATA: "Please enter valid data",
  UNAUTHORIZED: "Invalid token",
  ALREADY_EXISTS: "Mechademy Facility Code already exists",
  REQUIRED_FIELD: "This field is mandatory",
  LENGTH: "Length exceeded",
  INTERNAL: "Internal server error",
  NAME_ALREADY_EXISTS: "Facility Name already exists",
};

export const systemMessages = {
  UNAUTHORIZED: "Invalid token",
  REQUIRED_FIELD: "This field is mandatory",
  INVALID_INPUT_DATA: "Please enter valid data",
  systemAdded: "System added successfully",
  systemUpdated: "System updated successfully",
  systemName: "Please input System name",
  systemId: "Please input System ID",
  systemDescription: "Please input System Description",
  INVALID_INPUT: "Please enter Valid values",
  ALREADY_EXISTS: "System already exists. Check mechademy code",
  NOT_FOUND: "System not found",
  INTERNAL: "Internal server error",
};

export const EquipmentMessage = {
  ALREADY_EXISTS: "Equipment already exists. Check mechademy code",
  EQUIPMENT_ADDED: "Equipment added successfully",
  EQUIPMENT_UPDATED: "Equipment updated successfully",
  UNAUTHORIZED: "Invalid token",
  REQUIRED_FIELD: "This field is mandatory",
  INVALID_INPUT_DATA: "Please enter the values correctly",
  INVALID_INPUT: "Please enter the values correctly ",
  NOT_FOUND: "Equipment not found",
  INTERNAL: "Internal server error",
};

export const EquipmentTypeMessage = {
  ALREADY_EXISTS: "Equipment type already exists",
  INVALID_INPUT: "Invalid input ",
  ADDED: "Equipment type added successfully",
  INTERNAL: "Internal server error",
};

export const ManufacturerMessage = {
  ALREADY_EXISTS: "Manufacturer already exists",
  INVALID_INPUT: "Invalid input ",
  INTERNAL: "Internal server error",
};

export const fileMessage = {
  success: "Logo updated successfully",
  error: "File uploading failed",
  maxSize: "Image size must be less than 500 KB",
  type: "You can only upload JPG, JPEG or PNG file!",
  successMsgFile: "File uploaded successfully",
};

export const imageMessage = {
  success: "Logo updated successfully",
  error: "File uploading failed",
  maxSize: "Image size must be less than 1MB",
  type: "You can only upload JPG, JPEG, SVG or PNG file!",
};

export const UnitPropertiesMessages = {
  UNAUTHORIZED: "Invalid token",
  INTERNAL: "Internal server error",
  INVALID_MIME_TYPE: "Invalid file type selected",
};

export const UnitPropertiesTitle = "Units & Properties";

export const SchematicComponentMessages = {
  INVALID_INPUT: "Invalid input data",
  NOT_FOUND: "Component not found",
  UNAUTHORIZED: "Invalid token",
  ALREADY_EXISTS: "Component already exist",
  INTERNAL: "Internal server error",
};

export const SchematicMessages = {
  INVALID_INPUT: "Invalid input data",
  NOT_FOUND: "Schematic not found",
  UNAUTHORIZED: "Invalid token",
  ADDED: "Schematic created successfully",
  UPDATED: "Schematic updated successfully",
  BLANK: "Nothing to save",
  DEPLOYED: "Schematic deployed successfully",
  INVALID_GRAPH: "Schematic is not valid",
  CAN_NOT_SAVE: "Cannot save empty graph",
  UN_DEPLOYED_SUCCESS: "Schematic undeployed successfully",
  INTERNAL: "Internal server error",
};

export const GraphEditorMessages = {
  BROWSER_NOT_SUPPORTED: "Browser is not supported!",
  UN_DEPLOY_MODAL_TITLE: "Confirmation modal",
  UN_DEPLOY_MODAL_MSG: "Are you sure want to undeploy the component?",
  INTERNAL: "Internal server error",
};

export const EditCurrentNodeMessages = {
  ADDED: "Component created successfully",
  UPDATED: "Component updated successfully",
  DELETED: "Component deleted successfully",
  REQUIRED_FIELD: "This field is mandatory",
  INVALID_INPUT_DATA: "Please enter valid data",
  INVALID_INPUT: "Please enter the values correctly",
  NOT_FOUND: "Component not found",
  NOT_ALLOWED:
    "Cannot delete component because it's output tags used by other component(s) input config.",
  INTERNAL: "Internal server error",
};

export const RunnerDataMessages = {
  ERROR: "Error while loading config",
  RUNNERSUCCESS: "Runner data saved successfully",
  IMPELERSUCCESS: "Impeller data saved successfully",
  INVALID_INPUT: "Please enter the values correctly",
  MAX_RUNNER: "Max runner limit is 20",
  MIN_RUNNER: "Minimum 1 runner data is required",
  MAX_IMPELLER: "Max impeller limit is 20",
  MIN_IMPELLER: "Minimum 1 impeller data is required",
  DIRTY_DATA: "Fill correct data in table",
  FIELDS_DIRTY: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  ONSAVERUNNER: "Updating the runner data will revise the curve calculation.",
  ONSAVEIMPELLER:
    "Updating the impeller data will revise the curve calculation.",
  INTERNAL: "Internal server error",
};

export const CurvesMessages = {
  FIELDS_DIRTY: "Please enter the values correctly",
  INVALID_INPUT: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  EQUAL_DATA:
    "Number of rows in As-tested and Operational-baseline curves should be same",
  MIN_DATA:
    "Minimum 3 rows are required for adding As-tested and Operational-baseline curves",
  MIN_DATA_CV:
    "Minimum 3 rows are required for adding curves",
  REQUIRED_RUNNER: "Atleast 1 runner is required for curve data calculation",
  REQUIRED_IMPELLER:
    "Atleast 1 impeller is required for curve data calculation",
  REQUIRED_FIELD: "This field is mandatory",
  ADDED: "Curves saved successfully",
  DELETED: "Curves deleted successfully",
  DELETE_TITLE: "Delete Curve",
  DELETE_MSG: "Are you sure you want to remove this curve?",
  DELETE_NOTIFICATION: "You can delete only saved curve.",
  SELECT_TITLE: "Discard Curve",
  SELECT_MSG: "Are you sure you want to discard extra curve?",
  COPY_TITLE: "Duplicate as-tested curve",
  COPY_MSG:
    "Duplicating the As-tested curve data will copy and update the operational baseline curve. Click proceed to continue.",
  DUPLICATE_MSG: "Minimum 1 row is required to duplicate the data",
  INTERNAL: "Internal server error",
  SELECT_UNITS: "Please select the units",
  RATED_CURRENT_POWER: "Rated Current and Rated Power output are mandatory fields!"
};

export const FluidCompositionMessages = {
  FLUID_ADDED: "Fluids data saved successfully",
  EQUATION_STATE_REQUIRED: "Equation of state is required",
  COMPONENT_TYPE_REQUIRED: "Composition Type is required",
  INVALID_INPUT: "Please enter the values correctly",
  NOT_FOUND: "Fluid composition data not found",
  NO_FLUID_SELECTED: "No fluids selected",
  EXTRAPOLATING_MESSAGE1:
    "All fluid composition values will be automatically extrapolated in the ratio of composition entered in order to achieve 100% composition",
  // EXTRAPOLATING_MESSAGE2:
  //   'ratio of composition entered in order to acheive 100% composition',
  FIELDS_DIRTY: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  INTERNAL: "Internal server error",
};

export const DesignDataMessages = {
  FIELDS_DIRTY: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  INVALID_INPUT: "Please enter the values correctly",
  ADDED: "Design data saved successfully",
  METER_TYPE_ADDED: "Flow Meter Type Added successfully",
  ADD_NEW_METER_TYPE: "Add new flow meter type",
  MENDATORY_FIELD: "This field is mandatory",
  INTERNAL: "Internal server error",
};

export const FlowMeasurementMessages = {
  FIELDS_DIRTY: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  INVALID_INPUT: "Please enter the values correctly",
  ADDED: "Flow measurement data saved successfully",
  ADD_NEW_METER_TYPE: "Add new Flow Meter Type",
  MENDATORY_FIELD: "This field is mendatory",
  METER_TYPE_ADDED: "Flow Meter type added successfully",
  INTERNAL: "Internal server error",
  ADD_MANUFACTURER_TYPE: "Add new manufacturer Type",
  MANUFACTURER_ADDED: "Manufacturer added successfully!",
};

export const BypassValveMessages = {
  BYPASS_DATA_ADDED: "Bypass data saved successfully",
  INVALID_INPUT: "Please enter the values correctly",
  REQUIRED_FIELD: "This field is mandatory",
  FIELDS_DIRTY: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  MIN_DATA:
    "Minimum 3 rows are required for adding Theoretical and Operational valve characteristic curves",
  EQUAL_DATA:
    "Number of rows in Theoretical and Operational valve characteristic curves should be same",
  INTERNAL: "Internal server error",
  ADD_EQUIPMENT_MANUFACTURER: "Add Equipment Manufacturer",
  ADD_EQUIPMENT_TYPE: "Add Equipment Type",
  SUCCESS_MANUFACTURER: "Manufacturer saved successfully",
  SUCCESS_EQUIPMENT_TYPE: "Equipment type saved successfully",
};

export const OutputDataMessages = {
  ADDED: "Output data saved successfully",
  FIELDS_REQUIRED:
    "Variable name, Pi variable name, Variable property, Unit, Data type are mandatory",
  UNAUTHORIZED: "Invalid token",
  MIN_DATA: "Minimum one variable is required ",
  INVALID_INPUT: "Please enter the values correctly",
  NULL_MIN_MAX: "Multiplier and Adder are required to enable IQR",
  ZERO_MESSAGE: "Zero cannot be a multiplier",
  MULTIPLIERS_REQUIRED: "Enter both multipliers",
  PITAG_INFO: "Select Variable Name from list",
  INTERNAL: "Internal server error",
};

export const DashboardMessages = {
  NO_COMPONENT: "This facility doesn't has systems and equipments",
  INTERNAL: "Internal server error",
};

export const AlertDetailMessages = {
  ALERT_UPDATED: "Alert updated successfully",
  INVALID_INPUT: "Please enter the values correctly",
  NOT_ALLOWED: "This action is not allowed",
  NOT_FOUND: "NOT FOUND",
  ERROR_CLOSE: "Need client action",
  INTERNAL: "Internal server error",
  INVALID_TIME: "Please select valid shelving time",
  MACHINE_START_ALERT: "The equipment run status has changed from OFF to ON.",
  INSIGHTS_EMPTY: "Please enter insights!",
  FILL_REQUIREND_COMMENTS: "Please fill in comments first",
  FILL_REQUIREND_INSIGHTS: "Please fill in insights first",
  CHARACHETER_COUNT: "Comments length must be less than 50000 chars"
};

export const InputDataMessages = {
  INVALID_INPUT: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  NULLMINMAX: "Min multiplier and Max multiplier are required to enable IQR",
  VARIABLETAGUSED: "Variable tag already used",
  NOTHINGTOSAVE: "Nothing to Save",
  MIN_DATA: "Minimum one variable is required ",
  VARIABLENAMEUSED: "Variable Name already used",
  SUCCESS: "Input data added successfully",
  ERROR: "Unable to add input data",
  FIELDS_DIRTY: "Variable tag and Variable name are mandatory",
  CYCLE_FOUND: "Cannot create cycles",
  ERROR_VARIABLE_NAME: "Select variable name from the list",
  ERROR_VARIABLE_TAG: "Select variable tag from the list",
  INTERNAL: "Internal server error",
};

export const CalculatedDataMessages = {
  CALCULATED_DATA_ADDED: "Calculated data added successfully",
  FIELDS_DIRTY: "Please enter values correctly",
  UNAUTHORIZED: "Invalid token",
  MIN_DATA: "Minimum one variable is required ",
  MIN_MAX: "Min Bound should not be equal or greater than Max Bound",
  INTERNAL: "Internal server error",
  MANDATORY: "Variable name, Min Bound and Max Bound are mandatory",
};

export const AlertMsg = {
  DISCARD_ALERT_SUCCESS: "Selected alerts discarded successfully",
  DISCARD_FAILURE_MSG: "Comment is mandatory to discard high level alert",
  DISCARD_PARTIAL_MSG: "Low, medium, and unknown level alerts are discarded",
  CLOSE_ALERT_SUCCESS: "Selected alerts closed successfully",
  CLOSE_FAILURE_MSG: "Could not close selected Alerts",
  CLOSE_PARTIAL_MSG: "Could close only some of the selected Alerts",
  ASSOC_FAILURE_MSG: "Could not associate selected Alerts",
  ASSOC_PARTIAL_MSG: "Could associate only some of the selected Alerts",
  ASSOC_ALERT_SUCCESS: "Selected alerts associated successfully",
  DISASSOC_FAILURE_MSG: "Could not disassociate selected Alerts",
  DISASSOC_PARTIAL_MSG: "Could disassociate only some of the selected Alerts",
  DISASSOC_ALERT_SUCCESS: "Selected alerts disassociated successfully",
  errorInListing: "Api error",
  NO_ACCESS: "No Access provided",
  DISCARD_ALL_MSG: "Are you sure you want to discard all selected alerts",
  DISASSOC_ALL_MSG: "Are you sure you want to disassociate all selected alerts",
  CLOSE_ALL_MSG: "Are you sure you want to close all selected alerts",
  INTERNAL: "Internal server error",
  DISCARD_TITLE: "Discard alerts",
  CLOSE_TITLE: "Close alerts",
  ASSOC_TITLE:"Associate Alerts",
  DISASSOC_TITLE:"Disassociate Alerts",
  PROVIDE_COMMENT: "Please provide closing comment for your selection",
};

export const AddUserMessages = {
  REQUIRED_FIELD: "This field is mandatory",
  INVITATION_NOT_SENT: "Unable to send invitation",
  INTERNAL: "Internal server error",
};

export const UserManagementMessages = {
  INVITATION_SENT: "Invitation sent successfully",
  INVITED: "Invite sent",
  PASSWORD_SAVED: "Password saved successfully",
  INVALID_PASSWORD: "Password does not match",
  STATUS_CHANGE_SUCCESS: "Status changes successfully",
  STATUS_CHANGE_ERROR: "Unable to change status",
  INVITATION_NOT_SENT: "Unable to send invitation,",
  USER_SAVE_SUCCESS: "User saved successfully",
  ALREADY_EXISTS: "Email already used",
  USER_EDIT_SUCCESS: "User updated successfully",
  USER_EDIT_ERROR: "Unable to edit user",
  INVALID_INPUT: "Link is not valid or expired",
  REQUIRED_FIELD: "This field is mandatory",
  ALREADY_LOGGEDIN: "You are already logged in",
  ACTIVATE_USER: "Want to activate the user?",
  DEAVTIVATE_USER: "Want to de-activate the user?",
  NOTHINGTOSAVE: "Nothing to save",
  STATUS_ACTIVE: "Active",
  STATUS_INACTIVE: "Inactive",
  ALERTS_ACCESS_TITLE: "Alerts’ Access Details",
  USER_SAVE_ERROR: "Unable to add a user",
  INTERNAL: "Internal server error",
  NOT_ALLOWED:
    "This link is no longer valid. Please try creating your password again.",
  LONG_PASSWORD: "Password too long",
  ACCESS_TITLE: "Access Details",
  USER_MGMT_ACCESS: "User Management Access Details",
};

export const addNewCardMessages = {
  SUCCESS: "Card added successfully",
  DELETED: "Card deleted successfully",
  UPDATED: "Card Updated successfully",
  INVALID_INPUT: "Please enter the values correctly",
  UNAUTHORIZED: "Invalid token",
  REQUIRED_FIELD: "This field is mandatory",
  INTERNAL: "Internal server error",
  ADD_PANEL_TYPE: "Add Panel Type",
  ERROR: "Error",
  ADD_PANEL_SUCCESS: "Panel added successfully!",
  DELETE_PANEL_SUCCESS: "Panel deleted successfully!",
  EDIT_PANEL_TYPE: "Edit Panel Type",
  UPDATE_PANEL_SUCCESS: "Panel title updated successfully!",
};

export const surgeLineMessages = {
  MANDATORY: "All fields are mandatory",
  REQUIRED_INPUT:
    "Minimum 3 rows are required for AS-Tested Curve and Operational Baseline Curve",
  SAME_LENGTH:
    "Number of rows in AS-Tested and Operational-Baseline curves should be equal",
  DATA_SAVE: "Surge Line data saved successfully",
  DATA_SAVE_ERROR: "Unable to save the data",
  REQUIRED_FIELD: "This field is mandatory",
  INCORRECT_DETAILS: "Please fill all the fields correctly",
  REQUIRED_INPUT_MAX: "Max surge limit is 15",
  INTERNAL: "Internal server error",
  FIELDS_DIRTY: "Please enter the values correctly",
};

export const curveBoardMessages = {
  MODAL_TITLE: "Change to fixed speed",
  MODAL_MSG: "Only one curve you can add.",
};
export const deleteModal = {
  message: "Are you sure you want to remove this card ?",
  title: "Delete Confirm",
  panel: {
    message: "Are you sure you want to remove this panel ?",
  },
};

export const MANAGE_TAGS_MESSAGE = {
  CSV_SUCCESS_UPLOAD: "File uploaded successfully",
  CSV_PARTIALLY_UPLOAD: "File uploaded partially",
  CSV_FAIL_TO_UPLOAD: "File upload failed",
  DELETE_MODAL_TITLE: "Delete confirmation",
  DELETE_MODAL_MSG: "Are you sure you want to delete selected Pi-Tags?",
  EMPTY_UPLOAD_ERROR_MSG: "Please upload a CSV file",
  BASE_TIME_INTERVAL: "Please fill base time interval",
  NO_DATA: "Please upload a CSV file with Pi Tags",
  // EDIT_ANY_ROW_FIRST: 'Please edit any row first',
  FILED_EMPTY:
    "Equipment, Data type, Variable property, Mechademy unit, Variable min value, Variable max value are mandatory.",
  PI_TAG_DELETE_SUCCESS: "Selected Pi-Tags deleted successfully",
  PI_TAG_SAVED: "Pi-Tags saved successfully", // todo confirm Pi-Tags or Pi-Tag
  NOT_ALLOWED: "Pi-Tag is in use",
  NOT_FOUND: "Unable to fetch data",
  PARSING_ERROR: "Invalid data",
  PI_TAG_DELETE_ERROR: "Something went wrong, unable to delete",
  DELETE_PARTIALLY:
    "Action cannot be completed for some tags. Remove mapping in config to delete the remaining tags.",
  DELETE_ERROR:
    "Action cannot be completed. Remove mapping in config to delete the tag.",
  INTERNAL: "Internal server error",
  BOUND_ERROR_MSG:
    "Variable min bound should not be equal to or greater than variable max bound",
  MULTIPLIER_REQUIRED: "Enter the multiplier value",
  ADDER_REQUIRED: "Enter the adder value",
  ZERO_VALIDATION: "Multiplier cannot be zero or negative",
  FIELDS_DIRTY: "Please enter the values correctly",
  //PI_TAG file validation constants
  CSV_CONVERSION_FAIL: "CSV file conversion fail",
  UPLOAD_FILE: "Upload .csv file to start validation",
  START_VALIDATION: "File validation started",
  DIRTY_HEADER: "File has dirty header data",
  VARIABLE_PROPERTY_ERROR: "Problem with variable property of tag",
  UNIT_ERROR: "Problem with unit of tag",
  UAD_ERROR: "Problem with UAD adder/multiplier of tag",
  MIN_MAX_ERROR: "Variable min. value should be less than max. value for tag",
  MECHADEMY_UNIT_ERROR: "Problem with mechademy unit of tag",
  DATA_TYPE_ERROR: "Problem with data type of tag",
  NAN_VAL_CHECK: "Non-numerical value found for tag",
  IQR_VAL_CHECK: "IQR value should be 0 or 1 for tag",
  EMPTY_ROW : "File has an empty row",
  VALIDATION_SUCCESS : "File validation success",
};

export const PI_TAG_FILE_HEADER = [
  "piTag",
  "units",
  "piVariableName",
  "equipmentId",
  "dataType",
  "variableProperty",
  "mechademyUnits",
  "minThreshold",
  "maxThreshold",
  "multiplier",
  "adder",
  "isIQR"
]

export const PI_TAG_DATA_TYPE = [
  "Float",
  "Number",
  "Boolean",
  "Integer",
  "String"
]

export const PI_TAG_API_MSG = {
  INVALID_INPUT: "Missing required property: tag",
};

export const EquipmentAssetMessages = {
  NOTHINGTOSAVE: "Nothing to update",
  ADDED: "Equipment asset added successfully",
  UPDATED: "Equipment asset updated successfully",
  ALREADY_EXISTS: "Equipment already exist",
  INVALID_INPUT: "Please enter the values correctly",
  NOT_FOUND: "Asset not found",
  INTERNAL: "Internal server error",
};

export const SOCKET_MSG = {
  MSG: "New alert",
  KPI_MSG: "KPI updated",
  ERROR: "Socket event disconnected",
};

export const MotorDesignDataMessages = {
  SUCCESS: "Design data saved successfully",
  ERROR: "Unable to save data",
  INTERNAL: "Internal server error",
  DIRTY_DATA: "Please fill data correctly!"
};

export const PerformanceDataMessages = {
  SUCCESS: "Performance data saved successfully",
  ERROR: "Unable to save data",
  INTERNAL: "Internal server error",
};

export const StreamDataMessages = {
  SUCCESS: "Stream data saved successfully",
  INTERNAL: "Internal server error",
};
export const MIXER_MESSAGES = {
  ADDED: "Mixer Data added successfully.",
  INTERNAL: "Internal server error",
};

export const ML_ALGO_MSG = {
  MODAL_TITLE: "Confirmation modal",
  EXISTING_PI_TAG: "Variable Tag already selected",
  PI_TAG_REMOVED: "Variable Tag removed from list successfully",
  NO_INPUT_TAG_SELECTED: "Please select atleast 1 input tag",
  NO_OUTPUT_TAG_SELECTED: "Please select output ML-Tag",
  CONFIRMATION_MSG_DEPLOY_TOGGLE: "Are you sure you want to deploy the model?",
  CONFIRMATION_MSG_UNDEPLOY_TOGGLE:
    'Are you sure you want to undeploy the model?',
  BOUND_ERROR_MSG: 'Min Bound should not be equal to or greater than Max Bound',
  STATUS_CHANGE_SUCCESS: 'Status changed successfully',
  ADDED: 'Mixer Data added successfully.',
  CREATED_MODEL_SUCCESS: 'Ml Modal created sucessfully',
  ERROR_PI_TAG_LIST_FETCH: 'Unable to fetch pi tag',
  UNABLE_TO_FETCH_ML_LIST: 'Server error unable to fetch list',
  PLEASE_ENTER_OUTPUT_TAG: 'Please enter calculated tag data correctly',
  UPDATE_SUCCESS: 'Ml Model updated successfully',
  ALREADY_EXISTS: 'Tag already exists',
  INTERNAL: 'Internal server error',
  INVALID_INPUT: 'Please enter the values correctly',
  MANDATORY_FIELDS: 'Loss variable name, multiplier and adder are mandatory',
  FILED_EMPTY:
    "Equipment, Data Type, Variable Property, Mechademy Unit, Min Bound value and Max Bound value are mandatory",
  LEVEL_ERROR: "Keep level between 1 and 5",
  WAVELET_LEVEL_MANDATORY: "Wavelet Type and Level are mandatory fields!",
  PROCEED: 'Are you sure you want to proceed ?',
  TRY_AGAIN: 'Please Try Again!',
  REASON_UNDEPLOY: 'Please provide the reason for undeploying model',
  REASON_DEPLOY: 'Please provide the reason for deploying model',
};

export const PerformanceCurveMessages = {
  SUCCESS: "Performance curve saved successfully",
  ERROR: "Unable to save data",
  MIN: "Minimum 3 inputs required",
  MIN_CURVES: "Mininum three data is required",
  INTERNAL: "Internal server error",
};

export const StartingCharacteristicsCurvesMessages = {
  SUCCESS: "Starting characteristics curves saved successfully",
  ERROR: "Unable to save data",
  INTERNAL: "Internal server error",
};

export const VariableSpeedCurvesMessages = {
  SUCCESS: "Variable speed curves saved successfully",
  ERROR: "Unable to save data",
  SUCCESS_DRIVE_TYPE: "Drive Type saved successfully",
  SUCCESS_MANUFACTURER: "Manufacturer saved successfully",
  ERROR_DRIVE_TYPE: "Unable to add Drive Type",
  REQUIRED_FIELD: "This field is mandatory",
  ADD_DRIVE_TYPE: "Add Drive Type",
  ADD_MAUFACTURER: "Add Manufacturer",
  ALREADY_EXISTS: "Drive type already exists",
  INVALID_INPUT_DATA: "Please enter the values correctly",
  INVALID_INPUT: "Please enter the values correctly ",
  INTERNAL: "Internal server error",
};

export const API_ERROR_MSG = {
  INVALID_INPUT: "Something went wrong",
  INTERNAL: "Server error",
  NOT_ALLOWED : "Cannot undeploy. File is in processing."
};

export const SettingMessages = {
  FIELDS_DIRTY: "Please enter values correctly",
  DRIVE_TYPE: "Drive type updated successfully",
  EQUIPMENT_TYPE: "Equipment type updated successfully",
  FLOW_TYPE: "Flow meter type updated successfully",
  MANUFACTURER_TYPE: "Manufacturer type updated successfully",
  INVALID_INPUT: "Only Letter, Number, Space and & / . ( ) - , ' are allowed",
};

export const MotorParameterDataMessages = {
  SUCCESS: "Motor parameter data saved successfully",
  ERROR: "Unable to save data",
  INTERNAL: "Internal server error",
};

export const CapabilityCurveMessages = {
  SUCCESS: 'Capability curve saved successfully',
  ERROR: 'Unable to save data',
  MIN: 'Minimum 3 inputs required',
  MIN_CURVES: 'Mininum three data is required',
  INTERNAL: 'Internal server error',
};

export const GeneratorParameterDataMessages = {
  SUCCESS: 'Generator parameter data saved successfully',
  ERROR: 'Unable to save data',
  INTERNAL: 'Internal server error',
  DIRTY_DATA: 'Please fill data correctly!',
};

export const RecipCompDataMessage = {
  SUCCESS: 'Data saved successfully',
  ERROR: 'Unable to save data',
  INTERNAL: 'Internal server error',
  DIRTY_DATA: 'Please fill all the required fields!',
  SAVE_OR_RESET : 'Either save or rest the performed changes'
};

export const CsvValidationMessage = {
  UPLOAD_FILE: "Select a .csv file to upload",
  NOT_NUMBER: 'File contains non numerical values',
  MAX_POINT_ERROR : "File has more than 20 curve points",
  MIN_POINT_ERROR : "File has less than 3 curve points",
  BAD_HEADER_DATA: "Invalid File Header"
}

export const transientDataMessages = {
  ADDED_SUCCESS: "Transient data saved sucessfully",
  DATA_FIELD_MANDATORY: "Data fields are mandatory!",
  FIELDS_DIRTY: "Please enter the values correctly",
}

export const WorkingFluidDataMessages = {
  SUCCESS: "Working Fluid data saved sucessfully",
}
