import { ArrowBackIos } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AlertKPIPlots from '../AlertPlots/alertKPIPlots';
import ShelvedModal from '../ShelvedModal/ShelvedModal';
import AlertInfo from './AlertInfo';
import AlertSummary from './alertSummary';
import RenderAlertPlotButtons from './components/RenderAlertPlotButtons';
import RenderDiscussion from './components/RenderDiscussion';
import './newAlertDetail.scss';

const RenderPcView = (props, stateData) => {

    const { editAlertDetials } = props;
    const [detailTab, setDetailTab] = useState(0)

    const renderAlertDetail = () => {
        const { info, data } = props
        if (!detailTab) {
            return (
                <CardContent className='alert_detail_card'>
                    {AlertSummary(props, stateData)}
                    {RenderDiscussion(data)}
                    <div style={{ display: 'none' }}>
                    <AlertKPIPlots
                        info={info}
                        alertId={data['_id']}
                        activeKey={'2'}
                        alertPrint={false}
                    />
                    </div> 
                </CardContent>
            )
        }
        return <AlertKPIPlots
            info={info}
            alertId={data['_id']}
            activeKey={'2'}
            alertPrint={false}
        />
    }



    return (
        <div className="alertDetails">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Card style={{ position: 'relative' }}>
                        <Grid container spacing={1} >
                            <Grid item xs={6}>
                                <IconButton variant='contained' className='back_button' onClick={() => editAlertDetials(false, {})}>
                                    <ArrowBackIos fontSize='small' className='back_button_logo' />
                                </IconButton>
                                <Button variant='contained' className={!detailTab ? 'selected_tab' : 'unselected_tab'}
                                    onClick={() => { setDetailTab(0) }}
                                >Alert Summary</Button>
                                <Button variant='contained' className={detailTab ? 'selected_tab' : 'unselected_tab'}
                                    onClick={() => { setDetailTab(1) }}
                                >Alert Plots</Button>
                            </Grid>
                            <Grid item xs={6} className='alert_plot_button'>
                                {RenderAlertPlotButtons(detailTab, props)}
                            </Grid>
                        </Grid>
                        {renderAlertDetail()}
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card style={{ position: 'relative' }}>
                        <CardContent className='alert_info_card' style={{ paddingTop: '6px' }}>
                            {AlertInfo(props, stateData)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ShelvedModal
                visible={stateData.shelvedModalVisibility}
                handleCancel={() => { stateData.setShelvedModalVisibility(false) }}
                handleOk={stateData.handleShelvedModalOk}
                mechademyAction={stateData.mechademyActions}
            />
        </div>
    )
}

export default RenderPcView
