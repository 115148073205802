import Type from "../Types/synchronousMotor.type";

const synchronousMotorConfigData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  componentId: "",
  /** Performace data */
  performanceData: {
    ratedPowerOutput: {},
    ratedCurrent: {},
    synchronousSpeed: {},
    fullLoadSpeed: {},
    fullLoadTorque: {},
    rotorGD2AtFLRPM: {},
    rotorWeight: {},
    lockedRotortorque: {},
    pullInTorque: {},
    pullOutTorque: {},
    lockedRotorCurrent: {},
    lockedRotorPF: {},
    lockedRotorWithstandTimeCold: {},
    lockedRotorWithstandTimeRatedTemp: {},
    ratedMotorFieldCurrent: {},
    ratedMotorFieldVoltage: {},
    ratedExciterFieldCurrent: {},
    ratedExciterFieldVoltage: {},
    temperatureRiseAtZeroCurrent: {},
  },
  /** motor parameter data */
  motorParameterData: {
    motorRatedVoltage: {},
    ratedVoltageSaturatedSubtransientReactance: {},
    ratedVoltageSaturatedNegativeSequenceReactance: {},
    ratedVoltageSaturatedArmatureTimeConstant: {},
    armatureLeakageReactance: {},
    directAxisArmatureReactance: {},
    saturatedDirectAxisSynchronousReactance: {},
    unsaturatedDirectAxisSynchronousReactance: {},
    potierReactance: {},
    saturatedDirectAxisTransientReactance: {},
    unsaturatedDirectAxisTransientReactance: {},
    saturatedDirectAxisSubtransientReactance: {},
    unsaturatedDirectAxisSubtransientReactance: {},
    quadratureAxisArmatureReactance: {},
    saturatedQuadratureAxisSynchronousReactance: {},
    unsaturatedQuadratureAxisSynchronousReactance: {},
    fieldLeakageReactance: {},
    saturatedQuadratureAxisTransientReactance: {},
    unsaturatedQuadratureAxisTransientReactance: {},
    saturatedQuadratureAxisSubtransientReactance: {},
    unsaturatedQuadratureAxisSubtransientReactance: {},
    quadratureAxisAmortisseurReactance: {},
    directAxisAmortisseurReactance: {},
    saturatedNegativeSequenceReactance: {},
    unsaturatedNegativeSequenceReactance: {},
    statorArmatureResistance: {},
    withoutFdrsFieldResistance: {},
    withFdrsFieldResistance: {},
    quadratureAxisAmortisseurResistance: {},
    directAxisAmortisseurResistance: {},
    positiveSequenceResistance: {},
    negativeSequenceResistance: {},
    armatureShortCircuitTimeConstant: {},
    directAxisTransientOpenCircuitTimeConstant: {},
    quadratureAxisTransientOpenCircuitTimeConstant: {},
    directAxisSubtransientOpenCircuitTimeConstant: {},
    quadratureAxisSubtransientOpenCircuitTimeConstant: {},
    directAxisTransientShortCircuitTimeConstant: {},
    directAxisSubtransientShortCircuitTimeConstant: {},
    windingCapacitancetoGround: {},
    i22tLimit: null,
  },
  /** Perforrmance curve */
  performanceCurvesData: {
    data: [],
    dataUnit: {},
  },
  /** Starting char curve */
  startingCharacteristicsCurvesData: {
    data: [],
    dataUnit: {},
  },
  variableSpeedCurvesData: {
    data: [],
    dataUnit: {},
    driveType: "",
    driveManufacturer: "",
    driveModel: "",
    lineSideConverter: "",
    loadSideInverter: "",
    speedControl: "",
  },
  /** Design data */
  designData: {
    dutyType: "",
    numberOfPoles: null,
    voltage: {},
    frequency: {},
    serviceFactor: null,
    insulationClass: "",
    temperatureClass: "",
    nemaEnclosureType: "",
    enclosureIPCode: "",
    icCode: "",
    imCode: "",
    speedType: "",
    speedSignalRatio: null,
    bearingType: "",
    bearingQuantity: null,
    rotorConstruction: "",
  },
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addMotorParameterData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addPerformanceData = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addPerformanceCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addStartingCharCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};
const addVarSpeedCurve = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const addDriveTypes = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: {},
};

const getDriveType = {
  isLoading: false,
  isError: false,
  errorCode: "",
  apiMsg: "",
  isSuccess: false,
  data: [],
};

const INITIAL_STATE = {
  synchronousMotorConfigData: { ...synchronousMotorConfigData },
  addDesignData: { ...addDesignData },
  addPerformanceData: { ...addPerformanceData },
  addPerformanceCurve: { ...addPerformanceCurve },
  addStartingCharCurve: { ...addStartingCharCurve },
  addVarSpeedCurve: { ...addVarSpeedCurve },
  addDriveTypes: { ...addDriveTypes },
  getDriveType: { ...getDriveType },
  addMotorParameterData: { ...addMotorParameterData },
};

export default function synchronousMotorReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        synchronousMotorConfigData: {
          ...INITIAL_STATE.synchronousMotorConfigData,
          isLoading: true,
        },
      };
    case Type.GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        synchronousMotorConfigData: {
          ...state.synchronousMotorConfigData,
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          performanceData: action.payload.performanceData
            ? {
              ratedPowerOutput:
                  action.payload.performanceData.ratedPowerOutput ||
                  action.payload.performanceData.ratedPowerOutput === 0
                    ? action.payload.performanceData.ratedPowerOutput
                    : {},
              ratedCurrent:
                  action.payload.performanceData.ratedCurrent ||
                  action.payload.performanceData.ratedCurrent === 0
                    ? action.payload.performanceData.ratedCurrent
                    : {},
              synchronousSpeed: action.payload.performanceData
                .synchronousSpeed
                ? action.payload.performanceData.synchronousSpeed
                : {},
              fullLoadSpeed: action.payload.performanceData.fullLoadSpeed
                ? action.payload.performanceData.fullLoadSpeed
                : {},
              fullLoadTorque: action.payload.performanceData.fullLoadTorque
                ? action.payload.performanceData.fullLoadTorque
                : {},
              rotorGD2AtFLRPM: action.payload.performanceData.rotorGD2AtFLRPM
                ? action.payload.performanceData.rotorGD2AtFLRPM
                : {},
              rotorWeight: action.payload.performanceData.rotorWeight
                ? action.payload.performanceData.rotorWeight
                : {},
              lockedRotortorque: action.payload.performanceData
                .lockedRotortorque
                ? action.payload.performanceData.lockedRotortorque
                : {},
              pullInTorque: action.payload.performanceData.pullInTorque
                ? action.payload.performanceData.pullInTorque
                : {},
              pullOutTorque: action.payload.performanceData.pullOutTorque
                ? action.payload.performanceData.pullOutTorque
                : {},
              lockedRotorCurrent: action.payload.performanceData
                .lockedRotorCurrent
                ? action.payload.performanceData.lockedRotorCurrent
                : {},
              lockedRotorPF: action.payload.performanceData.lockedRotorPF
                ? action.payload.performanceData.lockedRotorPF
                : {},
              lockedRotorWithstandTimeCold:
                  action.payload.performanceData.lockedRotorWithstandTimeCold ||
                  action.payload.performanceData
                    .lockedRotorWithstandTimeCold === 0
                    ? action.payload.performanceData
                      .lockedRotorWithstandTimeCold
                    : {},
              lockedRotorWithstandTimeRatedTemp:
                  action.payload.performanceData
                    .lockedRotorWithstandTimeRatedTemp ||
                  action.payload.performanceData
                    .lockedRotorWithstandTimeRatedTemp === 0
                    ? action.payload.performanceData
                      .lockedRotorWithstandTimeRatedTemp
                    : {},
              ratedMotorFieldCurrent: action.payload.performanceData
                .ratedMotorFieldCurrent
                ? action.payload.performanceData.ratedMotorFieldCurrent
                : {},
              ratedMotorFieldVoltage: action.payload.performanceData
                .ratedMotorFieldVoltage
                ? action.payload.performanceData.ratedMotorFieldVoltage
                : {},
              ratedExciterFieldCurrent: action.payload.performanceData
                .ratedExciterFieldCurrent
                ? action.payload.performanceData.ratedExciterFieldCurrent
                : {},
              ratedExciterFieldVoltage: action.payload.performanceData
                .ratedExciterFieldVoltage
                ? action.payload.performanceData.ratedExciterFieldVoltage
                : {},
              temperatureRiseAtZeroCurrent: action.payload.performanceData
                .temperatureRiseAtZeroCurrent
                ? action.payload.performanceData.temperatureRiseAtZeroCurrent
                : {},
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData.performanceData,
            },
          /* Motor Parameter Data */
          motorParameterData: action.payload.motorParameterData
            ? {
              motorRatedVoltage:
                  action.payload.motorParameterData.motorRatedVoltage ||
                  action.payload.motorParameterData.motorRatedVoltage === 0
                    ? action.payload.motorParameterData.motorRatedVoltage
                    : {},
              ratedVoltageSaturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                      .ratedVoltageSaturatedNegativeSequenceReactance
                    : {},
              ratedVoltageSaturatedSubtransientReactance:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedSubtransientReactance ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedSubtransientReactance === 0
                    ? action.payload.motorParameterData
                      .ratedVoltageSaturatedSubtransientReactance
                    : {},
              ratedVoltageSaturatedArmatureTimeConstant:
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedArmatureTimeConstant ||
                  action.payload.motorParameterData
                    .ratedVoltageSaturatedArmatureTimeConstant === 0
                    ? action.payload.motorParameterData
                      .ratedVoltageSaturatedArmatureTimeConstant
                    : {},
              armatureLeakageReactance:
                  action.payload.motorParameterData.armatureLeakageReactance ||
                  action.payload.motorParameterData.armatureLeakageReactance ===
                    0
                    ? action.payload.motorParameterData.armatureLeakageReactance
                    : {},
              directAxisArmatureReactance:
                  action.payload.motorParameterData
                    .directAxisArmatureReactance ||
                  action.payload.motorParameterData
                    .directAxisArmatureReactance === 0
                    ? action.payload.motorParameterData
                      .directAxisArmatureReactance
                    : {},
              saturatedDirectAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedDirectAxisSynchronousReactance
                    : {},
              unsaturatedDirectAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedDirectAxisSynchronousReactance
                    : {},
              potierReactance:
                  action.payload.motorParameterData.potierReactance ||
                  action.payload.motorParameterData.potierReactance === 0
                    ? action.payload.motorParameterData.potierReactance
                    : {},
              saturatedDirectAxisTransientReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisTransientReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedDirectAxisTransientReactance
                    : {},
              unsaturatedDirectAxisTransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisTransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedDirectAxisTransientReactance
                    : {},
              saturatedDirectAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .saturatedDirectAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .saturatedDirectAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedDirectAxisSubtransientReactance
                    : {},
              unsaturatedDirectAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedDirectAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedDirectAxisSubtransientReactance
                    : {},
              quadratureAxisArmatureReactance:
                  action.payload.motorParameterData
                    .quadratureAxisArmatureReactance ||
                  action.payload.motorParameterData
                    .quadratureAxisArmatureReactance === 0
                    ? action.payload.motorParameterData
                      .quadratureAxisArmatureReactance
                    : {},
              saturatedQuadratureAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedQuadratureAxisSynchronousReactance
                    : {},
              unsaturatedQuadratureAxisSynchronousReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSynchronousReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSynchronousReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedQuadratureAxisSynchronousReactance
                    : {},
              fieldLeakageReactance:
                  action.payload.motorParameterData.fieldLeakageReactance ||
                  action.payload.motorParameterData.fieldLeakageReactance === 0
                    ? action.payload.motorParameterData.fieldLeakageReactance
                    : {},
              saturatedQuadratureAxisTransientReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisTransientReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedQuadratureAxisTransientReactance
                    : {},
              unsaturatedQuadratureAxisTransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisTransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisTransientReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedQuadratureAxisTransientReactance
                    : {},
              saturatedQuadratureAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .saturatedQuadratureAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedQuadratureAxisSubtransientReactance
                    : {},
              unsaturatedQuadratureAxisSubtransientReactance:
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSubtransientReactance ||
                  action.payload.motorParameterData
                    .unsaturatedQuadratureAxisSubtransientReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedQuadratureAxisSubtransientReactance
                    : {},
              quadratureAxisAmortisseurReactance:
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurReactance ||
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurReactance === 0
                    ? action.payload.motorParameterData
                      .quadratureAxisAmortisseurReactance
                    : {},
              directAxisAmortisseurReactance:
                  action.payload.motorParameterData
                    .directAxisAmortisseurReactance ||
                  action.payload.motorParameterData
                    .directAxisAmortisseurReactance === 0
                    ? action.payload.motorParameterData
                      .directAxisAmortisseurReactance
                    : {},
              saturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .saturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .saturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                      .saturatedNegativeSequenceReactance
                    : {},
              unsaturatedNegativeSequenceReactance:
                  action.payload.motorParameterData
                    .unsaturatedNegativeSequenceReactance ||
                  action.payload.motorParameterData
                    .unsaturatedNegativeSequenceReactance === 0
                    ? action.payload.motorParameterData
                      .unsaturatedNegativeSequenceReactance
                    : {},
              statorArmatureResistance:
                  action.payload.motorParameterData.statorArmatureResistance ||
                  action.payload.motorParameterData.statorArmatureResistance ===
                    0
                    ? action.payload.motorParameterData.statorArmatureResistance
                    : {},
              withoutFdrsFieldResistance:
                  action.payload.motorParameterData
                    .withoutFdrsFieldResistance ||
                  action.payload.motorParameterData
                    .withoutFdrsFieldResistance === 0
                    ? action.payload.motorParameterData
                      .withoutFdrsFieldResistance
                    : {},
              withFdrsFieldResistance:
                  action.payload.motorParameterData.withFdrsFieldResistance ||
                  action.payload.motorParameterData.withFdrsFieldResistance ===
                    0
                    ? action.payload.motorParameterData.withFdrsFieldResistance
                    : {},
              quadratureAxisAmortisseurResistance:
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurResistance ||
                  action.payload.motorParameterData
                    .quadratureAxisAmortisseurResistance === 0
                    ? action.payload.motorParameterData
                      .quadratureAxisAmortisseurResistance
                    : {},
              directAxisAmortisseurResistance:
                  action.payload.motorParameterData
                    .directAxisAmortisseurResistance ||
                  action.payload.motorParameterData
                    .directAxisAmortisseurResistance === 0
                    ? action.payload.motorParameterData
                      .directAxisAmortisseurResistance
                    : {},
              positiveSequenceResistance:
                  action.payload.motorParameterData
                    .positiveSequenceResistance ||
                  action.payload.motorParameterData
                    .positiveSequenceResistance === 0
                    ? action.payload.motorParameterData
                      .positiveSequenceResistance
                    : {},
              negativeSequenceResistance:
                  action.payload.motorParameterData
                    .negativeSequenceResistance ||
                  action.payload.motorParameterData
                    .negativeSequenceResistance === 0
                    ? action.payload.motorParameterData
                      .negativeSequenceResistance
                    : {},
              armatureShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .armatureShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .armatureShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .armatureShortCircuitTimeConstant
                    : {},
              directAxisTransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisTransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisTransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .directAxisTransientOpenCircuitTimeConstant
                    : {},
              quadratureAxisTransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .quadratureAxisTransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .quadratureAxisTransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .quadratureAxisTransientOpenCircuitTimeConstant
                    : {},
              directAxisSubtransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisSubtransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisSubtransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .directAxisSubtransientOpenCircuitTimeConstant
                    : {},
              quadratureAxisSubtransientOpenCircuitTimeConstant:
                  action.payload.motorParameterData
                    .quadratureAxisSubtransientOpenCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .quadratureAxisSubtransientOpenCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .quadratureAxisSubtransientOpenCircuitTimeConstant
                    : {},
              directAxisTransientShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisTransientShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisTransientShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .directAxisTransientShortCircuitTimeConstant
                    : {},
              directAxisSubtransientShortCircuitTimeConstant:
                  action.payload.motorParameterData
                    .directAxisSubtransientShortCircuitTimeConstant ||
                  action.payload.motorParameterData
                    .directAxisSubtransientShortCircuitTimeConstant === 0
                    ? action.payload.motorParameterData
                      .directAxisSubtransientShortCircuitTimeConstant
                    : {},
              windingCapacitancetoGround:
                  action.payload.motorParameterData
                    .windingCapacitancetoGround ||
                  action.payload.motorParameterData
                    .windingCapacitancetoGround === 0
                    ? action.payload.motorParameterData
                      .windingCapacitancetoGround
                    : {},
              i22tLimit:
                  action.payload.motorParameterData.i22tLimit ||
                  action.payload.motorParameterData.i22tLimit === 0
                    ? action.payload.motorParameterData.i22tLimit
                    : null,
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData.motorParameterData,
            },

          /** Perforrmance curve */
          performanceCurvesData: action.payload.performanceCurvesData
            ? {
              data: action.payload.performanceCurvesData.data
                ? action.payload.performanceCurvesData.data
                : {},
              dataUnit: action.payload.performanceCurvesData.dataUnit
                ? action.payload.performanceCurvesData.dataUnit
                : {},
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData
                .performanceCurvesData,
            },
          /** Starting char curve */
          startingCharacteristicsCurvesData: action.payload
            .startingCharacteristicsCurvesData
            ? {
              data: action.payload.startingCharacteristicsCurvesData.data
                ? action.payload.startingCharacteristicsCurvesData.data
                : {},
              dataUnit: action.payload.startingCharacteristicsCurvesData
                .dataUnit
                ? action.payload.startingCharacteristicsCurvesData.dataUnit
                : {},
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData
                .startingCharacteristicsCurvesData,
            },
          variableSpeedCurvesData: action.payload.variableSpeedCurvesData
            ? {
              data: action.payload.variableSpeedCurvesData.data
                ? action.payload.variableSpeedCurvesData.data
                : {},
              dataUnit: action.payload.variableSpeedCurvesData.dataUnit
                ? action.payload.variableSpeedCurvesData.dataUnit
                : {},
              driveType: action.payload.variableSpeedCurvesData.driveType
                ? action.payload.variableSpeedCurvesData.driveType
                : "",
              driveManufacturer: action.payload.variableSpeedCurvesData
                .driveManufacturer
                ? action.payload.variableSpeedCurvesData.driveManufacturer
                : "",
              driveModel: action.payload.variableSpeedCurvesData.driveModel
                ? action.payload.variableSpeedCurvesData.driveModel
                : "",
              lineSideConverter: action.payload.variableSpeedCurvesData
                .lineSideConverter
                ? action.payload.variableSpeedCurvesData.lineSideConverter
                : "",
              loadSideInverter: action.payload.variableSpeedCurvesData
                .loadSideInverter
                ? action.payload.variableSpeedCurvesData.loadSideInverter
                : "",
              speedControl: action.payload.variableSpeedCurvesData
                .speedControl
                ? action.payload.variableSpeedCurvesData.speedControl
                : "",
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData
                .variableSpeedCurvesData,
            },
          /** Design data */
          designData: action.payload.designData
            ? {
              dutyType:
                  action.payload.designData.dutyType ||
                  action.payload.designData.dutyType === 0
                    ? action.payload.designData.dutyType
                    : null,
              numberOfPoles:
                  action.payload.designData.numberOfPoles ||
                  action.payload.designData.numberOfPoles === 0
                    ? action.payload.designData.numberOfPoles
                    : null,
              voltage:
                  action.payload.designData.voltage ||
                  action.payload.designData.voltage === 0
                    ? action.payload.designData.voltage
                    : {},
              frequency:
                  action.payload.designData.frequency ||
                  action.payload.designData.frequency === 0
                    ? action.payload.designData.frequency
                    : {},
              serviceFactor:
                  action.payload.designData.serviceFactor ||
                  action.payload.designData.serviceFactor === 0
                    ? action.payload.designData.serviceFactor
                    : null,
              insulationClass:
                  action.payload.designData.insulationClass ||
                  action.payload.designData.insulationClass === 0
                    ? action.payload.designData.insulationClass
                    : null,
              temperatureClass:
                  action.payload.designData.temperatureClass ||
                  action.payload.designData.temperatureClass === 0
                    ? action.payload.designData.temperatureClass
                    : null,
              nemaEnclosureType:
                  action.payload.designData.nemaEnclosureType ||
                  action.payload.designData.nemaEnclosureType === 0
                    ? action.payload.designData.nemaEnclosureType
                    : null,
              enclosureIPCode:
                  action.payload.designData.enclosureIPCode ||
                  action.payload.designData.enclosureIPCode === 0
                    ? action.payload.designData.enclosureIPCode
                    : null,
              icCode:
                  action.payload.designData.icCode ||
                  action.payload.designData.icCode === 0
                    ? action.payload.designData.icCode
                    : null,
              imCode:
                  action.payload.designData.imCode ||
                  action.payload.designData.imCode === 0
                    ? action.payload.designData.imCode
                    : null,
              speedType:
                  action.payload.designData.speedType ||
                  action.payload.designData.speedType === 0
                    ? action.payload.designData.speedType
                    : null,
              speedSignalRatio:
                  action.payload.designData.speedSignalRatio ||
                  action.payload.designData.speedSignalRatio === 0
                    ? action.payload.designData.speedSignalRatio
                    : null,
              bearingType:
                  action.payload.designData.bearingType ||
                  action.payload.designData.bearingType === 0
                    ? action.payload.designData.bearingType
                    : null,
              bearingQuantity:
                  action.payload.designData.bearingQuantity ||
                  action.payload.designData.bearingQuantity === 0
                    ? action.payload.designData.bearingQuantity
                    : null,
              rotorConstruction:
                  action.payload.designData.rotorConstruction ||
                  action.payload.designData.rotorConstruction === 0
                    ? action.payload.designData.rotorConstruction
                    : null,
            }
            : {
              ...INITIAL_STATE.synchronousMotorConfigData.designData,
            },
        },
      };
    case Type.GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_FAILURE:
      return {
        ...state,
        synchronousMotorConfigData: {
          ...state.synchronousMotorConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_MOTOR_CONFIG_DATA_RESET:
      return {
        ...state,
        motorConfigData: {
          ...state.motorConfigData,
          isLoading: false,
          isError: false,
          errorCode: "",
          apiMsg: "",
          isSuccess: false,
          componentId: "",
        },
      };

    /** Add design data reducer */
    case Type.ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    /** Add performance data */
    case Type.ADD_PERFORMANCE_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
          isLoading: true,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_PERFORMANCE_DATA_RESET:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
        },
      };
      /** Add motor parameter data */

    case Type.ADD_MOTOR_PARAMETER_DATA_PROGRESS:
      return {
        ...state,
        addMotorParameterData: {
          ...INITIAL_STATE.addMotorParameterData,
          isLoading: true,
        },
      };
    case Type.ADD_MOTOR_PARAMETER_DATA_SUCCESS:
      return {
        ...state,
        addMotorParameterData: {
          ...state.addMotorParameterData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_MOTOR_PARAMETER_DATA_FAILURE:
      return {
        ...state,
        addMotorParameterData: {
          ...state.addMotorParameterData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_MOTOR_PARAMETER_DATA_RESET:
      return {
        ...state,
        addMotorParameterData: {
          ...INITIAL_STATE.addMotorParameterData,
        },
      };

    /** Add performace curve data  */
    case Type.ADD_PERFORMANCE_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
          isLoading: true,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_FAILURE:
      return {
        ...state,
        addPerformanceCurve: {
          ...state.addPerformanceCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_PERFORMANCE_CURVES_DATA_RESET:
      return {
        ...state,
        addPerformanceCurve: {
          ...INITIAL_STATE.addPerformanceCurve,
        },
      };

    /** Add starting char curve */
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
          isLoading: true,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE:
      return {
        ...state,
        addStartingCharCurve: {
          ...state.addStartingCharCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_RESET:
      return {
        ...state,
        addStartingCharCurve: {
          ...INITIAL_STATE.addStartingCharCurve,
        },
      };

    /** Add variable speed curve */
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
          isLoading: true,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_SUCCESS:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_FAILURE:
      return {
        ...state,
        addVarSpeedCurve: {
          ...state.addVarSpeedCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.ADD_VARIABLE_SPEED_CURVES_DATA_RESET:
      return {
        ...state,
        addVarSpeedCurve: {
          ...INITIAL_STATE.addVarSpeedCurve,
        },
      };

    /** Save Drive Type */
    case Type.ADD_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        addDriveTypes: {
          ...INITIAL_STATE.addDriveTypes,
          isLoading: true,
        },
      };
    case Type.ADD_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.ADD_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        addDriveTypes: {
          ...state.addDriveTypes,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Get Drive Type */
    case Type.GET_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        getDriveType: {
          ...INITIAL_STATE.getDriveType,
          isLoading: true,
        },
        addDriveTypes: {
          ...state.addDriveTypes,
          isSuccess: false,
          isError: false,
        },
      };
    case Type.GET_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: true,
          data: action.payload.items ? action.payload.items : [],
        },
      };
    case Type.GET_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        getDriveType: {
          ...state.getDriveType,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };

    /** Reset synchronous motor reducer */
    case Type.RESET_MOTOR_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
