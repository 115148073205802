import Type from '../Types/hydraulicVariableSpeedGear.types';

const HVSGConfigData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  componentId: '',
  designData: {},
  performanceData: {},
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addPerformanceData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addGearData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addWorkingFluidData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const HVSGCurvesData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  isUpdateSuccess: false,
  isUpdateError:false,
  componentId: '',
  curvesData: {},
};

const deleteGearboxCurves = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorCode: null,
  message: null,
};


const INITIAL_STATE = {
  HVSGConfigData: { ...HVSGConfigData },
  addDesignData: { ...addDesignData },
  addPerformanceData: { ...addPerformanceData },
  addWorkingFluidData: { ...addWorkingFluidData },
  addGearData: { ...addGearData },
  HVSGCurvesData: { ...HVSGCurvesData },
  deleteGearboxCurves: {...deleteGearboxCurves},
};


export default function HydraulicVariableSpeedGearComponentReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_HVSG_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        HVSGConfigData: {
          ...INITIAL_STATE.HVSGConfigData,
          isLoading: true,
        },
      };
    case Type.GET_HVSG_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        HVSGConfigData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          designData: action.payload.designData
            ? action.payload.designData
            : {
                ...INITIAL_STATE.HVSGConfigData.designData,
              },
          performanceData: action.payload
            ? action.payload.performanceData
            : {
                ...INITIAL_STATE.HVSGConfigData.performanceData,
              },
          workingFluidData: action.payload.workingFluidData
            ? action.payload.workingFluidData
            : {
                ...INITIAL_STATE.HVSGConfigData.workingFluidData,
              },
          gearData: action.payload
            ? action.payload.gearData
            : {
                ...INITIAL_STATE.HVSGConfigData.gearData,
              },
        },
      };
    case Type.GET_HVSG_CONFIG_DATA_FAILURE:
      return {
        ...state,
        HVSGConfigData: {
          ...state.HVSGConfigData,
          isLoading: false,
          isSuccess: false,
          isUpdateError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_HVSG_CONFIG_DATA_RESET:
      return {
        ...state,
        HVSGConfigData: {
          ...state.HVSGConfigData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          componentId: '',
        },
      };

    case Type.UPDATE_HVSG_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.UPDATE_HVSG_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        HVSGConfigData: {
          ...HVSGConfigData,
          designData: action.payload.designData,
        },
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_HVSG_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addIterationData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_HVSG_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };
    case Type.UPDATE_HVSG_PERFORMANCE_DATA_PROGRESS:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
          isLoading: true,
        },
      };
    case Type.UPDATE_HVSG_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        HVSGConfigData: {
          ...HVSGConfigData,
          PerformanceData: action.payload.PerformanceData,
        },
        addPerformanceData: {
          ...state.addPerformanceData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_HVSG_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        addPerformanceData: {
          ...state.addIterationData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_HVSG_PERFORMANCE_DATA_RESET:
      return {
        ...state,
        addPerformanceData: {
          ...INITIAL_STATE.addPerformanceData,
        },
      };
    case Type.UPDATE_HVSG_WORKING_FLUID_DATA_PROGRESS:
      return {
        ...state,
        addWorkingFluidData: {
          ...INITIAL_STATE.addWorkingFluidData,
          isLoading: true,
        },
      };
    case Type.UPDATE_HVSG_WORKING_FLUID_DATA_SUCCESS:
      return {
        ...state,
        HVSGConfigData: {
          ...HVSGConfigData,
          WorkingFluidData: action.payload.workingFluidData,
        },
        addWorkingFluidData: {
          ...state.addWorkingFluidData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_HVSG_WORKING_FLUID_DATA_FAILURE:
      return {
        ...state,
        addWorkingFluidData: {
          ...state.addWorkingFluidData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_HVSG_WORKING_FLUID_DATA_RESET:
      return {
        ...state,
        addWorkingFluidData: {
          ...INITIAL_STATE.addWorkingFluidData,
        },
      };
    case Type.GET_HVSG_CURVES_DATA_PROGRESS:
      return {
        ...state,
        HVSGCurvesData: {
          ...INITIAL_STATE.HVSGCurvesData,
          isLoading: true,
        },
      };
    case Type.GET_HVSG_CURVES_DATA_SUCCESS:
      return {
        ...state,
        HVSGCurvesData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          curvesData: action.payload.curvesData
            ? action.payload.curvesData
            : {
                ...INITIAL_STATE.HVSGCurvesData.curvesData,
              },
        },
      };
    case Type.GET_HVSG_CURVES_DATA_FAILURE:
      return {
        ...state,
        HVSGCurvesData: {
          ...state.HVSGCurvesData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_HVSG_CURVES_DATA_RESET:
      return {
        ...state,
        HVSGCurvesData: {
          ...state.HVSGCurvesData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          componentId: '',
        },
      };
    case Type.UPDATE_HVSG_CURVES_DATA_PROGRESS:
      return {
        ...state,
        HVSGCurvesData: {
          ...INITIAL_STATE.HVSGCurvesData,
          isLoading: true,
        },
      };
    case Type.UPDATE_HVSG_CURVES_DATA_SUCCESS:
      return {
        ...state,
        HVSGCurvesData: {
          ...HVSGCurvesData,
          curvesData: action.payload.curvesData,
          isLoading: false,
          isUpdateSuccess: true,
        },
      };
    case Type.UPDATE_HVSG_CURVES_DATA_FAILURE:
      return {
        ...state,
        HVSGCurvesData: {
          ...state.HVSGCurvesData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_HVSG_CURVES_DATA_RESET:
      return {
        ...state,
        HVSGCurvesData: {
          ...INITIAL_STATE.HVSGCurvesData,
        },
      };
      case Type.DELETE_HVSG_CURVES_DATA_PROGRESS:
        return {
          ...state,
          deleteGearboxCurves: {
            ...state.deleteGearboxCurves,
            isSuccess: false,
            isError: false,
            isLoading: true,
            errorCode: "",
            message: "",
          },
        };
      case Type.DELETE_HVSG_CURVES_DATA_SUCCESS:
        return {
          ...state,
          deleteGearboxCurves: {
            ...state.deleteGearboxCurves,
            isSuccess: true,
            isError: false,
            isLoading: false,
            errorCode: "",
            message: "",
          },
        };
      case Type.DELETE_HVSG_CURVES_DATA_FAILURE:
        return {
          ...state,
          deleteGearboxCurves: {
            ...state.deleteGearboxCurves,
            isLoading: false,
            isError: true,
            isSuccess: false,
            errorCode:
              action.payload.response && action.payload.response.body.code,
            message:
              action.payload.response && action.payload.response.body.message,
          },
        };
      case Type.DELETE_HVSG_CURVES_DATA_RESET:
        return {
          ...state,
          deleteGearboxCurves: {
            ...state.deleteGearboxCurves,
            isSuccess: false,
            isError: false,
            isLoading: false,
            errorCode: "",
            message: "",
          },
        };
    case Type.RESET_HVSG_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
