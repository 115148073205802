import Type from '../Types/steamTurbine.types';

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

export default function steamTurbineReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.UPDATE_ST_CURVES_DATA_PROGRESS:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case Type.UPDATE_ST_CURVES_DATA_SUCCESS:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.curvesData,
      };
    case Type.UPDATE_ST_CURVES_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        apiMsg: action.payload.response && action.payload.response.body.message,
      };
    case Type.UPDATE_ST_CURVES_DATA_RESET:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorCode: '',
        apiMsg: '',
      };
    case Type.GET_ST_CURVES_DATA_SUCCESS:
      const tempData = action ? action.payload ? action.payload.curvesData : null : null;
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: tempData,
      };
    case Type.GET_ST_CURVES_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        apiMsg: action.payload.response && action.payload.response.body.message,
      };
    default:
      return {
        ...state,
      };
  }
}
