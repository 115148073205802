import mergeByKey from 'array-merge-by-key';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import Loader from '../../../../common/Loader/Loader';
import ConfirmModal from '../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import {
  CalculatedDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../../constants/messages';
import { ButtonVariables } from '../../../../constants/variables';
import {
  addCalculatedData,
  clearState,
  getCalculatedData,
  getVariableName,
} from '../../../../redux/actions/calculatedData.actions';
import {
  addRowColumns,
  calculatedTableColumns,
} from './CalculatedData.constants';
import './CalculatedData.scss';

class CalculatedData extends React.Component {
  // states are declared here
  constructor(props) {
    super(props);
    this.state = {
      dirtyRows: [],
      formEdit: false,
      calculatedData: [],
      preserveCalculatedData: [],
      selectedVariableName: '',
      selectedVariableProperty: '',
      disabled: true,
      variableNameData: [],
      preserveVariableNameData: [],
      editRows: [],
      updatedFields: [],
      tag: '',
      min_max_same: false,
      min_greater: false,
      dirtyField: false,
      addFieldValues: {
        tag: null,
        variableName: null,
        variableProperty: 'Percentage',
        units: '%',
        maxBound: null,
        minBound: null,
        displayPiTag: '',
      },
      isError: {
        tag: false,
        variableName: false,
        variableProperty: false,
        units: false,
        maxBound: false,
        minBound: false,
      },
    };
  }

  // edit form handlers
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  // network handler
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  // component mounted
  componentDidMount() {
    const componentId = this.props.schematicState.schematicComponentData
      ? this.props.schematicState.schematicComponentData._id
      : this.props.location.state.componentData.info._id;

    if (this.handleNetwork()) {
      this.props.getVariableName(componentId);
      this.getCalculatedData();
    }
    // event listeners
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }

  // component update
  componentDidUpdate() {
    const {
      isVariableData,
      variableData,
      calculatedDataRecieved,
      calculatedDataAdded,
      getCalculatedData,
      isCalculatedDataAdded,
      isCalculatedDataAddError,
      isVariableGetError,
      isCalculatedDataRecievedError,
      errorCode,
    } = this.props.calculatedDataState.apiState;

    if (isCalculatedDataAdded) {
      this.props.clearState();
      this.disableEditForm();
      Notification.show(
        Types.Success,
        CalculatedDataMessages.CALCULATED_DATA_ADDED
      );
      const transformedData = calculatedDataAdded
        ? this.transformRecievedData(calculatedDataAdded)
        : null;
      if (!_.isEmpty(transformedData)) {
        this.setCalculatedData(transformedData);
      }
    }

    if (isVariableData) {
      this.props.clearState();
      this.setState(
        {
          variableNameData: variableData,
          preserveVariableNameData: variableData,
        },
        () => {
          this.filterUniqueDataVariableList();
        }
      );
    }
    if (getCalculatedData) {
      this.props.clearState();
      this.disableEditForm();
      const tempDataRecieved = this.transformRecievedData(
        calculatedDataRecieved
      );
      if (tempDataRecieved) {
        this.setCalculatedData(tempDataRecieved);
      }
    }
    if (isCalculatedDataAddError) {
      this.props.clearState();
      Notification.show(Types.Error, CalculatedDataMessages[errorCode]);
    }
    if (isCalculatedDataRecievedError) {
      this.props.clearState();
      Notification.show(Types.Error, CalculatedDataMessages[errorCode]);
    }
    if (isVariableGetError) {
      this.props.clearState();
      Notification.show(Types.Error, CalculatedDataMessages[errorCode]);
    }
  }

  // get calculated data api fetch
  getCalculatedData = () => {
    const componentId = this.props.schematicState.schematicComponentData
      ? this.props.schematicState.schematicComponentData._id
      : this.props.location.state.componentData.info._id;

    const equipmentId = this.props.location.state.equipmentData._id;
    const { getCalculatedData } = this.props;
    if (this.handleNetwork()) {
      getCalculatedData(equipmentId, componentId);
    }
  };

  // set calculated data function
  setCalculatedData = (data) => {
    this.setState(
      {
        calculatedData: _.cloneDeep(data),
        preserveCalculatedData: _.cloneDeep(data),
      },
      () => {
        this.filterUniqueDataVariableList();
      }
    );
  };

  // transform recieved data function
  transformRecievedData = (data) => {
    let calculatedDataArray = [];

    if (data && data.config) {
      calculatedDataArray = data.config.map((item) => {
        let { maxBound, minBound, tag, units, variableProperty } = item;
        const { variableName, _id, abbreviation } = item.variable;
        const variable = _id;
        const { tagInfo } = this.props.location.state.componentData.info;
        const displayPiTag = `${tagInfo}-${abbreviation}`;
        minBound = minBound || minBound === 0 ? minBound.toString(): "";
        maxBound = maxBound || maxBound === 0 ? maxBound.toString(): "";
        return {
          variableProperty,
          maxBound,
          minBound,
          tag,
          units,
          variableName,
          variable,
          displayPiTag,
        };
      });
      return calculatedDataArray;
    }
  };

  // transform payload data function
  transformPayloadData = () => {
    const { calculatedData, preserveVariableNameData } = this.state;
    let tempData = _.cloneDeep(calculatedData);
    tempData = tempData.filter((items) => {
      delete items.displayPiTag;
      return items;
    });
    const tempDatas = preserveVariableNameData;
    const tempVariableNameArray = tempData.map((item) => item.variableName);
    const filteredVariableNameData = tempDatas.filter((data) => {
      if (tempVariableNameArray.indexOf(data.variableName) >= 0) return 1;
      return 0;
    });
    const tempVariableData = filteredVariableNameData.map((item) => {
      const variable = item._id;
      const { variableName } = item;
      return {
        variable,
        variableName,
      };
    });

    const result = mergeByKey('variableName', tempData, tempVariableData);
    const tempConfig = result.map((item) => {
      delete item.variableName;
      item.maxBound = parseFloat(item.maxBound);
      item.minBound = parseFloat(item.minBound);
      return item;
    });
    return tempConfig;
  };

  // variable name list filter
  filterUniqueDataVariableList = () => {
    const { preserveVariableNameData, calculatedData } = this.state;
    const { tagNumber } = this.props.location.state.componentData.info;
    const { tagInfo } = this.props.location.state.componentData.info;
    const filteredNameData = preserveVariableNameData.filter((item) => {
      const tempFilter = calculatedData.filter(
        (element) =>
          element.tag === `${tagInfo}-${item.abbreviation}-${tagNumber}` ||
          element.variableName === item.variableName
      );
      if (tempFilter.length > 0) {
        return false;
      }
      return true;
    });
    this.setState({
      variableNameData: _.cloneDeep(filteredNameData),
    });
  };

  // edit rows setter
  setEditRows = () => {
    const { updatedFields, calculatedData } = this.state;
    const tempEditRows = [];
    const tempUpdatedField = [];
    updatedFields.forEach((field, uindex) => {
      field &&
        calculatedData.forEach((data, index) => {
          if (field.tag === data.tag) {
            tempEditRows.push(index);
            tempUpdatedField[index] = updatedFields[uindex];
          }
        });
    });
    this.setState({
      editRows: tempEditRows,
      updatedFields: tempUpdatedField,
    });
  };

  // variable name on change function
  onVariableNameChange = (value) => {
    const tempPiTag = this.getPiTag(value);
    const displayPiTag = this.getAbbreviation(value);
    this.enableEditForm();
    this.setState({
      addFieldValues: {
        ...this.state.addFieldValues,
        tag: tempPiTag,
        variableName: value,
        displayPiTag,
      },
    });
  };

  // get pi tag function
  getPiTag = (data) => {
    const { variableNameData } = this.state;
    const tempTagData = variableNameData
      ? variableNameData.map((items) =>
          items.variableName === data ? items.abbreviation : '0'
        )
      : '';
    const { tagNumber } = this.props.location.state.componentData.info;
    const { tagInfo } = this.props.location.state.componentData.info;
    let item = tempTagData.find((element) => element !== '0');
    item = item ? `${tagInfo}-${item}-${tagNumber}` : '';
    return item;
  };

  // get abbreviation function
  getAbbreviation = (data) => {
    const { variableNameData } = this.state;
    const tempAbbreviation = variableNameData
      ? variableNameData.map((items) =>
          items.variableName === data ? items.abbreviation : '0'
        )
      : '';
    const { tagInfo } = this.props.location.state.componentData.info;
    let item = tempAbbreviation.find((element) => element !== '0');
    item = item ? `${tagInfo}-${item}` : '';
    return item;
  };

  // variable property on change function
  onVariablePropertyChange = (value) => {
    this.setState(
      {
        selectedVariableProperty: value,
      },
      () => {
        this.enableEditForm();
        this.setState({
          addFieldValues: {
            ...this.state.addFieldValues,
            variableProperty: value,
          },
        });
      }
    );
  };

  // on add row change function
  onAddRowChange = (field) => {
    const { addFieldValues } = this.state;
    const temp = { ...addFieldValues };
    temp[field.name] = field.value;
    this.setState(
      {
        addFieldValues: temp,
      },
      () => {
        this.enableEditForm();
        this.validateFields(temp);
      }
    );
  };

  // on edit row change function
  onEditRowChange = (field, row) => {
    const { calculatedData } = this.state;
    const tempCalculatedData = _.cloneDeep(calculatedData);
    if (!tempCalculatedData[row]) {
      tempCalculatedData[row] = {};
    }
    tempCalculatedData[row][field.name] = field.value;
    this.setState(
      {
        calculatedData: tempCalculatedData,
      },
      () => {
        this.validateRowFields(row);
      }
    );
  };

  // on add row submit function
  onAddRow = () => {
    const { addFieldValues } = this.state;
    const temp = _.cloneDeep(this.state.calculatedData);
    if (this.validateFields(addFieldValues)) {
      temp.push(addFieldValues);
      this.setState(
        {
          calculatedData: temp,
          addFieldValues: {
            ...this.state.addFieldValues,
            tag: '',
            variableName: null,
            variableProperty: 'Percentage',
            units: '%',
            maxBound: null,
            minBound: null,
            displayPiTag: '',
          },
        },
        () => {
          this.filterUniqueDataVariableList();
          this.enableEditForm();
        }
      );
    } else if (this.state.dirtyField) {
      Notification.show(Types.Error, CalculatedDataMessages.FIELDS_DIRTY);
    } else if (
      parseFloat(addFieldValues.maxBound) ===
      parseFloat(addFieldValues.minBound)
    ) {
      Notification.show(Types.Info, CalculatedDataMessages.MIN_MAX);
    } else if (
      parseFloat(addFieldValues.minBound) > parseFloat(addFieldValues.maxBound)
    ) {
      Notification.show(Types.Info, CalculatedDataMessages.MIN_MAX);
    } else {
      this.setEditRows();
      Notification.show(Types.Error, CalculatedDataMessages.MANDATORY);
    }
  };

  // form clear function
  clearDirtyForm() {
    this.setState(
      {
        editRows: [],
        updatedFields: [],
        dirtyRows: [],
        formEdit: false,
        addFieldValues: {
          tag: null,
          variableName: null,
          variableProperty: 'Percentage',
          units: '%',
          maxBound: null,
          minBound: null,
        },
        isError: {
          tag: false,
          variableName: false,
          variableProperty: false,
          units: false,
          maxBound: false,
          minBound: false,
        },
      },
      () => {
        this.setCalculatedData(this.state.preserveCalculatedData);
      }
    );
  }

  // field validators
  validateFields(values) {
    let status = true;
    let dirtyField = false;
    const temp = {
      tag: false,
      variableName: false,
      variableProperty: false,
      units: false,
      minBound: false,
      maxBound: false,
    };
    Object.keys(values).forEach((item) => {
      if (item === 'maxBound' || item === 'minBound') {
        if (
          !_.isEmpty(values[item]) // when field data is >= 0
        ) {
          if (parseFloat(values.minBound) >= parseFloat(values.maxBound)) {
            temp[item] = true;
            status = false;
          }
        } else {
          temp[item] = true;
          status = false;
        }
      } else if (!values[item] || values[item].length === 0) {
        temp[item] = true;
        status = false;
      } else {
        temp[item] = false;
      }
    });
    this.setState({
      isError: temp,
      dirtyField,
    });
    return status;
  }

  validateRowFields(values) {
    const { calculatedData, dirtyRows } = this.state;
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let status = false;
    let min_greater = false;
    let dirtyField = false;

    Object.keys(calculatedData[values]).forEach((item) => {
      if (item === 'maxBound' || item === 'minBound') {
        if (!_.isEmpty(calculatedData[values][item])) {
          if (
            parseFloat(calculatedData[values].minBound) >=
            parseFloat(calculatedData[values].maxBound)
          ) {
            min_greater = true;
            status = true;
          }
        } else {
          status = true;
        }
      }
    });

    if (status) {
      tempDirtyRows.push(values);
    } else {
      tempDirtyRows = tempDirtyRows.filter((item) => item !== values);
    }

    this.setState({
      dirtyRows: tempDirtyRows,
      min_greater,
      dirtyField,
    });
  }

  // on save submit function with add calculated data api hit
  save = () => {
    const componentId = this.props.schematicState.schematicComponentData
      ? this.props.schematicState.schematicComponentData._id
      : this.props.location.state.componentData.info._id;
    const equipmentId = this.props.location.state.equipmentData._id;

    if (this.state.dirtyRows.length === 0) {
      const config = this.transformPayloadData();
      const payload = {
        config,
      };
      if (payload.config.length >= 1) {
        if (this.handleNetwork()) {
          this.props.addCalculatedData(payload, equipmentId, componentId);
        }
      } else {
        Notification.show(Types.Info, CalculatedDataMessages.MIN_DATA);
      }
    } else if (this.state.min_greater) {
      Notification.show(Types.Info, CalculatedDataMessages.MIN_MAX);
    } else if (this.state.dirtyField) {
      Notification.show(Types.Error, CalculatedDataMessages.FIELDS_DIRTY);
    } else {
      Notification.show(Types.Error, CalculatedDataMessages.MANDATORY);
    }
  };

  // undo row function
  undo = (row) => {
    const { updatedFields, calculatedData, dirtyRows } = this.state;
    const updatedTemp = _.cloneDeep(calculatedData);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempupdatedField = _.cloneDeep(updatedFields);
    updatedTemp[row] = _.cloneDeep(tempupdatedField[row]);
    tempDirtyRows = tempDirtyRows.filter((element) => element !== row);
    tempupdatedField[row] = undefined;
    this.setState(
      {
        calculatedData: _.cloneDeep(updatedTemp),
        updatedFields: tempupdatedField,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.setEditRows();
      }
    );
  };

  // delete row function
  deleteRow = (row) => {
    const { calculatedData, dirtyRows } = this.state;
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempCalculatedData = _.cloneDeep(calculatedData);
    tempCalculatedData.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((item) => item !== row);
    this.setState(
      {
        calculatedData: _.cloneDeep(tempCalculatedData),
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.enableEditForm();
        this.setEditRows();
        this.filterUniqueDataVariableList();
      }
    );
  };

  // edit row function
  editRow = (row) => {
    const { updatedFields, calculatedData } = this.state;
    const tempCalculated = _.cloneDeep(calculatedData);
    const updatedTemp = _.cloneDeep(updatedFields);
    updatedTemp[row] = tempCalculated[row];
    this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.setEditRows();
        this.enableEditForm();
      }
    );
  };

  // reset function
  reset() {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  // pop-up modal cancel
  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  // pop-up modal ok
  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  // pop-up modal
  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  render() {
    const { loading } = this.props.calculatedDataState;
    return (
      <div className="CalculatedData">
        {loading ? <Loader /> : null}
        <CustomTable
          showHeader
          columns={calculatedTableColumns({
            isCalculatedEditable: this.props.isSchematicEditable,
            delete: this.deleteRow,
            disabled: this.state.disabled,
            edit: this.editRow,
            undo: this.undo,
            tag: this.state.piTag,
            change: this.onEditRowChange,
            variableNameData: this.state.variableNameData,
          })}
          renderAddRow={
            this.props.isSchematicEditable
              ? addRowColumns({
                  change: this.onAddRowChange,
                  submit: this.onAddRow,
                  variableNameData: this.state.variableNameData,
                  onVariableNameChange: this.onVariableNameChange,
                  onVariablePropertyChange: this.onVariablePropertyChange,
                  variableName: this.state.selectedVariableName,
                  tag: this.state.tag,
                  error: this.state.isError,
                  onSelectVariableName: this.onVariableNameChange,
                  disabled:
                    this.state.variableNameData &&
                    !this.state.variableNameData.length,
                })
              : null
          }
          addFieldValues={this.state.addFieldValues}
          editableRow={this.state.editRows}
          data={this.state.calculatedData}
        />
        {this.state.formEdit && this.props.isSchematicEditable ? (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}
// actions dispatched
const mapDispatchToProps = (dispatch) => ({
  clearState: () => {
    dispatch(clearState());
  },
  getVariableName: (id) => {
    dispatch(getVariableName(id));
  },
  getCalculatedData: (equipmentId, componentId) => {
    dispatch(getCalculatedData(equipmentId, componentId));
  },
  addCalculatedData: (payload, equipmentId, componentId) => {
    dispatch(addCalculatedData(payload, equipmentId, componentId));
  },
});
// reducer states
const mapStateToProps = (state) => ({
  calculatedDataState: state.calculatedDataReducer,
  schematicState: state.SchematicReducer,
  network: state.NetworkReducer,
});
// connect
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalculatedData));
