import {
  INTERNET_CONNECTED,
  INTERNET_DISCONNECTED,
} from "../Types/network.types";

const INITIAL_STATE = {
  networkState: window.navigator.onLine,
};

export default function NetworkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INTERNET_CONNECTED:
      return {
        ...state,
        networkState: true,
      };
    case INTERNET_DISCONNECTED:
      return {
        ...state,
        networkState: false,
      };
    default:
      return {
        ...state,
      };
  }
}
