import { Icon, Input, Select, Switch } from "antd";
import React from "react";
import { validateNumericPositiveFloat } from '../../../../common/methods';
import { TransientDataVariable, TransientDataKeyChain } from "../../../../constants/variables";

const { Option } = Select;
export const inputRenderDataUser = (config) => [
  {
    dataIndex: "piTag",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config.piTag || !config.piTagId)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          type="text"
          name="piTag"
          value={config.displayPiTag === "" ? "Search here" : config.displayPiTag}
          AutoComplete="off"
          showSearch
          showArrow={false}
          onSelect={(tag) => config.setDataTag(tag)}
          style={{width: 200}}
        >
          {config.variableTagFetch &&
            config.variableTagFetch.map((item) => (
              <Option value={item.piTag} title={item.piTag}>
                {item.piTag}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "variableName",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config.variableName)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          disabled
          type="text"
          placeholder="Search here"
          name="variableName"
          value={
            config.variableName === "" ? "Search here" : config.variableName
          }
          AutoComplete="off"
          showSearch
          className={
            config.addValueListener &&
            (!config.variableName)
              ? "error"
              : "not-error"
          }
          onSelect={(name) => config.setVariableName(name)}
          showArrow={false}
          style={{width: 400}}
        >
          {config.variableTagFetch &&
            config.variableTagFetch.map((item) => (
              <Option value={item.variableName} title={item.variableName}>
                {item.variableName}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "analysisType",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config.analysisType)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          type="text"
          placeholder="Search"
          name="analysisType"
          value={
            config.analysisType === "" ? "Search" : config.analysisType
          }
          AutoComplete="off"
          showSearch
          onSelect={(type) => config.setAnalysisData(TransientDataKeyChain.analysisType, type)}
          showArrow={false}
          style={{width: 100}}
        >
          {config.analysisTypeList &&
            config.analysisTypeList.map((item) => (
              <Option value={item.name} title={item.type}>
                {item.name}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "curveType",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config.curveType)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          type="text"
          placeholder="Search"
          name="curveType"
          value={
            config.curveType === "" ? "Search" : config.curveType
          }
          AutoComplete="off"
          showSearch
          onSelect={(type) => config.setAnalysisData(TransientDataKeyChain.curveType, type)}
          showArrow={false}
          style={{width: 100}}
        >
          {config.curveTypeList &&
            config.curveTypeList.map((item) => (
              <Option value={item.name} title={item.type}>
                {item.name}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "lookBackTime",
    render: () => (
        <Input
          type="number"
          placeholder="seconds"
          name="lookBackTime"
          onChange={(e) => config.setAnalysisData(TransientDataKeyChain.lookBackwardTime, e.target)}
          value={config.lookBackTime}
          className={
            config.addValueListener &&
            (!config.lookBackTime)
              ? "error"
              : "not-error"
          }
        />
    ),
  },
  {
    dataIndex: "lookForwardTime",
    render: () => (
        <Input
          type="number"
          placeholder="seconds"
          name="lookForwardTime"
          onChange={(e) => config.setAnalysisData(TransientDataKeyChain.lookForwardTime, e.target)}
          value={config.lookForwardTime}
          className={
            config.addValueListener &&
            (!config.lookForwardTime)
              ? "error"
              : "not-error"
          }
        />
    ),
  },
  {
    dataIndex: "enable",
    render: () => (
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        onChange={(value) => config.setAnalysisData(TransientDataKeyChain.enable, value)}
        checked={config.enable}
      />
    ),
  },
  {
    render: () => (
      <div>
        <div>
          <button
            className="btn-default btn-small"
            onClick={() => config.addValue()}
          >
            Add
          </button>
        </div>
      </div>
    ),
  },
];
export const inputRenderData = (config) => [
  {
    title: TransientDataVariable.variableTag,
    dataIndex: 'displayPiTag',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (<Select
            disabled
            type="text"
            placeholder="Search here"
            name="piTag"
            value={value}
            AutoComplete="off"
            showSearch
            showArrow={false}
            style={{width: 200}}
          >
          </Select>
        ) : 
        (
          <Select
            type="text"
            placeholder="Search here"
            name="piTag"
            value={value}
            AutoComplete="off"
            showSearch
            onSelect={(type) => config.setDataTag(type,"edit", row)}
            showArrow={false}
            style={{width: 200}}
          >
          {config.variableTagFetch &&
            config.variableTagFetch.map((item) => (
              <Option value={item.piTag} title={item.piTag}>
                {item.piTag}
              </Option>
            ))}
          </Select>
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.variableName,
    dataIndex: 'variableName',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (<Select
            disabled
            type="text"
            placeholder="Search here"
            name="variableName"
            value={value}
            AutoComplete="off"
            showSearch
            showArrow={false}
            style={{width: 400}}
          >
          </Select>
        ) : 
        (
          <Select
            disabled
            type="text"
            placeholder="Search here"
            name="variableName"
            value={value}
            AutoComplete="off"
            showSearch
            onSelect={(type) => config.setData(TransientDataKeyChain.variableName, type, row)}
            showArrow={false}
            style={{width: 400}}
          >
          {config.variableTagFetch &&
            config.variableTagFetch.map((item) => (
              <Option value={item.variableName} title={item.variableName}>
                {item.variableName}
              </Option>
            ))}
          </Select>
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.analysisType,
    dataIndex: 'analysisType',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (<Select
            disabled
            type="text"
            placeholder="Search here"
            name="analysisType"
            value={value}
            AutoComplete="off"
            showSearch
            showArrow={false}
            style={{width: 100}}
          >
          </Select>
        ) : 
        (
          <Select
            type="text"
            placeholder="Search here"
            name="curveType"
            value={value}
            AutoComplete="off"
            showSearch
            onSelect={(type) => config.setData(TransientDataKeyChain.analysisType, type, row)}
            showArrow={false}
            style={{width: 100}}
          >
            {config.analysisTypeList &&
              config.analysisTypeList.map((item) => (
                <Option value={item.name} title={item.type}>
                  {item.name}
                </Option>
              ))}
          </Select>
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.curveType,
    dataIndex: 'curveType',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (<Select
            disabled
            type="text"
            placeholder="Search here"
            name="curveType"
            value={value}
            AutoComplete="off"
            showSearch
            showArrow={false}
            style={{width: 100}}
          >
          </Select>
        ) : 
        (
          <Select
            type="text"
            placeholder="Search here"
            name="curveType"
            value={value}
            AutoComplete="off"
            showSearch
            onSelect={(type) => config.setData(TransientDataKeyChain.curveType, type, row)}
            showArrow={false}
            style={{width: 100}}
          >
            {config.curveTypeList &&
              config.curveTypeList.map((item) => (
                <Option value={item.name} title={item.type}>
                  {item.name}
                </Option>
              ))}
          </Select>
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.lookBackTime,
    dataIndex: 'lookBackTime',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (
        <Input
          disabled
          type="number"
          placeholder="seconds"
          name="lookForwardTime"
          value={value}
          style={{width: 100}}
          />
        ) : 
        (
        <Input
            type="number"
            placeholder="seconds"
            name="lookForwardTime"
            onChange={(e) => config.setData(TransientDataKeyChain.lookBackwardTime, e.target.value, row)}
            value={value}
            className={
              (validateNumericPositiveFloat(value))
                ? "no-error"
                :(value === '' || !validateNumericPositiveFloat(value))
                  ? "error"
                  : ""
            }
            style={{width: 100}}
          />
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.lookForwardTime,
    dataIndex: 'lookForwardTime',
    render: (value, row, col, disabled) => (
      <div>
        { disabled ? 
        (
        <Input
          disabled
          type="number"
          placeholder="seconds"
          name="lookForwardTime"
          value={value}
          style={{width: 100}}
          />
        ) : 
        (
        <Input
            type="number"
            placeholder="seconds"
            name="lookForwardTime"
            onChange={(e) => config.setData(TransientDataKeyChain.lookForwardTime, e.target.value, row)}
            value={value}
            disabled={disabled}
            className={
              (validateNumericPositiveFloat(value))
                ? "no-error"
                :(value === '' || !validateNumericPositiveFloat(value))
                  ? "error"
                  : ""
            }
            style={{width: 100}}
          />
        )
        }
      </div>
    ),
  },
  {
    title: TransientDataVariable.enable,
    dataIndex: 'enable',
    render: (value, row, col, disabled) => (
      <div>
        {disabled ? (
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={value}
            disabled
          />
        ) : (
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={value}
            onChange={(value) => config.setData(TransientDataKeyChain.enable, value, row)}
          />
        )}
      </div>
    ),
  },
  config.isInputEditable && {
    title: TransientDataVariable.actions,
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {
        disabled ? (
          <button onClick={() => config.EditData(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.UndoData(row)}>
            <Icon type="undo" />
          </button>
        )}
        <button onClick={() => config.DeleteData(row, item)}>
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const none = "None";
export const noUnits = "No Units";
