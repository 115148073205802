import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import logger from "redux-logger";
import makeRootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["MlAlgorithmReducer", "settingReducer"],
  // stateReconciler: hardSet,
};

const isDev = process.env.NODE_ENV === "development";
const reducers = persistReducer(persistConfig, makeRootReducer());
const middlewares = [thunk];
if (isDev) middlewares.push(logger);

const store = createStore(
  reducers,
  {},
  compose(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export { store, persistor };
