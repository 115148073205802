import Type from '../Types/materialEnvironment.types';
const materialEnvironmentData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {
    pipeWallThermalConductivity : {},
    pipeRoughness: {},
    insulationThermalConductivity:{},
    thermalExpansionCoefficient: {},
    soilThermalConductivity: {}
  },
};

const addMaterialEnvironmentData = {
    isLoading: false,
    isError: false,
    errorCode: '',
    apiMsg: '',
    isSuccess: false,
    data: {},
  };
  


const INITIAL_STATE = {
  materialEnvironmentData: {...materialEnvironmentData},
  addMaterialEnvironmentData: { ...addMaterialEnvironmentData },
};

export default function MaterialEnvironmentReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.MATERIAL_ENVIRON_FETCH_PROGRESS:
      return {
        ...state,
        materialEnvironmentData: {
          ...INITIAL_STATE.materialEnvironmentData,
          isLoading: true,
        },
      };
    case Type.MATERIAL_ENVIRON_FETCH_SUCCESS:
      return {
        ...state,
        materialEnvironmentData: {
          isSuccess: true,
          isLoading: false,
          data: action.payload
            ? {
                pipeWallThermalConductivity: action.payload.pipeWallThermalConductivity
                  ? action.payload.pipeWallThermalConductivity
                  : {},
                pipeRoughness: action.payload.pipeRoughness
                  ? action.payload.pipeRoughness
                  : {},
                insulationThermalConductivity: action.payload.insulationThermalConductivity
                  ? action.payload.insulationThermalConductivity
                  : {},
                thermalExpansionCoefficient: action.payload.thermalExpansionCoefficient
                  ? action.payload.thermalExpansionCoefficient
                  : {},
                soilThermalConductivity: action.payload.soilThermalConductivity
                  ? action.payload.soilThermalConductivity
                  : {},
               
              }
            : {
                ...INITIAL_STATE.materialEnvironmentData.data,
              },
        },
      };
    case Type.MATERIAL_ENVIRON_FETCH_ERROR:
      return {
        ...state,
        materialEnvironmentData: {
          ...state.materialEnvironmentData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.MATERIAL_ENVIRON_FETCH_RESET:
      return {
        ...state,
        materialEnvironmentData: {
          ...state.materialEnvironmentData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
        },
      };

    case Type.MATERIAL_ENVIRON_UPDATE_SUCCESS:
      return {
        ...state,
        addMaterialEnvironmentData: {
          ...INITIAL_STATE.addMaterialEnvironmentData,
          isLoading: true,
        },
      };
    case Type.MATERIAL_ENVIRON_UPDATE_PROGRESS:
      return {
        ...state,
        addMaterialEnvironmentData: {
          ...state.addMaterialEnvironmentData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.MATERIAL_ENVIRON_UPDATE_ERROR:
      return {
        ...state,
        addMaterialEnvironmentData: {
          ...state.addMaterialEnvironmentData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.MATERIAL_ENVIRON_UPDATE_RESET:
      return {
        ...state,
        addMaterialEnvironmentData: {
          ...INITIAL_STATE.addMaterialEnvironmentData,
        },
      };

    case Type.MATERIAL_ENVIRON_REDUCER_RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
