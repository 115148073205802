import React from 'react';
import AddUserHeader from './AddUserHeader/AddUserHeader';
import AddUserBody from './AddUserBody/AddUserBody';
import './AddUser.scss';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formEdit: false,
    };
  }

  onChangeFormEdit = (isEdit) => {
    this.setState({
      formEdit: isEdit,
    });
  };

  render() {
    const { formEdit } = this.state;
    return (
      <div className="AddUser">
        <AddUserHeader formEdit={formEdit} />
        <AddUserBody onChangeFormEdit={this.onChangeFormEdit} />
      </div>
    );
  }
}

export default AddUser;
