import {
  CARDTYPE_FETCH_SUCCESS,
  CARDTYPE_FETCH_PROGRESS,
  CARDTYPE_FETCH_ERROR,
  CARDTYPE_CLEAR_STATE,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARDTYPE_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case CARDTYPE_FETCH_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case CARDTYPE_FETCH_SUCCESS:
      let cardTypes = [];
      action.payload.data.forEach((cardTypeItem) => {
          const { cardType, _id } = cardTypeItem;
          if(cardType !== 'Multiple X-Y Plot' && cardType !== 'Alert Plot')  {
            cardTypes.push( { cardType, _id, name: cardType });
          }
      });
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: cardTypes.filter(
          (card) =>
            card.cardType !== "Operating Statistics" &&
            card.cardType !== "Aggregate Values"
        ),
      };
    case CARDTYPE_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
};
