import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import './ShelvedModal.scss';
import { validateShelvedNumber } from '../../../../../libs/validator';
import _ from 'lodash';

class ShelvedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hours: null,
      minutes: null,
      old: 0,
      time: '',
      change: false,
    };
  }

  validateTime() {
    const { hours, minutes } = this.state;
    if (
      _.isFinite(hours) &&
      _.isFinite(minutes) &&
      (hours !== 0 || minutes !== 0)
    ) {
      return true;
    }
    return false;
  }

  handleOk = (e) => {
    const { hours, minutes } = this.state;
    const time = `${hours}:${minutes}`;
    if (this.validateTime()) {
      this.setState(
        {
          time,
        },
        () => {
          this.props.handleOk(this.state.time);
        }
      );
    } else {
      Notification.show(Types.Info, 'Please enter valid values');
    }
  };

  handleCancel = (e) => {
    this.props.handleCancel();
  };

  setHours = (value) => {
    this.setState({
      hours: value ? parseInt(value) : null,
      old: value,
      minutes: parseInt(this.state.minutes)
        ? parseInt(this.state.minutes)
        : null,
      change: true,
    });
  };

  setMinutes = (value) => {
    const { hours, old, minutes } = this.state;

    if (!(parseInt(value) === 0 || parseInt(value) >= 60)) {
      this.setState({
        minutes: !value && value !== 0 ? null : parseInt(value),
        hours: value === 0 ? null : parseInt(old) ? parseInt(old) : 0,
        change: true,
      });
    } else if (value !== 0) {
      const tempMinutes =
        parseInt(hours) < 999 ? parseInt(value) % 60 : parseInt(value);
      const tempHours =
        parseInt(minutes) < 60
          ? parseInt(hours) < 999
            ? parseInt(hours) + parseInt(value / 60)
            : parseInt(hours)
          : parseInt(old)
          ? parseInt(old)
          : 0;
      this.setState({
        minutes: tempMinutes,
        hours: tempHours,
      });
    } else {
      this.setState({
        minutes: 0,
      });
    }
  };

  render() {
    const { hours, change, minutes } = this.state;
    return (
      <div className="alert-details-shelved">
        <Modal
          title="Shelve for"
          visible={this.props.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="ShelvedModal"
          okText="Proceed"
          maskClosable={false}
        >
          <div className="shelve-parent-div">
            <div className="shelved-HHH-input">
              <br />
              <Input
                placeholder="HHH"
                onChange={(e) => this.setHours(e.target.value)}
                maxLength="3"
                value={this.state.hours}
                onKeyDown={(e) => validateShelvedNumber(e)}
                className={!(hours >= 0) && change ? 'error' : 'not-error'}
              />
            </div>
            &nbsp; : &nbsp;
            <div className="shelved-MM-input">
              <br />
              <Input
                placeholder="MM"
                onChange={(e) => this.setMinutes(e.target.value)}
                maxLength="2"
                value={this.state.minutes}
                onKeyDown={(e) => validateShelvedNumber(e)}
                className={
                  (this.state.hours === 999 && this.state.minutes >= 60) ||
                  (!hours && !(hours >= 0) && change) ||
                  (!minutes && !(minutes >= 0) && change)
                    ? 'error'
                    : 'not-error'
                }
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ShelvedModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  mechademyAction: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

export default ShelvedModal;
