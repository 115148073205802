import { Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../common/Loader/Loader';
import Notification, { Types } from '../../common/Notification/Notification';
import { NO_INTERNET } from '../../constants/messages';
import NotConnected from '../../libs/NotConnected';
import RouterOutlet from '../../libs/RouterOutlet';
import { clearClientState, clientList, getClientInfo } from '../../redux/actions/client.action';
import { clearFacilityState } from '../../redux/actions/facility.action';
import SystemSchematic from '../Clients/SystemSchematic/SystemSchematic';
import ConfirmModal from './../../common/Modal/Modal';
import './systemSchematicSideMenu.scss';

class SystemSchematicSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientInfoData: {},
      network: true,
      visible: false,
      demandPage: window.location.pathname,
      pageData: {},
      formEdit: false,
      closeMenu: false,
      systemId: null,
      search: null,
    };
  }

  componentDidMount() {
    if (this.handleNetwork()) {
      const { clientList } = this.props;
      clientList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isSuccessFetchClients,
      clientInfoData,
      isSuccessClientsInfo,
      clientList,
      isError,
    } = this.props.clientState.apiState;
    const { clearClientState } = this.props;
    if (
      isSuccessFetchClients &&
      isSuccessFetchClients !==
        prevProps.clientState.apiState.isSuccessFetchClients
    ) {
      const { getClientInfo } = this.props;
      if (this.handleNetwork()) {
        getClientInfo(clientList.items[0]._id);
      }
    }
    if (isSuccessClientsInfo) {
      clearClientState();
      this.setState({
        clientInfoData: clientInfoData,
      });

      const systemId =
        this.state.clientInfoData &&
        this.state.clientInfoData.systems &&
        this.state.clientInfoData.systems.map(item => ({
          _id: item._id,
          systemName: item.systemName,
          name: item.systemName,
        }));
      this.setState({
        systemId: systemId && systemId[0],
      });
    }

    if (isError) {
      // Notification.show(Types.Error, ClientListingMessages[errorCode]);
      clearClientState();
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      this.setState({ network: false });
      return false;
    }
  }
  closeMenu() {
    if (!this.state.closeMenu) {
      this.setState({
        closeMenu: true,
      });
    } else {
      this.setState({
        closeMenu: false,
      });
    }
  }
  onSelect = data => {
    this.setState({
      systemId: data,
    });
  };

  setSearch = e => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const { clientInfoData } = this.state;
    const { loading } = this.props.clientState;

    return (
      <div className="SystemSchematicSideMenu">
        <div>
          {loading && <Loader />}
          {this.state.network ? (
            <div className="ClientInfo">
              <div
                className="structure"
                style={
                  !this.state.closeMenu
                    ? { width: '220px', minWidth: '220px' }
                    : { width: '17px', minWidth: '17px' }
                }
              >
                <div
                  className="close-button"
                  onClick={() => {
                    this.closeMenu();
                  }}
                >
                  {!this.state.closeMenu ? (
                    <Icon type="left" />
                  ) : (
                    <Icon type="menu" />
                  )}
                </div>
                <div className="directory">
                  <div className="client-system-search">
                    <Input
                      value={this.state.search}
                      onChange={this.setSearch}
                      className="ant-input ant-select-search__field"
                      placeholder="Search here ..."
                    />
                  </div>
                  {clientInfoData && Object.keys(clientInfoData).length !== 0 && (
                    <div style={{ marginTop: '50px', marginLeft: '30px' }}>
                      {this.state.clientInfoData &&
                        this.state.clientInfoData.systems &&
                        this.state.clientInfoData.systems.map(item => {
                          if (
                            item.systemName.includes(this.state.search) ||
                            !this.state.search
                          ) {
                            return (
                              <span
                                key={item.systemName}
                                onClick={() =>
                                  this.onSelect({
                                    _id: item._id,
                                    systemName: item.systemName,
                                    name: item.systemName,
                                  })
                                }
                              >
                                <div className="systemList">
                                  {item.systemName}
                                </div>
                              </span>
                            );
                          }
                          return undefined;
                        })}
                    </div>
                  )}
                </div>
              </div>
              <div className="routes">
                <SystemSchematic
                  systemData={this.state.systemId}
                  sideMenu={true}
                />
                <RouterOutlet routes={this.props.routes} />
              </div>
            </div>
          ) : (
            <NotConnected />
          )}
          {this.state.visible ? (
            <ConfirmModal
              visible={this.state.visible}
              handleOk={() => this.handleOk()}
              handleCancel={() => this.handleCancel()}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
SystemSchematicSideMenu.prototypes = {
  network: PropTypes.object,
  clientState: PropTypes.object,
  clientList: PropTypes.func,
  clearClientState: PropTypes.func,
  getClientInfo: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

SystemSchematicSideMenu.defaultProps = {
  network: {},
  clientState: {},
  clientList: () => {},
  clearClientState: () => {},
  getClientInfo: () => {},
  history: {},
  location: {},
  match: {},
};

const mapStateToProps = state => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  facilityState: state.FacilityReducer,
  clientSystemState: state.SystemReducer,
  network: state.NetworkReducer,
  equipmentState: state.EquipmentReducer,
  schematicState: state.SchematicReducer,
  systemSchematicState: state.systemSchematicReducer,
});

const mapDispatchToProps = dispatch => ({
  getClientInfo: values => dispatch(getClientInfo(values)),
  clearClientState: () => dispatch(clearClientState()),
  clearFacilityState: () => dispatch(clearFacilityState()),
  clientList: () => dispatch(clientList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SystemSchematicSideMenu));
