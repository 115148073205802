import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import _ from 'lodash';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  MotorDesignDataMessages,
  PerformanceDataMessages,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  savePerformanceData,
  ResetPerformanceData,
} from '../../../../../../redux/actions/hydraulicVariableSpeedGear.action';
import Loader from '../../../../../../common/Loader/Loader';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  keyChange,
  initialData,
  hvsgPerformanceDataTable,
  hvsgPerformanceData,
  validateData,
} from './performanceDataConstants';
import { hvsgPerformanceDataVariables } from '../../../../../../constants/variables';
import './hvsgPerformanceData.scss';

const HydraulicVariableSpeedGearPerformanceData = (props) => {
  const {
    isSchematicEditable,
    location,
    network,
    savePerformanceData,
    ResetPerformanceData,
    HVSGConfigData,
    addPerformanceData,
    dataUnitFetchSuccess,
    inUnitData,
  } = props;

  const [formEdit, setFormEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [displayData, setDisplayData] = useState(initialData);
  const [copyDisplayData, setCopyDisplayData] = useState(initialData);
  const [unitData, setUnitData] = useState([]);
  const [saveListener, setSaveListener] = useState(false);
  const [editTableRows, setEditableRows] = useState([]);
  const [dirtyValues, setDirtyValues] = useState([]);
  const [dirtyUnits, setDirtyUnits] = useState([]);

  // upon successfully saving design data
  useEffect(() => {
    if (addPerformanceData.isSuccess) {
      disableEditForm();
      ResetPerformanceData();
      setCopyDisplayData(displayData);
      Notification.show(Types.Success, PerformanceDataMessages.SUCCESS);
    } else if (addPerformanceData.isError) {
      Notification.show(Types.Error, addPerformanceData.apiMsg);
    }
  }, [addPerformanceData, ResetPerformanceData, displayData]);

  const setData = useCallback(() => {
    if (HVSGConfigData.isSuccess) {
      const tempPerformanceData = HVSGConfigData.performanceData;
      const tempDisplayData = Object.values(hvsgPerformanceDataVariables).map(
        (item) => {
          return {
            name: item,
            design: tempPerformanceData
              ? tempPerformanceData[keyChange[item]]
                ? tempPerformanceData[keyChange[item]].design
                : ''
              : '',
            unit: tempPerformanceData
              ? tempPerformanceData[keyChange[item]]
                ? tempPerformanceData[keyChange[item]].unit
                : ''
              : '',
          };
        }
      );
      setDisplayData(_.cloneDeep(tempDisplayData));
      setCopyDisplayData(tempDisplayData);
    }
  }, [HVSGConfigData]);

  useEffect(() => {
    setData();
    window.addEventListener('form-edit', () => {
      setFormEdit(true);
    });
    window.addEventListener('form-edit-false', (e) => {
      setFormEdit(false);
      setSaveListener(false);
      setEditableRows([]);
    });
  }, [setData]);

  useEffect(() => {
    if (dataUnitFetchSuccess) {
      const tempUnitData =
        inUnitData && inUnitData.items ? _.clone(inUnitData.items) : [];
      setUnitData(tempUnitData);
    }
  }, [dataUnitFetchSuccess, inUnitData]);

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  const enableEditForm = () => {
    window.dispatchEvent(new Event('form-edit'));
  };

  const handleChange = (val, variableName, type) => {
    enableEditForm();
    const tempDisplayData = displayData.map((item) => {
      if (item.name === variableName && type === 'number') {
        return { name: variableName, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === variableName && type === 'select') {
        return { name: variableName, unit: item.unit, design: val };
      } else if (item.name === variableName && type === 'unit') {
        return { name: variableName, unit: val, design: item.design };
      } else if (item.name === variableName && type === 'text') {
        return { name: variableName, design: val };
      }
      return item;
    });
    setDisplayData(_.cloneDeep(tempDisplayData));
    enableEditForm();
  };

  const handleOk = () => {
    setDisplayData(copyDisplayData);
    setVisible(false);
    disableEditForm();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNetwork = () => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  };

  const onReset = () => {
    setDisplayData(copyDisplayData);
    setVisible(false);
    disableEditForm();
  };

  const onSave = () => {
    setSaveListener(true);
    let payload = {};
    const isValid = validateData(displayData, setDirtyValues, setDirtyUnits);
    if (!isValid) {
      displayData.forEach((item) => {
        payload[keyChange[item.name]] = {
          unit: item.unit,
          design: parseFloat(item.design),
        };
      });

      const { state } = location;
      if (handleNetwork() && state && state.componentData.info._id && payload) {
        payload = { performanceData: payload };
        savePerformanceData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, MotorDesignDataMessages.DIRTY_DATA);
    }
  };

  const onEdit = (row) => {
    let editTable = editTableRows;
    editTable.push(row);
    editTable.sort();
    setEditableRows(editTable);
    enableEditForm();
  };

  const onUndo = (row) => {
    let editTable = editTableRows;
    editTable = editTable.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);
    setEditableRows(editTable);
    setDisplayData(tempDisplayData);
    enableEditForm();
  };

  const renderModal = () => {
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  };

  // Render component
  return (
    <>
      <div className="HVSGPerformanceData">
        {(HVSGConfigData.isLoading || addPerformanceData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          showHeader={true}
          columns={hvsgPerformanceDataTable({
            handleChange: handleChange,
            onEdit: onEdit,
            onUndo: onUndo,
            saveListener,
            isSchematicEditable,
            unitData,
            dirtyValues,
            dirtyUnits,
          })}
          data={hvsgPerformanceData(displayData)}
          editableRow={editTableRows}
        />
      </div>
      {formEdit && (
        <div className="config-bottom-buttons">
          <button className="btn-default btn-white" onClick={onReset}>
            {ButtonVariables.RESET}
          </button>
          <button className="btn-default" onClick={onSave}>
            {ButtonVariables.SAVE}
          </button>
        </div>
      )}
      {renderModal()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    addPerformanceData:
      state.HydraulicVariableSpeedGearComponentReducer.addPerformanceData,
    HVSGConfigData:
      state.HydraulicVariableSpeedGearComponentReducer.HVSGConfigData,
    dataUnitFetchSuccess: state.DataUnitReducer.apiState.isSuccessDataUnitFetch,
    inUnitData: state.DataUnitReducer.apiState.unitData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  savePerformanceData: (componentId, payload) =>
    dispatch(savePerformanceData(componentId, payload)),
  ResetPerformanceData: () => dispatch(ResetPerformanceData()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HydraulicVariableSpeedGearPerformanceData)
);
