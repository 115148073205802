import * as Type from '../Types/iowTags.types';
import { GETRequest, PUTRequest } from '../../api/request';
import { endPoints } from '../../libs/endpoints';

export const setTourState = (startTour) => (dispatch) => {
  dispatch({ type: Type.IOW_TOUR_CHANGE, payload: startTour });
};

export function iowDataListFetch(payload) {
  return function (dispatch) {
    dispatch({ type: Type.IOW_TAG_GET_LIST_PROGRESS });
    GETRequest(`${endPoints.iowData}`, payload)
      .then((res) => {
        dispatch({ type: Type.IOW_TAG_GET_LIST_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.IOW_TAG_GET_LIST_ERROR, payload: err });
      });
  };
}

export function iowTagListClear() {
  return function (dispatch) {
    dispatch({ type: Type.IOW_TAG_GET_LIST_CLEAR });
  };
}

export function iowTagUpdate(payload, id) {
  return function (dispatch) {
    dispatch({ type: Type.IOW_TAG_UPDATE_PROGRESS });
    PUTRequest(`${endPoints.iowData}/${payload.tag}`, payload)
      .then((res) => {
        dispatch({ type: Type.IOW_TAG_UPDATE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: Type.IOW_TAG_UPDATE_ERROR, payload: err });
      });
  };
}

export function iowTagUpdateClearReducer() {
  return function (dispatch) {
    dispatch({ type: Type.IOW_TAG_UPDATE_CLEAR });
  };
}

export function iowTagListUpdate(payload){
  return function (dispatch){
    dispatch({ type: Type.IOW_TAG_LIST_UPDATE , payload })
  }
}
