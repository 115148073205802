import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import { validatePositiveFloat } from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  PerformanceDataMessages,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  PerformanceDataVariables,
} from '../../../../../../constants/variables';
import {
  savePerformanceData,
  savePerformanceDataReset,
} from '../../../../../../redux/actions/motor.action';
import {
  ChangeKey,
  initialData,
  PerformanceDataShow,
} from './PerformaceData.Constants';
import './PerformanceData.scss';

class PerformanceData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnit();
    this.getData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnit() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  getData() {
    const {
      motorConfigData: { performanceData },
    } = this.props.MotorReducer;
    const tempDisplayData = [
      {
        name: PerformanceDataVariables.ratedPowerOutput,
        unit: performanceData.ratedPowerOutput.unit,
        design: performanceData.ratedPowerOutput.design,
      },
      {
        name: PerformanceDataVariables.ratedCurrent,
        unit: performanceData.ratedCurrent.unit,
        design: performanceData.ratedCurrent.design,
      },
      {
        name: PerformanceDataVariables.synchronousSpeed,
        unit: performanceData.synchronousSpeed.unit,
        design: performanceData.synchronousSpeed.design,
      },
      {
        name: PerformanceDataVariables.fullLoadSpeed,
        unit: performanceData.fullLoadSpeed.unit,
        design: performanceData.fullLoadSpeed.design,
      },
      {
        name: PerformanceDataVariables.fullLoadTorque,
        unit: performanceData.fullLoadTorque.unit,
        design: performanceData.fullLoadTorque.design,
      },
      {
        name: PerformanceDataVariables.rotorGD2AtFLRPM,
        unit: performanceData.rotorGD2AtFLRPM.unit,
        design: performanceData.rotorGD2AtFLRPM.design,
      },
      {
        name: PerformanceDataVariables.rotorWeight,
        unit: performanceData.rotorWeight.unit,
        design: performanceData.rotorWeight.design,
      },
      {
        name: PerformanceDataVariables.lockedRotortorque,
        unit: performanceData.lockedRotortorque.unit,
        design: performanceData.lockedRotortorque.design,
      },
      {
        name: PerformanceDataVariables.pullUpTorque,
        unit: performanceData.pullUpTorque.unit,
        design: performanceData.pullUpTorque.design,
      },
      {
        name: PerformanceDataVariables.breakDownTorque,
        unit: performanceData.breakDownTorque.unit,
        design: performanceData.breakDownTorque.design,
      },
      {
        name: PerformanceDataVariables.lockedRotorCurrent,
        unit: performanceData.lockedRotorCurrent.unit,
        design: performanceData.lockedRotorCurrent.design,
      },
      {
        name: PerformanceDataVariables.lockedRotorPF,
        unit: performanceData.lockedRotorPF.unit,
        design: performanceData.lockedRotorPF.design,
      },
      {
        name: PerformanceDataVariables.lockedRotorWithstandTimeCold,
        unit: performanceData.lockedRotorWithstandTimeCold.unit,
        design: performanceData.lockedRotorWithstandTimeCold.design,
      },
      {
        name: PerformanceDataVariables.lockedRotorWithstandTimeRatedTemp,
        unit: performanceData.lockedRotorWithstandTimeRatedTemp.unit,
        design: performanceData.lockedRotorWithstandTimeRatedTemp.design,
      },
      {
        name: PerformanceDataVariables.statorSlots,
        design: performanceData.statorSlots,
      },
      {
        name: PerformanceDataVariables.rotorSlots,
        design: performanceData.rotorSlots,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const { addPerformaceData, motorConfigData } = this.props.MotorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (prevProps.activeKey !== '2' && this.props.activeKey === '2') {
      this.getData();
    }
    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnit();
    }

    if (
      motorConfigData.isSuccess &&
      prevProps.MotorReducer.motorConfigData.isSuccess !==
        motorConfigData.isSuccess
    ) {
      this.getData();
    }

    if (
      addPerformaceData.isSuccess &&
      prevProps.MotorReducer.addPerformaceData.isSuccess !==
        addPerformaceData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, PerformanceDataMessages.SUCCESS);
    }
    if (
      addPerformaceData.isError &&
      prevProps.MotorReducer.addPerformaceData.isError !==
        addPerformaceData.isError
    ) {
      Notification.show(Types.Error, PerformanceDataMessages.ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: val };
      }
      if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        saveListener: true,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    const editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onSave = () => {
    const {
      location: { state },
      savePerformanceData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
    });

    if (this.validateFields()) {
      const data = ChangeKey;
      const payload = {};
      displayData.forEach((item) => {
        if (
          item.name !== PerformanceDataVariables.statorSlots &&
          (item.design || item.design === 0) &&
          item.name !== PerformanceDataVariables.rotorSlots &&
          (item.design || item.design === 0)
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: parseFloat(item.design),
          };
        } else if (item.design || item.design === 0) {
          payload[data[item.name]] = parseFloat(item.design);
        }
      });
      if (
        (this.handleNetwork() && state && state.componentData.info._id, payload)
      ) {
        savePerformanceData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  validateFields = () => {
    let status = true;
    const { displayData } = this.state;
    displayData.forEach((item) => {
      // change of design values to string before validation
      if (_.isFinite(parseFloat(item.design))) {
        item.design = item.design.toString();
      }

      if (item.name === 'Full Load Speed') {
        if (!item.unit || !item.design) {
          status = false;
        }
        if (item.design && !validatePositiveFloat(item.design.toString())) {
          status = false;
        }
      }
      if (item.name === 'Stator Slots' || item.name === 'Rotor Slots') {
        if (
          _.isFinite(parseFloat(item.design)) &&
          !validatePositiveFloat(item.design.toString())
        ) {
          status = false;
        }
      }

      if (
        item.name !== 'Stator Slots' &&
        item.name !== 'Rotor Slots' &&
        item.name !== 'Full Load Speed'
      ) {
        if (!item.unit && item.design) {
          status = false;
        }
        if (item.unit && !item.design) {
          status = false;
        }

        if (
          _.isFinite(parseFloat(item.design)) &&
          !validatePositiveFloat(item.design.toString())
        )
          status = false;
      }
    });
    return status;
  };

  render() {
    const { isSchematicEditable } = this.props;
    const { motorConfigData, addPerformaceData } = this.props.MotorReducer;
    const { saveListener, unitData } = this.state;

    return (
      <div className="PerformanceData">
        {(motorConfigData.isLoading || addPerformaceData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          showHeader
          columns={PerformanceDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}

PerformanceData.PropType = {
  location: PropType.object,
  motorConfigData: PropType.object,
  addPerformaceData: PropType.object,
  performanceData: PropType.object,
  savePerformanceData: PropType.func,
  savePerformanceDataReset: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
  isSchematicEditable: PropType.bool,
};

PerformanceData.defaultProps = {
  isSchematicEditable: true,
};

const mapStateToProps = (state) => ({
  MotorReducer: state.MotorReducer,
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  savePerformanceData: (componentId, payload) =>
    dispatch(savePerformanceData(componentId, payload)),
  savePerformanceDataReset: () => dispatch(savePerformanceDataReset()),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(PerformanceData)
);
