import React from "react";
import { Icon, Input } from "antd";
import { newValidateNumber } from "../../../../../../../libs/validator";

export const DataUnit = ["Inlet Loss"];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const dataUnitsVariables = {
  inletDP: "Inlet Loss",
};
export const variablesMapper = {
  inletDP: "inletDP",
  powerCorr: "powerCorr",
  heatRateCorr: "heatRateCorr",
};
export const curvesColumnsTitle = {
  inletDP: "Inlet Differential Pressure",
  powerCorr: "Power Correction Factor",
  heatRateCorr: "Heat Rate Correction Factor",
};
export const curvesColumnsName = {
  inletDP: "dp",
  powerCorr: "power_corr",
  heatRateCorr: "hr_corr",
};
export const graphDetails = {
  title: "Curve",
  xAxis: "Inlet Differential Pressure",
  power_corr: "Inlet DP Power correction factor",
  hr_corr: "Inlet DP Heatrate correction factor",
  requiredMessage: "Mininum three data is required",
  inletDPCurve: "Inlet Losses Curve",
};

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.inletDP,
    dataIndex: curvesColumnsName.inletDP,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inletDP}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
export const InletDPCurveFormField = (units, config) => [
  {
    label: "Inlet Differential Pressure",
    key: "inletDP",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Inlet Loss")[0],
    data: getUnit(units, "Inlet Loss"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesLabel = {
  inletDPCurve: "Inlet Differential Pressure Curve",
};
export const createCurvesPayload = (formfieldValue, inletDPCurves = []) => {
  const { ...dataUnit } = formfieldValue;

  const dataInletDPCurves = inletDPCurves.map((item) => {
    const { dp, power_corr, hr_corr } = item;
    return {
      dp: parseFloat(dp),
      power_corr: parseFloat(power_corr),
      hr_corr: parseFloat(hr_corr),
    };
  });
  const payload = {
    inletDpCurve: {
      dataUnit: { dp: dataUnit.inletDP },
      data: dataInletDPCurves,
    },
  };
  return payload;
};

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.inletDP,
    dataIndex: curvesColumnsName.inletDP,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inletDP}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.inletDP] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.powerCorr] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.heatRateCorr]
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const curveVariables = {
  "Power Correction": "power_corr",
  "Heat Rate Correction": "hr_corr",
};

export const curveInput = (data, yAxisValue, config) => {
  return [
    {
      label: "Y Axis",
      key: "yAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: yAxisValue,
      style: { width: "200px", padding: "10px" },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};
