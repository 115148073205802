import { isLoggedIn } from "../libs/token";
import { deleteIDB } from "../services/indexedDB";

export const AuthenticatedGaurd = () => {
  if (isLoggedIn()) {
    return true;
  }
  deleteIDB(); /** Delete DB when user in not have auth */
  return false;
};
