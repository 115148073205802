import { Form, Input, Table } from 'antd';
import Decimal from 'decimal.js';
import _ from 'lodash';
import PropType from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../common/FormFields/FormFields';
import Loader from '../../../../../common/Loader/Loader';
import { validateNonNegativeFloat } from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FluidCompositionMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../../../constants/messages';
import {
  ButtonVariables,
  FluidCompositionVariables,
  MIXER,
} from '../../../../../constants/variables';
import {
  addFluid,
  clearFluidCompositionState,
  getFluidList,
} from '../../../../../redux/actions/fluidComposition.actions';
import {
  clearStreamState,
  getStreamData,
} from '../../../../../redux/actions/Stream.action';
import {
  fluidTableColumns,
  footerColumns,
  formFields,
  headerColumns,
  initialData,
  rowSelection,
  searchTable,
  CompressorFormFields,
  ScEquationOfState
} from './FluidComposition.constants';
import './FluidComposition.scss';
import { Compressor } from '../ConfigDataConstants';

const { Search } = Input;

class FluidComposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirtyForm: 0,
      dirtyRows: [],
      selectedFluid: [],
      selectedUnsearchedFluid: [],
      preserveFluidList: [],
      fluidList: [],
      editRows: [],
      selectedFluidKeys: [],
      updatedFields: [],
      ratioValue: 1,
      formEdit: false,
      typeComposition: null,
      preserveFluidData: {},
      normalizePopupVisible: false,
      setInitialValues: true,
      equationOfState: null,
      calcDischargeConditions: false,
    };
  }

  componentDidMount() {
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
    if (this.handleNetwork()) {
      this.props.getFluidListAction();
    }
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  componentDidUpdate(prevProps) {
    const {
      isFluidListSuccess,
      errorCode,
      isError,
      fluidList,
      isFluidAddSuccess,
    } = this.props.fluidCompositionState.apiState;
    const {
      isSave,
      isConfigData,
      clearConfigState,
      clearStreamState,
      clearFluidCompositionState,
    } = this.props;
    const {
      streamData,
      isStreamDataSuccess,
      isAddStreamDataSuccess,
    } = this.props.streamState;
    const { name } = this.props.location.state.componentData.assetInfo;
    let componentData = {};

    if (isConfigData) {
      clearConfigState();
      this.getComponentData();
      this.disableEditForm();
    }

    if (
      isStreamDataSuccess ||
      isAddStreamDataSuccess ||
      prevProps.streamId !== this.props.streamId
    ) {
      clearStreamState();
      this.getComponentData();
      this.props.setIsSave();
      this.setState({formEdit: false});
    }

    if (isFluidListSuccess) {
      clearFluidCompositionState();
      // component data as per the parent component
      if (name === MIXER) {
        if (streamData) {
          if (streamData[`stream${this.props.streamId}`]) {
            componentData = {
              ...streamData[`stream${this.props.streamId}`].fluidComposition,
            };
          }
        }
      } else {
        componentData = { ...this.props.configData.fluidComposition };
      }
      this.setState(
        {
          fluidList,
          preserveFluidList: fluidList,
        },
        () => {
          this.setSelectedFluidKeys(
            componentData ? componentData.fluids : null
          );
        }
      );
    }

    if (isFluidAddSuccess) {
      clearFluidCompositionState();
      Notification.show(Types.Success, FluidCompositionMessages.FLUID_ADDED);
    }

    if (
      isError &&
      prevProps.fluidCompositionState.apiState.isError !==
        this.props.fluidCompositionState.apiState.isError
    ) {
      clearFluidCompositionState();
      Notification.show(Types.Error, FluidCompositionMessages[errorCode]);
    }

    if (isSave && prevProps.isSave !== isSave) {
      this.save();
    }
  }

  setEquationOfStateValue = (value) => {
    this.setState({
      equationOfState: value,
    });
    this.enableEditForm();
  };

  setType = (value) => {
    this.setState({
      typeComposition: value,
    });
    this.enableEditForm();
  };

  getComponentData() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const { streamData } = this.props.streamState;
    let componentData = {};

    // component data as per the parent component
    if (name === MIXER) {
      if (
        streamData &&
        streamData[`stream${this.props.streamId}`] &&
        streamData[`stream${this.props.streamId}`].fluidComposition
      ) {
        componentData = {
          ...streamData[`stream${this.props.streamId}`].fluidComposition,
        };
      }
    } else {
      componentData = { ...this.props.configData.fluidComposition };
    }
    this.setState({
      preserveFluidData: componentData
        ? _.cloneDeep(componentData)
        : _.cloneDeep(initialData),
      equationOfState: componentData.equationState,
      typeComposition: componentData.type,
      calcDischargeConditions: componentData.calculateDischargeConditions || false,
    });
    this.setFluidData(
      componentData ? _.cloneDeep(componentData) : _.cloneDeep(initialData)
    );
    this.setSelectedFluidKeys(componentData ? componentData.fluids : null);
  }

  setSelectedFluidKeys(fluids) {
    const { fluidList } = this.state;
    const selectedFluidKeys = [];
    if (fluids && fluidList && fluidList.items) {
      for (let j = 0; j < fluids.length; j++) {
        for (let i = 0; i < fluidList.items.length; i++) {
          if (fluids[j].name === fluidList.items[i].shortName) {
            selectedFluidKeys.push(i);
            break;
          }
        }
      }
      this.setState({
        selectedFluidKeys,
      });
    } else {
      this.setState({
        selectedFluidKeys: [],
      });
    }
  }

  setFluidData(fluidComposition) {
    if (fluidComposition) {
      this.setSelectedFluidKeys(fluidComposition.fluids);
      this.setState({
        selectedFluid: fluidComposition.fluids ? fluidComposition.fluids : [],
        calcDischargeConditions:fluidComposition.calculateDischargeConditions,
        ratioValue: fluidComposition.moleFraction
          ? parseInt(fluidComposition.moleFraction)
          : initialData.moleFraction,
      });
      let pres = null;
      if (fluidComposition.equationState) {
        pres = ScEquationOfState.find(
          (ele) => ele._id === fluidComposition.equationState
        );
        this.props.form.setFieldsValue({
          equationState: pres.value,
          type: fluidComposition.type,
        });
      } else {
        this.props.form.setFieldsValue({
          type: fluidComposition.type,
        });
      }
    } else {
      this.setState({
        selectedFluid: [],
      });
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  onValueChange(field, row) {
    const { selectedFluid } = this.state;
    const temp = [...selectedFluid];
    temp[row][field.name] = field.value;
    this.setState(
      {
        selectedFluid: temp,
      },
      () => {
        this.setEditRows();
      }
    );
  }

  setEditRows() {
    const { updatedFields, selectedFluid } = this.state;
    const tempEditRows = [];
    const tempDirtyRows = [];
    const tempUpdatedField = [];
    updatedFields.forEach((field, updatedIndex) => {
      field &&
        selectedFluid.forEach((data, index) => {
          if (field.name === data.name) {
            tempEditRows.push(index);
            tempUpdatedField[index] = updatedFields[updatedIndex];
          }
          if (!validateNonNegativeFloat(`${data.value}`)) {
            tempDirtyRows.push(index);
          }
        });
    });
    this.setState({
      editRows: tempEditRows,
      updatedFields: tempUpdatedField,
      dirtyRows: tempDirtyRows,
    });
  }

  edit(row) {
    const { updatedFields, selectedFluid } = this.state;
    const updatedTemp = _.cloneDeep(updatedFields);
    updatedTemp[row] = _.cloneDeep(selectedFluid[row]);
    this.setState(
      {
        updatedFields: updatedTemp,
      },
      () => {
        this.setEditRows();
        this.enableEditForm();
      }
    );
  }

  undo(row) {
    const { updatedFields, selectedFluid } = this.state;
    const tempSelectedFluid = _.cloneDeep(selectedFluid);
    const tempUpdatedField = _.cloneDeep(updatedFields);
    tempSelectedFluid[row] = _.cloneDeep(tempUpdatedField[row]);
    tempUpdatedField[row] = null;
    this.setState(
      {
        selectedFluid: tempSelectedFluid,
        updatedFields: tempUpdatedField,
      },
      () => {
        this.setEditRows();
      }
    );
  }

  delete(row) {
    const scope = this;
    const { selectedFluid, updatedFields, dirtyRows } = this.state;
    const tempSelectedFluid = _.cloneDeep(selectedFluid);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    const tempUpdatedField = _.cloneDeep(updatedFields);
    tempSelectedFluid.splice(row, 1);
    tempUpdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    const tempSearched = this.state.selectedUnsearchedFluid.filter(
      (e) => e.name !== scope.state.selectedFluid[row].name
    );
    this.setState(
      {
        selectedFluid: tempSelectedFluid,
        updatedFields: tempUpdatedField,
        selectedUnsearchedFluid: tempSearched,
        dirtyRows: tempDirtyRows,
      },
      () => {
        this.setSelectedFluidKeys(this.state.selectedFluid);
        this.setEditRows();
        this.enableEditForm();
      }
    );
  }

  onSearch(value) {
    const { preserveFluidList } = this.state;
    const data = _.cloneDeep(preserveFluidList);
    data.items = [
      ...data.items.filter(
        (e) => e.shortName.toLowerCase().indexOf(value.toLowerCase()) > -1
      ),
    ];
    const temp = this.state.selectedFluid.filter(
      (e) =>
        e.name
          .substring(0, value.length)
          .toLowerCase()
          .indexOf(value.toLowerCase()) < 0
    );
    this.setState(
      {
        fluidList: data,
        selectedUnsearchedFluid: [...temp],
      },
      () => {
        this.setSelectedFluidKeys(this.state.selectedFluid);
      }
    );
  }

  ratioChanged(value) {
    const { selectedFluid } = this.state;
    if (selectedFluid) {
      const selectedTemp = [...selectedFluid];

      for (let i = 0; i < selectedTemp.length; i++) {
        selectedTemp[i].value =
          parseInt(value) === 1
            ? parseFloat(
                Decimal.div(parseFloat(selectedTemp[i].value), 100).valueOf()
              )
            : parseFloat(
                Decimal.mul(parseFloat(selectedTemp[i].value), 100).valueOf()
              );
        selectedTemp[i].value = parseFloat(selectedTemp[i].value);
      }

      this.setState(
        {
          selectedFluid: selectedTemp,
          ratioValue: parseInt(value),
        },
        () => {
          this.enableEditForm();
        }
      );
    }
  }

  calculateCompositionSum() {
    let sum = 0;
    const { selectedFluid } = this.state;
    if (selectedFluid) {
      const selectedTemp = [...selectedFluid];
      for (let i = 0; i < selectedTemp.length; i++) {
        sum = parseFloat(
          Decimal.add(
            sum,
            selectedTemp[i].value ? parseFloat(selectedTemp[i].value) : 0
          ).valueOf()
        );
      }
      return parseFloat(sum.toPrecision(5));
    }
  }

  equalize() {
    const { selectedFluid } = this.state;
    const selectedTemp = [...selectedFluid];

    for (let i = 0; i < selectedTemp.length; i++) {
      selectedTemp[i].value = parseFloat(
        Decimal.div(this.state.ratioValue, selectedTemp.length).valueOf()
      ).toPrecision(6);
    }
    this.setState(
      {
        selectedFluid: selectedTemp,
        dirtyRows: [],
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  normalize() {
    const { selectedFluid } = this.state;
    const selectedTemp = [...selectedFluid];
    const sum = this.calculateCompositionSum();

    if (sum === 0) {
      this.equalize();
      return true;
    }

    if (this.state.dirtyRows.length === 0) {
      for (let i = 0; i < selectedTemp.length; i++) {
        selectedTemp[i].value = parseFloat(
          Decimal.div(
            Decimal.mul(
              parseFloat(selectedTemp[i].value),
              this.state.ratioValue
            ).valueOf(),
            sum
          ).valueOf()
        ).toPrecision(6);
      }
      this.setState({ selectedFluid: selectedTemp }, () => {
        this.enableEditForm();
      });
    } else {
      Notification.show(Types.Error, FluidCompositionMessages.FIELDS_DIRTY);
    }
  }

  onFluidSelectionChange(key, row) {
    const selected = [];
    let status = false;
    for (let i = 0; i < row.length; i++) {
      status = false;
      for (
        let j = 0;
        this.state.selectedFluid && j < this.state.selectedFluid.length;
        j++
      ) {
        if (row[i].shortName === this.state.selectedFluid[j].name) {
          selected.push(this.state.selectedFluid[j]);
          status = true;
          break;
        }
      }
      if (!status) {
        const temp = {};
        temp.name = row[i].shortName;
        temp.value = 0;
        selected.push(temp);
      }
    }
    this.setState(
      {
        selectedFluid: [...this.state.selectedUnsearchedFluid, ...selected],
        selectedFluidKeys: key,
      },
      () => {
        this.enableEditForm();
        this.setEditRows();
      }
    );
  }

  save() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const {
      addFluid,
      location: { state },
    } = this.props;
    const {
      ratioValue,
      selectedFluid,
      calcDischargeConditions,
    } = this.state;
    const body = {};

    if (selectedFluid && selectedFluid.length) {
      for (let i = 0; i < selectedFluid.length; i++) {
        selectedFluid[i].value = parseFloat(selectedFluid[i].value);
      }
    }

    this.props.form.validateFields((err, values) => {
      let pres1 = null;
      if (values.equationState) {
        pres1 = ScEquationOfState.find(
          (ele) => ele.value === values.equationState
        )};
      if (!err && this.state.dirtyRows.length === 0) {
        const selectedFluidSend = selectedFluid;
        Object.keys(selectedFluidSend).forEach((item) => {
          delete selectedFluidSend[item].fractionValue;
        });
        if (name === Compressor) {
          Object.assign(body, {
            equationState: pres1._id,
            type: values.type,
            moleFraction: `${ratioValue}`,
            fluids: selectedFluidSend,
            totalValue: 1,
            calculateDischargeConditions: calcDischargeConditions,
          });
        } else {
          Object.assign(body, {
            equationState: pres1._id,
            type: values.type,
            moleFraction: `${ratioValue}`,
            fluids: selectedFluidSend,
            totalValue: 1,
          });
        }

        if (selectedFluid && selectedFluid.length) {
          if (
            this.calculateCompositionSum() === parseFloat(this.state.ratioValue)
          ) {
            if (name === MIXER) {
              this.props.setAddData(_.cloneDeep(body));
            } else if (this.handleNetwork()) {
              addFluid(
                body,
                state.componentData.info._id,
                state.componentData.assetInfo.name
              );
            }
          } else {
            this.props.setIsSave();
            this.setState({
              normalizePopupVisible: true,
            });
          }
        } else {
          this.props.setIsSave();
          Notification.show(
            Types.Error,
            FluidCompositionMessages.NO_FLUID_SELECTED
          );
        }
      } else {
        this.props.setIsSave();
      }
    });
  }

  clearDirtyForm() {
    const { preserveFluidData } = this.state;
    this.props.form.resetFields();
    this.setState(
      {
        editRows: [],
        updatedFields: [],
        dirtyRows: [],
        dirtyForm: 0,
      },
      () => {
        if (
          JSON.stringify(`${this.state.ratioValue}`) !==
            JSON.stringify(preserveFluidData.moleFraction) ||
          JSON.stringify(this.state.selectedFluid) !==
            JSON.stringify(preserveFluidData.fluids) ||
          JSON.stringify(this.props.form.getFieldValue('equationState')) !==
            JSON.stringify(preserveFluidData.equationState) ||
          JSON.stringify(this.props.form.getFieldValue('type')) !==
            JSON.stringify(preserveFluidData.type)
        ) {
          this.setFluidData(_.cloneDeep(preserveFluidData));
        }
      }
    );
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleNormalizeCancel() {
    this.setState({
      normalizePopupVisible: false,
    });
  }

  handleNormalizeOk() {
    this.setState(
      {
        normalizePopupVisible: false,
      },
      () => {
        this.normalize();
        this.save();
      }
    );
  }

  renderNormalizeModal() {
    if (this.state.normalizePopupVisible) {
      return (
        <ConfirmModal
          title={FluidCompositionVariables.NORMALIZE}
          visible={this.state.normalizePopupVisible}
          handleOk={() => this.handleNormalizeOk()}
          handleCancel={() => this.handleNormalizeCancel()}
          message={<div>{FluidCompositionMessages.EXTRAPOLATING_MESSAGE1}</div>}
        />
      );
    }
    return null;
  }

  setCalcDischargeConditions = (value) => {
    this.setState({
      calcDischargeConditions: value,
    });
    this.enableEditForm();
  };

  render() {
    const isConfigEditable = this.props.streamId
      ? this.props.mixerEditable
      : this.props.isSchematicEditable;
    const { loading } = this.props.fluidCompositionState;
    const { name } = this.props.location.state.componentData.assetInfo;
    const { calcDischargeConditions } = this.state;
    return (
      <div className="FluidComposition">
        <div className="FluidCompositionInner">
          {loading ? <Loader /> : null}
          <div className="data-info">
            {name === Compressor ? (
              <FormFields
                formFields={CompressorFormFields({
                  changeEquation: this.setEquationOfStateValue,
                  changeType: this.setType,
                  isConfigEditable: this.props.streamId
                    ? this.props.mixerEditable
                    : this.props.isSchematicEditable,
                  calcDischargeConditions: calcDischargeConditions,
                  changeCalcDischargeConditions: this
                    .setCalcDischargeConditions,
                })}
                form={this.props.form}
              />
            ) : (
              <FormFields
                formFields={formFields({
                  changeEquation: this.setEquationOfStateValue,
                  changeType: this.setType,
                  isConfigEditable: this.props.streamId
                    ? this.props.mixerEditable
                    : this.props.isSchematicEditable,
                  component: name
                })}
                form={this.props.form}
              />
            )}
          </div>
          <div className="data-list">
            <div className="fluid-list">
              <Search
                onChange={(e) => {
                  this.onSearch(e.target.value);
                }}
                placeholder={FluidCompositionVariables.SEARCH}
                disabled={!isConfigEditable}
                allowClear
              />
              <hr />
              <Table
                rowSelection={rowSelection(
                  {
                    change: isConfigEditable
                      ? (key, row) => {
                          this.onFluidSelectionChange(key, row);
                        }
                      : () => {},
                  },
                  this.state.selectedFluidKeys
                )}
                columns={searchTable()}
                dataSource={this.state.fluidList && this.state.fluidList.items}
                pagination={{
                  total: this.state.fluidList.total_count,
                  pageSize: this.state.fluidList.total_count,
                  hideOnSinglePage: true,
                }}
                scroll={{ y: 300 }}
              />
            </div>
            <div className="selected-fluid-list">
              <CustomTable
                columns={fluidTableColumns({
                  change: (field, row) => {
                    this.onValueChange(field, row);
                  },
                  edit: (row) => {
                    this.edit(row);
                  },
                  delete: (row) => {
                    this.delete(row);
                  },
                  undo: (row) => {
                    this.undo(row);
                  },
                  isConfigEditable,
                })}
                data={this.state.selectedFluid}
                editableRow={this.state.editRows}
                headerColumns={headerColumns({
                  ratioChanged: (value) => {
                    this.ratioChanged(value);
                  },
                  ratioValue: this.state.ratioValue,
                  isConfigEditable,
                })}
                footerColumns={footerColumns({
                  normalize: () => {
                    this.normalize();
                  },
                  ratioValue: this.state.ratioValue,
                  totalSum: this.calculateCompositionSum(),
                  isConfigEditable,
                })}
              />
            </div>
          </div>
          {this.state.formEdit &&
            this.props.isSchematicEditable &&
            name !== MIXER && (
              <div className="config-bottom-buttons">
                <button
                  className="btn-default btn-white"
                  onClick={() => {
                    this.reset();
                  }}
                >
                  {ButtonVariables.RESET}
                </button>
                <button
                  className="btn-default"
                  onClick={() => {
                    this.save();
                  }}
                >
                  {ButtonVariables.SAVE}
                </button>
              </div>
            )}
          {this.renderModal()}
          {this.renderNormalizeModal()}
        </div>
      </div>
    );
  }
}

FluidComposition.propTypes = {
  isSave: PropType.bool,
  isSchematicEditable: PropType.bool.isRequired,
  streamId: PropType.string,
  setIsSave: PropType.func,
  addFluid: PropType.func,
  setAddData: PropType.func,
  getStreamData: PropType.func,
  clearStreamState: PropType.func,
  getFluidListAction: PropType.func,
  clearFluidCompositionState: PropType.func,
  match: PropType.object,
  form: PropType.object,
  network: PropType.object,
  history: PropType.object,
  location: PropType.object,
  configState: PropType.object,
  streamState: PropType.object,
  fluidCompositionState: PropType.object,
  mixerEditable: PropType.bool,
};

FluidComposition.defaultProps = {
  setIsSave: () => {},
  mixerEditable: true,
};

const mapStateToProps = (state) => ({
  fluidCompositionState: state.FluidCompositionReducer,
  network: state.NetworkReducer,
  configState: state.ConfigReducer,
  streamState: state.streamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getFluidListAction: (payload) => {
    dispatch(getFluidList(payload));
  },
  clearFluidCompositionState: () => dispatch(clearFluidCompositionState()),
  addFluid: (payload, id, type) => dispatch(addFluid(payload, id, type)),
  getStreamData: (id) => dispatch(getStreamData(id)),
  clearStreamState: () => dispatch(clearStreamState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(FluidComposition))
);
