import Notification, { Types } from "../common/Notification/Notification";
import { fileMessage, imageMessage } from "../constants/messages";
import { oneMB } from "../constants/variables";
import { getToken } from "./token";

let BASE_API_PATH = process.env.REACT_APP_DEV_API;
if (process.env.NODE_ENV !== "development") {
  BASE_API_PATH = process.env.REACT_APP_PROD_API;
}

export const fileCsvProps = {
  name: "file",
  multiple: true,
  action: `${BASE_API_PATH}/pitag`,
  showUploadList: false,
  headers: {
    authorization: getToken(),
    api_key: process.env.REACT_APP_API_KEY,
  },
};

export const fileProps = {
  name: "file",
  multiple: true,
  action: `${BASE_API_PATH}/files`,
  showUploadList: false,

  beforeUpload: (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Notification.show(Types.Error, fileMessage.type);
    }
    const isLessThan500KB = file.size / oneMB < 500;
    if (!isLessThan500KB) {
      Notification.show(Types.Error, fileMessage.maxSize);
    }
    return isJpgOrPng && isLessThan500KB;
  },
};

export const imgProps = {
  name: "file",
  multiple: true,
  action: `${BASE_API_PATH}/files?type=assetsequipment`,
  showUploadList: false,

  beforeUpload: (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/svg+xml";
    if (!isJpgOrPng) {
      Notification.show(Types.Error, imageMessage.type);
    }
    const isLessThan1MB = file.size / oneMB <= 1024;
    if (!isLessThan1MB) {
      Notification.show(Types.Error, imageMessage.maxSize);
    }
    return isJpgOrPng && isLessThan1MB;
  },
};

export const fileUploadProps = {
  name: "file",
  multiple: true,
  action: `${BASE_API_PATH}/files?type=all`,
  showUploadList: false,

  beforeUpload: (file) => {
    const isLessThan1GB = file.size / oneMB < 1048576000;
    if (!isLessThan1GB) {
      Notification.show(Types.Error, fileMessage.maxSize);
    }
    return isLessThan1GB;
  },
};
