import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../libs/token';
import { getLatestRunData } from '../../../redux/actions/runTags.action';
import calculateKPIs from './KPIcalcs';

const RunsCard = (props) => {
  const [runStats, setRunStats] = useState('');
  let val = useSelector((state) => state.RunTagsReducer.lastRunData);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLatestRunData(props.equipmentId))
  },[]);// eslint-disable-line

  let API_URL = process.env.REACT_APP_DEV_API;
  if (process.env.NODE_ENV === 'development') {
    API_URL = process.env.REACT_APP_DEV_API;
  } else {
    API_URL = process.env.REACT_APP_PROD_API;
  }
  //const data = useQuery("Runs", fetchLatestRun(props.equipmentId))
  const { data, status } = useQuery('Runs', () =>
    axios(`${API_URL}/runs/latest/equipment/${props.equipmentId}`, {
      headers: {
        Authorization: getToken(),
        api_key: process.env.REACT_APP_API_KEY, 
      },
    })
  );

  useEffect(() => {
    if (status === 'success' && data.data) {
      const interval = setInterval(() => {
        liveCalculation();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [status]); // eslint-disable-line

  useEffect(() => {
    if (status === 'success' && data.data) {
      liveCalculation()
    }
  }, [status])// eslint-disable-line

  const liveCalculation = async () => {
    let KPIdata = await calculateKPIs(data.data, props.cardData, val.data, props.abbreviation);
    setRunStats(KPIdata);
  };
  //console.log(props)
  return <div className="runStats">{runStats}</div>;
};
export default RunsCard;
