import * as Routes from "./constants/routes";
import * as Components from "./constants/component";
import { authenticated, unauthenticated, roleGaurd } from "./gaurds";

export const routes = [
  {
    path: Routes.root,
    component: Components.login,
    active: unauthenticated,
    redirect: Routes.app,
    exact: true,
  },
  {
    path: Routes.callback,
    component: Components.AuthRedirect,
    active: unauthenticated,
    exact: true,
  },
  {
    path: Routes.login,
    component: Components.login,
    active: unauthenticated,
    redirect: Routes.app,
  },
  {
    path: Routes.forgotPassword,
    component: Components.forgotPassword,
    active: unauthenticated,
    redirect: Routes.app,
  },
  {
    path: Routes.resetPassword,
    component: Components.resetPassword,
    active: unauthenticated,
    redirect: Routes.app,
  },
  {
    path: Routes.forgotPasswordReset,
    component: Components.resetPassword,
    active: unauthenticated,
    redirect: Routes.app,
  },
  {
    path: Routes.app,
    component: Components.appScreen,
    active: authenticated,
    redirect: Routes.login,
    children: [
      {
        path: Routes.app,
        exact: true,
        component: Components.dashboard,
        active: () => roleGaurd(Routes.app),
        redirect: Routes.root,
      },
      {
        path: Routes.dashboard,
        component: Components.dashboard,
        active: () => roleGaurd(Routes.dashboard),
        redirect: Routes.app,
      },
      {
        path: Routes.clients,
        component: Components.clientListing,
        active: () => roleGaurd(Routes.clients),
        redirect: Routes.app,
      },
      {
        path: Routes.clientAdd,
        component: Components.clientDetails,
        active: () => roleGaurd(Routes.clientAdd),
        redirect: Routes.app,
      },

      {
        path: Routes.clientInfo,
        component: Components.clientInfo,
        active: () => roleGaurd(Routes.clientInfo),
        redirect: Routes.app,
        children: [
          {
            path: Routes.clientInfo,
            component: Components.clientDetails,
            exact: true,
          },
          {
            path: Routes.clientFacility,
            component: Components.clientFacility,
          },
          {
            path: Routes.clientSystem,
            component: Components.clientSystem,
          },
          {
            path: Routes.systemSchematic,
            component: Components.systemSchematic,
          },
          {
            path: Routes.clientEquipment,
            component: Components.clientEquipment,
          },
          {
            path: Routes.componentSchematic,
            component: Components.componentSchematic,
          },
          {
            path: Routes.componentInformation,
            component: Components.componentInformation,
          },
          {
            path: Routes.manageTags,
            component: Components.manageTags,
          },
          {
            path: Routes.dirty,
            component: Components.notFound,
          },
        ],
      },
      {
        path: Routes.userManagement,
        component: Components.userManagement,
        active: () => roleGaurd(Routes.userManagement),
        redirect: Routes.app,
      },
      {
        path: Routes.addUser,
        component: Components.addUser,
        active: () => roleGaurd(Routes.addUser),
        redirect: Routes.app,
      },
      {
        path: Routes.assetLibrary,
        component: Components.assetLibrary,
        active: () => roleGaurd(Routes.assetLibrary),
        redirect: Routes.app,
      },
      {
        path: Routes.addEquipment,
        component: Components.addEquipment,
        active: () => roleGaurd(Routes.addEquipment),
        redirect: Routes.app,
      },
      {
        path: Routes.unitProperties,
        component: Components.unitProperties,
        active: () => roleGaurd(Routes.unitProperties),
        redirect: Routes.app,
      },
      {
        path: Routes.mlAlgorithm,
        component: Components.mlAlgorithm,
        active: () => roleGaurd(Routes.mlAlgorithm),
        redirect: Routes.app,
      },
      {
        path: Routes.setting,
        component: Components.setting,
        active: () => roleGaurd(Routes.setting),
        redirect: Routes.app,
      },
      {
        path: Routes.schematic,
        component: Components.schematic,
        active: () => roleGaurd(Routes.schematic),
        redirect: Routes.app,
      },
      {
        path: Routes.dirty,
        component: Components.notFound,
        active: () => roleGaurd(Routes.dirty),
        redirect: Routes.app,
      },
    ],
  },
  {
    path: Routes.dirty,
    component: Components.notFound,
  },
];
