import React from "react";
import { Icon, Input } from "antd";
import { CapacityControlVariables, OilDataVariable, designFieldConstant, ScrewCompressorDesignVariable, ValveDataVariable, inputSections, filedsWithUnit } from "./designDataFieldConstants";
import { validateNonZeroInteger, validateNumberNegative, validatePositiveNumber } from "../../../../../../libs/validator";
import Notification, { Types } from "../../../../../../common/Notification/Notification";
import { RecipCompDataMessage } from "../../../../../../constants/messages";

export const renderHeader = (headerData, unitData, onEdit, onUndo, handleChange, headingName, stateName, saveFlag, 
  isSchematicEditable, dirtyValue, dirtyUnits
  ) => {
  return headerData({
    unitData,
    onEdit: onEdit,
    onUndo: onUndo,
    handleChange: handleChange,
    headingName: headingName,
    stateName: stateName,
    saveFlag: saveFlag,
    isSchematicEditable: isSchematicEditable,
    dirtyValue: dirtyValue,
    dirtyUnits: dirtyUnits
  })
}



const compressorType = ["Oil Free", "Oil Injected"]
const fluidComposition = ["Air", "Others"]
const capacityControlType = ["Fixed", "Variable Speed", "Inlet Slide Valve"]
const volumeReductionControl = ["Fixed", "Slide Valve"]
const flowMeterLocation = ["Suction", "Discharge"]

export const designDataHeader = (config) => [
  {
    title: config.headingName,
    dataIndex: 'name',
    render: (value) => {
      if (
        value === ScrewCompressorDesignVariable.application ||
        value === ScrewCompressorDesignVariable.configSpeed ||
        value === ScrewCompressorDesignVariable.compressorType ||
        value === ScrewCompressorDesignVariable.mechanicalEfficiency ||
        value === ScrewCompressorDesignVariable.startupSpeedThreshold ||
        value === ScrewCompressorDesignVariable.startupPowerThreshold ||
        value === ScrewCompressorDesignVariable.fluidComposition ||
        value === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
        value === ScrewCompressorDesignVariable.designVolumeReduction ||
        value === ScrewCompressorDesignVariable.maleRotorLobes ||
        value === ScrewCompressorDesignVariable.maleRotorDiameter ||
        value === ScrewCompressorDesignVariable.designVolumetricEfficiency
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        )
      }
      return value
    }
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === ScrewCompressorDesignVariable.configSpeed ||
        item.name === ScrewCompressorDesignVariable.maximumOperatingSpeed ||
        item.name === ScrewCompressorDesignVariable.minimumOperatingSpeed ||
        item.name === ScrewCompressorDesignVariable.startupSpeedThreshold ||
        item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
        item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
        item.name === ScrewCompressorDesignVariable.barometricPressure ||
        item.name === ScrewCompressorDesignVariable.ambientTemperature ||
        item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
        item.name === ScrewCompressorDesignVariable.condensateVapourFlowrate ||
        item.name === ScrewCompressorDesignVariable.inletPressureRated ||
        item.name === ScrewCompressorDesignVariable.inletPressureMaximum ||
        item.name === ScrewCompressorDesignVariable.inletTemperatureMaximum ||
        item.name === ScrewCompressorDesignVariable.inletTemperatureRated ||
        item.name === ScrewCompressorDesignVariable.inletVolumetricFlow ||
        item.name === ScrewCompressorDesignVariable.dischargePressureRated ||
        item.name === ScrewCompressorDesignVariable.dischargePressureMaximum ||
        item.name === ScrewCompressorDesignVariable.dischargeTemperatureRated ||
        item.name === ScrewCompressorDesignVariable.dischargeTemperatureMaximum ||
        item.name === ScrewCompressorDesignVariable.ratedIsentropicPower ||
        item.name === ScrewCompressorDesignVariable.ratedBrakePower ||
        item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
        item.name === ScrewCompressorDesignVariable.femaleRotorDiameter ||
        item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency ||
        item.name === OilDataVariable.oilPressure ||
        item.name === OilDataVariable.designOilFlowRate ||
        item.name === OilDataVariable.oilViscosity ||
        item.name === OilDataVariable.oilSpecificHeat ||
        item.name === OilDataVariable.oilDensity ||
        item.name === OilDataVariable.oilPumpEffeciency
      ) {
        const unitError = !!(
          (
            item.name === ScrewCompressorDesignVariable.configSpeed ||
            item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
            item.name === ScrewCompressorDesignVariable.startupSpeedThreshold ||
            item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
            item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
            item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
            item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency ||
            (config.dirtyUnits.indexOf(item.name) >= 0)
          ) &&
          (item.unit === "" || item.unit === undefined || item.unit === null || item.unit === 'Select')
          && config.saveFlag
        );
        return (
          <div
            className={`custom-table-select ${unitError ? 'error' : 'not-error'}  `} >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (
                  item.name === ScrewCompressorDesignVariable.configSpeed ||
                  item.name === ScrewCompressorDesignVariable.maximumOperatingSpeed ||
                  item.name === ScrewCompressorDesignVariable.minimumOperatingSpeed ||
                  item.name === ScrewCompressorDesignVariable.startupSpeedThreshold
                ) {
                  if (getUnits.dataVariable === "Speed") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
                  item.name === ScrewCompressorDesignVariable.ratedIsentropicPower ||
                  item.name === ScrewCompressorDesignVariable.ratedBrakePower
                ) {
                  if (getUnits.dataVariable === "Startup Power Threshold") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
                  item.name === OilDataVariable.oilPumpEffeciency ||
                  item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency
                ) {
                  if (getUnits.dataVariable === "Efficiency") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.designRelativeHumidity) {
                  if (getUnits.dataVariable === "Relative Humidity (Ambient)") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
                  item.name === ScrewCompressorDesignVariable.condensateVapourFlowrate ||
                  item.name === ScrewCompressorDesignVariable.inletVolumetricFlow ||
                  item.name === OilDataVariable.designOilFlowRate
                ) {
                  if (getUnits.dataVariable === "Volumetric Flow") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (
                  item.name === ScrewCompressorDesignVariable.inletPressureRated ||
                  item.name === ScrewCompressorDesignVariable.inletPressureMaximum ||
                  item.name === ScrewCompressorDesignVariable.dischargePressureRated ||
                  item.name === ScrewCompressorDesignVariable.barometricPressure ||
                  item.name === ScrewCompressorDesignVariable.dischargePressureMaximum ||
                  item.name === OilDataVariable.oilPressure ||
                  item.name === OilDataVariable.dischargePressureMaximum ||
                  item.name === OilDataVariable.dischargePressureMaximum ||
                  item.name === OilDataVariable.dischargePressureMaximum
                ) {
                  if (getUnits.dataVariable === "Inlet Pressure") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
                  item.name === OilDataVariable.oilPumpEffeciency
                ) {
                  if (getUnits.dataVariable === "Efficiency") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.inletTemperatureRated ||
                  item.name === ScrewCompressorDesignVariable.inletTemperatureMaximum ||
                  item.name === ScrewCompressorDesignVariable.dischargeTemperatureRated ||
                  item.name === ScrewCompressorDesignVariable.dischargeTemperatureMaximum ||
                  item.name === ScrewCompressorDesignVariable.ambientTemperature
                ) {
                  if (getUnits.dataVariable === "Rated Temperature") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
                  item.name === ScrewCompressorDesignVariable.femaleRotorDiameter
                ) {
                  if (getUnits.dataVariable === "Diameter") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === OilDataVariable.oilViscosity
                ) {
                  if (getUnits.dataVariable === "Fluid Dynamic Viscosity (μ)") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === OilDataVariable.oilSpecificHeat
                ) {
                  if (getUnits.dataVariable === "Oil Specific Heat") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                else if (item.name === OilDataVariable.oilDensity
                ) {
                  if (getUnits.dataVariable === "Fluid Density") {
                    return getUnits.units.map((dispUnit) => (
                      <option value={dispUnit}>{dispUnit}</option>
                    ));
                  }
                }
                return undefined;
              })}
            </select>
          </div>
        )
      }
    }
  },
  {
    title: "Design",
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const designError = !!(
        (
          item.name === ScrewCompressorDesignVariable.application ||
          item.name === ScrewCompressorDesignVariable.configSpeed ||
          item.name === ScrewCompressorDesignVariable.compressorType ||
          item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
          item.name === ScrewCompressorDesignVariable.startupSpeedThreshold ||
          item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
          item.name === ScrewCompressorDesignVariable.fluidComposition ||
          item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
          item.name === ScrewCompressorDesignVariable.designVolumeReduction ||
          item.name === ScrewCompressorDesignVariable.maleRotorLobes ||
          item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
          item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency ||
          (config.dirtyValue.indexOf(item.name) >= 0)
        ) &&
        (item.design === "" || item.design === undefined || item.design === null || item.design === "Select" ||
          (typeof (item.design) != 'string' && isNaN(item.design))
        ) &&
        config.saveFlag
      )
      if (item.name === ScrewCompressorDesignVariable.application ||
        item.name === ScrewCompressorDesignVariable.driver ||
        item.name === ScrewCompressorDesignVariable.cylinderConstruction ||
        item.name === ScrewCompressorDesignVariable.cylinderConfiguration ||
        item.name === OilDataVariable.loadingmode1 ||
        item.name === OilDataVariable.loadingmode2 ||
        item.name === OilDataVariable.loadingmode3 ||
        item.name === OilDataVariable.crankEndClearancePocketMode ||
        item.name === OilDataVariable.headEndClearancePocketMode ||
        item.name === ValveDataVariable.calculateValveLosses ||
        item.name === CapacityControlVariables.using ||
        item.name === CapacityControlVariables.by ||
        item.name === CapacityControlVariables.for ||
        item.name === CapacityControlVariables.with ||
        item.name === CapacityControlVariables.action
      ) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {designFieldConstant[item.name] &&
                designFieldConstant[item.name].map((item) => (
                  <option value={item.value} title={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.purpose ||
        item.name === OilDataVariable.oilType
      ) {
        return (
          <div className>
            <Input
              type="text"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'text')
              }
              className={designError ? 'error' : 'not-error'}
            />
          </div>
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.compressorType) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {compressorType &&
                compressorType.map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.fluidComposition) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {fluidComposition &&
                fluidComposition.map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === CapacityControlVariables.capacityControlType) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {capacityControlType &&
                capacityControlType.map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === CapacityControlVariables.volumeReductionControl) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {volumeReductionControl &&
                volumeReductionControl.map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.flowmeterLocation) {
        return (
          <div
            className={`custom-table-select ${designError ? 'error' : 'not-error'}  `}
          >
            <select
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              value={item.design ? item.design : item.design === false ? false : "Select"}
            >
              <option>Select</option>
              {flowMeterLocation &&
                flowMeterLocation.map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div >
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.inletPressureRated ||
        item.name === ScrewCompressorDesignVariable.inletPressureMaximum ||
        item.name === ScrewCompressorDesignVariable.inletTemperatureMaximum ||
        item.name === ScrewCompressorDesignVariable.inletTemperatureRated ||
        item.name === ScrewCompressorDesignVariable.inletTemperatureMaximum ||
        item.name === ScrewCompressorDesignVariable.dischargePressureRated ||
        item.name === ScrewCompressorDesignVariable.dischargePressureMaximum ||
        item.name === ScrewCompressorDesignVariable.dischargeTemperatureRated ||
        item.name === ScrewCompressorDesignVariable.dischargeTemperatureMaximum
        ) {
        return (
          <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validateNumberNegative(e, e.target.value);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={designError ? 'error' : 'not-error'}
          />
        </div>
        )
      }
      else if (item.name === ScrewCompressorDesignVariable.maleRotorLobes ||
        item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
        item.name === ScrewCompressorDesignVariable.femaleRotorLobes ||
        item.name === ScrewCompressorDesignVariable.femaleRotorDiameter ||
        item.name === ScrewCompressorDesignVariable.pressureRatioRated
        ) {
        return (
          <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validateNonZeroInteger(e, e.target.value);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={designError ? 'error' : 'not-error'}
          />
        </div>
        )
      }
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              validatePositiveNumber(e, e.target.value);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={designError ? 'error' : 'not-error'}
          />
        </div>)
    }
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => (
      <div className="more-items">{
        !config.isSchematicEditable ? (<></>) :
          disabled ? (
            <button onClick={() => config.onEdit(row, config.stateName)}>
              <Icon type="edit" />
            </button>
          ) : (
            <button onClick={() => config.onUndo(row, config.stateName, item.name)} >
              <Icon type="undo" />
            </button>
          )
      }
      </div>
    ),
  },
]

export const filterEquipmentData = (displayData, variableList) => {
  return displayData.filter((item) => {
    for (const key in variableList) {
      if (item.name === variableList[key]) {
        return item
      }
    }
    return undefined;
  });
}

export const Validate = async (displayData, setDirtyUnits, setDirtyValues) => {
  let mandatoryCheckError = false;
  let unitCheckError = false;
  let dirtyUnits = [],
  dirtyValue = []
  // mandatory field check
  displayData.forEach((item) => {
    if (
      item.name === ScrewCompressorDesignVariable.application ||
      item.name === ScrewCompressorDesignVariable.configSpeed ||
      item.name === ScrewCompressorDesignVariable.compressorType ||
      item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
      item.name === ScrewCompressorDesignVariable.startupSpeedThreshold ||
      item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
      item.name === ScrewCompressorDesignVariable.fluidComposition ||
      item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
      item.name === ScrewCompressorDesignVariable.designVolumeReduction ||
      item.name === ScrewCompressorDesignVariable.maleRotorLobes ||
      item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
      item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency
    ) {
      if (!item.design || item.design === "Select" || (typeof (item.design) != 'string' && isNaN(item.design))) {
        if ((typeof (item.design) != 'string' && isNaN(item.design)) || item.design === "Select") {
          mandatoryCheckError = true;
        }
        else if (item.design !== null && (item.design === 0 || typeof (item.design === 'boolean'))) {
        }
        else {
          mandatoryCheckError = true;
        }
      }
    }
    if (
      item.name === ScrewCompressorDesignVariable.configSpeed ||
      item.name === ScrewCompressorDesignVariable.mechanicalEfficiency ||
      item.name === ScrewCompressorDesignVariable.startupSpeedThreshold ||
      item.name === ScrewCompressorDesignVariable.startupPowerThreshold ||
      item.name === ScrewCompressorDesignVariable.ratedVolumetricDisplacement ||
      item.name === ScrewCompressorDesignVariable.maleRotorDiameter ||
      item.name === ScrewCompressorDesignVariable.designVolumetricEfficiency
    ) {
      if (!item.unit || item.unit === 'Select') {
        unitCheckError = true;
      }
    }
    if(((!item.design && item.design !== 0) && (item.unit && item.unit !== 'Select'))){
      unitCheckError = true
      dirtyValue.push(item.name)
    }
    if((item.design || item.design === 0)&& (!item.unit || item.unit === 'Select')){
      filedsWithUnit.forEach((field) => {
        if(inputSections[field] === item.name){
          dirtyUnits.push(item.name)
          unitCheckError = true
        }
      })
    }
  });
  setDirtyValues(dirtyValue)
  setDirtyUnits(dirtyUnits)
  if (mandatoryCheckError || unitCheckError){
    Notification.show(Types.Error, RecipCompDataMessage.DIRTY_DATA)
  }
  // return false
  return mandatoryCheckError || unitCheckError;
}