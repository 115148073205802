import NotFound from "../libs/Notfound";
import AppScreen from "../components/AppScreen/AppScreen";
import UnitsProperties from "../components/UnitsProperties/UnitsProperties";
import ClientDetails from "../components/Clients/ClientDetails/ClientDetails";
import ClientListing from "../components/Clients/ClientListing/ClientListing";
import AssetLibrary from "../components/AssetLibrary/AssetLibrary";
import AddEquipment from "../components/AssetLibrary/AddEquipment/AddEquipment";
// import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import ClientInfo from "../components/Clients/ClientInfo/ClientInfo";
import ClientFacility from "../components/Clients/ClientFacility/ClientFacility";
import ClientSystem from "../components/Clients/ClientSystem/ClientSystem";
import ClientEquipment from "../components/Clients/ClientEquipment/ClientEquipment";
import ComponentSchematic from "../components/Clients/ComponentSchematic/ComponentSchematic";
import ComponentInformation from "../components/Clients/ComponentInformation/ComponentInformation";
import UserManagement from "../components/UserManagement/UserManagement";
import Dashboard from "../components/Dashboard/Dashboard";
import AddUser from "../components/UserManagement/AddUser/AddUser";
import ResetPassword from "../components/UserManagement/ResetPassword/ResetPassword";
import SystemSchematic from "../components/Clients/SystemSchematic/SystemSchematic";
import ManageTags from "../components/Clients/ManageTags/ManageTags";
import ML_Algorithm from "../components/ML Algoritm";
import SystemSchematicSideMenu from "../components/System Schematic SideMenu/systemSchematicSideMenu";
import Settings from "../components/settings/Settings";
import Auth0Login from "../components/Login/Auth0Login";
import Auth0Redirect from "../components/Login/Auth0Redirect";

export const login = Auth0Login;
export const AuthRedirect = Auth0Redirect
export const forgotPassword = ForgotPassword;
export const appScreen = AppScreen;
export const userManagement = UserManagement;
export const addUser = AddUser;
export const resetPassword = ResetPassword;
export const dashboard = Dashboard;
export const assetLibrary = AssetLibrary;
export const addEquipment = AddEquipment;
export const unitProperties = UnitsProperties;
export const mlAlgorithm = ML_Algorithm;
export const schematic = SystemSchematicSideMenu;

export const clients = NotFound;
export const roles = NotFound;
export const reporting = NotFound;
export const setting = Settings;

export const clientListing = ClientListing;
export const clientDetails = ClientDetails;
export const clientInfo = ClientInfo;
export const clientFacility = ClientFacility;
export const clientSystem = ClientSystem;
export const systemSchematic = SystemSchematic;
export const clientEquipment = ClientEquipment;
export const manageTags = ManageTags;

export const componentSchematic = ComponentSchematic;
export const componentInformation = ComponentInformation;

export const notFound = NotFound;
