import {
  DATA_VARIABLE_FETCH_ERROR,
  DATA_VARIABLE_FETCH_SUCCESS,
  DESIGN_CLEAR_STATE,
  DESIGN_FETCH_PROGRESS,
  ADD_DESIGN_DATA_PROGRESS,
  ADD_DESIGN_DATA_SUCCESS,
  ADD_DESIGN_DATA_ERROR,
  ADD_PERFORMANCE_DATA_PROGRESS,
  ADD_PERFORMANCE_DATA_SUCCESS,
  ADD_PERFORMANCE_DATA_ERROR,
  CLEAR_PERFORMANCE_DATA,
} from '../Types/design.type';

const INITIAL_API_STATE = {
  dataVariables: null,
  designData: null,
  performanceData: null,
  isDataVariableError: false,
  isDesignError: false,
  isPerformanceError: false,
  isDataVariableSuccess: false,
  isDesignAddSuccess: false,
  isPerformanceAddSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function DesignReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DESIGN_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case DESIGN_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          dataVariables: state.apiState.dataVariables,
          designData: state.apiState.designData,
          isDesignAddSuccess: false,
          isDesignError: false
        },
      };

    case DATA_VARIABLE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isDataVariableError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case DATA_VARIABLE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          dataVariables: action.payload,
          isDataVariableSuccess: true,
        },
      };

    case ADD_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          designData: action.payload,
          isDesignAddSuccess: true,
        },
      };

    case ADD_DESIGN_DATA_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isDesignError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_PERFORMANCE_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case ADD_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          performanceData: action.payload,
          isPerformanceAddSuccess: true,
        },
      };

    case ADD_PERFORMANCE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isPerformanceError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_PERFORMANCE_DATA:
      return  {
        ...state,
        apiState: {
          ...state.apiState,
          isPerformanceAddSuccess: false,
          isPerformanceError: false,
          errorCode: null,
          message: null,
        }
      }
    default:
      return state;
  }
}
