export const { PI } = Math;
export const g = 9.80665;

export const headCoefficient = (
  headDrop = 0,
  speedVariable = 0,
  runnerDiameter = []
) => {
  const numerator = headDrop * g;
  const runnerDiameterSum = runnerDiameter.reduce(
    (accumulator, currentValue) => accumulator + Math.pow(currentValue, 2),
    0
  );
  const denominator =
    Math.pow(PI, 2) * Math.pow(speedVariable, 2) * runnerDiameterSum;
  return denominator ? parseFloat(numerator / denominator) : 0;
};

export const flowCoefficient = (
  valumetricFlow = 0,
  speedVariable = 0,
  runnerDiameter = 0,
  runnerType = null
) => {
  if (runnerType === "Double Suction") {
    valumetricFlow /= 2;
  }
  const numerator = 4 * valumetricFlow;
  const denominator =
    Math.pow(PI, 2) * Math.pow(runnerDiameter, 3) * speedVariable;
  return denominator ? parseFloat(numerator / denominator) : 0;
};

export const workInputCoefficient = (efficiency = 0, headCoefficient = 0) =>
  efficiency === 0 || headCoefficient === 0
    ? 0
    : parseFloat(headCoefficient / (efficiency / 100));

export const workCoefficient = (headDrop = 0, efficiency = 0) =>
  headDrop === 0 || efficiency === 0
    ? 0
    : parseFloat(headDrop / (efficiency / 100));
