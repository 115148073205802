import React from 'react';
import { Icon, Input } from 'antd';
import { newValidateNumber } from '../../../../../../../libs/validator';

export const DataUnit = ['Suction Steam Pressure Correction Curve'];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const curveTitle = {
  reheaterPressureDropCorrCurve: 'Reheater Pressure Drop Correction Curve',
};

export const curvesColumnsTitle = {
  reheaterPressureDrop: 'Reheater Pressure Drop',
  powerCorrFactor: 'Power Correction Factor',
  heatRateCorr: 'Heat Rate Correction Factor',
};
export const curvesColumnsName = {
  reheaterPressureDrop: 'reheaterPressureDrop',
  powerCorrFactor: 'powerCorrFactor',
  heatRateCorrFactor: 'heatRateCorrFactor',
};
export const graphDetails = {
  title: 'Curve',
  xAxis: 'Reheater Pressure Drop',
  power_corr: 'Power correction factor',
  hr_corr: 'Heatrate correction factor',
  requiredMessage: 'Mininum three data is required',
  reheaterPressureDropCurve: 'Reheater Pressure Drop Curve',
};

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.reheaterPressureDrop,
    dataIndex: curvesColumnsName.reheaterPressureDrop,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.reheaterPressureDrop}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorrFactor,
    dataIndex: curvesColumnsName.powerCorrFactor,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorrFactor}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorrFactor,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorrFactor}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const reheaterPressureDropUnitFormField = (units, config) => [
  {
    label: 'Reheater Pressure Drop (Select unit)',
    key: 'reheaterPressureDrop',
    type: 'dropdown',
    required: [true, 'Unit is required'],
    value: getUnit(units, 'Reheater pressure drop')[0],
    data: getUnit(units, "Reheater pressure drop"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curveInput = (data, yAxisValue, config) => {
  return [
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};

export const curvesLabel = {
  exhaustDPCurve: 'Exhaust Differential Pressure Curve',
};
export const createCurvesPayload = (
  formfieldValue,
  reheaterPressureDropCurves = []
) => {
  const { ...dataUnit } = formfieldValue;

  const reheaterPressureDropCurve = reheaterPressureDropCurves.map((item) => {
    const { reheaterPressureDrop, powerCorrFactor, heatRateCorrFactor } = item;
    return {
      reheaterPressureDrop: parseFloat(reheaterPressureDrop),
      powerCorrFactor: parseFloat(powerCorrFactor),
      heatRateCorrFactor: parseFloat(heatRateCorrFactor),
    };
  });
  const payload = {
    correctionCurves: {
      suctionSteamTempCorrCurve: {},
      exhaustSteamPressureCorrCurve: {},
      reheatSteamTempCorrCurve: {},
      reheaterPressureDropCorrCurve: {
        dataUnit: { reheaterPressureDrop: dataUnit.reheaterPressureDrop },
        data: reheaterPressureDropCurve,},
      suctionSteamPressureCorrCurve: {
      },
    },
    designGuaranteeCurve: {}
  };
  return payload;
};

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.reheaterPressureDrop,
    dataIndex: curvesColumnsName.reheaterPressureDrop,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.reheaterPressureDrop}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.reheaterPressureDrop] ? 'error' : 'not-error'
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorrFactor,
    dataIndex: curvesColumnsName.powerCorrFactor,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorrFactor}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.powerCorrFactor] ? 'error' : 'not-error'
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorrFactor,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorrFactor}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.heatRateCorr]
            ? 'error'
            : 'not-error'
        }
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const curveVariables = {
  'Power Correction': 'powerCorrFactor',
  'Heat Rate Correction': 'heatRateCorrFactor',
};
