import {
  CLIENTS_FETCH_ERROR,
  CLIENTS_FETCH_PROGRESS,
  CLIENTS_FETCH_SUCCESS,
  CLIENTS_CLEAR_STATE,
  CLIENTS_DETAILS_FETCH_ERROR,
  CLIENTS_DETAILS_FETCH_PROGRESS,
  CLIENTS_DETAILS_FETCH_SUCCESS,
  ADD_CLIENTS_FETCH_SUCCESS,
  ADD_CLIENTS_FETCH_PROGRESS,
  ADD_CLIENTS_FETCH_ERROR,
  CLIENTS_INFO_FETCH_SUCCESS,
  CLIENTS_INFO_FETCH_PROGRESS,
  CLIENTS_INFO_FETCH_ERROR,
  EDIT_CLIENTS_FETCH_SUCCESS,
  EDIT_CLIENTS_FETCH_ERROR,
  EDIT_CLIENTS_FETCH_PROGRESS,
  RESET_CLIENT_REDUCER,
} from "../Types/Client.types";

const INITIAL_API_STATE = {
  clientList: null,
  clientInfoData: {},
  clientDetailsData: {},
  isErrorClientDetails: false,
  isErrorEditClients: false,
  isErrorFetchClients: false,
  isErrorAddClients: false,
  isErrorClientInfo: false,
  isSuccessFetchClients: false,
  isSuccessAddClients: false,
  isSuccessEditClients: false,
  isSuccessClientsInfo: false,
  isSuccessClientsDetails: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function ClientReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLIENTS_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CLIENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorFetchClients: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          clientList: action.payload,
          isSuccessFetchClients: true,
        },
      };
    case CLIENTS_CLEAR_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          clientList: state.apiState.clientList,
          clientInfoData: state.apiState.clientInfoData,
          clientDetailsData: state.apiState.clientDetailsData,
        },
      };
    case ADD_CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          clientDetailsData: action.payload,
          isSuccessAddClients: true,
        },
      };
    case ADD_CLIENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorAddClients: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case ADD_CLIENTS_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CLIENTS_INFO_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          clientInfoData: action.payload,
          isSuccessClientsInfo: true,
        },
      };
    case CLIENTS_INFO_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorClientInfo: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };
    case CLIENTS_INFO_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CLIENTS_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          clientDetailsData: action.payload,
          isSuccessClientsDetails: true,
        },
      };
    case CLIENTS_DETAILS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorClientDetails: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };
    case CLIENTS_DETAILS_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_CLIENTS_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isSuccessEditClients: true,
          clientDetailsData: action.payload,
        },
      };
    case EDIT_CLIENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isErrorEditClients: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case RESET_CLIENT_REDUCER:
      return {
        ...INITIAL_STATE,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };
    default:
      return state;
  }
}
