import { Select, Tabs } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Loader from "../../../../../common/Loader/Loader";
import ConfirmModal from "../../../../../common/Modal/Modal";
import Notification, { Types } from "../../../../../common/Notification/Notification";
import {
  CurvesMessages, DISCARD_CHANGES,
  FORM_DISCARD_MSG, NO_INTERNET
} from "../../../../../constants/messages";
import {
  addEditCurve,
  deleteCurve,
  toggleDetectState,
  clearCurvesState
} from "../../../../../redux/actions/curve.action";
import { updateFormEdit } from "../../../../../redux/actions/formEdit.action";
import "../CompressorCurves/CurvesBoard.scss";
import Curve from "./Curve";
import {
  APPLICATIONS,
  COMPONENT,
  createCurvePayload, curvesMapper,
  getCurveData,
  getImpelerData, getMaxCurves, initialValue, PROCESSES,
  identifierDataOption, fillFixedSpeedCurve
} from "./Curves.constants";

const { TabPane } = Tabs;
const { Option } = Select;

/**
 * @class CurvesData
 * @classdesc Component for Curves
 */
class CurvesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      process: "Adiabatic",
      application: "Fixed Speed",
      curves: { curve1: "" },
      impeller: [],
      visible: false,
      appVisible: false,
      maxCurves: null,
      activeKey: "1",
      tabChange: null,
      appChange: null,
      identifierKey: 'Speed',
      fixedSpeedCurve: false,
      interimIdentifier: ''
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { name } = state.componentData.assetInfo;
    const { configData, dataUnitState } = this.props;
    const { unitData } = dataUnitState && dataUnitState.apiState && dataUnitState.apiState;

    const process =
      configData && configData.curvesData && configData.curvesData.process
        ? configData.curvesData.process
        : this.state.process;

    const application =
      configData && configData.curvesData && configData.curvesData.application
        ? configData.curvesData.application
        : configData?.curvesData?.designGuaranteeCurve?.application ? configData.curvesData.designGuaranteeCurve.application : this.state.application;
    
    const identifierKey =
    configData && configData.curvesData && configData.curvesData.identifierKey
      ? configData.curvesData.identifierKey
      : this.state.identifierKey;

    const impeller = getImpelerData(name, configData);

    const curvesData = getCurveData(name, configData);

    let curves = curvesData ? curvesData : { curve1: initialValue(unitData) };

    curves = this.embedInitialIdentifier(curves);

    const length = _.keys(curves).length;
    const curveId = _.keys(curvesMapper)[length];
    let fixedSpeedCurve = false;
    if(curveId !== 'curve1' && application === 'Fixed Speed' && identifierKey !== 'Speed')  {
      fixedSpeedCurve = true;
    }

    const maxCurves = getMaxCurves(name, application, identifierKey);
    this.setState({
      maxCurves,
      process,
      application,
      curves: curves,
      impeller: impeller,
      identifierKey: identifierKey,
      fixedSpeedCurve: fixedSpeedCurve
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeKey !== this.props.activeKey) {
      const { curves } = this.state;
      this.setState({
        //Added Later
        activeKey: "1",
        curves: { ...curves },
      });
    }

    if (this.state.application !== prevState.application) {
      this.props.updateFormEdit(true);
    }

    if (prevProps.curveState !== this.props.curveState) {
      const { activeKey } = this.state;
      const { apiState } = this.props.curveState;
      const { isSuccess, isError, isAdded, isDeleted, errorCode } = apiState;

      if (isSuccess && isAdded) {
        this.setState(
          {
            activeKey: parseInt(activeKey) + "",
          },
          () => {
            this.props.updateFormEdit(false);
            this.props.clearCurvesState();
            Notification.show(Types.Success, CurvesMessages.ADDED);
          }
        );
      } else if (isSuccess && isDeleted) {
        this.setState(
          {
            activeKey: parseInt(activeKey) - 1 ? parseInt(activeKey) - 1 + "" : parseInt(activeKey) + ""
          },
          () => {
            this.props.updateFormEdit(false);
            Notification.show(Types.Success, CurvesMessages.DELETED);
          }
        );
      } else if (isError) {
        Notification.show(Types.Error, CurvesMessages[errorCode]);
      }
    }

    //Added Later
    if (prevProps.configData.curvesData !== this.props.configData.curvesData
      ||
      prevProps.configData.impellerData !== this.props.configData.impellerData
      ||
      prevProps.configData.runnerData !== this.props.configData.runnerData
    ) {
      const { configData } = this.props;
      const { state } = this.props.location;
      const { name } = state.componentData.assetInfo;
      this.setState(
        {
          curves: getCurveData(name, configData)
            ? getCurveData(name, configData)
            : this.state.curves,
          impeller: getImpelerData(name, configData),
        },
        () => {
          this.props.updateFormEdit(false);
        }
      );
    }
  }

  /**
   * Embed Initial identifier value if not available
   */
   embedInitialIdentifier(curves)  {
    if(!_.has(curves["curve1"]["asTestedCurves"], "identifier"))  {
      curves["curve1"]["asTestedCurves"]["identifier"] = { value: '0', unit: '%/100' };
    }
    return curves;
  }

  /**
   *
   */
  handleNetwork() {
    const { isConnect } = this.props;
    if (isConnect) {
      return true;
    } 
    Notification.show(Types.Error, NO_INTERNET);
    return false;
    
  }

  /**
   *
   * @param {*} pane
   */
  onTabChange(pane) {
    const { isEdit } = this.props;
    if (isEdit) {
      this.setState({
        tabChange: pane,
        visible: true,
      });
    } else {
      this.setState({
        activeKey: pane,
      });
    }
  }

  /**
   *
   */
  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  /**
   *
   */
  handleOk() {
    const { tabChange } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.setState({
          activeKey: tabChange,
          tabChange: null,
        });
        this.props.updateFormEdit(false);
      }
    );
  }

  /**
   *
   */
  renderModal() {
    const { visible } = this.state;
    return (
      <ConfirmModal
        visible={visible}
        handleOk={() => this.handleOk()}
        handleCancel={() => this.handleCancel()}
        message={FORM_DISCARD_MSG}
        title={DISCARD_CHANGES}
      />
    );
  }

  /**
   *
   * @param {*} application
   */
  onAppChange(application) {
    const { location } = this.props;
    const { curves, identifierKey } = this.state;
    const { name } = location.state.componentData.assetInfo;
    const { curvesData } = this.props.configData;
    //TODO-isEdit
    if (name === COMPONENT.EXPANDER) {
      this.setState({
        appChange: application,
        application: application
      });
    }
    else if (application === "Fixed Speed" && Object.keys(curves).length > 1 && identifierKey === 'Speed') {
      this.setState({
        appVisible: true,
        appChange: application,
      });
    } else {
      this.setState({
        //Added Later
        activeKey: "1",
        curves: (application === "Fixed Speed" && identifierKey === 'Speed') ? 
                { curve1: curves["curve1"] } : (curvesData?.curvesData === undefined ? 
                  curves : curvesData?.curvesData),
        application: application,
        maxCurves: getMaxCurves(name, application),
      });
      if(curvesData?.application !== undefined && (application !== curvesData?.application))  {
        this.props.toggleDetectState(true);
      }
    }
  }

  /**
   *
   */
  handleAppCancel() {
    this.setState({
      appVisible: false,
    });
  }

  /**
   *
   */
  handleAppOk() {
    const { location } = this.props;
    const { curves, appChange, interimIdentifier, identifierKey } = this.state;
    const { name } = location.state.componentData.assetInfo;
    this.setState(
      {
        appVisible: false,
      },
      () => {
        this.setState({
          //Added Later
          activeKey: "1",
          application: appChange,
          curves:
            appChange === "Fixed Speed" ? { curve1: curves["curve1"] } : curves,
          maxCurves: getMaxCurves(name, appChange),
          appChange: null,
          identifierKey: interimIdentifier !== '' ? interimIdentifier : identifierKey,
          interimIdentifier: ''
        });
      }
    );
    this.props.toggleDetectState(true);
  }

  renderAppModal() {
    const { appVisible } = this.state;
    return (
      <ConfirmModal
        visible={appVisible}
        handleOk={() => this.handleAppOk()}
        handleCancel={() => this.handleAppCancel()}
        message={CurvesMessages.SELECT_MSG}
        title={CurvesMessages.SELECT_TITLE}
      />
    );
  }

  /**
   *
   * @param {*} process
   */
  onProcessChange(process) {
    this.setState({ process: process });
  }

  onIdentifierChange(i) {
    const { state } = this.props?.location;
    const { curvesData } = this.props.configData;
    const { name } = state.componentData.assetInfo;
    const { application } = this.state;
    const { curves } = this.state;
    const maxCurves = getMaxCurves(name, application, i);
        if (Object.keys(curves).length > 1 && i === 'Speed' && application === 'Fixed Speed') {
          this.setState({ appVisible: true, appChange: application, interimIdentifier: i });
        } else {
          this.setState({ maxCurves: maxCurves, identifierKey: i });
          if(curvesData?.curvesData !== undefined)  {
            this.setState({ curves: curvesData?.curvesData  });
          }
          if ((curvesData?.identifierKey !== undefined) && i !== curvesData?.identifierKey) {
            this.props.toggleDetectState(true);
          }
        }
  }

  /**
   *
   * @param {*} curveId
   * @param {*} formFieldVal
   * @param {*} asTestedCurves
   * @param {*} operationalBaseLineCurves
   */
  onSave(curveId, formFieldVal, asTestedCurves, operationalBaseLineCurves,percentageDeviation) {
    const { state } = this.props.location;
    const { _id } = state.componentData.info;
    const { name } = state.componentData.assetInfo;
    const { application, process, identifierKey } = this.state;

    const payload = createCurvePayload(
      name,
      formFieldVal,
      curveId,
      application,
      process,
      asTestedCurves,
      operationalBaseLineCurves,
      percentageDeviation,
      identifierKey,
    );
    if (this.handleNetwork() && state.componentData) {
      this.props.addEditCurve(_id, payload, name);
      this.props.updateFormEdit(false);
      this.props.toggleDetectState(false);
    }
  }

  /**
   *
   */
  onAdd() {
    const { curves, application, identifierKey } = this.state;
    const { configData, dataUnitState } = this.props;
    const { unitData } = dataUnitState && dataUnitState.apiState && dataUnitState.apiState;
    const length = _.keys(curves).length;
    const curveId = _.keys(curvesMapper)[length];
    const fixedSpeedCurve = (application === 'Fixed Speed' && identifierKey !== 'Speed') ? true : false;
    this.setState(
      {
        curves: { ...curves, [curveId]: !fixedSpeedCurve ? initialValue : fillFixedSpeedCurve(configData, unitData) },
        activeKey: length + 1 + "",
      },
      () => { }
    );
  }

  /**
   *
   * @param {*} curveId
   */
  onDelete(curveId) {
    const { state } = this.props.location;
    const { _id } = state.componentData.info;
    const { name } = state.componentData.assetInfo;
    if (this.handleNetwork() && state.componentData) {
      this.props.deleteCurve(_id, name, curveId);
      this.props.updateFormEdit(false);
    }
  }


  /**
   *
   * @param {*} curveId
   */
  renderCurve(curveId) {
    const { configData, isSchematicEditable } = this.props;
    const {
      curves,
      impeller,
      activeKey,
      process,
      application,
      maxCurves,
      identifierKey,
      fixedSpeedCurve
    } = this.state;
    const { asTestedCurves, operationalBaseLineCurves } = curves[curveId];
    let iValue = { value: '0', unit: '%/100' };
    if(asTestedCurves?.identifier)  {
      iValue['value'] = asTestedCurves.identifier.value;
      iValue['unit'] = asTestedCurves.identifier.unit;
    }
    return (
      <Curve
        activeKey={activeKey}
        process={process}
        application={application}
        configData={configData}
        curveId={curveId}
        curvesData={curves}
        impellerData={impeller}
        onAppChange={() => this.onAppChange(configData && configData.curvesData && configData.curvesData.application
          ? configData.curvesData.application
          : "Fixed Speed")}
        onProcessChange={() => this.onProcessChange(configData && configData.curvesData && configData.curvesData.process
          ? configData.curvesData.process : "Adiabatic")}
        onIdentifierChange={() => this.onIdentifierChange(configData && configData.curvesData && configData.curvesData
          .identifierKey ? configData.curvesData.identifierKey : "Speed")}
        isSchematicEditable={isSchematicEditable}
        onSave={(
          curveId,
          formFieldVal,
          asTestedCurves,
          operationalBaseLineCurves,
          percentageDeviation
        ) =>
          this.onSave(
            curveId,
            formFieldVal,
            asTestedCurves,
            operationalBaseLineCurves,
            percentageDeviation
          )
        }
        onDelete={curveId => this.onDelete(curveId)}
        onAdd={() => this.onAdd()}
        isSave={
          asTestedCurves &&
            asTestedCurves.data &&
            asTestedCurves.data.length > 2 &&
            operationalBaseLineCurves &&
            operationalBaseLineCurves.data &&
            operationalBaseLineCurves.data.length > 2
            ? true
            : false
        }
        canAdd={
          (application === "Variable Speed" || identifierKey !== "Speed") &&
            Object.keys(curves).length === parseInt(activeKey) &&
            Object.keys(curves).length < maxCurves
            ? true
            : false
        }
        canDelete={
          (application === "Variable Speed" || identifierKey !== "Speed")  &&
            Object.keys(curves).length === parseInt(activeKey) &&
            Object.keys(curves).length > 1
            ? true
            : false
        }
        identifierKey={identifierKey}
        fixedSpeedCurve={fixedSpeedCurve}
        identifierValue={iValue}
      />
    );
  }

  render() {
    const { state } = this.props.location;
    const { name } = state.componentData.assetInfo;
    const { isEdit } = this.props;
    const { curves, activeKey, process, application, identifierKey } = this.state;
    const { loading } = this.props.curveState;
    const { isSchematicEditable } = this.props;
    return (
      <div>
        {loading ? <Loader /> : null}
        <div className="CurvesHeader">
          <div className="application-process">
            <div className="app">
              <label>Application</label>
              <Select
                value={application}
                onChange={event => this.onAppChange(event)}
                disabled={!isSchematicEditable}
              >
                {APPLICATIONS.map(application => (
                  <Option value={application}>{application}</Option>
                ))}
              </Select>
            </div>
            {// Only for Compressor
              name === COMPONENT.COMPRESSOR && (
                <div className="pro">
                  <label>Process</label>
                  <Select
                    value={process}
                    onChange={event => this.onProcessChange(event)}
                    disabled={!isSchematicEditable}
                  >
                    {PROCESSES.map(process => (
                      <Option value={process}>{process}</Option>
                    ))}
                  </Select>
                </div>
              )}
              {// Only for Compressor
                name === COMPONENT.COMPRESSOR && (
                  <div style={{"padding-left": "5%"}}>
                    <label>Identifier Key</label>
                    <Select
                      value={identifierKey}
                      onChange={event => this.onIdentifierChange(event)}
                      disabled={!isSchematicEditable}
                      style={{"padding-left": "2%"}}
                    >
                      {identifierDataOption.map(i => (
                        <Option value={i}>{i}</Option>
                      ))}
                    </Select>
                  </div>
                )
              }
          </div>
        </div>
        {(application === "Fixed Speed" && identifierKey === 'Speed')  || name === COMPONENT.EXPANDER ? (
          this.renderCurve(_.keys(curvesMapper)[0])
        ) : (
          <Tabs
            defaultActiveKey="1"
            onChange={event => this.onTabChange(event)}
            activeKey={activeKey}
          >
            {Object.keys(curves).map((item, index) => {
              return (
                <TabPane tab={curvesMapper[item]} key={index + 1 + ""}>
                  {this.renderCurve(item)}
                </TabPane>
              );
            })}
          </Tabs>
        )}
        {isEdit && this.renderModal()}
        {this.renderAppModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    curveState: state.curveReducer,
    configState: state.ConfigReducer,
    isEdit: state.formEditReducer.isEdit,
    dataUnitState: state.DataUnitReducer,
    isConnect: state.NetworkReducer.networkState,
    changeDetected: state.curveReducer.apiState.changeDetected,
  };
};

const mapDispatchToProps = dispatch => ({
  addEditCurve: (id, payload, name) =>
    dispatch(addEditCurve(id, payload, name)),
  deleteCurve: (id, name, curveId) => dispatch(deleteCurve(id, name, curveId)),
  updateFormEdit: status => {
    status ? window.dispatchEvent(new Event("form-edit")) : window.dispatchEvent(new Event("form-edit-false"));
    return dispatch(updateFormEdit(status));
  },
  toggleDetectState: status =>  {
    dispatch(toggleDetectState(status));
  },
  clearCurvesState: () => {
    dispatch(clearCurvesState());
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CurvesData);
