import React, { useEffect, useState } from "react";
import { Form, Modal, Input } from "antd";
import FormFields from "../../../common/FormFields/FormFields";
import { tagFields } from "./tagFields";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { tagUpdate } from "../../../redux/actions/piTagUpload.action";
import Notification, { Types } from "../../../common/Notification/Notification";
const { TextArea } = Input;
const EditTags = (props) => {
    const dispatch = useDispatch()
    const [frozenEnabled, setFrozenEnabled] = useState(false)
    const [UADEnabled, setUADEnabled] = useState(false)
    const [tagData, setTagData] = useState("")
    const [copytagData, setCopyTagData] = useState("")
    const [systemIDS, setSystemIDS] = useState([])
    const [equipments, setEquipments] = useState([])
    const [selectedEquipmentList, setselectedEquipmentList] = useState([])
    const [reason, setReason] = useState("")
    useEffect(() => {
        let system =
            props.systems &&
            props.systems.filter(
                (system) => system.facilityId === props.facilityId
            );
        let systemIds = _.map(system, "_id");
        let equip = []
        let selectedEquipment = []
        let data = props.equipmentsList.filter(
            (equipment) => _.indexOf(systemIds, equipment.systemId) !== -1
        )
        if (systemIds && systemIds.length) {
            setSystemIDS(systemIds)
        }
        if (data.length) {
            data.forEach((data) => {
                equip.push(data.name)
            })
        }
        setEquipments(equip)
        if (props.tagData && props.tagData.equipmentId && props.equipmentsList.length) {
            props.tagData.equipmentId.forEach((data) => {
                props.equipmentsList.forEach((value) => {
                    if (data === value._id) {
                        selectedEquipment.push(value.name)
                    }
                })
            })
        }
        setselectedEquipmentList(selectedEquipment)
    }, [// eslint-disable-line
        props.systems, props.equipmentsList, props.tagData
    ])

    useEffect(() => {
        if (props.tagData && typeof props.tagData.isFrozen === "boolean") {
            setFrozenEnabled(props.tagData.isFrozen)
        }
        else {
            setFrozenEnabled(false)
        }
        if (props.tagData && typeof props.tagData.isIQR === "boolean") {
            setUADEnabled(props.tagData.isIQR)
        }
        else {
            setUADEnabled(false)
        }
        if (props.tagData) {
            setTagData(props.tagData)
            setCopyTagData(props.tagData)
        }
    }, [props.tagData])

    useEffect(() => {
        if (tagData && tagData.variableProperty) {
            props.handleChange(tagData.variableProperty, undefined, 'variableProperty')
        }
    }, [// eslint-disable-line
        tagData
    ])
    
    const handleFormSubmit = () => {
        if(/^\s*$/.test(reason)){
            return Notification.show(Types.Error, "Please fill reason/comment.")
        }
        const {tagData} = props
        props.form.validateFields((err, values) => {
            let equipmentIds = []
            if (values) {
                if (values.Equipemnt && props.equipmentsList) {
                    values.Equipemnt.forEach((data) => {
                        props.equipmentsList.forEach((value) => {
                            if (data === value.name) {
                                equipmentIds.push(value._id)
                            }
                        })
                    })
                }
                let payload = {
                    piTag: tagData && tagData.piTag ? tagData.piTag : null,
                    criticalLimitLow: tagData && tagData.criticalLimitLow ? tagData.criticalLimitLow : null,
                    criticalLimitHigh: tagData && tagData.criticalLimitHigh ? tagData.criticalLimitHigh : null,
                    optimalTarget: tagData && tagData.optimalTarget ? tagData.optimalTarget : null,
                    standardLimitHigh: tagData && tagData.standardLimitHigh ? tagData.standardLimitHigh : null,
                    standardLimitLow: tagData && tagData.standardLimitLow ? tagData.standardLimitLow : null,
                    dataType: values && values.dataType ? values.dataType : null,
                    facilityId: tagData && tagData.facilityId && tagData.facilityId._id ?
                        tagData.facilityId._id : null,
                    isIQR: UADEnabled,
                    isFrozen: tagData && (tagData.isFrozen || frozenEnabled) ? frozenEnabled : null,
                    maxThreshold: values && values.maxThreshold ? values.maxThreshold : null,
                    mechademyUnits: values && values.mechademyUnits ? values.mechademyUnits : null,
                    minThreshold: values && values.minThreshold ? values.minThreshold : null,
                    multiplier: values && values.multiplier ? Number(values.multiplier) : null,
                    adder: values && (values.adder || Number(values.adder) === 0) ? Number(values.adder) : null,
                    piVariableName: tagData && tagData.piVariableName ? tagData.piVariableName : null,
                    type: tagData && tagData.type ? tagData.type : null,
                    units: values && (values.clientUnits || values.clientUnits === "") ? values.clientUnits : null,
                    variableName: tagData && tagData.variableName ? tagData.variableName : null,
                    variableProperty: values && values.variableProperty ? values.variableProperty : null,
                    equipmentId: equipmentIds,
                    lastUpdateDate: (new Date()).toISOString(),
                    lastUpdatedBy : props.userData,
                    reason: reason                  
                }
                if (payload.dataType !== "Boolean") {
                    if (_.isNil(payload.dataType) || _.isNil(payload.variableProperty) || _.isNil(payload.units) ||
                        _.isNil(payload.mechademyUnits) || _.isNil(payload.maxThreshold) || _.isNil(payload.minThreshold)
                    ) {
                        return Notification.show(Types.Error, "Please fill all the required fields")
                    }
                    if (_.isNil(payload.multiplier) || _.isNil(payload.adder)) {
                        return Notification.show(Types.Error, "Please fill adder and multiplier details")
                    }
                    if (Number(payload.multiplier) === 0) {
                        return Notification.show(Types.Error, "Multiplier can not be equal to zero")
                    }
                    if (Number(payload.minThreshold) >= Number(payload.maxThreshold)) {
                        return Notification.show(Types.Error, "Varaibale min value greater than variable max value")
                    }
                    if (props.variablePropertyUnits.indexOf(payload.mechademyUnits) < 0) {
                        return Notification.show(Types.Error, "Please select proper units for Mechademy Unit")
                    }
                }
                else {
                    if(!isNaN(payload.multiplier) && payload.multiplier === 0){
                        return Notification.show(Types.Error, "Multiplier can not be equal to zero")
                    } 
                    if (payload.minThreshold && payload.maxThreshold && (Number(payload.minThreshold) >= Number(payload.maxThreshold))) {
                        return Notification.show(Types.Error, "Varaibale min value greater than variable max value")
                    }
                }
                dispatch(tagUpdate(payload, props.facilityId))
                setReason("")
            }
        })
    }
    const handleFormChange = (value, key) => {
        let tempData = _.cloneDeep(copytagData)
        tempData = { ...tempData, [key]: value }
        setCopyTagData(tempData)
    }

    const handleCancel = () => {
        props.setModalState(false)
        setCopyTagData(tagData)
        props.handleChange(tagData.variableProperty, undefined, 'variableProperty')
        if (tagData.isFrozen && typeof tagData.isFrozen == "boolean") {
            setFrozenEnabled(tagData.isFrozen)
        }
        else {
            setFrozenEnabled(false)
        }
        if (tagData.isIQR && typeof tagData.isIQR == "boolean") {
            setUADEnabled(tagData.isIQR)
        }
        else {
            setUADEnabled(false)
        }
    }
    const renderModal = () => {
        if (props.state) {
            return (
                <Modal visible={true}
                    closable={false}
                    onCancel={() => handleCancel()}
                    onOk={() => handleFormSubmit()}
                    title="Edit"
                >
                    <div className="question edit_tag">
                        <FormFields
                            formFields={tagFields({
                                setFrozenEnabled: setFrozenEnabled,
                                frozenEnabled: frozenEnabled,
                                UADEnabled: UADEnabled,
                                setUADEnabled: setUADEnabled,
                                adder: copytagData.adder,
                                multiplier: copytagData.multiplier,
                                dataType: copytagData.dataType,
                                mechademyUnits: copytagData.mechademyUnits,
                                clientUnits: copytagData.units,
                                minThreshold: copytagData.minThreshold,
                                maxThreshold: copytagData.maxThreshold,
                                variableProperty: copytagData.variableProperty,
                                equipmentsList: props.equipmentsList,
                                systemIds: systemIDS,
                                variablePropertyList: props.variablePropertyList,
                                variablePropertyUnits: props.variablePropertyUnits,
                                handleChange: props.handleChange,
                                handleFormChange: handleFormChange,
                                equipments: equipments,
                                selectedEquipmentList: selectedEquipmentList
                            })}
                            form={props.form}
                        />
                        <span className="ant-form-item-required" style={{fontSize: "12px",color: "#9a9a9a"}}>Change Reason or Comment :</span>
                        <TextArea rows={3} maxLength={500} onChange={(e)=>{
                            setReason(e.target.value)
                        }}/>
                        
                    </div>
                    {/* <button onClick={() => handleReset() }>RESET</button> */}
                </Modal>
            )
        }
        else {
            return (
                <>  </>
            )
        }
    }
    return (renderModal())
}

export default Form.create({ name: "PiTags" })(EditTags);

