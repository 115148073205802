import React, { Component } from 'react';
import './Header.scss';
import { Menu, Dropdown, Icon, Avatar, Modal } from 'antd';
import 'antd/dist/antd.css';
import { HeaderVariables } from '../../constants/variables';
import { USER_EMAIL_ADDRESS, USER_NICK_NAME, clearToken } from '../../libs/token';
import { logo } from '../../assets/logo/header';
// import * as Variables from '../../constants/variables';
// import * as Storage from '../../libs/storage';
import * as VersionJson from '../../version.json';
import { roles } from '../../permissions';
import { UserRole } from '../AppScreen/AppScreen';
import { useAuth0 } from '@auth0/auth0-react';



class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formEdit: false,
      visible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('form-edit', () => {
      if (!this.state.formDisable) {
        this.setState({
          formEdit: true,
        });
      }
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  logOut() {
    this.setState({
      visible: true,
    });
  }

  menu = (email) => (
    <Menu className="menu">
      <div className="email-role">{email}</div>
      <Menu.Item onClick={() => this.logOut()}>
        <Icon type="logout" />
        <span>{HeaderVariables.LOGOUT}</span>
      </Menu.Item>
    </Menu>
  );

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { logout } = this.props
    clearToken();
    logout({ logoutParams: { returnTo: process.env.REACT_APP_DOMAIN } })
  }

  render() {
    // const { email, nickname } = this.props.user;
    const email = localStorage.getItem(USER_EMAIL_ADDRESS)
    const nickname = localStorage.getItem(USER_NICK_NAME)

    return (
      <div className="Header">
        <div className="logo">
          <img src={logo} alt="logo" />
          <span>
            <small className='version_info'>{VersionJson?.version}</small>
          </span>
        </div>

        <Dropdown
          overlay={this.menu(email)}
          trigger={['click']}
          placement="bottomRight"
        >
          <div>
            <span className="name">
              {roles.MECHADEMY_ADMIN === UserRole
                ? 'Mechademy Admin'
                : nickname}
            </span>
            <div className="dp-back">
              <Avatar>
                {roles.MECHADEMY_ADMIN === UserRole
                  ? 'MA'
                  : nickname}
              </Avatar>
            </div>
          </div>
        </Dropdown>
        {this.state.visible && (
          <Modal
            visible={this.state.visible}
            onOk={() => this.handleOk()}
            onCancel={() => this.handleCancel()}
            title={HeaderVariables.LOGOUT_HEADER}
          >
            {HeaderVariables.LOGOUT_MESSAGE}
          </Modal>
        )}
      </div>
    );
  }
}

// export default Header;
const HeaderWrapper = () => {
  
  const { logout } = useAuth0()

  return <Header logout={logout} />
}

export default HeaderWrapper
