export default {
  CREATE_ML_MODEL_PROGRESS: "CREATE_ML_MODEL_PROGRESS",
  CREATE_ML_MODEL_SUCCESS: "CREATE_ML_MODEL_SUCCESS",
  CREATE_ML_MODEL_FAILURE: "CREATE_ML_MODEL_FAILURE",
  CREATE_ML_MODEL_RESET: "CREATE_ML_MODEL_RESET",

  GET_ML_MODEL_PROGRESS: "GET_ML_MODEL_PROGRESS",
  GET_ML_MODEL_SUCCESS: "GET_ML_MODEL_SUCCESS",
  GET_ML_MODEL_FAILURE: "GET_ML_MODEL_FAILURE",
  GET_ML_MODEL_RESET: "GET_ML_MODEL_RESET",

  DEPLOY_ML_MODEL_PROGRESS: "DEPLOY_ML_MODEL_PROGRESS",
  DEPLOY_ML_MODEL_SUCCESS: "DEPLOY_ML_MODEL_SUCCESS",
  DEPLOY_ML_MODEL_FAILURE: "DEPLOY_ML_MODEL_FAILURE",
  DEPLOY_ML_MODEL_RESET: "DEPLOY_ML_MODEL_RESET",

  UPDATE_ML_MODEL_PROGRESS: "UPDATE_ML_MODEL_PROGRESS",
  UPDATE_ML_MODEL_SUCCESS: "UPDATE_ML_MODEL_SUCCESS",
  UPDATE_ML_MODEL_FAILURE: "UPDATE_ML_MODEL_FAILURE",
  UPDATE_ML_MODEL_RESET: "UPDATE_ML_MODEL_RESET",

  GET_ALL_PI_TAG_PROGRESS: "GET_ALL_PI_TAG_PROGRESS",
  GET_ALL_PI_TAG_SUCCESS: "GET_ALL_PI_TAG_SUCCESS",
  GET_ALL_PI_TAG_FAILURE: "GET_ALL_PI_TAG_FAILURE",
  GET_ALL_PI_TAG_RESET: "GET_ALL_PI_TAG_RESET",

  GET_ML_VERSION_PROGRESS: "GET_ML_VERSION_PROGRESS",
  GET_ML_VERSION_SUCCESS: "GET_ML_VERSION_SUCCESS",
  GET_ML_VERSION_ERROR: "GET_ML_VERSION_ERROR",

  RESET_ML_ALGORITHM_REDUCER: "RESET_ML_ALGORITHM_REDUCER",

  DEPLOY_ML_MODEL_BULK_PROGRESS: 'DEPLOY_ML_MODEL_BULK_PROGRESS',
  DEPLOY_ML_MODEL_BULK_SUCCESS: 'DEPLOY_ML_MODEL_BULK_SUCCESS',
  DEPLOY_ML_MODEL_BULK_FAILURE: 'DEPLOY_ML_MODEL_BULK_FAILURE',
  DEPLOY_ML_MODEL_BULK_RESET: 'DEPLOY_ML_MODEL_BULK_RESET',
};
