import apiCall from "./apiRequest";
import { clearToken } from "../libs/token";
import { socket } from "../socket/connection";

export const GETRequest = (endpoint, query) =>
  apiCall({ method: "GET", endpoint, query }).catch((e) => {
    handleError(e);
  });

export const POSTRequest = (endpoint, payload) =>
  apiCall({ method: "POST", payload, endpoint }).catch((e) => {
    handleError(e);
  });

export const POSTRequestFile = (endpoint, payload) =>
  apiCall({
    method: "POST",
    payload,
    endpoint,
    type: "multipart/form-data",
  }).catch((e) => {
    handleError(e);
  });

export const POSTRequestFileCsv = (endpoint, payload) =>
  apiCall({
    method: "POST",
    payload,
    endpoint,
    type: "fileCsv",
  }).catch((e) => {
    handleError(e);
  });

export const PUTRequest = (endpoint, payload) =>
  apiCall({ method: "PUT", payload, endpoint }).catch((e) => {
    handleError(e);
  });

export const DeleteRequest = (endpoint, payload) =>
  apiCall({ method: "DELETE", payload, endpoint }).catch((e) => {
    handleError(e);
  });

export default {
  GETRequest,
  POSTRequest,
  PUTRequest,
};

function handleRedirect(redirectTime = 1500) {
  clearToken();
  setTimeout(() => {
    window.location.reload();
  }, redirectTime);
}

function handleError(e) {
  if (
    (e.response && e.response.body.errorCode === "UNAUTHORIZED") ||
    e.response.body.code === "UNAUTHORIZED"
  ) {
    socket.disconnect();
    socket.destroy();
    handleRedirect();
    if (e.response.body.message === 'User is no longer active') throw e;
  }
  if (
    e.response.body.code === 'INTERNAL' &&
    e.response.body.message ===
      'InvalidTokenError: "exp" claim timestamp check failed'
  ) {
    handleRedirect();
  }
  throw e;
}
