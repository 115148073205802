import { Icon, Input, Select, Switch } from "antd";
import React from "react";
import { InputDataVariable } from "../../../../constants/variables";

const { Option } = Select;
export const inputRenderDataUser = (config) => [
  {
    dataIndex: "piTag",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config._idTag || !config._idTag.length)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          type="text"
          name="piTag"
          value={config.piTag === "" ? "Search here" : config.piTag}
          AutoComplete="off"
          showSearch
          showArrow={false}
          onSelect={(tag) => config.setDataTag(tag)}
        >
          {config.displayPiTag &&
            config.displayPiTag.map((item) => (
              <Option value={item.piTag} title={item.piTag}>
                {item.piTag}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "variableName",
    render: () => (
      <div
        className={`custom-table-Select ${
          config.addValueListener && (!config._idName || !config._idName.length)
            ? "error"
            : "not-error"
        }`}
      >
        <Select
          type="text"
          placeholder="Search here"
          name="variableName"
          value={
            config.variableName === "" ? "Search here" : config.variableName
          }
          AutoComplete="off"
          showSearch
          className={
            config.addValueListener &&
            (!config._idName || !config._idName.length)
              ? "error"
              : "not-error"
          }
          onSelect={(name) => config.handleChangeName(name)}
          showArrow={false}
        >
          {config.displayVariableName &&
            config.displayVariableName.map((item) => (
              <Option value={item.variableName} title={item.variableName}>
                {item.variableName}
              </Option>
            ))}
        </Select>
      </div>
    ),
  },
  {
    dataIndex: "piVariableName",
    render: () => (
      <Input
        type="text"
        name="piVariableName"
        value={config.piVariableName}
        disabled
      />
    ),
  },
  {
    dataIndex: "variableProperty",
    render: () => (
      <Input
        type="text"
        name="variableProperty"
        disabled
        value={config.variableProperty}
      />
    ),
  },
  {
    dataIndex: "mechademyUnits",
    render: () => (
      <Input
        type="text"
        name="mechademyUnits"
        disabled
        value={config.mechademyUnits}
      />
    ),
  },
  {
    dataIndex: "multiplier",
    render: () => (
      <Input
        type="text"
        placeholder="Multiplier"
        name="minMultiplier"
        disabled
        value={config.minMultiplier}
      />
    ),
  },
  {
    dataIndex: "adder",
    render: () => (
      <Input
        type="text"
        placeholder="Adder"
        name="maxMultiplier"
        disabled
        value={config.maxMultiplier}
      />
    ),
  },
  {
    dataIndex: "isIQR",
    render: () => (
      <Switch
        disabled
        checkedChildren="ON"
        unCheckedChildren="OFF"
        checked={config.isIQR}
      />
    ),
  },
  {
    render: () => (
      <div>
        <div>
          <button
            className="btn-default btn-small"
            onClick={() => config.addValue()}
          >
            Add
          </button>
        </div>
      </div>
    ),
  },
];
export const inputRenderData = (config) => [
  {
    title: InputDataVariable.variableTag,
    dataIndex: "piTag",
  },
  {
    title: InputDataVariable.variableName,
    dataIndex: "variableName",
  },
  {
    title: InputDataVariable.piVariableName,
    dataIndex: "piVariableName",
  },
  {
    title: InputDataVariable.variableProperty,
    dataIndex: "variableProperty",
  },
  {
    title: InputDataVariable.unit,
    dataIndex: "mechademyUnits",
  },
  {
    title: InputDataVariable.minMultiplier,
    dataIndex: "multiplier",
  },
  {
    title: InputDataVariable.maxMultiplier,
    dataIndex: "adder",
  },
  {
    title: InputDataVariable.isIQR,
    dataIndex: "isIQR",
    render: (value, row, col, disabled) => (
      <div>
        {disabled ? (
          <Switch
            disabled
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={value}
          />
        ) : (
          <Switch
            disabled
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={value}
            onChange={() => {}}
          />
        )}
      </div>
    ),
  },
  config.isInputEditable && {
    title: InputDataVariable.actions,
    dataIndex: "piTag",
    render: (value, row, col, disabled, item) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.EditData(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.UndoData(row)}>
            <Icon type="undo" />
          </button>
        )}
        <button onClick={() => config.DeleteData(row, item)}>
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const none = "None";
export const noUnits = "No Units";
