import * as addNewCardTypes from "./addNewCard.types";
import cardTypeMapper from "../../constants/cardTypes";
import {
  POSTRequest,
  GETRequest,
  DeleteRequest,
  PUTRequest,
} from "../../api/request";
import { addDataIDB } from "../../services/indexedDB";

export const addNewCard = (levelName, levelId, payload) => (dispatch) => {
  dispatch({ type: addNewCardTypes.ADD_ADDNEWCARD_PROGRESS });
  POSTRequest(`kpis/${levelName}/${levelId}`, payload)
    .then((res) => {
      /** append ids into IDB */
      if (res.body.cardType.cardType === cardTypeMapper["Time series plot"]) {
        addDataIDB([res.body]);
      }
      return dispatch({
        type: addNewCardTypes.ADD_ADDNEWCARD_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.ADD_ADDNEWCARD_ERROR,
        payload: err,
      })
    );
};
export const clearAddNewCardState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.ADD_ADDNEWCARD_CLEAR_STATE });

export const getCardTypes = () => (dispatch) => {
  dispatch({ type: addNewCardTypes.CARDTYPE_FETCH_PROGRESS });
  GETRequest("cardtypes")
    .then((res) =>
      dispatch({
        type: addNewCardTypes.CARDTYPE_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) => {
      dispatch({ type: addNewCardTypes.CARDTYPE_FETCH_ERROR, payload: err });
    });
};

export const clearCardState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.CARDTYPE_CLEAR_STATE });

export const clearKPIState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.KPI_BY_LEVEL_CLEAR_STATE });

export const getDeployedComponent = (deployedEquipmentId, componentId) => (
  dispatch
) => {
  dispatch({ type: addNewCardTypes.DEPLOYED_COMPONENT_FETCH_PROGRESS });
  GETRequest(`components/equipment/${deployedEquipmentId}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.DEPLOYED_COMPONENT_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.DEPLOYED_COMPONENT_FETCH_ERROR,
        payload: err,
      })
    );
};

export const clearDeployedComponent = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.DEPLOYED_COMPONENT_CLEAR_STATE });

export const getDeployedEquipment = (levelName, levelId) => (dispatch) => {
  dispatch({ type: addNewCardTypes.DEPLOYED_EQUIPMENT_FETCH_PROGRESS });
  GETRequest(`deployedequipment/${levelName}/${levelId}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.DEPLOYED_EQUIPMENT_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.DEPLOYED_EQUIPMENT_FETCH_ERROR,
        payload: err,
      })
    );
};

export const clearDeloyedEquipment = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.DEPLOYED_EQUIPMENT_CLEAR_STATE });

export const getTagsByComponent = (componentId) => (dispatch) => {
  dispatch({ type: addNewCardTypes.TAGS_BY_COPMONENT_FETCH_PROGRESS });
  GETRequest(`tagsbycomponent?componentId=${componentId}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_COPMONENT_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_COPMONENT_FETCH_ERROR,
        payload: err,
      })
    );
};

export const clearTagsByComponent = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.TAGS_BY_COPMONENT_CLEAR_STATE });

export const getTagsByCardType = (levelName, levelId, type = null) => (
  dispatch
) => {
  dispatch({ type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_PROGRESS });
  GETRequest(`tagbycardtype/${levelName}/${levelId}?type=${type}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_ERROR,
        payload: err,
      })
    );
};

export const clearTagsByCardType = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.TAGS_BY_CARDTYPE_CLEAR_STATE });

export const getTagsforTrends = (levelName, levelId) => (dispatch) => {
  dispatch({ type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_PROGRESS });
  const type = "trends";
  GETRequest(`tagbycardtype/${levelName}/${levelId}`, { type })
    .then((res) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.TAGS_BY_CARDTYPE_FETCH_ERROR,
        payload: err,
      })
    );
};

export const deleteKpiById = (kpiId, type = null) => (dispatch) => {
  dispatch({ type: addNewCardTypes.DELETE_KPI_BY_KPIID_PROGESS });
  DeleteRequest(`kpisdetails/${kpiId}?type=${type}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.DELETE_KPI_BY_KPIID_SUCCESS,
        payload: { kpiId, status: res.body },
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.DELETE_KPI_BY_KPIID_ERROR,
        payload: { kpiId, err },
      })
    );
};

export const clearDeleteKpiState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.DELETE_KPI_BY_KPIID_CLEAR_STATE });

export const onUpdateKpi = (kpiId, payload, type = null) => (dispatch) => {
  dispatch({ type: addNewCardTypes.UPDATE_KPI_BY_KPIID_PROGESS });
  PUTRequest(`kpisdetails/${kpiId}?type=${type}`, payload)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.UPDATE_KPI_BY_KPIID_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.UPDATE_KPI_BY_KPIID_ERROR,
        payload: err,
      })
    );
};

export const clearUpdataKpiState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.UPDATE_KPI_BY_KPIID_CLEAR_STATE });

export const updateKPIReducer = (data) => (dispatch) => {
  addDataIDB(data.data);
  dispatch({ type: addNewCardTypes.UPDATE_KPI_REDUCER, payload: data });
};

export const clearPlotsState = () => (dispatch) => {
  dispatch({ type: addNewCardTypes.CLEAR_PLOTS_STATE });
};

export const getPanelTypes = (levelName, levelId) => (dispatch) => {
  dispatch({ type: addNewCardTypes.PANEL_TYPES_FETCH_PROGRESS });
  GETRequest(`paneltypes/${levelName}/${levelId}`)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.PANEL_TYPES_FETCH_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({ type: addNewCardTypes.PANEL_TYPES_FETCH_ERROR, payload: err })
    );
};

export const savePanelTypes = (payload) => (dispatch) => {
  dispatch({ type: addNewCardTypes.PANEL_TYPES_SAVE_PROGRESS });
  POSTRequest("paneltypes", payload)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.PANEL_TYPES_SAVE_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({ type: addNewCardTypes.PANEL_TYPES_SAVE_ERROR, payload: err })
    );
};

export const deletePanelType = (payload) => (dispatch) => {
  dispatch({ type: addNewCardTypes.PANEL_TYPES_DELETE_PROGRESS });
  DeleteRequest("paneltype", payload)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.PANEL_TYPES_DELETE_SUCCESS,
        payload: { id: payload.id, status: res.body },
      })
    )
    .catch((err) =>
      dispatch({ type: addNewCardTypes.PANEL_TYPES_DELETE_ERROR, payload: err })
    );
};

export const updatePanelType = (payload, id) => (dispatch) => {
  dispatch({ type: addNewCardTypes.PANEL_TYPES_UPDATE_PROGRESS });
  PUTRequest(`types/${id}`, payload)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.PANEL_TYPES_UPDATE_SUCCESS,
        payload: res.body,
      })
    )
    .catch((err) =>
      dispatch({ type: addNewCardTypes.PANEL_TYPES_UPDATE_ERROR, payload: err })
    );
};

export const clearPanelTypeState = () => (dispatch) =>
  dispatch({ type: addNewCardTypes.PANEL_TYPES_CLEAR_STATE });

export const bulkUpdateKpi = (payload, xyDateFrom) => (dispatch) => {
  dispatch({ type: addNewCardTypes.UPDATE_KPI_BULK_PROGESS });
  PUTRequest(`bulk/kpisdetails?xyDateFrom=${xyDateFrom}`, payload)
    .then((res) =>
      dispatch({
        type: addNewCardTypes.UPDATE_KPI_BULK_SUCCESS,
        payload: res.body,
        updateBody: payload,
        xyDateFrom: xyDateFrom
      })
    )
    .catch((err) =>
      dispatch({
        type: addNewCardTypes.UPDATE_KPI_BULK_ERROR,
        payload: err,
      })
    );
};