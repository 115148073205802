import React from 'react';
import _ from 'lodash';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import {
  tableHeader,
  inputFields,
  inputUnits,
  curveInput,
  graphDetails,
  curveVariables,
} from './CapabilityCurves.Constants';
import { Form, Modal } from 'antd';
import Line from '../../../../../../common/Graphs/Line/Line';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  NO_INTERNET,
  FORM_DISCARD_MSG,
  CapabilityCurveMessages,
  CurvesMessages,
  DISCARD_CHANGES,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Loader from '../../../../../../common/Loader/Loader';
import PropTypes from 'prop-types';
import { saveCapabilityCurve } from '../../../../../../redux/actions/synchronousGenerator.action';
import FormFields from '../../../../../../common/FormFields/FormFields';
import './CapabilityCurves.scss';

class CapabilityCurves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: [],
      copyDisplayData: [],
      editTableRows: [],
      inititalDisplayData: [],
      unitData: [],
      visible: false,
      formEdit: false,
      eventListener: false,
      saveListener: false,
      visibleGraph: false,
      visibleModal: false,
      xAxisValue: '',
      yAxisValue: '',
      ambientTemperature: ' ',
      realPower: ' ',
      ambientTemperatureUnit: '',
      realPowerUnit: ',',
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        efficiency: null,
        editTableRows: [],
        eventListener: false,
        saveListener: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }
  setData() {
    const {
      synchronousGeneratorConfigData: { capabilityCurvesData },
    } = this.props.synchronousGeneratorReducer;
    const tempDisplayData = capabilityCurvesData.data.map((item) => {
      return {
        ambientTemperature:
          item['ambientTemperature'] >= 0 ? item['ambientTemperature'] : ' ',
        realPower: item['realPower'] >= 0 ? item['realPower'] : ' ',
      };
    });

    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
      inititalDisplayData: _.cloneDeep(tempDisplayData),
      ambientTemperatureUnit: capabilityCurvesData.dataUnit.ambientTemperature,
      realPowerUnit: capabilityCurvesData.dataUnit.realPower,
    });
  }

  componentDidUpdate(prevProps) {
    const { addCapabilityCurve } = this.props.synchronousGeneratorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }

    if (
      addCapabilityCurve.isSuccess &&
      prevProps.synchronousGeneratorReducer.addCapabilityCurve.isSuccess !==
        addCapabilityCurve.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, CapabilityCurveMessages.SUCCESS);
    }
    if (
      addCapabilityCurve.isError &&
      prevProps.synchronousGeneratorReducer.addCapabilityCurve.isError !==
        addCapabilityCurve.isError
    ) {
      Notification.show(Types.Error, CapabilityCurveMessages.ERROR);
    }

    if (this.props.activeKey !== prevProps.activeKey) {
      this.setData();
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleChangeTableData = (e, unit) => {
    if (unit) {
      this.setState(
        {
          [e.target.id]: e.target.value,
        },
        () => this.enableEditForm()
      );
    } else {
      this.setState(
        {
          [e.target.id]:
            parseFloat(e.target.value) === 0
              ? e.target.value + ''
              : parseFloat(e.target.value.replace(/^0+/, '')),
        },
        () => this.enableEditForm()
      );
    }
  };

  handleChangeTableEditData = (e, row) => {
    const { displayData } = this.state;
    const key = e.target.id;
    const value = e.target.value;
    const tempDisplayData = displayData;
    tempDisplayData[row][key] =
      parseFloat(value) === 0
        ? value + ''
        : parseFloat(value.replace(/^0+/, ''));
    this.setState(
      {
        displayData: tempDisplayData,
      },
      () => this.enableEditForm()
    );
  };

  onAdd = () => {
    const {
      displayData,
      copyDisplayData,
      ambientTemperature,
      realPower
    } = this.state;
    this.setState({
      eventListener: true,
    });
    console.log('here',ambientTemperature, realPower);
    if (
      (ambientTemperature && ambientTemperature !== ' ') ||
      ambientTemperature === 0 ||
      (realPower && realPower !== ' ') ||
      realPower === 0
    ) {
      console.log('pass',ambientTemperature, realPower)
      this.setState(
        {
          displayData: [
            ...displayData,
            {
              ambientTemperature: _.isFinite(ambientTemperature)
                ? ambientTemperature
                : ' ',
              realPower: _.isFinite(realPower) ? realPower : ' ',
            },
          ],
          copyDisplayData: [
            ...copyDisplayData,
            {
              ambientTemperature,
              realPower,
            },
          ],
        },
        () => {
          this.setState(
            {
              eventListener: false,
              ambientTemperature: ' ',
              realPower: ' ',
            },
            () => this.enableEditForm()
          );
        }
      );
    } else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = (row) => {
    const { displayData, copyDisplayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    tempDisplayData[row] = { ...copyDisplayData[row] };
    const tempEditTableRows = editTableRows.filter((item) => item !== row);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditTableRows,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    this.setState(
      {
        editTableRows: [...editTableRows, row],
      },
      () => this.enableEditForm()
    );
  };

  onDelete = (row) => {
    const { displayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    const tempEditRow = editTableRows.filter((item) => item !== row);
    tempDisplayData.splice(row, 1);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditRow,
      },
      () => this.enableEditForm()
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    this.setState({
      displayData: [...copyDisplayData.map((item) => ({ ...item }))],
      visible: false,
      editTableRows: [],
      saveListener: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  handleOkGraph = () => {
    this.setState({ visibleGraph: false });
  };

  getGraphData() {
    const { displayData } = this.state;
    if (displayData.length > 0) {
      const { xAxisValue, yAxisValue } = this.state;
      const axes = displayData.map((item) => {
        return [item[xAxisValue] || null, item[yAxisValue] || null];
      });
      return axes;
    }
  }

  renderGraphModal() {
    const { visibleGraph } = this.state;
    if (visibleGraph) {
      const { xAxisValue, yAxisValue } = this.state;
      const graphData = this.getGraphData();
      return (
        <Modal
          title={graphDetails.title}
          visible={this.state.visibleGraph}
          footer={null}
          onCancel={this.handleOkGraph}
          className="curve-modal"
        >
          <div>
            <button
              className="btn-default btn-small"
              float="right"
              onClick={() => {
                this.showGraph();
              }}
            >
              BACK
            </button>
          </div>
          <div className="curve-plot">
            <div style={{ width: 700 }}>
              <Line
                graphData={graphData}
                xAxis={graphDetails[xAxisValue]}
                yAxis={graphDetails[yAxisValue]}
              />
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  }

  cancelModal() {
    this.setState({
      xAxisValue: '',
      yAxisValue: '',
    });
    this.setState({ visibleModal: false });
  }

  onChangeXAxes = (value) => {
    this.setState({ xAxisValue: value });
  };

  onChangeYAxes = (value) => {
    this.setState({ yAxisValue: value });
  };

  onSelectAxes() {
    const { xAxisValue, yAxisValue } = this.state;
    const xAxisTag = curveVariables[xAxisValue];
    const yAxisTag = curveVariables[yAxisValue];
    this.setState({ xAxisValue: xAxisTag });
    this.setState({ yAxisValue: yAxisTag });
    this.setState({ visibleModal: false });
    this.setState({ visibleGraph: true });
  }

  renderCurveInputModal() {
    const { visibleModal } = this.state;
    if (visibleModal) {
      const data = ['Ambient Temperature', 'Real Power'];
      const { xAxisValue, yAxisValue } = this.state;
      return (
        <Modal
          title={'Curve Input'}
          visible={this.state.visibleModal}
          onCancel={() => this.cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(data, xAxisValue, yAxisValue, {
              changeX: (e) => {
                this.onChangeXAxes(e);
              },
              changeY: (e) => {
                this.onChangeYAxes(e);
              },
            })}
            form={this.props.form}
          />
          {
            <div className="ok-button">
              <button
                className="btn-default btn-small"
                onClick={() => {
                  this.onSelectAxes();
                }}
                disabled={xAxisValue === '' || yAxisValue === ''}
              >
                OK
              </button>
            </div>
          }
        </Modal>
      );
    }
  }

  showGraph() {
    if (this.state.displayData.length >= 3) {
      this.setState({
        xAxisValue: '',
        yAxisValue: '',
      });
      this.setState({ visibleGraph: false });
      this.setState({ visibleModal: true });
    } else {
      Notification.show(Types.Info, CapabilityCurveMessages.MIN_CURVES);
    }
  }

  transformPayload = (data) => {
    data.forEach((item) => {
      if (!_.isFinite(parseFloat(item['ambientTemperature']))) {
        delete item['ambientTemperature'];
      } else {
        item['ambientTemperature'] = parseFloat(item['ambientTemperature']);
      }

      if (!_.isFinite(parseFloat(item['realPower']))) {
        delete item['realPower'];
      } else {
        item['realPower'] = parseFloat(item['realPower']);
      }
    });
    return data;
  };

  onSave = () => {
    this.setState({
      saveListener: true,
    });
    const {
      ambientTemperatureUnit,
      realPowerUnit,
      displayData
    } = this.state;

    let totalError = true;
    this.setState({ copyDisplayData: displayData });
    displayData.forEach((item) => {
      const row = Object.values(item);
      const error = row.find((item) => item && item !== ' ');
      totalError = totalError && (error === 0 ? true : error);
    });
    if (ambientTemperatureUnit && realPowerUnit) {
      if (totalError) {
        if (displayData.length >= 3 && displayData.length <= 15) {
          const payload = {
            dataUnit: {
              ambientTemperature: ambientTemperatureUnit,
              realPower: realPowerUnit,
            },
            data: this.transformPayload(displayData),
          };
          if (this.handleNetwork()) {
            const {
              location: { state },
              saveCapabilityCurve,
            } = this.props;
            if (state && state.componentData.info._id) {
              saveCapabilityCurve(state.componentData.info._id, payload);
            }
          }
        } else {
          Notification.show(Types.Info, CapabilityCurveMessages.MIN);
        }
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else {
      Notification.show(Types.Error, CurvesMessages.SELECT_UNITS);
    }
  };

  render() {
    const {
      eventListener,
      displayData,
      unitData,
      editTableRows,
      saveListener,
      ambientTemperature,
      ambientTemperatureUnit,
      realPower,
      realPowerUnit
    } = this.state;

    const {
      synchronousGeneratorConfigData,
      addCapabilityCurve,
    } = this.props.synchronousGeneratorReducer;
    const { isSchematicEditable } = this.props;
    return (
      <div className="PerformanceCurves">
        {(synchronousGeneratorConfigData.isLoading ||
          addCapabilityCurve.isLoading) && <Loader />}
        <div>
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.showGraph();
              }}
              style={{ border: '1px solid #363b4e' }}
            >
              {'View Curve Plot'}
            </button>
          </div>
          <CustomTable
            showHeader={true}
            columns={tableHeader({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              isSchematicEditable,
            })}
            data={displayData}
            renderAddRow={inputUnits({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              eventListener,
              unitData,
              saveListener,
              length: displayData.length,
              isSchematicEditable,
              ambientTemperature,
              ambientTemperatureUnit,
              realPower,
              realPowerUnit
            })}
            headerColumns={
              isSchematicEditable &&
              inputFields({
                handleChangeTableData: this.handleChangeTableData,
                onAdd: this.onAdd,
                eventListener,
                length: displayData.length,
                ambientTemperature,
                realPower
              })
            }
            editableRow={editTableRows}
          />
        </div>

        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderCurveInputModal()}
        {this.renderModal()}
        {this.renderGraphModal()}
      </div>
    );
  }
}
CapabilityCurves.propTypes = {
  getDataUnits: PropTypes.func,
  synchronousGeneratorConfigData: PropTypes.object,
  addPerformanceCurve: PropTypes.object,
  location: PropTypes.object,
  saveCapabilityCurve: PropTypes.func,
  network: PropTypes.object,
  isSchematicEditable: PropTypes.bool,
};

CapabilityCurves.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    synchronousGeneratorReducer: state.synchronousGeneratorReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveCapabilityCurve: (id, payload) =>
    dispatch(saveCapabilityCurve(id, payload)),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(
  Form.create({
    name: 'curveForm',
    onFieldsChange: (props, fields, allfields) => {},
  })(withRouter(CapabilityCurves))
);
