import React from 'react';
import { Icon, Input } from 'antd';
import { newValidateNumber } from '../../../../../../libs/validator';

export const DataUnit = ['Rated Temperature', 'LHV Efficiency'];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const variablesMapper = {
  roughnessRange: 'roughnessRange',
  soilTempVariation: 'soilTempVariation',
};
export const curvesColumnsTitle = {
  pressurePercError: 'Pressure % error',
  roughnessRange: 'Roughness range',
  temperaturePercError :'Temperature % error',
  soilTempVariation: 'Soil temperature variation',
};
export const curvesColumnsName = {
  pressurePercError:'pressurePercError',
  temperaturePercError:'temperaturePercError',
  roughnessRange: 'roughnessRange',
  soilTempVariation: 'soilTempVariation',
};

export const tableColumsRoughnessRange = (config) =>[
  {
    title: curvesColumnsTitle.pressurePercError,
    dataIndex: curvesColumnsName.pressurePercError,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.pressurePercError}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  { 
    title: curvesColumnsTitle.roughnessRange,
    dataIndex: curvesColumnsName.roughnessRange,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.roughnessRange}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
 
]

export const tableColumsSoilTempVariation = (config)=>[
  {
    title: curvesColumnsTitle.temperaturePercError,
    dataIndex: curvesColumnsName.temperaturePercError,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.temperaturePercError}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: curvesColumnsTitle.soilTempVariation,
    dataIndex: curvesColumnsName.soilTempVariation,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.soilTempVariation}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? 'error' : 'not-error'}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
]

export const iterationDataFormField = (units, config) => [
  {
    label: 'Temperature % error',
    key: 'temperaturePercError',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'LHV Efficiency')[0],
    data: getUnit(units, 'LHV Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Soil Temperature',
    key: 'soilTempVariation',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'Rated Temperature')[0],
    data: getUnit(units, 'Rated Temperature'),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Pressure % error',
    key: 'pressurePercError',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'LHV Efficiency')[0],
    data: getUnit(units, 'LHV Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Roughness Range',
    key: 'roughnessRange',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'LHV Efficiency')[0],
    data: getUnit(units, 'LHV Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const createPayload = (formfieldValue, iterationData = []) => {
  const { ...dataUnit } = formfieldValue;
  const payload = {
    data: {
      iterationData: {roughnessRange:iterationData.roughnessRange, soilTempVariation:iterationData.soilTempVariation},
    },
    dataUnit,
  };
  return payload;
};

export const tableAddRowRoughnessRange = (actions) => [
  {
    title: '',
    dataIndex: curvesColumnsName.pressurePercError,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.pressurePercError}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.pressurePercError]
            ? 'error'
            : 'not-error'
        }
      />
    ),
  },
  {
    title: '',
    dataIndex: curvesColumnsName.roughnessRange,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.roughnessRange}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.roughnessRange]
            ? 'error'
            : 'not-error'
        }
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const tableAddRowSoilTempVariation = (actions) => [
  {
    title: '',
    dataIndex: curvesColumnsName.temperaturePercError,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.temperaturePercError}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.temperaturePercError]
            ? 'error'
            : 'not-error'
        }
      />
    ),
  },
  {
    title: '',
    dataIndex: curvesColumnsName.soilTempVariation,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.soilTempVariation}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          //validateNumber(e);
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.soilTempVariation]
            ? 'error'
            : 'not-error'
        }
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];