import React from "react";
import { Icon, Input } from "antd";
import {
  curvesColumnsTitle,
  curvesColumnsName,
} from '../../../../../../constants/variables';
import { validateNumber } from "../../../../../../libs/validator";
// TODO delete this folder
export const maxVariableCurves = 8;

export const applications = ["Variable Speed", "Fixed Speed"];
export const ApplicationData = ["Fixed Speed", "Variable Speed"];

export const processes = ["Polytropic", "Adiabatic"];

export const DataUnit = [
  "Speed",
  "Volumetric Flow",
  "Head Drop",
  "Efficiency",
  "Diameter",
];

export const dataUnitsVariables = {
  speed: "Speed",
  volumetricFlow: "Volumetric Flow",
  headDrop: "Head Drop",
  efficiency: "Efficiency",
  diameter: "Diameter",
};
export const variablesMapper = {
  value: "value",
  speed: "speed",
  speedOperational: "speedOperational",
  volumetricFlow: "volumetricFlow",
  headDrop: "headDrop",
  efficiency: "efficiency",
  flowCoefficient: "flowCoefficient",
  headCoefficient: "headCoefficient",
  workInput: "workInput",
};

export const CurveScreenConstants = [
  "DA",
  "SACE",
  "SAHE"
]

export const curvesMapper = {
  curve1: "Curve 1",
  curve2: "Curve 2",
  curve3: "Curve 3",
  curve4: "Curve 4",
  curve5: "Curve 5",
  curve6: "Curve 6",
  curve7: "Curve 7",
  curve8: "Curve 8",
};

export const addCurve = "Add Curve";

export const curvesLabel = {
  operationBaselineCurve: "Operational Baseline Curve",
  selectUnits: "Select Units",
  asTestedCurve: "AS-Tested Curve",
};

export const graphDetails = {
  title: "Curve",
  xAxis: "Volume Reduction",
  yAxisInlet: "Inlet Volume",
  yAxisAdiabatic: "Adiabatic Efficiency",
  requiredMessage: "Mininum three data is required",
  asTestedCurve: "As Tested Curve",
  operationalBasedCurve: "Operational Baseline Curve",
};

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const ApplicationFormField = () => [
  {
    label: "Application",
    key: "application",
    type: "dropdown",
    required: [true, ""],
    data: ApplicationData,
  },
];

export const AsTesteCurveFormField = (units, config) => [
  {
    label: "Inlet Pressure",
    key: "inletPressure",
    type: "textWithDrop",
    input: "number",
    value: 0,
    dropDownKey: "unit",
    dropDownData: getUnit(units, "Inlet Pressure"),
    dropDownDefaultValue: "barg",
    dropDownchange: (e) => {
      config.change(e, DataUnit[0]);
    },
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    onKeyDown: (e) => {
      validateNumber(e);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Adiabatic Efficiency",
    key: "adiabaticEfficiency",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Efficiency")[0],
    data: getUnit(units, "Efficiency"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Inlet Volume",
    key: "inletVolume",
    type: "dropdown",
    required: [true, ""],
    value: "m3/h",
    data: getUnit(units, "Volumetric Flow"),
    change: (e) => {
      config.change(e, DataUnit[2]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const AsTesteCurveFormFieldOperational = (units, config) => [
  {
    label: "Inlet Pressure",
    key: "inletPressure",
    type: "textWithDrop",
    input: "number",
    value: 0,
    dropDownKey: "unit",
    dropDownData: getUnit(units, "Inlet Pressure"),
    dropDownDefaultValue: "barg",
    dropDownchange: (e) => {
      config.change(e, DataUnit[0]);
    },
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    onKeyDown: (e) => {
      validateNumber(e);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: curvesColumnsTitle.adiabaticEfficiency,
    key: "adiabaticEfficiency",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Efficiency")[0],
    data: getUnit(units, "Efficiency"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: curvesColumnsTitle.inletVolume,
    key: "inletVolume",
    type: "dropdown",
    required: [true, ""],
    value: "m3/h",
    data: getUnit(units, "Volumetric Flow"),
    change: (e) => {
      config.change(e, DataUnit[2]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.volumeReduction,
    dataIndex: curvesColumnsName.volumeReduction,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.volumeReduction}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title:curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.adiabaticEfficiency}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.inletVolume,
    dataIndex: curvesColumnsName.inletVolume,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inletVolume}
        type="number"
        defaultValue={value}
        value={value}
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          validateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  true && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.volumeReduction,
    dataIndex: curvesColumnsName.volumeReduction,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.volumeReduction}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.volumeReduction]
            ? "error"
            : "not-error"
        }
        onKeyDown={(e) => {
          validateNumber(e);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.adiabaticEfficiency,
    dataIndex: curvesColumnsName.adiabaticEfficiency,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.adiabaticEfficiency}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.adiabaticEfficiency] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validateNumber(e);
        }}
      />
    ),
  },
  {
    title: curvesColumnsTitle.inletVolume,
    dataIndex: curvesColumnsName.inletVolume,
    render: (value, row, col, editable) => (
      <input
        name={curvesColumnsName.inletVolume}
        type="number"
        defaultValue={value}
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        value={value}
        className={
          actions.isError[curvesColumnsName.inletVolume] ? "error" : "not-error"
        }
        onKeyDown={(e) => {
          validateNumber(e);
        }}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const initialValue = {
  application: "Fixed Speed",
  asTestedCurves: [
    {
      application: "Fixed Speed",
      speed: {
        value: 0,
        unit: "rpm",
      },
      volumetricFlow: {
        value: 0,
        unit: "L/h",
      },
      headDrop: {
        value: 0,
        unit: "yd",
      },
      efficiency: {
        value: 0,
        unit: "%/100",
      },
      flowCoefficient: {
        value: 0,
        unit: null,
      },
      headCoefficient: {
        value: 0,
        unit: null,
      },
      workInput: {
        value: 0,
        unit: null,
      },
    },
  ],
  operationalBaseLineCurves: [
    {
      speed: {
        value: 0,
        unit: "rpm",
      },
      volumetricFlow: {
        value: 0,
        unit: "L/h",
      },
      headDrop: {
        value: 0,
        unit: "yd",
      },
      efficiency: {
        value: 0,
        unit: "%/100",
      },
      flowCoefficient: {
        value: 0,
        unit: null,
      },
      headCoefficient: {
        value: 0,
        unit: null,
      },
      workInput: {
        value: 0,
        unit: null,
      },
    },
  ],
};

export const createCurvesPayoad = (
  curveScreen,
  formfieldValue,
  asTestedCurves = [],
  operationalBaseLineCurves = [],
  application = "Fixed Speed",
  curveId = "curve1"
) => {
  const { adiabaticEfficiency, inletVolume, inletPressure, unit } = formfieldValue;
  const dataUnit = {inletVolume, adiabaticEfficiency}
  const dataAsTestedCurves = asTestedCurves.map((item) => {
    const {
      volumeReduction,
      inletVolume,
      adiabaticEfficiency
    } = item;
    return {
      volumeReduction: parseFloat(volumeReduction),
      adiabaticEfficiency: parseFloat(adiabaticEfficiency),
      inletVolume: parseFloat(inletVolume),
    };
  });

  const dataBaseLineCurves = operationalBaseLineCurves.map((item) => {
    const {
      volumeReduction,
      inletVolume,
      adiabaticEfficiency
    } = item;
    return {
      volumeReduction: parseFloat(volumeReduction),
      adiabaticEfficiency: parseFloat(adiabaticEfficiency),
      inletVolume: parseFloat(inletVolume),
    };
  });
  const payload = {
    curveScreen,
    application,
    curveId,
    asTestedCurves: {
      inletPressure: {
        value: parseFloat(inletPressure),
        unit: unit,
      },
      dataUnit,
      data: dataAsTestedCurves,
    },
    operationalBaseLineCurves: {
      inletPressure: {
        value: parseFloat(inletPressure),
        unit: unit,
      },
      dataUnit,
      data: dataBaseLineCurves,
    },
  };
  return payload;
};
