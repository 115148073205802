export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";

export const UPLOAD_UNITCONVERSION_PROGRESS = "UPLOAD_UNITCONVERSION_PROGRESS";
export const UPLOAD_UNITCONVERSION_SUCCESS = "UPLOAD_UNITCONVERSION_SUCCESS";
export const UPLOAD_UNITCONVERSION_FAILURE = "UPLOAD_UNITCONVERSION_FAILURE";
export const UPLOAD_UNITCONVERSION_RESET = "UPLOAD_UNITCONVERSION_RESET";

export const UPLOAD_DATAVARIABLES_PROGRESS = "UPLOAD_DATAVARIABLES_PROGRESS";
export const UPLOAD_DATAVARIABLES_SUCCESS = "UPLOAD_DATAVARIABLES_SUCCESS";
export const UPLOAD_DATAVARIABLES_FAILURE = "UPLOAD_DATAVARIABLES_FAILURE";
export const UPLOAD_DATAVARIABLES_RESET = "UPLOAD_DATAVARIABLES_RESET";

export const UNITSPROPERTIES_FETCH_SUCCESS = "UNITSPROPERTIES_FETCH_SUCCESS";
export const UNITSPROPERTIES_FETCH_PROGRESS = "UNITSPROPERTIES_FETCH_PROGRESS";
export const UNITSPROPERTIES_FETCH_ERROR = "UNITSPROPERTIES_FETCH_ERROR";

export const ASSOCIATIONS_FETCH_SUCCESS = "ASSOCIATIONS_FETCH_SUCCESS";
export const PROPERTIES_FETCH_SUCCESS = "PROPERTIES_FETCH_SUCCESS";
export const DATAVARIBLES_FETCH_SUCCESS = "DATAVARIBLES_FETCH_SUCCESS";
export const UNITS_FETCH_SUCCESS = "UNITS_FETCH_SUCCESS";

export const UNITSPROPERTIES_CLEAR_STATE = "UNITSPROPERTIES_CLEAR_STATE";
