import React from "react";
import { Icon, Input } from "antd";
import { newValidateNumber } from "../../../../../../../libs/validator";

export const DataUnit = ["Inlet Temperature"];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const dataUnitsVariables = {
  inletTemp: "Inlet Temperature",
};
export const variablesMapper = {
  inletTemp: "inletTemp",
  powerCorr: "powerCorr",
  heatRateCorr: "heatRateCorr",
  exhaustFlowCorr: "exhaustFlowCorr",
};
export const curvesColumnsTitle = {
  inletTemp: "Inlet Temperature",
  powerCorr: "PowerCorrection Factor",
  heatRateCorr: "Heat Rate Correction Factor",
  exhaustFlowCorr: "Exhaust Flow Correction Factor",
};
export const curvesColumnsName = {
  inletTemp: "temp",
  powerCorr: "power_corr",
  heatRateCorr: "hr_corr",
  exhaustFlowCorr: "exaust_flow_corr",
};
export const graphDetails = {
  title: "Curve",
  xAxis: "Inlet Temperature",
  power_corr: "Inlet Temp Power correction factor",
  hr_corr: "Inlet Temp Power correction factor",
  exaust_flow_corr: "Inlet Temp Power correction factor",
  requiredMessage: "Mininum three data is required",
  inletTempCurve: "Inlet Temperature Curve",
};

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.inletTemp,
    dataIndex: curvesColumnsName.inletTemp,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inletTemp}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.exhaustFlowCorr,
    dataIndex: curvesColumnsName.exhaustFlowCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.exhaustFlowCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
export const InletTempCurveFormField = (units, config) => [
  {
    label: "Inlet Temperature",
    key: "inletTemp",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Inlet Tempertature")[0],
    data: getUnit(units, "Inlet Temperature"),
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesLabel = {
  inletTempCurve: "Inlet Temperature Curve",
};
export const createCurvesPayload = (formfieldValue, inletTempCurves = []) => {
  const { ...dataUnit } = formfieldValue;
  const dataInletTempCurves = inletTempCurves.map((item) => {
    const { temp, power_corr, hr_corr, exaust_flow_corr } = item;
    return {
      temp: parseFloat(temp),
      power_corr: parseFloat(power_corr),
      hr_corr: parseFloat(hr_corr),
      exaust_flow_corr: parseFloat(exaust_flow_corr),
    };
  });
  const payload = {
    inletTempCurve: {
      dataUnit: { temp: dataUnit.inletTemp },
      data: dataInletTempCurves,
    },
  };
  return payload;
};

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.inletTemp,
    dataIndex: curvesColumnsName.inletTemp,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inletTemp}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.inletTemp] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.powerCorr,
    dataIndex: curvesColumnsName.powerCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.powerCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.powerCorr] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.heatRateCorr,
    dataIndex: curvesColumnsName.heatRateCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.heatRateCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.heatRateCorr]
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.exhaustFlowCorr,
    dataIndex: curvesColumnsName.exhaustFlowCorr,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.exhaustFlowCorr}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.exhaustFlowCorr]
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

export const curveVariables = {
  "Power Correction": "power_corr",
  "Heat Rate Correction": "hr_corr",
  "Exhaust Flow Rate Correction": "exaust_flow_corr",
};

export const curveInput = (data, yAxisValue, config) => {
  return [
    {
      label: "Y Axis",
      key: "yAxis",
      type: "dropdown",
      required: [true, ""],
      data: data,
      value: yAxisValue,
      style: { width: "200px", padding: "10px" },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};
