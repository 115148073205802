import _, { isEmpty } from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  MotorDesignDataMessages,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import { Modal, Button } from 'antd';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  heatTransferDataTable,
  ambientTemperatureProfileDataTable,
  sizingDataTable,
  commonDesignDataTable,
  initialData,
  KeyChange,
  blockDesignDataMap,
  filterCommonDesignData,
  filterAmbientTemperatureProfileData,
  filterHeatTransferData,
  filterSizingData,
  createEmptyArray,
} from './PIMComponentDesignData.Constants';
import { saveDesignData } from '../../../../../../redux/actions/PIMComponent.action';
import {
  updateMaterialEnvironment,
  getMaterialEnvironment,
} from '../../../../../../redux/actions/materialEnvironment.action';
import Loader from '../../../../../../common/Loader/Loader';
import { PIMComponentDesignDataVariables } from '../../../../../../constants/variables';

class DesignDataPIMComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      visibleCopyDataModal: false,
      formEdit: false,
      editTableRows: {
        PIMCommonDesignData: [],
        PIMSizingData: [],
        PIMAmbientTempProfileData: [],
        PIMHeatTransferData: [],
      },
      saveListener: false,
      unitData: [],
      materialEnvironmentData: {},
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    this.props.getMaterialEnvironment();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: {
          PIMCommonDesignData: [],
          PIMSizingData: [],
          PIMAmbientTempProfileData: [],
          PIMHeatTransferData: [],
        },
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setMaterialEnvironment() {
    const {
      materialEnvironmentData: { data },
    } = this.props.MaterialEnvironmentReducer;
    this.setState({ materialEnvironmentData: data });
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  initDesignData(name, designValue) {
    const {
      PIMConfigData: { designData },
    } = this.props.PIMComponentReducer;

    return !_.isUndefined(designValue)
      ? designValue
      : !_.isUndefined(designData?.numSegments)
      ? createEmptyArray(name, designData?.numSegments?.design)
      : [''];
  }
  setData() {
    const {
      PIMConfigData: { designData },
    } = this.props.PIMComponentReducer;
    const modDesignData = {
      application: designData.application,
      twoPhaseFrictionFactorCorrelation:
        designData.twoPhaseFrictionFactorCorrelation,
      heatTransferCorrelationFactor: designData.heatTransferCorrelationFactor,
      singlePhaseFrictionFactorCorrelation:
        designData.singlePhaseFrictionFactorCorrelation,
      palmerCorrectionFactor: designData.palmerCorrectionFactor,
      waterVolume: designData.waterVolume,
      numSegments: designData.numSegments,
      ...designData.sizingData,
      ...designData.ambientTemperatureProfileData,
      ...designData.heatTransferData,
    };

    const tempDisplayData = [
      {
        //0
        name: PIMComponentDesignDataVariables.application, // req w/o unit
        design: modDesignData.application?.design,
      },
      {
        //1
        name: PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation,
        design: modDesignData.twoPhaseFrictionFactorCorrelation?.design,
      },
      {
        //2
        name: PIMComponentDesignDataVariables.heatTransferCorrelationFactor,
        design: modDesignData.heatTransferCorrelationFactor?.design,
      },
      {
        //3
        name:
          PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation,
        design: modDesignData.singlePhaseFrictionFactorCorrelation?.design,
      },
      {
        //4
        name: PIMComponentDesignDataVariables.palmerCorrectionFactor,
        design: modDesignData.palmerCorrectionFactor?.design,
      },
      {
        //5
        name: PIMComponentDesignDataVariables.waterVolume,
        design: modDesignData.waterVolume?.design,
        unit: modDesignData.waterVolume?.unit,
      },
      {
        //6
        name: PIMComponentDesignDataVariables.pipeSchedule,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeSchedule,
          modDesignData.pipeSchedule?.design
        ),
        unit: modDesignData.pipeSchedule?.unit,
      },
      {
        //7
        name: PIMComponentDesignDataVariables.nominalDiameter,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.nominalDiameter,
          modDesignData.nominalDiameter?.design
        ),
        unit: modDesignData.nominalDiameter?.unit,
      },
      {
        //8
        name: PIMComponentDesignDataVariables.inletDiameter, // req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.inletDiameter,
          modDesignData.inletDiameter?.design
        ),
        unit: modDesignData.inletDiameter?.unit,
      },
      {
        //9
        name: PIMComponentDesignDataVariables.outletDiameter, // req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.outletDiameter,
          modDesignData.outletDiameter?.design
        ),
        unit: modDesignData.outletDiameter?.unit,
      },
      {
        //10
        name: PIMComponentDesignDataVariables.pipeWallThickness, // req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeWallThickness,
          modDesignData.pipeWallThickness?.design
        ),
        unit: modDesignData.pipeWallThickness?.unit,
      },
      {
        //11
        name: PIMComponentDesignDataVariables.segmentLength, // req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.segmentLength,
          modDesignData.segmentLength?.design
        ),
        unit: modDesignData.segmentLength?.unit,
      },
      {
        //12
        name: PIMComponentDesignDataVariables.inletElevation, // ((12 && 13) || 14) req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.inletElevation,
          modDesignData.inletElevation?.design
        ),
        unit: modDesignData.inletElevation?.unit,
      },
      {
        //13
        name: PIMComponentDesignDataVariables.outletElevation, // ((12 && 13) || 14) req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.outletElevation,
          modDesignData.outletElevation?.design
        ),
        unit: modDesignData.outletElevation?.unit,
      },
      {
        //14
        name: PIMComponentDesignDataVariables.elevationChange, // ((12 && 13) || 14) req w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.elevationChange,
          modDesignData.elevationChange?.design
        ),
        unit: modDesignData.elevationChange?.unit,
      },
      {
        //15
        name: PIMComponentDesignDataVariables.pipeMaterial, // req w/o unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeMaterial,
          modDesignData.pipeMaterial?.design
        ),
      },
      {
        //16
        name: PIMComponentDesignDataVariables.pipeWallThermalConductivity,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeWallThermalConductivity,
          modDesignData.pipeWallThermalConductivity?.design
        ),
        unit: modDesignData.pipeWallThermalConductivity?.unit,
      },
      {
        //17
        name: PIMComponentDesignDataVariables.pipeWallThermalConductivityNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeWallThermalConductivityNote,
          modDesignData.pipeWallThermalConductivityNote?.design
        ),
      },
      {
        //18
        name: PIMComponentDesignDataVariables.pipeMaterialType, // req w/o unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeMaterialType,
          modDesignData.pipeMaterialType?.design
        ),
      },
      {
        //19
        name: PIMComponentDesignDataVariables.pipeRoughness,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeRoughness,
          modDesignData.pipeRoughness?.design
        ),
        unit: modDesignData.pipeRoughness?.unit,
      },
      {
        //20
        name: PIMComponentDesignDataVariables.pipeRoughnessNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.pipeRoughnessNote,
          modDesignData.pipeRoughnessNote?.design
        ),
      },
      {
        //21
        name: PIMComponentDesignDataVariables.thermalExpansionCoefficient,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.thermalExpansionCoefficient,
          modDesignData.thermalExpansionCoefficient?.design
        ),
        unit: modDesignData.thermalExpansionCoefficient?.unit,
      },
      {
        //22
        name: PIMComponentDesignDataVariables.thermalExpansionCoefficientNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.thermalExpansionCoefficientNote,
          modDesignData.thermalExpansionCoefficientNote?.design
        ),
      },
      {
        //23
        name: PIMComponentDesignDataVariables.hasInsideCoating,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.hasInsideCoating,
          modDesignData.hasInsideCoating?.design
        ),
      },
      {
        //24
        name: PIMComponentDesignDataVariables.coatingType, // req when 23 is true
        design: this.initDesignData(
          PIMComponentDesignDataVariables.coatingType,
          modDesignData.coatingType?.design
        ),
      },
      {
        //25
        name: PIMComponentDesignDataVariables.coatingRoughness,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.coatingRoughness,
          modDesignData.coatingRoughness?.design
        ),
        unit: modDesignData.coatingRoughness?.unit,
      },
      {
        //26
        name: PIMComponentDesignDataVariables.coatingRoughnessNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.coatingRoughnessNote,
          modDesignData.coatingRoughnessNote?.design
        ),
      },
      {
        //27
        name: PIMComponentDesignDataVariables.coatingThickness,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.coatingThickness,
          modDesignData.coatingThickness?.design
        ),
        unit: modDesignData.coatingThickness?.unit,
      },
      {
        //28
        name: PIMComponentDesignDataVariables.hasPipeWall,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.hasPipeWall,
          modDesignData.hasPipeWall?.design
        ),
      },
      {
        //29
        name: PIMComponentDesignDataVariables.hasInsulation,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.hasInsulation,
          modDesignData.hasInsulation?.design
        ),
      },
      {
        //30
        name: PIMComponentDesignDataVariables.insulationType, // req when 29 is true w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.insulationType,
          modDesignData.insulationType?.design
        ),
        unit: modDesignData.insulationType?.unit,
      },
      {
        //31
        name: PIMComponentDesignDataVariables.insulationThermalConductivity,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.insulationThermalConductivity,
          modDesignData.insulationThermalConductivity?.design
        ),
        unit: modDesignData.insulationThermalConductivity?.unit,
      },
      {
        //32
        name: PIMComponentDesignDataVariables.insulationThermalConductivityNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.insulationThermalConductivityNote,
          modDesignData.insulationThermalConductivityNote?.design
        ),
      },
      {
        //33
        name: PIMComponentDesignDataVariables.insulationThickness, // req when 29 is true w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.insulationThickness,
          modDesignData.insulationThickness?.design
        ),
        unit: modDesignData.insulationThickness?.unit,
      },
      {
        //34
        name: PIMComponentDesignDataVariables.hasOuterHTC,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.hasOuterHTC,
          modDesignData.hasOuterHTC?.design
        ),
      },
      {
        //35
        name: PIMComponentDesignDataVariables.ambientMedium, //req when 34 is true w/o unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.ambientMedium,
          modDesignData.ambientMedium?.design
        ),
      },
      {
        //36
        name: PIMComponentDesignDataVariables.soilType, // req when 34 is true w/o unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.soilType,
          modDesignData.soilType?.design
        ),
      },
      {
        //37
        name: PIMComponentDesignDataVariables.soilThermalConductivity,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.soilThermalConductivity,
          modDesignData.soilThermalConductivity?.design
        ),
        unit: modDesignData.soilThermalConductivity?.unit,
      },
      {
        //38
        name: PIMComponentDesignDataVariables.soilThermalConductivityNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.soilThermalConductivityNote,
          modDesignData.soilThermalConductivityNote?.design
        ),
      },
      {
        //39
        name: PIMComponentDesignDataVariables.burialDepth, // req when 35 is 'Ground' w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.burialDepth,
          modDesignData.burialDepth?.design
        ),
        unit: modDesignData.burialDepth?.unit,
      },
      {
        //40
        name: PIMComponentDesignDataVariables.ambientPressure, // req when 35 is 'Air' or 'Water' w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.ambientPressure,
          modDesignData.ambientPressure?.design
        ),
        unit: modDesignData.ambientPressure?.unit,
      },
      {
        //41
        name: PIMComponentDesignDataVariables.ambientVelocity, // req when 35 is 'Air' or 'Water' w unit
        design: this.initDesignData(
          PIMComponentDesignDataVariables.ambientVelocity,
          modDesignData.ambientVelocity?.design
        ),
        unit: modDesignData.ambientVelocity?.unit,
      },
      {
        //42
        name: PIMComponentDesignDataVariables.ambientVelocityNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.ambientVelocityNote,
          modDesignData.ambientVelocityNote?.design
        ),
      },
      {
        //43
        name: PIMComponentDesignDataVariables.janAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.janAmbientTemp,
          modDesignData.janAmbientTemp?.design
        ),
        unit: modDesignData.janAmbientTemp?.unit,
      },
      {
        //44
        name: PIMComponentDesignDataVariables.febAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.febAmbientTemp,
          modDesignData.febAmbientTemp?.design
        ),
        unit: modDesignData.febAmbientTemp?.unit,
      },
      {
        //45
        name: PIMComponentDesignDataVariables.marAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.marAmbientTemp,
          modDesignData.marAmbientTemp?.design
        ),
        unit: modDesignData.marAmbientTemp?.unit,
      },
      {
        //46
        name: PIMComponentDesignDataVariables.aprAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.aprAmbientTemp,
          modDesignData.aprAmbientTemp?.design
        ),
        unit: modDesignData.aprAmbientTemp?.unit,
      },
      {
        //47
        name: PIMComponentDesignDataVariables.mayAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.mayAmbientTemp,
          modDesignData.mayAmbientTemp?.design
        ),
        unit: modDesignData.mayAmbientTemp?.unit,
      },
      {
        //48
        name: PIMComponentDesignDataVariables.junAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.junAmbientTemp,
          modDesignData.junAmbientTemp?.design
        ),
        unit: modDesignData.junAmbientTemp?.unit,
      },
      {
        //49
        name: PIMComponentDesignDataVariables.julAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.julAmbientTemp,
          modDesignData.julAmbientTemp?.design
        ),
        unit: modDesignData.julAmbientTemp?.unit,
      },
      {
        //50
        name: PIMComponentDesignDataVariables.augAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.augAmbientTemp,
          modDesignData.augAmbientTemp?.design
        ),
        unit: modDesignData.augAmbientTemp?.unit,
      },
      {
        //51
        name: PIMComponentDesignDataVariables.sepAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.sepAmbientTemp,
          modDesignData.sepAmbientTemp?.design
        ),
        unit: modDesignData.sepAmbientTemp?.unit,
      },
      {
        //52
        name: PIMComponentDesignDataVariables.octAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.octAmbientTemp,
          modDesignData.octAmbientTemp?.design
        ),
        unit: modDesignData.octAmbientTemp?.unit,
      },
      {
        //53
        name: PIMComponentDesignDataVariables.novAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.novAmbientTemp,
          modDesignData.novAmbientTemp?.design
        ),
        unit: modDesignData.novAmbientTemp?.unit,
      },
      {
        //54
        name: PIMComponentDesignDataVariables.decAmbientTemp,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.decAmbientTemp,
          modDesignData.decAmbientTemp?.design
        ),
        unit: modDesignData.decAmbientTemp?.unit,
      },
      {
        //55
        name: PIMComponentDesignDataVariables.ambientTemperatureNote,
        design: this.initDesignData(
          PIMComponentDesignDataVariables.ambientTemperatureNote,
          modDesignData.ambientTemperatureNote?.design
        ),
      },
      {
        //56
        name: PIMComponentDesignDataVariables.numSegments,
        design: !_.isUndefined(modDesignData.numSegments?.design)
          ? modDesignData.numSegments?.design
          : 1,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const { addDesignData, PIMConfigData } = this.props.PIMComponentReducer;
    const {
      addMaterialEnvironmentData,
      materialEnvironmentData,
    } = this.props.MaterialEnvironmentReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (
      materialEnvironmentData.isSuccess &&
      prevProps.MaterialEnvironmentReducer.materialEnvironmentData.isSuccess !==
        materialEnvironmentData.isSuccess
    ) {
      this.setMaterialEnvironment();
    }
    if (
      PIMConfigData.isSuccess &&
      prevProps.PIMComponentReducer.PIMConfigData.isSuccess !==
        PIMConfigData.isSuccess
    ) {
      this.setData();
      //this.props.getMaterialEnvironment();
      // this.setMaterialEnvironment();
    }
    if (
      PIMConfigData.isError &&
      prevProps.PIMComponentReducer.PIMConfigData.isError !==
        PIMConfigData.isError
    ) {
      this.setState({ displayData: initialData, copyDisplayData: initialData });
      this.setData();
    }
    if (
      addDesignData.isSuccess &&
      prevProps.PIMComponentReducer.addDesignData.isSuccess !==
        addDesignData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: {
          PIMCommonDesignData: [],
          PIMSizingData: [],
          PIMAmbientTempProfileData: [],
          PIMHeatTransferData: [],
        },
      });
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
    if (
      addDesignData.isError &&
      prevProps.PIMComponentReducer.addDesignData.isError !==
        addDesignData.isError
    ) {
      Notification.show(Types.Error, MotorDesignDataMessages.ERROR);
    }

    if (
      addMaterialEnvironmentData.isSuccess &&
      prevProps.MaterialEnvironmentReducer.addMaterialEnvironmentData
        .isSuccess !== addMaterialEnvironmentData.isSuccess
    ) {
    }

    if (
      addMaterialEnvironmentData.isError &&
      prevProps.MaterialEnvironmentReducer.addMaterialEnvironmentData
        .isError !== addMaterialEnvironmentData.isError
    ) {
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  noneEmpty(arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === '') return false;
    }
    return true;
  }

  AllNull(arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== null) return false;
    }
    return true;
  }
  AllEmpty(arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== '') return false;
    }
    return true;
  }

  handleChange = (val, name, type, index = -1) => {
    this.enableEditForm();
    const dict = {
      [PIMComponentDesignDataVariables.insulationType]:
        'insulationThermalConductivity',
      [PIMComponentDesignDataVariables.soilType]: 'soilThermalConductivity',
      [PIMComponentDesignDataVariables.pipeMaterial]:
        'pipeWallThermalConductivity',
      [PIMComponentDesignDataVariables.pipeMaterialType]: 'pipeRoughness',
    };
    const displayDataIndices = {
      insulationThermalConductivity: 31,
      soilThermalConductivity: 37,
      pipeWallThermalConductivity: 16,
      pipeRoughness: 19,
    };

    const { displayData, materialEnvironmentData } = this.state;

    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        if (
          item.name === PIMComponentDesignDataVariables.numSegments &&
          parseFloat(val) < 1
        ) {
          Notification.show(Types.Error, 'There should be atleast one segment');
        }
        else
        {
          return {
            name,
            unit: item.unit,
            design: val === '' ? undefined : parseFloat(val),
          };
        }    
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return {
          name,
          unit: _.isUndefined(val) ? '' : val,
          design: item.design,
        };
      } else if (item.name === name && type === 'boolean') {
        return { name, design: val };
      } else if (item.name === name && type === 'numberArray') {
        let arr = item.design || createEmptyArray('', displayData[56].design);
        arr[index] = val === '' ? val : parseFloat(val);
        return { name, unit: item.unit, design: arr };
      } else if (item.name === name && type === 'booleanArray') {
        let arr = item.design;
        arr[index] = val;
        return { name, design: arr };
      } else if (item.name === name && type === 'textArray') {
        let arr = item.design || createEmptyArray('', displayData[56].design);
        arr[index] = val;
        return { name, design: arr };
      } else if (item.name === name && type === 'selectArray') {
        let arr = item.design || createEmptyArray('', displayData[56].design);
        arr[index] = val;
        return { name, design: arr };
      }
      return item;
    });

    let names = Object.keys(dict);
    let _val, _name;

    if (names.includes(name)) {
      _name = dict[name];
      _val = materialEnvironmentData[_name]
        .filter((item) => item.design === val)
        .map((item) => {
          return { design: item.siValue, unit: item.siunit };
        });
      _val = _val[0]; //map returns an array

      let idx = displayDataIndices[_name];

      if (_.isUndefined(_val)) {
        _val = { unit: '', design: '' };
      }
      tempDisplayData[idx].unit = _val['unit'];
      if (!tempDisplayData[idx].design) {
        let tempDesignData = createEmptyArray('', displayData[56].design);
        tempDesignData[index] = _val['design'];
        tempDisplayData[idx].design = tempDisplayData;
      } else tempDisplayData[idx].design[index] = _val['design'];
    }

    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  // handleChangeMaterialEnvironment = (val, name) => {
  //   const dict = {
  //     [PIMComponentDesignDataVariables.insulationType]:
  //       'insulationThermalConductivity',
  //     [PIMComponentDesignDataVariables.soilType]: 'soilThermalConductivity',
  //     [PIMComponentDesignDataVariables.pipeMaterial]:
  //       'pipeWallThermalConductivity',
  //     [PIMComponentDesignDataVariables.pipeMaterialType]: 'pipeRoughness',
  //   };

  //   const { materialEnvironmentData } = this.state;
  //   let _val, _name;
  //   _name = dict[name];
  //   _val = materialEnvironmentData[_name]
  //     .filter((item) => item.design === val)
  //     .map((item) => item.siValue);
  //   _val = _val[0]; //map returns an array
  //   this.handleChange(val, name, 'select');
  // };

  onEdit = (row, tableName) => {
    const { editTableRows } = this.state;
    let editTable = editTableRows;

    editTable[tableName].push(row);
    editTable[tableName].sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row, tableName, itemName = '') => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    let tempDisplayData = [];
    //one of the disappearing rows has been operated upon
    if(itemName!=='')
    {
      const index = displayData.findIndex(element => {
        if (element.name === itemName) {
          return true;
        }
      
        return false;
      });
       tempDisplayData = _.cloneDeep(displayData);
      tempDisplayData[index] = _.cloneDeep(copyDisplayData[index]);

    }
    else
    {
      row =
      tableName !== 'PIMCommonDesignData'
        ? blockDesignDataMap(row - 1, tableName)
        : blockDesignDataMap(row, tableName);
     tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);
    }
    
    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onAdd = (copyData) => {
    const { displayData, index } = this.state;
    let tempDisplayData = displayData;

    for (let i = 6; i <= 55; i++) {
      if (!copyData) {
        tempDisplayData[i]['design'].splice(index, 0, '');
      } else {
        let valueToAdd = tempDisplayData[i]['design'][index];
        tempDisplayData[i]['design'].splice(index, 0, valueToAdd);
      }
    }
    tempDisplayData[56].design += 1;
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        copyDisplayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onRemove = (index) => {
    const { displayData } = this.state;
    let tempDisplayData = displayData;

    if (displayData[56].design > 1) {
      // check numSegments
      for (let i = 6; i <= 55; i++) {
        tempDisplayData[i]['design'].splice(index, 1);
      }
      tempDisplayData[56].design -= 1;

      this.setState(
        {
          displayData: _.cloneDeep(tempDisplayData),
          copyDisplayData: _.cloneDeep(tempDisplayData),
        },
        () => this.enableEditForm()
      );
    } else {
      Notification.show(Types.Error, 'There should be atleast one segment');
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      visibleCopyDataModal: false,
    });
  };

  handleOk = () => {
    this.setState(
      {
        visible: false,
      },
      this.setData()
    );
    this.disableEditForm();
  };

  //required data (design only or design + unit)
  checkRequiredData = (displayData) => {
    if (
      !_.isUndefined(displayData[0].design) && !_.isEmpty(displayData[0].design)&& //application
      this.noneEmpty(displayData[15].design) && // pipeMaterial
      this.noneEmpty(displayData[18].design) && //pipeMaterialType
      this.noneEmpty(displayData[8].design) &&
      displayData[8].unit && //inletDiameter
      this.noneEmpty(displayData[9].design) &&
      displayData[9].unit && //outletDiameter
      this.noneEmpty(displayData[10].design) &&
      displayData[10].unit && //pipeWallThickness
      this.noneEmpty(displayData[11].design) && //segmentLength
      displayData[11].unit
    ) {
      return true;
    }
    Notification.show(Types.Error, 'Check required data');
    return false;
  };

  checkElevationData() {
    const { displayData } = this.state;
    if (
      (displayData[12].design &&
        this.noneEmpty(displayData[12].design) && //inletElevation
        displayData[12].unit &&
        displayData[13].design &&
        this.noneEmpty(displayData[13].design) && //outletElevation
        displayData[13].unit) ||
      (displayData[14].design &&
        this.noneEmpty(displayData[14].design) && //elevationChange
        displayData[14].unit)
    ) {
      // ((12 && 13) || 14) req w unit
      return true;
    }
    Notification.show(Types.Error, 'Check elevation data');
    return false;
  }
  checkCoatingData() {
    const { displayData } = this.state;
    const hasInsideCoatingData = displayData[23].design; //[True,True,True]
    const coatingTypeData = displayData[24].design; //['Asphalt','Asphalt','Asphalt']
    let flag = true;
    for (let i in hasInsideCoatingData) {
      if (
        (hasInsideCoatingData[i] &&
          coatingTypeData[i] !== '' &&
          !_.isUndefined(coatingTypeData[i])) ||
        !hasInsideCoatingData[i]
      ) {
      } else {
        flag = false;
        Notification.show(Types.Error, 'Check coating data');
        return;
      }
    }

    return flag;
  }

  checkInsulationData() {
    const { displayData } = this.state;
    const hasInsulationData = displayData[29].design;
    const insulationTypeData = displayData[30].design;
    const insulationThicknessData = displayData[33].design || [];
    let flag = true;
    for (let i in hasInsulationData) {
      if (
        !hasInsulationData[i] ||
        (hasInsulationData[i] &&
          !_.isUndefined(insulationTypeData[i]) &&
          insulationTypeData[i] &&
          !_.isUndefined(insulationThicknessData[i]) &&
          !_.isUndefined(displayData[33].unit))
      ) {
      } else {
        flag = false;
        Notification.show(Types.Error, 'Check insulation data');
        return;
      }
    }
    return flag;
  }

  checkAmbientMediumData() {
    const { displayData } = this.state;
    const hasOuterHTCData = displayData[34].design || []; //hasOuterHTC
    const ambientMediumData = displayData[35].design || []; //ambientMedium
    let soilTypeData = {};
    let burialDepthData = {};
    let ambientPressureData = {};
    let ambientVelocityData = {};
    displayData.forEach((item, index) => {
      if (item.name === PIMComponentDesignDataVariables.soilType) {
        soilTypeData = item;
      } else if (item.name === PIMComponentDesignDataVariables.burialDepth) {
        burialDepthData = item;
      } else if (
        item.name === PIMComponentDesignDataVariables.ambientPressure
      ) {
        ambientPressureData = item;
      } else if (
        item.name === PIMComponentDesignDataVariables.ambientVelocity
      ) {
        ambientVelocityData = item;
      }
    });

    let flag = true;
    for (let i in hasOuterHTCData) {
      if (!hasOuterHTCData[i]) {
      } else if (
        hasOuterHTCData[i] &&
        this.notUndefinedOrBlank(ambientMediumData[i])
      ) {
        if (ambientMediumData[i] === 'Ground') {
          if (
            !this.notUndefinedOrBlank(soilTypeData.design[i]) ||
            !this.notUndefinedOrBlank(burialDepthData.design[i]) ||
            !this.notUndefinedOrBlank(burialDepthData.unit)
          ) {
            flag = false;
            break;
          }
        } else if (
          ambientMediumData[i] === 'Air' ||
          ambientMediumData[i] === 'Water'
        ) {
          if (
            !this.notUndefinedOrBlank(ambientPressureData.design[i]) ||
            !this.notUndefinedOrBlank(ambientVelocityData.design[i]) ||
            !this.notUndefinedOrBlank(ambientVelocityData.unit) ||
            !this.notUndefinedOrBlank(ambientPressureData.unit)
          ) {
           
            flag = false;
            break;
          }
        }
      }
      else
      {
        flag = false;
            break;
      }
    }
    !flag &&  Notification.show(Types.Error, 'Check Ambient medium data');
    return flag;
  }

  notUndefinedOrBlank(item) {
    if (!_.isUndefined(item) && item !== '') {
      return true;
    }
    return false;
  }
  //required data with contingencies
  checkRequiredDataContingencies = () => {
    if (
      this.checkElevationData() &&
      this.checkCoatingData() &&
      this.checkInsulationData() &&
      this.checkAmbientMediumData()
    ) {
      return true;
    }
    return false;
  };

  //last set (non-mandatory) to have both unit and design or niether
  checkNonRequiredData = (displayData) => {
    let flag = true;
    const checkArr = [
      7, //nominalDiameter
      16, //pipeWallThermalConductivity
      19, //pipeRoughness
      21, //thermalExpansionCoefficient
      25, //coatingRoughness
      27, //coatingThickness
      31, //insulationThermalConductivity
      37, //soilThermalConductivity
      43, //janAmbientTemp
      44, //febAmbientTemp
      45, //marAmbientTemp
      46, //aprAmbientTemp
      47, //mayAmbientTemp
      48, //junAmbientTemp
      49, //julAmbientTemp
      50, //augAmbientTemp
      51, //sepAmbientTemp
      52, //octAmbientTemp
      53, //novAmbientTemp
      54, //decAmbientTemp
    ];
    if (
      (_.isUndefined(displayData[5].design) &&
        _.isUndefined(displayData[5].unit)) ||
      (!_.isUndefined(displayData[5].design) &&
        !_.isUndefined(displayData[5].unit))
    ) {
      //5
    } else {
      flag = false;
      Notification.show(Types.Error, 'Check Water volume');
      return;
    }
    for (let index in checkArr) {
      if (
        (this.AllEmpty(displayData[checkArr[index]].design) &&
          (displayData[checkArr[index]].unit === '' ||
            _.isUndefined(displayData[checkArr[index]].unit))) ||
        (displayData[checkArr[index]].unit !== '' &&
          !_.isUndefined(displayData[checkArr[index]].unit) &&
          !this.AllEmpty(displayData[checkArr[index]].design))
      ) {
      } else {
        flag = false;
        Notification.show(
          Types.Error,
          'Check ' + displayData[checkArr[index]].name
        );
        return;
      }
    }
    return flag;
  };

  onSave = () => {
    const {
      location: { state },
      saveDesignData,
    } = this.props;
    const { displayData } = this.state;
    const displayDataForPayload = _.cloneDeep(displayData);
    this.setState({
      saveListener: true,
      copyDisplayData: _.cloneDeep(displayData),
    });

    if (
      displayData[56].design >=1 &&
      this.checkRequiredData(displayData) &&
      this.checkRequiredDataContingencies() &&
      this.checkNonRequiredData(displayData)
    ) {
      const data = KeyChange;
      let payload = {
        sizingData: {},
        heatTransferData: {},
        ambientTemperatureProfileData: {},
      };
      displayDataForPayload.forEach((item) => {
        //common design data
        if (
          item.name === PIMComponentDesignDataVariables.application ||
          item.name ===
            PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation ||
          item.name ===
            PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation ||
          item.name ===
            PIMComponentDesignDataVariables.palmerCorrectionFactor ||
          item.name ===
            PIMComponentDesignDataVariables.heatTransferCorrelationFactor ||
          item.name === PIMComponentDesignDataVariables.waterVolume ||
          item.name === PIMComponentDesignDataVariables.numSegments
        ) {
          //withUnits
          if (
            item.name === PIMComponentDesignDataVariables.waterVolume &&
            item.design
          ) {
            payload[data[item.name]] = {
              unit: item.unit,
              design: item.design,
            };
          }
          //
          else if (
            item.name === PIMComponentDesignDataVariables.palmerCorrectionFactor
          ) {
            payload[data[item.name]] = {
              design: item.design ? item.design : false,
            };
          }
          //withoutUnits
          else if (item.design) {
            payload[data[item.name]] = {
              design: item.design,
            };
          }
        }

        //sizing data
        if (
          item.name === PIMComponentDesignDataVariables.pipeSchedule ||
          item.name === PIMComponentDesignDataVariables.nominalDiameter ||
          item.name === PIMComponentDesignDataVariables.inletDiameter ||
          item.name === PIMComponentDesignDataVariables.outletDiameter ||
          item.name === PIMComponentDesignDataVariables.pipeWallThickness ||
          item.name === PIMComponentDesignDataVariables.segmentLength ||
          item.name === PIMComponentDesignDataVariables.inletElevation ||
          item.name === PIMComponentDesignDataVariables.outletElevation ||
          item.name === PIMComponentDesignDataVariables.elevationChange ||
          item.name === PIMComponentDesignDataVariables.pipeMaterial ||
          item.name === PIMComponentDesignDataVariables.pipeMaterialType ||
          item.name ===
            PIMComponentDesignDataVariables.pipeWallThermalConductivity ||
          item.name ===
            PIMComponentDesignDataVariables.pipeWallThermalConductivityNote ||
          item.name === PIMComponentDesignDataVariables.pipeRoughness ||
          item.name === PIMComponentDesignDataVariables.pipeRoughnessNote ||
          item.name ===
            PIMComponentDesignDataVariables.thermalExpansionCoefficient ||
          item.name ===
            PIMComponentDesignDataVariables.thermalExpansionCoefficientNote ||
          item.name === PIMComponentDesignDataVariables.hasInsideCoating ||
          item.name === PIMComponentDesignDataVariables.coatingType ||
          item.name === PIMComponentDesignDataVariables.coatingRoughness ||
          item.name === PIMComponentDesignDataVariables.coatingRoughnessNote ||
          item.name === PIMComponentDesignDataVariables.coatingThickness
        ) {
          //withoutUnits
          if (
            (item.name === PIMComponentDesignDataVariables.pipeSchedule ||
              item.name === PIMComponentDesignDataVariables.pipeMaterial ||
              item.name ===
                PIMComponentDesignDataVariables.pipeWallThermalConductivityNote ||
              item.name === PIMComponentDesignDataVariables.pipeMaterialType ||
              item.name === PIMComponentDesignDataVariables.pipeRoughnessNote ||
              item.name ===
                PIMComponentDesignDataVariables.thermalExpansionCoefficientNote ||
              item.name === PIMComponentDesignDataVariables.hasInsideCoating ||
              item.name === PIMComponentDesignDataVariables.coatingType ||
              item.name ===
                PIMComponentDesignDataVariables.coatingRoughnessNote) &&
            item.design &&
            !this.AllEmpty(item.design)
          ) {
            payload['sizingData'][data[item.name]] = {
              design: item.design,
            };
          }

          //withUnits
          else if (item.design && !this.AllEmpty(item.design)) {
            payload['sizingData'][data[item.name]] = {
              unit: item.unit,
              design: item.design,
            };
          }
        }
        //heat transfer data
        if (
          item.name === PIMComponentDesignDataVariables.hasPipeWall ||
          item.name === PIMComponentDesignDataVariables.hasInsulation ||
          item.name === PIMComponentDesignDataVariables.insulationType ||
          item.name ===
            PIMComponentDesignDataVariables.insulationThermalConductivity ||
          item.name ===
            PIMComponentDesignDataVariables.insulationThermalConductivityNote ||
          item.name === PIMComponentDesignDataVariables.insulationThickness ||
          item.name === PIMComponentDesignDataVariables.hasOuterHTC ||
          item.name === PIMComponentDesignDataVariables.soilType ||
          item.name ===
            PIMComponentDesignDataVariables.soilThermalConductivity ||
          item.name ===
            PIMComponentDesignDataVariables.soilThermalConductivityNote ||
          item.name === PIMComponentDesignDataVariables.burialDepth ||
          item.name === PIMComponentDesignDataVariables.ambientMedium ||
          item.name === PIMComponentDesignDataVariables.ambientPressure ||
          item.name === PIMComponentDesignDataVariables.ambientVelocity ||
          item.name === PIMComponentDesignDataVariables.ambientVelocityNote
        ) {
          //withUnits
          if (
            (item.name ===
              PIMComponentDesignDataVariables.insulationThermalConductivity ||
              item.name ===
                PIMComponentDesignDataVariables.insulationThickness ||
              item.name ===
                PIMComponentDesignDataVariables.soilThermalConductivity ||
              item.name === PIMComponentDesignDataVariables.burialDepth ||
              item.name === PIMComponentDesignDataVariables.ambientPressure ||
              item.name === PIMComponentDesignDataVariables.ambientVelocity) &&
            item.design &&
            !this.AllEmpty(item.design)
          ) {
            payload['heatTransferData'][data[item.name]] = {
              unit: item.unit,
              design: item.design,
            };
          }

          //withoutUnits
          else if (item.design && !this.AllEmpty(item.design)) {
            payload['heatTransferData'][data[item.name]] = {
              design: item.design,
            };
          }
        }
        //ambient temperature profile data
        if (
          item.name === PIMComponentDesignDataVariables.janAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.febAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.marAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.aprAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.mayAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.junAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.julAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.augAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.sepAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.octAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.novAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.decAmbientTemp ||
          item.name === PIMComponentDesignDataVariables.ambientTemperatureNote
        ) {
          //withoutUnits
          if (
            item.name ===
              PIMComponentDesignDataVariables.ambientTemperatureNote &&
            item.design
          ) {
            payload['ambientTemperatureProfileData'][data[item.name]] = {
              design: item.design,
            };
          }

          //withUnits
          else if (item.design && !this.AllEmpty(item.design)) {
            payload['ambientTemperatureProfileData'][data[item.name]] = {
              unit: item.unit,
              design: item.design,
            };
          }
        }
      });
      if (
        this.handleNetwork() &&
        state &&
        state.componentData.info._id &&
        payload
      ) {
        payload = { designData: payload };

        //SizingData
        const hasInsideCoatingData =
          payload.designData.sizingData?.hasInsideCoating;

        hasInsideCoatingData &&
          hasInsideCoatingData.design.forEach((item, index) => {
            if (!item) {
              if (payload.designData.sizingData.coatingType) {
                delete payload.designData.sizingData.coatingType.design[index];
                displayData[24].design[index] = '';
              }
              if (payload.designData.sizingData.coatingRoughness) {
                delete payload.designData.sizingData.coatingRoughness.design[
                  index
                ];
                displayData[25].design[index] = '';
                displayData[25].unit = '';
              }

              if (payload.designData.sizingData.coatingThickness) {
                delete payload.designData.sizingData.coatingThickness.design[
                  index
                ];
                displayData[27].design[index] = '';
                displayData[27].unit = '';
              }
            }
          });
        if (
          hasInsideCoatingData &&
          !hasInsideCoatingData.design.filter((item) => item).length
        ) {
          delete payload.designData.sizingData?.coatingType;
          delete payload.designData.sizingData?.coatingRoughness;
          delete payload.designData.sizingData?.coatingThickness;
          displayData[25].unit = '';
          displayData[27].unit = '';
        }

        //Heat transfer data
        const hasInsulationData =
          payload.designData.heatTransferData?.hasInsulation;
        hasInsulationData &&
          hasInsulationData.design.forEach((item, index) => {
            if (!item) {
              if (payload.designData.heatTransferData.insulationType) {
                delete payload.designData.heatTransferData.insulationType
                  .design[index];
                displayData[30].design[index] = '';
              }
              if (
                payload.designData.heatTransferData
                  .insulationThermalConductivity
              ) {
                delete payload.designData.heatTransferData
                  .insulationThermalConductivity.design[index];
                displayData[31].design[index] = '';
              }
              if (payload.designData.heatTransferData.insulationThickness) {
                delete payload.designData.heatTransferData.insulationThickness
                  .design[index];
                displayData[33].design[index] = '';
              }
            }
          });

        if (
          hasInsulationData &&
          !hasInsulationData.design.filter((item) => item).length
        ) {
          delete payload.designData.heatTransferData?.insulationType;
          delete payload.designData.heatTransferData
            ?.insulationThermalConductivity;
          delete payload.designData.heatTransferData?.insulationThickness;
          displayData[31].unit = '';
          displayData[33].unit = '';
        }

        //ambient temperature profile data

        if (isEmpty(payload[ambientTemperatureProfileDataTable]))
          delete payload[ambientTemperatureProfileDataTable];

        const hasOuterHTCData =
          payload.designData.heatTransferData?.hasOuterHTC;
        const ambientMediumData =
          payload.designData.heatTransferData?.ambientMedium;
        const soilTypeData = payload.designData.heatTransferData?.soilType;
        const soilThermalConductivityData =
          payload.designData.heatTransferData?.soilThermalConductivity;
        const burialDepthData =
          payload.designData.heatTransferData?.burialDepth;
        const ambientPressureData =
          payload.designData.heatTransferData?.ambientPressure;
        const ambientVelocityData =
          payload.designData.heatTransferData?.ambientVelocity;

        hasOuterHTCData &&
          hasOuterHTCData.design.forEach((item, index) => {
            if (!item) {
              // delete all incase HTC data switch is false
              if (!_.isUndefined(ambientMediumData)) {
                delete ambientMediumData.design[index];
                displayData[35].design[index] = '';
              }

              if (!_.isUndefined(soilTypeData)) {
                delete soilTypeData.design[index];
                displayData[36].design[index] = '';
              }
              if (!_.isUndefined(soilThermalConductivityData)) {
                delete soilTypeData.design[index];
                displayData[37].design[index] = '';
              }
              if (!_.isUndefined(burialDepthData)) {
                delete burialDepthData.design[index];
                displayData[39].design[index] = '';
              }

              if (!_.isUndefined(ambientPressureData)) {
                delete ambientPressureData.design[index];
                displayData[40].design[index] = '';
              }

              if (!_.isUndefined(ambientVelocityData)) {
                delete ambientVelocityData.design[index];
                displayData[41].design[index] = '';
              }
            } else if (item) {
              if (!_.isUndefined(ambientMediumData)) {
                if (ambientMediumData.design[index] === 'Ground') {
                  if (!_.isUndefined(ambientPressureData)) {
                    delete ambientPressureData.design[index];
                    displayData[40].design[index] = '';
                  }

                  if (!_.isUndefined(ambientVelocityData)) {
                    delete ambientVelocityData.design[index];
                    displayData[41].design[index] = '';
                  }
                } else if (
                  ambientMediumData.design[index] === 'Air' ||
                  ambientMediumData.design[index] === 'Water'
                ) {
                  if (!_.isUndefined(soilTypeData)) {
                    delete soilTypeData.design[index];
                    displayData[36].design[index] = '';
                  }
                  if (!_.isUndefined(soilThermalConductivityData)) {
                    delete soilThermalConductivityData.design[index];
                    displayData[37].design[index] = '';
                  }
                  if (!_.isUndefined(burialDepthData)) {
                   delete burialDepthData.design[index];
                    displayData[39].design[index] = '';
                  }
                }
              }
            }
          });

        if (
          hasOuterHTCData &&
          !hasOuterHTCData.design.filter((item) => item).length
        ) {
          delete payload.designData.heatTransferData?.ambientMedium;
          delete payload.designData.heatTransferData?.soilType;
          delete payload.designData.heatTransferData?.soilThermalConductivity
          delete payload.designData.heatTransferData?.burialDepth;
          delete payload.designData.heatTransferData?.ambientPressure;
          delete payload.designData.heatTransferData?.ambientVelocity;

          displayData[40].unit = '';
          displayData[41].unit = '';
          displayData[37].unit = '';
          displayData[39].unit = '';
        }

        //if all ambient mediums are ground , delete air/water related stuff
        if( ambientMediumData && !ambientMediumData.design.filter((item)=>['Air','Water'].includes(item)).length)
        {
          delete payload.designData.heatTransferData?.ambientPressure;
          delete payload.designData.heatTransferData?.ambientVelocity;
          displayData[40].design= [''] 
          displayData[41].design= [''] 
          displayData[40].unit = '';
          displayData[41].unit = '';
        }

        //if all ambient mediums are air/water , delete ground related stuff
        if( ambientMediumData && !ambientMediumData.design.filter((item)=>item === 'Ground').length)
        {
          delete payload.designData.heatTransferData?.soilType;
          delete payload.designData.heatTransferData?.burialDepth;
          delete payload.designData.heatTransferData?.soilThermalConductivity;
          displayData[37].design= ['']; 
          displayData[39].design= ['']; 
          displayData[37].unit = '';
          displayData[39].unit = '';
        }

        saveDesignData(state.componentData.info._id, payload);
      }
    }
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  triggerDataCopy = (index) => {
    this.setState({
      visibleCopyDataModal: true,
      index,
    });
  };
  renderCopyDesignDataModal() {
    const { visibleCopyDataModal } = this.state;
    if (visibleCopyDataModal) {
      return (
        <Modal
          visible={visibleCopyDataModal}
          handleOk={this.handleOkCopyDataModal}
          handleCancel={() => {
            this.setState({
              visibleCopyDataModal: false,
            });
          }}
          title={'Copy segment data'}
          footer={[
            <Button
              key="yes"
              type="primary"
              onClick={() => {
                this.setState(
                  {
                    visibleCopyDataModal: false,
                  },

                  () => this.onAdd(true)
                );
              }}
            >
              Yes
            </Button>,
            <Button
              key="no"
              type="primary"
              onClick={() => {
                this.setState(
                  {
                    visibleCopyDataModal: false,
                  },

                  () => this.onAdd(false)
                );
              }}
            >
              No
            </Button>,
            <Button key="cancel" type="primary" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <p> Do you want to copy data across segments?</p>
        </Modal>
      );
    } else {
      return null;
    }
  }

  render() {
    const { PIMConfigData, addDesignData } = this.props.PIMComponentReducer;

    const {
      saveListener,
      unitData,
      materialEnvironmentData,
      copyDisplayData,
      displayData
    } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignDataMotor">
        {(PIMConfigData.isLoading || addDesignData.isLoading) && <Loader />}

        <CustomTable
          showHeader={true}
          columns={commonDesignDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={
            this.state.displayData &&
            filterCommonDesignData(this.state.displayData)
          }
          editableRow={this.state.editTableRows.PIMCommonDesignData}
        />
        <CustomTable
          showHeader={true}
          displayFlex={true}
          columns={sizingDataTable({
            handleChange: this.handleChange,
            handleChangeMaterialEnvironment: this
              .handleChangeMaterialEnvironment,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            onRemove: this.onRemove,
            triggerDataCopy: this.triggerDataCopy,
            numSegments: !_.isUndefined(copyDisplayData[56]) &&  copyDisplayData[56].design >=1
              ? copyDisplayData[56].design
              : 1,
            saveListener,
            unitData,
            materialEnvironmentData,
            isSchematicEditable,
          })}
          data={filterSizingData(this.state.displayData)}
          editableRow={this.state.editTableRows.PIMSizingData}
        />
        <CustomTable
          showHeader={true}
          displayFlex={true}
          columns={heatTransferDataTable({
            handleChange: this.handleChange,
            handleChangeMaterialEnvironment: this
              .handleChangeMaterialEnvironment,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            onRemove: this.onRemove,
            triggerDataCopy: this.triggerDataCopy,
            numSegments: !_.isUndefined(copyDisplayData[56]) &&  copyDisplayData[56].design >=1
              ? copyDisplayData[56].design
              : 1,
            saveListener,
            unitData,
            materialEnvironmentData,
            isSchematicEditable,
          })}
          data={filterHeatTransferData(this.state.displayData)}
          editableRow={this.state.editTableRows.PIMHeatTransferData}
        />
        <CustomTable
          showHeader={true}
          displayFlex={true}
          columns={ambientTemperatureProfileDataTable({
            handleChange: this.handleChange,
            handleChangeMaterialEnvironment: this
              .handleChangeMaterialEnvironment,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            onRemove: this.onRemove,
            triggerDataCopy: this.triggerDataCopy,
            numSegments:  !_.isUndefined(copyDisplayData[56]) &&  copyDisplayData[56].design >=1
              ? copyDisplayData[56].design
              : 1,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterAmbientTemperatureProfileData(this.state.displayData)}
          editableRow={this.state.editTableRows.PIMAmbientTempProfileData}
        />
        {this.state.formEdit && displayData[56].design >=1 && (
          <div style = {{"width": "100%" , "z-index": "1"}}className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModal()}
        {this.renderCopyDesignDataModal()}
      </div>
    );
  }
}

DesignDataPIMComponent.propTypes = {
  PIMConfigData: PropType.object,
  addDesignData: PropType.object,
  location: PropType.object,
  saveDesignData: PropType.func,
  updateMaterialEnvironment: PropType.func,
  network: PropType.object,
  isSchematicEditable: PropType.bool,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
};

DesignDataPIMComponent.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    MaterialEnvironmentReducer: state.MaterialEnvironmentReducer,
    PIMComponentReducer: state.PIMComponentReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
  updateMaterialEnvironment: (payload) =>
    dispatch(updateMaterialEnvironment(payload)),
  getMaterialEnvironment: () => dispatch(getMaterialEnvironment()),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(DesignDataPIMComponent)
);
