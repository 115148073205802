import { notification } from 'antd';
import 'antd/dist/antd.css';
import { Component } from 'react';
import './Notification.scss';

export const Types = {
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Info: 'info',
};

const key = 'updatable';

class Notification extends Component {
  static show = (type, msg) => {
    type &&
      notification[type]({
        key,
        message: type.charAt(0).toUpperCase() + type.slice(1),
        description: msg,
        duration: 2,
      });
  };
}
export default Notification;
