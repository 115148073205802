import React from 'react';
import _ from 'lodash';
import './TransientData.scss';
import '../../../../constants/global.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import { fetchTransientData, fetchVariableTags, clearState, addTransientData } from '../../../../redux/actions/transientData.action';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import Loader from '../../../../common/Loader/Loader';
import {
  InputDataMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  DISCARD_CHANGES,
  transientDataMessages
} from '../../../../constants/messages';
import {
  inputRenderDataUser,
  inputRenderData,
} from './TransientData.Constants';
import ConfirmModal from '../../../../common/Modal/Modal';
import { ElementPermissions } from '../../../../permissions';
import { elementsGaurd } from '../../../../gaurds';
import { ButtonVariables } from '../../../../constants/variables';
import { TransientDataKeyChain } from "../../../../constants/variables";
import { validateNumericPositiveFloat, validateNonNegativeFloat } from '../../../../common/methods';

class TransientData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialValues(),
      isIQR: false,
      variableTagFetch: [],
      copyVariableTagFetch: [],
      displayVariableName: '',
      resetVariableTagFetch: [],
      displayData: [],
      copydisplayData: [],
      variableNameFetch: [],
      copyVariableNameFetch: [],
      resetVariableNameFetch: [],
      resetData: [],
      editTableRows: [],
      updateData: [],
      formEdit: false,
      addValueListener: false,
      visible: false,
      type: '',
      totalData: [],
      isSave: false,
      enable: false,
      initialDataLength: 0
    };
  }

  initialValues() {
    return {
      displayPiTag: '',
      piTag: '',
      variableName: '',
      analysisType: '',
      curveType: '',
      lookForwardTime: '',
      lookBackTime: '',
      piTagId: '',
      variableNameId: '',
      enable: false,
      analysisTypeList: [
        {
          type: "runUp", name: "Run Up"
        },
        {
          type: "runDown", name: "Run Down"
        },
      ],
      curveTypeList: [
        {
          type: "rise", name: "Rise"
        },
        {
          type: "fall", name: "Fall"
        }
      ]
    };
  }

  componentDidMount() {
    // fetch list of the tags associated with the component
    this.getTransientData();
    // fetch list of all the I/O tags associated with the equipment
    this.getVariableTag();
    window.addEventListener('form-edit-false', (e) => {
      const {
        updateData
      } = this.state;
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.setState({
            ...this.initialValues(),
            displayData: updateData,
            editTableRows: [],
            visible: false,
          });
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabValue } = this.props;
    const {
      variableTagFetch
    } = this.state;

    const {
      isDataFetchSuccess,
      transientData,
      dataAddSuccess,
      dataAddFailure,
      errorMsg,
      isSuccess,
      variableTags,
    } = this.props;

    const { clearState } = this.props;

    if (
      isSuccess ||
      prevState.variableTagFetch !== variableTagFetch ||
      tabValue !== prevProps.tabValue
    ) {
      clearState();
      this.setState({
        variableTagFetch: variableTags,
      });
    }

    if (this.state.formEdit !== prevState.formEdit && tabValue) {
      this.props.onChangeFormEdit(this.state.formEdit);
    }

    if (isDataFetchSuccess && transientData) {
      clearState();
      const tempDisplayData = transientData && transientData?.config?.map((item)=>{
        return {
          analysisType: item.analysisType,
          curveType: item.curveType,
          enable: item.enable,
          lookBackTime: item.lookBackTime,
          lookForwardTime: item.lookForwardTime,
          displayPiTag: item.piTag.piTag,
          variableName: item.piTag.piVariableName,
          piTag: item.piTag,
          piTagId: item.piTag._id,
        }
      });
      this.setState({
        displayData: tempDisplayData || [],
        copydisplayData: tempDisplayData || [],
        initialDataLength: tempDisplayData.length,
        updateData: tempDisplayData || []
      });
    }

    // Successfully added transient data
    if (dataAddSuccess) {
      clearState();
      const { displayData } = this.state;
      // need to map incoming data to display Data
      this.setState(
        {
          isSave: true,
          formEdit: false,
          editTableRows: [],
          copydisplayData: displayData,
          initialDataLength: displayData.length,
          updateData: displayData
        },
        () => this.disableEditForm()
      );
      Notification.show(Types.Success, transientDataMessages.ADDED_SUCCESS);
    }

    if(dataAddFailure)  {
      clearState();
      Notification.show(Types.Error, errorMsg);
    }
  }

  getTransientData() {
    const {
      location: { state },
      fetchTransientData,
    } = this.props;
    if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      state.equipmentData._id
    ) {
      fetchTransientData(state.equipmentData._id, state.componentData.info._id);
    }
  }

  getVariableName() {
    const {
      location: { state },
      variableName,
    } = this.props;
    if (this.handleNetwork() && state && state.componentData.info._id) {
      variableName(state.componentData.info._id, 'input', '');
    }
  }

  getVariableTag() {
    const {
      location: { state },
      fetchVariableTags,
    } = this.props;
    if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      state.equipmentData._id &&
      state.facilityData._id
    ) {
      fetchVariableTags(
        state.equipmentData._id,
        state.componentData.info._id,
        state.facilityData._id
      );
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  setVariableName = (Name) => {
    this.setState(
      {
        variableName: Name
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  setAnalysisData = (type, value) => {
    switch (type) {
      case TransientDataKeyChain.analysisType:
        this.setState({ analysisType: value, formEdit: true });
        break;
      case TransientDataKeyChain.curveType:
        this.setState({ curveType: value, formEdit: true  });
        break;
      case TransientDataKeyChain.lookBackwardTime:
        if(validateNonNegativeFloat(value.value)) {
          this.setState({ lookBackTime: parseFloat(value.value), formEdit: true });
        }
        else if(value.value === '') {
          this.setState({ lookBackTime: '' });
        }
        break;
      case TransientDataKeyChain.lookForwardTime:
        if(validateNonNegativeFloat(value.value))
          this.setState({ lookForwardTime: parseFloat(value.value), formEdit: true  });
        else if(value.value === '')
          this.setState({ lookForwardTime: '', formEdit: true  });
        break;
      case TransientDataKeyChain.enable:
        this.setState({ enable: value, formEdit: true  });
        break;
      default:
        this.setState({ analysisType: value, formEdit: true  })
    }
  };

  setDataTag = (Tag, type = "add", row = null) => {
    this.enableEditForm();
    const {
      variableTagFetch,
      displayData
    } = this.state;
    const tag = variableTagFetch.find((tags) => Tag === tags.piTag);
    if (tag && type === "add") {
      this.setState({
        displayPiTag: Tag,
        piTag: tag._id,
        piTagId: tag._id,
        variableName: tag.piVariableName,
        formEdit: true
      });
    } else if (tag) {
      const tempDisplayData = displayData;
      tempDisplayData[row]["displayPiTag"] = Tag;
      tempDisplayData[row]["piTagId"] = tag._id;
      tempDisplayData[row]["variableName"] = tag.piVariableName;
      this.setState({
        displayData: tempDisplayData,
        formEdit: true
      });
    }
  };

  addValue = () => {
    this.enableEditForm();
    const {
      piTag,
      variableName,
      analysisType,
      curveType,
      lookForwardTime,
      lookBackTime,
      enable,
      displayPiTag,
      piTagId
    } = this.state;
    this.setState({ addValueListener: true });
    if (piTag === '' || variableName === '' || analysisType === '' ||
      curveType === '' || lookForwardTime === '' || lookBackTime === '') {
      Notification.show(Types.Error, transientDataMessages.FIELDS_DIRTY);
    }
    else {
      const onAdd = {
        displayPiTag,
        piTag,
        piTagId,
        variableName,
        analysisType,
        curveType,
        lookBackTime,
        lookForwardTime,
        enable
      };
      
      this.setState(
        (State) => ({
          displayData: [...State.displayData, onAdd],
          ...this.initialValues(),
          addValueListener: false,
          formEdit: true
        }),
        () => {
          this.enableEditForm();
        }
      );
    }
  };

  validatePayloadData = (payload) =>  {
    let res = true;
    payload.forEach((item)=>{
      if(!validateNumericPositiveFloat(parseFloat(item.lookBackTime)) || !validateNumericPositiveFloat(parseFloat(item.lookForwardTime)))  {
        Notification.show(Types.Error, transientDataMessages.FIELDS_DIRTY);
        res = false;
      }
    });
    return res;
  }

  saveData = () => {
    const {
      location: { state },
      addTransientData,
    } = this.props;
    const { tabValue } = this.props;
    const { displayData, totalData } = this.state;
    const tempObject = [...displayData];
    const changeObject = tempObject.map((getRow) => {
      const data = {
        piTag: getRow.piTagId,
        lookBackTime: getRow.lookBackTime,
        lookForwardTime: getRow.lookForwardTime,
        analysisType: getRow.analysisType,
        curveType: getRow.curveType,
        enable: getRow.enable
      };
      if (tabValue) {
        data.type = `stream${tabValue}`;
      }
      return data;
    });
    const tempTotalObject = [...totalData];
    const changeTotalObject = tempTotalObject.map((getRow) => ({
      tag: getRow.piTagId,
      type: getRow.tabValue,
    }));
    const payloadData = tabValue
      ? [...changeObject, ...changeTotalObject]
      : [...changeObject];
    if(this.validatePayloadData(payloadData)) {
      const finalObject = {
        config: payloadData
      };
      if (!changeObject.length) {
        Notification.show(Types.Info, InputDataMessages.MIN_DATA);
      } else if (
        this.handleNetwork() &&
        state &&
        state.componentData.info._id &&
        state.equipmentData._id
      ) {
        addTransientData(
          state.equipmentData._id,
          state.componentData.info._id,
          finalObject
        );
        this.setState({
          ...this.initialValues()
        });
      }
    }
  };

  ResetData = () => {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const {
      transientData
    } = this.props;
    const tempDisplayData = transientData && transientData?.config?.map((item)=>{
      return {
        analysisType: item.analysisType,
        curveType: item.curveType,
        enable: item.enable,
        lookBackTime: item.lookBackTime,
        lookForwardTime: item.lookForwardTime,
        displayPiTag: item.piTag.piTag,
        variableName: item.piTag.piVariableName,
        piTag: item.piTag,
        piTagId: item.piTag._id,
      }
    });
    this.setState({
      displayData: tempDisplayData || [],
      ...this.initialValues(),
      visible: false,
      formEdit: false,
      editTableRows: [],
      addValueListener: false,
    });
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
          title={DISCARD_CHANGES}
        />
      );
    }
    return null;
  }

  editData = (row) => {
    const { editTableRows, updateData, displayData } = this.state;
    const tempCopy = _.cloneDeep(updateData);
    tempCopy[row] = _.cloneDeep(displayData[row]);
    const temp = [...editTableRows];
    temp.push(row);
    temp.sort();

    this.setState(
      {
        editTableRows: temp,
        updateData: _.cloneDeep(tempCopy),
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  undoData = (row) => {
    const { displayData, editTableRows, updateData, initialDataLength } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    displayData[row] = updateData[row];
    this.setState({
      displayData,
      editTableRows: editTable,
      formEdit: (editTable.length === 0 && initialDataLength === displayData.length) ? false : true,
    });
  };

  deleteData = (row, deleterow) => {
    const {
      displayData,
      editTableRows
    } = this.state;

    const displayDataAfterDelete = displayData.filter(
      (_, index) => index !== row
    );
    const editRowsAfterDelete = editTableRows.filter(
      element =>  element!== row
    );
    this.setState(
      {
        displayData: displayDataAfterDelete,
        editTableRows: editRowsAfterDelete,
        formEdit: true
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  setData = (key, value, row) => {
    const { displayData } = this.state;
    const tempDisplayData = displayData;
    if(key === TransientDataKeyChain.lookBackwardTime ||
       key === TransientDataKeyChain.lookForwardTime) {
      if(validateNonNegativeFloat(value)) {
        tempDisplayData[row][key] = parseFloat(value);
      } else if(value === '') {
        tempDisplayData[row][key] = value;
      }
    } else  {
      tempDisplayData[row][key] = value;
    }
    this.setState({
      displayData: tempDisplayData,
      formEdit: true,
    });
  }

  render() {
    const { loading } = this.props.inputDataStateReducer;
    const {
      variableTagFetch,
      variableNameFetch,
      piTag,
      variableName,
      piVariableName,
      variableProperty,
      addValueListener,
      displayData,
      editTableRows,
      displayVariableName,
      analysisTypeList,
      analysisType,
      curveTypeList,
      curveType,
      lookBackTime,
      lookForwardTime,
      enable,
      piTagId,
      displayPiTag,
      initialDataLength
    } = this.state;
    const { facilityData } = this.props.location.state;
    const facilityId = facilityData['_id'];
    const currentDataLength = displayData?.length;
    return (
      <div className="InputData">
        {loading && <Loader />}
        <CustomTable
          showHeader
          columns={inputRenderData({
            isInputEditable: this.props.isSchematicEditable,
            variableTagFetch,
            variableNameFetch,
            displayVariableName,
            analysisTypeList,
            EditData: this.editData,
            UndoData: this.undoData,
            DeleteData: this.deleteData,
            setData: this.setData,
            setDataTag: this.setDataTag,
            curveTypeList,
            editTableRows,
          })}
          editableRow={editTableRows}
          data={displayData}
          renderAddRow={
            this.props.isSchematicEditable
              ? inputRenderDataUser({
                piTag,
                piTagId,
                variableTagFetch,
                displayVariableName,
                setDataTag: this.setDataTag,
                variableName,
                setVariableName: this.setVariableName,
                variableNameFetch,
                setAnalysisData: this.setAnalysisData,
                piVariableName,
                variableProperty,
                addValue: this.addValue,
                addValueListener,
                analysisTypeList,
                analysisType,
                curveTypeList,
                curveType,
                lookBackTime,
                lookForwardTime,
                enable,
                displayPiTag
              })
              : null
          }
        />
        {(this.state.formEdit || currentDataLength > initialDataLength) && this.props.isSchematicEditable
          ? elementsGaurd(
            <div className="config-bottom-buttons">
              <button
                className="btn-default btn-white"
                onClick={() => this.ResetData()}
              >
                {ButtonVariables.RESET}
              </button>
              <button className="btn-default" onClick={() => this.saveData()}>
                {ButtonVariables.SAVE}
              </button>
            </div>,
            ElementPermissions.ADD_INPUT,
            {
              type: 'facility',
              dataRef: {
                _id: facilityId,
              },
            }
          )
          : null}
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transientDataReducer: state.transientDataReducer,
  network: state.NetworkReducer,
  inputDataStateReducer: state.inputDataReducer,
  isDataFetchSuccess: state.transientDataReducer.isTransientDataFetchSuccess,
  transientData: state.transientDataReducer.transientData,
  dataAddSuccess: state.transientDataReducer.isDataAddedSuccess,
  dataAddFailure: state.transientDataReducer.isDataAddedError,
  errorMsg: state.transientDataReducer.errorMsg,
  isSuccess: state.transientDataReducer.isSuccess,
  variableTags: state.transientDataReducer.variableTags,
});

const mapsDispatchToProps = {
  fetchTransientData,
  fetchVariableTags,
  clearState,
  addTransientData
};

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(TransientData)
);
