import React, { Component, Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "./common/Loader/Loader";
import * as Mesasges from "./constants/messages";
import Notification, { Types } from "./common/Notification/Notification";
import { isNeworkConnected } from "./redux/actions/network.action";
import RouterOutlet from "./libs/RouterOutlet";
import ErrorBoundary from "./libs/ErrorBoundary";

import ConfirmModal from "./common/Modal/Modal";
// import { FORM_DISCARD_MSG, DISCARD_CHANGES } from "./constants/messages";
import { updateFormEdit } from "./redux/actions/formEdit.action";
import AuthProvider from "./auth/authProvider";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: true,
      visible: false,
    };
    this.callback = null;
  }

  componentDidMount() {
    /** * Internet Connection Break Handle *** */
    window.addEventListener("offline", this.networkStateOffline.bind(this));
    window.addEventListener("online", this.networkStateOnline.bind(this));

    if (window.navigator.onLine) this.networkStateOnline();
    else this.networkStateOffline();
  }

  networkStateOffline() {
    const { isNeworkConnectedAction } = this.props;
    Notification.show(Types.Error, Mesasges.NO_INTERNET);
    this.setState({ network: false });
    isNeworkConnectedAction();
  }

  networkStateOnline() {
    const { isNeworkConnectedAction } = this.props;
    if (!this.state.network) {
      Notification.show(Types.Success, Mesasges.INTERNET_CONNECTED);
      this.setState({ network: true });
      isNeworkConnectedAction();
    }
  }

  getUserConfirmation(message, callback) {
    this.setState({
      visible: true,
    });
    this.callback = callback;
  }

  handleOk() {
    this.props.updateFormEdit(false);
    this.setState({
      visible: false,
    });
    this.callback(true);
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { isEdit } = this.props;
    const { visible } = this.state;
    return (
      <Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <Router
            getUserConfirmation={(message, callback) =>
              this.getUserConfirmation(message, callback)
            }
          >
            <AuthProvider>
            <RouterOutlet routes={this.props.routes} />
            {isEdit && (
              <ConfirmModal
                visible={visible}
                handleOk={() => this.handleOk()}
                handleCancel={() => this.handleCancel()}
                message={Mesasges.FORM_DISCARD_MSG}
                title={Mesasges.DISCARD_CHANGES}
              />
            )}
            </AuthProvider>
          </Router>
        </ErrorBoundary>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  networkState: state.NetworkReducer,
  isEdit: state.formEditReducer.isEdit,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
  isNeworkConnectedAction: () => dispatch(isNeworkConnected()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
