import { Empty, Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import cardTypeMapper from '../../../../constants/cardTypes';
import { addNewCardMessages, SOCKET_MSG } from '../../../../constants/messages';
import {
  getPlantTime,
  clearPlantState,
} from '../../../../redux/actions/plantTime.action';
import {
  clearAddNewCardState,
  clearDeleteKpiState,
  clearUpdataKpiState,
  deleteKpiById,
  onUpdateKpi,
  updateKPIReducer,
  clearPlotsState,
} from '../../../../redux/AddNewCard/addNewCard.action';
import {
  deleteIDB,
  deleteParticularKPIDATA,
} from '../../../../services/indexedDB';
import {
  NewKPISocketListner,
  RemoveListeningNewKpi,
} from '../../../../socket/alerts/alerts.listner';
import AddNewCard from '../../AddNewCard/AddNewCard';
import { cardTypeConfigMapper } from '../../AddNewCard/AddNewCard.constants';
import {
  clearKPIState,
  getKPIByLevelId,
} from './../../../../redux/actions/addNewCard.action';
import {
  clearPlotState,
  getParticularPlotDataByKPIId,
  getPlotByKPIId,
} from './../../../../redux/actions/plots.action';
import { transformRunCardData, transformXYBGraphData } from './Plots.constants';
import './Plots.scss';
import moment from 'moment';
import { getBatchTime, clearBatchState } from "../../../../redux/actions/plantTime.action";

class Plots extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      visible: false,
      editableCard: null,
      kpiIdToDelete: '',
      plantTime: null,
      callType: 'apiCall',
      count: 0,
      isScaleUpdate: false,
      kpiIdToUpdate: '',
      batchTime: null
    };
  }

  componentDidMount() {
    this.getDashboardInfo('apiCall');
    this.setDataToState();
    NewKPISocketListner(() => {
      Notification.show(Types.Success, SOCKET_MSG.KPI_MSG);
      this.setState(
        {
          sokcetLoader: true,
          dashboardData: [],
        },
        () => {
          this.getDashboardInfo('socket');
          this.setDataToState();
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      info,
      kpiByLevelReducerState,
      deleteKpiReducerState,
      clearDeleteKpiState,
      updateKpiReducerState,
      clearUpdataKpiState,
      AddNewCard,
      clearAddNewCardState,
      plantTimeReducer,
      activeKey,
      batchTimeIsSuccess,
      batchTimeData
    } = this.props;

    if (prevProps.info !== info && activeKey === '3') {
      this.getDashboardInfo('apiCall');
    }
    if (
      this.props.activeKey !== prevProps.activeKey &&
      this.props.activeKey === '3'
    ) {
      this.getDashboardInfo('apiCall');
      this.setDataToState();
    }

    if (
      plantTimeReducer.isSuccess &&
      plantTimeReducer.isSuccess !== prevProps.plantTimeReducer.isSuccess &&
      activeKey === '3' && !kpiByLevelReducerState.isSuccessEdit
    ) {
      const { clearPlantState } = this.props;
      clearPlantState();
      const plantTime = plantTimeReducer.data && plantTimeReducer.data.to;
      this.setState(
        {
          plantTime,
        },
        () => {
          this.getDashboardData();
        }
      );
    }

    if (
      kpiByLevelReducerState.isSuccess &&
      kpiByLevelReducerState.isSuccess !==
        prevProps.kpiByLevelReducerState.isSuccess &&
      activeKey === '3'
    ) {
      const { getPlantTime, getBatchTime } = this.props;
      this.setDataToState();
      getPlantTime();
      getBatchTime();
      clearKPIState();
    }

    if (
      deleteKpiReducerState.isSuccess &&
      deleteKpiReducerState.isSuccess !==
        prevProps.deleteKpiReducerState.isSuccess &&
      activeKey === '3'
    ) {
      this.setDataToState();
      clearDeleteKpiState();
      this.setState({
        kpiIdToDelete: '',
      });
      Notification.show(Types.Success, `${addNewCardMessages['DELETED']}`);
    }
    if (
      deleteKpiReducerState.isError &&
      deleteKpiReducerState.isError !==
        prevProps.deleteKpiReducerState.isError &&
      activeKey === '3'
    ) {
      const { message } = deleteKpiReducerState;
      const { kpiIdToDelete } = this.state;
      if (deleteKpiReducerState.kpiId === kpiIdToDelete) {
        Notification.show(Types.Error, `${message}`);
        clearDeleteKpiState();
        this.setState({
          kpiIdToDelete: '',
        });
      }
    }

    if (
      AddNewCard.isSuccess &&
      AddNewCard.isSuccess !== prevProps.AddNewCard.isSuccess &&
      activeKey === '3'
    ) {
      this.setDataToState();
      clearAddNewCardState();
      const { getPlotByKPIId } = this.props;
      getPlotByKPIId(AddNewCard.data._id, AddNewCard.data, this.state.batchTime);
    }

    if (
      updateKpiReducerState.isSuccess &&
      updateKpiReducerState.isSuccess !==
        prevProps.updateKpiReducerState.isSuccess &&
      activeKey === '3'
    ) {
      const { data } = updateKpiReducerState;
      const { dashboardData } = this.state;
      const { updateKPIReducer } = this.props;
      const id = data._id;
      const index = dashboardData.findIndex((kpi) => kpi._id === id);
      if (index !== -1) {
        let tempd = [...dashboardData];
        tempd[index] = _.cloneDeep(data);
        this.setState(
          { dashboardData: tempd, visible: false, kpiIdToUpdate: id },
          () => {
            updateKPIReducer({ data: tempd });
            clearUpdataKpiState();
          }
        );
      }
      Notification.show(Types.Success, `${addNewCardMessages['UPDATED']}`);
    }

    if (
      kpiByLevelReducerState.isSuccessEdit &&
      kpiByLevelReducerState.isSuccessEdit !==
        prevProps.kpiByLevelReducerState.isSuccessEdit &&
      activeKey === '3'
    ) {
      const {
        clearPlotsState,
        getPlotByKPIId,
      } = this.props;
      const { dashboardData, kpiIdToUpdate } = this.state;
      clearPlotsState();
      const data = dashboardData.find((kpis) => {
        return kpis._id === kpiIdToUpdate;
      });
      if (data) {
        const id = data._id;
        getPlotByKPIId(id, data, this.state.batchTime);
        }
    }
    if(batchTimeIsSuccess && activeKey === '3')  {
      clearBatchState();
      const batchTime = batchTimeData && batchTimeData.to;
      this.setState({
        batchTime: batchTime
      })
    }
  }

  setDataToState = () => {
    const { data } = this.props.kpiByLevelReducerState;
    this.setState({
      dashboardData:
        data &&
        data.data &&
        data.data
          .map((item) => {
            const cardType = item?.cardType;
            if (
              cardType &&
              cardTypeConfigMapper[cardType.cardType] &&
              cardTypeConfigMapper[cardType.cardType]['viewScreen'] === 'plots'
            ) {
              return item;
            }
            return undefined;
          })
          .filter((itemPlot) => itemPlot !== undefined),
    });
  };

  getDashboardInfo(callType) {
    const { info, getKPIByLevelId, getBatchTime } = this.props;
    const { count } = this.state;
    let a = callType === 'socket' && count + 1;
    getBatchTime();
    this.setState(
      {
        callType,
        count: a,
      },
      () => {
        if (info && info.dataRef) {
          getKPIByLevelId(
            info.type,
            info.dataRef._id ? info.dataRef._id : info.dataRef.info._id
          );
        }
      }
    );
  }

  getDashboardData() {
    const { dashboardData } = this.state;
    const { getPlotByKPIId } = this.props;
    if (dashboardData && dashboardData.length) {
      dashboardData.forEach((value) => {
        getPlotByKPIId(value._id, value, this.state.batchTime);
      });
    }
  }

  onDelete = (kpiId) => {
    const { deleteKpiById } = this.props;
    this.setState({ kpiIdToDelete: kpiId }, () => {
      deleteKpiById(kpiId);
      /** Delete data from DB */
      deleteParticularKPIDATA(kpiId);
    });
  };

  onEdit = (kpiId) => {
    const { dashboardData } = this.state;
    let data = dashboardData.find((item) => item._id === kpiId);
    this.setState(
      {
        editableCard: data,
      },
      () => {
        this.setState({ visible: true });
      }
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onTimeChange = (kpiData) => {
    const { getParticularPlotDataByKPIId } = this.props;
    getParticularPlotDataByKPIId(kpiData);
  };

  handleSwitch = (kpiId, value) => {
    const { onUpdateKpi, kpiByLevelReducerState } = this.props;
    const payload = {
      isVisibleToClient: value,
    };
    onUpdateKpi(kpiId, payload, kpiByLevelReducerState.data);
  };

  onSelectScale = (data, manualScale, kpiId) => {
    const { onUpdateKpi } = this.props;
    const yAxisPayload = data.map((item) => {
      let min = '',
        max = '';
      if (manualScale) {
        min = item.minScale;
        max = item.maxScale;
      }
      return {
        minScale: min,
        maxScale: max,
        variableName: item.variableName,
        type: item.type,
        value: item.value,
      };
    });
    const payload = {
      axis: {
        y: yAxisPayload,
      },
      manualScale: manualScale,
    };
    this.setState({ isScaleUpdate: true });
    onUpdateKpi(kpiId, payload);
  };

  zoom = (id, viewDateFrom, viewDateTill, cardType, type) => {
    const { getParticularPlotDataByKPIId } = this.props;
    const dateFrom = moment(viewDateFrom).valueOf() + 1000;
    const dateTill = moment(viewDateTill).valueOf() + 1000;
    const numberOfHours = (dateTill - dateFrom) / 3600 / 1000;
    if (type === 'in') {
      viewDateFrom =
        moment(viewDateFrom)
          .add(numberOfHours / 2, 'hours')
          .valueOf() + 1000;
    } else if (type === 'out') {
      viewDateFrom =
        moment(viewDateFrom).subtract(numberOfHours, 'hours').valueOf() + 1000;
    } else {
      viewDateFrom = moment(viewDateTill).subtract(8, 'hours').valueOf() + 1000;
    }
    cardType = 'Time series plot';
    getParticularPlotDataByKPIId({
      id,
      cardType,
      dateFrom: viewDateFrom,
      dateTill: viewDateTill,
    });
  };

  renderEditCardModal() {
    const { visible, editableCard } = this.state;
    if (visible) {
      const { info } = this.props;
      return (
        <AddNewCard
          visible={visible}
          handleCancel={this.handleCancel}
          info={info}
          cardData={editableCard}
          mode="edit"
        />
      );
    }
  }

  componentWillUnmount() {
    RemoveListeningNewKpi();
    deleteIDB();
  }

  renderXYplotData(item, graph) {
    const { plotsReducerState } = this.props;
    if (
      plotsReducerState.kpisDataState[item._id] &&
      plotsReducerState.kpisDataState[item._id].isSuccess
    ) {
      return (
        <graph.viewGraph
          xlabel={item.axis.x[0].variableName}
          ylabel={item.axis.y[0].variableName}
          xPiTag={item.axis.x[0].value}
          yPiTag={item.axis.y[0].value}
          series={transformXYBGraphData(
            plotsReducerState.kpisDataState[item._id].data,
            item.axis,
          )}
          singleXYPlot={true}
        />
      );
    } else {
      return (
        <div className="div-loader">
          <Spin />
        </div>
      );
    }
  }

  renderXYBaselineplotData(item, graph) {
    const { plotsReducerState } = this.props;
    if (
      plotsReducerState.kpisDataState[item._id] &&
      plotsReducerState.kpisDataState[item._id].isSuccess
    ) {
      let series = []
      const tempSeries =  transformXYBGraphData(
        plotsReducerState.kpisDataState[item._id].data,
        item.axis,
      );
      if(tempSeries.length > 1) {
        let maxLen = tempSeries.length - 1;
        for(let i = 0; i< tempSeries.length; i++) {
          series[i] = tempSeries[maxLen];
          maxLen--;
        }
      } else  { 
        series = [...tempSeries] 
      }
      const componentType = plotsReducerState && plotsReducerState.kpisDataState && plotsReducerState.kpisDataState[item._id] && plotsReducerState.kpisDataState[item._id].data && plotsReducerState.kpisDataState[item._id].data.type
      const xMinScale = componentType && componentType === "Hydraulic Variable Speed Gear" ? 0 : undefined
      return (
        <graph.viewGraph
          xlabel={item.axis.x[0].variableName}
          ylabel={item.axis.y[0].variableName}
          xPiTag={item.axis.x[0].value}
          yPiTag={item.axis.y[0].value}
          series={series}
          baselineXY={true}
          xMinScale={xMinScale}
        />
      );
    } else {
      return (
        <div className="div-loader">
          <Spin />
        </div>
      );
    }
  }

  renderTimeSeriesPlotData(item, graph, runCard) {
    const { plotsReducerState } = this.props;
    if (runCard) {
      return (
        <graph.viewGraph
          id={item._id}
          series={transformRunCardData(
            plotsReducerState.kpisDataState[item._id] &&
              plotsReducerState.kpisDataState[item._id].data &&
              plotsReducerState.kpisDataState[item._id].data.data
              ? plotsReducerState.kpisDataState[item._id].data.data
              : [],
            item.axis
          )}
          runCard
        />
      );
    } else if (
      plotsReducerState.kpisDataState[item._id] &&
      plotsReducerState.kpisDataState[item._id].data
    ) {
      return (
        <graph.viewGraph
          id={item._id}
          ylabel={item.axis.y}
          series={_.cloneDeep(plotsReducerState.kpisDataState[item._id].data)}
          viewDateFrom={_.cloneDeep(
            plotsReducerState.kpisDataState[item._id].viewDateFrom
          )}
          viewDateTill={_.cloneDeep(
            plotsReducerState.kpisDataState[item._id].viewDateTill
          )}
          cardDetails={item}
        />
      );
    }
  }

  render() {
    const {
      // kpiByLevelReducerState,
      plotsReducerState,
      // deleteKpiReducerState,
      // updateKpiReducerState,
      info,
      activeKey,
    } = this.props;
    const { plantTime, dashboardData } = this.state;
    const facilityId =
      info && info.type === 'facility'
        ? info['dataRef']['_id']
        : info && info.type !== 'facility'
        ? info['dataRef']['facilityId']
        : '';
    return (
      activeKey &&
      activeKey === '3' && (
        <div className="Plots">
          {/* {(kpiByLevelReducerState.isLoading ||
          deleteKpiReducerState.isLoading ||
          plotsReducerState.isLoading ||
          updateKpiReducerState.isLoading) &&
          callType === 'apiCall' && <Loader />} */}
          {
            <React.Fragment>
              {dashboardData && dashboardData.length && plotsReducerState?.kpisDataState ? (
                dashboardData.map((item, index) => {
                  if (item) {
                    const { cardType } = item;
                    let graph = cardTypeConfigMapper[cardType.cardType];
                    return graph && graph.viewCard ? (
                      <graph.viewCard
                        id={item._id}
                        key={item._id}
                        title={item.cardTitle}
                        time={plantTime * 1000}
                        delete={this.onDelete}
                        edit={this.onEdit}
                        active={item.isVisibleToClient}
                        cardType={cardType.cardType}
                        changeVisibleStatus={(value) => {
                          this.handleSwitch(item._id, value);
                        }}
                        isLoader={true}
                        onTimeChange={this.onTimeChange}
                        isChangetime={
                          cardType.cardType !==
                          cardTypeMapper['Equipment Run Plot']
                        }
                        updatedAt={
                          plotsReducerState.kpisDataState[item._id]
                            ? plotsReducerState.kpisDataState[item._id].data
                            : ''
                        }
                        height={'fit-content'}
                        cardData={plotsReducerState.kpisDataState[item._id]}
                        isPlots={true}
                        duration={
                          item.duration === 'weeks'
                            ? 2
                            : item.duration === 'days'
                            ? 1
                            : 0
                        }
                        selectScale={this.onSelectScale}
                        cardDetails={item}
                        zoom={this.zoom}
                        facilityId={facilityId}
                      >
                        {graph.viewGraph ? (
                          cardType.cardType ===
                            cardTypeMapper['Time series plot'] ||
                          cardType.cardType ===
                            cardTypeMapper['Equipment Run Plot'] ? (
                            this.renderTimeSeriesPlotData(
                              item,
                              graph,
                              cardType.cardType ===
                                cardTypeMapper['Equipment Run Plot']
                            )
                          ) : (cardType.cardType ===
                            cardTypeMapper["X-Y Plot"] ?(
                            this.renderXYplotData(item, graph)
                          ): (
                            this.renderXYBaselineplotData(item, graph)
                          ))
                        ) : ((
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            imageStyle={{
                              height: 50
                            }}
                            description={
                              <span>
                                <b>ADD CARDS HERE</b>
                              </span>
                            }
                          >
                          </Empty>
                        )
                        )}
                      </graph.viewCard>
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        imageStyle={{
                          height: 50
                        }}
                        description={
                          <span>
                            <b>ADD CARDS HERE</b>
                          </span>
                        }
                      >
                      </Empty>
                    );
                  }
                  return (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 50
                      }}
                      description={
                        <span>
                          <b>ADD CARDS HERE</b>
                        </span>
                      }
                    >
                    </Empty>
                  );
                })
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 50
                  }}
                  description={
                    <span>
                      <b>ADD CARDS HERE</b>
                    </span>
                  }
                >
                </Empty>
              )}
            </React.Fragment>
          }
          {this.renderEditCardModal()}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AddNewCard: state.AddNewCardReducer,
    kpiByLevelReducerState: state.KpiByLevelReducer,
    plotsReducerState: state.PlotsReducer,
    deleteKpiReducerState: state.DeleteKpiByIdReducer,
    updateKpiReducerState: state.UpdateKpiByIdReducer,
    plantTimeReducer: state.plantTimeReducer,
    batchTimeIsSuccess: state.batchTimeReducer.isSuccess,
    batchTimeData: state.batchTimeReducer.data,
    batchTimeIsError: state.batchTimeReducer.isError,
  };
};

const mapDispatchToProps = {
  getKPIByLevelId,
  getPlotByKPIId,
  clearKPIState,
  clearPlotState,
  deleteKpiById,
  clearDeleteKpiState,
  clearUpdataKpiState,
  onUpdateKpi,
  getParticularPlotDataByKPIId,
  clearAddNewCardState,
  getPlantTime,
  updateKPIReducer,
  clearPlotsState,
  clearPlantState,
  getBatchTime,
  clearBatchState
};

export default connect(mapStateToProps, mapDispatchToProps)(Plots);
