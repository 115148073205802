import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import React from 'react';

Boost(Highcharts);

class HighChartsReactOfficial extends React.PureComponent {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        if (props.runCard) {
            this.state = {
                options: {
                    title: null,
                    xAxis: {
                        title: {
                            text: 'Runs',
                        },
                        tickInterval: 1,
                    },
                    yAxis: {
                        visible: false,
                    },
                    chart: {
                        type: 'spline',
                        zoomType: 'x',
                        lineWidth: 0.5,
                        height: 300,
                        resetZoomButton: {
                            theme: {
                                style: {
                                    fontSize: '12px',
                                    fontFamily: 'Rubik, sans-serif',
                                },
                            },
                        },
                    },
                    plotOptions: {
                        series: {
                            cursor: 'crosshair',
                            stickyTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                            marker: {
                                symbol: 'circle',
                            },
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '12.5px',
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: '100',
                        },
                    },
                    series: this.props.series ? this.props.series : [],
                },
            };
        } else if(this.props.xyPlot)    {
            const chart = {
              type: 'scatter',
              events: {
                selection: this.selectPointsByDrag,
              },
              zoomType: 'xy',
              lineWidth: 0.5,
              height: 300,
              marginRight: 20,
              paddingRight: 20,
              resetZoomButton: {
                theme: {
                  style: {
                    fontSize: '12px',
                    fontFamily: 'Rubik, sans-serif',
                  },
                },
              },
            };
            const tempToolTip = {
                    pointFormat: 
                    `
                    <div>
                    <span>{point.time}</span><br>
                    <hr></hr>
                    <span>{point.xPiTag}: <b>{point.x} {point.xunit}</b><br></span> 
                    <span>{point.yPiTag}: <b>{point.y} {point.yunit}</b><br></span>
                    </div>
                    ` ,
                    shared: true,
                    crosshairs: true,
            }
            if (this.props && (this.props?.trends2 || this.props?.xyPlot)) {
                chart.height = '600';
            }
            this.state = {
                options: {
                    title: null,
                    time: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                    chart,
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    plotOptions: {
                        series: {
                            cursor: 'crosshair',
                            stickyTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                            marker: {
                                symbol: 'circle',
                            },
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '12.5px',
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: '100',
                        },
                    },
                    tooltip: tempToolTip,
                    series: [],
                },
            };

        }   else if(this.props.singleXYPlot)    {
            const chart = {
                type: 'scatter',
                zoomType: 'xy',
                lineWidth: 0.5,
                height: 300,
                marginRight: 20,
                paddingRight: 20,
                resetZoomButton: {
                    theme: {
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Rubik, sans-serif',
                        },
                    },
                },
            };
            const tempToolTip = {
                    pointFormat: 
                    `
                    <div>
                    <span>{point.time}</span><br>
                    <hr></hr>
                    <span>{point.xPiTag}: <b>{point.x} {point.xunit}</b><br></span> 
                    <span>{point.yPiTag}: <b>{point.y} {point.yunit}</b><br></span>
                    </div>
                    ` ,
                    shared: true,
                    crosshairs: true,
            }
            this.state = {
                options: {
                    title: null,
                    time: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                    chart,
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    plotOptions: {
                        series: {
                            cursor: 'crosshair',
                            stickyTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                            marker: {
                                symbol: 'circle',
                            },
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '12.5px',
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: '100',
                        },
                    },
                    tooltip: tempToolTip,
                    series: [],
                    xAxis: {
                        startOnTick: true,
                        endOnTick: true,
                        showLastLabel: true
                    },
                },
            };
        }   else if(this.props.baselineXY)   {
            const tempToolTip = {
                    pointFormat: 
                    `
                    <div>
                    <span>{point.time}</span><br>
                    <hr></hr>
                    <span>{point.xPiTag}: <b>{point.x} {point.xunit}</b><br></span> 
                    <span>{point.yPiTag}: <b>{point.y} {point.yunit}</b><br></span>
                    </div>
                    ` ,
                    shared: true,
                    crosshairs: true,
            }
            this.state = {
                options: {
                    title: null,
                    xAxis: {
                        title: {
                            enabled: true,
                            text: 'XAxis'
                        },
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: 'YAxis'
                        },
                    },
                    time: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                    chart: {
                        zoomType: 'xy',
                        lineWidth: 0.5,
                        height: 300,
                        marginRight: 20,
                        paddingRight: 20,
                        resetZoomButton: {
                            theme: {
                                style: {
                                    fontSize: '12px',
                                    fontFamily: 'Rubik, sans-serif',
                                },
                            },
                        },
                    },
                    tooltip: tempToolTip,
                    plotOptions: {
                        series: {
                            cursor: 'crosshair',
                            stickyTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                            marker: {
                                symbol: 'circle',
                            },
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '12.5px',
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: '100',
                        },
                    },
                    series: [],
                },
            };
        }   else {
            const chart = {
              type: 'spline',
              events: {
                selection: this.selectPointsByDrag,
              },
              zoomType: 'x',
              lineWidth: 0.5,
              height: 300,
              marginRight: 20,
              paddingRight: 20,
              resetZoomButton: {
                theme: {
                  style: {
                    fontSize: '12px',
                    fontFamily: 'Rubik, sans-serif',
                  },
                },
              },
            };
            const xAxis = {
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e. %b',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y',
                },
            };
            if (this.props && this.props?.trends2) {
                chart.height = '600';
            }
            if(this.props && this.props.alertPrint === true){
                chart.width='775';
                chart.height = '350';
            }
            if(this.props && this.props.analysisPlots)  {
                chart.height='270';
                delete xAxis['type'];
                delete xAxis['dateTimeLabelFormats'];
            }
            this.state = {
              options: {
                title: null,
                xAxis,
                time: {
                  timezoneOffset: new Date().getTimezoneOffset(),
                },
                chart,
                tooltip: {
                  shared: true,
                  crosshairs: true,
                },
                annotations: [{
                    labels:[{
                        style:  {
                            useHTML: true,
                            fontSize: '12px'
                        }
                    }]
                }],
                plotOptions: {
                  series: {
                    cursor: 'crosshair',
                    stickyTracking: false,
                    states: {
                      hover: {
                        enabled: false,
                      },
                    },
                    marker: {
                      symbol: 'circle',
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                legend: {
                  itemStyle: {
                    fontSize: '12px',
                    fontFamily: 'Rubik, sans-serif',
                    fontWeight: '50',
                    floating: true
                  },
                },
                series: [],
              },
            };
        }
    }

    componentDidMount() {
        const { viewDateFrom, viewDateTill, xyPlot, singleXYPlot, baselineXY,
            analysisPlots } = this.props;
        if (this.props.alertPlot) {
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        ...this.state.options.xAxis,
                        title: {
                            text: 'Time',
                        },
                        visible: true,
                    },
                    yAxis: {
                        title: {
                            text: this.props.yAxisLabel !== 'None' ? this.props.yAxisLabel : '',
                        },
                        visible: true,
                    },
                    series: this.props.series ? this.props.series : [],
                },
            });
        } else if (this.props.runCard) {
            this.setState({
                options: {
                    ...this.state.options,
                    series: this.props.series ? this.props.series : [],
                },
            });
        } else if(xyPlot)   {
            const tempSeries = [...this.props.series];
            const { cardDetails } = this.props;
            cardDetails.axis.series.forEach((i, index)=>{
                tempSeries[index] = { ...tempSeries[index], name: i.seriesTitle || `Series${' '}${index+1}`, turboThreshold: 500000 };
            });
            this.setState({
                options: {
                    ...this.state.options,
                    yAxis: this.multipleyaxis(),
                    xAxis: this.multiplexaxis(),
                    series: tempSeries,
                },
            });
        }
        else if(singleXYPlot)   {
            const { xlabel, ylabel } = this.props;
            const tempSeries = [...this.props.series];
            if(tempSeries.length === 0) {
                tempSeries[0] = { type: 'scatter',xVariableName: xlabel,  yVariableName: ylabel  }
            }
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        ...this.state.options.xAxis,
                        title: {
                            enabled: true,
                            text: xlabel
                        },
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: ylabel
                        },
                    },
                    series: tempSeries,
                },
            });
        } else if(baselineXY)   {
            const { xlabel, ylabel } = this.props;
            const tempSeries = [...this.props.series];
            if(tempSeries.length === 0) {
                tempSeries[0] = { type: 'scatter', xVariableName: xlabel,  yVariableName: ylabel  }
            }
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        title: {
                            enabled: true,
                            text: xlabel
                        },
                    },
                    yAxis: {
                        title: {
                            text: ylabel
                        },
                    },
                    series: tempSeries,
                },
            });
        }
        else if (this.props.series.length) {
            let payload = {
                ...this.state.options,
                yAxis: this.multipleyaxis(),
                series: this.props.series,
                xAxis:  {
                    ...this.state.options.xAxis
                }
            };
            if(!analysisPlots)   {
                payload['xAxis'] = {
                    ...this.state.options.xAxis,
                    min: viewDateFrom,
                    max: viewDateTill,
                };
            }
            this.setState({
                options: payload,
            });
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        const {
 runCard, alertPlot, series, viewDateFrom, viewDateTill, xyPlot, singleXYPlot, baselineXY
} = this.props;

        if (alertPlot && (prevProps.alertPlot !== alertPlot || prevProps.series !== series)) {
            this.setState({
                options: {
                    ...this.state.options,
                    series: series || [],
                },
            });
        } else if (runCard && prevProps.runCard !== runCard) {
            this.setState({
                options: {
                    ...this.state.options,
                    series: series || [],
                },
            });
        } else if (prevProps.series !== series && series.length
            && !runCard && !alertPlot && !xyPlot && !singleXYPlot && !baselineXY) {
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        ...this.state.options.xAxis,
                        min: viewDateFrom,
                        max: viewDateTill,
                    },
                    yAxis: this.multipleyaxis(),
                    series,
                },
            });
        } else if (prevProps.series !== series && xyPlot) {
            const tempSeries = [...this.props.series];
            const { cardDetails } = this.props;
            cardDetails.axis.series.forEach((i, index)=>{
                tempSeries[index] = { ...tempSeries[index], name: i.seriesTitle || `Series${' '}${index+1}`, turboThreshold: 500000 };
            });
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: this.multiplexaxis(),
                    yAxis: this.multipleyaxis(),
                    series: tempSeries,
                },
            });
        }
        else if(prevProps.series !== series && series.length && singleXYPlot)   {
            const { xlabel, ylabel } = this.props;
            const tempSeries = [...this.props.series];
            if(tempSeries.length === 0) {
                tempSeries[0] = { type: 'scatter',xVariableName: xlabel,  yVariableName: ylabel  }
            }
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        ...this.state.options.xAxis,
                        title: {
                            enabled: true,
                            text: xlabel
                        },
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: ylabel
                        },
                    },
                    series: tempSeries,
                },
            });
        }
        else if(prevProps.series !== series && series.length && baselineXY)   {
            const { xlabel, ylabel } = this.props;
            const tempSeries = [...this.props.series];
            if(tempSeries.length === 0) {
                tempSeries[0] = { type: 'scatter',xVariableName: xlabel,  yVariableName: ylabel  }
            }
            this.setState({
                options: {
                    ...this.state.options,
                    xAxis: {
                        title: {
                            enabled: true,
                            text: xlabel
                        },
                    },
                    yAxis: {
                        title: {
                            text: ylabel
                        },
                    },
                    series: tempSeries,
                },
            });
        }
    }

    selectPointsByDrag = (event) => {
        if(event && !event.resetSelection)   {
            const { viewDateFrom, viewDateTill, id, fetchData, metaData, compressedData } = this.props;
            const dateFrom = parseInt(event?.xAxis[0].min);
            const dateTill = parseInt(event?.xAxis[0].max);
            if (((dateTill - dateFrom) / (24 * 3600) / 1000 < 3 && metaData) || 
               ((dateTill - dateFrom) / (24 * 3600) / 1000 < 45 && compressedData)) {
              fetchData(
                id,
                dateFrom,
                dateTill,
                viewDateFrom,
                viewDateTill
              );
              return false;
            }
        }
        return true;
    }

    multipleyaxis = () => {
        const arr = [];
        const { cardDetails, xyPlot, isYAxisVisible, alertPlots } = this.props;
        const scaleData = cardDetails?.axis?.y;
        let index = 0;
        if(xyPlot)  {
            const tagDetails = [];
            let map = new Map();
            cardDetails.axis.series.forEach((item)=>{
                if(!map.has(item.y.value)){
                    map.set(item.y.value, true);
                    tagDetails.push({
                      id: item.y._id,
                      name: `${item.y.variableName} (${item.y.value})`,
                      minScale: item.y.minScale || '',
                      maxScale: item.y.maxScale || '',
                      value: item.y.value,
                      variableName: item.y.variableName,
                      type: item.y.type,
                    });
                }
            });
            map = new Map();
            if(this.props.series.length)    {
                this.props.series.length && this.props.series.forEach((data) => {
                    let min = null;
                    let max = null;
                    tagDetails.forEach((item)=>{
                        if(item.value === data.yPiTag)    {
                            if(item.minScale !== '')  {
                                min = item.minScale;
                            }
                            if(item.maxScale !== '')  {
                                max = item?.maxScale;
                            }
                            let temp = {
                                visible: false,
                                opposite: false,
                                axisTicks: {
                                    show: true,
                                },
                                axisBorder: {
                                    show: false,
                                },
                                title: {
                                    text: data.yVariableName
                                },
                                min,
                                max
                            };
                            if(!map.has(data.yPiTag))    {
                                map.set(data.yPiTag, true);
                                temp['visible'] = isYAxisVisible;
                            }
                            arr.push(temp);
                        }
                    });
                });
            } else  {
                tagDetails.forEach((item)=>{
                        let temp = {
                            visible: false,
                            opposite: false,
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: false,
                            },
                            title: {
                                text: item.variableName
                            },
                        };
                        if(!map.has(item.value))    {
                            map.set(item.value, true);
                            temp['visible'] = isYAxisVisible;
                        }
                        arr.push(temp);
                });
            }
        }
        else {
            this.props.series.length && this.props.series.forEach((data) => {
                let minScale = null;
                let maxScale = null;
                if (scaleData && scaleData[index]) {
                    if (scaleData[index].minScale !== '') {
                        minScale = scaleData[index].minScale;
                    }
                    if (scaleData[index].maxScale !== '') {
                        maxScale = scaleData[index].maxScale;
                    }
                }
                arr.push({
                  visible: alertPlots ? true : false,
                  opposite: false,
                  min: minScale,
                  max: maxScale,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: false,
                  },
                  title: {
                    text: data.name,
                    style: {
                      fontSize: '10px',
                    },
                  },
                  width: '0.001%',
                });
                index++;
            });
        }
        return arr;
    };

    multiplexaxis = () => {
        const arr = [];
        const { xyPlot, series, cardDetails, isXAxisVisible } = this.props;
        const tagDetails = [];
        let map = new Map();
        cardDetails.axis.series.forEach((item)=>{
            if(!map.has(item.x.value)){
                map.set(item.x.value, true);
                tagDetails.push({
                  id: item.x._id,
                  name: `${item.x.variableName} (${item.x.value})`,
                  minScale: item.x.minScale || '',
                  maxScale: item.x.maxScale || '',
                  value: item.x.value,
                  variableName: item.x.variableName,
                  type: item.x.type,
                });
            }
        });
        map = new Map();
        if(xyPlot)  {
            if(series.length)   {
                series.length && series.forEach((data) => {
                    let min = null;
                    let max = null;
                        tagDetails.forEach((item)=>{
                            if(item.value === data.xPiTag)    {
                                if(item.minScale !== '')  {
                                    min = item.minScale;
                                }
                                if(item.maxScale !== '')  {
                                    max = item?.maxScale;
                                }
                                let temp = {
                                    visible: false,
                                    startOnTick: true,
                                    endOnTick: true,
                                    showLastLabel: true,
                                    title: {
                                        text: data.xVariableName
                                    },
                                    min,
                                    max
                                }
                                if(!map.has(data.xPiTag))   {
                                    map.set(data.xPiTag, true);
                                    temp['visible'] = isXAxisVisible;
                                }
                                arr.push(temp);
                            }
                        });
                });
            } else  {
                tagDetails.forEach((item)=>{
                        let temp = {
                            visible: false,
                            startOnTick: true,
                            endOnTick: true,
                            showLastLabel: true,
                            title: {
                                text: item.variableName
                            },
                        }
                        if(!map.has(item.value))   {
                            map.set(item.value, true);
                            temp['visible'] = isXAxisVisible;
                        }
                        arr.push(temp);
                });
            }
        }
        return arr;
    };

    render() {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            ref={this.chartRef}
            options={this.state.options}
          />
        );
    }
}

HighChartsReactOfficial.defaultProps = {
    alertPlot: false,
    runCard: false,
};

export default HighChartsReactOfficial;
