import React from 'react';
import './ListActionHeader.scss';
import HeaderNav from '../HeaderNav/HeaderNav';
import {
  USER_MANAGEMENT_FILTER_HEADING,
  USER_MANAGEMENT_STATUS,
  USER_MANGEMENT_SORT_VALUE,
} from '../../constants/variables';
import { populateInitialValues } from '../methods';

const IS_FILTER_OPEN = -1;

class ListActionHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedIndex: IS_FILTER_OPEN,
      statusFilterValues: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
      statusFilterState: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
      hasFilterActive: false,
    };
  }

  componentDidMount() {
    this.setState({
      statusFilterValues: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
      statusFilterState: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
    });
  }

  onSearch(e) {
    const { config } = this.props;
    config && config.onSearch(e);
  }

  onSearchChange(e) {
    if (e.target.value.toString().length < 1) {
      this.onSearch(e.target.value);
    }
  }

  mapValuesToState = () => {
    this.setState({
      statusFilterState: { ...this.state.statusFilterValues },
    });
  };

  applyFilter = () => {
    const { config } = this.props;
    const obj = this.state.statusFilterState;

    const selectedStatus = Object.keys(obj).find((keys) => obj[keys]);

    if (selectedStatus) {
      this.setState({
        hasFilterActive: true,
      });
    } else {
      this.setState({
        hasFilterActive: false,
      });
    }
    this.setState({
      statusFilterValues: { ...this.state.statusFilterState },
    });
    config && config.filterdata(selectedStatus);
  };

  resetFilter = () => {
    const { config } = this.props;
    this.setState({
      statusFilterValues: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
      statusFilterState: { ...populateInitialValues(USER_MANAGEMENT_STATUS) },
      hasFilterActive: false,
    });
    config && config.filterdata();
  };

  setExpandedIndex = (index) => {
    const expandedIndex =
      index === this.state.expandedIndex ? IS_FILTER_OPEN : index;
    this.setState({ expandedIndex });
  };

  toggleFilter = (filterHeadName, value) => {
    if (filterHeadName === USER_MANAGEMENT_FILTER_HEADING[0]) {
      const filters = { ...populateInitialValues(USER_MANAGEMENT_STATUS) };
      filters[value] = !this.state.statusFilterState[value];
      this.setState({
        statusFilterState: filters,
      });
    }
  };

  render() {
    const { config } = this.props;

    return (
      <div className="ListActionHeader">
        <div className="inputs">
          <HeaderNav
            searchClass="search"
            onSearch={(e) => config && config.onSearch(e)}
            isSort
            onSort={(value) => config && config.order(value)}
            isExpandableFilter
            headLabels={USER_MANAGEMENT_FILTER_HEADING}
            childLabels={[this.state.statusFilterState]}
            onToggleOpen={(isVisible) => {
              if (!isVisible) {
                this.mapValuesToState();
              }
            }}
            onApply={this.applyFilter}
            onReset={this.resetFilter}
            onHeadPress={this.setExpandedIndex}
            onChildPress={this.toggleFilter}
            expandedIndex={this.state.expandedIndex}
            isBtn={this.state.isDiscardBtn}
            hasFilterActive={this.state.hasFilterActive}
            isMultipleSelecet={false}
            sortValue={USER_MANGEMENT_SORT_VALUE}
            sortOrder={config.sortOrder}
            isSortActive={config.isSortActive}
          />
        </div>
        <div className="actions">{config && config.actions}</div>
      </div>
    );
  }
}

export default ListActionHeader;
