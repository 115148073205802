import React from 'react';
import _ from 'lodash';
import './InputData.scss';
import '../../../../constants/global.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import {
  inputData,
  clearInputData,
  variableName,
  variableTag,
  addInputData,
} from '../../../../redux/actions/inputData.action';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import Loader from '../../../../common/Loader/Loader';
import {
  InputDataMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  DISCARD_CHANGES,
} from '../../../../constants/messages';
import {
  inputRenderDataUser,
  inputRenderData,
  none,
  noUnits,
} from './InputData.Constants';
import ConfirmModal from '../../../../common/Modal/Modal';
import { ElementPermissions } from '../../../../permissions';
import { elementsGaurd } from '../../../../gaurds';
import { ButtonVariables } from '../../../../constants/variables';








class InputData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialValues(),
      isIQR: false,
      variableTagFetch: [],
      copyVariableTagFetch: [],
      displayPiTag: '',
      displayVariableName: '',
      resetVariableTagFetch: [],
      displayData: [],
      copydisplayData: [],
      variableNameFetch: [],
      copyVariableNameFetch: [],
      resetVariableNameFetch: [],
      resetData: [],
      editTableRows: [],
      formEdit: false,
      addValueListener: false,
      visible: false,
      type: '',
      totalData: [],
      isSave: false,
    };
  }

  initialValues() {
    return {
      piTag: '',
      variableName: '',
      piVariableName: '',
      variableProperty: '',
      mechademyUnits: '',
      minMultiplier: '',
      maxMultiplier: '',
      _idName: '',
      _idTag: '',
    };
  }

  componentDidMount() {
    this.getInputData();
    this.getVariableName();
    this.getVariableTag();

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener("resize", ()=>{
      document.querySelector('.ant-select-selection').click();
      
    }, false);
    window.addEventListener('form-edit-false', (e) => {
      const {
        resetData,
        resetVariableNameFetch,
        resetVariableTagFetch,
        isSave,
        displayData,
      } = this.state;
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.setState({
            ...this.initialValues(),
            displayData: isSave
              ? _.cloneDeep(displayData)
              : _.cloneDeep(resetData),
            editTableRows: [],
            addValueListener: false,
            variableNameFetch: _.cloneDeep(resetVariableNameFetch),
            variableTagFetch: _.cloneDeep(resetVariableTagFetch),
            isIQR: false,
            type: '',
            visible: false,
            isSave: false,
          });
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabValue } = this.props;
    const {
      displayData,
      copyVariableTagFetch,
      copyVariableNameFetch,
    } = this.state;
    if (
      prevState.displayData !== displayData ||
      prevState.copyVariableTagFetch !== copyVariableTagFetch ||
      prevState.copyVariableNameFetch !== copyVariableNameFetch ||
      tabValue !== prevProps.tabValue
    ) {
      this.setState((State) => ({
        displayPiTag: State.copyVariableTagFetch.filter(
          (tag) => !displayData.some((getRow) => getRow._idTag === tag._idTag)
        ),
        displayVariableName: State.copyVariableNameFetch.filter(
          (name) =>
            !displayData.some((getRow) => getRow._idName === name._idName)
        ),
      }));
    }

    const {
      isAddInputDataSuccess,
      isAddInputDataError,
      errorMessageAddInputData,
      isInputDataFetchSuccess,
      inputData,
      isVariableTagFetchSuccess,
      variableTag,
      variableName,
      isVariableNameFetchSuccess,
    } = this.props.inputDataStateReducer;
    const { clearInputData } = this.props;

    if (isVariableTagFetchSuccess) {
      clearInputData();
      const variableTagData =
        variableTag &&
        variableTag.items.map((tag) => ({
          ...tag,
          _idTag: tag._id,
        }));

      this.setState({
        variableTagFetch: variableTagData,
        copyVariableTagFetch: variableTagData,
      });
    }

    if (this.state.formEdit !== prevState.formEdit && tabValue) {
      this.props.onChangeFormEdit(this.state.formEdit);
    }

    if (isVariableNameFetchSuccess) {
      const variableNameData =
        variableName &&
        variableName.map((name) => ({
          variableName: name.variableName,
          _idName: name._id,
        }));
      clearInputData();
      this.setState({
        variableNameFetch: variableNameData,
        copyVariableNameFetch: variableNameData,
      });
    }
    if (isAddInputDataSuccess) {
      clearInputData();
      this.getInputData();
      this.setState(
        {
          isSave: true,
        },
        () => this.disableEditForm()
      );
      Notification.show(Types.Success, InputDataMessages.SUCCESS);
    }
    if (isAddInputDataError) {
      clearInputData();
      Notification.show(
        Types.Error,
        errorMessageAddInputData.body.code === 'CYCLE_FOUND'
          ? InputDataMessages.CYCLE_FOUND
          : InputDataMessages.ERROR
      );
    }
    if (this.props.tabValue !== prevProps.tabValue) {
      this.setState({
        displayData: [],
      });
    }

    if (isInputDataFetchSuccess || this.props.tabValue !== prevProps.tabValue) {
      const { variableTagFetch, variableNameFetch } = this.state;
      clearInputData();
      const tempData =
        inputData && inputData.config
          ? inputData.config
              .filter(
                (input) =>
                  input &&
                  input.tag &&
                  input.tag._id &&
                  input.abbreviation?._id &&
                  (tabValue ? input.type === `stream${tabValue}` : true)
              )
              .map((initialData) => {
                const {
                  tag: { _id, isIQR, mechademyUnits, piVariableName },
                  abbreviation,
                } = initialData;
                return {
                  ...initialData.tag,
                  _idTag: _id,
                  piVariableName,
                  isIQR,
                  variableName: abbreviation.variableName,
                  _idName: abbreviation._id,
                  mechademyUnits:
                    mechademyUnits === none || mechademyUnits === ''
                      ? noUnits
                      : mechademyUnits,
                };
              })
          : [];
      const tempTotalData =
        inputData && inputData.config
          ? inputData.config
              .filter(
                (input) =>
                  input &&
                  input.tag &&
                  input.tag._id &&
                  input.abbreviation?._id &&
                  input.type !== `stream${tabValue}`
              )
              .map((initialData) => {
                const {
                  tag: { _id, isIQR, piVariableName },
                  abbreviation,
                  type,
                } = initialData;
                return {
                  ...initialData.tag,
                  _idTag: _id,
                  piVariableName,
                  isIQR,
                  variableName: abbreviation.variableName,
                  _idName: abbreviation._id,
                  tabValue: type,
                };
              })
          : [];

      const initialVariableTagList = variableTagFetch.filter(
        (tag) => !tempData.some((getRow) => getRow._idTag === tag._idTag)
      );
      const initialVariableNameList = variableNameFetch.filter(
        (name) => !tempData.some((getRow) => getRow._idName === name._idName)
      );
      this.setState({
        totalData: _.cloneDeep(tempTotalData),
        displayData: _.cloneDeep(tempData),
        resetData:
          this.props.tabValue !== prevProps.tabValue
            ? _.cloneDeep(tempTotalData)
            : _.cloneDeep(tempData),
        variableTagFetch: _.cloneDeep(initialVariableTagList),
        resetVariableTagFetch: _.cloneDeep(initialVariableTagList),
        variableNameFetch: _.cloneDeep(initialVariableNameList),
        resetVariableNameFetch: _.cloneDeep(initialVariableNameList),
      });
    }
  }

  getInputData() {
    const {
      location: { state },
      inputData,
    } = this.props;
    if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      state.equipmentData._id
    ) {
      inputData(state.equipmentData._id, state.componentData.info._id);
    }
  }

  getVariableName() {
    const {
      location: { state },
      variableName,
    } = this.props;
    if (this.handleNetwork() && state && state.componentData.info._id) {
      variableName(state.componentData.info._id, 'input', '');
    }
  }

  getVariableTag() {
    const {
      location: { state },
      variableTag,
    } = this.props;
    if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      state.equipmentData._id &&
      state.facilityData._id
    ) {
      variableTag(
        state.equipmentData._id,
        state.componentData.info._id,
        '',
        state.facilityData._id
      );
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChangeName = (Name) => {
    const { copyVariableNameFetch } = this.state;
    const variableId =
      copyVariableNameFetch &&
      copyVariableNameFetch.find((name) => name.variableName === Name);
    this.setState(
      {
        variableName: Name,
        _idName: variableId ? variableId._idName : '',
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  setDataName = (Name) => {
    this.handleChangeName(Name);
  };

  handleChangeTag = (Tag) => {
    this.enableEditForm();
    const { copyVariableTagFetch, variableTagFetch } = this.state;
    const piTagFetch = copyVariableTagFetch.find((tag) => tag.piTag === Tag);
    const displayTag = variableTagFetch.filter((name) =>
      name.piTag.toUpperCase().includes(Tag.toUpperCase())
    );
    this.setState({
      piTag: Tag,
      variableProperty: piTagFetch ? piTagFetch.variableProperty : '',
      piVariableName: piTagFetch ? piTagFetch.piVariableName : '',
      mechademyUnits: piTagFetch
        ? piTagFetch.mechademyUnits === none || piTagFetch.mechademyUnits === ''
          ? noUnits
          : piTagFetch.mechademyUnits
        : '',
      minMultiplier: piTagFetch ? piTagFetch.minMultiplier : '',
      maxMultiplier: piTagFetch ? piTagFetch.maxMultiplier : '',
      type: piTagFetch ? piTagFetch.type : '',
      _idTag: piTagFetch ? piTagFetch._idTag : '',
      isIQR: piTagFetch ? piTagFetch.isIQR : false,
      displayPiTag: displayTag,
    });
  };

  setDataTag = (Tag) => {
    this.handleChangeTag(Tag);
    this.enableEditForm();
  };

  addValue = () => {
    this.enableEditForm();
    const {
      variableName,
      piTag,
      _idName,
      _idTag,
      piVariableName,
      variableProperty,
      mechademyUnits,
      isIQR,
      minMultiplier,
      maxMultiplier,
      type,
      displayData,
      variableTagFetch,
      variableNameFetch,
    } = this.state;
    this.setState({
      addValueListener: true,
    });
    const onAdd = {
      variableName,
      piTag,
      _idName,
      _idTag,
      piVariableName,
      variableProperty,
      isIQR,
      minMultiplier,
      maxMultiplier,
      type,
      mechademyUnits:
        mechademyUnits === none || mechademyUnits === ''
          ? noUnits
          : mechademyUnits,
    };

    if (onAdd._idName && onAdd._idTag) {
      if (
        !displayData.some((displaycheck) => displaycheck.piTag === onAdd.piTag)
      ) {
        const variableTag = variableTagFetch.filter(
          (tag) => tag._idTag !== _idTag
        );
        const variableName = variableNameFetch.filter(
          (name) => name._idName !== _idName
        );

        this.setState(
          (State) => ({
            displayData: [...State.displayData, onAdd],
            isIQR: false,
            ...this.initialValues(),
            addValueListener: false,
            type: '',
            variableTagFetch: variableTag,
            variableNameFetch: variableName,
          }),
          () => {
            this.enableEditForm();
          }
        );
      } else {
        Notification.show(Types.Error, InputDataMessages.VARIABLETAGUSED);
      }
    } else if (!onAdd._idTag && !onAdd._idName) {
      Notification.show(Types.Error, InputDataMessages.FIELDS_DIRTY);
    } else if (onAdd._idTag) {
      Notification.show(Types.Error, InputDataMessages.ERROR_VARIABLE_NAME);
    } else {
      Notification.show(Types.Error, InputDataMessages.ERROR_VARIABLE_TAG);
    }
  };

  saveData = () => {
    const {
      location: { state },
      addInputData,
    } = this.props;
    const { tabValue } = this.props;
    const { displayData, totalData } = this.state;

    const tempObject = [...displayData];
    const changeObject = tempObject.map((getRow) => {
      const data = {
        tag: getRow._idTag,
        abbreviation: getRow._idName,
      };
      if (tabValue) {
        data.type = `stream${tabValue}`;
      }
      return data;
    });
    const tempTotalObject = [...totalData];
    const changeTotalObject = tempTotalObject.map((getRow) => ({
      tag: getRow._idTag,
      abbreviation: getRow._idName,
      type: getRow.tabValue,
    }));

    const payloadData = tabValue
      ? [...changeObject, ...changeTotalObject]
      : [...changeObject];

    const finalObject = { config: payloadData };
    if (!changeObject.length) {
      Notification.show(Types.Info, InputDataMessages.MIN_DATA);
    } else if (
      this.handleNetwork() &&
      state &&
      state.componentData.info._id &&
      state.equipmentData._id
    ) {
      addInputData(
        state.equipmentData._id,
        state.componentData.info._id,
        finalObject
      );
      this.setState({
        ...this.initialValues(),
        isIQR: false,
      });
    }
  };

  ResetData = () => {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const {
      resetVariableNameFetch,
      resetVariableTagFetch,
      resetData,
    } = this.state;

    this.setState({
      displayData: resetData,
      ...this.initialValues(),
      editTableRows: [],
      isIQR: false,
      type: '',
      visible: false,
      variableNameFetch: _.cloneDeep(resetVariableNameFetch),
      variableTagFetch: _.cloneDeep(resetVariableTagFetch),
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
          title={DISCARD_CHANGES}
        />
      );
    }
    return null;
  }

  editData = (row) => {
    const { editTableRows, copydisplayData, displayData } = this.state;
    const tempCopy = _.cloneDeep(copydisplayData);
    tempCopy[row] = _.cloneDeep(displayData[row]);
    const temp = [...editTableRows];
    temp.push(row);
    temp.sort();

    this.setState(
      {
        editTableRows: temp,
        copydisplayData: _.cloneDeep(tempCopy),
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  undoData = (row) => {
    const { displayData, editTableRows, copydisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    displayData[row] = copydisplayData[row];
    this.setState({
      displayData,
      editTableRows: editTable,
    });
  };

  deleteData = (row, deleterow) => {
    const {
      displayData,
      editTableRows,
      variableTagFetch,
      variableNameFetch,
      copyVariableTagFetch,
      copyVariableNameFetch,
    } = this.state;
    const displayDataOnDelete = displayData.filter(
      (data, index) => index !== row
    );
    const variableTagAppend = copyVariableTagFetch.find(
      (variableTag) => variableTag.piTag === deleterow.piTag
    );
    const variableNameAppend = copyVariableNameFetch.find(
      (variableName) => variableName.variableName === deleterow.variableName
    );
    const editTableModified = editTableRows.map((getRow) => {
      if (getRow === row) {
        return false;
      }
      if (getRow > row) {
        return getRow - 1;
      }
      return getRow;
    });

    this.setState(
      {
        editTableRows: editTableModified,
        displayData: _.cloneDeep(displayDataOnDelete),
        copydisplayData: _.cloneDeep(displayDataOnDelete),
        variableTagFetch: [...variableTagFetch, variableTagAppend],
        variableNameFetch: [...variableNameFetch, variableNameAppend],
      },
      () => {
        this.enableEditForm();
      }
    );
  };

  changeEditIQR = (finalValue, row) => {
    const { displayData } = this.state;
    if (finalValue === true) {
      const getrow = displayData[row];
      if (getrow.type === 'input') {
        if (getrow.maxMultiplier && getrow.minMultiplier) {
          displayData[row].isIQR = finalValue;
          this.setState({
            displayData,
          });
        } else {
          Notification.show(Types.Info, InputDataMessages.NULLMINMAX);
        }
      }
    } else {
      displayData[row].isIQR = finalValue;
      this.setState({
        displayData,
      });
    }
  };

  handleIQR = () => {
    const { type, maxMultiplier, minMultiplier, isIQR } = this.state;
    if (type === 'input' || type === '') {
      if (isIQR === false) {
        if (maxMultiplier && minMultiplier) {
          this.setState((State) => ({ isIQR: !State.isIQR }));
        } else {
          Notification.show(Types.Info, InputDataMessages.NULLMINMAX);
        }
      } else {
        this.setState((State) => ({ isIQR: !State.isIQR }));
      }
    }
  };

  render() {
    const { loading } = this.props.inputDataStateReducer;
    const {
      variableTagFetch,
      variableNameFetch,
      piTag,
      variableName,
      piVariableName,
      variableProperty,
      mechademyUnits,
      isIQR,
      minMultiplier,
      maxMultiplier,
      addValueListener,
      displayData,
      editTableRows,
      displayVariableName,
      displayPiTag,
      _idName,
      _idTag,
    } = this.state;

    const { facilityData } = this.props.location.state;
    const facilityId = facilityData['_id'];

    return (
      <div className="InputData">
        {loading && <Loader />}
        <CustomTable
          showHeader
          columns={inputRenderData({
            isInputEditable: this.props.isSchematicEditable,
            variableTagFetch,
            variableNameFetch,
            displayVariableName,
            displayPiTag,
            EditData: this.editData,
            UndoData: this.undoData,
            DeleteData: this.deleteData,
          })}
          data={displayData}
          renderAddRow={
            this.props.isSchematicEditable
              ? inputRenderDataUser({
                  piTag,
                  _idName,
                  _idTag,
                  handleChangeTag: this.handleChangeTag,
                  displayPiTag,
                  displayVariableName,
                  variableTagFetch,
                  setDataTag: this.setDataTag,
                  variableName,
                  handleChangeName: this.handleChangeName,
                  variableNameFetch,
                  setDataName: this.setDataName,
                  piVariableName,
                  variableProperty,
                  mechademyUnits,
                  isIQR,
                  minMultiplier,
                  maxMultiplier,
                  addValue: this.addValue,
                  addValueListener,
                })
              : null
          }
          editableRow={editTableRows}
        />
        {this.state.formEdit && this.props.isSchematicEditable
          ? elementsGaurd(
              <div className="config-bottom-buttons">
                <button
                  className="btn-default btn-white"
                  onClick={() => this.ResetData()}
                >
                  {ButtonVariables.RESET}
                </button>
                <button className="btn-default" onClick={() => this.saveData()}>
                  {ButtonVariables.SAVE}
                </button>
              </div>,
              ElementPermissions.ADD_INPUT,
              {
                type: 'facility',
                dataRef: {
                  _id: facilityId,
                },
              }
            )
          : null}
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inputDataStateReducer: state.inputDataReducer,
  network: state.NetworkReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  inputData: (equipmentId, componentId) =>
    dispatch(inputData(equipmentId, componentId)),
  clearInputData: () => dispatch(clearInputData()),
  variableName: (componentId, type, keyword) =>
    dispatch(variableName(componentId, type, keyword)),
  variableTag: (equipmentId, componentId, keyword, facilityId) =>
    dispatch(variableTag(equipmentId, componentId, keyword, facilityId)),
  addInputData: (equipmentId, componentId, payload) =>
    dispatch(addInputData(equipmentId, componentId, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(InputData)
);
