import { Divider } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomTable from "../../../../common/CustomTable/CustomTable";
import { UserManagementMessages } from "../../../../constants/messages";
import {
  clearClientState,
  getClientInfo,
} from "../../../../redux/actions/client.action";
import {
  tableheader,
  userManagementTableHeader,
} from "./AddUserBody.Constants";
import "./AddUserBodyTable.scss";

class AddUserBodyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
    };
  }

  componentDidUpdate() {
    const { isClient, clientId, getClientInfo, clearClient } = this.props;

    if (isClient && clientId) {
      this.setState({
        clientId,
      });
      getClientInfo(clientId);
      clearClient();
    }
  }

  render() {
    const {
      clientInfoData,
      multipleRoleSelected,
      role,
      clientId,
      onchangeAccess,
      clientFacilityList,
      userManagementAccess,
    } = this.props;
    const { state } = this.props.location;
    const dataDisplay =
      multipleRoleSelected &&
      multipleRoleSelected.map((selectedRole) => {
        let info = "";
        if (role === "FacilityManager") {
          info =
            clientInfoData &&
            clientInfoData.facilities &&
            clientInfoData.facilities.find((clientInfo) => {
              if (clientInfo._id === selectedRole.data) {
                return true;
              }
              return false;
            });

          return {
            role: info && info.name,
            data: selectedRole.data,
            selectedRead: selectedRole.read,
            selectedWrite: selectedRole.write,
          };
        }
        if (role === "SystemEngineer") {
          info =
            clientInfoData &&
            clientInfoData.systems &&
            clientInfoData.systems.find((clientInfo) => {
              if (clientInfo._id === selectedRole.data) {
                return true;
              }
              return false;
            });
          return {
            role: info && info.systemName,
            data: selectedRole.data,
            selectedRead: selectedRole.read,
            selectedWrite: selectedRole.write,
          };
        }
        if (role === "EquipmentEngineer") {
          info =
            clientInfoData &&
            clientInfoData.equipments &&
            clientInfoData.equipments.find((clientInfo) => {
              if (clientInfo._id === selectedRole.data) {
                return true;
              }
              return false;
            });
          return {
            role: info && info.name,
            data: selectedRole.data,
            selectedRead: selectedRole.read,
            selectedWrite: selectedRole.write,
          };
        }
        if (role === "MechademyFacilityManager") {
          info =
            clientFacilityList &&
            clientFacilityList.find((clientInfo) => {
              if (clientInfo.id === selectedRole.data) {
                return true;
              }
              return false;
            });
          return {
            role: info && info.name,
            data: selectedRole.data,
            selectedDashboardRead: selectedRole.dashboard.read,
            selectedDashboardWrite: selectedRole.dashboard.write,
            selectedSetupRead: selectedRole.setup.read,
            selectedSetupWrite: selectedRole.setup.write,
            selectedMlRead: selectedRole.mlAlgo.read,
            selectedMlWrite: selectedRole.mlAlgo.write,
            selectedPitagRead: selectedRole.piTag.read,
            selectedPiTagWrite: selectedRole.piTag.write,
          };
        }
        return undefined;
      });
    const userManagementDataDisplay =
      role === "MechademyFacilityManager"
        ? [
          {
            role: "Global Access",
            selectedUserMgmtRead: userManagementAccess.userMgmt.read,
            selectedUserMgmtWrite: userManagementAccess.userMgmt.write,
          },
        ]
        : undefined;
    const title =
      role === "MechademyFacilityManager"
        ? UserManagementMessages.ACCESS_TITLE
        : UserManagementMessages.ALERTS_ACCESS_TITLE;
    const userAccessTitle = UserManagementMessages.USER_MGMT_ACCESS;
    return (
      <>
        {((role && clientId && role !== "ClientAdmin") ||
          (role && role === "MechademyFacilityManager")) && (
          <div className="add-user-table">
            <div className="notification">
              {title}
              <Divider />
            </div>
            <div className="customtable">
              <CustomTable
                showHeader
                columns={tableheader({
                  role,
                  editRole: state && state.data.role[0],
                  clientId,
                  onchangeAccess,
                })}
                data={dataDisplay}
              />
            </div>
            {role && role === "MechademyFacilityManager" && (
              <div>
                <div className="notification">
                  <Divider />
                  {userAccessTitle}
                  <Divider />
                </div>
                <div className="customtable">
                  <CustomTable
                    showHeader
                    columns={userManagementTableHeader({
                      role,
                      editRole: state && state.data.role[0],
                      clientId,
                      onchangeAccess,
                    })}
                    data={userManagementDataDisplay}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  facilityState: state.FacilityReducer,
  clientSystemState: state.SystemReducer,
  network: state.NetworkReducer,
  equipmentState: state.EquipmentReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClientInfo: (values) => dispatch(getClientInfo(values)),
  clearClientState: () => dispatch(clearClientState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddUserBodyTable));
