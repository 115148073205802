import React from 'react';
import { Tabs, Empty } from 'antd';
import ConfirmModal from '../../../../common/Modal/Modal';
import Loader from '../../../../common/Loader/Loader';
import './InputDataMapper.scss';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

class InputDataMapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      formEdit: false,
      visible: false,
      tabChange: '',
    };
  }

  componentDidMount() {
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  onTabChange(pane) {
    if (this.state.formEdit) {
      this.setState({
        activeKey: this.state.activeKey,
        tabChange: pane,
        visible: true,
      });
    } else {
      this.setState({
        activeKey: pane,
      });
    }
  }

  onChangeFormEdit = (edit) => {
    this.setState({
      formEdit: edit,
    });
  };

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.setState({
          activeKey: this.state.tabChange,
          tabChange: null,
        });
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
        />
      );
    }
    return null;
  }

  render() {
    const { tabsInfo, isSchematicEditable } = this.props;
    const { loading } = this.props.inputDataStateReducer;
    return (
      <div className="InputDataMapper">
        {loading && <Loader />}
        <div className="TabsStyle">
          <Tabs
            defaultActiveKey="1"
            onChange={(event) => {
              this.onTabChange(event);
            }}
            activeKey={this.state.activeKey}
          >
            {tabsInfo &&
              Object.keys(tabsInfo).map((item, index) => {
                const tabItem = tabsInfo[item];
                return (
                  <TabPane tab={item} key={`${index + 1}`}>
                    {tabItem.screen ? (
                      <tabItem.screen
                        tabValue={this.state.activeKey}
                        isSchematicEditable={isSchematicEditable}
                        onChangeFormEdit={this.onChangeFormEdit}
                      />
                    ) : (
                      <Empty />
                    )}
                  </TabPane>
                );
              })}
          </Tabs>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  inputDataStateReducer: state.inputDataReducer,
  network: state.NetworkReducer,
});

export default connect(mapStateToProps)(InputDataMapper);
