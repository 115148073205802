export const CLEAR_STATE = "CLEAR_STATE";
export const ADD_BYPASS_VALVE_SUCCESS = "ADD_BYPASS_VALVE_SUCCESS";
export const ADD_BYPASS_VALVE_PROGRESS = "ADD_BYPASS_VALVE_PROGRESS";
export const ADD_BYPASS_VALVE_ERROR = "ADD_BYPASS_VALVE_ERROR";

export const GET_EQUIPMENT_TYPE_FETCH_ERROR = "GET_EQUIPMENT_TYPE_FETCH_ERROR";
export const GET_EQUIPMENT_TYPE_FETCH_SUCCESS =
  "GET_EQUIPMENT_TYPE_FETCH_SUCCESS";
export const GET_EQUIPMENT_TYPE_FETCH_PROGRESS =
  "GET_EQUIPMENT_TYPE_FETCH_PROGRESS";

export const GET_MANUFACTURER_FETCH_ERROR = "GET_MANUFACTURER_FETCH_ERROR";
export const GET_MANUFACTURER_FETCH_SUCCESS = "GET_MANUFACTURER_FETCH_SUCCESS";
export const GET_MANUFACTURER_FETCH_PROGRESS =
  "GET_MANUFACTURER_FETCH_PROGRESS";
