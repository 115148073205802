import { UserManagementMessages } from "../../../constants/messages";
import { Password } from "../../../constants/pattern";

export const formFields = (config) => [
  {
    label: "New Password",
    key: "password",
    type: "password",
    required: [true, UserManagementMessages.REQUIRED_FIELD],
    pattern: [Password.regx, Password.message],
    max: 256,
  },
  {
    label: "Confirm Password",
    key: "confirmPassword",
    type: "password",
    required: [true, UserManagementMessages.REQUIRED_FIELD],
    pattern: [Password.regx, Password.message],
    max: 256,
  },
];
