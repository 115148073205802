import { Breadcrumb, Empty, Icon, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ConfirmModal from "../../../common/Modal/Modal";
import "../../../constants/global.scss";
import { MaskStyle } from "../../../constants/react-style";
import * as Routes from "../../../constants/routes";
import { elementsGaurd } from "../../../gaurds";
import { ElementPermissions } from "../../../permissions";
import { updateFormEdit } from "../../../redux/actions/formEdit.action";
import { AssetConfig } from "../../AssetLibrary/Assets.config";
import "./ComponentInformation.scss";

const { TabPane } = Tabs;

class ComponentInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentInfo: {},
      assetInfo: {},
      formEdit: false,
      demandPage: window.location.pathname,
      demandPageData: {},
      message: "",
      activeKey: "1",
      tabChange: null,
      visible: false,
    };
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;

    this.setState({
      componentInfo: state.componentData.componentInfo,
      assetInfo: state.componentData.assetInfo,
    });

    window.addEventListener("form-edit", () => {
      this.props.updateFormEdit(true);
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener("form-edit-false", (e) => {
      this.props.updateFormEdit(false);
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event("form-edit-false"));
  }

  enableEditForm() {
    window.dispatchEvent(new Event("form-edit"));
  }

  componentWillUnmount() {
    this.disableEditForm();
  }

  onTabChange(pane) {
    if (this.state.formEdit || this.props.isEdit) {
      this.setState({
        tabChange: pane,
        activeKey: this.state.activeKey,
        visible: true,
      });
    } else {
      this.setState({
        activeKey: pane,
      });
    }
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = "view";
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { demandPage, demandPageData } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        if (!this.state.tabChange) {
          this.props.history.push(demandPage, demandPageData);
        } else {
          this.setState({
            activeKey: this.state.tabChange,
            tabChange: null,
          });
        }
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
        />
      );
    }
    return null;
  }

  renderBreadCrumb() {
    const {
      clientData,
      facilityData,
      systemData,
      equipmentData,
    } = this.props.location.state;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <label onClick={() => this.navigateToScreen(Routes.clients)}>
            Clients
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData,
                mode: "view",
              })
            }
          >
            {clientData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData,
                facilityData,
                mode: "view",
              })
            }
          >
            {facilityData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientSystem, {
                clientData,
                facilityData,
                systemData,
                mode: "view",
              })
            }
          >
            {systemData.systemName}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientEquipment, {
                clientData,
                facilityData,
                systemData,
                equipmentData,
                mode: "view",
              })
            }
          >
            {equipmentData.name}
          </label>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  render() {
    const { assetInfo } = this.state;
    const {
      clientData,
      facilityData,
      systemData,
      equipmentData,
      schematicData,
    } = this.props.location.state;
    const facilityId = facilityData["_id"];
    return (
      <div className="ComponentInformation">
        <div className="header" style={MaskStyle}>
          <div className="title">
            <Icon
              type="left"
              onClick={() =>
                this.navigateToScreen(Routes.componentSchematic, {
                  clientData,
                  facilityData,
                  systemData,
                  equipmentData,
                  mode: "view",
                })
              }
            />{" "}
            {`${assetInfo.name} Information`}
          </div>
          <div className="path">{this.renderBreadCrumb()}</div>
        </div>
        <div className="screen-content">
          <div className="content-div">
            <div className="tab-type-card">
              <Tabs
                activeKey={this.state.activeKey}
                defaultActiveKey="4"
                type="card"
                onChange={(event) => {
                  this.onTabChange(event);
                }}
              >
                {assetInfo &&
                  assetInfo.type &&
                  Object.keys(AssetConfig[assetInfo.type.name]).map(
                    (item, index) => {
                      const tabInfo = AssetConfig[assetInfo.type.name][item];

                      return (
                        <TabPane tab={item} key={`${index + 1}`}>
                          {tabInfo.screen ? (
                            <tabInfo.screen
                              tabsInfo={tabInfo.children}
                              activeKey={1}       // {this.state.activeKey} changed to 1 to prevent the child active key from being equal to parent active key 
                              isSchematicEditable={
                                schematicData && schematicData.deployed
                                  ? false
                                  : elementsGaurd(
                                    true,
                                    ElementPermissions.EDIT_SCHEMATIC,
                                    {
                                      type: "facility",
                                      dataRef: {
                                        _id: facilityId,
                                      },
                                    }
                                  )
                              }
                            />
                          ) : (
                            <Empty />
                          )}
                        </TabPane>
                      );
                    }
                  )}
              </Tabs>
            </div>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ComponentInformation);
