import React from 'react';
import './FlowrateCurve.scss';
import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CustomTable from '../../../../../../../common/CustomTable/CustomTable';
import { saveCurvesData ,saveCurvesDataReset } from '../../../../../../../redux/actions/controlValve.action';
import {
  curvesTableColumns,
  curvesTableAddRow,
  CharCurveFormFieldOp,
  CharCurveFormFieldTheo,
  curvesLabel,
  graphDetails,
  createCurvesPayload,
  curveInput,
  curveVariables,
} from './FlowrateCurve.Constants';
import Notification, {
  Types,
} from '../../../../../../../common/Notification/Notification';
import FormFields from '../../../../../../../common/FormFields/FormFields';
import ConfirmModal from '../../../../../../../common/Modal/Modal';
import { ButtonVariables } from '../../../../../../../constants/variables';
import {
  NO_INTERNET,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  CurvesMessages,
} from '../../../../../../../constants/messages';
import {
  getDataUnits,
  clearDataUnitState,
} from '../../../../../../../redux/actions/dataUnit.action';
import Loader from '../../../../../../../common/Loader/Loader';
import Line from '../../../../../../../common/Graphs/Line/Line';

class FlowrateCurve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preserveCurveData: {},
      editableRows: { op: [], theo: [] },
      updatedFields: { op: [], theo: [] },
      dirtyRows: { op: [], theo: [] },
      formEdit: false,
      // exhaustDPCurves: [],
      flowrateCurveOp: [],
      flowrateCurveTheo: [],
      addFieldValueOp: {
        valvePosition: '',
        valveFlowrate: '',
      },
      addFieldValueTheo: {
        valvePosition: '',
        valveFlowrate: '',
      },
      isErrorOp: {
        valvePosition: false,
        valveFlowrate: false,
      },
      isErrorTheo: {
        valvePosition: false,
        valveFlowrate: false,
      },
      unitData: [],
      infoVisible: false,
      visibleGraph: false,
      //yAxisValue: '',
      unitMapper: {},
    };
  }
  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  componentDidMount() {
    const { unitData } = this.props.dataUnitState.apiState;
    const { controlValveReducer } = this.props;
    if (
      controlValveReducer?.controlValveConfigData?.curvesData?.curvesData
    ) {
      const { flowrateCurve } = controlValveReducer.controlValveConfigData.curvesData.curvesData;
      if (flowrateCurve) {
        this.setCurveData(flowrateCurve);
      }
    }
    this.setState({
      unitData: unitData && unitData.items ? unitData.items : [],
    });
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { addFlowrateCurve } = this.props.controlValveReducer;

    if (
      addFlowrateCurve.isSuccess &&
      prevProps.controlValveReducer.addFlowrateCurve.isSuccess !==
        addFlowrateCurve.isSuccess
    ) {
      saveCurvesDataReset();
      this.disableEditForm();
      Notification.show(Types.Success, CurvesMessages.ADDED);
    }
    if (
      addFlowrateCurve.isError &&
      prevProps.controlValveReducer.addFlowrateCurve.isError !==
        addFlowrateCurve.isError
    ) {
      Notification.show(Types.Error);
    }
  }

  setCurveData(flowrateCurve) {
    if (flowrateCurve) {
      const { data, dataUnit } = flowrateCurve;
      const { flowrateCurveOp, flowrateCurveTheo } = data;
      const formData = {
        valvePositionOp: dataUnit?.flowrateCurveOp.valvePosition,
        valveFlowrateOp: dataUnit?.flowrateCurveOp.valveFlowrate,
        valvePositionTheo: dataUnit?.flowrateCurveTheo.valvePosition,
        valveFlowrateTheo: dataUnit?.flowrateCurveTheo.valveFlowrate,
      };

      this.setState({
        flowrateCurveOp: [...flowrateCurveOp],
        flowrateCurveTheo: [...flowrateCurveTheo],
      });
      this.props.form.setFieldsValue(formData, () => {
        this.disableEditForm();
      });
    }
  }

  validateFields(values, tablename) {
    let status = true;
    if (tablename === 'flowrateCurveOp') {
      const { isErrorOp } = this.state;
      const temp = { ...isErrorOp };
      Object.keys(temp).forEach((item) => {
        if (
          (!values[item] && values[item] !== 0) ||
          (values[item] &&
            (values[item].length === 0 || values[item].trim().length === 0))
        ) {
          temp[item] = true;
          status = false;
        } else {
          temp[item] = false;
        }
      });
      this.setState({ isErrorOp: temp });
    } else if (tablename === 'flowrateCurveTheo') {
      const { isErrorTheo } = this.state;
      const temp = { ...isErrorTheo };
      Object.keys(temp).forEach((item) => {
        if (
          (!values[item] && values[item] !== 0) ||
          (values[item] &&
            (values[item].length === 0 || values[item].trim().length === 0))
        ) {
          temp[item] = true;
          status = false;
        } else {
          temp[item] = false;
        }
      });
      this.setState({ isErrorTheo: temp });
    }
    return status;
  }

  addRow(tablename) {
    const {
      addFieldValueOp,
      addFieldValueTheo,
      flowrateCurveOp,
      flowrateCurveTheo,
    } = this.state;
    if (tablename === 'flowrateCurveOp') {
      if (this.validateFields(this.state.addFieldValueOp, tablename)) {
        const tableData = flowrateCurveOp;
        const data = [...tableData, { ...addFieldValueOp }];
        this.setState({
          flowrateCurveOp: data,
          addFieldValueOp: {
            ...addFieldValueOp,
            valvePosition: '',
            valveFlowrate: '',
          },
        });
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else if (tablename === 'flowrateCurveTheo') {
      if (this.validateFields(addFieldValueTheo, tablename)) {
        const { addFieldValueTheo } = this.state;
        const tableData = flowrateCurveTheo;
        const data = [...tableData, { ...addFieldValueTheo }];
        this.setState({
          flowrateCurveTheo: data,
          addFieldValueTheo: {
            ...addFieldValueTheo,
            valvePosition: '',
            valveFlowrate: '',
          },
        });
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    }
  }

  getGraphDataOp()
  {
      if( this.state.flowrateCurveOp.length > 0 )
      {
        const flowrateCurveOp = this.state.flowrateCurveOp.map(
          (item) => {
            return [item.valvePosition, item.valveFlowrate];
          }
        );
        return flowrateCurveOp;
      }
      return null;
  }
  getGraphDataTheo()
  {
    if( this.state.flowrateCurveTheo.length > 0 )
    {
      const flowrateCurveTheo = this.state.flowrateCurveTheo.map(
        (item) => {
          return [item.valvePosition, item.valveFlowrate];
        }
      );
      return flowrateCurveTheo;
    }
    return null;
  }

  onAddFieldChange(row, col, field, tablename) {
    const { addFieldValueOp, addFieldValueTheo } = this.state;
    if (tablename === 'flowrateCurveOp') {
      const temp = addFieldValueOp;
      temp[field.name] =
        parseFloat(field.value) === 0
          ? parseFloat(field.value)
          : field.value.replace(/^0+/, '');
      this.setState({ addFieldValueOp: temp }, () => {
        this.validateFields(this.state.addFieldValueOp);
        this.enableEditForm();
      });
    } else if (tablename === 'flowrateCurveTheo') {
      const temp = addFieldValueTheo;
      temp[field.name] =
        parseFloat(field.value) === 0
          ? parseFloat(field.value)
          : field.value.replace(/^0+/, '');
      this.setState({ addFieldValueTheo: temp }, () => {
        this.validateFields(this.state.addFieldValueTheo);
        this.enableEditForm();
      });
    }
  }

  onEditFieldChange(row, col, field, tablename) {
    const tableData =
      tablename === 'flowrateCurveOp'
        ? [...this.state.flowrateCurveOp]
        : [...this.state.flowrateCurveTheo];
    const temp = { ...tableData[row] };
    temp[field.name] =
      parseFloat(field.value) === 0
        ? `${parseFloat(field.value)}`
        : field.value.replace(/^0+/, '');
    const tempCurveData = [...tableData];
    tempCurveData[row] = { ...temp };

    tablename === 'flowrateCurveOp' &&
      this.setState(
        {
          flowrateCurveOp: tempCurveData,
        },
        () => {
          this.setDirtyRows(row, tablename);
        }
      );
    tablename === 'flowrateCurveTheo' &&
      this.setState(
        {
          flowrateCurveTheo: tempCurveData,
        },
        () => {
          this.setDirtyRows(row, tablename);
        }
      );
  }

  setDirtyRows(row, tablename) {
    const { dirtyRows, flowrateCurveOp, flowrateCurveTheo } = this.state;
    const tableData =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(flowrateCurveOp)
        : _.cloneDeep(flowrateCurveTheo);
    const tableDataRow = tableData[row];
    let tempDirtyRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(dirtyRows.op)
        : _.cloneDeep(dirtyRows.theo);
    if (
      !tableDataRow.valvePosition.toString() ||
      !tableDataRow.valvePosition.toString().length ||
      !tableDataRow.valveFlowrate.toString() ||
      !tableDataRow.valveFlowrate.toString().length
    ) {
      tempDirtyRows.push(row);
    } else {
      tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    }
    tablename === 'flowrateCurveOp' &&
      this.setState({
        dirtyRows: { ...dirtyRows, op: tempDirtyRows },
      });
    tablename === 'flowrateCurveTheo' &&
      this.setState({
        dirtyRows: { ...dirtyRows, theo: tempDirtyRows },
      });
  }

  edit(row, tablename) {
    const {
      updatedFields,
      editableRows,
      flowrateCurveOp,
      flowrateCurveTheo,
    } = this.state;
    const tableData =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(flowrateCurveOp)
        : _.cloneDeep(flowrateCurveTheo);
    const updatedTemp =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(updatedFields.op)
        : _.cloneDeep(updatedFields.theo);
    const tempEditRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(editableRows.op)
        : _.cloneDeep(editableRows.theo);
    updatedTemp[row] = _.cloneDeep(tableData[row]);
    tempEditRows.push(row);
    tablename === 'flowrateCurveOp' &&
      this.setState(
        {
          updatedFields: { ...updatedFields, op: _.cloneDeep(updatedTemp) },
          editableRows: { ...updatedFields, op: tempEditRows },
        },
        () => {
          this.enableEditForm();
        }
      );
    tablename === 'flowrateCurveTheo' &&
      this.setState(
        {
          updatedFields: { ...updatedFields, theo: _.cloneDeep(updatedTemp) },
          editableRows: { ...updatedFields, theo: tempEditRows },
        },
        () => {
          this.enableEditForm();
        }
      );
  }

  undo(row, tablename) {
    const {
      updatedFields,
      dirtyRows,
      editableRows,
      flowrateCurveOp,
      flowrateCurveTheo,
    } = this.state;
    const tempTableData =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(flowrateCurveOp)
        : _.cloneDeep(flowrateCurveTheo);
    let tempDirtyRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(dirtyRows.op)
        : _.cloneDeep(dirtyRows.theo);
    let tempupdatedField =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(updatedFields.op)
        : _.cloneDeep(updatedFields.theo);
    let tempEditRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(editableRows.op)
        : _.cloneDeep(editableRows.theo);
    tempTableData[row] = _.cloneDeep(tempupdatedField[row]);
    tempEditRows = tempEditRows.filter((e) => e !== row);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempupdatedField[row] = undefined;
    tablename === 'flowrateCurveOp' &&
      this.setState({
        flowrateCurveOp: tempTableData,
        dirtyRows: { ...dirtyRows, op: tempDirtyRows },
        updatedFields: { ...dirtyRows, op: tempupdatedField },
        editableRows: { ...dirtyRows, op: tempEditRows },
      });
    tablename === 'flowrateCurveTheo' &&
      this.setState({
        flowrateCurveTheo: tempTableData,
        dirtyRows: { ...dirtyRows, theo: tempDirtyRows },
        updatedFields: { ...dirtyRows, theo: tempupdatedField },
        editableRows: { ...dirtyRows, theo: tempEditRows },
      });
  }

  deleteRow(row, tablename) {
    const {
      dirtyRows,
      updatedFields,
      editableRows,
      flowrateCurveOp,
      flowrateCurveTheo,
    } = this.state;
    const tempTableData =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(flowrateCurveOp)
        : _.cloneDeep(flowrateCurveTheo);
    let tempDirtyRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(dirtyRows.op)
        : _.cloneDeep(dirtyRows.theo);
    const tempupdatedField =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(updatedFields.op)
        : _.cloneDeep(updatedFields.theo);
    let tempEditRows =
      tablename === 'flowrateCurveOp'
        ? _.cloneDeep(editableRows.op)
        : _.cloneDeep(editableRows.theo);
    tempTableData.splice(row, 1);
    tempupdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    tempEditRows = tempEditRows.filter((e) => e !== row);
    tablename === 'flowrateCurveOp' &&
      this.setState(
        {
          flowrateCurveOp: tempTableData,
          dirtyRows: { ...dirtyRows, op: tempDirtyRows },
          updatedFields: { ...dirtyRows, op: tempupdatedField },
          editableRows: { ...dirtyRows, op: tempEditRows },
        },
        () => {
          this.enableEditForm();
        }
      );
    tablename === 'flowrateCurveTheo' &&
      this.setState(
        {
          flowrateCurveTheo: tempTableData,
          dirtyRows: { ...dirtyRows, op: tempDirtyRows },
          updatedFields: { ...dirtyRows, op: tempupdatedField },
          editableRows: { ...dirtyRows, op: tempEditRows },
        },
        () => {
          this.enableEditForm();
        }
      );
  }

  save() {
    const {
      location: { state },
    } = this.props;
    const { saveCurvesData } = this.props;
    const { flowrateCurveOp, flowrateCurveTheo, dirtyRows } = this.state;
    this.props.form.validateFields((err, values) => {
      if (err) {
        throw err;
      }
      const lengthCheck =
        dirtyRows.op.length === 0 && dirtyRows.theo.length === 0;
      const curveLength =
      (flowrateCurveTheo.length >= 3 &&
        flowrateCurveOp.length === 0) ||
      (flowrateCurveOp.length >= 3 &&
        flowrateCurveTheo.length >= 3);
      if (!lengthCheck) {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
        return;
      }
      if (!curveLength) {
        Notification.show(Types.Info, CurvesMessages.MIN_DATA_CV);
        return;
      }
      const payload = createCurvesPayload(
        values,
        flowrateCurveOp,
        flowrateCurveTheo
      );
      if (this.handleNetwork() && state.componentData) {
        saveCurvesData(state.componentData.info._id, payload);
      }
    });
  }

  clearDirtyForm() {
    this.setState({
      editableRows: { op: [], theo: [] },
      updatedFields: { op: [], theo: [] },
      dirtyRows: { op: [], theo: [] },
      formEdit: false,
      addFieldValueOp: {
        valvePosition: '',
        valveFlowrate: '',
      },
      addFieldValueTheo: {
        valvePosition: '',
        valveFlowrate: '',
      },
      isErrorOp: {
        valvePosition: false,
        valveFlowrate: false,
      },
      isErrorTheo: {
        valvePosition: false,
        valveFlowrate: false,
      },
    });
  }

  reset() {
    this.setState({
      visible: true,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { controlValveReducer } = this.props;
    const { flowrateCurve } = controlValveReducer.controlValveConfigData.curvesData.curvesData;
    this.setState(
      {
        visible: false,
      },
      () => {
        flowrateCurve && this.setCurveData(flowrateCurve);
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  onChangeSelect(value, key) {
    this.enableEditForm();
  }

  renderGraphModal() {
    if (this.state.visibleGraph) {
      const { yAxisValue } = this.state;
      const flowrateCurveOp = this.getGraphDataOp();
      const flowrateCurveTheo = this.getGraphDataTheo();
      if (yAxisValue === 'flowrateCurveOp' && flowrateCurveOp !== null) {
        return (
          <Modal
            title={graphDetails.title}
            visible={this.state.visibleGraph}
            onOk={() => this.handleOkGraph()}
            onCancel={() => this.handleOkGraph()}
            footer={null}
            className="curve-modal"
          >
            <div>
              <button
                className="btn-default btn-small"
                float="right"
                onClick={() => {
                  this.showGraph();
                }}
              >
                BACK
              </button>
            </div>
            <div className="curve-plot">
              <div style={{ width: 500 }}>
                <Line
                  graphData={flowrateCurveOp}
                  xAxis={graphDetails.xAxis}
                  yAxis={graphDetails.yAxis}
                />
                <span>{graphDetails.flowrateCurveOp}</span>
              </div>
            </div>
          </Modal>
        );
      } else if (yAxisValue === 'flowrateCurveTheo' && flowrateCurveTheo!==null) {
        return (
          <Modal
            title={graphDetails.title}
            visible={this.state.visibleGraph}
            onOk={() => this.handleOkGraph()}
            onCancel={() => this.handleOkGraph()}
            footer={null}
            className="curve-modal"
          >
            <div>
              <button
                className="btn-default btn-small"
                float="right"
                onClick={() => {
                  this.showGraph();
                }}
              >
                BACK
              </button>
            </div>
            <div className="curve-plot">
              <div style={{ width: 500 }}>
                <Line
                  graphData={flowrateCurveTheo}
                  xAxis={graphDetails.xAxis}
                  yAxis={graphDetails.yAxis}
                />
                <span>{graphDetails.flowrateCurveTheo}</span>
              </div>
            </div>
          </Modal>
        );
      }
      else
      {
        Notification.show(Types.Info, graphDetails.requiredMessage);
        return null;
      }
    }
  }

  cancelModal() {
    this.setState({
      yAxisValue: '',
    });
    this.setState({ visibleModal: false });
  }

  handleOkGraph = () => {
    this.setState({ visibleGraph: false });
  };

  onChangeYAxes = (value) => {
    this.setState({ yAxisValue: value });
  };

  onSelectAxes() {
    const { yAxisValue } = this.state;
    const yAxisTag = curveVariables[yAxisValue];
    this.setState({
      yAxisValue: yAxisTag,
      visibleModal: false,
      visibleGraph: true,
    });
  }

  renderCurveInputModal() {
    if (this.state.visibleModal) {
      const data = ['Operational Characteristics', 'Theoretical Characteristics'];
      const { yAxisValue } = this.state;
      return (
        <Modal
          title={'Curve Input'}
          visible={this.state.visibleModal}
          onCancel={() => this.cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(data, yAxisValue, {
              changeY: (e) => {
                this.onChangeYAxes(e);
              },
            })}
            form={this.props.form}
          />
          {
            <div className="ok-button">
              <button
                className="btn-default btn-small"
                onClick={() => {
                  this.onSelectAxes();
                }}
                disabled={yAxisValue === ''}
              >
                OK
              </button>
            </div>
          }
        </Modal>
      );
    }
  }

  showGraph() {
    const { flowrateCurveOp, flowrateCurveTheo } = this.state;
    if (
      flowrateCurveOp.length >= 0 &&
      flowrateCurveTheo.length >= 3
    ) {
      this.setState({
        yAxisValue: '',
        visibleGraph: false,
        visibleModal: true,
      });
    } else {
      Notification.show(Types.Info, graphDetails.requiredMessage);
    }
  }

  renderFlowrateCurve(tablename) {
    const {
      flowrateCurveOp,
      flowrateCurveTheo,
      editableRows,
      isErrorOp,
      isErrorTheo,
      addFieldValueOp,
      addFieldValueTheo,
      unitData,
    } = this.state;
    const { form, isSchematicEditable, process } = this.props;
    return (
      <div>
        <div className="heading-table">
          <div className="type-heading" style={{ width: '100%' }}>
            {tablename === 'flowrateCurveOp'
              ? curvesLabel.operationCharCurve
              : curvesLabel.theoreticalCharCurve}
          </div>
        </div>
        <div>
          {tablename === 'flowrateCurveOp' ? (
            <FormFields
              formFields={CharCurveFormFieldOp(unitData, {
                change: (e, key) => {
                  this.onChangeSelect(e, key);
                },
                isCurveEditable: isSchematicEditable,
              },flowrateCurveOp.length>0)}
              form={form}
            />
          ) : (
            <FormFields
              formFields={CharCurveFormFieldTheo(unitData, {
                change: (e, key) => {
                  this.onChangeSelect(e, key);
                },
                isCurveEditable: isSchematicEditable,
              })}
              form={form}
            />
          )}
        </div>
        <div className="table-curve">
          <CustomTable
            showHeader
            columns={curvesTableColumns({
              isCurveEditable: isSchematicEditable,
              onChange: (row, col, field) => {
                this.onEditFieldChange(row, col, field, tablename);
              },
              edit: (row) => {
                this.edit(row, tablename);
              },
              deleteRow: (row) => {
                this.deleteRow(row, tablename);
              },
              undo: (row) => {
                this.undo(row, tablename);
              },
              process: process,
            })}
            editableRow={
              tablename === 'flowrateCurveOp'
                ? editableRows.op
                : editableRows.theo
            }
            data={
              tablename === 'flowrateCurveOp'
                ? flowrateCurveOp
                : flowrateCurveTheo
            }
            renderAddRow={
              tablename === 'flowrateCurveOp'
                ? isSchematicEditable
                  ? flowrateCurveOp.length < 25
                    ? curvesTableAddRow({
                        onChange: (row, col, field) => {
                          this.onAddFieldChange(row, col, field, tablename);
                        },
                        submit: (e) => {
                          this.addRow(tablename);
                        },
                        isError: isErrorOp,
                      })
                    : null
                  : null
                : isSchematicEditable
                ? flowrateCurveTheo.length < 25
                  ? curvesTableAddRow({
                      onChange: (row, col, field) => {
                        this.onAddFieldChange(row, col, field, tablename);
                      },
                      submit: (e) => {
                        this.addRow(tablename);
                      },
                      isError: isErrorTheo,
                    })
                  : null
                : null
            }
            addFieldValues={
              tablename === 'flowrateCurveOp'
                ? addFieldValueOp
                : addFieldValueTheo
            }
          />
        </div>
      </div>
    );
  }

  render() {
    const dataUnitLoading = this.props.dataUnitState.loading;
    const { controlValveReducer } = this.props;
    return (
      <div className="Curves">
        <div className="CurvesInner">
          {dataUnitLoading || controlValveReducer.isLoading ? <Loader /> : null}
          <div className="curves-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.showGraph();
              }}
            >
              View Curve Plot
            </button>
          </div>
          {this.renderFlowrateCurve('flowrateCurveOp')}

        </div>
        <div className="CurvesInner">
          {dataUnitLoading || controlValveReducer.isLoading ? <Loader /> : null}
          {this.renderFlowrateCurve('flowrateCurveTheo')}

          {this.state.formEdit && this.props.isSchematicEditable ? (
            <div className="config-bottom-buttons">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  this.reset();
                }}
              >
                {ButtonVariables.RESET}
              </button>
              <button
                className="btn-default"
                onClick={() => {
                  this.save();
                }}
              >
                {ButtonVariables.SAVE}
              </button>
            </div>
          ) : null}
          {this.renderModal()}
          {this.renderGraphModal()}
          {this.renderCurveInputModal()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dataUnitState: state.DataUnitReducer,
  controlValveReducer: state.controlValveReducer,
  network: state.NetworkReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getDataUnits,
  clearDataUnitState,
  saveCurvesData: (componentId, payload) =>
    dispatch(saveCurvesData(componentId, payload)),
    saveCurvesDataReset
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(FlowrateCurve))
);
