import CalculatedData from '../Clients/ComponentInformation/CalulatedData/CalculatedData';
import ByPassValue from '../Clients/ComponentInformation/ConfigData/ByPassValue/ByPassValue';
import ConfigData from '../Clients/ComponentInformation/ConfigData/ConfigData';
import CurvesData from '../Clients/ComponentInformation/ConfigData/CurvesData/CurvesData';
import DesignData from '../Clients/ComponentInformation/ConfigData/DesignData/DesignData';
import FlowMeasurement from '../Clients/ComponentInformation/ConfigData/FlowMeasurement/FlowMeasurement';
import FluidComposition from '../Clients/ComponentInformation/ConfigData/FluidComposition/FluidComposition';
import CircuitData from '../Clients/ComponentInformation/ConfigData/Motor/CircuitData/CircuitData';
import DesignDataMotor from '../Clients/ComponentInformation/ConfigData/Motor/DesignData/DesignDataMotor';
import PerformanceCurves from '../Clients/ComponentInformation/ConfigData/Motor/PerformanceCurves/PerformanceCurves';
import PerformanceData from '../Clients/ComponentInformation/ConfigData/Motor/PerformanceData/PerformanceData';
import StartingCharacteristicsCurves from '../Clients/ComponentInformation/ConfigData/Motor/StartingCharacteristicsCurves/StartingCharacteristicsCurves';
import VariableSpeedCurves from '../Clients/ComponentInformation/ConfigData/Motor/VariableSpeedCurves/VariableSpeedCurves';
import RunnerData from '../Clients/ComponentInformation/ConfigData/RunnerData/RunnerData';
import Stream from '../Clients/ComponentInformation/ConfigData/Stream/Stream';
import SurgeLines from '../Clients/ComponentInformation/ConfigData/SurgeLines/SurgeLines';
import DesignDataSynchronousMotor from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/DesignData/DesignDataSynchronousMotor';
import SynchronousMotorParameter from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/MotorParameters/MotorParametersData';
import SynchronousMotorPerformanceCurves from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/PerformanceCurves/PerformanceCurves';
import SynchronousMotorPerformanceData from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/PerformanceData/PerformanceData';
import SynchronousMotorStartingCharacteristicsCurves from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/StartingCharacteristicsCurves/StartingCharacteristicsCurves';
import SynchronousMotorVariableSpeedCurves from '../Clients/ComponentInformation/ConfigData/SynchronousMotor/VariableSpeedCurves/VariableSpeedCurves';
import InputData from '../Clients/ComponentInformation/InputData/InputData';
import InputDataMapper from '../Clients/ComponentInformation/InputData/InputDataMapper';
import OutputData from '../Clients/ComponentInformation/OutputData/OutputData';
import BlankComponentDesignData from '../Clients/ComponentInformation/ConfigData/BlankComponent/DesignData/designDataBlankComponent';
import DesignDataGasTurbine from '../Clients/ComponentInformation/ConfigData/GasTurbine/DesignPerformanceData/DesignDataGasTurbine';
import PerformanceDataGasTurbine from '../Clients/ComponentInformation/ConfigData/GasTurbine/PerformanceData/PerformanceDataGasTurbine';
import GasTurbineFluidComposition from '../Clients/ComponentInformation/ConfigData/GasTurbine/FluidComposition/GasTurbineFluidComposition';
import InletTempCurve from '../Clients/ComponentInformation/ConfigData/GasTurbine/CurvesData/InletTempCurve/InletTempCurve';
import InletLossesCurve from '../Clients/ComponentInformation/ConfigData/GasTurbine/CurvesData/InletLossesCurve/InletLossesCurve';
import ExhaustLossesCurve from '../Clients/ComponentInformation/ConfigData/GasTurbine/CurvesData/ExhaustLossesCurve/ExhaustLossesCurve';
import RelativeHumidityCurve from '../Clients/ComponentInformation/ConfigData/GasTurbine/CurvesData/RelHumidityCurve/RelHumidityCurve';
import PercDegradationCurve from '../Clients/ComponentInformation/ConfigData/GasTurbine/CurvesData/PercDegradationCurve/PercDegradationCurve';
import steamTurbineDesignData from '../Clients/ComponentInformation/ConfigData/SteamTurbine/designData/designData';
import steamTurbinePerformanceData from '../Clients/ComponentInformation/ConfigData/SteamTurbine/PerformanceData/PerformanceData';
import suctionSteamPressureCorrCurve from '../Clients/ComponentInformation/ConfigData/SteamTurbine/correctionCurves/suctionSteamPressureCorrCurve/suctionSteamPressureCorrCurve';
import suctionSteamTempCorrCurve from '../Clients/ComponentInformation/ConfigData/SteamTurbine/correctionCurves/suctionSteamTemperatureCorrCurve/suctionSteamTempCorrCurve';
import exhaustSteamPressureCorrCurve from '../Clients/ComponentInformation/ConfigData/SteamTurbine/correctionCurves/exhaustSteamPressureCorrCurve/exhaustSteamPressureCorrCurve';
import reheatSteamPressureCorrCurve from '../Clients/ComponentInformation/ConfigData/SteamTurbine/correctionCurves/reheatSteamTemperatureCorrCurve/reheatSteamTempCorrCurve';
import reheaterPressureDropCorrCurve from '../Clients/ComponentInformation/ConfigData/SteamTurbine/correctionCurves/reheaterPressureDropCorrCurve/reheaterPressureDropCorrCurve';
import DesignDataSynchronousGenerator from '../Clients/ComponentInformation/ConfigData/SynchronousGenerator/DesignData/DesignDataSynchronousGenerator';
import SynchronousGeneratorParameter from '../Clients/ComponentInformation/ConfigData/SynchronousGenerator/MotorParameters/MotorParametersData';
import SynchronousGeneratorPerformanceCurves from '../Clients/ComponentInformation/ConfigData/SynchronousGenerator/PerformanceCurves/PerformanceCurves';
import SynchronousGeneratorVariableSpeedCurves from '../Clients/ComponentInformation/ConfigData/SynchronousGenerator/VariableSpeedCurves/VariableSpeedCurves';
import SynchronousGeneratorCapabilityCurves from '../Clients/ComponentInformation/ConfigData/SynchronousGenerator/CapabilityCurves/CapabilityCurves';
import DesignDataControlValve from '../Clients/ComponentInformation/ConfigData/ControlValve/DesignData/DesignDataControlValve';
import FlowCoefficientCurve from '../Clients/ComponentInformation/ConfigData/ControlValve/CurvesData/FlowCoefficientCurve/FlowCoefficientCurve';
import FlowrateCurve from '../Clients/ComponentInformation/ConfigData/ControlValve/CurvesData/FlowrateCurve/FlowrateCurve';
import NewCurvesData from '.././Clients/ComponentInformation/ConfigData/NewCurvesData';
import designDataRecipCompressor from '../Clients/ComponentInformation/ConfigData/ReciprocatingCompressors/DesignData/designDataRecipCompressor';
import CurveScreen from '../Clients/ComponentInformation/ConfigData/ReciprocatingCompressors/CurvesData/curveScreen';
import DesignDataPIMComponent from '../Clients/ComponentInformation/ConfigData/PIMComponent/DesignData/PIMComponentDesignData';
import IterationDataPIMComponent from '../Clients/ComponentInformation/ConfigData/PIMComponent/IterationData/PIMComponentIterationData';
import ScrewCompressorConfig from '../Clients/ComponentInformation/ConfigData/ScrewCompressor/DesignData/designDataScrewCompressor';
import capacityControlCurves from '../Clients/ComponentInformation/ConfigData/ScrewCompressor/CurvesData/capacityControlCurves';
import volumetricRatio from '../Clients/ComponentInformation/ConfigData/ScrewCompressor/CurvesData/volumetricRatio';
import TransientData from '../Clients/ComponentInformation/TransientAnalysisData/TransientData';
import AdiabaticEfficiencyCurves from '../Clients/ComponentInformation/ConfigData/ScrewCompressor/CurvesData/adiabaticEfficiencyCurves';
import DesignDataHydraulicVariableSpeedGear from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/DesignData/HydraulicVariableSpeedGearDesignData';
import PerformanceDataHydraulicVariableSpeedGear from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/PerformanceData/HydraulicVariableSpeedGearPerformanceData';
import HydraulicGearboxWorkingFluidData from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/WorkingFluidData/workingFluidData';
import HydraulicGearboxEfficiencyCurves from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/CurvesData/EfficiencyCurves/efficiencyCurves';
import HydraulicGearboxMassFlowCurves from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/CurvesData/MassFlowCurves/massFlowCurves';
import HydraulicGearboxTorqueCurves from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/CurvesData/TorqueCurves/torqueCurves';
import HydraulicGearboxMaxTorqueCurves from '../Clients/ComponentInformation/ConfigData/HydraulicVariableSpeedGear/CurvesData/MaxTorqueCurves/maxTorqueCurves';
import SqlConfigScreen from '../Clients/ComponentInformation/ConfigData/SQLComponentConfig/Config';

export const AssetName = {
  LIQUED_EXPANDER: 'Liquid expanders',
  COMPRESSOR: 'Compressors',
  MOTOR: 'Electric motors',
  PUMP: 'Pumps',
  MIXER: 'Mixers/Splitters',
  SYNCHRONOUS_MOTOR: 'Synchronous motors',
  BLANK_COMPONENT: 'Blank component',
  FLOW_METER: 'Flow meter',
  GAS_TURBINE: 'Gas Turbine',
  STEAM_TURBINE: 'Steam Turbine',
  SYNCHRONOUS_GENERATOR: 'Synchronous generator',
  CONTROL_VALVE: 'Control Valve',
  RECIPROCATING_COMPRESSOR: 'Reciprocating Compressor',
  PIM_COMPONENT:"Pipeline Inventory Management",
  SCREW_COMPRESSOR: 'Screw Compressor',
  HYDRAULIC_VARIABLE_SPEED_GEAR: 'Hydraulic Variable Speed Gear',
  RADIAL_INFLOW_GAS_EXPANDER: 'Radial Inflow Gas Expander',
  GAS_ENGINE: 'Gas Engine',
};

export const AssetConfig = {
  [AssetName.LIQUED_EXPANDER]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Runner Data': { screen: RunnerData },
        'Curves Data': { screen: CurvesData },
        'Design/Guarantee Data': { screen: DesignData },
        'Fluid Composition': { screen: FluidComposition },
        'Bypass Valve': { screen: ByPassValue },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.COMPRESSOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Impeller Data': { screen: RunnerData },
        'Curves Data': { screen: CurvesData },
        'Design/Guarantee Data': { screen: DesignData },
        'Fluid Composition': { screen: FluidComposition },
        'Surge Lines': { screen: SurgeLines },
        'Flow Measurement': { screen: FlowMeasurement },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.MIXER]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Stream 1': { screen: Stream },
        'Stream 2': { screen: Stream },
      },
    },
    'Input Data': {
      screen: InputDataMapper,
      children: {
        'Stream 1': { screen: InputData },
        'Stream 2': { screen: InputData },
      },
    },
    'Output Data': { screen: OutputData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.PUMP]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Impeller Data': { screen: RunnerData },
        'Curves Data': { screen: CurvesData },
        'Design/Guarantee Data': { screen: DesignData },
        'Fluid Composition': { screen: FluidComposition },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.MOTOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignDataMotor },
        'Performance Data': { screen: PerformanceData },
        'Equivalent Circuit Data': { screen: CircuitData },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Performance Curves': { screen: PerformanceCurves },
        'Starting Characteristics Curves': {
          screen: StartingCharacteristicsCurves,
        },
        'Variable Speed Curves': { screen: VariableSpeedCurves },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.SYNCHRONOUS_MOTOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignDataSynchronousMotor },
        'Performance Data': { screen: SynchronousMotorPerformanceData },
        'Motor Parameter': { screen: SynchronousMotorParameter },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Performance Curves': { screen: SynchronousMotorPerformanceCurves },
        'Starting Characteristics Curves': {
          screen: SynchronousMotorStartingCharacteristicsCurves,
        },
        'Variable Speed Curves': {
          screen: SynchronousMotorVariableSpeedCurves,
        },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.BLANK_COMPONENT]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: BlankComponentDesignData },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
  },
  [AssetName.FLOW_METER]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignData },
        'Flow Measurement': { screen: FlowMeasurement },
        'Fluid Composition': { screen: FluidComposition },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.GAS_TURBINE]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': {
          screen: DesignDataGasTurbine,
        },
        'Performance Data': {
          screen: PerformanceDataGasTurbine,
        },
        'Fluid Composition': { screen: GasTurbineFluidComposition },
        'Flow Measurement': { screen: FlowMeasurement },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Inlet Losses Curve': { screen: InletLossesCurve },
        'Exhaust Losses Curve': { screen: ExhaustLossesCurve },
        'Inlet Temperature Curve': { screen: InletTempCurve },
        'Relative Humidity Curve': { screen: RelativeHumidityCurve },
        'Percent Degradation Curve': { screen: PercDegradationCurve },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.STEAM_TURBINE]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': {
          screen: steamTurbineDesignData,
        },
        'Performance Data': {
          screen: steamTurbinePerformanceData,
        },
        'Fluid Composition': { screen: FluidComposition },
      },
    },

    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Design Guarantee Curve': { screen: CurvesData },
        'Suction Steam Pressure Correction Curve': {
          screen: suctionSteamPressureCorrCurve,
        },
        'Suction Steam Temperature Correction Curve': {
          screen: suctionSteamTempCorrCurve,
        },
        'Exhaust Steam Pressure Correction Curve': {
          screen: exhaustSteamPressureCorrCurve,
        },
        'Reheat Steam Temperature Correction Curve': {
          screen: reheatSteamPressureCorrCurve,
        },
        'Reheater Pressure Drop Correction Curve': {
          screen: reheaterPressureDropCorrCurve,
        },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.SYNCHRONOUS_GENERATOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignDataSynchronousGenerator },
        'Generator Parameters': { screen: SynchronousGeneratorParameter },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Efficiency Curve': { screen: SynchronousGeneratorPerformanceCurves },
        'Capability Curve': { screen: SynchronousGeneratorCapabilityCurves },
        'Variable Speed Curves': {
          screen: SynchronousGeneratorVariableSpeedCurves,
        },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.CONTROL_VALVE]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': {
          screen: DesignDataControlValve,
        },

        'Fluid Composition': { screen: FluidComposition },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Flow Coefficient Curve': { screen: FlowCoefficientCurve },
        'Flowrate Curve': { screen: FlowrateCurve },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.RECIPROCATING_COMPRESSOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: designDataRecipCompressor },
        'Fluid Composition': { screen: FluidComposition },
      },
    },
    'Curves Data': {
      screen: NewCurvesData,
      children: {
        'Double Acting': { screen: CurveScreen },
        'Single Acting Crank End': { screen: CurveScreen },
        'Single Acting Head End': { screen: CurveScreen },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.PIM_COMPONENT]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignDataPIMComponent },
        'Fluid Composition': { screen: FluidComposition },
        'Iteration Data': { screen: IterationDataPIMComponent },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.SCREW_COMPRESSOR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: ScrewCompressorConfig },
        'Fluid Composition': { screen: FluidComposition },
      },
    },
    'Curves Data': {
      screen: ConfigData,
      children: {
        'Adiabatic Efficiency': { screen: AdiabaticEfficiencyCurves },
        'Volume Ratio': { screen: volumetricRatio },
        'Capacity Control': { screen: capacityControlCurves },
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },

  [AssetName.HYDRAULIC_VARIABLE_SPEED_GEAR]: {
    'Config Data': {
      screen: ConfigData,
      children: {
        'Design Data': { screen: DesignDataHydraulicVariableSpeedGear },
        'Performance Data': { screen: PerformanceDataHydraulicVariableSpeedGear },
        'Working Fluid Data': { screen: HydraulicGearboxWorkingFluidData },
        // 'Gear Data': { screen: PerformanceDataHydraulicVariableSpeedGear },
      },
    },
    'Curves Data': {
      screen: ConfigData,
      children: {
        'Efficiency': { screen: HydraulicGearboxEfficiencyCurves },
        'Mass Flow': { screen: HydraulicGearboxMassFlowCurves },
        'Torque': { screen: HydraulicGearboxTorqueCurves },
        'Max Torque': {screen: HydraulicGearboxMaxTorqueCurves}
      },
    },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.RADIAL_INFLOW_GAS_EXPANDER]: {
    'Config Data': {screen: SqlConfigScreen},
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
  [AssetName.GAS_ENGINE]: {
    'Config Data': { screen: SqlConfigScreen },
    'Input Data': { screen: InputData },
    'Output Data': { screen: OutputData },
    'Calculated Data': { screen: CalculatedData },
    'Transient Data': { screen: TransientData },
  },
};
