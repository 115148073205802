import  {
    FETCH_TRANSIENT_DATA_CALC_ERROR,
    FETCH_TRANSIENT_DATA_CALC_PROGRESS,
    FETCH_TRANSIENT_DATA_CALC_SUCCESS,
    FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE,
    UPDATE_TRANSIENT_DATA_CALC_PROGRESS,
    UPDATE_TRANSIENT_DATA_CALC_SUCESS,
    UPDATE_TRANSIENT_DATA_CALC_ERROR,
    FETCH_BASELINE_DATA_SUCCESS,
} from "../Types/transientData.types";


const INITIAL_BASELINE_DATA_STATE = {
    isSuccess: false,
    isError: false,
    data: null,
    errorCode: null,
    errorMsg: null,
}

const INITIAL_STATE = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorCode: null,
    errorMsg: null,
    dataUpdateProgress: false,
    dataUpdateSuccess: false,
    calculationData: [],
    selectedCalcData: {},
    baselineDataState:  {},
    dataProcessed: false,
    isRun: false
}


const transientDataCalcReducer = (state = INITIAL_STATE, action) => {
    switch (action.type)    {
        case FETCH_TRANSIENT_DATA_CALC_PROGRESS:
            return  {
                ...state,
                isLoading: true
            }
        case FETCH_TRANSIENT_DATA_CALC_SUCCESS:
            const baselinePayload = {};
            if(action && action.payload)    {
                action.payload.items.forEach(element => {
                    baselinePayload[element._id] = {
                        ...INITIAL_BASELINE_DATA_STATE
                    }
                });
            }
            return  {
                ...state,
                isSuccess: true,
                isLoading: false,
                calculationData: action?.payload,
                baselineDataState: baselinePayload
            }
        case FETCH_TRANSIENT_DATA_CALC_ERROR:
            return {
                ...state,
                isError: true,
                errorMsg: action.payload
            }
        case UPDATE_TRANSIENT_DATA_CALC_PROGRESS:
            return  {
                ...state,
                dataUpdateProgress: true,
                isRun: action.isRun
            }
        case UPDATE_TRANSIENT_DATA_CALC_SUCESS:
            let calcData = state.calculationData;
            const index = calcData.items.findIndex((item)=> item._id === action.payload._id );
            calcData.items[index] = action.payload;
            return  {
                ...state,
                dataUpdateProgress: false,
                dataUpdateSuccess: true,
                calculationData: calcData,
                isRun: false
            }
        case UPDATE_TRANSIENT_DATA_CALC_ERROR:
            return  {
                ...state,
                dataUpdateProgress: false,
                isError: true,
                isRun: false
            }
        case FETCH_BASELINE_DATA_SUCCESS:
            let tempBaselineData = {};
            let baselineAnalysis = {};
            if(action && action.payload)    {
                baselineAnalysis = action.payload.baselineAnalysis;
                tempBaselineData = action.payload.baselineData;
            }
            const calculatedData = state.calculationData;
            const findEntry = (element) => element._id === action.analysisId;
            let entryToUpdate = calculatedData.items.findIndex(findEntry);
            calculatedData.items[entryToUpdate]['baselineAnalysis'] = baselineAnalysis;
            return {
                ...state,
                dataProcessed: true,
                baselineDataState:  {
                    ...state.baselineDataState,
                    [action.analysisId]: {
                        isSuccess: true,
                        data: tempBaselineData?.data
                    }
                },
                calculationData: calculatedData
            }
        case FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: false,
                dataUpdateProgress: false,
                dataUpdateSuccess: false,
                dataProcessed: false
            }
        default:
            return  {
                ...state
            }
    }
}

export default transientDataCalcReducer;