import { Avatar, Card, List } from 'antd';
import 'antd/dist/antd.css';
import Search from 'antd/lib/input/Search';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ic_address } from '../../../assets/ic_address';
import { ic_contract } from '../../../assets/ic_contract';
/* eslint-disable */
import { screen_head_bg } from '../../../assets/screen_head_bg';
import Loader from '../../../common/Loader/Loader';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  ClientListingMessages,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import { ClientListingVariables } from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import NotConnected from '../../../libs/NotConnected';
import { getImageUrl } from '../../../libs/token';
import { ElementPermissions } from '../../../permissions';
import {
  clearClientState,
  clientList,
} from '../../../redux/actions/client.action';
import './ClientListing.scss';

class ClientListing extends React.Component {
  limit = 12;

  constructor(props) {
    super(props);

    this.state = {
      network: true,
      page: 1,
      searchValue: '',
    };
  }

  componentDidMount() {
    this.getClientList();
  }

  componentDidUpdate() {
    const {
      isSuccessFetchClients,
      isError,
      errorCode,
    } = this.props.clientState.apiState;
    const { clearClientState } = this.props;
    if (isSuccessFetchClients) {
      clearClientState();
    }

    if (isError) {
      Notification.show(Types.Error, ClientListingMessages[errorCode]);
      clearClientState();
    }
  }

  getDataSource(dataSource) {
    return dataSource.map((data) => {
      const imagePath = getImageUrl(data.logo);
      return { ...data, imagePath };
    });
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getClientList(page = 1) {
    const { clientListAction } = this.props;
    this.setState({ page });
    if (this.handleNetwork()) {
      clientListAction({
        skip: (page - 1) * this.limit,
        limit: this.limit,
        keyword: this.state.searchValue,
      });
    }
  }

  onSearch(value) {
    const { clientListAction } = this.props;
    this.setState({
      searchValue: value.trim(),
    });
    if (this.handleNetwork()) {
      clientListAction({ limit: this.limit, keyword: value.trim() });
    }
  }

  onSearchChange(value) {
    if (!value) {
      this.onSearch('');
    }
  }

  clientDetail(item) {
    this.props.history.push(Routes.clientInfo, {
      clientData: item,
      mode: 'view',
    });
  }

  clientAdd() {
    this.props.history.push(Routes.clientAdd, { mode: 'add' });
  }

  renderList() {
    const { apiState } = this.props.clientState;
    const dataSource =
      apiState.clientList && apiState.clientList.items
        ? apiState.clientList.items
        : [];
    return (
      <div className="list">
        <List
          grid={{ gutter: 12, column: 2 }}
          itemLayout="vertical"
          size="large"
          dataSource={this.getDataSource(dataSource)}
          pagination={{
            onChange: (page) => {
              this.getClientList(page);
            },
            pageSize: apiState.clientList
              ? apiState.clientList.limit
              : this.limit,
            total: apiState.clientList ? apiState.clientList.total_count : 0,
            current: this.state.page,
            hideOnSinglePage: true,
          }}
          renderItem={(item) => (
            <a>
              <List.Item key={item.clientId}>
                <Card
                  onClick={() => {
                    this.clientDetail(item);
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={item.imagePath} />}
                    title={item.name}
                  />
                  <div className="info">
                    <div className="ic_contact_container_1">
                      <div className="ic_contact_img_container">
                        {item.contractNo ? (
                          <img src={ic_contract} className="ic_contact_img" />
                        ) : null}
                      </div>
                      <div className="ic_contact_details">
                        <p className="text-over">{item.contractNo}</p>
                      </div>
                    </div>
                    <div className="ic_contact_container_2">
                      <div className="ic_contact_img_container">
                        <img src={ic_address} className="ic_contact_img" />
                      </div>
                      <div className="ic_contact_details">
                        <p className="text-over">{item.address1}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </List.Item>
            </a>
          )}
        />
      </div>
    );
  }

  render() {
    const { loading } = this.props.clientState;
    return (
      <div className="ClientListing">
        {/* {loading ? <Loader /> : null} */}
        <div className="Mask" style={headBgStyle}>
          <div className="Clients">{ClientListingVariables.TITLE}</div>
          <div className="page-action">
            <Search
              placeholder={ClientListingVariables.SEARCH_HERE}
              onSearch={(value) => {
                this.onSearch(value);
              }}
              onChange={(e) => {
                this.onSearchChange(e.target.value);
              }}
              className="search-bar"
              allowClear
            />
            {elementsGaurd(
              <button className="btn-default" onClick={() => this.clientAdd()}>
                {ClientListingVariables.ADD_CLIENT}
              </button>,
              ElementPermissions.ADD_CLIENT
            )}
          </div>
        </div>
        <div className="content-body">
          {loading ? null : this.state.network ? (
            this.renderList()
          ) : (
            <NotConnected />
          )}
        </div>
      </div>
    );
  }
}

const headBgStyle = {
  background: `url(${screen_head_bg}) no-repeat center`,
  backgroundSize: '101% 100%',
};

const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  clientListAction: (values) => dispatch(clientList(values)),
  clearClientState: () => dispatch(clearClientState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientListing));
