export const FACILITY_FETCH_SUCCESS = "FACILITY_FETCH_SUCCESS";
export const FACILITY_FETCH_ERROR = "FACILITY_FETCH_ERROR";
export const FACILITY_FETCH_PROGRESS = "FACILITY_FETCH_PROGRESS";
export const FACILITY_CLEAR_STATE = "FACILITY_CLEAR_STATE";

export const ADD_FACILITY_FETCH_SUCCESS = "ADD_FACILITY_FETCH_SUCCESS";
export const ADD_FACILITY_FETCH_PROGRESS = "ADD_FACILITY_FETCH_PROGRESS";
export const ADD_FACILITY_FETCH_ERROR = "ADD_FACILITY_FETCH_ERROR";

export const EDIT_FACILITY_FETCH_SUCCESS = "EDIT_FACILITY_FETCH_SUCCESS";
export const EDIT_FACILITY_FETCH_PROGRESS = "EDIT_FACILITY_FETCH_PROGRESS";
export const EDIT_FACILITY_FETCH_ERROR = "EDIT_FACILITY_FETCH_ERROR";

export const FETCH_ALL_FACILITY_PROGRESS = "ALL_FACILITY_FETCH_PROGRESS";
export const FETCH_ALL_FACILITY_SUCCESS = "ALL_FACILITY_FETCH_SUCCESS";
export const FETCH_ALL_FACILITY_ERROR = "ALL_FACILITY_FETCH_ERROR";
