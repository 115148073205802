import React from 'react';
import { Icon, Input } from 'antd';
import {
  newValidateNumber,
  validatePositiveNumber,
} from '../../../../../../../libs/validator';

export const curveTitle = 'Mass Flow Curve';

export const curveTitleKey = 'massFlowCurve';

export const identifier = 'Pump Speed';
export const identifierKey = 'pumpSpeed';

const DataColumsVariables = {
  speedRatio: 'Speed Ratio',
  workingFluidMassFlow: 'Working Fluid Mass Flow',
  differentialPressure: 'Differential Pressure',
  guideVanePosition: 'Guide Vane Position',
};

export const defaultIsErrorObject = {
  speedRatio: false,
  workingFluidMassFlow: false,
  differentialPressure: false,
  guideVanePosition: false,
};

export const DataColumKeyChange = {
  [DataColumsVariables.speedRatio]: 'speedRatio',
  [DataColumsVariables.workingFluidMassFlow]: 'workingFluidMassFlow',
  [DataColumsVariables.differentialPressure]: 'differentialPressure',
  [DataColumsVariables.guideVanePosition]: 'guideVanePosition',
};

export const DataColumnsTitle = [
  DataColumsVariables.speedRatio,
  DataColumsVariables.workingFluidMassFlow,
  DataColumsVariables.differentialPressure,
  DataColumsVariables.guideVanePosition,
];

const DataColumnsAcceptingNegativeInput = ['guideVanePosition'];

export const isCurveTypeSelectionEnabled = false;

export const defaultIdentifierData = {
  design: 0,
  unit: 'rps',
};

const getDefaultAddFieldValues = () => {
  let defaulValues = {};
  const keys = Object.values(DataColumKeyChange);
  keys.forEach((item) => {
    defaulValues[item] = '';
  });
  return defaulValues;
};

const getDefaultIsErrorValues = () => {
  let defaulValues = {};
  const keys = Object.values(DataColumKeyChange);
  keys.forEach((item) => {
    defaulValues[item] = false;
  });
  return defaulValues;
};

export const defaultAddFieldValues = getDefaultAddFieldValues();

export const defaultIsErrorValues = getDefaultIsErrorValues();

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

const getNumberInput = (ColumnName, row, col, value, editable, config) => {
  return (
    <Input
      name={ColumnName}
      defaultValue={value}
      value={value}
      type="number"
      onChange={(e) => {
        config.onChange(row, col, e.target);
      }}
      disabled={editable}
      onKeyDown={(e) => {
        if (DataColumnsAcceptingNegativeInput.includes(ColumnName)) {
          newValidateNumber(e);
        } else {
          validatePositiveNumber(e);
        }
      }}
      className={!value && value !== 0 ? 'error' : 'not-error'}
    />
  );
};

export const HydraulicGearboxCurveFormField = (
  units,
  identifierData,
  unitsValues,
  config
) => {
  const identifierValue = identifierData.design;
  const identifierUnit = identifierData.unit;
  let hydraulicGearboxFormFields = [
    {
      label: identifier,
      key: curveTitleKey + identifierKey,
      type: 'textWithDrop',
      input: 'number',
      value: identifierValue ? identifierValue : 0,
      dropDownKey: curveTitleKey + identifierKey + '_unit',
      dropDownData: getUnit(units, identifier),
      dropDownDefaultValue: identifierUnit
        ? identifierUnit
        : getUnit(units, identifier)[0],
      dropDownchange: (e) => {
        config.change(e, 'identifierUnit');
      },
      change: (e) => {
        config.change(e, 'identifierValue');
      },
      onKeyDown: (e) => {
        validatePositiveNumber(e);
      },
      disabled: !config.isCurveEditable,
    },
  ];

  const unitsFormList = DataColumnsTitle.map((item) => {
    return {
      label: item,
      key: curveTitleKey + DataColumKeyChange[item],
      type: 'dropdown',
      required: [true, ''],
      value:
        unitsValues && unitsValues[DataColumKeyChange[item]]
          ? unitsValues[DataColumKeyChange[item]]
          : getUnit(units, item)[0],
      data: getUnit(units, item),
      change: (e) => {
        config.change(e, DataColumKeyChange[item]);
      },
      disabled: !config.isCurveEditable,
    };
  });

  hydraulicGearboxFormFields = hydraulicGearboxFormFields.concat(unitsFormList);

  return hydraulicGearboxFormFields;
};

const getActionCoulum = (config) => {
  return {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  };
};

export const curvesTableColumns = (config) => {
  let dataColumns = DataColumnsTitle.map((item) => {
    return {
      title: item,
      dataIndex: DataColumKeyChange[item],
      render: (value, row, col, editable) => {
        return getNumberInput(
          DataColumKeyChange[item],
          row,
          col,
          value,
          editable,
          config
        );
      },
    };
  });

  const action = getActionCoulum(config);

  dataColumns.push(action);

  return dataColumns;
};

const getAddRowInput = (curveName, row, col, value, actions) => {
  return (
    <Input
      name={curveName}
      type="number"
      defaultValue={value}
      onChange={(e) => {
        actions.onChange(row, col, e.target);
      }}
      value={value}
      className={actions.isError[curveName] ? 'error' : 'not-error'}
      onKeyDown={(e) => {
        if (DataColumnsAcceptingNegativeInput.includes(curveName)) {
          newValidateNumber(e);
        } else {
          validatePositiveNumber(e);
        }
      }}
    />
  );
};

export const curvesTableAddRow = (actions) => {
  let dataColumns = DataColumnsTitle.map((item) => {
    return {
      title: item,
      dataIndex: DataColumKeyChange[item],
      render: (value, row, col, editable) => {
        return getAddRowInput(
          DataColumKeyChange[item],
          row,
          col,
          value,
          actions
        );
      },
    };
  });

  dataColumns.push({
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  });

  return dataColumns;
};

export const curveInput = (data, yAxisValue, xAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'X');
      },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'Y');
      },
    },
  ];
};
