import {
  DATA_UNIT_CLEAR_STATE,
  DATA_UNIT_FETCH_ERROR,
  DATA_UNIT_FETCH_PROGRESS,
  DATA_UNIT_FETCH_SUCCESS,
} from "../Types/dataUnit.types";

import { POSTRequest } from "../../api/request";

export function getDataUnits(payload) {
  return function (dispatch) {
    dispatch({ type: DATA_UNIT_FETCH_PROGRESS });
    POSTRequest("dataunits", { dataVariables: payload })
      .then((res) => {
        dispatch({ type: DATA_UNIT_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: DATA_UNIT_FETCH_ERROR, payload: err });
      });
  };
}

export function clearDataUnitState() {
  return function (dispatch) {
    dispatch({ type: DATA_UNIT_CLEAR_STATE });
  };
}
