import * as Type from "../Types/Stream.type";

const INITIAL_STATE = {
  loading: false,
  streamData: "",
  isStreamDataSuccess: false,
  isStreamDataError: false,
  errorStreamDataFetch: "",
  isAddStreamDataSuccess: false,
  isAddStreamDataError: false,
  errorAddStreamDataFetch: "",
  errorCode: "",
};

export default function streamReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.STREAM_FETCH_PROGRESS:
      return { ...state, loading: true };

    case Type.STREAM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        isStreamDataSuccess: true,
        streamData: action.payload,
      };

    case Type.STREAM_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorCode: action.payload.response && action.payload.response.body.code,
        errorStreamDataFetch:
          action.payload.response && action.payload.response,
        isStreamDataError: true,
      };

    case Type.STREAM_FETCH_RESET:
      return {
        ...state,
        isStreamDataSuccess: false,
        isStreamDataError: false,
        isAddStreamDataSuccess: false,
        isAddStreamDataError: false,
      };

    case Type.STREAM_ADD_PROGRESS:
      return { ...state, loading: true };

    case Type.STREAM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        isAddStreamDataSuccess: true,
        streamData: action.payload,
      };
    case Type.STREAM_ADD_ERROR:
      return {
        ...state,
        loading: false,
        errorCode: action.payload.response && action.payload.response.body.code,
        errorAddStreamDataFetch:
          action.payload.response && action.payload.response,
        isAddStreamDataError: true,
      };
    case Type.STREAM_REDUCER_RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
