import Type from "../Types/motor.type";
import { endPoints } from "../../libs/endpoints";
import { GETRequest, PUTRequest, POSTRequest } from "../../api/request";

export function fetchMotorConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_MOTOR_CONFIG_DATA_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.motorDesign}`)
      .then((res) =>
        dispatch({
          type: Type.GET_MOTOR_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_MOTOR_CONFIG_DATA_FAILURE, payload: err })
      );
  };
}

export function fetchMotorConfigDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.GET_MOTOR_CONFIG_DATA_RESET });
  };
}

export function saveDesignData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_MOTOR_DESIGN_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorDesign}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_MOTOR_DESIGN_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_MOTOR_DESIGN_DATA_FAILURE, payload: err })
      );
  };
}

export function designDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_MOTOR_DESIGN_DATA_RESET });
  };
}

export function savePerformanceData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_PERFORMANCE_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorPerformaceData}`,
      payload
    )
      .then((res) =>
        dispatch({ type: Type.ADD_PERFORMANCE_DATA_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_PERFORMANCE_DATA_FAILURE, payload: err })
      );
  };
}

export function savePerformanceDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_PERFORMANCE_DATA_RESET });
  };
}

export function saveEquCktData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_EQUIVALENT_CIRCUIT_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorEquCkt}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_EQUIVALENT_CIRCUIT_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_EQUIVALENT_CIRCUIT_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveEquCktDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_EQUIVALENT_CIRCUIT_DATA_RESET });
  };
}

export function savePerformaceCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_PERFORMANCE_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorPerformaceCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_PERFORMANCE_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_PERFORMANCE_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function savePerformaceCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_PERFORMANCE_CURVES_DATA_RESET });
  };
}

export function saveStartCharCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorStartingCharCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveStartCharCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_STARTING_CHARACTERISTICS_CURVES_DATA_RESET });
  };
}

export function saveVarSpeedCurve(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_VARIABLE_SPEED_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.motorVarSpeedCurve}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_VARIABLE_SPEED_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_VARIABLE_SPEED_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveVarSpeedCurveReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_VARIABLE_SPEED_CURVES_DATA_RESET });
  };
}

export function clearMotorReducer() {
  return function (dispatch) {
    dispatch({ type: Type.RESET_MOTOR_REDUCER });
  };
}

export function saveDrivetypes(payload) {
  return function (dispatch) {
    dispatch({ type: Type.ADD_DRIVE_TYPE_PROGRESS });
    POSTRequest(`${endPoints.drivetypes}`, payload)
      .then((res) =>
        dispatch({ type: Type.ADD_DRIVE_TYPE_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.ADD_DRIVE_TYPE_FAILURE, payload: err })
      );
  };
}

export function getDrivetypes() {
  return function (dispatch) {
    dispatch({ type: Type.GET_DRIVE_TYPE_PROGRESS });
    POSTRequest(`${endPoints.drivetypes}/${endPoints.filter}`)
      .then((res) =>
        dispatch({ type: Type.GET_DRIVE_TYPE_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_DRIVE_TYPE_FAILURE, payload: err })
      );
  };
}
