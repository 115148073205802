export const VARIABLE_FETCH_SUCCESS = "VARIABLE_FETCH_SUCCESS";
export const VARIABLE_FETCH_ERROR = "VARIABLE_FETCH_ERROR";
export const VALUE_FETCH_PROGRESS = "VALUE_FETCH_PROGRESS";
export const VALUE_CLEAR_STATE = "VALUE_CLEAR_STATE";

export const GET_VARIABLES_DATA = "GET_VARIABLES_DATA";

export const GET_FLOW_METER_TYPE_FETCH_PROGRESS =
  "GET_FLOW_METER_TYPE_FETCH_PROGRESS";
export const GET_FLOW_METER_TYPE_FETCH_SUCCESS =
  "GET_FLOW_METER_TYPE_FETCH_SUCCESS";
export const GET_FLOW_METER_TYPE_FETCH_ERROR =
  "GET_FLOW_METER_TYPE_FETCH_ERROR";

export const ADD_FLOW_METER_TYPE_FETCH_PROGRESS =
  "ADD_FLOW_METER_TYPE_FETCH_PROGRESS";
export const ADD_FLOW_METER_TYPE_FETCH_SUCCESS =
  "ADD_FLOW_METER_TYPE_FETCH_SUCCESS";
export const ADD_FLOW_METER_TYPE_FETCH_ERROR =
  "ADD_FLOW_METER_TYPE_FETCH_ERROR";

export const ADD_VALUE_DATA_SUCCESS = "ADD_VALUE_DATA_SUCCESS";
export const ADD_VALUE_DATA_PROGRESS = "ADD_VALUE_DATA_PROGRESS";
export const ADD_VALUE_DATA_ERROR = "ADD_VALUE_DATA_ERROR";

export const FLOW_MEASUREMENT_CLEAR_STATE = "FLOW_MEASUREMENT_CLEAR_STATE";
