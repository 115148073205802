import { Form, Input, Table } from 'antd';
import Decimal from 'decimal.js';
import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../../common/FormFields/FormFields';
import Loader from '../../../../../../common/Loader/Loader';
import { validateNonNegativeFloat } from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FluidCompositionMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  FluidCompositionVariables,
} from '../../../../../../constants/variables';
import {
  clearFluidCompositionState,
  getFluidList,
} from '../../../../../../redux/actions/fluidComposition.actions';
import { saveFluidCompositionData } from '../../../../../../redux/actions/gasTurbine.action';
import {
  equationOfState,
  fluidTableColumns_air,
  fluidTableColumns_fg,
  footerColumns_air,
  footerColumns_fg,
  formFields_air,
  formFields_fg,
  headerColumns_air,
  headerColumns_fg,
  initialData,
  rowSelection,
  searchTable_air,
  searchTable_fg,
} from './GasTurbineFluidComposition.Constants';
import './GasTurbineFluidComposition.scss';

const { Search } = Input;
class GasTurbineFluidComposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirtyForm: 0,
      dirtyRowsFC: { air: [], fg: [] },
      selectedFluidFC: { air: [], fg: [] },
      selectedUnsearchedFluidFC: { air: [], fg: [] },
      preserveFluidListFC: { air: [], fg: [] },
      fluidListFC: { air: [], fg: [] },
      editRowsFC: { air: [], fg: [] },
      selectedFluidKeysFC: { air: [], fg: [] },
      updatedFieldsFC: { air: [], fg: [] },
      ratioValueFC: { air: 1, fg: 1 },
      formEdit: false,
      preserveFluidData: {},
      normalizePopupVisible: false,
      setInitialValues: true,
      air: {},
      fg: {},
    };
  }

  componentDidMount() {
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
    if (this.handleNetwork()) {
      this.props.getFluidListAction();
    }
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  componentDidUpdate(prevProps) {
    const {
      isFluidListSuccess,
      errorCode,
      fluidList,
    } = this.props.fluidCompositionState.apiState;
    const { addFluidCompositionData } = this.props.gasTurbineReducer;
    const {
      isSave,
      isConfigData,
      clearConfigState,
      clearFluidCompositionState,
    } = this.props;
    let componentData = {};

    if (isConfigData) {
      clearConfigState();
      this.getComponentData();
      this.disableEditForm();
    }

    if (isFluidListSuccess) {
      clearFluidCompositionState();

      componentData = { ...this.props.configData.fluidComposition };

      this.setState(
        {
          fluidListFC: { air: fluidList, fg: fluidList },
          preserveFluidListFC: { air: fluidList, fg: fluidList },
        },
        () => {
          this.setSelectedFluidKeysFC(componentData ? componentData : null);
        }
      );
    }

    if (
      addFluidCompositionData.isSuccess &&
      prevProps.gasTurbineReducer.addFluidCompositionData.isSuccess !==
        addFluidCompositionData.isSuccess
    ) {
     this.disableEditForm();
      Notification.show(Types.Success, FluidCompositionMessages.FLUID_ADDED);
    }

    if (
      addFluidCompositionData.isError &&
      prevProps.addFluidCompositionData.isError !==
        addFluidCompositionData.isError
    ) {
      clearFluidCompositionState();
      Notification.show(Types.Error, FluidCompositionMessages[errorCode]);
    }

    if (isSave && prevProps.isSave !== isSave) {
      this.save();
    }
  }

  setEosAir = (value) => {
    this.setState({
      air: { ...this.state.air, eos_air: value },
    });
    this.enableEditForm();
  };
  setEosFg = (value) => {
    this.setState({
      fg: { ...this.state.fg, eos_fg: value },
    });
    this.enableEditForm();
  };

  setTypeAir = (value) => {
    this.setState({
      air: { ...this.state.air, type: value },
    });
    this.enableEditForm();
  };
  setTypeFg = (value) => {
    this.setState({
      fg: { ...this.state.fg, type: value },
    });
    this.enableEditForm();
  };
  getComponentData() {
    let componentData = {};

    componentData = { ...this.props.configData.fluidComposition };

    this.setState({
      preserveFluidData: componentData
        ? _.cloneDeep(componentData)
        : _.cloneDeep(initialData),
      air: componentData
        ? _.cloneDeep(componentData.air)
        : _.cloneDeep(initialData.air),
      fg: componentData
        ? _.cloneDeep(componentData.fg)
        : _.cloneDeep(initialData.fg),
      equationOfState: componentData.equationState,
      typeComposition: componentData.type,
      calcDischargeConditions: componentData.calculateDischargeConditions,
    });
    this.setFluidData(
      componentData ? _.cloneDeep(componentData) : _.cloneDeep(initialData)
    );
  }
  setSelectedFluidKeysAlt(fluids, type = 'air') {
    const { fluidListFC } = this.state;
    let selectedFluidKeys_air = [];
    let selectedFluidKeys_fg = [];
    if (fluids && fluidListFC) {
      if (type === 'air') {
        selectedFluidKeys_fg = this.state.selectedFluidKeysFC.fg;
        for (let j = 0; j < fluids.length; j++) {
          for (let i = 0; i < fluidListFC.air.items.length; i++) {
            if (fluids[j].name === fluidListFC.air.items[i].shortName) {
              selectedFluidKeys_air.push(i);
              break;
            }
          }
          this.setState({
            selectedFluidKeysFC: {
              fg: selectedFluidKeys_fg,
              air: selectedFluidKeys_air,
            },
          });
        }
      } else if (type === 'fuel_gas') {
        selectedFluidKeys_air = this.state.selectedFluidKeysFC.air;
        for (let j = 0; j < fluids.length; j++) {
          for (let i = 0; i < fluidListFC.fg.items.length; i++) {
            if (fluids[j].name === fluidListFC.fg.items[i].shortName) {
              selectedFluidKeys_fg.push(i);
              break;
            }
          }
        }
      }
      this.setState({
        selectedFluidKeysFC: {
          air: selectedFluidKeys_air,
          fg: selectedFluidKeys_fg,
        },
      });
    } else {
      type === 'air' &&
        this.setState({
          selectedFluidKeysFC: { ...this.state.selectedFluidKeysFC, air: [] },
        });
      type === 'fuel_gas' &&
        this.setState({
          selectedFluidKeysFC: { ...this.state.selectedFluidKeysFC, fg: [] },
        });
    }
  }

  setSelectedFluidKeysFC(fluidComposition) {
    const { fluidListFC } = this.state;
    const air_fc = [],
      fuel_gas_fc = [];
    if (fluidComposition.air && fluidComposition.fg && fluidListFC) {
      if (fluidListFC.air && fluidListFC.air.items) {
        for (let j = 0; j < fluidComposition.air.composition_air.length; j++) {
          for (let i = 0; i < fluidListFC.air.items.length; i++) {
            if (
              fluidComposition.air.composition_air[j].compound ===
              fluidListFC.air.items[i].shortName
            ) {
              air_fc.push(i);
              break;
            }
          }
        }
      }
      if (fluidListFC.fg && fluidListFC.fg.items) {
        for (let j = 0; j < fluidComposition.fg.composition_fg.length; j++) {
          for (let i = 0; i < fluidListFC.fg.items.length; i++) {
            if (
              fluidComposition.fg.composition_fg[j].compound ===
              fluidListFC.fg.items[i].shortName
            ) {
              fuel_gas_fc.push(i);
              break;
            }
          }
        }
      }

      this.setState({
        selectedFluidKeysFC: {
          air: air_fc,
          fg: fuel_gas_fc,
        },
      });
    } else {
      this.setState({
        selectedFluidKeysFC: { air: [], fg: [] },
      });
    }
  }
  helper(composition) {
    let temp = [];
    composition.forEach((element) => {
      temp.push({ name: element.compound, value: element.fraction });
    });
    return temp;
  }

  setFluidData(fluidComposition) {
    if (fluidComposition && fluidComposition.air && fluidComposition.fg) {
      this.setSelectedFluidKeysFC(fluidComposition);
      this.setState({
        selectedFluidFC: {
          air: fluidComposition.air?.composition_air
            ? this.helper(fluidComposition.air.composition_air)
            : [],
          fg: fluidComposition.fg?.composition_fg
            ? this.helper(fluidComposition.fg.composition_fg)
            : [],
        },
        ratioValueFC: {
          air: fluidComposition.air?.moleFraction
            ? parseInt(fluidComposition.air.moleFraction)
            : initialData.air.moleFraction,
          fg: fluidComposition.fg?.moleFraction
            ? parseInt(fluidComposition.fg.moleFraction)
            : initialData.fg.moleFraction,
        },
      });
 
      let pres1 = null;
      if (fluidComposition.air && fluidComposition.air.eos_air) {
        pres1 = equationOfState.find(
          (ele) => ele._id === fluidComposition.air?.eos_air
        );
        this.props.form.setFieldsValue({
          equationState_air: pres1.value,
          type_air: fluidComposition.air?.type,
        });
      } else {
        this.props.form.setFieldsValue({
          type_air: fluidComposition.air?.type,
        });
      }

      let pres2 = null;
      if (fluidComposition.fg && fluidComposition.fg.eos_fg) {
        pres2 = equationOfState.find(
          (ele) => ele._id === fluidComposition.fg?.eos_fg
        );
        this.props.form.setFieldsValue({
          equationState_fg: pres2.value,
          type_fg: fluidComposition.fg?.type,
        });
      } else {
        this.props.form.setFieldsValue({
          type_fg: fluidComposition.fg?.type,
        });
      }
    } else {
      this.setState({
        selectedFluidFC: { air: [], fg: [] },
      });
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  onValueChange(field, row, type = 'air') {
    const { selectedFluidFC } = this.state;

    const temp =
      type === 'air' ? [...selectedFluidFC.air] : [...selectedFluidFC.fg];
    temp[row][field.name] = field.value;
    type === 'air' &&
      this.setState(
        {
          selectedFluidFC: { ...this.state.selectedFluidFC, air: temp },
        },
        () => {
          this.setEditRowsFC('air');
        }
      );
    type === 'fuel_gas' &&
      this.setState(
        {
          selectedFluidFC: { ...this.state.selectedFluidFC, fg: temp },
        },
        () => {
          this.setEditRowsFC('fuel_gas');
        }
      );
  }

  setEditRowsFC(type = 'air') {
    const { updatedFieldsFC, selectedFluidFC } = this.state;
    const tempEditRows = [];
    const tempDirtyRows = [];
    const tempUpdatedField = [];
    if (type === 'air') {
      updatedFieldsFC.air.forEach((field, updatedIndex) => {
        field &&
          selectedFluidFC.air.forEach((data, index) => {
            if (field.name === data.name) {
              tempEditRows.push(index);
              tempUpdatedField[index] = updatedFieldsFC.air[updatedIndex];
            }
            if (!validateNonNegativeFloat(`${data.value}`)) {
              tempDirtyRows.push(index);
            }
          });
      });
      this.setState({
        editRowsFC: { ...this.state.editRowsFC, air: tempEditRows },
        updatedFieldsFC: {
          ...this.state.updatedFieldsFC,
          air: tempUpdatedField,
        },
        dirtyRowsFC: { ...this.state.dirtyRowsFC, air: tempDirtyRows },
      });
    } else {
      updatedFieldsFC.fg.forEach((field, updatedIndex) => {
        field &&
          selectedFluidFC.fg.forEach((data, index) => {
            if (field.name === data.name) {
              tempEditRows.push(index);
              tempUpdatedField[index] = updatedFieldsFC.fg[updatedIndex];
            }
            if (!validateNonNegativeFloat(`${data.value}`)) {
              tempDirtyRows.push(index);
            }
          });
      });
      this.setState({
        editRowsFC: { ...this.state.editRowsFC, fg: tempEditRows },
        updatedFieldsFC: {
          ...this.state.updatedFieldsFC,
          fg: tempUpdatedField,
        },
        dirtyRowsFC: { ...this.state.dirtyRowsFC, fg: tempDirtyRows },
      });
    }
  }

  editFC(row, type = 'air') {
    const { updatedFieldsFC, selectedFluidFC } = this.state;
    const updatedTemp =
      type === 'air'
        ? _.cloneDeep(updatedFieldsFC.air)
        : _.cloneDeep(updatedFieldsFC.fg);
    updatedTemp[row] =
      type === 'air'
        ? _.cloneDeep(selectedFluidFC.air[row])
        : _.cloneDeep(selectedFluidFC.fg[row]);
    type === 'air' &&
      this.setState(
        {
          updatedFieldsFC: { ...this.state.updatedFieldsFC, air: updatedTemp },
        },
        () => {
          this.setEditRowsFC('air');
          this.enableEditForm();
        }
      );
    type === 'fuel_gas' &&
      this.setState(
        {
          updatedFieldsFC: { ...this.state.updatedFieldsFC, fg: updatedTemp },
        },
        () => {
          this.setEditRowsFC('fuel_gas');
          this.enableEditForm();
        }
      );
  }

  undoFC(row, type = 'air') {
    const { updatedFieldsFC, selectedFluidFC } = this.state;
    const tempSelectedFluid =
      type === 'air'
        ? _.cloneDeep(selectedFluidFC.air)
        : _.cloneDeep(selectedFluidFC.fg);
    const tempUpdatedField =
      type === 'air'
        ? _.cloneDeep(updatedFieldsFC.air)
        : _.cloneDeep(updatedFieldsFC.fg);
    tempSelectedFluid[row] = _.cloneDeep(tempUpdatedField[row]);
    tempUpdatedField[row] = null;
    type === 'air' &&
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            air: tempSelectedFluid,
          },
          updatedFieldsFC: {
            ...this.state.updatedFieldsFC,
            air: tempUpdatedField,
          },
        },
        () => {
          this.setEditRowsFC('air');
        }
      );

    type === 'fuel_gas' &&
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            fg: tempSelectedFluid,
          },
          updatedFieldsFC: {
            ...this.state.updatedFieldsFC,
            fg: tempUpdatedField,
          },
        },
        () => {
          this.setEditRowsFC('fuel_gas');
        }
      );
  }

  delete(row, type = 'air') {
    const { selectedFluidFC, updatedFieldsFC, dirtyRowsFC } = this.state;
    let tempSelectedFluid =
      type === 'air'
        ? _.cloneDeep(selectedFluidFC.air)
        : _.cloneDeep(selectedFluidFC.fg);
    let tempDirtyRows =
      type === 'air'
        ? _.cloneDeep(dirtyRowsFC.air)
        : _.cloneDeep(dirtyRowsFC.fg);
    let tempUpdatedField =
      type === 'air'
        ? _.cloneDeep(updatedFieldsFC.air)
        : _.cloneDeep(updatedFieldsFC.fg);
    tempSelectedFluid.splice(row, 1);
    tempUpdatedField.splice(row, 1);
    tempDirtyRows = tempDirtyRows.filter((e) => e !== row);
    let tempSearched =
      type === 'air'
        ? this.state.selectedUnsearchedFluidFC.air.filter(
            (e) => e.name !== this.state.selectedFluidFC.air[row].name
          )
        : this.state.selectedUnsearchedFluidFC.fg.filter(
            (e) => e.name !== this.state.selectedFluidFC.fg[row].name
          );
    type === 'air' &&
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            air: tempSelectedFluid,
          },
          updatedFieldsFC: {
            ...this.state.updatedFieldsFC,
            air: tempUpdatedField,
          },
          selectedUnsearchedFluidFC: {
            ...this.state.selectedUnsearchedFluidFC,
            air: tempSearched,
          },
          dirtyRowsFC: { ...this.state.dirtyRowsFC, air: tempDirtyRows },
        },
        () => {
          this.setSelectedFluidKeysAlt(this.state.selectedFluidFC.air, 'air');
          this.setEditRowsFC('air');
          this.enableEditForm();
        }
      );
    type === 'fuel_gas' &&
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            fg: tempSelectedFluid,
          },
          updatedFieldsFC: {
            ...this.state.updatedFieldsFC,
            fg: tempUpdatedField,
          },
          selectedUnsearchedFluidFC: {
            ...this.state.selectedUnsearchedFluidFC,
            fg: tempSearched,
          },
          dirtyRowsFC: { ...this.state.dirtyRowsFC, fg: tempDirtyRows },
        },
        () => {
          this.setSelectedFluidKeysAlt(
            this.state.selectedFluidFC.fg,
            'fuel_gas'
          );
          this.setEditRowsFC('fg');
          this.enableEditForm();
        }
      );
  }

  onSearch(value, type = 'air') {
    const { preserveFluidListFC } = this.state;
    const data = _.cloneDeep(preserveFluidListFC);
    data[type].items = [
      ...data[type].items.filter(
        (e) => e.shortName.toLowerCase().indexOf(value.toLowerCase()) > -1
      ),
    ];
    const temp =
      type === 'air'
        ? this.state.selectedFluidFC.air.filter(
            (e) =>
              e.name
                .substring(0, value.length)
                .toLowerCase()
                .indexOf(value.toLowerCase()) < 0
          )
        : this.state.selectedFluidFC.fg.filter(
            (e) =>
              e.name
                .substring(0, value.length)
                .toLowerCase()
                .indexOf(value.toLowerCase()) < 0
          );

    type === 'air' &&
      this.setState(
        {
          fluidListFC: { ...this.state.fluidListFC, air: data.air },
          selectedUnsearchedFluidFC: {
            ...this.selectedUnsearchedFluidFC,
            air: temp,
          },
        },
        () => {
          this.setSelectedFluidKeysAlt(this.state.selectedFluidFC.air, 'air');
        }
      );
    type === 'fg' &&
      this.setState(
        {
          fluidListFC: { ...this.state.fluidListFC, fg: data.fg },
          selectedUnsearchedFluidFC: {
            ...this.selectedUnsearchedFluidFC,
            fg: temp,
          },
        },
        () => {
          this.setSelectedFluidKeysAlt(
            this.state.selectedFluidFC.fg,
            'fuel_gas'
          );
        }
      );
  }
  ratioChanged(value, type = 'air') {
    const { selectedFluidFC } = this.state;
    if (selectedFluidFC) {
      const selectedTemp =
        type === 'air' ? [...selectedFluidFC.air] : [...selectedFluidFC.fg];

      for (let i = 0; i < selectedTemp.length; i++) {
        selectedTemp[i].value =
          parseInt(value) === 1
            ? parseFloat(
                Decimal.div(parseFloat(selectedTemp[i].value), 100).valueOf()
              )
            : parseFloat(
                Decimal.mul(parseFloat(selectedTemp[i].value), 100).valueOf()
              );
        selectedTemp[i].value = parseFloat(selectedTemp[i].value);
      }

      type === 'air' &&
        this.setState(
          {
            selectedFluidFC: {
              ...this.state.selectedFluidFC,
              air: selectedTemp,
            },
            ratioValueFC: { ...this.state.ratioValueFC, air: parseInt(value) },
          },
          () => {
            this.enableEditForm();
          }
        );
      type === 'fuel_gas' &&
        this.setState(
          {
            selectedFluidFC: {
              ...this.state.selectedFluidFC,
              fg: selectedTemp,
            },
            ratioValueFC: { ...this.state.ratioValueFC, fg: parseInt(value) },
          },
          () => {
            this.enableEditForm();
          }
        );
    }
  }

  calculateCompositionSum(type = 'air') {
    let sum = 0;
    const { selectedFluidFC } = this.state;
    if (selectedFluidFC) {
      const selectedTemp =
        type === 'air' ? [...selectedFluidFC.air] : [...selectedFluidFC.fg];
      for (let i = 0; i < selectedTemp.length; i++) {
        sum = parseFloat(
          Decimal.add(
            sum,
            selectedTemp[i].value ? parseFloat(selectedTemp[i].value) : 0
          ).valueOf()
        );
      }
      return parseFloat(sum.toPrecision(5));
    }
  }

  equalize(type = 'air') {
    const { selectedFluidFC } = this.state;
    const selectedTemp =
      type === 'air' ? [...selectedFluidFC.air] : [...selectedFluidFC.fg];

    for (let i = 0; i < selectedTemp.length; i++) {
      selectedTemp[i].value =
        type === 'air'
          ? parseFloat(
              Decimal.div(
                this.state.ratioValueFC.air,
                selectedTemp.length
              ).valueOf()
            ).toPrecision(6)
          : parseFloat(
              Decimal.div(
                this.state.ratioValueFC.fg,
                selectedTemp.length
              ).valueOf()
            ).toPrecision(6);
    }
    type === 'air' &&
      this.setState(
        {
          selectedFluidFC: { ...this.state.selectedFluidFC, air: selectedTemp },
          dirtyRowsFC: { ...this.state.dirtyRowsFC, air: [] },
        },
        () => {
          this.enableEditForm();
        }
      );
    type === 'fuel_gas' &&
      this.setState(
        {
          selectedFluidFC: { ...this.state.selectedFluidFC, fg: selectedTemp },
          dirtyRowsFC: { ...this.state.dirtyRowsFC, fg: [] },
        },
        () => {
          this.enableEditForm();
        }
      );
  }

  normalize(type = 'air') {
    const { selectedFluidFC } = this.state;
    const selectedTemp_air = selectedFluidFC.air;
    const selectedTemp_fg = selectedFluidFC.fg;
    const sum_air = this.calculateCompositionSum('air');
    const sum_fg = this.calculateCompositionSum('fuel_gas');

    if (type === 'air' && sum_air === 0) {
      this.equalize('air');
      return true;
    }
    if (type === 'fuel_gas' && sum_fg === 0) {
      this.equalize('fuel_gas');
      return true;
    }

    if (type === 'air' && this.state.dirtyRowsFC.air.length === 0) {
      for (let i = 0; i < selectedTemp_air.length; i++) {
        selectedTemp_air[i].value = parseFloat(
          Decimal.div(
            Decimal.mul(
              parseFloat(selectedTemp_air[i].value),
              this.state.ratioValueFC.air
            ).valueOf(),
            sum_air
          ).valueOf()
        ).toPrecision(6);
      }
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            air: selectedTemp_air,
          },
        },
        () => {
          this.enableEditForm();
        }
      );
    } else if (type === 'fuel_gas' && this.state.dirtyRowsFC.fg.length === 0) {
      for (let i = 0; i < selectedTemp_fg.length; i++) {
        selectedTemp_fg[i].value = parseFloat(
          Decimal.div(
            Decimal.mul(
              parseFloat(selectedTemp_fg[i].value),
              this.state.ratioValueFC.fg
            ).valueOf(),
            sum_fg
          ).valueOf()
        ).toPrecision(6);
      }
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            fg: selectedTemp_fg,
          },
        },
        () => {
          this.enableEditForm();
        }
      );
    } else {
      Notification.show(Types.Error, FluidCompositionMessages.FIELDS_DIRTY);
    }
  }

  onFluidSelectionChangeFC(key, row, type = 'air') {
    if (type === 'air') {
      const selected_air = [];
      let status_air = false;
      for (let i = 0; i < row.length; i++) {
        status_air = false;
        for (
          let j = 0;
          this.state.selectedFluidFC.air &&
          j < this.state.selectedFluidFC.air.length;
          j++
        ) {
          if (row[i].shortName === this.state.selectedFluidFC.air[j].name) {
            selected_air.push(this.state.selectedFluidFC.air[j]);
            status_air = true;
            break;
          }
        }
        if (!status_air) {
          const temp = {};
          temp.name = row[i].shortName;
          temp.value = 0;
          selected_air.push(temp);
        }
      }
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            air: [...this.state.selectedUnsearchedFluidFC.air, ...selected_air],
          },
          selectedFluidKeysFC: { ...this.state.selectedFluidKeysFC, air: key },
        },
        () => {
          this.enableEditForm();
          this.setEditRowsFC('air');
        }
      );
    } else if (type === 'fuel_gas') {
      const selected_fg = [];
      let status_fg = false;
      for (let i = 0; i < row.length; i++) {
        status_fg = false;
        for (
          let j = 0;
          this.state.selectedFluidFC.fg &&
          j < this.state.selectedFluidFC.fg.length;
          j++
        ) {
          if (row[i].shortName === this.state.selectedFluidFC.fg[j].name) {
            selected_fg.push(this.state.selectedFluidFC.fg[j]);
            status_fg = true;
            break;
          }
        }
        if (!status_fg) {
          const temp = {};
          temp.name = row[i].shortName;
          temp.value = 0;
          selected_fg.push(temp);
        }
      }
      this.setState(
        {
          selectedFluidFC: {
            ...this.state.selectedFluidFC,
            fg: [...this.state.selectedUnsearchedFluidFC.fg, ...selected_fg],
          },
          selectedFluidKeysFC: { ...this.state.selectedFluidKeysFC, fg: key },
        },
        () => {
          this.enableEditForm();
          this.setEditRowsFC('fuel_gas');
        }
      );
    }
  }

  reverseHelper(composition) {
    let temp = [];
    composition.forEach((element) => {
      temp.push({ compound: element.name, fraction: element.value });
    });
    return temp;
  }
  save() {
    const {
      saveFluidCompositionData,
      location: { state },
    } = this.props;
    const { ratioValueFC, selectedFluidFC } = this.state;
    const body = {};

    if (selectedFluidFC && selectedFluidFC.air.length) {
      for (let i = 0; i < selectedFluidFC.air.length; i++) {
        selectedFluidFC.air[i].value = parseFloat(selectedFluidFC.air[i].value);
      }
    }

    if (selectedFluidFC && selectedFluidFC.fg.length) {
      for (let i = 0; i < selectedFluidFC.fg.length; i++) {
        selectedFluidFC.fg[i].value = parseFloat(selectedFluidFC.fg[i].value);
      }
    }

    this.props.form.validateFields((err, values) => {
      let airValue = null;
      if (values.equationState_air) {
        airValue = equationOfState.find(
          (ele) => ele.value === values.equationState_air
        )};
        let fgValue = null;
        if (values.equationState_fg) {
          fgValue = equationOfState.find(
            (ele) => ele.value === values.equationState_fg
          )};

      if (
        !err &&
        this.state.dirtyRowsFC.air.length === 0 &&
        this.state.dirtyRowsFC.fg.length === 0
      ) {
        Object.assign(body, {
          air: {
            eos_air: airValue._id,
            type: values.type_air,
            moleFraction: ratioValueFC.air,
            composition_air: this.reverseHelper(selectedFluidFC.air),
          },
          fg: {
            eos_fg: fgValue._id,
            type: values.type_fg,
            moleFraction: ratioValueFC.fg,
            composition_fg: this.reverseHelper(selectedFluidFC.fg),
          },
        });

      this.setState({
       preserveFluidData:(_.cloneDeep(body))
      })

        if (
          selectedFluidFC &&
          selectedFluidFC.air.length &&
          selectedFluidFC.fg.length
        ) {
          if (
            this.calculateCompositionSum('air') ===
              parseFloat(this.state.ratioValueFC.air) &&
            this.calculateCompositionSum('fuel_gas') ===
              parseFloat(this.state.ratioValueFC.fg)
          ) {
            if (this.handleNetwork()) {
              saveFluidCompositionData(state.componentData.info._id, body);
            }
          } else {
            this.props.setIsSave();
            this.setState({
              normalizePopupVisible: true,
            });
          }
        } else {
          this.props.setIsSave();
          Notification.show(
            Types.Error,
            FluidCompositionMessages.NO_FLUID_SELECTED
          );
        }
      } else {
        this.props.setIsSave();
      }
    });
  }

  clearDirtyForm() {
    const { preserveFluidData } = this.state;
    this.props.form.resetFields();
    this.setState(
      {
      editRowsFC: { air: [], fg: [] },
      updatedFieldsFC: { air: [], fg: [] },
      dirtyRowsFC: { air: [], fg: [] },
      dirtyForm: 0,
    },
    () => {
      this.setFluidData(_.cloneDeep(preserveFluidData));
    });
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleNormalizeCancel() {
    this.setState({
      normalizePopupVisible: false,
    });
  }

  handleNormalizeOk() {
    this.setState(
      {
        normalizePopupVisible: false,
      },
      () => {
        this.calculateCompositionSum('air') !==
          parseFloat(this.state.ratioValueFC.air) && this.normalize('air');
        this.calculateCompositionSum('fuel_gas') !==
          parseFloat(this.state.ratioValueFC.fg) && this.normalize('fuel_gas');
        this.save();
      }
    );
  }

  renderNormalizeModal() {
    if (this.state.normalizePopupVisible) {
      return (
        <ConfirmModal
          title={FluidCompositionVariables.NORMALIZE}
          visible={this.state.normalizePopupVisible}
          handleOk={() => this.handleNormalizeOk()}
          handleCancel={() => this.handleNormalizeCancel()}
          message={<div>{FluidCompositionMessages.EXTRAPOLATING_MESSAGE1}</div>}
        />
      );
    }
    return null;
  }

  subsetKeys(type) {
    if (type === 'air') {
      let fullsetItems =
        this.state.selectedFluidFC.air &&
        this.state.selectedFluidFC.air.map((item) => item.name);
      let subsetItems =
        this.state.fluidListFC.air.items &&
        this.state.fluidListFC.air.items.map((item) => item.shortName);
      let subsetKeys = [];
      if (subsetItems && subsetItems.length) {
        for (let i = 0; i < subsetItems.length; i++) {
          for (let j = 0; j < fullsetItems.length; j++) {
            if (subsetItems[i] === fullsetItems[j]) {
              subsetKeys.push(i);
            }
          }
        }
        return subsetKeys;
      }
      return this.state.selectedFluidKeysFC.air;
    } else {
      let fullsetItems =
        (this.state.selectedFluidFC.fg &&
          this.state.selectedFluidFC.fg.map((item) => item.name)) ||
        [];
      let subsetItems =
        this.state.fluidListFC.fg &&
        this.state.fluidListFC.fg.items &&
        this.state.fluidListFC.fg.items.map((item) => item.shortName);
      let subsetKeys = [];
      if (subsetItems && subsetItems.length) {
        for (let i = 0; i < subsetItems.length; i++) {
          for (let j = 0; j < fullsetItems.length; j++) {
            if (subsetItems[i] === fullsetItems[j]) {
              subsetKeys.push(i);
            }
          }
        }
        return subsetKeys;
      }
      return this.state.selectedFluidKeysFC.fg;
    }
  }

  render() {
    const isConfigEditable = this.props.isSchematicEditable;
    const { loading } = this.props.fluidCompositionState;

    return (
      <div className="FluidComposition">
        <div className="FluidCompositionInner">
          {loading ? <Loader /> : null}
          <div className="data-info">
            <div className="heading-table">
              <div className="type-heading" style={{ width: '100%' }}>
                Air Composition
              </div>
            </div>

            <FormFields
              formFields={formFields_air({
                changeEquation: this.setEosAir,
                changeType: this.setTypeAir,
                isConfigEditable: this.props.streamId
                  ? this.props.mixerEditable
                  : this.props.isSchematicEditable,
              })}
              form={this.props.form}
            />
          </div>
          <div className="data-list">
            <div className="fluid-list">
              <Search
                onChange={(e) => {
                  this.onSearch(e.target.value, 'air');
                }}
                placeholder={FluidCompositionVariables.SEARCH}
                disabled={!isConfigEditable}
                allowClear
              />
              <hr />
              <Table
                rowSelection={rowSelection(
                  {
                    change: isConfigEditable
                      ? (key, row) => {
                          this.onFluidSelectionChangeFC(key, row, 'air');
                        }
                      : () => {},
                  },
                  this.subsetKeys('air')
                )}
                columns={searchTable_air()}
                dataSource={
                  this.state.fluidListFC.air && this.state.fluidListFC.air.items
                }
                pagination={{
                  total: this.state.fluidListFC.air.total_count,
                  pageSize: this.state.fluidListFC.air.total_count,
                  hideOnSinglePage: true,
                }}
                scroll={{ y: 300 }}
              />
            </div>
            <div className="selected-fluid-list">
              <CustomTable
                columns={fluidTableColumns_air({
                  change: (field, row) => {
                    this.onValueChange(field, row, 'air');
                  },
                  edit: (row) => {
                    this.editFC(row, 'air');
                  },
                  delete: (row) => {
                    this.delete(row, 'air');
                  },
                  undo: (row) => {
                    this.undoFC(row, 'air');
                  },
                  isConfigEditable,
                })}
                data={this.state.selectedFluidFC.air}
                editableRow={this.state.editRowsFC.air}
                headerColumns={headerColumns_air({
                  ratioChanged: (value) => {
                    this.ratioChanged(value, 'air');
                  },
                  ratioValue: this.state.ratioValueFC.air,
                  isConfigEditable,
                })}
                footerColumns={footerColumns_air({
                  normalize: () => {
                    this.normalize('air');
                  },
                  ratioValue: this.state.ratioValueFC.air,
                  totalSum:
                    (this.state.selectedFluidFC.air &&
                      this.calculateCompositionSum('air')) ||
                    0,
                  isConfigEditable,
                })}
              />
            </div>
          </div>
        </div>
        <div className="FluidCompositionInner">
          {loading ? <Loader /> : null}
          <div className="data-info">
            <div className="heading-table">
              <div className="type-heading" style={{ width: '100%' }}>
                Fuel Gas Composition
              </div>
            </div>

            <FormFields
              formFields={formFields_fg({
                changeEquation: this.setEosFg,
                changeType: this.setTypeFg,
                isConfigEditable: this.props.streamId
                  ? this.props.mixerEditable
                  : this.props.isSchematicEditable,
              })}
              form={this.props.form}
            />
          </div>
          <div className="data-list">
            <div className="fluid-list">
              <Search
                onChange={(e) => {
                  this.onSearch(e.target.value, 'fg');
                }}
                placeholder={FluidCompositionVariables.SEARCH}
                disabled={!isConfigEditable}
                allowClear
              />
              <hr />
              <Table
                rowSelection={rowSelection(
                  {
                    change: isConfigEditable
                      ? (key, row) => {
                          this.onFluidSelectionChangeFC(key, row, 'fuel_gas');
                        }
                      : () => {},
                  },
                  this.subsetKeys('fuel_gas')
                )}
                columns={searchTable_fg()}
                dataSource={
                  this.state.fluidListFC.fg && this.state.fluidListFC.fg.items
                }
                pagination={{
                  total:
                    this.state.fluidListFC.fg &&
                    this.state.fluidListFC.fg.total_count,
                  pageSize:
                    this.state.fluidListFC.fg &&
                    this.state.fluidListFC.fg.total_count,
                  hideOnSinglePage: true,
                }}
                scroll={{ y: 300 }}
              />
            </div>
            <div className="selected-fluid-list">
              <CustomTable
                columns={fluidTableColumns_fg({
                  change: (field, row) => {
                    this.onValueChange(field, row, 'fuel_gas');
                  },
                  edit: (row) => {
                    this.editFC(row, 'fuel_gas');
                  },
                  delete: (row) => {
                    this.delete(row, 'fuel_gas');
                  },
                  undo: (row) => {
                    this.undoFC(row, 'fuel_gas');
                  },
                  isConfigEditable,
                })}
                data={this.state.selectedFluidFC?.fg}
                editableRow={this.state.editRowsFC?.fg}
                headerColumns={headerColumns_fg({
                  ratioChanged: (value) => {
                    this.ratioChanged(value, 'fuel_gas');
                  },
                  ratioValue: this.state.ratioValueFC.fg,
                  isConfigEditable,
                })}
                footerColumns={footerColumns_fg({
                  normalize: () => {
                    this.normalize('fuel_gas');
                  },
                  ratioValue: this.state.ratioValueFC.fg,
                  totalSum:
                    (this.state.selectedFluidFC.fg &&
                      this.calculateCompositionSum('fuel_gas')) ||
                    0,
                  isConfigEditable,
                })}
              />
            </div>
          </div>
          {this.state.formEdit && this.props.isSchematicEditable && (
            <div className="config-bottom-buttons">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  this.reset();
                }}
              >
                {ButtonVariables.RESET}
              </button>
              <button
                className="btn-default"
                onClick={() => {
                  this.save();
                }}
              >
                {ButtonVariables.SAVE}
              </button>
            </div>
          )}
          {this.renderModal()}
          {this.renderNormalizeModal()}
        </div>
      </div>
    );
  }
}
GasTurbineFluidComposition.propTypes = {
  isSave: PropType.bool,
  isSchematicEditable: PropType.bool.isRequired,
  streamId: PropType.string,
  setIsSave: PropType.func,
  addFluid: PropType.func,
  setAddData: PropType.func,
  getStreamData: PropType.func,
  clearStreamState: PropType.func,
  getFluidListAction: PropType.func,
  clearFluidCompositionState: PropType.func,
  match: PropType.object,
  form: PropType.object,
  network: PropType.object,
  history: PropType.object,
  location: PropType.object,
  configState: PropType.object,
  streamState: PropType.object,
  fluidCompositionState: PropType.object,
  mixerEditable: PropType.bool,
};

GasTurbineFluidComposition.defaultProps = {
  setIsSave: () => {},
  mixerEditable: true,
};

const mapStateToProps = (state) => ({
  fluidCompositionState: state.FluidCompositionReducer,
  network: state.NetworkReducer,
  configState: state.ConfigReducer,
  streamState: state.streamReducer,
  gasTurbineReducer: state.gasTurbineReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getFluidListAction: (payload) => {
    dispatch(getFluidList(payload));
  },
  clearFluidCompositionState: () => dispatch(clearFluidCompositionState()),

  saveFluidCompositionData: (id, payload) =>
    dispatch(saveFluidCompositionData(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
  })(withRouter(GasTurbineFluidComposition))
);
