import React, { Component } from "react";
import "./Toolbar.scss";

class Toolbar extends Component {
  componentDidMount() {
    this.props.myRef(this.refs.toolbar);
  }

  render() {
    return <div className="toolbar" ref="toolbar" />;
  }
}

export default Toolbar;
