import moment from "moment";
import _ from "lodash";

import {
  ALERT_PLOT_GET_REQUEST,
  ALERT_PLOT_GET_ERROR,
  ALERT_PLOT_GET_SUCCESS,
  ALERT_PLOT_GET_CLEAR_STATE,
} from "../Types/Alerts.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isSuccess: false,
  data: [],
  isError: false,
  errorCode: null,
  message: null,
};

export default function AlertPlotReducer(state = INITIAL_API_STATE, action) {
  switch (action.type) {
    case ALERT_PLOT_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        data: [],
        isError: false,
        errorCode: null,
        message: null,
      };

    case ALERT_PLOT_GET_SUCCESS:
      action.payload.plots.sort((a, b) =>
        new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
      );
      const timeData = action.payload.plots.map((item) => [
        moment.utc(item.timestamp).format(),
        _.round(item.plantValue, 5),
      ]);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: [{ name: "Alert Plot", data: timeData }],
        isError: false,
        errorCode: null,
        message: null,
      };

    case ALERT_PLOT_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        data: [],
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case ALERT_PLOT_GET_CLEAR_STATE:
      return {
        ...INITIAL_API_STATE,
      };

    default:
      return state;
  }
}
