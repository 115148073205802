import { POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";
import {
  INVITATION_LINK_ERROR,
  INVITATION_LINK_PROGRESS,
  INVITATION_LINK_SUCCESS,
  RESET_DATA,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_PROGRESS,
  RESET_PASSWORD_SUCCESS,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_PROGRESS,
  USER_CHANGE_STATUS_SUCCESS,
  USER_CLEAR,
  USER_EDIT_ERROR,
  USER_EDIT_PROGRESS,
  USER_EDIT_SUCCESS,
  USER_FETCH_ERROR,
  USER_FETCH_PROGRESS,
  USER_FETCH_SUCCESS,
  USER_SAVE_ERROR,
  USER_SAVE_PROGRESS,
  USER_SAVE_SUCCESS,
} from "../Types/UserManagement.types";

const userFetchProgress = () => ({ type: USER_FETCH_PROGRESS });
const userFetchSuccess = (data) => ({
  type: USER_FETCH_SUCCESS,
  payload: data,
});
const userFetchError = (error) => ({ type: USER_FETCH_ERROR, payload: error });
const userSaveProgress = () => ({ type: USER_SAVE_PROGRESS });
const userSaveSuccess = (data) => ({ type: USER_SAVE_SUCCESS, payload: data });
const userSaveError = (error) => ({ type: USER_SAVE_ERROR, payload: error });
const userChangeStatusProgress = () => ({ type: USER_CHANGE_STATUS_PROGRESS });
const userChangeStatusSuccess = (data) => ({
  type: USER_CHANGE_STATUS_SUCCESS,
  payload: data,
});
const userChangeStatusError = (error) => ({
  type: USER_CHANGE_STATUS_ERROR,
  payload: error,
});
const editUserProgress = () => ({ type: USER_EDIT_PROGRESS });
const editUserSuccess = (data) => ({ type: USER_EDIT_SUCCESS, payload: data });
const editUserError = (error) => ({ type: USER_EDIT_ERROR, payload: error });
const invitationLinkProgress = () => ({ type: INVITATION_LINK_PROGRESS });
const invitationLinkSuccess = (data) => ({
  type: INVITATION_LINK_SUCCESS,
  payload: data,
});
const invitationLinkError = (error) => ({
  type: INVITATION_LINK_ERROR,
  payload: error,
});
const resetPasswordProgress = () => ({ type: RESET_PASSWORD_PROGRESS });
const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});
const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});
const clearUser = () => ({ type: USER_CLEAR });

const getEndPoint = () => "users";

const filterElement = (payload) => {
  let query = `?keyword=${payload && payload.keyword? payload.keyword:""}
  &order=${payload && payload.order? payload.order: "1"}
  &filter=${payload && payload.filter? payload.filter:""}`
return (
    query.replace(/\s+/g, '')
  )
}

export function userFetch(payload) {
  return function (dispatch) {
    dispatch(userFetchProgress());
    POSTRequest(getEndPoint()+filterElement(payload))
      .then((response) => dispatch(userFetchSuccess(response.body)))
      .catch((error) => dispatch(userFetchError(error)));
  };
}

export function userSave(payload) {
  return function (dispatch) {
    dispatch(userSaveProgress());
    POSTRequest(endPoints.user, payload)
      .then((response) => dispatch(userSaveSuccess(response.body)))
      .catch((error) => dispatch(userSaveError(error)));
  };
}

export function changeUserStatus(id, payload) {
  return function (dispatch) {
    dispatch(userChangeStatusProgress());
    PUTRequest(`${endPoints.userStatus}/${id}`, payload)
      .then((response) => dispatch(userChangeStatusSuccess(response.body)))
      .catch((error) => dispatch(userChangeStatusError(error)));
  };
}

export function editUser(id, payload) {
  return function (dispatch) {
    dispatch(editUserProgress());
    PUTRequest(`${endPoints.user}/${id}`, payload)
      .then((response) => dispatch(editUserSuccess(response.body)))
      .catch((error) => dispatch(editUserError(error)));
  };
}

export function invitationLink(payload) {
  return function (dispatch) {
    dispatch(invitationLinkProgress());
    PUTRequest(`${endPoints.user}/${endPoints.changePassInvite}`, payload)
      .then((response) => dispatch(invitationLinkSuccess(response.body)))
      .catch((error) => dispatch(invitationLinkError(error)));
  };
}

export function resetPassword(payload) {
  return function (dispatch) {
    dispatch(resetPasswordProgress());
    POSTRequest(endPoints.resetPassword, payload)
      .then((response) => dispatch(resetPasswordSuccess(response.body)))
      .catch((error) => dispatch(resetPasswordError(error)));
  };
}

export function clearUserData() {
  return function (dispatch) {
    dispatch(clearUser());
  };
}

export function resetReducerPassword() {
  return function (dispatch) {
    dispatch({ type: RESET_DATA });
  };
}
