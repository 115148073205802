//Do not chage the order of array
const Tags = ["RS-UPT", 'RS-DWT', 'RS-NOS','RS-TDT', 'RS-TUT', 'RS-MUT', 'RS-MDT', 'RS-OA', 'RS-TRCT', "RS-AT",
"RS-AVL", "RS-PDT", "RS-UDT", "RS-NOF", "RS-TAT","RS-MEA", "RS-METBF", "RS-FR", "RS-MTBR", "RS-MART", "RS-MTTF"] 
let payload = []
let uptime = 0
let downtime = 0
let NOS = 0
let totalUpTime = 0
let totalDownTime = 0
let meanUpTime = 0
let meanDownTime = 0
let index = 0
let totalRunCyleTime = 0
let totalAvailability = 0
let noOfFailure = 0
let MTTF = 0

const stillRunningCalcs = async (lastRunData, startTime, endTime, ROS, MART, MTBR) => {
    let lastData = JSON.parse(JSON.stringify(lastRunData))
    payload=[]
    uptime = 0
    NOS = 0
    totalUpTime = 0
    totalDownTime = 0
    meanUpTime = 0
    meanDownTime = 0
    downtime = 0
    index = 0
    totalRunCyleTime = 0
    totalAvailability = 0
    noOfFailure = 0

    if(lastRunData.length){
        for await( let tag of Tags) {
            for await (let data of lastData){
                compareTag(tag, data, startTime, endTime, ROS, MART, MTBR)
            }
            index =index+1
        }
    }
    if(!lastRunData.length){
        for await (let tag of Tags){
          firstRunTag(tag, startTime, endTime)
        }
    }
    return payload
}

//Only for 1st run of any machine
const firstRunTag = (tag, startTime, endTime) => {
    let KPIvalue = {}
    if(tag === 'RS-NOS'){
        KPIvalue.abbreviation = "NS: Number of starts"
        KPIvalue.value = 1
        NOS = KPIvalue.value
        payload.push(KPIvalue)  
    }
    else if(tag === 'RS-UPT'){
        KPIvalue.abbreviation = "Up-time (hours)"
        KPIvalue.value = (endTime)?(parseFloat((new Date(endTime) - new Date(startTime))/(1000*3600))).toFixed(3):(parseFloat((new Date() - new Date(startTime))/(1000*3600))).toFixed(3)
        uptime= KPIvalue.value
        payload.push(KPIvalue)
    }
    else if(tag === 'RS-DWT'){
        KPIvalue.value = (endTime)?(parseFloat((new Date() - new Date(endTime))/(1000*3600))).toFixed(3):0
        KPIvalue.abbreviation = "Down-time (hours)"
        downtime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-TDT'){
        KPIvalue.value = downtime
        KPIvalue.abbreviation = "Total Down-time"
        totalDownTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-TUT'){
        KPIvalue.value = uptime
        KPIvalue.abbreviation = "Total Up-time"
        totalUpTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-MUT'){
        KPIvalue.value = (totalUpTime/NOS).toFixed(3)
        KPIvalue.abbreviation = "MUT: Mean Up-time (hours)"
        meanUpTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-MDT'){
        KPIvalue.value = (endTime)?(totalDownTime/NOS).toFixed(3):0
        KPIvalue.abbreviation = "MDT: Mean Down-time (hours)"
        meanDownTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-TRCT'){
        KPIvalue.value = (parseFloat(totalUpTime)  + parseFloat(totalDownTime)).toFixed(3)
        KPIvalue.abbreviation = "Total run cycle time"
        payload.push(KPIvalue) 
    }
    else if(tag === 'RS-OA'){
        KPIvalue.value = (parseFloat(meanUpTime) / (parseFloat(meanUpTime) + parseFloat(meanDownTime))).toFixed(3)
        KPIvalue.abbreviation = "Ao: Operational Availability"
        payload.push(KPIvalue) 
    }
}
 // For remainig cases
const compareTag = async (tag, data, startTime, endTime, ROS, MART, MTBR) => {
    let KPIvalue = {}
    if((tag === data.abbreviation) && (tag === 'RS-NOS')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (parseInt(data.value) +1).toString()
        KPIvalue.abbreviation = "NS: Number of starts"
        NOS = KPIvalue.value
        payload.push(KPIvalue)
    }
    else if((tag === data.abbreviation) && (tag === 'RS-UPT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.abbreviation = "Up-time (hours)"
        KPIvalue.value = (endTime)?(parseFloat((new Date(endTime) - new Date(startTime))/(1000*3600))).toFixed(3):(parseFloat((new Date() - new Date(startTime))/(1000*3600))).toFixed(3)
        uptime = KPIvalue.value
        payload.push(KPIvalue)
    }
    else if((tag === data.abbreviation) && (tag === 'RS-DWT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value =(endTime)?(parseFloat((new Date() - new Date(endTime))/(1000*3600))).toFixed(3):0
        KPIvalue.abbreviation = "Down-time (hours)"
        downtime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-TDT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (endTime)?(parseFloat(data.value) + parseFloat(downtime)).toFixed(3):parseFloat(data.value).toFixed(3)
        KPIvalue.abbreviation = "Total Down-time"
        totalDownTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-TUT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (parseFloat(data.value) + parseFloat(uptime)).toFixed(3)
        KPIvalue.abbreviation = "Total Up-time"
        totalUpTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MUT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (totalUpTime/NOS).toFixed(3)
        KPIvalue.abbreviation = "MUT: Mean Up-time (hours)"
        meanUpTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MDT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (totalDownTime/NOS).toFixed(3)
        KPIvalue.abbreviation = "MDT: Mean Down-time (hours)"
        meanDownTime = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-TRCT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (parseFloat(totalUpTime)  + parseFloat(totalDownTime)).toFixed(3)
        KPIvalue.abbreviation = "Total run cycle time"
        totalRunCyleTime = (KPIvalue.value)
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-OA')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (parseFloat(meanUpTime) / (parseFloat(meanUpTime) + parseFloat(meanDownTime))).toFixed(3)
        KPIvalue.abbreviation = "Ao: Operational Availability"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-AT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (endTime)?(ROS && ROS ==='Failure/Trip')?parseFloat(uptime).toFixed(3):(parseFloat(uptime)+parseFloat(downtime)).toFixed(3):uptime
        KPIvalue.abbreviation = "Available Time  (hours)"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-AVL')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?"False":"True": "True"
        KPIvalue.abbreviation = "Availability"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-PDT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?0:downtime:0
        KPIvalue.abbreviation = "Planned Down-time (hours)"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-UDT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?downtime:0:0
        KPIvalue.abbreviation = "Unplanned Down-time (hours)"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-NOF')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?parseInt(KPIvalue.value)+1:KPIvalue.value:KPIvalue.value
        KPIvalue.abbreviation = "No. of Failures"
        noOfFailure = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-TAT')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value =(ROS)?(ROS === 'Failure/Trip')?(parseFloat(KPIvalue.value)+parseFloat(uptime)).toFixed(3):
                                        (parseFloat(KPIvalue.value)+parseFloat(uptime)+parseFloat(downtime)).toFixed(3):
                                        (parseFloat(KPIvalue.value)+parseFloat(uptime)+parseFloat(downtime)).toFixed(3)
        KPIvalue.abbreviation = "Total Available time"
        totalAvailability = (KPIvalue.value)
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MEA')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (parseFloat(totalAvailability)/parseFloat(totalRunCyleTime)).toFixed(3)
        KPIvalue.abbreviation = "Mean / Overall Availability"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MTTF')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?(parseFloat(totalUpTime)/parseInt(noOfFailure)).toFixed(3):
                        (parseInt(noOfFailure) !== 0)?(parseFloat(totalUpTime)/parseFloat(noOfFailure)).toFixed(3):"Not applicable":
                        (parseInt(noOfFailure) !== 0)?(parseFloat(totalUpTime)/parseFloat(noOfFailure)).toFixed(3):"Not applicable"
        KPIvalue.abbreviation = "MTTF: Mean time to failure (hours)"
        MTTF = KPIvalue.value
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-METBF')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (ROS)?(ROS === 'Failure/Trip')?(parseFloat(totalRunCyleTime)/parseFloat(noOfFailure)).toFixed(3):
                        (parseInt(noOfFailure) !== 0)?(parseFloat(totalRunCyleTime)/parseFloat(noOfFailure)).toFixed(3):"Not applicable":
                        (parseInt(noOfFailure) !== 0)?(parseFloat(totalRunCyleTime)/parseFloat(noOfFailure)).toFixed(3):"Not applicable"
        KPIvalue.abbreviation = "METBF: Mean elapsed time between failures (hours)"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-FR')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.abbreviation = "λ: Failure rate"
        KPIvalue.value = (MTTF !== 0)?(MTTF === "Not applicable")?"Not Applicable":parseFloat(1/MTTF).toFixed(3):"Not Applicable"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MTBR')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (MTBR && MTBR !== "")?parseFloat(MTBR).toFixed(3):(parseInt(KPIvalue.value) === 0)?"Not applicable": parseFloat(KPIvalue.value).toFixed(3)
        KPIvalue.abbreviation = "MTBR: Mean time between repairs  (hours)"
        payload.push(KPIvalue) 
    }
    else if((tag === data.abbreviation) && (tag === 'RS-MART')){
        KPIvalue = JSON.parse(JSON.stringify(data))
        KPIvalue.value = (MART && MART !== "")?parseFloat(MART).toFixed(3):(parseInt(KPIvalue.value) === 0)?"Not applicable":parseFloat(KPIvalue.value).toFixed(3) 
        KPIvalue.abbreviation = "MART: Mean Active Repair time  (hours)"
        payload.push(KPIvalue) 
    }
}


export default stillRunningCalcs