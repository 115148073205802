import React, { Component } from 'react';
import './SystemSchematic.scss';
import GraphEditor from '../../../common/CustomMxGraph/GraphEditorComponent/GraphEditor';
import { connect } from 'react-redux';
import { NO_INTERNET, SchematicMessages } from '../../../constants/messages';
import Notification, { Types } from '../../../common/Notification/Notification';
import Loader from '../../../common/Loader/Loader';
import { MaskStyle } from './../../../constants/react-style';
import { Breadcrumb } from 'antd';
import * as Routes from './../../../constants/routes';
import {
  SchematicVariables,
  ButtonVariables,
} from '../../../constants/variables';
import {
  getSystemSchematic,
  createSystemSchematic,
  editSystemSchematic,
  clearSystemSchematicState,
} from '../../../redux/actions/systemSchematic.action';
import ConfirmModal from '../../../common/Modal/Modal';
import {
  getSystem,
  clearSystemState,
} from '../../../redux/actions/system.action';
import { getEquipment } from '../../../redux/actions/assetEquipment.action';
import { withRouter } from 'react-router-dom';
import { elementsGaurd } from '../../../gaurds';
import { ElementPermissions } from '../../../permissions';
import PropTypes from 'prop-types';

class SystemSchematic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graph: null,
      graphData: null,
      network: window.navigator.onLine,
      objects: null, // data for object pallete
      isGraphChanged: false,
      formEdit: false,
      demandPage: window.location.pathname,
      demandPageData: {},
      message: '',
      validateSchematicInfo: null,
      addEquipmentVisible: false,
      datas: null,
      addValue: '',
      payload: null,
    };
  }

  componentDidMount() {
    const { getEquipmentAsset } = this.props;
    if (this.handleNetwork()) {
      getEquipmentAsset();
      this.getSystem();
    }

    window.addEventListener('form-edit', () => {
      if (!this.state.formDisable) {
        this.setState({
          formEdit: true,
        });
      }
    });

    window.addEventListener('form-edit-false', e => {
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate(prevProps) {
    const { isSuccessFetchSystem } = this.props.systemState.apiState;
    const { isGetSuccess, getData } = this.props.assetEquipmentState.apiState;

    const { clearSystemState } = this.props;
    const {
      isGetSchematicSuccess,
      getSchematicData,
      isGetSchematicError,
      addSchematicData,
      isAddSchematicError,
      isAddSchematicSuccess,
      errorCode
    } = this.props.systemSchematicState.apiState;

    if (
      !this.props.sideMenu &&
      prevProps.location.state.systemData &&
      prevProps.location.state.systemData._id !==
        this.props.location.state.systemData._id
    ) {
      if (this.handleNetwork()) {
        this.props.getEquipmentAsset();
        this.getSystem();
      }
    }

    if (
      isGetSuccess &&
      isGetSuccess !== prevProps.assetEquipmentState.apiState.isGetSuccess
    ) {
      this.setState({
        objects: getData ? getData.items : null,
      });
    }

    if (isSuccessFetchSystem) {
      this.getSchematic();
      clearSystemState();
    }
    if (
      this.props.systemData &&
      this.props.systemData !== prevProps.systemData
    ) {
      this.getSchematic();
    }

    if (
      isGetSchematicSuccess &&
      isGetSchematicSuccess !==
        prevProps.systemSchematicState.apiState.isGetSchematicSuccess
    ) {
      this.setState({
        graph: getSchematicData,
        isGraphChanged: true,
      });
    }

    if (
      isGetSchematicError &&
      isGetSchematicError !==
        prevProps.systemSchematicState.apiState.isGetSchematicError
    ) {
      this.setState({
        graph: null,
        isGraphChanged: true,
      });

      if(errorCode !== 'NOT_FOUND') {
        Notification.show(Types.Error, SchematicMessages[errorCode]);
      }
    }

    if (
      isAddSchematicSuccess &&
      isAddSchematicSuccess !==
        prevProps.systemSchematicState.apiState.isAddSchematicSuccess
    ) {
      Notification.show(Types.Success, SchematicMessages.UPDATED);
      this.setState({
        graph: addSchematicData,
        isGraphChanged: true,
      });
    }

    if (
      isAddSchematicError &&
      isAddSchematicError !==
        prevProps.systemSchematicState.apiState.isAddSchematicError
    ) {
      Notification.show(Types.Error, SchematicMessages[errorCode]);
    }
  }

  componentWillUnmount = () => {
    this.props.clearSystemState();
    this.props.clearSystemSchematicState();
  };

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      this.setState({ network: false });
      return false;
    }
  }

  getSystem() {
    const { getSystem } = this.props;
    const {
      location: { state },
    } = this.props;
    if (state && state.systemData) {
      if (this.handleNetwork()) {
        getSystem(state.systemData._id);
      }
    }
  }

  // get schematic first
  getSchematic() {
    if (this.props.sideMenu) {
      const { systemData } = this.props;
      const { getSystemSchematic } = this.props;
      if (this.handleNetwork()) {
        getSystemSchematic(systemData._id);
      }
    } else {
      const {
        systemState: { apiState: systemData },
      } = this.props;
      const { getSystemSchematic } = this.props;
      if (systemData && systemData.systemData) {
        if (this.handleNetwork() && systemData.systemData.schematic) {
          getSystemSchematic(systemData.systemData._id);
        }
      }
    }
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }
  renderBreadCrumbSchematic() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item></Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  renderBreadCrumb() {
    const { clientData, facilityData, systemData } = this.props.location.state;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <span
            className="crumb"
            onClick={() => this.navigateToScreen(Routes.clients)}
          >
            {SchematicVariables.CLIENTS}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span
            className="crumb"
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData: clientData,
                mode: 'view',
              })
            }
          >
            {clientData.name}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span
            className="crumb"
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData: clientData,
                facilityData: facilityData,
                mode: 'view',
              })
            }
          >
            {facilityData.name}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span
            className="crumb"
            onClick={() =>
              this.navigateToScreen(Routes.clientSystem, {
                clientData: clientData,
                facilityData: facilityData,
                systemData: systemData,
                mode: 'view',
              })
            }
          >
            {systemData && systemData.systemName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  filterGraph(graph) {
    const {
      systemState: { apiState: systemData },
    } = this.props;
    const { createSystemSchematic, editSystemSchematic } = this.props;
    if (systemData && graph) {
      if (this.handleNetwork()) {
        if (this.state.graph && this.state.graph._id) {
          editSystemSchematic(this.state.graph._id, {
            system: systemData.systemData._id,
            graph: graph.graph,
          });
        } else {
          graph.graph.length > 0
            ? createSystemSchematic({
                system: systemData.systemData._id,
                graph: graph.graph,
              })
            : Notification.show(Types.Info, SchematicMessages.CAN_NOT_SAVE);
        }
      }
    }
  }

  clearGraphChanged() {
    this.setState({
      isGraphChanged: false,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { demandPage, demandPageData } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.history.push(demandPage, demandPageData);
        this.disableEditForm();
      }
    );
  }

  getAssetData = data => {
    return data.map(item => item.name);
  };

  handleChange = e => {
    this.setState(prevState => {
      return {
        objects: prevState.copyObjects.filter(
          item => item.name.toLowerCase().indexOf(e.toLowerCase()) > -1
        ),
      };
    });
  };

  onSelect = data => {
    this.setState({
      addValue: data,
      dataSource: data,
    });
  };

  onSearch = data => {
    this.setState({
      addValue: data,
    });
  };

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const systemData = this.props.sideMenu
      ? this.props.systemData
      : this.props.location.state.systemData;
    const { isLoading } = this.props.systemSchematicState;
    return (
      <div className="ComponentSchemacic">
        {isLoading && <Loader />}
        <div className="ClientSystem">
          <div className="header" style={MaskStyle}>
            <div className="title">{SchematicVariables.SYSTEM_TITLE}</div>
            {this.props.sideMenu ? (
              <div className="pathSideMenu">
                {this.renderBreadCrumbSchematic()}
              </div>
            ) : (
              <div className="path">{this.renderBreadCrumb()}</div>
            )}
          </div>
          <div className="screen-content">
            <div className="content-div">
              {(this.state.objects || this.props.sideMenu) &&
              (!this.state.graph ||
                (this.state.graph &&
                  (this.state.graph.hasOwnProperty('deployed') ||
                    this.props.schematic !== 'system'))) ? (
                <GraphEditor
                  toolbar={true}
                  model={this.state.graph}
                  graphData={this.state.graphData}
                  configure={() => {}}
                  objects={
                    (this.state.graph && !this.state.graph.deployed) ||
                    !this.state.graph
                      ? elementsGaurd(
                          this.state.objects,
                          ElementPermissions.EDIT_SCHEMATIC,
                        )
                      : null
                  }
                  saveText={ButtonVariables.SAVE}
                  save={elementsGaurd(graph => {
                    this.filterGraph(graph);
                  }, ElementPermissions.EDIT_SCHEMATIC,
                  )}
                  systemId={systemData && systemData._id}
                  isGraphChanged={this.state.isGraphChanged}
                  schematic={'system'}
                  clearGraphChanged={() => this.clearGraphChanged()}
                  sideMenu={this.props.sideMenu}
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

SystemSchematic.prototypes = {
  network: PropTypes.object,
  assetState: PropTypes.object,
  schematicState: PropTypes.object,
  systemState: PropTypes.object,
  equipmentState: PropTypes.object,
  assetEquipmentState: PropTypes.object,
  systemSchematicState: PropTypes.object,
  getAsset: PropTypes.func,
  getSystem: PropTypes.func,
  clearAssetState: PropTypes.func,
  getSystemSchematic: PropTypes.func,
  createSystemSchematic: PropTypes.func,
  editSystemSchematic: PropTypes.func,
  clearSystemState: PropTypes.func,
  getEquipmentAsset: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

SystemSchematic.defaultProps = {
  network: {},
  assetState: {},
  schematicState: {},
  systemState: {},
  equipmentState: {},
  assetEquipmentState: {},
  systemSchematicState: {},
  getAsset: () => {},
  getSystem: () => {},
  clearAssetState: () => {},
  getSystemSchematic: () => {},
  createSystemSchematic: () => {},
  editSystemSchematic: () => {},
  clearSystemState: () => {},
  getEquipmentAsset: () => {},
  history: {},
  location: {},
  match: {},
};

const mapStateToProps = state => ({
  network: state.NetworkReducer,
  assetState: state.AssetReducer,
  schematicState: state.SchematicReducer,
  systemState: state.SystemReducer,
  equipmentState: state.EquipmentReducer,
  assetEquipmentState: state.assetEquipmentReducer,
  systemSchematicState: state.systemSchematicReducer,
});

const mapDispatchToProps = dispatch => ({
  getSystem: id => dispatch(getSystem(id)),
  getSystemSchematic: query => dispatch(getSystemSchematic(query)),
  createSystemSchematic: payload => dispatch(createSystemSchematic(payload)),
  editSystemSchematic: (id, payload) =>
    dispatch(editSystemSchematic(id, payload)),
  clearSystemState: () => dispatch(clearSystemState()),
  getEquipmentAsset: () => dispatch(getEquipment()),
  clearSystemSchematicState: () => dispatch(clearSystemSchematicState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SystemSchematic));
