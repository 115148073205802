import { Breadcrumb, Button, Form, Icon } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Table from '../../common/CustomTable';
import FormFields from '../../common/FormFields/FormFields';
import Loader from '../../common/Loader';
import {
  validateNegativeNumber,
  validatePositiveFloat,
} from '../../common/methods';
import ConfirmModal from '../../common/Modal/Modal';
import Notification, { Types } from '../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  ML_ALGO_MSG,
  NO_INTERNET,
} from '../../constants/messages';
import { MaskStyle } from '../../constants/react-style';
import {
  ButtonVariables,
  ML_ALGORITHM_VARIABLE,
  SideMenuVariables,
} from '../../constants/variables';
import {
  clientList,
  getClientInfo,
  resetClientReducer,
  clearClientState,
} from '../../redux/actions/client.action';
import {
  createMlModal,
  getModelVersion,
  getPiTagList,
  getPiTagListReset,
  updateMlModal,
} from '../../redux/actions/mlAlgorithm.action';
import { getVariableProperty } from '../../redux/actions/outputData.actions';
import {
  ADD_ALGO_FIELDS,
  ADD_ML_ROW,
  ML_DETAIL_TABLE,
  OUTPUT_ADD_ALGO,
  OUTPUT_PI_TAG,
  PREDICTED_TAG,
  calcFilterTag,
  WAVELET_TYPE,
} from './ML_Algorithm.constant';
import { elementsGaurd } from '../../gaurds';
import { ElementPermissions } from '../../permissions';

const PAGINATED_VALUE = 8000;
const INITIAL_SKIP = 0;
class AddAlgo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      editMode: false,
      skip: INITIAL_SKIP,
      clientId: '',
      facilityId: '',
      mlInputList: [],
      selectedPiTag: {},
      inputTag: [],
      outputTag: '',
      imageId: '',
      tag: {
        piTag: '',
        variableName: '',
        adder: null,
        maxThreshold: null,
        multiplier: null,
        minThreshold: null,
        variableProperty: '',
        units: '',
        _id: '',
      },
      modalId: '',
      isErrorOutput: false,
      selectedOutputTag: {},
      searchedPiTagList: [],
      formEdit: false,
      isEdited: false,
      visible: false,
      algoType: '',
      isRunStatus: false,
      mlTag: {
        piTag: '',
        variableName: '',
        _id: '',
      },
      equipmentId: '',
      equipmentsList: [],
      modelNumber: '',
      facilityList: [],
      isFilter: false,
      level: '',
      waveletType: '',
      isErrorLevel: false,
      constantToAdd: 0,
      isInfo: false
    };
  }

  componentWillUnmount() {
    this.props.form.resetFields();
    this.handleNetwork() && this.props.resetClientReducer();
    this.disableEditForm();
  }

  componentDidMount() {
    const { editModel } = this.props;

    window.addEventListener('form-edit', () => {
      this.setState({
        isEdited: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        isEdited: false,
      });
    });

    if (!_.isEmpty(editModel)) {
      this.setState(
        {
          editMode: true,
          isEdit: this.props.mode === 'view' ? false : true,
          imageId: editModel.imageId,
          inputTag: editModel.inputTags ? editModel.inputTags : [],
          outputTag: editModel.outputTag ? editModel.outputTag._id : '',
          clientId: editModel.clientId._id,
          mlInputList: editModel.inputTags ? editModel.inputTags : '',
          facilityId: editModel.facilityId._id,
          equipmentId:
            editModel.equipmentId && editModel.equipmentId._id
              ? editModel.equipmentId._id
              : '',
          tag: {
            _id: editModel.tag && editModel.tag._id,
            variableName:
              editModel.tag &&
              editModel.tag.variable &&
              editModel.tag.variable.variableName,
            piTag: editModel.tag && editModel.tag.piTag,
            minThreshold:
              editModel.tag &&
              parseFloat(editModel.tag.minThreshold).toString(),
            maxThreshold:
              editModel.tag &&
              parseFloat(editModel.tag.maxThreshold).toString(),
            multiplier:
              editModel.tag && parseFloat(editModel.tag.multiplier).toString(),
            adder: editModel.tag
              ? parseFloat(editModel.tag.adder).toString()
              : '0',
            variableProperty:
              editModel.tag && editModel.tag.variableProperty
                ? editModel.tag.variableProperty
                : '',
            units:
              editModel.tag && editModel.tag.units ? editModel.tag.units : '',
          },
          modalId: editModel._id,
          modelNumber: editModel.modelNumber,
          algoType: editModel.algoType,
          isRunStatus: editModel.isRunStatus,
          mlTag: {
            piTag: editModel.mlTag && editModel.mlTag.piTag,
            variableName:
              editModel.mlTag && editModel.mlTag.variable.variableName,
            _id: editModel.mlTag && editModel.mlTag._id,
            adder:
              editModel.algoType === 'autoencoder' &&
              (editModel.mlTag && editModel.mlTag.adder
                ? editModel.mlTag.adder.toString()
                : '0'),
            multiplier:
              editModel.algoType === 'autoencoder' &&
              editModel.mlTag &&
              editModel.mlTag.multiplier &&
              editModel.mlTag.multiplier.toString(),
          },
          isFilter: editModel?.isFilter || false,
          waveletType: editModel?.levelName || '',
          level: editModel?.levelId || '',
          constantToAdd: parseFloat(editModel?.constantToAdd) || 0,
          isInfo: editModel?.isInfo || false
        },
        () => {
          this.props.form.setFieldsValue({
            ['imageId']: editModel.imageId, // eslint-disable-line
            ['name']: editModel.name, // eslint-disable-line
            ['algoType']: editModel.algoType, // eslint-disable-line
            ['clientId']: editModel.clientId.name, // eslint-disable-line
            ['codeVersion']: editModel.codeVersion, // eslint-disable-line
            ['facilityId']: editModel.facilityId.name, // eslint-disable-line
            equipmentId:
              editModel.equipmentId && editModel.equipmentId.name
                ? editModel.equipmentId.name
                : '',
            predictedTag:
              editModel.mlTag && editModel.mlTag.piTag
                ? editModel.mlTag.piTag
                : '',
            predictedVariableName:
              editModel.mlTag && editModel.mlTag.variable.variableName
                ? editModel.mlTag.variable.variableName
                : '',
            ['outputTag']: editModel.outputTag ? editModel.outputTag.piTag : '', // eslint-disable-line
            ['piTagName']: editModel.outputTag // eslint-disable-line
              ? editModel.outputTag.piVariableName
              : '',
            isRunStatus:
              editModel && editModel.isRunStatus
                ? editModel.isRunStatus
                : false,
            ['modelId']: editModel.modelNumber // eslint-disable-line
              ? `ML-${editModel.modelNumber}`
              : '',
            constantToAdd: parseFloat(editModel?.constantToAdd) || 0,
            isInfo: editModel?.isInfo || false
          });
          this.disableEditForm();
        }
      );

      this.handleNetwork() && this.props.getClientInfo(editModel.clientId._id);
    }
    this.handleNetwork() && this.props.clientList({});

    this.setEquipmentsList();
    if (this.state.facilityId && this.state.equipmentId) {
      this.handleNetwork() &&
        this.props.getPiTagList(this.state.facilityId, this.payloadPiTagList());
    }
    this.handleNetwork() && this.props.getModelVersion();
    this.disableEditForm();
  }

  setEquipmentsList = () => {
    const { clientInfoData } = this.props.clientState.apiState;
    const equipmentsList = [];
    if (!_.isEmpty(clientInfoData)) {
      if (!_.isEmpty(clientInfoData.systems)) {
        const systems = _.groupBy(clientInfoData.systems, 'facilityId');

        if (!_.isEmpty(clientInfoData.equipments)) {
          const equipments = _.groupBy(clientInfoData.equipments, 'systemId');

          systems[this.state.facilityId] &&
            systems[this.state.facilityId].forEach((systemId) => {
              equipments[systemId._id] &&
                equipments[systemId._id].forEach((equipment) => {
                  equipmentsList.push(_.cloneDeep(equipment));
                });
            });
        }
      }
    }
    this.setState({ equipmentsList });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      saveModalReducer,
      getPiTagListReset,
      getPiTagList,
      updateReducer,
      piTagList,
      clientState,
      clearClientState,
    } = this.props;

    if (
      this.state.facilityId &&
      this.state.facilityId !== prevState.facilityId
    ) {
      this.setEquipmentsList();
    }

    if (
      this.state.equipmentId &&
      this.state.equipmentId !== prevState.equipmentId
    ) {
      if (this.state.facilityId) {
        getPiTagListReset();
        this.handleNetwork() &&
          getPiTagList(this.state.facilityId, this.payloadPiTagList());
      }
    }
    if (
      saveModalReducer.isSuccess &&
      saveModalReducer.isSuccess !== prevProps.saveModalReducer.isSuccess
    ) {
      Notification.show(Types.Success, ML_ALGO_MSG.CREATED_MODEL_SUCCESS);
      this.props.goBackTOMlList({});
    } else if (
      saveModalReducer.isError &&
      saveModalReducer.isError !== prevProps.saveModalReducer.isError
    ) {
      Notification.show(Types.Error, ML_ALGO_MSG[saveModalReducer.errorCode]);
    }

    if (
      piTagList.isError &&
      piTagList.isError !== prevProps.piTagList.isError
    ) {
      Notification.show(Types.Error, ML_ALGO_MSG.ERROR_PI_TAG_LIST_FETCH);
    } else if (
      piTagList.isSuccess &&
      piTagList.isSuccess !== prevProps.piTagList.isSuccess
    ) {
      this.setState({ searchedPiTagList: piTagList.data });
    }

    if (
      updateReducer.isSuccess &&
      updateReducer.isSuccess !== prevProps.updateReducer.isSuccess
    ) {
      this.props.goBackTOMlList({});
      Notification.show(Types.Success, ML_ALGO_MSG.UPDATE_SUCCESS);
    } else if (
      updateReducer.isError &&
      updateReducer.isError !== prevProps.updateReducer.isError
    ) {
      Notification.show(Types.Error, updateReducer.message);
    }
    if (
      /**TODO: for autoencoder */
      this.state.algoType &&
      this.state.algoType !== 'autoencoder' &&
      this.state.algoType !== prevState.algoType
    ) {
      this.handleNetwork() && this.props.getVariableProperty();
    }
    if (clientState.apiState.isSuccessClientsInfo) {
      clearClientState();
      const facility = [];
      const facilities = clientState.apiState.clientInfoData.facilities;
      facilities.forEach((element) => {
        const facilityId = element['_id'];
        if (
          elementsGaurd(true, ElementPermissions.ADD_ML_ALGO, {
            type: 'facility',
            dataRef: {
              _id: facilityId,
            },
          })
        ) {
          facility.push(element);
        }
      });
      this.setState({ facilityList: facility });
      this.setEquipmentsList();
    }
  }

  payloadPiTagList = () => {
    return {
      allType: true,
      skip: 0,
      limit: PAGINATED_VALUE,
    };
  };

  handleNetwork = () => {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      this.setState({ network: false });
      return false;
    }
  };

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  initialInput = () => {
    this.props.form.resetFields(['outputTag', 'piTag', 'piTagName']);
    this.setState({
      tag: {
        piTag: '',
        variableName: '',
        adder: null,
        maxThreshold: null,
        multiplier: null,
        minThreshold: null,
      },
      mlInputList: [],
      selectedPiTag: {},
      inputTag: [],
      selectedOutputTag: {},
    });
  };

  handleChange = (value, name) => {
    this.enableEditForm();
    if (name === 'clientId') {
      this.handleNetwork() && this.props.getClientInfo(value);
      this.props.form.resetFields(['facilityId', 'equipmentId']);
      this.initialInput();
      this.props.getPiTagListReset();
      this.setState({
        isEdited: true,
        [name]: value,
        facilityId: 'disabled',
        equipmentId: '',
      });
    } else if (name === 'algoType') {
      this.props.form.resetFields(['codeVersion']);
      this.setState({
        isEdited: true,
        [name]: value,
      });
    } else if (name === 'facilityId') {
      this.props.form.resetFields(['equipmentId']);
      this.initialInput();
      this.setState({
        isEdited: true,
        [name]: value,
        equipmentId: '',
      });
    } else if (name === 'equipmentId') {
      this.initialInput();
      this.setState({
        isEdited: true,
        [name]: value,
      });
    } else if (name === 'runStatusCheck') {
      this.setState({
        isEdited: true,
        isRunStatus: value,
      });
    } else {
      this.setState({ [name]: value, isEdited: true });
    }
  };

  piTagSelected = (value) => {
    const piTag = JSON.parse(value);
    this.enableEditForm();
    this.setState({
      isEdited: true,
      selectedPiTag: piTag,
    });
  };

  addInputTag = () => {
    const isPiTagPreSelected = this.state.mlInputList.find((mlInput) => {
      return mlInput._id === this.state.selectedPiTag._id;
    });
    if (!isPiTagPreSelected) {
      let tempList = this.state.mlInputList;
      tempList.push(this.state.selectedPiTag);
      this.props.form.resetFields(['piTag']);
      this.setState({
        mlInputList: tempList,
        selectedPiTag: {},
      });
    } else {
      this.props.form.resetFields(['piTag']);
      this.setState({ selectedPiTag: {} });
      Notification.show(Types.Error, ML_ALGO_MSG.EXISTING_PI_TAG);
    }
  };

  deletedPiTag = (_item, index) => {
    let tempList = this.state.mlInputList;
    tempList.splice(index, 1);
    this.enableEditForm();
    this.setState({ mlInputList: tempList, isEdited: true });
  };

  outputChange = (e, name) => {
    this.enableEditForm();
    if (this.state.algoType !== 'autoencoder') {
      if (name === 'variableProperty') {
        this.setState(
          {
            isEdited: true,
            tag: {
              ...this.state.tag,
              [name]: e,
              units: '',
            },
          },
          () => this.validateFieldsValue()
        );
      } else if (name === 'waveletType') {
        this.setState({ waveletType: e });
      } else if (name) {
        this.setState(
          {
            isEdited: true,
            tag: {
              ...this.state.tag,
              [name]: e,
            },
          },
          () => this.validateFieldsValue()
        );
      } else {
        this.setState(
          {
            isEdited: true,
            tag: {
              ...this.state.tag,
              [e.target.name]: e.target.value,
            },
          },
          () => this.validateFieldsValue()
        );
      }
    } else {
      if (name) {
        this.setState(
          {
            isEdited: true,
            mlTag: {
              ...this.state.mlTag,
              [name]: e,
            },
          },
          () => this.validateFieldsValue()
        );
      } else {
        this.setState(
          {
            isEdited: true,
            mlTag: {
              ...this.state.mlTag,
              [e.target.name]: e.target.value,
            },
          },
          () => this.validateFieldsValue()
        );
      }
    }
  };

  outputTagSelected = (value) => {
    const { modelNumber } = this.props.editModel;
    const piTag = JSON.parse(value);
    this.enableEditForm();
    this.setState({
      selectedOutputTag: piTag,
      isEdited: true,
      outputTag: piTag._id,
      mlTag: {
        ...this.state.mlTag,
        piTag: `${'ML-'}${modelNumber}${'-'}${piTag.piTag}`,
      },
      tag: {
        ...this.state.tag,
        piTag: `${'MCAL-'}${modelNumber}${'-'}${piTag.piTag}`,
      },
    });
    this.props.form.setFieldsValue({
      ['predictedTag']: `${'ML-'}${modelNumber}${'-'}${piTag.piTag}`, // eslint-disable-line
      ['piTagName']: piTag.piVariableName, // eslint-disable-line
    });
  };

  handleMlModalSave = () => {
    const { mlInputList, tag, isFilter, waveletType, level } = this.state;
    this.props.form.validateFields((err, values) => {
      const value = _.cloneDeep(values);
      if (!err) {
        delete value['piTag'];
        delete value['clientId'];
        delete value['facilityId'];
        delete value['equipmentId'];
        delete value['piTagName'];
        delete value['predictedTag'];
        delete value['predictedVariableName'];
        delete value['modelId'];
        if (_.isEmpty(mlInputList)) {
          Notification.show(Types.Error, ML_ALGO_MSG.NO_INPUT_TAG_SELECTED);
        } else if (!this.validateFieldsValue().status) {
          if (this.state.algoType === 'autoencoder') {
            this.validateFieldsValue().error
              ? Notification.show(Types.Error, ML_ALGO_MSG.INVALID_INPUT)
              : Notification.show(Types.Error, ML_ALGO_MSG.MANDATORY_FIELDS);
          } else {
            this.validateFieldsValue().error
              ? Notification.show(Types.Error, ML_ALGO_MSG.INVALID_INPUT)
              : Notification.show(Types.Error, ML_ALGO_MSG.FILED_EMPTY);
          }
        } else if (!tag.piTag && this.state.algoType !== 'autoencoder') {
          Notification.show(Types.Error, ML_ALGO_MSG.NO_OUTPUT_TAG_SELECTED);
        } else if (
          parseFloat(tag.minThreshold) >= parseFloat(tag.maxThreshold)
        ) {
          Notification.show(Types.Error, ML_ALGO_MSG.BOUND_ERROR_MSG);
          this.setState({ isErrorOutput: true });
        } else if (isFilter && (waveletType === '' || level === '')) {
          Notification.show(Types.Error, ML_ALGO_MSG.WAVELET_LEVEL_MANDATORY);
          this.setState({ isErrorLevel: true });
        } else if (isFilter && (level < 1 || level > 5)) {
          Notification.show(Types.Error, ML_ALGO_MSG.LEVEL_ERROR);
          this.setState({ isErrorLevel: true });
        } else {
          const tempInputTag = [];
          mlInputList.forEach((item) => {
            tempInputTag.push(item._id);
          });
          /**case of autoencoder */
          if (this.state.algoType === 'autoencoder') {
            if (
              _.isFinite(parseFloat(this.state.mlTag.adder)) &&
              _.isFinite(parseFloat(this.state.mlTag.multiplier))
            ) {
              const payload = {
                ...value,
                mlTag: {
                  ...this.state.mlTag,
                  piTag: `ML-${this.state.modelNumber}-LOSS`,
                  variableName: this.state.mlTag.variableName,
                  adder: +this.state.mlTag.adder,
                  multiplier: +this.state.mlTag.multiplier,
                },
                inputTags: tempInputTag,
                isFilter: isFilter,
                levelName: waveletType,
                levelId: level.toString() || '',
              };

              this.handleNetwork() &&
                this.props.updateMlModal({
                  id: this.state.modalId,
                  payload,
                });
            } else {
              Notification.show(Types.Error, ML_ALGO_MSG.MANDATORY_FIELDS);
            }
          } else {
            /** For algoType h2o keras sklearn and xg_boost */

            const tempMltag = _.cloneDeep(this.state.mlTag);
            const { constantToAdd } = this.state;
            delete tempMltag.adder;
            delete tempMltag.multiplier;

            const payload = {
              ...value,
              tag: {
                _id: this.state.tag._id,
                piTag: this.state.tag.piTag,
                maxThreshold: this.state.tag.maxThreshold,
                minThreshold: this.state.tag.minThreshold,
                variableName: this.state.tag.variableName,
                variableProperty: this.state.tag.variableProperty,
                units: this.state.tag.units,
              },
              mlTag: {
                ...tempMltag,
              },
              inputTags: tempInputTag,
              outputTag: this.state.outputTag,
              isFilter: isFilter,
              levelName: waveletType,
              levelId: level.toString() || '',
              constantToAdd: parseFloat(constantToAdd) || 0,
              isInfo: this.state.isInfo
            };
            this.handleNetwork() &&
              this.props.updateMlModal({
                id: this.state.modalId,
                payload,
              });
          }
        }
      }
    });
  };

  resetField = () => {
    this.setState({ searchedPiTagList: this.props.piTagList.data });
  };

  cancelEdit = () => {
    if (this.state.isEdited) {
      this.setState({
        visible: true,
      });
    } else {
      this.props.goBackTOMlList({});
    }
  };

  cancelUpdate = () => {
    if (this.state.isEdited) {
      this.setState({
        visible: true,
        cancelUpdate: true,
      });
    } else {
      this.props.goBackTOMlList({});
    }
  };

  createModel = () => {
    const { clientId, facilityId, equipmentId } = this.state;
    this.validateFieldsValue();
    this.props.form.validateFields((err, value) => {
      const values = _.cloneDeep(value);
      if (!err) {
        delete values['clientId'];
        delete values['facilityId'];
        delete values['equipmentId'];
        this.handleNetwork() &&
          this.props.createMlModal({
            clientId,
            facilityId,
            equipmentId,
            payload: { equipmentId, ...values },
          });
      }
    });
  };

  validateFieldsValue = () => {
    let status = true;
    let error = null;
    let values = this.props.form.getFieldsValue();
    const { tag, mlTag } = this.state;
    let valuesKey = Object.keys(this.props.form.getFieldsValue());
    for (let i = 0; i < valuesKey.length; i++) {
      if (typeof values[valuesKey[i]] === 'string') {
        values[valuesKey[i]] = values[valuesKey[i]]
          ? values[valuesKey[i]].trim()
          : values[valuesKey[i]];
      }
    }

    this.props.form.setFieldsValue(values);
    if (this.props.editModel && this.state.algoType !== 'autoencoder') {
      let tagVariableName = tag?.variableName ? tag.variableName : '';
      let mlTagVariableName = mlTag?.variableName ? mlTag.variableName : '';
      if (
        _.isEmpty(tag.maxThreshold) ||
        _.isEmpty(tag.minThreshold) ||
        _.isEmpty(tagVariableName.trim()) ||
        _.isEmpty(this.state.mlInputList) ||
        _.isEmpty(tag.units) ||
        _.isEmpty(tag.variableProperty) ||
        _.isEmpty(mlTagVariableName.trim())
      ) {
        status = false;
        this.setState({
          isErrorOutput: true,
        });
      } else {
        status = true;
      }
    } else if (this.props.editModel && this.state.algoType === 'autoencoder') {
      if (
        _.isEmpty(mlTag.multiplier) ||
        _.isEmpty(mlTag.adder) ||
        _.isEmpty(mlTag.variableName)
      ) {
        status = false;
        this.setState({
          isErrorOutput: true,
        });
      } else if (
        (mlTag.multiplier && !validatePositiveFloat(mlTag.multiplier)) ||
        (mlTag.adder && validateNegativeNumber(mlTag.adder))
      ) {
        error = 'INVALID_INPUT';
        status = false;
        this.setState({
          isErrorOutput: true,
        });
      } else {
        status = true;
      }
    }

    return { status, error };
  };

  mlVarName = (e) => {
    this.enableEditForm();
    this.setState({
      isEdited: true,
      mlTag: {
        ...this.state.mlTag,
        variableName: e.target.value,
      },
    });
  };

  flipFilter = (e) => {
    this.enableEditForm();
    this.setState({ isFilter: e });
  };

  levelChange = (e) => {
    if (e.target.value < 1 || e.target.value > 5) {
      this.setState({ isErrorLevel: true });
    }
    this.enableEditForm();
    this.setState({ level: e.target.value });
  };

  handleConstantChange = (e) => {
    this.setState({
      constantToAdd: e.target.value,
    });
  };

  flipIsInfo = (value) => {
    this.setState({
      isInfo: value
    });
  }

  render() {
    const { clientList } = this.props.clientState.apiState;
    const {
      facilityList,
      waveletType,
      isFilter,
      level,
      isErrorLevel,
    } = this.state;
    const {
      saveModalReducer,
      updateReducer,
      deployReducer,
      modelVersions,
      editModel,
    } = this.props;
    return (
      <div className="ml-algo-wrapper upload-pi-tags-wrapper ClientDetails">
        {((saveModalReducer && saveModalReducer.isLoading) ||
          (updateReducer && updateReducer.isLoading) ||
          (deployReducer && deployReducer.isLoading)) && <Loader />}
        <div className="header-content">
          <div className="pi-tag-header " style={MaskStyle}>
            <div className="pi-tag-title">
              <div className="header-redirect" onClick={this.cancelEdit}>
                <Icon type="left" className="back-icon" />
                <span>
                  {_.isEmpty(editModel)
                    ? ML_ALGORITHM_VARIABLE.ADD_ALGO_TITLE
                    : this.state.isEdit
                    ? ML_ALGORITHM_VARIABLE.EDIT_ALGO_TITLE
                    : ML_ALGORITHM_VARIABLE.VIEW_ALGO}
                </span>
              </div>
              <div className="path">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <label className="crumb" onClick={this.cancelEdit}>
                      {SideMenuVariables.ML_ALGORITHM}
                    </label>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <label className="crumb">
                      {_.isEmpty(editModel)
                        ? ML_ALGORITHM_VARIABLE.ADD_ALGO_TITLE
                        : this.state.isEdit
                        ? ML_ALGORITHM_VARIABLE.EDIT_ALGO_TITLE
                        : ML_ALGORITHM_VARIABLE.VIEW_ALGO}
                    </label>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
        <div className="screen-content">
          <div className="content-div">
            <div className="add-ml-algo-modal ">
              <FormFields
                formFields={ADD_ALGO_FIELDS({
                  clients:
                    clientList && clientList.items ? clientList.items : [],
                  facilities: facilityList,
                  equipmentsList: this.state.equipmentsList,
                  isClientSelected: _.isEmpty(this.state.clientId),
                  changeClient: (id) => this.handleChange(id, 'clientId'),
                  changeAlgoType: (id) => this.handleChange(id, 'algoType'),
                  changeFacility: (id) => this.handleChange(id, 'facilityId'),
                  changeCodeVersion: (id) =>
                    this.handleChange(id, 'codeVersion'),
                  changeEquipment: (id) => this.handleChange(id, 'equipmentId'),
                  changeName: (id) => this.handleChange(id, 'name'),
                  changeFile: (id) => this.handleChange(id, 'imageId'),
                  changeRunStatus: (id) =>
                    this.handleChange(id, 'runStatusCheck'),
                  checked: this.state.isRunStatus,
                  version: modelVersions.data,
                  disabled:
                    this.state.editMode && !this.state.isEdit ? true : false,
                  algoType: this.state.algoType,
                  editMode: this.state.editMode,
                  fileStatus: editModel && editModel.fileStatus,
                  algoList: modelVersions.algoList,
                  isInfo: this.state.isInfo,
                  flipIsInfo: this.flipIsInfo,
                })}
                formDisable={
                  this.state.editMode && !this.state.isEdit ? true : false
                }
                form={this.props.form}
              />
            </div>
            {!_.isEmpty(this.props.editModel) && (
              <>
                <div className="ml-output">
                  {this.state.algoType !== 'autoencoder' && (
                    <h3>{ML_ALGORITHM_VARIABLE.ML_TAG}</h3>
                  )}
                  {this.state.algoType !== 'autoencoder' && <hr />}
                  {this.state.algoType !== 'autoencoder' && (
                    <FormFields
                      formFields={_.cloneDeep(
                        OUTPUT_PI_TAG({
                          piTagList: this.state.searchedPiTagList,
                          change: this.outputTagSelected,
                          piVariableName:
                            this.state.selectedOutputTag &&
                            this.state.selectedOutputTag.piVariableName,
                          resetField: this.resetField,
                          disabled:
                            this.state.editMode && !this.state.isEdit
                              ? true
                              : false,
                          algoType: this.state.algoType,
                        })
                      )}
                      form={this.props.form}
                    />
                  )}
                  {this.state.algoType !== 'autoencoder' && (
                    <h3>{ML_ALGORITHM_VARIABLE.PREDICTED_TAG}</h3>
                  )}
                  {this.state.algoType !== 'autoencoder' && <hr />}
                  {this.state.algoType !== 'autoencoder' && (
                    <FormFields
                      formFields={PREDICTED_TAG({
                        mlVarName: this.mlVarName,
                        disabled: !this.state.isEdit,
                        handleConstantChange: this.handleConstantChange,
                      })}
                      form={this.props.form}
                    />
                  )}
                  {
                    <Table
                      showHeader={true}
                      columns={calcFilterTag({
                        flipFilter: this.flipFilter,
                        isFilter: isFilter,
                        levelChange: this.levelChange,
                        outputChange: this.outputChange,
                        level: level,
                        waveletTypeOptions: WAVELET_TYPE,
                        waveletType: waveletType,
                        isError: isErrorLevel,
                        disabled: !this.state.isEdit,
                      })}
                      data={
                        this.state.algoType === 'autoencoder'
                          ? [
                              this.state.mlTag._id
                                ? this.state.mlTag
                                : {
                                    ...this.state.mlTag,
                                    piTag: `ML-${this.state.modelNumber}-LOSS`,
                                  },
                            ]
                          : [this.state.tag]
                      }
                    />
                  }
                  {!_.isEmpty(this.props.editModel) && (
                    <>
                      {this.state.algoType === 'autoencoder' ? (
                        <h3>{'Loss'}</h3>
                      ) : (
                        <h3>{ML_ALGORITHM_VARIABLE.CALCULATED_TAG}</h3>
                      )}
                      <hr />
                      <Table
                        showHeader={true}
                        columns={OUTPUT_ADD_ALGO({
                          outputPiTag: _.isEmpty(this.state.outputPiTag)
                            ? ''
                            : this.state.outputPiTag.piVariableName,
                          outputChange: this.outputChange,
                          isError: this.state.isErrorOutput,
                          disabled:
                            this.state.editMode && !this.state.isEdit
                              ? true
                              : false,
                          algoType: this.state.algoType,
                          piTag: this.state.selectedOutputTag,
                          variableProperties:
                            this.props.variableProperty.apiState &&
                            this.props.variableProperty.apiState.variableData &&
                            this.props.variableProperty.apiState.variableData
                              .items,
                        })}
                        data={
                          this.state.algoType === 'autoencoder'
                            ? [
                                this.state.mlTag._id
                                  ? this.state.mlTag
                                  : {
                                      ...this.state.mlTag,
                                      piTag: `ML-${this.state.modelNumber}-LOSS`,
                                    },
                              ]
                            : [this.state.tag]
                        }
                      />
                    </>
                  )}
                </div>

                <div className="ml-input">
                  <h3>Input Tag</h3>
                  <hr />
                  <Table
                    showHeader={true}
                    columns={ML_DETAIL_TABLE({
                      delete: this.deletedPiTag,
                      disabled:
                        this.state.editMode && !this.state.isEdit
                          ? true
                          : false,
                      algoType: this.state.algoType,
                    })}
                    data={this.state.mlInputList}
                    renderAddRow={
                      this.state.editMode &&
                      this.state.isEdit &&
                      ADD_ML_ROW({
                        form: this.props.form,
                        piTagList: this.state.searchedPiTagList,
                        addInputTag: this.addInputTag,
                        piTagSelected: this.piTagSelected,
                        selectedPiTag: this.state.selectedPiTag,
                        resetField: this.resetField,
                        disabled:
                          this.state.editMode && !this.state.isEdit
                            ? true
                            : false,
                        algoType: this.state.algoType,
                        formDisable:
                          this.state.editMode && !this.state.isEdit
                            ? true
                            : false,
                      })
                    }
                  />
                </div>
              </>
            )}
            <div className="pi-tag-table-footer">
              {!this.state.editMode && (
                <>
                  <Button
                    className={'btn-transparent'}
                    onClick={this.cancelUpdate}
                  >
                    {ButtonVariables.CANCEL}
                  </Button>
                  <Button className="btn-default" onClick={this.createModel}>
                    {ButtonVariables.SAVE}
                  </Button>
                </>
              )}
              {this.state.isEdit && this.state.editMode && this.state.isEdited && (
                <div>
                  <Button
                    className={'btn-transparent'}
                    onClick={this.cancelUpdate}
                  >
                    {ButtonVariables.CANCEL}
                  </Button>
                  <Button
                    className="btn-default"
                    onClick={this.handleMlModalSave}
                    disabled={!this.state.isEdited}
                  >
                    {ButtonVariables.UPDATE}
                  </Button>
                </div>
              )}
            </div>
            {this.state.visible && (
              <ConfirmModal
                title={this.state.cancelUpdate && DISCARD_CHANGES}
                visible={this.state.visible}
                handleOk={() => this.props.goBackTOMlList({})}
                handleCancel={() =>
                  this.setState({ visible: false, cancelUpdate: false })
                }
                message={this.state.cancelUpdate && FORM_DISCARD_MSG}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddAlgo.propTypes = {
  form: PropTypes.object.isRequired,
  goBackTOMlList: PropTypes.func.isRequired,
  editModel: PropTypes.object.isRequired,
  clientState: PropTypes.object.isRequired,
  loginState: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  piTagList: PropTypes.object.isRequired,
  saveModalReducer: PropTypes.object.isRequired,
  updateReducer: PropTypes.object.isRequired,
  clientList: PropTypes.func.isRequired,
  getPiTagList: PropTypes.func.isRequired,
  createMlModal: PropTypes.func.isRequired,
  updateMlModal: PropTypes.func.isRequired,
  getClientInfo: PropTypes.func.isRequired,
  resetClientReducer: PropTypes.func.isRequired,
  getPiTagListReset: PropTypes.func.isRequired,
  getVariableProperty: PropTypes.func,
  getModelVersion: PropTypes.func,
  clearClientState: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  network: state.NetworkReducer,
  piTagList: state.MlAlgorithmReducer.piTagListAll,
  saveModalReducer: state.MlAlgorithmReducer.createMlModal,
  updateReducer: state.MlAlgorithmReducer.updateMlModal,
  modelVersions: state.MlAlgorithmReducer.modelVersions,
  variableProperty: state.outputDataReducer,
});

const mapDispatchToProps = {
  clientList,
  getPiTagList,
  createMlModal,
  updateMlModal,
  getClientInfo,
  resetClientReducer,
  getPiTagListReset,
  getVariableProperty,
  getModelVersion,
  clearClientState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'addAlgo',
    onFieldsChange: () => {
      window.dispatchEvent(new Event('form-edit'));
    },
  })(AddAlgo)
);
