import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import './AlertPlot.scss';
import _ from 'lodash';
import { getAletPlotByAlertId } from '../../../../redux/actions/alertDetails.action';
import MultiTimeVarientLineGraph from '../../../../common/Graphs/MultiTimeVariantLineGraph';

class AlertPlot extends React.Component {
  componentDidMount() {
      this.props.getAletPlotByAlertId(this.props.alertId);
  }

  dataSeries = (data) => {
    let series = _.cloneDeep(data);

    series.forEach((tag, index) => {
      tag.data.forEach((point, i) => {
        series[index].data[i][0] = new Date(point[0]).getTime();
      });
    });

    return series;
  }

  render() {
    const { data } = this.props;
    return (
      <div className={'alertPlot'}>
        {/* <div className={'title'}>Plot</div> */}
        <div className={'chart'}>
          <br />
          {data && <MultiTimeVarientLineGraph alertPlot={true} yAxisLabel ={this.props.unit} series={this.dataSeries(data)} alertPrint = {this.props.alertPrint}/>}
        </div>
      </div>
    );
  }
}

AlertPlot.prototypes = {
  alertId: PropTypes.string,
  alertPlotReducer: PropTypes.object,
  getAletPlotByAlertId: PropTypes.func
}

AlertPlot.defaultProps = {
  alertId: '',
  alertPlotReducer: {},
  getAletPlotByAlertId: () => { }
}
const mapStateToProps = state => ({
  data: state.AlertPlotReducer.data,
  isSuccess: state.AlertPlotReducer.isSuccess
});
const mapDispatchToProps = {
  getAletPlotByAlertId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertPlot);
