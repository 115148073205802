export const CURVES_ADD_EDIT_SUCCESS = "CURVES_ADD_EDIT_SUCCESS";
export const CURVES_ADD_EDIT_ERROR = "CURVES_ADD_EDIT_ERROR";
export const CURVES_ADD_EDIT_PROGRESS = "CURVES_ADD_EDIT_PROGRESS";
export const CURVES_CLEAR_STATE = "CURVES_CLEAR_STATE";

export const COMPRESSOR_CURVES_ADD_EDIT_SUCCESS =
  "COMPRESSOR_CURVES_ADD_EDIT_SUCCESS";
export const COMPRESSOR_CURVES_ADD_EDIT_ERROR =
  "COMPRESSOR_CURVES_ADD_EDIT_ERROR";
export const COMPRESSOR_CURVES_ADD_EDIT_PROGRESS =
  "COMPRESSOR_CURVES_ADD_EDIT_PROGRESS";
export const COMPRESSOR_CURVES_CLEAR_STATE = "COMPRESSOR_CURVES_CLEAR_STATE";

export const COMPRESSOR_CURVES_FETCH_SUCCESS =
  "COMPRESSOR_CURVES_FETCH_SUCCESS";
export const COMPRESSOR_CURVES_FETCH_ERROR = "COMPRESSOR_CURVES_FETCH_ERROR";
export const COMPRESSOR_CURVES_FETCH_PROGRESS =
  "COMPRESSOR_CURVES_FETCH_PROGRESS";
export const COMPRESSOR_CURVES_FETCH_CLEAR_STATE =
  "COMPRESSOR_CURVES_FETCH_CLEAR_STATE";

export const COMPRESSOR_CURVES_DELETE_SUCCESS =
  "COMPRESSOR_CURVES_DELETE_SUCCESS";
export const COMPRESSOR_CURVES_DELETE_ERROR = "COMPRESSOR_CURVES_DELETE_ERROR";
export const COMPRESSOR_CURVES_DELETE_PROGRESS =
  "COMPRESSOR_CURVES_DELETE_PROGRESS";
export const COMPRESSOR_CURVES_DELETE_CLEAR_STATE =
  "COMPRESSOR_CURVES_DELETE_CLEAR_STATE";
