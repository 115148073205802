import * as Types from "../Types/settings.types";

const INITIAL_API_STATE = {
  addData: null,
  isAddError: false,
  isAddSuccess: false,
  errorCode: null,
  message: null,
};

const getDriveType = {
  isLoading: false,
  isError: false,
  errorCode: null,
  message: null,
  isSuccess: false,
  data: [],
};

const INITIAL_STATE = {
  loading: false,
  apiState: {
    ...INITIAL_API_STATE,
    getDriveType,
  },
};

export default function settingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_DRIVE_TYPE_PROGRESS:
      return {
        ...state,
        loading: true,
        // apiState: {
        //   ...INITIAL_API_STATE,
        //   getDriveType: {
        //     ...getDriveType,
        //   },
        // },
      };

    case Types.GET_DRIVE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          getDriveType: {
            ...getDriveType,
            data: action.payload,
            isSuccess: true,
          },
        },
      };

    case Types.GET_DRIVE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          getDriveType: {
            ...getDriveType,
            isError: true,
            errorCode:
              action.payload.response && action.payload.response.body.code,
            message:
              action.payload.response && action.payload.response.body.message,
          },
        },
      };

    case Types.CLEAR_DRIVE_TYPE_SUCCESS_STATE:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          getDriveType: {
            ...state.apiState.getDriveType,
            isSuccess: false,
          },
        },
      };

    case Types.ADD_TYPE_PROGRESS:
      return {
        ...state,
        loading: true,
        apiState: {
          ...INITIAL_API_STATE,
          getDriveType,
        },
      };

    case Types.ADD_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          getDriveType,
          addData: action.payload,
          isAddSuccess: true,
        },
      };

    case Types.ADD_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...INITIAL_API_STATE,
          getDriveType,
          isAddError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    default:
      return {
        ...INITIAL_STATE,
      };
  }
}
