import {
  NaturalNumber,
  NegativeNumber,
  positiveInteger,
  PositiveNumber,
  NonNegativeNumber,
  FloatNumber,
} from "../constants/pattern";

export const populateInitialValues = (filtersLabels, value) => {
  const filters = {};
  for (let i = 0; i < filtersLabels.length; i += 1) {
    Object.assign(filters, { [filtersLabels[i]]: !!value });
  }
  return filters;
};

/**
 * sort the list ignoring case sensitive
 */
export const handleSort = (list, sortBy = null) =>
  list.sort((a, b) => {
    let item1 = null;
    let item2 = null;
    if (sortBy) {
      item1 = a[sortBy].toUpperCase();
      item2 = b[sortBy].toUpperCase();
    } else {
      item1 = a.toUpperCase();
      item2 = b.toUpperCase();
    }
    if (item1 < item2) {
      return -1;
    }
    if (item1 > item2) {
      return 1;
    }
    return 0;
  });

/** to check number >=1 */
export const validateNaturalNumber = (value) => {
  value = value && value.trim();

  if (value && value.length === 1 && value === ".") {
    return false;
  }
  if (parseFloat(value) === 0) {
    return false;
  }
  if (NaturalNumber.test(value)) {
    return true;
  }
  return false;
};

/** to check number >=0 */
export const validateWholeNumber = (value) => {
  value = value.trim();

  if (value.length === 1 && value === ".") {
    return false;
  }
  if (NaturalNumber.test(value)) {
    return true;
  }
  return false;
};

/** to check negative number  */
export const validateNegativeNumber = (value) => {
  value = value.trim();

  if (value.length === 1 && (value === "." || value === "-")) {
    return false;
  }
  if (value.length === 2 && value === "-.") {
    return false;
  }
  if (NegativeNumber.test(value)) {
    return true;
  }
  return false;
};

export const validatePositiveFloat = (value) => {
  value = value.trim();
  if (PositiveNumber.test(value)) {
    return true;
  }
  return false;
};

export const validateFloat = (value) => {
  value = value.trim();
  if (FloatNumber.test(value)) {
    return true;
  }
  return false;
};

export const validateNonNegativeFloat = (value) => {
  value = value.trim();
  if (NonNegativeNumber.test(value)) {
    return true;
  }
  return false;
};

export const validatePositiveIntegers = (value) => {
  value = value.trim();
  if (positiveInteger.test(value)) {
    return true;
  }
  return false;
};

export const validateNumericPositiveFloat = (value) => {
  if (PositiveNumber.test(value)) {
    return true;
  }
  return false;
};
