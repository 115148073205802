import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import {
  handleSort,
  validateNegativeNumber,
  validatePositiveFloat
} from "../../../../../../common/methods";
import { CIRCUIT_DATA } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

export const dataUnitsOptions = (units) => {
  if (units) {
    return handleSort(units).map((item) => {
      return <option value={item}>{item}</option>;
    });
  }
  return null;
};

export const tableDataVariables = (config) => {
  return [
    {
      title: CIRCUIT_DATA.DATA_VARIABLES,
      dataIndex: "dataVariable",
      render: (value) => {
        return <span>{columnsDataVariablesMap[value]}</span>;
      },
    },
    {
      title: CIRCUIT_DATA.UNITS,
      dataIndex: "unit",
      render: (value, row, _col, disabled, item) => {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}
          >
            <select
              defaultValue={value}
              value={value ? value : ""}
              disabled={disabled}
              name={"unit"}
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                parseFloat(item.design) >= 0 && !value ? "error" : "not-error"
              }
            >
              <option value={""}>{"Select"}</option>
              {dataUnitsOptions(
                config.dataUnits[dataVariablesMap[item["dataVariable"]]]
              )}
            </select>
          </div>
        );
      },
    },
    {
      title: CIRCUIT_DATA.VALUES,
      dataIndex: "design",
      render: (value, row, _col, disabled, item) => {
        return (
          <Input
            name={"design"}
            defaultValue={value}
            onChange={(e) => {
              config.change(e.target, row, item);
            }}
            disabled={disabled}
            autoComplete={"off"}
            value={value}
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            className={
              (item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit && !_.isFinite(parseFloat(item.design))) ||
              (!item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit &&
                !_.isFinite(parseFloat(item.design)) &&
                config.saveListener)
                ? "error"
                : "not-error"
            }
          />
        );
      },
    },
    config.isSchematicEditable && {
      title: CIRCUIT_DATA.ACTIONS,
      render: (_value, row, _col, disabled) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button
                onClick={(e) => {
                  config.edit(row);
                }}
              >
                <Icon type="edit" />
              </button>
            ) : (
              <button
                onClick={(e) => {
                  config.undo(row);
                }}
              >
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      },
    },
  ];
};

export const tableInletCondition = (config) => {
  return [
    {
      title: CIRCUIT_DATA.LOCKED_ROTOR,
      dataIndex: "lockedRotorAtRatedVoltage",
      render: (value) => {
        return <span>{columnsDataVariablesMap[value]}</span>;
      },
    },
    {
      title: "",
      dataIndex: "unit",
      render: (value, row, _col, disabled, item) => {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}
          >
            <select
              defaultValue={value}
              name={"unit"}
              value={value ? value : ""}
              disabled={disabled}
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                parseFloat(item.design) >= 0 && !value ? "error" : "not-error"
              }
            >
              <option value={""}>{"Select"}</option>
              {dataUnitsOptions(
                config.dataUnits[
                  dataVariablesMap[item["lockedRotorAtRatedVoltage"]]
                ]
              )}
            </select>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "design",
      render: (value, row, _col, disabled, item) => {
        return (
          <Input
            name={"design"}
            defaultValue={value}
            onChange={(e) => {
              config.change(e.target, row, item);
            }}
            disabled={disabled}
            autoComplete={"off"}
            value={value}
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            className={
              (item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit && !_.isFinite(parseFloat(item.design))) ||
              (!item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit &&
                !_.isFinite(parseFloat(item.design)) &&
                config.saveListener)
                ? "error"
                : "not-error"
            }
          />
        );
      },
    },
    config.isSchematicEditable && {
      title: "",
      render: (_value, row, _col, disabled) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button
                onClick={(e) => {
                  config.edit(row);
                }}
              >
                <Icon type="edit" />
              </button>
            ) : (
              <button
                onClick={(e) => {
                  config.undo(row);
                }}
              >
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      },
    },
  ];
};

export const tableDischargeConditions = (config) => {
  return [
    {
      title: CIRCUIT_DATA.RATED_LOAD,
      dataIndex: "ratedLoadAtRatedVoltage",
      render: (value) => {
        return <span>{columnsDataVariablesMap[value]}</span>;
      },
    },
    {
      title: "",
      dataIndex: "unit",
      render: (value, row, _col, disabled, item) => {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}
          >
            <select
              defaultValue={value}
              name={"unit"}
              value={value ? value : ""}
              disabled={disabled}
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                parseFloat(item.design) >= 0 && !value ? "error" : "not-error"
              }
            >
              <option value={""}>{"Select"}</option>
              {dataUnitsOptions(
                config.dataUnits[
                  dataVariablesMap[item["ratedLoadAtRatedVoltage"]]
                ]
              )}
            </select>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "design",
      render: (value, row, _col, disabled, item) => {
        return (
          <Input
            name={"design"}
            defaultValue={value}
            onChange={(e) => {
              config.change(e.target, row, item);
            }}
            autoComplete={"off"}
            disabled={disabled}
            value={value}
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            className={
              (item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit && !_.isFinite(parseFloat(item.design))) ||
              (!item.unit &&
                item.design &&
                !validatePositiveFloat(item.design.toString())) ||
              (item.unit &&
                !_.isFinite(parseFloat(item.design)) &&
                config.saveListener)
                ? "error"
                : "not-error"
            }
          />
        );
      },
    },
    config.isSchematicEditable && {
      title: "",
      render: (_value, row, _col, disabled) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button
                onClick={(e) => {
                  config.edit(row);
                }}
              >
                <Icon type="edit" />
              </button>
            ) : (
              <button
                onClick={(e) => {
                  config.undo(row);
                }}
              >
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      },
    },
  ];
};

export const tableCalculatedValue = (config) => {
  return [
    {
      title: CIRCUIT_DATA.RESIDUAL_VOLTAGE,
      dataIndex: "residualVoltageOpenCircuitTimeConstant",
      render: (value) => {
        return <span>{columnsDataVariablesMap[value]}</span>;
      },
    },
    {
      title: "",
      dataIndex: "unit",
      render: (value, row, _col, disabled, item) => {
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}
          >
            <select
              defaultValue={value}
              name={"unit"}
              value={value ? value : ""}
              disabled={disabled}
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                item.residualVoltageOpenCircuitTimeConstant !==
                "phasetoPhaseResistanceTemperature"
                  ? parseFloat(item.design) >= 0 && !value
                    ? "error"
                    : "not-error"
                  : item.design && !value
                    ? "error"
                    : "not-error"
              }
            >
              <option value={""}>{"Select"}</option>
              {dataUnitsOptions(
                config.dataUnits[
                  dataVariablesMap[
                    item["residualVoltageOpenCircuitTimeConstant"]
                  ]
                ]
              )}
            </select>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "design",
      render: (value, row, _col, disabled, item) => {
        return (
          <Input
            name={"design"}
            defaultValue={value}
            onChange={(e) => {
              config.change(e.target, row, item);
            }}
            autoComplete={"off"}
            disabled={disabled}
            value={value}
            onKeyDown={(e) => {
              newValidateNumber(e, value);
            }}
            className={
              (item.residualVoltageOpenCircuitTimeConstant ===
                "phasetoPhaseResistanceTemperature" &&
                ((item.unit &&
                  item.design &&
                  validateNegativeNumber(item.design.toString())) ||
                  (item.unit && !_.isFinite(parseFloat(item.design))) ||
                  (!item.unit &&
                    item.design &&
                    !validatePositiveFloat(item.design.toString())) ||
                  (item.unit &&
                    !_.isFinite(parseFloat(item.design)) &&
                    config.saveListener))) ||
              (item.residualVoltageOpenCircuitTimeConstant !==
                "phasetoPhaseResistanceTemperature" &&
                ((item.unit &&
                  item.design &&
                  !validatePositiveFloat(item.design.toString())) ||
                  (item.unit && !_.isFinite(parseFloat(item.design))) ||
                  (!item.unit &&
                    item.design &&
                    !validatePositiveFloat(item.design.toString())) ||
                  (item.unit &&
                    !_.isFinite(parseFloat(item.design)) &&
                    config.saveListener)))
                ? "error"
                : "not-error"
            }
          />
        );
      },
    },
    config.isSchematicEditable && {
      title: "",
      render: (_value, row, _col, disabled) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button
                onClick={(e) => {
                  config.edit(row);
                }}
              >
                <Icon type="edit" />
              </button>
            ) : (
              <button
                onClick={(e) => {
                  config.undo(row);
                }}
              >
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      },
    },
  ];
};

export const data = {
  ratedLoadAtRatedVoltage: {
    statorR: {
      unit: "",
      design: "",
    },
    rotorR: {
      unit: "",
      design: "",
    },
    magnR: {
      unit: "",
      design: "",
    },
    statorX: {
      unit: "",
      design: "",
    },
    rotorX: {
      unit: "",
      design: "",
    },
    magnX: {
      unit: "",
      design: "",
    },
  },
  dataVariable: {
    ratedkVA: {
      unit: "",
      design: "",
    },
    subtransientReactance: {
      unit: "",
      design: "",
    },
    totalWindingCapacitanceToGround: {
      unit: "",
      design: "",
    },
  },
  lockedRotorAtRatedVoltage: {
    statorR: {
      unit: "",
      design: "",
    },
    rotorR: {
      unit: "",
      design: "",
    },
    magnR: {
      unit: "",
      design: "",
    },
    statorX: {
      unit: "",
      design: "",
    },
    rotorX: {
      unit: "",
      design: "",
    },
    magnX: {
      unit: "",
      design: "",
    },
  },
  residualVoltageOpenCircuitTimeConstant: {
    motorOnly: {
      unit: "",
      design: "",
    },
    motorWithSurgeCapacitors: {
      unit: "",
      design: "",
    },
    phasetoPhaseResistance: {
      unit: "",
      design: "",
    },
    phasetoPhaseResistanceTemperature: {
      unit: "",
      design: "",
    },
  },
};

export const dataVariablesMap = {
  statorR: "Stator R",
  rotorR: "Rotor R",
  magnR: "Magn R",
  statorX: "Stator X",
  rotorX: "Rotor X",
  magnX: "Magn X",
  ratedkVA: "Rated kVA (25 deg C)",
  subtransientReactance: "Subtransient Reactance X\"d",
  totalWindingCapacitanceToGround: "Total Winding Capacitance to Ground",
  dynamicViscosity: "Dynamic Viscosity",
  inletTemperature: "Inlet Temperature",
  inletPressure: "Inlet Pressure",
  actualInletVolumetricFlow: "Volumetric Flow",
  motorOnly: "Residual Voltage Open-Circuit Time Constant - Motor only",
  motorWithSurgeCapacitors:
    "Residual Voltage Open-Circuit Time Constant - Motor with surge capacitors",
  phasetoPhaseResistance: "Phase to Phase Resistance",
  phasetoPhaseResistanceTemperature: "Phase to Phase Resistance Temperature",
};

export const columnsDataVariablesMap = {
  statorR: "Stator R",
  rotorR: "Rotor R",
  magnR: "Magn R",
  statorX: "Stator X",
  rotorX: "Rotor X",
  magnX: "Magn X",
  ratedkVA: "Rated kVA (25 deg C)",
  subtransientReactance: "Subtransient Reactance X\"d",
  totalWindingCapacitanceToGround: "Total Winding Capacitance to Ground",
  dynamicViscosity: "Dynamic Viscosity",
  inletTemperature: "Inlet Temperature",
  inletPressure: "Inlet Pressure",
  actualInletVolumetricFlow: "Actual Inlet Volumetric Flow",
  motorOnly: "Motor only",
  motorWithSurgeCapacitors: "Motor with surge capacitors",
  phasetoPhaseResistance: "Phase to Phase Resistance",
  phasetoPhaseResistanceTemperature: "Phase to Phase Resistance Temperature",
};
