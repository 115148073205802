import * as Type from '../Types/iowTags.types';

const INITIAL_STATE = {
  iowTour: {
    isLoading: false,
    startTour: false,
  },
  iowTagList: {
    isLoading: false,
    isSuccess: false,
    item_count: 0,
    data: [],
    skip: 0,
    limit: 0,
    error: '',
    apiMessage: '',
    errorCode: '',
  },
  iowTagUpdate: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorCode: '',
    apiMessage: '',
  },
};

export default function IowTagsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.IOW_TOUR_CHANGE:
      return {
        ...state,
        iowTour: {
          isLoading: false,
          startTour: action.payload,
        },
      };
    case Type.IOW_TAG_GET_LIST_PROGRESS:
      return {
        ...state,
        iowTagList: {
          ...state.iowTagList,
          isLoading: true,
          apiMessage: '',
        },
      };
    case Type.IOW_TAG_GET_LIST_SUCCESS:
      const outputArray = action.payload.items.map((item) => {
        const { tag, _id, ...rest } = item;
        const {
          criticalLimitLowTime,
          criticalLimitHighTime,
          optimalTimeRange,
          standardLimitLowTime,
          standardLimitHighTime,
          ...tagWithOutTimers
        } = tag;
        return { ...tagWithOutTimers, ...rest };
      });
      return {
        ...state,
        iowTagList: {
          ...state.iowTagList,
          isLoading: false,
          isSuccess: true,
          skip: action.payload.skip,
          data: outputArray,
          limit: action.payload.limit,
          apiMessage: action.payload.message,
          total_count: action.payload.total_count,
        },
      };
    case Type.IOW_TAG_LIST_UPDATE:
      const filteredData = [...state.iowTagList.data].filter(tag => tag.piTag !== action.payload.piTag)
      return {
        ...state,
        iowTagList: {
          ...state.iowTagList,
          data: filteredData,
          total_count: state.iowTagList.total_count - 1
        }
      }
    case Type.IOW_TAG_GET_LIST_ERROR:
      return {
        ...state,
        iowTagList: {
          ...state.iowTagList,
          isLoading: false,
          isSuccess: false,
          isError: true,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };
    case Type.IOW_TAG_GET_LIST_CLEAR:
      return {
        ...state,
        iowTagList: {
          ...state.iowTagList,
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
      };
    case Type.IOW_TAG_UPDATE_PROGRESS:
      return {
        ...state,
        iowTagUpdate: {
          ...state.iowTagUpdate,
          isLoading: true,
        },
      };
    case Type.IOW_TAG_UPDATE_SUCCESS:
      const { tag, _id,  ...rest } = action.payload;
      const {
        criticalLimitLowTime,
        criticalLimitHighTime,
        optimalTimeRange,
        standardLimitLowTime,
        standardLimitHighTime,
        ...tagWithOutTimers
      } = tag;
      const result = { ...tagWithOutTimers, ...rest };
      return {
        ...state,
        iowTagUpdate: {
          ...state.iowTagUpdate,
          isSuccess: true,
          data: result,
        },
      };
    case Type.IOW_TAG_UPDATE_ERROR:
      return {
        ...state,
        iowTagUpdate: {
          ...state.iowTagUpdate,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMessage:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.IOW_TAG_UPDATE_CLEAR:
      return {
        ...state,
        iowTagUpdate: {
          ...state.iowTagUpdate,
          isSuccess: false,
          isError: false,
          data : null
        },
      };
    default:
      return state;
  }
}
