import { Connection } from "../socket/connection";
import { deleteIDB } from "../services/indexedDB";

export const AUTH_ACCESS_TOKEN = "AUTH_ACCESS_TOKEN";
export const USER_NICK_NAME = "USER_NICK_NAME";
export const AUTH_REFRESH_TOKEN ="AUTH_REFRESH_TOKEN";
export const USER_EMAIL_ADDRESS = "USER_EMAIL_ADDRESS";

export const getToken = () => localStorage.getItem(AUTH_ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(AUTH_REFRESH_TOKEN);
export const setToken = (token) => {
  /** Call connection event after receiving token */
  Connection(token);
  localStorage.setItem(AUTH_ACCESS_TOKEN, token);
};
export const setRefreshToken = (refresh_token) =>{
  localStorage.setItem(AUTH_REFRESH_TOKEN, refresh_token);
}
export const setUser = (email, name) => {
  localStorage.setItem(USER_EMAIL_ADDRESS, email);
  localStorage.setItem(USER_NICK_NAME, name);
};

/**
 * delete the local storage and indexed DB
 * @param {} token
 */
export const clearToken = (token) => {
  deleteIDB();
  localStorage.removeItem(AUTH_ACCESS_TOKEN);
  localStorage.removeItem("alertFilterLevel");
  localStorage.removeItem("alertFilterType");
  localStorage.removeItem("alertSortOrder");
  localStorage.removeItem("activeKey");
  localStorage.removeItem('algoTypeFilter');
  localStorage.removeItem('statusFilter');
  localStorage.removeItem('mlAlgoKeyword');
  localStorage.removeItem('mlAlgoPage');
  localStorage.removeItem(USER_NICK_NAME);
  localStorage.removeItem(USER_EMAIL_ADDRESS);
};

export const isLoggedIn = () => {
  if (getToken()) {
    const token = getToken();
    Connection(token);
    return true;
  }
  return false;
};

export const getImageUrl = (logoId) =>
  `${process.env.REACT_APP_DEV_API}/public/files/${logoId}?api_key=${process.env.REACT_APP_API_KEY}`;

/** Handling of delete DB */
export const dbDeleteHandling = () => {
  // window.location.reload();
};
