import { Form, Modal } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Notification, { Types } from "../../../../../common/Notification/Notification";
import { AlertDetailMessages } from "../../../../../constants/messages";
import FormFields from "../../../../../common/FormFields/FormFields";
import BulkActionEditor from "./BulkActionEditor";
import formField from "./BulkActionModal.constants";

function BulkActionModal(props) {
  const [closingComment, setClosingComment] = useState("");
  const {
    visible,
    title,
    form,
    handleCancel,
    handleOk,
    highLevelAlertsPresent,
    isEventAssocAction,
    eventName,
    nameSuggestions
  } = props;
  const commentRequired = highLevelAlertsPresent;

  const handleBulkOk = () => {
    if (!isEventAssocAction){
      if (closingComment === "" && commentRequired) {
        Notification.show(Types.Error, AlertDetailMessages.FILL_REQUIREND_COMMENTS)
      }
      else if (closingComment && closingComment.length > 50000) {
        return Notification.show(Types.Warning, AlertDetailMessages.CHARACHETER_COUNT)
      }
      else if (closingComment !== "") {
        handleOk(closingComment);
        setClosingComment("");
      }
      else handleOk();
    }
    else{
      form.validateFields((err, values) => {
        if (!err) {
          if (values.comment !== "") {
            handleOk(values.comment);
            form.resetFields();
          } else handleOk();
        }
      });
    }
  }

  return (
    <Modal
      visible={visible}
      title={title}
      style={{ minWidth: '60vw' }}
      okText="Proceed"
      cancelText="Cancel"
      onCancel={() => {
        setClosingComment("");
        handleCancel();
        form.resetFields();
      }}
      onOk={() => {
       handleBulkOk()
      }}
    >
      {/* <FormFields
        formFields={formField({ closingComment, reqd: commentRequired, isEventAssocAction, eventName, nameSuggestions })}
        form={form}
      /> */}
      {isEventAssocAction ?
        <FormFields
          formFields={formField({ closingComment, reqd: commentRequired, isEventAssocAction, eventName, nameSuggestions })}
          form={form}
        /> : visible ?
          <BulkActionEditor
            closingComment={closingComment}
            setClosingComment={setClosingComment}
          /> :
          <></>}
    </Modal>
  );
}

BulkActionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  highLevelAlertsPresent: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  form: {
    resetFields: PropTypes.func,
    validateFields: PropTypes.func,
  },
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
BulkActionModal.defaultProps = {
  form: {
    resetFields: () => { },
    validateFields: () => { },
  },
};

export default Form.create({ name: "BulkActionModal" })(BulkActionModal);
