import * as ForgotPassword from "../Types/forgotPassword.types";
import { POSTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

const setForgotPassword = (payload) => (dispatch) => {
  dispatch({ type: ForgotPassword.SET_FORGOT_PASSWORD_PROGRESS });
  POSTRequest(endPoints.forgotPassword, {
    email: payload.email,
    token: payload.token,
  })
    .then((response) => {
      dispatch({
        type: ForgotPassword.SET_FORGOT_PASSWORD_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: ForgotPassword.SET_FORGOT_PASSWORD_ERROR,
        payload: error,
      });
    });
};

const setNewPassword = (payload) => (dispatch) => {
  dispatch({ type: ForgotPassword.SET_NEW_FORGOT_PASSWORD_PROGRESS });
  POSTRequest(endPoints.changePassword, payload)
    .then((response) =>
      dispatch({
        type: ForgotPassword.SET_NEW_FORGOT_PASSWORD_SUCCESS,
        payload: response,
      })
    )
    .catch((error) =>
      dispatch({
        type: ForgotPassword.SET_NEW_FORGOT_PASSWORD_ERROR,
        payload: error,
      })
    );
};

const clearForgotPasswordState = () => (dispatch) => {
  dispatch({ type: ForgotPassword.FORGOT_PASSWORD_CLEAR_STATE });
};

export { setNewPassword, setForgotPassword, clearForgotPasswordState };
