import { Button } from 'antd';
import {
  mxCellState,
  mxClient,
  mxCompactTreeLayout,
  mxConnectionConstraint,
  mxConstants,
  mxConstraintHandler,
  mxDragSource,
  mxEdgeHandler,
  mxEdgeStyle,
  mxEvent,
  mxGraph,
  mxGraphHandler,
  mxGuide,
  mxImage,
  mxKeyHandler,
  mxObjectCodec,
  mxPerimeter,
  mxPoint,
  mxRubberband,
  mxUndoManager,
  mxUtils,
  mxVertexHandler,
} from 'mxgraph-js';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ic_zoomin } from '../../../assets/ic_zoomin';
import { ic_zoomout } from '../../../assets/ic_zoomout';
import { stroke_point } from '../../../assets/stroke_point';
import { AssetConfig } from '../../../components/AssetLibrary/Assets.config';
import {
  EditCurrentNodeMessages,
  GraphEditorMessages,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import { BASE_API_PATH, ButtonVariables } from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { getImageUrl } from '../../../libs/token';
import { ElementPermissions } from '../../../permissions';
import {
  clearSchematicState,
  deleteSchematicComponent,
} from '../../../redux/actions/schematic.action';
import Modal from '../../Modal/Modal';
import Notification, { Types } from '../../Notification/Notification';
import EditCurrentNode from '../EditCurrentNode/EditCurrentNode';
import ObjectContainer from '../ObjectComponent/ObjectContainer';
import Toolbar from '../ToolbarComponent/Toolbar';
import './GraphEditor.scss';

class JsonCodec extends mxObjectCodec {
  constructor(props) {
    super((value) => {});
  }

  encode(value) {
    const xmlDoc = mxUtils.createXmlDocument();
    const newObject = xmlDoc.createElement('ComponentObject');
    for (const prop in value) {
      newObject.setAttribute(prop, value[prop]);
    }
    return newObject;
  }

  decode(model) {
    return Object.keys(model.cells)
      .map((iCell) => {
        const currentCell = model.getCell(iCell);
        return currentCell.value !== undefined ? currentCell : null;
      })
      .filter((item) => item !== null);
  }
}

class mxCellAttributeChange {
  constructor(cell, attribute, value) {
    this.cell = cell;
    this.attribute = attribute;
    this.value = value;
    this.previous = value;
  }

  execute() {
    if (this.cell != null) {
      this.cell.value[this.attribute] = this.value;
    }
  }
}

class GraphEditor extends Component {
  constructor(props) {
    super(props);
    this.loadingGarph = false;
    this.state = {
      toolbarRefs: null,
      objectRefs: null,
      graph: {},
      dragElt: null,
      layout: {},
      currentNode: null,
      currentTask: '',
      editNode: false,
      graphData: this.props.graphData ? this.props.graphData : {},
      componentEditMode: false,
      status: null,
      deleteError: 'NOT_ALLOWED',
      noComponent: 'NOT_FOUND',
      isModal: false,
    };

    this.getToolbarRef = this.getToolbarRef.bind(this);
    this.getObjectContainerRef = this.getObjectContainerRef.bind(this);
    this.LoadGraph = this.LoadGraph.bind(this);
    this.keyHandler = null;
  }

  componentDidMount() {
    this.LoadGraph(this.props.model);
  }

  componentWillUnmount() {
    this.closePopupMenu();
    this.keyHandler.destroy();
  }

  componentDidUpdate(preProps, preState) {
    const {
      isSuccessDeleteSchematicComponentFetch,
      isErrorComponentSchematic,
      errorCode,
    } = this.props.schematicState.apiState;
    const { clearSchematicState } = this.props;
    this.closePopupMenu();

    if (isSuccessDeleteSchematicComponentFetch) {
      clearSchematicState();
      this.state.graph.removeCells([this.state.graph.getSelectionCell()]);
      this.saveGraphToLocal();
    }

    if (isErrorComponentSchematic) {
      clearSchematicState();
      if (
        errorCode === this.state.deleteError ||
        errorCode === this.state.noComponent
      ) {
        Notification.show(Types.Error, EditCurrentNodeMessages[errorCode]);
      } else if (errorCode === this.state.noComponent) {
        Notification.show(Types.Error, EditCurrentNodeMessages[errorCode]);
      } else {
        Notification.show(Types.Success, EditCurrentNodeMessages[errorCode]);
      }
    }

    if (this.props.isGraphChanged) {
      this.props.clearGraphChanged();
      this.renderJSON(this.props.model, this.state.graph);
    }

    if (preProps.model !== this.props.model) {
      this.initToolbar();
    }
  }

  closePopupMenu() {
    if (document.getElementsByClassName('mxPopupMenu')[0]) {
      document.getElementsByClassName('mxPopupMenu')[0].remove();
    }
  }

  getToolbarRef(ref) {
    this.setState({ toolbarRefs: ref });
  }

  getObjectContainerRef(ref) {
    this.setState({ objectRefs: ref }, () => {
      this.createDragElement();
    });
  }

  graphF = (evt) => {
    const { graph } = this.state;
    const x = mxEvent.getClientX(evt);
    const y = mxEvent.getClientY(evt);
    const elt = document.elementFromPoint(x, y);
    if (mxUtils.isAncestorNode(graph.container, elt)) {
      return graph;
    }
    return null;
  };

  createPopupMenu = (graph, menu, cell, evt) => {
    const { facilityData } = this.props;
    const facilityId = facilityData ? facilityData._id : '';
    const scope = this;
    if (
      cell &&
      (!this.props.model || (this.props.model && !this.props.model.deployed))
    ) {
      if (cell.edge === true) {
        menu.addItem('Delete', null, () => {
          if (
            elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
              type: 'facility',
              dataRef: {
                _id: facilityId,
              },
            })
          ) {
            graph.removeCells([cell]);
            scope.props.schematic !== 'system' && scope.saveGraphToLocal();
          }
          mxEvent.consume(evt);
        });
      } else if (this.props.schematic === 'system') {
        menu.addItem('Delete', null, () => {
          if (
            elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
              type: 'facility',
              dataRef: {
                _id: facilityId,
              },
            })
          ) {
            graph.removeCells([cell]);
          }
          mxEvent.consume(evt);
        });
      } else {
        menu.addItem('Edit', null, () => {
          scope.setState({ componentEditMode: true }, () => {
            scope.selectionChanged(graph, null);
          });
        });
        menu.addItem('Configuration', null, () => {
          if (
            cell.value.assetInfo &&
            AssetConfig[cell.value.assetInfo.type.name]
          ) {
            scope.props.history.push(Routes.componentInformation, {
              ...scope.props.location.state,
              componentData: graph.getSelectionCell().value,
              schematicData: scope.props.model,
            });
          } else {
            Notification.show(
              Types.Info,
              `Configuration is not defined for ${cell.value.assetInfo.type.name}`
            );
          }
        });
        menu.addItem('Delete', null, () => {
          const { deleteSchematicComponent } = scope.props;
          if (
            scope.handleNetwork() &&
            elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
              type: 'facility',
              dataRef: {
                _id: facilityId,
              },
            })
          ) {
            deleteSchematicComponent(cell.value.info._id);
          }
          mxEvent.consume(evt);
        });
      }
    }
  };

  selectionChange = (sender, evt) => {};

  selectionChanged = (graph, value) => {
    this.setState({
      editNode: true,
      currentNode: graph.getSelectionCell(),
      currentTask: value,
    });
  };

  funct = (graph, evt, target, x, y, value) => {
    const parent = graph.getDefaultParent();
    const cell =
      this.props.schematic === 'system'
        ? graph.insertVertex(
            parent,
            target,
            value,
            x,
            y,
            90,
            70,
            'strokeColor=transparent;strokeWidth=0;fillColor=transparent'
          )
        : graph.insertVertex(
            parent,
            target,
            value,
            x,
            y,
            value.assetInfo.type.config.width / 2,
            value.assetInfo.type.config.height / 2,
            'strokeColor=transparent;strokeWidth=0;fillColor=transparent'
          );
    graph.setSelectionCell(cell);
    this.props.schematic === 'system'
      ? this.handleConfirm()
      : this.selectionChanged(graph, value);
  };

  createDragElement = () => {
    const { graph } = this.state;

    let componentDrag = [];
    if (this.state.objectRefs) {
      componentDrag = ReactDOM.findDOMNode(
        this.state.objectRefs.components
      ).querySelectorAll('.item');
    }

    Array.prototype.slice.call(componentDrag).forEach((ele) => {
      const value = { assetInfo: JSON.parse(ele.getAttribute('data')) };
      const ds = mxUtils.makeDraggable(
        ele,
        this.graphF,
        (graph, evt, target, x, y) =>
          this.funct(graph, evt, target, x, y, value),
        this.dragElt,
        null,
        null,
        graph.autoscroll,
        true
      );

      ds.isGuidesEnabled = function () {
        return graph.graphHandler.guidesEnabled;
      };
      ds.createDragElement = mxDragSource.prototype.createDragElement;
    });
  };

  settingConnection = () => {
    const { facilityData } = this.props;
    const facilityId = facilityData ? facilityData._id : '';
    const scope = this;
    const { graph } = this.state;
    if (graph.connectionHandler.connectImage == null) {
      graph.connectionHandler.isConnectableCell = function (cell) {
        return false;
      };
      mxEdgeHandler.prototype.isConnectableCell = function (cell) {
        return graph.connectionHandler.isConnectableCell(cell);
      };
    }

    graph.getAllConnectionConstraints = function (terminal) {
      if (
        terminal != null &&
        this.model.isVertex(terminal.cell) &&
        scope.props.schematic !== 'system' &&
        scope.props.model &&
        !scope.props.model.deployed
      ) {
        const configuration = terminal.cell.value.assetInfo.type.config;
        return (
          elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
            type: 'facility',
            dataRef: {
              _id: facilityId,
            },
          }) &&
          scope.props.schematic !== 'system' &&
          configuration.points.map((points) => {
            const xp = configuration.width ? points.x / configuration.width : 0;
            const yp = configuration.height
              ? (configuration.height - points.y) / configuration.height
              : 1;
            return new mxConnectionConstraint(new mxPoint(xp, yp), true);
          })
        );
      }
      if (
        terminal != null &&
        this.model.isVertex(terminal.cell) &&
        scope.props.schematic === 'system'
      ) {
        return (
          elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC) && [
            new mxConnectionConstraint(new mxPoint(0, 0.5), true),
            new mxConnectionConstraint(new mxPoint(0.5, 0), true),
            new mxConnectionConstraint(new mxPoint(1, 0.5), true),
            new mxConnectionConstraint(new mxPoint(0.5, 1), true),
          ],
          {
            type: 'facility',
            dataRef: {
              _id: facilityId,
            },
          }
        );
      }
      return null;
    };

    graph.connectionHandler.createEdgeState = function (me) {
      const edge = graph.createEdge(null, null, 'Edge', null, null, '');
      return new mxCellState(
        this.graph.view,
        edge,
        this.graph.getCellStyle(edge)
      );
    };

    graph.connectionHandler.addListener(mxEvent.CONNECT, (e) => {
      this.props.schematic !== 'system' && scope.saveGraphToLocal();
    });

    graph.model.addListener(mxEvent.EXECUTE, (sender, evt) => {
      this.props.schematic !== 'system' &&
        !this.loadingGarph &&
        evt.properties.change.previous &&
        evt.properties.change.previous.getTerminalPoint &&
        scope.saveGraphToLocal();
    });

    if (this.props.schematic === 'system') {
      const vertexHandlerUnion = mxVertexHandler.prototype.union;
      mxVertexHandler.prototype.union = function (bounds) {
        const result = vertexHandlerUnion.apply(this, arguments);
        const coff = bounds.width / bounds.height;
        result.width = result.height * coff;
        if (graph && graph.getSelectionCell() && graph.getSelectionCell().id) {
          document.getElementById(
            `object${graph.getSelectionCell().id}`
          ).style.width = `${result.width}px`;
          document.getElementById(
            `object${graph.getSelectionCell().id}`
          ).style.height = `${result.height}px`;
        }
        return result;
      };
    }
  };

  renderJSON = (dataModel, graph) => {
    const vertices = {};
    graph.getModel().clear();
    const parent = graph.getDefaultParent();
    graph.getModel().beginUpdate(); // Adds cells to the model in a single step
    try {
      this.loadingGarph = true;
      dataModel &&
        dataModel.graph.forEach((node, index) => {
          if (node.value) {
            if (node.vertex) {
              vertices[node.id] = graph.insertVertex(
                parent,
                null,
                node.value,
                node.geometry.x,
                node.geometry.y,
                node.geometry.width,
                node.geometry.height,
                node.style
              );
            } else if (node.edge) {
              const cell = graph.insertEdge(
                parent,
                null,
                'Edge',
                vertices[node.source],
                vertices[node.target],
                node.style
              );
              let geo = graph.getCellGeometry(cell);

              if (geo != null) {
                geo = geo.clone();
                geo.points = node.geometry.points;
                graph.getModel().setGeometry(cell, geo);
              }
            } else {
            }
          }
        });
    } finally {
      graph.getModel().endUpdate();
      this.loadingGarph = false;
      // Updates the display
    }
  };

  stringifyWithoutCircular = (json) =>
    JSON.stringify(
      json,
      (key, value) => {
        if (
          (key === 'parent' || key === 'source' || key === 'target') &&
          value !== null
        ) {
          return value.id;
        }
        if (key === 'value' && value !== null && value.localName) {
          const results = {};
          Object.keys(value.attributes).forEach((attrKey) => {
            const attribute = value.attributes[attrKey];
            results[attribute.nodeName] = attribute.nodeValue;
          });
          return results;
        }
        return value;
      },
      4
    );

  getJsonModel = (graph) => {
    const encoder = new JsonCodec();
    const jsonModel = encoder.decode(graph.getModel());
    return {
      graph: jsonModel,
    };
  };

  getToolBarActios(icon, action) {
    const div = document.createElement('div');
    const img = document.createElement('img');
    img.src = icon;
    div.addEventListener('click', action);
    div.appendChild(img);
    return div;
  }

  getZoomScale() {
    const { graph } = this.state;
    return `${graph.getView().getScale() * 100} %`;
  }

  initToolbar = () => {
    if (!this.props.sideMenu) {
      const { facilityData } = this.props;
      const facilityId = facilityData ? facilityData._id : '';
      const that = this;
      const { graph } = this.state;
      const toolbar = ReactDOM.findDOMNode(this.state.toolbarRefs);
      toolbar.innerHTML = '';
      const div = document.createElement('div');
      div.classList.add('edit-btn');
      const label = document.createElement('label');
      label.innerHTML = this.getZoomScale();

      if (!toolbar) {
        return;
      }

      const undoManager = new mxUndoManager();
      const listener = function (sender, evt) {
        undoManager.undoableEditHappened(evt.getProperty('edit'));
      };
      graph.getModel().addListener(mxEvent.UNDO, listener);
      graph.getView().addListener(mxEvent.UNDO, listener);

      div.appendChild(
        this.getToolBarActios(ic_zoomin, () => {
          if (graph.getView().getScale() < 1.3) {
            graph.zoomIn();
            label.innerHTML = this.getZoomScale();
          }
        })
      );
      div.appendChild(label);
      div.appendChild(
        this.getToolBarActios(ic_zoomout, () => {
          if (graph.getView().getScale() > 0.7) {
            graph.zoomOut();
            label.innerHTML = this.getZoomScale();
          }
        })
      );
      toolbar.appendChild(div);

      const statusDiv = document.createElement('div');
      const savebutton = mxUtils.button(
        this.props.saveButtonText
          ? this.props.saveButtonText
          : ButtonVariables.SAVE,
        () => {
          const jsonNodes = that.getJsonModel(graph);
          const jsonStr = that.stringifyWithoutCircular(jsonNodes);
          that.props.schematic !== 'system' &&
            that.props.deploy &&
            that.props.deploy(JSON.parse(jsonStr));
          that.props.schematic === 'system' &&
            that.props.save &&
            that.props.save(JSON.parse(jsonStr));
        }
      );
      /** Previous code now moved in render */
      // if ((this.props.model && this.props.model.deployed)) {
      //     statusDiv.innerHTML = `<div class="equipment-run-status">Equipment is in Running State</div>`;
      // }

      this.props.schematic === 'system' &&
        elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }) &&
        statusDiv.appendChild(savebutton);

      !this.props.model ||
        (this.props.model &&
          !this.props.model.deployed &&
          elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
            type: 'facility',
            dataRef: {
              _id: facilityId,
            },
          }) &&
          statusDiv.appendChild(savebutton));

      toolbar.appendChild(statusDiv);
    }
  };

  setGraphSetting = () => {
    const { facilityData } = this.props;
    const facilityId = facilityData ? facilityData._id : '';
    const { graph } = this.state;
    const that = this;
    graph.gridSize = 30;
    graph.setPanning(true);
    graph.setTooltips(true);
    graph.setConnectable(true);
    if (!this.props.model || (this.props.model && !this.props.model.deployed)) {
      graph.setCellsEditable(true);
    } else {
      graph.setCellsEditable(false);
    }
    graph.setEnabled(true);
    // Enables HTML labels
    graph.setHtmlLabels(true);
    // graph.centerZoom = true;
    // Autosize labels on insert where autosize=1
    graph.autoSizeCellsOnAdd = true;

    this.keyHandler = new mxKeyHandler(graph);

    this.keyHandler.bindKey(8, (evt) => {
      if (graph.isEnabled()) {
        const currentNode = graph.getSelectionCell();
        if (currentNode && that.props.model && !that.props.model.deployed) {
          if (
            currentNode.edge === true &&
            elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
              type: 'facility',
              dataRef: {
                _id: facilityId,
              },
            }) &&
            (!that.props.model ||
              (that.props.model && !that.props.model.deployed))
          ) {
            graph.removeCells([currentNode]);
            that.props.schematic !== 'system' && that.saveGraphToLocal();
          } else {
            const { deleteSchematicComponent } = that.props;
            if (
              that.handleNetwork() &&
              elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
                type: 'facility',
                dataRef: {
                  _id: facilityId,
                },
              }) &&
              (!that.props.model ||
                (that.props.model && !that.props.model.deployed))
            ) {
              deleteSchematicComponent(currentNode.value.info._id);
            }
          }
        }
      }
    });

    this.keyHandler.bindKey(13, (evt) => {
      if (graph.isEnabled()) {
        const cell = graph.getSelectionCell();
        if (cell) {
          if (cell.edge === true) {
          } else if (
            cell.value.assetInfo &&
            AssetConfig[cell.value.assetInfo.type.name]
          ) {
            that.props.history.push(Routes.componentInformation, {
              ...that.props.location.state,
              componentData: graph.getSelectionCell().value,
              schematicData: that.props.model,
            });
          } else {
            Notification.show(
              Types.Info,
              `Configuration is not defined for ${cell.value.assetInfo.type.name}`
            );
          }
        }
      }
    });

    new mxRubberband(graph);
    graph.getTooltipForCell = function (cell) {
      return cell.getAttribute('desc');
    };
    let style = [];
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    style[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_FILLCOLOR] = '#C3D9FF';
    style[mxConstants.STYLE_STROKECOLOR] = '#6482B9';
    style[mxConstants.STYLE_FONTCOLOR] = '#774400';
    style[mxConstants.HANDLE_FILLCOLOR] = '#80c6ee';
    graph.getStylesheet().putDefaultVertexStyle(style);
    style = [];
    style[mxConstants.STYLE_STROKECOLOR] = '#000';
    style[mxConstants.STYLE_STROKEWIDTH] = '2';
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_EDGE] = mxEdgeStyle.OrthConnector;
    style[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_OPEN;
    style[mxConstants.STYLE_FONTSIZE] = '10';
    style[mxConstants.VALID_COLOR] = '#27bf81';
    graph.getStylesheet().putDefaultEdgeStyle(style);

    graph.popupMenuHandler.factoryMethod = (menu, cell, evt) => {
      const { facilityData } = this.props;
      const facilityId = facilityData ? facilityData._id : '';
      if (
        elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        })
      ) {
        return that.createPopupMenu(graph, menu, cell, evt);
      }
      return null;
    };

    graph.addListener(mxEvent.DOUBLE_CLICK, (sender, event) => {
      const cell = graph.getSelectionCell();
      if (cell && cell.value && this.props.schematic !== 'system') {
        if (cell.value.assetInfo) {
          if (
            cell.value.assetInfo &&
            AssetConfig[cell.value.assetInfo.type.name]
          ) {
            that.props.history.push(Routes.componentInformation, {
              ...that.props.location.state,
              componentData: cell.value,
              schematicData: that.props.model,
            });
          } else {
            Notification.show(
              Types.Info,
              `Configuration is not defined for ${cell.value.assetInfo.type.name}`
            );
          }
        }
      }
    });

    graph.convertValueToString = (cell) => {
      if (cell.vertex) {
        const div = document.createElement('div');
        div.setAttribute('class', 'taskWrapper');
        const img = document.createElement('img');
        img.setAttribute('id', `object${cell.id}`);
        img.setAttribute(
          'src',
          this.props.schematic === 'system'
            ? getImageUrl(cell.value.assetInfo.imgUrl)
            : `${BASE_API_PATH}/${cell.value.assetInfo.imgUrl}`
        );
        img.setAttribute('width', `${cell.geometry.width}px`);
        img.setAttribute('height', `${cell.geometry.height}px`);
        const label = document.createElement('label');
        label.innerHTML =
          this.props.schematic !== 'system'
            ? cell.value.info
              ? `${cell.value.info.name}`
              : ''
            : `${cell.value.assetInfo.name}`;
        div.appendChild(label);
        div.appendChild(img);
        return div;
      }
      return null;
    };
  };

  loadGlobalSetting = () => {
    mxGraphHandler.prototype.guidesEnabled = true;
    mxGuide.prototype.isEnabledForEvent = function (evt) {
      return !mxEvent.isAltDown(evt);
    };
    mxEdgeHandler.prototype.snapToTerminals = true;
    mxConstraintHandler.prototype.pointImage = new mxImage(stroke_point, 5, 5);
  };

  setLayoutSetting = (layout) => {
    layout.parallelEdgeSpacing = 10;
    layout.useBoundingBox = false;
    layout.edgeRouting = false;
    layout.levelDistance = 60;
    layout.nodeDistance = 16;
    layout.parallelEdgeSpacing = 10;
    layout.isVertexMovable = function (cell) {
      return true;
    };
    layout.localEdgeProcessing = function (node) {};
  };

  getEditPreview = () => {
    const dragElt = document.createElement('div');
    dragElt.style.border = 'dashed black 1px';
    dragElt.style.width = '120px';
    dragElt.style.height = '40px';
    return dragElt;
  };

  LoadGraph(model) {
    const { facilityData } = this.props;
    const facilityId = facilityData ? facilityData._id : '';
    const graphEditor = ReactDOM.findDOMNode(this.refs.graphEditor);
    if (!mxClient.isBrowserSupported()) {
      mxUtils.error(GraphEditorMessages.BROWSER_NOT_SUPPORTED, 200, false);
    } else {
      const graph = new mxGraph(graphEditor);
      this.setState(
        {
          graph,
          dragElt: this.getEditPreview(),
        },
        () => {
          const layout = new mxCompactTreeLayout(graph, false);
          this.setState({ layout });
          this.setLayoutSetting(layout);
          this.loadGlobalSetting();
          this.setGraphSetting();
          this.initToolbar();
          this.settingConnection();

          graph.getModel().beginUpdate();
          graph.getModel().endUpdate();
          if (model && model.graph) {
            this.renderJSON(model, graph);
          }
        }
      );

      mxEvent.disableContextMenu(graphEditor);
      graph
        .getSelectionModel()
        .addListener(mxEvent.CHANGE, this.selectionChange);
      this.props.schematic === 'system' &&
      elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
        type: 'facility',
        dataRef: {
          _id: facilityId,
        },
      })
        ? graph.setCellsResizable(true)
        : graph.setCellsResizable(false);
    }
  }

  saveGraphToLocal() {
    const { graph } = this.state;
    const jsonNodes = this.getJsonModel(graph);
    const jsonStr = this.stringifyWithoutCircular(jsonNodes);
    this.props.schematic !== 'system' &&
      this.props.save &&
      this.props.save(JSON.parse(jsonStr));
  }

  handleCancel = () => {
    if (!this.state.componentEditMode) {
      this.state.graph.removeCells([this.state.currentNode]);
    }
    this.setState({
      editNode: false,
      componentEditMode: false,
    });
  };

  handleConfirm = (node, values) => {
    const { graph } = this.state;
    const cell = graph.getSelectionCell();
    this.applyHandler(graph, cell, 'info', values);
    this.saveGraphToLocal();
    this.setState({
      editNode: false,
      componentEditMode: false,
    });
  };

  applyHandler = (graph, cell, name, newValue) => {
    graph.getModel().beginUpdate();
    try {
      const edit = new mxCellAttributeChange(cell, name, newValue);
      graph.getModel().execute(edit);
    } finally {
      graph.getModel().endUpdate();
    }
  };

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  handleUnDeploy = () => {
    this.setState({ isModal: true });
  };

  cancelUnDeploy = () => {
    this.setState({ isModal: false });
  };

  handleUnDeploySchematic = () => {
    const { schematicData } = this.props.schematicState.apiState;
    if (schematicData && schematicData._id) {
      if (this.handleNetwork()) {
        this.props.unDeploySchematic(schematicData._id);
      }
    }
    this.cancelUnDeploy();
  };

  render() {
    const { facilityData } = this.props;
    const facilityId = facilityData ? facilityData._id : '';
    return (
      <div className="GraphEditor">
        <div
          className="box editor"
          style={{
            width:
              elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
                type: 'facility',
                dataRef: {
                  _id: facilityId,
                },
              }) &&
              (!this.props.model ||
                (this.props.model && !this.props.model.deployed))
                ? 'calc(100% - 144px)'
                : '100%',
          }}
        >
          {this.props.toolbar ? <Toolbar myRef={this.getToolbarRef} /> : null}
          {this.props.model && this.props.model.deployed && (
            <div className="box-header">
              <div className="equipment-run-status">
                Equipment is in Running State
              </div>
              {elementsGaurd(
                <Button
                  onClick={this.handleUnDeploy}
                  className="undeploy-component-schematic-btn"
                >
                  {ButtonVariables.UN_DEPLOY}
                </Button>,
                ElementPermissions.EDIT_SCHEMATIC,
                {
                  type: 'facility',
                  dataRef: {
                    _id: facilityId,
                  },
                }
              )}
            </div>
          )}

          {!this.props.sideMenu && <hr />}
          <div className="graphEditor-wrapper">
            <div className="graphEditor resize" ref="graphEditor" />
          </div>
        </div>
        {this.props.objects && !this.props.sideMenu ? (
          <div className="box pallete">
            <ObjectContainer
              myRef={this.getObjectContainerRef}
              callBack={() => this.createDragElement()}
              objects={this.props.objects}
              schematic={this.props.schematic}
            />
          </div>
        ) : null}

        {this.state.editNode &&
        elementsGaurd(true, ElementPermissions.EDIT_SCHEMATIC, {
          type: 'facility',
          dataRef: {
            _id: facilityId,
          },
        }) &&
        (!this.props.model ||
          !this.props.model ||
          (this.props.model && !this.props.model.deployed)) ? (
          <EditCurrentNode
            node={this.state.currentNode}
            visible={this.state.editNode}
            handleCancel={this.handleCancel}
            handleConfirm={this.handleConfirm}
            equipmentId={this.props.equipmentId}
            editMode={this.state.componentEditMode}
          />
        ) : null}

        {this.state.isModal && (
          <Modal
            visible={this.state.isModal}
            title={GraphEditorMessages.UN_DEPLOY_MODAL_TITLE}
            message={GraphEditorMessages.UN_DEPLOY_MODAL_MSG}
            handleOk={this.handleUnDeploySchematic}
            handleCancel={this.cancelUnDeploy}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  schematicState: state.SchematicReducer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSchematicComponent: (id) => dispatch(deleteSchematicComponent(id)),
  clearSchematicState: () => dispatch(clearSchematicState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GraphEditor));
