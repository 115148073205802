import {
  Dropdown, Icon, Input, Menu, DatePicker, Statistic,
} from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import icSort from '../../assets/ic_sort';
import icSortActive from '../../assets/ic_sort_active';
import ExpandableFilter from '../ExpandableFilter';
import './HeaderNav.scss';

const { Search } = Input;
const { RangePicker } = DatePicker;

const menuItem = (filterValue) => {
  const value = filterValue || [''];
  const menu = value.map((item) => (
    <Menu.Item key={item.key}>
      <span className="dropdown-menu">{item.name}</span>
    </Menu.Item>
  ));

  return menu;
};

const alertActionMenuItem = (filterValue, isActionDropDown, alertDiscardIds, alertAssocIds, eventList) => {
  const value = [];
  filterValue.forEach(item => {
    if (isActionDropDown && item === 'Close') {
      value.push(<Menu.Item key='Close'>
        <span className="dropdown-menu">{item}</span>
      </Menu.Item>);
    }
    else if (isActionDropDown && item === 'Discard') {
      value.push(<Menu.Item key='Discard'>
        <span className="dropdown-menu">{item}</span>
      </Menu.Item>);
    }

    //check whether alert discard ids span multiple events
    else if (item === 'Associate') {
      if (alertDiscardIds.length > 1 ) {
        value.push(<Menu.Item key='Associate'>
          <span className="dropdown-menu">{item}</span>
        </Menu.Item>)
      };
    }
    //alert discard ids tagged to event > 1
    else if (item === 'Disassociate' && alertAssocIds?.length >= 1 && alertDiscardIds?.length === alertAssocIds?.length) {
      value.push(<Menu.Item key='Disassociate'>
        <span className="dropdown-menu">{item}</span>
      </Menu.Item>);
    }
  });
  return value;
}
const menu = (filterValue, handleFilterClick, sortOrder) => (
  <Menu
    onClick={(e) => {
      handleFilterClick(e);
    }}
    selectedKeys={[sortOrder]}
    defaultOpenKeys={[1]}
  >
    <Menu.ItemGroup title="Sort By" className="sort-menu">
      {menuItem(filterValue, sortOrder)}
    </Menu.ItemGroup>
  </Menu>
);

const alertActionMenu = (filterValue, handleBtnClick, handleCloseBtnClick, handleAssocBtnClick, handleDisassocBtnClick, isActionDropDown, alertDiscardIds, alertAssocIds, eventList) =>
(
  <Menu
    onClick={(e) => {
      e.key === 'Close' && handleCloseBtnClick(e);
      e.key === 'Discard' && handleBtnClick(e);
      (e.key === 'Associate') && handleAssocBtnClick(e);
      (e.key === 'Disassociate') && handleDisassocBtnClick(e)
    }}
    defaultOpenKeys={[1]}
  >
    <Menu.ItemGroup title="Actions" className="sort-menu">
      {alertActionMenuItem(filterValue, isActionDropDown, alertDiscardIds, alertAssocIds, eventList)}
    </Menu.ItemGroup>
  </Menu>
);
class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  componentDidMount() {
    const { keyword } = this.props;
    this.setState({
      search: keyword,
    });
  }

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSort = (e) => {
    const { onSort } = this.props;
    onSort(e.key);
  };

  handleFilterClick = (e) => {
    const { onFilter } = this.props;
    onFilter(e.key);
  };

  renderSearchBar() {
    const { isSearchBar, searchClass, onSearch } = this.props;
    const { search } = this.state;
    return (
      <>
        {isSearchBar && (
          <Search
            className={searchClass}
            onChange={(e) => this.handleChange(e)}
            onSearch={(e) => onSearch(e)}
            value={search}
            allowClear
          />
        )}
      </>
    );
  }

  renderRangePicker() {
    const { startDate, endDate, onDateRangeChange } = this.props;
    let value = null;

    if (startDate && startDate !== '' && endDate && endDate !== '') value = [moment(startDate), moment(endDate)];
    else if (
      localStorage.getItem('startDate')
      && localStorage.getItem('startDate') !== ''
      && localStorage.getItem('endDate')
      && localStorage.getItem('endDate') !== ''
    ) {
      value = [
        moment(localStorage.getItem('startDate')),
        moment(localStorage.getItem('endDate')),
      ];
    }

    return (
      <>
        <RangePicker
          defaultValue={value}
          onChange={(moments, dates) => onDateRangeChange(dates)}
          format="DD-MMM-YYYY"
        />
      </>
    );
  }

  renderStats() {
    const { totalCount, countExDate } = this.props;
    return (
      <>
        {totalCount !== 0 && (
          <Statistic
            style={{ 'margin-left': '30px' }}
            title="Filtered"
            value={totalCount}
          />
        )}
        {totalCount !== 0 && countExDate !== 0 && (
          <Statistic
            style={totalCount !== 0 ? { 'margin-left': '10px' } : { 'margin-left': '30px' }}
            title="Total"
            value={countExDate}
          />
        )}
      </>
    );
  }

  renderSort() {
    const {
      isSort, sortValue, sortOrder, isSortActive,
    } = this.props;
    return (
      <>
        {isSort && (
          <Dropdown
            overlay={menu(sortValue, this.handleSort, sortOrder)}
            trigger={['click']}
          >
            <button type="button" className="sort">
              <img alt="" src={isSortActive ? icSortActive : icSort} />
            </button>
          </Dropdown>
        )}
      </>
    );
  }

  renderAlertActions() {
    const { isActionDropDown, handleBtnClick, handleCloseBtnClick, handleAssocBtnClick, handleDisassocBtnClick, alertDiscardIds, alertAssocIds, eventList } = this.props;
    return (<> {isActionDropDown && <Dropdown overlay={alertActionMenu(
      ['Discard', 'Close', 'Associate', 'Disassociate'],
      handleBtnClick, handleCloseBtnClick, handleAssocBtnClick, handleDisassocBtnClick, isActionDropDown, alertDiscardIds, alertAssocIds, eventList)} trigger={['click']}>
      <button type="button" className="header-btn">
        Actions
      </button>
    </Dropdown>} </>)
  }

  render() {
    const {
      isExpandableFilter,
      headLabels,
      childLabels,
      onHeadPress,
      onChildPress,
      onToggleOpen,
      onApply,
      onReset,
      expandedIndex,
      hasFilterActive,
      fromAlerts,
      isRefreshBtn,
      isActionDropDown,
      handleRefresh,
    } = this.props;

    // hide statistics when alert action dropdown enabled
    const displayStats = fromAlerts
      && !isActionDropDown
      && ((localStorage.getItem('startDate')
        && localStorage.getItem('startDate') !== ''
        && localStorage.getItem('endDate')
        && localStorage.getItem('endDate') !== '')
        || hasFilterActive
        || localStorage.getItem('keyword')
        || localStorage.getItem('keyword') !== '');
    return (
      <div className="Searchbar" style={{ display: 'flex' }}>
        {this.renderSearchBar()}
        {this.renderSort()}
        {isExpandableFilter && (
          <ExpandableFilter
            headLabels={headLabels}
            childLabels={childLabels}
            onHeadPress={onHeadPress}
            onChildPress={onChildPress}
            onToggleOpen={onToggleOpen}
            onApply={onApply}
            onReset={onReset}
            expandedIndex={expandedIndex}
            hasFilterActive={hasFilterActive}
          />
        )}
        {fromAlerts && this.renderRangePicker()}
        {displayStats && this.renderStats()}
        {isRefreshBtn && (
          <button type="button" onClick={handleRefresh} className="refresh-btn">
            <Icon type="sync" />
          </button>
        )}
        {isActionDropDown && this.renderAlertActions()}
      </div>
    );
  }
}

HeaderNav.propTypes = {
  onSort: PropTypes.func,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
  isActionDropDown: PropTypes.bool,
  isSort: PropTypes.bool,
  isSearchBar: PropTypes.bool,
  hasFilterActive: PropTypes.bool,
  isExpandableFilter: PropTypes.bool,
  searchClass: PropTypes.string,
  /** Selected sort highlighted */
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isSortActive: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  sortValue: PropTypes.arrayOf(PropTypes.shape()),
  totalCount: PropTypes.number,
  countExDate: PropTypes.number,
  headLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  childLabels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onHeadPress: PropTypes.func.isRequired,
  onChildPress: PropTypes.func.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  expandedIndex: PropTypes.number.isRequired,
  fromAlerts: PropTypes.bool.isRequired,
  isRefreshBtn: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func,
  handleBtnClick: PropTypes.func.isRequired,
  handleCloseBtnClick: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
};

HeaderNav.defaultProps = {
  isSearchBar: true,
  isSort: true,
  isExpandableFilter: false,
  isActionDropDown: false,
  onFilter: () => { },
  onSort: () => { },
  onSearch: () => { },
  hasFilterActive: false,
  handleRefresh: () => { },
  searchClass: 'search-bar',
  sortValue: [
    { name: 'Name: A-Z', key: 1 },
    { name: 'Name: Z-A', key: -1 },
  ],
  totalCount: 0,
  countExDate: 0,
};

export default HeaderNav;
