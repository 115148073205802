import * as Types from "../Types/discussionThread.types";
import { GETRequest, POSTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

export const getMessages = (id, limit, skip) => (dispatch) => {
  dispatch({ type: Types.GET_MESSAGE_PROGRESS });
  GETRequest(`${endPoints.alertsDiscussion}${id}?limit=${limit}&skip=${skip}`)
    .then((res) =>
      dispatch({ type: Types.GET_MESSAGE_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: Types.GET_MESSAGE_ERROR, payload: err }));
};

export const addMessage = (payload) => (dispatch) => {
  dispatch({ type: Types.CREATE_MESSAGE_PROGRESS });
  POSTRequest(`${endPoints.alertsDiscussion}`, payload)
    .then((res) =>
      dispatch({ type: Types.CREATE_MESSAGE_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.CREATE_MESSAGE_ERROR, payload: err })
    );
};

export const clearState = () => (dispatch) =>
  dispatch({ type: Types.CLEAR_DATA });
