import { SideMenuVariables } from "../../constants/variables";
import * as SidemenuIcon from "../../assets/ic_sidemenu";
import * as Routes from "../../constants/routes";
import { roleGaurd } from "../../gaurds";

export const menuOptions = [
  {
    label: SideMenuVariables.DASHBOARD,
    icon: SidemenuIcon.ic_desktop,
    path: Routes.dashboard,
    class: "dashboard",
    active: () => roleGaurd(Routes.dashboard),
  },
  {
    label: SideMenuVariables.CLIENTS,
    icon: SidemenuIcon.ic_clients,
    path: Routes.clients,
    class: "clients",
    active: () => roleGaurd(Routes.clients),
  },
  {
    label: SideMenuVariables.ML_ALGORITHM,
    icon: SidemenuIcon.ic_role,
    path: Routes.mlAlgorithm,
    class: "roles",
    active: () => roleGaurd(Routes.mlAlgorithm),
  },
  {
    label: SideMenuVariables.ASSET_LIBRARY,
    icon: SidemenuIcon.ic_assets,
    path: Routes.assetLibrary,
    class: "assest-library",
    active: () => roleGaurd(Routes.assetLibrary),
  },
  {
    label: SideMenuVariables.UNIT_PROPERTIES,
    icon: SidemenuIcon.ic_units,
    path: Routes.unitProperties,
    class: "unit-property",
    active: () => roleGaurd(Routes.unitProperties),
  },
  {
    label: SideMenuVariables.USER_MANGEMENT,
    icon: SidemenuIcon.ic_user_management,
    path: Routes.userManagement,
    class: "usermangmnt",
    active: () => roleGaurd(Routes.userManagement),
  },
  {
    label: SideMenuVariables.SETTING,
    icon: SidemenuIcon.ic_setting,
    path: Routes.setting,
    class: "setting",
    active: () => roleGaurd(Routes.setting),
  },
  {
    label: SideMenuVariables.SYSTEMSCHEMATIC,
    // icon: SidemenuIcon.ic_setting,
    path: Routes.schematic,
    class: "systemSchematic",
    active: () => roleGaurd(Routes.schematic),
  },
];

export const activeTab = {};
activeTab[Routes.root] = Routes.dashboard;
activeTab[Routes.app] = Routes.dashboard;
activeTab[Routes.dashboard] = Routes.dashboard;
activeTab[Routes.clients] = Routes.clients;
activeTab[Routes.clientInfo] = Routes.clients;
activeTab[Routes.clientAdd] = Routes.clients;
activeTab[Routes.clientFacility] = Routes.clients;
activeTab[Routes.clientSystem] = Routes.clients;
activeTab[Routes.systemSchematic] = Routes.clients;
activeTab[Routes.clientEquipment] = Routes.clients;
activeTab[Routes.componentSchematic] = Routes.clients;
activeTab[Routes.componentInformation] = Routes.clients;
activeTab[Routes.addUser] = Routes.userManagement;
activeTab[Routes.addEquipment] = Routes.assetLibrary;
activeTab[Routes.manageTags] = Routes.clients;

export const defaultDisabledMenu = {};
defaultDisabledMenu[Routes.root] = Routes.dashboard;
defaultDisabledMenu[Routes.app] = Routes.dashboard;
defaultDisabledMenu[Routes.dashboard] = Routes.dashboard;
defaultDisabledMenu[Routes.clientInfo] = Routes.clients;
defaultDisabledMenu[Routes.clientAdd] = Routes.clients;
defaultDisabledMenu[Routes.clientFacility] = Routes.clients;
defaultDisabledMenu[Routes.clientSystem] = Routes.clients;
defaultDisabledMenu[Routes.clientEquipment] = Routes.clients;
defaultDisabledMenu[Routes.componentSchematic] = Routes.clients;
defaultDisabledMenu[Routes.componentInformation] = Routes.clients;
defaultDisabledMenu[Routes.systemSchematic] = Routes.clients;
defaultDisabledMenu[Routes.addEquipment] = Routes.assetLibrary;
defaultDisabledMenu[Routes.manageTags] = Routes.clients;
defaultDisabledMenu[Routes.schematic] = Routes.schematic;
