import React from 'react';
import eventDescription from '../../../../../../libs/description';

const RenderDescription = (alertData) => {
     
    const getAlertDescription = () => {
        let text = "";
        if (alertData && alertData.identifierDetails) {
            const { type, description, alertMetaObject, level } = alertData
            const { variableName, variableTagNumber, displayVariableTagNumber } = alertData.identifierDetails
            if( type === 'Process deviation' && level === 'Medium')
                return description;
            text = eventDescription({
                type,
                variableName,
                variableTagNumber,
                displayVariableTagNumber,
                alertMetaObject,
                description,
            })
            return text
        }
        return text
    }
    return (
        <>
            <div className='details_description_heading' style={{marginTop:'0.9rem'}}>
                Event Description:
            </div>
            <div className='details_description'>
                { getAlertDescription()}
            </div>
        </>
    )
}

export default RenderDescription