import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./redux";
import { routes } from "./App.routing";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as VersionJson from './version.json';


const isDev = process.env.REACT_APP_ENV === "development";

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  release: 'v' + VersionJson?.version,
  environment: process.env.REACT_APP_ENV,
  debug: isDev ? true : false,
  tracesSampleRate: isDev ? 1.0 : 0.2,
  integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
  <Provider store={store}>
    <App routes={routes} />
  </Provider>,
  document.getElementById('root')
);