export const PLOTS_FETCH_PROGRESS = "PLOTS_FETCH_PROGRESS";
export const PLOTS_FETCH_ERROR = "PLOTS_FETCH_ERROR";
export const PLOTS_FETCH_SUCCESS = "PLOTS_FETCH_SUCCESS";
export const PLOTS_CLEAR_STATE = "PLOTS_CLEAR_STATE";
export const PLOTS_PARTICULAR_FETCH_SUCCESS = "PLOTS_PARTICULAR_FETCH_SUCCESS";

export const PLANT_TIME_PROGRESS = "PLANT_TIME_PROGRESS";
export const PLANT_TIME_SUCCESS = "PLANT_TIME_SUCCESS";
export const PLANT_TIME_ERROR = "PLANT_TIME_ERROR";
export const CLEAR_PLANT_TIME = "CLEAR_PLANT_TIME";

export const BATCH_TIME_PROGRESS = "BATCH_TIME_PROGRESS";
export const BATCH_TIME_SUCCESS = "BATCH_TIME_SUCCESS";
export const BATCH_TIME_ERROR = "BATCH_TIME_ERROR";
export const CLEAR_BATCH_TIME = "CLEAR_BATCH_TIME";
