import { Icon, Input } from 'antd';
import React from 'react';
import { controlValveDesignDataVariables } from '../../../../../../constants/variables';
import { newValidateNumber } from '../../../../../../libs/validator';

export const dataUnitsOptions = (units) => {
  if (units) {
    return units.map((item) => <option value={item}>{item}</option>);
  }
  return null;
};

export const ValveOperationDataTable = (config) => [
  {
    title: 'Valve Operation Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => value,
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => undefined,
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === controlValveDesignDataVariables.purpose ||
          item.name === controlValveDesignDataVariables.type ||
          item.name === controlValveDesignDataVariables.inherentCharacter ||
          item.name === controlValveDesignDataVariables.actuator) &&
        item.design === '' &&
        config.saveListener
      );
      return (
        <div
          className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
            tempError ? 'error' : 'not-error'
          }  `}
        >
          <select
            value={!item.design ? '' : item.design}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'select')
            }
            className={tempError ? 'error' : 'not-error'}
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'valveOpData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'valveOpData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];
export const ReqdCalcDataTable = (config) => [
  {
    title: 'Required Calculation Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          {value} <span className="mandatory">*</span>
        </div>
      );
    },
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === controlValveDesignDataVariables.configDP ||
        item.name === controlValveDesignDataVariables.nominalDiameter ||
        item.name === controlValveDesignDataVariables.inletDiameter ||
        item.name === controlValveDesignDataVariables.outletDiameter ||
        item.name === controlValveDesignDataVariables.criticalPressure
      ) {
        const tempError = !!(
          (item.name === controlValveDesignDataVariables.configDP ||
            item.name === controlValveDesignDataVariables.nominalDiameter ||
            item.name === controlValveDesignDataVariables.inletDiameter ||
            item.name === controlValveDesignDataVariables.outletDiameter ||
            item.name === controlValveDesignDataVariables.criticalPressure) &&
          !item.unit &&
          config.saveListener
        );
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === controlValveDesignDataVariables.fluidPhase ||
        item.name === controlValveDesignDataVariables.valveDPToggle
      ) {
        const tempError = !item.design && config.saveListener;
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.design ? '' : item.design}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'select')
              }
              className={tempError ? 'error' : 'not-error'}
            >
              <option value="">Select</option>
              {data[item.name] &&
                data[item.name].map((item) => (
                  <option value={item} title={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div>
        );
      }
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={'not-error'}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'reqCalcData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'reqCalcData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];
export const PipingGeoDataTable = (config) => [
  {
    title: 'Piping Geometry Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => value,
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => undefined,
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={'not-error'}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'pipingGeoData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'pipingGeoData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];
export const StrokeMassFlowTable = (config) => [
  {
    title: 'Stroke and Mass Flow Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => value,
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === controlValveDesignDataVariables.valveMaxFlowrate ||
        item.name === controlValveDesignDataVariables.valveMaxStroke
      ) {
        const tempError = !!(
          (item.name === controlValveDesignDataVariables.valveMaxFlowrate ||
            item.name === controlValveDesignDataVariables.valveMaxStroke) &&
          !item.unit &&
          item.design &&
          config.saveListener
        );
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        (item.name === controlValveDesignDataVariables.valveMaxFlowrate ||
          item.name === controlValveDesignDataVariables.valveMaxStroke) &&
        item.unit &&
        !item.design &&
        config.saveListener
      );
      return (
        <div>
          <Input
            type="number"
            value={item.design}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'number')
            }
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row, 'strokeMassData')}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row, 'strokeMassData')}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const KeyChange = {
  [controlValveDesignDataVariables.purpose]: 'purpose',
  [controlValveDesignDataVariables.type]: 'type',
  [controlValveDesignDataVariables.inherentCharacter]: 'inherentCharacter',
  [controlValveDesignDataVariables.actuator]: 'actuator',
  [controlValveDesignDataVariables.equationConstantN1]: 'equationConstantN1',
  [controlValveDesignDataVariables.equationConstantN2]: 'equationConstantN2',
  [controlValveDesignDataVariables.equationConstantN3]: 'equationConstantN3',
  [controlValveDesignDataVariables.configDP]: 'configDP',
  [controlValveDesignDataVariables.nominalDiameter]: 'nominalDiameter',
  [controlValveDesignDataVariables.inletDiameter]: 'inletDiameter',
  [controlValveDesignDataVariables.outletDiameter]: 'outletDiameter',
  [controlValveDesignDataVariables.fluidPhase]: 'fluidPhase',
  [controlValveDesignDataVariables.ratedValveFlowCoeff]: 'ratedValveFlowCoeff',
  [controlValveDesignDataVariables.liqDPrecoveryFactor]: 'liqDPrecoveryFactor',
  [controlValveDesignDataVariables.chokedDPratioFactor]: 'chokedDPratioFactor',
  [controlValveDesignDataVariables.velHeadLossCoeff]: 'velHeadLossCoeff',
  [controlValveDesignDataVariables.pipingGeoFactor]: 'pipingGeoFactor',
  [controlValveDesignDataVariables.valveMaxStroke]: 'valveMaxStroke',
  [controlValveDesignDataVariables.valveMaxFlowrate]: 'valveMaxFlowrate',
  [controlValveDesignDataVariables.criticalPressure]: 'criticalPressure',
  [controlValveDesignDataVariables.valveDPToggle]: 'valveDPToggle',
  [controlValveDesignDataVariables.valveOpenThreshold]: 'valveOpenThreshold',
};

export const initialData = [
  {
    name: controlValveDesignDataVariables.purpose,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.type,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.inherentCharacter,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.actuator,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.equationConstantN1,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.equationConstantN2,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.equationConstantN3,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.configDP,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.nominalDiameter,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.inletDiameter,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.outletDiameter,
    unit: null,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.fluidPhase,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.ratedValveFlowCoeff,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.liqDPrecoveryFactor,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.chokedDPratioFactor,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.velHeadLossCoeff,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.pipingGeoFactor,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.valveMaxStroke,
    design: parseFloat(''),
    unit: null,
  },
  {
    name: controlValveDesignDataVariables.valveMaxFlowrate,
    design: parseFloat(''),
    unit: null,
  },
  {
    name: controlValveDesignDataVariables.valveDPToggle,
    design: parseFloat(''),
  },
  {
    name: controlValveDesignDataVariables.criticalPressure,
    design: parseFloat(''),
    unit: null,
  },
  {
    name: controlValveDesignDataVariables.valveOpenThreshold,
    design: parseFloat(''),
  },
];

/*to populate dropdowns*/
export const data = {
  [controlValveDesignDataVariables.purpose]: [
    'Throttling Valve',
    'Control Valve',
    'Bypass Valve',
    'Sanitary Valve',
    'Isolation Valve',
  ],
  [controlValveDesignDataVariables.type]: [
    'Ball Valve',
    'Butterfly Valve',
    'Diaphragm Valve',
    'Globe Valve',
    'Pinch Valve',
    'Plug Valve',
  ],
  [controlValveDesignDataVariables.inherentCharacter]: [
    'Linear',
    'Quick Opening',
    'Equal Percentage',
  ],
  [controlValveDesignDataVariables.actuator]: [
    'Manual',
    'Electric',
    'Hydraulic',
    'Electro-Hydraulic',
    'Pneumatic',
  ],
  [controlValveDesignDataVariables.fluidPhase]: ['liquid', 'gas'],
  [controlValveDesignDataVariables.valveDPToggle]: ['True', 'False'],
};

export const filterValveOperationData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === controlValveDesignDataVariables.purpose ||
      item.name === controlValveDesignDataVariables.type ||
      item.name === controlValveDesignDataVariables.inherentCharacter ||
      item.name === controlValveDesignDataVariables.actuator
    ) {
      return item;
    }
    return undefined;
  });
};
export const filterReqdCalcData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === controlValveDesignDataVariables.equationConstantN1 ||
      item.name === controlValveDesignDataVariables.equationConstantN2 ||
      item.name === controlValveDesignDataVariables.equationConstantN3 ||
      item.name === controlValveDesignDataVariables.configDP ||
      item.name === controlValveDesignDataVariables.nominalDiameter ||
      item.name === controlValveDesignDataVariables.inletDiameter ||
      item.name === controlValveDesignDataVariables.outletDiameter ||
      item.name === controlValveDesignDataVariables.fluidPhase ||
      item.name === controlValveDesignDataVariables.ratedValveFlowCoeff ||
      item.name === controlValveDesignDataVariables.liqDPrecoveryFactor ||
      item.name === controlValveDesignDataVariables.chokedDPratioFactor ||
      item.name === controlValveDesignDataVariables.valveDPToggle ||
      item.name === controlValveDesignDataVariables.criticalPressure ||
      item.name === controlValveDesignDataVariables.valveOpenThreshold
    ) {
      return item;
    }
    return undefined;
  });
};
export const filterPipingGeoData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === controlValveDesignDataVariables.velHeadLossCoeff ||
      item.name === controlValveDesignDataVariables.pipingGeoFactor
    ) {
      return item;
    }
    return undefined;
  });
};
export const filterStrokeMassFlowData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === controlValveDesignDataVariables.valveMaxFlowrate ||
      item.name === controlValveDesignDataVariables.valveMaxStroke
    ) {
      return item;
    }
    return undefined;
  });
};

export const blockDesignDataMap = (row,tablename) =>
{
  const mapper = {
    valveOpData: [0,1,2,3],
    reqCalcData: [4,5,6,7,8,9,10,11,12,13,14,19,20,21],
    pipingGeoData: [15,16],
    strokeMassData: [17,18],
  }
  return mapper[tablename][row];
}