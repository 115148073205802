import React, { Component } from "react";
import "./Dashboard.scss";
import DashboardInfo from "./DashboardInfo/Dashboard.info";
import DashboardTree from "./DashboardTree/Dashboard.tree";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: null,
      isInfo: false,
    };

    this.setInfo = this.setInfo.bind(this);
    this.clearInfoState = this.clearInfoState.bind(this);
  }

  setInfo(info) {
    this.setState({
      info,
      isInfo: true,
    });
  }

  clearInfoState() {
    this.setState({
      isInfo: false,
    });
  }

  render() {
    return (
      <div className="Dashboard">
        <DashboardTree
          setInfo={(info) => {
            this.setInfo(info);
          }}
        />
        <DashboardInfo
          info={this.state.info}
          isInfo={this.state.isInfo}
          clearInfoState={() => {
            this.clearInfoState();
          }}
        />
      </div>
    );
  }
}

export default Dashboard;
