import {
  SYSTEM_FETCH_ERROR,
  SYSTEM_FETCH_PROGRESS,
  SYSTEM_FETCH_SUCCESS,
  SYSTEM_CLEAR_STATE,
  ADD_SYSTEM_FETCH_SUCCESS,
  ADD_SYSTEM_FETCH_PROGRESS,
  ADD_SYSTEM_FETCH_ERROR,
  EDIT_SYSTEM_FETCH_ERROR,
  EDIT_SYSTEM_FETCH_SUCCESS,
  EDIT_SYSTEM_FETCH_PROGRESS,
} from "../Types/System.types";

import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";

export function getSystem(query) {
  return function (dispatch) {
    dispatch({ type: SYSTEM_FETCH_PROGRESS });
    GETRequest(`systems/${query}`)
      .then((res) => {
        dispatch({ type: SYSTEM_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: SYSTEM_FETCH_ERROR, payload: err });
      });
  };
}

export function addSystem(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_SYSTEM_FETCH_PROGRESS });
    POSTRequest("systems", payload)
      .then((res) => {
        dispatch({ type: ADD_SYSTEM_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_SYSTEM_FETCH_ERROR, payload: err });
      });
  };
}

export function editSystem(payload, id, facilityId) {
  return function (dispatch) {
    dispatch({ type: EDIT_SYSTEM_FETCH_PROGRESS });
    PUTRequest(`systems/${id}/${facilityId}`, payload)
      .then((res) => {
        dispatch({ type: EDIT_SYSTEM_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: EDIT_SYSTEM_FETCH_ERROR, payload: err });
      });
  };
}

export function clearSystemState() {
  return function (dispatch) {
    dispatch({ type: SYSTEM_CLEAR_STATE });
  };
}
