import React, { useEffect, useState } from "react";
import { Layout, Menu, Row, Col, DatePicker, Button, Spin, Modal, Pagination, message, Checkbox } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./RunsDetail.scss";
import Details from "./Details.jsx";
import {
  getRunTagsList,
  filterRunTag,
  getRunTags as getTags,
  clearState,
  clearFilterState,
  clearRecalculateState,
  clearErrorState,
  getRunNumber,
  getFilterRunNumber,
  bulkDeleteRun,
} from "../../../../redux/actions/runTags.action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import highAlert from "../../../../assets/ic_alert_high/ic_alert_high.png";
import mediumAlert from "../../../../assets/ic_alert_medium/ic_alert_medium.png";
import lowAlert from "../../../../assets/ic_alert_low/ic_alert_low.png";
import removeFilter from "../../../../assets/ic_filter_active/ic_filter_active.png";
import addFilter from "../../../../assets/ic_filter/filter.png";
import NewRuns from "./newRuns";
// import Tour from "./tourRuns";
import runStatuses from "./DummyData/runStatus";
//import RecalculateComponent from "./RecalculateKPI";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const Runs = (props) => {
  //Redux Connection //
  let data = useSelector((state) => state.RunTagsReducer);
  const dispatch = useDispatch();
  const getRunTagList = data.getRunTagList;
  const newRuns = data.submitNewRun;
  const deleteRun = data.deleteRun;
  const filter = data.filterRuns;
  const recalculate = data.recalculateRuns;
  const getTotalRunNumber = data.totalRunNumber;
  const totalFilterRunNumber = data.totalFilterRunNumber;
  const runTagsInfo = data.getRunTags;
  const tourState = data.runTour;
  const { activeKey } = props;
  // State Defintion //
  const [equipmentId, setEquipmentId] = useState();
  const [runTags, setRunTags] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(20);
  const [isLoaded, setIsLoaded] = useState(false);
  const [runId, setRunId] = useState("");
  const [runNumber, setRunNo] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [comment, setComment] = useState();
  const [errorMessage, setError] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [filterIndicator, setFilterIndicator] = useState(false);
  const [totalRunNumber, setTotalRunNumber] = useState(0);
  const [filterRunNumber, setfilterRunNumber] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterpageNumber, setfilterPageNumber] = useState(1);
  const [hamburger, setHamburger] = useState(false);
  const [selectedRun, setSelectedRun] = useState(0);
  const [bulkDeleteFlag, setBulkDeleteFlag] = useState(false);
  const [bulkDeleteList, setBulkDeleteList] = useState({
    lastDate : [],
    IdArray : []
  });
  //ComponentDidMount and ComponentDidUpdate//
  useEffect(() => {
    if (recalculate.isLoading) {
      //setIsLoaded(false);
      message.info("Recalculating Run KPIs")
    }
    else if (recalculate.isSuccess) {
      dispatch(clearRecalculateState());
      message.info("Run KPIs recalculation successful")
    }
    else if (recalculate.isError) {
      dispatch(clearErrorState());
      setIsLoaded(true);
      message.info("Run KPIs recalculation failed");
    }
  }, [// eslint-disable-line
    recalculate,
  ]);

  useEffect(() => {
    if (activeKey === '4') {
      if (
        !getRunTagList.isSuccess && props.info.equipmentList._id !== undefined
      ) {
        dispatch(getRunTagsList(props.info.equipmentList._id, skip, limit));
        dispatch(getRunNumber(props.info.equipmentList._id))
        dispatch(getTags(props.info.equipmentList._id));
        setEquipmentId(props.info.equipmentList._id);
        //setIsLoaded(false);
      } else if (
        getRunTagList.isSuccess &&
        getRunTagList.data.items[selectedRun] !== undefined &&
        tourState.startTour === true
      ) {
        setRunTags(getRunTagList.data.items);
        setRunId(getRunTagList.data.items[selectedRun]._id);
        setRunNo(getRunTagList.data.items[selectedRun].runNumber);
        setStartTime(getRunTagList.data.items[selectedRun].start);
        setEndTime(getRunTagList.data.items[selectedRun].end);
        setComment(getRunTagList.data.items[selectedRun].comment);
        setIsLoaded(true);
      } else if (
        getRunTagList.isSuccess &&
        getRunTagList.data.items[0] !== undefined
      ) {
        setSelectedRun(0)
        setRunTags(getRunTagList.data.items);
        setRunId(getRunTagList.data.items[0]._id);
        setRunNo(getRunTagList.data.items[0].runNumber);
        setStartTime(getRunTagList.data.items[0].start);
        setEndTime(getRunTagList.data.items[0].end);
        setComment(getRunTagList.data.items[0].comment);
        setIsLoaded(true);
      }
      else if (
        getRunTagList.isError ||
        getRunTagList.data.items[0] === undefined
      ) {
        setRunTags([]);
        setIsLoaded(true);
        setRunId("");
      }
      setFilterIndicator(false);
    }
  }, [// eslint-disable-line
    getRunTagList.isSuccess, activeKey, tourState
  ]);

  useEffect(() => {
    if (
      props.info.equipmentList._id !== equipmentId && equipmentId !== undefined && activeKey === "4"
    ) {
      dispatch(getRunTagsList(props.info.equipmentList._id, skip, limit));
      dispatch(getRunNumber(props.info.equipmentList._id));
      dispatch(getTags(props.info.equipmentList._id));
      setEquipmentId(props.info.equipmentList._id);
      setFilterIndicator(false);
      setIsLoaded(false);
      setBulkDeleteFlag(false)
      setBulkDeleteList({ 
        lastDate : [],
        IdArray : []})
    }
  }, [ // eslint-disable-line
    props.info.equipmentList._id, activeKey
  ]);

  useEffect(() => {
    if (getTotalRunNumber.isSuccess) {
      setTotalRunNumber(getTotalRunNumber.data);
    }
  }, [// eslint-disable-line
    getTotalRunNumber.isSuccess
  ]);

  useEffect(() => {
    if (newRuns.isSuccess === true) {
      dispatch(getRunTagsList(props.info.equipmentList._id, skip, limit));
      setIsLoaded(false)
    }
    else if (newRuns.isError === true) {
      setError(newRuns.message);
      dispatch(clearErrorState());
    }
    if (deleteRun.isSuccess === true) {
      dispatch(getRunTagsList(props.info.equipmentList._id, skip, limit));
      dispatch(clearState());
    }
  }, [// eslint-disable-line
    newRuns, deleteRun.isSuccess
  ]);

  useEffect(() => {
    if (filter.isSuccess === true) {
      setRunTags(filter.data);
      setFilterIndicator(true);
      setIsLoaded(true);
      setRunId((filter.data[0]) ? filter.data[0]._id : "");
      setRunNo((filter.data[0]) ? filter.data[0].runNumber : "");
      setEndTime((filter.data[0]) ? filter.data[0].end : "");
      setStartTime((filter.data[0]) ? filter.data[0].start : "");
    }
    else if (filter.isLoading === true) {
      setIsLoaded(false);
    }
    else if (filter.isError === true) {
      setError(filter.message);
      dispatch(clearErrorState());
    }
    if (totalFilterRunNumber.isSuccess) {
      setfilterRunNumber(totalFilterRunNumber.data);
    }
  }, [// eslint-disable-line
    filter, totalFilterRunNumber.isSuccess
  ]);
  useEffect(() => {
    if (
      getRunTagList.isSuccess &&
      getRunTagList.data.items[0] !== undefined
    ) {
      setRunTags(getRunTagList.data.items);
      setRunId(getRunTagList.data.items[0]._id);
      setRunNo(getRunTagList.data.items[0].runNumber);
      setStartTime(getRunTagList.data.items[0].start);
      setEndTime(getRunTagList.data.items[0].end);
      setComment(getRunTagList.data.items[0].comment);
      setIsLoaded(true);
    }
    setFilterIndicator(false);
  }, [ // eslint-disable-line
    filterDate
  ]);

  useEffect(() => {
    if (tourState.startTour === true) {
      setRunTags(runStatuses)
      setRunNo(runStatuses[0].runNumber);
      setStartTime(runStatuses[0].start);
      setEndTime(runStatuses[0].end);
      setComment(runStatuses[0].comment);
      setRunId(runStatuses[0]._id)
    }
  }, [// eslint-disable-line
    tourState
  ]);


  //filter runs section//
  const filterTags = () => {
    let skip = 0;
    if (filterDate.length !== 2) {
      setError("Please select both filter limits");
    }
    else if (filterDate[1] < filterDate[0]) {
      setError("End time can not be greater than start time");
    }
    else if (filterDate.length === 2) {
      dispatch(getFilterRunNumber(equipmentId, filterDate[0], filterDate[1]));
      dispatch(filterRunTag(equipmentId, filterDate[0], filterDate[1], skip, limit));
    }
  };
  const filterRunsData = () => {
    if (filterIndicator === false) {
      return (
        <>
          <Button
            type="default"
            className="filter"
            onClick={() => filterTags()}
            disabled={totalRunNumber === 0}
          >
            <img
              src={addFilter}
              alt="addFilter"
              className="add-filter"
            />
          </Button>
        </>
      );
    } else if (filterIndicator === true) {
      return (
        <>
          <Button
            type="default"
            className="filter"
            onClick={() => {
              setFilterIndicator(false);
              setRunTags(getRunTagList.data.items);
              setRunId((getRunTagList.data.items[0]) ? getRunTagList.data.items[0]._id : "");
              setRunNo((getRunTagList.data.items[0]) ? getRunTagList.data.items[0].runNumber : "");
              setEndTime((getRunTagList.data.items[0]) ? getRunTagList.data.items[0].end : "");
              setStartTime((getRunTagList.data.items[0]) ? getRunTagList.data.items[0].start : "");
              dispatch(clearFilterState());
            }}
          >
            <img
              src={removeFilter}
              alt="removeFilter"
              className="remove-filter"
            />
          </Button>
        </>
      );
    }
  };

  const alerts = () => {
    return (
      <Modal
        visible={errorMessage.length}
        title="Alert"
        onCancel={() => setError("")}
        onOk={() => setError("")}
        closable={false}
      >
        <div>{errorMessage}</div>
      </Modal>
    );
  };
  const handlePageClick = (pageNumber) => {
    if (filterIndicator) {
      const skipRun = (pageNumber - 1) * 20;
      setfilterPageNumber(pageNumber);
      dispatch(filterRunTag(equipmentId, filterDate[0], filterDate[1], skipRun, limit));
    }
    else {
      const skipRun = (pageNumber - 1) * 20;
      setSkip(skipRun);
      setPageNumber(pageNumber);
      dispatch(getRunTagsList(props.info.equipmentList._id, skipRun, limit));
    }
  };
  const paginateData = () => {
    if (filterIndicator) {
      return (
        <div className="paginateAlign">
          <Pagination defaultCurrent={1} total={Math.round(filterRunNumber / 2)} onChange={handlePageClick} current={filterpageNumber} />
        </div>
      );
    }

    return (
      <div className="paginateAlign">
        <Pagination defaultCurrent={1} total={Math.round(totalRunNumber / 2)} onChange={handlePageClick} current={pageNumber} />
      </div>
    );

  };
  
  const bulkCheckboxOnClick = (id, date, status) => {
    const bulkDeletePayload = {
      lastDate: [...bulkDeleteList.lastDate],
      IdArray: [...bulkDeleteList.IdArray]
    }
    if (status === "select") {
      bulkDeletePayload.IdArray.push(id)
      bulkDeletePayload.lastDate.push(date)
    }
    else if (status === "deselect") {
      const filteredID = bulkDeletePayload.IdArray.filter(item => item !== String(id))
      const filteredDate = bulkDeletePayload.lastDate.filter(item => item !== date)
      bulkDeletePayload.IdArray = filteredID
      bulkDeletePayload.lastDate = filteredDate
    }
    setBulkDeleteList(bulkDeletePayload)
  }
  const onBulkCancle = () => {
    const bulkDeletePayload = {
      lastDate: [],
      IdArray: []
    }
    setBulkDeleteList(bulkDeletePayload)
  }
  const onBulkDlete = async() => {
    const {autoRecalculate } = data
    if(!bulkDeleteList.IdArray.length){
      message.info("Select runs first")
    }
    else{
      if(autoRecalculate.autoRecalculateMode){
        const sortedDate = bulkDeleteList.lastDate.sort((a,b) => new Date(a) - new Date (b))
        dispatch(bulkDeleteRun(equipmentId, bulkDeleteList, autoRecalculate.autoRecalculateMode, sortedDate))
      }
      else{
        dispatch(bulkDeleteRun(equipmentId, bulkDeleteList, autoRecalculate.autoRecalculateMode))
      }
      const bulkDeletePayload = {
        lastDate: [],
        IdArray: []
      }
      setBulkDeleteList(bulkDeletePayload)
      setBulkDeleteFlag(false)
    }
  }
  //Runs page output//
  const runsPage = () => {
    let wdth = "28em"
    if (hamburger === true) {
      wdth = "0em"
    }
    return (
      <Layout className="runDetails">
        <Sider
          style={{
            overflow: 'auto',
            height: '70em',
            left: 0,
            background: 'white',
          }}
          width={wdth}
          className="menuStyle"
        >
          <Menu mode="inline" selectedKeys={[selectedRun.toString()]}>
            <Row className="menu-padding">
              <Col span={9} className="menuHead">
                Run History
              </Col>
              {(bulkDeleteFlag)? 
              <>
              <Col span={8} className="button-align">
                <Button className="capsule-btn newRun" onClick={onBulkDlete}>
                  Delete Selected
                </Button>
              </Col>
              <Col span={7} className="button-align">
                <Button className="capsule-btn newRun" onClick={() => {setBulkDeleteFlag(false); onBulkCancle()}}>
                  Cancel
                </Button>
              </Col>
              </>
              :
              <> 
              <Col span={7} className="button-align">
                <Button className="capsule-btn bulkDelete" onClick={() => setBulkDeleteFlag(true)}>
                  Bulk Delete
                </Button>
              </Col>
              <Col span={7} className="button-align">
                <NewRuns id={props.info.equipmentList._id} runTagList={runTagsInfo} />
              </Col>
              </>
              }
            </Row>
            <div className="menu-btns">
              <Row className="filterRun">
                <Col span={19} className="filter-Date ">
                  <RangePicker
                    className="date-picker"
                    onChange={(e) => setFilterDate(e)}
                    value={filterDate}
                  />
                </Col>
                <Col span={5} className="filter-btn-align">{filterRunsData()}</Col>
              </Row>
            </div>
            {runTags.map((runs, index) => {
              var num = 0;
              var runNumber = (runs.runNumber === 0) ? "**" : runs.runNumber;
              if (index % 2 === 0) {
                num = 'menu-color0';
              } else {
                num = 'menu-color1';
              }
              var start = moment(runs.start).format('ddd, MMM Do YYYY, h:mm:ss A');
              var end = (runs.end === undefined || runs.end === null || runs.end === "") ? "Machine still running" : moment(runs.end).format('ddd, MMM Do YYYY, h:mm:ss A');
              const alertPriority = () => {
                if (runs.ROS === 'Failure/Trip') {
                  return (
                    <div className="alert-align">
                      <img src={highAlert} alt="alertHigh" />
                    </div>
                  );
                }
                if (runs.ROS === 'Preventive Maintenance') {
                  return (
                    <div className="alert-align">
                      <img src={mediumAlert} alt="alertHigh" />
                    </div>
                  );
                }
                if (runs.ROS === 'Machine operation not required') {
                  return (
                    <div className="alert-align">
                      <img src={lowAlert} alt="alertHigh" />
                    </div>
                  );
                }
              };
              return (
                <Menu.Item
                  key={index}
                  icon={<UserOutlined />}
                  className={num}
                  onClick={() => {
                    if (!bulkDeleteFlag) {
                      if (selectedRun !== index) {
                        setIsLoaded(false)
                      }
                      setRunId(runs._id);
                      setRunNo(runNumber);
                      setStartTime(runs.start);
                      setEndTime(runs.end);
                      setComment(runs.comment);
                      setSelectedRun(index)
                    }
                  }}
                >
                  <div className="runs-menu">
                    <Row>
                      {(bulkDeleteFlag) ? <Col span={2} className="alert-align">
                        <Checkbox
                          onClick={() => bulkCheckboxOnClick(runs._id, runs.start, (bulkDeleteList.IdArray.includes(runs._id)) ? "deselect" : "select") }
                          checked={bulkDeleteList.IdArray.includes(runs._id)}
                        >
                        </Checkbox></Col> : null
                      }
                      <Col span={bulkDeleteFlag ? 2 : 3}>{alertPriority()}</Col>
                      <Col span={bulkDeleteFlag ? 4 : 5} className="alert-align">
                        Run: {runNumber}
                      </Col>
                      <Col span={16}>
                        Start : {start}
                        <br />
                        End : {end}
                      </Col>
                    </Row>
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
          {paginateData()}
        </Sider>
        {Details(runId, runNumber, equipmentId, startTime, endTime,
          comment, setHamburger, hamburger, activeKey, selectedRun, filterIndicator,
          pageNumber, setIsLoaded, bulkDeleteFlag
        )}
        {alerts()}
        {/* {Tour(isLoaded)} */}
      </Layout>
    );
  };
  // Final component renders
  if (!isLoaded) {
    return <Spin>{runsPage()}</Spin>;
  }
  return <>{runsPage()}</>;
};
export default Runs;
