import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../common/FormFields/FormFields';
import Loader from '../../../../../common/Loader/Loader';
import {
  validateNegativeNumber,
  validatePositiveFloat,
} from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  BypassValveMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  ManufacturerMessage,
  EquipmentTypeMessage,
} from '../../../../../constants/messages';
import {
  ButtonVariables,
  BypassValveVariables,
} from '../../../../../constants/variables';
import {
  addBypassValve,
  clearState,
  getEquipmentType,
  getManufacturer,
} from '../../../../../redux/actions/bypass.action';
import {
  clearDataUnitState,
  getDataUnits,
} from '../../../../../redux/actions/dataUnit.action';
import {
  byPassTheoraticalTableAddRow,
  byPassTheoraticalTableColumns,
  DataUnit,
  formFieldsValveData,
  formFieldsValveDescription,
  initialValue,
} from './ByPassValue.constants';
import {
  addManufacturer,
  addEquipmentType,
  clearEquipmentState,
} from '../../../../../redux/actions/equipment.action';
import * as Pattern from '../../../../../constants/pattern';
import './ByPassValue.scss';

const valveCharacteristicCurveTheoreticalKey =
  'valveCharacteristicCurveTheoretical';
const valveCharacteristicCurveOperationalKey =
  'valveCharacteristicCurveOperational';

class ByPassValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formEdit: false,
      preserveBypassData: {},
      unitData: [],
      equipmentTypeData: [],
      equipmentManufacturerData: [],
      addFieldValueVCCT: {
        valvePosition: '',
        valveFlowCoefficient: '',
      },
      addFieldValueVCCO: {
        valvePosition: '',
        valveFlowCoefficient: '',
      },
      valveCharacteristicCurveOperational: [],
      valveCharacteristicCurveTheoretical: [],
      dirtyRows: {
        valveCharacteristicCurveTheoretical: [],
        valveCharacteristicCurveOperational: [],
      },
      editRows: {
        valveCharacteristicCurveTheoretical: [],
        valveCharacteristicCurveOperational: [],
      },
      isError: {
        valveCharacteristicCurveTheoretical: {},
        valveCharacteristicCurveOperational: {},
      },
      updatedFields: {
        valveCharacteristicCurveTheoretical: [],
        valveCharacteristicCurveOperational: [],
      },
      addModalVisible: false,
      isEquipment: false,
      isEquipmentManufacturer: false,
      addNewValue: '',
    };
  }

  componentDidMount() {
    if (this.handleNetwork()) {
      this.props.getDataUnits(DataUnit);
      this.props.getEquipmentType();
      this.props.getManufacturer();
    }

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isConfigData,
      configData,
      clearState,
      clearConfigState,
    } = this.props;
    const {
      isBypassValveAddedSuccess,
      isError,
      errorCode,
      isSuccessGetEquipmentType,
      isSuccessGetManufacturer,
      manufacturerData,
      isErrorGetEquipmentType,
      isErrorGetManufacturer,
      equipmentTypeData,
    } = this.props.bypassData.apiState;
    const {
      unitData,
      isSuccessDataUnitFetch,
    } = this.props.dataUnitState.apiState;
    const {
      isSuccessAddEquipmentType,
      isErrorAddManufacturer,
      isErrorAddEquipmentType,
      isSuccessAddManufacturer,
      message,
    } = this.props.equipmentState.apiState;

    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';

    if (isSuccessDataUnitFetch) {
      this.props.clearDataUnitState();
      this.setState({
        unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
      });
    }

    if (isSuccessGetEquipmentType) {
      clearState();
      this.setState({
        equipmentTypeData: [...equipmentTypeData.items],
      });
    } else if (isErrorGetEquipmentType) {
      clearState();
      Notification.show(Types.Error, BypassValveMessages[errorCode]);
    }

    if (isSuccessGetManufacturer) {
      clearState();
      this.setState({
        equipmentManufacturerData: manufacturerData.items,
      });
    } else if (isErrorGetManufacturer) {
      clearState();
      Notification.show(Types.Error, BypassValveMessages[errorCode]);
    }

    if (isBypassValveAddedSuccess) {
      clearState();
      Notification.show(Types.Success, BypassValveMessages.BYPASS_DATA_ADDED);
    }

    if (isError) {
      clearState();
      Notification.show(Types.Error, BypassValveMessages[errorCode]);
    }

    if (isConfigData) {
      clearConfigState();
      this.disableEditForm();
      this.setState({
        preserveBypassData:
          configData && configData.bypassValve
            ? _.cloneDeep(configData.bypassValve)
            : initialValue,
      });
      this.setBypassData(
        configData && configData.bypassValve
          ? _.cloneDeep(configData.bypassValve)
          : initialValue
      );
    }

    if (
      isSuccessAddManufacturer &&
      prevProps.equipmentState.apiState.isSuccessAddManufacturer !==
        isSuccessAddManufacturer
    ) {
      const { addNewValue } = this.state;
      this.setState({
        addModalVisible: false,
        isEquipmentManufacturer: false,
      });
      this.props.form.setFieldsValue(
        {
          equipmentManufacturer: addNewValue,
        },
        () => this.enableEditForm()
      );
      Notification.show(
        Types.Success,
        BypassValveMessages.SUCCESS_MANUFACTURER
      );
    }

    if (
      isErrorAddManufacturer &&
      prevProps.equipmentState.apiState.isErrorAddManufacturer !==
        isErrorAddManufacturer
    ) {
      Notification.show(
        Types.Error,
        `${ManufacturerMessage[errorCode]} ${displayMessage}`
      );
      clearEquipmentState();
    }

    if (
      isSuccessAddEquipmentType &&
      prevProps.equipmentState.apiState.isSuccessAddEquipmentType !==
        isSuccessAddEquipmentType
    ) {
      const { clearEquipmentState } = this.props;
      const { addNewValue } = this.state;
      this.props.getEquipmentType();
      clearEquipmentState();
      this.setState({
        addModalVisible: false,
        isEquipment: false,
      });
      this.props.form.setFieldsValue(
        {
          equipmentType: addNewValue,
        },
        () => this.enableEditForm()
      );
      Notification.show(
        Types.Success,
        BypassValveMessages.SUCCESS_EQUIPMENT_TYPE
      );
    }

    if (isErrorAddEquipmentType) {
      Notification.show(
        Types.Error,
        `${
          EquipmentTypeMessage[this.props.equipmentState.apiState.errorCode]
        } ${displayMessage}`
      );
      clearEquipmentState();
    }
  }

  transformBypassDataInput(data) {
    const tempdata = {
      valveCharacteristicCurveOperational: [],
      valveCharacteristicCurveTheoretical: [],
      formData: {},
    };
    if (data) {
      tempdata.valveCharacteristicCurveOperational = data.valveCharacteristicCurveOperational.map(
        (item) => {
          delete item._id;
          return item;
        }
      );
      tempdata.valveCharacteristicCurveTheoretical = data.valveCharacteristicCurveTheoretical.map(
        (item) => {
          delete item._id;
          return item;
        }
      );
      tempdata.formData = {
        ...data.bypassValveDescription,
        ...data.bypassValveData,
        characteristic: data.bypassValveData.characteristic,
        flowCoefficient: isFinite(data.bypassValveData.flowCoefficient)
          ? `${data.bypassValveData.flowCoefficient}`
          : '',
        pipingGeometryFactor: isFinite(
          data.bypassValveData.pipingGeometryFactor
        )
          ? `${data.bypassValveData.pipingGeometryFactor}`
          : '',
        bypassValveCalculationThreshold: isFinite(
          data.bypassValveData.bypassValveCalculationThreshold
        )
          ? `${data.bypassValveData.bypassValveCalculationThreshold}`
          : '',
        velocityHeadLossCoefficient: isFinite(
          data.bypassValveData.velocityHeadLossCoefficient
        )
          ? `${data.bypassValveData.velocityHeadLossCoefficient}`
          : '',
        inletPipeDiameter: data.bypassValveData.inletPipeDiameter
          ? `${data.bypassValveData.inletPipeDiameter.value}`
          : '',
        outletPipeDiameter: data.bypassValveData.outletPipeDiameter
          ? `${data.bypassValveData.outletPipeDiameter.value}`
          : '',
        valveNominalDiameter: data.bypassValveData.valveNominalDiameter
          ? `${data.bypassValveData.valveNominalDiameter.value}`
          : '',
        unitInletPipeDiameter: data.bypassValveData.inletPipeDiameter
          ? data.bypassValveData.inletPipeDiameter.unit
          : 'yd',
        unitOutletPipeDiameter: data.bypassValveData.outletPipeDiameter
          ? data.bypassValveData.outletPipeDiameter.unit
          : 'yd',
        unitValveNominalDiameter: data.bypassValveData.valveNominalDiameter
          ? data.bypassValveData.valveNominalDiameter.unit
          : 'yd',
      };
      const temp = tempdata.formData;
      delete temp._id;
      tempdata.formData = temp;
    }
    return _.cloneDeep(tempdata);
  }

  setBypassData(data) {
    if (data) {
      const transformedData = this.transformBypassDataInput(data);
      this.setState(
        {
          valveCharacteristicCurveOperational:
            transformedData.valveCharacteristicCurveOperational,
          valveCharacteristicCurveTheoretical:
            transformedData.valveCharacteristicCurveTheoretical,
        },
        () => {
          this.props.form.setFieldsValue(
            {
              ...transformedData.formData,
            },
            () => {
              this.disableEditForm();
            }
          );
        }
      );
    } else {
      this.setState(
        {
          valveCharacteristicCurveOperational: [],
          valveCharacteristicCurveTheoretical: [],
        },
        () => {
          this.props.form.resetFields();
        }
      );
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  edit(row, key) {
    const {
      editRows,
      updatedFields,
      valveCharacteristicCurveOperational,
      valveCharacteristicCurveTheoretical,
    } = this.state;
    const tempEditRows = _.cloneDeep(editRows);
    const tempUpdatedFields = _.cloneDeep(updatedFields);
    tempEditRows[key].push(row);
    if (key === valveCharacteristicCurveOperationalKey) {
      tempUpdatedFields[key][row] = _.cloneDeep(
        valveCharacteristicCurveOperational[row]
      );
    } else if (key === valveCharacteristicCurveTheoreticalKey) {
      tempUpdatedFields[key][row] = _.cloneDeep(
        valveCharacteristicCurveTheoretical[row]
      );
    }
    this.setState(
      {
        editRows: tempEditRows,
        updatedFields: tempUpdatedFields,
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row, key) {
    const {
      editRows,
      updatedFields,
      dirtyRows,
      valveCharacteristicCurveOperational,
      valveCharacteristicCurveTheoretical,
    } = this.state;
    const tempEditRows = _.cloneDeep(editRows);
    const tempUpdatedFields = _.cloneDeep(updatedFields);
    const tempDirtyRows = _.cloneDeep(dirtyRows);
    tempEditRows[key] = tempEditRows[key].filter((e) => e !== row);
    tempDirtyRows[key] = tempDirtyRows[key].filter((e) => e !== row);
    if (key === valveCharacteristicCurveOperationalKey) {
      const updatedTemp = _.cloneDeep(valveCharacteristicCurveOperational);
      updatedTemp[row] = _.cloneDeep(tempUpdatedFields[key][row]);
      tempUpdatedFields[key][row] = undefined;
      this.setState({
        editRows: tempEditRows,
        dirtyRows: tempDirtyRows,
        updatedFields: tempUpdatedFields,
        valveCharacteristicCurveOperational: updatedTemp,
      });
    } else if (key === valveCharacteristicCurveTheoreticalKey) {
      const updatedTemp = _.cloneDeep(valveCharacteristicCurveTheoretical);
      updatedTemp[row] = _.cloneDeep(tempUpdatedFields[key][row]);
      tempUpdatedFields[key][row] = undefined;
      this.setState({
        editRows: tempEditRows,
        dirtyRows: tempDirtyRows,
        updatedFields: tempUpdatedFields,
        valveCharacteristicCurveTheoretical: updatedTemp,
      });
    }
  }

  deleteRow(row, key) {
    const {
      editRows,
      updatedFields,
      dirtyRows,
      valveCharacteristicCurveOperational,
      valveCharacteristicCurveTheoretical,
    } = this.state;
    const tempEditRows = _.cloneDeep(editRows);
    const tempUpdatedFields = _.cloneDeep(updatedFields);
    const tempDirtyRows = _.cloneDeep(dirtyRows);
    tempDirtyRows[key] = tempDirtyRows[key].filter((e) => e !== row);
    tempEditRows[key] = tempEditRows[key].filter((e) => e !== row);
    if (key === valveCharacteristicCurveOperationalKey) {
      const updatedTemp = _.cloneDeep(valveCharacteristicCurveOperational);
      updatedTemp.splice(row, 1);
      tempUpdatedFields[key].splice(row, 1);
      this.setState(
        {
          valveCharacteristicCurveOperational: updatedTemp,
          dirtyRows: tempDirtyRows,
          editRows: tempEditRows,
          updatedFields: tempUpdatedFields,
        },
        () => {
          this.enableEditForm();
        }
      );
    } else if (key === valveCharacteristicCurveTheoreticalKey) {
      const updatedTemp = _.cloneDeep(valveCharacteristicCurveTheoretical);
      updatedTemp.splice(row, 1);
      tempUpdatedFields[key].splice(row, 1);
      this.setState(
        {
          valveCharacteristicCurveTheoretical: updatedTemp,
          dirtyRows: tempDirtyRows,
          editRows: tempEditRows,
          updatedFields: tempUpdatedFields,
        },
        () => {
          this.enableEditForm();
        }
      );
    }
  }

  validateFields(values, key) {
    const { isError } = this.state;
    let status = true;
    const temp = { ...isError };
    Object.keys(values).forEach((item) => {
      if (item === 'valvePosition') {
        if (
          (_.isEmpty(values[item].toString().trim()) && values[item] !== 0) ||
          !validatePositiveFloat(values[item].toString())
        ) {
          temp[key][item] = true;
          status = false;
        } else {
          temp[key][item] = false;
        }
      } else if (
        (_.isEmpty(values[item].toString().trim()) && values[item] !== 0) ||
        validateNegativeNumber(values[item].toString())
      ) {
        temp[key][item] = true;
        status = false;
      } else {
        temp[key][item] = false;
      }
    });
    this.setState({
      isError: temp,
    });
    return status;
  }

  addRow(key) {
    if (key === valveCharacteristicCurveOperationalKey) {
      const {
        addFieldValueVCCO,
        valveCharacteristicCurveOperational,
      } = this.state;
      if (this.validateFields(addFieldValueVCCO, key)) {
        const temp = [
          ...valveCharacteristicCurveOperational,
          { ...addFieldValueVCCO },
        ];
        for (const fieldKey in addFieldValueVCCO) {
          addFieldValueVCCO[fieldKey] = '';
        }
        this.setState(
          { valveCharacteristicCurveOperational: temp, addFieldValueVCCO },
          () => {
            this.enableEditForm();
          }
        );
      } else {
        Notification.show(Types.Error, BypassValveMessages.FIELDS_DIRTY);
      }
    } else if (key === valveCharacteristicCurveTheoreticalKey) {
      const {
        addFieldValueVCCT,
        valveCharacteristicCurveTheoretical,
      } = this.state;
      if (this.validateFields(addFieldValueVCCT, key)) {
        const temp = [
          ...valveCharacteristicCurveTheoretical,
          { ...addFieldValueVCCT },
        ];
        for (const fieldKey in addFieldValueVCCT) {
          addFieldValueVCCT[fieldKey] = '';
        }
        this.setState(
          { valveCharacteristicCurveTheoretical: temp, addFieldValueVCCT },
          () => {
            this.enableEditForm();
          }
        );
      } else {
        Notification.show(Types.Error, BypassValveMessages.FIELDS_DIRTY);
      }
    }
  }

  onAddRowChange(field, key) {
    const { name, value } = field;
    if (key === valveCharacteristicCurveOperationalKey) {
      const { addFieldValueVCCO } = this.state;
      const temp = _.cloneDeep(addFieldValueVCCO);
      temp[name] = value;
      this.setState({ addFieldValueVCCO: temp }, () => {
        this.validateFields(this.state.addFieldValueVCCO, key);
        this.enableEditForm();
      });
    } else if (key === valveCharacteristicCurveTheoreticalKey) {
      const { addFieldValueVCCT } = this.state;
      const temp = _.cloneDeep(addFieldValueVCCT);
      temp[name] = value;
      this.setState({ addFieldValueVCCT: temp }, () => {
        this.validateFields(this.state.addFieldValueVCCT, key);
        this.enableEditForm();
      });
    }
  }

  setDirtyRows(key, row) {
    const { dirtyRows } = this.state;
    const tableData = _.cloneDeep(this.state[key]);
    const tempDirtyRows = _.cloneDeep(dirtyRows);
    let status = false;
    Object.keys(tableData[row]).forEach((item) => {
      if (item === 'valvePosition') {
        if (
          (_.isEmpty(tableData[row][item].toString().trim()) &&
            tableData[row][item] !== 0) ||
          !validatePositiveFloat(tableData[row][item].toString())
        ) {
          status = true;
        }
      } else if (
        (_.isEmpty(tableData[row][item].toString().trim()) &&
          tableData[row][item] !== 0) ||
        validateNegativeNumber(tableData[row][item].toString())
      ) {
        status = true;
      }
    });
    if (status) {
      tempDirtyRows[key].push(row);
    } else {
      tempDirtyRows[key] = tempDirtyRows[key].filter((e) => e !== row);
    }
    this.setState({
      dirtyRows: tempDirtyRows,
    });
  }

  onValueChange(field, row, key) {
    const { name, value } = field;
    if (key === valveCharacteristicCurveTheoreticalKey) {
      const { valveCharacteristicCurveTheoretical } = this.state;
      const temp = _.cloneDeep(valveCharacteristicCurveTheoretical);
      temp[row][name] = value;
      this.setState(
        {
          valveCharacteristicCurveTheoretical: temp,
        },
        () => {
          this.setDirtyRows(key, row);
        }
      );
    } else if (key === valveCharacteristicCurveOperationalKey) {
      const { valveCharacteristicCurveOperational } = this.state;
      const temp = _.cloneDeep(valveCharacteristicCurveOperational);
      temp[row][name] = value;
      this.setState(
        {
          valveCharacteristicCurveOperational: temp,
        },
        () => {
          this.setDirtyRows(key, row);
        }
      );
    }
  }

  save() {
    const {
      location: { state },
      addBypassValveData,
    } = this.props;
    this.props.form.setFieldsValue(
      {
        title:
          this.props.form.getFieldValue('title') &&
          this.props.form.getFieldValue('title').trim(),
        tagNumber:
          this.props.form.getFieldValue('tagNumber') &&
          this.props.form.getFieldValue('tagNumber').trim(),
        equipmentManufacturer:
          this.props.form.getFieldValue('equipmentManufacturer') &&
          this.props.form.getFieldValue('equipmentManufacturer').trim(),
        equipmentModel:
          this.props.form.getFieldValue('equipmentModel') &&
          this.props.form.getFieldValue('equipmentModel').trim(),
      },
      () => {
        this.props.form.validateFields((err, values) => {
          if (!err) {
            if (
              this.state.dirtyRows.valveCharacteristicCurveOperational
                .length === 0 &&
              this.state.dirtyRows.valveCharacteristicCurveTheoretical
                .length === 0
            ) {
              const {
                valveCharacteristicCurveOperational,
                valveCharacteristicCurveTheoretical,
              } = this.state;
              if (
                valveCharacteristicCurveOperational.length >= 3 &&
                valveCharacteristicCurveTheoretical.length >= 3 &&
                valveCharacteristicCurveOperational.length <= 15 &&
                valveCharacteristicCurveTheoretical.length <= 15
              ) {
                if (
                  valveCharacteristicCurveOperational.length ===
                  valveCharacteristicCurveTheoretical.length
                ) {
                  const {
                    characteristic,
                    flowCoefficient,
                    inletPipeDiameter,
                    outletPipeDiameter,
                    pipingGeometryFactor,
                    bypassValveCalculationThreshold,
                    unitInletPipeDiameter,
                    unitOutletPipeDiameter,
                    unitValveNominalDiameter,
                    valveNominalDiameter,
                    velocityHeadLossCoefficient,
                    ...bypassValveDescription
                  } = values;

                  const bypassValveData = {
                    characteristic,
                    flowCoefficient: parseFloat(flowCoefficient),
                    pipingGeometryFactor: parseFloat(pipingGeometryFactor),
                    bypassValveCalculationThreshold:parseFloat(bypassValveCalculationThreshold),
                    velocityHeadLossCoefficient: parseFloat(
                      velocityHeadLossCoefficient
                    ),

                    inletPipeDiameter: {
                      unit: unitInletPipeDiameter,
                      value: parseFloat(inletPipeDiameter),
                    },
                    outletPipeDiameter: {
                      unit: unitOutletPipeDiameter,
                      value: parseFloat(outletPipeDiameter),
                    },
                    valveNominalDiameter: {
                      unit: unitValveNominalDiameter,
                      value: parseFloat(valveNominalDiameter),
                    },
                  };
                  for (const keys in bypassValveData) {
                    if (!bypassValveData[keys] && bypassValveData[keys] !== 0) {
                      delete bypassValveData[keys];
                    } else if (
                      bypassValveData[keys] &&
                      typeof bypassValveData[keys] === 'object' &&
                      !bypassValveData[keys].value &&
                      bypassValveData[keys].value !== 0
                    ) {
                      delete bypassValveData[keys];
                    }
                  }
                  const characteristicCurveOperational = valveCharacteristicCurveOperational;
                  characteristicCurveOperational.forEach((data) => {
                    data.valvePosition = parseFloat(data.valvePosition);
                    data.valveFlowCoefficient = parseFloat(
                      data.valveFlowCoefficient
                    );
                  });
                  const characteristicCurveTheoretical = valveCharacteristicCurveTheoretical;
                  characteristicCurveTheoretical.forEach((data) => {
                    data.valvePosition = parseFloat(data.valvePosition);
                    data.valveFlowCoefficient = parseFloat(
                      data.valveFlowCoefficient
                    );
                  });
                  const payload = {
                    valveCharacteristicCurveOperational: characteristicCurveOperational,
                    valveCharacteristicCurveTheoretical: characteristicCurveTheoretical,
                    bypassValveData,
                    bypassValveDescription,
                  };
                  if (this.handleNetwork && state.componentData) {
                    addBypassValveData(payload, state.componentData.info._id);
                  }
                } else {
                  Notification.show(Types.Info, BypassValveMessages.EQUAL_DATA);
                }
              } else {
                Notification.show(Types.Info, BypassValveMessages.MIN_DATA);
              }
            } else {
              Notification.show(Types.Error, BypassValveMessages.FIELDS_DIRTY);
            }
          }
        });
      }
    );
  }

  clearDirtyForm() {
    const { preserveBypassData } = this.state;
    this.setState(
      {
        dirtyRows: {
          valveCharacteristicCurveTheoretical: [],
          valveCharacteristicCurveOperational: [],
        },
        editRows: {
          valveCharacteristicCurveTheoretical: [],
          valveCharacteristicCurveOperational: [],
        },
        isError: {
          valveCharacteristicCurveTheoretical: {},
          valveCharacteristicCurveOperational: {},
        },
        updatedFields: {
          valveCharacteristicCurveTheoretical: [],
          valveCharacteristicCurveOperational: [],
        },
        addFieldValueVCCT: {
          valvePosition: '',
          valveFlowCoefficient: '',
        },
        addFieldValueVCCO: {
          valvePosition: '',
          valveFlowCoefficient: '',
        },
      },
      () => {
        const transformedData = this.transformBypassDataInput(
          preserveBypassData
        );

        if (
          JSON.stringify(
            transformedData.valveCharacteristicCurveOperational
          ) !==
            JSON.stringify(this.state.valveCharacteristicCurveOperational) ||
          JSON.stringify(
            transformedData.valveCharacteristicCurveTheoretical
          ) !==
            JSON.stringify(this.state.valveCharacteristicCurveTheoretical) ||
          !_.isEqual(transformedData.formData, this.props.form.getFieldsValue())
        ) {
          this.setBypassData(_.cloneDeep(preserveBypassData));
        }
      }
    );
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleAddCancel = () => {
    this.setState({
      addModalVisible: false,
      addNewValue: null,
    });
  };

  handleAddOk = () => {
    const {
      addNewValue,
      isEquipment,
      addModalVisible,
      isEquipmentManufacturer,
    } = this.state;

    const { addManufacturer, addEquipmentType } = this.props;

    if (addModalVisible) {
      if (this.handleNetwork()) {
        if (addNewValue && addNewValue.trim() && addNewValue.trim().length) {
          isEquipmentManufacturer && addManufacturer({ name: addNewValue });
          isEquipment && addEquipmentType({ type: addNewValue });
        } else {
          Notification.show(Types.Error, BypassValveMessages.REQUIRED_FIELD);
        }
      }
    }
  };

  setAddNewValue(value) {
    this.setState({ addNewValue: value });
  }

  renderModalAddValue() {
    const { addModalVisible, isEquipmentManufacturer } = this.state;
    return (
      <div className="EditCurrentNode">
        <Modal
          title={
            isEquipmentManufacturer
              ? BypassValveMessages.ADD_EQUIPMENT_MANUFACTURER
              : BypassValveMessages.ADD_EQUIPMENT_TYPE
          }
          visible={addModalVisible}
          onOk={this.handleAddOk}
          onCancel={this.handleAddCancel}
          okText="Save"
          cancelText="Cancel"
        >
          <Form>
            <Form.Item>
              {addModalVisible &&
                this.props.form.getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: BypassValveMessages.REQUIRED_FIELD,
                    },
                    {
                      pattern: Pattern.Name.regx,
                      message: Pattern.Name.message,
                    },
                  ],
                })(
                  <Input
                    onChange={(e) => {
                      this.setAddNewValue(e.target.value);
                    }}
                    maxLength={70}
                  />
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  openAddModal = (key) => {
    let tempEquipmentManufacturer = false;
    let tempEquipment = false;
    if (key === 'isEquipment') {
      tempEquipment = true;
    } else if (key === 'isEquipmentManufacturer') {
      tempEquipmentManufacturer = true;
    }

    this.setState({
      addModalVisible: true,
      isEquipment: tempEquipment,
      isEquipmentManufacturer: tempEquipmentManufacturer,
    });
  };

  renderBypassValueDescription() {
    const { isSchematicEditable } = this.props;
    return (
      <div>
        <h3>{BypassValveVariables.BYPASS_VALVE_DESC}</h3>
        <hr />
        <div className="data-info">
          <FormFields
            formFields={formFieldsValveDescription({
              equipmentTypeData: this.state.equipmentTypeData,
              equipmentManufacturerData: this.state.equipmentManufacturerData,
              isSchematicEditable,
              openAddModal: this.openAddModal,
            })}
            form={this.props.form}
          />
        </div>
      </div>
    );
  }

  renderBypassValueData() {
    const { isSchematicEditable } = this.props;
    const { unitData } = this.state;
    return (
      <div>
        <h3>{BypassValveVariables.BYPASS_VALVE_DATA}</h3>
        <hr />
        <div className="data-info">
          <FormFields
            formFields={formFieldsValveData({ unitData, isSchematicEditable })}
            form={this.props.form}
          />
        </div>
      </div>
    );
  }

  renderTheoracticalValue() {
    const { isSchematicEditable } = this.props;
    const { valveCharacteristicCurveTheoretical } = this.state;
    return (
      <div>
        <h4>{BypassValveVariables.THEORITICAL}</h4>
        <CustomTable
          showHeader
          columns={byPassTheoraticalTableColumns({
            change: (field, row) => {
              this.onValueChange(
                field,
                row,
                valveCharacteristicCurveTheoreticalKey
              );
            },
            edit: (row) => {
              this.edit(row, valveCharacteristicCurveTheoreticalKey);
            },
            delete: (row) => {
              this.deleteRow(row, valveCharacteristicCurveTheoreticalKey);
            },
            undo: (row) => {
              this.undo(row, valveCharacteristicCurveTheoreticalKey);
            },
            isSchematicEditable,
          })}
          editableRow={this.state.editRows.valveCharacteristicCurveTheoretical}
          data={this.state.valveCharacteristicCurveTheoretical}
          renderAddRow={
            isSchematicEditable &&
            valveCharacteristicCurveTheoretical.length < 15 &&
            byPassTheoraticalTableAddRow({
              change: (e) => {
                this.onAddRowChange(e, valveCharacteristicCurveTheoreticalKey);
              },
              submit: (e) => {
                this.addRow(valveCharacteristicCurveTheoreticalKey);
              },
              isError: this.state.isError.valveCharacteristicCurveTheoretical,
            })
          }
          addFieldValues={this.state.addFieldValueVCCT}
        />
      </div>
    );
  }

  renderOperationalValue() {
    const { isSchematicEditable } = this.props;
    const { valveCharacteristicCurveOperational } = this.state;
    return (
      <div>
        <h4>{BypassValveVariables.OPERATIONAL}</h4>
        <CustomTable
          showHeader
          columns={byPassTheoraticalTableColumns({
            change: (field, row) => {
              this.onValueChange(
                field,
                row,
                valveCharacteristicCurveOperationalKey
              );
            },
            edit: (row) => {
              this.edit(row, valveCharacteristicCurveOperationalKey);
            },
            delete: (row) => {
              this.deleteRow(row, valveCharacteristicCurveOperationalKey);
            },
            undo: (row) => {
              this.undo(row, valveCharacteristicCurveOperationalKey);
            },
            isSchematicEditable,
          })}
          editableRow={this.state.editRows.valveCharacteristicCurveOperational}
          data={this.state.valveCharacteristicCurveOperational}
          renderAddRow={
            isSchematicEditable &&
            valveCharacteristicCurveOperational.length < 15 &&
            byPassTheoraticalTableAddRow({
              change: (e) => {
                this.onAddRowChange(e, valveCharacteristicCurveOperationalKey);
              },
              submit: (e) => {
                this.addRow(valveCharacteristicCurveOperationalKey);
              },
              isError: this.state.isError.valveCharacteristicCurveOperational,
            })
          }
          addFieldValues={this.state.addFieldValueVCCO}
        />
      </div>
    );
  }

  render() {
    const { loading } = this.props.bypassData;
    return (
      <div className="ByPassValue">
        {loading && <Loader />}
        <div className="bypass-desc">{this.renderBypassValueDescription()}</div>
        <div className="bypass-data">{this.renderBypassValueData()}</div>
        <div className="value-curve">
          <h3>{BypassValveVariables.VAVLE_CHARACTERSTIC_CURVE}</h3>
          <hr />
          <div className="left">{this.renderTheoracticalValue()}</div>
          <div className="right">{this.renderOperationalValue()}</div>
        </div>
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModalAddValue()}
        {this.renderModal()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => ({
  bypassData: state.BypassReducer,
  network: state.NetworkReducer,
  configState: state.ConfigReducer,
  dataUnitState: state.DataUnitReducer,
  equipmentState: state.EquipmentReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getDataUnits: (payload) => {
    dispatch(getDataUnits(payload));
  },
  addBypassValveData: (payload, id) => {
    dispatch(addBypassValve(payload, id));
  },
  getEquipmentType: () => {
    dispatch(getEquipmentType());
  },
  getManufacturer: () => {
    dispatch(getManufacturer());
  },
  clearState: () => {
    dispatch(clearState());
  },
  clearDataUnitState: () => {
    dispatch(clearDataUnitState());
  },
  addManufacturer: (payload) => {
    dispatch(addManufacturer(payload));
  },
  clearEquipmentState: () => {
    dispatch(clearEquipmentState());
  },
  addEquipmentType: (payload) => {
    dispatch(addEquipmentType(payload));
  },
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'form',
    onFieldsChange: (props, fields, allfields) => {
      window.dispatchEvent(new Event('form-edit'));
    },
  })(withRouter(ByPassValue))
);
