import React from 'react';
import { Icon, Input } from 'antd';
import {
  newValidateNumber,
  validatePositiveNumber,
} from '../../../../../../../libs/validator';

export const curveTitle = 'Max Torque Limit Curve';

export const curveTitleKey = 'maxTorqueLimitCurve';

export const identifierKey = '';

const DataColumsVariables = {
  speedRatio: 'Speed Ratio',
  outputTorque: 'Output Torque',
  outputSpeed: 'Output Speed',
  guideVanePosition: 'Guide Vane Position',
  intermediateSpeed: 'Intermediate Speed',
  wheelSpeed: 'Wheel Speed',
  outputPower: 'Output Power',
};

export const defaultIsErrorObject = {
  speedRatio: false,
  outputTorque: false,
  outputSpeed: false,
  guideVanePosition: false,
  outputPower: false,
  intermediateSpeed: false,
  wheelSpeed: false,
};

export const DataColumKeyChange = {
  [DataColumsVariables.speedRatio]: 'speedRatio',
  [DataColumsVariables.outputTorque]: 'outputTorque',
  [DataColumsVariables.outputSpeed]: 'outputSpeed',
  [DataColumsVariables.guideVanePosition]: 'guideVanePosition',
  [DataColumsVariables.outputPower]: 'outputPower',
  [DataColumsVariables.intermediateSpeed]: 'intermediateSpeed',
  [DataColumsVariables.wheelSpeed]: 'wheelSpeed',
};

export const DataColumnsTitle = [
  DataColumsVariables.speedRatio,
  DataColumsVariables.outputTorque,
  DataColumsVariables.outputSpeed,
  DataColumsVariables.guideVanePosition,
  DataColumsVariables.intermediateSpeed,
  DataColumsVariables.outputPower,
  DataColumsVariables.wheelSpeed,
];

const DataColumnsAcceptingNegativeInput = ['guideVanePosition' , 'intermediateSpeed'];

export const isCurveTypeSelectionEnabled = true;

const getDefaultIsErrorValues = () => {
  let defaulValues = {};
  const keys = Object.values(DataColumKeyChange);
  keys.forEach((item) => {
    defaulValues[item] = false;
  });
  return defaulValues;
};

export const defaultIsErrorValues = getDefaultIsErrorValues();

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

const getNumberInput = (ColumnName, row, col, value, editable, config) => {
  return (
    <Input
      name={ColumnName}
      defaultValue={value}
      value={value}
      type="number"
      onChange={(e) => {
        config.onChange(row, col, e.target);
      }}
      disabled={editable}
      onKeyDown={(e) => {
        if (DataColumnsAcceptingNegativeInput.includes(ColumnName)) {
          newValidateNumber(e);
        } else {
          validatePositiveNumber(e);
        }
      }}
      className={!value && value !== 0 ? 'error' : 'not-error'}
    />
  );
};

export const HydraulicGearboxCurveFormField = (
  units,
  identifierData,
  unitsValues,
  config
) => {
  const unitsFormList = DataColumnsTitle.map((item) => {
    return {
      label: item,
      key: curveTitleKey + DataColumKeyChange[item],
      type: 'dropdown',
      required: [true, ''],
      value:
        unitsValues && unitsValues[DataColumKeyChange[item]]
          ? unitsValues[DataColumKeyChange[item]]
          : getUnit(units, item)[0],
      data: getUnit(units, item),
      change: (e) => {
        config.change(e, DataColumKeyChange[item]);
      },
      disabled: !config.isCurveEditable,
    };
  });

  return unitsFormList;
};

const getActionCoulum = (config) => {
  return {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  };
};

export const curvesTableColumns = (config) => {
  let dataColumns = DataColumnsTitle.map((item) => {
    return {
      title: item,
      dataIndex: DataColumKeyChange[item],
      render: (value, row, col, editable) => {
        return getNumberInput(
          DataColumKeyChange[item],
          row,
          col,
          value,
          editable,
          config
        );
      },
    };
  });

  const action = getActionCoulum(config);

  dataColumns.push(action);

  return dataColumns;
};

const getAddRowInput = (curveName, row, col, value, actions) => {
  return (
    <Input
      name={curveName}
      type="number"
      defaultValue={value}
      onChange={(e) => {
        actions.onChange(row, col, e.target);
      }}
      value={value}
      className={actions.isError[curveName] ? 'error' : 'not-error'}
      onKeyDown={(e) => {
        if (DataColumnsAcceptingNegativeInput.includes(curveName)) {
          newValidateNumber(e);
        } else {
          validatePositiveNumber(e);
        }
      }}
    />
  );
};

export const curvesTableAddRow = (actions) => {
  let dataColumns = DataColumnsTitle.map((item) => {
    return {
      title: item,
      dataIndex: DataColumKeyChange[item],
      render: (value, row, col, editable) => {
        return getAddRowInput(
          DataColumKeyChange[item],
          row,
          col,
          value,
          actions
        );
      },
    };
  });

  dataColumns.push({
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  });

  return dataColumns;
};

export const curveInput = (data, yAxisValue, xAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'X');
      },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.change(e, 'Y');
      },
    },
  ];
};
