import _ from "lodash";
import {
  PLOTS_FETCH_SUCCESS,
  PLOTS_FETCH_ERROR,
  PLOTS_FETCH_PROGRESS,
  PLOTS_CLEAR_STATE,
  PLOTS_PARTICULAR_FETCH_SUCCESS,
} from "../Types/plots.types";
import { KPI_BY_LEVEL_FETCH_SUCCESS } from "../Types/addNewCard.types";
import {
  ADD_ADDNEWCARD_SUCCESS,
  DELETE_KPI_BY_KPIID_SUCCESS,
} from "../AddNewCard/addNewCard.types";

const initialKpisState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errorCode: null,
  errorMsg: null,
  viewDateFrom: null,
  viewDateTill: null,
};

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errorCode: null,
  message: null,
  timeSeriesData: {},
};

let series = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KPI_BY_LEVEL_FETCH_SUCCESS:
      const obj = {};
      action.payload &&
        action.payload.data.length &&
        action.payload.data.forEach((kpiId) => {
          obj[kpiId._id] = {
            ...initialKpisState,
            data: {
              y: kpiId.axis.y.map((kpiData) => ({
                _id: kpiData.value,
                data: [],
              })),
            },
          };
        });

      return {
        ...state,
        kpisDataState: obj,
      };

    case ADD_ADDNEWCARD_SUCCESS:
      const newObj = {};
      newObj[action.payload._id] = {
        ...initialKpisState,
        data: {
          y: action.payload.axis.y.map((kpiData) => ({
            _id: kpiData.value,
            data: [],
          })),
        },
      };
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          ...newObj,
        },
      };

    case DELETE_KPI_BY_KPIID_SUCCESS:
      if (state && state.kpisDataState && action.payload.kpiId) {
        delete state.kpisDataState[action.payload.kpiId];
      }
      return {
        ...state,
      };

    case PLOTS_FETCH_PROGRESS:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload]: {
            isLoading: true,
            isError: false,
            isSuccess: false,
            data: null,
            errorCode: null,
            errorMsg: null,
          },
        },
      };

    case PLOTS_FETCH_ERROR:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: true,
            isSuccess: false,
            data: _.cloneDeep(action.payload.response),
            viewDateFrom: null,
            viewDateTill: null,
            errorCode:
              action.payload.err.response &&
              action.payload.err.response.body.code,
            errorMsg:
              action.payload.err.response &&
              action.payload.err.response.body.message,
          },
        },
      };

    case PLOTS_FETCH_SUCCESS:
      series = [];
      if (action.payload.isTimeSeries && action.payload.response.series) {
        series = action.payload.response.series.map((pitag) => {
          let data = [];
          data = pitag.data.filter((ele) => ele[0] >= action.payload.dateFrom &&
          ele[1] !== null);
          data = data.sort();
          return {
            name: pitag.name,
            data,
            tooltip: pitag.tooltip,
            yAxis: pitag.yAxis,
          };
        });
      }
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: false,
            isSuccess: true,
            data:
              typeof action.payload.response === "string"
                ? _.cloneDeep(JSON.parse(action.payload.response))
                : _.cloneDeep(series),
            errorCode: null,
            errorMsg: null,
            viewDateFrom: action.payload.dateFrom,
            viewDateTill: action.payload.dateTill,
          },
        },
      };

    case PLOTS_PARTICULAR_FETCH_SUCCESS:
      series = [];

      if (action.payload.isTimeSeries && action.payload.response.series) {
        series = action.payload.response.series.map((pitag) => {
          let data = [];
          data = pitag.data.filter((ele) => ele[0] >= action.payload.dateFrom &&
          ele[1] !== null);
          data = data.sort();
          return {
            name: pitag.name,
            data,
            tooltip: pitag.tooltip,
            yAxis: pitag.yAxis,
          };
        });
      }

      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            isLoading: false,
            isError: false,
            isSuccess: true,
            data:
              typeof action.payload.response === "string"
                ? _.cloneDeep(JSON.parse(action.payload.response))
                : _.cloneDeep(series),
            errorCode: null,
            errorMsg: null,
            viewDateFrom: action.payload.dateFrom,
            viewDateTill: action.payload.dateTill,
          },
        },
      };

    case PLOTS_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
        data: state.data,
      };
    default:
      return {
        ...state,
      };
  }
};
