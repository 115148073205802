import { GETRequest, PUTRequest } from '../../api/request';
import {
  FETCH_TRANSIENT_DATA_PROGRESS,
  FETCH_TRANSIENT_DATA_SUCCESS,
  FETCH_TRANSIENT_DATA_ERROR,
  FETCH_VARIABLE_TAGS_PROGRESS,
  FETCH_VARIABLE_TAGS_SUCCESS,
  FETCH_VARIABLE_TAGS_ERROR,
  CLEAR_STATE,
  ADD_TRANSIENT_DATA_PROGRESS,
  ADD_TRANSIENT_DATA_SUCCESS,
  ADD_TRANSIENT_DATA_ERROR,
  FETCH_TRANSIENT_DATA_CALC_SUCCESS,
  FETCH_TRANSIENT_DATA_CALC_ERROR,
  FETCH_TRANSIENT_DATA_CALC_PROGRESS,
  FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE,
  UPDATE_TRANSIENT_DATA_CALC_PROGRESS,
  UPDATE_TRANSIENT_DATA_CALC_SUCESS,
  UPDATE_TRANSIENT_DATA_CALC_ERROR,
  FETCH_BASELINE_DATA_PROGRESS,
  FETCH_BASELINE_DATA_SUCCESS,
  FETCH_BASELINE_DATA_ERROR
} from "../Types/transientData.types";

export const fetchTransientData = (equipment, component) => (dispatch) => {
  dispatch({ type: FETCH_TRANSIENT_DATA_PROGRESS });
  GETRequest(`equipment/${equipment}/component/${component}/config/transient`)
    .then((res) => {
      dispatch({ type: FETCH_TRANSIENT_DATA_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: FETCH_TRANSIENT_DATA_ERROR, payload: err });
    });
}

export const fetchVariableTags = (equipmentId, componentId, facilityId, keyword = "", type = "transient") => (dispatch) => {
  dispatch({ type: FETCH_VARIABLE_TAGS_PROGRESS });
  GETRequest(`tags?equipmentId=${equipmentId}&componentId=${componentId}&facilityId=${facilityId}&keyword=${keyword}&type=${type}`)
    .then((res) => {
      dispatch({ type: FETCH_VARIABLE_TAGS_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: FETCH_VARIABLE_TAGS_ERROR, payload: err });
    });
}

export const addTransientData = (equipmentId, componentId, configData) => (dispatch) => {
  dispatch({ type: ADD_TRANSIENT_DATA_PROGRESS });
  PUTRequest(`equipment/${equipmentId}/component/${componentId}/config/transient`, configData)
    .then((res) => {
      dispatch({ type: ADD_TRANSIENT_DATA_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: ADD_TRANSIENT_DATA_ERROR, payload: err });
    })
}

export const fetchTransientDataCalc = (equipment, payload) => (dispatch) => {
  payload.skip = payload && payload.skip ? payload.skip : 0;
  payload.limit = payload && payload.limit ? payload.limit : 10;
  payload.keyword = payload && payload.keyword ? payload.keyword : "";
  
  dispatch({ type: FETCH_TRANSIENT_DATA_CALC_PROGRESS });
  GETRequest(`equipment/${equipment}/calculation/transient`, payload)
    .then((res) => {
      dispatch({ type: FETCH_TRANSIENT_DATA_CALC_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: FETCH_TRANSIENT_DATA_CALC_ERROR, payload: err });
    });
}

export const updateTransientDataCalc = (equipment, transientid, payload) => (dispatch) => {
  dispatch({ type: UPDATE_TRANSIENT_DATA_CALC_PROGRESS, isRun: payload['run'] });
  PUTRequest(`equipment/${equipment}/calculation/transient/${transientid}`, payload)
    .then((res) => {
      dispatch({ type: UPDATE_TRANSIENT_DATA_CALC_SUCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_TRANSIENT_DATA_CALC_ERROR, payload: err });
    });
}

export const fetchBaselineData = (tag, analysisType, analysisId, equipment) => (dispatch) => {
  dispatch({ type: FETCH_BASELINE_DATA_PROGRESS });
  GETRequest(`baseline/data/${equipment}/tag/${tag}/analysistype/${analysisType}/${analysisId}`)
    .then((res) => {
      dispatch({ type: FETCH_BASELINE_DATA_SUCCESS, payload: res.body, analysisId: analysisId });
    })
    .catch((err) => {
      dispatch({ type: FETCH_BASELINE_DATA_ERROR, payload: err });
    });
}

export const clearTransientDataCalcState = () => (dispatch) => dispatch({ type: FETCH_TRANSIENT_DATA_CALC_CLEAR_STATE });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_STATE });