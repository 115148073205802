import {
  ADD_ADDNEWCARD_ERROR,
  ADD_ADDNEWCARD_PROGRESS,
  ADD_ADDNEWCARD_SUCCESS,
  ADD_ADDNEWCARD_CLEAR_STATE,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
  isAlertPlotSuccess: false
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ADDNEWCARD_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case ADD_ADDNEWCARD_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case ADD_ADDNEWCARD_SUCCESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: action.payload,
      };
    case ADD_ADDNEWCARD_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
};
