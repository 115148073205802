import { POSTRequest, PUTRequest } from "../../api/request";
import * as AssetTypes from "../Types/Alerts.types";


export const fetchAlertList = (payload, alertType, bodyPayload) => (
  dispatch
) => {
  dispatch({ type: AssetTypes.ALL_ALERTS_FETCH_PROGRESS });
  let url = "";
  Object.keys(payload).forEach((item) => {
    if(item==="keyword"){
      let keyword=payload[item].trim();
      payload[item]=encodeURIComponent(keyword)
    }
    url += `${item}=${payload[item]}&`;
  });

  if(bodyPayload && bodyPayload.filter && bodyPayload.filter.type){
    bodyPayload.filter.type.forEach((data, index) => {
      if(data === "Univariate Anomaly Detection"){
        bodyPayload.filter.type[index] = 'IQR threshold exceedance'
      }
    })
  }
  POSTRequest(`alerts/${alertType}/filter?${url}`, bodyPayload)
    .then((res) =>
      dispatch({ type: AssetTypes.ALL_ALERTS_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: AssetTypes.ALL_ALERTS_FETCH_ERROR, payload: err })
    );
};

export function clearAlertState() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALL_ALERTS_CLEAR_STATE });
  };
}

export function clearAlertApiState() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERTS_CLEAR_API_STATE });
  };
}

export function requestAlertDiscard(payload) {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_DISCARD_REQUEST });
    PUTRequest("alerts/discard", payload)
      .then((res) => {
        dispatch({ type: AssetTypes.ALERT_DISCARD_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ALERT_DISCARD_ERROR, payload: err });
      });
  };
}
export function requestAlertClose(payload) {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_CLOSE_REQUEST });
    PUTRequest("alerts/close", payload)
      .then((res) => {
        dispatch({ type: AssetTypes.ALERT_CLOSE_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ALERT_CLOSE_ERROR, payload: err });
      });
  };
}

export function resetAlertDiscard() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_DISCARD_CLEAR_STATE });
  };
}

export function resetAlertClose() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_CLOSE_CLEAR_STATE });
  };
}

export function clearAlertList(alertTab) {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_CLEAR_LIST, tab: alertTab });
  };
}

export function requestAlertAssoc(payload)
{
  return function(dispatch)
  {
    dispatch({type: AssetTypes.ALERT_ASSOC_REQUEST});
    PUTRequest("alerts/associate", payload)
      .then((res) => {
        dispatch({ type: AssetTypes.ALERT_ASSOC_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ALERT_ASSOC_ERROR, payload: err });
      });
  }
}

export function requestAlertDisassoc(payload)
{
  return function(dispatch)
  {
    dispatch({type: AssetTypes.ALERT_DISASSOC_REQUEST});
    PUTRequest("alerts/disassociate", payload)
      .then((res) => {
        dispatch({ type: AssetTypes.ALERT_DISASSOC_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ALERT_DISASSOC_ERROR, payload: err });
      });
  }

}
export function resetAlertDisassoc() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_DISASSOC_CLEAR_STATE });
  };
}
export function resetAlertAssoc() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ALERT_ASSOC_CLEAR_STATE });
  };
}

export function requestEventEdit(payload)
{
  return function (dispatch)
  {
    dispatch({type: AssetTypes.ALERT_ASSOC_REQUEST});
    PUTRequest("event/edit", payload)
      .then((res) => {
        dispatch({ type: AssetTypes.ALERT_ASSOC_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ALERT_ASSOC_ERROR, payload: err });
      });

  }
}

