import {
  VARIABLE_PROPERTY_FETCH_PROGRESS,
  VARIABLE_PROPERTY_FETCH_SUCCESS,
  VARIABLE_PROPERTY_FETCH_ERROR,
  VARIABLE_NAME_FETCH_PROGRESS,
  VARIABLE_NAME_FETCH_SUCCESS,
  VARIABLE_NAME_FETCH_ERROR,
  OUTPUT_DATA_FETCH_ERROR,
  OUTPUT_DATA_FETCH_PROGRESS,
  OUTPUT_DATA_FETCH_SUCCESS,
  OUTPUT_DATA_ADD_PROGRESS,
  OUTPUT_DATA_ADD_SUCCESS,
  OUTPUT_DATA_ADD_ERROR,
  CLEAR_STATE,
} from "../Types/outputData.types";
import { endPoints } from "../../libs/endpoints";
import { GETRequest, PUTRequest } from "../../api/request";

const { variable, propertyunits, output, equipments, component } = endPoints;

export function getVariableProperty() {
  return function (dispatch) {
    dispatch({ type: VARIABLE_PROPERTY_FETCH_PROGRESS });
    GETRequest(propertyunits)
      .then((res) => {
        dispatch({ type: VARIABLE_PROPERTY_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: VARIABLE_PROPERTY_FETCH_ERROR, payload: err });
      });
  };
}

export function getVariableNames(id) {
  return function (dispatch) {
    dispatch({ type: VARIABLE_NAME_FETCH_PROGRESS });
    GETRequest(variable, { type: "output", componentId: id })
      .then((res) => {
        dispatch({ type: VARIABLE_NAME_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: VARIABLE_NAME_FETCH_ERROR, payload: err });
      });
  };
}

export function getOutputData(e_id, c_id) {
  return function (dispatch) {
    dispatch({ type: OUTPUT_DATA_FETCH_PROGRESS });
    GETRequest(`${equipments}/${e_id}/${component}/${c_id}/${output}`)
      .then((res) => {
        dispatch({ type: OUTPUT_DATA_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: OUTPUT_DATA_FETCH_ERROR, payload: err });
      });
  };
}

export function addOutputData(payload, e_id, c_id) {
  return function (dispatch) {
    dispatch({ type: OUTPUT_DATA_ADD_PROGRESS });
    PUTRequest(`${equipments}/${e_id}/${component}/${c_id}/${output}`, payload)
      .then((res) => {
        dispatch({ type: OUTPUT_DATA_ADD_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: OUTPUT_DATA_ADD_ERROR, payload: err });
      });
  };
}
export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}
