const fail_main_info = [
    {
        "unit": null,
        "_id": "6114c435d1a50c2701d98e9b",
        "tag": "RS-ROS-1021",
        "tagId": "60ec08c9b504edf9eb25eb88",
        "value": "Machine operation not required",
        "type": "String",
        "abbreviation": "Reason for stoppage",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "startAt": "2021-07-18T06:48:00.000Z",
        "endAt": "2021-07-19T06:48:00.000Z",
        "upTime": 0,
        "downTime": 0,
        "createdAt": "2021-08-12T06:48:21.379Z",
        "updatedAt": "2021-08-12T06:48:21.379Z"
    },
    {
        "unit": null,
        "_id": "6114c435d1a50cb36ad98e9c",
        "tag": "RS-MAP-1021",
        "tagId": "60ec08c9b504ed76d925eb91",
        "value": "Yes",
        "type": "String",
        "abbreviation": "Maintenance activity performed",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "startAt": "2021-07-18T06:48:00.000Z",
        "endAt": "2021-07-19T06:48:00.000Z",
        "upTime": 0,
        "downTime": 0,
        "createdAt": "2021-08-12T06:48:21.379Z",
        "updatedAt": "2021-08-12T06:48:21.379Z"
    },
    {
        "unit": null,
        "_id": "611cacdf4ca26a031d7713cb",
        "abbreviation": "Maintenance Start Time",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "endAt": "2021-07-19T06:48:00.000Z",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "startAt": "2021-07-18T06:48:00.000Z",
        "tag": "RS-MST-1021",
        "tagId": "60ec08c9b504ed391225eb86",
        "type": "String",
        "value": "Tuesday, July 20, 2021 12:16 PM"
    },
    {
        "unit": null,
        "_id": "611cacdf4ca26a031d7713ca",
        "abbreviation": "Maintenance Activity",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "endAt": "2021-07-19T06:48:00.000Z",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "startAt": "2021-07-18T06:48:00.000Z",
        "tag": "RS-MA-1021",
        "tagId": "60ec08c9b504ed3ce625eb8f",
        "type": "String",
        "value": "Adjust"
    },
    {
        "unit": null,
        "_id": "611cacdf4ca26a031d7713c9",
        "abbreviation": "Maintenance End Time",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "endAt": "2021-07-19T06:48:00.000Z",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "startAt": "2021-07-18T06:48:00.000Z",
        "tag": "RS-MET-1021",
        "tagId": "60ec08c9b504ed6e1b25eb90",
        "type": "String",
        "value": "Wednesday, July 21, 2021 2:04 PM"
    },
    {
        "unit": "hours",
        "_id": "611cc6934ca26a031d7824c1",
        "tag": "RS-ART-1021",
        "tagId": "60ec08c9b504ed1cbb25eb99",
        "value": "25.801",
        "type": "Float",
        "abbreviation": "Active Repair Time  (hours)",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "upTime": 24,
        "downTime": 358.5833333333333,
        "startAt": "2021-07-18T06:48:00.000Z",
        "endAt": "2021-07-19T06:48:00.000Z",
        "createdAt": "2021-09-14T12:01:53.130Z"
    },
    {
        "unit": null,
        "_id": "611cacdf4ca26a031d7713cc",
        "abbreviation": "Machine available after maintenance",
        "runId": "6114c435d1a50c5b6fd98e9a",
        "endAt": "2021-07-19T06:48:00.000Z",
        "equipmentId": "60ec08c9b504ed3d3025eb84",
        "startAt": "2021-07-18T06:48:00.000Z",
        "tag": "RS-MAAM-1021",
        "tagId": "60ec08c9b504edc2d225eb93",
        "type": "String",
        "value": "Yes"
    }
]

export default fail_main_info