import { Icon } from "antd";
import React from "react";
import { ic_edit } from "../../../assets/ic_edit";
import { EquipmentAssetVariable } from "../../../constants/variables";

export const columns = (config) => [
  {
    title: EquipmentAssetVariable.ID,
    key: "assetEquipmentId",
    dataIndex: "assetEquipmentId",
  },
  {
    title: EquipmentAssetVariable.EQUIPMENT_NAME,
    key: "name",
    dataIndex: "name",
  },
  {
    title: EquipmentAssetVariable.EQUIPMENT_TYPE,
    key: "type",
    dataIndex: "type",
  },
  {
    title: EquipmentAssetVariable.ACTIONS,
    render: (row) => (
      <div className="eye">
        <Icon type="eye" onClick={(e) => config.rowClick(row)} /> &nbsp;&nbsp;
        <span onClick={(e) => config.edit(row)}>
          <img alt="" src={ic_edit} />
        </span>
      </div>
    ),
  },
];
