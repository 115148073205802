import { Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../../common/FormFields/FormFields';
import { curvesLabel } from '../../CurvesData/Curves.constants';
import { adiabaticEfficiencyCurvesTable, adiabaticEfficiencyTableAddRow, AdiabaticEfficiencyUnit } from './curveConstants';
import UploadModal from './uploadCurves/uploadModal';
import RenderGraph from "./utils/curveGraph"
import DuplicateAstestedCurve from "./utils/duplicateAsTested"
import ValidateCurve from './utils/validateCurve';
import { clearCurveUpdateState, updateCurvesData } from '../../../../../../redux/actions/screwCompressor.action';
import { ButtonVariables } from '../../../../../../constants/variables';
import { useDispatch, useSelector } from 'react-redux';
import resetCurve from './utils/resetCurev';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import { RecipCompDataMessage } from '../../../../../../constants/messages';

const AdiabaticEfficiencyCurves = (props) => {
    const dispatch = useDispatch()
    const screwCompCurveData = useSelector((state) => state.screwCompressorReducer.configData.curvesData);
    const updatedCurveData = useSelector((state) => state.screwCompressorReducer.updateCurveData);

    const curveTypes = ["asTestedCurve", "operationalBaseLine"]
    const curveUnits = { slideValvePosition: "%", adiabaticEfficiency: "%" }
    const graphName = "adiabaticEfficiency"
    const curveKeys = {
        slideValvePosition: '',
        adiabaticEfficiency: '',
    }
    const [adiabaticEffeciencyUnits, setAdiabaticEffeciencyUnits] = useState({
        asTestedCurve: curveUnits,
        operationalBaselineCurve: curveUnits
    })
    const [asTestedCurve, setAsTestedCurve] = useState([])
    const [copyAsTestedCurve, setCopyAsTestedCurve] = useState([])
    const [operationalBasedCurve, setOperationalBasedCurve] = useState([])
    const [copyOperationalBasedCurve, setCopyOperationalBasedCurve] = useState([])
    const [isError, setIsError] = useState(_.cloneDeep(curveKeys))
    const [isErrorBaseLine, setIsErrorBaseLine] = useState(_.cloneDeep(curveKeys))
    const [addFieldValue, setAddFieldValue] = useState(_.cloneDeep(curveKeys))
    const [addFieldValueOpearional, setAddFieldValueOperation] = useState(_.cloneDeep(curveKeys))
    const [editableRows, setEditableRows] = useState({
        asTestedCurve: [],
        operationalBaseLine: []
    })
    const [viewGraph, setViewGraph] = useState(false)
    const [viewCopyModal, setViewCopyModal] = useState(false)
    const [viewUploadModal, setViewUploadModal] = useState(false)
    const [viewButtons, setViewButtons] = useState(false)

    const enableEditForm = () => {
        window.dispatchEvent(new Event('form-edit'));
    }

    const disableEditForm = () => {
        window.dispatchEvent(new Event('form-edit-false'));
    }

    window.addEventListener('form-edit', () => {
        setViewButtons(true)
    });

    useEffect(() => {
        if (Number(props.activeKey) !== 3) {
            onReset()
        }
    }, [props.activeKey]) // eslint-disable-line

    useEffect(() => {
        setAdiabaticEffeciencyUnits(adiabaticEffeciencyUnits)
        setAsTestedCurve([])
        setCopyAsTestedCurve([])
        setOperationalBasedCurve([])
        setCopyOperationalBasedCurve([])
    }, [props.componentID])  // eslint-disable-line

    useEffect(() => {
        if (screwCompCurveData
            && screwCompCurveData.curvesData
            && screwCompCurveData.curvesData.adiabaticEfficiency
            && screwCompCurveData.curvesData.adiabaticEfficiency.data
        ) {
            const { dataUnit } = screwCompCurveData.curvesData.adiabaticEfficiency
            const { asTestedCurves, operationalBaseLineCurves } = screwCompCurveData.curvesData.adiabaticEfficiency.data
            const tempCurveUnit = { ...adiabaticEffeciencyUnits }
            tempCurveUnit.asTestedCurve = dataUnit ? dataUnit : adiabaticEffeciencyUnits.asTestedCurve
            tempCurveUnit.operationalBaselineCurve = dataUnit ? dataUnit : adiabaticEffeciencyUnits.operationalBaselineCurve
            if (asTestedCurves && operationalBaseLineCurves) {
                setAdiabaticEffeciencyUnits(tempCurveUnit)
                setAsTestedCurve(asTestedCurves)
                setCopyAsTestedCurve(asTestedCurves)
                setOperationalBasedCurve(operationalBaseLineCurves)
                setCopyOperationalBasedCurve(operationalBaseLineCurves)
            }
        }
    }, [screwCompCurveData]) // eslint-disable-line

    useEffect(() => {
        if (updatedCurveData.isSuccess
            && updatedCurveData.data
            && updatedCurveData.data.curvesData
            && updatedCurveData.data.curvesData.adiabaticEfficiency
            && updatedCurveData.data.curvesData.adiabaticEfficiency.data &&
            screwCompCurveData?.component?._id === updatedCurveData?.data?.component
        ) {
            const { asTestedCurves, operationalBaseLineCurves } = updatedCurveData.data.curvesData.adiabaticEfficiency.data
            const { dataUnit } = updatedCurveData.data.curvesData.adiabaticEfficiency
            const tempCurveUnit = { ...adiabaticEffeciencyUnits }
            tempCurveUnit.asTestedCurve = dataUnit ? dataUnit : adiabaticEffeciencyUnits.asTestedCurve
            tempCurveUnit.operationalBaselineCurve = dataUnit ? dataUnit : adiabaticEffeciencyUnits.asTestedCurve
            setAdiabaticEffeciencyUnits(tempCurveUnit)
            setAsTestedCurve(asTestedCurves)
            setCopyAsTestedCurve(asTestedCurves)
            setOperationalBasedCurve(operationalBaseLineCurves)
            setCopyOperationalBasedCurve(operationalBaseLineCurves)
            dispatch(clearCurveUpdateState())
            setEditableRows({ asTestedCurve: [], operationalBaseLine: [] })
            setViewButtons(false)
            Notification.show(Types.Success, RecipCompDataMessage.SUCCESS);
        }
        else if (updatedCurveData.isSuccess) {
            setEditableRows({ asTestedCurve: [], operationalBaseLine: [] })
            setViewButtons(false)
            Notification.show(Types.Success, RecipCompDataMessage.SUCCESS);
        }
    }, [updatedCurveData.isSuccess]) // eslint-disable-line

    const onSave = () => {
        let payload = ValidateCurve(graphName, adiabaticEffeciencyUnits, asTestedCurve, operationalBasedCurve)
        asTestedCurve.forEach((data) => {
            if (!String(data.slideValvePosition) ||
                !String(data.adiabaticEfficiency) ||
                !data.slideValvePosition === "" ||
                !data.adiabaticEfficiency === "") {
                return payload.validationError = true
            }
            data.slideValvePosition = Number(data.slideValvePosition)
            data.adiabaticEfficiency = Number(data.adiabaticEfficiency)
        })
        operationalBasedCurve.forEach((data) => {
            if (!String(data.slideValvePosition) ||
                !String(data.adiabaticEfficiency) ||
                !data.slideValvePosition === "" ||
                !data.adiabaticEfficiency === "") {
                return payload.validationError = true
            }
            data.slideValvePosition = Number(data.slideValvePosition)
            data.adiabaticEfficiency = Number(data.adiabaticEfficiency)
        })
        if (!payload.validationError) {
            dispatch(updateCurvesData(props.componentID, payload.payload))
        }
        else {
            Notification.show(Types.Error, RecipCompDataMessage.DIRTY_DATA);
        }
        disableEditForm()
    }

    const onReset = () => {
        const resetData = resetCurve(graphName, updatedCurveData, screwCompCurveData, props.componentID)
        if (resetData && resetData.data && resetData.dataUnit) {
            const { asTestedCurves, operationalBaseLineCurves } = resetData.data
            const { dataUnit } = resetData
            const tempCurveUnit = { ...adiabaticEffeciencyUnits }
            tempCurveUnit.asTestedCurve = dataUnit && dataUnit.asTestedCurve ? dataUnit.asTestedCurve : adiabaticEffeciencyUnits.asTestedCurve
            tempCurveUnit.operationalBaselineCurve = dataUnit && dataUnit.asTestedCurve ? dataUnit.asTestedCurve : adiabaticEffeciencyUnits.operationalBaselineCurve
            const valvePosition = {
                slideValvePosition: dataUnit && dataUnit.slideValvePosition ? dataUnit.slideValvePosition : "%",
                adiabaticEfficiency: dataUnit && dataUnit.adiabaticEfficiency ? dataUnit.adiabaticEfficiency : "%"
            }
            props.form.setFieldsValue(valvePosition)
            setAdiabaticEffeciencyUnits(tempCurveUnit)
            setAsTestedCurve(asTestedCurves)
            setCopyAsTestedCurve(asTestedCurves)
            setOperationalBasedCurve(operationalBaseLineCurves)
            setCopyOperationalBasedCurve(operationalBaseLineCurves)
            dispatch(clearCurveUpdateState())
            setEditableRows({ asTestedCurve: [], operationalBaseLine: [] })
            setViewButtons(false)
            disableEditForm()
        }
    }

    const handleUnitChange = (value, name, unitName) => {
        setViewButtons(true)
        const tempSuctionPosition = { ..._.cloneDeep(adiabaticEffeciencyUnits) }
        if (name === curveTypes[0]) {
            tempSuctionPosition.asTestedCurve[unitName] = value
            setAdiabaticEffeciencyUnits(tempSuctionPosition)
        }
        else {
            tempSuctionPosition.operationalBaselineCurve[unitName] = value
            setAdiabaticEffeciencyUnits(tempSuctionPosition)
        }
        enableEditForm()
    }

    const validateFields = (values, tableName) => {
        let error = false
        if (tableName === curveTypes[0]) {
            const temp = { ...isError };
            Object.keys(temp).forEach((item) => {
                if (
                    (!values[item] && values[item] !== 0) ||
                    (values[item] &&
                        (values[item].length === 0 || values[item].trim().length === 0))
                ) {
                    temp[item] = true;
                    error = true
                } else {
                    temp[item] = false;
                }
            });
            setIsError(temp);
        } else if (tableName === curveTypes[1]) {
            const temp = { ...isErrorBaseLine };
            Object.keys(temp).forEach((item) => {
                if (
                    (!values[item] && values[item] !== 0) ||
                    (values[item] &&
                        (values[item].length === 0 || values[item].trim().length === 0))
                ) {
                    temp[item] = true;
                    error = true
                } else {
                    temp[item] = false;
                }
            });
            setIsErrorBaseLine(temp);
        }
        return error
    }

    const onAddFieldChange = (field, tableName, index) => {

        if (tableName === curveTypes[0]) {
            const temp = addFieldValue;
            temp[field.name] = parseFloat(field.value) === 0
                ? `${parseFloat(field.value)}` : field.value.replace(/^0+/, '');
            setAddFieldValue(temp)
            validateFields(addFieldValue, curveTypes[index])
            enableEditForm()
        } else if (tableName === curveTypes[1]) {
            const temp = addFieldValueOpearional;
            temp[field.name] = parseFloat(field.value) === 0
                ? `${parseFloat(field.value)}` : field.value.replace(/^0+/, '');
            setAddFieldValueOperation(temp)
            validateFields(addFieldValueOpearional, curveTypes[index])
            enableEditForm()
        }
    }

    const addRow = (curveType) => {
        setViewButtons(true)
        if (curveType === curveTypes[0]) {
            const tempData = _.cloneDeep(asTestedCurve)
            tempData.push(addFieldValue)
            if (!validateFields(addFieldValue, curveTypes[0])) {
                setAsTestedCurve(tempData)
                setAddFieldValue(curveKeys)
                setCopyAsTestedCurve(tempData)
            }
            enableEditForm()
        }
        else if (curveType === curveTypes[1]) {
            const tempData = _.cloneDeep(operationalBasedCurve)
            tempData.push(addFieldValueOpearional)
            if (!validateFields(addFieldValueOpearional, curveTypes[0])) {
                setOperationalBasedCurve(tempData)
                setAddFieldValueOperation(curveKeys)
                setCopyOperationalBasedCurve(tempData)
            }
            enableEditForm()
        }
    }

    const onEditFieldChange = (row, col, field, tableName) => {
        if (tableName === curveTypes[0]) {
            const tempData = _.cloneDeep(asTestedCurve)
            tempData[row][field.name] = field.value
            setAsTestedCurve(tempData)
        }
        else if (tableName === curveTypes[1]) {
            const tempData = _.cloneDeep(operationalBasedCurve)
            tempData[row][field.name] = field.value
            setOperationalBasedCurve(tempData)
        }
    }

    const undo = (row, tableName) => {
        if (tableName === curveTypes[0]) {
            const tempData = _.cloneDeep(asTestedCurve)
            const tempEditable = _.cloneDeep(editableRows)
            tempData[row] = copyAsTestedCurve[row]
            tempEditable[tableName] = tempEditable[tableName].filter(index => index !== row)
            setAsTestedCurve(tempData)
            setEditableRows(tempEditable)
        }
        else if (tableName === curveTypes[1]) {
            const tempData = _.cloneDeep(operationalBasedCurve)
            const tempEditable = _.cloneDeep(editableRows)
            tempData[row] = copyOperationalBasedCurve[row]
            tempEditable[tableName] = tempEditable[tableName].filter(index => index !== row)
            setOperationalBasedCurve(tempData)
            setEditableRows(tempEditable)
        }
    }

    const deleteRow = (row, tableName) => {
        setViewButtons(true)
        if (tableName === curveTypes[0]) {
            const tempData = _.cloneDeep(asTestedCurve)
            const tempEditable = _.cloneDeep(editableRows)
            tempEditable[tableName] = tempEditable[tableName].filter(index => index !== row)
            tempData.splice(row, 1)
            setAsTestedCurve(tempData)
            setEditableRows(tempEditable)
            enableEditForm()
        }
        else if (tableName === curveTypes[1]) {
            const tempData = _.cloneDeep(operationalBasedCurve)
            const tempEditable = _.cloneDeep(editableRows)
            tempEditable[tableName] = tempEditable[tableName].filter(index => index !== row)
            tempData.splice(row, 1)
            setOperationalBasedCurve(tempData)
            setEditableRows(tempEditable)
            enableEditForm()
        }
    }

    const edit = (row, tableName) => {
        setViewButtons(true)
        if (tableName === curveTypes[0]) {
            const tempData = _.cloneDeep(editableRows)
            tempData.asTestedCurve.push(row)
            setEditableRows(tempData)
            enableEditForm()
        }
        else if (tableName === curveTypes[1]) {
            const tempData = _.cloneDeep(editableRows)
            tempData.operationalBaseLine.push(row)
            setEditableRows(tempData)
            enableEditForm()
        }
    }

    const renderCurves = (curve, index) => {
        return (
            <div>
                <div className="heading-table">
                    <div className="type-heading">{curvesLabel[curve] ? curvesLabel[curve] : curvesLabel.operationBaselineCurve}</div>
                    <div className="unit-heading">
                        {props.isConfigData ? curvesLabel.selectUnits : 'Units'}
                    </div>
                </div>
                <div>
                    <FormFields
                        formFields={AdiabaticEfficiencyUnit({
                            change: (e, unitName) => {
                                handleUnitChange(e, curveTypes[0], unitName);
                            },
                            isCurveEditable: props.isSchematicEditable,
                            data: adiabaticEffeciencyUnits
                        })}
                        form={props.form}
                    />
                </div>
                <div className="table-curve">
                    <CustomTable
                        showHeader
                        columns={adiabaticEfficiencyCurvesTable({
                            isCurveEditable: props.isSchematicEditable,
                            onChange: (row, col, field) => {
                                onEditFieldChange(row, col, field, curveTypes[index]);
                            },
                            edit: (row) => {
                                edit(row, curveTypes[index]);
                            },
                            deleteRow: (row) => {
                                deleteRow(row, curveTypes[index]);
                            },
                            undo: (row) => {
                                undo(row, curveTypes[index]);
                            },
                        })}
                        editableRow={curve === curveTypes[0] ? editableRows.asTestedCurve : editableRows.operationalBaseLine}
                        data={curve === curveTypes[0] ? asTestedCurve : operationalBasedCurve}
                        renderAddRow={
                            props.isSchematicEditable
                                ? asTestedCurve.length < 20
                                    ? adiabaticEfficiencyTableAddRow({
                                        onChange: (field) => {
                                            onAddFieldChange(field, curveTypes[index], index);
                                        },
                                        submit: () => {
                                            addRow(curveTypes[index]);
                                        },
                                        isError: curve === curveTypes[0] ? isError : isErrorBaseLine,
                                    })
                                    : null
                                : null
                        }
                        addFieldValues={curve === curveTypes[0] ? addFieldValue : addFieldValueOpearional}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="Curves">
            <div className="curves-buttons">
                <button className="btn-default btn-white"
                    onClick={() => setViewUploadModal(true)} >
                    {'Upload Curves'}
                </button>
                <button
                    className="btn-default btn-white"
                    onClick={() => { setViewGraph(!viewGraph); }}
                >
                    View Curve Plot
                </button>
                {props.isSchematicEditable && (
                    <button className="btn-default btn-white"
                        onClick={() => setViewCopyModal(true)} >
                        {'Duplicate'}
                    </button>
                )}
            </div>
            {curveTypes.map((curve, index) => {
                return (
                    <>
                        {renderCurves(curve, index)}
                        <br />
                    </>
                )
            })}
            {RenderGraph(viewGraph, setViewGraph, graphName, asTestedCurve, operationalBasedCurve)}
            {DuplicateAstestedCurve(viewCopyModal, setViewCopyModal, asTestedCurve, setOperationalBasedCurve)}
            <UploadModal visible={viewUploadModal} setVisibility={setViewUploadModal} graphName={graphName}
                setAsTestedCurve={setAsTestedCurve} setOperationalBasedCurve={setOperationalBasedCurve}
                setCopyAsTestedCurve={setCopyAsTestedCurve} setCopyOperationalBasedCurve={setCopyOperationalBasedCurve}
            />
            {viewButtons && (
                <div className="config-bottom-buttons">
                    <button
                        className="btn-default btn-white"
                        onClick={() => onReset()}
                    >
                        {ButtonVariables.RESET}
                    </button>
                    <button
                        className="btn-default"
                        onClick={() => onSave()}
                    >
                        {ButtonVariables.SAVE}
                    </button>
                </div>
            )}
        </div>
    )
}

export default Form.create({ name: "ScrewCompressor" })(AdiabaticEfficiencyCurves);