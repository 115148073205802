import { Button, Empty, Input, Pagination, Table, Tabs } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { connect } from 'react-redux';
import UploadModal from '../../common/Modal/UploadModal';
import Notification, { Types } from '../../common/Notification/Notification';
import {
  MANAGE_TAGS_MESSAGE,
  NO_INTERNET,
  UnitPropertiesMessages,
  UnitPropertiesTitle,
} from '../../constants/messages';
import { MaskStyle } from '../../constants/react-style';
import {
  columnsTitle,
  UnitPropertiesVariables,
} from '../../constants/variables';
import NotConnected from '../../libs/NotConnected';
import {
  clearState,
  unitsProperties,
  uploadDataVariable,
  uploadUnitConversion,
} from '../../redux/actions/unitsProperties.action';
import CollapseTable from './CollapseTable';
import './unitsproperties.scss';

const columnsUnits = [
  {
    title: columnsTitle.units.unit,
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: columnsTitle.units.property,
    dataIndex: 'property',
    key: 'property',
  },
];

const columnsDataVariables = [
  {
    title: columnsTitle.dataVariables.dataVariable,
    dataIndex: 'name',
    key: 'name  ',
  },
  {
    title: columnsTitle.dataVariables.property,
    dataIndex: 'property',
    key: 'property',
  },
];

const columnsProperties = [
  {
    title: columnsTitle.properties.properties,
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: columnsTitle.properties.siUnit,
    dataIndex: 'siUnit',
    key: 'siUnit',
  },
];

class UnitsProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 20,
      searchText: '',
      searchValue: '',
      tabPaneNumber: '1',
      currentPage: [1, 1, 1, 1],
      isUploadVariable: false,
      isUploadUnit: false,
      network: window.navigator.onLine,
    };
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  setTabPane = async (paneNumber) => {
    const { limit } = this.state;
    this.setState(
      {
        tabPaneNumber: paneNumber,
        currentPage: [1, 1, 1, 1],
        searchValue: '',
      },
      () => {
        if (this.handleNetwork()) {
          this.props.unitsProperties(paneNumber, { limit });
        }
      }
    );
  };

  componentDidMount() {
    const { limit } = this.state;
    if (this.handleNetwork()) {
      this.props.unitsProperties('1', {
        limit,
        keyword: this.state.searchValue,
      });
    }
  }

  componentDidUpdate(_prevProps) {
    const { limit } = this.state;
    const { dataVariable, unitConversion } = this.props;

    if (this.props.isError) {
      Notification.show(
        Types.Error,
        UnitPropertiesMessages[this.props.errorCode]
      );
      this.props.clearState();
    }

    if (
      dataVariable.isSuccess &&
      dataVariable.isSuccess !== _prevProps.dataVariable.isSuccess
    ) {
      this.setState({
        isUploadVariable: false,
      });
      Notification.show(Types.Success, MANAGE_TAGS_MESSAGE.CSV_SUCCESS_UPLOAD);
      if (this.handleNetwork()) {
        this.props.unitsProperties(this.state.tabPaneNumber, {
          limit,
          keyword: this.state.searchValue,
        });
      }
    } else if (
      dataVariable.isError &&
      dataVariable.isError !== _prevProps.dataVariable.isError
    ) {
      Notification.show(Types.Error, this.props.dataVariable.msg);
    }

    if (
      unitConversion.isSuccess &&
      unitConversion.isSuccess !== _prevProps.unitConversion.isSuccess
    ) {
      this.setState({
        isUploadUnit: false,
      });
      Notification.show(Types.Success, MANAGE_TAGS_MESSAGE.CSV_SUCCESS_UPLOAD);
      if (this.handleNetwork()) {
        this.props.unitsProperties(this.state.tabPaneNumber, {
          limit,
          keyword: this.state.searchValue,
        });
      }
    } else if (
      unitConversion.isError &&
      unitConversion.isError !== _prevProps.unitConversion.isError
    ) {
      Notification.show(Types.Error, this.props.unitConversion.msg);
    }
  }

  onPagination(pane, page) {
    const { limit } = this.state;
    const pages = [1, 1, 1, 1];
    pages[pane - 1] = page;
    this.setState({ currentPage: pages }, () => {
      if (this.handleNetwork()) {
        this.props.unitsProperties(pane, {
          limit,
          skip: (page - 1) * limit,
          keyword: this.state.searchValue,
        });
      }
    });
  }

  onSearch(data) {
    const { tabPaneNumber, limit } = this.state;
    this.setState({
      searchValue: data,
    });
    if (this.handleNetwork()) {
      this.props.unitsProperties(tabPaneNumber, {
        limit,
        keyword: data,
      });
    }
  }

  onSearchChange(value) {
    this.setState({
      searchValue: value,
    });
    if (!value) {
      this.onSearch('');
    }
  }

  handleToggle = (key) => {
    this.setState({ [key]: !this.state[`${key}`] });
  };

  uploadCsv = (data) => {
    if (data) {
      if (this.handleNetwork()) {
        this.props.uploadUnitConversion(data);
      }
    } else {
      Notification.show(
        Types.Error,
        MANAGE_TAGS_MESSAGE.EMPTY_UPLOAD_ERROR_MSG
      );
    }
  };

  uploadVariableCsv = (data) => {
    if (data) {
      if (this.handleNetwork()) {
        this.props.uploadDataVariable(data);
      }
    } else {
      Notification.show(
        Types.Error,
        MANAGE_TAGS_MESSAGE.EMPTY_UPLOAD_ERROR_MSG
      );
    }
  };

  renderTabs() {
    const { TabPane } = Tabs;
    return (
      <Tabs
        defaultActiveKey="1"
        type="card"
        className="tabs"
        onChange={this.setTabPane}
      >
        <TabPane tab={UnitPropertiesVariables.associations} key="1">
          {this.props.associations && this.props.associations.items ? (
            <div>
              <CollapseTable
                collapseData={
                  this.props.associations ? this.props.associations.items : []
                }
              />
              <Pagination
                total={
                  this.props.associations
                    ? this.props.associations.total_count
                    : 0
                }
                onChange={(data) => {
                  this.onPagination('1', data);
                }}
                pageSize={this.state.limit}
                hideOnSinglePage
                current={this.state.currentPage[0]}
              />
            </div>
          ) : (
            <Empty />
          )}
        </TabPane>

        <TabPane tab={UnitPropertiesVariables.properties} key="2">
          <Table
            dataSource={
              this.props.properties ? this.props.properties.items : []
            }
            columns={columnsProperties}
            pagination={{
              total: this.props.properties
                ? this.props.properties.total_count
                : 0,
              onChange: (data) => {
                this.onPagination('2', data);
              },
              pageSize: this.state.limit,
              hideOnSinglePage: true,
              current: this.state.currentPage[1],
            }}
          />
        </TabPane>

        <TabPane tab={UnitPropertiesVariables.dataVariables} key="3">
          <Table
            dataSource={
              this.props.dataVariables ? this.props.dataVariables.items : []
            }
            columns={columnsDataVariables}
            pagination={{
              total: this.props.dataVariables
                ? this.props.dataVariables.total_count
                : 0,
              onChange: (data) => {
                this.onPagination('3', data);
              },
              pageSize: this.state.limit,
              hideOnSinglePage: true,
              current: this.state.currentPage[2],
            }}
          />
        </TabPane>

        <TabPane tab={UnitPropertiesVariables.units} key="4">
          <Table
            dataSource={this.props.units ? this.props.units.items : []}
            columns={columnsUnits}
            pagination={{
              total: this.props.units ? this.props.units.total_count : 0,
              onChange: (data) => {
                this.onPagination('4', data);
              },
              pageSize: this.state.limit,
              hideOnSinglePage: true,
              current: this.state.currentPage[3],
            }}
          />
        </TabPane>
      </Tabs>
    );
  }

  render() {
    const { Search } = Input;
    // const { isLoading, dataVariable, unitConversion } = this.props;

    return (
      <div className="UnitProperties">
        {/* {(isLoading || dataVariable.isLoading || unitConversion.isLoading) && (
          <Loader />
        )} */}
        <div className="header" style={MaskStyle}>
          <div className="units-properties">{UnitPropertiesTitle}</div>
          <div className="page-action page-action-tab">
            <Search
              placeholder={UnitPropertiesVariables.searchHere}
              className="search-bar"
              onChange={(e) => {
                this.onSearchChange(e.target.value);
              }}
              onSearch={(value) => {
                this.onSearch(value);
              }}
              value={this.state.searchValue}
              allowClear
            />
            <Button
              className="btn-default upload-variable"
              onClick={() => this.handleToggle('isUploadVariable')}
            >
              {UnitPropertiesVariables.UPLOAD_VARIABLE_FILE}
            </Button>
            <Button
              className="btn-default"
              onClick={() => this.handleToggle('isUploadUnit')}
            >
              {UnitPropertiesVariables.UPLOAD_UNIT_FILE}
            </Button>
          </div>
        </div>
        <div className="content-body">
          {this.state.network ? this.renderTabs() : <NotConnected />}
        </div>
        {this.state.isUploadVariable && (
          <UploadModal
            title="Upload File"
            content="Data Variables/Properties Association"
            visible={this.state.isUploadVariable}
            handleOk={(payload) => this.uploadVariableCsv(payload)}
            handleCancel={() => this.handleToggle('isUploadVariable')}
          />
        )}
        {this.state.isUploadUnit && (
          <UploadModal
            title="Upload File"
            content="Unit Conversion"
            visible={this.state.isUploadUnit}
            handleOk={(payload) => this.uploadCsv(payload)}
            handleCancel={() => this.handleToggle('isUploadUnit')}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unitConversion: state.unitsProperty.unitConversion,
  dataVariables: state.unitsProperty.dataVariables,
  dataVariable: state.unitsProperty.dataVariable,
  associations: state.unitsProperty.association,
  properties: state.unitsProperty.properties,
  isLoading: state.unitsProperty.isFetching,
  errorCode: state.unitsProperty.errorCode,
  isError: state.unitsProperty.isError,
  units: state.unitsProperty.units,
  network: state.NetworkReducer,
});

const mapDispatchToProps = {
  uploadUnitConversion,
  uploadDataVariable,
  unitsProperties,
  clearState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsProperties);
