import React, { useEffect, useState, useCallback } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getConfigData,
  updateConfigData,
  resetSqlComponentConfigData,
} from '../../../../../redux/actions/SQLComponent.action';
import {
  NO_INTERNET,
  MotorDesignDataMessages,
} from '../../../../../constants/messages';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import Loader from '../../../../../common/Loader/Loader';
import './ConfigSql.scss';

function SQLComponentConfigScreen(props) {
  const {
    network,
    location,
    configDataState,
    getConfigData,
    updateConfigData,
    resetSqlComponentConfigData,
  } = props;

  const [sqlComponentId, setSqlComponentId] = useState();
  const [isInputModified, setInputModified] = useState(false);

  const handleNetwork = useCallback(() => {
    if (network) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }, [network]);

   // upon successfully saving design data
   useEffect(() => {
    if (configDataState.isUpdateSuccess) {
      resetSqlComponentConfigData();
      Notification.show(Types.Success, "SQL Component Config Saved Succesfully");
    } else if (configDataState.isError) {
      Notification.show(Types.Error, configDataState.apiMsg);
    }
  }, [configDataState, resetSqlComponentConfigData]);

  const setData = useCallback(() => {
    if (configDataState.isSuccess) {
      const sqlCompId = configDataState.sqlComponentId;
      if (sqlCompId) {
        setSqlComponentId(sqlCompId);
      }
    }
  }, [configDataState]);

  const getData = useCallback(() => {
    if (handleNetwork()) {
      const { state } = location;
      if (state && state.componentData.info._id) {
        getConfigData(state.componentData.info._id);
      }
    }
  }, [getConfigData, location, handleNetwork]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setData();
  }, [setData]);

  const handleInput = (e) => {
    setSqlComponentId(e.target.value);
    setInputModified(true);
  };

  const handleSubmit = () => {
    const { state } = location;
    if (
      handleNetwork() &&
      state &&
      state.componentData.info._id &&
      sqlComponentId
    ) {
      const payload = { sqlComponentId: parseFloat(sqlComponentId) };
      updateConfigData(state.componentData.info._id, payload);
      setInputModified(false);
    } else {
      Notification.show(Types.Error, MotorDesignDataMessages.DIRTY_DATA);
    }
  };

  return (
    <div className="parent-container">
      {configDataState.isLoading && <Loader />}

      <div className="input-container">
        <p htmlFor="">SQL Component Id </p>
        <Input
          id="sqlComponentId"
          disabled={false}
          type="number"
          name="sqlComponentId"
          value={sqlComponentId}
          onChange={handleInput}
          placeholder="Please provide sql component Id"
        />
      </div>

      {isInputModified && (
        <div className="button-container">
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer.networkState,
    configDataState: state.SQLComponentReducer.ConfigData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConfigData: (componentId) => dispatch(getConfigData(componentId)),
  updateConfigData: (componentId, payload) =>
    dispatch(updateConfigData(componentId, payload)),
  resetSqlComponentConfigData: () => dispatch(resetSqlComponentConfigData()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SQLComponentConfigScreen)
);
