import { Types } from "../common/Notification/Notification";
import { MANAGE_TAGS_MESSAGE } from "./messages";

const csvToArray = async (payload) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        let file = payload.get("file")
        let parsedArray = []
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                const processCSV = (str, delim = ',') => {
                    const headers = str.slice(0, str.indexOf('\n')).trim().split(delim);
                    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
                    parsedArray = rows.map(row => {
                        const values = row.split(delim);
                        const eachObject = headers.reduce((obj, header, i) => {
                            obj[header] = values[i];
                            return obj;
                        }, {})
                        return eachObject;
                    })
                    resolve(parsedArray)
                };
                processCSV(csvOutput)
            }
            fileReader.readAsText(file);
        }
        else {
            Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.CSV_CONVERSION_FAIL)
            return parsedArray
        }
    })
}

export default csvToArray