export const USER_FETCH_PROGRESS = "USER_FETCH_PROGRESS";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";
export const USER_SAVE_PROGRESS = "USER_SAVE_PROGRESS";
export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const USER_SAVE_ERROR = "USER_SAVE_ERROR";
export const USER_CHANGE_STATUS_PROGRESS = "USER_CHANGE_STATUS_PROGRESS";
export const USER_CHANGE_STATUS_SUCCESS = "USER_CHANGE_STATUS_SUCCESS";
export const USER_CHANGE_STATUS_ERROR = "USER_CHANGE_STATUS_ERROR";
export const USER_EDIT_PROGRESS = "USER_EDIT_PROGRESS";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_ERROR = "USER_EDIT_ERROR";
export const USER_CLEAR = "USER_CLEAR";
export const INVITATION_LINK_PROGRESS = "INVITATION_LINK_PROGRESS";
export const INVITATION_LINK_SUCCESS = "INVITATION_LINK_SUCCESS";
export const INVITATION_LINK_ERROR = "INVITATION_LINK_ERROR";
export const RESET_PASSWORD_PROGRESS = "RESET_PASSWORD_PROGRESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_DATA = "RESET_DATA";
