import {
  DELETE_KPI_BY_KPIID_SUCCESS,
  DELETE_KPI_BY_KPIID_PROGESS,
  DELETE_KPI_BY_KPIID_ERROR,
  DELETE_KPI_BY_KPIID_CLEAR_STATE,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_KPI_BY_KPIID_PROGESS:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_KPI_BY_KPIID_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        kpiId: action.payload.kpiId,
        isError: true,
        errorCode:
          action.payload.err.response && action.payload.err.response.body.code,
        message:
          action.payload.err.response &&
          action.payload.err.response.body.message,
      };

    case DELETE_KPI_BY_KPIID_SUCCESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: {
          id: action.payload.id,
          satus: action.payload.status,
        },
      };
    case DELETE_KPI_BY_KPIID_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
};
