import {
  Avatar,
  Breadcrumb,
  Button,
  Form,
  Icon,
  Input,
  Select,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ic_download } from '../../../assets/ic_download';
import Loader from '../../../common/Loader/Loader';
import ConfirmModal from '../../../common/Modal/Modal';
import Notification, { Types } from '../../../common/Notification/Notification';
import '../../../constants/global.scss';
import {
  ClientsMessages,
  DISCARD_CHANGES,
  fileMessage,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../constants/messages';
import * as Pattern from '../../../constants/pattern';
import { MaskStyle } from '../../../constants/react-style';
import * as Routes from '../../../constants/routes';
import {
  ButtonVariables,
  ClientDetailsVariable,
  fileUpload,
  NO_COUNTRY_DATA,
} from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { generateCode } from '../../../libs/codeGenerators';
import NotConnected from '../../../libs/NotConnected';
import { getImageUrl, getToken } from '../../../libs/token';
import { fileProps } from '../../../libs/uploader';
import { ElementPermissions } from '../../../permissions';
import {
  addNewClient,
  clearClientState,
  editClient,
  getClient,
} from '../../../redux/actions/client.action';
import {
  clearStatesFetchState,
  getState,
} from '../../../redux/actions/state.action';
import isStringEmpty from '../../../services/ValidateService';
import './ClientDetails.scss';

const { Option } = Select;
const { Dragger } = Upload;

class ClientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'view',
      formDisable: true,
      logoPath: '',
      logoUrl: '',
      country: [],
      selectedStates: [],
      uploading: false,
      visible: false,
      demandPage: window.location.pathname,
      formEdit: false,
      network: window.navigator.onLine,
      message: null,
      title: null,
      clientInfoData: {},
    };

    this.clientDetailsFormFields = [
      {
        label: ClientDetailsVariable.CLIENT_NAME,
        key: 'name',
        max: '50',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        blur: (e) => {
          this.getCode(e.target.value);
        },
      },
      {
        label: ClientDetailsVariable.CLIENT_CODE,
        key: 'clientId',
        max: '25',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: ClientDetailsVariable.CLIENT_CONTARACT_NUMBER,
        key: 'contractNo',
        max: '15',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: ClientDetailsVariable.MECHADEMY_ID,
        key: 'mechademyId',
        max: '25',
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
    ];

    this.clientAddressDetailsFormFields = [
      {
        label: ClientDetailsVariable.ADDRESS_ONE,
        key: 'address1',
        max: '150',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        pattern: [Pattern.Address.regx, Pattern.Address.message],
      },
      {
        label: ClientDetailsVariable.ADDRESS_TWO,
        key: 'address2',
        max: '150',
        pattern: [Pattern.Address.regx, Pattern.Address.message],
      },
      {
        label: ClientDetailsVariable.CITY,
        key: 'city',
        max: '50',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        pattern: [Pattern.Letters.regx, Pattern.Letters.message],
      },
      {
        label: ClientDetailsVariable.ZIP_CODE,
        key: 'zip',
        max: '10',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        pattern: [Pattern.Zip.regx, Pattern.Zip.message],
      },
      {
        label: ClientDetailsVariable.COUNTRY,
        key: 'country',
        type: 'dropdown',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        change: (e) => {
          this.onCountrySelect(e);
        },
        data: this.state.country,
      },
      {
        label: ClientDetailsVariable.STATE,
        key: 'state',
        type: 'dropdown',
        required: [true, ClientsMessages.REQUIRED_FIELD],
        data: this.state.selectedStates,
      },
    ];
  }

  componentDidMount() {
    const { getStateAction } = this.props;
    const {
      location: { state },
    } = this.props;

    if (this.handleNetwork()) {
      getStateAction();
    }

    if (state && state.clientData) {
      this.setState({ clientInfoData: _.cloneDeep(state.clientData) });
    }

    this.getPageMode();

    window.addEventListener('form-edit', () => {
      if (!this.state.formDisable) {
        this.setState({
          formEdit: true,
        });
      }
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
      });
    });
  }

  componentDidUpdate() {
    const {
      isSuccessAddClients,
      isSuccessEditClients,
      isSuccessClientsDetails,
      isErrorEditClients,
      isErrorAddClients,
      clientDetailsData,
      errorCode,
      message,
    } = this.props.clientState.apiState;
    const { isSuccessStateFetch, stateData } = this.props.statesState.apiState;
    const { clearStatesFetchState, clearClientState } = this.props;

    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';

    if (isSuccessAddClients) {
      Notification.show(Types.Success, ClientsMessages.clientAdded);
      clearClientState();
      this.disableEditForm();
      this.setState({
        mode: 'view',
        formDisable: true,
        clientDetailsData,
      });
    }

    if (isSuccessEditClients) {
      Notification.show(Types.Success, ClientsMessages.clientUpdated);
      clearClientState();
      this.disableEditForm();
      this.setState({
        mode: 'view',
        formDisable: true,
        clientDetailsData,
      });
    }

    if (isSuccessClientsDetails) {
      clearClientState();
      this.setState(
        {
          clientDetailsData,
        },
        () => {
          this.setFormData();
        }
      );
    }

    if (isSuccessStateFetch) {
      clearStatesFetchState();
      this.setState({ country: stateData });
      this.getPageMode();
    }

    if (isErrorEditClients) {
      if (errorCode === 'ALREADY_EXISTS') {
        if (_.includes(message, 'mechademy'))
          Notification.show(Types.Error, `${ClientsMessages[errorCode]}`);
        else
          Notification.show(Types.Error, ClientsMessages.NAME_ALREADY_EXISTS);
      } else {
        Notification.show(
          Types.Error,
          `${ClientsMessages[errorCode]} ${displayMessage}`
        );
      }
      clearClientState();
    }

    if (isErrorAddClients) {
      if (errorCode === 'ALREADY_EXISTS') {
        if (_.includes(message, 'mechademy'))
          Notification.show(Types.Error, `${ClientsMessages[errorCode]}`);
        else Notification.show(Types.Error, message);
      } else {
        Notification.show(
          Types.Error,
          `${ClientsMessages[errorCode]} ${displayMessage}`
        );
      }
      clearClientState();
    }
  }

  getPageMode() {
    const {
      location: { state },
    } = this.props;
    if (state && state.mode) {
      if (state.mode === 'edit') {
        this.setState({ formDisable: false, mode: 'edit' });
      } else if (state.mode === 'add') {
        this.setState({ formDisable: false, mode: 'add' });
      } else {
        this.setState({ formDisable: true, mode: 'view' });
        this.getClientDetails();
      }
    } else {
      this.props.history.push(Routes.clients);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  dataPopup = (path) => {
    this.setState({ logoPath: `${path}` }, () => {
      const imageUrl = getImageUrl(path);
      this.setState({ logoUrl: imageUrl });
    });
  };

  onImageUpload(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({ uploading: false });
    } else {
      this.setState({ uploading: true });
    }

    if (status === 'done') {
      this.dataPopup(info.file.response.fileId);
      Notification.show(Types.Success, fileMessage.success);
    }
  }

  getClientDetails() {
    const {
      location: { state },
    } = this.props;
    const { getClient } = this.props;

    if (state && state.clientData) {
      if (this.handleNetwork()) {
        getClient(state.clientData._id);
      }
    }
  }

  setFormData() {
    const { clientDetailsData } = this.state;

    if (clientDetailsData && clientDetailsData._id) {
      this.onCountrySelect(clientDetailsData.country);
      this.props.form.setFieldsValue(clientDetailsData, () => {
        this.disableEditForm();
      });
    }
  }

  onEdit() {
    this.setState({ mode: 'edit' }, () => {
      this.setState({
        formDisable: false,
      });
    });
  }

  onCancel() {
    const { mode } = this.state;

    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        demandPage: mode === 'edit' ? null : Routes.clients,
        demandPageData: {},
      });
    } else if (!mode || mode === 'add') {
      this.props.history.push(Routes.clients);
    } else {
      this.setState({ mode: 'view' }, () => {
        this.setState({
          formDisable: true,
          formEdit: false,
        });
      });
      this.props.form.resetFields();
      this.setFormData();
      this.disableEditForm();
    }
  }

  onCountrySelect(countryVal) {
    const { country } = this.state;
    const selectedState = country.find((val) => val.name === countryVal);
    this.setState({
      selectedStates: selectedState ? selectedState.states : [],
    });
    this.props.form.resetFields(['state']);
  }

  getOption(data) {
    return <Option value={data.name}>{data.name}</Option>;
  }

  newFacility = () => {
    const { clientDetailsData } = this.state;
    this.props.history.push(Routes.clientFacility, {
      clientData: { ...clientDetailsData },
      mode: 'add',
    });
  };

  getCode(value) {
    if (this.state.mode !== 'edit') {
      value = value || '';
      const code = generateCode(value.trim());
      const { clientId, mechademyId, name } = this.props.form.getFieldsValue();
      if (!clientId && !mechademyId) {
        this.props.form.setFieldsValue({
          name: value.trim(),
          clientId: code,
          mechademyId: code,
        });
      } else if (name && name.length >= 3 && clientId && clientId.length >= 3) {
        const firstThreeCharOfName = name.trim().substr(0, 3).toLowerCase();
        const firstThreeCharOfClientId = clientId
          .trim()
          .substr(0, 3)
          .toLowerCase();
        if (firstThreeCharOfName !== firstThreeCharOfClientId) {
          this.props.form.setFieldsValue({
            name: value.trim(),
            clientId: code,
            mechademyId: code,
          });
        }
      }
    }
  }

  checkIsEmpty(formFields) {
    return (
      !isStringEmpty(formFields.name) &&
      !isStringEmpty(formFields.address1) &&
      !isStringEmpty(formFields.zipcode) &&
      !isStringEmpty(formFields.state) &&
      !isStringEmpty(formFields.city) &&
      !isStringEmpty(formFields.country)
    );
  }

  setDefaultvalue(values) {
    values.address2 = values.address2 === '' ? null : values.address2;
    values.clientId = values.clientId === '' ? null : values.clientId;
    values.contractNo = values.contractNo === '' ? null : values.contractNo;
    values.mechademyId = values.mechademyId === '' ? null : values.mechademyId;
    return values;
  }

  validateFieldsValue() {
    const values = this.props.form.getFieldsValue();
    const valuesKey = Object.keys(this.props.form.getFieldsValue());
    for (let i = 0; i < valuesKey.length; i++) {
      if (valuesKey[i] !== 'logo') {
        values[valuesKey[i]] = values[valuesKey[i]]
          ? values[valuesKey[i]].trim()
          : values[valuesKey[i]];
      }
    }
    this.props.form.setFieldsValue(values);
  }

  save = (e) => {
    this.validateFieldsValue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.logoPath !== '') values.logo = this.state.logoPath;
        for (const fieldKey in values) {
          if (!values[fieldKey]) {
            delete values[fieldKey];
          }
        }
        if (this.handleNetwork()) {
          this.props.addNewClientAction(values);
        }
      }
    });
  };

  update = () => {
    this.validateFieldsValue();
    const { _id, logo } = this.state.clientDetailsData;
    if (!this.state.formEdit) {
      this.onCancel();
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.logoPath !== '') {
          values.logo = this.state.logoPath;
        } else if (values.logo && values.logo.file) {
          values.logo = this.state.logoPath || logo || null;
        }
        values = this.setDefaultvalue(values);
        if (this.handleNetwork()) {
          this.props.updateClientAction(values, _id);
        }
      }
    });
  };

  componentWillUnmount() {
    this.disableEditForm();
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { mode, demandPage, demandPageData } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        if (mode === 'edit' && !demandPage) {
          const { apiState } = this.props.clientState;
          const { clientDetailsData } = apiState;
          const imageUrl = getImageUrl(clientDetailsData.logo);
          this.setState(
            {
              mode: 'view',
              logoPath: clientDetailsData.logo,
              logoUrl: imageUrl,
            },
            () => {
              this.setState({
                formDisable: true,
                formEdit: false,
              });
            }
          );
          this.props.form.resetFields();
          this.setFormData();
        } else {
          this.props.history.push(demandPage, demandPageData);
        }
      }
    );
  }

  getClientName() {
    const { clientDetailsData } = this.state;
    return clientDetailsData && clientDetailsData.name
      ? clientDetailsData.name
      : ClientDetailsVariable.ADD_CLIENT;
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = 'view';
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        message: null,
        title: null,
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  renderFormButton() {
    const { mode } = this.state;
    if (mode === 'add') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button className="btn-default" onClick={() => this.save()}>
            {ButtonVariables.SAVE}
          </Button>
        </div>,
        ElementPermissions.ADD_CLIENT
      );
    }
    if (mode === 'edit') {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button
            className="btn-default"
            onClick={() => {
              this.update();
            }}
          >
            {ButtonVariables.UPDATE}
          </Button>
        </div>,
        ElementPermissions.EDIT_CLIENT
      );
    }
    if (mode === 'view') {
      return elementsGaurd(
        <Button
          className="btn-default"
          onClick={() => {
            this.onEdit();
          }}
        >
          {ButtonVariables.EDIT}
        </Button>,
        ElementPermissions.EDIT_CLIENT
      );
    }
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    if (field.type) {
      if (field.type === 'dropdown') {
        let data = [];
        if (field.key === 'country') {
          data = this.state.country;
        } else {
          data = this.state.selectedStates;
        }
        return (
          <Select
            disabled={formDisable}
            showArrow={!formDisable}
            showSearch
            placeholder="Search here"
            className="dropdown-search-field"
            onChange={field.change ? field.change : () => {}}
          >
            {field.key === 'country' ||
            (this.state.selectedStates &&
              this.state.selectedStates.length) ? null : (
              <Option value={null}>{NO_COUNTRY_DATA}</Option>
            )}
            {data && data.map((data) => this.getOption(data))}
          </Select>
        );
      }
      return (
        <Input
          maxLength={field.max ? field.max : ''}
          onBlur={field.blur ? field.blur : () => {}}
          autoComplete="off"
          disabled={formDisable}
        />
      );
    }
    return (
      <Input
        maxLength={field.max ? field.max : ''}
        onBlur={field.blur ? field.blur : () => {}}
        autoComplete="off"
        disabled={formDisable}
      />
    );
  }

  renderField(field) {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item label={field.label} style={field.style}>
        {getFieldDecorator(field.key, {
          rules: [
            field.required
              ? {
                  required: field.required[0],
                  message: field.required[1],
                }
              : '',
            field.pattern
              ? {
                  pattern: field.pattern[0],
                  message: field.pattern[1],
                }
              : '',
          ],
        })(this.renderFieldType(field))}
      </Form.Item>
    );
  }

  getLogo() {
    const { mode, clientDetailsData, logoUrl, logoPath } = this.state;
    if ((mode === 'add' || mode === 'edit') && logoUrl) {
      return <img alt="" className="image" src={logoUrl} />;
    }
    if (mode === 'view' && clientDetailsData) {
      if (clientDetailsData.logo) {
        return (
          <img
            alt=""
            className="image"
            src={getImageUrl(clientDetailsData.logo)}
          />
        );
      }
      return <Avatar src="" />;
    }
    if (mode === 'edit' && clientDetailsData && clientDetailsData.logo) {
      return (
        <img
          alt=""
          className="image"
          src={
            _.isEmpty(logoPath)
              ? getImageUrl(clientDetailsData.logo)
              : getImageUrl(logoPath)
          }
        />
      );
    }
    return (
      <div>
        <p className="ant-upload-drag-icon">
          <img src={`${ic_download}`} alt="ic" />
        </p>
        <p className="ant-upload-text">{fileUpload.text}</p>
        <p className="ant-upload-hint">{fileUpload.hint}</p>
      </div>
    );
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { formDisable, logoUrl, clientInfoData, mode } = this.state;

    return (
      <Form>
        <Form.Item className="upload-box">
          {getFieldDecorator(
            'logo',
            {}
          )(
            !_.isEmpty(logoUrl) || (mode === 'edit' && clientInfoData.logo) ? (
              <div>
                <div className="logo">{this.getLogo()}</div>
                <div className="upload-icon">
                  <Upload
                    accept="image/png,image/jpg,image/jpeg"
                    onChange={(e) => {
                      this.onImageUpload(e);
                    }}
                    {...fileProps}
                    headers={{
                      authorization: getToken(),
                      api_key: process.env.REACT_APP_API_KEY,
                    }}
                    disabled={formDisable}
                  >
                    <span className="edit">
                      <Icon type="edit" />
                    </span>
                  </Upload>
                </div>
              </div>
            ) : (
              <Dragger
                accept="image/png,image/jpg,image/jpeg"
                onChange={(e) => {
                  this.onImageUpload(e);
                }}
                {...fileProps}
                headers={{
                  authorization: getToken(),
                  api_key: process.env.REACT_APP_API_KEY,
                }}
                disabled={formDisable}
              >
                <div>{this.getLogo()}</div>
              </Dragger>
            )
          )}
        </Form.Item>
        <div className="spacialForm">
          {this.clientDetailsFormFields.map((field) => this.renderField(field))}
        </div>
        <div className="Address">
          <label className="Address-Details">
            {ClientDetailsVariable.ADDRESS_DETAILS}
          </label>
          <div className="bar">
            <hr />
          </div>
        </div>
        {this.clientAddressDetailsFormFields.map((field) =>
          this.renderField(field)
        )}
        <div className="action-btn">{this.renderFormButton()}</div>
      </Form>
    );
  }

  renderBreadCrumb() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <label onClick={() => this.navigateToScreen(Routes.clients)}>
            {ClientDetailsVariable.CLIENTS}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{this.getClientName()}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
          title={this.state.title}
        />
      );
    }
    return null;
  }

  render() {
    const { mode } = this.state;
    const { loading } = this.props.clientState;

    return (
      <div className="ClientDetails">
        {loading || this.state.uploading ? <Loader /> : null}
        <div className="header" style={MaskStyle}>
          <div className="title">{this.getClientName()}</div>
          <div className="path">
            {this.renderBreadCrumb()}
            {mode === 'view'
              ? elementsGaurd(
                  <Button
                    className="btn-default"
                    onClick={() => this.newFacility()}
                  >
                    {ClientDetailsVariable.ADD_FACILITY}
                  </Button>,
                  ElementPermissions.ADD_FACILITY
                )
              : ''}
          </div>
        </div>

        <div className="screen-content">
          <div className="content-div">
            {this.state.network ? this.renderForm() : <NotConnected />}
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  statesState: state.StateReducer,
  loginState: state.LoginReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addNewClientAction: (payload) => {
    dispatch(addNewClient(payload));
  },
  updateClientAction: (payload, id) => dispatch(editClient(payload, id)),
  getClient: (values) => dispatch(getClient(values)),
  clearClientState: () => dispatch(clearClientState()),
  getStateAction: () => {
    dispatch(getState());
  },
  clearStatesFetchState: () => dispatch(clearStatesFetchState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'clientDetails',
    onFieldsChange: (props, fields, allfields) => {
      window.dispatchEvent(new Event('form-edit'));
    },
  })(withRouter(ClientDetails))
);
