import { Icon } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DirectoryStructure from '../../../common/DirectoryStructure/DirectoryStructure';
import Notification, { Types } from '../../../common/Notification/Notification';
import {
  ClientListingMessages,
  NO_INTERNET,
} from '../../../constants/messages';
import {
  clearClientState,
  clientList,
  getClientInfo,
} from '../../../redux/actions/client.action';
import '../Dashboard.scss';
import {
  getClientChildren,
  getClientChildren2,
  getClientListObject,
  getSelectedNodeInfoObject,
  NodeTypes,
} from './DashboardTree.constants';

class DashboardTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeMenu: false,
      clientList: null,
      clientInfoData: {},
      selectedNode: {},
      selectedKey: null,
      expandedKey: [],
      selectedDirectoryIndex: 0,
    };
  }

  componentDidMount() {
    this.getClientList();
    if(window.innerWidth <= 1400){
      this.setState({closeMenu: true})
    }
  }

  componentDidUpdate() {
    const {
      isSuccessClientsInfo,
      isErrorClientInfo,
      errorCode,
      clientInfoData,
      clientList,
      isSuccessFetchClients,
      isError,
    } = this.props.clientState.apiState;

    const { clearClientState } = this.props;

    if (isSuccessClientsInfo) {
      clearClientState();
      this.setState((preState) => {
        if (preState.clientList) {
          preState.clientList[
            preState.selectedDirectoryIndex
          ].children = getClientChildren(clientInfoData);
          preState.selectedKey =
            clientInfoData &&
            clientInfoData.facilities &&
            Object.values(
              getClientChildren2(clientInfoData)[
                this.props.location.state && this.props.location.state.type
                  ? this.props.location.state.type
                  : 0
              ]
            )[
              Object.keys(
                getClientChildren2(clientInfoData)[
                  this.props.location.state && this.props.location.state.type
                    ? this.props.location.state.type
                    : 0
                ]
              ).indexOf(
                this.props.location?.state?.id
                  ? this.props.location?.state?.id
                  : Object.keys(
                      getClientChildren2(clientInfoData)[
                        this.props.location.state &&
                        this.props.location.state.type
                          ? this.props.location.state.type
                          : 0
                      ]
                    )[0]
              )
            ];
        }
        return preState;
      });
    }

    if (isSuccessFetchClients) {
      clearClientState();
      if (clientList && clientList.items) {
        const clientListObject = getClientListObject(clientList.items);
        const defaultClient = Object.values(clientListObject)[1];
        this.setState({
          clientList: clientListObject,
          expandedKey: defaultClient && defaultClient.info._id,
        });
      }
    }

    if (isError) {
      Notification.show(Types.Error, ClientListingMessages[errorCode]);
      clearClientState();
    }

    if (isErrorClientInfo) {
      Notification.show(Types.Error, ClientListingMessages[errorCode]);
      clearClientState();
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getClientList() {
    const { clientListAction } = this.props;
    if (this.handleNetwork()) {
      clientListAction({});
    }
  }

  getClientInfo(id) {
    const { getClientInfo } = this.props;
    if (this.handleNetwork()) {
      getClientInfo(id);
    }
  }

  closeMenu() {
    if (!this.state.closeMenu) {
      this.setState({
        closeMenu: true,
      });
    } else {
      this.setState({
        closeMenu: false,
      });
    }
  }

  onSelect = (data, event) => {
    if (data.type !== NodeTypes.CLIENT) {
      this.setState(
        {
          selectedKey: data.info._id,
        },
        () => {
          this.props.history.push({
            state: { ...this.props.location.state, selectedkey: data.info._id },
          });

          this.props.setInfo(getSelectedNodeInfoObject(data));
        }
      );
    }
  };

  onDirectoryExpand = (item) => {
    this.setState(
      {
        selectedDirectoryIndex: item && item.info._id,
      },
      () => {
        if (item && item.type) {
          this.props.history.push({
            state: {
              ...this.props.location.state,
              selectedExpandindex: item.info._id,
            },
          });
          this.getClientInfo(item.info._id);
        }
      }
    );
  };

  render() {
    // const { loading } = this.props.clientState;
    const { clientList } = this.state;
    return (
      <div className="DashboardTree">
        {/* {loading ? <Loader /> : null} */}
        <div
          className="structure"
          style={!this.state.closeMenu ? { width: '220px' } : { width: '17px' }}
        >
          <div
            className="close-button"
            onClick={() => {
              this.closeMenu();
            }}
          >
            {!this.state.closeMenu ? (
              <Icon type="left" />
            ) : (
              <Icon type="menu" />
            )}
          </div>
          <div className="directory">
            {clientList && (
              <DirectoryStructure
                data={clientList}
                onSelect={this.onSelect}
                onExpand={this.onDirectoryExpand}
                loader
                defaultSelectedKey={this.state.selectedKey}
                defaultExpandedKeys={[
                  this.props.location.state &&
                  this.props.location.state.selectedExpandindex
                    ? this.props.location.state.selectedExpandindex
                    : Object.keys(clientList)[0],
                ]}
                dashboard="dashboard"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  loginState: state.LoginReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClientInfo: (values) => dispatch(getClientInfo(values)),
  clearClientState: () => dispatch(clearClientState()),
  clientListAction: (values) => dispatch(clientList(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardTree));
