import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, LocalStorageCache } from '@auth0/auth0-react';


const AuthProvider = ({ children }) => {
  const history = useHistory();
  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };
  const config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  };

  // customLocalStorage.js
class CustomLocalStorageCache extends LocalStorageCache {
  constructor() {
    super();
    this.keyPrefix = 'RefreshTokenKey';
  }
  set(key, entry) {
    super.set(this.keyPrefix + key, entry);
  }
  get(key) {
    return super.get(this.keyPrefix + key);
  }
  remove(key) {
    super.remove(this.keyPrefix + key);
  }
  allKeys() {
    const allKeys = super.allKeys();
    return allKeys.filter(key => key.startsWith(this.keyPrefix));
  }
}

const customLocalStorageCache = new CustomLocalStorageCache();

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    cache : customLocalStorageCache,
    cacheKey: "abc",
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: `${process.env.REACT_APP_DOMAIN}/callback`,
      ...(config.audience ? { audience: config.audience } : null),
      scope: 'openid profile email offline_access',
    },
  };
  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default AuthProvider;
