export default {
  "Time series plot": "Time series plot", // plots
  "X-Y Plot": "X-Y Plot", // plots
  "X-Y Plot with baseline curve": "X-Y Plot with baseline curve", // plots
  "Time Card": "Time Card", // overview
  "Alert by priority bar chart": "Alert by priority bar chart", // overview
  "Alert by priority pie chart": "Alert by priority pie chart", // overview
  "Alert by status bar chart": "Alert by status bar chart", // overview
  "Alert priority Analytics tornado graph":
    "Alert priority Analytics tornado graph",
  "Operating Statistics": "Operating Statistics", // overview
  "Runtime / Reliability Analytics plot":
    "Runtime / Reliability Analytics plot", // overview
  "Alert status Analytics tornado graph":
    "Alert status Analytics tornado graph", // overview
  "Active alert summary card": "Active alert summary card", // overview
  "Alert by type pie chart": "Alert by type pie chart", // overview
  "Aggregate Values": "Aggregate Values", // overview;
  "Variable card": "Variable card", // overview
  "Runtime / Reliability analytics card":
    "Runtime / Reliability analytics card", // overview
  "Alert by status pie chart": "Alert by status pie chart", // overview
  "Run status Card": "Run status Card",
  "Equipment Run Plot": "Equipment Run Plot", // plots
  "Failure cause pie chart": "Failure cause pie chart", // overview
  "Failure bar chart": "Failure bar chart", // overview
  trends: "trends", // trends
  "Standard deviation card": "Standard deviation card", // overview
  "Run Statistic": "Run Statistic", // overview,
  "Multiple X-Y Plot": "Multiple X-Y Plot", //trends
  "Alert Plot": "Alert Plot", // inside alert description
  "Transient Analysis Plot": "Transient Analysis Plot", // transient analysis summary tab
};
