import { Button } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";

class NotConnected extends Component {
  render() {
    const { networkState } = this.props.network;
    return !networkState ? (
      <div className="notConnected">
        <i className="material-icons">&#xe1da;</i>
        <p>Internet not Connected</p>
        <Button onClick={() => window.location.reload()}>Retry</Button>
      </div>
    ) : (
      <div className="connected">
        <i className="material-icons">&#xe1d8;</i>
        <p>Internet Connected</p>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotConnected);
