const workercode = async(validatorProps) => {
        const { piTagdata, unitData, headerData, tagMessages, dataType } = validatorProps
        let validationMessage = {
            validation: true,
            message: tagMessages.VALIDATION_SUCCESS
        }
        let varaiblePropertyCheck = false,
            unitCheck = false,
            mechademyUnitCheck = false,
            dataTypeCheck = false,
            numValueError = false,
            iqrError = false,
            UADError = false,
            minMaxError = false
        const validate = async () => {
            for await (let tag of piTagdata) {
                let tagKeys = Object.keys(tag)
                varaiblePropertyCheck = false
                unitCheck = false
                dataTypeCheck = false
                numValueError = false
                mechademyUnitCheck = false
                iqrError = false
                UADError = false
                minMaxError = false
                if (JSON.stringify(tagKeys) === JSON.stringify(headerData)) {
                    for await (let unit of unitData) {
                        if (tag.variableProperty && String(tag.variableProperty).trim() === unit._id) {
                            varaiblePropertyCheck = true
                            for await (let value of unit.units) {
                                if(value === tag.mechademyUnits){
                                    mechademyUnitCheck = true
                                }
                                if(tag.units){
                                    unitCheck = true  
                                }
                            }
                        }
                    }
                    if (tag && tag.dataType) {
                        for await (let type of dataType) {
                            if (tag.dataType === type) {
                                dataTypeCheck = true
                            }
                        }
                    }
                    if (tag.minThreshold && tag.maxThreshold && tag.multiplier && tag.adder && tag.isIQR && tag.isIQR !== '\r') {
                        tag.minThreshold = Number(tag.minThreshold)
                        tag.maxThreshold = Number(tag.maxThreshold)
                        tag.multiplier = Number(tag.multiplier)
                        tag.adder = Number(tag.adder)
                        tag.isIQR = Number(tag.isIQR)
                        if (isNaN(tag.minThreshold) || isNaN(tag.maxThreshold) || isNaN(tag.multiplier) ||
                            isNaN(tag.adder) || isNaN(tag.isIQR)
                        ) {
                            numValueError = true
                        }
                        if((tag.isIQR > 1 || tag.isIQR < 0)){
                            iqrError = true
                        }
                        if(tag.multiplier <= 0 || tag.adder < 0){
                            UADError = true
                        }
                        if(tag.minThreshold >= tag.maxThreshold){
                            minMaxError = true
                        }
                    }
                    else if(tag.dataType === "Boolean" && tag.isIQR){
                        numValueError = false
                    }
                    else{
                        numValueError = true
                    } 
                    if (!varaiblePropertyCheck || !unitCheck || !dataTypeCheck || numValueError || !mechademyUnitCheck || iqrError || UADError || minMaxError) {
                        validationMessage.validation = false
                        if(!tag.piTag){
                            validationMessage.message =  tagMessages.EMPTY_ROW
                        }
                        else{
                            validationMessage.message = ((!varaiblePropertyCheck) ? tagMessages.VARIABLE_PROPERTY_ERROR : (!unitCheck) ?
                            tagMessages.UNIT_ERROR : (!dataTypeCheck) ? tagMessages.DATA_TYPE_ERROR : (!mechademyUnitCheck)? tagMessages.MECHADEMY_UNIT_ERROR:
                            (iqrError)? tagMessages.IQR_VAL_CHECK :(UADError)? tagMessages.UAD_ERROR: (minMaxError)? tagMessages.MIN_MAX_ERROR:
                            tagMessages.NAN_VAL_CHECK) + " " + tag.piTag
                        }
                        return validationMessage
                    }
                }
                else {
                    validationMessage.validation = false
                    validationMessage.message = tagMessages.DIRTY_HEADER
                    return validationMessage
                }
            }
            return validationMessage
        }
        let headerValidation = await validate()
        return headerValidation
}

export default workercode;
