import React from "react";
import { Select } from "antd";

const { Option } = Select;

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAvailable: false,
    };
  }

  getOptionObject(data, field) {
    return (
      <Option value={data.name ? data.name : data} key={data._id ? data._id : data} title={data.name ? data.name : data}>
        {data.name ? data.name : data}
      </Option>
    );
  }

  render() {
    const multiple = this.props.type === "multiDropdown" && "multiple";
    return (
      <>
        <Select
          style={this.props.width ? { width: this.props.width } : {}}
          mode={multiple ? "multiple" : {}}
          value={this.props.value}
          disabled={this.props.isDisable}
          showArrow={!this.props.isDisable}
          showSearch
          placeholder="Search here"
          className={`dropdown-search-field-pi-tag  ${this.props.className}`}
          onChange={(value, event) => {
            multiple ?
              this.props.change(event, this.props.id, this.props.name, event.key)
              : this.props.change(value, this.props.id, this.props.name, event.key);
          }}
          onFocus={() => this.props.onFocus && this.props.onFocus()}
          defaultActiveFirstOption={false}
        >
          {this.props.data &&
            this.props.data.length &&
            this.props.data.map(data => {
              return this.getOptionObject(data);
            })}
        </Select>
      </>
    );
  }
}

export default DropDown;
