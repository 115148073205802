import * as AssetTypes from "../Types/Asset.types";
import { GETRequest } from "../../api/request";
import { NO_DATA_API } from "../../constants/messages";

const getEndPoint = (payload) => {
  if (payload.keyword !== null && payload.keyword !== undefined) {
    return `assets?keyword=${payload.keyword}`;
  }
  else if( payload.skip !== undefined && payload.limit !== undefined ) {
    return `assets?skip=${payload.skip}limit=${payload.limit}`;
  }
  return `assets`;
};

export function getAsset(params) {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ASSET_FETCH_PROGRESS });
    GETRequest(getEndPoint(params))
      .then((res) => {
        if (res)
          dispatch({ type: AssetTypes.ASSET_FETCH_SUCCESS, payload: res });
        else throw new Error(NO_DATA_API);
      })
      .catch((err) => {
        dispatch({ type: AssetTypes.ASSET_FETCH_ERROR, payload: err });
      });
  };
}

export function clearAssetState() {
  return function (dispatch) {
    dispatch({ type: AssetTypes.ASSET_CLEAR_STATE });
  };
}
