import React from 'react';
import { Modal, Form, Button, Checkbox } from 'antd';
import { Component } from 'react';
import FormFields from '../../../../common/FormFields/FormFields';
import {
    POP_DATA,
    Editfields
} from '../../Alerts/AlertsDetails/Questionnaire/QuestionnaireModal.constants';
import { connect } from 'react-redux';
import { EditRun as editRun } from '../../../../redux/actions/runTags.action';
import moment from 'moment';
import tagConstant from './tagsConstants';

class EditRun extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            hasNext: false,
            startTime: '',
            endTime: '',
            start: this.props.start,
            end: this.props.stop,
            reason: this.props.ROS,
            renderReason: false,
            renderQuestions: false,
            renderDelete: false,
            tagsData: {},
            renderMore: false,
            times: null,
            detection: null,
            cause: null,
            mechanism: null,
            activity: null,
            errorMessage: '',
            stillRunning: false
        };
    }
    componentDidUpdate(prevProps) {
        const { runDetails } = this.props;
        if(runDetails && runDetails.length && prevProps.runDetails !== runDetails){
            for (let index = 0; index < runDetails.length; index ++){
                if(runDetails[index].abbreviation === "RS-MAP"){
                    this.onSelect(runDetails[index].value)
                }
            }
            const {start, stop, ROS} = this.props;
            this.setState({start: start, end: stop, reason: ROS});
        }
    }
    alerts = () => {
        return (
            <Modal
                visible={this.state.errorMessage.length}
                title="Alert"
                onCancel={() => this.setState({ errorMessage: '' })}
                onOk={() => this.setState({ errorMessage: '' })}
                closable={false}
            >
                <div>{this.state.errorMessage}</div>
            </Modal>
        );
    };
    onSelect = (value) => {
        if (value === 'Yes') {
            this.setState({
                renderMore: true,
            });
        }
        else if (value === 'No') {
            this.setState({
                renderMore: false,
            });
        }
    };

    range = (start, end, type) => {
        const result = [];
        if (type === 'hours') {
            for (let i = start; i < end; i++) {
                result.push(i);
            }
        } else {
            for (let i = start; i <= end; i++) {
                result.push(i);
            }
        }
        return result;
    };

    disabledDate = (current, key) => {
        const { end } = this.state;
        let date = new Date(end)
        if (end)
            return (
                current && current.valueOf() <= date.getTime()
            );
    };

    disabledTime = (time, key) => {
        const { times } = this.props;
        const { startTime } = this.state;

        if (time && time.format('DD') === moment(times).format('DD')) {
            return {
                disabledHours: () =>
                    this.range(0, moment.utc(times).format('HH'), 'hours'),
                disabledMinutes: () =>
                    time && time.format('HH') === moment.utc(times).format('HH')
                        ? this.range(0, moment.utc(times).format('mm'), 'minutes')
                        : '',
            };
        } else if (
            time &&
            time.format('DD') === moment(startTime).format('DD') &&
            key === 'RS-MET'
        ) {
            return {
                disabledHours: () => this.range(0, startTime.format('HH'), 'hours'),
                disabledMinutes: () =>
                    time && time.format('HH') === startTime.format('HH')
                        ? this.range(0, startTime.format('mm'), 'minutes')
                        : '',
            };
        } else if (moment().isSame(time, 'day')) {
            return {
                disabledHours: () => this.range(moment().format('HH'), 24, 'hours'),
                disabledMinutes: () =>
                    time && time.format('HH') === moment().format('HH')
                        ? this.range(moment().format('mm'), 59, 'minutes')
                        : '',
            };
        }
    };

    handleChange = (date, _dateString, key) => {
        key === 'RS-MST'
            ? this.setState({
                startTime: date,
                endTime: null,
            })
            : this.setState({
                endTime: date,
            });
    };

    handleFormDetection = (values) => {
        this.setState({
            detection: POP_DATA[values],
        });
    };

    handleFormMechanism = (values) => {
        this.setState({
            mechanism: POP_DATA[values],
        });
    };

    handleFormFailure = (values) => {
        this.setState({
            cause: POP_DATA[values],
        });
    };

    handleFormActivity = (values) => {
        this.setState({
            activity: POP_DATA[values],
        });
    };
    handleFormChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    };
    questionnaireModalHandleCancel = () => {
        this.setState({
            renderQuestions: false
        });
    };
    questionnaireModalHandleOk = async () => {
        const { autoRecalculate } = this.props.runTagsState;
        var values = this.props.form.getFieldsValue();
        let tagList = this.props.runTagList.data;
        var tags = [];
        let d1 = ""
        let d2 = ""
        for await (const j of tagList) {
            for (const data in values) {
                if (data === 'RS-MST' && j.variableProperty === 'RS-MST'  && values[data] &&values[data]._d) {
                    d1 = new Date(values[data]._d)
                    tags.push({
                        tag: j.piTag,
                        tagId: j._id,
                        value: d1.toISOString(),
                        type: 'String',
                        abbreviation: j.variableProperty,
                    });
                }
                else if (data === 'RS-MET' && j.variableProperty === 'RS-MET' && values[data] && values[data]._d) {
                    d2 = new Date(values[data]._d)
                    tags.push({
                        tag: j.piTag,
                        tagId: j._id,
                        value: d2.toISOString(),
                        type: 'String',
                        abbreviation: j.variableProperty,
                    });
                }
                else if (j.variableProperty === data) {
                    let type =
                        (typeof values[data]).charAt(0).toUpperCase() +
                        (typeof values[data]).slice(1);
                    tags.push({
                        tag: j.piTag,
                        tagId: j._id,
                        value: values[data],
                        type: type,
                        abbreviation: j.variableProperty,
                    });
                }
            }
            if (j.variableProperty === 'RS-ROS' && !this.state.stillRunning) {
                let type =
                    (typeof this.state.reason).charAt(0).toUpperCase() +
                    (typeof this.state.reason).slice(1);
                tags.push({
                    tag: j.piTag,
                    tagId: j._id,
                    value: this.state.reason,
                    type: type,
                    abbreviation: j.variableProperty,
                });
            }
            else if (j.variableProperty === 'RS-UPT') {
                tags.push({
                    tag: j.piTag,
                    tagId: j._id,
                    value: "0",
                    type: "Float",
                    abbreviation: j.variableProperty,
                });
            }
            else if (j.variableProperty === 'RS-AVL') {
                tags.push({
                    tag: j.piTag,
                    tagId: j._id,
                    value: "Not calculated",
                    type: "Float",
                    abbreviation: j.variableProperty,
                });
            }
        }

        for await (const i of tagConstant) {
            for await (const j of tagList) {
                if (i === j.variableProperty) {
                    tags.push({
                        tag: j.piTag,
                        tagId: j._id,
                        value: "0",
                        type: "Float",
                        abbreviation: j.variableProperty,
                    });
                }
            }
        }
        var equipmentId = this.props.equipment;
        const { start, end, stillRunning, reason } = this.state;
        let startAt = new Date(start)
        let endAt = new Date(end)
        let data ={}
        if(stillRunning){
            data = {
            start: startAt.toISOString(),
            equipmentId: equipmentId,
            tags: tags,
            }
        }
        else{
            data = {
                start: startAt.toISOString(),
                end: (stillRunning)?"" : endAt.toISOString(),
                equipmentId: equipmentId,
                ROS: reason,
                tags: tags,
            };
        }
        if(new Date(end) > new Date(d1) || new Date(end) > new Date(d2)){
            return(
                this.setState({
                    errorMessage: 'Machine Maintenance time can not lie in the range of machine run time',
                })
            )
        }
        this.props.form.validateFields((err, values) => {
            if(!err){
                if(new Date(this.props.start)>= new Date(this.state.start)){
                    this.props.editRun(this.props.id, data, autoRecalculate.autoRecalculateMode, this.state.start);
                }
                else{
                    this.props.editRun(this.props.id, data, autoRecalculate.autoRecalculateMode, this.props.start);
                }
            }
        })
    };
    newFormHandleOk = () => {
        const {stillRunning} = this.state
        if(stillRunning){
            this.state.start === '' ? this.setState({ errorMessage: 'Please enter a valid date' }) : this.questionnaireModalHandleOk();
        }
        else if (this.state.start === '' || this.state.end === ''|| this.state.end ===undefined || this.state.end === null ) {
            this.setState({ errorMessage: 'Please enter a valid date' });
        } else if (this.state.start >= this.state.end) {
            this.setState({
                errorMessage: 'Machine stop time can not be greater than start time',
            });
        } else if (this.state.reason === "" || this.state.reason === "------- Select -------") {
            this.setState({
                errorMessage: 'Please select a ROS',
            });
        }
         else {
            this.setState({ renderReason: false, renderQuestions: true });
        }
    };
    runningCondition = () => {
        const {stillRunning} = this.state
        const reasons = [
            '------- Select -------',
            'Preventive Maintenance',
            'Failure/Trip',
            'Machine operation not required',
        ];
        if(!stillRunning){
            return(
                <>
                <label>End Time &nbsp;&nbsp;: </label>
                            <input
                                type="datetime-local"
                                onChange={this.handleFormChange('end')}
                                className={'reasonInput'}
                                value={this.state.end}
                            ></input>
                            <br></br>
                            <br />
                            <label>
                                ROS :
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                            <select
                                onChange={this.handleFormChange('reason')}
                                className={'reasonInput'}
                                value={this.state.reason}
                            >
                                {reasons.map((item) => {
                                    return <option>{item}</option>;
                                })}
                            </select>
                            <br></br>
                        <br/>
                </>
            )
        }
        else{
            return(
                <></>
            )
        }
    }
    newForm = () => {
        const {stillRunning} = this.state
        const {start, stop, ROS} = this.props;
        return (
            <Modal
                visible={this.state.renderReason}
                title="Edit Run"
                okText={(stillRunning)?'Submit':'Proceed'}
                onCancel={() => this.setState({ renderReason: false, start: start, end: stop, reason: ROS })}
                onOk={() => this.newFormHandleOk()}
                closable={false}
            >
                <div className="question">
                    <div className={'runDetails'}>
                        <label>Start Time : </label>
                        <span className={'inputAlign'}>
                            <input
                                type="datetime-local"
                                onChange={this.handleFormChange('start')}
                                className={'reasonInput'}
                                value={this.state.start}
                            ></input>
                        </span>
                        <br></br>
                        <br />
                        {this.runningCondition()}

                        <label>Machine still running: &nbsp;&nbsp;&nbsp;</label>
                        <Checkbox onChange={()=> this.setState({stillRunning: !stillRunning})}></Checkbox>
                    </div>
                </div>
            </Modal>
        );
    };
    QuestionForm = () => {
        let popOverContentDetection = this.state.detection && (
            <span>{this.state.detection}</span>
        );
        let popOverContentMechanism = this.state.mechanism && (
            <span>{this.state.mechanism}</span>
        );
        let popOverContentFailure = this.state.cause && (
            <span>{this.state.cause}</span>
        );
        let popOverContentActivity = this.state.activity && (
            <span>{this.state.activity}</span>
        );
        return (
            <>
                <Modal
                    visible={this.state.renderQuestions}
                    title={'Edit Run'}
                    className="runDetails"
                    closable={false}
					footer={[
                        <Button className='back-btn-top' onClick={()=> this.setState({renderQuestions:false,renderReason: true})}>Back</Button>,
                        <Button  onClick={()=>this.questionnaireModalHandleCancel()}>Cancel</Button>,
                        <Button className="ant-btn ant-btn-primary"  onClick={()=>this.questionnaireModalHandleOk()}>Submit</Button>
                    ]}
                >
					<br></br>
                    Please enter the following information to add runs
          <div className="question">
                        <FormFields
                            formFields={Editfields({
                                reason: this.state.reason,
                                onSelect: this.onSelect, disabledDate: this.disabledDate,
                                renderMore: this.state.renderMore,
                                handleChange: this.handleChange,
                                startTime: this.state.startTime,
                                endTime: this.state.endTime,
                                popOverContentDetection: popOverContentDetection,
                                popOverContentMechanism: popOverContentMechanism,
                                popOverContentFailure: popOverContentFailure,
                                popOverContentActivity: popOverContentActivity,
                                handleFormDetection: this.handleFormDetection,
                                handleFormMechanism: this.handleFormMechanism,
                                handleFormFailure: this.handleFormFailure,
                                handleFormActivity: this.handleFormActivity,
                                runDetails: this.props.runDetails
                            })}
                            form={this.props.form}
                        />
                    </div>
                </Modal>
            </>
        );
    };
    render() {
        return (
            <>
                <Button
                    className="capsule-btn editRun"
                    onClick={() => this.setState({ renderReason: true })}
                >
                    Edit
        </Button>
                {this.newForm()}
                {this.QuestionForm()}
                {this.alerts()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        runTagsState: state.RunTagsReducer,
    };
};
const mapDispatchToProps = (dispatch) => ({
    editRun: (id, data, mode, recalcDate) => dispatch(editRun(id, data, mode, recalcDate)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Form.create({
        name: 'runs',
        onFieldsChange: () => {
            window.dispatchEvent(new Event('add-run'));
        },
    })(EditRun)
);