import { Icon, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { validateNumberNegative } from '../../../../../../libs/validator';
import { VariableSpeedCurvesVariables } from './../../../../../../constants/variables';

export const formFieldsValue = fieldData => {
  return [
    {
      label: 'Drive Type',
      key: 'driveType',
      type: 'dropdownWithAdd',
      data:
        fieldData &&
        fieldData.driveTypeList &&
        fieldData.driveTypeList.map(item => item.type),
      change: value => fieldData.handleChangeFieldData(value, 'driveType'),
      openAddModal: () => fieldData.openAddModal('isDriveType'),
      addMessage: 'Add Drive Type',
      disabled: !fieldData.isSchematicEditable
    },
    {
      label: 'Manufacturer',
      key: 'driveManufacturer',
      type: 'dropdownWithAdd',
      data:
        fieldData &&
        fieldData.driveManufacturerList &&
        fieldData.driveManufacturerList.map(item => item.name),
      change: value =>
        fieldData.handleChangeFieldData(value, 'driveManufacturer'),
      openAddModal: () => fieldData.openAddModal('isDriveManufacturer'),
      addMessage: 'Add Drive Manufacturer',
      disabled: !fieldData.isSchematicEditable
    },
    {
      label: 'Drive Model',
      key: 'driveModel',
      type: 'text',
      change: value => fieldData.handleChangeFieldData(value, 'driveModel'),
      disabled: !fieldData.isSchematicEditable
    },
    {
      label: 'Line Side Converter',
      key: 'lineSideConverter',
      type: 'text',
      change: value => fieldData.handleChangeFieldData(value, 'lineSideConverter'),
      disabled: !fieldData.isSchematicEditable
    },
    {
      label: 'Load Side Inverter',
      key: 'loadSideInverter',
      type: 'text',
      change: value => fieldData.handleChangeFieldData(value, 'loadSideInverter'),
      disabled: !fieldData.isSchematicEditable
    },
    {
      label: 'Speed Control',
      key: 'speedControl',
      type: 'text',
      change: value => fieldData.handleChangeFieldData(value, 'speedControl'),
      disabled: !fieldData.isSchematicEditable
    },
  ];
};

export const tableHeader = tableHeader => {
  return [
    {
      title: VariableSpeedCurvesVariables.speed,
      dataIndex: 'speed',
      render: (value, row, col, disabled, item) => {
        const tempError =
          parseFloat(item.torque) ||
          parseFloat(item.current) ||
          parseFloat(item.powerFactor) ||
          parseFloat(item.power) ||
          parseFloat(item.speed) || 
          parseFloat(item.efficiency) || 
          item.speed === 0 ||
          item.torque === 0 ||
          item.current === 0 ||
          item.powerFactor === 0 ||
          item.power === 0 ||
          item.efficiency === 0;          
          
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="speed"
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                className={tempError  ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.torque,
      dataIndex: 'torque',
      render: (value, row, col, disabled, item) => {
        const tempError =
        parseFloat(item.torque) ||
        parseFloat(item.current) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.power) ||
        parseFloat(item.speed) || 
        parseFloat(item.efficiency) || 
        item.speed === 0 ||
        item.torque === 0 ||
        item.current === 0 ||
        item.powerFactor === 0 ||
        item.power === 0 ||
        item.efficiency === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="torque"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError  ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.current,
      dataIndex: 'current',
      render: (value, row, col, disabled, item) => {
        const tempError =
        parseFloat(item.torque) ||
        parseFloat(item.current) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.power) ||
        parseFloat(item.speed) || 
        parseFloat(item.efficiency) || 
        item.speed === 0 ||
        item.torque === 0 ||
        item.current === 0 ||
        item.powerFactor === 0 ||
        item.power === 0 ||
        item.efficiency === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="current"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    {
      title: VariableSpeedCurvesVariables.power,
      dataIndex: 'power',
      render: (value, row, col, disabled, item) => {
        const tempError =
        parseFloat(item.torque) ||
        parseFloat(item.current) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.power) ||
        parseFloat(item.speed) || 
        parseFloat(item.efficiency) || 
        item.speed === 0 ||
        item.torque === 0 ||
        item.current === 0 ||
        item.powerFactor === 0 ||
        item.power === 0 ||
        item.efficiency === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="power"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError  ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },

    {
      title: VariableSpeedCurvesVariables.powerFactor,
      dataIndex: 'powerFactor',
      render: (value, row, col, disabled, item) => {
        const tempError =
        parseFloat(item.torque) ||
        parseFloat(item.current) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.power) ||
        parseFloat(item.speed) || 
        parseFloat(item.efficiency) || 
        item.speed === 0 ||
        item.torque === 0 ||
        item.current === 0 ||
        item.powerFactor === 0 ||
        item.power === 0 ||
        item.efficiency === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="powerFactor"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError  ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },

    {
      title: VariableSpeedCurvesVariables.efficiency,
      dataIndex: 'efficiency',
      render: (value, row, col, disabled, item) => {
        const tempError =
        parseFloat(item.torque) ||
        parseFloat(item.current) ||
        parseFloat(item.powerFactor) ||
        parseFloat(item.power) ||
        parseFloat(item.speed) || 
        parseFloat(item.efficiency) || 
        item.speed === 0 ||
        item.torque === 0 ||
        item.current === 0 ||
        item.powerFactor === 0 ||
        item.power === 0 ||
        item.efficiency === 0;
        return (
          <div>
            {disabled ? (
              value
            ) : (
              <Input
                value={value}
                autoComplete="off"
                type="number"
                id="efficiency"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                onChange={e => tableHeader.handleChangeTableEditData(e, row)}
                className={tempError  ? 'not-error' : 'error'}
              />
            )}
          </div>
        );
      },
    },
    tableHeader.isSchematicEditable && {
      title: VariableSpeedCurvesVariables.actions,
      dataIndex: 'actions',
      render: (value, row, col, disabled, item) => {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => tableHeader.onEdit(row)}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => tableHeader.onUndo(row)}>
                <Icon type="undo" />
              </button>
            )}
            <button onClick={() => tableHeader.onDelete(row)}>
              <Icon type="delete" />
            </button>
          </div>
        );
      },
    },
  ];
};

export const inputFields = input => {
  if (input.length !== 15) {
    const tempError =
      _.isEmpty(input.speed) ||
      _.isEmpty(input.torque) ||
      _.isEmpty(input.current) ||
      _.isEmpty(input.powerFactor) ||
      _.isEmpty(input.power) ||
      _.isEmpty(input.efficiency) ||
      input.speed === 0 ||
      input.torque === 0 ||
      input.current === 0 ||
      input.powerFactor === 0 ||
      input.power === 0 ||
      input.efficiency === 0 ||
      !input.eventListener;
    return [
      {
        render: () => {
          return (
            <div>
              <Input
                id="speed"
                autoComplete="off"
                type="number"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                value={input.speed}
                onChange={value => input.handleChangeTableData(value)}
                className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <div>
              <Input
                id="torque"
                autoComplete="off"
                type="number"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                value={input.torque}
                onChange={value => input.handleChangeTableData(value)}
                className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <div>
              <Input
                id="current"
                autoComplete="off"
                type="number"
                onKeyDown={e => {
                  validateNumberNegative(e);
                }}
                value={input.current}
                onChange={value => input.handleChangeTableData(value)}
                className={tempError ? 'not-error' : 'error'}
              />
            </div>
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="powerFactor"
              autoComplete="off"
              type="number"
              onKeyDown={e => {
                validateNumberNegative(e);
              }}
              value={input.powerFactor}
              onChange={value => input.handleChangeTableData(value)}
              className={tempError ? 'not-error' : 'error'}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="power"
              autoComplete="off"
              type="number"
              onKeyDown={e => {
                validateNumberNegative(e);
              }}
              value={input.power}
              onChange={value => input.handleChangeTableData(value)}
              className={tempError ? 'not-error' : 'error'}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <Input
              id="efficiency"
              autoComplete="off"
              type="number"
              onKeyDown={e => {
                validateNumberNegative(e);
              }}
              value={input.efficiency}
              onChange={value => input.handleChangeTableData(value)}
              className={tempError ? 'not-error' : 'error'}
            />
          );
        },
      },
      {
        render: () => {
          return (
            <button
              className="btn-default btn-small"
              onClick={() => input.onAdd()}
            >
              Add
            </button>
          );
        },
      },
    ];
  }
};

export const inputUnits = input => {
  return [
    {
      render: () => {
        const tempError = !input.speedUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="speedUnit"
              value={!input.speedUnit ? '' : input.speedUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === 'Starting curve - Speed') {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.torqueUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="torqueUnit"
              value={!input.torqueUnit ? '' : input.torqueUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable ===
                  'Starting curve - Current 100% Voltage'
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.currentUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="currentUnit"
              value={!input.currentUnit ? '' : input.currentUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable ===
                  'Starting curve - Current 80% Voltage'
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },

    {
      render: () => {
        const tempError = !input.powerUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="powerUnit"
              value={!input.powerUnit ? '' : input.powerUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable ===
                  'Starting curve - Torque 100% Voltage'
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.powerFactorUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="powerFactorUnit"
              value={!input.powerFactorUnit ? '' : input.powerFactorUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (getUnits.dataVariable === 'Starting curve - Power Factor') {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        const tempError =
          !input.efficiencyUnit && input.saveListener ? true : false;
        return (
          <div className={`custom-table-select  ${tempError && 'error'}`}>
            <select
              id="efficiencyUnit"
              value={!input.efficiencyUnit ? '' : input.efficiencyUnit}
              onChange={value => input.handleChangeTableData(value, 'unit')}
              disabled={!input.isSchematicEditable}
            >
              <option value={''}>{'Select'}</option>
              {input.unitData.map(getUnits => {
                if (
                  getUnits.dataVariable ===
                  'Starting curve - Torque 80% Voltage'
                ) {
                  return getUnits.units.map(dispUnit => {
                    return <option value={dispUnit}>{dispUnit}</option>;
                  });
                }
                return undefined;
              })}
            </select>
          </div>
        );
      },
    },
    {
      render: () => {
        return <div></div>;
      },
    },
  ];
};
