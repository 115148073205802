import React, { useEffect, useState } from "react";
import { Button, Spin, Input, Layout, Row, Col, Modal, Empty, Switch } from "antd";
import moment from "moment";
import "./RunsDetail.scss";
import { useSelector, useDispatch } from "react-redux";
import { EditFilled, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  getRunTagsData,
  addRunComments,
  deleteRuns,
  clearCommentState,
  clearEditState,
  getLatestRunData,
} from "../../../../redux/actions/runTags.action";
import { detailConstant } from "./DetailsConstants";
import EditRun from "./editRun";
import runKPIData from "./runTagsSplit";
import stillRunningCalcs from "./stillRunningCalculation";
import runInfos from "./DummyData/runInfos";
import fail_main_info from "./DummyData/failure_main";
const { Header, Content } = Layout;

const Details = (
  runTagId,
  runTagNumber,
  equipment,
  startTime,
  endTime,
  InfoComment,
  setHamburger,
  hamburger,
  activeKey,
  selectedRun,
  filterIndicator,
  pageNumber,
  setLoaded,
  bulkDeleteFlag
) => {
  // Redux Connection //
  const data = useSelector((state) => state.RunTagsReducer);
  const dispatch = useDispatch();
  const runData = data.getRunTagsData;
  const runComment = data.runComments;
  const edit = data.editRuns;
  const lastRundata = data.lastRunData;
  const getTotalRunNumber = data.totalRunNumber;
  const recalculateMode = data.autoRecalculate;
  const runTagsInfo = data.getRunTags
  const tourState = data.runTour;

  // State Definition//
  const [runId, setRunId] = useState(runTagId);
  const [runDetails, setRunDetails] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [commentData, setCommentData] = useState("");
  const [KPISection, setKPISection] = useState([]);
  const [Failure_Maintenance, setFailure_Maintenance] = useState([]);
  const [commentHanlder, setHandeler] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ROS, setROS] = useState("");
  const [lastRun, setLastRun] = useState([]);
  const [MART, setMART] = useState("");
  const [MTBR, setMTBR] = useState("");
  const [liveMode, setLiveMode] = useState(true);

  const handleInputChange = (e) => {
    setCommentData(e.target.value);
  };

  useEffect(() => {
    if(tourState.startTour){
      setRunDetails([])
      setKPISection([])
      setFailure_Maintenance([])
      setKPISection(runInfos)
      setFailure_Maintenance(fail_main_info)
      setIsLoaded(true)
      setROS("Machine operation not required")
    }
  },[tourState.startTour])

  // ComponentDidMount and ComponentDidUpdate//
  useEffect(() => {
    if (activeKey === "4" && !tourState.startTour) {
      if ((!runData.isSuccess || runTagId !== runId) && runTagId !== "") {
        setShowDeleteForm(false);
        dispatch(getRunTagsData(runTagId));
        setRunId(runTagId);
        setRunDetails([]);
        setIsLoaded(false);
        setCommentData("");
        setKPISection([]);
        setFailure_Maintenance([]);
        setROS("");
      } else if (runData.isSuccess && !isLoaded) {
        setRunDetails(
          runData.data &&
          runData.data.map((item, key) => {
            if (
              item.abbreviation === "RS-MST" ||
              item.abbreviation === "RS-MET"
            ) {
              item.abbreviation = detailConstant[item.abbreviation];
              item.value = moment(item.value).format("LLLL");
              return item;
            }
            else if (item.abbreviation === "RS-MTTF" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-METBF" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-MEA" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-OA" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-MART" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-MART") {
              setMART(item.value);
            }
            else if (item.abbreviation === "RS-MTBR" && item.value === "0") {
              item.value = "Not applicable";
            }
            else if (item.abbreviation === "RS-MTBR") {
              setMTBR(item.value);
            }
            else if (item.abbreviation === "RS-ROS") {
              setROS(item.value);
            }
            item.abbreviation = detailConstant[item.abbreviation];
            item.value = parseFloat(item.value)
              ? parseFloat(item.value).toFixed(3)
              : item.value;
            if (runData.data.length === key + 1) {
              setIsLoaded(true);
            }
            return item;
          })
        );
        setIsLoaded(true)
        setLoaded(true)
      }
      else if (runData.isError) {
        setRunDetails([]);
        setIsLoaded(true);
        setLoaded(true)
      }
      if ((endTime === undefined || endTime === null) && equipment !== undefined) {
        dispatch(getLatestRunData(equipment))
      }
      if (runTagId === "") {
        setIsLoaded(true);
      }
      if (InfoComment !== undefined) {
        setCommentData(InfoComment);
      }
      if (runComment.isSuccess) {
        dispatch(clearCommentState());
        setHandeler(false);
      }
      if (edit.isSuccess) {
        dispatch(clearEditState());
      }
      if (edit.isError) {
        setErrorMessage(edit.message);
        dispatch(clearEditState());
      }
    }
  }, [runTagId, runData.isSuccess, equipment, runComment.data, edit, isLoaded, endTime, activeKey, tourState.startTour]); // eslint-disable-line

  useEffect(() => {
    if (runDetails.length && isLoaded && endTime !== undefined && endTime !== null) {
      KPISectionSplit();
    }
  }, [runDetails, isLoaded, runTagId, liveMode, selectedRun])// eslint-disable-line

  useEffect(() => {
    if (InfoComment !== undefined && InfoComment !== null) {
      setCommentData(InfoComment);
    }
  }, [InfoComment])// eslint-disable-line

  useEffect(() => {
    if (runTagId !== runId) {
      dispatch(getRunTagsData(runTagId));
      setRunId(runTagId);
      setLastRun([]);
      setRunDetails([]);
    }
    if (runTagId !== runId) {
      dispatch(getLatestRunData(equipment));
    }
  }, [runTagId])// eslint-disable-line

  useEffect(() => {
    if (lastRundata.isSuccess) {
      setLastRun(lastRundata.data);
    }
  }, [lastRundata.isSuccess])// eslint-disable-line

  // Auto refresh feature ( If the equipment has only one run) 
  useEffect(() => {
    if (activeKey === "4" && runData.isSuccess) {
      if (!runData.data.length && !lastRun.length && endTime && getTotalRunNumber.data > 1) {
        dispatch(getLatestRunData(equipment));
      }
      if (lastRun.length && endTime && selectedRun === 0 && liveMode && filterIndicator === false && pageNumber === 1 && tourState.startTour === false) {
        const interval = setInterval(() => {
          setKPISection([]);
          setFailure_Maintenance([]);
          stillRunningData();
        }, 1000);
        return () => clearInterval(interval);
      }
      //If equipment has only one run
      else if (getTotalRunNumber.data === 1 && liveMode && endTime && pageNumber === 1 && tourState.startTour === false) {
        const interval = setInterval(() => {
          setKPISection([]);
          setFailure_Maintenance([]);
          stillRunningData();
        }, 1000);
        return () => clearInterval(interval);
      }
      //for latest complete run//
      else if (ROS !== "" && selectedRun === 0 && getTotalRunNumber.data !== 1 && liveMode && pageNumber === 1 && tourState.startTour === false) {
        const interval = setInterval(() => {
          setKPISection([]);
          stillRunningData();
          setFailure_Maintenance([]);
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [runData.isSuccess, lastRun, getTotalRunNumber, selectedRun, ROS, MTBR, MART, liveMode, tourState])// eslint-disable-line

  // Auto refresh for still running machines
  useEffect(() => {
    if (isLoaded && (endTime === undefined || endTime === null)) {
      const interval = setInterval(() => {
        setKPISection([]);
        stillRunningData([]);
        setFailure_Maintenance([]);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [lastRun]);// eslint-disable-line


  const alerts = () => {
    return (
      <Modal
        visible={errorMessage.length}
        title="Alert"
        onCancel={() => setErrorMessage("")}
        onOk={() => setErrorMessage("")}
        closable={false}
      >
        <div>{errorMessage}</div>
      </Modal>
    );
  };
  const KPISectionSplit = async () => {
    if (runDetails[0] !== undefined) {
      for (let i = 0; i < runKPIData.runKPIconst.length; i++) {
        for (let j = 0; j < runDetails.length; j++) {
          if (runKPIData.runKPIconst[i] === runDetails[j].abbreviation) {
            setKPISection(oldArray => [...oldArray, runDetails[j]]);
            //KPISection.push(runDetails[j]);
          }
        }
      }
      for (let i = 0; i < runKPIData.Failure_Maintenance.length; i++) {
        for (let j = 0; j < runDetails.length; j++) {
          if (
            runKPIData.Failure_Maintenance[i] === runDetails[j].abbreviation
          ) {
            setFailure_Maintenance(oldArray => [...oldArray, runDetails[j]]);
            //Failure_Maintenance.push(runDetails[j]);
          }
        }
      }
    } else {
    }
  };

  const stillRunningData = async () => {
    let modifiedData = [];
    //function call to get data of still running machines
    modifiedData = await stillRunningCalcs(lastRun, startTime, endTime, ROS, MART, MTBR);
    if (modifiedData[0] !== undefined) {
      for await (let kpiData of runKPIData.runKPIconst) {
        for await (let data of modifiedData) {
          if (kpiData === data.abbreviation) {
            setKPISection(oldArray => [...oldArray, data]);
          }
        }
      }
    }
    for (let i = 0; i < runKPIData.Failure_Maintenance.length; i++) {
      for (let j = 0; j < runDetails.length; j++) {
        if (
          runKPIData.Failure_Maintenance[i] === runDetails[j].abbreviation
        ) {
          setFailure_Maintenance(oldArray => [...oldArray, runDetails[j]]);
          //Failure_Maintenance.push(runDetails[j]);
        }
      }
    }
  };
  const commentSection = () => {
    if (InfoComment === undefined || InfoComment === "") {
      return (
        <div className="comment">
          <br />
          <Input.TextArea
            className="txt-area"
            name="runComment"
            onChange={handleInputChange}
          />
          <br />
          <br />
          <Button
            className="capsule-btn"
            onClick={() =>
              dispatch(addRunComments(runTagId, commentData))
            }
          >
            Save
          </Button>
          <br />
        </div>
      );
    }
    if (InfoComment && commentHanlder === false) {
      return (
        <div className="comment-details">
          {commentData}
          <div className="comment">
            <Button className="capsule-btn" onClick={() => setHandeler(true)}>
              Edit
            </Button>
          </div>
        </div>
      );
    }
    if (commentHanlder === true) {
      return (
        <div className="comment">
          <br />
          <Input.TextArea
            className="txt-area"
            name="runComment"
            onChange={handleInputChange}
            value={commentData}
          />
          <br />
          <br />
          <Button
            className="capsule-btn"
            onClick={() =>
              dispatch(addRunComments(runTagId, commentData))
            }
          >
            Save
          </Button>
          <Button className="capsule-btn" onClick={() => {
            setHandeler(false); setCommentData(InfoComment)
          }}
          >
            Cancel
          </Button>
          <br />
        </div>
      );
    }
  };

  const confirmDelete = () => {
    if (showDeleteForm === true) {
      return (
        <Modal
          visible={showDeleteForm}
          title="Alert"
          okText="Delete"
          onCancel={() => setShowDeleteForm(false)}
          onOk={() => { dispatch(deleteRuns(runId, startTime, equipment, recalculateMode.autoRecalculateMode)); setShowDeleteForm(false); setIsLoaded(true); }}
          closable={false}
        >
          <div className="question">Are you sure you want to delete this Run ?</div>
        </Modal>
      );
    }
  };
  if (isLoaded && runTagId === "") {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 50
        }}
        description={
          <span>
            <b>No Runs Here</b>
          </span>
        }
      >
      </Empty>
    );
  }
  if (isLoaded) {
    return (
      <Layout className="site-layout" style={{
        background: "white",
      }}>
        <Header
          className="site-layout header-design"
          style={{ padding: 0, background: "white" }}
        >
          <Row>
            <Col span={12}>
              <span className="heading">
                <button onClick={() => setHamburger(!hamburger)} className="collapse-btn">
                  {React.createElement(hamburger ? MenuUnfoldOutlined : MenuFoldOutlined, { className: "hamburger-size" })}
                </button>
                Run Details
              </span>
            </Col>
            <Col span={12} className="button-align">
              <span>
                <EditRun id={runId} start={(startTime === undefined) ? startTime : moment(startTime).toISOString(true).slice(0, -8)}
                  stop={(endTime === undefined || endTime === null || endTime === "") ? endTime : moment(endTime).toISOString(true).slice(0, -8)} ROS={ROS}
                  equipment={equipment} runDetails={runData.data} runTagList={runTagsInfo}
                />
                <Button
                  className="capsule-btn deleteRun"
                  onClick={() => setShowDeleteForm(true)}
                  disabled = {bulkDeleteFlag}
                >
                  Delete
                </Button>
                {confirmDelete()}
              </span>
            </Col>
          </Row>
        </Header>
        <Content style={{ overflow: "initial" }} className="run-details">
          <div style={{ padding: 24 }}>
            <div className="deatil-content">
              <div className="detail-heading">Run Summary

                <span className="liveMode">{(selectedRun === 0 && ROS !== "" && filterIndicator === false && pageNumber === 1) ?
                  <>
                    Live Mode &nbsp;&nbsp;
                    <Switch
                      onChange={() => { setLiveMode(!liveMode); setKPISection([]); setFailure_Maintenance([]); }}
                      checked={liveMode}
                    >
                      Live Mode
                    </Switch>
                  </>
                  : ""}</span>
              </div>
              <Row>
                <Col span={12} className="run-data">
                  Start Time : <span className="run-values">{moment(startTime).format("ddd, MMM Do YYYY, h:mm:ss A")}</span>
                </Col>
                <Col span={12} className="run-data">
                  Run Number :{" "}
                  <span className="run-values">{(runTagNumber === 0) ? "**" : runTagNumber}</span>
                </Col>
                <Col span={12} className="run-data">
                  End Time : <span className="run-values">{(endTime === undefined || endTime === null) ? "Machine still running" : moment(endTime).format("ddd, MMM Do YYYY, h:mm:ss A")}</span>
                </Col>
                <Col span={12} className="run-data">
                  ROS : <span className="run-values">{(ROS) ? ROS : (endTime === undefined || endTime === null) ? "" : "Unknown"}</span>
                </Col>
              </Row>
            </div>
            <br />
            <div className="deatil-content KPIDetails">
              <div className="detail-heading">Run KPIs</div>
              <Row>
                {KPISection.map(
                  (item, index) =>
                    item && (
                      <Col span={8} key={index}>
                        <div key={index} className="run-data">
                          {item && item.abbreviation} :
                          <br />
                          <span className="run-values">
                            {item && item.value}
                          </span>
                        </div>
                      </Col>
                    )
                )}
              </Row>
            </div>
            <br />
            <div className="deatil-content fail-main">
              <div className="detail-heading">Failure / Maintenance Info</div>
              <Row>
                {Failure_Maintenance.map(
                  (item, index) =>
                    item && (
                      <Col span={8} key={index}>
                        <div key={index} className="run-data">
                          {item && item.abbreviation} :
                          <br />
                          <span className="run-values">
                            {item && item.value}
                          </span>
                        </div>
                      </Col>
                    )
                )}
                <br />
              </Row>
            </div>
            <br />
            <div className="deatil-content comment-section">
              <div className="detail-heading">
                <span>Comments</span>
                &nbsp;&nbsp;
                <EditFilled />
              </div>
              {commentSection()}
              {alerts()}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
  return <Spin style={{ paddingTop: "35vh" }} />;
};

export default Details;