const Tags = ["RS-UPT", 'RS-DWT', 'RS-NOS','RS-TDT', 'RS-TUT', 'RS-MUT', 'RS-MDT', 'RS-TRCT', 'RS-OA',"RS-PDT", 
"RS-UDT", "RS-AT", "RS-NOF", "RS-TAT","RS-FR",  "RS-AVL","RS-MEA", "RS-METBF", "RS-MTBR", "RS-MART", "RS-MTTF"] 

let start = ""
let end = ""
let upTime = 0
let downTime = 0
let NOS = 0
let TDT = 0
let TUT = 0
let MUT = 0
let MDT = 0
let OA = 0

const calculateKPIs = async(latestRun, previousRun, lastRunData, abbreviation) => {
    switch(abbreviation){
      case Tags[0]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = (latestRun[0])?(latestRun[0].end)?new Date(latestRun[0].end):"":0
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        return parseFloat(upTime).toFixed(3)
      case Tags[1]:
        end =(latestRun[0])?(latestRun[0].end)? new Date(latestRun[0].end): "":0
        downTime = (latestRun[0]&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        return parseFloat(downTime).toFixed(3)
      case Tags[2]:
        NOS =((latestRun[0])&&previousRun[0].plantValue)?previousRun[0].plantValue +1:((latestRun[0])&&latestRun[0].start)?1:0
        return NOS
      case Tags[3]:
        end = ((latestRun[0])&&latestRun[0].end)? new Date(latestRun[0].end): ""
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        TDT = (previousRun[0].value)?parseFloat(previousRun[0].value)  + parseFloat(downTime): parseFloat(downTime)
        return TDT.toFixed(3)
      case Tags[4]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        TUT = (previousRun[0].value)?parseFloat(previousRun[0].value)  + parseFloat(upTime): parseFloat(upTime)
        return TUT.toFixed(3)
      case Tags[5]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3)
        TUT =(previousRun[0].value)?parseFloat(previousRun[0].value)*(parseFloat(latestRun[0].runNumber)-1): upTime
        MUT =(latestRun[0])?(previousRun[0].value)?((parseFloat(TUT)+parseFloat(upTime))/parseFloat(latestRun[0].runNumber)).toFixed(3): TUT:0
        return parseFloat(MUT).toFixed(3)
      case Tags[6]:
        end = ((latestRun[0])&&latestRun[0].end)? new Date(latestRun[0].end): ""
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        TDT = (previousRun[0].value)?parseFloat(previousRun[0].value)*(parseFloat(latestRun[0].runNumber)-1): downTime
        MDT = (previousRun[0].value)?((parseFloat(TDT)+parseFloat(downTime))/parseFloat(latestRun[0].runNumber)).toFixed(3): TDT
        return parseFloat(MDT).toFixed(3)
      case Tags[7]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime =(latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let TRCT = (latestRun[0])?(previousRun[0].value)?parseFloat(previousRun[0].value) + parseFloat(upTime) + parseFloat(downTime):parseFloat(upTime)+parseFloat(downTime):0
        return TRCT.toFixed(3)
      case Tags[8]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)? new Date(latestRun[0].end): ""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        if (lastRunData && lastRunData.length){
          for await (let data of lastRunData){
            if(data.abbreviation === 'RS-TUT'){
              TUT = data.value
            }
            else if(data.abbreviation === 'RS-TDT'){
              TDT = data.value
            }
          }
          TUT = parseFloat(TUT) + parseFloat(upTime)
          MUT = parseFloat(TUT) / parseFloat(latestRun[0].runNumber)
          TDT  = parseFloat(TDT) + parseFloat(downTime)
          MDT = parseFloat(TDT) / parseFloat(latestRun[0].runNumber)
        }
        OA =(latestRun[0])?parseFloat(MUT)/ (parseFloat(MUT)+ parseFloat(MDT)):0
        return OA.toFixed(3)
      case Tags[9]:
        end = ((latestRun[0])&&latestRun[0].end)? new Date(latestRun[0].end): ""
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let PDT = ((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?0.000:downTime
        return parseFloat(PDT).toFixed(3)
      case Tags[10]:
        end = ((latestRun[0])&&latestRun[0].end)? new Date(latestRun[0].end): ""
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let UDT = ((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?downTime:0.000
        return parseFloat(UDT).toFixed(3)
      case Tags[11]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let AT = ((latestRun[0])&&latestRun[0].ROS === "Machine operation not required")?parseFloat(upTime) + parseFloat(downTime):parseFloat(upTime)  
        return AT.toFixed(3)
      case Tags[12]:
        let noOfFailure = (latestRun[0])?((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?(previousRun[0].value)?parseInt(previousRun[0].value)+1:1:(previousRun[0].value)?(previousRun[0].value):0:0
        return noOfFailure
      case Tags[13]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let TAT = ((latestRun[0])&&(latestRun[0].ROS === "Machine operation not required" ||latestRun[0].ROS === "Preventive Maintenance"))?parseFloat(previousRun[0].value)? parseFloat(previousRun[0].value)+parseFloat(upTime) + parseFloat(downTime):
        parseFloat(upTime) + parseFloat(downTime):(previousRun[0].value)?parseFloat(previousRun[0].value) + parseFloat(upTime):parseFloat(upTime)
        return TAT.toFixed(3)
      case Tags[14]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        TUT = upTime
        let failureNumber = 0
        if (lastRunData && lastRunData.length){
          for await (let data of lastRunData){
            if(data.abbreviation === 'RS-TUT'){
              TUT = (data.value)?parseFloat(data.value)+parseFloat(upTime):parseFloat(upTime)
            }
            else if(data.abbreviation === 'RS-NOF'){
              failureNumber = (latestRun[0])?((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?(data.value)?parseInt(data.value)+1:1:(data.value)?(data.value):0:0
            }
          }
        }
        let FR = (failureNumber === 0)?"Not applicable":parseFloat(1/(parseFloat(TUT/failureNumber))).toFixed(3)
        return FR
      case Tags[16]:
        start =(latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime =(latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let totalAvailableTime = 0.000
        let totalRunCycle = 0.000
        let meanOverallAvail = 0.000
        if (lastRunData && lastRunData.length){
          for await (let data of lastRunData){
            if(data.abbreviation === 'RS-TAT'){
              totalAvailableTime = data.value
            }
            else if(data.abbreviation === 'RS-TRCT'){
              totalRunCycle = data.value
            }
          }
        }
        totalAvailableTime = ((latestRun[0])&&(latestRun[0].ROS === "Machine operation not required"|| latestRun[0].ROS === "Preventive Maintenance"))? parseFloat(totalAvailableTime)+parseFloat(upTime) + parseFloat(downTime)
        :parseFloat(totalAvailableTime) + parseFloat(upTime)
        totalRunCycle = parseFloat(totalRunCycle) + parseFloat(upTime) + parseFloat(downTime)
        meanOverallAvail = (latestRun[0])?totalAvailableTime/totalRunCycle:0
        return meanOverallAvail.toFixed(3)
      case Tags[17]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime =(latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        downTime = ((latestRun[0])&&latestRun[0].end)?parseFloat((new Date() - end)/(1000*3600)).toFixed(3) : 0.000
        let failureNum = 0
        let TotalRunCycle = 0
        if (lastRunData && lastRunData.length){
          for await (let data of lastRunData){
            if(data.abbreviation === 'RS-NOF'){
              failureNum = (latestRun[0])?((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?(data.value)?parseInt(data.value)+1:1:(data.value)?(data.value):0:0
            }
            if(data.abbreviation === 'RS-TRCT'){
              TotalRunCycle = (latestRun[0])?(data.value)?parseFloat(data.value) + parseFloat(upTime) + parseFloat(downTime):parseFloat(upTime)+parseFloat(downTime):0
            }
          }
        }
        let METBF = (parseInt(failureNum) === 0 || parseInt(TotalRunCycle) === 0)?"Not applicable": parseFloat(TotalRunCycle/failureNum).toFixed(3)
        return METBF
      case Tags[20]:
        start = (latestRun[0])?new Date(latestRun[0].start):""
        end = ((latestRun[0])&&latestRun[0].end)?new Date(latestRun[0].end):""
        upTime = (latestRun[0])?(latestRun[0].end)?parseFloat((end - start)/(1000*3600)).toFixed(3):parseFloat((new Date() - start)/(1000*3600)).toFixed(3):0
        TUT = upTime
        let failureNo = 0
        if (lastRunData && lastRunData.length){
          for await (let data of lastRunData){
            if(data.abbreviation === 'RS-TUT'){
              TUT = (data.value)?parseFloat(data.value)+parseFloat(upTime):parseFloat(upTime)
            }
            else if(data.abbreviation === 'RS-NOF'){
              failureNo = (latestRun[0])?((latestRun[0])&&latestRun[0].ROS === "Failure/Trip")?(data.value)?parseInt(data.value)+1:1:(data.value)?(data.value):0:0
            }
          }
        }
        let MTTF = (parseInt(failureNo) === 0)?"Not applicable":parseFloat(parseFloat(TUT)/parseInt(failureNo)).toFixed(3)
        return MTTF
      default:
        if(latestRun.length > 1 && latestRun[1].length){
          for await(let values of latestRun[1]){
              if(values.abbreviation === previousRun[0].abbreviation){
                return parseFloat(values.value).toFixed(3)
              }
          }
          return "No Data"
        }
        else if(latestRun.length === 0){
          return 0.000
        }
        return previousRun[0].plantValue
    }
}

export default calculateKPIs