import {
  DEPLOYED_COMPONENT_FETCH_SUCCESS,
  DEPLOYED_COMPONENT_FETCH_PROGRESS,
  DEPLOYED_COMPONENT_FETCH_ERROR,
  DEPLOYED_COMPONENT_CLEAR_STATE,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEPLOYED_COMPONENT_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case DEPLOYED_COMPONENT_FETCH_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case DEPLOYED_COMPONENT_FETCH_SUCCESS:
      const deployedComponentList = action.payload.data.map(
        (deployedComponent) => {
          const { _id, name } = deployedComponent;
          const type = deployedComponent.assetId.type.name;
          return { _id, name, type };
        }
      );
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: deployedComponentList,
      };
    case DEPLOYED_COMPONENT_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
};
