import React from 'react';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { PrintFrame } from './PrintFrame';
import './AlertDetails.scss';
import { Button } from '@mui/material';

class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      alertDetails: null,
      ref: null,
    };
  }

  componentDidMount() {
    let tempRef = this.componentRef;
    this.setState({ ref: tempRef });
  }

  render() {
    const {
      alertsData,
      plotData,
      assignees,
      discussion,
      userRole,
      descriptionText,
      info,
      kpiPlotData,
      kpiData,
      isSmall,
      styling
    } = this.props;
    const tempObj = {
      alertsData: alertsData,
      plotData: plotData,
      assignees: assignees,
      discussion: discussion,
      userRole: userRole,
      descriptionText: descriptionText,
    };
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <span className="print-btn-span">
              <Button variant='contained'
              style={isSmall ?styling:{}}
                type="button"
                className="selected_tab">
                <PrinterOutlined className="print-logo" />
              </Button>
            </span>
          )}
          content={() => this.state.ref}
          documentTitle={
            alertsData && alertsData.fwdAlertId
              ? alertsData.fwdAlertId
              : 'Alert Details'
          }
        />
        <div style={{ display: 'none' }}>
          <PrintFrame
            ref={(el) => this.setState({ ref: el })}
            alertsData={tempObj}
            info={info}
            kpiPlotData={kpiPlotData}
            kpiData={kpiData}
          />
        </div>
      </>
    );
  }
}

export default ComponentToPrint;
