import socketIOClient from "socket.io-client";
import _ from "lodash";
import Notification, { Types } from "../common/Notification/Notification";
import { SOCKET_MSG } from "../constants/messages";

export let socket = null;

/** socket event connetion when after receiving token */
export const Connection = (token) => {
  if (_.isNil(socket) && !_.isNil(token)) {
    const nameSpace = process.env.REACT_APP_NAME_SPACE;
    socket = socketIOClient(
      `${nameSpace}?authToken=${token}&apiKey=${process.env.REACT_APP_API_KEY}`,
      { reconnection: true, transports: ["websocket"] }
    );
    socket.on("ApiError", (event) => {
      Notification.show(Types.ERROR, SOCKET_MSG.ERROR);
    });
  }
};
