import { Button, Empty, Icon, Input, Table, Tabs } from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notification, { Types } from '../../common/Notification/Notification';
import { AssetMessages, NO_INTERNET } from '../../constants/messages';
import { MaskStyle } from '../../constants/react-style';
import * as Routes from '../../constants/routes';
import { BASE_API_PATH } from '../../constants/variables';
import * as Device from '../../libs/device';
import NotConnected from '../../libs/NotConnected';
import { clearAssetState, getAsset } from '../../redux/actions/asset.action';
import './AssetLibrary.scss';
import EquipmentListing from './EquipmentListing/EquipmentListing';

const { TabPane } = Tabs;
const { Search } = Input;

const columns = [
  {
    title: AssetMessages.ID_TITLE,
    dataIndex: 'assetId',
    key: 'assetId',
  },
  {
    title: AssetMessages.COMPONENT_NAME_TITLE,
    dataIndex: 'name',
    key: 'name',
    render: (name) => name || AssetMessages.NOT_AVAILABLE_LABEL,
  },
  {
    title: AssetMessages.COMPONENT_TYPE_TITLE,
    dataIndex: 'type',
    key: 'type',
    render: (type) => (type ? type.name : AssetMessages.NOT_AVAILABLE_LABEL),
  },
  {
    title: AssetMessages.COMPONENT_SYMBOL_TITLE,
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    render: (imgUrl) =>
      imgUrl ? (
        <img
          alt=""
          src={`${BASE_API_PATH}/${imgUrl}`}
          className="symbolImage"
        />
      ) : (
        AssetMessages.NOT_AVAILABLE_LABEL
      ),
  },
];

const columnsMobile = [
  {
    title: AssetMessages.COMPONENT_NAME_TITLE,
    dataIndex: 'name',
    key: 'name',
    render: (name) => name || AssetMessages.NOT_AVAILABLE_LABEL,
  },
];

class AssetLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      screenWidth: 0,
      network: true,
      type: this.props.location.state ? this.props.location.state.type : null,
      changeType: null,
      addEquipment: null,
      searchEquipment: null,
      propss: true,
      showText: null,
      skip: 0
    };
  }

  componentDidMount() {
    const { skip } = this.state;
    const params = {
      skip: skip,
      limit: AssetMessages.COMPONENT_PAGE_COUNT,
      keyword: null,
    };
    this.getAssetList(params);

    window.addEventListener('resize', this.resize.bind(this));
    this.props.location.state
      ? this.setState({
          type: this.props.location.state.type
            ? this.props.location.state.type
            : '1',
        })
      : this.setState({
          type: '1',
        });
  }

  componentDidUpdate() {
    const {
      isError,
      isSuccess,
      errorCode,
      message,
    } = this.props.assetState.apiState;
    if (isError) {
      this.props.clearState();
      if (
        errorCode === 'INTERNAL' &&
        _.includes(message, 'SyntaxError: Invalid regular expression')
      ) {
        Notification.show(Types.Error, AssetMessages.INVALID_SEARCH);
      } else Notification.show(Types.Error, AssetMessages[errorCode]);
      this.setState({ searchEquipment: null });
    }

    if (isSuccess) {
      this.props.clearState();
      this.setState({ searchEquipment: null });
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getAssetList(params) {
    const { getAsset } = this.props;
    if (this.handleNetwork()) {
      getAsset(params);
    }
  }

  type = (type) => {
    this.setState(
      {
        type,
        showText: null,
      },
      () => {
        this.props.history.push(Routes.assetLibrary, { type });
      }
    );
  };

  resize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  addEquipment = () => {
    this.props.history.push(Routes.addEquipment, { type: this.state.type });
  };

  handleSearchChange = (keyword, type) => {
    const { skip } = this.state;
    if (_.isEmpty(keyword.target.value)) {
      this.setState(
        {
          searchEquipment: null,
          showText: null,
        },
        () => {
          if (type === '1') {
            this.getAssetList({
              keyword: null,
              skip: skip,
              limit: AssetMessages.COMPONENT_PAGE_COUNT,
            });
          }
        }
      );
    }
    if (keyword.target.value) {
      this.setState({ showText: keyword.target.value });
    }
  };

  handleSearch = (keyword, type) => {
    if (type === '1') {
      this.getAssetList({ keyword });
    }
    this.setState({
      searchEquipment: keyword,
    });
  };

  handleChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const { pageSize } = pagination;
    const skip = (current - 1) * pageSize;
    const parameters = { skip, limit: pageSize };
    this.setState({ skip: skip });
    this.getAssetList(parameters); // reducer call for retrieving data
  };

  CustomExpandIcon(props) {
    if (props.expanded) {
      return (
        <Icon
          type="down"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
    return (
      <Icon type="right" onClick={(e) => props.onExpand(props.record, e)} />
    );
  }

  renderRecord(record) {
    return (
      <div>
        <div className="flex">
          <div className="heading">ID</div>
          <div className="content">{record.assetId}</div>
        </div>
        <div className="flex">
          <div className="heading">Type</div>
          <div className="content"> {record.type.name}</div>
        </div>
        <div className="flex">
          <div className="heading">Symbol</div>
          <div className="content">
            <img
              src={`${BASE_API_PATH}/${record.imgUrl}`}
              className="symbolImage"
              alt="Symbol"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { type, searchEquipment, showText } = this.state;
    // const { loading } = this.props.assetState;
    const dataSource = this.props.assetState.apiState.data;
    return (
      <div className="AssetLibrary">
        {/* {loading ? <Loader /> : null} */}
        <div className="content-head" style={MaskStyle}>
          <div className="heading">{AssetMessages.HEADING_ASSET}</div>
          <div className="page-action page-action-tab">
            <div>
              <Search
                placeholder={AssetMessages.SEARCH_PLACEHOLDER}
                style={{ width: 200 }}
                className="search"
                onChange={(value) => this.handleSearchChange(value, type)}
                onSearch={(value) => this.handleSearch(value, type)}
                value={showText}
                allowClear
              />
            </div>
            {type === '2' && (
              <Button onClick={this.addEquipment}>Add Equipment</Button>
            )}
          </div>
        </div>
        <div className="content-body">
          <Tabs
            type={Device.mobile() ? '' : 'card'}
            defaultActiveKey={this.state.type}
            onChange={this.type}
          >
            <TabPane tab="Component" key="1">
              {!this.state.network ? (
                <NotConnected />
              ) : dataSource ? (
                <Table
                  columns={Device.mobile() ? columnsMobile : columns}
                  expandedRowRender={
                    Device.mobile()
                      ? (record) => this.renderRecord(record)
                      : false
                  }
                  dataSource={dataSource.items ? dataSource.items : null}
                  expandIcon={Device.mobile() ? this.CustomExpandIcon : false}
                  onChange={this.handleChange}
                  pagination={{
                    pageSize: AssetMessages.COMPONENT_PAGE_COUNT,
                    total: dataSource.total_count,
                    hideOnSinglePage: true,
                  }}
                />
              ) : (
                <Empty />
              )}
            </TabPane>
            <TabPane tab="Equipment" key="2">
              <EquipmentListing
                searchEquipment={searchEquipment}
                type={this.state.type}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assetState: state.AssetReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAsset: (params) => dispatch(getAsset(params)),
  clearState: () => dispatch(clearAssetState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetLibrary));
