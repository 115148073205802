import * as Types from "../Types/settings.types";
import { POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

const getEndPoints = (type, id) => {
  if (type === "man") {
    return `manufacturers/${id}`;
  }
  return `types/${id}`;
};

export const addTypes = (id, payload, type) => (dispatch) => {
  dispatch({ type: Types.ADD_TYPE_PROGRESS });
  PUTRequest(getEndPoints(type, id), payload)
    .then((res) =>
      dispatch({ type: Types.ADD_TYPE_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: Types.ADD_TYPE_ERROR, payload: err }));
};

export const getDrivetypes = (keyword) => (dispatch) => {
  dispatch({ type: Types.GET_DRIVE_TYPE_PROGRESS });
  POSTRequest(
    `${endPoints.drivetypes}/${endPoints.filter}${
      keyword ? `?keyword=${keyword}` : ""
    }`
  )
    .then((res) =>
      dispatch({ type: Types.GET_DRIVE_TYPE_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_DRIVE_TYPE_FAILURE, payload: err })
    );
};

export const clearDriveTypeState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_DRIVE_TYPE_SUCCESS_STATE });
};