import { Collapse, Empty, Dropdown, Menu, Form, Modal, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import TableCard from '../../../../common/Cards/TableCard';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import cardTypeMapper from '../../../../constants/cardTypes';
import {
  addNewCardMessages,
  ERROR_HANDLE_TEXT,
  SOCKET_MSG,
  ERROR_MESSAGES,
} from '../../../../constants/messages';
import {
  clearKPIState,
  getKPIByLevelId,
  getKPIByPanelType,
  clearKPIByPanelState,
} from '../../../../redux/actions/addNewCard.action';
// import {
//   clearCommonPlots,
//   fetchCommonPlots,
// } from '../../../../redux/actions/commonPlots.action';
import { getPlantTime } from '../../../../redux/actions/plantTime.action';
import {
  clearPlotState,
  getParticularPlotDataByKPIId,
  getPlotByKPIId,
} from '../../../../redux/actions/plots.action';
import {
  clearAddNewCardState,
  clearDeleteKpiState,
  clearUpdataKpiState,
  deleteKpiById,
  onUpdateKpi,
  updateKPIReducer,
  clearPlotsState,
} from '../../../../redux/AddNewCard/addNewCard.action';
import {
  NewKPISocketListner,
  RemoveListeningNewKpi,
} from '../../../../socket/alerts/alerts.listner';
import AddNewCard from '../../AddNewCard/AddNewCard';
import { cardTypeConfigMapper } from '../../AddNewCard/AddNewCard.constants';
import {
  COMMON_PLOTS_CARD,
  OVERVIEW_ALERT_STATUS_HEADER,
  OVERVIEW_RUN_STATUS_HEADER,
} from './overview.constants';
import './overview.scss';
import {
  getPanelTypes,
  clearPanelTypeState,
  deletePanelType,
  updatePanelType,
} from '../../../../redux/AddNewCard/addNewCard.action';
import ConfirmModal from '../../../../common/Modal/Modal';
import { deleteModal } from '../../../../constants/messages';
import { elementsGaurd } from '../../../../gaurds';
import { ElementPermissions } from '../../../../permissions';
import { MoreOutlined } from '@ant-design/icons';
import * as Pattern from '../../../../constants/pattern';
import { CaretRightOutlined } from '@ant-design/icons';
import { getBatchTime, clearBatchState } from "../../../../redux/actions/plantTime.action";

const { Panel } = Collapse;

/** Overview Screen */
class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      kpiIdToDelete: '',
      dashboardData: [],
      editableCard: null,
      variableCardCount: 0,
      stdDeviationCardCount: 0,
      statisticsCardCount: 0,
      runStatsCardCount: 0,
      plantTime: null,
      kpiIdToUpdate: '',
      panelTypes: [],
      deletePanelModalVisible: false,
      panelToOperate: null,
      editPanelModalVisible: false,
      editedPanelTitle: '',
      batchTime: null
    };
  }

  componentDidMount() {
    this.getDashboardInfo();
    // this.getCommonPlotsData();
    NewKPISocketListner((event) => {
      Notification.show(Types.Success, SOCKET_MSG.KPI_MSG);
      this.setState({ sokcetLoader: true }, () => {
        this.setState(
          {
            sokcetLoader: true,
            dashboardData: [],
          },
          () => {
            // this.getCommonPlotsData();
            this.setDataToState();
            this.getDashboardData();
            this.getDashboardInfo();
          }
        );
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      info,
      AddNewCard,
      UpdateCardReducer,
      // commonPlotsReducer,
      deleteKpiReducerState,
      kpiByLevelReducerState,
      clearKPIState,
      clearDeleteKpiState,
      clearAddNewCardState,
      plantTimeReducer,
      activeKey,
      PanelTypeReducer,
      clearPanelTypeState,
      getPanelTypes,
      getPlotByKPIId,
      getKPIByPanelType,
      kpiByPanelReducer,
      clearKPIByPanelState,
      clearBatchState,
      batchTimeIsSuccess,
      batchTimeData
    } = this.props;

    if (prevProps.info !== info && activeKey === '1') {
      this.getDashboardInfo();
      // this.getCommonPlotsData();
    }

    if (prevProps.activeKey !== this.props.activeKey) {
      this.getDashboardInfo();
      // this.getCommonPlotsData();
    }

    if (
      plantTimeReducer.isSuccess &&
      prevProps.plantTimeReducer.isSuccess !== plantTimeReducer.isSuccess
    ) {
      const plantTime = plantTimeReducer.data && plantTimeReducer.data.to;
      this.setState({
        plantTime,
      });
    }

    if (
      kpiByLevelReducerState.isSuccess &&
      prevProps.kpiByLevelReducerState.isSuccess !==
        kpiByLevelReducerState.isSuccess &&
      activeKey === '1'
    ) {
      clearKPIState();
      this.props.getPlantTime();
      this.setDataToState();
      this.getDashboardData();
    }

    if (
      AddNewCard.isSuccess &&
      AddNewCard.isSuccess !== prevProps.AddNewCard.isSuccess &&
      activeKey === '1'
    ) {
      const { info } = this.props;
      const { batchTime } = this.state;
      this.setDataToState();
      clearAddNewCardState();
      getPlotByKPIId(AddNewCard.data._id, AddNewCard.data, batchTime);
      if (info && info.dataRef) {
        getPanelTypes(info.type, info.dataRef?._id);
      }
    }

    if (
      deleteKpiReducerState.isSuccess &&
      deleteKpiReducerState.isSuccess !==
        prevProps.deleteKpiReducerState.isSuccess &&
      activeKey === '1'
    ) {
      this.setDataToState();
      clearDeleteKpiState();
      this.setState({
        kpiIdToDelete: '',
      });
      Notification.show(Types.Success, `${addNewCardMessages.DELETED}`);
    }
    if (
      deleteKpiReducerState.isError &&
      deleteKpiReducerState.isError !== prevProps.deleteKpiReducerState.isError
    ) {
      if (deleteKpiReducerState.kpiId === this.state.kpiIdToDelete) {
        Notification.show(Types.Error, ERROR_HANDLE_TEXT);
        clearDeleteKpiState();
        this.setState({
          kpiIdToDelete: '',
        });
      }
    }

    // if (
    //   commonPlotsReducer.apiState.isSuccess !==
    //   prevProps.commonPlotsReducer.apiState.isSuccess
    // ) {
    // }

    if (
      UpdateCardReducer.isSuccess &&
      prevProps.UpdateCardReducer.isSuccess !== UpdateCardReducer.isSuccess &&
      activeKey === '1'
    ) {
      const { updateKPIReducer, clearUpdataKpiState } = this.props;
      const { data } = UpdateCardReducer;
      const { dashboardData } = this.state;
      const kpiId = data._id;
      let variableCardCount = 0;
      let statisticsCardCount = 0;
      let stdDeviationCardCount = 0;
      let runStatsCardCount = 0;
      const index = dashboardData.findIndex((kpi) => kpi._id === kpiId);
      if (index !== -1) {
        const tempd = [...dashboardData];
        tempd[index] = { ...data };
        tempd.forEach((card) => {
          if (card.cardType.cardType === 'Variable card') variableCardCount++;
          else if (card.cardType.cardType === 'Standard deviation card')
            stdDeviationCardCount++;
          else if (card.cardType.cardType === 'Run Statistic')
            runStatsCardCount++;
          else statisticsCardCount++;
        });
        this.setState(
          {
            dashboardData: tempd,
            visible: false,
            variableCardCount,
            statisticsCardCount,
            stdDeviationCardCount,
            runStatsCardCount,
            kpiIdToUpdate: kpiId,
          },
          () => {
            updateKPIReducer({ data: tempd });
            clearUpdataKpiState();
          }
        );
      }
      Notification.show(Types.Success, `${addNewCardMessages['UPDATED']}`);
    }

    if (
      kpiByLevelReducerState.isSuccessEdit &&
      kpiByLevelReducerState.isSuccessEdit !==
        prevProps.kpiByLevelReducerState.isSuccessEdit &&
      activeKey === '1'
    ) {
      const { clearPlotsState, getPlotByKPIId } = this.props;
      const { kpiIdToUpdate, dashboardData, batchTime } = this.state;
      clearPlotsState();
      const data = dashboardData.find((kpis) => {
        return kpis._id === kpiIdToUpdate;
      });
      const id = kpiIdToUpdate;
      getPlotByKPIId(id, data, batchTime);
    }

    if (PanelTypeReducer.isSuccess) {
      clearPanelTypeState();
      const { data } = PanelTypeReducer;
      this.setState({
        panelTypes: data,
      });
    }

    if (PanelTypeReducer.isDeleteSuccess) {
      clearPanelTypeState();
      const { data } = PanelTypeReducer;
      const { panelToOperate } = this.state;
      if (panelToOperate && panelToOperate.id) {
        getKPIByPanelType(
          info.type,
          info.dataRef._id ? info.dataRef._id : info.dataRef.info._id,
          panelToOperate?.id
        );
      }
      this.setState({
        panelTypes: data,
        panelToOperate: null,
      });
      Notification.show(
        Types.Success,
        `${addNewCardMessages['DELETE_PANEL_SUCCESS']}`
      );
    }

    if (PanelTypeReducer.isError) {
      clearPanelTypeState();
      const { message } = PanelTypeReducer;
      Notification.show(Types.Error, `${message}`);
    }

    if (
      kpiByPanelReducer.isSuccess &&
      prevProps.kpiByPanelReducer.isSuccess !== kpiByPanelReducer.isSuccess &&
      activeKey === '1'
    ) {
      const { updateKPIReducer } = this.props;
      const { data } = kpiByPanelReducer.data;
      const { dashboardData } = this.state;
      const tempd = [...dashboardData];
      data.forEach((item) => {
        const kpiId = item['_id'];
        const index = dashboardData.findIndex((kpi) => kpi._id === kpiId);
        if (index !== -1) {
          tempd[index] = { ...item };
        }
      });
      this.setState(
        {
          dashboardData: tempd,
        },
        () => {
          updateKPIReducer({ data: tempd });
          clearKPIByPanelState();
        }
      );
    }

    if (PanelTypeReducer.isUpdateSuccess) {
      clearPanelTypeState();
      const { data } = PanelTypeReducer;
      const { panelToOperate } = this.state;
      if (panelToOperate && panelToOperate.id) {
        getKPIByPanelType(
          info.type,
          info.dataRef._id ? info.dataRef._id : info.dataRef.info._id,
          panelToOperate?.id
        );
      }
      this.setState({
        panelTypes: data,
        panelToOperate: null,
        editPanelModalVisible: false,
      });
      Notification.show(
        Types.Success,
        `${addNewCardMessages['UPDATE_PANEL_SUCCESS']}`
      );
    }

    if (PanelTypeReducer.isError) {
      clearPanelTypeState();
      const { message } = PanelTypeReducer;
      Notification.show(Types.Error, `${message}`);
    }

    if(batchTimeIsSuccess && activeKey === '1')  {
      clearBatchState();
      const batchTime = batchTimeData && batchTimeData.to;
      this.setState({
        batchTime: batchTime
      })
    }
  }

  setDataToState = () => {
    let dashboardData = [];
    let variableCardCount = 0;
    let statisticsCardCount = 0;
    let stdDeviationCardCount = 0;
    let runStatsCardCount = 0;
    const { data } = this.props.kpiByLevelReducerState;

    dashboardData =
      data &&
      data.data &&
      data.data
        .map((item) => {
          const { cardType } = item;
          if (
            cardType &&
            cardTypeConfigMapper[cardType.cardType] &&
            cardTypeConfigMapper[cardType.cardType].viewScreen === 'overview'
          ) {
            return item;
          }
          return undefined;
        })
        .filter((itemPlot) => itemPlot !== undefined);
    dashboardData &&
      dashboardData.forEach((card) => {
        if (card.cardType.cardType === 'Variable card') variableCardCount++;
        else if (card.cardType.cardType === 'Standard deviation card')
          stdDeviationCardCount++;
        else if (card.cardType.cardType === 'Run Statistic')
          runStatsCardCount++;
        else statisticsCardCount++;
      });

    this.setState({
      dashboardData,
      variableCardCount,
      statisticsCardCount,
      stdDeviationCardCount,
      runStatsCardCount,
    });
  };

  getCommonPlotsData() {
    const { info, fetchCommonPlots } = this.props;
    if (info && info.dataRef) {
      fetchCommonPlots(
        info.type,
        info.dataRef._id ? info.dataRef._id : info.dataRef.info._id,
        Object.values(COMMON_PLOTS_CARD)
      );
    }
  }

  getDashboardInfo() {
    const { info, getKPIByLevelId, getPanelTypes, getBatchTime, activeKey } = this.props;
    if (info && info.dataRef && activeKey === '1') {
      getBatchTime()
      getPanelTypes(info.type, info.dataRef?._id);
      getKPIByLevelId(
        info.type,
        info.dataRef._id ? info.dataRef._id : info.dataRef.info._id
      );
    }
  }

  rendertable(title) {
    const { commonPlotsReducer } = this.props;
    let data = [];
    data =
      commonPlotsReducer.data[title] &&
      commonPlotsReducer.data[title].data.map((element) => {
        const { _id } = element;
        return { ...element, name: _.capitalize(_id.name) };
      });
    const column =
      title === COMMON_PLOTS_CARD.ALERT_SUMMARY_CARD
        ? OVERVIEW_ALERT_STATUS_HEADER
        : OVERVIEW_RUN_STATUS_HEADER;
    return (
      <div className="overview-table-card">
        <TableCard title={title} />
        <CustomTable
          showHeader={true}
          columns={column({})}
          data={commonPlotsReducer.data[title] ? _.sortBy(data, 'name') : []}
        />
      </div>
    );
  }

  getDashboardData() {
    const { kpiByLevelReducerState, getPlotByKPIId } = this.props;
    const { batchTime } = this.state;
    if (kpiByLevelReducerState.data && kpiByLevelReducerState.data.data) {
      kpiByLevelReducerState.data.data.forEach((value) => {
        if (
          (value.cardType && value.cardType.cardType === 'Time series plot') ||
          (value.cardType &&
            value.cardType.cardType === 'Equipment Run Plot') ||
          (value.cardType &&
            value.cardType.cardType === 'X-Y Plot with baseline curve') ||
          (value.cardType && value.cardType.cardType === 'X-Y Plot')
        ) {
        } else getPlotByKPIId(value._id, value, batchTime);
      });
    }
  }

  onDelete = (kpiId) => {
    this.setState({ kpiIdToDelete: kpiId }, () => {
      this.props.deleteKpiById(kpiId);
    });
  };

  handleSwitch = (kpiId, value) => {
    const payload = {
      isVisibleToClient: value,
    };
    this.props.onUpdateKpi(kpiId, payload);
  };

  onTimeChange = (kpiIdData) => {
    this.props.getParticularPlotDataByKPIId(kpiIdData);
  };

  componentWillUnmount() {
    const { clearPlotState } = this.props;
    RemoveListeningNewKpi();
    clearPlotState();
  }

  openCardModal = (id) => {
    const { dashboardData } = this.state;
    let data = dashboardData.find((item) => item._id === id);
    this.setState({
      visible: true,
      kpiIdToEdit: id,
      editableCard: data,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  renderEditCardModal = () => {
    if (this.state.visible) {
      const { info } = this.props;
      return (
        <AddNewCard
          visible={this.state.visible}
          handleCancel={this.handleCancel}
          info={info}
          activeKey={'1'}
          mode={'edit'}
          cardData={this.state.editableCard}
        />
      );
    }
  };

  deletePanel() {
    const { panelToOperate } = this.state;
    const { deletePanelType } = this.props;
    deletePanelType({ id: panelToOperate?.id });
    this.setState({ deletePanelModalVisible: false });
  }

  cancelDeletePanel() {
    this.setState({ deletePanelModalVisible: false, panelToOperate: null });
  }

  saveEditPanelTitle = () => {
    const { editedPanelTitle, panelToOperate } = this.state;
    const { updatePanelType } = this.props;
    if (panelToOperate && panelToOperate.id) {
      updatePanelType({ type: editedPanelTitle }, panelToOperate?.id);
    }
  };

  closeEditPanelModal = () => {
    this.setState({
      editPanelModalVisible: false,
    });
  };

  setAddNewValue(value) {
    this.setState({
      editedPanelTitle: value,
    });
  }

  renderDeletePanelModal = () => {
    const { deletePanelModalVisible } = this.state;
    if (deletePanelModalVisible) {
      return (
        <ConfirmModal
          visible={deletePanelModalVisible}
          handleOk={() => this.deletePanel()}
          handleCancel={() => this.cancelDeletePanel()}
          message={deleteModal.panel.message}
          title={deleteModal.title}
        />
      );
    }
  };

  renderEditPanelModal = () => {
    const { editPanelModalVisible } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="EditCurrentNode">
        <Modal
          title={addNewCardMessages.EDIT_PANEL_TYPE}
          visible={editPanelModalVisible}
          onOk={this.saveEditPanelTitle}
          onCancel={this.closeEditPanelModal}
          okText="Update"
          cancelText="Cancel"
        >
          <Form>
            <Form.Item>
              {editPanelModalVisible &&
                getFieldDecorator('name', {
                  rules: [
                    { required: true, message: ERROR_MESSAGES.REQUIRED_FIELD },
                    {
                      pattern: Pattern.Name.regx,
                      message: Pattern.Name.message,
                    },
                  ],
                })(
                  <Input
                    onChange={(e) => {
                      this.setAddNewValue(e.target.value);
                    }}
                    maxLength={70}
                  />
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };

  handleMenuClick = (e) => {
    if (e.key === '1') {
      this.setState({
        deletePanelModalVisible: true,
      });
    } else {
      this.setState({
        editPanelModalVisible: true,
      });
    }
  };

  menu = (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="1">Delete</Menu.Item>
      <Menu.Item key="2">Edit</Menu.Item>
    </Menu>
  );

  menuOp = (item) => {
    this.setState({ panelToOperate: item });
  };

  renderCard(item, cardTypeInfo, abbreviation, level, equipmentName) {
    const { cardType } = item;
    const { plotsReducerState, info } = this.props;
    const type = info?.type;
    const { plantTime } = this.state;
    const graph = cardTypeConfigMapper[cardType.cardType];
    const isFailureChart =
      cardType.cardType === cardTypeMapper['Failure bar chart'];
    const barChartIndex = !(type === 'facility' || type === 'system');
    const facilityId =
      info && info.type === 'facility'
        ? info['dataRef']['_id']
        : info && info.type !== 'facility'
        ? info['dataRef']['facilityId']
        : '';
    return graph && graph.viewCard ? (
      <graph.viewCard
        id={item._id}
        key={item._id}
        title={item.cardTitle}
        time={plantTime * 1000}
        delete={(id) => {
          this.onDelete(id);
        }}
        active={item.isVisibleToClient}
        viewDataAdmin={item.toViewClientData ? 'Client' : 'Mechademy'}
        cardType={cardType.cardType}
        changeVisibleStatus={(value) => {
          this.handleSwitch(item._id, value);
        }}
        variableName={item.cardValue && item.cardValue.variableName}
        onTimeChange={this.onTimeChange}
        cardData={
          plotsReducerState.kpisDataState &&
          plotsReducerState.kpisDataState[item._id]
        }
        cardValue={
          plotsReducerState.kpisDataState &&
          plotsReducerState.kpisDataState[item._id] &&
          plotsReducerState.kpisDataState[item._id].isSuccess &&
          plotsReducerState.kpisDataState[item._id].data
        }
        isChangetime={
          cardType.cardType === cardTypeMapper['Failure cause pie chart'] ||
          cardType.cardType === cardTypeMapper['Failure bar chart']
        }
        duration={
          item.duration === '1 years' ? 2 : item.duration === '6 months' ? 1 : 0
        }
        edit={(id) => {
          this.openCardModal(id);
        }}
        facilityId={facilityId}
        cardTypeInfo = {cardTypeInfo}
        abbreviation = {abbreviation}
        level = {level}
        equipmentName = {equipmentName}
      >
        {cardType.cardType !== cardTypeMapper['Time series plot']
          ? plotsReducerState.kpisDataState[item._id] &&
            plotsReducerState.kpisDataState[item._id].data &&
            plotsReducerState.kpisDataState[item._id].isSuccess &&
            /** for card type: Failure cause bar/tornado chart */
            (isFailureChart && barChartIndex ? (
              <graph.viewGraph
                id={item._id}
                cardValue={plotsReducerState.kpisDataState[item._id].data}
                isSuccess={plotsReducerState.kpisDataState[item._id].isSuccess}
                cardType={cardType.cardType}
                cardLevel={type}
                edit={(id) => {
                  this.openCardModal(id);
                }}
              />
            ) : (
              <graph.viewGraph
                id={item._id}
                cardValue={plotsReducerState.kpisDataState[item._id].data}
                isSuccess={plotsReducerState.kpisDataState[item._id].isSuccess}
                cardType={cardType.cardType}
                viewDataAdmin={item.toViewClientData ? 'Client' : 'Mechademy'}
                edit={(id) => {
                  this.openCardModal(id);
                }}
                type={cardType.cardType}
              />
            ))
          : plotsReducerState.kpisDataState[item._id] &&
            plotsReducerState.kpisDataState[item._id].data &&
            plotsReducerState.kpisDataState[item._id].isSuccess && (
              <graph.viewGraph cardValue={item} />
            )}
      </graph.viewCard>
    ) : null;
  }

  renderCardByType(type) {
    return (
      <>
        {/* {kpiByLevelReducerState.isLoading ||
          (UpdateCardReducer.isLoading && <Loader />)} */}
        {this.state.dashboardData && this.state.dashboardData.length ? (
          this.state.dashboardData.map((item) => {
            if (
              type === 'all' &&
              item &&
              item.cardType.cardType !== 'Variable card' &&
              item.cardType.cardType !== 'Standard deviation card' &&
              item.cardType.cardType !== 'Run Statistic'
            ) {
              return this.renderCard(item);
            }
            if (
              ((type === 'Variable card' && item.panelType === null) ||
                type === 'Standard deviation card' ||
                item.cardType.cardType === 'Run Statistic') &&
              item &&
              item.cardType.cardType === type
            ) {
              let cardType = item.cardType.cardType
              let abbreviation = item.cardValue.value
              let level = item.level
              let equipmentName = item.equipment
              return this.renderCard(item, cardType, abbreviation, level, equipmentName);
            }
            if (item && item.panelType?.type === type) {
              return this.renderCard(item);
            }
            // TODO all type of cards check once they are done
            return undefined;
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50
            }}
            description={
              <span>
                <b>ADD CARDS HERE</b>
              </span>
            }
          >
          </Empty>
        )}
        {type === 'Variable card' &&
        this.state.dashboardData &&
        this.state.dashboardData.length &&
        this.state.variableCardCount === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50
            }}
            description={
              <span>
                <b>ADD CARDS HERE</b>
              </span>
            }
          >
          </Empty>
        ) : null}
        {type === 'all' &&
        this.state.dashboardData &&
        this.state.dashboardData.length &&
        this.state.statisticsCardCount === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50
            }}
            description={
              <span>
                <b>ADD CARDS HERE</b>
              </span>
            }
          >
          </Empty>
        ) : null}
        {type === 'Standard deviation card' &&
        this.state.dashboardData &&
        this.state.dashboardData.length &&
        this.state.stdDeviationCardCount === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50
            }}
            description={
              <span>
                <b>ADD CARDS HERE</b>
              </span>
            }
          >
          </Empty>
        ) : null}
        {type === 'Run Statistic' &&
        this.state.dashboardData &&
        this.state.dashboardData.length &&
        this.state.runStatsCardCount === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50
            }}
            description={
              <span>
                <b>ADD CARDS HERE</b>
              </span>
            }
          >
          </Empty>
        ) : null}
      </>
    );
  }

  render() {
    const { panelTypes } = this.state;
    const { info, activeKey } = this.props;
    const facilityId =
      info && info.type === 'facility'
        ? info['dataRef']['_id']
        : info && info.type !== 'facility'
        ? info['dataRef']['facilityId']
        : '';
    return (
      activeKey &&
      activeKey === '1' && (
        <div className="overview-main-screen">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Variable Cards" key="1">
              {this.renderCardByType('Variable card')}
              <Collapse bordered={false}>
                {panelTypes.map((item, index) => {
                  return (
                    <Panel
                      header={item.name}
                      key={index}
                      extra={elementsGaurd(
                        <Dropdown
                          trigger="click"
                          overlay={this.menu}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.menuOp(item);
                          }}
                        >
                          <MoreOutlined />
                        </Dropdown>,
                        ElementPermissions.ADD_CARD,
                        {
                          type: 'facility',
                          dataRef: {
                            _id: facilityId,
                          },
                        }
                      )}
                    >
                      {this.renderCardByType(item.name)}
                    </Panel>
                  );
                })}
              </Collapse>
            </Panel>
            {
              <Panel header="Standard deviation Cards" key="2">
                {this.renderCardByType('Standard deviation card')}
              </Panel>
            }
            {/* {UserRole === 'MechademyAdmin' ||
            UserRole === 'MechademyFacilityManager' ? (
              <Panel header="Run statistics" key="3">
                {this.renderCardByType('Run Statistic')}
              </Panel>
            ) : null} */}
            {
              <Panel header="Run statistics" key="3">
                {this.renderCardByType('Run Statistic')}
              </Panel>
            }
            {/* <Panel
              header="Operational statistics"
              className="Operational-statistics"
              key="4"
            >
              {this.rendertable(Object.values(COMMON_PLOTS_CARD)[1])}
              {this.rendertable(Object.values(COMMON_PLOTS_CARD)[0])}
            </Panel> */}
            <Panel header="Alert statistics" key="5">
              {this.renderCardByType('all')}
            </Panel>
          </Collapse>
          {this.renderEditCardModal()}
          {this.renderDeletePanelModal()}
          {this.renderEditPanelModal()}
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  kpiByLevelReducerState: state.KpiByLevelReducer,
  plotsReducerState: state.PlotsReducer,
  AddNewCard: state.AddNewCardReducer,
  // commonPlotsReducer: state.commonPlotsReducer,
  deleteKpiReducerState: state.DeleteKpiByIdReducer,
  UpdateCardReducer: state.UpdateKpiByIdReducer,
  plantTimeReducer: state.plantTimeReducer,
  PanelTypeReducer: state.PanelTypeReducer,
  kpiByPanelReducer: state.KPIByPanelReducer,
  batchTimeIsSuccess: state.batchTimeReducer.isSuccess,
  batchTimeData: state.batchTimeReducer.data,
  batchTimeIsError: state.batchTimeReducer.isError,
});

const mapDispatchToProps = {
  getKPIByLevelId,
  clearKPIState,
  // fetchCommonPlots,
  // clearCommonPlots,
  getPlotByKPIId,
  clearPlotState,
  deleteKpiById,
  clearDeleteKpiState,
  onUpdateKpi,
  clearUpdataKpiState,
  getParticularPlotDataByKPIId,
  clearAddNewCardState,
  getPlantTime,
  updateKPIReducer,
  clearPlotsState,
  getPanelTypes,
  deletePanelType,
  clearPanelTypeState,
  getKPIByPanelType,
  clearKPIByPanelState,
  updatePanelType,
  getBatchTime, 
  clearBatchState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'EditPanelForm' })(Overview));
