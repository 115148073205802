import React from "react";
import PropType from "prop-types";
import _ from "lodash";

function VariableCardDetails(props) {
  const dataLength = props?.cardValue?.cardValue[0]?.data.length - 1;
  return (
    <>
      {props.cardValue &&
      props.cardValue.cardValue[0] &&
      props.cardValue.cardValue[0].data &&
      props.cardValue.cardValue[0].data.length > 0 &&
      (props.cardValue.cardValue[0].data[dataLength].plantValue ||
        props.cardValue.cardValue[0].data[dataLength].predictedValue ||
        props.cardValue.cardValue[0].plantUnit ||
        props.cardValue.cardValue[0]._id 
        || props.cardValue.cardValue[0].data[dataLength]?.value
        ) ? (
          <>
            <div className="text-center">
              {props.cardValue.cardValue[0].data[dataLength]?.equipmentName
                ? props.cardValue.cardValue[0].data[dataLength]?.equipmentName
                : ""}
            </div>
            <div className="card-body-section">
              <div className="card-info">
                {
              _.isFinite(parseFloat(props.cardValue.cardValue[0].value)) &&
              props.cardType === "Run Statistic"
                  ? parseFloat(props.cardValue.cardValue[0].value).toFixed(2)
                 : _.isFinite(props.cardValue.cardValue[0].data[dataLength]?.plantValue)
                    ? props.cardValue.cardValue[0].data[dataLength]?.plantValue.toFixed(2)
                    : _.isFinite(props.cardValue.cardValue[0].data[dataLength]?.predictedValue)
                      ? props.cardValue.cardValue[0].data[dataLength]?.predictedValue.toFixed(2)
                      : "---"}
              </div>
              <div className="card-unit">
                {props.cardValue.cardValue[0].plantUnit
                  ? props.cardValue.cardValue[0].plantUnit
                  : ""}
              </div>
            </div>
          </>
        ) : (
          <div className="card-body-section">
            <div className="card-info">
              ---
            </div>
          </div>
        )}
    </>
  );
}

VariableCardDetails.prototypes = {
  cardValue: PropType.array,
};

export default VariableCardDetails;
