/* eslint-disable */
import { Button, Form } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as VersionJson from '../../../version.json';
import { bg_screen } from '../../../assets/bg_screen';
import { bg_star } from '../../../assets/bg_star';
import cross from '../../../assets/ic_cross/cross.svg';
import { logo } from '../../../assets/logo';
import FormFields from '../../../common/FormFields/FormFields';
import Loader from '../../../common/Loader/Loader';
import Notification, { Types } from '../../../common/Notification/Notification';
import '../../../constants/global.scss';
import {
  changePasswordMessages,
  LOGGEDIN_WARNING,
  T_AND_C,
  UserManagementMessages,
} from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import {
  ButtonVariables,
  CreateNewPassword,
} from '../../../constants/variables';
import { isLoggedIn } from '../../../libs/token';
import {
  clearForgotPasswordState,
  setNewPassword,
} from '../../../redux/actions/forgotPassword.action';
import {
  clearUserData,
  resetPassword,
  resetReducerPassword,
} from '../../../redux/actions/UserManagement.action';
import './ResetPassword.scss';
import { formFields } from './ResetPasswordConstants';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tandc: false };
  }

  componentDidMount() {
    if (isLoggedIn()) {
      Notification.show(Types.Warning, LOGGEDIN_WARNING);
      this.props.history.push(Routes.app);
    }
  }

  componentDidUpdate() {
    const {
      isSuccess,
      isError,
      errorCode,
      message,
    } = this.props.changePasswordState;
    const {
      isResetPasswordSuccess,
      isResetPasswordError,
      errorMessageResetPassword,
    } = this.props.userManagementState;
    if (isResetPasswordSuccess) {
      Notification.show(Types.Success, UserManagementMessages.PASSWORD_SAVED);
      this.props.history.push(Routes.root);
    }
    if (isResetPasswordError) {
      this.props.clearUserData();
      if (_.includes(errorMessageResetPassword.body.message, 'too long')) {
        Notification.show(Types.Error, UserManagementMessages.LONG_PASSWORD);
      } else {
        Notification.show(
          Types.Error,
          errorMessageResetPassword &&
            errorMessageResetPassword.body &&
            UserManagementMessages[errorMessageResetPassword.body.code]
        );
      }
    }
    if (isSuccess) {
      Notification.show(Types.Success, changePasswordMessages.SUCCESS);
      this.props.history.push(Routes.root);
    }
    if (isError) {
      this.props.clearForgotPasswordState();
      Notification.show(
        Types.Error,
        changePasswordMessages[errorCode]
          ? changePasswordMessages[errorCode]
          : message
      );
    }
  }

  componentWillUnmount() {
    this.props.resetReducerPassword();
    this.props.clearForgotPasswordState();
  }

  isCreatePassword = () => {
    const { match } = this.props;
    return _.includes(match.path, '/create-new-password/');
  };

  onSave = () => {
    const { match } = this.props;
    const { tandc } = this.state;

    this.props.form.validateFields((error, values) => {
      if (!error) {
        if (values.password !== values.confirmPassword) {
          Notification.show(
            Types.Error,
            UserManagementMessages.INVALID_PASSWORD
          );
        } else if (!isLoggedIn()) {
          if (match.path === Routes.resetPassword && tandc) {
            this.props.resetPassword({
              code: this.props.match.params.token,
              password: values.password,
            });
          } else if (match.path === Routes.forgotPasswordReset) {
            this.props.setNewPassword({
              code: this.props.match.params.token,
              password: values.password,
            });
          } else {
            Notification.show(Types.Info, T_AND_C);
          }
        } else {
          Notification.show(
            Types.Info,
            UserManagementMessages.ALREADY_LOGGEDIN
          );
          this.props.history.push(Routes.app);
        }
      }
    });
  };

  tandc = (e) => {
    this.setState({ tandc: e.target.checked });
  };

  onClose = () => {
    this.props.history.push(Routes.app);
  };

  render() {
    const { form } = this.props;
    const { loading } = this.props.userManagementState;
    const { isLoading } = this.props.changePasswordState;
    return (
      <div className="reset-password">
        {(loading || isLoading) && <Loader />}
        <div className="logo" style={createPassowrdStyle}>
          <img src={logo} alt="logo" />
          <span>
            <small>{VersionJson?.version}</small>
          </span>
        </div>
        <div className="sideShow" style={sideShowStyle} />
        <div className="form-div" style={createPassowrdStyle}>
          <div className="form">
            <div className="form-inner-left" />
            <div className="iform">
              <span>
                {' '}
                <img src={cross} alt="close" onClick={this.onClose} />{' '}
              </span>
              <br />
              <div className="welcomeText">
                <span>{CreateNewPassword.TITLE}</span>
              </div>
              <div className="tag">{CreateNewPassword.INSTRUCTION}</div>
              <FormFields formFields={formFields()} form={form} />
              {this.isCreatePassword() && (
                <div className="tandc">
                  <input
                    type="checkbox"
                    className="check"
                    onChangeCapture={this.tandc}
                  />{' '}
                  <span>
                    I agree to{' '}
                    <a
                      href="https://backend.mechademy.ml/terms-of-service"
                      target="_blank"
                    >
                      terms & conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://backend.mechademy.ml/privacy-policy"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </span>
                </div>
              )}
              <div className="actions">
                <Button type="primary" htmlType="submit" onClick={this.onSave}>
                  {ButtonVariables.SUBMIT}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const sideShowStyle = {
  background: `url(${bg_screen}) no-repeat`,
  backgroundSize: 'cover',
};

const createPassowrdStyle = {
  background: `url(${bg_star}) no-repeat center`,
  backgroundPosition: 'right top',
};

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  userManagementState: state.userManagementReducer,
  changePasswordState: state.ForgotPasswordReducer.changePasswordState,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => dispatch(resetPassword(payload)),
  setNewPassword: (payload) => dispatch(setNewPassword(payload)),
  clearUserData: () => dispatch(clearUserData()),
  clearForgotPasswordState: () => dispatch(clearForgotPasswordState()),
  resetReducerPassword: () => dispatch(resetReducerPassword()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'ResetPassword' })(withRouter(ResetPassword)));
