import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notification, { Types } from '../../../common/Notification/Notification';
import { AssetMessages, NO_INTERNET } from '../../../constants/messages';
import * as Routes from '../../../constants/routes';
import { getEquipmentAsset } from '../../../redux/actions/assetEquipment.action';
import { columns } from './EquipmentListing.constants';
import './EquipmentListing.scss';

class EquipmentListing extends React.Component {
  state = {
    data: [],
    keyword: null,
    type: this.props.type,
    total_count: null,
  };

  componentDidMount() {
    const params = {
      skip: 0,
      limit: AssetMessages.COMPONENT_PAGE_COUNT,
      keyword: '',
    };
    if (this.handleNetwork()) {
      this.props.getEquipment(params);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isGetSuccess, getData } = this.props.assetEquipmentState.apiState;
    const { searchEquipment } = this.props;

    if (searchEquipment && searchEquipment !== prevProps.searchEquipment) {
      this.setState(
        {
          keyword: searchEquipment,
        },
        () => {
          if (this.state.keyword.length) {
            this.searchList(this.state.keyword);
          }
        }
      );
    } else if (prevProps.searchEquipment && searchEquipment === null) {
      this.setState(
        {
          keyword: null,
        },
        () => {
          this.searchList(this.state.keyword);
        }
      );
    }

    if (
      isGetSuccess &&
      isGetSuccess !== prevProps.assetEquipmentState.apiState.isGetSuccess
    ) {
      const tempData = getData && getData.items;
      const temp_count = getData && getData.total_count;
      this.setState({
        data: this.transformData(tempData),
        keyword: null,
        total_count: temp_count,
      });
    }
  }

  searchList = (data) => {
    const keyword = data;
    this.setState(
      {
        keyword: null,
      },
      () => {
        this.props.getEquipment({ skip: 0, limit: null, keyword });
      }
    );
  };

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  transformData = (data) =>
    data.map((element) => {
      const item = { ...element };
      item.typeId = item.type && item.type._id;
      item.type = item.type && item.type.type;
      return item;
    });

  onEdit = (data) => {
    this.props.history.push(Routes.addEquipment, {
      data,
      type: this.state.type,
      mode: 'edit',
    });
  };

  rowClick = (data) => {
    this.props.history.push(Routes.addEquipment, {
      data,
      type: this.state.type,
      mode: 'view',
    });
  };

  handleChange = (pagination) => {
    const { current } = pagination;
    const { pageSize } = pagination;
    const skip = (current - 1) * pageSize;
    const parameters = { skip, limit: pageSize, keyword: '' };
    if (this.handleNetwork()) {
      this.props.getEquipment(parameters); // reducer call for retrieving data
    }
  };

  render() {
    // const { loading } = this.props.assetEquipmentState;

    return (
      <div className="EquipmentListing">
        {/* {loading && <Loader />} */}
        <Table
          showHeader
          columns={columns({
            edit: this.onEdit,
            rowClick: this.rowClick,
          })}
          dataSource={this.state.data}
          pagination={{
            pageSize: AssetMessages.COMPONENT_PAGE_COUNT,
            total: this.state.total_count,
            hideOnSinglePage: true,
          }}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

EquipmentListing.prototypes = {
  assetEquipmentState: PropTypes.object,
  getEquipment: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

EquipmentListing.defaultProps = {
  assetEquipmentState: {},
  getEquipment: () => {},
  history: {},
  location: {},
  match: {},
};

const mapStateToProps = (state) => ({
  assetEquipmentState: state.assetEquipmentReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEquipment: (payload) => dispatch(getEquipmentAsset(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EquipmentListing));
