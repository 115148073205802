import React from "react";
import ReactApexChart from "react-apexcharts";

class Line extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        legend: {
          position: "bottom",
          show: false,
        },
        chart: {
          height: 250,
          width: 250,
          type: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        fill: {
          type: "solid",
        },
        minPolylineStep: 20,
        markers: {
          size: [0, 0],
        },
        stroke: {
          show: true,
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        tooltip: {
          shared: false,
          intersect: true,
        },
        xaxis: {
          tickAmount: 5,
          tickPlacement: "between",
          title: {
            text: this.props.xAxis,
            offsetY: 8,
          },
          floating: false,
          labels: {
            formatter(value) {
              return value && value.toFixed(4);
            },
          },
        },
        yaxis: [
          {
            tickAmount: 5,
            trim: true,
            title: {
              text: this.props.yAxis,
            },
            floating: false,
            labels: {
              formatter(value) {
                return value && value.toFixed(5);
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Baseline Curve",
          type: "line",
          data: this.props.graphData,
        },
      ],
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height="250"
        />
      </div>
    );
  }
}

export default Line;
