import React from "react";
import { getTimeInFormat } from "../../../../libs/valueFormatter";

export const OVERVIEW_RUN_STATUS_HEADER = () => [
  {
    title: "Equipment Name",
    dataIndex: "equip",
    render: (value, row, col, disabled, item) => {
      const name = item && item._id && item._id.name;
      return <span>{name}</span>;
    },
  },
  {
    title: "Run Status",
    dataIndex: "runStatus",
    render: (value, row, col, disabled, item) => (
      <span
        className="overview-table-run-status"
        style={
          item.data && item.data.length > 0
            ? { color: "green" }
            : { color: "red" }
        }
      >
        {item.data && item.data.length > 0 ? "ON" : "OFF"}
      </span>
    ),
  },
  {
    title: "Last Updated On",
    dataIndex: "timestamp",
    render: (value, row, col, disabled, item) => {
      const time = item && item.toTimestamp * 1000;
      return (
        <span className="time-display">{` ${getTimeInFormat(time)}`}</span>
      );
    },
  },
];

export function alertCount(value, level) {
  const count = value.map((item) => {
    if (item.level && item.level === level) {
      return item.count;
    }
    return undefined;
  });
  return count;
}

export const OVERVIEW_ALERT_STATUS_HEADER = () => [
  {
    title: "Equipment Name",
    dataIndex: "equipmentName",
    render: (value, row, col, isDisable, item) => {
      const { name } = item._id;
      return <span>{name}</span>;
    },
  },
  {
    title: "High",
    dataIndex: "count",
    render: (value, row, col, isDisable, item) => {
      const count = alertCount(item.data, "High");
      return <span>{count}</span>;
    },
  },
  {
    title: "Medium",
    dataIndex: "count",
    render: (value, row, col, isDisable, item) => {
      const count = alertCount(item.data, "Medium");
      return <span>{count}</span>;
    },
  },
  {
    title: "Low",
    dataIndex: "count",
    render: (value, row, col, isDisable, item) => {
      const count = alertCount(item.data, "Low");

      return <span>{count}</span>;
    },
  },
  {
    title: "Unknown",
    dataIndex: "count",

    render: (value, row, col, isDisable, item) => {
      const count = alertCount(item.data, "Unknown");
      return <span>{count}</span>;
    },
  },
  {
    title: "Last Update",
    dataIndex: "timeStamp",
    render: (value, row, col, isDisable, item) => {
      if (item.data[0]) {
        const { timestamp } = item.data[0];
        return (
          <span className="time-display">
            {` ${getTimeInFormat(timestamp)}`}
          </span>
        );
      }
      return <span>--</span>;
    },
  },
];

export const COMMON_PLOTS_CARD = {
  ALERT_SUMMARY_CARD: "Active alert summary card",
  RUN_STATUS_CARD: "Run status Card",
};
