import React from "react";
import PropType from "prop-types";
import { Empty } from "antd";
import _ from "lodash";

function StdDeviationCardDetails(props) {
  const dataLength = props?.cardValue?.cardValue[0]?.data.length - 1;
  let unit = props.cardValue.cardValue[0].plantUnit || "";
  if (unit === "psia" || unit === "psig") {
    unit = "psi";
  }
  if (unit === "bara" || unit === "barg") {
    unit = "bar";
  }
  if (unit === "kPa" || unit === "kPag") {
    unit = "kPa";
  }
  return (
    <>
      {props.cardValue &&
      props.cardValue.cardValue[0] &&
      props.cardValue.cardValue[0].data &&
      props.cardValue.cardValue[0].data.length > 0 &&
      (props.cardValue.cardValue[0].data[dataLength].stdValue ||
        props.cardValue.cardValue[0]._id) ? (
        <>
          <div className="card-body-section">
            <div className="card-info">
              {_.isFinite(
                props.cardValue.cardValue[0].data[dataLength].stdValue
              )
                ? props.cardValue.cardValue[0].data[
                    dataLength
                  ].stdValue.toFixed(2)
                : '---'}
            </div>
            <div className="card-unit">{unit || ''}</div>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}

StdDeviationCardDetails.prototypes = {
  cardValue: PropType.array,
};

export default StdDeviationCardDetails;
