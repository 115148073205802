import Type from '../Types/PIMComponent.types';
import { endPoints } from '../../libs/endpoints';
import { GETRequest, PUTRequest} from '../../api/request';


export function fetchPIMConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_PIM_CONFIG_DATA_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.pimConfig}`)
      .then((res) =>
        dispatch({
          type: Type.GET_PIM_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_PIM_CONFIG_DATA_FAILURE, payload: err })
      );
  };
}

// export function fetchConfigDataReset() {
//   return function (dispatch) {
//     dispatch({ type: Type.GET_PIM_CONFIG_DATA_RESET });
//   };
// }

export function saveDesignData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_PIM_DESIGN_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.pimDesign}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_PIM_DESIGN_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.UPDATE_PIM_DESIGN_DATA_FAILURE, payload: err })
      );
  };
}

export function saveIterationData(id,payload)
{
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_PIM_ITERATION_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.pimIteration}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_PIM_ITERATION_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.UPDATE_PIM_ITERATION_DATA_FAILURE, payload: err })
      );
  };
}


export function saveDesignDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_PIM_DESIGN_DATA_RESET });
  };
}

export function clearPIMReducer() {
    return function (dispatch) {
      dispatch({ type: Type.RESET_PIM_REDUCER });
    };
  }