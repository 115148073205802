import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RenderDiscussion from './components/RenderDiscussion';
import { Card, Grid, IconButton } from '@mui/material';
import ShelvedModal from '../ShelvedModal/ShelvedModal';
import { ArrowBackIos } from '@mui/icons-material';
import RenderAlertPlotButtons from './components/RenderAlertPlotButtons';
import RenderDescription from './components/RenderDescription';
import RenderPlots from './components/RenderPlot';
import AlertKPIPlots from '../AlertPlots/alertKPIPlots';
import RenderAlertInfo from './components/RenderAlertInfo';
import RenderAlertInfoHeader from './components/RenderAlerInfoHeader';
import RenderComment from './components/RenderComment';

const RenderMobileView = (props, stateData) => {
  const { data, editAlertDetials, info } = props;
  const [detailTab] = useState(1)

  const getClassName = (index) => {
    if (Number(index) % 2 === 0) {
      return "accordian_bg_1"
    }
    else {
      return "accordian_bg_2"
    }
  }

  return (
    <div className="alertDetails">
      <Grid container>
        <Grid item xs={1} md={1}>
          <div className='alertInfo'>
            <IconButton variant='contained' className='back_button' onClick={() => editAlertDetials(false, {})}>
              <ArrowBackIos fontSize='small' className='back_button_logo' />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={11} md={11} className='alert_plot_button'>
          <div className='alertInfo'>
            {RenderAlertInfoHeader(props, data, stateData)}
          </div>
        </Grid>
      </Grid>
      


      <Accordion
        expanded={stateData.expanded === "1"}
        onChange={() => stateData.setExpanded(stateData.expanded === "1" ? false : "1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={getClassName("1")}
        >
          <Typography>Alert Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div>
              <Card>
                <div className='AlerSummary'>
                  {RenderDescription(data)}
                  {RenderPlots(data)}
                  {RenderComment(props, stateData)}
                </div>
              </Card>
              <ShelvedModal
                visible={stateData.shelvedModalVisibility}
                handleCancel={() => { stateData.setShelvedModalVisibility(false) }}
                handleOk={stateData.handleShelvedModalOk}
                mechademyAction={stateData.mechademyActions}
              />
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={stateData.expanded === "2"}
        onChange={() => stateData.setExpanded(stateData.expanded === "2" ? false : "2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={getClassName("2")}
        >
          <Typography>Alert Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className='alertInfo'>
              {/* {AlertInfo(props, stateData)} */}
              {RenderAlertInfo(data, stateData)}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={stateData.expanded === "3"}
        onChange={() => stateData.setExpanded(stateData.expanded === "3" ? false : "3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={getClassName("3")}
        >
          <Typography>Alert Plots</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {RenderAlertPlotButtons(detailTab, props)}
            <AlertKPIPlots
              info={info}
              alertId={data['_id']}
              activeKey={'2'}
              alertPrint={false}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion
        expanded={stateData.expanded === "4"}
        onChange={() => stateData.setExpanded(stateData.expanded === "4" ? false : "4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={getClassName("4")}
        >
          <Typography>Alert Chat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {RenderDiscussion(data)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}




export default RenderMobileView