import * as Types from "../Types/runTags.types";

const INITIAL_API_STATE = {
  getRunTags: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  submitRunTags: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  getRunTagsData: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  getRunTagList: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  submitNewRun: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  editRuns: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  deleteRun: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  filterRuns: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  runComments: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  recalculateRuns: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  totalRunNumber: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  totalFilterRunNumber: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  lastRunData: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorCode: null,
    message: null,
  },
  autoRecalculate: {
    isLoading: false,
    autoRecalculateMode: true
  },
  bulkDelete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
  },
  runTour: {
    isLoading: false,
    startTour: false
  }
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
};

export default function RunTagsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RUNTAGS_PROGRESS:
      return {
        ...state,
        getRunTags: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.GET_RUNTAGS_SUCCESS:
      return {
        ...state,
        getRunTags: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Types.GET_RUNTAGS_ERROR:
      return {
        ...state,
        getRunTags: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.SUBMIT_RUNTAGS_PROGRESS:
      return {
        ...state,
        submitRunTags: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.SUBMIT_RUNTAGS_SUCCESS:
      return {
        ...state,
        submitRunTags: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.SUBMIT_RUNTAGS_ERROR:
      return {
        ...state,
        submitRunTags: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.GET_RUNTAGS_DATA_PROGRESS:
      return {
        ...state,
        getRunTagsData: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.GET_RUNTAGS_DATA_SUCCESS:
      return {
        ...state,
        getRunTagsData: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.GET_RUNTAGS_DATA_ERROR:
      return {
        ...state,
        getRunTagsData: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.GET_RUNTAGS_LIST_PROGRESS:
      return {
        ...state,
        getRunTagList: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.GET_RUNTAGS_LIST_SUCCESS:
      return {
        ...state,
        getRunTagList: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.GET_RUNTAGS_LIST_ERROR:
      return {
        ...state,
        getRunTagList: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.SUBMIT_NEWRUN_PROGRESS:
      return {
        ...state,
        submitNewRun: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.SUBMIT_NEWRUN_SUCCESS:
      return {
        ...state,
        submitNewRun: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.SUBMIT_NEWRUN_ERROR:
      return {
        ...state,
        submitNewRun: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.EDIT_RUN_PROGRESS:
      return {
        ...state,
        editRuns: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.EDIT_RUN_SUCCESS:
      return {
        ...state,
        editRuns: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.EDIT_RUN_ERROR:
      return {
        ...state,
        editRuns: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.DELETE_RUN_PROGRESS:
      return {
        ...state,
        deleteRun: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.DELETE_RUN_SUCCESS:
      return {
        ...state,
        deleteRun: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.DELETE_RUN_ERROR:
      return {
        ...state,
        deleteRun: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.FILTER_RUNTAGS_PROGRESS:
      return {
        ...state,
        filterRuns: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.FILTER_RUNTAGS_SUCCESS:
      return {
        ...state,
        filterRuns: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.FILTER_RUNTAGS_ERROR:
      return {
        ...state,
        filterRuns: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.RECALCULATE_RUN_PROGRESS:
      return {
        ...state,
        recalculateRuns: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.RECALCULATE_RUN_SUCCESS:
      return {
        ...state,
        recalculateRuns: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.RECALCULATE_RUN_ERROR:
      return {
        ...state,
        recalculateRuns: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.SUBMIT_RUN_COMMENT_PROGRESS:
      return {
        ...state,
        runComments: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };

    case Types.SUBMIT_RUN_COMMENT_SUCCESS:
      return {
        ...state,
        runComments: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };

    case Types.SUBMIT_RUN_COMMENT_ERROR:
      return {
        ...state,
        runComments: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Types.GET_RUN_NUMBER_PROGRESS:
      return {
        ...state,
        totalRunNumber: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.GET_RUN_NUMBER_SUCCESS:
      return {
        ...state,
        totalRunNumber: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Types.GET_RUN_NUMBER_ERROR:
      return {
        ...state,
        totalRunNumber: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Types.GET_FILTER_RUN_NUMBER_PROGRESS:
      return {
        ...state,
        totalFilterRunNumber: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.GET_FILTER_RUN_NUMBER_SUCCESS:
      return {
        ...state,
        totalFilterRunNumber: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Types.GET_FILTER_RUN_NUMBER_ERROR:
      return {
        ...state,
        totalFilterRunNumber: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.GET_LAST_RUN_DATA_PROGRESS:
      return {
        ...state,
        lastRunData: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.GET_LAST_RUN_DATA_SUCCESS:
      return {
        ...state,
        lastRunData: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case Types.GET_LAST_RUN_DATA_ERROR:
      return {
        ...state,
        lastRunData: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case Types.CLEAR_RUNTAGS_STATE:
      return {
        ...state,
        submitNewRun: {
          isSuccess: false,
        },
        deleteRun: {
          isSuccess: false,
        },
      };
    case Types.CLEAR_SUBMIT_COMMENT_STATE:
      return {
        ...state,
        runComments: {
          isSuccess: false,
        },
        getRunTagList: {
          isSuccess: false,
        },
        getRunTagsData: {
          isSuccess: false,
        },
      };
    case Types.CLEAR_EDIT_STATE:
      return {
        ...state,
        getRunTagList: {
          isSuccess: false,
        },
        getRunTagsData: {
          isSuccess: false,
        },
        editRuns: {
          isSuccess: false,
        },
      };
    case Types.CLEAR_FILTER_STATE:
      return {
        ...state,
        filterRuns: {
          isSuccess: false,
          isError: false,
        },
      };
    case Types.CLEAR_RECALCULATE_STATE:
      return {
        ...state,
        getRunTagList: {
          isSuccess: false,
        },
        getRunTagsData: {
          isSuccess: false,
        },
        recalculateRuns: {
          isSuccess: false,
        },
      };
    case Types.CLEAR_ERROR_STATE:
      return {
        ...state,
        submitNewRun: {
          isError: false,
        },
        runComments: {
          isError: false,
        },
        filterRuns: {
          isError: false,
        },
      };
    case Types.CLEAR_RUNTAG_STATE:
      return {
        ...state,
        getRunTagsData: {
          isSuccess: false,
        },
      };
    case Types.AUTO_RECALC_STATE_SUCCESS:
      return {
        ...state,
        autoRecalculate: {
          isLoading: false,
          autoRecalculateMode: action.payload
        }
      };
    case Types.RUN_TOUR_CHANGE:
      return {
        ...state,
        runTour: {
          isLoading: false,
          startTour: action.payload
        }
      }
    case Types.BULK_DELETE_RUN_PROGRESS:
      return {
        ...state,
        bulkDelete: {
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      };
    case Types.BULK_DELETE_RUN_SUCCESS:
      return {
        ...state,
        bulkDelete: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: action.payload
        },
        getRunTagList:{
          isSuccess: false
        }
      };
    case Types.BULK_DELETE_RUN_ERROR:
      return {
        ...state,
        bulkDelete: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          data: action.payload
        },
        getRunTagList:{
          isSuccess: false
        }
      };
    default:
      return {
        ...INITIAL_STATE,
      };
  }
}
