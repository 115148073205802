export default {
  ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_PROGRESS: "ADD_MOTOR_DESIGN_DATA_PROGRESS",
  ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_SUCCESS: "ADD_MOTOR_DESIGN_DATA_SUCCESS",
  ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_FAILURE: "ADD_MOTOR_DESIGN_DATA_FAILURE",
  ADD_SYNCHRONOUS_MOTOR_DESIGN_DATA_RESET: "ADD_MOTOR_DESIGN_DATA_RESET",

  GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_PROGRESS: "GET_MOTOR_CONFIG_DATA_PROGRESS",
  GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_SUCCESS: "GET_MOTOR_CONFIG_DATA_SUCCESS",
  GET_SYNCHRONOUS_MOTOR_CONFIG_DATA_FAILURE: "GET_MOTOR_CONFIG_DATA_FAILURE",
  GET_MOTOR_CONFIG_DATA_RESET: "GET_MOTOR_CONFIG_DATA_RESET",

  ADD_PERFORMANCE_DATA_PROGRESS: "ADD_PERFORMANCE_DATA_PROGRESS",
  ADD_PERFORMANCE_DATA_SUCCESS: "ADD_PERFORMANCE_DATA_SUCCESS",
  ADD_PERFORMANCE_DATA_FAILURE: "ADD_PERFORMANCE_DATA_FAILURE",
  ADD_PERFORMANCE_DATA_RESET: "ADD_PERFORMANCE_DATA_RESET",

  ADD_MOTOR_PARAMETER_DATA_PROGRESS: "ADD_MOTOR_PARAMETER_DATA_PROGRESS",
  ADD_MOTOR_PARAMETER_DATA_SUCCESS: "ADD_MOTOR_PARAMETER_DATA_SUCCESS",
  ADD_MOTOR_PARAMETER_DATA_FAILURE: "ADD_MOTOR_PARAMETER_DATA_FAILURE",
  ADD_MOTOR_PARAMETER_DATA_RESET: "ADD_MOTOR_PARAMETER_DATA_RESET",

  ADD_PERFORMANCE_CURVES_DATA_PROGRESS: "ADD_PERFORMANCE_DATA_CURVES_PROGRESS",
  ADD_PERFORMANCE_CURVES_DATA_SUCCESS: "ADD_PERFORMANCE_DATA_CURVES_SUCCESS",
  ADD_PERFORMANCE_CURVES_DATA_FAILURE: "ADD_PERFORMANCE_DATA_CURVES_FAILURE",
  ADD_PERFORMANCE_CURVES_DATA_RESET: "ADD_PERFORMANCE_DATA_CURVES_RESET",

  ADD_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS:
    "ADD_STARTING_CHARACTERISTICS_CURVES_DATA_PROGRESS",
  ADD_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS:
    "ADD_STARTING_CHARACTERISTICS_CURVES_DATA_SUCCESS",
  ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE:
    "ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE",
  ADD_STARTING_CHARACTERISTICS_CURVES_DATA_RESET:
    "ADD_STARTING_CHARACTERISTICS_CURVES_DATA_FAILURE",

  ADD_VARIABLE_SPEED_CURVES_DATA_PROGRESS:
    "ADD_VARIABLE_SPEED_CURVES_DATA_PROGRESS",
  ADD_VARIABLE_SPEED_CURVES_DATA_SUCCESS:
    "ADD_VARIABLE_SPEED_CURVES_DATA_SUCCESS",
  ADD_VARIABLE_SPEED_CURVES_DATA_FAILURE:
    "ADD_VARIABLE_SPEED_CURVES_DATA_FAILURE",
  ADD_VARIABLE_SPEED_CURVES_DATA_RESET: "ADD_VARIABLE_SPEED_CURVES_DATA_RESET",

  ADD_DRIVE_TYPE_PROGRESS: "ADD_DRIVE_TYPE_PROGRESS",
  ADD_DRIVE_TYPE_SUCCESS: "ADD_DRIVE_TYPE_SUCCESS",
  ADD_DRIVE_TYPE_FAILURE: "ADD_DRIVE_TYPE_FAILURE",

  GET_DRIVE_TYPE_PROGRESS: "GET_DRIVE_TYPE_PROGRESS",
  GET_DRIVE_TYPE_SUCCESS: "GET_DRIVE_TYPE_SUCCESS",
  GET_DRIVE_TYPE_FAILURE: "GET_DRIVE_TYPE_FAILURE",

  RESET_MOTOR_REDUCER: "RESET_MOTOR_REDUCER",
};
