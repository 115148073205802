import { Icon, Input } from "antd";
import React from "react";

export const initialData = [{ name: "expression", value: "", comment: "" }, { name: "expression 2", value: "", comment: "" }];

const { TextArea } = Input;

export const DesignDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      if (value === "expression" || value === "expression 2") {
        return (
          <div>
            {value === "expression" ? "expression 1": "expression 2"} <span className="mandatory">{value ==="expression"? "*": ""}</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: "Value",
    dataIndex: "value",
    render: (value, row, col, disabled, item) => {
      //console.log(value)
      if (item.name === "expression" || item.name === "expression 2") {
        return (
          <div>
            <Input
              type="text"
              value={item.value}
              disabled={disabled}
              AutoComplete="off"
              style={{ width: "300px" }}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "text")
              }
              className={
                item.name === "expression" && !item.value && config.saveListener
                  ? "error"
                  : "not-error"
              }
            />
          </div>
        );
      }
    },
  },
  {
    title: "Comment",
    dataIndex: "comment",
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          <TextArea
            type="text"
            value={item.comment}
            disabled={disabled}
            AutoComplete="off"
            style={{ width: "300px" }}
            onChange={(e) => 
              config.handleChange(e.target.value, "comment", "text", item.name)
            }
            className={
              item.name === "expression" && !item.value && config.saveListener
                ? "error"
                : "not-error"
            }
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const ExpressionDemoTable = (config) => [
  {
    title: "Operator",
    dataIndex: "function",
    render: (value, row, col, disabled, item) => {
      const selectedOperator = arithmeticTable.filter((item) => {
        return item.function === value;
      });
      return <div> {selectedOperator[0].operator} </div>;
    },
  },
  {
    title: "Name",
    dataIndex: "operator",
    render: (value, row, col, disabled, item) => {
      const selectedName = arithmeticTable.filter((item) => {
        return item.operator === value;
      });
      return <div> {selectedName[0].function} </div>;
    },
  },
  {
    title: "Example",
    dataIndex: "operator",
    render: (value, row, col, disabled) => {
      const selectedExample = arithmeticTable.filter((item) => {
        return item.operator === value;
      });
      return <div> {selectedExample[0].example} </div>;
    },
  },
];

export const CalculatedTagsDemoTable = (config) => [
  {
    title: "Equation",
    dataIndex: "equation",
    render: (value, row, col, disabled, item) => {
      const selectedOperator = calculatedEquations.filter((item) => {
        return item.equation === value;
      });
      return <div> {selectedOperator[0].equation} </div>;
    },
  },
  {
    title: "Calculated Variable Name",
    dataIndex: "calculatedTag",
    render: (value, row, col, disabled, item) => {
      const selectedName = calculatedEquations.filter((item) => {
        return item.calculatedTag === value;
      });
      return <div> {selectedName[0].calculatedTag} </div>;
    },
  },
];

export const arithmeticTable = [
  { function: "Addition", operator: "+", example: "Num1+Num2" },
  { function: "Subtraction", operator: "-", example: "Num1-Num2" },
  { function: "Multiplication", operator: "*", example: "Num1*Num2" },
  { function: "Division", operator: "/", example: "Num1/Num2" },
  { function: "Modulus", operator: "%", example: "Num1%Num2" },
  { function: "Exponentiation", operator: "**", example: "Num1**Num2" },
  { function: "Floor division", operator: "//", example: "Num1//Num2" },
  { function: "Mixed", operator: "* +", example: "3*Num2+2*Num1" },
];

export const calculatedEquations = [
  {equation: "100 * ( output1 - output2 ) / output1", calculatedTag: "Calculated Value 1"},
  {equation: "100 * ( output1 - num1 ) / output1", calculatedTag: "Calculated Value 2"},
  {equation: "100 * ( output1 - num2 ) / output1", calculatedTag: "Calculated Value 3"},
  {equation: "100 * ( output1 - num3 ) / output1", calculatedTag: "Calculated Value 4"},
  {equation: "100 * ( output2 - num1 ) / output2", calculatedTag: "Calculated Value 5"},
  {equation: "100 * ( output2 - num2 ) / output2", calculatedTag: "Calculated Value 6"},
  {equation: "100 * ( output2 - num3 ) / output2", calculatedTag: "Calculated Value 7"},
  {equation: "100 * ( num1 - num2 ) / num1", calculatedTag: "Calculated Value 8"},
  {equation: "100 * ( num1 - num3 ) / num1", calculatedTag: "Calculated Value 9"},
  {equation: "100 * ( num2 - num3 ) / num2", calculatedTag: "Calculated Value 10"},
]