import * as Types from "../Types/runTags.types";
import {
  GETRequest,
  PUTRequest,
  POSTRequest,
  DeleteRequest,
} from "../../api/request";
import moment from "moment";

export const getRunTags = (id) => (dispatch) => {
  dispatch({ type: Types.GET_RUNTAGS_PROGRESS });
  GETRequest(`run/tag?equipmentId=${id}`)
    .then((res) =>
      dispatch({ type: Types.GET_RUNTAGS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: Types.GET_RUNTAGS_ERROR, payload: err }));
};

export const submitQuestions = (payload, id) => (dispatch) => {
  dispatch({ type: Types.SUBMIT_RUNTAGS_PROGRESS });
  PUTRequest(`run/${id}`, payload)
    .then((res) =>
      dispatch({ type: Types.SUBMIT_RUNTAGS_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.SUBMIT_RUNTAGS_ERROR, payload: err })
    );
};

export const getRunTagsData = (id) => (dispatch) => {
  dispatch({ type: Types.GET_RUNTAGS_DATA_PROGRESS });
  GETRequest(`run/${id}`)
    .then((res) =>
      dispatch({ type: Types.GET_RUNTAGS_DATA_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_RUNTAGS_DATA_ERROR, payload: err })
    );
};

export const getRunTagsList = (id, skip, limit) => (dispatch) => {
  dispatch({ type: Types.GET_RUNTAGS_LIST_PROGRESS });
  GETRequest(`runs?equipmentId=${id}&skip=${skip}&limit=${limit}`)
    .then((res) =>
      dispatch({ type: Types.GET_RUNTAGS_LIST_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_RUNTAGS_LIST_ERROR, payload: err })
    );
};

export const updateAutoRecalculate = (state) => (dispatch) => {
  dispatch({ type: Types.AUTO_RECALC_STATE_SUCCESS, payload: state });
}

export const addNewRuns = (payload, mode) => (dispatch) => {
  if (mode === false) {
    dispatch({ type: Types.SUBMIT_NEWRUN_PROGRESS });
    POSTRequest("runstatus", payload)
      .then((res) => {
        dispatch({ type: Types.SUBMIT_NEWRUN_SUCCESS, payload: res.body });
      }
      )
      .catch((err) =>
        dispatch({ type: Types.SUBMIT_NEWRUN_ERROR, payload: err })
      );
  }
  else if (mode === true) {
    let start = new Date(payload.start)
    start.setDate(start.getDate() - 1)
    start = start.toISOString()
    const date = moment(start).format("YYYY-MM-DDThh:mm");
    dispatch({ type: Types.SUBMIT_NEWRUN_PROGRESS });
    POSTRequest("runstatus", payload)
      .then((res) => {
        dispatch({ type: Types.RECALCULATE_RUN_PROGRESS });
        PUTRequest(`runs/kpi?equipmentId=${payload.equipmentId}&time=${date}`)
          .then((res) => {
            dispatch({ type: Types.RECALCULATE_RUN_SUCCESS, payload: res.body })
          }
          )
          .catch((err) =>
            dispatch({ type: Types.RECALCULATE_RUN_ERROR, payload: err })
          );
        dispatch({ type: Types.SUBMIT_NEWRUN_SUCCESS, payload: res.body });
      }
      )
      .catch((err) =>
        dispatch({ type: Types.SUBMIT_NEWRUN_ERROR, payload: err })
      );
  }
};

export const EditRun = (id, payload, mode, recalcDate) => (dispatch) => {
  if(mode === false){
    dispatch({ type: Types.EDIT_RUN_PROGRESS });
    PUTRequest(`runstatus/${id}`, payload)
      .then((res) =>
        dispatch({ type: Types.EDIT_RUN_SUCCESS, payload: res.body })
      )
      .catch((err) => dispatch({ type: Types.EDIT_RUN_ERROR, payload: err }));
  }
  else if( mode === true ){
    let start = new Date(recalcDate)
    start.setDate(start.getDate() - 1)
    start = start.toISOString()
    const date = moment(start).format("YYYY-MM-DDThh:mm");
    dispatch({ type: Types.EDIT_RUN_PROGRESS });
    PUTRequest(`runstatus/${id}`, payload)
      .then((res) =>{
        dispatch({ type: Types.RECALCULATE_RUN_PROGRESS });
        PUTRequest(`runs/kpi?equipmentId=${payload.equipmentId}&time=${date}`)
          .then((res) => {
            dispatch({ type: Types.RECALCULATE_RUN_SUCCESS, payload: res.body })
          }
          )
          .catch((err) =>
            dispatch({ type: Types.RECALCULATE_RUN_ERROR, payload: err })
          );
        dispatch({ type: Types.EDIT_RUN_SUCCESS, payload: res.body })
      }
      )
      .catch((err) => dispatch({ type: Types.EDIT_RUN_ERROR, payload: err }));
  }
};

export const deleteRuns = (id, startTime, equipmentId,mode) => (dispatch) => {
  if(mode === false){
    dispatch({ type: Types.DELETE_RUN_PROGRESS });
    DeleteRequest(`runstatus/${id}`)
      .then((res) =>
        dispatch({ type: Types.DELETE_RUN_SUCCESS, payload: res.body })
      )
      .catch((err) => dispatch({ type: Types.DELETE_RUN_ERROR, payload: err }));
  }
  else if (mode === true){
    let start = new Date(startTime)
    start.setDate(start.getDate() - 1)
    start = start.toISOString()
    const date = moment(start).format("YYYY-MM-DDThh:mm"); 
    dispatch({ type: Types.DELETE_RUN_PROGRESS });
    DeleteRequest(`runstatus/${id}`)
      .then((res) =>{
        dispatch({ type: Types.DELETE_RUN_SUCCESS, payload: res.body })
        dispatch({ type: Types.RECALCULATE_RUN_PROGRESS });
        PUTRequest(`runs/kpi?equipmentId=${equipmentId}&time=${date}`)
          .then((res) => {
            dispatch({ type: Types.RECALCULATE_RUN_SUCCESS, payload: res.body })
          }
          )
          .catch((err) =>
            dispatch({ type: Types.RECALCULATE_RUN_ERROR, payload: err })
          );
      }
      )
      .catch((err) => dispatch({ type: Types.DELETE_RUN_ERROR, payload: err }));
  }
};

export const getEquipmentTags = (id) => (dispatch) => {
  dispatch({ type: Types.GET_RUNTAGS_PROGRESS });
  GETRequest(`/run/tag?equipmentId=${id}`)
    .then((res) =>
      dispatch({ type: Types.GET_RUNTAGS_SUCCESS, payload: res.body })
    )
    .catch((err) => dispatch({ type: Types.GET_RUNTAGS_ERROR, payload: err }));
};

export const filterRunTag = (id, startDate, endDate, skip, limit) => (
  dispatch
) => {
  dispatch({ type: Types.FILTER_RUNTAGS_PROGRESS });
  GETRequest(
    `run/equipmentId/${id}/start/${startDate}/to/${endDate}/skip/${skip}/limit/${limit}`
  )
    .then((res) => {
      dispatch({ type: Types.FILTER_RUNTAGS_SUCCESS, payload: res.body });
    })
    .catch((err) => {
      dispatch({ type: Types.FILTER_RUNTAGS_ERROR, payload: err });
    });
};

export const addRunComments = (id, payload) => (dispatch) => {
  dispatch({ type: Types.SUBMIT_RUN_COMMENT_PROGRESS });
  PUTRequest(`runs/${id}/comment`, { payload })
    .then((res) =>
      dispatch({ type: Types.SUBMIT_RUN_COMMENT_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.SUBMIT_RUN_COMMENT_ERROR, payload: err })
    );
};

export const getRunNumber = (equipmentId) => (dispatch) => {
  dispatch({ type: Types.GET_RUN_NUMBER_PROGRESS });
  GETRequest(`runs/equipment/${equipmentId}/number`)
    .then((res) =>
      dispatch({ type: Types.GET_RUN_NUMBER_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_RUN_NUMBER_ERROR, payload: err })
    );
};

export const getFilterRunNumber = (equipmentId, start, end) => (dispatch) => {
  dispatch({ type: Types.GET_FILTER_RUN_NUMBER_PROGRESS });
  GETRequest(`run/filter/equipmentId/${equipmentId}/start/${start}/to/${end}`)
    .then((res) =>
      dispatch({ type: Types.GET_FILTER_RUN_NUMBER_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_FILTER_RUN_NUMBER_ERROR, payload: err })
    );
};

export const recalculateKPIs = (equipmentId, time) => (dispatch) => {
  dispatch({ type: Types.RECALCULATE_RUN_PROGRESS });
  PUTRequest(`runs/kpi?equipmentId=${equipmentId}&time=${time}`)
    .then((res) =>
      dispatch({ type: Types.RECALCULATE_RUN_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.RECALCULATE_RUN_ERROR, payload: err })
    );
};

export const getLatestRunData = (equipmentId) => (dispatch) => {
  dispatch({ type: Types.GET_LAST_RUN_DATA_PROGRESS });
  GETRequest(`runs/equipment/${equipmentId}/previous`)
    .then((res) =>
      dispatch({ type: Types.GET_LAST_RUN_DATA_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_LAST_RUN_DATA_ERROR, payload: err })
    );
};

export const bulkDeleteRun = (equipmentId, payload, mode, sortedDate) => (dispatch) => {
  if(mode === false){
    const {IdArray} = payload
    dispatch({ type: Types.BULK_DELETE_RUN_PROGRESS });
    DeleteRequest(`runs/equipment/${equipmentId}/bulk`, IdArray)
      .then((res) =>
        dispatch({ type: Types.BULK_DELETE_RUN_SUCCESS, payload: res.body })
      )
      .catch((err) =>
        dispatch({ type: Types.BULK_DELETE_RUN_ERROR, payload: err })
      );
  }
  else if( mode === true){
   const {IdArray} = payload
   let start = new Date(sortedDate[0])
   start.setDate(start.getDate() - 1)
   start = start.toISOString()
   const date = moment(start).format("YYYY-MM-DDThh:mm");
   dispatch({ type: Types.BULK_DELETE_RUN_PROGRESS  });
   DeleteRequest(`runs/equipment/${equipmentId}/bulk`, IdArray)
     .then((res) =>{
       dispatch({ type: Types.BULK_DELETE_RUN_SUCCESS, payload: res.body })
       dispatch({ type: Types.RECALCULATE_RUN_PROGRESS });
       PUTRequest(`runs/kpi?equipmentId=${equipmentId}&time=${date}`)
         .then((res) => {
           dispatch({ type: Types.RECALCULATE_RUN_SUCCESS, payload: res.body })
         }
         )
         .catch((err) =>
           dispatch({ type: Types.RECALCULATE_RUN_ERROR, payload: err })
         );
     }
     )
     .catch((err) => dispatch({ type: Types.BULK_DELETE_RUN_ERROR, payload: err }));
  }
};

export const setTourState = (startTour) => (dispatch) =>{
  dispatch({type: Types.RUN_TOUR_CHANGE, payload: startTour})
}

export const clearState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_RUNTAGS_STATE });
};

export const clearCommentState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_SUBMIT_COMMENT_STATE });
};

export const clearFilterState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_FILTER_STATE });
};

export const clearEditState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_EDIT_STATE });
};

export const clearRecalculateState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_RECALCULATE_STATE });
};

export const clearErrorState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_ERROR_STATE });
};

export const clearRunTagState = () => (dispatch) => {
  dispatch({ type: Types.CLEAR_RUNTAG_STATE });
};
