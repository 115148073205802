import _ from "lodash";

export const validateNumber = (e, val) => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 110) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    e.keyCode === 67 ||
    e.keyCode === 65 ||
    e.keyCode === 86 ||
    e.keyCode === 46 ||
    (e.keyCode === 190 && !(val && val.toString().includes(".")))
  ) {
  } else {
    e.preventDefault();
  }
};
export const validateNumberNegative = (e, val) => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    e.keyCode === 107 ||
    e.keyCode === 109 ||
    e.keyCode === 67 ||
    e.keyCode === 65 ||
    e.keyCode === 86 ||
    e.keyCode === 189 ||
    (e.keyCode === 190 && !(val && val.toString().includes("."))) ||
    (e.key === "-" &&
      e.target.value.trim().length === 0 &&
      typeof e.target.value &&
      typeof e.target.value === "string")
  ) {
  } else {
    e.preventDefault();
  }
};

export const validateNonZeroInteger = (e, data) => {
  if(data.length === 0){
    if ((e.keyCode > 48 && e.keyCode <= 57) || e.keyCode === 8) {
      } 
    else {
      e.preventDefault();
    }
  }
};

export const validateNegativeInteger = (e, value) => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    e.key === "." ||
    (e.key === "-" &&
      e.target.value.trim().length === 0 &&
      typeof e.target.value &&
      typeof e.target.value === "string")
  ) {
    if (e.keyCode === 189 && value && value.indexOf("-") === 0) {
      e.preventDefault();
    }
    if (e.keyCode === 190 && value && value.indexOf(".") !== -1) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
};

export const isMaxExceeded = (values, maxValue) => {
  if (values.length > maxValue) {
    return true;
  }
  return false;
};

export const validateXorCondition = (item1, item2) => {
  if (
    (item1 && item1.toString() && item2 && item2.toString()) ||
    (!item1 && !item2)
  ) {
    return true;
  }
  return false;
};

export const validateNaturalNumber = (e, value) => {
  if (
    (e.keyCode > 48 && e.keyCode <= 57) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    (e.keyCode === 48 && value > 0)
  ) {
  } else {
    e.preventDefault();
  }
};

export const validateShelvedNumber = (e, val) => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37
  ) {
  } else {
    e.preventDefault();
  }
};

export const validateTypeNumberIQR = (e, value) => {
  if (
    (_.isNil(value) ||
      (typeof value === "string" && value.trim().length === 0)) &&
    e.key === "."
  ) {
    // to check first dot
    e.preventDefault();
  } else if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    e.key === "."
  ) {
    if (e.keyCode === 189 && value && value.toString().indexOf("-") === 0) {
      e.preventDefault();
    }
    if (e.keyCode === 190 && value && value.toString().indexOf(".") !== -1) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
};

/// this is to be used with new input types
export const newValidateNumber = (e, val) => {
  if ((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 32) {
    e.preventDefault();
  } else {
  }
};

export const validatePositiveNumber = (e, val) => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) || 
    (e.keyCode >= 96 && e.keyCode <= 110) ||
    e.keyCode === 8 ||
    e.keyCode === 9 ||
    e.keyCode === 39 ||
    e.keyCode === 37 ||
    e.keyCode === 67 ||
    e.keyCode === 65 ||
    e.keyCode === 86 ||
    e.keyCode === 46 ||
    e.keyCode === 109 ||
    (e.keyCode === 190 && !(val && val.toString().includes(".")))
  ) {
  } else {
    e.preventDefault();
  }
} 
