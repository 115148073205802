import {
  ALERT_DETAILS_SAVE_ERROR,
  ALERT_DETAILS_SAVE_REQUEST,
  ALERT_DETAILS_SAVE_SUCCESS,
  ALERT_DETAILS_CLEAR_STATE,
  ALERT_PLOT_GET_REQUEST,
  ALERT_PLOT_GET_ERROR,
  ALERT_PLOT_GET_SUCCESS,
  ALERT_LABELS_GET_REQUEST,
  ALERT_LABELS_GET_ERROR,
  ALERT_LABELS_GET_SUCCESS,
  ALERT_ASSIGNEES_GET_REQUEST,
  ALERT_ASSIGNEES_GET_ERROR,
  ALERT_ASSIGNEES_GET_SUCCESS,
  EVENT_DETAILS_GET_REQUEST,
  EVENT_DETAILS_GET_SUCCESS,
  EVENT_DETAILS_GET_ERROR,
} from '../Types/Alerts.types';
import { endPoints } from '../../libs/endpoints';
import { PUTRequest, GETRequest, POSTRequest } from '../../api/request';
import Notification ,{Types} from '../../common/Notification/Notification';

export function alertDetail(payload) {
  const alertEditPayload = {};
  Object.keys(payload).forEach((key) => {
    if (payload[key]) {
      alertEditPayload[key] = payload[key];
    }
  });
  return function (dispatch) {
    dispatch({ type: ALERT_DETAILS_SAVE_REQUEST });
    PUTRequest(endPoints.alerts, alertEditPayload)
      .then((res) => {
        dispatch({
          type: ALERT_DETAILS_SAVE_SUCCESS,
          payload: res.body,
        });
      })
      .catch((err) => {
        if(alertEditPayload?.comment?.length > 50000 || alertEditPayload?.insights?.length> 50000){
          Notification.show(Types.Warning, "Comments/Insights length must be less than 50000 chars");
          }
        else
        dispatch({ type: ALERT_DETAILS_SAVE_ERROR, payload: err });
      });
  };
}

export function alertDetailClearState() {
  return function (dispatch) {
    dispatch({ type: ALERT_DETAILS_CLEAR_STATE });
  };
}

export const getAletPlotByAlertId = (alertId) => (dispatch) => {
  dispatch({ type: ALERT_PLOT_GET_REQUEST });
  GETRequest(`${endPoints.alertPlots}/${alertId}`)
    .then((res) => {
      dispatch({
        type: ALERT_PLOT_GET_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) => {
      dispatch({ type: ALERT_PLOT_GET_ERROR, payload: err });
    });
};

export const getLabels = () => (dispatch) => {
  dispatch({ type: ALERT_LABELS_GET_REQUEST });
  GETRequest(`${endPoints.labels}`)
    .then((res) => {
      dispatch({
        type: ALERT_LABELS_GET_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) => {
      dispatch({ type: ALERT_LABELS_GET_ERROR, payload: err });
    });
};

export const getAssignees = (equipmentId) => (dispatch) => {
  dispatch({ type: ALERT_ASSIGNEES_GET_REQUEST });
  POSTRequest(`${endPoints.assignees}`, { equipmentId })
    .then((res) => {
      dispatch({
        type: ALERT_ASSIGNEES_GET_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) => {
      dispatch({ type: ALERT_ASSIGNEES_GET_ERROR, payload: err });
    });
};

export const getEventDetails = (eventId) => (dispatch) => {
  dispatch({ type: EVENT_DETAILS_GET_REQUEST });
  POSTRequest(`${endPoints.eventDetails}`, { eventId })
    .then((res) => {
      dispatch({
        type: EVENT_DETAILS_GET_SUCCESS,
        payload: res.body,
      });
    })
    .catch((err) => {
      dispatch({ type: EVENT_DETAILS_GET_ERROR, payload: err });
    });
};
