import { Switch, Icon } from "antd";
import React from "react";
import { UserManagementMessages } from "../../../constants/messages";
import {
  ROLE_ENUM,
  USER_MANGEMENT_TABLE_HEADER,
} from "../../../constants/variables";
import { elementsGaurd } from "../../../gaurds";
import { ElementPermissions } from "../../../permissions";
import { roles } from "../../../permissions/roles.permissions";
export const filterType = ["ACTIVE", "INACTIVE"];

const getClientName = (clientsList) => {
  let clientsName = clientsList.map((client) => {
    return client.name;
  });
  clientsName = [...new Set(clientsName)]
  return clientsName.join(" , ");
};


export const tableheader = (headerList) => [
  {
    title: USER_MANGEMENT_TABLE_HEADER.NAME,
    dataIndex: 'firstName',
    render: (value, row, col, disable, item) => `${value} ${item.lastName}`,
  },
  {
    title: USER_MANGEMENT_TABLE_HEADER.EMAIL,
    dataIndex: 'email',
  },
  {
    title: USER_MANGEMENT_TABLE_HEADER.WORK_PHONE,
    dataIndex: 'workPhone',
  },
  {
    title: USER_MANGEMENT_TABLE_HEADER.CLIENT_NAME,
    dataIndex: 'clientName',
    render: (value, row, col, disable, item) => getClientName(item.client),
  },
  {
    title: USER_MANGEMENT_TABLE_HEADER.ROLE,
    dataIndex: 'role',
    render: (value) => {
      /** == used as data type from backend and fronted are different */
      if (value.length > 0) {
        if (value[0] === roles['MECHADEMY_ADMIN'])
          return <span>{ROLE_ENUM['MechademyAdmin']}</span>;
        if (value[0] === roles['CLIENT_ADMIN'])
          return <span>{ROLE_ENUM.ClientAdmin}</span>;
        if (value[0] === roles['FACILITY_MANAGER'])
          return <span>{ROLE_ENUM.FacilityManager}</span>;
        if (value[0] === roles['SYSTEM_MANAGER'])
          return <span>{ROLE_ENUM.SystemEngineer}</span>;
        if (value[0] === roles['EQUIPMENT_MANAGER'])
          return <span>{ROLE_ENUM.EquipmentEngineer}</span>;
        if (value[0] === roles['MECHADEMY_FACILITY_MANAGER'])
          return <span>{ROLE_ENUM.mechademyFacilityManager}</span>;
      } else return null;
    },
  },
  {
    title: USER_MANGEMENT_TABLE_HEADER.STATUS,
    dataIndex: 'isActive',
    render: (value, row, col, disabled, item) => {
      if (headerList.UserRole === 'ClientAdmin') {
        if (
          item.role[0] !== 'ClientAdmin' &&
          item.role[0] !== 'MechademyFacilityManager'
        ) {
          return (
            <>
              {
                <Switch
                  className="user-management-switch"
                  checked={value}
                  onChange={(value) => headerList.changeStatus(value, item)}
                />
              }
              <span className="switch-status">
                {value
                  ? UserManagementMessages.STATUS_ACTIVE
                  : UserManagementMessages.STATUS_INACTIVE}
              </span>
            </>
          );
        }
        return (
          <span className="switch-status">
            {value
              ? UserManagementMessages.STATUS_ACTIVE
              : UserManagementMessages.STATUS_INACTIVE}
          </span>
        );
      }
      if (headerList.UserRole === 'MechademyFacilityManager') {
        if (item.role[0] !== 'ClientAdmin') {
          return (
            <>
              {headerList.userId !== item['_id']
                ? elementsGaurd(
                    <Switch
                      className="user-management-switch"
                      checked={value}
                      onChange={(value) => headerList.changeStatus(value, item)}
                    />,
                    ElementPermissions.EDIT_USER
                  )
                : ''}
              <span className="switch-status">
                {value
                  ? UserManagementMessages.STATUS_ACTIVE
                  : UserManagementMessages.STATUS_INACTIVE}
              </span>
            </>
          );
        }
        return (
          <span className="switch-status">
            {value
              ? UserManagementMessages.STATUS_ACTIVE
              : UserManagementMessages.STATUS_INACTIVE}
          </span>
        );
      }
      return (
        <>
          <Switch
            className="user-management-switch"
            checked={value}
            onChange={(value) => headerList.changeStatus(value, item)}
          />
          <span className="switch-status">
            {value
              ? UserManagementMessages.STATUS_ACTIVE
              : UserManagementMessages.STATUS_INACTIVE}
          </span>
        </>
      );
    },
  },
  elementsGaurd(
    {
      title: USER_MANGEMENT_TABLE_HEADER.ACTION,
      dataIndex: 'actions',
      render: (value, row, col, disabled, item) => (
        <div className="more-items">
          {!item.isEmailVerified &&
            (headerList.UserRole === 'MechademyFacilityManager' ? (
              headerList.userId !== item['_id'] ? (
                <button onClick={() => headerList.sendInvitation(item._id)}>
                  <Icon type="reload" style={{ width: '50%' }} />
                </button>
              ) : null
            ) : (
              <button onClick={() => headerList.sendInvitation(item)}>
                <Icon type="reload" style={{ width: '50%' }} />
              </button>
            ))}
          {headerList.UserRole === 'MechademyFacilityManager' ? (
            headerList.userId !== item['_id'] ? (
              <button onClick={() => headerList.editUser(item)}>
                <Icon type="edit" style={{ width: '50%' }} />
              </button>
            ) : null
          ) : (
            <button onClick={() => headerList.editUser(item)}>
              <Icon type="edit" style={{ width: '50%' }} />
            </button>
          )}
        </div>
      ),
    },
    ElementPermissions.EDIT_USER
  ),
];
