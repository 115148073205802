import * as Types from "../Types/systemSchematic.types";
import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

export const getSystemSchematic = (id) => (dispatch) => {
  dispatch({ type: Types.GET_SYSTEM_SCHEMATIC_PROGRESS });
  GETRequest(`${endPoints.systemSchematic}=${id}`)
    .then((res) =>
      dispatch({ type: Types.GET_SYSTEM_SCHEMATIC_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.GET_SYSTEM_SCHEMATIC_ERROR, payload: err })
    );
};

export const createSystemSchematic = (payload) => (dispatch) => {
  dispatch({ type: Types.ADD_SYSTEM_SCHEMATIC_PROGRESS });
  POSTRequest(`${endPoints.createSystemSchematic}`, payload)
    .then((res) =>
      dispatch({ type: Types.ADD_SYSTEM_SCHEMATIC_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.ADD_SYSTEM_SCHEMATIC_ERROR, payload: err })
    );
};

export const editSystemSchematic = (id, payload) => (dispatch) => {
  dispatch({ type: Types.EDIT_SYSTEM_SCHEMATIC_PROGRESS });
  PUTRequest(`${endPoints.createSystemSchematic}/${id}`, payload)
    .then((res) =>
      dispatch({ type: Types.EDIT_SYSTEM_SCHEMATIC_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: Types.EDIT_SYSTEM_SCHEMATIC_ERROR, payload: err })
    );
};

export const clearSystemSchematicState = () => (dispatch) =>
  dispatch({ type: Types.CLEAR_STATE });
