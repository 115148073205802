import * as Type from "../Types/commonPlots.types";

const INITIAL_API_STATE = {
  isError: false,
  isSuccess: false,
  errorCode: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  isLoading: false,
  data: [],
};

export default function commonPlotsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.COMMON_PLOTS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        apiState: {
          ...INITIAL_STATE,
          isSuccess: true,
        },
      };
    case Type.COMMON_PLOTS_FETCH_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case Type.COMMON_PLOTS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        apiState: {
          ...INITIAL_STATE,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
        },
      };

    case Type.COMMON_PLOTS_RESET_API_STATE: {
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };
    }
    case Type.COMMON_PLOTS_RESET:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
