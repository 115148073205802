import {
  CLIENTS_FETCH_ERROR,
  CLIENTS_FETCH_SUCCESS,
  CLIENTS_FETCH_PROGRESS,
  ADD_CLIENTS_FETCH_PROGRESS,
  ADD_CLIENTS_FETCH_SUCCESS,
  ADD_CLIENTS_FETCH_ERROR,
  CLIENTS_CLEAR_STATE,
  CLIENTS_INFO_FETCH_ERROR,
  CLIENTS_INFO_FETCH_PROGRESS,
  CLIENTS_INFO_FETCH_SUCCESS,
  EDIT_CLIENTS_FETCH_ERROR,
  EDIT_CLIENTS_FETCH_SUCCESS,
  EDIT_CLIENTS_FETCH_PROGRESS,
  CLIENTS_DETAILS_FETCH_PROGRESS,
  CLIENTS_DETAILS_FETCH_ERROR,
  CLIENTS_DETAILS_FETCH_SUCCESS,
  RESET_CLIENT_REDUCER,
} from "../Types/Client.types";

import { GETRequest, POSTRequest, PUTRequest } from "../../api/request";

export function clientList(query) {
  return function (dispatch) {
    dispatch({ type: CLIENTS_FETCH_PROGRESS });
    GETRequest("client", query)
      .then((res) => {
        dispatch({ type: CLIENTS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: CLIENTS_FETCH_ERROR, payload: err });
      });
  };
}

export function addNewClient(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_CLIENTS_FETCH_PROGRESS });
    POSTRequest("client", payload)
      .then((res) => {
        dispatch({ type: ADD_CLIENTS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_CLIENTS_FETCH_ERROR, payload: err });
      });
  };
}

export function getClientInfo(id) {
  return function (dispatch) {
    dispatch({ type: CLIENTS_INFO_FETCH_PROGRESS });
    GETRequest(`client/${id}/info`)
      .then((res) => {
        dispatch({ type: CLIENTS_INFO_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: CLIENTS_INFO_FETCH_ERROR, payload: err });
      });
  };
}

export function getClient(id) {
  return function (dispatch) {
    dispatch({ type: CLIENTS_DETAILS_FETCH_PROGRESS });
    GETRequest(`client/${id}`)
      .then((res) => {
        dispatch({ type: CLIENTS_DETAILS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: CLIENTS_DETAILS_FETCH_ERROR, payload: err });
      });
  };
}

export function editClient(payload, id) {
  return function (dispatch) {
    dispatch({ type: EDIT_CLIENTS_FETCH_PROGRESS });
    PUTRequest(`client/${id}`, payload)
      .then((res) => {
        dispatch({ type: EDIT_CLIENTS_FETCH_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: EDIT_CLIENTS_FETCH_ERROR, payload: err });
      });
  };
}

export function clearClientState() {
  return function (dispatch) {
    dispatch({ type: CLIENTS_CLEAR_STATE });
  };
}

export function resetClientReducer() {
  return function (dispatch) {
    dispatch({ type: RESET_CLIENT_REDUCER });
  };
}
