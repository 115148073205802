import PropTypes from "prop-types";
import React from "react";
import { ic_filter } from "../../assets/ic_filter";
import { ic_filter_active } from "../../assets/ic_filter_active";
import "./Filter.scss";

const DefaultFilterDropDownContainer = (props) => {
  const {
    children, isOpened, toggleOpen, hasFilterActive,
  } = props;
  return (
    <div className="dropdown filter-dropdown">
      <button className="filter" type="button" onClick={toggleOpen}>
        {hasFilterActive ? (
          <img
            alt=""
            className="multi-select-filter-active"
            src={ic_filter_active}
          />
        ) : (
          <img alt="" className="multi-select-filter-inactive" src={ic_filter} />
        )}
      </button>
      {isOpened && children}
    </div>
  );
};

DefaultFilterDropDownContainer.propTypes = {
  children: PropTypes.object,
  isOpened: PropTypes.bool,
  toggleOpen: PropTypes.func,
  hasFilterActive: PropTypes.bool,
};

DefaultFilterDropDownContainer.defaultProps = {
  hasFilterActive: false,
};

export default DefaultFilterDropDownContainer;
