import { Empty, Pagination, Skeleton } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomTable from '../../../../common/CustomTable/CustomTable';
import HeaderNavBar from '../../../../common/HeaderNav/HeaderNav';
import { populateInitialValues } from '../../../../common/methods';
import ConfirmationModal from '../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import {
  AlertMsg,
  NO_INTERNET,
  SOCKET_MSG,
} from '../../../../constants/messages';
import {
  ALERT_CLI_STATUS,
  ALERT_FILTER_HEADING,
  ALERT_FILTER_CLIENT_HEADING,
  ALERT_LEVELS,
  ALERT_MEC_STATUS,
  ALERT_MEC_STATUS_KEY,
  ALERT_SORT_BY,
  ALERT_TYPE,
  ROLE_ENUM,
  SORT_ORDER,
} from '../../../../constants/variables';
import {
  clearAlertApiState as clearAlertApiStateFunc,
  clearAlertList as clearAlertListFunc,
  clearAlertState as clearAlertStateFunc,
  fetchAlertList as fetchAlertListFunc,
  requestAlertClose as requestAlertCloseFunc,
  requestAlertDiscard as requestAlertDiscardFunc,
  resetAlertClose as resetAlertCloseFunc,
  resetAlertDiscard as resetAlertDiscardFunc,
  requestAlertAssoc as requestAlertAssocFunc,
  resetAlertAssoc as resetAlertAssocFunc,
  requestAlertDisassoc as requestAlertDisassocFunc,
  resetAlertDisassoc as resetAlertDisassocFunc,
  requestEventEdit as requestEventEditFunc,
} from '../../../../redux/actions/Alerts.action';
import {
  ForwardedAlertSocketListner,
  NewAlertSocketListner,
  RemoveListeningFwdAlert,
  RemoveListeningNewAlert,
} from '../../../../socket/alerts/alerts.listner';
import './AllAlerts.scss';
import { columns } from './AllAlerts.table';
import BulkActionModal from './BulkActionModal/BulkActionModal';
import _ from 'lodash';
//import Loader from '../../../../common/Loader/Loader';

const PAGINATED_VALUE = 30;
const INITIAL_SKIP = 0;
const IS_FILTER_OPEN = -1;
class AllAlerts extends Component {
  constructor(props) {
    super(props);
    const { info, order } = this.props;
    this.state = {
      sideTab: {
        id: info.dataRef._id, // eslint-disable-line
        levelname: info.type,
      },
      order: order || SORT_ORDER.descending,
      limit: localStorage.getItem('pageSize') || PAGINATED_VALUE,
      keyword: '',
      skip: INITIAL_SKIP,
      alertEventSkip : INITIAL_SKIP,
      expandedIndex: IS_FILTER_OPEN,
      alertListData: {},
      levelFilterValues: { ...populateInitialValues(ALERT_LEVELS) },
      levelFilterState: { ...populateInitialValues(ALERT_LEVELS) },
      typeFilterValues: { ...populateInitialValues(ALERT_TYPE) },
      typeFilterState: { ...populateInitialValues(ALERT_TYPE) },
      clientStatusFilterValues: { ...populateInitialValues(ALERT_CLI_STATUS) },
      clientStatusFilterState: { ...populateInitialValues(ALERT_CLI_STATUS) },
      mechademyStatusFilterValues: {
        ...populateInitialValues(ALERT_MEC_STATUS),
      },
      mechademyStatusFilterState: {
        ...populateInitialValues(ALERT_MEC_STATUS),
      },
      isReload: false,
      isActionDropDown: false,
      alertDiscardIds: [],
      alertAssocIds: [],
      highLevelDiscardIds: [],
      alertCloseIds: [],
      isRefreshAlert: false,
      sokcetLoader: false,
      hasFilterActive: false,
      isDiscardModal: false,
      isCloseModal: false,
      isAssocModal: false,
      isDisassocModal: false,
      isEventEditModal: false,
      startDate: '',
      endDate: '',
    };
  }

  componentDidMount() {
    const level = localStorage.getItem('alertFilterLevel')
      ? JSON.parse(localStorage.getItem('alertFilterLevel'))
      : null;
    const type = localStorage.getItem('alertFilterType')
      ? JSON.parse(localStorage.getItem('alertFilterType'))
      : null;
    const clientStatus = localStorage.getItem('alertFilterClientStatus')
      ? JSON.parse(localStorage.getItem('alertFilterClientStatus'))
      : null;
    const mechademyStatus = localStorage.getItem('alertFilterMechademyStatus')
      ? JSON.parse(localStorage.getItem('alertFilterMechademyStatus'))
      : null;
    const selectedStartDate = localStorage.getItem('startDate')
      ? localStorage.getItem('startDate')
      : '';
    const selectedEndDate = localStorage.getItem('endDate')
      ? localStorage.getItem('endDate')
      : '';
    const selectedKeyword = localStorage.getItem('keyword')
      ? localStorage.getItem('keyword')
      : '';
    const selectedLevel = level ? this.mapObjtoArray(level) : [];
    const alertPage = localStorage.getItem('alertPage');
    const selectedType = type ? this.mapObjtoArray(type) : [];
    const selectedClientStatus = clientStatus
      ? this.mapObjtoArray(this.mapFilterValues(clientStatus))
      : [];
    const selectedMechademyStatus = mechademyStatus
      ? this.mapObjtoArray(this.mapFilterValues(mechademyStatus))
      : [];

    if (alertPage) {
      const limit = localStorage.getItem('pageSize') || this.state.limit;
      const {alertListData} = this.state;

      this.setState({
        skip: limit * (alertPage - 1),
        alertEventSkip: limit * (alertPage - 1) + alertListData?.eventList?.offset
      });
    }
    if (
      selectedLevel.length ||
      selectedType.length ||
      selectedClientStatus.length ||
      selectedMechademyStatus.length
    ) {
      this.setState({
        hasFilterActive: true,
      });
    }
    const { info, userRoleState } = this.props;
    this.setState({
      sideTab: {
        id: info.dataRef._id, // eslint-disable-line
        levelname: info.type,
      },
      levelFilterValues: level
        ? { ...level }
        : { ...populateInitialValues(ALERT_LEVELS) },
      levelFilterState: level
        ? { ...level }
        : { ...populateInitialValues(ALERT_LEVELS) },
      typeFilterValues: type
        ? { ...type }
        : { ...populateInitialValues(ALERT_TYPE) },
      typeFilterState: type
        ? { ...type }
        : { ...populateInitialValues(ALERT_TYPE) },
      clientStatusFilterValues: clientStatus
        ? { ...clientStatus }
        : { ...populateInitialValues(ALERT_CLI_STATUS) },
      clientStatusFilterState: clientStatus
        ? { ...clientStatus }
        : { ...populateInitialValues(ALERT_CLI_STATUS) },
      mechademyStatusFilterValues: mechademyStatus
        ? { ...mechademyStatus }
        : { ...populateInitialValues(ALERT_MEC_STATUS) },
      mechademyStatusFilterState: mechademyStatus
        ? { ...mechademyStatus }
        : { ...populateInitialValues(ALERT_MEC_STATUS) },
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      keyword: selectedKeyword,
    });
    if (userRoleState.apiState.userRole === Object.keys(ROLE_ENUM)[0]) {
      NewAlertSocketListner(() => {
        this.setState({ sokcetLoader: true }, () => {
          Notification.show(Types.Success, SOCKET_MSG.MSG);
          this.fetchList();
        });
      });
    } else {
      ForwardedAlertSocketListner(() => {
        this.setState({ sokcetLoader: true }, () => {
          Notification.show(Types.Success, SOCKET_MSG.MSG);
          this.fetchList();
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isReload,
      sideTab,
      limit,
      keyword,
      skip,
      levelFilterValues,
      typeFilterValues,
      clientStatusFilterValues,
      mechademyStatusFilterValues,
      startDate,
      endDate,
    } = this.state;
    const {
      order,
      info,
      clearAlertApiState,
      clearAlertState,
      clearAlertList,
      fetchAlertList,
      resetAlertClose,
      resetAlertDiscard,
      resetAlertAssoc,
      resetAlertDisassoc,
      alertActionReducerState,
      alertProps,
      alertReducerState,
    } = this.props;
    const {
      isSuccess,
      isCloseSuccess,
      isAssocSuccess,
      isDisassocSuccess,
      errorIds,
      successIds,
    } = alertActionReducerState;

    const {
      itemAllAlerts,
      itemActiveAlerts,
      itemBypassedAlerts,
      itemInactiveAlerts,
      itemNewAlerts,
      itemMyAlerts,
    } = alertReducerState;
    const {
      isErrorList,
      isSuccessAllAlertList,
      isSuccessActiveAlertList,
      isSuccessBypassedAlertList,
      isSuccessInactiveAlertList,
      isSuccessNewAlertList,
      errorCodeList,
      isSuccessMyAlertList,
    } = alertReducerState.apiState;

    const { alertType } = alertProps;
    if (
      isSuccessAllAlertList ||
      (alertType !== prevProps.alertProps.alertType && alertType === 'All')
    ) {
      clearAlertList(alertProps.alertType);
      clearAlertApiState();
      this.setAlertListData(itemAllAlerts);
    } else if (
      isSuccessActiveAlertList ||
      (alertType !== prevProps.alertProps.alertType && alertType === 'Active')
    ) {
      clearAlertList(alertProps.alertType);
      clearAlertApiState();
      this.setAlertListData(itemActiveAlerts);
    } else if (
      isSuccessBypassedAlertList ||
      (alertType !== prevProps.alertProps.alertType && alertType === 'Bypassed')
    ) {
      clearAlertApiState();
      this.setAlertListData(itemBypassedAlerts);
    } else if (
      isSuccessInactiveAlertList ||
      (alertType !== prevProps.alertProps.alertType && alertType === 'Inactive')
    ) {
      clearAlertApiState();
      this.setAlertListData(itemInactiveAlerts);
    } else if (
      isSuccessNewAlertList ||
      (alertType !== prevProps.alertProps.alertType && alertType === 'New')
    ) {
      clearAlertApiState();
      this.setAlertListData(itemNewAlerts);
    } else if (
      isSuccessMyAlertList ||
      (alertType !== prevProps.alertProps.alertType &&
        alertType === 'My Alerts')
    ) {
      clearAlertApiState();
      this.setAlertListData(itemMyAlerts);
    }

    if (isErrorList) {
      clearAlertApiState();
      if (errorCodeList === 'NO_ACCESS') {
        Notification.show(Types.Error, AlertMsg.NO_ACCESS);
      }
    }

    if (order !== prevProps.order) {
      this.setOrder(order);
    }

    if (
      skip !== prevState.skip ||
      limit !== prevState.limit ||
      keyword !== prevState.keyword ||
      order !== prevProps.order ||
      levelFilterValues !== prevState.levelFilterValues ||
      typeFilterValues !== prevState.typeFilterValues ||
      clientStatusFilterValues !== prevState.clientStatusFilterValues ||
      mechademyStatusFilterValues !== prevState.mechademyStatusFilterValues ||
      isReload !== prevState.isReload ||
      sideTab !== prevState.sideTab ||
      startDate !== prevState.startDate ||
      endDate !== prevState.endDate
    ) {
      const queryParams = {};
      const paramsCheck = {
        order,
        limit,
        keyword,
        skip,
      };
      Object.keys(paramsCheck).forEach((key) => {
        if (paramsCheck[key]) {
          queryParams[key] = paramsCheck[key];
        }
      });

      const bodyPayload = {
        _id: sideTab.id,
        levelname: sideTab.levelname,
        filter: {
          level: this.mapObjtoArray(levelFilterValues),
          type: this.mapObjtoArray(typeFilterValues),
          clientStatus: this.mapObjtoArray(
            this.mapFilterValues(clientStatusFilterValues)
          ),
          mechademyStatus: this.mapObjtoArray(
            this.mapFilterValues(mechademyStatusFilterValues)
          ),
        },
        dateFrom: startDate,
        dateTo: endDate,
      };
      if (bodyPayload.dateFrom === '') delete bodyPayload.dateFrom;
      if (bodyPayload.dateTo === '') delete bodyPayload.dateTo;

      if (this.handleNetwork()) {
        fetchAlertList(queryParams, alertType, bodyPayload);
      }
    }
    if (info !== prevProps.info) {
      clearAlertState();
      resetAlertDiscard();
      localStorage.removeItem('alertPage');
      localStorage.removeItem('keyword');
      this.setInfo(info);
    }

    // for discarding alerts
    if (isSuccess && successIds && successIds.length > 0) {
      if (errorIds && errorIds.length > 0) {
        this.fetchList();
        resetAlertDiscard();
        Notification.show(Types.Success, AlertMsg.DISCARD_PARTIAL_MSG);
      } else if (errorIds && errorIds.length === 0) {
        this.fetchList();
        resetAlertDiscard();
        Notification.show(Types.Success, AlertMsg.DISCARD_ALERT_SUCCESS);
      }
    } else if (
      successIds &&
      successIds.length === 0 &&
      errorIds &&
      errorIds.length > 0
    ) {
      resetAlertDiscard();
      Notification.show(Types.Error, AlertMsg.DISCARD_FAILURE_MSG);
    }

    // for closing alerts
    if (isCloseSuccess && successIds && successIds.length > 0) {
      if (errorIds && errorIds.length > 0) {
        this.fetchList();
        resetAlertClose();
        Notification.show(Types.Success, AlertMsg.CLOSE_PARTIAL_MSG);
      } else if (errorIds && errorIds.length === 0) {
        this.fetchList();
        resetAlertClose();
        Notification.show(Types.Success, AlertMsg.CLOSE_ALERT_SUCCESS);
      }
    } else if (
      successIds &&
      successIds.length === 0 &&
      errorIds &&
      errorIds.length > 0
    ) {
      resetAlertClose();
      Notification.show(Types.Error, AlertMsg.CLOSE_FAILURE_MSG);
    }

    //for alert association
    if (isAssocSuccess && successIds && successIds.length > 0) {
      if (errorIds && errorIds.length > 0) {
        this.fetchList();
        resetAlertAssoc();
        Notification.show(Types.Success, AlertMsg.ASSOC_PARTIAL_MSG);
      } else if (errorIds && errorIds.length === 0) {
        this.fetchList();
        resetAlertAssoc();
        Notification.show(Types.Success, AlertMsg.ASSOC_ALERT_SUCCESS);
      }
    } else if (
      successIds &&
      successIds.length === 0 &&
      errorIds &&
      errorIds.length > 0
    ) {
      resetAlertClose();
      Notification.show(Types.Error, AlertMsg.ASSOC_FAILURE_MSG);
    }
    //for alert disassociation
    if (isDisassocSuccess && successIds && successIds.length > 0) {
      if (errorIds && errorIds.length > 0) {
        this.fetchList();
        resetAlertDisassoc();
        Notification.show(Types.Success, AlertMsg.DISASSOC_PARTIAL_MSG);
      } else if (errorIds && errorIds.length === 0) {
        this.fetchList();
        resetAlertDisassoc();
        Notification.show(Types.Success, AlertMsg.DISASSOC_ALERT_SUCCESS);
      }
    } else if (
      successIds &&
      successIds.length === 0 &&
      errorIds &&
      errorIds.length > 0
    ) {
      resetAlertDisassoc();
      Notification.show(Types.Error, AlertMsg.DISASSOC_FAILURE_MSG);
    }
  }

  componentWillUnmount() {
    const {
      clearAlertState,
      resetAlertDiscard,
      resetAlertClose,
      resetAlertAssoc,
      resetAlertDisassoc,
    } = this.props;
    clearAlertState();
    resetAlertDiscard();
    resetAlertClose();
    resetAlertAssoc();
    resetAlertDisassoc();
    RemoveListeningFwdAlert();
    RemoveListeningNewAlert();
  }

  setAlertListData = (data) => {
    this.setState({
      alertListData: data,
    });
  };

  setOrder = (order) => {
    this.setState({ order });
  };

  setInfo = (info) => {
    this.setState({
      sideTab: {
        id: info.dataRef._id, // eslint-disable-line
        levelname: info.type,
      },
      limit: localStorage.getItem('pageSize') || PAGINATED_VALUE,
      expandedIndex: IS_FILTER_OPEN,
      alertListData: {},
      isReload: false,
      isActionDropDown: false,
      eventIds: [],
      alertDiscardIds: [],
      alertAssocIds: [],
      highLevelDiscardIds: [],
      alertCloseIds: [],
      isRefreshAlert: false,
      skip: INITIAL_SKIP,
    });
  };

  fetchList = () => {
    const { clearAlertList, alertProps, order, fetchAlertList } = this.props;
    const {
      sideTab,
      levelFilterValues,
      typeFilterValues,
      clientStatusFilterValues,
      mechademyStatusFilterValues,
      startDate,
      endDate,
      keyword,
      limit,
      skip,
    } = this.state;
    clearAlertList(alertProps.alertType);
    const bodyPayload = {
      _id: sideTab.id,
      levelname: sideTab.levelname,
      filter: {
        level: this.mapObjtoArray(levelFilterValues),
        type: this.mapObjtoArray(typeFilterValues),
        clientStatus: this.mapObjtoArray(
          this.mapFilterValues(clientStatusFilterValues)
        ),
        mechademyStatus: this.mapObjtoArray(
          this.mapFilterValues(mechademyStatusFilterValues)
        ),
      },
      dateFrom: startDate,
      dateTo: endDate,
    };

    if (bodyPayload.dateFrom === '') delete bodyPayload.dateFrom;
    if (bodyPayload.dateTo === '') delete bodyPayload.dateTo;
    this.setState({
      order,
      keyword,
    });
    if (this.handleNetwork()) {
      if (keyword !== '') {
        fetchAlertList(
          { limit, skip, order, keyword },
          alertProps.alertType,
          bodyPayload
        );
      } else
        fetchAlertList(
          { limit, skip, order },
          alertProps.alertType,
          bodyPayload
        );
    }
  };

  handleSearch = (value) => {
    const { clearAlertList, alertProps } = this.props;
    clearAlertList(alertProps.alertType);
    this.setState({
      keyword: value,
      limit: localStorage.getItem('pageSize') || PAGINATED_VALUE,
      skip: INITIAL_SKIP,
    });
    localStorage.setItem('keyword', value);
    if (value === '') localStorage.removeItem('keyword');
  };

  handleSort = (value) => {
    const { clearAlertList, alertProps, handleSort } = this.props;
    clearAlertList(alertProps.alertType);
    handleSort(value);
    this.setState({
      limit: localStorage.getItem('pageSize') || PAGINATED_VALUE,
      skip: INITIAL_SKIP,
    });
  };

  fetchData = (page) => {
    localStorage.setItem('alertPage', page);
    const { clearAlertList, alertProps } = this.props;
    clearAlertList(alertProps.alertType);
    const { limit } = this.state;
    this.setState({
      skip: limit * (page - 1),
    });
  };

  changeAlertsPerPage = (pageSize) => {
    const { clearAlertList, alertProps } = this.props;
    clearAlertList(alertProps.alertType);
    localStorage.removeItem('alertPage');
    localStorage.setItem('pageSize', pageSize);
    this.setState({ limit: pageSize, skip: INITIAL_SKIP });
  };

  setExpandedIndex = (index) => {
    const { expandedIndex } = this.state;
    this.setState({
      expandedIndex: index === expandedIndex ? IS_FILTER_OPEN : index,
    });
  };

  mapValuesToState = () => {
    const {
      levelFilterValues,
      typeFilterValues,
      mechademyStatusFilterValues,
      clientStatusFilterValues,
    } = this.state;
    this.setState({
      levelFilterState: { ...levelFilterValues },
      typeFilterState: { ...typeFilterValues },
      mechademyStatusFilterState: { ...mechademyStatusFilterValues },
      clientStatusFilterState: { ...clientStatusFilterValues },
    });
  };

  resetFilter = () => {
    const { clearAlertList, alertProps } = this.props;
    clearAlertList(alertProps.alertType);
    this.setState({
      levelFilterValues: { ...populateInitialValues(ALERT_LEVELS) },
      levelFilterState: { ...populateInitialValues(ALERT_LEVELS) },
      typeFilterValues: { ...populateInitialValues(ALERT_TYPE) },
      typeFilterState: { ...populateInitialValues(ALERT_TYPE) },
      clientStatusFilterValues: { ...populateInitialValues(ALERT_CLI_STATUS) },
      clientStatusFilterState: { ...populateInitialValues(ALERT_CLI_STATUS) },
      mechademyStatusFilterValues: {
        ...populateInitialValues(ALERT_MEC_STATUS),
      },
      mechademyStatusFilterState: {
        ...populateInitialValues(ALERT_MEC_STATUS),
      },
      skip: INITIAL_SKIP,
      hasFilterActive: false,
    });
    localStorage.removeItem('alertFilterLevel');
    localStorage.removeItem('alertFilterType');
    localStorage.removeItem('alertFilterClientStatus');
    localStorage.removeItem('alertFilterMechademyStatus');
  };

  toggleFilter = (filterHeadName, value) => {
    const {
      levelFilterState,
      typeFilterState,
      mechademyStatusFilterState,
      clientStatusFilterState,
    } = this.state;
    if (filterHeadName === ALERT_FILTER_HEADING[0]) {
      const filters = { ...levelFilterState };
      filters[value] = !levelFilterState[value];
      this.setState({
        levelFilterState: filters,
      });
    } else if (filterHeadName === ALERT_FILTER_HEADING[1]) {
      const filters = { ...typeFilterState };
      filters[value] = !typeFilterState[value];
      this.setState({
        typeFilterState: filters,
      });
    } else if (filterHeadName === ALERT_FILTER_HEADING[2]) {
      const filters = { ...mechademyStatusFilterState };
      filters[value] = !mechademyStatusFilterState[value];
      this.setState({
        mechademyStatusFilterState: filters,
      });
    } else if (
      filterHeadName === ALERT_FILTER_HEADING[3] ||
      filterHeadName === ALERT_FILTER_CLIENT_HEADING[2]
    ) {
      const filters = { ...clientStatusFilterState };
      filters[value] = !clientStatusFilterState[value];
      this.setState({
        clientStatusFilterState: filters,
      });
    }
  };

  applyFilter = () => {
    const { clearAlertList, alertProps } = this.props;
    const {
      levelFilterState,
      typeFilterState,
      mechademyStatusFilterState,
      clientStatusFilterState,
    } = this.state;
    clearAlertList(alertProps.alertType);
    localStorage.setItem('alertFilterLevel', JSON.stringify(levelFilterState));
    localStorage.setItem('alertFilterType', JSON.stringify(typeFilterState));
    localStorage.setItem(
      'alertFilterClientStatus',
      JSON.stringify(clientStatusFilterState)
    );
    localStorage.setItem(
      'alertFilterMechademyStatus',
      JSON.stringify(mechademyStatusFilterState)
    );
    const selectedMechademyStatus = this.mapObjtoArray(
      this.mapFilterValues(mechademyStatusFilterState)
    );
    const selectedClientStatus = this.mapObjtoArray(
      this.mapFilterValues(clientStatusFilterState)
    );
    const selectedLevel = this.mapObjtoArray(levelFilterState);
    const selectedType = this.mapObjtoArray(typeFilterState);
    if (
      selectedLevel.length ||
      selectedType.length ||
      selectedMechademyStatus.length ||
      selectedClientStatus.length
    ) {
      this.setState({
        hasFilterActive: true,
      });
    } else {
      this.setState({
        hasFilterActive: false,
      });
    }
    this.setState({
      levelFilterValues: { ...levelFilterState },
      typeFilterValues: { ...typeFilterState },
      mechademyStatusFilterValues: { ...mechademyStatusFilterState },
      clientStatusFilterValues: { ...clientStatusFilterState },
      skip: INITIAL_SKIP,
    });
  };

  applyRangePicker = (dates) => {
    const { clearAlertList, alertProps } = this.props;
    clearAlertList(alertProps.alertType);
    this.setState({ startDate: dates[0], endDate: dates[1] }, () => {
      localStorage.setItem('startDate', dates[0]);
      localStorage.setItem('endDate', dates[1]);
    });
  };

  /** mapping obj with true values into array */
  mapObjtoArray = (obj) => {
    const resultArray = [];
    Object.keys(obj).forEach((keys) => {
      if (obj[keys]) {
        resultArray.push(keys);
      }
    });
    return resultArray;
  };

  //for action dropdown
  showDisableActionDropdown = (
    value,
    array = [],
    highLevelDiscardIds = [],
    alertAssocIds = [],
    eventIds = []
  ) => {
    this.setState({
      isActionDropDown: value,
      alertCloseIds: array,
      alertDiscardIds: array,
      highLevelDiscardIds,
      alertAssocIds,
      eventIds,
    });
  };

  handleDiscard = () => {
    const { resetAlertDiscard, requestAlertDiscard } = this.props;
    const { alertDiscardIds } = this.state;
    resetAlertDiscard();
    if (this.handleNetwork()) {
      requestAlertDiscard({ alertIds: alertDiscardIds });
    }
    this.setState({ isDiscardModal: false });
  };

  handleBulkDiscard = (discardComment) => {
    const { resetAlertDiscard, requestAlertDiscard } = this.props;
    const { highLevelDiscardIds, alertDiscardIds } = this.state;
    resetAlertDiscard();
    if (this.handleNetwork()) {
      if (highLevelDiscardIds) {
        requestAlertDiscard({
          alertIds: alertDiscardIds,
          discardComment,
        });
      }
    }
    localStorage.removeItem('alertPage');
    this.setState({ isDiscardModal: false });
  };

  handleClose = (closingComment = '') => {
    const { resetAlertClose, requestAlertClose } = this.props;
    const { alertCloseIds } = this.state;
    resetAlertClose();
    if (this.handleNetwork()) {
      if (closingComment !== '')
        requestAlertClose({ alertIds: alertCloseIds, closingComment });
      else requestAlertClose({ alertIds: alertCloseIds });
    }
    this.setState({ isCloseModal: false });
  };

  handleAssociation = (givenEventName = '') => {
    const { resetAlertAssoc, requestAlertAssoc } = this.props;
    const {
      alertCloseIds,
      alertListData,
      alertAssocIds,
      eventIds,
    } = this.state;
    const { eventList } = alertListData;
    resetAlertAssoc();
    let eventAlteredAlertIds = [];
    let update_events = {};
    let delete_events = [];
    if (
      this.handleNetwork() &&
      givenEventName !== '' &&
      alertCloseIds.length > 0 
    ) {
      alertCloseIds.length > 0 &&
        eventList.docs.forEach((evnt) => {
          const eventAlertCount = evnt.eventSize;
          let selectedEventAlertCount = 0;
          evnt.alerts && evnt.alerts.forEach((alert) => {
            if (alertCloseIds.includes(alert._id)) {
              selectedEventAlertCount++;
              if (evnt._id in update_events)
                update_events[evnt._id]['alerts'].push(alert._id);
              else {
                update_events[evnt._id] = {};
                update_events[evnt._id]['alerts'] = [];
                update_events[evnt._id]['alerts'].push(alert._id);
              }
            }
            if (eventAlertCount === selectedEventAlertCount) {
              delete update_events[evnt._id];
              delete_events.push(evnt._id);
            }
          });
        });

      //to capture changes to ETL generated events

      //selection has only independent alerts
      if (!(alertAssocIds.length === 0 && alertCloseIds.length >= 2)) {
        if (
          (alertAssocIds.length === alertCloseIds.length &&
            alertCloseIds.length !== 0 &&
            eventIds.length >= 2) || // events only
          (alertCloseIds.length > alertAssocIds.length &&
            eventIds.length >= 2) || //alert +  multiple event
          (eventIds.length === 0 && alertCloseIds.length >= alertAssocIds.length) || //partially selected events
          (eventIds.length === 1 && alertCloseIds.length >= 2)  //alert +  single event
        ) {
          eventAlteredAlertIds = alertCloseIds;
        }
      }

      requestAlertAssoc({
        alertIds: alertCloseIds,
        eventAlteredAlertIds,
        givenEventName,
        delete_events,
        update_events,
      });
    }
    this.setState({ isAssocModal: false });
  };

  handleDisassociation = () => {
    const { resetAlertDisassoc, requestAlertDisassoc } = this.props;
    const { alertAssocIds, alertListData } = this.state;
    const { eventList } = alertListData;
    let dict = {};

    alertAssocIds.length > 0 &&
      eventList.docs.forEach((event) => { 
        const eventAlertCount = event.eventSize;
        let selectedEventAlertCount = 0;
        event.alerts.forEach((alert) => {
          if (alertAssocIds.includes(alert._id)) {
            selectedEventAlertCount++;
            if (event._id in dict) 
              dict[event._id]['alerts'].push(alert._id);
            else {
              dict[event._id] = {};
              dict[event._id]['alerts'] = [];
              dict[event._id]['alerts'].push(alert._id);
            }
          }
          if (eventAlertCount === selectedEventAlertCount) {
            dict[event._id]['delete'] = true;
          }
        });
      });

    resetAlertDisassoc();

    if (this.handleNetwork()) requestAlertDisassoc(dict);
    this.setState({ isDisassocModal: false });
  };

  handleEventNameEdit = (givenEventName = '') => {
    const { resetAlertAssoc, requestEventEdit } = this.props;
    const { editEventId } = this.state;
    resetAlertAssoc();
    if (this.handleNetwork())
      requestEventEdit({ eventId: editEventId, givenEventName });
    this.setState({
      isEventEditModal: false,
      editEventId: null,
      editEventName: null,
    });
  };

  /** TODO: API with timestamp */
  handleRefresh = () => {
    const { sideTab } = this.state;
    const { fetchAlertList, alertProps } = this.props;

    const bodyPayload = {
      createdAt: moment.utc(new Date()).format(),
      _id: sideTab.id,
      levelname: sideTab.levelname,
    };
    fetchAlertList(
      {
        limit: localStorage.getItem('pageSize') || PAGINATED_VALUE,
      },
      alertProps.alertType,
      bodyPayload
    );
  };

  /**
   * Handle alert details page data flow and navigation
   */
  rowClick = (value, row, col, disabled, item) => {
    if (item.identifierDetails) {
      const { editAlertDetials } = this.props;
      editAlertDetials(true, item);
    }
    //catch hold of item._id and toggle expanded true/false
    else {
      const { alertListData } = this.state;
      let eventIndex = alertListData?.eventList.docs.findIndex( 
        (evnt) => evnt._id === item._id
      );
      alertListData.eventList.docs[eventIndex].expanded = 
        _.isNil(alertListData.eventList.docs[eventIndex].expanded) ||
        !alertListData.eventList.docs[eventIndex].expanded
          ? true
          : false;
      this.setState({
        alertListData: {
          ...alertListData,
          eventList: alertListData.eventList,
        },
      });
    }
  };

  toggleModal = (value) => {
    this.setState({ isDiscardModal: value });
  };

  toggleCloseModal = (value) => {
    this.setState({ isCloseModal: value });
  };

  toggleAssocModal = (value) => {
    this.setState({ isAssocModal: value });
  };

  toggleDisassocModal = (value) => {
    this.setState({ isDisassocModal: value });
  };

  mapFilterValues = (data) => {
    const result = {};
    const keys = Object.keys(data); // eslint-disable-line
    for (let i = 0; i < keys.length; i += 1) {
      Object.assign(result, { [ALERT_MEC_STATUS_KEY[keys[i]]]: data[keys[i]] });
    }
    return result;
  };

  handleNetwork() {
    const { network } = this.props;
    if (network.networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }


  // method for pre populating suggestions in case (alert + event) or (event + event) are associated
  suggestEvntName() {
    const suggestedEvntNames = new Set();
    const {alertAssocIds} = this.state;
    const { eventList } = this.state.alertListData;

    eventList && eventList.docs.length >0 && eventList.docs.forEach((event) => { 
     
        if (event.alerts?.length >0 && alertAssocIds.includes(event.alerts[0]._id)) {
          suggestedEvntNames.add(event.eventName)
        }
    });

    return suggestedEvntNames;
  }

  calculateTotalSize(totalItemCount = 0 , totalEventCount = 0)
  {
    const {limit} = this.state;
    const totalAlertCount = totalItemCount - totalEventCount;
    return (totalEventCount > totalAlertCount && totalItemCount >= limit)
    ? (totalItemCount-totalAlertCount)
    : (totalItemCount-totalEventCount);
  }

  
  render() {
    const {
      alertListData,
      isRefreshAlert,
      hasFilterActive,
      levelFilterState,
      typeFilterState,
      mechademyStatusFilterState,
      clientStatusFilterState,
      expandedIndex,
      skip,
      isActionDropDown,
      order,
      startDate,
      endDate,
      isCloseModal,
      isDiscardModal,
      isAssocModal,
      isDisassocModal,
      isEventEditModal,
      alertDiscardIds,
      alertAssocIds,
      highLevelDiscardIds,
      limit,
      editEventName,
    } = this.state;
    const {
      alertReducerState,
      isOrderActive,
      userRoleState,
      alertProps,
      accessInfo,
      info,
    } = this.props;

    const userRole = userRoleState ? userRoleState.apiState.userRole : '';

    const { apiState } = alertReducerState;
    const { isLoadingList } = apiState;
    const selectedKeyword = localStorage.getItem('keyword');

    return (
      <div className="AllAlerts">
        <HeaderNavBar
          onSearch={(value) => {
            this.handleSearch(value);
          }}
          keyword={selectedKeyword}
          onSort={this.handleSort}
          filterValue={ALERT_LEVELS}
          headLabels={
            userRole.startsWith('Mechademy')
              ? ALERT_FILTER_HEADING
              : ALERT_FILTER_CLIENT_HEADING
          }
          childLabels={
            userRole.startsWith('Mechademy')
              ? [
                  levelFilterState,
                  typeFilterState,
                  mechademyStatusFilterState,
                  clientStatusFilterState,
                ]
              : [levelFilterState, typeFilterState, clientStatusFilterState]
          }
          onToggleOpen={(isVisible) => {
            if (!isVisible) {
              this.mapValuesToState();
            }
          }}
          onApply={this.applyFilter}
          onReset={this.resetFilter}
          onFilter={this.handleFilter}
          onHeadPress={this.setExpandedIndex}
          onChildPress={this.toggleFilter}
          expandedIndex={expandedIndex}
          isFilter={false}
          isExpandableFilter
          isActionDropDown={isActionDropDown}
          handleBtnClick={() => this.toggleModal(true)}
          handleCloseBtnClick={() => this.toggleCloseModal(true)}
          handleAssocBtnClick={() => this.toggleAssocModal(true)}
          handleDisassocBtnClick={() => this.toggleDisassocModal(true)}
          isRefreshBtn={isRefreshAlert}
          handleRefresh={this.handleRefresh}
          refreshTxt="Refresh"
          hasFilterActive={hasFilterActive}
          sortValue={ALERT_SORT_BY}
          sortOrder={order}
          isSortActive={isOrderActive}
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={this.applyRangePicker}
          fromAlerts
          countExDate={
            alertListData &&
            alertListData.data &&
            alertListData.data.count_ex_date
          }
          totalCount={
            alertListData &&
            alertListData.data &&
            alertListData.data.total_count
          }
          alertDiscardIds={this.state.alertDiscardIds}
          alertAssocIds={this.state.alertAssocIds}
          eventList={this.state.alertListData?.eventList}
        />
        {/* {skip === INITIAL_SKIP && isLoadingList && !sokcetLoader && <Loader />} */}
        {isLoadingList ? <Skeleton active> </Skeleton> : null}
        {!(skip === INITIAL_SKIP && isLoadingList) &&
        alertListData &&
        alertListData.list &&
        alertListData.list.length === 0 &&
        alertListData.eventList &&
        alertListData.eventList.docs &&
        alertListData.eventList.docs.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 50,
            }}
            description={
              <span>
                <b>No Alerts Here</b>
              </span>
            }
          ></Empty>
        ) : (
          <CustomTable
            showHeader={!isLoadingList}
            columns={columns({
              userId: userRoleState.apiState.userId,
              alertPermission: accessInfo.write,
              editAlertDetials: this.editAlertDetials,
              alertTab: alertProps.alertType,
              alertList: alertListData && alertListData.list,
              eventList: alertListData && alertListData.eventList,
              alertAction: this.alertAction,
              showDisableActionDropdown: this.showDisableActionDropdown,
              isDiscardModal,
              isCloseModal,
              isAssocModal,
              isDisassocModal,
              isEventEditModal,
              info: info,
              userRole: userRole,
              alertDiscardIds,
              highLevelDiscardIds,
              alertAssocIds,
              eventNameEdit: (event) => {
                this.setState({
                  editEventId: event._id,
                  editEventName: event.eventName,
                  isEventEditModal: true,
                });
              },
            })}
            onRowClick={this.rowClick}
            data={!isLoadingList && alertListData && alertListData.list}
            eventData={!isLoadingList && alertListData && alertListData.eventList}
            userRole={userRole}
          />
        )}
        <Pagination
          showSizeChanger
          style={{ paddingTop: '20px', float: 'right' }}
          current={skip / limit + 1}
          pageSize={limit}
          total={this.calculateTotalSize(alertListData?.data?.total_count , alertListData?.eventList?.total_count)}
          onChange={(page) => this.fetchData(page)}
          onShowSizeChange={(current, pageSize) =>
            this.changeAlertsPerPage(pageSize)
          }
          pageSizeOptions={[10, 30, 50, 100]}
        />
        <ConfirmationModal
          visible={isDiscardModal}
          message={AlertMsg.DISCARD_ALL_MSG}
          title={AlertMsg.DISCARD_TITLE}
          handleOk={this.handleDiscard}
          handleCancel={() => this.toggleModal(false)}
        />
        <BulkActionModal
          highLevelAlertsPresent={highLevelDiscardIds.length > 0}
          visible={isDiscardModal && highLevelDiscardIds.length > 0}
          message={AlertMsg.DISCARD_ALL_MSG}
          title={AlertMsg.DISCARD_TITLE}
          handleOk={this.handleBulkDiscard}
          handleCancel={() => this.toggleModal(false)}
        />
        <BulkActionModal
          highLevelAlertsPresent={highLevelDiscardIds.length > 0}
          visible={isCloseModal}
          title={AlertMsg.CLOSE_TITLE}
          handleOk={this.handleClose}
          handleCancel={() => this.toggleCloseModal(false)}
        />
        <BulkActionModal
          visible={isAssocModal}
          title={AlertMsg.ASSOC_TITLE}
          handleOk={this.handleAssociation}
          handleCancel={() => this.toggleAssocModal(false)}
          isEventAssocAction={true}
          nameSuggestions = {this.suggestEvntName()}
        />
        <BulkActionModal
          visible={isEventEditModal}
          title={AlertMsg.ASSOC_TITLE}
          handleOk={this.handleEventNameEdit}
          eventName={editEventName}
          handleCancel={() =>
            this.setState({
              isEventEditModal: false,
              editEventName: null,
              editEventId: null,
            })
          }
          isEventAssocAction={true}
        />
        <ConfirmationModal
          visible={isDisassocModal}
          message={AlertMsg.DISASSOC_ALL_MSG}
          title={AlertMsg.DISASSOC_TITLE}
          handleOk={this.handleDisassociation}
          handleCancel={() => this.toggleDisassocModal(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  alertReducerState: state.AlertReducer,
  alertActionReducerState: state.AlertActionReducer,
  userRoleState: state.LoginReducer,
});

const mapDispatchToProps = {
  fetchAlertList: fetchAlertListFunc,
  clearAlertState: clearAlertStateFunc,
  requestAlertDiscard: requestAlertDiscardFunc,
  resetAlertDiscard: resetAlertDiscardFunc,
  requestAlertClose: requestAlertCloseFunc,
  resetAlertClose: resetAlertCloseFunc,
  requestAlertAssoc: requestAlertAssocFunc,
  resetAlertAssoc: resetAlertAssocFunc,
  requestAlertDisassoc: requestAlertDisassocFunc,
  resetAlertDisassoc: resetAlertDisassocFunc,
  requestEventEdit: requestEventEditFunc,
  clearAlertApiState: clearAlertApiStateFunc,
  clearAlertList: clearAlertListFunc,
};

AllAlerts.propTypes = {
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  info: PropTypes.shape({
    dataRef: { _id: PropTypes.string },
    type: PropTypes.string,
  }).isRequired,
  isOrderActive: PropTypes.bool.isRequired,
  network: PropTypes.shape({
    networkState: PropTypes.bool,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  alertProps: PropTypes.shape({
    alertType: PropTypes.string,
  }).isRequired,
  editAlertDetials: PropTypes.func.isRequired,
  alertReducerState: PropTypes.shape({
    apiState: {
      isErrorList: PropTypes.bool.isRequired,
      isSuccessAllAlertList: PropTypes.bool.isRequired,
      isSuccessActiveAlertList: PropTypes.bool.isRequired,
      isSuccessBypassedAlertList: PropTypes.bool.isRequired,
      isSuccessInactiveAlertList: PropTypes.bool.isRequired,
      isLoadingList: PropTypes.bool.isRequired,
    },
    itemAllAlerts: PropTypes.shape().isRequired,
    itemActiveAlerts: PropTypes.shape().isRequired,
    itemBypassedAlerts: PropTypes.shape().isRequired,
    itemInactiveAlerts: PropTypes.shape().isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isCloseSuccess: PropTypes.bool.isRequired,
    errorIds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    successIds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isReload: PropTypes.shape().isRequired,
  }).isRequired,
  alertActionReducerState: PropTypes.shape({
    isReload: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isCloseSuccess: PropTypes.bool.isRequired,
    errorIds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    successIds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  userRoleState: PropTypes.shape({
    accessInfo: {
      write: PropTypes.bool.isRequired,
    },
    userId: PropTypes.string.isRequired,
    apiState: PropTypes.shape({
      userRole: PropTypes.string,
      userId: PropTypes.string,
    }),
  }).isRequired,
  clearAlertApiState: PropTypes.func.isRequired,
  clearAlertState: PropTypes.func.isRequired,
  clearAlertList: PropTypes.func.isRequired,
  fetchAlertList: PropTypes.func.isRequired,
  resetAlertClose: PropTypes.func.isRequired,
  resetAlertDiscard: PropTypes.func.isRequired,
  requestAlertClose: PropTypes.func.isRequired,
  requestAlertDiscard: PropTypes.func.isRequired,
};

AllAlerts.defaultProps = {
  order: SORT_ORDER.descending,
};
export default connect(mapStateToProps, mapDispatchToProps)(AllAlerts);
