import _ from "lodash";

const ALERT_TYPE = [
  "Out of Bound Data",
  "Process deviation",
  "Bad Data",
  "Timestamp error",
  "Missing input data",
  "Frozen Data",
  "IQR threshold exceedance",
  "Missing process data",
  "Run Status Change",
  "Iteration failed",
  "Univariate Anomaly Detection",
];
const NONE = "None";
const eventDescription = (data) => {
  if (!data.alertMetaObject) {
    return null;
  }
  const alertData = { ...data, ...data.alertMetaObject };
  switch (alertData.type) {
    case ALERT_TYPE[0]:
      return `The current value of ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName} is ${
        alertData.tagValue.length > 5
          ? parseFloat(alertData.tagValue).toFixed(5)
          : alertData.tagValue
      } ${
        alertData.unit !== NONE ? alertData.unit : ''
      } and it is outside the acceptable range of ${
        alertData.minOutBoundValue.length > 5
          ? parseFloat(alertData.minOutBoundValue).toFixed(5)
          : alertData.minOutBoundValue
      } and ${
        alertData.maxOutBoundValue.length > 5
          ? parseFloat(alertData.maxOutBoundValue).toFixed(5)
          : alertData.maxOutBoundValue
      } and will be discarded.`;

    case ALERT_TYPE[1]:
      if (alertData?.level !== 'Medium')
        return `The current value of ${
          alertData.displayVariableTagNumber
            ? alertData.displayVariableTagNumber
            : alertData.variableTagNumber
        } ${alertData.variableName} is ${
          alertData?.tagValue?.length > 5
            ? parseFloat(alertData?.tagValue).toFixed(5)
            : alertData?.tagValue
        } ${
          alertData.unit !== NONE ? alertData.unit : ''
        } and it is outside the acceptable range of ${
          alertData.minOutBoundValue.length > 5
            ? parseFloat(alertData.minOutBoundValue).toFixed(5)
            : alertData.minOutBoundValue
        } and ${
          alertData.maxOutBoundValue.length > 5
            ? parseFloat(alertData.maxOutBoundValue).toFixed(5)
            : alertData.maxOutBoundValue
        } ${alertData.unit !== NONE ? alertData.unit : ''}.`;
      else
        return alertData?.tagValue?.length > 5
          ? _.replace(
              alertData?.description,
              alertData?.tagValue,
              parseFloat(alertData?.tagValue).toFixed(5)
            )
          : alertData?.description;

    case ALERT_TYPE[2]:
      return `Incorrect/bad data recieved. The current value of  ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName} is ${
        !isNaN(parseFloat(alertData.tagValue))
          ? alertData.tagValue.length > 5
            ? parseFloat(alertData.tagValue).toFixed(5)
            : parseFloat(alertData.tagValue)
          : alertData.tagValue
      }.`;

    case ALERT_TYPE[3]:
      return `Duplicate data received for ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName}.`;

    case ALERT_TYPE[4]:
      return `No data received for ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName}.`;

    case ALERT_TYPE[5]:
      return `The value of  ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${
        alertData.variableName
      } has not changed from last 15 mins. The current value of  ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName} is ${
        alertData.tagValue.length > 5
          ? parseFloat(alertData.tagValue).toFixed(5)
          : alertData.tagValue
      }.`;

    case ALERT_TYPE[6]:
      return `The current value of ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName} is ${
        alertData.tagValue.length > 5
          ? parseFloat(alertData.tagValue).toFixed(5)
          : alertData.tagValue
      } ${
        alertData.unit !== NONE ? alertData.unit : ''
      } and it is outside the predicted range of ${
        alertData.minIQRValue.length > 5
          ? parseFloat(alertData.minIQRValue).toFixed(5)
          : alertData.minIQRValue
      } ${alertData.unit !== NONE ? alertData.unit : ''} and ${
        alertData.maxIQRValue.length > 5
          ? parseFloat(alertData.maxIQRValue).toFixed(5)
          : alertData.maxIQRValue
      } ${alertData.unit !== NONE ? alertData.unit : ''}.`;

    case ALERT_TYPE[7]:
      return `The ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${
        alertData.variableName
      } for this equipment cannot be determined due to insufficient input data.`;

    case ALERT_TYPE[8]:
      if (
        alertData.description ===
          'The equipment run status has changed from ON to OFF.' ||
        alertData.description ===
          'The equipment run status has changed from OFF to ON.'
      ) {
        return alertData.description;
      }
      return 'The equipment run status has changed.';

    case ALERT_TYPE[9]:
      return 'The iteration failed to converge.';
    case ALERT_TYPE[10]:
      return `The current value of ${
        alertData.displayVariableTagNumber
          ? alertData.displayVariableTagNumber
          : alertData.variableTagNumber
      } ${alertData.variableName} is ${
        alertData.tagValue.length > 5
          ? parseFloat(alertData.tagValue).toFixed(5)
          : alertData.tagValue
      } ${
        alertData.unit !== NONE ? alertData.unit : ''
      } and it is outside the predicted range of ${
        alertData.minIQRValue.length > 5
          ? parseFloat(alertData.minIQRValue).toFixed(5)
          : alertData.minIQRValue
      } ${alertData.unit !== NONE ? alertData.unit : ''} and ${
        alertData.maxIQRValue.length > 5
          ? parseFloat(alertData.maxIQRValue).toFixed(5)
          : alertData.maxIQRValue
      } ${alertData.unit !== NONE ? alertData.unit : ''}.`;
    default:
      return alertData.description;
  }
};
export default eventDescription;
