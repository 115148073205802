import Type from "../Types/controlValve.types";
import { endPoints } from "../../libs/endpoints";
import { GETRequest, PUTRequest } from "../../api/request";

export function updateValveCurvesData() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_VALVE_CURVES_DATA_PROGRESS });
  };
}

export function fetchValveConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_VALVE_CONFIG_DATA_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.valveConfig}`)
      .then((res) =>
        dispatch({
          type: Type.GET_VALVE_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.GET_VALVE_CONFIG_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveDesignData(id, payload) {
  const payload_final = { designData: { ...payload } };
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_VALVE_DESIGN_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.valveDesign}`,
      payload_final
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_VALVE_DESIGN_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_VALVE_DESIGN_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveDesignDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_VALVE_DESIGN_DATA_RESET });
  };
}


export function saveCurvesData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_VALVE_CURVES_DATA_PROGRESS });
    PUTRequest(`${endPoints.components}/${id}/${endPoints.valveCurves}`, payload)
      .then((res) =>
        dispatch({
          type: Type.UPDATE_VALVE_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_VALVE_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveCurvesDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_VALVE_CURVES_DATA_RESET });
  };
}
