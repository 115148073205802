import {
  ADD_EQUIPMENT_FETCH_ERROR,
  ADD_EQUIPMENT_FETCH_SUCCESS,
  ADD_EQUIPMENT_FETCH_PROGRESS,
  GET_EQUIPMENT_FETCH_ERROR,
  GET_EQUIPMENT_FETCH_SUCCESS,
  GET_EQUIPMENT_FETCH_PROGRESS,
  EDIT_EQUIPMENT_FETCH_PROGRESS,
  EDIT_EQUIPMENT_FETCH_ERROR,
  EDIT_EQUIPMENT_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_PROGRESS,
  GET_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_EQUIPMENT_TYPE_FETCH_ERROR,
  ADD_EQUIPMENT_TYPE_FETCH_ERROR,
  ADD_EQUIPMENT_TYPE_FETCH_PROGRESS,
  ADD_EQUIPMENT_TYPE_FETCH_SUCCESS,
  GET_MANUFACTURER_FETCH_ERROR,
  GET_MANUFACTURER_FETCH_PROGRESS,
  GET_MANUFACTURER_FETCH_SUCCESS,
  ADD_MANUFACTURER_FETCH_ERROR,
  ADD_MANUFACTURER_FETCH_PROGRESS,
  ADD_MANUFACTURER_FETCH_SUCCESS,
  EQUIPMENT_CLEAR_STATE,
} from "../Types/equipment.types";

const INITIAL_STATE = {
  apiState: {
    equipmentData: null,
    getEquipmentTypeData: null,
    manufacturerData: null,
    addEquipmentTypeData: {},
    addManufacturerData: {},
    isSuccessAddEquipment: false,
    isSuccessEditEquipment: false,
    isSuccessGetEquipmentType: false,
    isSuccessGetManufacturer: false,
    isSuccessAddEquipmentType: false,
    isSuccessAddManufacturer: false,
    isSuccessGetEquipment: false,
    isErrorAddEquipment: false,
    isErrorEditEquipment: false,
    isErrorGetEquipment: false,
    isErrorGetEquipmentType: false,
    isErrorGetManufacturer: false,
    isErrorAddEquipmentType: false,
    isErrorAddManufacturer: false,
    errorCode: null,
    message: null,
  },
  isLoading: false,
};

export default function EquipmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EQUIPMENT_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorAddEquipment: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case ADD_EQUIPMENT_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };
    case ADD_EQUIPMENT_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessAddEquipment: true,
          equipmentData: action.payload,
        },
      };

    case GET_EQUIPMENT_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetEquipment: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_EQUIPMENT_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case GET_EQUIPMENT_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetEquipment: true,
          equipmentData: action.payload,
        },
      };

    case EDIT_EQUIPMENT_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorEditEquipment: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case EDIT_EQUIPMENT_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case EDIT_EQUIPMENT_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessEditEquipment: true,
          equipmentData: action.payload,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetEquipmentType: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetEquipmentType: true,
          getEquipmentTypeData: action.payload,
        },
      };

    case GET_EQUIPMENT_TYPE_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
          isSuccessGetEquipmentType: false,
          isErrorGetEquipmentType: false,
        },
      };

    case ADD_EQUIPMENT_TYPE_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorAddEquipmentType: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_EQUIPMENT_TYPE_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessAddEquipmentType: true,
          addEquipmentTypeData: action.payload,
        },
      };

    case ADD_EQUIPMENT_TYPE_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
        },
      };

    case GET_MANUFACTURER_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorGetManufacturer: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case GET_MANUFACTURER_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessGetManufacturer: true,
          manufacturerData: action.payload,
        },
      };

    case GET_MANUFACTURER_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
          isSuccessGetManufacturer: false,
          isErrorGetManufacturer: false,
        },
      };

    case ADD_MANUFACTURER_FETCH_ERROR:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isErrorAddManufacturer: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case ADD_MANUFACTURER_FETCH_SUCCESS:
      return {
        isLoading: false,
        apiState: {
          ...state.apiState,
          isSuccessAddManufacturer: true,
          addManufacturerData: action.payload,
        },
      };

    case ADD_MANUFACTURER_FETCH_PROGRESS:
      return {
        isLoading: true,
        apiState: {
          ...state.apiState,
          isSuccessAddManufacturer: false,
          isErrorAddManufacturer: false,
        },
      };

    case EQUIPMENT_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_STATE,
          getEquipmentTypeData: state.apiState.getEquipmentTypeData,
          equipmentData: state.apiState.equipmentData,
          manufacturerData: state.apiState.manufacturerData,
          addEquipmentTypeData: state.apiState.addEquipmentTypeData,
          addManufacturerData: state.apiState.addManufacturerData,
        },
      };

    default:
      return state;
  }
}
