const piTagsDummy = [
    {
      componentId: '614982ca22ae8068df7d320f',
      createdAt: '2021-12-05T14:46:07.306Z',
      criticalLimitHigh: 650,
      criticalLimitHighTime: 2160,
      criticalLimitLow: 290,
      criticalLimitLowTime: 4320,
      dataType: 'Float',
      enableIOW: true,
      mechademyUnits: ('m3/h'),
      equipmentId: ['6149824822ae80e1e67d31e2'],
      facilityId: {
        _id: '5f16a9bcb8177357324fe32a',
        name: 'Mechademy Offshore',
        baseIntervalTime: 15,
      },
      isIQR: false,
      multiplier: null,
      optimalTarget: null,
      optimalTimeRange: 3645000,
      piTag: 'FI-F2-3001B',
      piVariableName: 'Sea Water Re-injection Pump B Volumetric Flow',
      maxThreshold:800,
      minThreshold:0,
      referenceStartDate: '2021-12-05T11:10:05',
      standardLimitHigh: 550,
      standardLimitHighTime: 37440,
      standardLimitLow: 375,
      standardLimitLowTime: 43920,
      type: 'input',
      updatedAt: '2021-12-5T03:50:33.419Z',
      variableName: ' Sea Water Re-injection Pump B Volumetric Flow ',
      variableProperty: 'Volumetric Flow Rate',
      _id: '615db6afa94ef6d49c53c628',
    },
  
    {
      componentId: '614982ca22ae8068df7d320f',
      createdAt: '2021-10-06T14:46:07.306Z',
      criticalLimitHigh: '6000',
      criticalLimitHighTime: '1.65',
      criticalLimitLow: 'null',
      criticalLimitLowTime: 'null',
      dataType: 'Float',
      enableIOW: 'true',
      equipmentId: ['6149824822ae80e1e67d31e2'],
      facilityId: {
        _id: '5f16a9bcb8177357324fe32a',
        name: 'Mechademy Offshore',
        baseIntervalTime: 15,
      },
      isIQR: 'true',
      multiplier: 4,
      optimalTarget: 'null',
      optimalTimeRange: '1211.4',
      piTag: 'JI-F2-3001A',
      piVariableName: 'Sea Water Re-injection Pump A Motor Power',
      referenceStartDate: '2021-12-05T10:59:59.660Z',
      standardLimitHigh: 5700,
      standardLimitHighTime: '5.44',
      standardLimitLow: 'null',
      standardLimitLowTime: 'null',
      type: 'output',
      updatedAt: '2022-01-24T09:18:44.015Z',
      variableName: 'Sea Water Re-injection Pump A Motor Power',
      variableProperty: 'Power / Energy Flow',
      _id: '615db6afa94ef6d49c53c615',
    },
    {
      componentId: '614982ca22ae8068df7d320f',
      createdAt: '2021-10-06T14:26:40.951Z',
      criticalLimitHigh: null,
      criticalLimitHighTime: null,
      criticalLimitLow: 64,
      criticalLimitLowTime: 0,
      dataType: 'Float',
      enableIOW: true,
      equipmentId: ['6149824822ae80e1e67d31e2'],
      facilityId: {
        _id: '5f16a9bcb8177357324fe32a',
        name: 'Mechademy Offshore',
        baseIntervalTime: 15,
      },
      isIQR: false,
      mechademyUnits: '%',
      multiplier: null,
      optimalTarget: null,
      optimalTimeRange: 2244.7,
      piTag: '2ST401A-STNO-EFO-1255',
      piVariableName: 'Steam Turbine A Isentropic Efficiency',
      referenceStartDate: '2022-01-24T09:20:06.777Z',
      standardLimitHigh: null,
      standardLimitHighTime: null,
      standardLimitLow: 69,
      standardLimitLowTime: 4.83,
      type: 'output',
      units: '%',
      updatedAt: '2022-01-24T09:21:30.218Z',
      variableName: 'Steam Turbine Efficiency',
      variableProperty: 'Percentage',
      _id: '615db220a94ef6d49c537618',
    },
    {
      componentId: '614982ca22ae8068df7d320f',
      createdAt: '2021-09-29T06:31:40.901Z',
      criticalLimitHigh: null,
      criticalLimitLow: null,
      dataType: 'Float',
      enableIOW: false,
      equipmentId: ['6149824822ae80e1e67d31e2'],
      facilityId: {
        _id: '5f16a9bcb8177357324fe32a',
        name: 'Mechademy Offshore',
        baseIntervalTime: 15,
      },
      isIQR: false,
      mechademyUnits: 'lbm/h',
      multiplier: 4,
      optimalTarget: null,
      piTag: '2ST401A-STNO-FO_M-1255',
      piVariableName: 'Steam Turbine A Throttle Steam Mass Flow',
      standardLimitHigh: null,
      standardLimitLow: null,
      type: 'output',
      units: 'lbm/h',
      updatedAt: '2021-09-29T06:31:40.901Z',
      variableName: 'Steam Turbine Throttle Steam Mass Flow',
      variableProperty: 'Mass Flow Rate',
      _id: '6154084c1bf354ff64230c4c',
    },
  ];
  
  export default piTagsDummy;
  