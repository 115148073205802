import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, message, Menu, Dropdown } from "antd";
import moment from "moment";
import "./RunsDetail.scss";
import { recalculateKPIs, updateAutoRecalculate} from "../../../../redux/actions/runTags.action";
import { useEffect } from "react";

const RecalculateComponent = (equipmentId, activeKey ) => {
  const autoRecalcGlobalState = useSelector((state) => state.RunTagsReducer.autoRecalculate) 
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [recalculateDate, setRecalculateDate] = useState("");
  useEffect(() => {
    if(activeKey === '4'){
      setRecalculateDate("");
    }
  }, [activeKey])// eslint-disable-line
  const handleInputChange = (event) => {
    setRecalculateDate(event.target.value);
  };
  const recalcDispatcher = () => {
    if (recalculateDate === "") {
    }
    else if (recalculateDate !== "") {
      let dates = moment(recalculateDate, moment.defaultFormat);
      dispatch(recalculateKPIs(equipmentId.equipmentId, dates._i));
      setShowForm(false);
      setRecalculateDate("");
    }
  };
  const ModalFunction = () => {
    return (
      <Modal visible={showForm} title="Enter Date"
        okText={"Recalculate"} onCancel={() => {setShowForm(false); setRecalculateDate("");}} className={"runDetails"}
        onOk={() => recalcDispatcher()}
      >
        <div className="question">
          Enter the date from which you want to recalculate KPIs
          <br /><br />
          <div>
            <input
              type="datetime-local"
              onChange={handleInputChange}
              className={"recalculateInput"}
              value = {recalculateDate}
            ></input>
          </div>
        </div>
      </Modal>
    );
  };
  const HandelAutoRecalc = (mode) => {
    if(mode === true){
      message.info('Auto Recalculate KPI mode Enabled')
    }
    else if(mode === false){
      message.info('Auto Recalculate KPI mode Disabled')
    }
  }
  const MenuOptions = (
    <Menu>
      <Menu.Item key="1" onClick = {() => {HandelAutoRecalc(true); dispatch(updateAutoRecalculate(true))}}>
        ON
      </Menu.Item>
      <Menu.Item key="2" onClick = {() => {HandelAutoRecalc(false); dispatch(updateAutoRecalculate(false))}}>
        OFF
      </Menu.Item>
    </Menu>
  )
  const recalcBtn = () => {
    let btnColor = ""
    if(autoRecalcGlobalState.autoRecalculateMode === false){
      btnColor = "btn-default autoRecalc reacalulate"
      return btnColor
    }
    else{
      btnColor = "btn-disabled autoRecalc reacalulate"
      return btnColor
    }
  }
  return (
    <div className="runDetails">
      <Dropdown overlay={MenuOptions}>
        <Button className="btn-default autoRecalc" >
          Auto Recalculate : 
          {(autoRecalcGlobalState.autoRecalculateMode)?" ON":" OFF"}
        </Button>
      </Dropdown>
      <Button className = {recalcBtn()} disabled= {autoRecalcGlobalState.autoRecalculateMode} onClick = {() => {setShowForm(!showForm)}}>Recalculate</Button>
      {ModalFunction()}
    </div>
  );
};

export default RecalculateComponent;