import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Switch, Empty, Input, Button, DatePicker } from 'antd';
import { TransientDataVariable } from '../../../../constants/variables';
import { getTimeInFormat, getLumpSumValue } from '../../../../libs/valueFormatter';
import { cardTypeConfigMapper } from '../../AddNewCard/AddNewCard.constants';
import Notification, { Types } from '../../../../common/Notification/Notification';
import './transientData.scss';

const { Content } = Layout;

const AnalysisDetails = (analysisInfo, updateAnalysisData, series, updateTransientDataSuccess, isRun) => {


  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [commentData, setCommentData] = useState("");
  const [commentHanlder, setHandeler] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updatingBaseline, setUpdatingBaseline] = useState(false);
  const [updatingMarkAsBad, setUpdatingMarkAsBad] = useState(false);

  useEffect(()=>{
    if(analysisInfo && analysisInfo.startTime && analysisInfo.endTime)  {
      setStartTime(analysisInfo.startTime);
      setEndTime(analysisInfo.endTime);
    }
  }, [analysisInfo]);

  useEffect(() => {
    if(updateTransientDataSuccess && editMode)  {
      setEditMode(false);
    }
  }, [updateTransientDataSuccess, editMode])

  useEffect(()=>{
    if(analysisInfo && analysisInfo.comment)  {
      setCommentData(analysisInfo.comment);
    }else{
      setCommentData("")
    }
    setHandeler(false)
    setEditMode(false)
    setUpdatingBaseline(false)
    setUpdatingMarkAsBad(false)
  }, [analysisInfo]);

  const handleChange = (value, type) => {
    let payload = {};
    if(type === 'baseline') {
      payload['baselineStatus'] = value;
      setUpdatingBaseline(true)
    } else if(type === 'start') {
      setEditMode(true);
      value = value !== null ? moment(value) : analysisInfo.startTime;
      setStartTime(value);
    } else if(type === 'end') {
      setEditMode(true);
      value = value !== null ? moment(value) : analysisInfo.endTime;
      setEndTime(value);
    } else {
      payload['markedBad'] = value;
      setUpdatingMarkAsBad(true)
    }
    if(type !== 'start' && type !== 'end')  {
      updateAnalysisData(payload);
    }
  }

  const handleInputChange = (e) => {
    setCommentData(e.target.value);
  };

  const runAnalysis = () => {
    if(startTime && endTime && moment(startTime) > moment(endTime)){
      Notification.show(Types.Error, "Start time can not be greater than End time");
      return;
    }
    const payload = {
      startTime: startTime,
      endTime: endTime,
      run: true
    };
    updateAnalysisData(payload);
  }

  const addComment = () => {
    const payload = {
      'comment': commentData
    };
    updateAnalysisData(payload);
  }

  const commentSection = () => {
    const inputElem = <Input.TextArea
      rows={8}
      className="txt-area"
      name="transientAnalysisComment"
      onChange={handleInputChange}
    />

    const inputElemEdit = <Input.TextArea
      rows={8}
      className="txt-area"
      name="transientAnalysisComment"
      onChange={handleInputChange}
      value={commentData}
    />

    const saveButton = <Button
      className="capsule-btn"
      onClick={() => addComment()}
      style={{ margin: "1%" }}
    >
      Save
    </Button>

    const cancelButton = <Button
      className="capsule-btn"
      onClick={() => {
        setHandeler(false);
        setCommentData(analysisInfo.comment);
      }}
      style={{ margin: "1%" }}
    >
      Cancel
    </Button>

    // no comments
    if (analysisInfo && !analysisInfo.comment) {
      return (
        <div>
          <div className="comment-details">
            {inputElem}
          </div>
          <div className="comment">
            {saveButton}
          </div>
        </div>
      );
    }
    // Comment present
    if (analysisInfo && analysisInfo.comment && commentHanlder === false) {
      return (
        <div className="comment-details">
          <br/>
          {inputElemEdit}
          <div className="comment"  style ={{paddingBottom:"2vh",paddingTop:"2vh"}}>
            {saveButton}
            {cancelButton}
          </div>
        </div>
      );
    }
  };
  
  const renderAnalysisPlot = () =>  {
    let graph = cardTypeConfigMapper[TransientDataVariable.cardType];
    return graph && graph.viewCard ? (
      <React.Fragment>
          {graph.viewGraph ? (
            <graph.viewGraph
              series={_.cloneDeep(series)}
              analysisPlots={true}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{
                height: 50,
              }}
              description={
                <span>
                  <b>NO DATA</b>
                </span>
              }
            ></Empty>
          )}
      </React.Fragment>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 50,
        }}
        description={
          <span>
            <b>ADD CARDS HERE</b>
          </span>
        }
      ></Empty>
    );
  }
  return (
    <>
    {analysisInfo && startTime && endTime ? (
    <Layout
      className="site-layout"
      style={{
        background: 'white',
      }}
    >
      <Content style={{ overflow: 'initial' }} className="run-details">        
      <div className="detail-content KPIDetails">
          <Row>
            <Col span={20} style={{padding: 10}}>
              <b>{TransientDataVariable.analysisDetails}</b>
            </Col>
            <Col span={4} style={{padding: 10, textAlign: "right"}}>
                  <Button
                    className="capsule-btn"
                    onClick={() => runAnalysis()}
                    disabled={startTime === analysisInfo.startTime &&
                      endTime === analysisInfo.endTime}
                    loading={isRun}
                  >
                    Run
                  </Button>
            </Col>
          </Row>
          <Row>
            <Col span={18} style={{ padding: 10 }}>
              {TransientDataVariable.tagId}:
              <span>
                {'  '}
                {analysisInfo?.piTag}
              </span>
            </Col>
            <Col className="switch" span={6} style={{ textAlign: 'right', padding: 10 }}>
              {TransientDataVariable.baseline}:
              <span>
                {'  '}
                <Switch
                  disabled={false}
                  className="user-management-switch"
                  checked={analysisInfo?.baselineStatus}
                  onChange={(e) => {
                    handleChange(e, 'baseline');
                  }}
                  loading={updatingBaseline}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={18} style={{ padding: 10 }}>
              {TransientDataVariable.startTime}:
              <span>
                {'  '}
                <DatePicker
                  value={moment(startTime)}
                  format="DD-MM-YYYY HH:mm:ss"
                  showTime
                  onChange={(e) => {
                    handleChange(e, "start");
                  }}
                  allowClear={startTime === analysisInfo.startTime ? false : true}
                />
              </span>
            </Col>
            <Col span={6} style={{ textAlign: 'right', padding: 10 }}>
              {TransientDataVariable.markAsBad}:
              <span>
                {'  '}
                <Switch
                  disabled={false}
                  className="user-management-switch"
                  checked={analysisInfo?.markedBad}
                  onChange={(e) => {
                    handleChange(e, 'markedBad');
                  }}
                  loading={updatingMarkAsBad}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={18} style={{ padding: 10 }}>
              {TransientDataVariable.endTime}:
              <span style={{ paddingLeft: 8 }}>
                {'  '}
                <DatePicker
                  value={moment(endTime)}
                  format="DD-MM-YYYY HH:mm:ss"
                  showTime
                  onChange={(e) => {
                    handleChange(e, "end");
                  }}
                  allowClear={endTime === analysisInfo.endTime ? false : true}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.eventTriggerTime}:
              <span>
                {'  '}
                {getTimeInFormat(analysisInfo?.triggerTime)}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right', padding: 10 }}>
              {TransientDataVariable.type}:
              <span>
                {'  '}
                {analysisInfo?.analysisType}
              </span>
            </Col>
          </Row>
        </div>
        <br></br>
        <div className="detail-content KPIDetails">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>{TransientDataVariable.analysisKPIs}</b>
            </h>
          </div>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.startValue}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.startValue) + " " + analysisInfo.plantUnit}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right', padding: 10 }}>
              {TransientDataVariable.endValue}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.endValue) + " " + analysisInfo.plantUnit}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.auc}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.AUC)}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right', padding: 10 }}>
              {TransientDataVariable.rateOfChange}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.rateOfChange)}
              </span>
            </Col>
              </Row>
              <Row>
                <Col span={12} style={{ padding: 10 }}>
                  {TransientDataVariable.differential}:
                  <span>
                    {'  '}
                    {getLumpSumValue(analysisInfo?.differential) + " " + analysisInfo.plantUnit}
                  </span>
                </Col>
                <Col span={12} style={{ textAlign: 'right', padding: 10 }}>
                  {TransientDataVariable.timeToComplete}:
                  <span>
                    {'  '}
                    {getLumpSumValue(analysisInfo?.timeToComplete) + " " + TransientDataVariable.timeUnit}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ padding: 10 }}>
                  {TransientDataVariable.triggerTimeStartTimeDiff}:
                  <span>
                    {'  '}
                    {getLumpSumValue(analysisInfo?.triggerTimeStartTimeDiff) + " " + TransientDataVariable.timeUnit}
                  </span>
                </Col>
              </Row>
        </div>
        <br></br>
        <div className="detail-content KPIDetails">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>{TransientDataVariable.baselineDeviation}</b>
            </h>
          </div>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.aucDifference}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.baselineAnalysis?.AUCDifference)}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.aucDifferencePerc}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.baselineAnalysis?.AUCPerctDifference)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.aucTimeDifference}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.baselineAnalysis?.timeToCompleteDifference)}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {TransientDataVariable.aucTimeDifferencePerc}:
              <span>
                {'  '}
                {getLumpSumValue(analysisInfo?.baselineAnalysis?.timeToCompletePerctDifference)}
              </span>
            </Col>
          </Row>
        </div>
        <br></br>
        <div className="detail-content KPIDetails">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>{TransientDataVariable.comments}</b>
            </h>
          </div>
          <Row>
            <Col span={24} >
              {commentSection()}
            </Col>
          </Row>
        </div>
        <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>{TransientDataVariable.dataPlot}</b>
            </h>
        </div>
        <div className="">
            {renderAnalysisPlot()}
        </div>
      </Content>
    </Layout>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 50,
      }}
      description={
        <span>
          <b>Empty Tags</b>
        </span>
      }
    ></Empty>
    )}
  </>
  )
};

export default AnalysisDetails;
