export const IOW_TOUR_CHANGE = "IOW_TOUR_CHANGE";
export const IOW_TAG_GET_LIST_PROGRESS = "IOW_TAG_GET_LIST_PROGRESS"
export const IOW_TAG_GET_LIST_SUCCESS = "IOW_TAG_GET_LIST_SUCCESS"
export const IOW_TAG_GET_LIST_ERROR = "IOW_TAG_GET_LIST_ERROR"
export const IOW_TAG_GET_LIST_CLEAR = "IOW_TAG_GET_LIST_CLEAR"
export const IOW_TAG_UPDATE_PROGRESS = "IOW_TAG_UPDATE_PROGRESS"
export const IOW_TAG_UPDATE_SUCCESS = "IOW_TAG_UPDATE_SUCCESS"
export const IOW_TAG_UPDATE_ERROR = "IOW_TAG_UPDATE_ERROR"
export const IOW_TAG_UPDATE_CLEAR = "IOW_TAG_UPDATE_CLEAR"
export const IOW_TAG_LIST_UPDATE = "IOW_TAG_LIST_UPDATE"
