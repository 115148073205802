import { Form } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../common/FormFields/FormFields';
import Loader from '../../../../../common/Loader/Loader';
import { validateWholeNumber } from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  surgeLineMessages,
} from '../../../../../constants/messages';
import { ButtonVariables } from '../../../../../constants/variables';
import {
  clearSurgeLineData,
  surgeLinesData,
} from '../../../../../redux/actions/SurgeLines.action';
import {
  formFieldsValueAsTestedSurgeLines,
  formFieldsValueOperationalSurgeLines,
  inputFieldsAsTestedSurgeLines,
  inputFieldsOperationalSurgeLines,
  surgeLabel,
  tableHeaderAsTestedSurgeLines,
  tableHeaderoperationalSurgeLines,
  variableName,
} from './SurgeLines.constant';
import './SurgeLines.scss';

class SurgeLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slopeCoefficientAsTestedCurveLines: '',
      surgeMarginAsTestedCurveLines: '',
      slopeCoefficientOperationalSurgeLines: '',
      surgeMarginOperationalSurgeLines: '',
      copySlopeCoefficientOperationalSurgeLines: '',
      copySurgeMarginOperationalSurgeLines: '',
      copySlopeCoefficientAsTestedCurveLines: '',
      copySurgeMarginAsTestedCurveLines: '',
      volumetricFlowUnit: '',
      volumetricFlowUnits: '',
      copyVolumetricFlowUnit: '',
      copyVolumetricFlowUnits: '',
      headUnit: '',
      headUnits: '',
      copyHeadUnit: '',
      copyHeadUnits: '',
      asTestedSurgeLines: {
        volumetricFlow: '',
        headDrop: '',
        pressureRatio: '',
        reduceFlow: '',
        surgeLimit: '',
        surgeControl: '',
      },
      displayAsTestedSurgeLines: [],
      copyDisplayAsTestedSurgeLines: [],
      editTableRowsAsTestedSurgeLines: [],
      inititalDisplayAsTestedSurgeLines: [],
      operationalSurgeLines: {
        volumetricFlow: '',
        headDrop: '',
        pressureRatio: '',
        reduceFlow: '',
        surgeLimit: '',
        surgeControl: '',
      },
      displayOperationalSurgeLines: [],
      copyDisplayOperationalSurgeLines: [],
      editTableRowsoperationalSurgeLines: [],
      initialDisplayOperationalSurgeLines: [],
      unitData: [],
      visible: false,
      formEdit: false,
      asTestedIsError: false,
      operationalIsError: false,
    };
  }

  componentDidMount() {
    const { unitData } = this.props.dataUnitState.apiState;

    this.setState({
      unitData:
        unitData && unitData.items
          ? unitData.items.filter(
              (ele) =>
                ele.dataVariable === 'Volumetric Flow' ||
                ele.dataVariable === 'Polytropic / Adiabatic Head'
            )
          : [],
    });

    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      const {
        copySlopeCoefficientAsTestedCurveLines,
        copySurgeMarginAsTestedCurveLines,
        copySlopeCoefficientOperationalSurgeLines,
        copySurgeMarginOperationalSurgeLines,
        inititalDisplayAsTestedSurgeLines,
        initialDisplayOperationalSurgeLines,
        copyVolumetricFlowUnit,
        copyHeadUnit,
        copyVolumetricFlowUnits,
        copyHeadUnits,
      } = this.state;

      if (this.state.formEdit) {
        this.props.form.setFieldsValue(
          {
            slopeCoefficientAsTestedCurveLines: `${copySlopeCoefficientAsTestedCurveLines}`,
            surgeMarginAsTestedCurveLines: `${copySurgeMarginAsTestedCurveLines}`,
            slopeCoefficientOperationalSurgeLines: `${copySlopeCoefficientOperationalSurgeLines}`,
            surgeMarginOperationalSurgeLines: `${copySurgeMarginOperationalSurgeLines}`,
            volumetricFlowUnit: copyVolumetricFlowUnit,
            headUnit: copyHeadUnit,
            volumetricFlowUnits: copyVolumetricFlowUnits,
            headUnits: copyHeadUnits,
          },
          () => {
            this.disableEditForm();
            this.setState({
              formEdit: false,
              asTestedSurgeLines: {
                volumetricFlow: '',
                headDrop: '',
                pressureRatio: '',
                reduceFlow: '',
                surgeLimit: '',
                surgeControl: '',
              },
              operationalSurgeLines: {
                volumetricFlow: '',
                headDrop: '',
                pressureRatio: '',
                reduceFlow: '',
                surgeLimit: '',
                surgeControl: '',
              },
              slopeCoefficientAsTestedCurveLines: copySlopeCoefficientAsTestedCurveLines,
              surgeMarginAsTestedCurveLines: copySurgeMarginAsTestedCurveLines,
              slopeCoefficientOperationalSurgeLines: copySlopeCoefficientOperationalSurgeLines,
              surgeMarginOperationalSurgeLines: copySurgeMarginOperationalSurgeLines,
              displayAsTestedSurgeLines: [
                ...inititalDisplayAsTestedSurgeLines.map((item) => ({
                  ...item,
                })),
              ],
              displayOperationalSurgeLines: [
                ...initialDisplayOperationalSurgeLines.map((item) => ({
                  ...item,
                })),
              ],
              editTableRowsAsTestedSurgeLines: [],
              editTableRowsoperationalSurgeLines: [],
              asTestedIsError: false,
              operationalIsError: false,
            });
          }
        );
      }
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate() {
    const { configData, isConfigData, clearConfigState } = this.props;
    const { unitData } = this.props.dataUnitState.apiState;
    const {
      clearSurgeLineData,
      surgeLineState: { isSurgeLineFetchSuccess, isSurgeLineFetchError },
    } = this.props;

    if (isConfigData && configData.surgeLines) {
      this.setState({
        unitData:
          unitData && unitData.items
            ? unitData.items.filter(
                (ele) =>
                  ele.dataVariable === 'Volumetric Flow' ||
                  ele.dataVariable === 'Polytropic / Adiabatic Head'
              )
            : [],
      });

      const {
        asTestedSurgeLines,
        operationalSurgeLines,
      } = configData.surgeLines;
      const displayAsTestedSurgeLines = [
        ...asTestedSurgeLines.data.map((item) => ({ ...item })),
      ];
      const displayOperationalSurgeLines = [
        ...operationalSurgeLines.data.map((item) => ({ ...item })),
      ];
      const inititalDisplayAsTestedSurgeLines = [
        ...asTestedSurgeLines.data.map((item) => ({ ...item })),
      ];
      const copyDisplayAsTestedSurgeLines = [
        ...asTestedSurgeLines.data.map((item) => ({ ...item })),
      ];
      const copyDisplayOperationalSurgeLines = [
        ...operationalSurgeLines.data.map((item) => ({ ...item })),
      ];
      const initialDisplayOperationalSurgeLines = [
        ...operationalSurgeLines.data.map((item) => ({ ...item })),
      ];

      clearConfigState();
      this.setState({
        slopeCoefficientAsTestedCurveLines: isFinite(
          asTestedSurgeLines.info.slopeCoefficient
        )
          ? `${+asTestedSurgeLines.info.slopeCoefficient}`
          : '',
        copySlopeCoefficientAsTestedCurveLines: isFinite(
          asTestedSurgeLines.info.slopeCoefficient
        )
          ? `${+asTestedSurgeLines.info.slopeCoefficient}`
          : '',
        surgeMarginAsTestedCurveLines: isFinite(
          asTestedSurgeLines.info.surgeMargin
        )
          ? `${asTestedSurgeLines.info.surgeMargin}`
          : '',
        copySurgeMarginAsTestedCurveLines: isFinite(
          asTestedSurgeLines.info.surgeMargin
        )
          ? `${asTestedSurgeLines.info.surgeMargin}`
          : '',
        slopeCoefficientOperationalSurgeLines: isFinite(
          operationalSurgeLines.info.slopeCoefficient
        )
          ? `${operationalSurgeLines.info.slopeCoefficient}`
          : '',
        copySlopeCoefficientOperationalSurgeLines: isFinite(
          operationalSurgeLines.info.slopeCoefficient
        )
          ? `${operationalSurgeLines.info.slopeCoefficient}`
          : '',
        surgeMarginOperationalSurgeLines: isFinite(
          operationalSurgeLines.info.surgeMargin
        )
          ? `${operationalSurgeLines.info.surgeMargin}`
          : '',
        copySurgeMarginOperationalSurgeLines: isFinite(
          operationalSurgeLines.info.surgeMargin
        )
          ? `${operationalSurgeLines.info.surgeMargin}`
          : '',
        volumetricFlowUnit: asTestedSurgeLines.dataUnits.volumetricFlow,
        copyVolumetricFlowUnit: asTestedSurgeLines.dataUnits.volumetricFlow,
        headUnit: asTestedSurgeLines.dataUnits.headDrop,
        copyHeadUnit: asTestedSurgeLines.dataUnits.headDrop,
        volumetricFlowUnits: operationalSurgeLines.dataUnits.volumetricFlow,
        copyVolumetricFlowUnits: operationalSurgeLines.dataUnits.volumetricFlow,
        headUnits: operationalSurgeLines.dataUnits.headDrop,
        copyHeadUnits: operationalSurgeLines.dataUnits.headDrop,
        displayAsTestedSurgeLines,
        copyDisplayAsTestedSurgeLines,
        initialDisplayOperationalSurgeLines,
        inititalDisplayAsTestedSurgeLines,
        displayOperationalSurgeLines,
        copyDisplayOperationalSurgeLines,
      });
      this.props.form.setFieldsValue(
        {
          slopeCoefficientAsTestedCurveLines: isFinite(
            asTestedSurgeLines.info.slopeCoefficient
          )
            ? `${+asTestedSurgeLines.info.slopeCoefficient}`
            : '',
          surgeMarginAsTestedCurveLines: isFinite(
            asTestedSurgeLines.info.surgeMargin
          )
            ? `${asTestedSurgeLines.info.surgeMargin}`
            : '',
          slopeCoefficientOperationalSurgeLines: isFinite(
            operationalSurgeLines.info.slopeCoefficient
          )
            ? `${operationalSurgeLines.info.slopeCoefficient}`
            : '',
          surgeMarginOperationalSurgeLines: isFinite(
            operationalSurgeLines.info.surgeMargin
          )
            ? `${operationalSurgeLines.info.surgeMargin}`
            : '',
          volumetricFlowUnit: asTestedSurgeLines.dataUnits.volumetricFlow,
          headUnit: asTestedSurgeLines.dataUnits.headDrop,
          volumetricFlowUnits: operationalSurgeLines.dataUnits.volumetricFlow,
          headUnits: operationalSurgeLines.dataUnits.headDrop,
        },
        () => this.disableEditForm()
      );
    }
    if (isSurgeLineFetchSuccess) {
      clearSurgeLineData();
      Notification.show(Types.Success, surgeLineMessages.DATA_SAVE);
    }
    if (isSurgeLineFetchError) {
      clearSurgeLineData();
      Notification.show(Types.Error, surgeLineMessages.DATA_SAVE_ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChangeFieldData = (value, state) => {
    this.setState(
      {
        [state]: value,
      },
      () => this.enableEditForm()
    );
  };

  handleChangeTableData = (e, tableName) => {
    if (tableName === variableName.asTestedSurgeLines) {
      this.setState(
        {
          asTestedSurgeLines: {
            ...this.state.asTestedSurgeLines,
            [e.target.id]: e.target.value,
          },
        },
        () => this.enableEditForm()
      );
    } else if (tableName === variableName.operationalSurgeLines) {
      this.setState(
        {
          operationalSurgeLines: {
            ...this.state.operationalSurgeLines,
            [e.target.id]: e.target.value,
          },
        },
        () => this.enableEditForm()
      );
    }
  };

  handleChangeTableEditData = (e, row, tableName) => {
    if (tableName === variableName.asTestedSurgeLines) {
      const { displayAsTestedSurgeLines } = this.state;
      const key = e.target.id;
      const { value } = e.target;
      const tempDisplayData = displayAsTestedSurgeLines;
      tempDisplayData[row][key] = value;
      this.setState(
        {
          displayAsTestedSurgeLines: tempDisplayData,
        },
        () => this.enableEditForm()
      );
    } else if (tableName === variableName.operationalSurgeLines) {
      const { displayOperationalSurgeLines } = this.state;
      const key = e.target.id;
      const { value } = e.target;
      const tempDisplayData = displayOperationalSurgeLines;
      tempDisplayData[row][key] = value;
      this.setState(
        {
          displayOperationalSurgeLines: tempDisplayData,
        },
        () => this.enableEditForm()
      );
    }
  };

  onAdd = (tableName) => {
    const {
      displayAsTestedSurgeLines,
      asTestedSurgeLines,
      copyDisplayAsTestedSurgeLines,
      operationalSurgeLines,
      displayOperationalSurgeLines,
      copyDisplayOperationalSurgeLines,
    } = this.state;
    if (tableName === variableName.asTestedSurgeLines) {
      this.setState({
        asTestedIsError: true,
      });
      if (
        !_.isEmpty(
          asTestedSurgeLines.volumetricFlow &&
            asTestedSurgeLines.volumetricFlow.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.volumetricFlow) &&
        !_.isEmpty(
          asTestedSurgeLines.headDrop && asTestedSurgeLines.headDrop.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.headDrop) &&
        !_.isEmpty(
          asTestedSurgeLines.pressureRatio &&
            asTestedSurgeLines.pressureRatio.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.pressureRatio) &&
        !_.isEmpty(
          asTestedSurgeLines.reduceFlow && asTestedSurgeLines.reduceFlow.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.reduceFlow) &&
        !_.isEmpty(
          asTestedSurgeLines.surgeLimit && asTestedSurgeLines.surgeLimit.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.surgeLimit) &&
        !_.isEmpty(
          asTestedSurgeLines.surgeControl &&
            asTestedSurgeLines.surgeControl.trim()
        ) &&
        validateWholeNumber(asTestedSurgeLines.surgeControl)
      ) {
        this.setState(
          {
            displayAsTestedSurgeLines: [
              ...displayAsTestedSurgeLines,
              { ...asTestedSurgeLines },
            ],
            copyDisplayAsTestedSurgeLines: [
              ...copyDisplayAsTestedSurgeLines,
              { ...asTestedSurgeLines },
            ],
          },
          () => {
            this.setState(
              {
                asTestedSurgeLines: {},
                asTestedIsError: false,
              },
              () => this.enableEditForm()
            );
          }
        );
      } else {
        Notification.show(Types.Error, surgeLineMessages.FIELDS_DIRTY);
      }
    } else if (tableName === variableName.operationalSurgeLines) {
      this.setState({
        operationalIsError: true,
      });
      if (
        !_.isEmpty(
          operationalSurgeLines.volumetricFlow &&
            operationalSurgeLines.volumetricFlow.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.volumetricFlow) &&
        !_.isEmpty(
          operationalSurgeLines.headDrop &&
            operationalSurgeLines.headDrop.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.headDrop) &&
        !_.isEmpty(
          operationalSurgeLines.pressureRatio &&
            operationalSurgeLines.pressureRatio.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.pressureRatio) &&
        !_.isEmpty(
          operationalSurgeLines.reduceFlow &&
            operationalSurgeLines.reduceFlow.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.reduceFlow) &&
        !_.isEmpty(
          operationalSurgeLines.surgeLimit &&
            operationalSurgeLines.surgeLimit.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.surgeLimit) &&
        !_.isEmpty(
          operationalSurgeLines.surgeControl &&
            operationalSurgeLines.surgeControl.trim()
        ) &&
        validateWholeNumber(operationalSurgeLines.surgeControl)
      ) {
        this.setState(
          {
            displayOperationalSurgeLines: [
              ...displayOperationalSurgeLines,
              { ...operationalSurgeLines },
            ],
            copyDisplayOperationalSurgeLines: [
              ...copyDisplayOperationalSurgeLines,
              { ...operationalSurgeLines },
            ],
          },
          () => {
            this.setState({
              operationalSurgeLines: {},
              operationalIsError: false,
            });
          },
          () => this.enableEditForm()
        );
      } else {
        Notification.show(Types.Error, surgeLineMessages.FIELDS_DIRTY);
      }
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = (row, tableName) => {
    const {
      displayAsTestedSurgeLines,
      copyDisplayAsTestedSurgeLines,
      editTableRowsAsTestedSurgeLines,
      displayOperationalSurgeLines,
      editTableRowsoperationalSurgeLines,
      copyDisplayOperationalSurgeLines,
    } = this.state;
    if (tableName === variableName.asTestedSurgeLines) {
      const tempDisplayData = displayAsTestedSurgeLines;
      tempDisplayData[row] = { ...copyDisplayAsTestedSurgeLines[row] };
      const tempEditTableRows = editTableRowsAsTestedSurgeLines.filter(
        (item) => item !== row
      );
      this.setState(
        {
          displayAsTestedSurgeLines: tempDisplayData,
          editTableRowsAsTestedSurgeLines: tempEditTableRows,
        },
        () => this.enableEditForm()
      );
    } else if (tableName === variableName.operationalSurgeLines) {
      const tempDisplayData = displayOperationalSurgeLines;
      tempDisplayData[row] = { ...copyDisplayOperationalSurgeLines[row] };
      const tempEditTableRows = editTableRowsoperationalSurgeLines.filter(
        (item) => item !== row
      );
      this.setState(
        {
          displayData: tempDisplayData,
          editTableRowsoperationalSurgeLines: tempEditTableRows,
        },
        () => this.enableEditForm()
      );
    }
  };

  onEdit = (row, tableName) => {
    const {
      editTableRowsAsTestedSurgeLines,
      editTableRowsoperationalSurgeLines,
    } = this.state;
    if (tableName === variableName.asTestedSurgeLines) {
      this.setState(
        {
          editTableRowsAsTestedSurgeLines: [
            ...editTableRowsAsTestedSurgeLines,
            row,
          ],
        },
        () => this.enableEditForm()
      );
    } else if (tableName === variableName.operationalSurgeLines) {
      this.setState(
        {
          editTableRowsoperationalSurgeLines: [
            ...editTableRowsoperationalSurgeLines,
            row,
          ],
        },
        () => this.enableEditForm()
      );
    }
  };

  onDelete = (row, tableName) => {
    const {
      displayAsTestedSurgeLines,
      displayOperationalSurgeLines,
      editTableRowsAsTestedSurgeLines,
      editTableRowsoperationalSurgeLines,
    } = this.state;
    if (tableName === variableName.asTestedSurgeLines) {
      const tempDisplayData = displayAsTestedSurgeLines;
      const tempEditRow = editTableRowsAsTestedSurgeLines.filter(
        (item) => item !== row
      );
      tempDisplayData.splice(row, 1);
      this.setState(
        {
          displayAsTestedSurgeLines: tempDisplayData,
          editTableRowsAsTestedSurgeLines: tempEditRow,
        },
        () => this.enableEditForm()
      );
    } else if (tableName === variableName.operationalSurgeLines) {
      const tempDisplayData = displayOperationalSurgeLines;
      const tempEditRow = editTableRowsoperationalSurgeLines.filter(
        (item) => item !== row
      );
      tempDisplayData.splice(row, 1);
      this.setState(
        {
          displayOperationalSurgeLines: tempDisplayData,
          editTableRowsoperationalSurgeLines: tempEditRow,
        },
        () => this.enableEditForm()
      );
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const {
      copySlopeCoefficientAsTestedCurveLines,
      copySlopeCoefficientOperationalSurgeLines,
      copyHeadUnit,
      copyVolumetricFlowUnit,
      copyHeadUnits,
      copyVolumetricFlowUnits,
      copySurgeMarginAsTestedCurveLines,
      copyDisplayAsTestedSurgeLines,
      copyDisplayOperationalSurgeLines,
    } = this.state;
    this.setState({
      displayAsTestedSurgeLines: [
        ...copyDisplayAsTestedSurgeLines.map((item) => ({ ...item })),
      ],
      displayOperationalSurgeLines: [
        ...copyDisplayOperationalSurgeLines.map((item) => ({ ...item })),
      ],
      volumetricFlowUnit: copyVolumetricFlowUnit,
      headUnit: copyHeadUnit,
      volumetricFlowUnits: copyVolumetricFlowUnits,
      headUnits: copyHeadUnits,
      visible: false,
      editTableRowsAsTestedSurgeLines: [],
      editTableRowsoperationalSurgeLines: [],
    });

    this.props.form.setFieldsValue(
      {
        slopeCoefficientAsTestedCurveLines: copySlopeCoefficientAsTestedCurveLines,
        surgeMarginAsTestedCurveLines: copySurgeMarginAsTestedCurveLines,
        slopeCoefficientOperationalSurgeLines: copySlopeCoefficientOperationalSurgeLines,
        surgeMarginOperationalSurgeLines: copySurgeMarginAsTestedCurveLines,
        volumetricFlowUnit: copyVolumetricFlowUnit,
        headUnit: copyHeadUnit,
        volumetricFlowUnits: copyVolumetricFlowUnits,
        headUnits: copyHeadUnits,
      },
      () => this.disableEditForm()
    );
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
          title={DISCARD_CHANGES}
        />
      );
    }
    return null;
  }

  validatePayload = (
    displayAsTestedSurgeLines,
    displayOperationalSurgeLines
  ) => {
    const asTestedData = displayAsTestedSurgeLines;

    asTestedData.forEach((data, index) => {
      delete asTestedData[index].siValueVolumetricFlow;
      delete asTestedData[index].siValueHeadDrop;
    });

    for (const data of asTestedData) {
      for (let value of Object.values(data)) {
        value = value.toString();
        if (_.isEmpty(value) || !validateWholeNumber(value)) {
          return false;
        }
      }
    }

    const operationalData = displayOperationalSurgeLines;
    operationalData.forEach((data, index) => {
      delete operationalData[index].siValueVolumetricFlow;
      delete operationalData[index].siValueHeadDrop;
    });

    for (const data of operationalData) {
      for (let value of Object.values(data)) {
        value = value.toString();
        if (_.isEmpty(value) || !validateWholeNumber(value)) {
          return false;
        }
      }
    }
    return true;
  };

  onSave = () => {
    let totalDispalyData = [];
    this.state.displayAsTestedSurgeLines.forEach((item) => {
      totalDispalyData = [...totalDispalyData, ...Object.values(item)];
    });
    this.state.displayOperationalSurgeLines.forEach((item) => {
      totalDispalyData = [...totalDispalyData, ...Object.values(item)];
    });
    if (!totalDispalyData.some((item) => item === null)) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const {
            slopeCoefficientAsTestedCurveLines,
            surgeMarginAsTestedCurveLines,
            slopeCoefficientOperationalSurgeLines,
            surgeMarginOperationalSurgeLines,
            volumetricFlowUnit,
            headUnit,
            volumetricFlowUnits,
            headUnits,
            displayAsTestedSurgeLines,
            displayOperationalSurgeLines,
          } = this.state;
          if (
            (displayAsTestedSurgeLines.length === 0 &&
              displayOperationalSurgeLines.length === 0) ||
            (displayAsTestedSurgeLines.length >= 3 &&
              displayOperationalSurgeLines.length >= 3 &&
              displayAsTestedSurgeLines.length <= 15 &&
              displayOperationalSurgeLines.length <= 15 &&
              displayAsTestedSurgeLines.length ===
                displayOperationalSurgeLines.length)
          ) {
            if (
              this.validatePayload(
                displayAsTestedSurgeLines,
                displayOperationalSurgeLines
              )
            ) {
              const asTestedData = displayAsTestedSurgeLines;

              asTestedData.forEach((data, index) => {
                delete asTestedData[index].siValueVolumetricFlow;
                delete asTestedData[index].siValueHeadDrop;
                for (const key of Object.keys(data)) {
                  asTestedData[index][key] = +asTestedData[index][key];
                }
              });

              const operationalData = displayOperationalSurgeLines;
              operationalData.forEach((data, index) => {
                delete operationalData[index].siValueVolumetricFlow;
                delete operationalData[index].siValueHeadDrop;
                for (const key of Object.keys(data)) {
                  operationalData[index][key] = +operationalData[index][key];
                }
              });

              const payload = {
                asTestedSurgeLines: {
                  dataUnits: {
                    volumetricFlow: volumetricFlowUnit,
                    headDrop: headUnit,
                  },
                  data: asTestedData,
                  info: {
                    slopeCoefficient: parseFloat(
                      slopeCoefficientAsTestedCurveLines
                    ),
                    surgeMargin: parseFloat(surgeMarginAsTestedCurveLines),
                  },
                },
                operationalSurgeLines: {
                  dataUnits: {
                    volumetricFlow: volumetricFlowUnits,
                    headDrop: headUnits,
                  },
                  data: operationalData,
                  info: {
                    slopeCoefficient: parseFloat(
                      slopeCoefficientOperationalSurgeLines
                    ),
                    surgeMargin: parseFloat(surgeMarginOperationalSurgeLines),
                  },
                },
              };

              Object.keys(payload.asTestedSurgeLines.dataUnits).forEach(
                (item) => {
                  if (
                    !payload.asTestedSurgeLines.dataUnits[item] &&
                    payload.asTestedSurgeLines.dataUnits[item] !== 0
                  ) {
                    delete payload.asTestedSurgeLines.dataUnits[item];
                  }
                }
              );
              Object.keys(payload.asTestedSurgeLines.info).forEach((item) => {
                if (
                  !payload.asTestedSurgeLines.info[item] &&
                  payload.asTestedSurgeLines.info[item] !== 0
                ) {
                  delete payload.asTestedSurgeLines.info[item];
                }
              });
              Object.keys(payload.operationalSurgeLines.dataUnits).forEach(
                (item) => {
                  if (
                    !payload.operationalSurgeLines.dataUnits[item] &&
                    payload.operationalSurgeLines.dataUnits[item] !== 0
                  ) {
                    delete payload.operationalSurgeLines.dataUnits[item];
                  }
                }
              );
              Object.keys(payload.operationalSurgeLines.info).forEach(
                (item) => {
                  if (
                    !payload.operationalSurgeLines.info[item] &&
                    payload.operationalSurgeLines.info[item] !== 0
                  ) {
                    delete payload.operationalSurgeLines.info[item];
                  }
                }
              );

              if (this.handleNetwork()) {
                const {
                  location: { state },
                  surgeLinesData,
                } = this.props;
                if (
                  this.handleNetwork() &&
                  state &&
                  state.componentData.info._id
                ) {
                  surgeLinesData(state.componentData.info._id, payload);
                }
              }
            } else {
              Notification.show(Types.Error, surgeLineMessages.FIELDS_DIRTY);
            }
          } else if (
            displayAsTestedSurgeLines.length ===
            displayOperationalSurgeLines.length
          ) {
            /** may required later */
            if (
              displayAsTestedSurgeLines.length >= 3 &&
              displayOperationalSurgeLines.length >= 3
            ) {
              Notification.show(
                Types.Error,
                surgeLineMessages.REQUIRED_INPUT_MAX
              );
            } else {
              Notification.show(Types.Error, surgeLineMessages.REQUIRED_INPUT);
            }
          } else {
            Notification.show(Types.Info, surgeLineMessages.SAME_LENGTH);
          }
        }
      });
    }
  };

  asTestedSurgeLines = () => {
    const {
      asTestedIsError,
      displayAsTestedSurgeLines,
      asTestedSurgeLines,
      unitData,
      editTableRowsAsTestedSurgeLines,
      volumetricFlowUnit,
      headUnit,
    } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div>
        <div className="header">
          <div className="heading-table">
            <div className="type-heading">{surgeLabel.asTestedCurve}</div>
            <div className="unit-heading">{surgeLabel.selectUnits}</div>
          </div>
          <div className="form-surge">
            <FormFields
              formFields={formFieldsValueAsTestedSurgeLines({
                handleChangeFieldData: this.handleChangeFieldData,
                unitData,
                isSchematicEditable,
                displayAsTestedSurgeLines,
              })}
              form={this.props.form}
            />
          </div>
        </div>
        <div className="table-surge">
          <CustomTable
            showHeader
            columns={tableHeaderAsTestedSurgeLines({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              isSchematicEditable,
            })}
            data={displayAsTestedSurgeLines}
            renderAddRow={
              isSchematicEditable &&
              inputFieldsAsTestedSurgeLines({
                handleChangeTableData: this.handleChangeTableData,
                handleChangeFieldData: this.handleChangeFieldData,
                onAdd: this.onAdd,
                asTestedSurgeLines,
                volumetricFlowUnit,
                headUnit,
                asTestedIsError,
                length: displayAsTestedSurgeLines.length,
              })
            }
            editableRow={editTableRowsAsTestedSurgeLines}
          />
        </div>
      </div>
    );
  };

  operationalSurgeLines = () => {
    const {
      displayOperationalSurgeLines,
      operationalIsError,
      operationalSurgeLines,
      unitData,
      editTableRowsoperationalSurgeLines,
      volumetricFlowUnit,
      headUnit,
    } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div>
        <div className="header">
          <div className="heading-table">
            <div className="type-heading">
              {surgeLabel.operationBaselineCurve}
            </div>
            <div className="unit-heading">{surgeLabel.selectUnits}</div>
          </div>
          <div className="form-surge">
            <FormFields
              formFields={formFieldsValueOperationalSurgeLines({
                handleChangeFieldData: this.handleChangeFieldData,
                unitData,
                isSchematicEditable,
                displayOperationalSurgeLines,
              })}
              form={this.props.form}
            />
          </div>
        </div>
        <div className="table-surge">
          <CustomTable
            showHeader
            columns={tableHeaderoperationalSurgeLines({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              isSchematicEditable,
            })}
            data={displayOperationalSurgeLines}
            renderAddRow={
              isSchematicEditable &&
              inputFieldsOperationalSurgeLines({
                handleChangeTableData: this.handleChangeTableData,
                handleChangeFieldData: this.handleChangeFieldData,
                onAdd: this.onAdd,
                operationalSurgeLines,
                volumetricFlowUnit,
                headUnit,
                operationalIsError,
                length: displayOperationalSurgeLines.length,
              })
            }
            editableRow={editTableRowsoperationalSurgeLines}
          />
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.props.surgeLineState;
    return (
      <div className="SurgeLines">
        {loading && <Loader />}
        {this.asTestedSurgeLines()}
        <br />
        {this.operationalSurgeLines()}
        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}
SurgeLines.propTypes = {
  getDataUnits: PropTypes.func,
  configData: PropTypes.object,
  clearConfigState: PropTypes.func,
  surgeLineState: PropTypes.object,
  surgeLinesData: PropTypes.func,
  isSchematicEditable: PropTypes.bool,
};

SurgeLines.defaultProps = {
  isSchematicEditable: true,
};

const mapStateToProps = (state) => ({
  surgeLineState: state.surgeLineReducer,
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  surgeLinesData: (componentId, payload) =>
    dispatch(surgeLinesData(componentId, payload)),
  clearSurgeLineData: () => dispatch(clearSurgeLineData()),
});

export default Form.create({
  name: 'SurgeLines',
  onFieldsChange: () => {
    window.dispatchEvent(new Event('form-edit'));
  },
})(connect(mapStateToProps, mapsDispatchToProps)(withRouter(SurgeLines)));
