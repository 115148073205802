import Notification, { Types } from '../../../../common/Notification/Notification'
import csvToArray from '../../../../constants/csvToArray'
import { MANAGE_TAGS_MESSAGE, PI_TAG_DATA_TYPE, PI_TAG_FILE_HEADER } from '../../../../constants/messages'
import workercode from './tagValidator.js';
//import WorkerBuilder from './webWorker.js';

const Validator = async (uploadCSV, payload, facalityId, unitData, handleToggle, piTagUploadClear) => {
    if (payload) {
                
        Notification.show(Types.Info, MANAGE_TAGS_MESSAGE.START_VALIDATION)
        const piTagArray = await csvToArray(payload)

        const response = await workercode({
            piTagdata: piTagArray,
            unitData: unitData,
            headerData: PI_TAG_FILE_HEADER,
            tagMessages: MANAGE_TAGS_MESSAGE,
            dataType: PI_TAG_DATA_TYPE
        })


        if (response && response.validation){
            Notification.show(Types.Success, response.message)
            uploadCSV(payload, facalityId) 
        }
        else {
            Notification.show(Types.Error, response.message)
            handleToggle() 
            piTagUploadClear() 
        }
    }
    else {
        handleToggle() 
        piTagUploadClear() 
        Notification.show(Types.Error, MANAGE_TAGS_MESSAGE.UPLOAD_FILE)
    }
}

export default Validator