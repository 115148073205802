import {
  GET_FLOW_METER_TYPE_FETCH_PROGRESS,
  GET_FLOW_METER_TYPE_FETCH_SUCCESS,
  GET_FLOW_METER_TYPE_FETCH_ERROR,
  ADD_FLOW_METER_TYPE_FETCH_PROGRESS,
  ADD_FLOW_METER_TYPE_FETCH_SUCCESS,
  ADD_FLOW_METER_TYPE_FETCH_ERROR,
  ADD_VALUE_DATA_SUCCESS,
  ADD_VALUE_DATA_PROGRESS,
  ADD_VALUE_DATA_ERROR,
  FLOW_MEASUREMENT_CLEAR_STATE,
} from "../Types/flowMeasurement.types";
import { POSTRequest, PUTRequest } from "../../api/request";
import { endPoints } from "../../libs/endpoints";

const getFlowMeterTypes = (keyword) => (dispatch) => {
  dispatch({ type: GET_FLOW_METER_TYPE_FETCH_PROGRESS });
  POSTRequest(
    `${endPoints.flowMeterType}${keyword ? `?keyword=${keyword}` : ""}`
  )
    .then((res) =>
      dispatch({ type: GET_FLOW_METER_TYPE_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: GET_FLOW_METER_TYPE_FETCH_ERROR, payload: err })
    );
};

const addFlowMeterType = (payload) => (dispatch) => {
  dispatch({ type: ADD_FLOW_METER_TYPE_FETCH_PROGRESS });
  POSTRequest(endPoints.addFlowMeterType, payload)
    .then((res) =>
      dispatch({ type: ADD_FLOW_METER_TYPE_FETCH_SUCCESS, payload: res.body })
    )
    .catch((err) =>
      dispatch({ type: ADD_FLOW_METER_TYPE_FETCH_ERROR, payload: err })
    );
};

const clearFlowMeasurementState = () => (dispatch) => {
  dispatch({ type: FLOW_MEASUREMENT_CLEAR_STATE });
};

const addEditFlowMeasurementData = (
  payload,
  id,
  type = "Centrifugal compressor"
) =>
  function (dispatch) {
    dispatch({ type: ADD_VALUE_DATA_PROGRESS });
    const componentAPIMapper = {
      "Centrifugal compressor": `components/${id}/compressorconfigs/flowmeasurement`,
      "Gas Turbine": `components/${id}/gtconfigs/flowmeasurement`,
      "Flow meter": `components/${id}/flowmeterconfigs/flowmeasurement`,
    };
    PUTRequest(componentAPIMapper[type], payload)
      .then((res) => {
        dispatch({ type: ADD_VALUE_DATA_SUCCESS, payload: res.body });
      })
      .catch((err) => {
        dispatch({ type: ADD_VALUE_DATA_ERROR, payload: err });
      });
  };

export {
  addFlowMeterType,
  getFlowMeterTypes,
  clearFlowMeasurementState,
  addEditFlowMeasurementData,
};
