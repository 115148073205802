export const ScrewCompressorDesignVariable = {
    application: 'Application',
    purpose: "Purpose",
    compressorType: "Compressor Type",
    configSpeed: "Config Speed",
    maximumOperatingSpeed: "Maximum Operating Speed",
    minimumOperatingSpeed: "Minimum Operating Speed",
    startupSpeedThreshold: "Startup speed threshold (to determine running state)",
    startupPowerThreshold: "Startup power threshold (to determine running state)",
    mechanicalEfficiency: "Mechanical Efficiency",
    fluidComposition:"Working Fluid",
    barometricPressure: "Barometric Pressure",
    ambientTemperature: "Ambient Temperature",
    designInternalPressureRatio: "Design Internal Pressure Ratio",
    ratedVolumetricDisplacement: "Rated Volumetric Displacement",
    designVolumetricEfficiency: "Design Volumetric Efficiency",
    flowmeterLocation: "Flowmeter Location",
    condensateVapourFlowrate: "Condensate Vapour Flowrate",
    designVolumeReduction: "Design Volume Reduction",
    inletPressureRated: "Inlet Pressure Rated",
    inletPressureMaximum: "Inlet Pressure Maximum",
    inletTemperatureRated: "Inlet Temperature Rated",
    inletTemperatureMaximum: "Inlet Temperature Maximum",
    inletVolumetricFlow: "Inlet Volumetric Flow (Wet) Rated",
    dischargePressureRated: "Discharge Pressure Rated",
    dischargePressureMaximum: "Discharge Pressure Maximum",
    dischargeTemperatureRated: "Discharge Temperature Rated",
    dischargeTemperatureMaximum: "Discharge Temperature Maximum",
    ratedIsentropicPower: "Rated Isentropic Power",
    ratedBrakePower: "Rated Brake Power",
    driver: "Driver",
    pressureRatioRated: "Pressure Ratio Rated",
    maleRotorLobes: "Male rotor lobes",
    femaleRotorLobes: "Female rotor lobes",
    maleRotorDiameter: "Male Rotor Diameter",
    femaleRotorDiameter: "Female Rotor Diameter"
  };
  export const OilDataVariable = {
    oilType: 'Oil Type',
    oilPressure: "Oil Pressure",
    designOilFlowRate: "Design Oil Flow Rate (for oil injected units only)",
    oilViscosity : "Oil Viscosity ",
    oilSpecificHeat: "Oil Specific Heat",
    oilDensity: "Oil Density",
    oilPumpEffeciency: "Oil Pump Efficiency",
  };
  export const ValveDataVariable = {
    calculateValveLosses: 'Calculate Valve Losses',
    headEndSuctionValveQuantity: "Head End Suction Valve Quantity",
    headEndDischargeValveQuantity: "Head End Discharge Valve Quantity",
    crankEndSuctionValveQuantity: "Crank End Suction Valve Quantity",
    crankEndDischargeValveQuantity: "Crank End Discharge Valve Quantity",
    headEndSuctionValveBoreDiameter: "Head End Suction Valve Bore Diameter",
    headEndDischargeValveBoreDiameter: "Head End Discharge Valve Bore Diameter",
    crankEndSuctionValveBoreDiamater: "Crank End Suction Valve Bore Diamater",
    crankEndDischargeValveBoreDiameter: "Crank End Discharge Valve Bore Diameter",
    headEndSuctionValveResistanceFactor: "Head End Suction Valve Resistance Factor",
    headEndDischargeValveResistanceFactor: "Head End Discharge Valve Resistance Factor",
    crankEndSuctionValveResistanceFactor: "Crank End Suction Valve Resistance Factor",
    crankEndDischargeValveResistanceFactor: "Crank End Discharge Valve Resistance Factor",
    headEndSuctionValveLosses: "Head End Suction Valve Losses",
    headEndDischargeValveLosses: "Head End Discharge Valve Losses",
    crankEndSuctionValveLosses: "Crank End Suction Valve Losses",
    crankEndDischargeValveLosses: "Crank End Discharge Valve Losses"
  };
  export const CapacityControlVariables = {
    capacityControlType:"Capacity Control Type",
    volumeReductionControl: "Volume reduction control", 
  };

  export const inputSections =  {...ScrewCompressorDesignVariable, ...OilDataVariable, ...ValveDataVariable, ...CapacityControlVariables}
  export const payloadMapper = [ScrewCompressorDesignVariable, OilDataVariable, CapacityControlVariables]
  export const screenConstant = [
    {variableName: ScrewCompressorDesignVariable, heading: "Minimum Required Data", stateName: "equipmentData"},
    {variableName: OilDataVariable, heading: "Oil Data", stateName: "oilData"},
    {variableName: CapacityControlVariables, heading: "Capacity Control Details", stateName: "capacityControlData"}
  ]
  export const filedsWithUnit = [
    "configSpeed", "maximumOperatingSpeed", "minimumOperatingSpeed", "startupSpeedThreshold", "startupPowerThreshold",
    "mechanicalEfficiency","barometricPressure", "ambientTemperature","ratedVolumetricDisplacement", "designVolumetricEfficiency",
    "condensateVapourFlowrate",  "inletPressureRated","inletPressureMaximum", "inletTemperatureRated", "inletTemperatureMaximum",
    "inletVolumetricFlow", "dischargePressureRated", "dischargePressureMaximum", "dischargeTemperatureRated", "dischargeTemperatureMaximum", 
    "ratedIsentropicPower","ratedBrakePower", "maleRotorDiameter", "femaleRotorDiameter", "oilPressure", "designOilFlowRate",
    "oilViscosity", "oilSpecificHeat", "oilDensity", "oilPumpEffeciency"
  ]
  const fieldsWithoutUnit = [
    "application", "purpose", "compressorType", "designInternalPressureRatio","flowmeterLocation","designVolumeReduction","driver", "pressureRatioRated", 
    "maleRotorLobes", "fluidComposition","femaleRotorLobes", "oilType","capacityControlType", "volumeReductionControl"
  ]
  
  export const FieldMapper = async(Mapper) => {
    const mappedFields = []
    const keys = Object.keys(Mapper)
    for await (let data of keys){
      if(filedsWithUnit.indexOf(data)>=0){
        const fieldObject ={ name: Mapper[data], design: null, unit: null }
        mappedFields.push(fieldObject)
      }
      else if(fieldsWithoutUnit.indexOf(data)>=0){
        const fieldObject =
        { name: Mapper[data], design:null }
        mappedFields.push(fieldObject)
      }
    }
    return mappedFields
  }
  
  
  export const designFieldConstant = {
    [ScrewCompressorDesignVariable.application]: [
      { title: 'Fixed Speed', value: 'fixedSpeed' },
      { title: 'Variable Speed', value: 'varaibleSpeed' },
    ],
    [ScrewCompressorDesignVariable.purpose]: [
      { title: 'Boil Off Gas Compression', value: 'boilOffGasCompression' }
    ],
    [ScrewCompressorDesignVariable.driver]: [
      { title: 'Synchronous Motor', value: 'synchronousMotor' },
      { title: 'Induction Motor', value: 'inductionMotor' },
      { title: 'Steam Turbine', value: 'steamTurbine' },
      { title: 'Gas Turbine', value: 'gasTurbine' },
      { title: 'IC Engine', value: 'ICEngine' },
    ],
    [ScrewCompressorDesignVariable.cylinderConfiguration]: [
      { title: 'Vertical', value: 'vertical' },
      { title: 'Horizontal', value: 'horizontal' },
    ],
    [ScrewCompressorDesignVariable.cylinderConstruction]: [
      { title: 'Non-Lubricated', value: 'NonLubricated' },
      { title: 'Lubricated', value: 'Lubricated' },
    ],
    [ScrewCompressorDesignVariable.interstageEquipment]: [
      { title: 'Coolers and Pulsation Devices', value: 'CoolersandPulsationDevices' },
    ],
    [OilDataVariable.loadingmode1]: [
      { title: 'Not-Applicable', value: 'notApplicable' },
      { title: 'Double Acting (DA)', value: 'DA' },
      { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
      { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
    ],
    [OilDataVariable.loadingmode2]: [
      { title: 'Not-Applicable', value: 'notApplicable' },
      { title: 'Double Acting (DA)', value: 'DA' },
      { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
      { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
    ],
    [OilDataVariable.loadingmode3]: [
      { title: 'Not-Applicable', value: 'notApplicable' },
      { title: 'Double Acting (DA)', value: 'DA' },
      { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
      { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
    ],
    [OilDataVariable.headEndClearancePocketMode]: [
      { title: 'Fixed', value: 'Fixed' },
      { title: 'Variable', value: 'Variable' },
    ],
    [OilDataVariable.crankEndClearancePocketMode]: [
      { title: 'Fixed', value: 'Fixed' },
      { title: 'Variable', value: 'Variable' },
    ],
    [ValveDataVariable.calculateValveLosses]: [
      { title: 'True', value: true },
      { title: 'False', value: false },
    ],
    [CapacityControlVariables.by]: [
      { title: "Manufacturer's Capacity Control", value: 'ManufacturerCapacityControl' },
      { title: "Purchaser's Bypass Control", value: 'PurchasersBypassControl' },
      { title: 'Both', value: 'Both' },
    ],
    [CapacityControlVariables.for]: [
      { title: 'Startup Only', value: 'StartupOnly' },
      { title: 'Part-Load Conditions', value: 'PartLoadConditions' },
      { title: 'Both', value: 'Both' },
    ],
    [CapacityControlVariables.with]: [
      { title: 'Auto Loading Delay Interlock', value: 'AutoLoadingDelayInterlock' },
      { title: 'Auto Immediate Unloading', value: 'AutoImmediateUnloading' },
    ],
    [CapacityControlVariables.using]: [
      { title: 'Fixed Volume Pockets', value: 'FixedVolumePockets' },
      { title: 'Suction Valve Unloaders', value: 'SuctionValveUnloaders' },
      { title: 'Finger', value: 'Finger' },
      { title: 'Plug', value: 'Plug' },
    ],
    [CapacityControlVariables.action]: [
      { title: 'Direct (Air-To-Unload)', value: 'Direct(Air-To-Unload)' },
      { title: 'Reversed (Air-To-Load/Fail Safe)', value: 'Reversed (Air-To-Load/Fail Safe)' },
    ]
  }