import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import {
  DesignDataMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  GeneratorParameterDataMessages,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  MotorParametersDataVariables
} from '../../../../../../constants/variables';
import {
  saveMotorParameterData,
  saveMotorParameterDataReset
} from '../../../../../../redux/actions/synchronousGenerator.action';
import {
  ChangeKey,
  initialData,
  MotorParametersDataShow
} from './MotorParametersData.Constants';
import './MotorParametersData.scss';

class MotorParametersData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: [],
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnit();
    this.getData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: [],
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnit() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  getData() {
    const {
      synchronousGeneratorConfigData: { motorParameterData },
    } = this.props.synchronousGeneratorReducer;
    const tempDisplayData = [
      {
        name: MotorParametersDataVariables.generatorRatedVoltage,
        unit: motorParameterData.generatorRatedVoltage.unit,
        design: motorParameterData.generatorRatedVoltage.design,
      },
      {
        name:
          MotorParametersDataVariables.ratedVoltageSaturatedSubtransientReactance,
        unit:
          motorParameterData.ratedVoltageSaturatedSubtransientReactance.unit,
        design:
          motorParameterData.ratedVoltageSaturatedSubtransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.ratedVoltageSaturatedNegativeSequenceReactance,
        unit:
          motorParameterData.ratedVoltageSaturatedNegativeSequenceReactance
            .unit,
        design:
          motorParameterData.ratedVoltageSaturatedNegativeSequenceReactance
            .design,
      },
      {
        name:
          MotorParametersDataVariables.ratedVoltageSaturatedArmatureTimeConstant,
        unit: motorParameterData.ratedVoltageSaturatedArmatureTimeConstant.unit,
        design:
          motorParameterData.ratedVoltageSaturatedArmatureTimeConstant.design,
      },
      {
        name: MotorParametersDataVariables.armatureLeakageReactance,
        unit: motorParameterData.armatureLeakageReactance.unit,
        design: motorParameterData.armatureLeakageReactance.design,
      },
      {
        name: MotorParametersDataVariables.directAxisArmatureReactance,
        unit: motorParameterData.directAxisArmatureReactance.unit,
        design: motorParameterData.directAxisArmatureReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedDirectAxisSynchronousReactance,
        unit: motorParameterData.saturatedDirectAxisSynchronousReactance.unit,
        design:
          motorParameterData.saturatedDirectAxisSynchronousReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedDirectAxisSynchronousReactance,
        unit: motorParameterData.unsaturatedDirectAxisSynchronousReactance.unit,
        design:
          motorParameterData.unsaturatedDirectAxisSynchronousReactance.design,
      },
      {
        name: MotorParametersDataVariables.potierReactance,
        unit: motorParameterData.potierReactance.unit,
        design: motorParameterData.potierReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedDirectAxisTransientReactance,
        unit: motorParameterData.saturatedDirectAxisTransientReactance.unit,
        design: motorParameterData.saturatedDirectAxisTransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedDirectAxisTransientReactance,
        unit: motorParameterData.unsaturatedDirectAxisTransientReactance.unit,
        design:
          motorParameterData.unsaturatedDirectAxisTransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedDirectAxisSubtransientReactance,
        unit: motorParameterData.saturatedDirectAxisSubtransientReactance.unit,
        design:
          motorParameterData.saturatedDirectAxisSubtransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedDirectAxisSubtransientReactance,
        unit:
          motorParameterData.unsaturatedDirectAxisSubtransientReactance.unit,
        design:
          motorParameterData.unsaturatedDirectAxisSubtransientReactance.design,
      },
      {
        name: MotorParametersDataVariables.quadratureAxisArmatureReactance,
        unit: motorParameterData.quadratureAxisArmatureReactance.unit,
        design: motorParameterData.quadratureAxisArmatureReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedQuadratureAxisSynchronousReactance,
        unit:
          motorParameterData.saturatedQuadratureAxisSynchronousReactance.unit,
        design:
          motorParameterData.saturatedQuadratureAxisSynchronousReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedQuadratureAxisSynchronousReactance,
        unit:
          motorParameterData.unsaturatedQuadratureAxisSynchronousReactance.unit,
        design:
          motorParameterData.unsaturatedQuadratureAxisSynchronousReactance
            .design,
      },
      {
        name: MotorParametersDataVariables.fieldLeakageReactance,
        unit: motorParameterData.fieldLeakageReactance.unit,
        design: motorParameterData.fieldLeakageReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedQuadratureAxisTransientReactance,
        unit: motorParameterData.saturatedQuadratureAxisTransientReactance.unit,
        design:
          motorParameterData.saturatedQuadratureAxisTransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedQuadratureAxisTransientReactance,
        unit:
          motorParameterData.unsaturatedQuadratureAxisTransientReactance.unit,
        design:
          motorParameterData.unsaturatedQuadratureAxisTransientReactance.design,
      },
      {
        name:
          MotorParametersDataVariables.saturatedQuadratureAxisSubtransientReactance,
        unit:
          motorParameterData.saturatedQuadratureAxisSubtransientReactance.unit,
        design:
          motorParameterData.saturatedQuadratureAxisSubtransientReactance
            .design,
      },
      {
        name:
          MotorParametersDataVariables.unsaturatedQuadratureAxisSubtransientReactance,
        unit:
          motorParameterData.unsaturatedQuadratureAxisSubtransientReactance
            .unit,
        design:
          motorParameterData.unsaturatedQuadratureAxisSubtransientReactance
            .design,
      },
      {
        name: MotorParametersDataVariables.quadratureAxisAmortisseurReactance,
        unit: motorParameterData.quadratureAxisAmortisseurReactance.unit,
        design: motorParameterData.quadratureAxisAmortisseurReactance.design,
      },
      {
        name: MotorParametersDataVariables.directAxisAmortisseurReactance,
        unit: motorParameterData.directAxisAmortisseurReactance.unit,
        design: motorParameterData.directAxisAmortisseurReactance.design,
      },
      {
        name: MotorParametersDataVariables.saturatedNegativeSequenceReactance,
        unit: motorParameterData.saturatedNegativeSequenceReactance.unit,
        design: motorParameterData.saturatedNegativeSequenceReactance.design,
      },
      {
        name: MotorParametersDataVariables.unsaturatedNegativeSequenceReactance,
        unit: motorParameterData.unsaturatedNegativeSequenceReactance.unit,
        design: motorParameterData.unsaturatedNegativeSequenceReactance.design,
      },
      {
        name: MotorParametersDataVariables.statorArmatureResistance,
        unit: motorParameterData.statorArmatureResistance.unit,
        design: motorParameterData.statorArmatureResistance.design,
      },
      {
        name: MotorParametersDataVariables.withoutFdrsFieldResistance,
        unit: motorParameterData.withoutFdrsFieldResistance.unit,
        design: motorParameterData.withoutFdrsFieldResistance.design,
      },
      {
        name: MotorParametersDataVariables.withFdrsFieldResistance,
        unit: motorParameterData.withFdrsFieldResistance.unit,
        design: motorParameterData.withFdrsFieldResistance.design,
      },
      {
        name: MotorParametersDataVariables.quadratureAxisAmortisseurResistance,
        unit: motorParameterData.quadratureAxisAmortisseurResistance.unit,
        design: motorParameterData.quadratureAxisAmortisseurResistance.design,
      },
      {
        name: MotorParametersDataVariables.directAxisAmortisseurResistance,
        unit: motorParameterData.directAxisAmortisseurResistance.unit,
        design: motorParameterData.directAxisAmortisseurResistance.design,
      },
      {
        name: MotorParametersDataVariables.zeroSequenceResistance,
        unit: motorParameterData.zeroSequenceResistance.unit,
        design: motorParameterData.zeroSequenceResistance.design,
      },
      {
        name: MotorParametersDataVariables.negativeSequenceResistance,
        unit: motorParameterData.negativeSequenceResistance.unit,
        design: motorParameterData.negativeSequenceResistance.design,
      },
      {
        name: MotorParametersDataVariables.armatureShortCircuitTimeConstant,
        unit: motorParameterData.armatureShortCircuitTimeConstant.unit,
        design: motorParameterData.armatureShortCircuitTimeConstant.design,
      },
      {
        name:
          MotorParametersDataVariables.directAxisTransientOpenCircuitTimeConstant,
        unit:
          motorParameterData.directAxisTransientOpenCircuitTimeConstant.unit,
        design:
          motorParameterData.directAxisTransientOpenCircuitTimeConstant.design,
      },
      {
        name:
          MotorParametersDataVariables.directAxisSubtransientOpenCircuitTimeConstant,
        unit:
          motorParameterData.directAxisSubtransientOpenCircuitTimeConstant.unit,
        design:
          motorParameterData.directAxisSubtransientOpenCircuitTimeConstant
            .design,
      },
      {
        name:
          MotorParametersDataVariables.quadratureAxisSubtransientOpenCircuitTimeConstant,
        unit:
          motorParameterData.quadratureAxisSubtransientOpenCircuitTimeConstant
            .unit,
        design:
          motorParameterData.quadratureAxisSubtransientOpenCircuitTimeConstant
            .design,
      },
      {
        name:
          MotorParametersDataVariables.directAxisTransientShortCircuitTimeConstant,
        unit:
          motorParameterData.directAxisTransientShortCircuitTimeConstant.unit,
        design:
          motorParameterData.directAxisTransientShortCircuitTimeConstant.design,
      },
      {
        name:
          MotorParametersDataVariables.directAxisSubtransientShortCircuitTimeConstant,
        unit:
          motorParameterData.directAxisSubtransientShortCircuitTimeConstant
            .unit,
        design:
          motorParameterData.directAxisSubtransientShortCircuitTimeConstant
            .design,
      },
      {
        name: MotorParametersDataVariables.windingCapacitancetoGround,
        unit: motorParameterData.windingCapacitancetoGround.unit,
        design: motorParameterData.windingCapacitancetoGround.design,
      },
      {
        name: MotorParametersDataVariables.i22tLimit,
        design: motorParameterData.i22tLimit,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      addMotorParameterData,
      synchronousGeneratorConfigData,
    } = this.props.synchronousGeneratorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnit();
    }

    if (
      synchronousGeneratorConfigData.isSuccess &&
      prevProps.synchronousGeneratorReducer.synchronousGeneratorConfigData.isSuccess !==
      synchronousGeneratorConfigData.isSuccess
    ) {
      this.getData();
    }

    if (
      addMotorParameterData.isSuccess &&
      prevProps.synchronousGeneratorReducer.addMotorParameterData.isSuccess !==
        addMotorParameterData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, GeneratorParameterDataMessages.SUCCESS);
    }
    if (
      addMotorParameterData.isError &&
      prevProps.synchronousGeneratorReducer.addMotorParameterData.isError !==
        addMotorParameterData.isError
    ) {
      Notification.show(Types.Error, GeneratorParameterDataMessages.ERROR);
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      }
      if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
        saveListener: true,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row) => {
    const { editTableRows } = this.state;
    const editTable = [...editTableRows];
    editTable.push(row);
    editTable.sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows.filter((editRow) => editRow !== row);
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onSave = () => {
    const {
      location: { state },
      saveMotorParameterData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
      copyDisplayData: displayData
    });

    if (
      !displayData.some(
        (item) =>
          item.name !== MotorParametersDataVariables.i22tLimit &&
          ((item.unit && !item.design && item.design !== 0) ||
            (!item.unit && (item.design || item.design === 0)))
      )
    ) {
      const data = ChangeKey;
      const payload = {};
      displayData.forEach((item) => {
        if (
          item.name !== MotorParametersDataVariables.i22tLimit &&
          (item.design || item.design === 0)
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: item.design,
          };
        } else if (item.design || item.design === 0) {
          payload[data[item.name]] = item.design;
        }
      });
      if (
        (this.handleNetwork() && state && state.componentData.info._id, payload)
      ) {
        saveMotorParameterData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, DesignDataMessages.FIELDS_DIRTY);
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          title={DISCARD_CHANGES}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  render() {
    const { isSchematicEditable } = this.props;
    const {
      synchronousGeneratorConfigData,
      addMotorParameterData,
    } = this.props.synchronousGeneratorReducer;
    const { saveListener, unitData } = this.state;

    return (
      <div className="MotorParametersData">
        {(synchronousGeneratorConfigData.isLoading ||
          addMotorParameterData.isLoading) && <Loader />}
        <CustomTable
          showHeader
          columns={MotorParametersDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={this.state.displayData}
          editableRow={this.state.editTableRows}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}

MotorParametersData.PropType = {
  location: PropType.object,
  synchronousGeneratorConfigData: PropType.object,
  addMotorParameterData: PropType.object,
  motorParameterData: PropType.object,
  saveMotorParameterData: PropType.func,
  saveMotorParameterDataReset: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
  isSchematicEditable: PropType.bool,
};

MotorParametersData.defaultProps = {
  isSchematicEditable: true,
};

const mapStateToProps = (state) => ({
  synchronousGeneratorReducer: state.synchronousGeneratorReducer,
  network: state.NetworkReducer,
  dataUnitState: state.DataUnitReducer,
});

const mapsDispatchToProps = (dispatch) => ({
  saveMotorParameterData: (componentId, payload) =>
    dispatch(saveMotorParameterData(componentId, payload)),
  saveMotorParameterDataReset: () => dispatch(saveMotorParameterDataReset()),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(MotorParametersData)
);
