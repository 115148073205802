import { Icon, Input } from "antd";
import _ from "lodash";
import React from "react";
import { validatePositiveFloat } from "../../../../../common/methods";
import {
  ButtonVariables,
  recipCompVariable,
  RunnerDataVariables,
  screwCompVariable,
  hvsgPerformanceDataVariables,
} from "../../../../../constants/variables";
import { newValidateNumber } from "../../../../../libs/validator";
import { Liquid_Expander, Pump } from "../ConfigDataConstants";
import { columnsDataVariablesMap } from "../FlowMeasurement/FlowMeasurementConstants";
import { DataVariableMap } from "../GasTurbine/DesignPerformanceData/designPerformanceGT.Constants";
import { GtPerfDataVariableMap } from "../GasTurbine/PerformanceData/PerformanceData.Constants";
import { dataVariablesMap } from "../Motor/CircuitData/CircuitData.constants";
import { stPerformanceDataVariables, stDesignDataVariables, controlValveDesignDataVariables, PIMComponentDesignDataVariables } from '../../../../../constants/variables';


export const dataVariable = [
  "Diameter",
  "Speed",
  "Volumetric Flow",
  "Head Drop",
  "Efficiency",
  "Valve Nominal Diameter",
  "Inlet Pipe Diameter",
  "Outlet Pipe Diameter",
  "Characterstics",
  "Polytropic / Adiabatic Head",
  "Voltage",
  "Frequency",
  "Rated Power Output",
  "Rated Current",
  "Synchronous Speed",
  "Full Load Speed",
  "Full Load Torque (FLT)",
  "Rotor GD2 at FLRPM",
  "Rotor Weight",
  "Locked Rotor Torque (LRT)",
  "Pull-up Torque (PUT)",
  "Pull-in Torque (PIT)",
  "Pull-out Torque (POT)",
  "Break Down Torque (BDT)",
  "Locked-Rotor Current",
  "Locked-Rotor PF",
  "Locked-Rotor Withstand Time, Cold",
  "Locked-Rotor Withstand Time, Rated Temp",
  "Stator Slots",
  "Rotor Slots",
  "Rated Motor Field Current",
  "Rated Motor Field Voltage",
  "Rated Exciter Field Current",
  "Rated Exciter Field Voltage",
  "Temperature Rise at Zero current",
  "Starting curve - Speed",
  "Starting curve - Current 100% Voltage",
  "Starting curve - Current 80% Voltage",
  "Starting curve - Power Factor",
  "Starting curve - Torque Rated Load",
  "Starting curve - Torque 100% Voltage",
  "Starting curve - Torque 80% Voltage",
  "Variable curve - Speed",
  "Variable curve - Torque",
  "Variable curve - Current",
  "Variable curve - Power",
  "Variable curve - Power Factor",
  "Performance curve - Load",
  "Performance curve - Power Factor",
  "Performance curve - Current",
  "Performance curve - Slip",
  "Motor kVA at Rated Voltage",
  "Rated Voltage Saturated Subtransient Reactance",
  "Rated Voltage Saturated Negative Sequence Reactance",
  "Rated Voltage Saturated Armature Time Constant",
  "Armature Leakage Reactance",
  "Direct Axis Armature Reactance",
  "Direct Axis Synchronous Reactance (Saturated)",
  "Direct Axis Synchronous Reactance (Un-Saturated)",
  "Potier Reactance",
  "Direct Axis Transient Reactance (Saturated)",
  "Direct Axis Transient Reactance (Un-Saturated)",
  "Direct Axis Subtransient Reactance (Saturated)",
  "Direct Axis Subtransient Reactance (Un-Saturated)",
  "Quadrature Axis Armature Reactance",
  "Quadrature Axis Synchronous Reactance (Saturated)",
  "Quadrature Axis Synchronous Reactance (Un-Saturated)",
  "Field Leakage Reactance",
  "Quadrature Axis Transient Reactance (Saturated)",
  "Quadrature Axis Transient Reactance (Un-Saturated)",
  "Quadrature Axis Subtransient Reactance (Saturated)",
  "Quadrature Axis Subtransient Reactance (Un-Saturated)",
  "Quadrature Axis Amortisseur Reactance (Slip = 1.0)",
  "Direct Axis Amortisseur Reactance (Slip = 1.0)",
  "Negative Sequence Reactance (Saturated)",
  "Negative Sequence Reactance (Un-Saturated)",
  "Stator Armature Resistance/Phase",
  "Field Resistance (Without FDRs)",
  "Field Resistance (With FDRs)",
  "Quadrature Axis Amortisseur Resistance (Slip = 1.0)",
  "Direct Axis Amortisseur Resistance (Slip = 1.0)",
  "Positive Sequence Resistance",
  "Negative Sequence Resistance",
  "Armature Short-Circuit Time Constant",
  "Direct Axis Transient Open-Circuit Time Constant",
  "Quadrature Axis Transient Open-Circuit Time Constant, SecCircuit Time Constant",
  "Direct Axis Subtransient Open-Circuit Time Constant",
  "Quadrature Axis Subtransient Open-Circuit Time Constant",
  "Direct Axis Transient Short-Circuit Time Constant",
  "Direct Axis Subtransient Short-Circuit Time Constant",
  "Winding Capacitance to Ground (per phase)",
  "Rated Voltage Saturated Armature Time Constant",
  "Exhaust Steam pressure",
  "Suction Steam temperature",
  "Reheater pressure drop",
  "Reheat Steam temperature",
  "Suction Steam pressure",
  "Throttle steam mass flow",
  "Output Power",
  "Real Power At Unity Power Factor",
  "Ambient Temperature",
  "Short Circuit Current (3 Phase RMS)",
  "Short Circuit Current (2 Phase Peak)",
  "Short Circuit Current (3 Phase Peak)",
  "Short Circuit Current (Steady state)",
  "Stator current Threshold For Running Status",
  "Rated Power Factor",
  "Real Power At Unity Power Factor",
  "Field I2R Losses",
  "Stray Load Losses",
  "Armature I2R losses",
  "Core Losses At Nominal Rating",
  "Friction and windage losses at nominal rating",
  "Generator power Threshold For Running Status",
  "Excitor Stator Resistance",
  "Generator Rotor Resistance",
  "Generator Stator Resistance",
  "Rated Temperature",
  "Generator Accelaration Time",
  "Rated Power",
  "Rated Voltage",
  "Zero Sequence Resistance",
  "Generator kVA Base at Rated Voltage and 25 C",
  "Generator speed Threshold For Running Status",
  "IGV",
  "DGV",
  "MW",
  "Work Input",
  "Working Fluid Viscosity",
  "Fluid Specific Gravity",
  "Working Fluid CP",
  "Guide Vane Position",
  "Speed Ratio",
  "Output Speed",
  "Differential Pressure",
  "Working Fluid Mass Flow",
  "Pump Speed",
  "Intermediate Speed",
  "Wheel Speed",
  "Output Torque",
  ...Object.values(columnsDataVariablesMap),
  ...Object.values(dataVariablesMap),
  ...Object.values(DataVariableMap),
  ...Object.values(GtPerfDataVariableMap),
  ...Object.values(stDesignDataVariables),
  ...Object.values(stPerformanceDataVariables),
  ...Object.values(controlValveDesignDataVariables),
  ...Object.values(recipCompVariable),
  ...Object.values(PIMComponentDesignDataVariables),
  ...Object.values(screwCompVariable),
  ...Object.values(hvsgPerformanceDataVariables),
];

const select = {
  name: "Select",
  value: "",
};

export const getUnit = (units, key) => {
  if (units && key && units.length > 0) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const runnerTableColumns = (config) => {
  const columnName =
    config.name === Pump
      ? [
        {
          title: RunnerDataVariables.ITEM_NAME,
          dataIndex: "name",
        },
        {
          title: RunnerDataVariables.TYPE,
          dataIndex: "type",
          render: (value, row, col, editable) => (
            <div
              className={`custom-table-select ${
                editable ? "beforeEdit" : ""
              } `}
            >
              <select
                name="type"
                defaultValue={value}
                disabled={editable}
                style={{ color: editable && "#d9d9d9" }}
                value={value}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
              >
                {RunnerImpellerType(config.name).map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.DIAMETER,
          dataIndex: "diameter",
          render: (value, row, col, editable) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="diameter"
                placeholder={dataVariable[0]}
                value={value}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
                disabled={editable}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.MIN_DIAMETER,
          dataIndex: "minDiameter",
          render: (value, row, col, editable) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="minDiameter"
                placeholder={dataVariable[0]}
                value={value}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
                disabled={editable}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.MAX_DIAMETER,
          dataIndex: "maxDiameter",
          render: (value, row, col, editable) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="maxDiameter"
                placeholder={dataVariable[0]}
                value={value}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
                disabled={editable}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.UNIT,
          dataIndex: "unit",
          render: (value, row, col, editable) => (
            <div
              className={`custom-table-select ${
                editable ? "beforeEdit" : ""
              } `}
            >
              <select
                name="unit"
                defaultValue={value}
                value={value}
                disabled={editable}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
              >
                {getUnit(config.unitData, "Diameter").map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        config.isRunnerEditable && {
          title: RunnerDataVariables.ACTIONS,
          render: (value, row, col, disabled, item) => (
            <div className="more-items">
              {disabled ? (
                <button
                  onClick={(e) => {
                    config.edit(row);
                  }}
                >
                  <Icon type="edit" />
                </button>
              ) : null}
              {!disabled ? (
                <button
                  onClick={(e) => {
                    config.undo(row);
                  }}
                >
                  <Icon type="undo" />
                </button>
              ) : null}
              {
                <button
                  onClick={(e) => {
                    config.delete(row);
                  }}
                >
                  <Icon type="delete" />
                </button>
              }
            </div>
          ),
        },
      ]
      : [
        {
          title: RunnerDataVariables.ITEM_NAME,
          dataIndex: "name",
        },
        {
          title: RunnerDataVariables.TYPE,
          dataIndex: "type",
          render: (value, row, col, editable) => (
            <div
              className={`custom-table-select ${
                editable ? "beforeEdit" : ""
              } `}
            >
              <select
                name="type"
                defaultValue={value}
                disabled={editable}
                value={value}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
              >
                {RunnerImpellerType(config.name).map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.DIAMETER,
          dataIndex: "diameter",
          render: (value, row, col, editable) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="diameter"
                placeholder={dataVariable[0]}
                value={value}
                min={0}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
                disabled={editable}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.UNIT,
          dataIndex: "unit",
          render: (value, row, col, editable) => (
            <div
              className={`custom-table-select ${
                editable ? "beforeEdit" : ""
              } `}
            >
              <select
                name="unit"
                defaultValue={value}
                value={value}
                disabled={editable}
                onChange={(e) => {
                  config.change(e.target, row);
                }}
              >
                {getUnit(config.unitData, "Diameter").map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        config.isRunnerEditable && {
          title: RunnerDataVariables.ACTIONS,
          render: (value, row, col, disabled, item) => (
            <div className="more-items">
              {disabled ? (
                <button
                  onClick={(e) => {
                    config.edit(row);
                  }}
                >
                  <Icon type="edit" />
                </button>
              ) : null}
              {!disabled ? (
                <button
                  onClick={(e) => {
                    config.undo(row);
                  }}
                >
                  <Icon type="undo" />
                </button>
              ) : null}
              {
                <button
                  onClick={(e) => {
                    config.delete(row);
                  }}
                >
                  <Icon type="delete" />
                </button>
              }
            </div>
          ),
        },
      ];

  return columnName;
};

export const runnerTableAddRow = (actions) => {
  const coulmnData =
    actions.name === Pump
      ? [
        {
          title: RunnerDataVariables.ITEM_NAME,
          dataIndex: "name",
        },
        {
          title: RunnerDataVariables.TYPE,
          dataIndex: "type",
          render: (value, row, col, disabled) => (
            <div
              className={`custom-table-select ${
                actions.isError.type ? "error" : "not-error"
              }`}
            >
              <select
                name="type"
                defaultValue={value}
                value={value}
                onChange={(e) => {
                  actions.change(e.target);
                }}
              >
                <option value={select.value}>{select.name}</option>
                {RunnerImpellerType(actions.name).map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.DIAMETER,
          dataIndex: "diameter",
          render: (value, row, col) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="diameter"
                placeholder={dataVariable[0]}
                value={value}
                min={0}
                onChange={(e) => {
                  actions.change(e.target);
                }}
                className={
                  (!_.isEmpty(value) && !validatePositiveFloat(value)) ||
                    actions.isError["diameter"]
                    ? "error"
                    : "not-error"
                }
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.MIN_DIAMETER,
          dataIndex: "minDiameter",
          render: (value, row, col) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="minDiameter"
                placeholder={dataVariable[0]}
                value={value}
                min={0}
                onChange={(e) => {
                  actions.change(e.target);
                }}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.MAX_DIAMETER,
          dataIndex: "maxDiameter",
          render: (value, row, col) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="maxDiameter"
                placeholder={dataVariable[0]}
                value={value}
                min={0}
                onChange={(e) => {
                  actions.change(e.target);
                }}
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.UNIT,
          dataIndex: "unit",
          render: (value, row, col, disabled) => (
            <div
              className={`custom-table-select ${
                actions.isError.unit ? "error" : "not-error"
              }`}
            >
              <select
                name="unit"
                defaultValue={value}
                value={value}
                onChange={(e) => {
                  actions.change(e.target);
                }}
              >
                {!value || !value.length ? (
                  <option value={select.value}>{select.name}</option>
                ) : null}
                {getUnit(actions.unitData, "Diameter").map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.ACTIONS,
          dataIndex: "name",
          render: (value, row, col) => (
            <button
              className="btn-default btn-small"
              onClick={(e) => {
                actions.submit(e.target);
              }}
            >
              {ButtonVariables.ADD}
            </button>
          ),
        },
      ]
      : [
        {
          title: RunnerDataVariables.ITEM_NAME,
          dataIndex: "name",
        },
        {
          title: RunnerDataVariables.TYPE,
          dataIndex: "type",
          render: (value, row, col, disabled) => (
            <div
              className={`custom-table-select ${
                actions.isError.type ? "error" : "not-error"
              }`}
            >
              <select
                name="type"
                defaultValue={value}
                value={value}
                onChange={(e) => {
                  actions.change(e.target);
                }}
              >
                <option value={select.value}>{select.name}</option>
                {RunnerImpellerType(actions.name).map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.DIAMETER,
          dataIndex: "diameter",
          render: (value, row, col) => {
            value = _.isNil(value) ? "" : value.toString();
            return (
              <Input
                name="diameter"
                placeholder={dataVariable[0]}
                value={value}
                min={0}
                onChange={(e) => {
                  actions.change(e.target);
                }}
                className={
                  !_.isEmpty(value) && !validatePositiveFloat(value)
                    ? "error"
                    : "not-error"
                }
                onKeyDown={(e) => {
                  newValidateNumber(e);
                }}
                autoComplete="off"
              />
            );
          },
        },
        {
          title: RunnerDataVariables.UNIT,
          dataIndex: "unit",
          render: (value, row, col, disabled) => (
            <div
              className={`custom-table-select ${
                actions.isError.unit ? "error" : "not-error"
              }`}
            >
              <select
                name="unit"
                defaultValue={value}
                value={value}
                onChange={(e) => {
                  actions.change(e.target);
                }}
              >
                {!value || !value.length ? (
                  <option value={select.value}>{select.name}</option>
                ) : null}
                {getUnit(actions.unitData, "Diameter").map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          ),
        },
        {
          title: RunnerDataVariables.ACTIONS,
          dataIndex: "name",
          render: (value, row, col) => (
            <button
              className="btn-default btn-small"
              onClick={(e) => {
                actions.submit(e.target);
              }}
            >
              {ButtonVariables.ADD}
            </button>
          ),
        },
      ];
  return coulmnData;
};

export const getRunnerOrImpellerArray = (name) => {
  const RunnerIndex = [];
  for (let i = 1; i <= 20; i++) {
    RunnerIndex.push(
      name === Liquid_Expander ? `Runner ${[i]}` : `Impeller ${[i]}`
    );
  }
  return RunnerIndex;
};

export const RunnerImpellerType = (name) => {
  if (name === Liquid_Expander) {
    return [
      "Shrouded",
      "Shrouded 2D",
      "Shrouded 3D",
      "Semi-open",
      "Semi-open R-Type",
      "Semi-open S-Type",
    ];
  }
  return [
    "Shrouded",
    "Shrouded 2D",
    "Shrouded 3D",
    "Semi-open",
    "Semi-open R-Type",
    "Semi-open S-Type",
    "Double Suction",
  ];
};
