import React from 'react';
import PropTypes from 'prop-types';
import DefaultFilterDropDownComponent from './DefaultFilterDropDownComponent';
import DefaultFilterDropDownContainer from './DefaultFilterDropDownContainer';
import './Filter.scss';

class ExpandableDropdown extends React.PureComponent {
  state = {
    isOpened: false,
  };

  node = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState(
      {
        isOpened: false,
      },
      () => {
        this.props.onToggleOpen(this.state.isOpened);
      },
    );
  };

  closeModal=() => {
    this.setState(
      {
        isOpened: false,
      },
      () => {
        this.props.onToggleOpen(this.state.isOpened);
      },
    );
  }

  render() {
    const {
      headLabels,
      childLabels,
      expandedIndex,
      onApply,
      onReset,
      onHeadPress,
      onChildPress,
      DropDownComponent,
      DropDownContainerComponent,
      className,
    } = this.props;
    return (
      <div className={className} ref={this.node}>
        <DropDownContainerComponent
          isOpened={this.state.isOpened}
          toggleOpen={() => this.setState({ isOpened: !this.state.isOpened })}
          hasFilterActive={this.props.hasFilterActive}
        >
          <DropDownComponent
            headLabels={headLabels}
            childLabels={childLabels}
            expandedIndex={expandedIndex}
            onHeadPress={onHeadPress}
            onChildPress={onChildPress}
            onApply={() => {
              this.setState({ isOpened: false });
              onApply();
            }}
            onClose={this.closeModal}
            onReset={onReset}
          />
        </DropDownContainerComponent>
      </div>
    );
  }
}

ExpandableDropdown.propTypes = {
  headLabels: PropTypes.array.isRequired,
  childLabels: PropTypes.array.isRequired,
  expandedIndex: PropTypes.number.isRequired,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  onHeadPress: PropTypes.func,
  onChildPress: PropTypes.func,
  DropDownComponent: PropTypes.func.isRequired,
  DropDownContainerComponent: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  onToggleOpen: PropTypes.func,
  hasFilterActive: PropTypes.bool,
};

ExpandableDropdown.defaultProps = {
  headLabels: ['Level', 'Type'],
  childLabels: [
    { High: false, Low: false },
    { Bad: false, good: false },
  ],
  expandedIndex: -1,
  onApply: () => {},
  onReset: () => {},
  onHeadPress: () => {},
  onChildPress: () => {},
  DropDownComponent: DefaultFilterDropDownComponent,
  DropDownContainerComponent: DefaultFilterDropDownContainer,
  className: 'filter-row',
  onToggleOpen: () => {},
  hasFilterActive: false,
};

export default ExpandableDropdown;
