import { Col, Form, Input, Modal, Row } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { connect } from 'react-redux';
import '../../../constants/global.scss';
import {
  EditCurrentNodeMessages,
  NO_INTERNET,
  SchematicComponentMessages,
} from '../../../constants/messages';
import * as Pattern from '../../../constants/pattern';
import { EditCurrentNodeVariables } from '../../../constants/variables';
import {
  clearSchematicState,
  createSchematicComponent,
  deleteSchematicComponent,
  editSchematicComponent,
  getSchematicComponent,
} from '../../../redux/actions/schematic.action';
import Loader from '../../Loader/Loader';
import Notification, { Types } from '../../Notification/Notification';

class EditCurrentNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false,
    };
  }

  componentDidMount() {
    const { editMode } = this.props;
    if (editMode) {
      this.setFormData();
    }
  }

  componentDidUpdate() {
    const {
      isSuccessCreateSchematicComponentFetch,
      isSuccessEditSchematicComponentFetch,
      isErrorComponentSchematic,
      errorCode,
      schematicComponentData,
    } = this.props.schematicState.apiState;

    const { clearSchematicState } = this.props;

    if (isSuccessEditSchematicComponentFetch) {
      const { node, handleConfirm } = this.props;
      clearSchematicState();
      handleConfirm(node, schematicComponentData);
    }

    if (isSuccessCreateSchematicComponentFetch) {
      const { node, handleConfirm } = this.props;
      clearSchematicState();
      handleConfirm(node, schematicComponentData);
    }

    if (isErrorComponentSchematic) {
      Notification.show(Types.Error, SchematicComponentMessages[errorCode]);
      clearSchematicState();
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  setFormData() {
    const { info } = this.props.node.value;

    if (info && info._id) {
      this.props.form.setFieldsValue(info);
    }
  }

  save(values) {
    const { createSchematicComponent, editSchematicComponent } = this.props;
    const { info } = this.props.node.value;

    if (this.handleNetwork()) {
      if (this.props.editMode) {
        editSchematicComponent(values, info._id);
      } else {
        createSchematicComponent(values);
      }
    }
  }

  hideModal = () => {
    const { handleCancel } = this.props;
    handleCancel();
  };

  handleConfirm = () => {
    const { form, node } = this.props;
    const name = form.getFieldValue('name');
    const tagInfo = form.getFieldValue('tagInfo');
    const error = !(name && tagInfo);
    if (error) {
      Notification.show(Types.Error, EditCurrentNodeMessages.INVALID_INPUT);
    } else {
      this.props.form.setFieldsValue({
        name: this.props.form.getFieldValue('name').trim(),
      });
      this.props.form.setFieldsValue({
        tagInfo: this.props.form.getFieldValue('tagInfo').trim(),
      });
      form.validateFields((err, values) => {
        if (!err) {
          if (!this.props.editMode) {
            values.equipmentId = this.props.equipmentId;
            values.assetId = node.value.assetInfo._id;
          }
          this.save(values);
        }
      });
    }
  };

  render() {
    const { node, visible } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.props.schematicState;

    return (
      <div className="EditCurrentNode">
        {loading ? <Loader /> : null}
        <Modal
          title={
            this.props.editMode && node.value.info
              ? EditCurrentNodeVariables.COMPONENT_EDIT
              : EditCurrentNodeVariables.COMPONENT_ADDED
          }
          visible={visible}
          onOk={this.handleConfirm}
          onCancel={this.hideModal}
          okText={
            this.props.editMode
              ? EditCurrentNodeVariables.UPDATE
              : EditCurrentNodeVariables.PROCEED
          }
          cancelText={EditCurrentNodeVariables.CANCEL}
        >
          <Form>
            <Row>
              <Col>
                <Form.Item label={EditCurrentNodeVariables.COMPONENT_TITLE}>
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: EditCurrentNodeMessages.REQUIRED_FIELD,
                      },
                      {
                        pattern: Pattern.Name.regx,
                        message: Pattern.Name.message,
                      },
                    ],
                  })(<Input maxLength={50} />)}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={EditCurrentNodeVariables.COMPONENT_TAG_INFO}>
                  {getFieldDecorator('tagInfo', {
                    rules: [
                      {
                        required: true,
                        message: EditCurrentNodeMessages.REQUIRED_FIELD,
                      },
                      {
                        pattern: Pattern.Name.regx,
                        message: Pattern.Name.message,
                      },
                    ],
                  })(<Input maxLength={50} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  schematicState: state.SchematicReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSchematicComponent: (payload) =>
    dispatch(createSchematicComponent(payload)),
  editSchematicComponent: (payload, id) =>
    dispatch(editSchematicComponent(payload, id)),
  getSchematicComponent: (id) => dispatch(getSchematicComponent(id)),
  deleteSchematicComponent: (id) => dispatch(deleteSchematicComponent(id)),
  clearSchematicState: () => dispatch(clearSchematicState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'componentForm' })(EditCurrentNode));
