import React, { Component } from 'react';
import { Tabs, Empty } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../common/Loader/Loader';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import { NO_INTERNET } from '../../../../constants/messages';
import {
  getConfig,
  clearConfigState,
} from '../../../../redux/actions/config.action';
import ConfirmModal from '../../../../common/Modal/Modal';
import { updateFormEdit } from '../../../../redux/actions/formEdit.action';
const { TabPane } = Tabs;

class NewCurvesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      activeKey: '1',
      tabChange: null,
      formEdit: false,
      visible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('form-edit', () => {
      const { updateFormEdit } = this.props;
      updateFormEdit(true);
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', (e) => {
      const { updateFormEdit } = this.props;
      updateFormEdit(false);
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeKey } = this.props;
    if (prevProps.activeKey !== activeKey) {
      this.setState({
        activeKey: activeKey || '1',
      });
    }

    const {
      isConfigFetchSuccess,
      isConfigFetchError,
    } = this.props.configState.apiState;
    const { clearConfigState, tabsInfo } = this.props;
    if (isConfigFetchSuccess) {
      clearConfigState();
      this.setState({
        isConfigData: Object.keys(tabsInfo).map((item) => true),
      });
    }

    if (isConfigFetchError) {
      clearConfigState();
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    return false;
  }

  onTabChange(pane) {
    const { isEdit } = this.props;
    const { formEdit, activeKey } = this.state;
    if (formEdit || isEdit) {
      this.setState({
        activeKey: activeKey,
        tabChange: pane,
        visible: true,
      });
    } else {
      this.setState({
        activeKey: pane,
      });
    }
  }

  clearConfigState(index) {
    const { isConfigData } = this.state;
    const newisConfig = [...isConfigData];
    newisConfig[index] = false;
    this.setState({
      isConfigData: newisConfig,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { tabChange } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.setState({
          activeKey: tabChange,
          tabChange: null,
        });
        this.disableEditForm();
      }
    );
  }

  renderModal() {
    const { visible, message } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={message}
        />
      );
    }
    return null;
  }

  componentWillUnmount() {
    const { clearConfigState } = this.props;
    clearConfigState();
  }

  render() {
    const { tabsInfo, isSchematicEditable } = this.props;
    const { loading } = this.props.configState;
    const { configData } = this.props.configState.apiState;
    const designDataObject = configData?.designData;
    const performanceData = configData?.performanceData;
    const curvesData = configData?.curvesData;
    //const { activeKey, isConfigData } = this.state;
    const { activeKey } = this.state;
    return (
      <div className="ConfigData">
        {loading ? <Loader /> : null}
        <div className="tab-type-button">
          <Tabs
            defaultActiveKey="1"
            onChange={(event) => {
              this.onTabChange(event);
            }}
            activeKey={activeKey}
          >
            {tabsInfo &&
              Object.keys(tabsInfo).map((item, index) => {
                const tabItem = tabsInfo[item];
                return (
                  <TabPane tab={item} key={`${index + 1}`}>
                    {tabItem.screen ? (
                      <tabItem.screen
                        isSchematicEditable={isSchematicEditable}
                        activeKey={activeKey}
                        configData={{ ...configData }}
                        //isConfigData={isConfigData[index]}
                        clearConfigState={() => {
                          this.clearConfigState(index);
                        }}
                        tabValue={this.state.activeKey}
                        location={this.props.location}
                        designDataObject={designDataObject}
                        performanceData={performanceData}
                        curvesData={curvesData}
                      />
                    ) : (
                      <Empty />
                    )}
                  </TabPane>
                );
              })}
          </Tabs>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
  network: state.NetworkReducer,
  configState: state.ConfigReducer,
  equipmentState: state.EquipmentReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
  getConfig: (id, type) => dispatch(getConfig(id, type)),
  clearConfigState: () => dispatch(clearConfigState()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewCurvesData)
);
