import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import Loader from '../../../../../../common/Loader/Loader';
import {
  validateNegativeNumber,
  validatePositiveFloat
} from '../../../../../../common/methods';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET
} from '../../../../../../constants/messages';
import {
  ButtonVariables,
  CIRCUIT_DATA
} from '../../../../../../constants/variables';
import { updateFormEdit } from '../../../../../../redux/actions/formEdit.action';
import {
  clearMotorReducer,
  fetchMotorConfigData,
  saveEquCktData
} from '../../../../../../redux/actions/motor.action';
import {
  data,
  tableCalculatedValue,
  tableDataVariables,
  tableDischargeConditions,
  tableInletCondition
} from './CircuitData.constants';
import './CircuitData.scss';

class CircuitData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formEdit: false,
      dirtyForm: 0,
      dirtyRows: [],
      circuitData: {},
      editRow: {},
      updatedFields: {},
      dataUnits: {},
      visible: false,
      saveListener: false,
    };
  }

  componentDidMount() {
    this.setUnit();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', () => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });

    this.setState({
      /** use deep cloning here */
      circuitData: !_.isEmpty(
        this.props.circuitData.motorConfigData.equivalentCircuitData
          .dataVariable.ratedkVA
      )
        ? _.cloneDeep(
            this.props.circuitData.motorConfigData.equivalentCircuitData
          )
        : _.cloneDeep(data),
    });
  }

  componentDidUpdate(prevProps) {
    const { motorConfigData, addEquCktData } = this.props.circuitData;
    if (
      motorConfigData.isSuccess &&
      motorConfigData.isSuccess !==
        prevProps.circuitData.motorConfigData.isSuccess
    ) {
      this.setState(
        {
          circuitData: !_.isEmpty(
            motorConfigData.equivalentCircuitData.dataVariable.ratedkVA
          )
            ? _.cloneDeep(motorConfigData.equivalentCircuitData)
            : data,
        },
        () => {
          this.disableEditForm();
        }
      );
    }
    if (
      addEquCktData.isSuccess &&
      addEquCktData.isSuccess !== prevProps.circuitData.addEquCktData.isSuccess
    ) {
      Notification.show(Types.Success, CIRCUIT_DATA.ADDED);
      this.setState(
        {
          visible: false,
        },
        () => {
          this.getCircuitData();
        }
      );
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  getCircuitData = () => {
    const {
      location: { state },
    } = this.props;
    if (state.componentData && state.componentData.info) {
      this.props.getCircuitData(state.componentData.info._id);
    }
  };

  setUnit = () => {
    const { unitData } =
      this.props.dataUnitState &&
      this.props.dataUnitState.apiState &&
      this.props.dataUnitState.apiState;
    let obj = {};
    if (unitData && unitData.items && unitData.items.length) {
      for (let i = 0; i < unitData.items.length; i++) {
        obj[unitData.items[i].dataVariable] = unitData.items[i].units;
      }
    }
    this.setState({ dataUnits: obj });
  };

  filterData(data) {
    let temp = { ...data };
    delete temp['_id'];
    delete temp['createdAt'];
    delete temp['updatedAt'];
    Object.keys(temp).forEach((item) => {
      if (temp[item]._id) {
        delete temp[item]['_id'];
      }
      Object.keys(temp[item]).forEach((itemi) => {
        if (temp[item][itemi]._id) {
          delete temp[item][itemi]['_id'];
        }
      });
    });
    return _.cloneDeep(temp);
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      this.setState({ network: false });
      return false;
    }
  }

  getDataArray(data, key) {
    let temp = [];
    if (data) {
      Object.keys(data).forEach((item) => {
        if (item !== '_id') {
          data[item][key] = item;
          temp.push(data[item]);
        }
      });
    }
    return _.cloneDeep(temp);
  }

  validateFields = () => {
    const { circuitData } = this.state;
    let tempDirtyRow = [];
    Object.values(circuitData).forEach((item) => {
      Object.values(item).forEach((element) => {
        // changes values of design to string before validation
        if (_.isFinite(parseFloat(element.design))) {
          element.design = element.design.toString();
        }

        if (element.unit && !element.design) {
          tempDirtyRow.push(element);
        }

        if (!element.unit && element.design) {
          tempDirtyRow.push(element);
        }

        if (element.unit && element.design) {
          if (
            element.residualVoltageOpenCircuitTimeConstant ===
            'phasetoPhaseResistanceTemperature'
          ) {
            if (
              !_.isFinite(parseFloat(element.design)) ||
              (element.design &&
                validateNegativeNumber(element.design.toString()))
            ) {
              tempDirtyRow.push(element);
            }
          } else {
            if (
              !_.isFinite(parseFloat(element.design)) ||
              (element.design &&
                !validatePositiveFloat(element.design.toString()))
            ) {
              tempDirtyRow.push(element);
            }
          }
        }
      });
    });

    if (tempDirtyRow.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  onValueChange(field, row, key, item) {
    const { circuitData } = this.state;
    let temp = { ...circuitData };
    let rowKey = Object.keys(temp[key])[row];

    if (!temp[key]) {
      temp[key] = {};
    }
    if (!temp[key][rowKey]) {
      temp[key][rowKey] = {};
    }

    temp[key][rowKey][field.name] = field.value;

    this.setState(
      {
        circuitData: _.cloneDeep(temp),
      },
      () => {
        this.validateFields();
      }
    );
  }

  edit(row, key) {
    const { editRow, updatedFields, circuitData } = this.state;
    let temp = { ...editRow };
    let rowKey = Object.keys(circuitData[key])[row];
    let updatedTemp = { ...updatedFields };

    if (!temp[key]) {
      temp[key] = [];
    }
    temp[key].push(row);
    if (!updatedTemp[key]) {
      updatedTemp[key] = {};
    }
    updatedTemp[key][rowKey] = _.cloneDeep(circuitData[key][rowKey]);
    this.setState(
      {
        editRow: temp,
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row, key) {
    const { editRow, updatedFields, circuitData } = this.state;
    const { dirtyRows } = this.state;
    let tempDirtyRow = [...dirtyRows];
    let temp = { ...editRow };
    let rowKey = Object.keys(circuitData[key])[row];
    let updatedTemp = _.cloneDeep(circuitData);
    tempDirtyRow = tempDirtyRow.filter(function (e) {
      return e !== row;
    });
    temp[key] = temp[key].filter(function (e) {
      return e !== row;
    });
    updatedTemp[key][rowKey] = { ...updatedFields[key][rowKey] };
    this.setState({
      editRow: temp,
      circuitData: _.cloneDeep(updatedTemp),
      dirtyRows: tempDirtyRow,
    });
  }

  save() {
    const {
      location: { state },
    } = this.props;
    const { addCircuitData } = this.props;
    const { circuitData } = this.state;
    this.setState({
      saveListener: true,
    });
    let temp = _.cloneDeep(circuitData);

    if (this.validateFields()) {
      Object.keys(temp).forEach((item) => {
        Object.keys(temp[item]).forEach((data) => {
          delete temp[item][data][item];
          Object.keys(temp[item][data]).forEach((element) => {
            //delete the extra key (siValue)
            if (element !== 'unit' && element !== 'design') {
              delete temp[item][data][element];
            }

            //delete the fields if there's a bad value in that
            if (
              !temp[item][data]['design'] &&
              temp[item][data]['design'] !== 0
            ) {
              delete temp[item][data][element];
            }

            //changes the values from string to parsefloat
            if (temp[item][data]['design']) {
              temp[item][data]['design'] = parseFloat(
                temp[item][data]['design']
              );
            }
          });
        });
      });

      let payload = temp;
      if (this.handleNetwork() && state.componentData) {
        addCircuitData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, CIRCUIT_DATA.DIRTY_ROWS);
    }
  }

  clearDirtyForm() {
    const { motorConfigData } = this.props.circuitData;
    this.setState({
      circuitData: _.cloneDeep(motorConfigData.equivalentCircuitData),
      editRow: {},
      updatedFields: {},
    });
  }

  reset() {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    }
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState(
      {
        circuitData: [],
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  };

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { loading } = this.props.designState;
    const { motorConfigData, addEquCktData } = this.props.circuitData;
    const { isSchematicEditable } = this.props;

    return (
      <div className="CircuitData">
        {(loading || motorConfigData.isLoading || addEquCktData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          columns={tableDataVariables({
            change: (field, row, item) => {
              this.onValueChange(field, row, 'dataVariable', item);
            },
            edit: (row) => {
              this.edit(row, 'dataVariable');
            },
            undo: (row) => {
              this.undo(row, 'dataVariable');
            },
            dataUnits: this.state.dataUnits,
            saveListener: this.state.saveListener,
            isSchematicEditable,
          })}
          data={this.getDataArray(
            this.state.circuitData['dataVariable'],
            'dataVariable'
          )}
          editableRow={this.state.editRow['dataVariable']}
          showHeader={true}
        />
        <CustomTable
          columns={tableInletCondition({
            change: (field, row, item) => {
              this.onValueChange(field, row, 'lockedRotorAtRatedVoltage', item);
            },
            edit: (row) => {
              this.edit(row, 'lockedRotorAtRatedVoltage');
            },
            undo: (row) => {
              this.undo(row, 'lockedRotorAtRatedVoltage');
            },
            dataUnits: this.state.dataUnits,
            saveListener: this.state.saveListener,
            isSchematicEditable,
          })}
          data={this.getDataArray(
            this.state.circuitData['lockedRotorAtRatedVoltage'],
            'lockedRotorAtRatedVoltage'
          )}
          editableRow={this.state.editRow['lockedRotorAtRatedVoltage']}
          showHeader={true}
        />
        <CustomTable
          columns={tableDischargeConditions({
            change: (field, row, item) => {
              this.onValueChange(field, row, 'ratedLoadAtRatedVoltage', item);
            },
            edit: (row) => {
              this.edit(row, 'ratedLoadAtRatedVoltage');
            },
            undo: (row) => {
              this.undo(row, 'ratedLoadAtRatedVoltage');
            },
            dataUnits: this.state.dataUnits,
            saveListener: this.state.saveListener,
            isSchematicEditable,
          })}
          data={this.getDataArray(
            this.state.circuitData['ratedLoadAtRatedVoltage'],
            'ratedLoadAtRatedVoltage'
          )}
          editableRow={this.state.editRow['ratedLoadAtRatedVoltage']}
          showHeader={true}
        />
        <CustomTable
          columns={tableCalculatedValue({
            change: (field, row, item) => {
              this.onValueChange(
                field,
                row,
                'residualVoltageOpenCircuitTimeConstant',
                item
              );
            },
            edit: (row) => {
              this.edit(row, 'residualVoltageOpenCircuitTimeConstant');
            },
            undo: (row) => {
              this.undo(row, 'residualVoltageOpenCircuitTimeConstant');
            },
            dataUnits: this.state.dataUnits,
            isSchematicEditable,
            saveListener: this.state.saveListener,
          })}
          data={this.getDataArray(
            this.state.circuitData['residualVoltageOpenCircuitTimeConstant'],
            'residualVoltageOpenCircuitTimeConstant'
          )}
          editableRow={
            this.state.editRow['residualVoltageOpenCircuitTimeConstant']
          }
          showHeader={true}
        />
        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    circuitData: state.MotorReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCircuitData: (id) => dispatch(fetchMotorConfigData(id)),
  addCircuitData: (id, payload) => dispatch(saveEquCktData(id, payload)),
  clearMotorReducer: () => dispatch(clearMotorReducer()),
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
});

CircuitData.prototypes = {
  isSchematicEditable: PropTypes.bool,
  activeKey: PropTypes.string,
  configData: PropTypes.object,
  clearConfigState: PropTypes.func,
  tabValue: PropTypes.string,
  designState: PropTypes.object,
  network: PropTypes.object,
  circuitData: PropTypes.object,
  getCircuitData: PropTypes.func,
  addCircuitData: PropTypes.func,
  clearMotorReducer: PropTypes.func,
  getDataVariables: PropTypes.func,
  clearDesignState: PropTypes.func,
  addEditDesignData: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

CircuitData.defaultProps = {
  isSchematicEditable: true,
  activeKey: '',
  configData: {},
  clearConfigState: () => {},
  tabValue: '',
  designState: {},
  network: {},
  circuitData: {},
  getCircuitData: () => {},
  addCircuitData: () => {},
  clearMotorReducer: () => {},
  getDataVariables: () => {},
  clearDesignState: () => {},
  addEditDesignData: () => {},
  history: {},
  location: {},
  match: {},
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CircuitData)
);
