import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppLoader from "../../AppLoader";
import RouterOutlet from "../../libs/RouterOutlet";
import { clearToken } from "../../libs/token";
import { clearUserRoleState, userRole } from "../../redux/actions/login.action";
import { requestDB } from "../../services/indexedDB";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";
import * as Routes from "../../constants/routes";
import "./AppScreen.scss";
import { RefreshAuthToken } from "../../auth/authRefrehToken";

export let UserRole = null;
export let UserAccess = null;
export let FirstName = null;
export let LastName = null;
export let UserId = null;

class AppScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: true,
    };
  }

  componentDidMount() {
    this.getUserRole();
    requestDB();
    setTimeout(() => {
      this.setState({
        logo: false,
      });
    }, 5000);
  }

  componentDidUpdate() {
    const {
      isSuccessUserRole,
      isErrorUserRole,
      userRole,
      userId,
      firstName,
      lastName
    } = this.props.userRoleState.apiState;
    const { accessInfo } = this.props.userRoleState;

    const { clearUserRoleStateAction } = this.props;

    if (isSuccessUserRole) {
      clearUserRoleStateAction();
      UserRole = userRole;
      UserAccess = accessInfo;
      UserId = userId;
      FirstName = firstName;
      LastName = lastName;
    }

    if (isErrorUserRole) {
      clearUserRoleStateAction();
      clearToken();
      this.props.history.push(Routes.login);
    }
  }

  getUserRole() {
    const { userRoleAction, networkState } = this.props;
    if (networkState.networkState) {
      userRoleAction();
      RefreshAuthToken();
    }
  }

  render() {
    const { logo } = this.state;
    return UserRole ? (
      <div className="AppScreen">
        {logo && <AppLoader />}
        <Header />
        <div className="content-section">
          <SideMenu />
          <div className="screen">
            <RouterOutlet routes={this.props.routes} />
          </div>
        </div>
      </div>
    ) : (
      <AppLoader />
    );
  }
}

const mapStateToProps = (state) => ({
  networkState: state.NetworkReducer,
  userRoleState: state.LoginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  userRoleAction: () => dispatch(userRole()),
  clearUserRoleStateAction: () => dispatch(clearUserRoleState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppScreen));
