import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import PropType from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../common/FormFields/FormFields';
import Loader from '../../../../../common/Loader/Loader';
import { validatePositiveFloat } from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  ERROR_MESSAGES,
  FlowMeasurementMessages,
  FORM_DISCARD_MSG,
  NO_INTERNET,
} from '../../../../../constants/messages';
import * as Pattern from '../../../../../constants/pattern';
import {
  ButtonVariables,
  MIXER,
  FLOW_METER,
} from '../../../../../constants/variables';
import {
  clearDataUnitState,
  getDataUnits,
} from '../../../../../redux/actions/dataUnit.action';
import {
  addEditFlowMeasurementData,
  addFlowMeterType,
  clearFlowMeasurementState,
  getFlowMeterTypes,
} from '../../../../../redux/actions/flowMeasurement.action';
import {
  clearStreamState,
  getStreamData,
} from '../../../../../redux/actions/Stream.action';
import { GasTurbine } from '../ConfigDataConstants';
import './FlowMeasurement.scss';
import {
  columnsDataVariablesMap,
  data,
  formFields,
  tableDataVariables,
  initalFlowMeterData,
  flowMeterFormFields,
} from './FlowMeasurementConstants';
import { Flow_Meter } from '../ConfigDataConstants';
import {
  getManufacturer,
  clearState,
} from '../../../../../redux/actions/bypass.action';
import {
  addManufacturer,
  clearEquipmentState,
} from '../../../../../redux/actions/equipment.action';

class FlowMeasurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formEdit: false,
      isAddnewValue: false,
      addModalVisible: false,
      addNewValue: null,
      addModalType: null,
      variablesData: null,
      dirtyRows: [],
      meterTypes: [],
      meterLocation: '',
      flowMeterType: '',
      preserveMeterLocation: '',
      preserveFlowMeterType: '',
      editRow: {},
      dataUnits: {},
      updatedFields: {},
      flowMeasurementData: {},
      preserveflowMeasurementData: {},
      network: window.navigator.onLine,
      isFlowMeterAvailable: false,
      preserveIsFlowMeterAvailable: false,
      manufacturerDataList: [],
      flowMeterManufacturer: '',
      preserveFlowMeterManufacturer: '',
      flowMeterModel: '',
      flowMeterSerialNumber: '',
      flowMeterTagNumber: '',
      manufacturerName: [],
      addListener: false,
      venturiMeterTypeError: false,
      orificeMeterTypeError: false,
      vconeDiameterTypeError : false
    };
  }

  componentDidMount() {
    const { getFlowMeterTypes, getManufacturer } = this.props;
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState(
        {
          formEdit: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
    });
    this.getComponentData();
    if (this.handleNetwork()) {
      getFlowMeterTypes();
      getManufacturer();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isAddDataError,
      isAddDataSuccess,
      isSuccessGetFlowMeterType,
      isSuccessAddFlowMeterType,
      isErrorGetFlowMeterType,
      isErrorAddFlowMeterType,
      getFlowMeterTypeData,
      addFlowMeterTypeValue,
      errorCode,
    } = this.props.flowMeasurementState.apiState;
    const {
      isStreamDataSuccess,
      isAddStreamDataSuccess,
    } = this.props.streamState;
    const {
      clearFlowMeasurementState,
      isConfigData,
      clearConfigState,
      clearDataUnitState,
      isSave,
      clearStreamState,
      clearState,
      streamId,
      setIsSave,
      form,
      manufacturerAddStatus,
      manufacturerAddData,
      clearEquipmentState,
      getManufacturer,
    } = this.props;
    const {
      isErrorGetManufacturer,
      isSuccessGetManufacturer,
      manufacturerData,
    } = this.props.byPassData.apiState;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;
    const { addNewValue, isAddnewValue } = this.state;
    if (isConfigData) {
      clearConfigState();
      this.getComponentData();
    }
    if (
      isStreamDataSuccess ||
      isAddStreamDataSuccess ||
      prevProps.streamId !== streamId
    ) {
      clearStreamState();
      this.getComponentData();
      setIsSave();
      this.disableEditForm();
    }

    if (isSuccessDataUnitFetch) {
      clearDataUnitState();
      this.setDataUnits();
    }

    if (isSuccessGetFlowMeterType) {
      clearFlowMeasurementState();
      this.setState(
        {
          meterTypes: getFlowMeterTypeData ? getFlowMeterTypeData.items : [],
          typeName: this.getTypeName(getFlowMeterTypeData),
          addNewValue: null,
        },
        () =>
          form.setFieldsValue({
            name: addNewValue,
          })
      );
      !isAddnewValue && this.setDropDownValues();
    }

    if (isErrorGetFlowMeterType) {
      clearFlowMeasurementState();
      Notification.show(Types.Error, ERROR_MESSAGES[errorCode]);
    }

    if (isSuccessAddFlowMeterType) {
      clearFlowMeasurementState();

      form.setFieldsValue(
        {
          meterType: addFlowMeterTypeValue.type,
        },
        () => this.enableEditForm()
      );
      this.closeAddModal();
      Notification.show(
        Types.Success,
        FlowMeasurementMessages.METER_TYPE_ADDED
      );
      this.setState(
        {
          addNewValue: null,
          isAddnewValue: true,
          flowMeterType: addFlowMeterTypeValue.type,
        },
        () => {
          form.setFieldsValue({
            name: addNewValue,
          });
          this.getFlowMeterTypes();
        }
      );
    }

    if (isErrorAddFlowMeterType) {
      clearFlowMeasurementState();
      Notification.show(Types.Error, ERROR_MESSAGES[errorCode]);
    }

    if (isAddDataSuccess) {
      const {
        flowMeasurementData,
        flowMeterType,
        meterLocation,
        isFlowMeterAvailable,
        flowMeterManufacturer,
      } = this.state;
      clearFlowMeasurementState();
      Notification.show(Types.Success, FlowMeasurementMessages.ADDED);
      this.setState({
        preserveflowMeasurementData: flowMeasurementData,
        preserveFlowMeterType: flowMeterType,
        preserveMeterLocation: meterLocation,
        preserveIsFlowMeterAvailable: isFlowMeterAvailable,
        preserveFlowMeterManufacturer: flowMeterManufacturer,
        addListener: false,
        venturiMeterTypeError: false,
        orificeMeterTypeError: false,
        vconeDiameterTypeError : false,
      });
      this.disableEditForm();
    }

    if (isAddDataError) {
      clearFlowMeasurementState();
      Notification.show(Types.Error, ERROR_MESSAGES[errorCode]);
    }

    if (isSave && prevProps.isSave !== isSave) {
      this.save();
    }

    if (isSuccessGetManufacturer) {
      clearState();
      this.setState({
        manufacturerDataList: manufacturerData ? manufacturerData.items : [],
        manufacturerName: this.getManufacturerName(manufacturerData),
      });
      !isAddnewValue && this.setDropDownValues();
    }
    if (isErrorGetManufacturer) {
      clearState();
      Notification.show(Types.Error, ERROR_MESSAGES[errorCode]);
    }

    if (manufacturerAddStatus) {
      clearEquipmentState();

      form.setFieldsValue(
        {
          manufacturer: manufacturerAddData.name,
        },
        () => this.enableEditForm()
      );

      this.closeAddModal();
      Notification.show(
        Types.Success,
        FlowMeasurementMessages.MANUFACTURER_ADDED
      );

      this.setState(
        {
          addNewValue: null,
          isAddnewValue: true,
          flowMeterManufacturer: manufacturerAddData.name,
        },
        () => {
          form.setFieldsValue({
            name: addNewValue,
          });
          getManufacturer();
        }
      );
    }
  }

  setFlowMeterType = (initialValue, list) => {
    if (list && list.length > 0) {
      const value = list.find((item) => item._id === initialValue);
      return value ? value.type : true;
    }
  };

  setManufacturerType = (initialValue, list) => {
    if (list && list.length > 0) {
      const value = list.find((item) => item._id === initialValue);
      return value ? value.name : '';
    }
  };

  clearDirtyForm() {
    const { form } = this.props;
    const {
      preserveflowMeasurementData,
      preserveFlowMeterType,
      preserveMeterLocation,
      preserveIsFlowMeterAvailable,
      preserveFlowMeterManufacturer,
    } = this.state;
    this.setState({
      flowMeasurementData: _.cloneDeep(preserveflowMeasurementData),
      flowMeterType: preserveFlowMeterType,
      meterLocation: preserveMeterLocation,
      editRow: {},
      updatedFields: {},
      isFlowMeterAvailable: preserveIsFlowMeterAvailable,
      flowMeterManufacturer: preserveFlowMeterManufacturer,
      addListener: false,
      venturiMeterTypeError: false,
      orificeMeterTypeError: false,
    });
    form.setFieldsValue({
      meterType: preserveFlowMeterType,
      location: preserveMeterLocation,
      isFlowMeterAvailable: preserveIsFlowMeterAvailable,
      manufacturer: preserveFlowMeterManufacturer,
    });
  }

  getTypeName = (data) => data.items.map((item) => item.type);

  getComponentData() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const { streamData } = this.props.streamState;
    const { streamId, configData } = this.props;
    let initialData = {};
    let componentData = {};

    // component data as per the parent component
    if (name === MIXER) {
      if (streamData) {
        if (streamData[`stream${streamId}`]) {
          componentData = {
            ...streamData[`stream${streamId}`].flowMeasurementData,
          };
        }
      }
    } else {
      componentData = { ...configData.flowMeasurementData };
    }
    if (Object.keys(componentData).length) {
      if (name === FLOW_METER) {
        initialData = {
          variable: {
            thermalExpansionFactor: {
              unit: '',
              value: isFinite(componentData.thermalExpansionFactor)
                ? `${componentData.thermalExpansionFactor}`
                : '',
            },
            pipeInsideDiameter: {
              unit: componentData.pipeInsideDiameter.unit
                ? componentData.pipeInsideDiameter.unit
                : '',
              value: isFinite(componentData.pipeInsideDiameter.value)
                ? `${componentData.pipeInsideDiameter.value}`
                : '',
            },
            throatDiameter: {
              unit: componentData.throatDiameter.unit
                ? componentData.throatDiameter.unit
                : '',
              value: isFinite(componentData.throatDiameter.value)
                ? `${componentData.throatDiameter.value}`
                : '',
            },
            orificeDiameter: {
              unit: componentData.orificeDiameter.unit
                ? componentData.orificeDiameter.unit
                : '',
              value: isFinite(componentData.orificeDiameter.value)
                ? `${componentData.orificeDiameter.value}`
                : '',
            },
            vconeDiameter: {
              unit: componentData?.vconeDiameter?.unit
                ? componentData.vconeDiameter?.unit
                : '',
              value: isFinite(componentData.vconeDiameter?.value)
                ? `${componentData.vconeDiameter?.value}`
                : '',
            },
            betaRatio: {
              unit: '',
              value: isFinite(componentData.betaRatio)
                ? `${componentData.betaRatio}`
                : '',
            },
            dischargeCoefficient: {
              unit: '',
              value: isFinite(componentData.dischargeCoefficient)
                ? `${componentData.dischargeCoefficient}`
                : '',
            },
            reynoldsNumber: {
              unit: '',
              value: isFinite(componentData.reynoldsNumber)
                ? `${componentData.reynoldsNumber}`
                : '',
            },
            expansibilityFactor: {
              unit: '',
              value: isFinite(componentData.expansibilityFactor)
                ? `${componentData.expansibilityFactor}`
                : '',
            },
            frictionFactor: {
              unit: '',
              value: isFinite(componentData.frictionFactor)
                ? `${componentData.frictionFactor}`
                : '',
            },
            fluidDynamicViscosity: {
              unit: componentData.fluidDynamicViscosity.unit
                ? componentData.fluidDynamicViscosity.unit
                : '',
              value: isFinite(componentData.fluidDynamicViscosity.value)
                ? `${componentData.fluidDynamicViscosity.value}`
                : '',
            },
            fluidKinematicViscosity: {
              unit: componentData.fluidKinematicViscosity.unit
                ? componentData.fluidKinematicViscosity.unit
                : '',
              value: isFinite(componentData.fluidKinematicViscosity.value)
                ? `${componentData.fluidKinematicViscosity.value}`
                : '',
            },
            fluidDensity: {
              unit: componentData.fluidDensity.unit
                ? componentData.fluidDensity.unit
                : '',
              value: isFinite(componentData.fluidDensity.value)
                ? `${componentData.fluidDensity.value}`
                : '',
            },
          },
        };
      } else {
        initialData = {
          variable: {
            dischargeCoefficient: {
              unit: '',
              value: isFinite(componentData.dischargeCoefficient)
                ? `${componentData.dischargeCoefficient}`
                : '',
            },
            throatDiameter: {
              unit: componentData.throatDiameter.unit
                ? componentData.throatDiameter.unit
                : '',
              value: isFinite(componentData.throatDiameter.value)
                ? `${componentData.throatDiameter.value}`
                : '',
            },
            pipeInsideDiameter: {
              unit: componentData.pipeInsideDiameter.unit
                ? componentData.pipeInsideDiameter.unit
                : '',
              value: isFinite(componentData.pipeInsideDiameter.value)
                ? `${componentData.pipeInsideDiameter.value}`
                : '',
            },
            thermalExpansionFactor: {
              unit: '',
              value: isFinite(componentData.thermalExpansionFactor)
                ? `${componentData.thermalExpansionFactor}`
                : '',
            },
          },
        };
      }
    } else {
      initialData =
        name === FLOW_METER
          ? _.cloneDeep(initalFlowMeterData)
          : _.cloneDeep(data);
    }

    this.setState({
      flowMeasurementData: _.cloneDeep(initialData),
      preserveflowMeasurementData: _.cloneDeep(initialData),
    });

    this.setDataUnits();
    this.setDropDownValues();
  }

  setDataUnits = () => {
    const { unitData } = this.props.dataUnitState.apiState;
    if (unitData) {
      const units = {};
      const unitArray = unitData.items;
      const {
        throatDiameter,
        pipeInsideDiameter,
        orificeDiameter,
        fluidDensity,
        fluidDynamicViscosity,
        fluidKinematicViscosity,
      } = columnsDataVariablesMap;
      for (let i = 0; i < unitArray.length; i++) {
        if (
          unitArray[i].dataVariable === throatDiameter ||
          unitArray[i].dataVariable === pipeInsideDiameter ||
          unitArray[i].dataVariable === orificeDiameter ||
          unitArray[i].dataVariable === fluidDensity ||
          unitArray[i].dataVariable === fluidDynamicViscosity ||
          unitArray[i].dataVariable === fluidKinematicViscosity
        ) {
          units[unitArray[i].dataVariable] = unitArray[i].units;
        }
      }
      units["V-Cone Diameter"] = units["Orifice Diameter (do)"]
      this.setState({ dataUnits: units });
    }
  };

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  setDropDownValues = () => {
    let componentData = {};
    const { getFlowMeterTypeData } = this.props.flowMeasurementState.apiState;
    const { manufacturerData } = this.props.byPassData.apiState;
    const { name } = this.props.location.state.componentData.assetInfo;
    const { streamData } = this.props.streamState;
    const { manufacturerDataList } = this.state;
    const { streamId, configData, form } = this.props;
    // component data as per the parent component
    if (name === MIXER) {
      if (streamData) {
        if (streamData[`stream${streamId}`]) {
          componentData = {
            ...streamData[`stream${streamId}`].flowMeasurementData,
          };
        }
      }
    } else {
      componentData = { ...configData.flowMeasurementData };
    }
    if (name === Flow_Meter) {
      this.setState({
        flowMeterType: componentData.flowMeterType
          ? this.setFlowMeterType(
              componentData.flowMeterType,
              getFlowMeterTypeData.items
            )
          : '',
        flowMeterManufacturer: componentData.flowMeterManufacturer
          ? manufacturerData
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerData.items
              )
            : manufacturerDataList
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerDataList
              )
            : ''
          : '',
        preserveFlowMeterManufacturer: componentData.flowMeterManufacturer
          ? manufacturerData
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerData.items
              )
            : manufacturerDataList
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerDataList
              )
            : ''
          : '',
        flowMeterModel: componentData.flowMeterModel
          ? componentData.flowMeterModel
          : '',
        flowMeterSerialNumber: componentData.flowMeterSerialNumber
          ? componentData.flowMeterSerialNumber
          : '',
        flowMeterTagNumber: componentData.flowMeterTagNumber
          ? componentData.flowMeterTagNumber
          : '',
      });
      form.setFieldsValue({
        meterType: componentData.flowMeterType
          ? this.setFlowMeterType(
              componentData.flowMeterType,
              getFlowMeterTypeData.items
            )
          : '',
        manufacturer: componentData.flowMeterManufacturer
          ? manufacturerData
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerData.items
              )
            : manufacturerDataList
            ? this.setManufacturerType(
                componentData.flowMeterManufacturer,
                manufacturerDataList
              )
            : ''
          : '',
        model: componentData.flowMeterModel ? componentData.flowMeterModel : '',
        serialNumber: componentData.flowMeterSerialNumber
          ? componentData.flowMeterSerialNumber
          : '',
        tagNumber: componentData.flowMeterTagNumber
          ? componentData.flowMeterTagNumber
          : '',
      });
    } else {
      this.setState({
        flowMeterType: componentData.flowMeterType
          ? this.setFlowMeterType(
              componentData.flowMeterType,
              getFlowMeterTypeData.items
            )
          : '',
        meterLocation: componentData.meterLocation
          ? componentData.meterLocation
          : '',
        preserveFlowMeterType: componentData.flowMeterType
          ? this.setFlowMeterType(
              componentData.flowMeterType,
              getFlowMeterTypeData.items
            )
          : '',
        preserveMeterLocation: componentData.meterLocation
          ? componentData.meterLocation
          : '',
        isFlowMeterAvailable: componentData.isFlowMeterAvailable
          ? componentData.isFlowMeterAvailable
          : false,
        preserveIsFlowMeterAvailable: componentData.isFlowMeterAvailable
          ? componentData.isFlowMeterAvailable
          : false,
      });
      form.setFieldsValue({
        meterType: componentData.flowMeterType
          ? this.setFlowMeterType(
              componentData.flowMeterType,
              getFlowMeterTypeData.items
            )
          : '',
        location: componentData.meterLocation
          ? componentData.meterLocation
          : '',
        isFlowMeterAvailable: componentData.isFlowMeterAvailable
          ? componentData.isFlowMeterAvailable
          : false,
      });
    }
  };

  onValueChange(field, row, key, item) {
    const { flowMeasurementData, dirtyRows } = this.state;
    let tempDirtyRow = [...dirtyRows];
    const temp = { ...flowMeasurementData };
    const rowKey = Object.keys(temp[key])[row];

    if (
      item.variable === 'throatDiameter' ||
      item.variable === 'pipeInsideDiameter' ||
      item.variable === 'orificeDiameter' ||
      item.variable === 'vconeDiameter'
    ) {
      if (
        (field.name === 'unit' &&
          ((field.value && !item.value) || (!field.value && item.value))) ||
        (field.name === 'value' &&
          ((!field.value && item.unit) || (field.value && !item.unit)))
      ) {
        tempDirtyRow.push(row);
      } else if (item.unit && item.value) {
        const value = field.name === 'value' ? field.value : item.value;
        if (value.length) {
          if (validatePositiveFloat(value)) {
            tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
          } else {
            tempDirtyRow.push(row);
          }
        } else {
          tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
        }
      } else {
        tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
      }
    } else if (field.value.length) {
      if (validatePositiveFloat(field.value)) {
        tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
      } else {
        tempDirtyRow.push(row);
      }
    } else {
      tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
    }

    if (!temp[key]) {
      temp[key] = {};
    } else if (field.name === 'unit') {
      temp[key][rowKey][field.name] = field.value;
    } else {
      temp[key][rowKey][field.name] = field.value;
    }

    this.setState({
      flowMeasurementData: _.cloneDeep(temp),
      dirtyRows: tempDirtyRow,
    });
  }

  edit(row, key) {
    const { editRow, updatedFields, flowMeasurementData } = this.state;
    const temp = { ...editRow };
    const rowKey = Object.keys(flowMeasurementData[key])[row];
    const updatedTemp = { ...updatedFields };

    if (!temp[key]) {
      temp[key] = [];
    }
    temp[key].push(row);
    if (!updatedTemp[key]) {
      updatedTemp[key] = {};
    }
    updatedTemp[key][rowKey] = _.cloneDeep(flowMeasurementData[key][rowKey]);
    this.setState(
      {
        editRow: temp,
        updatedFields: _.cloneDeep(updatedTemp),
      },
      () => {
        this.enableEditForm();
      }
    );
  }

  undo(row, key) {
    const {
      editRow,
      updatedFields,
      flowMeasurementData,
      dirtyRows,
    } = this.state;
    let tempDirtyRow = [...dirtyRows];
    const temp = { ...editRow };
    const rowKey = Object.keys(flowMeasurementData[key])[row];
    const updatedTemp = _.cloneDeep(flowMeasurementData);
    tempDirtyRow = tempDirtyRow.filter((e) => e !== row);
    temp[key] = temp[key].filter((e) => e !== row);
    updatedTemp[key][rowKey] = { ...updatedFields[key][rowKey] };
    this.setState({
      editRow: temp,
      flowMeasurementData: _.cloneDeep(updatedTemp),
      dirtyRows: tempDirtyRow,
    });
  }

  /**
   *
   * @param {table data for render} data
   * @param {*} key
   */
  getDataArray(data, key) {
    const temp = [];
    if (data) {
      Object.keys(data).forEach((item) => {
        if (item !== '_id') {
          data[item][key] = item;
          temp.push(data[item]);
        }
      });
    }
    return _.cloneDeep(temp);
  }

  openAddModal = (key) => {
    this.setState({
      addModalType: key,
      addModalVisible: true,
    });
  };

  closeAddModal = () => {
    const { addNewValue } = this.state;
    const { form } = this.props;
    this.setState(
      {
        addModalVisible: false,
        addNewValue: null,
      },
      () =>
        form.setFieldsValue({
          name: addNewValue,
        })
    );
  };

  renderAddModal() {
    const { addModalVisible, addModalType } = this.state;
    let title = FlowMeasurementMessages.ADD_NEW_METER_TYPE;
    if (addModalType === 'manufacturer') {
      title = FlowMeasurementMessages.ADD_MANUFACTURER_TYPE;
    }
    const { form } = this.props;
    return (
      <div className="EditCurrentNode">
        <Modal
          title={title}
          visible={addModalVisible}
          onOk={this.saveAddModalValue}
          onCancel={this.closeAddModal}
          okText="Save"
          cancelText="Cancel"
        >
          <Form>
            <Form.Item>
              {addModalVisible &&
                form.getFieldDecorator('name', {
                  rules: [
                    { required: true, message: ERROR_MESSAGES.REQUIRED_FIELD },
                    {
                      pattern: Pattern.Name.regx,
                      message: Pattern.Name.message,
                    },
                  ],
                })(
                  <Input
                    onChange={(e) => {
                      this.setAddNewValue(e.target.value);
                    }}
                    maxLength={70}
                  />
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  dropDownOnChange = (value, field) => {
    if (field && field.key === 'meterType') {
      this.setState({
        flowMeterType: value,
      });
    } else if (field && field.key === 'isFlowMeterAvailable') {
      this.setState({ isFlowMeterAvailable: value });
    } else {
      this.setState({
        meterLocation: value,
      });
    }
    this.enableEditForm();
  };

  saveAddModalValue = () => {
    const { addNewValue, addModalVisible, addModalType } = this.state;
    const { addFlowMeterType, addManufacturer } = this.props;
    if (addModalVisible) {
      if (this.handleNetwork()) {
        if (addNewValue && addNewValue.trim() && addNewValue.trim().length) {
          if (addModalType === 'manufacturer') {
            addManufacturer({ name: addNewValue });
          } else {
            addFlowMeterType({ type: addNewValue });
          }
        } else {
          Notification.show(
            Types.Error,
            FlowMeasurementMessages.MENDATORY_FIELD
          );
        }
      }
    }
  };

  setAddNewValue(value) {
    this.setState({ addNewValue: value });
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  getFlowMeterTypes() {
    const { getFlowMeterTypes } = this.props;
    if (this.handleNetwork()) {
      getFlowMeterTypes();
    } else {
      Notification.show(Types.Error, NO_INTERNET);
    }
  }

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleOk() {
    const {
      preserveFlowMeterType,
      preserveMeterLocation,
      preserveIsFlowMeterAvailable,
    } = this.state;
    const { form } = this.props;
    form.setFieldsValue({
      meterType: preserveFlowMeterType,
      location: preserveMeterLocation,
      isFlowMeterAvailable: preserveIsFlowMeterAvailable,
    });
    this.setState(
      {
        visible: false,
      },
      () => {
        this.disableEditForm();
      }
    );
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  reset() {
    const { formEdit } = this.state;
    if (formEdit) {
      this.setState({
        visible: true,
        dirtyRows: [],
      });
    }
  }

  getFlowMeterType = (flowMeterType) => {
    const { meterTypes } = this.state;
    return meterTypes.find((item) => item.type === flowMeterType)._id;
  };

  getManufacturerName = (data) => data.items.map((item) => item.name);

  getManufacturerType = (manufacturer) => {
    const { manufacturerDataList } = this.state;
    return manufacturer
      ? manufacturerDataList.find((item) => item.name === manufacturer)._id
      : '';
  };

  validateFields = () => {
    const { variable: measurementData } = this.state.flowMeasurementData;
    const { name } = this.props.location.state.componentData.assetInfo;
    const { flowMeterType } = this.state;
    let tempDirtyRow = [];
    if (name === FLOW_METER) {
      Object.keys(measurementData).forEach((item) => {
        if (item === 'pipeInsideDiameter' && !measurementData[item].value) {
          tempDirtyRow.push(item);
        }
        if(item === "vconeDiameter" && flowMeterType === 'Vcone' && !measurementData[item].value){
          tempDirtyRow.push(item);
          this.setState({
          vconeDiameterTypeError : true
        })
        }
        if (
          item === 'throatDiameter' &&
          flowMeterType === 'Venturi' &&
          !measurementData[item].value
        ) {
          tempDirtyRow.push(item);
          this.setState({
            venturiMeterTypeError: true,
          });
        }
        if (
          item === 'orificeDiameter' &&
          flowMeterType === 'Orifice' &&
          !measurementData[item].value
        ) {
          tempDirtyRow.push(item);
          this.setState({
            orificeMeterTypeError: true,
          });
        }
      });
    }
    if (tempDirtyRow.length > 0) {
      return false;
    }
    return true;
  };

  save() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const {
      location: { state },
      form,
      setIsSave,
      setAddData,
    } = this.props;
    const { addEditFlowMeasurementData } = this.props;
    const {
      flowMeterType,
      meterLocation,
      isFlowMeterAvailable,
      dirtyRows,
    } = this.state;
    this.setState({
      isAddnewValue: false,
      addListener: true,
      orificeMeterTypeError: false,
      venturiMeterTypeError: false,
    });

    if (name === Flow_Meter) {
      form.setFieldsValue({
        manufacturer:
          form.getFieldValue('manufacturer') &&
          form.getFieldValue('manufacturer').trim(),
        model:
          form.getFieldValue('model') && form.getFieldValue('model').trim(),
        serialNumber:
          form.getFieldValue('serialNumber') &&
          form.getFieldValue('serialNumber').trim(),
        tagNumber:
          form.getFieldValue('tagNumber') &&
          form.getFieldValue('tagNumber').trim(),
      });
    }
    if (this.validateFields()) {
      form.validateFields((err, _values) => {
        if (!err) {
          if (dirtyRows.length === 0) {
            const {
              variable: measurementData,
            } = this.state.flowMeasurementData;
            const flowMeasurementData = {};
            Object.keys(measurementData).forEach((item) => {
              Object.assign(flowMeasurementData, {
                [item]:
                  item === 'throatDiameter' ||
                  item === 'pipeInsideDiameter' ||
                  item === 'orificeDiameter' ||
                  item === 'vconeDiameter' ||
                  item === 'fluidDynamicViscosity' ||
                  item === 'fluidKinematicViscosity' ||
                  item === 'fluidDensity'
                    ? {
                        unit: measurementData[item].unit,
                        value: parseFloat(measurementData[item].value),
                      }
                    : parseFloat(measurementData[item].value),
              });
            });
            Object.assign(flowMeasurementData, {
              flowMeterType: this.getFlowMeterType(flowMeterType),
            });
            if (meterLocation) {
              Object.assign(flowMeasurementData, {
                meterLocation,
              });
            }
            if (name === Flow_Meter) {
              Object.assign(flowMeasurementData, {
                flowMeterManufacturer: this.getManufacturerType(
                  _values.manufacturer
                ),
                flowMeterSerialNumber: _values.serialNumber,
                flowMeterModel: _values.model,
                flowMeterTagNumber: _values.tagNumber,
              });
            }
            let temp = { ...flowMeasurementData };
            Object.keys(temp).forEach((item) => {
              if (!temp[item] && temp[item] !== 0) {
                delete temp[item];
              }
              if (typeof temp[item] === 'object') {
                Object.keys(temp[item]).forEach((data) => {
                  if (!temp[item][data] && temp[item][data] !== 0) {
                    delete temp[item][data];
                  }
                });
              }
            });
            /** isFlowMeterAvailable in payload */
            if (name !== Flow_Meter) {
              temp = { ...temp, isFlowMeterAvailable };
            }
            if (name === MIXER) {
              setAddData(_.cloneDeep(temp));
            } else if (this.handleNetwork() && state.componentData) {
              if (name === GasTurbine) {
                delete temp.isFlowMeterAvailable;
                addEditFlowMeasurementData(
                  temp,
                  state.componentData.info._id,
                  name
                );
              } else {
                addEditFlowMeasurementData(
                  temp,
                  state.componentData.info._id,
                  name
                );
              }
            }
          } else {
            Notification.show(
              Types.Error,
              FlowMeasurementMessages.FIELDS_DIRTY
            );
            setIsSave();
          }
        } else {
          setIsSave();
          return true;
        }
      });
    } else {
      Notification.show(Types.Error, FlowMeasurementMessages.FIELDS_DIRTY);
    }
  }

  render() {
    const { isLoading } = this.props.flowMeasurementState;
    const { loading } = this.props.dataUnitState;
    const { loading: isDataLoading } = this.props.runnerDataState;
    const { name } = this.props.location.state.componentData.assetInfo;
    const {
      addModalVisible,
      typeName,
      manufacturerName,
      isFlowMeterAvailable,
      dataUnits,
      flowMeasurementData,
      editRow,
      formEdit,
      addListener,
      venturiMeterTypeError,
      orificeMeterTypeError,
      vconeDiameterTypeError
    } = this.state;
    const { isSchematicEditable, mixerEditable, streamId, form } = this.props;
    return (
      <div className="FlowMeasurement">
        {(isLoading || loading || isDataLoading) && <Loader />}
        {name !== Flow_Meter ? (
          <FormFields
            formFields={formFields({
              meterType: typeName,
              componentName: name,
              openAddModal: this.openAddModal,
              change: this.dropDownOnChange,
              addModalVisible,
              streamId: streamId ? streamId : 1,
              isFlowMeterAvailable: isFlowMeterAvailable,
              isConfigEditable: streamId ? mixerEditable : isSchematicEditable,
            })}
            form={form}
          />
        ) : (
          <FormFields
            formFields={flowMeterFormFields({
              meterType: typeName,
              isConfigEditable: streamId ? mixerEditable : isSchematicEditable,
              manufacturer: manufacturerName,
              change: this.dropDownOnChange,
              changeManufacturer: this.changeManufacturer,
              changeModel: this.changeModel,
              changeSerialNumber: this.changeSerialNumber,
              changeTagNumber: this.changeTagNumber,
              enable: this.enableEditForm,
              openAddModal: this.openAddModal,
              addListener: addListener,
            })}
            form={form}
          />
        )}
        {this.renderAddModal()}
        <CustomTable
          columns={tableDataVariables({
            edit: (row) => {
              this.edit(row, 'variable');
            },
            undo: (row) => {
              this.undo(row, 'variable');
            },
            change: (field, row, item) => {
              this.onValueChange(field, row, 'variable', item);
            },
            dataUnits: dataUnits,
            isConfigEditable: streamId ? mixerEditable : isSchematicEditable,
            addListener: addListener,
            venturiMeterTypeError: venturiMeterTypeError,
            orificeMeterTypeError: orificeMeterTypeError,
            vconeDiameterTypeError: vconeDiameterTypeError,
            component: name,
          })}
          data={this.getDataArray(flowMeasurementData.variable, 'variable')}
          editableRow={editRow.variable}
          showHeader
        />
        {formEdit && name !== MIXER && (
          <div className="config-bottom-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.reset();
              }}
            >
              {ButtonVariables.RESET}
            </button>
            <button
              className="btn-default"
              onClick={() => {
                this.save();
              }}
            >
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}
        {this.renderModal()}
      </div>
    );
  }
}

FlowMeasurement.propTypes = {
  isSave: PropType.bool,
  loading: PropType.bool,
  isConfigData: PropType.bool,
  isSchematicEditable: PropType.bool,
  streamId: PropType.string,
  setIsSave: PropType.func,
  getStreamData: PropType.func,
  addFlowMeterType: PropType.func,
  clearConfigState: PropType.func,
  clearStreamState: PropType.func,
  getFlowMeterTypes: PropType.func,
  clearFlowMeasurementState: PropType.func,
  addEditFlowMeasurementData: PropType.func,
  form: PropType.object,
  match: PropType.object,
  network: PropType.object,
  history: PropType.object,
  apiState: PropType.object,
  location: PropType.object,
  configData: PropType.object,
  configState: PropType.object,
  streamState: PropType.object,
  dataUnitState: PropType.object,
  flowMeasurementState: PropType.object,
  mixerEditable: PropType.bool,
  manufacturerAddStatus: PropType.bool,
  manufacturerAddData: PropType.object,
  addManufacturer: PropType.func,
  clearEquipmentState: PropType.func,
};

FlowMeasurement.defaultProps = {
  setIsSave: () => {},
  mixerEditable: true,
};
const mapStateToProps = (state) => ({
  network: state.NetworkReducer,
  streamState: state.streamReducer,
  flowMeasurementState: state.flowMeasurementReducer,
  runnerDataState: state.RunnerDataReducer,
  dataUnitState: state.DataUnitReducer,
  configState: state.ConfigReducer,
  byPassData: state.BypassReducer,
  manufacturerAddStatus:
    state.EquipmentReducer.apiState.isSuccessAddManufacturer,
  manufacturerAddData: state.EquipmentReducer.apiState.addManufacturerData,
});

const mapDispatchToProps = {
  getDataUnits,
  getStreamData,
  addFlowMeterType,
  clearStreamState,
  getFlowMeterTypes,
  clearDataUnitState,
  clearFlowMeasurementState,
  addEditFlowMeasurementData,
  getManufacturer,
  clearState,
  addManufacturer,
  clearEquipmentState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'flowMeasurement',
  })(withRouter(FlowMeasurement))
);
