import { Icon, Input, Switch } from 'antd';
import _ from 'lodash';
import React from 'react';
import { PIMComponentDesignDataVariables } from '../../../../../../constants/variables';
import { newValidateNumber } from '../../../../../../libs/validator';
const { TextArea } = Input;

export const dataUnitsOptions = (units) => {
  if (units) {
    return units.map((item) => <option value={item}>{item}</option>);
  }
  return null;
};

export const commonDesignDataTable = (config) => [
  {
    title: 'General',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (value === PIMComponentDesignDataVariables.application) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (item.name === PIMComponentDesignDataVariables.waterVolume) {
        const tempError = false;
        return (
          <div
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = !!(
        item.name === PIMComponentDesignDataVariables.application &&
        !item.design  &&
        config.saveListener
      );

      if (
        item.name === PIMComponentDesignDataVariables.waterVolume ||
        item.name === PIMComponentDesignDataVariables.numSegments
      ) {
        return (
          <div>
            <Input
              type="number"
              value={item.design}
              disabled={disabled}
              AutoComplete="off"
              onKeyDown={(e) => {
                newValidateNumber(e);
              }}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'number', -1)
              }
              className={'not-error'}
            />
          </div>
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.palmerCorrectionFactor
      ) {
        return (
          <div>
            <Switch
              style={{
                'margin-left': '5px',
                'margin-right': '140px',
                'margin-top': '8px',
              }}
              disabled={disabled}
              className="user-management-switch"
              checked={item.design ? item.design : false}
              onChange={(i) => {
                config.handleChange(i, item.name, 'boolean');
              }}
            />
          </div>
        );
      }
      return (
        <div
          className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
            tempError ? 'error' : 'not-error'
          }  `}
        >
          <select
            value={!item.design ? '' : item.design}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, 'select')
            }
            className={tempError ? 'error' : 'not-error'}
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => {
      return (
        <div className="more-items">
          {disabled ? (
            <button onClick={() => config.onEdit(row, 'PIMCommonDesignData')}>
              <Icon type="edit" />
            </button>
          ) : (
            <button onClick={() => config.onUndo(row, 'PIMCommonDesignData')}>
              <Icon type="undo" />
            </button>
          )}
        </div>
      );
    },
  },
];
export const sizingDataTable = (config) => [
  {
    title: 'Sizing data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === PIMComponentDesignDataVariables.inletDiameter ||
        value === PIMComponentDesignDataVariables.outletDiameter ||
        value === PIMComponentDesignDataVariables.pipeWallThickness ||
        value === PIMComponentDesignDataVariables.segmentLength ||
        value === PIMComponentDesignDataVariables.inletElevation ||
        value === PIMComponentDesignDataVariables.outletElevation ||
        value === PIMComponentDesignDataVariables.elevationChange ||
        value === PIMComponentDesignDataVariables.pipeMaterial ||
        value === PIMComponentDesignDataVariables.pipeMaterialType ||
        value === PIMComponentDesignDataVariables.coatingType
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      } else if (value === 'Add/Remove Segments') {
        return;
      }
      return value;
    },
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === PIMComponentDesignDataVariables.nominalDiameter ||
        item.name === PIMComponentDesignDataVariables.inletDiameter ||
        item.name === PIMComponentDesignDataVariables.outletDiameter ||
        item.name === PIMComponentDesignDataVariables.pipeWallThickness ||
        item.name === PIMComponentDesignDataVariables.segmentLength ||
        item.name === PIMComponentDesignDataVariables.inletElevation ||
        item.name === PIMComponentDesignDataVariables.outletElevation ||
        item.name === PIMComponentDesignDataVariables.elevationChange ||
        item.name ===
          PIMComponentDesignDataVariables.pipeWallThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.pipeRoughness ||
        item.name ===
          PIMComponentDesignDataVariables.thermalExpansionCoefficient ||
        item.name === PIMComponentDesignDataVariables.coatingRoughness ||
        item.name === PIMComponentDesignDataVariables.coatingThickness
      ) {
        const tempError = !!(
          !item.unit &&
          config.saveListener &&
          (item.name === PIMComponentDesignDataVariables.inletDiameter ||
            item.name === PIMComponentDesignDataVariables.outletDiameter ||
            item.name === PIMComponentDesignDataVariables.pipeWallThickness ||
            item.name === PIMComponentDesignDataVariables.segmentLength ||
            item.name === PIMComponentDesignDataVariables.inletElevation ||
            item.name === PIMComponentDesignDataVariables.outletElevation ||
            item.name === PIMComponentDesignDataVariables.elevationChange)
        );
        return (
          <div
            style={{ width: '194px' }}
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              style={{ width: '194px' }}
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (
        item.name === PIMComponentDesignDataVariables.nominalDiameter ||
        item.name === PIMComponentDesignDataVariables.pipeSchedule ||
        item.name === PIMComponentDesignDataVariables.inletDiameter ||
        item.name === PIMComponentDesignDataVariables.outletDiameter ||
        item.name === PIMComponentDesignDataVariables.pipeWallThickness ||
        item.name === PIMComponentDesignDataVariables.segmentLength ||
        item.name === PIMComponentDesignDataVariables.inletElevation ||
        item.name === PIMComponentDesignDataVariables.outletElevation ||
        item.name === PIMComponentDesignDataVariables.elevationChange ||
        item.name ===
          PIMComponentDesignDataVariables.pipeWallThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.pipeRoughness ||
        item.name ===
          PIMComponentDesignDataVariables.thermalExpansionCoefficient ||
        item.name === PIMComponentDesignDataVariables.coatingRoughness ||
        item.name === PIMComponentDesignDataVariables.coatingThickness
      ) {
        const designDataItems = item.design;

        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }

        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            const tempError = !!(
              !designDataItem &&
              config.saveListener &&
              (item.name === PIMComponentDesignDataVariables.inletDiameter ||
                item.name === PIMComponentDesignDataVariables.outletDiameter ||
                item.name ===
                  PIMComponentDesignDataVariables.pipeWallThickness ||
                item.name === PIMComponentDesignDataVariables.segmentLength ||
                item.name === PIMComponentDesignDataVariables.inletElevation ||
                item.name === PIMComponentDesignDataVariables.outletElevation ||
                item.name === PIMComponentDesignDataVariables.elevationChange)
            );
            return (
              <div style={{ width: '194px' }}>
                <Input
                  style={{ width: '185px' }}
                  type="number"
                  value={designDataItem}
                  disabled={disabled}
                  AutoComplete="off"
                  onKeyDown={(e) => {
                    newValidateNumber(e);
                  }}
                  onChange={(e) =>
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'numberArray',
                      index
                    )
                  }
                  className={tempError ? 'error' : 'not-error'}
                />
              </div>
            );
          })
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.hasInsideCoating
      ) {
        const designDataItems = value;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <Switch
                  style={{
                    'margin-left': '5px',
                    'margin-right': '140px',
                    'margin-top': '8px',
                  }}
                  disabled={disabled}
                  className="user-management-switch"
                  checked={designDataItem ? designDataItem : false}
                  onChange={(i) => {
                    config.handleChange(i, item.name, 'booleanArray', index);
                  }}
                />
              </div>
            );
          })
        );
      } else if (
        item.name ===
          PIMComponentDesignDataVariables.pipeWallThermalConductivityNote ||
        item.name === PIMComponentDesignDataVariables.pipeRoughnessNote ||
        item.name ===
          PIMComponentDesignDataVariables.thermalExpansionCoefficientNote ||
        item.name === PIMComponentDesignDataVariables.coatingRoughnessNote
      ) {
        const designDataItems = item.design;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }

        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  disabled={disabled}
                  value={designDataItem}
                  onChange={(e) => {
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'textArray',
                      index
                    );
                  }}
                  style={{ width: '185px' }}
                />
              </div>
            );
          })
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.pipeMaterial ||
        item.name === PIMComponentDesignDataVariables.pipeMaterialType
      ) {
        const materialEnvironmentData = config?.materialEnvironmentData || {};
        const dict = {
          [PIMComponentDesignDataVariables.pipeMaterial]:
            'pipeWallThermalConductivity',
          [PIMComponentDesignDataVariables.pipeMaterialType]: 'pipeRoughness',
        };

        if (!_.isEmpty(materialEnvironmentData)) {
          const designDataItems = item.design;
          if (config.numSegments > designDataItems.length) {
            for (let i = designDataItems.length; i < config.numSegments; i++) {
              designDataItems.push(designDataItems[designDataItems.length - 1]);
            }
          }
          if (config.numSegments < designDataItems.length) {
            designDataItems.splice(
              config.numSegments,
              designDataItems.length - config.numSegments
            );
          }

          const MEData = materialEnvironmentData[dict[item.name]].map(
            (m) => m.design
          );
          return (
            designDataItems &&
            designDataItems.map((designDataItem, index) => {
              const tempError = !!(!designDataItem && config.saveListener); //pipeMaterial and pipeMaterialType both are mandatory
              return (
                <div
                  className={`custom-table-select ${
                    disabled ? 'beforeEdit' : ''
                  } ${tempError ? 'error' : 'not-error'}  `}
                  style={{ width: '194px' }}
                >
                  <select
                    style={{ width: '194px' }}
                    value={designDataItem}
                    disabled={disabled}
                    onChange={(e) => {
                      // config.handleChangeMaterialEnvironment(
                      //   e.target.value,
                      //   item.name
                      // );

                      config.handleChange(
                        e.target.value,
                        item.name,
                        'selectArray',
                        index
                      );
                    }}
                    className={tempError ? 'error' : 'not-error'}
                  >
                    <option value="">Select</option>
                    {MEData.map((item) => (
                      <option value={item} title={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              );
            })
          );
        }
      } else if (item.name === 'Add/Remove Segments') {
        let designDataItems = createEmptyArray('', config.numSegments);
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div
                style={{ width: '194px', display: 'inline-flex' }}
                className={`custom-table-select ${
                  disabled ? 'beforeEdit' : ''
                }  'not-error'}  `}
              >
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.triggerDataCopy(index);
                  }}
                >
                  <Icon type="plus-circle" />
                </div>
                <div style={{ 'font-weight': 'bold' }}>Segment {index + 1}</div>
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.onRemove(index);
                  }}
                >
                  <Icon type="minus-circle" />
                </div>
              </div>
            );
          })
        );
      }
      const designDataItems = item.design;
      if (config.numSegments > designDataItems.length) {
        for (let i = designDataItems.length; i < config.numSegments; i++) {
          designDataItems.push(designDataItems[designDataItems.length - 1]);
        }
      }
      if (config.numSegments < designDataItems.length) {
        designDataItems.splice(
          config.numSegments,
          designDataItems.length - config.numSegments
        );
      }

      return (
        designDataItems &&
        designDataItems.map((designDataItem, index) => {
          const tempError = !!(
            !designDataItem &&
            config.saveListener &&
            item.name === PIMComponentDesignDataVariables.coatingType
          );
          return (
            <div
              style={{ width: '194px' }}
              className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
                tempError ? 'error' : 'not-error'
              }  `}
            >
              <select
                style={{ width: '194px' }}
                value={!designDataItem ? '' : designDataItem}
                disabled={disabled}
                onChange={(e) =>
                  config.handleChange(
                    e.target.value,
                    item.name,
                    'selectArray',
                    index
                  )
                }
                className={tempError ? 'error' : 'not-error'}
              >
                <option value="">Select</option>
                {data[item.name] &&
                  data[item.name].map((item) => (
                    <option value={item} title={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          );
        })
      );
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => {
      if (item.name !== 'Add/Remove Segments') {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => config.onEdit(row, 'PIMSizingData')}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => config.onUndo(row, 'PIMSizingData')}>
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      }
    },
  },
];
export const ambientTemperatureProfileDataTable = (config) => [
  {
    title: 'Ambient temperature profile data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (value === 'Add/Remove Segments') {
        return;
      } else return value;
    },
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name !== PIMComponentDesignDataVariables.ambientTemperatureNote &&
        item.name !== 'Add/Remove Segments'
      ) {
        const tempError = false; //!!(!item.unit && config.saveListener);
        return (
          <div style={{ width: '194px' }}
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select style={{ width: '194px' }}
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      const tempError = false; //no required variable

      if (
        item.name !== PIMComponentDesignDataVariables.ambientTemperatureNote &&
        item.name !== 'Add/Remove Segments'
      ) {
        const designDataItems = item.design;

        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }

        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <Input
                  style={{ width: '185px' }}
                  type="number"
                  value={designDataItem}
                  disabled={disabled}
                  AutoComplete="off"
                  onKeyDown={(e) => {
                    newValidateNumber(e);
                  }}
                  onChange={(e) =>
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'numberArray',
                      index
                    )
                  }
                  className={'not-error'}
                />
              </div>
            );
          })
        );
      } else if (item.name === 'Add/Remove Segments') {
        let designDataItems = createEmptyArray('', config.numSegments);
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div
                style={{ width: '194px', display: 'inline-flex' }}
                className={`custom-table-select ${
                  disabled ? 'beforeEdit' : ''
                } ${tempError ? 'error' : 'not-error'}  `}
              >
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.triggerDataCopy(index);
                  }}
                >
                  <Icon type="plus-circle" />
                </div>
                <div style={{ 'font-weight': 'bold' }}>Segment {index + 1}</div>
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.onRemove(index);
                  }}
                >
                  <Icon type="minus-circle" />
                </div>
              </div>
            );
          })
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.ambientTemperatureNote
      ) {
        const designDataItems = item.design;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }

        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  disabled={disabled}
                  value={designDataItem}
                  onChange={(e) => {
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'textArray',
                      index
                    );
                  }}
                  style={{ width: '185px' }}
                />
              </div>
            );
          })
        );
      }
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => {
      if (item.name !== 'Add/Remove Segments') {
        return (
          <div className="more-items">
            {disabled ? (
              <button
                onClick={() => config.onEdit(row, 'PIMAmbientTempProfileData')}
              >
                <Icon type="edit" />
              </button>
            ) : (
              <button
                onClick={() => config.onUndo(row, 'PIMAmbientTempProfileData')}
              >
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      }
    },
  },
];
export const heatTransferDataTable = (config) => [
  {
    title: 'Heat transfer data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      if (
        value === PIMComponentDesignDataVariables.insulationType ||
        value === PIMComponentDesignDataVariables.insulationThickness ||
        value === PIMComponentDesignDataVariables.ambientMedium ||
        value === PIMComponentDesignDataVariables.soilType ||
        value === PIMComponentDesignDataVariables.burialDepth ||
        value === PIMComponentDesignDataVariables.ambientPressure ||
        value === PIMComponentDesignDataVariables.ambientVelocity
      ) {
        return (
          <div>
            {value} <span className="mandatory">*</span>
          </div>
        );
      } else if (value === 'Add/Remove Segments') {
        return;
      }
      return value;
    },
  },
  {
    title: '',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (
        item.name ===
          PIMComponentDesignDataVariables.insulationThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.insulationThickness ||
        item.name === PIMComponentDesignDataVariables.soilThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.burialDepth ||
        item.name === PIMComponentDesignDataVariables.ambientPressure ||
        item.name === PIMComponentDesignDataVariables.ambientVelocity
      ) {
        const tempError = !!(
          !item.unit &&
          config.saveListener &&
          (item.name === PIMComponentDesignDataVariables.insulationThickness ||
            item.name === PIMComponentDesignDataVariables.burialDepth ||
            item.name === PIMComponentDesignDataVariables.ambientPressure ||
            item.name === PIMComponentDesignDataVariables.ambientVelocity)
        );
        return (
          <div style={{ width: '194px' }}
            className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
              tempError ? 'error' : 'not-error'
            }  `}
          >
            <select
              value={!item.unit ? '' : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, 'unit')
              }
            >
              <option value="">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: '',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (
        item.name ===
          PIMComponentDesignDataVariables.insulationThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.insulationThickness ||
        item.name === PIMComponentDesignDataVariables.soilThermalConductivity ||
        item.name === PIMComponentDesignDataVariables.burialDepth ||
        item.name === PIMComponentDesignDataVariables.ambientPressure ||
        item.name === PIMComponentDesignDataVariables.ambientVelocity
      ) {
        const designDataItems = item.design;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }
        return (
          
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            const tempError = !!(
              !designDataItem &&
              config.saveListener &&
              (
                item.name === PIMComponentDesignDataVariables.insulationThickness ||
                  item.name ===
                  PIMComponentDesignDataVariables.burialDepth ||
                  item.name === PIMComponentDesignDataVariables.ambientPressure ||
                item.name === PIMComponentDesignDataVariables.ambientVelocity
                  )
            );
            return (
              
              <div style={{ width: '194px' }}>
                <Input
                  style={{ width: '185px' }}
                  type="number"
                  value={designDataItem}
                  disabled={disabled}
                  AutoComplete="off"
                  onKeyDown={(e) => {
                    newValidateNumber(e);
                  }}
                  onChange={(e) =>
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'numberArray',
                      index
                    )
                  }
                  className={tempError?'error':'not-error'}
                />
              </div>
            );
          })
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.hasPipeWall ||
        item.name === PIMComponentDesignDataVariables.hasInsulation ||
        item.name === PIMComponentDesignDataVariables.hasOuterHTC
      ) {
        const designDataItems = value;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <Switch
                  style={{
                    'margin-left': '5px',
                    'margin-right': '140px',
                    'margin-top': '8px',
                  }}
                  disabled={disabled}
                  className="user-management-switch"
                  checked={designDataItem ? designDataItem : false}
                  onChange={(i) => {
                    config.handleChange(i, item.name, 'booleanArray', index);
                  }}
                />
              </div>
            );
          })
        );
      } else if (
        item.name ===
          PIMComponentDesignDataVariables.insulationThermalConductivityNote ||
        item.name ===
          PIMComponentDesignDataVariables.soilThermalConductivityNote ||
        item.name === PIMComponentDesignDataVariables.ambientVelocityNote
      ) {
        const designDataItems = item.design;
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div style={{ width: '194px' }}>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  disabled={disabled}
                  value={designDataItem}
                  onChange={(e) => {
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'textArray',
                      index
                    );
                  }}
                  style={{ width: '185px' }}
                />
              </div>
            );
          })
        );
      } else if (
        item.name === PIMComponentDesignDataVariables.insulationType ||
        item.name === PIMComponentDesignDataVariables.soilType
      ) {
        const materialEnvironmentData = config?.materialEnvironmentData || {};
        const dict = {
          [PIMComponentDesignDataVariables.insulationType]:
            'insulationThermalConductivity',
          [PIMComponentDesignDataVariables.soilType]: 'soilThermalConductivity',
        };

        if (!_.isEmpty(materialEnvironmentData)) {
          const designDataItems = item.design;
          if (config.numSegments > designDataItems.length) {
            for (let i = designDataItems.length; i < config.numSegments; i++) {
              designDataItems.push(designDataItems[designDataItems.length - 1]);
            }
          }
          if (config.numSegments < designDataItems.length) {
            designDataItems.splice(
              config.numSegments,
              designDataItems.length - config.numSegments
            );
          }

          const MEData = materialEnvironmentData[dict[item.name]].map(
            (m) => m.design
          );
            
          return (
            designDataItems &&
            designDataItems.map((designDataItem, index) => {
              const tempError = !!(
                !designDataItem &&
                config.saveListener &&
                (
                  item.name === PIMComponentDesignDataVariables.insulationType ||
                    item.name ===
                    PIMComponentDesignDataVariables.soilType 
                    )
              );
              return (
                <div
                  style={{ width: '194px' }}
                  className={`custom-table-select ${
                    disabled ? 'beforeEdit' : ''
                  } ${
                    tempError ? 'error' : 'not-error'
                  } `}
                >
                  <select
                    style={{ width: '185px' }}
                    value={designDataItem}
                    disabled={disabled}
                    onChange={(e) => {
                      config.handleChange(
                        e.target.value,
                        item.name,
                        'selectArray',
                        index
                      );
                    }}
                  >
                    <option value="">Select</option>
                    {MEData.map((item) => (
                      <option value={item} title={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              );
            })
          );
        }
      } else if (item.name === PIMComponentDesignDataVariables.ambientMedium) {
        const designDataItems =
          item.design || createEmptyArray('', config.numSegments);
        if (config.numSegments > designDataItems.length) {
          for (let i = designDataItems.length; i < config.numSegments; i++) {
            designDataItems.push(designDataItems[designDataItems.length - 1]);
          }
        }
        if (config.numSegments < designDataItems.length) {
          designDataItems.splice(
            config.numSegments,
            designDataItems.length - config.numSegments
          );
        }
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            const tempError = !!(
              !designDataItem &&
              config.saveListener
            );
            return (
              <div
                style={{ width: '194px' }}
                className={`custom-table-select ${
                  disabled ? 'beforeEdit' : ''
                } ${tempError ? 'error' : 'not-error'}  `}
              >
                <select
                  style={{ width: '194px' }}
                  value={!designDataItem ? '' : designDataItem}
                  disabled={disabled}
                  onChange={(e) =>
                    config.handleChange(
                      e.target.value,
                      item.name,
                      'selectArray',
                      index
                    )
                  }
                  className={tempError ? 'error' : 'not-error'}
                >
                  <option value="">Select</option>
                  {data[item.name] &&
                    data[item.name].map((item) => (
                      <option value={item} title={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
            );
          })
        );
      } else if (item.name === 'Add/Remove Segments') {
        let designDataItems = createEmptyArray('', config.numSegments);
        return (
          designDataItems &&
          designDataItems.map((designDataItem, index) => {
            return (
              <div
                style={{ width: '194px', display: 'inline-flex' }}
                className={`custom-table-select ${
                  disabled ? 'beforeEdit' : ''
                } ${'not-error'}  `}
              >
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.triggerDataCopy(index);
                  }}
                >
                  <Icon type="plus-circle" />
                </div>
                <div style={{ 'font-weight': 'bold' }}>Segment {index + 1}</div>
                <div
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    config.onRemove(index);
                  }}
                >
                  <Icon type="minus-circle" />
                </div>
              </div>
            );
          })
        );
      }
    },
  },
  config.isSchematicEditable && {
    title: '',
    dataIndex: 'actions',
    render: (value, row, col, disabled, item) => {
      if(item.name ===  PIMComponentDesignDataVariables.soilType ||
        item.name ===    PIMComponentDesignDataVariables.soilThermalConductivity ||
        item.name ===   PIMComponentDesignDataVariables.soilThermalConductivityNote ||
        item.name ===   PIMComponentDesignDataVariables.burialDepth ||
        item.name ===   PIMComponentDesignDataVariables.ambientPressure ||
        item.name ===   PIMComponentDesignDataVariables.ambientVelocity ||
        item.name ===   PIMComponentDesignDataVariables.ambientVelocityNote
        )
      {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => config.onEdit(row, 'PIMHeatTransferData')}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => config.onUndo(row, 'PIMHeatTransferData',item.name)}>
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      }
      else if (item.name !== 'Add/Remove Segments') {
        return (
          <div className="more-items">
            {disabled ? (
              <button onClick={() => config.onEdit(row, 'PIMHeatTransferData','')}>
                <Icon type="edit" />
              </button>
            ) : (
              <button onClick={() => config.onUndo(row, 'PIMHeatTransferData',item.name)}>
                <Icon type="undo" />
              </button>
            )}
          </div>
        );
      }
    },
  },
];

export const filterCommonDesignData = (displayData) => {
  return displayData.filter((item) => {
    if (
      item.name === PIMComponentDesignDataVariables.application ||
      item.name ===
        PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation ||
      item.name ===
        PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation ||
      item.name ===
        PIMComponentDesignDataVariables.heatTransferCorrelationFactor ||
      item.name === PIMComponentDesignDataVariables.waterVolume ||
      item.name === PIMComponentDesignDataVariables.palmerCorrectionFactor ||
      item.name === PIMComponentDesignDataVariables.numSegments
    ) {
      return item;
    }
    return undefined;
  });
};

export const createEmptyArray = (name, size) => {
  var arr = [];
  for (var i = 0; i < size; i++) {
    if (
      name === PIMComponentDesignDataVariables.hasInsideCoating ||
      name === PIMComponentDesignDataVariables.hasInsulation ||
      name === PIMComponentDesignDataVariables.hasOuterHTC ||
      name === PIMComponentDesignDataVariables.hasPipeWall
    )
      arr.push(false);
    else arr.push('');
  }
  return arr;
};

export const filterSizingData = (displayData) => {
  const hasInsideCoating = displayData
    .filter(
      (item) => item.name === PIMComponentDesignDataVariables.hasInsideCoating
    )
    .map((item) => {
      if (!_.isUndefined(item.design)) return item.design;
      return false;
    })[0];

  let tempArr = displayData.filter((item) => {
    if (
      item.name === PIMComponentDesignDataVariables.pipeSchedule ||
      item.name === PIMComponentDesignDataVariables.nominalDiameter ||
      item.name === PIMComponentDesignDataVariables.inletDiameter ||
      item.name === PIMComponentDesignDataVariables.outletDiameter ||
      item.name === PIMComponentDesignDataVariables.pipeWallThickness ||
      item.name === PIMComponentDesignDataVariables.segmentLength ||
      item.name === PIMComponentDesignDataVariables.inletElevation ||
      item.name === PIMComponentDesignDataVariables.outletElevation ||
      item.name === PIMComponentDesignDataVariables.elevationChange ||
      item.name === PIMComponentDesignDataVariables.pipeMaterial ||
      item.name === PIMComponentDesignDataVariables.pipeMaterialType ||
      item.name ===
        PIMComponentDesignDataVariables.pipeWallThermalConductivity ||
      item.name ===
        PIMComponentDesignDataVariables.pipeWallThermalConductivityNote ||
      item.name === PIMComponentDesignDataVariables.pipeRoughness ||
      item.name === PIMComponentDesignDataVariables.pipeRoughnessNote ||
      item.name ===
        PIMComponentDesignDataVariables.thermalExpansionCoefficient ||
      item.name ===
        PIMComponentDesignDataVariables.thermalExpansionCoefficientNote ||
      item.name === PIMComponentDesignDataVariables.hasInsideCoating ||
      (hasInsideCoating &&
        item.name === PIMComponentDesignDataVariables.coatingType) ||
      item.name === PIMComponentDesignDataVariables.coatingRoughness ||
      item.name === PIMComponentDesignDataVariables.coatingRoughnessNote ||
      item.name === PIMComponentDesignDataVariables.coatingThickness
    ) {
      return item;
    }
    return undefined;
  });
  tempArr.splice(0, 0, { name: 'Add/Remove Segments', design: [] });
  return tempArr;
};

export const filterAmbientTemperatureProfileData = (displayData) => {
  let tempArr = displayData.filter((item) => {
    if (
      item.name === PIMComponentDesignDataVariables.janAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.febAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.marAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.aprAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.mayAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.junAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.julAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.augAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.sepAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.octAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.novAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.decAmbientTemp ||
      item.name === PIMComponentDesignDataVariables.ambientTemperatureNote
    ) {
      return item;
    }
    return undefined;
  });
  tempArr.splice(0, 0, { name: 'Add/Remove Segments', design: [] });
  return tempArr;
};

export const filterHeatTransferData = (displayData) => {
  let includeOuterHTC, ambientMedium, hasInsulation;
  displayData.forEach((item) => {
    if (item.name === PIMComponentDesignDataVariables.hasInsulation) {
      if (!_.isUndefined(item.design))
        hasInsulation = item.design.includes(true);
      else hasInsulation = false;
    } else if (item.name === PIMComponentDesignDataVariables.hasOuterHTC) {
      if (!_.isUndefined(item.design)) includeOuterHTC = item.design;
      else includeOuterHTC = false;
    } else if (item.name === PIMComponentDesignDataVariables.ambientMedium) {
      ambientMedium = item.design;
    }
  });
  let tempArr = displayData.filter((item, index) => {
    if (
      item.name === PIMComponentDesignDataVariables.hasPipeWall ||
      item.name === PIMComponentDesignDataVariables.hasInsulation ||
      (hasInsulation &&
        item.name === PIMComponentDesignDataVariables.insulationType) ||
      item.name ===
        PIMComponentDesignDataVariables.insulationThermalConductivity ||
      item.name ===
        PIMComponentDesignDataVariables.insulationThermalConductivityNote ||
      (hasInsulation &&
        item.name === PIMComponentDesignDataVariables.insulationThickness) ||
      item.name === PIMComponentDesignDataVariables.hasOuterHTC ||
      (includeOuterHTC &&
        item.name === PIMComponentDesignDataVariables.ambientMedium) ||
      (ambientMedium.includes('Ground') &&
        item.name === PIMComponentDesignDataVariables.soilType) ||
      (ambientMedium.includes('Ground') &&
        item.name ===
          PIMComponentDesignDataVariables.soilThermalConductivity) ||
      (ambientMedium.includes('Ground') && item.name ===
        PIMComponentDesignDataVariables.soilThermalConductivityNote) ||
      (ambientMedium.includes('Ground') &&
        item.name === PIMComponentDesignDataVariables.burialDepth) ||
      item.name === PIMComponentDesignDataVariables.ambientMedium ||
      ((ambientMedium.includes('Air') || ambientMedium.includes('Water')) &&
        item.name === PIMComponentDesignDataVariables.ambientPressure) ||
      ((ambientMedium.includes('Air') || ambientMedium.includes('Water')) &&
        item.name === PIMComponentDesignDataVariables.ambientVelocity) ||
      ((ambientMedium.includes('Air') || ambientMedium.includes('Water')) &&
        item.name === PIMComponentDesignDataVariables.ambientVelocityNote)
    ) {
      return item;
    }
    return undefined;
  });
  tempArr.splice(0, 0, { name: 'Add/Remove Segments', design: [] });
  return tempArr;
};

export const blockDesignDataMap = (row, tablename) => {
  const mapper = {
    PIMCommonDesignData: [0, 1, 2, 3, 4, 5, 56],
    PIMSizingData: [
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
    ],
    PIMAmbientTempProfileData: [
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
    ],
    PIMHeatTransferData: [
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      42,
    ],
  };
  return mapper[tablename][row];
};
/* to populate dropdowns*/
export const data = {
  [PIMComponentDesignDataVariables.application]: ['Liquid', 'Gas'],
  [PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation]: [
    'Baxendell and Thomas',
    'Beggs and Brill',
    'Duns and Ros',
    'Gregory Aziz Mandhane',
    'Aziz , Govier and Fogarasi',
    'Hagedorn and Brown',
    'Poettmann and Carpenter',
    'Tacite Hydrodynamic Module',
  ],
  [PIMComponentDesignDataVariables.heatTransferCorrelationFactor]: [
    'Petukov',
    'Dittus and Boelter',
    'Sieder and Tate',
  ],
  [PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation]: [
    'Colebrook',
    'Drew, Koo and McAdams',
    'Blasius',
    'Nikuradse',
    'Zigrang Sylvester'
  ],
  [PIMComponentDesignDataVariables.coatingType]: [
    'Cement Mortar',
    'Coal Tar Enamel (CTE)',
    'Fusion Bonded Epoxy (FBE)',
    'Liquid Applied Epoxy',
    'Polyurethane (PU)',
    'Other',
  ],
  [PIMComponentDesignDataVariables.ambientMedium]: ['Air', 'Water', 'Ground'],
};

export const initialData = [
  {
    name: PIMComponentDesignDataVariables.application, //0
    design: parseFloat(''),
  },
  {
    name: PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation, //1
    design: parseFloat(''),
  },
  {
    name: PIMComponentDesignDataVariables.heatTransferCorrelationFactor, //2
    design: parseFloat(''),
  },
  {
    name: PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation, //3
    design: parseFloat(''),
  },
  {
    name: PIMComponentDesignDataVariables.palmerCorrectionFactor, //4
    design: parseFloat(''),
  },
  {
    name: PIMComponentDesignDataVariables.waterVolume, //5
    design: parseFloat(''),
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.pipeSchedule, //6
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.nominalDiameter, //7
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.inletDiameter, //8
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.outletDiameter, //9
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.pipeWallThickness, //10
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.segmentLength, //11
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.inletElevation, //12
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.outletElevation, //13
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.elevationChange, //14
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.pipeMaterial, //15
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.pipeWallThermalConductivity, //16
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.pipeWallThermalConductivityNote, //17
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.pipeMaterialType, //18
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.pipeRoughness, //19
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.pipeRoughnessNote, //20
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.thermalExpansionCoefficient, //21
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.thermalExpansionCoefficientNote, //22
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.hasInsideCoating, //23
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.coatingType, //24
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.coatingRoughness, //25
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.coatingRoughnessNote, //26
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.coatingThickness, //27
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.hasPipeWall, //28
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.hasInsulation, //29
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.insulationType, //30
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.insulationThermalConductivity, //31
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.insulationThermalConductivityNote, //32
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.insulationThickness, //33
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.hasOuterHTC, //34
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.ambientMedium, //35
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.soilType, //36
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.soilThermalConductivity, //37
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.soilThermalConductivityNote, //38
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.burialDepth, //39
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.ambientPressure, //40
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.ambientVelocity, //41
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.ambientVelocityNote, //42
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.janAmbientTemp, //43
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.febAmbientTemp, //44
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.marAmbientTemp, //45
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.aprAmbientTemp, //46
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.mayAmbientTemp, //47
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.junAmbientTemp, //48
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.julAmbientTemp, //49
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.augAmbientTemp, //50
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.sepAmbientTemp, //51
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.octAmbientTemp, //52
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.novAmbientTemp, //53
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.decAmbientTemp, //54
    design: [],
    unit: null,
  },
  {
    name: PIMComponentDesignDataVariables.ambientTemperatureNote, //54
    design: [],
  },
  {
    name: PIMComponentDesignDataVariables.numSegments, //54
    design: 1,
  },
  { name: 'Add/Remove Segments', design: [] },
];

export const initialDataNew = {
  [PIMComponentDesignDataVariables.application]: { design: '' }, //0
  [PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation]: {
    design: '',
  }, //1
  [PIMComponentDesignDataVariables.heatTransferCorrelationFactor]: {
    design: '',
  }, //2
  [PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation]: {
    design: '',
  }, //3
  [PIMComponentDesignDataVariables.palmerCorrectionFactor]: { design: '' }, //4
  [PIMComponentDesignDataVariables.waterVolume]: { design: '' }, //5
  sizingData: {
    [PIMComponentDesignDataVariables.pipeSchedule]: [
      { design: [], unit: null },
    ], //6
    [PIMComponentDesignDataVariables.nominalDiameter]: [
      { design: [], unit: null },
    ], //7
    [PIMComponentDesignDataVariables.inletDiameter]: [
      { design: [], unit: null },
    ], //8
    [PIMComponentDesignDataVariables.outletDiameter]: [
      { design: [], unit: null },
    ], //9
    [PIMComponentDesignDataVariables.pipeWallThickness]: [
      { design: [], unit: null },
    ], //10
    [PIMComponentDesignDataVariables.segmentLength]: [
      { design: [], unit: null },
    ], //11
    [PIMComponentDesignDataVariables.inletElevation]: [
      { design: [], unit: null },
    ], //12
    [PIMComponentDesignDataVariables.outletElevation]: [
      { design: [], unit: null },
    ], //13
    [PIMComponentDesignDataVariables.elevationChange]: [
      { design: [], unit: null },
    ], //14
    [PIMComponentDesignDataVariables.pipeMaterial]: [
      { design: [], unit: null },
    ], //15
    [PIMComponentDesignDataVariables.pipeWallThermalConductivity]: [
      { design: [], unit: null },
    ], //16
    [PIMComponentDesignDataVariables.pipeWallThermalConductivityNote]: [
      { design: [], unit: null },
    ], //17
    [PIMComponentDesignDataVariables.pipeMaterialType]: [
      { design: [], unit: null },
    ], //18
    [PIMComponentDesignDataVariables.pipeRoughness]: [
      { design: [], unit: null },
    ], //19
    [PIMComponentDesignDataVariables.pipeRoughnessNote]: [
      { design: [], unit: null },
    ], //20
    [PIMComponentDesignDataVariables.thermalExpansionCoefficient]: [
      { design: [], unit: null },
    ], //21
    [PIMComponentDesignDataVariables.thermalExpansionCoefficientNote]: [
      { design: [], unit: null },
    ], //22
    [PIMComponentDesignDataVariables.hasInsideCoating]: [
      { design: [], unit: null },
    ], //23
    [PIMComponentDesignDataVariables.coatingType]: [{ design: [], unit: null }], //24
    [PIMComponentDesignDataVariables.coatingRoughness]: [
      { design: [], unit: null },
    ], //25
    [PIMComponentDesignDataVariables.coatingRoughnessNote]: [
      { design: [], unit: null },
    ], //26
    [PIMComponentDesignDataVariables.coatingThickness]: [
      { design: [], unit: null },
    ], //27
  },
  heatTransferData: {
    [PIMComponentDesignDataVariables.hasPipeWall]: [{ design: [], unit: null }], //28
    [PIMComponentDesignDataVariables.hasInsulation]: [
      { design: [], unit: null },
    ], //29
    [PIMComponentDesignDataVariables.insulationType]: [
      { design: [], unit: null },
    ], //30
    [PIMComponentDesignDataVariables.insulationThermalConductivity]: [
      { design: [], unit: null },
    ], //31
    [PIMComponentDesignDataVariables.insulationThermalConductivityNote]: [
      { design: [] },
    ], //32
    [PIMComponentDesignDataVariables.insulationThickness]: [
      { design: [], unit: null },
    ], //33
    [PIMComponentDesignDataVariables.hasOuterHTC]: [{ design: [] }], //34
    [PIMComponentDesignDataVariables.ambientMedium]: [{ design: [] }], //35
    [PIMComponentDesignDataVariables.soilType]: [{ design: [] }], //36
    [PIMComponentDesignDataVariables.soilThermalConductivity]: [
      { design: [], unit: null },
    ], //37
    [PIMComponentDesignDataVariables.soilThermalConductivityNote]: [
      { design: [], unit: null },
    ], //38
    [PIMComponentDesignDataVariables.burialDepth]: [{ design: [], unit: null }], //39
    [PIMComponentDesignDataVariables.ambientPressure]: [
      { design: [], unit: null },
    ], //40
    [PIMComponentDesignDataVariables.ambientVelocity]: [
      { design: [], unit: null },
    ], //41
    [PIMComponentDesignDataVariables.ambientVelocityNote]: [
      { design: [], unit: null },
    ], //42
  },
  ambientTemperatureProfileData: {
    [PIMComponentDesignDataVariables.janAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //43
    [PIMComponentDesignDataVariables.febAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //44
    [PIMComponentDesignDataVariables.marAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //45
    [PIMComponentDesignDataVariables.aprAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //46
    [PIMComponentDesignDataVariables.mayAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //47
    [PIMComponentDesignDataVariables.junAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //48
    [PIMComponentDesignDataVariables.julAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //49
    [PIMComponentDesignDataVariables.augAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //50
    [PIMComponentDesignDataVariables.sepAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //51
    [PIMComponentDesignDataVariables.octAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //52
    [PIMComponentDesignDataVariables.novAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //53
    [PIMComponentDesignDataVariables.decAmbientTemp]: [
      { design: parseFloat(''), unit: null },
    ], //54
    [PIMComponentDesignDataVariables.ambientTemperatureNote]: [{ design: '' }], //55
  },
};

export const KeyChange = {
  [PIMComponentDesignDataVariables.application]: 'application',
  [PIMComponentDesignDataVariables.twoPhaseFrictionFactorCorrelation]:
    'twoPhaseFrictionFactorCorrelation',
  [PIMComponentDesignDataVariables.heatTransferCorrelationFactor]:
    'heatTransferCorrelationFactor',
  [PIMComponentDesignDataVariables.singlePhaseFrictionFactorCorrelation]:
    'singlePhaseFrictionFactorCorrelation',
  [PIMComponentDesignDataVariables.palmerCorrectionFactor]:
    'palmerCorrectionFactor',
  [PIMComponentDesignDataVariables.waterVolume]: 'waterVolume',
  [PIMComponentDesignDataVariables.pipeSchedule]: 'pipeSchedule',
  [PIMComponentDesignDataVariables.numSegments]: 'numSegments',
  [PIMComponentDesignDataVariables.nominalDiameter]: 'nominalDiameter',
  [PIMComponentDesignDataVariables.inletDiameter]: 'inletDiameter',
  [PIMComponentDesignDataVariables.outletDiameter]: 'outletDiameter',
  [PIMComponentDesignDataVariables.pipeWallThickness]: 'pipeWallThickness',
  [PIMComponentDesignDataVariables.segmentLength]: 'segmentLength',
  [PIMComponentDesignDataVariables.inletElevation]: 'inletElevation',
  [PIMComponentDesignDataVariables.outletElevation]: 'outletElevation',
  [PIMComponentDesignDataVariables.elevationChange]: 'elevationChange',
  [PIMComponentDesignDataVariables.pipeMaterial]: 'pipeMaterial',
  [PIMComponentDesignDataVariables.pipeWallThermalConductivity]:
    'pipeWallThermalConductivity',
  [PIMComponentDesignDataVariables.pipeWallThermalConductivityNote]:
    'pipeWallThermalConductivityNote',
  [PIMComponentDesignDataVariables.pipeMaterialType]: 'pipeMaterialType',
  [PIMComponentDesignDataVariables.pipeRoughness]: 'pipeRoughness',
  [PIMComponentDesignDataVariables.pipeRoughnessNote]: 'pipeRoughnessNote',
  [PIMComponentDesignDataVariables.thermalExpansionCoefficient]:
    'thermalExpansionCoefficient',
  [PIMComponentDesignDataVariables.thermalExpansionCoefficientNote]:
    'thermalExpansionCoefficientNote',
  [PIMComponentDesignDataVariables.hasInsideCoating]: 'hasInsideCoating',
  [PIMComponentDesignDataVariables.coatingType]: 'coatingType',
  [PIMComponentDesignDataVariables.coatingRoughness]: 'coatingRoughness',
  [PIMComponentDesignDataVariables.coatingRoughnessNote]:
    'coatingRoughnessNote',
  [PIMComponentDesignDataVariables.coatingThickness]: 'coatingThickness',
  [PIMComponentDesignDataVariables.hasPipeWall]: 'hasPipeWall',
  [PIMComponentDesignDataVariables.hasInsulation]: 'hasInsulation',
  [PIMComponentDesignDataVariables.insulationType]: 'insulationType',
  [PIMComponentDesignDataVariables.insulationThermalConductivity]:
    'insulationThermalConductivity',
  [PIMComponentDesignDataVariables.insulationThermalConductivityNote]:
    'insulationThermalConductivityNote',
  [PIMComponentDesignDataVariables.insulationThickness]: 'insulationThickness',
  [PIMComponentDesignDataVariables.hasOuterHTC]: 'hasOuterHTC',
  [PIMComponentDesignDataVariables.ambientMedium]: 'ambientMedium',
  [PIMComponentDesignDataVariables.soilType]: 'soilType',
  [PIMComponentDesignDataVariables.soilThermalConductivity]:
    'soilThermalConductivity',
  [PIMComponentDesignDataVariables.soilThermalConductivityNote]:
    'soilThermalConductivityNote',
  [PIMComponentDesignDataVariables.burialDepth]: 'burialDepth',
  [PIMComponentDesignDataVariables.ambientMedium]: 'ambientMedium',
  [PIMComponentDesignDataVariables.ambientPressure]: 'ambientPressure',
  [PIMComponentDesignDataVariables.ambientVelocity]: 'ambientVelocity',
  [PIMComponentDesignDataVariables.ambientVelocityNote]: 'ambientVelocityNote',
  [PIMComponentDesignDataVariables.janAmbientTemp]: 'janAmbientTemp',
  [PIMComponentDesignDataVariables.febAmbientTemp]: 'febAmbientTemp',
  [PIMComponentDesignDataVariables.marAmbientTemp]: 'marAmbientTemp',
  [PIMComponentDesignDataVariables.aprAmbientTemp]: 'aprAmbientTemp',
  [PIMComponentDesignDataVariables.mayAmbientTemp]: 'mayAmbientTemp',
  [PIMComponentDesignDataVariables.junAmbientTemp]: 'junAmbientTemp',
  [PIMComponentDesignDataVariables.julAmbientTemp]: 'julAmbientTemp',
  [PIMComponentDesignDataVariables.augAmbientTemp]: 'augAmbientTemp',
  [PIMComponentDesignDataVariables.sepAmbientTemp]: 'sepAmbientTemp',
  [PIMComponentDesignDataVariables.octAmbientTemp]: 'octAmbientTemp',
  [PIMComponentDesignDataVariables.novAmbientTemp]: 'novAmbientTemp',
  [PIMComponentDesignDataVariables.decAmbientTemp]: 'decAmbientTemp',
  [PIMComponentDesignDataVariables.ambientTemperatureNote]:
    'ambientTemperatureNote',
};
