import Type from '../Types/controlValve.types';

const controlValveConfigData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  componentId: '',
  designData: {
    purpose: '',
    type: '',
    inherentCharacter: '',
    actuator: '',
    equationConstantN1: '',
    equationConstantN2: '',
    equationConstantN3: '',
    configDP: '',
    nominalDiameter: '',
    inletDiameter: '',
    outletDiameter: '',
    fluidPhase: '',
    ratedValveFlowCoeff: '',
    liqDPrecoveryFactor: '',
    chokedDPratioFactor: '',
    velHeadLossCoeff: '',
    pipingGeoFactor: '',
    valveMaxStroke: '',
    valveMaxFlowrate: '',
    criticalPressure: '',
    valveDPToggle: '',
    valveOpenThreshold: '',
  },
  fluidComposition: {
    eos: '',
    composition: [],
    type: '',
  },
  curvesData: {},
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addFlowCoefficientCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addFlowrateCurve = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const INITIAL_STATE = {
  controlValveConfigData: { ...controlValveConfigData },
  addDesignData: { ...addDesignData },
  addFlowCoefficientCurve: { ...addFlowCoefficientCurve },
  addFlowrateCurve: { ...addFlowrateCurve },
};

export default function controlValveReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_VALVE_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        controlValveConfigData: {
          ...INITIAL_STATE.controlValveConfigData,
          isLoading: true,
        },
      };
    case Type.GET_VALVE_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        controlValveConfigData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,

          /** Design data */
          designData: action.payload.designData
            ? {
                purpose:
                  action.payload.designData.purpose ||
                  action.payload.designData.purpose === 0
                    ? action.payload.designData.purpose
                    : null,
                type:
                  action.payload.designData.type ||
                  action.payload.designData.type === 0
                    ? action.payload.designData.type
                    : null,
                inherentCharacter:
                  action.payload.designData.inherentCharacter ||
                  action.payload.designData.inherentCharacter === 0
                    ? action.payload.designData.inherentCharacter
                    : null,
                actuator:
                  action.payload.designData.actuator ||
                  action.payload.designData.actuator === 0
                    ? action.payload.designData.actuator
                    : null,
                equationConstantN1:
                  action.payload.designData.equationConstantN1 ||
                  action.payload.designData.equationConstantN1 === 0
                    ? action.payload.designData.equationConstantN1
                    : null,
                equationConstantN2:
                  action.payload.designData.equationConstantN2 ||
                  action.payload.designData.equationConstantN2 === 0
                    ? action.payload.designData.equationConstantN2
                    : null,
                equationConstantN3:
                  action.payload.designData.equationConstantN3 ||
                  action.payload.designData.equationConstantN3 === 0
                    ? action.payload.designData.equationConstantN3
                    : null,
                configDP:
                  action.payload.designData.configDP ||
                  action.payload.designData.configDP === 0
                    ? action.payload.designData.configDP
                    : null,
                nominalDiameter:
                  action.payload.designData.nominalDiameter ||
                  action.payload.designData.nominalDiameter === 0
                    ? action.payload.designData.nominalDiameter
                    : null,
                inletDiameter:
                  action.payload.designData.inletDiameter ||
                  action.payload.designData.inletDiameter === 0
                    ? action.payload.designData.inletDiameter
                    : null,
                outletDiameter:
                  action.payload.designData.outletDiameter ||
                  action.payload.designData.outletDiameter === 0
                    ? action.payload.designData.outletDiameter
                    : null,
                fluidPhase:
                  action.payload.designData.fluidPhase ||
                  action.payload.designData.fluidPhase === 0
                    ? action.payload.designData.fluidPhase
                    : null,
                ratedValveFlowCoeff:
                  action.payload.designData.ratedValveFlowCoeff ||
                  action.payload.designData.ratedValveFlowCoeff === 0
                    ? action.payload.designData.ratedValveFlowCoeff
                    : null,
                liqDPrecoveryFactor:
                  action.payload.designData.liqDPrecoveryFactor ||
                  action.payload.designData.liqDPrecoveryFactor === 0
                    ? action.payload.designData.liqDPrecoveryFactor
                    : null,
                chokedDPratioFactor:
                  action.payload.designData.chokedDPratioFactor ||
                  action.payload.designData.chokedDPratioFactor === 0
                    ? action.payload.designData.chokedDPratioFactor
                    : null,
                velHeadLossCoeff:
                  action.payload.designData.velHeadLossCoeff ||
                  action.payload.designData.velHeadLossCoeff === 0
                    ? action.payload.designData.velHeadLossCoeff
                    : null,
                pipingGeoFactor:
                  action.payload.designData.pipingGeoFactor ||
                  action.payload.designData.pipingGeoFactor === 0
                    ? action.payload.designData.pipingGeoFactor
                    : null,
                valveMaxStroke:
                  action.payload.designData.valveMaxStroke ||
                  action.payload.designData.valveMaxStroke === 0
                    ? action.payload.designData.valveMaxStroke
                    : null,
                valveMaxFlowrate:
                  action.payload.designData.valveMaxFlowrate ||
                  action.payload.designData.valveMaxFlowrate === 0
                    ? action.payload.designData.valveMaxFlowrate
                    : null,
                criticalPressure:
                  action.payload.designData.criticalPressure ||
                  action.payload.designData.criticalPressure === 0
                    ? action.payload.designData.criticalPressure
                    : null,
                valveDPToggle:
                  action.payload.designData.valveDPToggle ||
                  action.payload.designData.valveDPToggle === 0
                    ? action.payload.designData.valveDPToggle
                    : null,
                valveOpenThreshold:
                  action.payload.designData.valveOpenThreshold ||
                  action.payload.designData.valveOpenThreshold === 0
                    ? action.payload.designData.valveOpenThreshold
                    : null,
              }
            : {
                ...INITIAL_STATE.controlValveConfigData.designData,
              },

          fluidComposition: action.payload.fluidComposition
            ? action.payload.fluidComposition
            : { ...INITIAL_STATE.controlValveConfigData.fluidComposition },
          curvesData : action.payload.curvesData ? action.payload.curvesData : {...INITIAL_STATE.controlValveConfigData.curvesData}
        },
      };
    case Type.GET_VALVE_CONFIG_DATA_FAILURE:
      return {
        ...state,
        controlValveConfigData: {
          ...state.controlValveConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_VALVE_CONFIG_DATA_RESET:
      return {
        ...state,
        controlValveConfigData: {
          ...state.controlValveConfigData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          componentId: '',
        },
      };

    /** Add Control Valve design data */
    case Type.UPDATE_VALVE_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.UPDATE_VALVE_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        controlValveConfigData: {
          ...controlValveConfigData,
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          designData : action.payload ? action.payload.designData : {...INITIAL_STATE.controlValveConfigData.designData}
        },
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_VALVE_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_VALVE_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    case Type.UPDATE_VALVE_CURVES_DATA_PROGRESS:
      return {
        ...state,
        addFlowCoefficientCurve: {
          ...INITIAL_STATE.addFlowCoefficientCurve,
          isLoading: true,
        },
        addFlowrateCurve: {
          ...INITIAL_STATE.addFlowrateCurve,
          isLoading: true,
        },
      };
    case Type.UPDATE_VALVE_CURVES_DATA_SUCCESS:
      return {
        ...state,
        controlValveConfigData: {
          ...controlValveConfigData,
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          curvesData : action.payload ? action.payload : {...INITIAL_STATE.controlValveConfigData.curvesData}
        },
        addFlowCoefficientCurve: {
          ...state.addInletTempCurve,
          isLoading: false,
          isSuccess: true,
          data:  action.payload.curvesData?.flowCoefficientCurve,
        },
        addFlowrateCurve: {
          ...state.addInletLossesCurve,
          isLoading: false,
          isSuccess: true,
          data: action.payload.curvesData?.flowrateCurve,
        },
      };
    case Type.UPDATE_VALVE_CURVES_DATA_FAILURE:
      return {
        ...state,
        addFlowCoefficientCurve: {
          ...state.addInletTempCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
        addFlowrateCurve: {
          ...state.addInletLossesCurve,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_VALVE_CURVES_DATA_RESET:
      return {
        ...state,
        addFlowCoefficientCurve: {
          ...INITIAL_STATE.addFlowCoefficientCurve,
        },
        addFlowrateCurve: {
          ...INITIAL_STATE.addFlowrateCurve,
        },
      };

    /** Reset valve reducer */
    case Type.RESET_VALVE_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
