export const RecpComperssorDesignVariables = {
  application: 'Application',
  purpose: "Purpose",
  configSpeed: "Config Speed",
  maximumOperatingSpeed: "Maximum Operating Speed",
  minimumOperatingSpeed: "Minimum Operating Speed",
  numberOfStages: "Number of Stages",
  totalNumberOfCylinders: "Total Number of cylinders including all stages",
  startupSpeedThreshold: "Startup speed threshold (to determine running state)",
  startupPowerThreshold: "Startup power threshold (to determine running state)",
  mechanicalEfficiency: "Mechanical Efficiency",
  driver: "Driver",
  cylinderConstruction: "Cylinder Construction",
  cylinderConfiguration: "Cylinder Configuration",
  interstageEquipment: "Interstage Equipment",
  driverRatedPower: "Driver Rated Power",
};
export const CylinderDataVariables = {
  stage: 'Stage',
  cylinderNumber: "Cylinder number",
  cylinderBoreDiameter: "Cylinder Bore Diameter",
  stroke: "Stroke",
  rodDiameter: "Rod Diameter",
  tailRodDiameter: "Tail Rod Diameter",
  loadingmode1: "Loading mode #1",
  loadingmode2: "Loading mode #2",
  loadingmode3: "Loading mode #3",
  headEndDisplacement: "Head End Displacement",
  crankEndDisplacement: "Crank End Displacement",
  headEndFixedClearanceVolume1: "Head End Fixed Clearance Volume",
  headEndFixedClearanceVolume2: "Head End Fixed Clearance Volume ( percentage )",
  crankEndFixedClearanceVolume1: "Crank End Fixed Clearance Volume",
  crankEndFixedClearanceVolume2: "Crank End Fixed Clearance Volume ( percentage )",
  headEndClearancePocketMode: "Head End Clearance Pocket Mode",
  crankEndClearancePocketMode: "Crank End Clearance Pocket Mode",
  headEndVariableClearancePocketVolume1: "Head End Variable Clearance Pocket Volume",
  headEndVariableClearancePocketVolume2: "Head End Variable Clearance Pocket Volume ( percentage )",
  crankEndVariableClearancePocketVolume1: "Crank End Variable Clearance Pocket Volume",
  crankEndVariableClearancePocketVolume2: "Crank End Variable Clearance Pocket Volume ( percentage )",
  fixedHeadEndInletLosses: "Fixed Head End Inlet Losses",
  fixedHeadEndDischargeLosses: "Fixed Head End Discharge Losses",
  fixedCrankEndInletLosses: "Fixed Crank End Inlet Losses",
  fixedCrankEndDischargeLosses: "Fixed Crank End Discharge Losses",
  ratedInletPressure: "Rated Inlet Pressure",
  ratedDischargePressure: "Rated Discharge Pressure",
  ratedPistonSpeed: "Rated Piston Speed",
  ratedGasLoadCompression: "Rated Gas Load - Compression",
  ratedGasLoadTension: "Rated Gas Load - Tension"
};
export const ValveDataVariable = {
  calculateValveLosses: 'Calculate Valve Losses',
  headEndSuctionValveQuantity: "Head End Suction Valve Quantity",
  headEndDischargeValveQuantity: "Head End Discharge Valve Quantity",
  crankEndSuctionValveQuantity: "Crank End Suction Valve Quantity",
  crankEndDischargeValveQuantity: "Crank End Discharge Valve Quantity",
  headEndSuctionValveBoreDiameter: "Head End Suction Valve Bore Diameter",
  headEndDischargeValveBoreDiameter: "Head End Discharge Valve Bore Diameter",
  crankEndSuctionValveBoreDiamater: "Crank End Suction Valve Bore Diamater",
  crankEndDischargeValveBoreDiameter: "Crank End Discharge Valve Bore Diameter",
  headEndSuctionValveResistanceFactor: "Head End Suction Valve Resistance Factor",
  headEndDischargeValveResistanceFactor: "Head End Discharge Valve Resistance Factor",
  crankEndSuctionValveResistanceFactor: "Crank End Suction Valve Resistance Factor",
  crankEndDischargeValveResistanceFactor: "Crank End Discharge Valve Resistance Factor",
  headEndSuctionValveLosses: "Head End Suction Valve Losses",
  headEndDischargeValveLosses: "Head End Discharge Valve Losses",
  crankEndSuctionValveLosses: "Crank End Suction Valve Losses",
  crankEndDischargeValveLosses: "Crank End Discharge Valve Losses"
};
export const CapacityControlVariables = {
  by:"By",
  for: "For", 
  with: "With",
  using: "Using",
  action: "Action",
  numberOfSteps: "Number Of Steps"
};
export const inputSections =  {...RecpComperssorDesignVariables, ...CylinderDataVariables, ...ValveDataVariable, ...CapacityControlVariables}
export const payloadMapper = [RecpComperssorDesignVariables, CylinderDataVariables, ValveDataVariable, CapacityControlVariables]
export const screenConstant = [
  {variableName: RecpComperssorDesignVariables, heading: "Equipment Data", stateName: "equipmentData"},
  {variableName: CylinderDataVariables, heading: "Cylinder Data", stateName: "cylinderData"},
  {variableName: ValveDataVariable, heading: "Valve Data", stateName: "valveData"},
  {variableName: CapacityControlVariables, heading: "Capacity Control Details", stateName: "capacityControlData"}
]
const filedsWithUnit = [
  "configSpeed", "maximumOperatingSpeed", "minimumOperatingSpeed", "startupSpeedThreshold", "startupPowerThreshold",
  "mechanicalEfficiency","driverRatedPower", "cylinderBoreDiameter", "stroke", "rodDiameter", "tailRodDiameter", "headEndDisplacement", "crankEndDisplacement", 
  "headEndFixedClearanceVolume1", "headEndFixedClearanceVolume2", "crankEndFixedClearanceVolume1", "crankEndFixedClearanceVolume2",
  "headEndVariableClearancePocketVolume1", "headEndVariableClearancePocketVolume2", "crankEndVariableClearancePocketVolume1", 
  "crankEndVariableClearancePocketVolume2", "fixedHeadEndInletLosses", "fixedHeadEndDischargeLosses", "fixedCrankEndInletLosses"
  ,"fixedCrankEndDischargeLosses", "ratedInletPressure", "ratedDischargePressure", "ratedPistonSpeed", "ratedGasLoadCompression",
  "ratedGasLoadTension", "calculateValveLosses", "headEndSuctionValveQuantity", "headEndDischargeValveQuantity", "crankEndSuctionValveQuantity",
  "crankEndDischargeValveQuantity", "headEndSuctionValveBoreDiameter", "headEndDischargeValveBoreDiameter", "crankEndSuctionValveBoreDiamater", "crankEndDischargeValveBoreDiameter",
  "headEndSuctionValveResistanceFactor", "headEndDischargeValveResistanceFactor", "crankEndSuctionValveResistanceFactor", "crankEndDischargeValveResistanceFactor",
  "headEndSuctionValveLosses", "headEndDischargeValveLosses", "crankEndSuctionValveLosses", "crankEndDischargeValveLosses"
]
const fieldsWithoutUnit = [
  "application", "purpose", "numberOfStages", "totalNumberOfCylinders", "driver", "cylinderConstruction","cylinderConfiguration", 
  "interstageEquipment", "stage", "cylinderNumber", "loadingmode1", "loadingmode2","loadingmode3", 
  "headEndClearancePocketMode", "crankEndClearancePocketMode", "by", "for", "with", "using", "action", "numberOfSteps"
]

export const FieldMapper = async(Mapper) => {
  const mappedFields = []
  const keys = Object.keys(Mapper)
  for await (let data of keys){
    if(filedsWithUnit.indexOf(data)>=0){
      const fieldObject ={ name: Mapper[data], design: null, unit: null }
      mappedFields.push(fieldObject)
    }
    else if(fieldsWithoutUnit.indexOf(data)>=0){
      const fieldObject =
      { name: Mapper[data], design:null }
      mappedFields.push(fieldObject)
    }
  }
  return mappedFields
}


export const designFieldConstant = {
  [RecpComperssorDesignVariables.application]: [
    { title: 'Fixed Speed', value: 'fixedSpeed' },
    { title: 'Variable Speed', value: 'varaibleSpeed' },
  ],
  [RecpComperssorDesignVariables.purpose]: [
    { title: 'Boil Off Gas Compression', value: 'boilOffGasCompression' }
  ],
  [RecpComperssorDesignVariables.driver]: [
    { title: 'Synchronous Motor', value: 'fixedSpeed' },
    { title: 'Induction Motor', value: 'inductionMotor' },
    { title: 'Steam Turbine', value: 'steamTurbine' },
    { title: 'Gas Turbine', value: 'gasTurbine' },
    { title: 'IC Engine', value: 'ICEngine' },
  ],
  [RecpComperssorDesignVariables.cylinderConfiguration]: [
    { title: 'Vertical', value: 'vertical' },
    { title: 'Horizontal', value: 'horizontal' },
  ],
  [RecpComperssorDesignVariables.cylinderConstruction]: [
    { title: 'Non-Lubricated', value: 'NonLubricated' },
    { title: 'Lubricated', value: 'Lubricated' },
  ],
  [RecpComperssorDesignVariables.interstageEquipment]: [
    { title: 'Coolers and Pulsation Devices', value: 'CoolersandPulsationDevices' },
  ],
  [CylinderDataVariables.loadingmode1]: [
    { title: 'Not-Applicable', value: 'notApplicable' },
    { title: 'Double Acting (DA)', value: 'DA' },
    { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
    { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
  ],
  [CylinderDataVariables.loadingmode2]: [
    { title: 'Not-Applicable', value: 'notApplicable' },
    { title: 'Double Acting (DA)', value: 'DA' },
    { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
    { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
  ],
  [CylinderDataVariables.loadingmode3]: [
    { title: 'Not-Applicable', value: 'notApplicable' },
    { title: 'Double Acting (DA)', value: 'DA' },
    { title: 'Single Acting Crank End (SACE)', value: 'SACE' },
    { title: 'Single Acting Head End (SAHE)', value: 'SAHE' },
  ],
  [CylinderDataVariables.headEndClearancePocketMode]: [
    { title: 'Fixed', value: 'Fixed' },
    { title: 'Variable', value: 'Variable' },
  ],
  [CylinderDataVariables.crankEndClearancePocketMode]: [
    { title: 'Fixed', value: 'Fixed' },
    { title: 'Variable', value: 'Variable' },
  ],
  [ValveDataVariable.calculateValveLosses]: [
    { title: 'True', value: true },
    { title: 'False', value: false },
  ],
  [CapacityControlVariables.by]: [
    { title: "Manufacturer's Capacity Control", value: 'ManufacturerCapacityControl' },
    { title: "Purchaser's Bypass Control", value: 'PurchasersBypassControl' },
    { title: 'Both', value: 'Both' },
  ],
  [CapacityControlVariables.for]: [
    { title: 'Startup Only', value: 'StartupOnly' },
    { title: 'Part-Load Conditions', value: 'PartLoadConditions' },
    { title: 'Both', value: 'Both' },
  ],
  [CapacityControlVariables.with]: [
    { title: 'Auto Loading Delay Interlock', value: 'AutoLoadingDelayInterlock' },
    { title: 'Auto Immediate Unloading', value: 'AutoImmediateUnloading' },
  ],
  [CapacityControlVariables.using]: [
    { title: 'Fixed Volume Pockets', value: 'FixedVolumePockets' },
    { title: 'Suction Valve Unloaders', value: 'SuctionValveUnloaders' },
    { title: 'Finger', value: 'Finger' },
    { title: 'Plug', value: 'Plug' },
  ],
  [CapacityControlVariables.action]: [
    { title: 'Direct (Air-To-Unload)', value: 'Direct(Air-To-Unload)' },
    { title: 'Reversed (Air-To-Load/Fail Safe)', value: 'Reversed (Air-To-Load/Fail Safe)' },
  ]
}