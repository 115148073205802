import {
  FLUID_COMPOSITION_CLEAR_STATE,
  FLUID_COMPOSITION_FETCH_ERROR,
  FLUID_COMPOSITION_FETCH_PROGRESS,
  FLUID_LIST_FETCH_SUCCESS,
  ADD_FLUID_FETCH_SUCCESS,
  ADD_FLUID_FETCH_PROGRESS,
  ADD_FLUID_FETCH_ERROR,
} from "../Types/fluidComposition.types";

const INITIAL_API_STATE = {
  fluidList: null,
  fluidData: null,
  isError: false,
  isFluidListSuccess: false,
  isFluidAddSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
};

export default function FluidCompositionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FLUID_COMPOSITION_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case FLUID_COMPOSITION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    case FLUID_COMPOSITION_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          fluidList: state.apiState.fluidList,
          fluidData: state.apiState.fluidData,
        },
      };

    case FLUID_LIST_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          fluidList: action.payload,
          isFluidListSuccess: true,
        },
      };
    case ADD_FLUID_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          fluidData: action.payload,
          isFluidAddSuccess: true,
        },
      };

    case ADD_FLUID_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case ADD_FLUID_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          message:
            action.payload.response && action.payload.response.body.message,
        },
      };

    default:
      return state;
  }
}
