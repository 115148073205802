import React from 'react';
import RenderComment from './components/RenderComment';
import RenderDescription from './components/RenderDescription';
import RenderHeader from './components/RenderHeader';
import RenderPlots from './components/RenderPlot';

const AlertSummary = (props, stateData) => {
    const { data } = props
    return (
        <div className='AlerSummary'>
            {RenderHeader("Alert Details")}
            {RenderDescription(data)}
            {RenderPlots(data)}
            {RenderComment(props,stateData)}
        </div>
    )
}

export default AlertSummary