import React from "react";
import { Input, Icon } from "antd";

export const columnsProperties = (config) => [
  {
    title: config.type,
    dataIndex: "type",
    key: "type",
    render: (value, row, col, disabled, item) =>
      disabled ? (
        value
      ) : (
        <Input
          value={value}
          disabled={disabled}
          onChange={(e) => config.change(e.target, row)}
          className={!value.trim() ? "error" : "non-error"}
        />
      ),
  },
  {
    title: "Actions",
    dataIndex: "Actions",
    render: (value, row, col, disabled, item) =>
      disabled ? (
        <Icon
          type="edit"
          style={{ fontSize: 16 }}
          onClick={(e) => config.edit(row)}
        />
      ) : (
        <Icon
          type="undo"
          style={{ fontSize: 16 }}
          onClick={(e) => config.undo(row)}
        />
      ),
  },
];

export const datas = [
  "Drive Type",
  "Equipment Type",
  "Flow Meter Type",
  "Manufacturer",
];
