import * as Types from "../Types/reciprocatingCompressorTypes"

const INITIAL_STATE = {
    configData: {
        isLoading: false,
        isError: false,
        errorCode: "",
        apiMsg: "",
        isSuccess: false,
        componentId: "",
        designData: {
        },
        fluidComposition: {
        },
        curvesData: {},
    },
    updateDesignData: {
        isLoading: false,
        isError: false,
        errorCode: "",
        apiMsg: "",
        isSuccess: false,
        data: {},
    },
};

export default function reciprocatingCompressorReducer(
    state = { ...INITIAL_STATE },
    action
) {
    switch (action.type) {
        //Get recip. compressor config
        case Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_PROGRESS:
            return {
                ...state,
                configData: {
                    ...INITIAL_STATE.configData,
                    isLoading: true
                }
            }
        case Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_SUCCESS:
            return {
                ...state,
                configData: {
                    ...INITIAL_STATE.configData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    componentId: action.payload.componentId,
                    designData: action.payload.designData,
                    fluidComposition: action.payload.fluidComposition
                }
            }
        case Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_FAILURE:
            return {
                ...state,
                configData: {
                    ...INITIAL_STATE.configData,
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    errorCode:
                        action.payload.response && action.payload.response.body.code,
                    apiMsg:
                        action.payload.response && action.payload.response.body.message,
                }
            }
        case Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_PROGRESS:
            return {
                ...state,
                updateDesignData: {
                    ...INITIAL_STATE.updateDesignData,
                    isLoading: true,
                },
            }
        case Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_SUCCESS:
            return {
                ...state,
                updateDesignData: {
                    ...INITIAL_STATE.updateDesignData,
                    isLoading: false,
                    isError: false,
                    errorCode: "",
                    apiMsg: "",
                    isSuccess: true,
                    data: action.payload,
                },
            }
        case Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_FAILURE:
            return {
                ...state,
                updateDesignData: {
                    ...INITIAL_STATE.updateDesignData,
                    isLoading: false,
                    isError: true,
                    errorCode: action.payload,
                    apiMsg: action.payload,
                    isSuccess: true,
                },
            }
        case Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_RESET:
            return {
                ...state,
                updateDesignData: {
                    ...state.updateDesignData,
                    isSuccess: false,
                    isLoading:false,
                    isError:false
                },
            }
        default:
            return {
                ...state,
            };
    }
}

