export const SYSTEM_FETCH_SUCCESS = "SYSTEM_FETCH_SUCCESS";
export const SYSTEM_FETCH_ERROR = "SYSTEM_FETCH_ERROR";
export const SYSTEM_FETCH_PROGRESS = "SYSTEM_FETCH_PROGRESS";
export const SYSTEM_CLEAR_STATE = "SYSTEM_CLEAR_STATE";

export const ADD_SYSTEM_FETCH_SUCCESS = "ADD_SYSTEM_FETCH_SUCCESS";
export const ADD_SYSTEM_FETCH_PROGRESS = "ADD_SYSTEM_FETCH_PROGRESS";
export const ADD_SYSTEM_FETCH_ERROR = "ADD_SYSTEM_FETCH_ERROR";

export const EDIT_SYSTEM_FETCH_SUCCESS = "EDIT_SYSTEM_FETCH_SUCCESS";
export const EDIT_SYSTEM_FETCH_PROGRESS = "EDIT_SYSTEM_FETCH_PROGRESS";
export const EDIT_SYSTEM_FETCH_ERROR = "EDIT_SYSTEM_FETCH_ERROR";
