export default {
  GET_HVSG_CONFIG_DATA_PROGRESS: 'GET_HVSG_CONFIG_DATA_PROGRESS',
  GET_HVSG_CONFIG_DATA_SUCCESS: 'GET_HVSG_CONFIG_DATA_SUCCESS',
  GET_HVSG_CONFIG_DATA_FAILURE: 'GET_HVSG_CONFIG_DATA_FAILURE',
  GET_HVSG_CONFIG_DATA_RESET: 'GET_HVSG_CONFIG_DATA_RESET',

  UPDATE_HVSG_DESIGN_DATA_PROGRESS: 'UPDATE_HVSG_DESIGN_DATA_PROGRESS',
  UPDATE_HVSG_DESIGN_DATA_SUCCESS: 'UPDATE_HVSG_DESIGN_DATA_SUCCESS',
  UPDATE_HVSG_DESIGN_DATA_FAILURE: 'UPDATE_HVSG_DESIGN_DATA_FAILURE',
  UPDATE_HVSG_DESIGN_DATA_RESET: 'UPDATE_HVSG_DESIGN_DATA_RESET',

  UPDATE_HVSG_PERFORMANCE_DATA_PROGRESS:
    'UPDATE_HVSG_PERFORMANCE_DATA_PROGRESS',
  UPDATE_HVSG_PERFORMANCE_DATA_SUCCESS: 'UPDATE_HVSG_PERFORMANCE_DATA_SUCCESS',
  UPDATE_HVSG_PERFORMANCE_DATA_FAILURE: 'UPDATE_HVSG_PERFORMANCE_DATA_FAILURE',
  UPDATE_HVSG_PERFORMANCE_DATA_RESET: 'UPDATE_HVSG_PERFORMANCE_DATA_RESET',

  UPDATE_HVSG_WORKING_FLUID_DATA_PROGRESS:
    'UPDATE_HVSG_WORKING_FLUID_DATA_PROGRESS',
  UPDATE_HVSG_WORKING_FLUID_DATA_SUCCESS:
    'UPDATE_HVSG_WORKING_FLUID_DATA_SUCCESS',
  UPDATE_HVSG_WORKING_FLUID_DATA_FAILURE:
    'UPDATE_HVSG_WORKING_FLUID_DATA_FAILURE',
  UPDATE_HVSG_WORKING_FLUID_DATA_RESET: 'UPDATE_HVSG_WORKING_FLUID_DATA_RESET',

  UPDATE_HVSG_GEAR_DATA_PROGRESS: 'UPDATE_HVSG_GEAR_DATA_PROGRESS',
  UPDATE_HVSG_GEAR_DATA_SUCCESS: 'UPDATE_HVSG_GEAR_DATA_SUCCESS',
  UPDATE_HVSG_GEAR_DATA_FAILURE: 'UPDATE_HVSG_GEAR_DATA_FAILURE',
  UPDATE_HVSG_GEAR_DATA_RESET: 'UPDATE_HVSG_GEAR_DATA_RESET',

  GET_HVSG_CURVES_DATA_PROGRESS: 'GET_HVSG_CURVES_DATA_PROGRESS',
  GET_HVSG_CURVES_DATA_SUCCESS: 'GET_HVSG_CURVES_DATA_SUCCESS',
  GET_HVSG_CURVES_DATA_FAILURE: 'GET_HVSG_CURVES_DATA_FAILURE',
  GET_HVSG_CURVES_DATA_RESET: 'GET_HVSG_CURVES_DATA_RESET',

  UPDATE_HVSG_CURVES_DATA_PROGRESS: 'UPDATE_HVSG_CURVES_DATA_PROGRESS',
  UPDATE_HVSG_CURVES_DATA_SUCCESS: 'UPDATE_HVSG_CURVES_DATA_SUCCESS',
  UPDATE_HVSG_CURVES_DATA_FAILURE: 'UPDATE_HVSG_CURVES_DATA_FAILURE',
  UPDATE_HVSG_CURVES_DATA_RESET: 'UPDATE_HVSG_CURVES_DATA_RESET',

  DELETE_HVSG_CURVES_DATA_PROGRESS: 'DELETE_HVSG_CURVES_DATA_PROGRESS',
  DELETE_HVSG_CURVES_DATA_SUCCESS: 'DELETE_HVSG_CURVES_DATA_SUCCESS',
  DELETE_HVSG_CURVES_DATA_FAILURE: 'DELETE_HVSG_CURVES_DATA_FAILURE',
  DELETE_HVSG_CURVES_DATA_RESET: 'DELETE_HVSG_CURVES_DATA_RESET',


  RESET_HVSG_REDUCER: 'RESET_HVSG_REDUCER',
};
