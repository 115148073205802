import React from "react";
import { not_found } from "../assets/not_found";

const style = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function Notfound() {
  return (
    <div style={style}>
      <img src={not_found} alt="Coming Soon" />
    </div>
  );
}

export default Notfound;
