import { AutoComplete, Icon } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './DirectoryStructure.scss';

const keywords = {
  '@sort:': ['Name'],
  '@filter:': ['Client', 'Facility', 'System', 'Equipment', 'Deployed'],
};

function BFS(tree, nodeValue, nodeKey) {
  const queue = [];
  const parents = {};
  let head = 0;
  const foundedNode = {};

  function findNode(subtree, current) {
    if (subtree) {
      const allTreeNode = Object.keys(subtree);
      const limit = allTreeNode.length;
      for (let i = 0; i < limit; i++) {
        const currentNode = allTreeNode[i];
        parents[currentNode] = current;

        if (
          subtree[currentNode].info[nodeKey] &&
          subtree[currentNode].info[nodeKey]
            .toLowerCase()
            .indexOf(nodeValue.toLowerCase()) > -1
        ) {
          foundedNode[currentNode] = subtree[currentNode];
        }
        queue.push(subtree[currentNode]);
      }
    }
    if (queue[head]) {
      findNode(queue[head].children, queue[head++]);
    }
  }

  function getPathFromRoot(processData, id) {
    const path = {};
    let tempNode = id;
    path[nodeValue] = null;
    while (processData[tempNode]) {
      path[tempNode] = processData[tempNode].info._id;
      tempNode = processData[tempNode].info._id;
    }
    return path;
  }

  findNode(tree, null);

  Object.keys(foundedNode).forEach((item) => {
    foundedNode[item].path = getPathFromRoot(parents, item);
  });

  return foundedNode;
}

class DirectoryStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedNode: {},
      clickedNode: null,
      searchValue: '',
      searchlist: [],
      filteredList: [],
    };
  }

  componentDidMount() {
    const { defaultExpandedKeys, defaultSelectedKey } = this.props;
    if (this.props.defaultExpandedKeys) {
      defaultExpandedKeys &&
        defaultExpandedKeys.forEach((item) => {
          this.onDirectoryNodeExpanded(item);
          this.props.onExpand && this.props.onExpand(this.props.data[item]);
        });
    }

    if (defaultSelectedKey && defaultSelectedKey.info) {
      this.onDirectoryNodeSelected(defaultSelectedKey.info._id);
      this.props.onSelect && this.props.onSelect(defaultSelectedKey);
    }
  }

  componentDidUpdate(prevProps, preState) {
    const { defaultSelectedKey, data } = this.props;

    if (data && Object.keys(data).length) {
      if (
        prevProps.defaultSelectedKey !== defaultSelectedKey &&
        this.props.defaultSelectedKey &&
        this.props.defaultSelectedKey.info
      ) {
        this.onDirectoryNodeSelected(this.props.defaultSelectedKey.info._id);
        this.props.onSelect &&
          this.props.onSelect(this.props.defaultSelectedKey);
      }
    }
  }

  onDirectoryNodeSelected(id, e) {
    const result = BFS(this.props.data, id, '_id');
    this.setState(
      {
        clickedNode: id,
      },
      () => {
        this.onDirectoryNodeExpanded(id, true);
        Object.keys(result).map((item) => {
          if (result[item].path) {
            this.setState((preState) => {
              Object.values(result[item].path).forEach((tempId) => {
                preState.expandedNode[tempId] = true;
              });
              return preState;
            });
          }
          return undefined;
        });
      }
    );
  }

  onDirectoryNodeExpanded(id, e = false) {
    this.setState((preState) => {
      preState.expandedNode[id] = e ? true : !preState.expandedNode[id];
      return preState;
    });
  }

  renderName(name) {
    const { searchValue } = this.state;
    if (searchValue && searchValue.length > 0) {
      const nameArray = name.toLowerCase().split(searchValue.toLowerCase());
      let callength = 0;
      return nameArray.map((namePart, index) => {
        if (index < nameArray.length - 1) {
          callength += namePart.length + searchValue.length;
          if (index > 0) {
            return (
              <span>
                {name.substring(
                  callength - namePart.length - searchValue.length,
                  callength - searchValue.length
                )}
                <span className="searched-text">
                  {name.substring(callength - searchValue.length, callength)}
                </span>
              </span>
            );
          }
          return (
            <span>
              {name.substring(0, namePart.length)}
              <span className="searched-text">
                {name.substring(namePart.length, callength)}
              </span>
            </span>
          );
        }
        return (
          <span>
            {name.substring(name.length - namePart.length, name.length)}
          </span>
        );
      });
    }
    return name;
  }

  renderList(data = {}, depth) {
    return Object.keys(data).map((id, index) => {
      const idData = data[id];
      const dataInfo = data[id].info;
      const dataChildren = data[id].children;

      return (
        <div
          className="custom-directory"
          style={{ paddingLeft: `${12}px` }}
          key={id}
        >
          <div
            className={`custom-directory-title enabled ${
              this.state.clickedNode === id && 'selected'
            }`}
          >
            <div
              className={
                data[id].schematic || data[id].systemSchematic
                  ? 'icons'
                  : 'icon'
              }
              onClick={
                idData.clickable === false || dataChildren
                  ? (e) => {
                      this.onDirectoryNodeExpanded(id, false);
                      !this.state.expandedNode[id] &&
                        !dataChildren &&
                        this.props.onExpand &&
                        this.props.onExpand(idData, e);
                    }
                  : () => {}
              }
            >
              {this.state.expandedNode[id] && dataChildren ? (
                <Icon type="caret-right" rotate={45} />
              ) : (
                <Icon type="caret-right" />
              )}
            </div>
            <div
              className={`label ${
                (idData.hasOwnProperty('clickable') && idData.clickable) ||
                !idData.hasOwnProperty('clickable')
                  ? 'enabled'
                  : 'disabled'
              }`}
            >
              <div
                onClick={(e) => {
                  if (
                    (idData.hasOwnProperty('clickable') && idData.clickable) ||
                    !idData.hasOwnProperty('clickable')
                  ) {
                    this.onDirectoryNodeSelected(id, e);
                    this.props.onSelect && this.props.onSelect(idData, 'label');
                    if (this.props.dashboard) {
                      this.props.history.push({
                        state: {
                          ...this.props.location.state,
                          selectedIndex: index,
                          id,
                          type:
                            data[id].type === 'facility'
                              ? 0
                              : data[id].type === 'system'
                              ? 1
                              : 2,
                        },
                      });
                    }
                  }
                }}
              >
                <label title={dataInfo.name}>
                  {this.renderName(dataInfo.name)}
                </label>
              </div>

              {data[id].schematic && (
                <div
                  className="schematic"
                  onClick={(e) => {
                    if (
                      (idData.hasOwnProperty('clickable') &&
                        idData.clickable) ||
                      !idData.hasOwnProperty('clickable')
                    ) {
                      this.onDirectoryNodeSelected(id, e);
                      this.props.onSelect &&
                        this.props.onSelect(idData, 'schematic');
                    }
                  }}
                >
                  {data[id].schematic && <div>Component schematic</div>}
                </div>
              )}

              {data[id].systemSchematic && (
                <div
                  className="schematic"
                  onClick={(e) => {
                    if (
                      (idData.hasOwnProperty('clickable') &&
                        idData.clickable) ||
                      !idData.hasOwnProperty('clickable')
                    ) {
                      this.onDirectoryNodeSelected(id, e);
                      this.props.onSelect &&
                        this.props.onSelect(idData, 'systemSchematic');
                    }
                  }}
                >
                  {data[id].systemSchematic && <div>System schematic</div>}
                </div>
              )}
            </div>
          </div>
          {dataChildren &&
            this.state.expandedNode[id] &&
            this.renderList(dataChildren, depth + 1)}
        </div>
      );
    });
  }

  onSearchChange = (e) => {
    if (e === '@') {
      this.setState({
        searchlist: Object.keys(keywords),
      });
    } else if (keywords[e]) {
      this.setState({
        searchlist: keywords[e].map((item) => e + item),
      });
    } else {
      this.setState({
        searchlist: [],
      });
    }
  };

  onSelect = (e) => {};

  onSearch = (e) => {
    const result = BFS(this.props.data, e, 'name');
    this.setState(
      {
        searchValue: e || '',
      },
      () => {
        const filteredList = {};
        Object.keys(result).forEach((item) => {
          if (
            (result[item].hasOwnProperty('clickable') &&
              result[item].clickable) ||
            !result[item].hasOwnProperty('clickable')
          ) {
            if (!filteredList[result[item].type]) {
              filteredList[result[item].type] = {};
            }
            filteredList[result[item].type][item] = result[item];
          }
        });
        this.setState({
          filteredList,
        });
      }
    );
  };

  renderSearchView() {
    const { filteredList } = this.state;

    return filteredList && Object.keys(filteredList).length
      ? Object.keys(filteredList).map((item) => (
          <div className="search-view">
            <div className="category">{`${item} List`}</div>
            {this.renderList(filteredList[item], 0)}
          </div>
        ))
      : 'No search result found. You can search using Facility Name, System Name, Equipment Name.';
  }

  render() {
    const { data } = this.props;
    return data ? (
      <div className="CustomDirectoryStructure">
        <AutoComplete
          placeholder="Search here ..."
          dataSource={this.state.searchlist}
          onChange={this.onSearchChange}
          onSelect={this.onSelect}
          onSearch={this.onSearch}
        />
        {this.state.searchValue && this.state.searchValue.length > 0
          ? this.renderSearchView()
          : this.renderList(data, 0)}
      </div>
    ) : null;
  }
}

export default withRouter(DirectoryStructure);
