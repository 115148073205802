export const FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS =
  'FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS';
export const FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS =
  'FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS';
export const FETCH_ALERT_KPIS_BY_LEVEL_ERROR =
  'FETCH_ALERT_KPIS_BY_LEVEL_ERROR';
export const FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE =
  'FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE';
export const ADD_ALERT_KPIS_PROGRESS = 'ADD_ALERT_KPIS_PROGRESS';
export const ADD_ALERT_KPIS_SUCCESS = 'ADD_ALERT_KPIS_SUCCESS';
export const ADD_ALERT_KPIS_ERROR = 'ADD_ALERT_KPIS_ERROR';
export const ADD_ALERT_KPIS_CLEAR_STATE = 'ADD_ALERT_KPIS_CLEAR_STATE';
export const FETCH_ALERT_PLOT_DATA_PROGRESS = 'FETCH_ALERT_PLOT_DATA_PROGRESS';
export const FETCH_ALERT_PLOT_DATA_SUCCESS = 'FETCH_ALERT_PLOT_DATA_SUCCESS';
export const FETCH_ALERT_PLOT_DATA_ERROR = 'FETCH_ALERT_PLOT_DATA_ERROR';
export const FETCH_ALERT_PLOT_DATA_CLEAR_STATE = 'FETCH_ALERT_PLOT_DATA_CLEAR_STATE';
export const UPDATE_ALERT_KPI_PROGRESS = 'UPDATE_ALERT_KPI_PROGRESS';
export const UPDATE_ALERT_KPI_SUCCESS = 'UPDATE_ALERT_KPI_SUCCESS';
export const UPDATE_ALERT_KPI_ERROR = 'UPDATE_ALERT_KPI_ERROR';
export const UPDATE_ALERT_KPI_CLEAR_STATE = 'UPDATE_ALERT_KPI_CLEAR_STATE';
export const SET_TIME_RANGE_VISIBILITY_ON = 'SET_TIME_RANGE_VISIBILITY_ON';
export const SET_TIME_RANGE_VISIBILITY_OFF = 'SET_TIME_RANGE_VISIBILITY_OFF';
export const UPDATE_KPI_BULK_PROGESS = 'UPDATE_KPI_BULK_PROGESS';
export const UPDATE_ALERT_KPI_BULK_PROGRESS = 'UPDATE_ALERT_KPI_BULK_PROGRESS';
export const UPDATE_ALERT_KPI_BULK_SUCCESS = 'UPDATE_ALERT_KPI_BULK_SUCCESS';
export const UPDATE_ALERT_KPI_BULK_ERROR = 'UPDATE_ALERT_KPI_BULK_ERROR';
export const BULK_UPDATE_ALERT_KPI_CLEAR_STATE = 'BULK_UPDATE_ALERT_KPI_CLEAR_STATE';
export const DELETE_ALERT_KPI_PROGRESS = 'DELETE_ALERT_KPI_PROGRESS';
export const DELETE_ALERT_KPI_SUCCESS = 'DELETE_ALERT_KPI_SUCCESS';
export const DELETE_ALERT_KPI_ERROR = 'DELETE_ALERT_KPI_ERROR';
export const DELETE_ALERT_KPI_CLEAR_STATE = 'DELETE_ALERT_KPI_CLEAR_STATE';
export const SET_ZOOM_IN = 'SET_ZOOM_IN';
export const SET_ZOOM_OUT = 'SET_ZOOM_OUT';
export const SET_META_DATA_ON = 'SET_META_DATA_ON';
export const SET_META_DATA_OFF = 'SET_META_DATA_OFF';
export const CLEAR_DATA_UPDATE_STATE = 'CLEAR_DATA_UPDATE_STATE';

