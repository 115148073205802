import { Button, Form, Tabs } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Notification, { Types } from '../../../common/Notification/Notification';
import { MaskStyle } from '../../../constants/react-style';
import { DashboardVariables } from '../../../constants/variables';
import { elementsGaurd } from '../../../gaurds';
import { ElementPermissions } from '../../../permissions';
import { roles } from '../../../permissions/roles.permissions';
import AddNewCard from '../AddNewCard/AddNewCard';
import Alerts from '../Alerts/Alerts';
import './Dashboard.info.scss';
// import LiveData from './LiveData/LiveData';
import Overview from './Overview';
import Plots from './Plots/Plots';
import Runs from './Runs/Runs';
// import Trends from './Trends/Trends';
import Trends2 from './Trends/Trends2';
// import { DownOutlined } from '@ant-design/icons';
import {
  setInspectionModeOn,
  setInspectionModeOff,
} from '../../../redux/actions/trends.action';
import RecalculateComponent from './Runs/RecalculateKPI';
import Iow from './IOW/iow';
import TransientAnalysis from './TransientData/transientData';

const { TabPane } = Tabs;
class DashboardInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      info: null,
      visible: false,
      accessInfo: null,
    };
  }

  componentDidMount() {
    const key = localStorage.getItem('activeKey');
    this.setState({
      activeKey: key || '1',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { info, isInfo, clearInfoState } = this.props;
    const { userRole } = this.props.userRoleState.apiState;
    if (isInfo) {
      clearInfoState();
      this.setState(
        {
          info,
        },
        () => {
          this.showAlerts(userRole);
        }
      );
    }

    if (prevProps.info && prevProps.info.type !== info && info.type) {
      if (
        (this.state.activeKey === '4' || this.state.activeKey === '5') &&
        info.type !== 'equipment'
      ) {
        this.setState({
          activeKey: '1',
        });
      }
    }
   

    if(this.state.activeKey === '2' && this.state.accessInfo?.read === false)
    {
      this.setState({
        activeKey: '1',
      }, () => {
        localStorage.setItem('activeKey', this.state.activeKey);
      });
    }
  }

  // this function defines the access provided to the user with different roles
  // userRole containes the role of the user, info containes the relative info about the selected key from the tree
  showAlerts(userRole) {
    const { accessInfo } = this.props.userRoleState;
    const { info } = this.props;

    const clientAdminAlertAccess = { read: true, write: true };
    if (userRole === roles.CLIENT_ADMIN || userRole === roles.MECHADEMY_ADMIN) {
      this.setState({
        accessInfo: clientAdminAlertAccess,
      });
    } else {
      let facilityId = info.dataRef['_id'];
      if (info.type === 'system' || info.type === 'equipment') {
        facilityId = info.dataRef['facilityId'];
      }
      info &&
        accessInfo.facility.find(
          (element) => element.facilityId === facilityId
        ) &&
        accessInfo.facility.forEach((element) => {
          if (element.facilityId === facilityId) {
            if (userRole === roles.MECHADEMY_FACILITY_MANAGER) {
              this.setState({
                accessInfo: element.permission && element.permission.dashboard,
              });
            } else if (userRole === roles.SYSTEM_MANAGER) {
              if (
                accessInfo.system.find(
                  (element) =>
                    element.systemId === info.dataRef?._id ||
                    element.systemId === info.dataRef?.systemId
                )
              ) {
                accessInfo.system.forEach((element) => {
                  if (
                    element.systemId === info.dataRef._id ||
                    element.systemId === info.dataRef?.systemId
                  ) {
                    this.setState({
                      accessInfo:
                        element.permission && element.permission.alerts,
                    });
                  }
                });
              }
            } else if (userRole === roles.EQUIPMENT_MANAGER) {
              accessInfo.equipment.forEach((element) => {
                if (element.equipmentId === info.dataRef._id) {
                  this.setState({
                    accessInfo: element.permission && element.permission.alerts,
                  });
                }
              });
            } else {
              this.setState({
                accessInfo: element.permission && element.permission.alerts,
              });
            }
          }
        });
    }
  }

  onTabChange(pane) {
    if (this.state.formEdit) {
      this.setState({
        tabChange: pane,
        activeKey: this.state.activeKey,
        visible: true,
      });
    } else {
      this.setState(
        {
          activeKey: pane,
        },
        () => {
          localStorage.setItem('activeKey', this.state.activeKey);
        }
      );
    }
  }

  getTitle() {
    const { info } = this.state;
    if (info) {
      return `${info.title} ${DashboardVariables.TITLE}`;
    }
    return DashboardVariables.TITLE;
  }

  openAddCardModal() {
    const { info } = this.props;
    if (info.type === 'equipment') {
      this.setState({ visible: true });
    } else if (
      info.type !== 'equipment' &&
      info.equipmentList &&
      info.equipmentList.length
    ) {
      this.setState({ visible: true });
    } else {
      Notification.show(
        Types.Info,
        DashboardVariables.NO_SYSTEMS_AND_EQUIPMENTS
      );
    }
  }

  switchGlobalInspectionMode()  {
    const { setInspectionModeOn } = this.props;
    setInspectionModeOn();
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  renderAddCardModal() {
    if (this.state.visible) {
      const { info } = this.props;
      return (
        <AddNewCard
          visible={this.state.visible}
          handleCancel={this.handleCancel}
          info={info}
          activeKey={this.state.activeKey}
        />
      );
    }
  }

  renderDashboard() {
    //const { userRole } = this.props.userRoleState.apiState;
    const { info } = this.props;
    const equipmentId =(this.props.info)?(this.props.info.equipmentList)?(this.props.info.equipmentList._id)?this.props.info.equipmentList._id:"": "":""
    const overviewTab = (
      <TabPane tab="Overview" key="1">
        <Overview info={this.props.info} activeKey={this.state.activeKey} />
      </TabPane>
    );
    const alertsTab = (
      <TabPane tab="Alerts" key="2">
        {this.state.activeKey === '2' && (
          <Alerts
            info={this.props.info}
            accessInfo={this.state.accessInfo}
            activeKey={this.state.activeKey}
          />
        )}
      </TabPane>
    );
    const plotsTab = (
      <TabPane tab="Plots" key="3">
        <Plots info={this.props.info} activeKey={this.state.activeKey} />
      </TabPane>
    );
    const runsTab = (
      <TabPane tab="Runs" key="4">
        <Runs info={this.props.info} activeKey={this.state.activeKey} />
      </TabPane>
    );
    // const liveDataTab = (
    //   <TabPane tab="Live data" key="5">
    //     <LiveData info={this.props.info} />
    //   </TabPane>
    // );
    const trendsTab2 = (
      <TabPane tab="Trends" key="7">
        <Trends2 info={this.props.info} activeKey={this.state.activeKey}/>
      </TabPane>
    );
    const iowTab = (
      <TabPane tab="IOW" key="8">
        <Iow info={this.props.info} activeKey={this.state.activeKey} />
      </TabPane>
    )
    const transientAnalysisTab = (
      <TabPane tab="Transient Analysis" key="5">
        <TransientAnalysis info={this.props.info} activeKey={this.state.activeKey} />
      </TabPane>
    )
    return (
      <>
        <div className="header" style={MaskStyle}>
          <div className="title">{this.getTitle()}</div>
        </div>
        <div className="screen-content">
          <div className="content-div">
            {this.state.activeKey !== '2' &&
              this.state.activeKey !== '4' &&
              this.state.activeKey !== '5' &&
              this.state.activeKey !== '6' &&
              this.state.activeKey !== '8' &&
              this.state.activeKey !== '7' &&
              elementsGaurd(
                <Button
                  className="add-btn btn-default new add-btn-align"
                  onClick={() => this.openAddCardModal()}
                >
                  {DashboardVariables.ADD_CARD}
                </Button>,
                ElementPermissions.ADD_CARD,
                info
              )}
            {(this.state.activeKey === '6' || this.state.activeKey === '7') && (
              <div className="add-btn new">
                {elementsGaurd(
                  <Button
                    className="btn-default btn-align"
                    onClick={() => this.openAddCardModal()}
                  >
                    {DashboardVariables.ADD_TREND}
                  </Button>,
                  ElementPermissions.DASHBOARD_VIEW,
                  info
                )}
                {elementsGaurd(
                  <Button
                    className="btn-default"
                    onClick={() => this.switchGlobalInspectionMode()}
                  >
                    {DashboardVariables.GLOBAL_INSPECTION_MODE}
                  </Button>,
                  ElementPermissions.DASHBOARD_VIEW,
                  info
                )}
              </div>
            )}
            {this.state.activeKey === '4' && (
              <div className="add-btn new">
                <RecalculateComponent
                  equipmentId={equipmentId}
                ></RecalculateComponent>
              </div>
            )}
            <div className="tab-type-card">
              {
                <Tabs
                  defaultActiveKey="1"
                  activeKey={this.state.activeKey}
                  type="card"
                  onChange={(event) => {
                    this.onTabChange(event);
                  }}
                >
                  {elementsGaurd(
                    overviewTab,
                    ElementPermissions.DASHBOARD_VIEW,
                    info
                  )}
                  {elementsGaurd(iowTab, ElementPermissions.DASHBOARD_VIEW, info)}

                  {/** Tab visible only when alert read/write access */}
                  {this.state.accessInfo &&
                    this.state.accessInfo.read &&
                    elementsGaurd(
                      alertsTab,
                      ElementPermissions.DASHBOARD_VIEW,
                      info
                    )}
                  {elementsGaurd(
                    plotsTab,
                    ElementPermissions.DASHBOARD_VIEW,
                    info
                  )}
                  {/* {(userRole === 'MechademyAdmin' ||
                    userRole === 'MechademyFacilityManager') &&
                    this.state.info?.type === 'equipment' &&
                    runsTab} */}
                  {this.state.info?.type === 'equipment' && runsTab}
                  {this.state.info?.type === 'equipment' &&
                    elementsGaurd(
                      transientAnalysisTab, 
                      ElementPermissions.ADD_CARD, 
                      info
                    )}
                  {/* {this.state.info?.type === 'equipment' &&
                    elementsGaurd(
                      liveDataTab,
                      ElementPermissions.DASHBOARD_VIEW,
                      info
                    )}  */}
                  {/* {elementsGaurd(trendsTab, ElementPermissions.DASHBOARD_VIEW, info)} */}
                  {elementsGaurd(
                    trendsTab2,
                    ElementPermissions.DASHBOARD_VIEW,
                    info
                  )}
                </Tabs>
              }
            </div>
          </div>
          {this.renderAddCardModal()}
        </div>
      </>
    );
  }

  render() {
    // const {
    //   AddNewCard,
    //   CardTypesReducer,
    //   DeployedComponentReducer,
    //   DeployedEquipmentReducer,
    //   TagsByComponentReducer,
    //   TagsByCardTypeReducer,
    // } = this.props;
    return (
      <div className="DashboardInfo">
        {/* {(AddNewCard.isLoading ||
          CardTypesReducer.isLoading ||
          DeployedEquipmentReducer.isLoading ||
          DeployedComponentReducer.isLoading ||
          TagsByComponentReducer.isLoading ||
          TagsByCardTypeReducer.isLoading) && <Loader />} */}
        {this.renderDashboard()}
      </div>
    );
  }
}

DashboardInfo.propTypes = {
  info: PropTypes.object,
  isInfo: PropTypes.bool,
  clearInfoState: PropTypes.func,
  form: PropTypes.object,
};

DashboardInfo.defaultProps = {
  info: {},
  isInfo: false,
  clearInfoState: () => {},
};

const mapStateToProps = (state) => ({
  userRoleState: state.LoginReducer,
  AddNewCard: state.AddNewCardReducer,
  CardTypesReducer: state.CardTypesReducer,
  DeployedComponentReducer: state.DeployedComponentReducer,
  DeployedEquipmentReducer: state.DeployedEquipmentReducer,
  TagsByComponentReducer: state.TagsByComponentReducer,
  TagsByCardTypeReducer: state.TagsByCardTypeReducer,
  inspectionMode: state.trendsReducer.inspectionMode,
});

const mapDispatchToProps = {
  setInspectionModeOn,
  setInspectionModeOff,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'DashBoardInfoForm' })(DashboardInfo));
