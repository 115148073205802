import React from 'react';
import './AddUserBody.scss';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import _ from 'lodash';
import { formFieldsValveData, userRole } from './AddUserBody.Constants';
import FormFields from '../../../../common/FormFields/FormFields';
import '../../../../constants/global.scss';
import { connect } from 'react-redux';
import {
  clientList,
  clearClientState,
  getClientInfo,
} from '../../../../redux/actions/client.action';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import {
  NO_INTERNET,
  UserManagementMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
} from '../../../../constants/messages';
import { ButtonVariables } from '../../../../constants/variables';
import AddUserBodyTable from './AddUserBodyTable';
import {
  userSave,
  editUser,
  clearUserData,
} from '../../../../redux/actions/UserManagement.action';
import * as Routes from '../../../../constants/routes';
import Loader from '../../../../common/Loader/Loader';
import ConfirmModal from '../../../../common/Modal/Modal';

import { roles } from '../../../../permissions/roles.permissions';
import {
  getAllFacilityList,
  clearFacilityState,
} from '../../../../redux/actions/facility.action';

class AddUserBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      clientSelected: [],
      roleSelected: '',
      isClient: false,
      clientInfoData: '',
      multipleRoleSelected: [],
      setEditData: true,
      saveMore: false,
      visible: false,
      formEdit: false,
      readWriteEdit: false,
      formDataValues: '',
      initialClientSet: true,
      clientFacilityList: [],
      userManagementAccess: '',
      mailSubscription: false,
    };
  }

  componentDidMount() {
    this.getClientList();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', () => {
      this.setState({
        formEdit: false,
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isSuccessFetchClients,
      clientList,
    } = this.props.clientState.apiState;
    const {
      isSaveUserSuccess,
      isEditUserSuccess,
      isSaveUserError,
      isEditUserError,
      errorMessageSaveUser,
    } = this.props.userManagementReducer;
    const { clearClientState, clearUserData } = this.props;
    const {
      location: { state },
      clearFacilityState,
    } = this.props;
    const { setEditData, saveMore, formEdit } = this.state;
    const {
      isSuccessFetchFacility,
      isErrorFetchFacility,
      facilityData,
    } = this.props.facilityReducer.apiState;
    if (isSuccessFetchClients) {
      clearClientState();
      this.setState({
        clientList: clientList.items,
      });
    }

    if (state && state.mode && state.mode === 'edit' && setEditData) {
      const { fetchFacilityList } = this.props;
      const values = state.data;
      if (
        values.role[0] === 'MechademyFacilityManager' &&
        this.handleNetwork()
      ) {
        fetchFacilityList();
      }
      const access = () => {
        if (values.access && values.access.equipment.length) {
          return values.access.equipment.map(
            (equipment) => equipment.equipmentId
          );
        }
        if (values.access && values.access.facility.length) {
          return values.access.facility.map((facility) => facility.facilityId);
        }
        if (values.access && values.access.system.length) {
          return values.access.system.map((system) => system.systemId);
        }
      };
      const roleSelect = access()
      const formData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        workPhone: values.workPhone,
        client:
          state.data.role[0] === "MechademyFacilityManager"
            ? values.client
            : values.client[0]._id,
        clientName:
          state.data.role[0] === "MechademyFacilityManager"
            ? [
                ...new Set(
                  values.client.map((item) => {
                    return item.name;
                  })
                ),
              ]
            : values.client[0].name,
        role: userRole[userRole.findIndex((roles) => roles.role === values.role[0])]
          .displayRole,
        roleSelect: roleSelect ? roleSelect : "",
      };
      this.setState({
        formDataValues: formData,
      });

      const displayEditData = () => {
        let info = '';
        if (state.data.role[0] === 'FacilityManager') {
          info = state.data.access.facility.map((facility) => ({
            data: facility.facilityId,
            read: facility.permission.alerts.read,
            write: facility.permission.alerts.write,
          }));
        } else if (state.data.role[0] === 'SystemEngineer') {
          info = state.data.access.system.map((system) => ({
            data: system.systemId,
            read: system.permission.alerts.read,
            write: system.permission.alerts.write,
          }));
        } else if (state.data.role[0] === 'EquipmentEngineer') {
          info = state.data.access.equipment.map((equipment) => ({
            data: equipment.equipmentId,
            read: equipment.permission.alerts.read,
            write: equipment.permission.alerts.write,
          }));
        } else if (state.data.role[0] === 'MechademyFacilityManager') {
          info = state.data.access.facility.map((facility) => ({
            data: facility.facilityId,
            dashboard: {
              read: facility.permission.dashboard.read,
              write: facility.permission.dashboard.write,
            },
            setup: {
              read: facility.permission.setup.read,
              write: facility.permission.setup.write,
            },
            mlAlgo: {
              read: facility.permission.mlAlgo.read,
              write: facility.permission.mlAlgo.write,
            },
            piTag: {
              read: facility.permission.piTag.read,
              write: facility.permission.piTag.write,
            },
          }));
        }
        return info;
      };

      const userManagementData = () => {
        let info = '';
        if (state.data.role[0] === 'MechademyFacilityManager') {
          info = {
            userMgmt: {
              read: state.data.access.userManagementPermission
                ? state.data.access.userManagementPermission.read
                : false,
              write: state.data.access.userManagementPermission
                ? state.data.access.userManagementPermission.write
                : false,
            },
          };
        }
        return info;
      };

      this.setState({
        multipleRoleSelected: displayEditData(),
        userManagementAccess: userManagementData(),
        setEditData: false,
        clientSelected:
          state.data &&
          state.data.client.map((client) => {
            return client._id;
          }),
        roleSelected: state.data && state.data.role[0],
      });
      if (this.handleNetwork()) {
        this.props.getClientInfo(state.data.client[0]._id);
      }
    }

    if (
      this.props.clientState.apiState.clientList !==
      prevProps.clientState.apiState.clientList
    ) {
      this.props.form.setFieldsValue({
        ...this.state.formDataValues,
        client: this.state.formDataValues.clientName,
      });
      this.disableEditForm();
    }

    if (isSaveUserSuccess && !saveMore) {
      clearUserData();
      Notification.show(
        Types.Success,
        UserManagementMessages.USER_SAVE_SUCCESS
      );
      this.props.history.push(Routes.userManagement);
    }
    if (isSaveUserSuccess && saveMore) {
      clearUserData();
      Notification.show(
        Types.Success,
        UserManagementMessages.USER_SAVE_SUCCESS
      );
      this.props.form.setFieldsValue({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        workPhone: '',
        client: '',
        role: '',
        selectRole: [],
      });
      this.setState(
        {
          multipleRoleSelected: [],
          userManagementAccess: '',
          roleSelected: '',
          saveMore: false,
        },
        () => this.disableEditForm()
      );
    }
    if (isSaveUserError) {
      clearUserData();
      Notification.show(
        Types.Error,
        errorMessageSaveUser &&
          errorMessageSaveUser.body &&
          errorMessageSaveUser.body.code
          ? UserManagementMessages[errorMessageSaveUser.body.code]
          : UserManagementMessages.USER_SAVE_ERROR
      );
    }
    if (isEditUserSuccess) {
      clearUserData();
      Notification.show(
        Types.Success,
        UserManagementMessages.USER_EDIT_SUCCESS
      );
      this.props.history.push(Routes.userManagement);
    }
    if (isEditUserError) {
      clearUserData();
      Notification.show(Types.Error, UserManagementMessages.USER_EDIT_ERROR);
    }
    if (this.props.clientState.isSuccessClientsInfo) {
      clearClientState();
      this.setState({
        clientInfoData: this.props.clientInfoData,
      });
    }
    if (prevState.formEdit !== formEdit) {
      this.props.onChangeFormEdit(formEdit);
    }
    if (isSuccessFetchFacility) {
      clearFacilityState();
      const clientFacilityList = [];
      const items = facilityData.items;
      items.forEach((element) => {
        clientFacilityList.push({
          name: element.name,
          id: element._id,
          client: element.client,
        });
      });
      this.setState({
        clientFacilityList: clientFacilityList,
      });
    }

    if (isErrorFetchFacility) {
      clearFacilityState();
    }
  }

  componentWillUnmount() {
    this.disableEditForm();
  }

  getClientList() {
    const { clientListAction } = this.props;
    if (this.handleNetwork()) {
      clientListAction();
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  access = () => {
    const {
      roleSelected,
      multipleRoleSelected,
      userManagementAccess,
    } = this.state;
    switch (roleSelected) {
      case roles.FACILITY_MANAGER:
        return {
          facility: multipleRoleSelected.map((selected) => ({
            facilityId: selected.data,
            permission: {
              alerts: {
                read: selected.read,
                write: selected.write,
              },
            },
          })),
        };

      case roles.EQUIPMENT_MANAGER:
        return {
          equipment: multipleRoleSelected.map((selected) => ({
            equipmentId: selected.data,
            permission: {
              alerts: {
                read: selected.read,
                write: selected.write,
              },
            },
          })),
        };
      case roles.SYSTEM_MANAGER:
        return {
          system: multipleRoleSelected.map((selected) => ({
            systemId: selected.data,
            permission: {
              alerts: {
                read: selected.read,
                write: selected.write,
              },
            },
          })),
        };
      case roles.CLIENT_ADMIN:
        return {};
      case roles.MECHADEMY_FACILITY_MANAGER:
        return {
          facility: multipleRoleSelected.map((selected) => ({
            facilityId: selected.data,
            permission: {
              dashboard: {
                read: selected.dashboard.read,
                write: selected.dashboard.write,
              },
              setup: {
                read: selected.setup.read,
                write: selected.setup.write,
              },
              mlAlgo: {
                read: selected.mlAlgo.read,
                write: selected.mlAlgo.write,
              },
              piTag: {
                read: selected.piTag.read,
                write: selected.piTag.write,
              },
            },
          })),
          userManagementPermission: {
            read: userManagementAccess.userMgmt.read,
            write: userManagementAccess.userMgmt.write,
          },
        };
      default:
        return undefined;
    }
  };

  checkAfterTrim() {
    const formValues = this.props.form.getFieldsValue();
    this.props.form.setFieldsValue({
      firstName: formValues.firstName && formValues.firstName.trim(),
      lastName: formValues.lastName && formValues.lastName.trim(),
    });
  }

  update = () => {
    this.checkAfterTrim();
    const { _id } = this.props.location.state.data;
    const { formEdit } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err && formEdit) {
        const payload = {
          firstName: _.capitalize(values.firstName),
          lastName: _.capitalize(values.lastName),
          phone: values.phone ? values.phone : '',
          workPhone: values.workPhone,
          client: this.state.clientSelected,
          role: [this.state.roleSelected],
          mailSubscription: this.state.mailSubscription,
          access: this.access(),
        };
        if (this.handleNetwork()) {
          this.props.editUser(_id, payload);
        }
      } else if (!formEdit) {
        this.props.history.push(Routes.userManagement);
      }
    });
  };

  save = (type) => {
    this.checkAfterTrim();
    const { roleSelected } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const payload = {
          firstName: _.capitalize(values.firstName),
          lastName: _.capitalize(values.lastName),
          email: values.email,
          phone: values.phone,
          workPhone: values.workPhone,
          client: this.state.clientSelected,
          role: [roleSelected],
          access: this.access(),
          mailSubscription: this.state.mailSubscription,
        };
        if (this.handleNetwork()) {
          this.props.userSave(payload);
        }
        type === 'MORE' &&
          this.setState({
            saveMore: true,
          });
      }
    });
  };

  cancel = () => {
    if (this.state.formEdit) {
      this.setState({
        visible: true,
      });
    } else {
      this.props.history.push(Routes.userManagement);
    }
  };

  clientSelect = (client) => {
    if (this.handleNetwork()) {
      this.props.getClientInfo(client);
    }
    this.setState(
      {
        clientSelected: [client],
        multipleRoleSelected: [],
        isClient: true,
      },
      () => {
        this.props.form.setFieldsValue({
          selectRole: [],
        });
      }
    );
  };

  clearClient = () => {
    this.setState({
      isClient: false,
    });
  };

  onSwitchChange = (val) => {
    this.setState({
      mailSubscription: val,
    });
    if (this.props?.location?.state?.data?.mailSubscription) {
      this.props.location.state.data.mailSubscription = val;
    }
  };

  roleSelect = (role) => {
    const { fetchFacilityList } = this.props;
    if (role === 'MechademyFacilityManager' && this.handleNetwork()) {
      fetchFacilityList();
      const userManagementBody = {
        userMgmt: {
          read: false,
          write: false,
        },
      };
      this.setState({
        userManagementAccess: userManagementBody,
      });
    }
    this.setState(
      {
        roleSelected: role,
        multipleRoleSelected: [],
        client: '',
        clientSelected: [],
        isClient: false,
      },
      () => {
        this.props.form.setFieldsValue({
          selectRole: [],
          client: [],
        });
      }
    );
  };

  // this function is only called in case of role is MechademyFacilityManager
  // because in only that case thier is a possibilty of multiple clients
  autoFillClientField = (multipleRoleSelected) => {
    const { clientFacilityList, clientList } = this.state;
    let clients = multipleRoleSelected.map((item) => {
      const index = clientFacilityList.findIndex((element) => {
        return element.id === item.data;
      });
      return clientFacilityList[index].client;
    });

    // removing duplicate clients from the list
    clients = [...new Set(clients)];

    const clientName = clients.map((client) => {
      const index = clientList.findIndex((element) => {
        return element._id === client;
      });
      return clientList[index].name;
    });

    this.setState({
      clientSelected: clients,
    });
    this.props.form.setFieldsValue({
      client: clientName,
    });
  };

  multipleRoleSelect = (multiRole) => {
    const { roleSelected } = this.state;
    if (roleSelected !== 'MechademyFacilityManager') {
      this.setState({
        multipleRoleSelected: [
          ...this.state.multipleRoleSelected,
          { data: multiRole, read: false, write: false },
        ],
      });
    } else {
      const updated_role_selected = [
        ...this.state.multipleRoleSelected,
        {
          data: multiRole,
          dashboard: { read: false, write: false },
          setup: { read: false, write: false },
          mlAlgo: { read: false, write: false },
          piTag: { read: false, write: false },
        },
      ];
      this.setState({
        multipleRoleSelected: updated_role_selected,
      });
      this.autoFillClientField(updated_role_selected);
      
    }
  };

  multipleRoleDeSelect = (multiRole) => {
    const afterDeselectRole = this.state.multipleRoleSelected.filter(
      (row) => row.data !== multiRole
    );
    this.setState({
      multipleRoleSelected: afterDeselectRole,
    });

    const { roleSelected } = this.state;
    if (roleSelected === 'MechademyFacilityManager') {
      this.autoFillClientField(afterDeselectRole)
    }

  };

  populateData = (preSelected) => {
    this.state.initialClientSet &&
      this.props.form.setFieldsValue(
        {
          selectRole: preSelected,
        },
        () => {
          this.setState({
            initialClientSet: false,
          });
        }
      );
  };

  onchangeAccess = (data, row, type, col) => {
    const { multipleRoleSelected, roleSelected } = this.state;
    const copymultipleRoleSelected = multipleRoleSelected;
    if (roleSelected !== 'MechademyFacilityManager') {
      copymultipleRoleSelected[row] = {
        data,
        read:
          type === 'read' && !copymultipleRoleSelected[row].write
            ? !copymultipleRoleSelected[row].read
            : true,
        write:
          type === 'write'
            ? !copymultipleRoleSelected[row].write
            : copymultipleRoleSelected[row].write,
      };

      this.setState(
        {
          multipleRoleSelected: copymultipleRoleSelected,
          readWriteEdit: true,
        },
        () => this.enableEditForm()
      );
    } else if (
      roleSelected === 'MechademyFacilityManager' &&
      col !== 'userMgmt'
    ) {
      copymultipleRoleSelected[row][col] = {
        read:
          type === 'read' && !copymultipleRoleSelected[row][col].write
            ? !copymultipleRoleSelected[row][col].read
            : true,
        write:
          type === 'write'
            ? !copymultipleRoleSelected[row][col].write
            : copymultipleRoleSelected[row][col].write,
      };
      copymultipleRoleSelected[row] = {
        data,
        ...copymultipleRoleSelected[row],
      };
      this.setState(
        {
          multipleRoleSelected: copymultipleRoleSelected,
          readWriteEdit: true,
        },
        () => this.enableEditForm()
      );
    } else {
      const { userManagementAccess } = this.state;
      let copyUserManagementAccess = { ...userManagementAccess };
      copyUserManagementAccess.userMgmt = {
        read:
          type === 'read' && !copyUserManagementAccess.userMgmt.write
            ? !copyUserManagementAccess.userMgmt.read
            : true,
        write:
          type === 'write'
            ? !copyUserManagementAccess.userMgmt.write
            : copyUserManagementAccess.userMgmt.write,
      };
      this.setState(
        {
          userManagementAccess: copyUserManagementAccess,
          readWriteEdit: true,
        },
        () => this.enableEditForm()
      );
    }
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          message={FORM_DISCARD_MSG}
        />
      );
    }
    return null;
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.history.push(Routes.userManagement);
    this.disableEditForm();
  };


  render() {
    const { loading } = this.props.clientState;
    const { userManagementReducer } = this.props;
    const { clientInfoData } = this.props.clientState.apiState;
    const { state } = this.props.location;
    const {
      clientList,
      clientSelected,
      roleSelected,
      isClient,
      multipleRoleSelected,
      showTable,
      clientFacilityList,
      userManagementAccess,
    } = this.state;
    return (
      <div className="AddUserBody">
        {(loading || userManagementReducer.loading) && <Loader />}
        <div className="content-div">
          <FormFields
            formFields={formFieldsValveData({
              clientList,
              clientSelect: (value) => this.clientSelect(value),
              roleSelect: this.roleSelect,
              multipleRoleSelect: this.multipleRoleSelect,
              mailSubscription: this.state.mailSubscription,
              onSwitchChange: this.onSwitchChange,
              multipleRoleDeSelect: this.multipleRoleDeSelect,
              clientId: clientSelected[0] ? clientSelected[0] : '',
              role: roleSelected,
              clientInfoData: clientInfoData && clientInfoData,
              clientFacilityList: clientFacilityList,
              editUserData: state,
              preSelected: this.state.multipleRoleSelected,
              populateData: (preSelected) => {
                this.populateData(preSelected);
              },
            })}
            form={this.props.form}
          />
          <AddUserBodyTable
            clientId={clientSelected[0] ? clientSelected[0] : ''}
            role={roleSelected}
            isClient={isClient}
            multipleRoleSelected={multipleRoleSelected}
            onchangeAccess={this.onchangeAccess}
            clearClient={() => this.clearClient()}
            clientInfoData={clientInfoData && clientInfoData}
            showTable={showTable}
            roleEdit={state && state.data.role[0]}
            clientFacilityList={clientFacilityList}
            userManagementAccess={userManagementAccess}
          />
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.cancel}>
              {ButtonVariables.CANCEL}
            </button>
            {!state ? (
              <>
                <button
                  className="btn-default"
                  onClick={() => this.save('SAVE')}
                >
                  {ButtonVariables.SAVE}
                </button>
                <button
                  className="btn-default"
                  onClick={() => this.save('MORE')}
                >
                  {ButtonVariables.SAVE_MORE}
                </button>
              </>
            ) : (
              <button className="btn-default" onClick={this.update}>
                {ButtonVariables.UPDATE}
              </button>
            )}
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientState: state.ClientReducer,
  network: state.NetworkReducer,
  userManagementReducer: state.userManagementReducer,
  facilityReducer: state.FacilityReducer,
});

const mapDispatchToProps = (dispatch) => ({
  clientListAction: () => dispatch(clientList()),
  clearClientState: () => dispatch(clearClientState()),
  userSave: (payload) => dispatch(userSave(payload)),
  editUser: (id, payload) => dispatch(editUser(id, payload)),
  getClientInfo: (values) => dispatch(getClientInfo(values)),
  clearUserData: () => dispatch(clearUserData()),
  fetchFacilityList: () => dispatch(getAllFacilityList()),
  clearFacilityState: () => dispatch(clearFacilityState()),
});

export default Form.create({
  name: 'AddUser',
  onFieldsChange: () => {
    window.dispatchEvent(new Event('form-edit'));
  },
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(AddUserBody)));
