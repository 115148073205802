import React from "react";
import { Icon, Input } from "antd";
import _ from "lodash";
import {
  flowMeasurementDropDownVariables,
  flowMeasurementHeaderVariables,
  METER_LOCATION_DATA,
} from "../../../../../constants/variables";
import { FlowMeasurementMessages } from "../../../../../constants/messages";
import { validatePositiveFloat } from "../../../../../common/methods";
import { newValidateNumber } from "../../../../../libs/validator";
/**
 * header dropdowns
 */
const formFields = (config) => [
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_TYPE,
    key: "meterType",
    type: "dropdownWithAdd",
    data: config && config.meterType ? config.meterType : ["No Data"],
    required: [
      !config.addModalVisible,
      FlowMeasurementMessages.MENDATORY_FIELD,
    ],
    openAddModal: config.openAddModal,
    addMessage: "Add Flow Meter Type",
    change: config.change,
    disabled: !config.isConfigEditable,
  },
  {
    label: flowMeasurementDropDownVariables.METER_LOCATION,
    key: "location",
    type: "dropdown",
    data: METER_LOCATION_DATA,
    change: config.change,
    disabled: !config.isConfigEditable,
  },
  config.streamId !== "2" && config.componentName !== "Gas Turbine"
    ? {
      label: flowMeasurementDropDownVariables.IS_FLOW_MEASUREMENT,
      key: "isFlowMeterAvailable",
      type: "switch",
      change: config.change,
      data: config.isFlowMeterAvailable,
      disabled: !config.isConfigEditable,
    }
    : null,
];

/**
 * rows and columns to be rendered
 * @param {*} config
 */
const tableDataVariables = (config) => [
  {
    title: flowMeasurementHeaderVariables.VARIABLES,
    dataIndex: "variable",
    render: (value) => (
      <span>
        {columnsDataVariablesMap[value]}
        {(columnsDataVariablesMap[value] === "Pipe Inside Diameter (D)") &&
          config.component === "Flow meter" && (
          <span style={{ color: "red" }}> *</span>
        )}
      </span>
    ),
  },
  {
    title: flowMeasurementHeaderVariables.UNITS,
    dataIndex: "unit",
    render: (value, row, col, disabled, item) =>
      item.variable !== "dischargeCoefficient" &&
      item.variable !== "thermalExpansionFactor" &&
      item.variable !== "betaRatio" &&
      item.variable !== "reynoldsNumber" &&
      item.variable !== "expansibilityFactor" &&
      item.variable !== "frictionFactor" ? (
          <div className={`custom-table-select ${disabled ? "beforeEdit" : ""} `}>
            <select
              defaultValue={value}
              value={value || ""}
              disabled={disabled}
              name="unit"
              onChange={(e) => {
                config.change(e.target, row, item);
              }}
              className={
                !value &&
              item.value.toString() !== "" &&
              item.value.toString() !== "NaN"
                  ? "error"
                  : "not-error"
              }
            >
              <option value="">Select</option>
              {dataUnitsOptions(
                config.dataUnits[columnsDataVariablesMap[item.variable]]
              )}
            </select>
          </div>
        ) : (
          <div />
        ),
  },
  {
    title: flowMeasurementHeaderVariables.VALUES,
    dataIndex: "value",
    render: (value, row, _col, disabled, item) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="value"
          defaultValue={value}
          onChange={(e) => {
            config.change(e.target, row, item);
          }}
          disabled={disabled}
          value={value}
          autoComplete="off"
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          className={
            (!_.isEmpty(value) && !validatePositiveFloat(value)) ||
            (item.variable !== "dischargeCoefficient" &&
              item.variable !== "thermalExpansionFactor" &&
              item.variable !== "betaRatio" &&
              item.variable !== "reynoldsNumber" &&
              item.variable !== "expansibilityFactor" &&
              item.variable !== "frictionFactor" &&
              item.unit &&
              _.isEmpty(value)) ||
            (item.variable === "pipeInsideDiameter" &&
              config.addListener &&
              !value) ||
            (item.variable === "throatDiameter" &&
              config.venturiMeterTypeError) ||
            (item.variable === "orificeDiameter" &&
              config.orificeMeterTypeError) ||
            (item.variable === "vconeDiameter" && 
              config.vconeDiameterTypeError) 
              ? "error"
              : "not-error"
            
          }
        />
      );
    },
  },

  config.isConfigEditable && {
    title: flowMeasurementHeaderVariables.ACTIONS,
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
      </div>
    ),
  },
];

/**
 * enum for Flow Meter Table Variables
 */
const columnsDataVariablesMap = {
  dischargeCoefficient: "Discharge coeff (C∞)",
  throatDiameter: "Throat Diameter (d)",
  pipeInsideDiameter: "Pipe Inside Diameter (D)",
  thermalExpansionFactor: "Thermal expansion factor (Fa)",
  orificeDiameter: "Orifice Diameter (do)",
  vconeDiameter: "V-Cone Diameter",
  betaRatio: "Beta Ratio (β)",
  reynoldsNumber: "Reynolds Number (Re)",
  expansibilityFactor: "Expansibility factor (ε)",
  frictionFactor: "Friction Factor (λ)",
  fluidDynamicViscosity: "Fluid Dynamic Viscosity (μ)",
  fluidKinematicViscosity: "Fluid Kinematic Viscosity (ν)",
  fluidDensity: "Fluid Density",
};

/**
 * render drop down for  units
 * @param {array of units} units
 */
const dataUnitsOptions = (units) => {
  if (units) {
    return units.map((item) => <option value={item}>{item}</option>);
  }
  return null;
};

/**
 * initial data for table
 */
const data = {
  variable: {
    dischargeCoefficient: {
      unit: "",
      value: "",
    },
    throatDiameter: {
      unit: "",
      value: "",
    },
    pipeInsideDiameter: {
      unit: "",
      value: "",
    },
    thermalExpansionFactor: {
      unit: "",
      value: "",
    },
  },
};

// initial data for flow meter

const initalFlowMeterData = {
  variable: {
    thermalExpansionFactor: {
      unit: "",
      value: "",
    },
    pipeInsideDiameter: {
      unit: "",
      value: "",
    },
    throatDiameter: {
      unit: "",
      value: "",
    },
    orificeDiameter: {
      unit: "",
      value: "",
    },
    vconeDiameter: {
      unit: "",
      value: "",
    },
    betaRatio: {
      unit: "",
      value: "",
    },
    dischargeCoefficient: {
      unit: "",
      value: "",
    },
    reynoldsNumber: {
      unit: "",
      value: "",
    },
    expansibilityFactor: {
      unit: "",
      value: "",
    },
    frictionFactor: {
      unit: "",
      value: "",
    },
    fluidDynamicViscosity: {
      unit: "",
      value: "",
    },
    fluidKinematicViscosity: {
      unit: "",
      value: "",
    },
    fluidDensity: {
      unit: "",
      value: "",
    },
  },
};

const flowMeterFormFields = (config) => [
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_TYPE,
    key: "meterType",
    type: "dropdownWithAdd",
    data: config && config.meterType ? config.meterType : ["No Data"],
    required: [
      !config.addModalVisible,
      FlowMeasurementMessages.MENDATORY_FIELD,
    ],
    openAddModal: config.openAddModal,
    addMessage: "Add Flow Meter Type",
    change: config.change,
    disabled: !config.isConfigEditable,
  },
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_MANUFACTURER,
    key: "manufacturer",
    type: "dropdownWithAdd",
    data: config && config.manufacturer ? config.manufacturer : ["No Data"],
    openAddModal: config.openAddModal,
    addMessage: "Add Flow Meter Manufacturer",
    disabled: !config.isConfigEditable,
    change: config.enable,
  },
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_MODEL,
    key: "model",
    type: "text",
    disabled: !config.isConfigEditable,
    change: config.enable,
  },
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_SERIAL_NUMBER,
    key: "serialNumber",
    type: "text",
    disabled: !config.isConfigEditable,
    change: config.enable,
  },
  {
    label: flowMeasurementDropDownVariables.FLOW_METER_TAG_NUMBER,
    key: "tagNumber",
    type: "text",
    disabled: !config.isConfigEditable,
    change: config.enable,
  },
];

export {
  data,
  formFields,
  dataUnitsOptions,
  tableDataVariables,
  columnsDataVariablesMap,
  initalFlowMeterData,
  flowMeterFormFields,
};
