import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import * as Routes from "../../constants/routes"
import { useSelector } from 'react-redux'
import Notification, { Types } from "../../common/Notification/Notification";



const Auth0Login = () => {
  let loginState = useSelector((state) => state.LoginReducer.apiState);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    const redirect = async () => {
      await loginWithRedirect({
        appState: {
          returnTo: Routes.callback,
        },
        scope: 'openid profile email offline_access', // Include the 'offline_access' scope
      });
    }
    if (!isAuthenticated) {
      redirect()
    }
    if (
      loginState.isErrorUserRole &&
      loginState.apiMessage === 'User is no longer active'
    ) {
      Notification.show(Types.Error, 'You are not an authorized user.');
    }

  }, [isAuthenticated, loginState])// eslint-disable-line

  return (
    <></>
  );
};

export default Auth0Login;
