import React from "react";
import CustomTable from "../../CustomTable/CustomTable";

function TableView(props) {
  return (
    <div className="table-card">
      <CustomTable />
    </div>
  );
}

export default TableView;
