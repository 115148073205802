import * as LoginTypes from "../Types/Login.types";

const INITIAL_API_STATE = {
  data: null,
  token: null,
  userRole: null,
  userId: null,
  isError: false,
  isSuccess: false,
  isErrorUserRole: false,
  isSuccessUserRole: false,
  errorCode: null,
  apiMessage: "",
  tourTaken: [],
  firstName : null,
  lastName: null
};

const INITIAL_STATE = {
  apiState: {
    ...INITIAL_API_STATE,
  },
  loading: false,
  accessInfo: {},
};

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export default function LoginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LoginTypes.LOGIN_FETCH_SUCCESS:
      const decoded_data = parseJwt(action.payload.headers.authorization);
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          data: decoded_data.data.user,
          userRole: action.payload.headers && decoded_data.data.user.role[0],
          userId: action.payload.headers && decoded_data.data.user._id,
          token: action.payload.headers && action.payload.headers.authorization,
          message: null,
          isSuccess: true,
        },
      };
    case LoginTypes.LOGIN_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LoginTypes.LOGIN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          isError: true,
        },
      };
    case LoginTypes.LOGIN_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          data: state.apiState.data,
          userRole: state.apiState.userRole,
        },
      };
    case LoginTypes.USER_ROLE_FETCH_SUCCESS:
      /** Alert access permission. */
      const adminAlertAccess = { read: true, write: true };
      return {
        ...state,
        loading: false,
        accessInfo: action.payload.body.access
          ? action.payload.body.access
          : adminAlertAccess,
        apiState: {
          ...state.apiState,
          userRole: action.payload.body.roles[0],
          userId: action.payload.body._id,
          isSuccessUserRole: true,
          tourTaken: action.payload.body.tourTaken,
          firstName : action.payload.body.firstName,
          lastName : action.payload.body.lastName
        },
      };
    case LoginTypes.USER_ROLE_FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LoginTypes.USER_ROLE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        apiState: {
          ...state.apiState,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          isErrorUserRole: true,
          apiMessage:action.payload.response && action.payload.response.body.message
        },
      };
    case LoginTypes.USER_ROLE_CLEAR_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
          userRole: state.apiState.userRole,
          userId: state.apiState.userId,
          data: state.apiState.data,
          tourTaken: state.apiState.tourTaken,
        },
      };
    case LoginTypes.TOUR_TAKEN_SUCCESS:
      /** Alert access permission. */
      return {
        ...state,
        loading: false,
      };
    case LoginTypes.TOUR_TAKEN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LoginTypes.TOUR_TAKEN_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
