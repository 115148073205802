import React, { useEffect, useState } from "react";
import './ManagePiTags.scss';
import {  Tabs } from 'antd';
import Loader from "../../../common/Loader";
import { useSelector, useDispatch } from "react-redux";
import FieldCalculatedTags from "./FiledCalculatedTags";
import "./ManageTags.scss"
import { piTagListFetch } from "../../../redux/actions/piTagUpload.action";
import Notification, { Types } from "../../../common/Notification/Notification";
const { TabPane } = Tabs;

const ManageTag = (props) => {
  let tagsData = useSelector((state) => state.piTagReducer.piTagList);
  let deleteTag = useSelector((state) => state.piTagReducer.piTagDelete);
  let uploadTag = useSelector((state) => state.piTagReducer.piTagUpload);
  let tagUpdate = useSelector((data) => data.piTagReducer.tagUpdate);
  let baseTimeUpdate = useSelector((data) => data.piTagReducer.baseTimeUpdate);
  
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(20)
  const [isLoaded] = useState(true)
  const [activeTab, setActiveTab] = useState("1")
  const [totalTagCount, setTotalTagCount] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const payload = { limit: limit, skip: skip }
    if(props && props.facilityId && props.facilityId._id ){
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }, [// eslint-disable-line
    props.facilityId
  ]) 

  useEffect(() => {
    if(searchText.length === 0){
      setSkip(0)
      const payload = { limit: limit, skip: 0 }
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }, [searchText])// eslint-disable-line
  
  useEffect(() => {
    if(searchText.length === 0){
      const payload = { limit: limit, skip: skip }
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
    else if(searchText.length > 0){
      const payload = { limit: limit, skip: skip, keyword: searchText}
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }, [skip])// eslint-disable-line

  useEffect(() => {
    const payload = { limit: limit, skip: skip }
    setSkip(0)
    setLimit(20)
    if(props && props.facilityId && props.facilityId._id && !uploadTag.isLoading && uploadTag.isSuccess){
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }, [// eslint-disable-line
    uploadTag.isLoading
  ])

  useEffect(() => {
    const payload = searchText === "" ? { limit: limit, skip: skip }: {limit: limit, skip: skip, keyword: searchText}
    if(props && props.facilityId && props.facilityId._id && !tagUpdate.isLoading && tagUpdate.isSuccess){
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
    if(tagUpdate.isError && !tagUpdate.isSuccess){
      Notification.show(Types.Error, tagUpdate.apiMessage)
    }
  }, [// eslint-disable-line
    tagUpdate
  ])

  useEffect(() => {
    if(deleteTag && deleteTag.isSuccess && !deleteTag.isError){
      const payload = { limit: limit, skip: skip }
      setSearchText("")
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  },[// eslint-disable-line
    deleteTag
  ])

  useEffect(() => {
    if(tagsData.data && tagsData.data.length > 0){
      setTagList(tagsData.data)
      setTotalTagCount(tagsData.total_count)
    }
    else if(skip >=20 && tagsData.data.length <= 0 && searchText === ""){ 
      setSkip(0)
      const payload = { limit: limit, skip: skip }
      dispatch(piTagListFetch(props.facilityId._id, payload))
      setPageNumber(1)
    }
    else{
      setTagList([])
      setTotalTagCount(0)
    }
  }, [// eslint-disable-line
    tagsData.data
  ])

  useEffect(() => {
    const payload = { limit: limit, skip: skip }
    if(baseTimeUpdate.isSuccess){
      Notification.show(Types.Success, "Updated Successfully")
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }, [baseTimeUpdate])// eslint-disable-line
  
  const onTextChange = (text) => {
    setSearchText(text)
  }

  const onSearch = () => {
    setPageNumber(1)
    setSkip(0)
    const payload = { keyword: searchText, limit: limit, skip: 0 };
    let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/  // eslint-disable-line
    if(format.test(searchText)){
      Notification.show(Types.Error, "Special characters not allowed")
    }
    else if(searchText.trim() === ""){
      Notification.show(Types.Error, "Search text contains empty spaces only")
    }
    else if(searchText.length){
      dispatch(piTagListFetch(props.facilityId._id, payload))
    }
  }

  const changeActiveKey = (key) => {
    setActiveTab(key)
  }
  const TagsPanel = () => {
    return (
      <div className="manageTags">
        <div className="screen-content">
          <div className="content-div">
            <Tabs defaultActiveKey="1" type="card" onChange={changeActiveKey}>
              <TabPane tab="Field Tags" key="1">
                {FieldCalculatedTags(activeTab, tagList, setSkip, totalTagCount, props.equipmentsList, 
                  props.systems, props.FacilityId, props.variablePropertyList, props.variablePropertyUnits,
                  props.handleChange, onTextChange, searchText,onSearch, pageNumber, setPageNumber
                )}
              </TabPane>
              {/* <TabPane tab="Calculated Tags" key="2">
                {FieldCalculatedTags(activeTab)}
              </TabPane> */}
            </Tabs>
          </div>
        </div>
      </div>
    )
  }

  if (isLoaded) {
    return TagsPanel()
  }
  else return <Loader></Loader>
}

export default ManageTag