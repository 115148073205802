export const CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS =
  "CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS";
export const GET_SCHEMATIC_COMPONENT_FETCH_SUCCESS =
  "CREATE_SCHEMATIC_COMPONENT_FETCH_SUCCESS";
export const EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS =
  "EDIT_SCHEMATIC_COMPONENT_FETCH_SUCCESS";
export const DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS =
  "DELETE_SCHEMATIC_COMPONENT_FETCH_SUCCESS";

export const CREATE_SCHEMATIC_FETCH_SUCCESS = "CREATE_SCHEMATIC_FETCH_SUCCESS";
export const GET_SCHEMATIC_FETCH_SUCCESS = "GET_SCHEMATIC_FETCH_SUCCESS";
export const EDIT_SCHEMATIC_FETCH_SUCCESS = "EDIT_SCHEMATIC_FETCH_SUCCESS";
export const VALIDATE_SCHEMATIC_FETCH_SUCCESS =
  "VALIDATE_SCHEMATIC_FETCH_SUCCESS";

export const SCHEMATIC_CLEAR_STATE = "SCHEMATIC_CLEAR_STATE";
export const SCHEMATIC_FETCH_PROGRESS = "SCHEMATIC_FETCH_PROGRESS";
export const SCHEMATIC_FETCH_ERROR = "SCHEMATIC_FETCH_ERROR";

export const SCHEMATIC_COMPONENT_CLEAR_STATE =
  "SCHEMATIC_COMPONENT_CLEAR_STATE";
export const SCHEMATIC_COMPONENT_FETCH_PROGRESS =
  "SCHEMATIC_COMPONENT_FETCH_PROGRESS";
export const SCHEMATIC_COMPONENT_FETCH_ERROR =
  "SCHEMATIC_COMPONENT_FETCH_ERROR";

export const UN_DEPLOY_EQUIPMENT_SUCCESS = "UN_DEPLOY_EQUIPMENT_SUCCESS";
export const UN_DEPLOY_EQUIPMENT_PROGRESS = "UN_DEPLOY_EQUIPMENT_PROGRESS";
export const UN_DEPLOY_EQUIPMENT_ERROR = "UN_DEPLOY_EQUIPMENT_ERROR";
export const UN_DEPLOY_EQUIPMENT_RESET = "UN_DEPLOY_EQUIPMENT_RESET";
