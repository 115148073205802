import Type from "../Types/gasTurbine.types";
import { endPoints } from "../../libs/endpoints";
import { GETRequest, PUTRequest } from "../../api/request";

export function updateGTCurvesData() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_CURVES_DATA_PROGRESS });
  };
}

export function fetchGasTurbineConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_GT_CONFIG_DATA_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.gtConfig}`)
      .then((res) =>
        dispatch({
          type: Type.GET_GT_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.GET_GT_CONFIG_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveDesignData(id, payload) {
  const payload_final = { designData: { ...payload } };
  return function (dispatch) {
    dispatch({ type: Type.ADD_GT_DESIGN_PERF_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.gtDesignPerformance}`,
      payload_final
    )
      .then((res) =>
        dispatch({
          type: Type.ADD_GT_DESIGN_PERF_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.ADD_GT_DESIGN_PERF_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveDesignDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_GT_DESIGN_PERF_DATA_RESET });
  };
}

export function savePerformanceData(id, payload) {
  const payload_final = { performanceData: { ...payload } };

  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_PERF_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.gtDesignPerformance}`,
      payload_final
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_GT_PERF_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_GT_PERF_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function savePerformanceDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_PERF_DATA_RESET });
  };
}

export function saveFluidCompositionData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_FLUID_COMP_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.gtFluidComp}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_GT_FLUID_COMP_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_GT_FLUID_COMP_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveFluidCompositionDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_FLUID_COMP_DATA_RESET });
  };
}

export function saveCurvesData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_CURVES_DATA_PROGRESS });
    PUTRequest(`${endPoints.components}/${id}/${endPoints.gtCurves}`, payload)
      .then((res) =>
        dispatch({
          type: Type.UPDATE_GT_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_GT_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveCurvesDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_GT_CURVES_DATA_RESET });
  };
}

export function updateGTConfigData() {
  return function (dispatch) {
    dispatch({ type: Type.ADD_MOTOR_DESIGN_DATA_RESET });
  };
}
