import React from 'react';
import PropTypes from 'prop-types';
import { Result, Button } from 'antd';
import { deleteIDB } from '../services/indexedDB';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  handleReload = () => {
    deleteIDB();
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Result
          title="Sorry! Something went wrong"
          extra={
            <Button type="primary" key="Reload" onClick={this.handleReload}>
              Reload
            </Button>
          }
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
