import React from 'react';
import {
  Typography,
  Skeleton,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { Markup } from 'interweave';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';


export const Logs = (
  props,
  totalCount,
  currentPage,
  setCurrentPage,
  loading,
  expanded,
  handleSetExpand
) => {
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  
 let naming = {
    equipmentId: 'Equipment',
    dataType: 'Data Type',
    variableProperty: 'Variable Property',
    units: 'Client Unit',
    mechademyUnits: 'Mechademy Unit',
    minThreshold: 'Variable Min Value',
    maxThreshold: 'Variable Max Value',
    multiplier: 'UAD Multiplier',
    adder: 'UAD Adder',
    isIQR: 'Enable UAD',
    isFrozen: 'IS Frozen',
  };

  const renderActionKey = (name) => {
    return (
      <div style={{ marginRight: '1.5vw' }}>
        <div
          style={{
            background: '#ffeab7',
            padding: '3px 7px',
            marginBottom: '5px',
            width: '8vw',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '0.7vw',
          }}
        >
          {name}
        </div>
      </div>
    );
  };

  const renderActionValues = (value, action, marginRight = '0px') => {
    return (
      <div className="test" style={{ width: '11vw', display: 'flex' }}>
        <div
          className={action === 'deleted' ? 'deleted-log' : 'added-log'}
          style={{
            letterSpacing: '1px',
            display: 'inline-block',
            fontSize: '0.7vw',
            justifyContent: 'center',
            flex: '1',
            textAlign: 'center',
          }}
        >
          {value}
        </div>
      </div>
    );
  };

  const renderEquipments = (item, tags) => {
    return (
      <div style={{ display: 'flex' }}>
        {renderActionKey(naming[item])}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags[item].deleted.length > 0 &&
            tags[item].deleted.map((equipment) => {
              return renderActionValues(equipment, 'deleted', '5px');
            })}
          {tags[item].added.length > 0 &&
            tags[item].added.map((equipment) => {
              return renderActionValues(equipment, 'added');
            })}
        </div>
      </div>
    );
  };

  const renderFrozen_UAD = (item, tags) => {
    return (
      <div style={{ display: 'flex' }}>
        {renderActionKey(naming[item])}
        <div style={{ display: 'flex' }}>
          {renderActionValues(
            tags[item].prev === true ? 'True' : 'False',
            'deleted'
          )}

          {renderActionValues(
            tags[item].curr === true ? 'True' : 'False',
            'added'
          )}
        </div>
      </div>
    );
  };

  function LoaderSkeleton() {
    const variants = ['h3', 'h3', 'h3', 'h3', 'h3'];
    return (
      <div>
        {variants.map((variant) => (
          <Typography component="div" key={variant} variant={variant}>
            <Skeleton />
          </Typography>
        ))}
      </div>
    );
  }

  function renderActionHeader(data) {
    let keys = Object.keys(data);
    let headerAction = '';
    if (keys.length === 1) headerAction += naming[keys[0]];
    else if (keys.length === 2) {
      keys.map((item, index) => { // eslint-disable-line
        if (index === 0) headerAction += naming[item] + ', ';
        else headerAction += naming[keys[1]];
      });
    } else {
      let remaining = keys.length - 2;
      headerAction +=
        naming[keys[0]] +
        ', ' +
        naming[keys[1]].split(' ')[0] +
        '... +' +
        remaining +
        ' more.';
    }
    return headerAction;
  }

  if (loading === true)
    return (
      <>
        <Grid container spacing={8}>
          <Grid item xs>
            <LoaderSkeleton />
          </Grid>
        </Grid>
      </>
    );

  return (
    <>
      {props.length === 0 && loading === false ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '30px',
          }}
        >
          No Logs Found.
        </div>
      ) : (
        <div className="modified_logs">
          {props.map((row, index) => (
            <Accordion
              expanded={expanded === index}
              onChange={handleSetExpand(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div style={{ display: 'flex', width: '100%' }}>
                <div
                    style={{
                      minWidth: '9.5vw',
                      fontSize: '0.7vw',
                      color: 'rgba(0, 0, 0, 0.65)',
                    }}
                  >
                    {row.created_by}
                  </div>
                  <div
                    style={{
                      minWidth: '11vw',
                      fontSize: '0.7vw',
                      color: 'rgba(0, 0, 0, 0.65)',
                    }}
                  >
                    {row.created_at
                      ? moment(row.created_at).format('DD-MMM-YYYY,')
                      : ''}
                      <span style={{marginLeft:"5px"}}>
                        {row.created_at
                          ? moment(row.created_at).format('h:mm:ss A')
                          : ''}
                      </span>
                  </div>
                  <div
                    className="total_logs_header"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      fontSize: '0.7vw',

                      padding: '0',
                      overflow: 'hidden',
                    }}
                  >
                    {renderActionHeader(row.action)}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="parent" style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '15px',
                    }}
                  >
                    {Object.keys(row.action).map((item) => {
                      switch (item) {
                        case 'equipmentId':
                          return renderEquipments(item, row.action);

                        case 'isIQR':
                        case 'isFrozen':
                          return renderFrozen_UAD(item, row.action);

                        default:
                          return (
                            <div style={{ display: 'flex' }}>
                              {renderActionKey(naming[item])}
                              <div style={{ display: 'flex' }}>
                                {renderActionValues(
                                  row.action[item].prev === (undefined || null)
                                    ? 'none'
                                    : row.action[item].prev,
                                  'deleted'
                                )}

                                {renderActionValues(
                                  row.action[item].curr,
                                  'added'
                                )}
                              </div>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
                {row.reason !== '' && (
                  <div>
                    {' '}
                    <div
                      className="reason_ctn"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <p
                        style={{
                          margin: '0px',
                          fontSize: '0.7vw',
                          color: 'rgba(0, 0, 0, 0.65)',
                        }}
                      >
                        Reason/Comment :{' '}
                      </p>
                      <div className="hr_line"></div>
                    </div>
                    <p
                      style={{
                        fontSize: '0.7vw',
                        marginTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <Markup content={row.reason} />
                    </p>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

          <Pagination
            count={Math.ceil(totalCount / 100)}
            page={currentPage}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
};
