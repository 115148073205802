export const GET_SCREW_COMPRESSOR_CONFIG_DATA_PROGRESS = 'GET_SCREW_COMPRESSOR_CONFIG_DATA_PROGRESS';
export const GET_SCREW_COMPRESSOR_CONFIG_DATA_SUCCESS = 'GET_SCREW_COMPRESSOR_CONFIG_DATA_SUCCESS';
export const GET_SCREW_COMPRESSOR_CONFIG_DATA_FAILURE = 'GET_SCREW_COMPRESSOR_CONFIG_DATA_FAILURE';
export const GET_SCREW_COMPRESSOR_CONFIG_DATA_RESET = 'GET_SCREW_COMPRESSOR_CONFIG_DATA_RESET';

export const UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_PROGRESS = 'UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_PROGRESS';
export const UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_SUCCESS = 'UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_SUCCESS';
export const UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_FAILURE = 'UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_FAILURE';
export const UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_RESET = 'UPDATE_SCREW_COMPRESSOR_DESIGN_DATA_RESET';

export const UPDATE_SCREW_COMPRESSOR_CURVES_DATA_PROGRESS = 'UPDATE_SCREW_COMPRESSOR_CURVES_DATA_PROGRESS';
export const UPDATE_SCREW_COMPRESSOR_CURVES_DATA_SUCCESS = 'UPDATE_SCREW_COMPRESSOR_CURVES_DATA_SUCCESS';
export const UPDATE_SCREW_COMPRESSOR_CURVES_DATA_FAILURE = 'UPDATE_SCREW_COMPRESSOR_CURVES_DATA_FAILURE';
export const UPDATE_SCREW_COMPRESSOR_CURVES_DATA_RESET = 'UPDATE_SCREW_COMPRESSOR_CURVES_DATA_RESET';

export const RESET_SCREW_COMPRESSOR_REDUCER = 'RESET_SCREW_COMPRESSOR_REDUCER';