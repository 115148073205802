import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, { Types } from '../../../../../../common/Notification/Notification';
import * as Pattern from '../../../../../../constants/pattern';
import {
  addManufacturer,
  clearEquipmentState
} from '../../../../../../redux/actions/equipment.action';
import { saveDrivetypes, saveVarSpeedCurve } from '../../../../../../redux/actions/synchronousGenerator.action';
import CustomTable from './../../../../../../common/CustomTable/CustomTable';
import FormFields from './../../../../../../common/FormFields/FormFields';
import Loader from './../../../../../../common/Loader/Loader';
import {
  CurvesMessages,
  DISCARD_CHANGES, EquipmentMessage, ERROR_MESSAGES, FORM_DISCARD_MSG,



  ManufacturerMessage, NO_INTERNET,

  VariableSpeedCurvesMessages
} from './../../../../../../constants/messages';
import { ButtonVariables } from './../../../../../../constants/variables';
import {
  formFieldsValue, inputFields,
  inputUnits, tableHeader
} from './VariableSpeedCurves.Constants';

class VariableSpeedCurves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: ' ',
      torque: ' ',
      current: ' ',
      powerFactor: ' ',
      power: ' ',
      efficiency: ' ',
      speedUnit: '',
      torqueUnit: '',
      currentUnit: '',
      powerFactorUnit: '',
      powerUnit: '',
      efficiencyUnit: '',
      driveType: '',
      driveManufacturer: '',
      driveModel: '',
      lineSideConverter: '',
      loadSideInverter: '',
      speedControl: '',
      displayData: [],
      copyDisplayData: [],
      editTableRows: [],
      inititalDisplayData: [],
      unitData: [],
      visible: false,
      formEdit: false,
      eventListener: false,
      saveListener: false,
      addModalVisible: false,
      addNewValue: '',
      isDriveType: false,
      isDriveManufacturer: false,
      setInitialData: true,
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });

    window.addEventListener('form-edit-false', e => {
      const { inititalDisplayData, saveListener } = this.state;
      const {
        synchronousGeneratorConfigData: { variableSpeedCurvesData },
        getDriveType,
      } = this.props.synchronousGeneratorReducer;
      const { manufacturerData } = this.props.equipmentState.apiState;

      const tempDriveType =
        getDriveType.data && variableSpeedCurvesData.driveType
          ? getDriveType.data.find(
            item => item._id === variableSpeedCurvesData.driveType
          ).type
          : '';
      const tempDriveManufacturer =
        manufacturerData && variableSpeedCurvesData.driveManufacturer
          ? manufacturerData.items.find(
            item => item._id === variableSpeedCurvesData.driveManufacturer
          ).name
          : '';
      this.setState({
        formEdit: false,
        speed: null,
        torque: null,
        current: null,
        powerFactor: null,
        power: null,
        efficiency: null,
        editTableRows: [],
        eventListener: false,
        saveListener: false,
      });
      if (this.state.formEdit && !saveListener) {
        this.setState({
          driveType: tempDriveType,
          driveManufacturer: tempDriveManufacturer,
          driveModel: variableSpeedCurvesData.driveModel,
          lineSideConverter: variableSpeedCurvesData.lineSideConverter,
          loadSideInverter: variableSpeedCurvesData.loadSideInverter,
          speedControl: variableSpeedCurvesData.speedControl,
          speedUnit: variableSpeedCurvesData.dataUnit.speed,
          torqueUnit: variableSpeedCurvesData.dataUnit.torque,
          currentUnit: variableSpeedCurvesData.dataUnit.current,
          powerFactorUnit: variableSpeedCurvesData.dataUnit.powerFactor,
          powerUnit: variableSpeedCurvesData.dataUnit.power,
          efficiencyUnit: variableSpeedCurvesData.dataUnit.efficiency,
          displayData: [...inititalDisplayData.map(item => ({ ...item }))],
          copyDisplayData: [...inititalDisplayData.map(item => ({ ...item }))],
        });
        this.props.form.setFieldsValue({
          driveType: tempDriveType,
          driveManufacturer: tempDriveManufacturer,
          driveModel: variableSpeedCurvesData.driveModel,
          lineSideConverter: variableSpeedCurvesData.lineSideConverter,
          loadSideInverter: variableSpeedCurvesData.loadSideInverter,
          speedControl: variableSpeedCurvesData.speedControl,
        });
      }
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }
  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  setData() {
    const {
      synchronousGeneratorConfigData: { variableSpeedCurvesData },
    } = this.props.synchronousGeneratorReducer;

    const tempDisplayData = variableSpeedCurvesData.data.map(item => {
      return {
        speed: item['speed'] >= 0 ? item['speed'] : ' ',
        torque: item['torque'] >= 0 ? item['torque'] : ' ',
        current: item['current'] >= 0 ? item['current'] : ' ',
        powerFactor: item['powerFactor'] >= 0 ? item['powerFactor'] : ' ',
        power: item['power'] >= 0 ? item['power'] : ' ',
        efficiency: item['efficiency'] >= 0 ? item['efficiency'] : ' ',
      };
    });

    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
      inititalDisplayData: _.cloneDeep(tempDisplayData),
      speedUnit: variableSpeedCurvesData.dataUnit.speed,
      torqueUnit: variableSpeedCurvesData.dataUnit.torque,
      currentUnit: variableSpeedCurvesData.dataUnit.current,
      powerFactorUnit: variableSpeedCurvesData.dataUnit.powerFactor,
      powerUnit: variableSpeedCurvesData.dataUnit.power,
      efficiencyUnit: variableSpeedCurvesData.dataUnit.efficiency,
      driveModel: variableSpeedCurvesData.driveModel,
      lineSideConverter: variableSpeedCurvesData.lineSideConverter,
      loadSideInverter: variableSpeedCurvesData.loadSideInverter,
      speedControl: variableSpeedCurvesData.speedControl,
    });
    this.props.form.setFieldsValue({
      driveModel: variableSpeedCurvesData.driveModel,
      lineSideConverter: variableSpeedCurvesData.lineSideConverter,
      loadSideInverter: variableSpeedCurvesData.loadSideInverter,
      speedControl: variableSpeedCurvesData.speedControl,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      addVarSpeedCurve,
      synchronousGeneratorConfigData,
      getDriveType,
      addDriveTypes,
    } = this.props.synchronousGeneratorReducer;
    const {
      synchronousGeneratorConfigData: { variableSpeedCurvesData },
    } = this.props.synchronousGeneratorReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;
    const {
      isSuccessGetManufacturer,
      isErrorGetManufacturer,
      isErrorAddManufacturer,
      isSuccessAddManufacturer,
      manufacturerData,
      errorCode,
      message,
    } = this.props.equipmentState.apiState;
    const displayMessage =
      message && message.split('/')[1] ? message && message.split('/')[1] : '';
    if (this.props.activeKey !== prevProps.activeKey) {
      this.setState({
        speedUnit: variableSpeedCurvesData.dataUnit.speed,
        torqueUnit: variableSpeedCurvesData.dataUnit.torque,
        currentUnit: variableSpeedCurvesData.dataUnit.current,
        powerFactorUnit: variableSpeedCurvesData.dataUnit.powerFactor,
        powerUnit: variableSpeedCurvesData.dataUnit.power,
        efficiencyUnit: variableSpeedCurvesData.dataUnit.efficiency,
        displayData: [
          ...this.state.inititalDisplayData.map(item => ({ ...item })),
        ],
        copyDisplayData: [
          ...this.state.inititalDisplayData.map(item => ({ ...item })),
        ],
      });
      const tempDriveType =
        getDriveType.data && variableSpeedCurvesData.driveType
          ? getDriveType.data.find(
            item => item._id === variableSpeedCurvesData.driveType
          ).type
          : '';
      const tempDriveManufacturer =
        manufacturerData && variableSpeedCurvesData.driveManufacturer
          ? manufacturerData.items.find(
            item => item._id === variableSpeedCurvesData.driveManufacturer
          ).name
          : '';
      this.props.form.setFieldsValue({
        driveType: tempDriveType,
        driveManufacturer: tempDriveManufacturer,
      });
      this.setState({
        driveType: tempDriveType,
        driveManufacturer: tempDriveManufacturer,
      });
    }

    if (
      getDriveType.isSuccess &&
      synchronousGeneratorConfigData.isSuccess &&
      isSuccessGetManufacturer &&
      this.state.setInitialData
    ) {
      const tempDriveType =
        getDriveType.data && variableSpeedCurvesData.driveType
          ? getDriveType.data.find(
            item => item._id === variableSpeedCurvesData.driveType
          ).type
          : '';
      const tempDriveManufacturer =
        manufacturerData && variableSpeedCurvesData.driveManufacturer
          ? manufacturerData.items.find(
            item => item._id === variableSpeedCurvesData.driveManufacturer
          ).name
          : '';
      this.props.form.setFieldsValue({
        driveType: tempDriveType,
        driveManufacturer: tempDriveManufacturer,
      });
      this.setState({
        driveType: tempDriveType,
        driveManufacturer: tempDriveManufacturer,
        setInitialData: false,
      });
    }

    if (
      isSuccessAddManufacturer &&
      prevProps.equipmentState.apiState.isSuccessAddManufacturer !==
      isSuccessAddManufacturer
    ) {
      this.setState({
        addModalVisible: false,
        isDriveManufacturer: false,
        driveManufacturer: this.state.addNewValue,
      });
      this.props.form.setFieldsValue(
        {
          driveManufacturer: this.state.addNewValue,
        },
        () => this.enableEditForm()
      );
      Notification.show(
        Types.Success,
        VariableSpeedCurvesMessages.SUCCESS_MANUFACTURER
      );
    }

    if (
      isErrorGetManufacturer &&
      prevProps.equipmentState.apiState.isErrorGetManufacturer !==
      isErrorGetManufacturer
    ) {
      Notification.show(
        Types.Error`${EquipmentMessage[errorCode]} ${displayMessage}`
      );
    }
    if (
      isErrorAddManufacturer &&
      prevProps.equipmentState.apiState.isErrorAddManufacturer !==
      isErrorAddManufacturer
    ) {
      Notification.show(
        Types.Error,
        `${ManufacturerMessage[errorCode]} ${displayMessage}`
      );
      clearEquipmentState();
    }

    if (
      addDriveTypes.isSuccess &&
      addDriveTypes.isSuccess !== prevProps.synchronousGeneratorReducer.addDriveTypes.isSuccess
    ) {
      this.setState({
        addModalVisible: false,
        isDriveType: false,
        driveType: this.state.addNewValue,
      });
      this.props.form.setFieldsValue(
        {
          driveType: this.state.addNewValue,
        },
        () => this.enableEditForm()
      );
      Notification.show(
        Types.Success,
        VariableSpeedCurvesMessages.SUCCESS_DRIVE_TYPE
      );
    }
    if (
      addDriveTypes.isError &&
      addDriveTypes.isError !== prevProps.synchronousGeneratorReducer.addDriveTypes.isError
    ) {
      Notification.show(
        Types.Error,
        VariableSpeedCurvesMessages[addDriveTypes.errorCode]
      );
    }

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
      isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }

    if (
      synchronousGeneratorConfigData.isSuccess &&
      prevProps.synchronousGeneratorReducer.synchronousGeneratorConfigData.isSuccess !==
      synchronousGeneratorConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      addVarSpeedCurve.isSuccess &&
      prevProps.synchronousGeneratorReducer.addVarSpeedCurve.isSuccess !==
      addVarSpeedCurve.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: [],
      });
      Notification.show(Types.Success, VariableSpeedCurvesMessages.SUCCESS);
    }
    if (
      addVarSpeedCurve.isError &&
      prevProps.synchronousGeneratorReducer.addVarSpeedCurve.isError !==
      addVarSpeedCurve.isError
    ) {
      Notification.show(Types.Error, VariableSpeedCurvesMessages.ERROR);
    }

    if (this.props.activeKey !== prevProps.activeKey) {
      this.setData();
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }

  handleChangeTableData = (e, unit) => {
    if (unit) {
      this.setState(
        {
          [e.target.id]: e.target.value,
        },
        () => this.enableEditForm()
      );
    } else {
      this.setState(
        {
          [e.target.id]: parseFloat(e.target.value) === 0 ? e.target.value + '' : parseFloat(e.target.value.replace(/^0+/, '')),
        },
        () => this.enableEditForm()
      );
    }
  };

  handleChangeTableEditData = (e, row) => {
    const { displayData } = this.state;
    const key = e.target.id;
    const value = e.target.value;
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row][key] = parseFloat(value) === 0 ? value + '' : parseFloat(value.replace(/^0+/, ''));
    this.setState(
      {
        displayData: tempDisplayData,
      },
      () => this.enableEditForm()
    );
  };

  handleChangeFieldData = (value, key) => {
    this.enableEditForm();
    let tempValue = value;
    if (key === 'driveModel') {
      tempValue = value.target.value;
    }
    if (key === 'lineSideConverter') {
      tempValue = value.target.value;
    }
    if (key === 'loadSideInverter') {
      tempValue = value.target.value;
    }
    if (key === 'speedControl') {
      tempValue = value.target.value;
    }
    this.setState({
      [key]: tempValue,
    });
  };

  openAddModal = key => {
    let tempDriveType = false;
    let tempDriveManufacturer = false;
    if (key === 'isDriveType') {
      tempDriveType = true;
    } else if (key === 'isDriveManufacturer') {
      tempDriveManufacturer = true;
    }

    this.setState({
      addModalVisible: true,
      isDriveType: tempDriveType,
      isDriveManufacturer: tempDriveManufacturer,
    });
  };

  handleAddCancel = () => {
    this.setState({
      addModalVisible: false,
      addNewValue: null,
    });
  };

  handleAddOk = () => {
    const {
      addNewValue,
      addModalVisible,
      isDriveType,
      isDriveManufacturer,
    } = this.state;
    const { saveDrivetypes, addManufacturer } = this.props;

    if (addModalVisible) {
      this.props.form.validateFields((err, _values) => {
        if (!err) {
          if (this.handleNetwork()) {
            if (
              addNewValue &&
              addNewValue.trim() &&
              addNewValue.trim().length
            ) {
              isDriveType && saveDrivetypes({ type: addNewValue });
              isDriveManufacturer && addManufacturer({ name: addNewValue });
            } else {
              Notification.show(
                Types.Error,
                VariableSpeedCurvesMessages.REQUIRED_FIELD
              );
            }
          }
        }
      });
    }
  };
  setAddNewValue(value) {
    this.setState({ addNewValue: value });
  }

  renderModalAddValue() {
    const { addModalVisible, isDriveType } = this.state;
    return (
      <div className="EditCurrentNode">
        <Modal
          title={
            isDriveType
              ? VariableSpeedCurvesMessages.ADD_DRIVE_TYPE
              : VariableSpeedCurvesMessages.ADD_MAUFACTURER
          }
          visible={addModalVisible}
          onOk={this.handleAddOk}
          onCancel={this.handleAddCancel}
          okText="Save"
          cancelText="Cancel"
        >
          <Form>
            <Form.Item>
              {addModalVisible &&
                this.props.form.getFieldDecorator('name', {
                  rules: [
                    { required: true, message: ERROR_MESSAGES.REQUIRED_FIELD },
                    {
                      pattern: Pattern.Name.regx,
                      message: Pattern.Name.message,
                    },
                  ],
                })(
                  <Input
                    onChange={e => {
                      this.setAddNewValue(e.target.value);
                    }}
                    maxLength={70}
                  />
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  onAdd = () => {
    const {
      displayData,
      copyDisplayData,
      speed,
      torque,
      current,
      powerFactor,
      power,
      efficiency,
    } = this.state;
    this.setState({
      eventListener: true,
    });
    if (
      _.isEmpty(speed) ||
      _.parseInt(speed) === 0 ||
      _.isEmpty(torque) ||
      _.parseInt(torque) === 0 ||
      _.isEmpty(current) ||
      _.parseInt(current) === 0 ||
      _.isEmpty(powerFactor) ||
      _.parseInt(powerFactor) === 0 ||
      _.isEmpty(power) ||
      _.parseInt(power) === 0 ||
      _.isEmpty(efficiency) ||
      _.parseInt(efficiency) === 0
    ) {
      this.setState(
        {
          displayData: [
            ...displayData,
            {
              speed,
              torque,
              current,
              powerFactor,
              power,
              efficiency,
            },
          ],
          copyDisplayData: [
            ...copyDisplayData,
            {
              speed,
              torque,
              current,
              powerFactor,
              power,
              efficiency,
            },
          ],
        },
        () => {
          this.setState(
            {
              speed: ' ',
              torque: ' ',
              current: ' ',
              powerFactor: ' ',
              power: ' ',
              efficiency: ' ',
              eventListener: false,
            },
            () => this.enableEditForm()
          );
        }
      );
    }
    else {
      Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY)
    }
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  onUndo = row => {
    const { displayData, copyDisplayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    tempDisplayData[row] = { ...copyDisplayData[row] };
    const tempEditTableRows = editTableRows.filter(item => item !== row);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditTableRows,
      },
      () => this.enableEditForm()
    );
  };

  onEdit = row => {
    const { editTableRows } = this.state;
    this.setState(
      {
        editTableRows: [...editTableRows, row],
      },
      () => this.enableEditForm()
    );
  };

  onDelete = row => {
    const { displayData, editTableRows } = this.state;
    const tempDisplayData = displayData;
    const tempEditRow = editTableRows.filter(item => item !== row);
    tempDisplayData.splice(row, 1);
    this.setState(
      {
        displayData: tempDisplayData,
        editTableRows: tempEditRow,
      },
      () => this.enableEditForm()
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { copyDisplayData } = this.state;
    const {
      synchronousGeneratorConfigData: { variableSpeedCurvesData },
    } = this.props.synchronousGeneratorReducer;
    this.setState({
      displayData: [...copyDisplayData.map(item => ({ ...item }))],
      speedUnit: variableSpeedCurvesData.dataUnit.speed,
      torqueUnit: variableSpeedCurvesData.dataUnit.torque,
      currentUnit: variableSpeedCurvesData.dataUnit.current,
      powerFactorUnit: variableSpeedCurvesData.dataUnit.powerFactor,
      powerUnit: variableSpeedCurvesData.dataUnit.power,
      efficiencyUnit: variableSpeedCurvesData.dataUnit.efficiency,
      driveType: variableSpeedCurvesData.driveType,
      driveManufacturer: variableSpeedCurvesData.driveManufacturer,
      driveModel: variableSpeedCurvesData.driveModel,
      lineSideConverter: variableSpeedCurvesData.lineSideConverter,
      loadSideInverter: variableSpeedCurvesData.loadSideInverter,
      speedControl: variableSpeedCurvesData.speedControl,
      visible: false,
      editTableRows: [],
    });
    this.disableEditForm();
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  transformPayload = (data) => {
    data.forEach(item => {
      if (!_.isFinite(parseFloat(item['speed']))) {
        delete item['speed']
      }
      else {
        item['speed'] = parseFloat(item['speed'])
      }
      if (!_.isFinite(parseFloat(item['torque']))) {
        delete item['torque']
      }
      else {
        item['torque'] = parseFloat(item['torque'])
      }

      if (!_.isFinite(parseFloat(item['efficiency']))) {
        delete item['efficiency']
      }
      else {
        item['efficiency'] = parseFloat(item['efficiency'])
      }

      if (!_.isFinite(parseFloat(item['power']))) {
        delete item['power']
      }
      else {
        item['power'] = parseFloat(item['power'])
      }

      if (!_.isFinite(parseFloat(item['powerFactor']))) {
        delete item['powerFactor']
      }
      else {
        item['powerFactor'] = parseFloat(item['powerFactor'])
      }

      if (!_.isFinite(parseFloat(item['current']))) {
        delete item['current']
      }
      else {
        item['current'] = parseFloat(item['current'])
      }
    })
    return data
  }

  onSave = () => {
    this.setState({
      saveListener: true,
    });
    const {
      speedUnit,
      torqueUnit,
      currentUnit,
      powerFactorUnit,
      powerUnit,
      efficiencyUnit,
      driveType,
      driveManufacturer,
      driveModel,
      lineSideConverter,
      loadSideInverter,
      speedControl,
      displayData
    } = this.state;
    const { getDriveType } = this.props.synchronousGeneratorReducer;
    const { manufacturerData } = this.props.equipmentState.apiState;
    let totalError = true;
    this.setState({ copyDisplayData: displayData })
    displayData.forEach(item => {
      let error = false;
      if (_.isEmpty(item['speed']) ||
          _.isEmpty(item['torque']) ||
          _.isEmpty(item['current']) ||
          _.isEmpty(item['powerFactor']) ||
          _.isEmpty(item['power']) ||
          _.isEmpty(item['efficiency'])
      ) {
        error = true;
      }
      totalError = totalError && (error === false ? true : error);
    });
    if (
      speedUnit &&
      torqueUnit &&
      currentUnit &&
      powerFactorUnit &&
      powerUnit &&
      efficiencyUnit
    ) {
          if (totalError) {
        if (displayData.length <= 15) {
          const tempDriveType =
            getDriveType && driveType
              ? getDriveType.data.find(item => item.type === driveType)._id
              : '';
          const tempDriveManufacturer =
            manufacturerData && driveManufacturer
              ? manufacturerData.items.find(
                item => item.name === driveManufacturer
              )._id
              : '';
          const payload = {
            dataUnit: {
              speed: speedUnit,
              current: currentUnit,
              torque: torqueUnit,
              powerFactor: powerFactorUnit,
              power: powerUnit,
              efficiency: efficiencyUnit,
            },
            data: this.transformPayload(displayData),
          };
          if (tempDriveType) {
            payload.driveType = tempDriveType;
          }
          if (tempDriveManufacturer) {
            payload.driveManufacturer = tempDriveManufacturer;
          }
          if (driveModel) {
            payload.driveModel = driveModel;
          }
          if (lineSideConverter) {
            payload.lineSideConverter = lineSideConverter
          }
          if (loadSideInverter) {
            payload.loadSideInverter = loadSideInverter
          }
          if (speedControl) {
            payload.speedControl = speedControl
          }
          const {
            location: { state },
            saveVarSpeedCurve,
          } = this.props;
          if (this.handleNetwork() && state && state.componentData.info._id) {
            saveVarSpeedCurve(state.componentData.info._id, payload);
          }
        }
      }
      else
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY)
    }
    else {
      Notification.show(Types.Error, CurvesMessages.SELECT_UNITS)
    }
  };

  render() {
    const {
      eventListener,
      displayData,
      asTestedSurgeLines,
      unitData,
      editTableRows,
      volumetricFlowUnit,
      headUnit,
      speed,
      torque,
      current,
      powerFactor,
      power,
      efficiency,
      driveType,
      driveManufacturer,
      driveModel,
      lineSideConverter,
      loadSideInverter,
      speedControl,
      speedUnit,
      torqueUnit,
      currentUnit,
      powerFactorUnit,
      powerUnit,
      efficiencyUnit,
      saveListener,
      addModalVisible,
    } = this.state;
    const { manufacturerData } = this.props.equipmentState.apiState;
    const { isSchematicEditable } = this.props;
    const {
      synchronousGeneratorConfigData,
      addVarSpeedCurve,
      addDriveTypes,
      getDriveType,
    } = this.props.synchronousGeneratorReducer;
    const { isLoading } = this.props.equipmentState;
    return (
      <div className="VariableSpeedCurves">
        {(synchronousGeneratorConfigData.isLoading ||
          addVarSpeedCurve.isLoading ||
          isLoading ||
          addDriveTypes.isLoading) && <Loader />}
        <div className="form-surge">
          <FormFields
            formFields={formFieldsValue({
              handleChangeFieldData: this.handleChangeFieldData,
              openAddModal: this.openAddModal,
              driveType,
              driveManufacturer,
              driveModel,
              lineSideConverter,
              loadSideInverter,
              speedControl,
              driveTypeList: getDriveType && getDriveType.data,
              addModalVisible,
              driveManufacturerList: manufacturerData && manufacturerData.items,
              isSchematicEditable
            })}
            form={this.props.form}
          />
        </div>
        <div>
          <CustomTable
            showHeader={true}
            columns={tableHeader({
              onEdit: this.onEdit,
              onDelete: this.onDelete,
              onUndo: this.onUndo,
              handleChangeTableEditData: this.handleChangeTableEditData,
              isSchematicEditable
            })}
            data={displayData}
            renderAddRow={inputUnits({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              asTestedSurgeLines,
              volumetricFlowUnit,
              headUnit,
              eventListener,
              speed,
              torque,
              current,
              powerFactor,
              power,
              efficiency,
              unitData,
              speedUnit,
              torqueUnit,
              currentUnit,
              powerFactorUnit,
              powerUnit,
              efficiencyUnit,
              length: displayData.length,
              saveListener,
              isSchematicEditable
            })}
            headerColumns={isSchematicEditable && inputFields({
              handleChangeTableData: this.handleChangeTableData,
              onAdd: this.onAdd,
              asTestedSurgeLines,
              volumetricFlowUnit,
              headUnit,
              eventListener,
              speed,
              torque,
              current,
              powerFactor,
              power,
              efficiency,
              speedUnit,
              torqueUnit,
              currentUnit,
              powerFactorUnit,
              powerUnit,
              efficiencyUnit,
              length: displayData.length,
            })}
            editableRow={editTableRows}
          />
        </div>

        {this.state.formEdit ? (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        ) : null}
        {this.renderModal()}
        {this.renderModalAddValue()}
      </div>
    );
  }
}
VariableSpeedCurves.propTypes = {
  getDataUnits: PropTypes.func,
  synchronousGeneratorConfigData: PropTypes.object,
  addVarSpeedCurve: PropTypes.object,
  location: PropTypes.object,
  saveVarSpeedCurve: PropTypes.func,
  network: PropTypes.object,
  isSchematicEditable: PropTypes.bool,
};

VariableSpeedCurves.defaultProps = {
  isSchematicEditable: true
}
const mapStateToProps = state => {
  return {
    network: state.NetworkReducer,
    dataUnitState: state.DataUnitReducer,
    equipmentState: state.EquipmentReducer,
    synchronousGeneratorReducer: state.synchronousGeneratorReducer
  };
};

const mapsDispatchToProps = dispatch => ({
  saveVarSpeedCurve: (id, payload) => dispatch(saveVarSpeedCurve(id, payload)),
  addManufacturer: payload => dispatch(addManufacturer(payload)),
  saveDrivetypes: (id, payload) => dispatch(saveDrivetypes(id, payload)),
  clearEquipmentState: () => dispatch(clearEquipmentState()),
});

export default Form.create({
  name: 'VariableSpeedCurves',
})(
  connect(mapStateToProps, mapsDispatchToProps)(withRouter(VariableSpeedCurves))
);
