import Type from '../Types/hydraulicVariableSpeedGear.types';
import { endPoints } from '../../libs/endpoints';
import { GETRequest, PUTRequest, DeleteRequest } from '../../api/request';

export function fetchHVSGCurvesData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_HVSG_CURVES_DATA_PROGRESS });
    GETRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearCurves}`
    )
      .then((res) =>
        dispatch({
          type: Type.GET_HVSG_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_HVSG_CURVES_DATA_FAILURE, payload: err })
      );
  };
}

export function saveHVSGCurvesData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_CURVES_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearCurves}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_HVSG_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.UPDATE_HVSG_CURVES_DATA_FAILURE, payload: err })
      );
  };
}

export function deleteHVSGCurvesData(id, curveId, curveTitleKey) {
  return function (dispatch) {
    dispatch({ type: Type.DELETE_HVSG_CURVES_DATA_PROGRESS });
    DeleteRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearCurves}?curveTitle=${curveTitleKey}&curveId=${curveId}`,
    )
      .then((res) =>
        dispatch({
          type: Type.DELETE_HVSG_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.DELETE_HVSG_CURVES_DATA_FAILURE, payload: err })
      );
  };
}

export function fetchHVSGConfigData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_HVSG_CONFIG_DATA_PROGRESS });
    GETRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearConfig}`
    )
      .then((res) =>
        dispatch({
          type: Type.GET_HVSG_CONFIG_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.GET_HVSG_CONFIG_DATA_FAILURE, payload: err })
      );
  };
}
export function saveDesignData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_DESIGN_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearDesign}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_HVSG_DESIGN_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({ type: Type.UPDATE_HVSG_DESIGN_DATA_FAILURE, payload: err })
      );
  };
}

export function savePerformanceData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_PERFORMANCE_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearPerformance}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_HVSG_PERFORMANCE_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_HVSG_PERFORMANCE_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function saveWorkingFluidData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_WORKING_FLUID_DATA_PROGRESS });
    PUTRequest(
      `${endPoints.components}/${id}/${endPoints.hydraulicVariableSpeedGearWorkingFluid}`,
      payload
    )
      .then((res) =>
        dispatch({
          type: Type.UPDATE_HVSG_WORKING_FLUID_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_HVSG_WORKING_FLUID_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function ResetDesignData() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_DESIGN_DATA_RESET });
  };
}

export function ResetPerformanceData() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_PERFORMANCE_DATA_RESET });
  };
}

export function ResetWorkingFluidData() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_WORKING_FLUID_DATA_RESET });
  };
}

export function clearHVSGReducer() {
  return function (dispatch) {
    dispatch({ type: Type.RESET_HVSG_REDUCER });
  };
}
export function clearUpdateCurveDataState() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_HVSG_CURVES_DATA_RESET });
  };
}
export function clearDeleteCurvesState() {
  return function (dispatch) {
    dispatch({ type: Type.DELETE_HVSG_CURVES_DATA_RESET });
  };
}
