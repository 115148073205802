export const root = "/";
export const login = "/login";
export const app = "/app";
export const callback = "/callback";
export const forgotPassword = "/forgotPassword";
export const termsConditions = "/terms-and-conditions";

export const dashboard = `${app}/dashboard`;
export const addDashboardCard = `${app}/aadDashboardCard`;

export const clients = `${app}/clients`;
export const userManagement = `${app}/user-management`;
export const labels = `${app}/labels`;
export const addUser = `${app}/add-user`;
export const resetPassword = "/create-new-password/:token";
export const forgotPasswordReset = "/create-forgot-password/:token";
export const assetLibrary = `${app}/asset-library`;
export const addEquipment = `${app}/add-equipment`;
export const unitProperties = `${app}/unit-properties`;
export const mlAlgorithm = `${app}/ml-algorithm`;
export const setting = `${app}/setting`;
export const schematic = `${app}/system-Schematic`;

export const clientAdd = `${app}/client/add`;
export const clientInfo = `${app}/client-info`;
export const clientFacility = `${clientInfo}/facility`;
export const clientSystem = `${clientInfo}/system`;
export const systemSchematic = `${clientInfo}/system-schematic`;
export const clientEquipment = `${clientInfo}/equipment`;
export const componentSchematic = `${clientInfo}/schematic`;
export const componentInformation = `${clientInfo}/component-info`;
export const manageTags = `${clientInfo}/pi-tags`;

export const dirty = "*";
