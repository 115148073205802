import React, { Component } from 'react';
import './SideMenu.scss';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu, Icon, Button } from 'antd';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { ic_menu_mobile } from '../../assets/ic_menu_mobile';
import * as Device from '../../libs/device';

import ConfirmModal from '../../common/Modal/Modal';
import {
  menuOptions,
  activeTab,
  defaultDisabledMenu,
} from './SideMenu.constants';
import { updateFormEdit } from '../../redux/actions/formEdit.action';

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      page: '0',
      disableMenu: false,
      visible: false,
      demandPage: window.location.pathname,
      disableMenuIcon: false,
    };
  }

  componentDidMount() {
    if (!Device.mobile() && !Device.tab()) {
      this.setState({ collapsed: false });
    }
    window.addEventListener('form-edit', (e) => {
      this.props.updateFormEdit(true);
      this.setState({
        disableMenu: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.props.updateFormEdit(false);
      this.setState({
        disableMenu: false,
      });
    });
    this.checkRoutes(this.props.location);
    this.props.history.listen((e) => {
      this.checkRoutes(e);
    });
  }

  checkRoutes(e) {
    if (defaultDisabledMenu[e.pathname]) {
      this.setState({
        collapsed: true,
        disableMenuIcon: true,
      });
    } else {
      this.setState({
        disableMenuIcon: false,
      });
    }
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  clickedItem(item) {
    if (Device.mobile()) {
      this.toggleCollapsed();
    }
  }

  closeMenu(e) {
    this.setState({
      demandPage: e.key,
    });
    if (Device.mobile() || Device.tab()) {
      this.setState({
        collapsed: true,
      });
    }
  }

  openFormPopup(e) {
    e.preventDefault();
    this.setState({ visible: true });
  }

  renderNavLink(option, index) {
    if (option.hasOwnProperty('active') && option.active()) {
      return (
        <Menu.Item
          className={option.class}
          key={option.path}
          onClick={(e) => {
            this.closeMenu(e);
          }}
        >
          <NavLink
            to={option.path}
            onClick={
              this.state.disableMenu || this.props.isEdit
                ? (e) => this.openFormPopup(e)
                : () => {}
            }
          >
            <Icon type="" />
            <span className="option">{option.label}</span>
          </NavLink>
        </Menu.Item>
      );
    }
    return null;
  }

  getCurrentTab() {
    const currentTab = this.props.location.pathname;
    return activeTab[currentTab] ? activeTab[currentTab] : currentTab;
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    this.setState(
      {
        visible: false,
        disableMenu: false,
      },
      () => {
        this.props.history.push(this.state.demandPage);
        this.props.updateFormEdit(false);
      }
    );
  }

  render() {
    return (
      <div className="sidemenu">
        <Button
          onClick={this.toggleCollapsed}
          className="menu-toggle-button"
          disabled={this.state.disableMenuIcon}
        >
          <img src={ic_menu_mobile} alt="menu-mobile" />
        </Button>
        <div className="content" id="sideMenu">
          <Menu
            selectedKeys={[this.getCurrentTab()]}
            defaultOpenKeys={[this.getCurrentTab()]}
            mode="inline"
            theme="dark"
            inlineCollapsed={this.state.collapsed}
          >
            {menuOptions.map((option, index) =>
              this.renderNavLink(option, index)
            )}
          </Menu>
        </div>
        {this.state.visible ? (
          <ConfirmModal
            visible={this.state.visible}
            handleOk={() => this.handleOk()}
            handleCancel={() => this.handleCancel()}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => dispatch(updateFormEdit(status)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideMenu));
