import React from "react";
import { Icon, Input } from "antd";
import {
  validateNumber,
  newValidateNumber,
} from "../../../../../../../libs/validator";

export const DataUnit = ["Inlet Temperature", "Relative Humidity (Ambient)"];

export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

export const dataUnitsVariables = {
  inlet_temp: "Temperature",
  est_degradation: "Percentage",
};
export const variablesMapper = {
  inlet_temp: "inlet_temp",
  est_degradation: "est_degradation",
};
export const curvesColumnsTitle = {
  inlet_temp: "Inlet Temperature",
  est_degradation: "Estimated Degradation",
};
export const curvesColumnsName = {
  inlet_temp: "inlet_temp",
  est_degradation: "est_degradation",
};
export const graphDetails = {
  title: "Curve",
  xAxis: "Inlet Temperature",
  yAxis: "Estimated Degradation",
  requiredMessage: "Mininum three data is required",
  percentDegradationCurve: "Estimated Degradation Curve",
};

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.inlet_temp,
    dataIndex: curvesColumnsName.inlet_temp,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inlet_temp}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: curvesColumnsTitle.est_degradation,
    dataIndex: curvesColumnsName.est_degradation,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.est_degradation}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          config.onChange(row, col, e.target);
        }}
        disabled={editable}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={!value && value !== 0 ? "error" : "not-error"}
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
export const PercDegradationCurveFormField = (units, config) => [
  {
    label: "Inlet Temperature",
    key: "inlet_temp",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Inlet Temperature")[0],
    data: getUnit(units, "Inlet Temperature"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: "Estimated Degradation",
    key: "est_degradation",
    type: "dropdown",
    required: [true, ""],
    value: getUnit(units, "Relative Humidity (Ambient)")[1],
    data: getUnit(units, "Relative Humidity (Ambient)"),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
  },
];

export const curvesLabel = {
  percentDegradationCurve: "Percentage Degradation Curve",
};
export const createCurvesPayload = (
  formfieldValue,
  estDegradationCurves = []
) => {
  const { ...dataUnit } = formfieldValue;

  const dataEstDegradationCurves = estDegradationCurves.map((item) => {
    const { inlet_temp, est_degradation } = item;
    return {
      inlet_temp: parseFloat(inlet_temp),
      est_degradation: parseFloat(est_degradation),
    };
  });
  const payload = {
    percentDegradationCurve: {
      dataUnit,
      data: dataEstDegradationCurves,
    },
  };
  return payload;
};

export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.inlet_temp,
    dataIndex: curvesColumnsName.inlet_temp,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.inlet_temp}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.inlet_temp] ? "error" : "not-error"
        }
      />
    ),
  },
  {
    title: curvesColumnsTitle.est_degradation,
    dataIndex: curvesColumnsName.est_degradation,
    render: (value, row, col, editable) => (
      <Input
        name={curvesColumnsName.est_degradation}
        defaultValue={value}
        value={value}
        type="number"
        onChange={(e) => {
          actions.onChange(row, col, e.target);
        }}
        onKeyDown={(e) => {
          validateNumber(e);
        }}
        className={
          actions.isError[curvesColumnsName.est_degradation]
            ? "error"
            : "not-error"
        }
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, editable) => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];
