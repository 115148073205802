import * as Types from "../Types/reciprocatingCompressorTypes"
import { endPoints }  from "../../libs/endpoints";
import {
    GETRequest,
    PUTRequest,
} from "../../api/request";

export const updateDesignData = (id, payload) => (dispatch) => {
    dispatch({ type: Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_PROGRESS });
    PUTRequest(`components/${id}/rcconfigs/designData`, payload)
        .then((res) =>
            dispatch({ type: Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_SUCCESS, payload: res.body })
        )
        .catch((err) =>
            dispatch({ type: Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_FAILURE, payload: err })
        );
};

export const clearUpdateState = () => (dispatch) => {
  dispatch({ type: Types.UPDATE_RECIPROCATING_COMPRESSOR_DESIGN_DATA_RESET });
};

export const fetchRecipCompressorConfigData = (id) => (dispatch) => {
      dispatch({ type: Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_PROGRESS });
      GETRequest(`${endPoints.components}/${id}/${endPoints.reciprocatingCompressorConfig}`)
        .then((res) => 
            dispatch({
                type: Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_SUCCESS,
                payload: res.body,
              })
            )
        .catch((err) =>
          dispatch({
            type: Types.GET_RECIPROCATING_COMPRESSOR_CONFIG_DATA_SUCCESS,
            payload: err,
          })
        );
}
