import _ from "lodash"
import Notification, { Types } from "../../../../../../../common/Notification/Notification"
import { CsvValidationMessage } from "../../../../../../../constants/messages"

const validationKeysAdiabaticEfficiency = ["Slide Valve Position - As Tested", "Adiabatic Efficiency -  As Tested",
"Slide Valve Position - Operational", "Adiabatic Efficiency - Operational" ,  ]
const validationKeysVolumetricRatio = ["Suction Slide Valve Position - As Tested", "Suction Slide Valve Position - Operational",
    "Volumetric Ratio Multiplier - As Tested", "Volumetric Ratio Multiplier - Operational"]
const validationKeysCapacityControl = ["Discharge Slide Valve Position - As Tested", "Discharge Slide Valve Position - Operational",
    "Volumetric Capacity Multiplier - As Tested", "Volumetric Capacity Multiplier - Operational"]

const vaildateHeader = (data, keys) => {
    let validation = true
    if (data[0]) {
        keys.forEach((key) => {
            let value = data[0][String(key)];
            if (value === null || value === undefined) {
                validation = false
            }
        })
    }
    return validation
}

const validateFields = (data, props) => {
    let asTestedPayload = [],
        operationalPayload = []
    if (data.length > 20) {
        Notification.show(Types.Error, CsvValidationMessage.MAX_POINT_ERROR)
        return { asTestedPayload, operationalPayload }
    }
    else if (data.length < 3) {
        Notification.show(Types.Error, CsvValidationMessage.MIN_POINT_ERROR)
        return { asTestedPayload, operationalPayload }
    }
    if (props.graphName === "adiabaticEfficiency") {
        let curveDataSchema = {
            slideValvePosition: "",
            adiabaticEfficiency: ""
        },
            validation = vaildateHeader(data, validationKeysAdiabaticEfficiency)
        if (validation) {
            data.forEach((value) => {
                let payloadAstested = _.cloneDeep(curveDataSchema),
                    payloadOperational = _.cloneDeep(curveDataSchema)
                payloadAstested["adiabaticEfficiency"] = String(Math.abs(value["Adiabatic Efficiency -  As Tested"]))
                payloadAstested["slideValvePosition"] = String(Math.abs(value["Slide Valve Position - As Tested"]))
                payloadOperational["adiabaticEfficiency"] = String(Math.abs(value["Adiabatic Efficiency - Operational"]))
                payloadOperational["slideValvePosition"] = String(Math.abs(value["Slide Valve Position - Operational"]))
                asTestedPayload.push(payloadAstested)
                operationalPayload.push(payloadOperational)
            })
        }
        else {
            Notification.show(Types.Error, CsvValidationMessage.BAD_HEADER_DATA)
            return { asTestedPayload, operationalPayload }
        }

    }
    else if(props.graphName ==="volumetricRatio"){
        let curveDataSchema = {
            suctionSlideValvePosition: "",
            volumetricRatioMultiplier: ""
        },
        validation = vaildateHeader(data, validationKeysVolumetricRatio)
        if (validation) {
            data.forEach((value) => {
                let payloadAstested = _.cloneDeep(curveDataSchema),
                    payloadOperational = _.cloneDeep(curveDataSchema)
                payloadAstested["suctionSlideValvePosition"] = String(Math.abs(value["Suction Slide Valve Position - As Tested"]))
                payloadAstested["volumetricRatioMultiplier"] = String(Math.abs(value["Volumetric Ratio Multiplier - As Tested"]))
                payloadOperational["suctionSlideValvePosition"] = String(Math.abs(value["Suction Slide Valve Position - Operational"]))
                payloadOperational["volumetricRatioMultiplier"] = String(Math.abs(value["Volumetric Ratio Multiplier - Operational"]))
                asTestedPayload.push(payloadAstested)
                operationalPayload.push(payloadOperational)
            })
        }
        else {
            Notification.show(Types.Error, CsvValidationMessage.BAD_HEADER_DATA)
            return { asTestedPayload, operationalPayload }
        }
    }
    else if(props.graphName ==="capacityControl"){
        let curveDataSchema = {
            dischargeSlideValvePosition: "",
            volumetricCapacityMultiplier: ""
        },
        validation = vaildateHeader(data, validationKeysCapacityControl)
        if (validation) {
            data.forEach((value) => {
                let payloadAstested = _.cloneDeep(curveDataSchema),
                    payloadOperational = _.cloneDeep(curveDataSchema)
                payloadAstested["dischargeSlideValvePosition"] = String(Math.abs(value["Discharge Slide Valve Position - As Tested"]))
                payloadAstested["volumetricCapacityMultiplier"] = String(Math.abs(value["Volumetric Capacity Multiplier - As Tested"]))
                payloadOperational["dischargeSlideValvePosition"] = String(Math.abs(value["Discharge Slide Valve Position - Operational"]))
                payloadOperational["volumetricCapacityMultiplier"] = String(Math.abs(value["Volumetric Capacity Multiplier - Operational"]))
                asTestedPayload.push(payloadAstested)
                operationalPayload.push(payloadOperational)
            })
        }
        else {
            Notification.show(Types.Error, CsvValidationMessage.BAD_HEADER_DATA)
            return { asTestedPayload, operationalPayload }
        }
    }
    return { asTestedPayload, operationalPayload }
    
}

export default validateFields