import { Card, CardContent } from '@mui/material';
import React from 'react';
import DiscussionThread from '../../DiscussionThread/DiscussionThread';
import RenderHeader from './RenderHeader';
import './RenderDiscussion.scss'
const RenderDiscussion = (data) => {
    return(
        <>
            {RenderHeader("Discussion Thread")}
            <Card>
                <CardContent style={{padding:'0px'}}>
                <DiscussionThread
                    alert={data._id} //eslint-disable-line
                />
                </CardContent>
            </Card>
        </>
    )
}

export default RenderDiscussion