import {
  ASSOCIATIONS_FETCH_SUCCESS,
  DATAVARIBLES_FETCH_SUCCESS,
  PROPERTIES_FETCH_SUCCESS,
  UNITSPROPERTIES_CLEAR_STATE,
  UNITSPROPERTIES_FETCH_ERROR,
  UNITSPROPERTIES_FETCH_SUCCESS,
  UNITS_FETCH_SUCCESS,
  UPLOAD_DATAVARIABLES_FAILURE,
  UPLOAD_DATAVARIABLES_PROGRESS,
  UPLOAD_DATAVARIABLES_SUCCESS,
  UPLOAD_UNITCONVERSION_FAILURE,
  UPLOAD_UNITCONVERSION_PROGRESS,
  UPLOAD_UNITCONVERSION_SUCCESS,
} from "../Types/unitsProperties";

const unitConversion = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: null,
  msg: "",
};

const dataVariable = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  errorCode: null,
  msg: "",
};

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  association: null,
  dataVariables: null,
  properties: null,
  units: null,
  errorCode: null,
  unitConversion: { ...unitConversion },
  dataVariable: { ...dataVariable },
};

export default function unitsProperties(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UNITSPROPERTIES_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case UNITSPROPERTIES_FETCH_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false,
        errorCode:
          action.payload && action.payload.body && action.payload.body.code,
      };
    case ASSOCIATIONS_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        isFetching: false,
        association: action.payload,
      };
    case UNITS_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        isFetching: false,
        units: action.payload,
      };
    case PROPERTIES_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        isFetching: false,
        properties: action.payload,
      };
    case DATAVARIBLES_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        isFetching: false,
        dataVariables: action.payload,
      };
    case UNITSPROPERTIES_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    case UPLOAD_DATAVARIABLES_PROGRESS:
      return {
        ...state,
        dataVariable: {
          ...INITIAL_STATE.dataVariable,
          isLoading: true,
        },
      };
    case UPLOAD_DATAVARIABLES_SUCCESS:
      return {
        ...state,
        dataVariable: {
          ...state.dataVariable,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case UPLOAD_DATAVARIABLES_FAILURE:
      return {
        ...state,
        dataVariable: {
          ...state.dataVariable,
          isLoading: false,
          isError: true,
          errorCode: action.payload && action.payload.body.code,
          msg: action.payload && action.payload.body.message,
        },
      };

    case UPLOAD_UNITCONVERSION_PROGRESS:
      return {
        ...state,
        unitConversion: {
          ...INITIAL_STATE.unitConversion,
          isLoading: true,
        },
      };
    case UPLOAD_UNITCONVERSION_SUCCESS:
      return {
        ...state,
        unitConversion: {
          ...state.unitConversion,
          isLoading: false,
          isSuccess: true,
          data: action.payload,
        },
      };
    case UPLOAD_UNITCONVERSION_FAILURE:
      return {
        ...state,
        unitConversion: {
          ...state.unitConversion,
          isLoading: false,
          isError: true,
          errorCode: action.payload && action.payload.body.code,
          msg: action.payload && action.payload.body.message,
        },
      };
    default:
      return { ...state };
  }
}
