import React, { Component } from 'react';
import { Tabs } from 'antd';
import './Alerts.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AllAlerts from './AllAlerts/AllAlerts';
//import AlertDetails from './AlertsDetails/AlertDetails';
import AlertDetails from './AlertsDetails/NewAlertDetails';
import { ALERT_TABS, SORT_ORDER } from '../../../constants/variables';

const { TabPane } = Tabs;

class Alerts extends Component {
  constructor(props) {
    super(props);
    const { info } = this.props;
    this.state = {
      isOrderActive: false,
      order: SORT_ORDER.descending,
      alertType: Object.keys(ALERT_TABS)[4],
      alertDetail: false,
      alertDetailData: {},
      sideTab: {
        _id: info.dataRef._id, //eslint-disable-line
        levelname: info.type,
      },
    };
  }

  componentDidMount() {
    const { info } = this.props;
    this.setState({
      alertType: Object.keys(ALERT_TABS)[4],
      sideTab: {
        _id: info.dataRef._id //eslint-disable-line
          ? info.dataRef._id //eslint-disable-line
          : info.dataRef.info._id, //eslint-disable-line
        levelname: info.type,
      },
    });
    if (localStorage.getItem('alertSortOrder')) {
      const sort = JSON.parse(localStorage.getItem('alertSortOrder'));
      this.setState({
        order: sort.order,
        isOrderActive: sort.isOrderActive,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { info } = this.props;
    if (prevProps.info !== info) {
      this.handleAlertDetail(false, {});
    }
  }

  handleAlertChange = (value) => {
    const { info } = this.props;
    this.setState({
      alertType: value,
      sideTab: {
        _id: info.dataRef._id //eslint-disable-line
          ? info.dataRef._id //eslint-disable-line
          : info.dataRef.info._id, //eslint-disable-line
        levelname: info.type,
      },
    });
    localStorage.removeItem('alertPage');
  };

  handleAlertDetail = (value, item) => {
    this.setState({ alertDetail: value, alertDetailData: item });
  };

  handleSort = (value) => {
    localStorage.setItem(
      'alertSortOrder',
      JSON.stringify({
        order: value,
        isOrderActive: true,
      })
    );
    this.setState({
      order: value,
      isOrderActive: true,
    });
  };

  render() {
    const {
      order,
      alertType,
      alertDetailData,
      alertDetail,
      isOrderActive,
      sideTab,
    } = this.state;

    const { info, accessInfo } = this.props;
    return alertDetail ? (
      // <AlertDetails
      //   data={alertDetailData}
      //   levelDetails={sideTab}
      //   editAlertDetials={this.handleAlertDetail}
      //   equipmentData={info.equipmentList}
      //   accessInfo={accessInfo}
      //   info={info}
      // />
      <AlertDetails 
        data={alertDetailData}
        levelDetails={sideTab}
        editAlertDetials={this.handleAlertDetail}
        equipmentData={info.equipmentList}
        accessInfo={accessInfo}
        info={info}
      />
    ) : (
      <div className="Alerts">
        <div className="tab-type-button">
          <Tabs
            defaultActiveKey={alertType}
            onChange={(value) => this.handleAlertChange(value)}
          >
            <TabPane tab={ALERT_TABS.New} key={Object.keys(ALERT_TABS)[4]}>
              {alertType === Object.keys(ALERT_TABS)[4] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
            <TabPane tab={ALERT_TABS.MyAlerts} key={Object.keys(ALERT_TABS)[5]}>
              {alertType === Object.keys(ALERT_TABS)[5] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
            <TabPane tab={ALERT_TABS.Active} key={Object.keys(ALERT_TABS)[1]}>
              {alertType === Object.keys(ALERT_TABS)[1] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
            <TabPane tab={ALERT_TABS.Bypassed} key={Object.keys(ALERT_TABS)[2]}>
              {alertType === Object.keys(ALERT_TABS)[2] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
            <TabPane tab={ALERT_TABS.Inactive} key={Object.keys(ALERT_TABS)[3]}>
              {alertType === Object.keys(ALERT_TABS)[3] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
            <TabPane tab={ALERT_TABS.All} key={Object.keys(ALERT_TABS)[0]}>
              {alertType === Object.keys(ALERT_TABS)[0] && (
                <AllAlerts
                  order={order}
                  isOrderActive={isOrderActive}
                  alertProps={{ alertType }}
                  info={info}
                  editAlertDetials={this.handleAlertDetail}
                  handleSort={this.handleSort}
                  accessInfo={accessInfo}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

Alerts.propTypes = {
  info: PropTypes.shape({
    type: PropTypes.string,
    equipmentList: PropTypes.shape({}).isRequired,
  }).isRequired,
  accessInfo: PropTypes.shape({}).isRequired,
};
Alerts.defaultProps = {};

export default withRouter(Alerts);
