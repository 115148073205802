import { Icon, Input, Select } from "antd";
import React from "react";
import { handleSort } from "../../../../common/methods";
import {
  ButtonVariables,
  CalculatedDataVariables,
} from "../../../../constants/variables";
import { newValidateNumber } from "../../../../libs/validator";

const { Option } = Select;

export const calculatedTableColumns = (actions) => [
  {
    title: CalculatedDataVariables.TAG,
    dataIndex: "displayPiTag",
    render: (value, _row, _col) => <span>{value}</span>,
  },
  {
    title: CalculatedDataVariables.VARIABLE_NAME,
    dataIndex: "variableName",
    render: (value, _row, _col) => <span>{value}</span>,
  },
  {
    title: CalculatedDataVariables.VARIABLE_PROPERTY,
    dataIndex: "variableProperty",
    render: (value, _row, _col) => <span>{value}</span>,
  },
  {
    title: CalculatedDataVariables.UNITS,
    dataIndex: "units",
    render: (value, _row, _col) => <span>{value}</span>,
  },
  {
    title: CalculatedDataVariables.MIN,
    dataIndex: "minBound",
    render: (value, row, _col, disabled, item) => (
      <Input
        value={value}
        name="minBound"
        onKeyDown={(e) => newValidateNumber(e)}
        disabled={disabled}
        onChange={(e) => {
          actions.change(e.target, row);
        }}
        className={
          !value || (value && parseFloat(item.maxBound) <= parseFloat(value))
            ? "error"
            : "no-error"
        }
      />
    ),
  },
  {
    title: CalculatedDataVariables.MAX,
    dataIndex: "maxBound",
    render: (value, row, _col, disabled, item) => (
      <Input
        value={value}
        name="maxBound"
        disabled={disabled}
        onKeyDown={(e) => newValidateNumber(e)}
        onChange={(e) => {
          actions.change(e.target, row);
        }}
        className={
          !value || (value && parseFloat(item.minBound) >= parseFloat(value))
            ? "error"
            : "no-error"
        }
      />
    ),
  },
  actions.isCalculatedEditable && {
    title: CalculatedDataVariables.ACTIONS,
    dataIndex: "acitons",
    render: (value, row, _col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={(e) => {
              actions.edit(row);
            }}
          >
            <Icon type="edit" />{" "}
          </button>
        ) : (
          <button
            onClick={(e) => {
              actions.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        )}
        <button
          onClick={(e) => {
            actions.delete(row);
          }}
        >
          <Icon type="delete" />
        </button>
      </div>
    ),
  },
];

export const addRowColumns = (config) => [
  {
    dataIndex: "displayPiTag",
    render: (value) => (
      <Input
        placeholder={`${CalculatedDataVariables.TAG}Number`}
        disabled
        name="displayPiTag"
        value={value}
      />
    ),
  },
  {
    dataIndex: "variableName",

    render: (value) => {
      const tempList = [];
      config.variableNameData &&
        config.variableNameData.forEach((item) =>
          tempList.push(item.variableName)
        );

      return (
        <div
          className={`custom-table-Select ${
            config.error.variableName && (!value || !value.length)
              ? "error"
              : "not-error"
          }
           `}
        >
          <Select
            name="variableName"
            onSelect={(value) => {
              config.onSelectVariableName(value);
            }}
            showArrow={false}
            showSearch
            value={!value ? CalculatedDataVariables.SEARCH_HERE : value}
            className="no-search"
            disabled={config.disabled}
          >
            {handleSort(tempList).map((item) => (
              <Option title={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      );
    },
  },
  {
    dataIndex: "variableProperty",
  },
  {
    dataIndex: "units",
  },
  {
    dataIndex: "minBound",
    render: (value) => (
      <Input
        name="minBound"
        value={value}
        onChange={(e) => {
          config.change(e.target);
        }}
        placeholder={CalculatedDataVariables.MIN}
        disabled={config.disabled}
        onKeyDown={(e) => newValidateNumber(e)}
        className={config.error.minBound ? "error" : "no-error"}
      />
    ),
  },
  {
    dataIndex: "maxBound",
    render: (value) => (
      <Input
        name="maxBound"
        value={value}
        onChange={(e) => {
          config.change(e.target);
        }}
        placeholder={CalculatedDataVariables.MAX}
        onKeyDown={(e) => newValidateNumber(e)}
        disabled={config.disabled}
        className={config.error.maxBound ? "error" : "no-error"}
      />
    ),
  },
  {
    dataIndex: "actions",
    render: () => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            config.submit(e);
          }}
          disabled={config.disabled}
        >
          {" "}
          {ButtonVariables.ADD}{" "}
        </button>{" "}
      </div>
    ),
  },
];

export const variablePropertyData = ["Percentage"];
export const units = ["%", "%/100"];
