import { Icon, Input } from 'antd';
import React from 'react';
import { validatePositiveNumber } from '../../../../../../libs/validator';
import { hvsgWorkingFluidVariables } from '../../../../../../constants/variables';

export const keyChange = {
  [hvsgWorkingFluidVariables.workingFluidType]: 'workingFluidType',
  [hvsgWorkingFluidVariables.workingFluidISO]: 'workingFluidISO',
  [hvsgWorkingFluidVariables.workingFluidViscosity]: 'workingFluidViscosity',
  [hvsgWorkingFluidVariables.workingFluidCP]: 'workingFluidCP',
  [hvsgWorkingFluidVariables.fluidSpecificGravity]: 'fluidSpecificGravity',
};

// fields with units are the same fields which require number input and which are mandotry as well
export const fieldsWithUnits = [
  hvsgWorkingFluidVariables.workingFluidViscosity,
  hvsgWorkingFluidVariables.workingFluidCP,
  hvsgWorkingFluidVariables.fluidSpecificGravity,
];

export const initialData = Object.values(hvsgWorkingFluidVariables).map(
  (item) => {
    if (fieldsWithUnits.includes(item.name)) {
      return {
        name: item,
        design: parseFloat(''),
        unit: null,
      };
    }
    return {
      name: item,
      design: '',
    };
  }
);

const getStringInputDesign = (item, disabled, config) => {
  const designError = !!(
    config.dirtyValues.indexOf(item.name) >= 0 &&
    (item.design === '' || !item.design) &&
    config.saveListener
  );

  return (
    <div className>
      <Input
        type="text"
        value={item.design}
        disabled={disabled}
        AutoComplete="off"
        onChange={(e) => config.handleChange(e.target.value, item.name, 'text')}
        className={designError ? 'error' : 'not-error'}
      />
    </div>
  );
};

const getNumberInputDesign = (item, disabled, config) => {
  const tempError = !!(
    config.dirtyValues.indexOf(item.name) >= 0 &&
    (item.design === '' || !item.design) &&
    config.saveListener
  );

  return (
    <div>
      <Input
        type="number"
        value={item.design}
        disabled={disabled}
        AutoComplete="off"
        onKeyDown={(e) => {
          validatePositiveNumber(e);
        }}
        onChange={(e) =>
          config.handleChange(e.target.value, item.name, 'number')
        }
        className={tempError ? 'error' : 'not-error'}
      />
    </div>
  );
};

const getWorkingFluidDataUnits = (item, disabled, config) => {
  const unitError = !!(
    config.dirtyUnits.indexOf(item.name) >= 0 &&
    (item.unit === '' ||
      item.unit === undefined ||
      item.unit === null ||
      item.unit === 'Select') &&
    config.saveFlag
  );
  return (
    <div
      className={`custom-table-select ${disabled ? 'beforeEdit' : ''} ${
        unitError ? 'error' : 'not-error'
      }  `}
    >
      <select
        value={!item.unit ? '' : item.unit}
        disabled={disabled}
        onChange={(e) => config.handleChange(e.target.value, item.name, 'unit')}
      >
        <option value="Select">Select</option>
        {config.unitData.map((getUnits) => {
          if (getUnits.dataVariable === item.name) {
            return getUnits.units.map((dispUnit) => (
              <option value={dispUnit}>{dispUnit}</option>
            ));
          }
          return undefined;
        })}
      </select>
    </div>
  );
};

export const hvsgWorkingFluidDataTable = (config) => [
  {
    title: 'Data',
    dataIndex: 'name',
    render: (value, row, col, disabled, item) => {
      return (
        <div>
          {value}{' '}
          {fieldsWithUnits.includes(item.name) ? (
            <span className="mandatory">*</span>
          ) : (
            ''
          )}
        </div>
      );
    },
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    render: (value, row, col, disabled, item) => {
      if (fieldsWithUnits.includes(item.name)) {
        return getWorkingFluidDataUnits(item, disabled, config);
      }
    },
  },
  {
    title: 'Design',
    dataIndex: 'design',
    render: (value, row, col, disabled, item) => {
      if (!fieldsWithUnits.includes(item.name)) {
        return getStringInputDesign(item, disabled, config);
      }
      return getNumberInputDesign(item, disabled, config);
    },
  },
  config.isSchematicEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const hvsgWorkingFluidData = (displayData) => {
  return displayData.filter((item) => {
    if (Object.values(hvsgWorkingFluidVariables).includes(item.name)) {
      return item;
    }
    return undefined;
  });
};

export const validateData = (displayData, setDirtyValues, setDirtyUnits) => {
  let mandatoryCheckError = false;
  let unitCheckError = false;
  let dirtyUnits = [];
  let dirtyRows = [];
  displayData.forEach((item) => {
    // adding checks only for required fields
    if (fieldsWithUnits.includes(item.name)) {
      if (
        !item.design ||
        item.design === 'Select' ||
        (typeof item.design != 'string' && isNaN(item.design))
      ) {
        mandatoryCheckError = true;
        dirtyRows.push(item.name);
      }

      if (!item.unit || item.unit === 'Select') {
        dirtyUnits.push(item.name);
        unitCheckError = true;
      }
    }
  });
  setDirtyValues(dirtyRows);
  setDirtyUnits(dirtyUnits);

  return mandatoryCheckError || unitCheckError;
};
