import  {
    FETCH_TRANSIENT_DATA_PROGRESS,
    FETCH_TRANSIENT_DATA_SUCCESS,
    FETCH_TRANSIENT_DATA_ERROR,
    FETCH_VARIABLE_TAGS_PROGRESS,
    FETCH_VARIABLE_TAGS_SUCCESS,
    FETCH_VARIABLE_TAGS_ERROR,
    CLEAR_STATE,
    ADD_TRANSIENT_DATA_PROGRESS,
    ADD_TRANSIENT_DATA_SUCCESS,
    ADD_TRANSIENT_DATA_ERROR,
} from "../Types/transientData.types";


const INITIAL_STATE = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorCode: null,
    errorMsg: null,
    transientData: [],
    variableTags: [],
    isDataAddedProgress: false,
    isDataAddedSuccess: false,
    isDataAddedError: false,
    isTransientDataFetchSuccess: false,
}


const transientDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type)    {
        case FETCH_TRANSIENT_DATA_PROGRESS:
            return  {
                ...state,
                isLoading: true
            }
        case FETCH_TRANSIENT_DATA_SUCCESS:
            return  {
                ...state,
                isTransientDataFetchSuccess: true,
                transientData: action?.payload
            }
        case FETCH_TRANSIENT_DATA_ERROR:
            return {
                ...state,
                isError: true,
                errorMsg: action.payload
            }
        case FETCH_VARIABLE_TAGS_PROGRESS:
            return  {
                ...state,
                isLoading: true
            }
        case FETCH_VARIABLE_TAGS_SUCCESS:
            return  {
                ...state,
                isSuccess: true,
                variableTags: action?.payload?.items
            }
        case FETCH_VARIABLE_TAGS_ERROR:
            return {
                ...state,
                isError: true,
                errorMsg: action.payload
            }
        case ADD_TRANSIENT_DATA_PROGRESS:
            return  {
                ...state,
                isDataAddedProgress: true
            }
        case ADD_TRANSIENT_DATA_SUCCESS:
            return  {
                ...state,
                isDataAddedSuccess: true,
                transientData: action?.payload
            }
        case ADD_TRANSIENT_DATA_ERROR:
            return  {
                ...state,
                isDataAddedError: true,
                errorMsg: action.payload.response.body.message,
            }
        case CLEAR_STATE:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: false,
                errorCode: null,
                errorMsg: null,
                isDataAddedSuccess: false,
                isTransientDataFetchSuccess: false,
                isDataAddedError: false
            }
        default:
            return  {
                ...state
            }
    }
}

export default transientDataReducer;