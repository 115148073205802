import { Breadcrumb, Button, Form, Input } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import ConfirmModal from "../../../common/Modal/Modal";
import Notification, { Types } from "../../../common/Notification/Notification";
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  NO_INTERNET,
  systemMessages,
} from "../../../constants/messages";
import * as Pattern from "../../../constants/pattern";
import { MaskStyle } from "../../../constants/react-style";
import * as Routes from "../../../constants/routes";
import {
  ButtonVariables,
  ClientFacilityVariables,
  systemLableVariables,
  systemVariables,
} from "../../../constants/variables";
import { elementsGaurd } from "../../../gaurds";
import { generateCode } from "../../../libs/codeGenerators";
import { ElementPermissions } from "../../../permissions";
import {
  addSystem,
  clearSystemState,
  editSystem,
  getSystem,
} from "../../../redux/actions/system.action";
import "./ClientSystem.scss";

const { TextArea } = Input;

class ClientSystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "add",
      formDisable: true,
      network: true,
      systemName: "",
      systemData: {},
      facilityData: {},
      clientInfoData: {},
      formEdit: false,
      visible: false,
      demandPage: Routes.clientFacility,
      demandPageData: {},
      message: null,
      title: null,
      addMore: false,
    };

    this.formFields = [
      {
        label: systemLableVariables.SYSTEM_NAME,
        key: "systemName",
        max: "50",
        required: [true, systemMessages.REQUIRED_FIELD],
        pattern: [Pattern.Name.regx, Pattern.Name.message],
        blur: (e) => {
          this.getCode(e.target.value);
        },
      },
      {
        label: systemLableVariables.CLIENT_SYSTEM_CODE,
        key: "clientSystemId",
        max: "25",
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: systemLableVariables.MECHADEMY_SYSTEM_CODE,
        key: "mechademySystemId",
        max: "25",
        pattern: [Pattern.Code.regx, Pattern.Code.message],
      },
      {
        label: systemLableVariables.SYSTEM_DESCRIPTION,
        type: "textarea",
        key: "systemDescription",
        max: "250",
        required: [true, systemMessages.REQUIRED_FIELD],
        style: { width: "66%" },
      },
    ];
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;

    if (state && state.clientData) {
      this.setState({
        clientInfoData: state.clientData,
        facilityData: state.facilityData,
        systemData: state.systemData,
      });
    }

    window.addEventListener("form-edit", () => {
      if (!this.state.formDisable) {
        this.setState({
          formEdit: true,
        });
      }
    });

    window.addEventListener("form-edit-false", (e) => {
      this.setState({
        formEdit: false,
      });
    });

    this.getPageMode();
  }

  componentDidUpdate(prevProps) {
    const {
      isSuccessAddSystem,
      isSuccessFetchSystem,
      isSuccessEditSystem,
      isErrorEditSystem,
      isErrorAddSystem,
      isErrorFetchSystem,
      errorCode,
      message,
      systemData,
    } = this.props.clientSystemState.apiState;
    const { clearSystemState } = this.props;
    const {
      location: { state },
    } = this.props;

    if (isSuccessAddSystem) {
      let tempAddMore = this.state.addMore;
      Notification.show(Types.Success, systemMessages.systemAdded);
      this.disableEditForm();
      clearSystemState();
      if (!tempAddMore) {
        this.setState(
          {
            mode: "view",
            formDisable: true,
            systemData,
          },
          () => {
            tempAddMore = false;
          }
        );
      } else {
        this.setState(
          {
            addMore: tempAddMore,
            mode: "add",
          },
          () => {
            tempAddMore = true;
            this.props.form.resetFields();
            this.disableEditForm();
          }
        );
      }
    }

    if (isSuccessEditSystem) {
      Notification.show(Types.Success, systemMessages.systemUpdated);
      this.disableEditForm();
      this.setState({
        mode: "view",
        systemData,
        formDisable: true,
      });
      clearSystemState();
    }

    if (isSuccessFetchSystem) {
      clearSystemState();
      this.setState(
        {
          systemData,
          mode: "view",
          formDisable: true,
        },
        () => {
          this.setFormData();
        }
      );
    }

    if (isErrorAddSystem) {
      if (errorCode === "ALREADY_EXISTS") {
        if (_.includes(message, "mechademy"))
          Notification.show(Types.Error, `${systemMessages[errorCode]}`);
        else Notification.show(Types.Error, message);
      } else Notification.show(Types.Error, systemMessages[errorCode]);
      clearSystemState();
      this.setState({
        formDisable: false,
        mode: "add",
      });
    }

    if (isErrorEditSystem) {
      if (errorCode === "ALREADY_EXISTS") {
        if (_.includes(message, "mechademy"))
          Notification.show(Types.Error, `${systemMessages[errorCode]}`);
        else Notification.show(Types.Error, message);
      } else Notification.show(Types.Error, systemMessages[errorCode]);
      clearSystemState();
      this.setState({
        formDisable: false,
        mode: "edit",
      });
    }

    if (isErrorFetchSystem) {
      Notification.show(Types.Error, systemMessages[errorCode]);
      clearSystemState();
    }

    if (
      state.systemData &&
      ((prevProps.location.state.systemData &&
        state.systemData._id !== prevProps.location.state.systemData._id) ||
        !prevProps.location.state.systemData)
    ) {
      this.setState({
        facilityData: state.facilityData ? state.facilityData : {},
      });
      this.getSystem();
    }
  }

  getFacilityData() {
    const {
      location: { state },
    } = this.props;
    const { systemData } = this.props.clientSystemState.apiState;
    const { mode } = this.state;

    if (mode !== "add" && systemData) {
      return state.clientData.facilities.find(
        (a) => a._id === systemData.facilityId
      );
    }
    if (mode === "add" && state && state.facilityData) {
      return state.facilityData;
    }
    return {};
  }

  getSystem() {
    const { getSystemAction } = this.props;
    const {
      location: { state },
    } = this.props;

    if (state && state.systemData) {
      if (this.handleNetwork()) {
        getSystemAction(state.systemData._id);
      }
    }
  }

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    }
    Notification.show(Types.Error, NO_INTERNET);
    this.setState({ network: false });
    return false;
  }

  setFormData() {
    const { systemData } = this.state;

    if (systemData && systemData._id) {
      this.props.form.setFieldsValue(systemData, () => {
        this.disableEditForm();
      });
    }
  }

  disableEditForm() {
    window.dispatchEvent(new Event("form-edit-false"));
  }

  getPageMode() {
    const {
      location: { state },
    } = this.props;
    if (state && state.mode) {
      if (state.mode === "edit") {
        this.setState({ formDisable: false, mode: "edit" });
      } else if (state.mode === "add") {
        this.setState({ formDisable: false, mode: "add" });
      } else {
        this.setState({ formDisable: true, mode: "view" });
        this.getSystem();
      }
    } else {
      this.props.history.push(Routes.clients);
    }
  }

  getCode(value) {
    if (this.state.mode !== "edit") {
      value = value || "";
      const code = generateCode(value.trim());

      const {
        mechademySystemId,
        clientSystemId,
        systemName,
      } = this.props.form.getFieldsValue();
      if (!mechademySystemId && !clientSystemId) {
        this.props.form.setFieldsValue({
          systemName: value.trim(),
          mechademySystemId: code,
          clientSystemId: code,
        });
      } else if (
        systemName &&
        systemName.length >= 3 &&
        mechademySystemId &&
        mechademySystemId.length >= 3
      ) {
        const firstThreeCharOfName = systemName
          .trim()
          .substr(0, 3)
          .toLowerCase();
        const firstThreeCharOfFacilityCode = mechademySystemId
          .trim()
          .substr(0, 3)
          .toLowerCase();
        if (firstThreeCharOfName !== firstThreeCharOfFacilityCode) {
          this.props.form.setFieldsValue({
            systemName: value.trim(),
            mechademySystemId: code,
            clientSystemId: code,
          });
        }
      }
    }
  }

  onEdit() {
    if (this.handleNetwork()) {
      this.setState({ mode: "edit" }, () => {
        this.setState({ formDisable: false });
      });
    }
  }

  newEquipment() {
    const facilityInfo = this.props;
    this.props.history.push(Routes.clientEquipment, {
      clientData: this.props.location.state.clientData,
      facilityData: this.state.facilityData.name
        ? this.state.facilityData
        : facilityInfo.location.state.facilityData,
      systemData: this.props.clientSystemState.apiState.systemData,
      mode: "add",
    });
  }

  onCancel() {
    const { mode, facilityData, clientInfoData } = this.state;

    if (this.state.formEdit) {
      this.setState({
        message: FORM_DISCARD_MSG,
        title: DISCARD_CHANGES,
        visible: true,
        demandPage: mode === "edit" ? null : Routes.clientFacility,
        demandPageData: {
          facilityData,
          clientData: clientInfoData,
          mode: "view",
        },
      });
    } else if (mode === "add") {
      this.props.history.push(Routes.clientFacility, {
        facilityData,
        clientData: clientInfoData,
        mode: "view",
      });
    } else {
      this.setState({ mode: "view" }, () => {
        this.setState({
          formDisable: true,
        });
      });
      this.props.form.resetFields();
      this.setFormData();
    }
  }

  validateFieldsValue() {
    const values = this.props.form.getFieldsValue();
    const valuesKey = Object.keys(this.props.form.getFieldsValue());

    for (let i = 0; i < valuesKey.length; i++) {
      values[valuesKey[i]] = values[valuesKey[i]]
        ? values[valuesKey[i]].trim()
        : values[valuesKey[i]];
    }
    this.props.form.setFieldsValue(values);
  }

  saveMore() {
    this.setState({ addMore: true }, () => {
      this.saveData();
    });
  }

  save() {
    this.setState({ addMore: false }, () => {
      this.saveData();
    });
  }

  saveData() {
    const { facilityData } = this.state;
    this.validateFieldsValue();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.facilityId = facilityData._id;

        for (const facilityKey in values) {
          if (!values[facilityKey]) {
            delete values[facilityKey];
          }
        }

        if (this.handleNetwork()) {
          this.props.addSystemAction(values);
        }
      }
    });
  }

  update() {
    const { systemData } = this.state;

    this.validateFieldsValue();

    if (!this.state.formEdit) {
      this.onCancel();
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.handleNetwork()) {
          this.props.editSystemAction(
            values,
            systemData._id,
            systemData.facilityId
          );
        }
      }
    });
  }

  componentWillUnmount() {
    this.disableEditForm();
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleOk() {
    const { mode, demandPage, demandPageData } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        if (mode === "edit" && !demandPage) {
          this.setState({ mode: "view" }, () => {
            this.setState({
              formDisable: true,
            });
          });
          this.props.form.resetFields();
          this.setFormData();
        } else {
          this.props.history.push(demandPage, demandPageData);
        }
      }
    );
  }

  navigateToScreen(path, data) {
    if (data) {
      data.mode = "view";
    } else {
      data = {};
    }
    if (this.state.formEdit) {
      this.setState({
        message: null,
        title: null,
        visible: true,
        demandPage: path,
        demandPageData: data,
      });
    } else {
      this.props.history.push(path, data);
    }
  }

  getSystemName() {
    const { systemData } = this.state;
    return systemData && systemData.systemName
      ? systemData.systemName
      : systemVariables.ADD_SYSTEM;
  }

  renderBreadCrumb() {
    const { facilityData, clientInfoData } = this.state;

    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <label onClick={() => this.navigateToScreen(Routes.clients)}>
            {ClientFacilityVariables.CLIENTS}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientInfo, {
                clientData: clientInfoData,
                mode: "view",
              })
            }
          >
            {clientInfoData && clientInfoData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <label
            onClick={() =>
              this.navigateToScreen(Routes.clientFacility, {
                clientData: clientInfoData,
                facilityData,
                mode: "view",
              })
            }
          >
            {facilityData && facilityData.name}
          </label>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{this.getSystemName()}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  renderFormButton() {
    const { mode, facilityData } = this.state;
    const facilityId = facilityData["_id"];

    if (mode === "add") {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button className="btn-default" onClick={() => this.save()}>
            {ButtonVariables.SAVE}
          </Button>
          <Button className="btn-default" onClick={() => this.saveMore()}>
            {ButtonVariables.SAVE_MORE}
          </Button>
        </div>,
        ElementPermissions.ADD_SYSTEM,
        {
          type: "facility",
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    if (mode === "edit") {
      return elementsGaurd(
        <div>
          <label className="btn-transparent" onClick={() => this.onCancel()}>
            {ButtonVariables.CANCEL}
          </label>
          <Button
            className="btn-default"
            onClick={() => {
              this.update();
            }}
          >
            {ButtonVariables.UPDATE}
          </Button>
        </div>,
        ElementPermissions.EDIT_SYSTEM,
        {
          type: "facility",
          dataRef: {
            _id: facilityId,
          },
        }
      );
    }
    if (mode === "view") {
      return (
        <div>
          {elementsGaurd(
            <Button
              className="btn-default"
              onClick={() => {
                this.onEdit();
              }}
            >
              {ButtonVariables.EDIT}
            </Button>,
            ElementPermissions.EDIT_SYSTEM,
            {
              type: "facility",
              dataRef: {
                _id: facilityId,
              },
            }
          )}
        </div>
      );
    }
  }

  renderFieldType(field) {
    const { formDisable } = this.state;
    if (field.type) {
      if (field.type === "textarea") {
        return (
          <TextArea
            rows={5}
            maxLength={field.max ? field.max : ""}
            disabled={formDisable}
          />
        );
      }
      return (
        <Input
          maxLength={field.max ? field.max : ""}
          onBlur={field.blur ? field.blur : () => {}}
          autoComplete="off"
          disabled={formDisable}
        />
      );
    }
    return (
      <Input
        maxLength={field.max ? field.max : ""}
        onBlur={field.blur ? field.blur : () => {}}
        autoComplete="off"
        disabled={formDisable}
      />
    );
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        {this.formFields.map((field) => (
          <Form.Item label={field.label} style={field.style}>
            {getFieldDecorator(field.key, {
              rules: [
                field.required
                  ? {
                    required: field.required[0],
                    message: field.required[1],
                  }
                  : "",
                field.pattern
                  ? {
                    pattern: field.pattern[0],
                    message: field.pattern[1],
                  }
                  : "",
              ],
            })(this.renderFieldType(field))}
          </Form.Item>
        ))}
        <div className="action-btn">{this.renderFormButton()}</div>
      </Form>
    );
  }

  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={this.state.message}
          title={this.state.title}
        />
      );
    }
    return null;
  }

  render() {
    const { mode, systemData, facilityData } = this.state;
    const { loading, apiState } = this.props.clientSystemState;
    const facilityId = facilityData["_id"];

    return (
      <div className="ClientSystem">
        {loading ? <Loader /> : null}
        <div className="header" style={MaskStyle}>
          <div className="title">{this.getSystemName()}</div>
          <div className="path">
            {this.renderBreadCrumb()}
            <div className="schematic">
              {mode === "view"
                ? elementsGaurd(
                  <Button
                    className="add-btn btn-default"
                    onClick={() =>
                      this.props.history.push(Routes.systemSchematic, {
                        ...this.props.location.state,
                        systemData,
                        isSystemSchematic: true,
                      })
                    }
                  >
                    {apiState.systemData && apiState.systemData.schematic
                      ? ButtonVariables.VIEW_SYSTEM_SCHEMATIC
                      : ButtonVariables.CREATE_SYSTEM_SCHEMATIC}
                  </Button>,
                  ElementPermissions.ADD_EQUIPMENT,
                  {
                    type: "facility",
                    dataRef: {
                      _id: facilityId,
                    },
                  }
                )
                : ""}
              {mode === "view"
                ? elementsGaurd(
                  <Button
                    className="add-btn btn-default"
                    onClick={() => this.newEquipment()}
                  >
                    {systemVariables.ADD_EQUIPMENT}
                  </Button>,
                  ElementPermissions.ADD_EQUIPMENT,
                  {
                    type: "facility",
                    dataRef: {
                      _id: facilityId,
                    },
                  }
                )
                : ""}
            </div>
          </div>
        </div>
        <div className="screen-content">
          <div className="content-div">{this.renderForm()}</div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientSystemState: state.SystemReducer,
  clientFacilityState: state.FacilityReducer,
  network: state.NetworkReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addSystemAction: (payload) => {
    dispatch(addSystem(payload));
  },
  getSystemAction: (payload) => {
    dispatch(getSystem(payload));
  },
  editSystemAction: (payload, id, facilityId) => {
    dispatch(editSystem(payload, id, facilityId));
  },
  clearSystemState: () => dispatch(clearSystemState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "systemDetails",
    onFieldsChange: (props, fields, allfields) => {
      window.dispatchEvent(new Event("form-edit"));
    },
  })(withRouter(ClientSystem))
);
