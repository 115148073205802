import { Icon, Input } from "antd";
import React from "react";
import { gtPerformanceDataVariables } from "../../../../../../constants/variables";
import { newValidateNumber } from "../../../../../../libs/validator";

export const PerformanceDataShow = (config) => [
  {
    title: "Data Variables",
    dataIndex: "name",
    render: (value, row, col, disabled, item) => {
      return value;
    },
  },
  {
    title: "Units",
    dataIndex: "unit",
    render: (value, row, col, disabled, item) => {
      if (
        item.name !== gtPerformanceDataVariables.certifiedPoint &&
        item.name !== gtPerformanceDataVariables.turbineInletAirChilled
      ) {
        const tempError = false;
        return (
          <div
            className={`custom-table-select ${disabled ? "beforeEdit" : ""} ${
              tempError ? "error" : "not-error"
            }  `}
          >
            <select
              value={!item.unit ? "" : item.unit}
              disabled={disabled}
              onChange={(e) =>
                config.handleChange(e.target.value, item.name, "unit")
              }
            >
              <option value="Select">Select</option>
              {config.unitData.map((getUnits) => {
                if (getUnits.dataVariable === item.name) {
                  return getUnits.units.map((dispUnit) => (
                    <option value={dispUnit}>{dispUnit}</option>
                  ));
                }
                return undefined;
              })}
            </select>
          </div>
        );
      }
    },
  },
  {
    title: "Site Rated",
    dataIndex: "siteRated",
    render: (value, row, col, disabled, item) => {
      if (
        item.name === gtPerformanceDataVariables.turbineInletAirChilled ||
        item.name === gtPerformanceDataVariables.certifiedPoint
      ) {
        return (
          <select
            value={!item.siteRated ? "" : item.siteRated}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteRated")
            }
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        );
      }

      return (
        <div>
          <Input
            type="number"
            value={item.siteRated}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteRated")
            }
          />
        </div>
      );
    },
  },
  {
    title: "Normal Duty",
    dataIndex: "normalDuty",
    render: (value, row, col, disabled, item) => {
      if (
        item.name === gtPerformanceDataVariables.turbineInletAirChilled ||
        item.name === gtPerformanceDataVariables.certifiedPoint
      ) {
        return (
          <select
            value={!item.normalDuty ? "" : item.normalDuty}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "normalDuty")
            }
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        );
      }

      return (
        <div>
          <Input
            type="number"
            value={item.normalDuty}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "normalDuty")
            }
          />
        </div>
      );
    },
  },
  {
    title: "Site Max Temp",
    dataIndex: "siteMaxTemp",
    render: (value, row, col, disabled, item) => {
      if (
        item.name === gtPerformanceDataVariables.turbineInletAirChilled ||
        item.name === gtPerformanceDataVariables.certifiedPoint
      ) {
        return (
          <select
            value={!item.siteMaxTemp ? "" : item.siteMaxTemp}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteMaxTemp")
            }
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        );
      }

      return (
        <div>
          <Input
            type="number"
            value={item.siteMaxTemp}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteMaxTemp")
            }
          />
        </div>
      );
    },
  },
  {
    title: "Site Min Temp",
    dataIndex: "siteMinTemp",
    render: (value, row, col, disabled, item) => {
      if (
        item.name === gtPerformanceDataVariables.turbineInletAirChilled ||
        item.name === gtPerformanceDataVariables.certifiedPoint
      ) {
        return (
          <select
            value={!item.siteMinTemp ? "" : item.siteMinTemp}
            disabled={disabled}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteMinTemp")
            }
          >
            <option value="">Select</option>
            {data[item.name] &&
              data[item.name].map((item) => (
                <option value={item} title={item}>
                  {item}
                </option>
              ))}
          </select>
        );
      }

      return (
        <div>
          <Input
            type="number"
            value={item.siteMinTemp}
            disabled={disabled}
            AutoComplete="off"
            onKeyDown={(e) => {
              newValidateNumber(e);
            }}
            onChange={(e) =>
              config.handleChange(e.target.value, item.name, "siteMinTemp")
            }
          />
        </div>
      );
    },
  },
  config.isSchematicEditable && {
    title: "Actions",
    dataIndex: "actions",
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button onClick={() => config.onEdit(row)}>
            <Icon type="edit" />
          </button>
        ) : (
          <button onClick={() => config.onUndo(row)}>
            <Icon type="undo" />
          </button>
        )}
      </div>
    ),
  },
];

export const initialData = [
  {
    name: gtPerformanceDataVariables.inletLoss,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.exhaustLoss,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.dryBulbTemp,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.turbineInletAirChilled,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.turbineInletTemp,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.relativeHumidity,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.barometricPressure,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.gtOutputShaftPower,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.gasGeneratorOutputShaftSpeed,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.powerTurbineOutputShaftSpeed,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.lhvHeatRate,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.lhvEfficiency,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.firingTemp,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.airFlow,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.ggExhaustTemp,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.ptExhaustFlow,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.ptExhaustTemp,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.fuelFlowrate,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.certifiedPoint,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.steamFlow,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
  {
    name: gtPerformanceDataVariables.waterFlow,
    unit: null,
    normalDuty: parseFloat(""),
    siteRated: parseFloat(""),
    siteMaxTemp: parseFloat(""),
    siteMinTemp: parseFloat(""),
  },
];

export const ChangeKey = {
  [gtPerformanceDataVariables.inletLoss]: "inletLoss",
  [gtPerformanceDataVariables.exhaustLoss]: "exhaustLoss",
  [gtPerformanceDataVariables.dryBulbTemp]: "dryBulbTemp",
  [gtPerformanceDataVariables.turbineInletAirChilled]: "turbineInletAirChilled",
  [gtPerformanceDataVariables.turbineInletTemp]: "turbineInletTemp",
  [gtPerformanceDataVariables.relativeHumidity]: "relativeHumidity",
  [gtPerformanceDataVariables.barometricPressure]: "barometricPressure",
  [gtPerformanceDataVariables.gtOutputShaftPower]: "gtOutputShaftPower",
  [gtPerformanceDataVariables.gasGeneratorOutputShaftSpeed]:
    "gasGeneratorOutputShaftSpeed",
  [gtPerformanceDataVariables.powerTurbineOutputShaftSpeed]:
    "powerTurbineOutputShaftSpeed",
  [gtPerformanceDataVariables.lhvHeatRate]: "lhvHeatRate",
  [gtPerformanceDataVariables.lhvEfficiency]: "lhvEfficiency",
  [gtPerformanceDataVariables.firingTemp]: "firingTemp",
  [gtPerformanceDataVariables.airFlow]: "airFlow",
  [gtPerformanceDataVariables.ggExhaustTemp]: "ggExhaustTemp",
  [gtPerformanceDataVariables.ptExhaustFlow]: "ptExhaustFlow",
  [gtPerformanceDataVariables.ptExhaustTemp]: "ptExhaustTemp",
  [gtPerformanceDataVariables.fuelFlowrate]: "fuelFlowrate",
  [gtPerformanceDataVariables.certifiedPoint]: "certifiedPoint",
  [gtPerformanceDataVariables.steamFlow]: "steamFlow",
  [gtPerformanceDataVariables.waterFlow]: "waterFlow",
};

export const GtPerfDataVariableMap = {
  inletLoss: "Inlet Loss",
  exhaustLoss: "Exhaust Loss",
  dryBulbTemp: "Dry Bulb Temperature (Ambient)",
  turbineInletAirChilled: "Turbine Inlet Air Chilled (Yes/No)",
  turbineInletTemp: "Turbine Inlet Temperature",
  relativeHumidity: "Relative Humidity (Ambient)",
  barometricPressure: "Barometric Pressure",
  gtOutputShaftPower: "GT Output Shaft Power",
  gasGeneratorOutputShaftSpeed: "Gas Generator (GG) Output Shaft Speed",
  powerTurbineOutputShaftSpeed: "Power Turbine (PT) Output Shaft Speed",
  lhvHeatRate: "LHV Heat Rate",
  lhvEfficiency: "LHV Efficiency",
  firingTemp: "Firing Temperature",
  airFlow: "Air Flow",
  ggExhaustTemp: "GG Exhaust Temperature",
  ptExhaustFlow: "PT Exhaust Flow",
  ptExhaustTemp: "PT Exhaust Temperature",
  fuelFlowrate: "Fuel Flowrate",
  certifiedPoint: "Certified Point (Yes/No)",
  steamFlow: "Steam Flow",
  waterFlow: "Water Flow",
};

export const data = {
  [gtPerformanceDataVariables.turbineInletAirChilled]: ["Yes", "No"],
  [gtPerformanceDataVariables.certifiedPoint]: ["Yes", "No"],
};
