import { Form, Icon, Modal } from "antd";
import PropType from "prop-types";
import React, { useEffect, useState } from "react";
import ic_csv from "../../assets/ic_csv/ic_csv.svg";
import ic_upload from "../../assets/ic_upload/ic_upload.svg";
import { ButtonVariables } from "../../constants/variables";
import "./Modal.scss";

function UploadModal(props) {
  const fileInput = React.createRef();
  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const formData = new FormData();

  useEffect(() => () => {
    setCsvFile(null);
    setFileName("");
  }, []);

  const upload = (event) => {
    formData.append("file", event.target.files[0]);
    setFileName(event.target.files[0].name);
    setCsvFile(formData);
  };

  const fileUpload = () => {
    if (csvFile === null) {
      fileInput.current.click();
    }
  };
  const clearFileData = () => {
    setFileName("");
    setCsvFile(null);
    fileInput.current.value = null;
  };

  return (
    <Modal
      title={props.title ? props.title : "Upload File"}
      visible={props.visible}
      onOk={() => props.handleOk(csvFile, props.id)}
      onCancel={() => props.handleCancel()}
      okText={ButtonVariables.UPLOAD}
    >
      <div className="upload-file-main-screen">
        <div className={csvFile === null ? "file-input" : "file-uploaded"}>
          {csvFile !== null && (
            <div className="modal-cross-icon">
              <span onClick={() => clearFileData()}>
                <Icon type="close-circle" />
              </span>
            </div>
          )}
          {csvFile === null ? (
            <div onClick={() => fileUpload()}>
              <div className="csv-file-upload-icon">
                <img alt="" src={ic_upload} />
              </div>
              <div className="content">{props.content}</div>
            </div>
          ) : (
            <div onClick={() => fileUpload()}>
              <img src={ic_csv} alt="csv-icon" />
              <div className="content">{fileName}</div>
            </div>
          )}
          <form className="form-inline" encType="multipart/form-data">
            <div onClick={() => fileUpload()} />
            <input
              accept=".csv"
              type="file"
              className="input-csv-file"
              onChange={(event) => upload(event)}
              ref={fileInput}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
}
UploadModal.propTypes = {
  title: PropType.string.isRequired,
};

UploadModal.defaultProps = {
  id: null,
  title: "Upload File",
  content: "Pi Tag List",
};
export default Form.create({
  name: "clientDetails",
  onFieldsChange: (props, fields, allfields) => {
    window.dispatchEvent(new Event("form-edit"));
  },
})(UploadModal);
