import Type from "../Types/steamTurbine.types";
import { endPoints } from "../../libs/endpoints";
import { GETRequest, PUTRequest } from "../../api/request";

export function saveCurvesData(id, payload) {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_ST_CURVES_DATA_PROGRESS });
    PUTRequest(`${endPoints.components}/${id}/${endPoints.stCurves}`, payload)
      .then((res) =>
        dispatch({
          type: Type.UPDATE_ST_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.UPDATE_ST_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}

export function getCurvesData(id) {
  return function (dispatch) {
    dispatch({ type: Type.GET_ST_CURVES_DATA_PROGRESS });
    GETRequest(`${endPoints.components}/${id}/${endPoints.stCurves}`)
      .then((res) =>
        dispatch({
          type: Type.GET_ST_CURVES_DATA_SUCCESS,
          payload: res.body,
        })
      )
      .catch((err) =>
        dispatch({
          type: Type.GET_ST_CURVES_DATA_FAILURE,
          payload: err,
        })
      );
  };
}


export function saveCurvesDataReset() {
  return function (dispatch) {
    dispatch({ type: Type.UPDATE_ST_CURVES_DATA_RESET });
  };
}
