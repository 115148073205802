import { Empty } from 'antd';
import _ from 'lodash';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import cardTypeMapper from '../../../constants/cardTypes';
import labels from '../graph.constants';

class TornadoGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataPresent: false,
      series: [],
      options: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 10,
          formatter(seriesName, seriesOptions) {
            return [
              `${seriesName} : ${
                seriesOptions.w.globals.seriesTotals[seriesOptions.seriesIndex]
              }  `,
            ];
          },
        },
        colors: [],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: undefined,
          },
          maxWidth: 60,
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  componentDidMount() {
    const tornadoData = this.getData();
    this.setState({
      ...this.state,
      isDataPresent: tornadoData.isDataPresent,
      series: tornadoData.series,
      options: {
        ...this.state.options,
        colors: tornadoData.colors,
        xaxis: {
          categories: tornadoData.categoryValues,
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cardValue !== prevProps.cardValue) {
      const tornadoData = this.getData();
      this.setState({
        ...this.state,
        isDataPresent: tornadoData.isDataPresent,
        series: tornadoData.series,
        options: {
          ...this.state.options,
          colors: tornadoData.colors,
          xaxis: {
            categories: tornadoData.categoryValues,
          },
        },
      });
    }
  }

  // Needs to handle lot more use cases
  getLabels(arr) {
    return arr.map((ele) => {
      if (ele) {
        if (ele.length > 15) {
          return ele.split(' ');
        }
        return ele;
      }
      return ele;
    });
  }

  getData = () => {
    const { cardValue } = this.props;
    let isDataPresent = false;
    let categories = [];
    if (this.props.cardType === cardTypeMapper['Failure bar chart']) {
      if (this.props.cardLevel) {
        if (cardValue && cardValue.data && cardValue.data.length > 0) {
          return {
            isDataPresent: true,
            colors: ['#add2f4'],
            series: [{ data: cardValue.data.map((ele) => ele.count) }],
            categoryValues: cardValue.data.map((ele) => ele._id.value),
          };
        }
        return {
          isDataPresent: true,
          colors: [],
          series: [],
          categoryValues: [],
        };
      }

      const System = [];
      const Design = [];
      const Fabrication = [];
      const Operation = [];
      const Management = [];
      const Miscellaneous = [];
      const categoryValues = [];
      const categories = Object.keys(
        labels.FailureTypeTornadoLabels.labelConstants
      );
      const colors = labels.FailureTypeTornadoLabels.colorConstants;

      if (cardValue && cardValue.data) {
        const data = _.cloneDeep(cardValue.data);
        isDataPresent = true;
        data.forEach((item) => {
          // find levels
          const levels = _.map(item.info, 'value');
          const remainingLevel = _.difference(categories, levels);
          _.forEach(remainingLevel, (rl) => {
            item.info.push({ value: rl, count: 0 });
          });
        });
        data.forEach((item) => {
          categoryValues.push(item._id);
          item.info.forEach((barData) => {
            switch (barData.value) {
              case labels.FailureTypeTornadoLabels.labelConstants.System:
                System.push(barData.count);
                break;
              case labels.FailureTypeTornadoLabels.labelConstants.Design:
                Design.push(barData.count);
                break;
              case labels.FailureTypeTornadoLabels.labelConstants[
                'Fabrication/Installation'
              ]:
                Fabrication.push(barData.count);
                break;
              case labels.FailureTypeTornadoLabels.labelConstants[
                'Operation/Maintenance'
              ]:
                Operation.push(barData.count);
                break;
              case labels.FailureTypeTornadoLabels.labelConstants.Management:
                Management.push(barData.count);
                break;
              case labels.FailureTypeTornadoLabels.labelConstants.Miscellaneous:
                Miscellaneous.push(barData.count);
                break;
              default:
                break;
            }
          });
        });
      }
      return {
        isDataPresent,
        colors,
        series: [
          {
            name: labels.FailureTypeTornadoLabels.labelConstants.System,
            data: System,
          },
          {
            name: labels.FailureTypeTornadoLabels.labelConstants.Design,
            data: Design,
          },
          {
            name:
              labels.FailureTypeTornadoLabels.labelConstants[
                'Fabrication/Installation'
              ],
            data: Fabrication,
          },
          {
            name:
              labels.FailureTypeTornadoLabels.labelConstants[
                'Operation/Maintenance'
              ],
            data: Operation,
          },
          {
            name: labels.FailureTypeTornadoLabels.labelConstants.Management,
            data: Management,
          },
          {
            name: labels.FailureTypeTornadoLabels.labelConstants.Miscellaneous,
            data: Miscellaneous,
          },
        ],
        categoryValues,
      };
    }
    if (
      this.props.cardType ===
      cardTypeMapper['Alert status Analytics tornado graph']
    ) {
      if (this.props.viewDataAdmin === 'Mechademy') {
        categories = labels.statusTypeLabels.MechademyAdmin.labelConstants;
      } else {
        categories = labels.statusTypeLabels.ClientAdmin.labelConstants;
      }
      const _new = [];
      const forward = [];
      const close = [];
      const disable = [];
      const discard = [];
      const shelved = [];
      const acknowledged = [];
      let categoryValues = [];
      const colors = labels.statusTypeLabels.MechademyAdmin.colorConstants;
      if (cardValue && cardValue.data) {
        const { data } = cardValue;
        isDataPresent = true;
        data.forEach((item) => {
          // find levels
          const levels = _.map(item.data, 'status');
          const remainingLevel = _.difference(categories, levels);
          _.forEach(remainingLevel, (rl) => {
            item.data.push({ status: rl, count: 0 });
          });
        });
        data.forEach((item) => {
          categoryValues.push(item._id.name);
          item.data.forEach((barData) => {
            if (barData.status === 'New') {
              _new.push(barData.count);
            } else if (barData.status === 'Forward') {
              forward.push(barData.count);
            } else if (barData.status === 'Close') {
              close.push(barData.count);
            } else if (barData.status === 'Disable') {
              disable.push(barData.count);
            } else if (barData.status === 'Discard') {
              discard.push(barData.count);
            } else if (barData.status === 'Shelved') {
              shelved.push(barData.count);
            } else if (barData.status === 'Acknowledged') {
              acknowledged.push(barData.count);
            }
          });
        });

        categoryValues =
          categoryValues?.length <= 5
            ? this.getLabels(categoryValues)
            : categoryValues;
      }
      return {
        isDataPresent,
        colors,
        series:
          this.props.viewDataAdmin === 'Mechademy'
            ? [
                {
                  name: 'New',
                  data: _new,
                },
                {
                  name: 'Forward',
                  data: forward,
                },
                {
                  name: 'Close',
                  data: close,
                },
                {
                  name: 'Disable',
                  data: disable,
                },
                {
                  name: 'Discard',
                  data: discard,
                },
                {
                  name: 'Shelved',
                  data: shelved,
                },
              ]
            : [
                {
                  name: 'New',
                  data: _new,
                },
                {
                  name: 'Acknowledged',
                  data: acknowledged,
                },
                {
                  name: 'Close',
                  data: close,
                },
                {
                  name: 'Shelved',
                  data: shelved,
                },
              ],
        categoryValues,
      };
    } else {
      const high = [];
      const medium = [];
      const low = [];
      const unKnown = [];
      let categoryValues = [];

      const categories = labels.priorityTypeLabels.labelConstants;
      const colors = labels.priorityTypeLabels.colorConstants;
      if (cardValue && cardValue.data) {
        const { data } = cardValue;
        isDataPresent = true;
        data.forEach((item) => {
          // find levels
          const levels = _.map(item.data, 'level');
          const remainingLevel = _.difference(categories, levels);
          _.forEach(remainingLevel, (rl) => {
            item.data.push({ level: rl, count: 0 });
          });
        });
        data.forEach((item) => {
          categoryValues.push(item._id.name);
          item.data.forEach((barData) => {
            if (barData.level === 'High') {
              high.push(barData.count);
            } else if (barData.level === 'Medium') {
              medium.push(barData.count);
            } else if (barData.level === 'Low') {
              low.push(barData.count);
            } else if (barData.level === 'Unknown') {
              unKnown.push(barData.count);
            }
          });
        });

        categoryValues =
          categoryValues?.length <= 5
            ? this.getLabels(categoryValues)
            : categoryValues;
      }
      return {
        isDataPresent,
        colors,
        series: [
          {
            name: 'High',
            data: high,
          },
          {
            name: 'Medium',
            data: medium,
          },
          {
            name: 'Low',
            data: low,
          },
          {
            name: 'Unknown',
            data: unKnown,
          },
        ],
        categoryValues,
      };
    }
  };

  render() {
    return (
      <div id="chart">
        {this.state.isDataPresent ? (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}

export default TornadoGraph;
