import Type from '../Types/PIMComponent.types';
const PIMConfigData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  componentId: '',
  designData: {
    application: '',
    twoPhaseFrictionFactorCorrelation: '',
    singlePhaseFrictionFactorCorrelation: '',
    palmerCorrectionFactor: false,
    heatTransferCorrelationFactor: '',
    waterVolume: null,
    ambientTemperatureProfileData: [],
    heatTransferData: [],
    sizingData: [],
  },
  fluidComposition: {},
  iterationData: {},
};

const addDesignData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addFluidCompositionData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const addIterationData = {
  isLoading: false,
  isError: false,
  errorCode: '',
  apiMsg: '',
  isSuccess: false,
  data: {},
};

const INITIAL_STATE = {
  PIMConfigData: { ...PIMConfigData },
  addDesignData: { ...addDesignData },
  addFluidCompositionData: { ...addFluidCompositionData },
  addIterationData: { ...addIterationData },
};

export default function PIMComponentReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case Type.GET_PIM_CONFIG_DATA_PROGRESS:
      return {
        ...state,
        PIMConfigData: {
          ...INITIAL_STATE.PIMConfigData,
          isLoading: true,
        },
      };
    case Type.GET_PIM_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        PIMConfigData: {
          isSuccess: true,
          isLoading: false,
          componentId: action.payload.componentId,
          designData: action.payload.designData
            ? {
                application: action.payload.designData.application
                  ? action.payload.designData.application
                  : null,
                twoPhaseFrictionFactorCorrelation: action.payload.designData
                  .twoPhaseFrictionFactorCorrelation
                  ? action.payload.designData.twoPhaseFrictionFactorCorrelation
                  : null,
                singlePhaseFrictionFactorCorrelation: action.payload.designData
                  .singlePhaseFrictionFactorCorrelation
                  ? action.payload.designData
                      .singlePhaseFrictionFactorCorrelation
                  : null,
                palmerCorrectionFactor: action.payload.designData
                  .palmerCorrectionFactor
                  ? action.payload.designData.palmerCorrectionFactor
                  : null,
                heatTransferCorrelationFactor: action.payload.designData
                  .heatTransferCorrelationFactor
                  ? action.payload.designData.heatTransferCorrelationFactor
                  : null,
                waterVolume: action.payload.designData.waterVolume
                  ? action.payload.designData.waterVolume
                  : null,
                numSegments: action.payload.designData.numSegments
                  ? action.payload.designData.numSegments
                  : null,
                ambientTemperatureProfileData: action.payload.designData
                  .ambientTemperatureProfileData
                  ? action.payload.designData.ambientTemperatureProfileData
                  : [],
                heatTransferData: action.payload.designData.heatTransferData
                  ? action.payload.designData.heatTransferData
                  : [],
                sizingData: action.payload.designData.sizingData
                  ? action.payload.designData.sizingData
                  : [],
              }
            : {
                ...INITIAL_STATE.PIMConfigData.designData,
              },
          iterationData: action.payload
            ? action.payload.iterationData
            : {
                ...INITIAL_STATE.PIMConfigData.iterationData,
              },
        },
      };
    case Type.GET_PIM_CONFIG_DATA_FAILURE:
      return {
        ...state,
        PIMConfigData: {
          ...state.PIMConfigData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.GET_PIM_CONFIG_DATA_RESET:
      return {
        ...state,
        PIMConfigData: {
          ...state.PIMConfigData,
          isLoading: false,
          isError: false,
          errorCode: '',
          apiMsg: '',
          isSuccess: false,
          componentId: '',
        },
      };

    case Type.UPDATE_PIM_DESIGN_DATA_PROGRESS:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
          isLoading: true,
        },
      };
    case Type.UPDATE_PIM_ITERATION_DATA_PROGRESS:
      return {
        ...state,
        addIterationData: {
          ...INITIAL_STATE.addIterationData,
          isLoading: true,
        },
      };
    case Type.UPDATE_PIM_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        PIMConfigData : {
          ...PIMConfigData,
          designData : action.payload.designData
        },
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
        },
      };
    case Type.UPDATE_PIM_ITERATION_DATA_SUCCESS:
      return {
        ...state,
        PIMConfigData : {
          ...PIMConfigData,
          iterationData : action.payload.iterationData
        },
        addIterationData: {
          ...state.addIterationData,
          isLoading: false,
          isSuccess: true,
          data: action.payload.iterationData,
        },
      };
    case Type.UPDATE_PIM_DESIGN_DATA_FAILURE:
      return {
        ...state,
        addDesignData: {
          ...state.addDesignData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_PIM_ITERATION_DATA_FAILURE:
      return {
        ...state,
        addIterationData: {
          ...state.addIterationData,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errorCode:
            action.payload.response && action.payload.response.body.code,
          apiMsg:
            action.payload.response && action.payload.response.body.message,
        },
      };
    case Type.UPDATE_PIM_DESIGN_DATA_RESET:
      return {
        ...state,
        addDesignData: {
          ...INITIAL_STATE.addDesignData,
        },
      };

    case Type.RESET_PIM_REDUCER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
