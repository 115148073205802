import _ from 'lodash';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../../common/CustomTable/CustomTable';
import ConfirmModal from '../../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../../common/Notification/Notification';
import {
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
  MotorDesignDataMessages,
  NO_INTERNET,
} from '../../../../../../constants/messages';
import { ButtonVariables } from '../../../../../../constants/variables';
import {
  DesignDataShow,
  ReqdCalcDataTable,
  filterReqdData,
  OtherDesignDataTable,
  filterOtherDesignData,
  filterConfigIsoCondData,
  initialData,
  KeyChange,
} from './designPerformanceGT.Constants';
import { saveDesignData } from '../../../../../../redux/actions/gasTurbine.action';
import Loader from '../../../../../../common/Loader/Loader';
import { gtDesignDataVariables } from '../../../../../../constants/variables';

class DesignDataGasTurbine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: initialData,
      copyDisplayData: initialData,
      visible: false,
      formEdit: false,
      editTableRows: { isoData: [], reqData: [], othData: [] },
      saveListener: false,
      unitData: [],
    };
  }

  componentDidMount() {
    this.setUnits();
    this.setData();
    window.addEventListener('form-edit', () => {
      this.setState({
        formEdit: true,
      });
    });
    window.addEventListener('form-edit-false', (e) => {
      this.setState({
        formEdit: false,
        saveListener: false,
        editTableRows: { isoData: [], reqData: [], othData: [] },
      });
    });
  }

  disableEditForm() {
    window.dispatchEvent(new Event('form-edit-false'));
  }

  enableEditForm() {
    window.dispatchEvent(new Event('form-edit'));
  }

  setUnits() {
    const { unitData } = this.props.dataUnitState.apiState;
    this.setState({
      unitData: unitData && unitData.items ? _.clone(unitData.items) : [],
    });
  }

  setData() {
    const {
      gasTurbineConfigData: {
        designPerformanceData: { designData },
      },
    } = this.props.gasTurbineReducer;
    const tempDisplayData = [
      {
        name: gtDesignDataVariables.application,
        design: designData.application?.design,
      },
      {
        name: gtDesignDataVariables.gtModel,
        design: designData.gtModel?.design,
      },
      {
        name: gtDesignDataVariables.gtClass,
        design: designData.gtClass?.design,
      },
      {
        name: gtDesignDataVariables.gtIsoPower,
        unit: designData.gtIsoPower?.unit,
        design: designData.gtIsoPower?.design,
      },
      {
        name: gtDesignDataVariables.gtIsoHeatRate,
        unit: designData.gtIsoHeatRate?.unit,
        design: designData.gtIsoHeatRate?.design,
      },
      {
        name: gtDesignDataVariables.gtIsoExhaustFlowRate,
        unit: designData.gtIsoExhaustFlowRate?.unit,
        design: designData.gtIsoExhaustFlowRate?.design,
      },
      {
        name: gtDesignDataVariables.gtIsoSpeed,
        unit: designData.gtIsoSpeed?.unit,
        design: designData.gtIsoSpeed?.design,
      },
      {
        name: gtDesignDataVariables.configSpeed,
        unit: designData.configSpeed?.unit,
        design: designData.configSpeed?.design,
      },
      {
        name: gtDesignDataVariables.startupSpeed,
        unit: designData.startupSpeed?.unit,
        design: designData.startupSpeed?.design,
      },
      {
        name: gtDesignDataVariables.startupPower,
        unit: designData.startupPower?.unit,
        design: designData.startupPower?.design,
      },
      {
        name: gtDesignDataVariables.defaultAxialCompressorInletPressure,
        unit: designData.defaultAxialCompressorInletPressure?.unit,
        design: designData.defaultAxialCompressorInletPressure?.design,
      },
      {
        name: gtDesignDataVariables.referenceAmbientPressure,
        unit: designData.referenceAmbientPressure?.unit,
        design: designData.referenceAmbientPressure?.design,
      },
      {
        name: gtDesignDataVariables.gtPowerCorrectionFactor,
        design: designData.gtPowerCorrectionFactor?.design,
      },
      {
        name: gtDesignDataVariables.gtPowerEstCorrectionFactor,
        design: designData.gtPowerEstCorrectionFactor?.design,
      },
      {
        name: gtDesignDataVariables.cycle,
        unit: designData.cycle?.unit,
        design: designData.cycle?.design,
      },
      {
        name: gtDesignDataVariables.gtDriverOutputSpeedRangeMin,
        unit: designData.gtDriverOutputSpeedRangeMin?.unit,
        design: designData.gtDriverOutputSpeedRangeMin?.design,
      },
      {
        name: gtDesignDataVariables.gtDriverOutputSpeedRangeMax,
        unit: designData.gtDriverOutputSpeedRangeMax?.unit,
        design: designData.gtDriverOutputSpeedRangeMax?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorStages,
        design: designData.airCompressorStages?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorMaximumTipSpeed,
        unit: designData.airCompressorMaximumTipSpeed?.unit,
        design: designData.airCompressorMaximumTipSpeed?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorType,
        design: designData.airCompressorType?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorPressureRatioISOConditions,
        design: designData.airCompressorPressureRatioISOConditions?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorCasingSplit,
        design: designData.airCompressorCasingSplit?.design,
      },
      {
        name: gtDesignDataVariables.airCompressorRotor,
        design: designData.airCompressorRotor?.design,
      },
      {
        name: gtDesignDataVariables.turbineStages,
        unit: designData.turbineStages?.unit,
        design: designData.turbineStages?.design,
      },
      {
        name: gtDesignDataVariables.turbineMaximumTipSpeed,
        unit: designData.turbineMaximumTipSpeed?.unit,
        design: designData.turbineMaximumTipSpeed?.design,
      },
      {
        name: gtDesignDataVariables.turbineCasingSplit,
        design: designData.turbineCasingSplit?.design,
      },
      {
        name: gtDesignDataVariables.turbineRotor,
        design: designData.turbineRotor?.design,
      },
      {
        name: gtDesignDataVariables.combustorType,
        design: designData.combustorType?.design,
      },
      {
        name: gtDesignDataVariables.combustorQuantity,
        design: designData.combustorQuantity?.design,
      },
      {
        name: gtDesignDataVariables.fuelNozzlesPerCombustor,
        design: designData.fuelNozzlesPerCombustor?.design,
      },
      {
        name: gtDesignDataVariables.combustorConfiguration,
        design: designData.combustorConfiguration?.design,
      },
      {
        name: gtDesignDataVariables.fuelType,
        design: designData.fuelType?.design,
      },
      {
        name: gtDesignDataVariables.combustorMaxAllowableTemperatureVariation,
        unit: designData.combustorMaxAllowableTemperatureVariation?.unit,
        design: designData.combustorMaxAllowableTemperatureVariation?.design,
      },
      {
        name: gtDesignDataVariables.ratedFuelMoleWeight,
        unit: designData.ratedFuelMoleWeight?.unit,
        design: designData.ratedFuelMoleWeight?.design,
      },
      {
        name: gtDesignDataVariables.ratedFuelLHV,
        unit: designData.ratedFuelLHV?.unit,
        design: designData.ratedFuelLHV?.design,
      },
      {
        name: gtDesignDataVariables.fuelPressure,
        unit: designData.fuelPressure?.unit,
        design: designData.fuelPressure?.design,
      },
      {
        name: gtDesignDataVariables.fuelTemperature,
        unit: designData.fuelTemperature?.unit,
        design: designData.fuelTemperature?.design,
      },
      {
        name: gtDesignDataVariables.mineralLubeOil,
        design: designData.mineralLubeOil?.design,
      },
      {
        name: gtDesignDataVariables.syntheticLubeOil,
        design: designData.syntheticLubeOil?.design,
      },
    ];
    this.setState({
      displayData: _.cloneDeep(tempDisplayData),
      copyDisplayData: _.cloneDeep(tempDisplayData),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      addDesignData,
      gasTurbineConfigData,
    } = this.props.gasTurbineReducer;
    const { isSuccessDataUnitFetch } = this.props.dataUnitState.apiState;

    if (
      isSuccessDataUnitFetch &&
      prevProps.dataUnitState.apiState.isSuccessDataUnitFetch !==
        isSuccessDataUnitFetch
    ) {
      this.setUnits();
    }
    if (
      gasTurbineConfigData.isSuccess &&
      prevProps.gasTurbineReducer.gasTurbineConfigData.isSuccess !==
        gasTurbineConfigData.isSuccess
    ) {
      this.setData();
    }
    if (
      gasTurbineConfigData.isError &&
      prevProps.gasTurbineReducer.gasTurbineConfigData.isError !==
        gasTurbineConfigData.isError
    ) {
      this.setState({ displayData: initialData, copyDisplayData: initialData });
      this.setData();
    }
    if (
      addDesignData.isSuccess &&
      prevProps.gasTurbineReducer.addDesignData.isSuccess !==
        addDesignData.isSuccess
    ) {
      this.disableEditForm();
      this.setState({
        saveListener: false,
        editTableRows: { isoData: [], reqData: [], othData: [] },
      });
      Notification.show(Types.Success, MotorDesignDataMessages.SUCCESS);
    }
    if (
      addDesignData.isError &&
      prevProps.gasTurbineReducer.addDesignData.isError !==
        addDesignData.isError
    ) {
      Notification.show(Types.Error, MotorDesignDataMessages.ERROR);
    }
  }
  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  }
  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onEdit = (row, tableName) => {
    const { editTableRows } = this.state;
    let editTable = editTableRows;

    editTable[tableName].push(row);
    editTable[tableName].sort();
    this.setState(
      {
        editTableRows: editTable,
      },
      () => this.enableEditForm()
    );
  };

  onUndo = (row, tableName) => {
    const { editTableRows, displayData, copyDisplayData } = this.state;
    const editTable = editTableRows;
    editTable[tableName] = editTable[tableName].filter(
      (editRow) => editRow !== row
    );
    const tempDisplayData = _.cloneDeep(displayData);
    tempDisplayData[row] = _.cloneDeep(copyDisplayData[row]);

    this.setState(
      {
        editTableRows: editTable,
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  onReset = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      displayData: this.state.copyDisplayData,
      visible: false,
    });
    this.disableEditForm();
  };
  onSave = () => {
    const {
      location: { state },
      saveDesignData,
    } = this.props;
    const { displayData } = this.state;
    this.setState({
      saveListener: true,
    });
    if (
      (displayData[0].design || displayData[0].design === 0) &&
      (displayData[2].design || displayData[2].design === 0) &&
      (displayData[3].design || displayData[3].design === 0) &&
      displayData[3].unit &&
      (displayData[4].design || displayData[4].design === 0) &&
      displayData[4].unit &&
      (displayData[7].design || displayData[7].design === 0) &&
      displayData[7].unit &&
      (displayData[8].design || displayData[8].design === 0) &&
      displayData[8].unit &&
      (displayData[9].design || displayData[9].design === 0) &&
      displayData[9].unit &&
      (displayData[10].design || displayData[10].design === 0) &&
      displayData[10].unit &&
      (displayData[11].design || displayData[11].design === 0) &&
      displayData[11].unit &&
      (displayData[12].design || displayData[12].design === 0) &&
      (displayData[13].design || displayData[13].design === 0)
    ) {
      const data = KeyChange;
      let payload = {};
      displayData.forEach((item) => {
        if (
          (item.name === gtDesignDataVariables.gtIsoPower &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.gtIsoSpeed &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.configSpeed &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.gtIsoHeatRate &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.gtIsoExhaustFlowRate &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.gtDriverOutputSpeedRangeMax &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.gtDriverOutputSpeedRangeMin &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.startupSpeed &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.startupPower &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.referenceAmbientPressure &&
            (item.design || item.design === 0)) ||
          (item.name ===
            gtDesignDataVariables.defaultAxialCompressorInletPressure &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.airCompressorMaximumTipSpeed &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.turbineMaximumTipSpeed &&
            (item.design || item.design === 0)) ||
          (item.name ===
            gtDesignDataVariables.combustorMaxAllowableTemperatureVariation &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.ratedFuelMoleWeight &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.ratedFuelLHV &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.fuelPressure &&
            (item.design || item.design === 0)) ||
          (item.name === gtDesignDataVariables.fuelTemperature &&
            (item.design || item.design === 0))
        ) {
          payload[data[item.name]] = {
            unit: item.unit,
            design: item.design,
          };
        } else if (
          item.design ||
          item.design === 0 ||
          item.name === gtDesignDataVariables.cycle ||
          item.name === gtDesignDataVariables.gtPowerCorrectionFactor ||
          item.name === gtDesignDataVariables.gtPowerEstCorrectionFactor ||
          item.name === gtDesignDataVariables.airCompressorStages ||
          item.name === gtDesignDataVariables.airCompressorType ||
          item.name ===
            gtDesignDataVariables.airCompressorPressureRatioISOConditions ||
          item.name === gtDesignDataVariables.airCompressorCasingSplit ||
          item.name === gtDesignDataVariables.airCompressorRotor ||
          item.name === gtDesignDataVariables.turbineStages ||
          item.name === gtDesignDataVariables.turbineCasingSplit ||
          item.name === gtDesignDataVariables.turbineRotor ||
          item.name === gtDesignDataVariables.combustorType ||
          item.name === gtDesignDataVariables.combustorQuantity ||
          item.name === gtDesignDataVariables.fuelNozzlesPerCombustor ||
          item.name === gtDesignDataVariables.combustorConfiguration ||
          item.name === gtDesignDataVariables.fuelType ||
          item.name === gtDesignDataVariables.mineralLubeOil ||
          item.name === gtDesignDataVariables.syntheticLubeOil
        ) {
          payload[data[item.name]] = { design: item.design };
        }
      });
      if (
        this.handleNetwork() &&
        state &&
        state.componentData.info._id &&
        payload
      ) {
        saveDesignData(state.componentData.info._id, payload);
      }
    } else {
      Notification.show(Types.Error, MotorDesignDataMessages.FIELDS_DIRTY);
    }
  };

  handleChange = (val, name, type) => {
    this.enableEditForm();
    const { displayData } = this.state;
    const tempDisplayData = displayData.map((item) => {
      if (item.name === name && type === 'number') {
        return { name, unit: item.unit, design: parseFloat(val) };
      } else if (item.name === name && type === 'select') {
        return { name, unit: item.unit, design: val };
      } else if (item.name === name && type === 'unit') {
        return { name, unit: val, design: item.design };
      }
      return item;
    });
    this.setState(
      {
        displayData: _.cloneDeep(tempDisplayData),
      },
      () => this.enableEditForm()
    );
  };

  renderModal() {
    const { visible } = this.state;
    if (visible) {
      return (
        <ConfirmModal
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          title={DISCARD_CHANGES}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }
  render() {
    const {
      gasTurbineConfigData,
      addDesignData,
    } = this.props.gasTurbineReducer;
    const { saveListener, unitData } = this.state;
    const { isSchematicEditable } = this.props;
    return (
      <div className="DesignDataMotor">
        {(gasTurbineConfigData.isLoading || addDesignData.isLoading) && (
          <Loader />
        )}
        <CustomTable
          showHeader={true}
          columns={DesignDataShow({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterConfigIsoCondData(this.state.displayData)}
          editableRow={this.state.editTableRows.isoData}
        />
        <CustomTable
          showHeader={true}
          columns={ReqdCalcDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterReqdData(this.state.displayData)}
          editableRow={this.state.editTableRows.reqData}
        />
        <CustomTable
          showHeader={true}
          columns={OtherDesignDataTable({
            handleChange: this.handleChange,
            onEdit: this.onEdit,
            onUndo: this.onUndo,
            saveListener,
            unitData,
            isSchematicEditable,
          })}
          data={filterOtherDesignData(this.state.displayData)}
          editableRow={this.state.editTableRows.othData}
        />
        {this.state.formEdit && (
          <div className="config-bottom-buttons">
            <button className="btn-default btn-white" onClick={this.onReset}>
              {ButtonVariables.RESET}
            </button>
            <button className="btn-default" onClick={this.onSave}>
              {ButtonVariables.SAVE}
            </button>
          </div>
        )}

        {this.renderModal()}
      </div>
    );
  }
}
DesignDataGasTurbine.propTypes = {
  gasTurbineConfigData: PropType.object,
  addDesignData: PropType.object,
  location: PropType.object,
  saveDesignData: PropType.func,
  network: PropType.object,
  isMotorData: PropType.bool,
  clearMotorData: PropType.func,
  isSchematicEditable: PropType.bool,
  isUnit: PropType.bool,
  clearUnitData: PropType.func,
};

DesignDataGasTurbine.defaultProps = {
  isSchematicEditable: true,
};
const mapStateToProps = (state) => {
  return {
    network: state.NetworkReducer,
    gasTurbineReducer: state.gasTurbineReducer,
    dataUnitState: state.DataUnitReducer,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  saveDesignData: (componentId, payload) =>
    dispatch(saveDesignData(componentId, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(DesignDataGasTurbine)
);
