import React from "react";
import { Icon, Input } from "antd";
import _ from "lodash";
import { BypassValveVariables } from "../../../../../constants/variables";
import * as Pattern from "../../../../../constants/pattern";
import { BypassValveMessages } from "../../../../../constants/messages";
import { newValidateNumber } from "../../../../../libs/validator";
import { validateNegativeNumber } from "../../../../../common/methods";

export const DataUnit = [
  "Diameter",
  "Speed",
  "Volumetric Flow",
  "Head Drop",
  "Efficiency",
  "Valve Nominal Diameter",
  "Inlet Pipe Diameter",
  "Outlet Pipe Diameter",
  "Characterstics",
];
export const characterstics = ["Linear", "Quick Opening", "Equal Percentage"];

export const getUnit = (units, key) => {
  for (let i = 0; units && i < units.length; i++) {
    if (units[i].dataVariable === key) {
      return units[i].units;
    }
  }
  return [];
};

export const formFieldsValveDescription = (data) => [
  {
    label: BypassValveVariables.COMPONENT_TITLE,
    key: "title",
    type: "text",
    max: 50,
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    pattern: [Pattern.Name.regx, Pattern.Name.message],
    disabled: !data.isSchematicEditable,
  },
  {
    label: BypassValveVariables.TAG_NUMBER,
    key: "tagNumber",
    type: "text",
    max: 50,
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    pattern: [Pattern.Name.regx, Pattern.Name.message],
    disabled: !data.isSchematicEditable,
  },
  {
    label: BypassValveVariables.EQUIPMENT_TYPE,
    key: "equipmentType",
    type: "dropdownWithAdd",
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    data: data.equipmentTypeData.map((item) => item.type),
    openAddModal: () => data.openAddModal("isEquipment"),
    addMessage: "Add Equipment Type",
    disabled: !data.isSchematicEditable,
  },
  {
    label: BypassValveVariables.EQUIPMENT_MANUFACTURER,
    key: "equipmentManufacturer",
    type: "dropdownWithAdd",
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    data: data.equipmentManufacturerData.map((item) => item.name),
    openAddModal: () => data.openAddModal("isEquipmentManufacturer"),
    addMessage: "Add Equipment Manufacturer",
    disabled: !data.isSchematicEditable,
  },
  {
    label: BypassValveVariables.EQUIPMENT_MODEL,
    key: "equipmentModel",
    type: "text",
    max: 50,
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    pattern: [Pattern.Name.regx, Pattern.Name.message],
    disabled: !data.isSchematicEditable,
  },
];

export const formFieldsValveData = (units) => [
  {
    label: BypassValveVariables.CHARACTERSTICS,
    key: "characteristic",
    type: "dropdown",
    data: characterstics,
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.VALVE_NOMINAL_DIAMETER,
    key: "valveNominalDiameter",
    type: "textWithDrop",
    max: 15,
    width: "72px",
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    dropDownKey: "unitValveNominalDiameter",
    dropDownData: getUnit(units.unitData, "Valve Nominal Diameter"),
    dropDownDefaultValue: getUnit(units.unitData, "Valve Nominal Diameter")[0],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.INLET_PIPE_DIAMETER,
    key: "inletPipeDiameter",
    type: "textWithDrop",
    max: 15,
    width: "72px",
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    dropDownKey: "unitInletPipeDiameter",
    dropDownData: getUnit(units.unitData, "Inlet Pipe Diameter"),
    dropDownDefaultValue: getUnit(units.unitData, "Inlet Pipe Diameter")[0],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.OUTLET_PIPE_DIAMETER,
    key: "outletPipeDiameter",
    type: "textWithDrop",
    max: 15,
    width: "72px",
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    dropDownKey: "unitOutletPipeDiameter",
    dropDownData: getUnit(units.unitData, "Outlet Pipe Diameter"),
    dropDownDefaultValue: getUnit(units.unitData, "Outlet Pipe Diameter")[0],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.FLOW_COEFFICIENT,
    key: "flowCoefficient",
    max: 15,
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.PIPING_GEOMETRY_FACTOR,
    key: "pipingGeometryFactor",
    max: 15,
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.BYPASS_VALVE_CALCULATION_THRESHOLD,
    key: "bypassValveCalculationThreshold",
    required: [true, BypassValveMessages.REQUIRED_FIELD],
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    disabled: !units.isSchematicEditable,
  },
  {
    label: BypassValveVariables.VELOCITY_HEAD_LOSS_COEFFICIENT,
    key: "velocityHeadLossCoefficient",
    max: 15,
    pattern: [Pattern.PositiveNumber, Pattern.NumberMessage],
    disabled: !units.isSchematicEditable,
  },
];

export const dataVariables = {
  dataVariables: ["Valve Nominal Diameter", "Inlet Pipe Diameter"],
};

export const byPassTheoraticalTableColumns = (config) => [
  {
    title: BypassValveVariables.VALVE_POSITION,
    dataIndex: "valvePosition",
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="valvePosition"
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.change(e.target, row);
          }}
          disabled={editable}
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          className={
            _.isEmpty(value.trim()) || validateNegativeNumber(value)
              ? "error"
              : "not-error"
          }
          autoComplete="off"
        />
      );
    },
  },
  {
    title: BypassValveVariables.VALVE_FLOW_COEFFICIENT,
    dataIndex: "valveFlowCoefficient",
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? "" : value.toString();
      return (
        <Input
          name="valveFlowCoefficient"
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.change(e.target, row);
          }}
          disabled={editable}
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          className={
            _.isEmpty(value.trim()) || validateNegativeNumber(value)
              ? "error"
              : "not-error"
          }
          autoComplete="off"
        />
      );
    },
  },
  config.isSchematicEditable && {
    title: BypassValveVariables.ACTIONS,
    render: (value, row, col, disabled) => (
      <div className="more-item-wrapper">
        <div className="more-items">
          {disabled && (
            <button
              onClick={(e) => {
                config.edit(row);
              }}
            >
              <Icon type="edit" />
            </button>
          )}
          {!disabled && (
            <button
              onClick={(e) => {
                config.undo(row);
              }}
            >
              <Icon type="undo" />
            </button>
          )}
          {
            <button
              onClick={(e) => {
                config.delete(row);
              }}
            >
              <Icon type="delete" />
            </button>
          }
        </div>
      </div>
    ),
  },
];

export const byPassTheoraticalTableAddRow = (actions) => [
  {
    title: BypassValveVariables.VALVE_POSITION,
    dataIndex: "valvePosition",
    render: (value, row, col) => (
      <Input
        name="valvePosition"
        defaultValue={value}
        value={value}
        onChange={(e) => {
          actions.change(e.target);
        }}
        className={actions.isError.valvePosition ? "error" : "not-error"}
        onKeyDown={(e) => {
          newValidateNumber(e);
        }}
        autoComplete="off"
      />
    ),
  },
  {
    title: BypassValveVariables.VALVE_FLOW_COEFFICIENT,
    dataIndex: "valveFlowCoefficient",
    render: (value, row, col) => {
      value = `${value}`;
      return (
        <Input
          name="valveFlowCoefficient"
          defaultValue={value}
          value={value}
          onChange={(e) => {
            actions.change(e.target);
          }}
          onKeyDown={(e) => {
            newValidateNumber(e);
          }}
          autoComplete="off"
          className={
            actions.isError.valveFlowCoefficient ? "error" : "not-error"
          }
        />
      );
    },
  },
  {
    title: BypassValveVariables.ACTIONS,
    dataIndex: "name",
    render: (value, row, col) => (
      <button
        className="btn-default btn-small"
        onClick={(e) => {
          actions.submit(e.target);
        }}
      >
        Add
      </button>
    ),
  },
];

export const data = {
  valveCharacteristicCurveTheoretical: [],
  valveCharacteristicCurveOperational: [],
};

export const initialValue = {
  valveCharacteristicCurveTheoretical: [],
  valveCharacteristicCurveOperational: [],
  bypassValveDescription: {
    tagNumber: "",
    title: "",
    equipmentManufacturer: "",
    equipmentModel: "",
    equipmentType: "",
  },
  bypassValveData: {
    valveNominalDiameter: {
      unit: "yd",
      value: "",
    },
    inletPipeDiameter: {
      unit: "yd",
      value: "",
    },
    outletPipeDiameter: {
      unit: "yd",
      value: "",
    },
    velocityHeadLossCoefficient: "",
    pipingGeometryFactor: "",
    bypassValveCalculationThreshold:"",
    characteristic: "",
    flowCoefficient: "",
  },
};
