import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import './RenderAlertInfoHeader.scss'
import { useSelector } from 'react-redux';
import { ButtonVariables } from '../../../../../../constants/variables';
import { roles } from '../../../../../../permissions';
import { actions, ALERT_ACTIONS_LIST } from '../../Alerts.constants';
import ComponentToPrint from '../../PrintAlertDetails';
import { Spin } from 'antd';

const RenderAlertInfoHeader = (props, alertsData, stateData) => {

    let userRoleState = useSelector((state) => state.LoginReducer);
    let AlertAssigneesReducer = useSelector((state) => state.AlertAssigneesReducer);
    let discussionState = useSelector((state) => state.DiscussionThreadReducer);
    let kpiPlotData = useSelector((state) => state.AlertPlotKPIReducer.kpisDataState);
    let kpiData = useSelector((state) => state.AlertPlotKPIReducer.data);
    const [width, setWidth] = useState(window.innerWidth);
    const [isSmall, setIsSmall] = useState(false)
    const [plotsLoaded, setPlotLoaded] = useState(false)

    useEffect(() => {
        if (alertsData._id) {
            //  dispatch(getMessages(alertsData._id, AssetMessages.COMPONENT_PAGE_COUNT, 0))
            stateData.setMechademyActions(alertsData.mechademyStatus)
        }
    }, [alertsData]) // eslint-disable-line

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        if (width <= 691) {
            setIsSmall(true)
            if (
                document.querySelector('.close-button').querySelectorAll('*')[0]
                    .className === 'anticon anticon-left'
            ) {
                document.querySelector('.close-button').click();
            }
        }
        else setIsSmall(false)
    }, [width]);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    useEffect(() => {
        setTimeout(() => {
            setPlotLoaded(true)
        }, 8000)
    })
    const handleActionChange = (value) => {
        stateData.setMechademyActions(value)
        stateData.setActionChanged(true);
    }
    let styling = {
        minHeight: '2.5rem',
        minWidth: '1rem',
        borderRadius: '50',
    };
    const getPermissionDetails = () => {
        const { accessInfo } = props;
        return accessInfo && accessInfo.write;
    };

    const renderAlertActions = () => {
        const { userId, userRole } = userRoleState.apiState;
        return (
            <div style={{ display: "flex" }}>
                <div>
                    {userRoleState.accessInfo && (
                        <div className="bottom-buttons">
                            {userRole === roles.MECHADEMY_ADMIN ||
                                userRole === roles.CLIENT_ADMIN || userRole === roles.MECHADEMY_FACILITY_MANAGER ? (
                                <Button variant='contained'
                                    type="button"
                                    style={isSmall ? styling : {}}
                                    className="selected_tab"
                                    onClick={() => stateData.HandleAlertSave()}
                                >
                                    {stateData.mechademyActions === ALERT_ACTIONS_LIST.FORWARD && ((stateData.userRole === roles.MECHADEMY_ADMIN) || (stateData.userRole === roles.MECHADEMY_FACILITY_MANAGER))
                                        && alertsData && alertsData.mechademyAction !== ALERT_ACTIONS_LIST.FORWARD
                                        ? ButtonVariables.SEND
                                        : ButtonVariables.SAVE}
                                </Button>
                            ) : getPermissionDetails() ? (
                                <Button variant='contained'
                                    type="button"
                                    className="selected_tab"
                                    onClick={() => stateData.HandleAlertSave()}
                                >
                                    {ButtonVariables.SAVE}
                                </Button>
                            ) : null}
                        </div>
                    )}
                </div>
                <div className="alert_actions" style={{ textAlign: "left" }}>
                    {((userRole === roles.MECHADEMY_ADMIN &&
                        (_.isEmpty(alertsData.assignees) ||
                            _.indexOf(alertsData.assignees, userId) !== -1)) ||
                        (userRole === roles.CLIENT_ADMIN &&
                            (_.isEmpty(alertsData.assignees) ||
                                _.indexOf(alertsData.assignees, userId) !== -1)) ||
                        (getPermissionDetails() &&
                            (_.isEmpty(alertsData.assignees) ||
                                _.indexOf(alertsData.assignees, userId) !== -1))) && (
                            <FormControl className='alert_actions_list'>
                                <InputLabel id="demo-simple-select-label">Actions</InputLabel>
                                <Select
                                    onChange={(e) => handleActionChange(e.target.value)}
                                    disabled={
                                        userRole === roles.MECHADEMY_ADMIN ||
                                            userRole === roles.MECHADEMY_FACILITY_MANAGER
                                            ? alertsData.mechademyStatus ===
                                            ALERT_ACTIONS_LIST.DISCARD ||
                                            alertsData.mechademyStatus === ALERT_ACTIONS_LIST.CLOSE
                                            : alertsData.clientStatus === ALERT_ACTIONS_LIST.DISCARD ||
                                            alertsData.clientStatus === ALERT_ACTIONS_LIST.CLOSE
                                    }
                                    value={stateData.actionChanged && stateData.mechademyActions}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Actions"
                                    className='action_selection_menu'
                                >
                                    {actions(
                                        userRole === roles.MECHADEMY_ADMIN ||
                                            userRole === roles.MECHADEMY_FACILITY_MANAGER
                                            ? alertsData.mechademyStatus
                                            : alertsData.clientStatus,
                                        props.info
                                    ).map((item) => {
                                        return (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                </div>
            </div>
        )
    }
    const { userRole } = userRoleState.apiState;
    return (
        <div className='alert_info_action_button' style={{ marginLeft: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderAlertActions()}
                <Grid item xs={4} md={3} lg={4} className='print_button'>
                    {plotsLoaded ? (<ComponentToPrint
                        isSmall={isSmall}
                        styling={styling}
                        alertsData={alertsData}
                        plotData={props.data}
                        assignees={AlertAssigneesReducer.data}
                        discussion={discussionState}
                        userRole={userRole}
                        descriptionText={alertsData.description ? alertsData.description : ""}
                        info={props.info}
                        kpiPlotData={kpiPlotData}
                        kpiData={kpiData}
                    />) :
                        <Button variant='contained'
                            style={{color:"white", backgroundColor:"#373b4e"}}
                            type="button"
                            className="selected_tab"
                            disabled
                            >
                            <Spin style={{paddingTop:"2px" }} />
                        </Button>
                    }
                </Grid>
            </div>
        </div>
    )
}

export default RenderAlertInfoHeader