import {
  FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS,
  FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS,
  // FETCH_ALERT_KPIS_BY_LEVEL_ERROR,
  FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE,
  ADD_ALERT_KPIS_PROGRESS,
  ADD_ALERT_KPIS_SUCCESS,
  ADD_ALERT_KPIS_ERROR,
  ADD_ALERT_KPIS_CLEAR_STATE,
  FETCH_ALERT_PLOT_DATA_PROGRESS,
  FETCH_ALERT_PLOT_DATA_SUCCESS,
  // FETCH_ALERT_PLOT_DATA_ERROR,
  FETCH_ALERT_PLOT_DATA_CLEAR_STATE,
  UPDATE_ALERT_KPI_PROGRESS,
  UPDATE_ALERT_KPI_SUCCESS,
  UPDATE_ALERT_KPI_CLEAR_STATE,
  FETCH_ALERT_PLOT_DATA_ERROR,
  SET_TIME_RANGE_VISIBILITY_ON,
  SET_TIME_RANGE_VISIBILITY_OFF,
  UPDATE_ALERT_KPI_BULK_SUCCESS,
  BULK_UPDATE_ALERT_KPI_CLEAR_STATE,
  DELETE_ALERT_KPI_SUCCESS,
  DELETE_ALERT_KPI_CLEAR_STATE,
  SET_ZOOM_IN,
  SET_ZOOM_OUT,
  SET_META_DATA_ON,
  SET_META_DATA_OFF,
  CLEAR_DATA_UPDATE_STATE
  // UPDATE_ALERT_KPI_ERROR,
  // UPDATE_ALERT_KPI_CLEAR_STATE
} from '../Types/alertPlots.types';
import _ from 'lodash';

const INITIAL_CARD_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errorCode: null,
  errorMsg: null,
  viewDateFrom: null,
  viewDateTill: null,
};

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  errorCode: null,
  message: null,
  kpisDataState: {},
  isKPIAddSuccess: false,
  isDataFetchSuccess: false,
  dataProcessed: false,
  newCardData: null,
  isKPIUpdateSuccess: false,
  isDateTimeVisible: false,
  bulkUpdateSuccess: false,
  deleteKPISuccess: false,
  needUpdate: true,
};

export default function AlertPlotKPIReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALERT_KPIS_BY_LEVEL_PROGRESS:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case FETCH_ALERT_KPIS_BY_LEVEL_SUCCESS:
      const obj = {};
      action.payload &&
        action.payload.data.length &&
        action.payload.data.forEach((kpiId) => {
          obj[kpiId._id] = {
            ...INITIAL_CARD_STATE,
            data: {
              y: kpiId.axis.y.map((kpiData) => ({
                _id: kpiData.value,
                data: [],
              })),
            },
          };
        });
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
        kpisDataState: obj,
      };
    case ADD_ALERT_KPIS_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ALERT_KPIS_SUCCESS:
      const tempData = state.data;
      const newObj = {};
      tempData.push(action.payload);
      newObj[action.payload._id] = {
        ...INITIAL_CARD_STATE,
        data: {
          y: action.payload.axis.y.map((kpiData) => ({
            _id: kpiData.value,
            data: [],
          })),
        },
      };
      return {
        ...state,
        isKPIAddSuccess: true,
        data: tempData,
        kpisDataState: {
          ...state.kpisDataState,
          ...newObj,
        },
        newCardData: action.payload,
      };
    case ADD_ALERT_KPIS_ERROR:
      return {
        ...state,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case FETCH_ALERT_PLOT_DATA_PROGRESS:
      return {
        ...state,
        isLoading: true,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload]: {
            ...state.kpisDataState[action.payload],
            isLoading: true,
          },
        },
      };
    case FETCH_ALERT_PLOT_DATA_SUCCESS:
      let series = [];
      if (action.payload.response.series) {
        series = action.payload.response.series.map((pitag) => {
          let data = [];
          let metaMaxMinData = [];
          let metaMaxMinPercentileData = [];
          data = pitag.data.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinData = pitag.maxMinData.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          metaMaxMinPercentileData = pitag.maxMinPercentile.filter(
            (ele) =>
              ele[0] >= action.payload.viewDateFrom &&
              ele[0] <= action.payload.viewDateTill &&
              ele[1] !== null
          );
          data = data.sort();
          metaMaxMinData = metaMaxMinData.sort();
          metaMaxMinPercentileData = metaMaxMinPercentileData.sort();
          return {
            name: pitag.name,
            data: data,
            tooltip: pitag.tooltip,
            yAxis: pitag.yAxis,
            _id: pitag.tag,
            metaMaxMinData: metaMaxMinData,
            metaMaxMinPercentileData: metaMaxMinPercentileData,
          };
        });
      }
      const numberOfDays =
        (action.payload.viewDateTill - action.payload.viewDateFrom) /
        (24 * 3600) /
        1000;
      return {
        ...state,
        dataProcessed: true,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            ...state.kpisDataState[action.payload.kpiId],
            isLoading: false,
            isError: false,
            isSuccess: true,
            data: _.cloneDeep(series),
            viewDateFrom: parseInt(action.payload.viewDateFrom),
            viewDateTill: parseInt(action.payload.viewDateTill),
            errorCode: null,
            errorMsg: null,
            numberOfDays: numberOfDays,
            zoomIn: action.payload.refreshZoom
              ? false
              : state.kpisDataState[action.payload.kpiId].zoomIn,
            initDateFrom: action.payload.refreshZoom
              ? null
              : state.kpisDataState[action.payload.kpiId].initDateFrom,
            initDateTill: action.payload.refreshZoom
              ? null
              : state.kpisDataState[action.payload.kpiId].initDateTill,
          },
        },
      };
    case FETCH_ALERT_PLOT_DATA_ERROR:
      return {
        ...state,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };
    case ADD_ALERT_KPIS_CLEAR_STATE:
      return {
        ...state,
        isKPIAddSuccess: false,
        newCardData: null,
      };
    case FETCH_ALERT_PLOT_DATA_CLEAR_STATE:
      return {
        ...state,
        dataProcessed: false,
      };
    case FETCH_ALERT_KPIS_BY_LEVEL_CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
      };
    case UPDATE_ALERT_KPI_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ALERT_KPI_SUCCESS:
      const alertData = state.data;
      const indexToUpdate = alertData.findIndex(
        (item) => item._id === action.payload['_id']
      );
      alertData[indexToUpdate] = _.cloneDeep(action.payload);
      return {
        ...state,
        data: alertData,
        isKPIUpdateSuccess: true,
        isLoading: false,
        needUpdate: action.needUpdate,
      };
    case UPDATE_ALERT_KPI_CLEAR_STATE:
      return {
        ...state,
        isKPIUpdateSuccess: false,
        needUpdate: true,
      };
    case SET_TIME_RANGE_VISIBILITY_ON:
      return {
        ...state,
        isDateTimeVisible: true,
      };
    case SET_TIME_RANGE_VISIBILITY_OFF:
      return {
        ...state,
        isDateTimeVisible: false,
      };
    case UPDATE_ALERT_KPI_BULK_SUCCESS:
      let tempKpiData = _.cloneDeep(state?.data);
      tempKpiData.forEach((kpi) => {
        kpi.inspection = action.updateBody.inspection;
      });
      return {
        ...state,
        data: tempKpiData,
        bulkUpdateSuccess: true,
        dataUpdateProgress: true
      };
    case BULK_UPDATE_ALERT_KPI_CLEAR_STATE:
      return {
        ...state,
        bulkUpdateSuccess: false,
      };
    case DELETE_ALERT_KPI_SUCCESS:
      let tempKPIData = state?.data;
      tempKPIData.find(
        (card, i) =>
          card._id === action.payload.kpiId && tempKPIData.splice(i, 1)
      );
      delete state.kpisDataState[action.payload.kpiId];
      return {
        ...state,
        data: tempKPIData,
        deleteKPISuccess: true,
      };
    case DELETE_ALERT_KPI_CLEAR_STATE:
      return {
        ...state,
        deleteKPISuccess: false,
      };
    case SET_ZOOM_IN:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            ...state.kpisDataState[action.payload.kpiId],
            zoomIn: true,
            initDateFrom: action.payload.dateFrom,
            initDateTill: action.payload.dateTill,
          },
        },
      };
    case SET_ZOOM_OUT:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload.kpiId]: {
            ...state.kpisDataState[action.payload.kpiId],
            zoomIn: false,
            initDateFrom: action.payload.dateFrom,
            initDateTill: action.payload.dateTill,
          },
        },
      };
    case SET_META_DATA_ON:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload]: {
            ...state.kpisDataState[action.payload],
            metaData: true,
          },
        },
      };
    case SET_META_DATA_OFF:
      return {
        ...state,
        kpisDataState: {
          ...state.kpisDataState,
          [action.payload]: {
            ...state.kpisDataState[action.payload],
            metaData: false,
          },
        },
      };
    case CLEAR_DATA_UPDATE_STATE:
      return  {
        ...state,
        dataUpdateProgress: false
      }
    default:
      return state;
  }
}
