import {
  RUNNERDATA_ADD_EDIT_ERROR,
  RUNNERDATA_ADD_EDIT_PROGRESS,
  RUNNERDATA_ADD_EDIT_SUCCESS,
  RUNNERDATA_CLEAR_STATE,
} from "../Types/runnerData.types";

import { PUTRequest } from "../../api/request";

const runnerAddEditError = (err) => ({
  type: RUNNERDATA_ADD_EDIT_ERROR,
  payload: err,
});
const runnerAddEditProgress = () => ({ type: RUNNERDATA_ADD_EDIT_PROGRESS });
const runnerAddEditSuccess = (res) => ({
  type: RUNNERDATA_ADD_EDIT_SUCCESS,
  payload: res.body,
});

export function addEditRunnerData(id, payload, type) {
  const componentAPIMapper = {
    "Centrifugal liquid expander": `components/${id}/liquidexpanderconfigs/runner`,
    "Centrifugal compressor": `components/${id}/compressorconfigs/impeller`,
    "Centrifugal pump": `components/${id}/pumpconfigs/impeller`,
  };
  return function (dispatch) {
    dispatch(runnerAddEditProgress());
    PUTRequest(componentAPIMapper[type], payload)
      .then((res) => {
        dispatch(runnerAddEditSuccess(res));
      })
      .catch((err) => {
        dispatch(runnerAddEditError(err));
      });
  };
}

export function clearRunnerReducerState() {
  return function (dispatch) {
    dispatch({ type: RUNNERDATA_CLEAR_STATE });
  };
}
