import React from 'react';
import RenderAlertInfoHeader from './components/RenderAlerInfoHeader';
import RenderAlertInfo from './components/RenderAlertInfo';
import RenderHeader from './components/RenderHeader';

const AlertInfo = (props, stateData) => {
    const { data } = props
    
    return (
        <div className='alertInfo'>
        {RenderAlertInfoHeader(props, data, stateData)}
        {RenderHeader('Alert Info','0rem')}
        {RenderAlertInfo(data, stateData)}
        </div>
    )
}

export default AlertInfo