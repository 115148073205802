import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

class RouterOutlet extends React.Component {
  getRoutes() {
    const { routes } = this.props;
    return (
      routes &&
      routes.map((route, index) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact ? route.exact : false}
          render={({ location }) =>
            route.hasOwnProperty("active") && !route.active() ? (
              <Redirect
                to={{
                  pathname: route.redirect,
                  state: { from: location },
                }}
              />
            ) : (
                <route.component routes={route.children} />
            )
          }
        />
      ))
    );
  }

  render() {
    return <Switch>{this.getRoutes()}</Switch>;
  }
}

export default RouterOutlet;
