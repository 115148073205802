import _ from "lodash";
import {
  GET_RUNTAGS_ERROR,
  GET_RUNTAGS_PROGRESS,
  GET_RUNTAGS_SUCCESS,
  TAGS_BY_CARDTYPE_CLEAR_STATE,
  TAGS_BY_CARDTYPE_FETCH_ERROR,
  TAGS_BY_CARDTYPE_FETCH_PROGRESS,
  TAGS_BY_CARDTYPE_FETCH_SUCCESS,
} from "./addNewCard.types";

const INITIAL_API_STATE = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorCode: null,
  message: null,
};

const INITIAL_STATE = {
  ...INITIAL_API_STATE,
  data: null,
};

export default function TagsByCardTypeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TAGS_BY_CARDTYPE_FETCH_PROGRESS:
    case GET_RUNTAGS_PROGRESS:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isLoading: true,
      };
    case TAGS_BY_CARDTYPE_FETCH_ERROR:
    case GET_RUNTAGS_ERROR:
      return {
        ...state,
        ...INITIAL_API_STATE,
        isError: true,
        errorCode: action.payload.response && action.payload.response.body.code,
        message:
          action.payload.response && action.payload.response.body.message,
      };

    case TAGS_BY_CARDTYPE_FETCH_SUCCESS:
    case GET_RUNTAGS_SUCCESS:
      const data = _.cloneDeep(action.payload);
      let tags = [];
      tags =
        data.length &&
        data
          .filter((element) => element.piTag)
          .map((tagElement) => {
            if (tagElement.piTag) {
              const { piTag, _id, type } = tagElement;
              if (tagElement.variableName) {
                if (tagElement.dataType === "float") {
                  const piVariableName = tagElement.variableName;
                  return {
                    name: piTag,
                    _id,
                    type,
                    variableName: piVariableName,
                    variableNameTag: `${piVariableName} (${piTag})`,
                  };
                }
              } else {
                const { piVariableName } = tagElement;
                return {
                  name: piTag,
                  _id,
                  type,
                  variableName: piVariableName,
                  variableNameTag: `${piVariableName} (${piTag})`,
                };
              }
            }
            return undefined;
          });
      tags = tags && tags.length && tags.filter((tag) => tag);
      return {
        ...state,
        ...INITIAL_API_STATE,
        isSuccess: true,
        data: tags,
      };

    case TAGS_BY_CARDTYPE_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return {
        ...state,
      };
  }
}
