import React from 'react'
import { Form, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../constants/global.scss';
import * as Routes from '../../constants/routes';
import Notification, { Types } from '../../common/Notification/Notification';
import { isLoggedIn } from '../../libs/token';
import { bg_screen } from '../../assets/bg_screen';
import { bg_star } from '../../assets/bg_star';
import { logo } from '../../assets/logo';
import { forgotPassword, ButtonVariables } from '../../constants/variables';
import * as VersionJson from '../../version.json';
import {
  LoginMessages,
  forgotPasswordMessages,
  NO_INTERNET,
  LOGGEDIN_WARNING,
} from '../../constants/messages';
import { setForgotPassword, clearForgotPasswordState } from '../../redux/actions/forgotPassword.action';
import Loader from '../../common/Loader/Loader';
import cross from '../../assets/ic_cross/cross.svg';
import ReCAPTCHA from "react-google-recaptcha";

class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      value: null
    }
  }
  componentDidMount() {
    if (isLoggedIn()) {
      Notification.show(Types.Warning, LOGGEDIN_WARNING);
      this.props.history.push(Routes.app);
    }
  }

  componentDidUpdate() {
    const {
      isSuccess,
      isError,
      errorCode,
    } = this.props.forgotPasswordState;

    if (isSuccess) {
      Notification.show(Types.Success, forgotPasswordMessages.SUCCESS);
      this.props.history.push(Routes.root);
    }
    if (isError) {
      this.props.clearForgotPasswordState();   
        setTimeout(window.grecaptcha.reset(), 2000);
        Notification.show(Types.Error, forgotPasswordMessages[errorCode]);
    }
  }

  componentWillUnmount() {
    this.props.clearForgotPasswordState();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(this.state.value){
          if (this.handleNetwork()) {
            if (!isLoggedIn()) {
              this.props.setForgotPassword({email: values.email, token: this.state.value});
            } else {
              Notification.show(Types.Info, LOGGEDIN_WARNING);
            }
          }
        }
        else{
          Notification.show(Types.Error, forgotPasswordMessages.REQUIRED_CAPTCHA);
        }
      }
    });
  };

  onClose = () => {
    this.props.history.push(Routes.app);
  };

  handleNetwork() {
    const { networkState } = this.props.network;
    if (networkState) {
      return true;
    } else {
      Notification.show(Types.Error, NO_INTERNET);
      return false;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoading } = this.props.forgotPasswordState;
    const sitekey = process.env.REACT_APP_RECAPTCHA_KEY;
    return (
      <div className="reset-password">
        {isLoading && <Loader />}
        <div className="logo" style={createPassowrdStyle}>
          <img src={logo} alt={'mechademy-logo'} />
          <span><small>{VersionJson?.version}</small></span>
        </div>
        <div className="sideShow" style={sideShowStyle} />
        <div className="form-div" style={createPassowrdStyle}>
          <div className="form">
            <div className="form-inner-left"></div>
            <div className="iform">
              <span> <img src={cross} alt='close' onClick={this.onClose}></img> </span>
              <br />
              <div className="welcomeText">
                <span>{forgotPassword.TITLE}</span>
              </div>
              <div className="tag">{forgotPassword.INSTRUCTION}</div>
              <Form >
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: LoginMessages.REQUIRED_EMAIL },
                      { type: 'email', message: LoginMessages.INVALID_EMAIL },
                    ],
                  })(
                    <Input
                      autoComplete="off"
                      maxLength="100"
                    />,
                  )}
                </Form.Item>
                <Form.Item style={{paddingTop: 20}} label="">
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={sitekey}
                  onChange={value => { this.setState({value: value})}}
              />
                </Form.Item>
                <Form.Item>
                  <div className="actions">
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>{ButtonVariables.SUBMIT}</Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const sideShowStyle = {
  background: `url(${bg_screen}) no-repeat`,
  backgroundSize: 'cover',
};

const createPassowrdStyle = {
  background: `url(${bg_star}) no-repeat center`,
  backgroundPosition: 'right top',
};

const mapStateToProps = state => {
  return {
    network: state.NetworkReducer,
    forgotPasswordState: state.ForgotPasswordReducer,
  }
};

const mapDispatchToProps = {
  setForgotPassword,
  clearForgotPasswordState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ForgotPassword' })(withRouter(ForgotPassword)));
