export const endPoints = {
  equipment: "equipments",
  equipmentType: "equipmenttypes/filter",
  units: "units",
  datavariables: "datavariables",
  properties: "properties",
  associations: "associations",
  uploadDV: "upload/dv",
  uploadUC: "upload/uc",
};
