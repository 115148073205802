import { GETRequest, PUTRequest } from "../../api/request";
import {
  INPUTDATA_FETCH_PROGRESS,
  INPUTDATA_FETCH_SUCCESS,
  INPUTDATA_FETCH_ERROR,
  INPUTDATA_FETCH_CLEAR,
  VARIABLENAME_FETCH_PROGRESS,
  VARIABLENAME_FETCH_SUCCESS,
  VARIABLENAME_FETCH_ERROR,
  VARIABLETAG_FETCH_PROGRESS,
  VARIABLETAG_FETCH_SUCCESS,
  VARIABLETAG_FETCH_ERROR,
  ADDINPUT_PUT_PROGRESS,
  ADDINPUTDATA_PUT_SUCCESS,
  ADDINPUTDATA_PUT_ERROR,
} from "../Types/inputData.type";

import { endPoints } from "../../libs/endpoints";

const inputDataProgress = () => ({ type: INPUTDATA_FETCH_PROGRESS });
const inputDataSuccess = (data) => ({
  type: INPUTDATA_FETCH_SUCCESS,
  payload: data,
});
const inputDataError = (error) => ({
  type: INPUTDATA_FETCH_ERROR,
  payload: error,
});
const clearInputDataValue = () => ({ type: INPUTDATA_FETCH_CLEAR });
const variableNameProgress = () => ({ type: VARIABLENAME_FETCH_PROGRESS });
const variableNameSuccess = (data) => ({
  type: VARIABLENAME_FETCH_SUCCESS,
  payload: data,
});
const variableNameError = (error) => ({
  type: VARIABLENAME_FETCH_ERROR,
  payload: error,
});
const variableTagProgress = () => ({ type: VARIABLETAG_FETCH_PROGRESS });
const variableTagSuccess = (data) => ({
  type: VARIABLETAG_FETCH_SUCCESS,
  payload: data,
});
const variableTagError = (error) => ({
  type: VARIABLETAG_FETCH_ERROR,
  payload: error,
});
const addInputDataProgress = () => ({ type: ADDINPUT_PUT_PROGRESS });
const addInputDataSuccess = (data) => ({
  type: ADDINPUTDATA_PUT_SUCCESS,
  payload: data,
});
const addInputDataError = (error) => ({
  type: ADDINPUTDATA_PUT_ERROR,
  payload: error,
});

export function inputData(e_id, c_id) {
  return function (dispatch) {
    dispatch(inputDataProgress());
    GETRequest(
      `${endPoints.equipments}/${e_id}/${endPoints.component}/${c_id}/${endPoints.input}`
    )
      .then((response) => dispatch(inputDataSuccess(response.body)))
      .catch((error) => dispatch(inputDataError(error)));
  };
}

export function clearInputData() {
  return function (dispatch) {
    dispatch(clearInputDataValue());
  };
}

export function variableName(componentId, type, keyword) {
  return function (dispatch) {
    dispatch(variableNameProgress());
    if (keyword === "") {
      GETRequest(
        `${endPoints.config}/variable?type=${type}&componentId=${componentId}`
      )
        .then((response) => dispatch(variableNameSuccess(response.body)))
        .catch((error) => dispatch(variableNameError(error)));
    } else {
      GETRequest(
        `${endPoints.config}/variable?type=${type}&keyword=${keyword}&componentId=${componentId}`
      )
        .then((response) => dispatch(variableNameSuccess(response.body)))
        .catch((error) => dispatch(variableNameError(error)));
    }
  };
}

export function variableTag(equipmentId, componentId, keyword, facilityId) {
  return function (dispatch) {
    dispatch(variableTagProgress());
    if (keyword === "") {
      GETRequest(
        `${endPoints.tags}?equipmentId=${equipmentId}&componentId=${componentId}&facilityId=${facilityId}`
      )
        .then((response) => dispatch(variableTagSuccess(response.body)))
        .catch((error) => dispatch(variableTagError(error)));
    } else {
      GETRequest(
        `${endPoints.tags}?equipmentId=${equipmentId}&componentId=${componentId}&keyword=${keyword}&facilityId=${facilityId}`
      )
        .then((response) => dispatch(variableTagSuccess(response.body)))
        .catch((error) => dispatch(variableTagError(error)));
    }
  };
}

export function addInputData(e_id, c_id, payload) {
  return function (dispatch) {
    dispatch(addInputDataProgress());
    PUTRequest(
      `${endPoints.equipments}/${e_id}/${endPoints.component}/${c_id}/${endPoints.input}`,
      payload
    )
      .then((response) => dispatch(addInputDataSuccess(response.body)))
      .catch((error) => dispatch(addInputDataError(error)));
  };
}
