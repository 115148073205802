import { Button, Checkbox, Col, Input, Layout, Menu, Modal, Pagination, Row } from "antd";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import { MANAGE_PI_TAGS_CONSTANTS } from "../../../constants/variables";
import TagDetails from "./TagDetails";
import onSign from "../../../assets/ic_on/on.png"
import offSign from "../../../assets/ic_off/off.png"
import { piTagDelete, TagBaseTimeUpdate , piTagLogsDataClear} from "../../../redux/actions/piTagUpload.action";
import { useDispatch, useSelector } from "react-redux";
import { validateNaturalNumber } from "../../../libs/validator";
const { Sider, Content } = Layout;

const FieldCalculatedTags = (activeTab, tagList, setSkip, totalTagCount,
    equipmentsList, systems, FacilityId, variablePropertyList, variablePropertyUnits,
    handleChange, onTextChange, searchText, onSearch, pageNumber, setPageNumber
) => {
    const dispatch = useDispatch()
    let deleteTag = useSelector((state) => state.piTagReducer.piTagDelete);

    const [tags, setTags] = useState([])
    const [timeInterval, setTimeInterval] = useState(0)
    const [selectedKey, setselectedKey] = useState(0)
    const [selectedTag, setselectedTag] = useState()
    // const [pageNumber, setPageNumber] = useState(1)
    const [deleteList, setDeleteList] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [bulkSelect, setBulkSelect] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (tagList && tagList.length > 0) {
            setTags(tagList)
        }
        if (tagList && tagList[selectedKey]) {
            setselectedTag(tagList[selectedKey])
        }
        else if(tagList && tagList.length > 0){
            setselectedKey(0)
            setselectedTag(tagList[0])
        }
        else {
            setTags([])
            setselectedTag()
        }
    }, [tagList, selectedKey])

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        if(width <= 1419)
        {
            if (
                document.querySelector('.close-button').querySelectorAll('*')[0]
                  .className === 'anticon anticon-left'
              ) {
                document.querySelector('.close-button').click();
              }
        }
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [width]);

    useEffect(() => {
        if (selectedTag && selectedTag.facilityId && selectedTag.facilityId.baseIntervalTime) {
            setTimeInterval(selectedTag.facilityId.baseIntervalTime)
        }
    }, [selectedTag])

    useEffect(() => {
        if (deleteTag && deleteTag.isSuccess && !deleteTag.isError) {
            setBulkSelect(false)
            setDeleteList([])
            setselectedKey(0)
        }
    }, [// eslint-disable-line
        deleteTag
    ])

    const handleSelect = (id, type) => {
        let deleteArrayPayload = [...deleteList]
        if (type === "Select") {
            deleteArrayPayload = [...deleteList, id]
        }
        else if (type === "Deselect") {
            let filteredList = deleteArrayPayload.filter(item => item !== id)
            deleteArrayPayload = filteredList
        }
        setDeleteList(deleteArrayPayload)
    }

    const tagState = (data) => {
        if (data) {
            return (
                <div>
                    <img src={onSign} className="state-logo" alt="state-logo" />
                </div>
            )
        }
        else {
            return (
                <div>
                    <img src={offSign} className="state-logo" alt="state-logo" />
                </div>
            )
        }
    }

    const handlePageChange = (page) => {
        setPageNumber(page)
        setBulkSelect(false)
        setDeleteList([])
        setselectedKey(0)
        if (page === 1) {
            setSkip(0)
        }
        else {
            setSkip((Number(page) - 1) * 20)
        }
    }
    const showDeleteButton = () => {
        if (deleteList.length) {
            return (
                <span className="bulkDelete">
                    <Button className="delete-btn" onClick={() => { setShowDeleteModal(!showDeleteModal) }}>Delete</Button>
                </span>
            )
        }
    }

    const showSaveButton = () => {
        if (selectedTag && selectedTag.facilityId && selectedTag.facilityId.baseIntervalTime
            && selectedTag.facilityId.baseIntervalTime !== Number(timeInterval)
        ) {
            return (
                <span className="save-time">
                    <Button className="delete-btn" onClick={() =>
                        dispatch(TagBaseTimeUpdate(FacilityId, timeInterval))}
                        disabled={(timeInterval === 0 || timeInterval === "")}
                    >Save</Button>
                    &nbsp; &nbsp;
                    <Button className="cancle-btn" onClick={() =>
                        setTimeInterval(selectedTag.facilityId.baseIntervalTime)
                       }
                    >Cancel</Button>
                </span>
                
            )
        }
        else if (selectedTag && selectedTag.facilityId && !selectedTag.facilityId.baseIntervalTime) {
            return (
                <span className="save-time">
                    <Button className="delete-btn" onClick={() =>
                        dispatch(TagBaseTimeUpdate(FacilityId, timeInterval))}
                        disabled={(timeInterval === 0 || timeInterval === "")}
                    >Save</Button>
                </span>
            )
        }
    }
    const handleBulkSelect = (bulkSelect) => {
        setBulkSelect(!bulkSelect);
        setDeleteList([])
        let selectedTags = []
        if(!bulkSelect){
            tags.forEach((tag) => {
                if(tag && tag._id){
                    selectedTags.push(tag._id)
                }
            })
            setDeleteList(selectedTags)
        }
    }

    const handleDelete = () => {
        let payload = {
            facilityId: FacilityId,
            tags: deleteList
        }
        if (deleteList.length) {
            dispatch(piTagDelete(payload))
        }
    }

    const handleDeleteModal = () => {
        if (showDeleteModal) {
            return (
                <Modal
                    visible={showDeleteModal}
                    onCancel={() => setShowDeleteModal(false)}
                    onOk={() => { setShowDeleteModal(false); handleDelete() }}
                    title={"Alert"}
                >
                    Delete selected tags ?
                </Modal>
            )
        }
    }
    const handleSearch = () => {
        setselectedKey(0)
        onSearch()
    }
    return (
        <Layout className="layout-design">
            <Sider
                style={{
                    overflow: 'auto',
                    height: '125vh',
                    left: 0,
                    background: 'white',
                }}
                width={"50%"}
                className="menuStyle"
            >
                <div className="header-design">
                    <div className="time-interval">{MANAGE_PI_TAGS_CONSTANTS.BASE_INTERVAL_TIME}
                        <Input
                        value={timeInterval}
                        onKeyDown={(e) => {
                            validateNaturalNumber(e, e.target.value);
                        }}
                        onChange={(e) => setTimeInterval(e.target.value)}
                        type="number"
                        className="time-input"
                        />
                        {showSaveButton()}
                        {showDeleteButton()}
                    </div>
                    <div className="search-section time-interval">
                        <Search
                            placeholder="Search Here"
                            className="search-bar"
                            onChange={(e) => onTextChange(e.target.value)}
                            allowClear={true}
                            onSearch={() => handleSearch()}
                            value={searchText}
                        ></Search>
                    </div>
                    <Row className="tag-list ">
                        <Col span={2} className="bulk-select">
                            {tags.length ?
                                <Checkbox onChange={() => { handleBulkSelect(bulkSelect)}} checked={bulkSelect} /> :
                                <></>
                            }
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={6} >
                            Variable Tag
                        </Col>
                        <Col span={4} >
                            Units
                        </Col>
                        <Col span={11}  style={width <= 1100 ?{paddingLeft:'0.6rem'}:{}}>
                            Variable Name
                        </Col>
                    </Row>
                </ div>
                <Content >
                    <Menu mode="inline" className="tag-section" selectedKeys={[selectedKey.toString()]}>
                        {tags.map((data, index) => {
                            if (data.type) {
                                return (
                                    <Menu.Item
                                        key={index}
                                        onClick={() => {
                                            dispatch(piTagLogsDataClear());
                                                setselectedKey(index)
                                                setCurrentPage(1);
                                                }}
                                        className={index % 2 === 0 ? "menu-design1" : "menu-design2"}
                                    >
                                        <Row >
                                            <Col span={2} className="tags tag-checkbox">
                                                <Checkbox onChange={() => handleSelect(data._id, deleteList.includes(data._id) ? "Deselect" : "Select")}
                                                    checked={deleteList.includes(data._id)} />
                                            </Col>
                                            <Col span={1} className="tags">
                                                {tagState(data.isIQR)}
                                            </Col>
                                            <Col span={6} className="tags" >
                                                {data.piTag}
                                            </Col>
                                            <Col span={4} className="tags" >
                                                {data.units}
                                            </Col>
                                            <Col span={11} className="tags variable-name" >
                                                {data.variableName}
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                )
                            }
                            else {
                                return <></>
                            }
                        })}
                    </Menu>
                </Content>
                <div className="pagination">
                    <Pagination defaultCurrent={1} total={Math.round(totalTagCount / 2)} current={pageNumber} onChange={handlePageChange} />
                </div>
            </Sider>
            {TagDetails(selectedTag, equipmentsList, systems, FacilityId, variablePropertyList, variablePropertyUnits, handleChange, deleteList.length, currentPage, setCurrentPage)}
            {handleDeleteModal()}

        </Layout>
    )
}

export default FieldCalculatedTags